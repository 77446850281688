import React, { memo, useState } from 'react';
import { Input, DatePicker, Drawer, Select, AutoComplete } from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';

import {
  RideButton,
  RideGlFiltersSolid,
  RideGlDeleteSolid,
} from '@rimac-seguros/ride-system-components';

const { RangePicker } = DatePicker;

const FiltersMobile = ({
  filters = [],
  onClearFilters = () => {},
  buttonTitle,
  drawerTitle,
  setVisibleFilter = () => {},
  onFinishFilters = () => {},
}) => {
  const [visibleFilters, setVisibleFilters] = useState();

  const onClearFilter = () => {
    onClearFilters();
    setVisibleFilter(false);
    setVisibleFilters(false);
  };

  return (
    <div className='flex items-center'>
      <RideButton
        variant='text-primary'
        onClick={() => {
          setVisibleFilter(true);
          setVisibleFilters(true);
        }}
        text={buttonTitle ?? 'Filtrar'}
        glyph={<RideGlFiltersSolid />}
        glyphPosition='left'
      />

      <Drawer
        title={drawerTitle ?? 'Filtra tu búsqueda'}
        placement='right'
        closable={true}
        onClose={() => {
          setVisibleFilter(false);
          setVisibleFilters(false);
        }}
        open={visibleFilters}
      >
        <div className='flex flex-col gap-2'>
          {filters.map(
            ({ key, typeFilter, onChange, className, ...inputProps }) => {
              switch (typeFilter) {
                case 'search':
                  return (
                    <Input.Search
                      key={key}
                      allowClear
                      placeholder={'Palabras clave'}
                      onChange={onChange}
                      style={{
                        width: '100%',
                        maxWidth: '300px',
                      }}
                      {...inputProps}
                    />
                  );
                case 'select':
                  return (
                    <Select
                      key={key}
                      className='full-width'
                      onChange={onChange}
                      {...inputProps}
                    />
                  );
                case 'selectMultiple':
                  return (
                    <AutoComplete
                      key={key}
                      className='full-width'
                      options={inputProps.options.map((option) => ({
                        value: option.label,
                      }))}
                      onSelect={(value) =>
                        inputProps._handleUpdateFilters(value)
                      }
                      placeholder={inputProps.placeholder}
                    />
                  );
                case 'rangePicker':
                  return (
                    <RangePicker
                      key={key}
                      style={{ width: '100%' }}
                      format='DD-MM-YYYY'
                      onChange={onChange}
                      locale={locale}
                      {...inputProps}
                    />
                  );
                default:
                  return null;
              }
            },
          )}
          <div className='flex justify-between gap-3 mt-3'>
            <RideButton
              variant='text-primary'
              onClick={onClearFilter}
              text='Limpiar filtros'
              glyph={<RideGlDeleteSolid />}
              glyphPosition='left'
            />
            <RideButton
              onClick={() => {
                onFinishFilters();
                setVisibleFilter(false);
                setVisibleFilters(false);
              }}
              text='Filtrar'
            />
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default memo(FiltersMobile);
