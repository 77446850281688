import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import {
  getArticleByUrl,
  getPopularArticles,
} from '../../../services/articles';

import FooterPortal from '../../../common/FooterPortal';
import HeaderPortal from '../../../common/HeaderPortal';
import BreadcrumbSection from './BreadcrumbSection';
import Header from './Header';
import FeedbackButton from './FeedbackButton';
import MainSection from './MainSection';
import Loader from './Loader';

const Article = () => {
  let navigate = useNavigate();
  let { article_url } = useParams();
  const [popularArticles, setPopularArticles] = useState([]);
  const [article, setArticle] = useState(null);

  useEffect(() => {
    getArticleByUrl(article_url).then((article) => {
      if (!article) navigate('/articulos');

      setArticle(article);
    });
    getPopularArticles().then((data) => {
      setPopularArticles(data.list);
    });
  }, [article_url, navigate]);

  const titleRedNull = (titleRed) => {
    if (!titleRed) {
      return '';
    } else {
      return titleRed;
    }
  };

  return (
    <div className='portal-web'>
      <HeaderPortal />
      <main className='portal-article'>
        {article ? (
          <>
            <BreadcrumbSection
              href={'/articulos'}
              title={article.title ?? ''}
            />
            <Header
              title={`${article.title} ${titleRedNull(article.title_red)}`}
              subtitle={article.subtitle}
              category={article.category}
            />
            <MainSection article={article} popularArticles={popularArticles} />
            <FeedbackButton article={article} />
          </>
        ) : (
          <Loader />
        )}
      </main>
      <FooterPortal />
    </div>
  );
};

export default Article;
