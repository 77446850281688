import React, { useState, useMemo } from 'react';
import { Typography, Divider, notification, Select } from 'antd';
import {
  getForecastConfigById,
  getLocationById,
} from '../../../../services/locations';
import { useSelector } from 'react-redux';
import CardCollapse from '../../../../components/admin/Settings2/components/CardCollapse';
import NotSelectedAddress from '../../../../components/admin/Settings2/components/NotSelectedAddress';

import FlashAlerts from '../../../../components/admin/Settings2/Alerts2/FlashAlerts';
import TemperatureAlerts from '../../../../components/admin/Settings2/Alerts2/TemperatureAlerts';
import WindsAlerts from '../../../../components/admin/Settings2/Alerts2/WindsAlerts';
import RainAlerts from '../../../../components/admin/Settings2/Alerts2/RainAlerts';

const { Text } = Typography;

const DATA_BY_LOCATION = [
  { title: 'Lluvias', type: 'rains', component: RainAlerts },
  { title: 'Vientos', type: 'winds', component: WindsAlerts },
  { title: 'Temperatura', type: 'temperature', component: TemperatureAlerts },
  { title: 'Rayos', type: 'flash', component: FlashAlerts },
];

const DrawerAlerts = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [dataLocation, setDataLocation] = useState([]);
  const [locationSelected, setLocationSelected] = useState();

  const map = useSelector((state) => state.maps.map);
  const listLocations = useSelector((state) => state.locations.list);

  const handleSelectLocation = async (value) => {
    setIsLoading(true);
    setLocationSelected(value);
    await getForecastConfigById(value)
      .then((res) => {
        setDataLocation(res);
      })
      .finally(() => setIsLoading(false));
    await getLocationById(value).then((res) => {
      if (res && res.lat && res.lng) {
        map.flyTo({ lat: res.lat, lng: res.lng }, 10);
      } else {
        notification.error({
          message: 'Ubicación sin coordenadas',
          description:
            'La ubicación consultada no tiene coordenadas para mostrar en el mapa',
          duration: 7,
        });
      }
    });
  };

  const locations = useMemo(
    () =>
      listLocations.map(({ name, id, province, department }) => ({
        label: name || `${province}, ${department}`,
        value: String(id),
      })),
    [listLocations],
  );

  const handleUpdatedData = (type, updatedData) => {
    setDataLocation((prevData) => {
      const newData = [...prevData];
      const index = newData.findIndex((el) => el.type === type);
      if (index !== -1) {
        newData[index] = { ...newData[index], ...updatedData };
      } else {
        newData.push({ type, ...updatedData });
      }
      return newData;
    });
  };

  return (
    <>
      <Text className='text-neutral'>Elige tu Ubicación</Text>
      <div style={{ maxWidth: '321px' }}>
        <Select
          value={locationSelected}
          placeholder='Ubicaciones'
          loading={isLoading}
          options={locations.map((el) => ({
            label: el.label,
            value: el.value,
          }))}
          onChange={handleSelectLocation}
          style={{ width: '100%' }}
        />
      </div>
      <Divider />
      {locationSelected ? (
        <Text className='text-neutral'>Configura tus alertas</Text>
      ) : (
        <NotSelectedAddress title='Aún no has seleccionado una ubicación para configurar alertas' />
      )}
      {locationSelected && (
        <div className='card-container-drawer'>
          {DATA_BY_LOCATION.map((el) => {
            const data = dataLocation.find((e) => e.type === el.type);
            const Component = el.component;
            return (
              <CardCollapse
                key={el.type}
                dataLocation={dataLocation}
                type={el.type}
                title={el.title}
              >
                <Component
                  data={data}
                  idLocation={Number(locationSelected)}
                  onUpdatedData={handleUpdatedData}
                />
              </CardCollapse>
            );
          })}
        </div>
      )}
    </>
  );
};

export default DrawerAlerts;
