import { useState } from 'react';

import { useScreen } from '../../../../lib/screen';

import Topbar from '../../../../common/ui/Topbar';
import Title from '../../../../common/Typograph/Title';

import Register from './Register';
import UploadFile from './UploadFile';
import Tabs from '../../../../common/Tabs';

const LIST_TABS = [
  {
    key: 'upload',
    label: 'Subir Archivo',
    children: <UploadFile />,
  },
  {
    key: 'resgister',
    label: 'Historial',
    children: <Register />,
  },
];

const SocialConflicts = () => {
  const { isMobileScreen } = useScreen();
  const [activeTab, setActiveTab] = useState(LIST_TABS[0]);

  return (
    <div id='checkSize' className='h100vh dashboard-UI'>
      <Topbar />
      <div className='d_content service-prevention'>
        <Title type={isMobileScreen ? 'bold-32' : 'bold-28'}>
          Conflictos Sociales
        </Title>

        <Tabs
          activeKey={activeTab.key}
          onChange={(activeKey) =>
            setActiveTab(LIST_TABS.find((el) => el.key === activeKey))
          }
          items={LIST_TABS}
        />
      </div>
    </div>
  );
};

export default SocialConflicts;
