import React from 'react';
import { Button } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

const SuccessMessage = ({ handleShowRecovery }) => (
  <div className='flex flex-col items-center content-ready'>
    <InfoCircleOutlined style={{ fontSize: '24px' }} />
    <h3>¡Listo!</h3>
    <p>
      Si ya contabas con una contraseña anteriormente; te hemos enviado con
      éxito a tu correo electrónico un link para que puedas crear una nueva
      contraseña
    </p>
    <Button
      type='primary'
      size='large'
      onClick={() => handleShowRecovery(false)}
    >
      De acuerdo
    </Button>
  </div>
);

export default SuccessMessage;
