import { useEffect, useState } from 'react';
import {
  RideButton,
  RideRadioButton,
  RideRadioButtonGroup,
  RideSelect,
} from '@rimac-seguros/ride-system-components';
import dayjs from 'dayjs';

import { useScreen } from '../../../../../lib/screen';

import Drawer from '../../../../../common/Drawer';
import Divider from '../../../../../common/Divider';
import DatePicker from '../../../../../common/DatePicker';
import { FILTERS, initialFilters } from '.';

const { RangePicker } = DatePicker;

const NewsletterDrawer = ({ open, onClose, onSave, filters }) => {
  const { isMobileScreen } = useScreen();
  const [drawerFilters, setDrawerFilters] = useState(filters);

  useEffect(() => {
    setDrawerFilters(filters);
  }, [filters]);

  const handleFilters = (e) => {
    setDrawerFilters((prev) => ({
      ...prev,
      [e.target.name]:
        e.target.value === '' || isNaN(e.target.value)
          ? e.target.value
          : +e.target.value,
    }));
  };

  const handleDateFilter = (_, date) => {
    setDrawerFilters((prev) => ({
      ...prev,
      date,
    }));
  };

  const hanldeResetFilters = () => setDrawerFilters(initialFilters);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      footer={
        <div
          className={`flex ${
            isMobileScreen
              ? 'flex-col'
              : 'flex-row-reverse justify-center gap-4'
          } items-center`}
        >
          <RideButton
            size='large'
            text='Aplicar filtros'
            onClick={() => onSave(drawerFilters)}
          />
          <RideButton
            size='large'
            variant='text-primary'
            text='Borrar filtros'
            onClick={hanldeResetFilters}
          />
        </div>
      }
    >
      <div className='newsltettersDrawer'>
        <strong className='newsltettersDrawer__title'>Filtrar por:</strong>
        <Divider style={{ borderColor: '#CCD1EE' }} />
        <div className='flex flex-col gap-4'>
          {FILTERS.map((filter) => {
            if (filter.type === 'select') {
              return (
                <RideSelect
                  label={filter.label}
                  name={filter.name}
                  key={filter.name}
                  value={drawerFilters[filter.name] ?? ''}
                  placeholder={filter.placeholder}
                  options={filter.options}
                  onChange={handleFilters}
                  required
                />
              );
            }
            if (filter.type === 'radioGroup') {
              return (
                <RideRadioButtonGroup label={filter.label} key={filter.name}>
                  {filter.options.map((option) => (
                    <RideRadioButton
                      key={option.text}
                      name={filter.name}
                      text={option.text}
                      value={option.value}
                      checked={drawerFilters[filter.name] === option.value}
                      onChange={handleFilters}
                    />
                  ))}
                </RideRadioButtonGroup>
              );
            }
            if (filter.type === 'rangePicker') {
              return (
                <RangePicker
                  key={filter.name}
                  label={filter.label}
                  size='large'
                  name={filter.name}
                  value={drawerFilters[filter.name].map((date) =>
                    Boolean(date) ? dayjs(date, 'DD/MM/YYYY') : null,
                  )}
                  onChange={handleDateFilter}
                  disabledDate={(current) =>
                    current && current < dayjs('05/05/2020')
                  }
                />
              );
            }
          })}
        </div>
      </div>
    </Drawer>
  );
};

export default NewsletterDrawer;
