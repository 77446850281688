import React from 'react';

const MobileFooter = () => {
  return (
    <footer className='mobile-footer'>
      <img src='/images/portal/login/logo-rimac-white.svg' alt='logo rimac' />
      <span className='ride-sys-text-paragraph-small-default'>
        &copy; {new Date().getFullYear()} RIMAC Seguros y Reaseguros.
      </span>
    </footer>
  );
};

export default MobileFooter;
