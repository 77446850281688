import {
  LOAD_USERS_LOADING,
  LOADED_USERS,
  ERROR_FORM_USERS,
  LOADING_CREATE_USERS,
  CHANGE_USERS_QUERY,
  UPDATE_TOTAL_USERS,
} from '../actions/actionsType';

const initialState = {
  users: [],
  totalUsers: 0,
  isLoadingUser: false,
  isLoadingCreate: false,
  errors: null,
  query: '',
  pagination: {
    pageSize: 5,
    current: 1,
    total: 0,
  },
};

const users = (state, action) => {
  // NOSONAR
  if (!state) state = initialState;

  switch (action.type) {
    case LOAD_USERS_LOADING:
      return {
        ...state,
        isLoadingUser: action.payload,
      };
    case LOADED_USERS:
      if (state.query && state.query !== action.payload.query) return state;

      return {
        ...state,
        users: action.payload.list,
        isLoadingUser: false,
        pagination: {
          current: action.payload.page,
          pageSize: action.payload.items_per_page,
          total: action.payload.total,
        },
      };
    case ERROR_FORM_USERS:
      return {
        ...state,
        errors: action.payload,
      };
    case LOADING_CREATE_USERS:
      return {
        ...state,
        isLoadingCreate: action.payload,
      };
    case CHANGE_USERS_QUERY:
      return {
        ...state,
        query: action.payload || '',
      };
    case UPDATE_TOTAL_USERS:
      return {
        ...state,
        totalUsers: action.payload,
      };
    default:
      return state;
  }
};

export default users;
