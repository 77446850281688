import {
  LOADED_RPT_POLIZAS,
  LOADED_RPT_POLIZAS_RESET,
  LOADING_RPT_POLIZAS,
  LOADING_RPT_POLIZAS_FAILED,
  LOADED_POLIZAS_COMPANY_TRACKING,
} from '../actions/actionsType';

const initialState = {
  isLoading: false,
  exportFailed: false,
  filePath: null,
  list: [],
};

const poliza = (state, action) => {
  if (!state) state = initialState;

  switch (action.type) {
    case LOADING_RPT_POLIZAS:
      return {
        ...state,
        isLoading: action.payload,
      };
    case LOADING_RPT_POLIZAS_FAILED:
      return {
        ...state,
        isLoading: false,
        exportFailed: true,
      };
    case LOADED_RPT_POLIZAS:
      return {
        ...state,
        isLoading: false,
        exportFailed: false,
        filePath: action.payload,
      };
    case LOADED_RPT_POLIZAS_RESET:
      return {
        ...state,
        isLoading: false,
        exportFailed: false,
        filePath: null,
      };
    case LOADED_POLIZAS_COMPANY_TRACKING:
      return {
        ...state,
        list: action.payload,
      };
    default:
      return state;
  }
};

export default poliza;
