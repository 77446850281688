import { get, post, put } from '../lib/ajax';
import {
  LOADED_ALL_LEVEL_COMPANY,
  LOADED_LEVEL_COMPANY,
  LOAD_LEVEL_COMPANY_LOADING,
} from '../redux/actions/actionsType';

export const getLevelCompany = async (dispatch) => {
  const r = await get('/level_company/listAll');
  if (r.data.success) {
    let list = r.data.payload;
    list = list?.map((item) => {
      const numberServices = item.levelCompanyServices?.length
        ? item?.levelCompanyServices?.length
        : 0;
      const typeFormated =
        item.type === 2
          ? 'Personalizado'
          : `Estandar (${item.minor} - ${item.major})`;

      return {
        ...item,
        numberServices: `${numberServices} servicios`,
        typeFormated,
      };
    });
    dispatch({ type: LOADED_LEVEL_COMPANY, payload: list });
  }
};

export const removeLevelCompany = async (id, dispatch) => {
  const r = await post('/level_company/remove?id=' + id);
  if (r.data.success) {
    dispatch({ type: LOADED_LEVEL_COMPANY });
  }
};

export const listLevelCompanyPag = async (dispatch, pagination) => {
  dispatch({ type: LOAD_LEVEL_COMPANY_LOADING, payload: true });

  const r = await post('/level_company/list', pagination);
  if (r.data.success) {
    dispatch({ type: LOADED_ALL_LEVEL_COMPANY, payload: r.data.payload });
    return true;
  }

  return false;
};

export const updateLevelCompanyPag = async (
  typeIndustry,
  dispatch,
  pagination = {},
) => {
  dispatch({ type: LOAD_LEVEL_COMPANY_LOADING, payload: true });
  const r = await put('/level_company/updatePagination', typeIndustry);

  if (r.data.success) {
    dispatch({ type: LOAD_LEVEL_COMPANY_LOADING, payload: false });
    dispatch && (await listLevelCompanyPag(dispatch, pagination));

    return true;
  }
  dispatch({ type: LOAD_LEVEL_COMPANY_LOADING, payload: false });

  return false;
};

export const createLevelCompanyPag = async (typeIndustry, dispatch) => {
  dispatch && dispatch({ type: LOAD_LEVEL_COMPANY_LOADING, payload: true });
  const r = await post('/level_company/createPagination', typeIndustry);

  if (r.data.success) {
    dispatch && dispatch({ type: LOAD_LEVEL_COMPANY_LOADING, payload: false });
    dispatch &&
      (await listLevelCompanyPag(dispatch, {
        page: 1,
        items_per_page: 5,
        query: '',
      }));

    return true;
  }
  dispatch && dispatch({ type: LOAD_LEVEL_COMPANY_LOADING, payload: false });

  return false;
};

export const removeLevelCompanyPag = async (id, dispatch, pagination) => {
  const r = await post('/level_company/removePagination?id=' + id);
  if (r.data.success) {
    await listLevelCompanyPag(dispatch, pagination);

    return true;
  }

  return false;
};
