export const processDataToStorage = (rsIdata) => {
  try {
    return rsIdata.reduce((acc, curr) => {
      if (Array.isArray(curr.value)) {
        acc[curr.key] = processArrayValue(acc[curr.key], curr.value);
      }

      return acc;
    }, {});
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const processArrayValue = (existingValue, arrayValue) => {
  const processedValue = arrayValue.reduce((acc, item) => {
    acc[item.key] = item.value;
    if (item.comment) acc[`${item.key}-comment`] = item.comment;
    if (item.file) acc[`${item.key}-file`] = item.file;

    return acc;
  }, {});

  return { ...existingValue, ...processedValue };
};
