const SERVICES_ITEMS = [
  {
    url: '/images/portal/icon_survey.svg',
    text: 'Evaluación de riesgos',
  },
  {
    url: '/images/portal/icon_fire-training.svg',
    text: 'Asesoría en Ingeniería contra incendios',
  },
  {
    url: '/images/portal/icon_fire-system.svg',
    text: 'Revalidación de sistemas contra incendio',
  },
  {
    url: '/images/portal/icon_battery.svg',
    text: 'Peritaje eléctricos',
  },
  {
    url: '/images/portal/Icon_thermography.svg',
    text: 'Análisis Termográfico',
  },
  {
    url: '/images/portal/icon_vibration.svg',
    text: 'Análisis Vibracional',
  },
  {
    url: '/images/portal/icon_signage.svg',
    text: 'Rotulación y señalética',
  },
  {
    url: '/images/portal/icon_buildings.svg',
    text: 'Capacitación in house',
  },
  {
    url: '/images/portal/icon_person-cuorse.svg',
    text: 'Curso on line',
  },
];

export default SERVICES_ITEMS;
