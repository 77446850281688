// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import { MAP_SET_MAP } from '../../../../redux/actions/actionsType';
import { connect } from 'react-redux';
import L from 'leaflet';
import { GeoJSON, TileLayer } from 'react-leaflet';
import { getFlashes } from '../../../../services/ligthing';
import moment from 'moment/moment';

const Index = ({
  activeSubLayer,
  activeSubLayerFromRedux,
  getPoints,
  opacity,
}) => {
  const currentSubLayer = activeSubLayer ?? activeSubLayerFromRedux;
  const [geojson, setGeojson] = useState(null);

  useEffect(() => {
    getPoints(currentSubLayer.path).then((results) => {
      setGeojson(results);
    });
  }, []);

  const attr = {
    pointToLayer: (feature, latlng) => {
      const now = moment.utc();
      let color = '';
      let geojsonMarkerOptions = {
        radius: 3,
        fillColor: '#01b0f1',
        color: '#000',
        weight: 1,
        opacity: 1,
        fillOpacity: 0.8,
      };
      if (feature.properties.datetime) {
        const timePoint = moment.utc(feature.properties.datetime);
        const timeOld = (now.unix() - timePoint.unix()) / 60 / 2;
        if (timeOld < 1) color = '#fe0000';
        else if (timeOld > 1 && timeOld <= 2) color = '#ffff01';
        else if (timeOld > 2 && timeOld <= 3) color = '#92d14f';
        else if (timeOld > 3 && timeOld <= 4) color = '#00af4f';
        else if (timeOld > 4 && timeOld <= 5) color = '#01b0f1';
      }

      if (color)
        geojsonMarkerOptions = {
          ...geojsonMarkerOptions,
          fillColor: color,
        };

      return L.circleMarker(latlng, geojsonMarkerOptions);
    },
  };

  return (
    <>
      <TileLayer
        attribution='Google Maps'
        url='https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}'
        opacity={opacity}
      />
      {geojson && <GeoJSON data={geojson} pointToLayer={attr.pointToLayer} />}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setMap: (map) => dispatch({ type: MAP_SET_MAP, payload: map }),
  getPoints: (path) => getFlashes(path),
});

const mapStateToProps = (state) => ({
  activeSubLayerFromRedux: state.maps.activeSubLayer,
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
