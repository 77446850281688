const states = {
  1: { color: 'blue', status: 'Nuevo' },
  2: { color: 'gray', status: 'Cerrado' },
  3: { color: 'yellow', status: 'En proceso' },
};

const CardHeader = ({ status }) => {
  return (
    <div className='flex flex-row flex-justify-justify-between'>
      <span className={`tag-${states[status].color} withpoint`}>
        {states[status].status}
      </span>

      <div className='flex'></div>
    </div>
  );
};
export default CardHeader;
