import { SIDEMENU_CHANGE_VISIBLE } from '../actions/actionsType';

const initialState = {
  isOpened: false,
};

const sideMenu = (state, action) => {
  if (!state) {
    state = initialState;
  }

  switch (
    action.type // NOSONAR
  ) {
    case SIDEMENU_CHANGE_VISIBLE:
      return {
        ...state,
        isOpened: action.payload,
      };
    default:
      return state;
  }
};

export default sideMenu;
