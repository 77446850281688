import {
  CONTROLMAP_DRAWER_VISIBLE,
  CONTROLMAP_SELECTED,
} from '../../redux/actions/actionsType';
import {
  extractDataCircle,
  extractDataPoly,
  getCircle,
  getPoly,
} from '../../services/polizas';
import { post } from '../../lib/ajax';
import axios from 'axios';
import area from '@turf/area';
import { closePopUp } from '../../lib/utils';

export const openDrawerCoordenadas = (dispatch) => {
  const btn = document.querySelector('.btn-create-cicle-popup');
  if (btn) {
    btn.onclick = async () => {
      dispatch({ type: CONTROLMAP_SELECTED, payload: 'marker' });
      dispatch({ type: CONTROLMAP_DRAWER_VISIBLE, payload: true });
    };
  }
};

export const listenDownloadCircleData = (circle, filters) => {
  const btn = document.querySelector('.download-circle-data');
  if (btn) {
    btn.onclick = async (e) => {
      e.stopPropagation();
      btn.innerHTML = 'Descargando...';

      post('/polizas/exportCircle', {
        ...circle,
        filters,
      }).then((response) => {
        const id = setInterval(() => {
          post('/polizas/checkExportedCircle', response.data.payload).then(
            (resp) => {
              if (resp.data.payload.found) {
                clearInterval(id);
                const idDownload = setInterval(() => {
                  let cancelRequest = axios.CancelToken.source();
                  axios({
                    method: 'get',
                    url: resp.data.payload.data,
                    onDownloadProgress: (progressEvent) => {
                      const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total,
                      );
                      if (percentCompleted !== Infinity) {
                        clearInterval(idDownload);
                        cancelRequest.cancel();

                        const link = document.createElement('a');
                        link.href = resp.data.payload.data;
                        document.body.appendChild(link);
                        btn.innerHTML = `Descargar`;
                        link.click();
                      }
                    },
                    cancelToken: cancelRequest.token,
                  })
                    .then(() => {
                      clearInterval(idDownload);
                    })
                    .catch((response) => {
                      console.error('errr', response);
                    });
                }, 4000);
              }
            },
          );
        }, 4000);
      });
    };
  }
};

const listenSaveSimulation = (data, circle, filters) => {
  const showOption = document.querySelector('.show-options');
  const btn = document.querySelector('.save-circle-data');
  const input = document.querySelector('.save-field-name');
  let name;

  if (input) {
    input.addEventListener('input', (e) => {
      name = e.target.value;
      if (name && name.trim().length > 0) {
        btn.removeAttribute('disabled');
      } else {
        btn.setAttribute('disabled', 'disabled');
      }
    });
  }

  if (btn) {
    btn.onclick = async () => {
      btn.innerHTML = 'Guardando...';
      btn.setAttribute('disabled', 'disabled');

      post('/simulations/create', {
        name,
        cantLocation: data.itemsInside,
        countClients: data.count_companies_client,
        totalDeclared: data.sumValDeclarado,
        totalWithheld: data.sumValDeclaradoRetenido,
        geometry: JSON.stringify(circle),
        filters: JSON.stringify(filters),
        type: 1,
      }).then(() => {
        btn.innerHTML = 'Guardado';
        input.remove();
        btn.setAttribute('disabled', 'disabled');
      });
    };
  }

  if (showOption) {
    showOption.onclick = () => {
      const popupOption = document.querySelector('.popup-options');
      if (popupOption) {
        popupOption.classList.remove('hidden');
        showOption.remove();
      }
    };
  }
};

export const loadContentOfCircle = async ({
  circle,
  popup,
  filters,
  dispatch,
  fromDrawer,
}) => {
  let body;
  try {
    const circleData = extractDataCircle(circle);

    getCircle(circle, filters).then((result) => {
      if (result.data.success) {
        const response = result.data.payload;
        const sumValDeclarado =
          response && response.sumValDeclarado
            ? new Intl.NumberFormat('de-DE').format(
                response.sumValDeclarado.toFixed(0),
              )
            : 0;
        const sumValDeclaradoRetenido =
          response && response.sumValDeclaradoRetenido
            ? new Intl.NumberFormat('de-DE').format(
                response.sumValDeclaradoRetenido.toFixed(0),
              )
            : 0;
        const countCompaniesClients =
          response && response.count_companies_client
            ? response.count_companies_client
            : 0;
        const radius = (circleData.circle.radius / 1000).toFixed(2);

        body = `
          <div class='popup-map popup-circle'>
            <div class='popup-title'>Resultado del análisis</div>
            <div class='popup-content'>
              <span class='popup-detail'>
                <span class='popup-detail-header'>Locales analizados</span>
                <span class='popup-detail-content'>
                  ${response.itemsInside} locales
                </span>
                <span class='popup-detail-header'>Empresas clientes</span>
                <span class='popup-detail-content'>
                  ${countCompaniesClients} empresas
                </span>
                <span class='popup-detail-header'>Monto valor</span>
                <span class='popup-detail-content'>$${sumValDeclarado}</span>
                <span class='popup-detail-header'>Valor retenido</span>
                <span class='popup-detail-content'>
                  $${sumValDeclaradoRetenido}
                </span>
                <span class='popup-detail-header'>Coordenadas</span>
                <span class='popup-detail-content'>
                  ${circleData.circle.latlng.lat.toFixed(
                    6,
                  )}, ${circleData.circle.latlng.lng.toFixed(6)}
                </span>
                <span class='popup-detail-header'>Área</span>
                <span class='popup-detail-content'>
                  ${(Math.PI * Math.pow(radius, 2)).toFixed(2)} km²
                </span>
                <span class='popup-detail-header'>Radio</span>
                <span class='popup-detail-content'>${radius} km</span>
              </span>
              <div class='popup-options'>
                ${
                  !fromDrawer
                    ? `
                  <div>
                    <i class='edit-icon'></i> Editar
                  </div>
                `
                    : ''
                }
                <div class='save-form'>
                  <input
                    name='name'
                    placeholder='Nombre de la simulación'
                    class='save-field-name ant-input'
                  />
                </div>
                <div class='popup-detail-buttons'>
                  ${
                    response.itemsInside <= 10000000
                      ? `
                    <button type='button' class='download-circle-data'>
                      Descargar
                    </button>
                  `
                      : ''
                  }
                  <button disabled type='button' class='save-circle-data'>
                    Guardar
                  </button>
                </div>
              </div>
            </div>
          </div>
        `;

        popup.setContent(body);
        listenDownloadCircleData(extractDataCircle(circle), filters);
        listenSaveSimulation(response, extractDataCircle(circle), filters);
        openDrawerCoordenadas(dispatch);
        popup.on('remove', function () {
          closePopUp();
        });
      }
    });
  } catch (e) {}
  body = `
      <div class='popup-map'>
        <div class='popup-content'>
          <div class='spinner h20'></div>
        </div>
      </div>
    `;
  popup.setContent(body);
};

export const loadContentOfPoly = async (poly, popup, filters) => {
  let body;
  try {
    const polyData = extractDataPoly(poly);

    getPoly(poly, filters).then((result) => {
      if (result.data.success) {
        const response = result.data.payload;
        const sumValDeclarado =
          response && response.sumValDeclarado
            ? new Intl.NumberFormat('de-DE').format(
                response.sumValDeclarado.toFixed(0),
              )
            : 0;
        const sumValDeclaradoRetenido =
          response && response.sumValDeclaradoRetenido
            ? new Intl.NumberFormat('de-DE').format(
                response.sumValDeclaradoRetenido.toFixed(0),
              )
            : 0;
        const countCompaniesClients =
          response && response.count_companies_client
            ? response.count_companies_client
            : 0;

        body = `
          <div class='popup-map popup-circle'>
            <div class='popup-title'>Resultado del análisis</div>
            <div class='popup-content'>
              <span class='popup-detail'>
                <span class='popup-detail-header'>Locales analizados</span>
                <span class='popup-detail-content'>
                  ${response.itemsInside} locales
                </span>
                <span class='popup-detail-header'>Empresas clientes</span>
                <span class='popup-detail-content'>
                  ${countCompaniesClients} empresas
                </span>
                <span class='popup-detail-header'>
                  Valor declarado total
                </span>
                <span class='popup-detail-content'>$${sumValDeclarado}</span>
                <span class='popup-detail-header'>Valor retenido</span>
                <span class='popup-detail-content'>
                  $${sumValDeclaradoRetenido}
                </span>
                <span class='popup-detail-header'>Área</span>
                <span class='popup-detail-content'>
                  ${(area(poly.toGeoJSON(6)) / 1000000).toFixed(2)} km²
                </span>
                <span class='popup-detail-header'>Centro del área</span>
                <span class='popup-detail-content'>
                  ${polyData.poly.latlng.lat.toFixed(
                    6,
                  )}, ${polyData.poly.latlng.lng.toFixed(6)}
                </span>
              </span>
              <div class='popup-options'>
                <div class='save-form'>
                  <input
                    name='name'
                    placeholder='Nombre de la simulación'
                    class='save-field-name ant-input'
                  />
                </div>
                <div class='popup-detail-buttons'>
                  ${
                    response.itemsInside <= 10000000
                      ? `
                  <button type='button' class='download-circle-data'>
                    <DownloadOutlined /> Descargar
                  </button>`
                      : ''
                  }
                  <button disabled type='button' class='save-circle-data'>
                    <SaveOutlined /> Guardar
                  </button>
                </div>
              </div>
            </div>
          </div>`;

        popup.setContent(body);
        listenDownloadCircleData(polyData, filters);
        listenSaveSimulation(response, polyData, filters);
        popup.on('remove', function () {
          closePopUp();
        });
      }
    });
  } catch (e) {}
  body = `
      <div class='popup-map'>
        <div class='popup-content'>
          <div class='spinner h20'></div>
        </div>
      </div>
    `;
  popup.setContent(body);
};
