import React, { useEffect } from 'react';
import { Button, List, Skeleton, Typography } from 'antd';
import { RideButton } from '@rimac-seguros/ride-system-components';
import { connect } from 'react-redux';

import {
  getExplorations,
  processExplorations,
} from '../../../../services/exploration';
import ListItem from '../ListItem';
import Topbar from '../../../../common/ui/Topbar';

const { Title } = Typography;

const Google = ({ list, provider, getExplorations, processExplorations }) => {
  list = list.map((item) => ({
    ...item,
    site_url: `${item.site_url}/favicon.ico`,
  }));

  useEffect(() => {
    getExplorations();
  }, [getExplorations]);

  return (
    <div id='checkSize' className='h100vh dashboard-UI'>
      <Topbar />
      <div className='d_content service-prevention'>
        <div className='service-header mb-4'>
          <Title className='header-container'>Noticias Google</Title>

          <RideButton
            onClick={processExplorations}
            type='fill'
            size='medium'
            text='Actualizar'
          />
        </div>
        <div className='list exploration'>
          <div className='list_content'>
            {provider !== 'google' ? (
              <Skeleton active />
            ) : (
              <List
                dataSource={list}
                renderItem={(item) => <ListItem {...item} />}
                pagination={true}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getExplorations: () => getExplorations(dispatch, 'google'),
  processExplorations: () => processExplorations(dispatch),
});

const mapStateToProps = (state) => ({
  list: state.exploration.list,
  provider: state.exploration.provider,
});

export default connect(mapStateToProps, mapDispatchToProps)(Google);
