import axios from 'axios';
import { get, post, put } from '../lib/ajax';
import {
  LOADED_ALL_PARAMETERS,
  LOAD_PARAMETER_LOADING,
} from '../redux/actions/actionsType';
import { sendBannerImage } from './banner';
import { v4 as uuidv4 } from 'uuid';

export const getParameterByKey = async (key) => {
  const r = await get(`/config/GetByKey?key=${key}`);
  if (r.data.success) {
    return r.data.payload;
  }
  return null;
};

export const createParameter = async (parameter, dispatch, pagination) => {
  const r = await post('/config/create', parameter);

  if (r.data.success) {
    await listParametersPag(dispatch, pagination);

    return true;
  }

  return false;
};

export const updateParameter = async (parameter, dispatch, pagination) => {
  const r = await put('/config/update', parameter);

  if (r.data.success) {
    await listParametersPag(dispatch, pagination);

    return true;
  }

  return false;
};

export const listParametersPag = async (dispatch, pagination) => {
  dispatch({ type: LOAD_PARAMETER_LOADING, payload: true });

  const r = await post('/config/pagination', pagination);

  if (r.data.success) {
    dispatch({ type: LOADED_ALL_PARAMETERS, payload: r.data.payload });

    return true;
  }

  return false;
};

export const removeParameterPag = async (id, dispatch, pagination) => {
  const r = await post('/config/removePagination?id=' + id);
  if (r.data.success) {
    await listParametersPag(dispatch, pagination);

    return true;
  }

  return false;
};

export const uploadShapeFile = async (data, handleProgress = () => {}) => {
  try {
    const payload = {
      type: data.type,
      name: 'main_file.zip',
    };

    const res = await post('/config/uploadShapeFile', payload);

    if (res?.data?.success) {
      const { url } = res.data.payload;
      await axios.put(url, data, {
        onUploadProgress: handleProgress,
      });

      return res.data.result;
    }
    throw Error('Error al guardar archivo en historial.');
  } catch (error) {
    throw error;
  }
};

export const uploadLayers = async (data, title, name) => {
  try {
    const uuid = uuidv4();
    const payload = {
      name: `${name}-${uuid}`,
    };

    const res = await post('/weather_forecast/importMyRoutes', payload);

    if (!res?.data?.success) {
      return null;
    }

    const { url } = res.data.payload;

    let bodyUrl;
    switch (name) {
      case 'rainfall-efm':
        bodyUrl = 'weatherForecast/rainfall-efm';
        break;
      case 'rainfall':
        bodyUrl = 'weatherForecast/rainfall';
        break;
      case 'maxTemperature':
        bodyUrl = 'weatherForecast/maxTemperature';
        break;
      case 'minTemperature':
        bodyUrl = 'weatherForecast/minTemperature';
        break;
      default:
        bodyUrl = 'weatherForecast';
    }

    const body = {
      url: `${bodyUrl}-${uuid}`,
      data: name,
      title,
      state: 1,
    };

    await sendBannerImage(url, data);
    return await post('/weather_forecast/create', body);
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const getWeatherForecastLayers = async () => {
  try {
    return await get('/weather_forecast/list');
  } catch (error) {
    return error;
  }
};
