import React from 'react';
import { RightOutlined } from '@ant-design/icons';

const BreadCrumbSection = ({ href, title }) => {
  return (
    <section className='section'>
      <div className='breadcrumb-article'>
        <div className='breadcrumb'>
          <a href={href}>ARTÍCULOS</a> <RightOutlined /> {title}
        </div>
      </div>
    </section>
  );
};

export default BreadCrumbSection;
