import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import FooterPortal from '../../../common/FooterPortal';
import HeaderPortal from '../../../common/HeaderPortal';
import FiltersList from '../components/FiltersList';
import ArticleCard from '../components/ArticleCard';
import List from '../components/List';
import {
  getPopularArticles,
  getPortalArticles,
} from '../../../services/articles';

const filters = ['Todos', 'Monitoreo', 'Prevención', 'Riesgos'];

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Articles = () => {
  let query = useQuery();
  const [articles, setArticles] = useState([]);
  const [lastArticle, setLastArticle] = useState(false);
  const [category, setCategory] = useState();
  const [popularArticles, setPopularArticles] = useState([]);
  const scrollRef = useRef();
  const [total, setTotal] = useState();
  const [reRender, setReRender] = useState(false);

  const fullTitle = (title, titleRed) => {
    if (titleRed) {
      return `${title} ${titleRed}`;
    }
    return title;
  };

  const handleChangeFilter = (index) => {
    setReRender((s) => !s);
    setCategory(index);
    loadArticles(1, index);
    scrollRef.current &&
      scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const onChangeList = (page) => {
    loadArticles(page, category);
    scrollRef.current &&
      scrollRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const defaultFilter = useCallback(() => {
    const filter = query.get('f');
    switch (filter) {
      case 'monitoreo':
        return 1;
      case 'prevencion':
        return 2;
      case 'riesgos':
        return 3;
      default:
        return 0;
    }
  }, []);

  const loadArticles = (page, category) => {
    getPortalArticles(category, page).then((data) => {
      setArticles(data.list);
      setLastArticle(data.lastArticle);
      setTotal(data.total);
    });
    getPopularArticles(category).then((data) => {
      setPopularArticles(data.list);
    });
  };

  useEffect(() => {
    const category = defaultFilter();
    setCategory(category);
    loadArticles(1, category);
  }, [defaultFilter]);

  return (
    <div className='portal-web'>
      <HeaderPortal />
      <div className='portal-articles'>
        <div className='breadcrumb-articles'>
          <div className='breadcrumb'>
            <a href='/'>INICIO</a> <i className='fas fa-chevron-right'></i>
            BIBLIOTECA VIRTUAL <i className='fas fa-chevron-right'></i>
            ARTÍCULOS
          </div>
        </div>
        <div className='head'>
          <div className='head__content'>
            <h2 className='head__title'>Artículos</h2>
            <div className='head__filters'>
              <FiltersList
                defaultFilter={defaultFilter}
                data={filters}
                onChange={handleChangeFilter}
              />
            </div>
          </div>
        </div>
        {popularArticles && popularArticles.length > 0 ? (
          <>
            <div className='section'>
              <div className='main'>
                <a
                  className='main__main-card'
                  href={
                    lastArticle.seo_canonical
                      ? `/articles/${lastArticle.url}.html`
                      : `/articulos/${lastArticle.url}`
                  }
                >
                  <div className='main__main-card__img-container'>
                    <div className='img-container-clip'>
                      <span className='main__main-card__image-tag'>
                        {filters[lastArticle.category]}
                      </span>
                      <img
                        className='main__main-card__image'
                        src={
                          lastArticle.image
                            ? `${process.env.REACT_APP_WEB_PATH_S3_NEW}${lastArticle.image}`
                            : undefined
                        }
                        alt='Artículo'
                      />
                    </div>
                  </div>
                  <div className='main__main-card__text-container'>
                    <div className='main__main-card__title'>
                      {fullTitle(lastArticle.title, lastArticle.title_red)}
                    </div>
                    <div className='main__main-card__description'>
                      {lastArticle.subtitle}
                    </div>
                    <div className='main__main-card__button'>Leer artículo</div>
                  </div>
                </a>
                <div className='main__side'>
                  <h3 className='main__side__head'>Artículos populares</h3>
                  {popularArticles.map((articule, index) => (
                    <a
                      href={
                        articule.seo_canonical
                          ? `/articles/${articule.url}.html`
                          : `/articulos/${articule.url}`
                      }
                      key={`${articule.id}-${index}-popular`}
                      className='main__side__card'
                    >
                      <span className='main__side__tag'>
                        {filters[articule.category]}
                      </span>
                      <div className='main__side__title'>
                        {fullTitle(articule.title, articule.title_red)}
                      </div>
                      <div
                        className='main__side__description'
                        ref={index === 2 ? scrollRef : undefined}
                      >
                        {articule.subtitle}
                      </div>
                    </a>
                  ))}
                </div>
              </div>
            </div>
            <div className='section'>
              <div className='articles'>
                {articles.length > 0 && (
                  <List
                    data={articles}
                    render={(item) => (
                      <div
                        key={`article-${item.id}`}
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <ArticleCard
                          title={fullTitle(item.title, item.title_red)}
                          img={`${process.env.REACT_APP_WEB_PATH_S3_NEW}${item.image}`}
                          description={item.subtitle}
                          tag={filters[item.category]}
                          href={
                            item.seo_canonical
                              ? `/articles/${item.url}.html`
                              : `/articulos/${item.url}`
                          }
                        />
                      </div>
                    )}
                    onChange={onChangeList}
                    reRender={reRender}
                    pagination={{ total }}
                    paginationSize='small'
                    itemsPerPage={6}
                    hideSummary
                    className='articles__list'
                  />
                )}
              </div>
            </div>
          </>
        ) : null}
      </div>
      <FooterPortal />
    </div>
  );
};

export default Articles;
