import { useSelector } from 'react-redux';
import { Input, DatePicker, Button, Select } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import moment, { locale } from 'moment';

import { useScreen } from '../../../../lib/screen';

const { Search } = Input;
const { RangePicker } = DatePicker;

const FilterSection = ({
  text,
  textSearch,
  complete = false,
  handleFilter,
  handleSearchFilter = () => {},
  handleClearFilters = () => {},
  filters,
  placeholder = false,
  total,
  handleToggleFiltersDrawer,
}) => {
  const eventTypes = useSelector((state) => state.eventType.eventTypes);
  const { isMobileScreen } = useScreen();

  return (
    <>
      <div className='profile-company__users--header filter-section mt-0'>
        <h2>{complete ? text : `Filtrar lista de ${text}`}</h2>

        <Search
          value={textSearch}
          placeholder={`${placeholder ? placeholder : 'Palabras clave'}`}
          onChange={handleSearchFilter}
          allowClear
          style={{ width: '100%', maxWidth: '300px' }}
        />
      </div>
      {!isMobileScreen ? (
        <div className='flex items-center mb-4' style={{ gap: '20px' }}>
          <Select
            placeholder='Seleccione el tipo de evento'
            value={filters?.event_type_id}
            style={{ width: '200px' }}
            onChange={(value) => handleFilter('event_type_id', value)}
            options={eventTypes.map(({ id, name }) => ({
              value: id,
              label: name,
            }))}
          />
          <RangePicker
            value={[
              filters?.date_event_end && moment(filters?.date_event_start),
              filters?.date_event_end && moment(filters?.date_event_end),
            ]}
            name='date'
            placeholder={['Fecha de inicio', 'Fecha de fin']}
            format='DD-MM-YYYY'
            onChange={(dates) => handleFilter('date', dates)}
            locale={locale}
          />

          <Button
            type='link'
            style={{ fontSize: '14px', lineHeight: '16px', fontWeight: '700' }}
            className='btn-clearFilter'
            onClick={handleClearFilters}
          >
            <DeleteOutlined className='mr-1' />
            Borrar Filtros
          </Button>
        </div>
      ) : (
        <div
          className='d_content__list__action mb-4 flex flex-row'
          style={{ fontSize: '14px', lineHeight: '16px', fontWeight: '700' }}
        >
          <div className='info-header'>
            {total === 1 ? (
              <>
                <div>
                  Se encontró <b>1</b> evento
                </div>
              </>
            ) : (
              <>
                <div>
                  Se encontraron <b>{total}</b> eventos
                </div>
              </>
            )}
          </div>
          <div className='filter-action' onClick={handleToggleFiltersDrawer}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='none'
            >
              <path
                d='M13 11.26h-.926c-.278-.592-.87-1-1.574-1-.704 0-1.296.408-1.574 1H3a.763.763 0 0 0-.76.76c0 .407.334.759.76.759h5.926c.278.592.87 1 1.574 1 .704 0 1.296-.408 1.574-1H13c.407 0 .76-.334.76-.76a.75.75 0 0 0-.76-.759Zm-2.5 1.5a.763.763 0 0 1-.76-.759c0-.407.334-.76.76-.76s.76.334.76.76-.334.76-.76.76Zm2.5-5.5H7.074c-.278-.592-.87-1-1.574-1-.704 0-1.296.408-1.574 1H3a.763.763 0 0 0-.76.76c0 .407.334.759.76.759h.926c.278.592.87 1 1.574 1 .704 0 1.296-.408 1.574-1H13c.407 0 .76-.334.76-.76A.75.75 0 0 0 13 7.26Zm-7.5 1.5a.763.763 0 0 1-.76-.759c0-.426.334-.76.76-.76s.76.334.76.76-.334.76-.76.76Zm7.5-5.5h-2.426c-.278-.592-.87-1-1.574-1-.704 0-1.296.408-1.574 1H3a.763.763 0 0 0-.76.76c0 .407.334.759.76.759h4.426c.278.592.87 1 1.574 1 .704 0 1.296-.408 1.574-1H13c.407 0 .76-.334.76-.76A.75.75 0 0 0 13 3.26Zm-4 1.5a.763.763 0 0 1-.76-.759c0-.407.334-.76.76-.76.407 0 .76.334.76.76s-.334.76-.76.76Z'
                fill='currentColor'
              ></path>
            </svg>
            <span>Filtrar</span>
          </div>
        </div>
      )}
    </>
  );
};

export default FilterSection;
