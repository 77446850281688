import { LOADED_EVENT_TYPE } from '../actions/actionsType';

const initialState = {
  eventTypes: [],
};

const eventType = (state, action) => {
  if (!state) state = initialState;

  switch (
    action.type // NOSONAR
  ) {
    case LOADED_EVENT_TYPE:
      return {
        ...state,
        eventTypes: action.payload,
      };
    default:
      return state;
  }
};

export default eventType;
