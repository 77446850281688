export const typeServices = [
  {
    id: '1',
    name: 'Capacitación',
    value: '1',
  },
  {
    id: '2',
    name: 'Especializados',
    value: '2',
  },
  {
    id: '4',
    name: 'Riesgos',
    value: '4',
  },
  {
    id: '5',
    name: 'Workshops de Riesgos',
    value: '5',
  },
  {
    id: '6',
    name: 'Evaluación de Riesgo por Lluvia',
    value: '6',
  },
];

export const FORM_FIELDS = {
  services: [
    {
      title: 'Escribe el nombre del servicio',
      label: 'Nombre de servicio',
      key: 'name',
      required: true,
      valid: 'text',
      type: 'text',
    },
    {
      title: '¿El servicio es fijo?',
      key: 'fixed',
      options: [
        { label: 'Si', value: true },
        { label: 'No', value: false },
      ],
      defaultValue: false,
      type: 'radio',
    },
    {
      label: 'Tipo de servicio',
      key: 'type',
      type: 'list',
      valid: 'number',
      required: true,
      options: typeServices,
    },
    {
      label: 'Costo rimac',
      key: 'rimac_cost',
      type: 'number',
      valid: 'number',
      defaultValue: 0,
      required: true,
    },
    {
      label: 'Costo mercado',
      key: 'market_cost',
      type: 'number',
      defaultValue: 0,
      valid: 'number',
      required: true,
    },
    {
      label: 'Beneficio principal',
      key: 'core_benefit',
      required: false,
      valid: 'text',
      type: 'text',
    },
  ],
  corredores: [
    {
      label: 'Nombre',
      key: 'name',
      required: true,
      valid: 'text',
      type: 'text',
    },
    {
      label: 'Código',
      key: 'code',
      required: true,
      valid: 'number',
      type: 'number',
    },
    {
      label: 'Sub gerente comercial',
      key: 'sub_gerente_comercial_id',
      type: 'list',
      required: true,
      options: [],
    },
    {
      label: 'Ejecutivo comercial',
      key: 'ejecutivo_comercial_id',
      type: 'list',
      required: true,
      options: [],
    },
    {
      label: 'Ingeniero responsable',
      key: 'ingeniero_responsable_id',
      type: 'list',
      required: true,
      options: [],
    },
  ],
  versions: [
    {
      title: 'Escribe el título de la versión',
      label: 'Título',
      key: 'title',
      required: true,
      valid: 'text',
      type: 'text',
      maxLength: 80,
      style: {
        maxWidth: 412,
      },
    },
    {
      title: 'Adjunta una imágen',
      type: 'image',
      key: 'image',
      required: false,
    },
    {
      title: 'Selecciona el tipo de versión',
      label: 'Tipo',
      key: 'type',
      type: 'list',
      required: true,
      options: [
        {
          id: 1,
          name: 'Nuevo',
          value: 1,
        },
        {
          id: 2,
          name: 'Mejorado',
          value: 2,
        },
      ],
    },
    {
      label: 'Versión',
      key: 'version',
      required: true,
      valid: 'text',
      type: 'text',
    },
    {
      title: 'Roles',
      key: 'rols',
      type: 'check',
      required: true,
      options: [],
    },
  ],
  'articles-list': [
    {
      title: 'Selecciona la categoría',
      key: 'category',
      options: [
        { label: 'Monitoreo', value: 1 },
        { label: 'Prevención', value: 2 },
        { label: 'Riesgos', value: 3 },
      ],
      type: 'radio',
      defaultValue: 1,
    },
    {
      title: 'Título Negro',
      label: 'Título',
      key: 'title',
      required: true,
      valid: 'text',
      type: 'text',
    },
    {
      title: 'Título Rojo',
      label: 'Título Rojo',
      key: 'title_red',
      required: true,
      valid: 'text',
      type: 'text',
    },
    {
      title: 'Subtítulo',
      label: 'Subtítulo',
      key: 'subtitle',
      required: true,
      valid: 'text',
      type: 'text',
    },
    {
      title: 'Adjunta una imágen',
      type: 'image',
      key: 'image',
      required: false,
    },
    {
      title: 'Título de SEO',
      label: 'Título de SEO',
      key: 'seo_title',
      required: true,
    },
    {
      title: 'Descripción de SEO',
      label: 'Descripción de SEO',
      key: 'seo_description',
      required: true,
    },
    {
      title: 'Descripción de imagen',
      label: 'Descripción de imagen',
      key: 'image_subtitle',
      rows: 2,
      hasCounter: false,
      required: false,
      valid: 'text',
      type: 'textarea',
    },
    {
      title: 'Resumen',
      label: 'Resumen',
      key: 'abstract_email',
      required: true,
      rows: 4,
      valid: 'text',
      type: 'textarea',
    },
    {
      title: 'Contenido',
      key: 'message',
      required: true,
      valid: 'text',
      type: 'editor',
    },
  ],
  'level-company': [
    {
      title: 'Escribe el nombre del nivel de empresa',
      label: 'Nombre del nivel de empresa',
      key: 'name',
      required: true,
      valid: 'text',
      type: 'text',
      style: { maxWidth: 354 },
    },
    {
      key: 'custom-company',
      type: 'custom-company',
    },
  ],
  parameters: [
    {
      title: 'Key',
      label: 'Título',
      key: 'key',
      required: true,
      valid: 'text',
      type: 'text',
      style: {
        maxWidth: '50%',
      },
    },
    {
      title: 'Valor',
      key: 'value',
      type: 'component-code',
    },
    {
      title: 'Descripción',
      label: 'Descripción',
      key: 'description',
      valid: 'text',
      required: true,
      type: 'textarea',
      rows: 4,
      maxLength: 100,
    },
    {
      title: 'Etiquetas',
      label: 'Etiquetas',
      key: 'tooltips',
      valid: 'text',
      type: 'textarea',
      rows: 4,
      maxLength: 500,
    },
    {
      title: '¿Es editable?',
      key: 'type',
      required: true,
      options: [
        { label: 'Si', value: 1 },
        { label: 'No', value: 0 },
      ],
      defaultValue: 1,
      type: 'radio',
    },
  ],
  banner: [
    {
      title: 'Agregar campos de texto',
      label: 'Texto encima del título',
      key: 'text',
      required: false,
      valid: 'text',
      type: 'text',
    },
    {
      label: 'Título en Negro',
      key: 'header1',
      required: true,
      valid: 'text',
      type: 'text',
    },
    {
      label: 'Título en Rojo',
      key: 'header2',
      required: true,
      valid: 'text',
      type: 'text',
    },
    {
      title: 'Descripción',
      label: 'Descripción',
      key: 'description',
      valid: 'text',
      required: true,
      type: 'textarea',
      rows: 4,
      maxLength: 160,
    },
    {
      title: 'Agregar imágen',
      type: 'image',
      key: 'image',
      required: true,
    },
    {
      title: 'Selecciona el orden',
      label: 'Orden',
      key: 'order',
      type: 'list',
      required: true,
      options: [
        {
          id: 1,
          name: 'Primer Slide',
          value: 1,
        },
        {
          id: 2,
          name: 'Segundo Slide',
          value: 2,
        },
        {
          id: 3,
          name: 'Tercer Slide',
          value: 3,
        },
        {
          id: 4,
          name: 'Cuarto Slide',
          value: 4,
        },
        {
          id: 5,
          name: 'Quinto Slide',
          value: 5,
        },
      ],
    },
    {
      title: 'URL',
      label: 'Url',
      key: 'link',
      valid: 'text',
      required: true,
      type: 'text',
      rows: 4,
    },
    {
      title: '¿Es un URL que se debe abrir en una ventana externa?',
      key: 'target',
      required: true,
      options: [
        { label: 'Si', value: 1 },
        { label: 'No', value: 0 },
      ],
      defaultValue: 0,
      type: 'radio',
    },
    {
      title: '¿Activar o desactivar?',
      key: 'enabled',
      required: true,
      options: [
        { label: 'Activar', value: 1 },
        { label: 'Desactivar', value: 0 },
      ],
      defaultValue: 1,
      type: 'radio',
    },
  ],
};
