import { useState, useCallback, useMemo, useEffect, memo } from 'react';
import { useSelector } from 'react-redux';

import useQueryParams from '../../../../../hooks/useQueryParams';

import { FORM_FIELDS } from './constants';
import FieldsInputs from './FieldsInputs';
import { RideButton } from '@rimac-seguros/ride-system-components';

const isValidField = (type, value) => {
  switch (type) {
    case 'number':
    case 'text':
      return value && String(value).length;
    default:
      return true;
  }
};

const FormConfiguration = ({
  onAction = () => {},
  action,
  isMobile,
  dinamycOptions = {},
  hasImage = true,
  hasFullImage,
  ...formProps
}) => {
  const [loadingForm, setLoading] = useState(false);
  const { configkey } = useQueryParams();
  const [fields, setFields] = useState(FORM_FIELDS[configkey]);
  const [formData, setFormData] = useState(() => {
    return !fields
      ? {}
      : fields.reduce((acc, curr) => {
          if (formProps['keyValue']) formProps['key'] = formProps['keyValue'];

          acc[curr.key] = formProps[curr.key] || curr.defaultValue;
          acc['id'] = formProps['id'];

          return acc;
        }, {});
  });

  const user = useSelector((state) => state.auth.user);
  const showIdsCompanies = ['admin_rimac', 'gestor_de_cuenta'].includes(
    user?.rol?.slug,
  );

  const disabledNext = useMemo(() => {
    return fields.reduce((result, elem) => {
      if (elem.required)
        return result || !isValidField(elem.valid, formData[elem.key]);

      return result;
    }, false);
  }, [fields, formData]);

  const _handleChangeField = useCallback(({ target: { value, name } }) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const _handleAction = useCallback(() => {
    setLoading(true);
    onAction(formData, () => setLoading(false));
  }, [formData, onAction]);

  useEffect(() => {
    if (configkey === 'corredores')
      setFields((prev) => {
        return prev.map((field) => {
          if (field.key === 'sub_gerente_comercial_id')
            return {
              ...field,
              options: dinamycOptions.sub_gerente_comercial || [],
            };

          if (field.key === 'ejecutivo_comercial_id')
            return {
              ...field,
              options: dinamycOptions.ejecutivo_comercial || [],
            };

          if (field.key === 'ingeniero_responsable_id')
            return {
              ...field,
              options: dinamycOptions.ingeniero || [],
            };

          return field;
        });
      });

    if (configkey === 'versions')
      setFields((prev) => {
        return prev.map((field) => {
          if (field.key === 'rols')
            return {
              ...field,
              options: dinamycOptions || [],
            };

          return field;
        });
      });
  }, [configkey]);

  return (
    <div className={'flex flex-col full-width mb-4 pt-3'}>
      <div
        className={`flex relative ${isMobile ? 'flex-col' : ''}`}
        style={isMobile ? { marginBottom: 120 } : {}}
      >
        <div className='flex-1'>
          {fields.map(({ key, type = 'text', options, ...props }, index) => {
            return (
              <FieldsInputs
                loading={loadingForm}
                options={options}
                key={key}
                name={key}
                className={
                  index > 1 || hasFullImage ? 'full-width' : 'middle-width'
                }
                type={type}
                onChange={_handleChangeField}
                value={formData[key]}
                invalidField={!isValidField(type, formData[key])}
                style={
                  configkey === 'services' &&
                  key === 'idsCompanies' &&
                  !showIdsCompanies
                    ? { display: 'none' }
                    : { ...props.style }
                }
                formProps={formProps}
                {...props}
              />
            );
          })}
        </div>
      </div>
      {isMobile ? (
        <div
          className={'fixed-bottom p-3 full-width'}
          style={{ background: '#EDEFFC' }}
        >
          <span
            className='full-width absolute'
            style={{
              height: 12,
              top: -12,
              left: 0,
              background:
                'linear-gradient(to bottom, rgba(255,0,0,0), #EDEFFC)',
            }}
          ></span>
          <RideButton
            disabled={disabledNext}
            onClick={() => {
              onAction(formData);
            }}
            loading={loadingForm}
            text={action === 'create' ? 'Crear' : 'Guardar cambios'}
            variant='fill'
            size='large'
            className='w-full'
          />
        </div>
      ) : (
        <RideButton
          disabled={disabledNext}
          style={{
            alignSelf: 'flex-end',
            maxWidth: 200,
          }}
          onClick={_handleAction}
          loading={loadingForm}
          text={action === 'create' ? 'Crear' : 'Guardar cambios'}
          variant='fill'
          size='medium'
        />
      )}
    </div>
  );
};

export default memo(FormConfiguration);
