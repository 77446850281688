export const columns = [
  {
    title: 'Usuario',
    dataIndex: 'name',
    key: 'name',
    width: 140,
  },
  {
    title: 'Correo',
    dataIndex: 'email',
    key: 'email',
    width: 140,
  },
  {
    title: 'Activo / Inactivo',
    dataIndex: 'status',
    key: 'status',
    width: 140,
  },
  {
    title: 'Ubicación Favorita',
    dataIndex: 'isFavorite',
    key: 'isFavorite',
    width: 180,
  },
  {
    title: 'Departamento de u. favorita',
    dataIndex: 'department',
    key: 'department',
    width: 220,
  },
  {
    title: 'Provincia de u. favorita',
    dataIndex: 'province',
    key: 'province',
    width: 200,
  },
  {
    title: 'Tipo de envío',
    dataIndex: 'notificationType',
    key: 'notificationType',
    width: 120,
  },
  {
    title: 'Tipo',
    dataIndex: 'type',
    key: 'type',
    width: 160,
  },
  {
    title: 'Fecha de envio',
    dataIndex: 'sent_date',
    key: 'sent_date',
    width: 150,
  },
];

export const filtersCommon = [
  {
    title: 'Estado',
    dataIndex: 'isActive',
    key: 'isActive',
    type: 'select',
    placeholder: 'Por estado del usuario',
    value: 'id',
    label: 'name',
  },
  {
    title: 'Tipo de envio',
    dataIndex: 'typeSend',
    key: 'typeSend',
    type: 'select',
    placeholder: 'Por tipo de envio',
    value: 'id',
    label: 'name',
  },
  {
    title: 'Departamento',
    dataIndex: 'departments',
    key: 'departments',
    type: 'select',
    placeholder: 'Por departamento',
    value: 'id',
    label: 'name',
  },
  {
    title: 'Provincia',
    dataIndex: 'provinces',
    key: 'provinces',
    type: 'select',
    placeholder: 'Por provincia',
    value: 'id',
    label: 'name',
  },
  {
    title: 'Fecha de envío',
    dataIndex: 'date',
    key: 'date',
    type: 'rangePicker',
    placeholder: ['Fecha de inicio', 'Fecha de fin'],
    label: 'name',
  },
];
