import React, { memo, useEffect, useState } from 'react';
import { getInfoCompany } from '../../services/requestServices';
import { IconInfoService, IconCopy } from '../Icons/iconText';
import { RideButton } from '@rimac-seguros/ride-system-components';

const CardEmptyContact = ({
  title = 'Por ahora no tienes servicios disponibles para realizar una solicitud',
  description = 'Comunícate con tu gestor',
  companyId,
  isAdminRimac,
}) => {
  const [gestor, setGestor] = useState(null);

  useEffect(() => {
    if (!isAdminRimac && companyId) {
      getInfoCompany(companyId).then((res) => setGestor(res?.gestor || null));
    }
  }, [companyId, isAdminRimac]);

  return (
    <article className='card-info'>
      <div className='card-message'>
        <IconInfoService />
        <div className='text'>
          <h3>{title}</h3>
          <p>
            {gestor
              ? `${description}: ${gestor.name}`
              : 'Comunícate con el centro de monitoreo'}
          </p>
        </div>
      </div>

      <div className='card-action'>
        <div className='copy'>
          <span>
            {gestor ? gestor.username : 'centrodemonitoreo@rimac.com.pe'}
          </span>
          <RideButton
            variant='text-primary'
            onClick={() =>
              navigator.clipboard.writeText(
                gestor ? gestor.username : 'centrodemonitoreo@rimac.com.pe',
              )
            }
            text='Copiar'
          />
        </div>
      </div>
    </article>
  );
};

export default memo(CardEmptyContact);
