import { useCallback, useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { Button, notification, Skeleton } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';

import PopoverActions from '../../../../common/Table/PopoverActions';
import Table from '../../../../common/Table';
import ModalConfirm from '../../../../common/ModalConfirm';

import useDebounce from '../../../../hooks/useDebounce';
import {
  createLevelCompanyPag,
  listLevelCompanyPag,
  removeLevelCompanyPag,
  updateLevelCompanyPag,
} from '../../../../services/levelCompany';

import { CardSkeleton } from '../../ui/PreventionService/Reports/utils';

import MainTitle from '../common/MainTitle';
import CardItemMobile from '../common/CardItemMobile';
import FilterSection from '../../ui/PreventionService/components/FilterSection';
import DeleteModalImage from '../../../../images/delete_modal_image.svg';
import Empty from '../../../../common/Empty';

const LevelCompany = ({
  load,
  create,
  update,
  list,
  isLoading,
  renderItem,
  pagination,
  onOpenForm,
  isMobileScreen,
  onModalSuccess,
  isBeforeTable,
  queryAction,
  onSetAction,
}) => {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [textSearch, setTextSearch] = useState('');
  const debouncedQuerySearch = useDebounce(textSearch);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const handleFilter = (e) => setTextSearch(e.target.value);

  const handleTableChange = (pag) =>
    load({
      page: pag.current,
      limit: pag.pageSize,
      query: textSearch,
    });

  const handleTableChangeMobile = (current) => {
    if (
      current === 0 ||
      Math.ceil(pagination.total / pagination.pageSize) + 1 === current
    ) {
      return;
    }

    load({
      page: current,
      limit: pagination.pageSize,
      query: textSearch,
    });
  };

  const _handleDelete = (item) => () => setItemToDelete(item);

  const _handleAction = useCallback(
    (action, item) => async () => {
      onOpenForm({
        action,
        ...item,
        keyConfig: 'level-company',
        hasImage: false,
        titleSection:
          action === 'create'
            ? 'Crear nivel de empresa'
            : 'Editar nivel de empresa',
        onAction: async (data, onFinish = () => {}) => {
          if (data?.type === 2) {
            if (data?.totalCost > data?.budget) {
              notification.destroy();

              notification.warning({
                description: `Servicios: un máximo de $${data?.budget}.`,
                message: 'Crear nivel de empresa',
              });
              onFinish();

              return;
            }
          }

          if (
            data?.levelCompanyServices &&
            data?.levelCompanyServices.length > 0
          ) {
            const serviceInvalid = data.levelCompanyServices.find(
              (service) => service.serviceId === null,
            );
            if (
              serviceInvalid &&
              Object.keys(serviceInvalid).length === 3 &&
              serviceInvalid?.serviceId === null
            ) {
              notification.warning({
                description: 'Complete todos los servicios añadidos.',
                message: 'Crear nivel de empresa',
              });
              onFinish();

              return;
            }
          }

          action === 'create'
            ? await create(data, isBeforeTable, onModalSuccess)
            : await update(
                data,
                {
                  page: pagination.current,
                  limit: pagination.pageSize,
                  query: textSearch,
                },
                isBeforeTable,
                onModalSuccess,
              );
          onFinish();
          onOpenForm(null);
          action === 'create' && onSetAction(false);
        },
      });
    },
    [onOpenForm, create, update],
  );

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      width: '190px',
      render: (text) => (
        <span className='text-uppercase'>
          {text?.length > 50 ? `${(text || '').substring(0, 50)}...` : text}
        </span>
      ),
    },
    {
      title: 'Cantidad de servicio',
      dataIndex: 'count',
      key: 'count',
      width: '180px',
      render: (__, item) =>
        `${
          item?.levelCompanyServices?.length
            ? item?.levelCompanyServices?.length
            : 0
        } ${
          item?.levelCompanyServices?.length === 1 ? 'Servicio' : 'Servicios'
        }`,
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'typeService',
      render: (type, item) => {
        return type === 2
          ? 'Personalizado'
          : `Estandar (${item.minor} - ${item.major})`;
      },
    },
    {
      dataIndex: 'actions',
      title: 'Acciones',
      fixed: 'right',
      key: 'actions',
      width: 80,
      render: (__, item) =>
        rows.length > 0 ? (
          <PopoverActions
            actions={
              <>
                <Button onClick={_handleAction('edit', item)}>
                  <EditOutlined /> <span>Editar</span>
                </Button>

                <Button onClick={_handleDelete(item)}>
                  <DeleteOutlined /> <span>Eliminar</span>
                </Button>
              </>
            }
          />
        ) : null,
    },
  ];

  const handleRemove = async () => {
    setLoadingDelete(true);

    if (!itemToDelete) {
      return notification.warning({
        description: 'No ha seleccionado ningun elemento para eliminar.',
        message: 'Eliminar nivel de empresa',
      });
    }

    const deleted = await removeLevelCompanyPag(itemToDelete.id, dispatch, {
      page: pagination.current,
      limit: pagination.pageSize,
      query: textSearch,
    });

    if (isBeforeTable && deleted) {
      setItemToDelete(null);
      setLoadingDelete(false);

      return onModalSuccess({ message: '¡Listo! Se eliminó correctamente' });
    }

    if (deleted)
      notification.success({
        description: 'Se eliminó el nivel de empresa correctamente.',
        message: 'Nivel de empresa eliminado! ',
      });
    else
      notification.warning({
        description: 'Ha ocurrido un error.',
        message: 'Eliminar nivel de empresa',
      });

    setItemToDelete(null);
    setLoadingDelete(false);
  };

  useEffect(() => {
    load({
      query: debouncedQuerySearch.length >= 2 ? debouncedQuerySearch : '',
      page: 1,
      limit: pagination.pageSize,
    });
  }, [debouncedQuerySearch]);

  useEffect(() => {
    setRows(list);
  }, [list]);

  useEffect(() => {
    if (queryAction) _handleAction('create', renderItem)();
  }, [queryAction]);

  return (
    <>
      <MainTitle
        buttonAction={_handleAction('create', renderItem)}
        renderItem={renderItem}
        isMobileScreen={isMobileScreen}
      />

      <FilterSection
        complete={true}
        text={`${
          isMobileScreen
            ? 'Filtra tu búsqueda'
            : 'Filtrar lista de nivel de empresa'
        }`}
        handleFilter={handleFilter}
      />

      {!isMobileScreen ? (
        !isLoading && list?.length === 0 ? (
          <Empty title='No se encontró ningun nivel de empresas' />
        ) : (
          <section className='show-data'>
            {isLoading ? (
              <Skeleton active title={false} paragraph={{ rows: 1 }} />
            ) : (
              <p className='info-total-data'>
                Se encontraron <span>{pagination?.total}</span>{' '}
                {pagination?.total === 1
                  ? 'nivel de empresa'
                  : 'niveles de empresas'}
              </p>
            )}

            <Table
              dataSource={rows}
              columns={columns}
              rowKey={(record) => record.id}
              loading={isLoading}
              locale={{ emptyText: 'No se encontraron niveles de empresas.' }}
              pagination={pagination}
              onChangeRows={setRows}
              onChange={handleTableChange}
            />
          </section>
        )
      ) : (
        <section className='content-mobile action-bottom'>
          {isLoading ? (
            <Skeleton active title={false} paragraph={{ rows: 1 }} />
          ) : (
            <p className='info-total-data'>
              Se encontraron <span>{pagination?.total}</span>{' '}
              {pagination?.total === 1
                ? 'nivel de empresa'
                : 'niveles de empresas'}
            </p>
          )}

          {isLoading ? (
            [1, 2, 3, 4].map((el) => <CardSkeleton key={el} />)
          ) : rows?.length === 0 ? (
            <Empty title='No se encontró ningun nivel de empresas' />
          ) : (
            rows?.map((data) => (
              <CardItemMobile
                key={data.id}
                data={data}
                columns={columns.filter((el) => el.dataIndex !== 'actions')}
                onEdit={_handleAction('edit', data)}
                onDelete={setItemToDelete}
                section='levelCompany'
              />
            ))
          )}

          <div className='d_content__list__pag'>
            <div
              className={'d_content__list__pag__button'}
              onClick={() => handleTableChangeMobile(pagination?.current - 1)}
            >
              <LeftOutlined
                style={{
                  color: pagination?.current === 1 ? '#A9AFD9' : '#4F4FFF',
                }}
              />
            </div>

            <div className='d_content__list__pag__number color-red-1'>
              {pagination?.current}
            </div>
            <div className='d_content__list__pag__sep'>/</div>
            <div className='d_content__list__pag__number color-gray-3'>
              {Math.ceil(pagination?.total / pagination?.pageSize)}
            </div>

            <div
              className={'d_content__list__pag__button'}
              onClick={() => handleTableChangeMobile(pagination?.current + 1)}
            >
              <RightOutlined
                style={{
                  color:
                    pagination.current ===
                    Math.ceil(pagination?.total / pagination?.pageSize)
                      ? '#A9AFD9'
                      : '#4F4FFF',
                }}
              />
            </div>
          </div>
        </section>
      )}

      <ModalConfirm
        visible={itemToDelete}
        onClose={() => setItemToDelete(null)}
        title='¿Seguro que desea eliminar el nivel de empresa de la lista?'
        image={DeleteModalImage}
        actionsContent={
          <div className='actions-delete-modal mt-2'>
            <Button
              className='button secondary-button mr-2'
              onClick={() => setItemToDelete(null)}
            >
              Cancelar
            </Button>

            <Button
              className='button ml-1'
              type='primary'
              disabled={loadingDelete}
              loading={loadingDelete}
              onClick={handleRemove}
            >
              Eliminar
            </Button>
          </div>
        }
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  load: (pagination) => listLevelCompanyPag(dispatch, pagination),
  create: async (data, isBeforeTable, onModalSuccess) => {
    const created = await createLevelCompanyPag(data, dispatch);

    if (isBeforeTable && created) {
      onModalSuccess({
        message: '¡Listo! Se creó nivel de empresa correctamente',
      });

      return;
    }

    created
      ? notification.success({
          description: 'Se creó nivel de empresa correctamente.',
          message: '¡Nivel de empresa creado!',
        })
      : notification.warning({
          description: 'Ha ocurrido un error.',
          message: 'Crear nivel de empresa',
        });
  },
  update: async (data, pagination, isBeforeTable, onModalSuccess) => {
    const updated = await updateLevelCompanyPag(data, dispatch, pagination);

    if (isBeforeTable && updated) {
      onModalSuccess({
        message: '¡Cambios guardados! Se guardaron los cambios correctamente',
      });

      return;
    }

    updated
      ? notification.success({
          description: 'Se guardaron los cambios correctamente.',
          message: '¡Cambios guardados!',
        })
      : notification.warning({
          description: 'Ha ocurrido un error.',
          message: 'Actualizar nivel de empresa',
        });
  },
});

const mapStateToProps = (state) => ({
  list: state.levelCompany.listPag || [],
  pagination: state.levelCompany.pagination || [],
  isLoading: state.levelCompany.isLoadingList,
  isLoadingCreate: state.levelCompany.isLoadingCreate,
});

export default connect(mapStateToProps, mapDispatchToProps)(LevelCompany);
