import React, { memo, useEffect, useState, useCallback, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Card, Col, Divider, Modal, Row, Skeleton } from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import {
  RideButton,
  RideGlDeleteOutline,
} from '@rimac-seguros/ride-system-components';

import RightDrawer from '../../../../../common/RightDrawer';
import TableCommon from '../../../../../common/Table';
import Title from '../../../../../common/Typograph/Title';
import { useScreen } from '../../../../../lib/screen';
import { getDataProvincesDashboard } from '../../../../../services/dashboard';
import { IconFilter } from '../../../../../common/Icons';
import PaginationMobile from '../../../../../common/PaginationMobile';
import { SelectWithLabel } from '../../../../../common/InputWithLabel';
import { getEventType } from '../../../../../services/eventType';
import { useSelector } from 'react-redux';
import { LOADED_EVENT_TYPE } from '../../../../../redux/actions/actionsType';
import { useRef } from 'react';
import { secureRandom } from '../../../../../lib/utils';

const columns = [
  { title: 'Provincia', dataIndex: 'province', key: 'province' },
  { title: 'Departamento', dataIndex: 'department', key: 'department' },
  {
    title: 'Cantidad total de eventos',
    dataIndex: 'totalEvents',
    key: 'totalEvents',
    align: 'center',
  },
  {
    title: 'Acciones',
    dataIndex: 'onSelectProvince',
    key: 'onSelectProvince',
    width: 80,
    render: (onSelectProvince, record) => (
      <RideButton
        variant='text-primary'
        onClick={onSelectProvince}
        text={record.isEventTypes ? 'Ver más eventos' : 'Ver eventos'}
      />
    ),
  },
];

const filtersList = [
  {
    label: 'Departamento',
    key: 'department',
  },
  {
    label: 'Provincia',
    key: 'province',
  },
];

const compareValues = (key, toCompare, value) => {
  switch (key) {
    case 'text':
      return toCompare === value;
    case 'list':
      return toCompare.includes(value);
    default:
      return true;
  }
};

const TableProvinces = ({ dates }) => {
  const { isMobileScreen } = useScreen();
  const dispatch = useDispatch();
  const eventTypes = useSelector(
    ({ eventType }) =>
      eventType.eventTypes
        ?.map((el) => el.name)
        .sort((a, b) => a.localeCompare(b)) ?? [],
  );
  const timeoutFilters = useRef();
  const [loadingProvincesData, setLoadingProvincesData] = useState(true);
  const [provinciesData, setDataProvinces] = useState([]);
  const [provinciesDataFiltered, setDataProvincesFiltered] = useState([]);
  const [filtersOptions, setFiltersOptions] = useState({});
  const [eventsDetails, setEventsDetails] = useState(null);
  const [pagination, setPagination] = useState({ pageSize: 5, current: 1 });
  const [mobileFilters, setMobileFilters] = useState(false);
  const [filtersValue, setFiltersValue] = useState({});

  useEffect(() => {
    const eventTypesStorage = JSON.parse(
      localStorage.getItem('eventTypes') ?? '[]',
    );

    if (eventTypesStorage.length)
      dispatch({
        type: LOADED_EVENT_TYPE,
        payload: eventTypesStorage,
      });
    else getEventType(dispatch);

    return () => {
      clearTimeout(timeoutFilters.current);
    };
  }, []);

  const getProvincesData = useCallback((dates) => {
    getDataProvincesDashboard(dates)
      .then((data) => {
        setPagination((prev) => ({
          ...prev,
          total: Object.values(data).length,
        }));

        const provinces = Object.values(data).map((province) => ({
          key: province.id,
          id: province.id,
          province: province.name,
          department: province?.department?.name,
          eventTypes: Object.keys(province.eventTypes).reduce(
            (acc, key) =>
              province.eventTypes[key] > 0 ? acc.concat(key) : acc,
            [],
          ),
          isEventTypes: false,
          totalEvents: Object.values(province.eventTypes).reduce(
            (a, b) => a + b,
            0,
          ),
          onSelectProvince: () => {
            setEventsDetails(
              Object.keys(province.eventTypes).reduce(
                (acc, key) =>
                  province.eventTypes[key] > 0
                    ? acc.concat({
                        name: key,
                        value: province.eventTypes[key],
                      })
                    : acc,
                [],
              ),
            );
          },
        }));

        setFiltersOptions({
          department: [
            ...new Set(provinces.map((el) => el.department).filter(Boolean)),
          ].sort((a, b) => a.localeCompare(b)),
          province: provinces
            .map((el) => el.province)
            .sort((a, b) => a.localeCompare(b)),
        });
        setDataProvinces(provinces);
        setDataProvincesFiltered(provinces);
      })
      .finally(() => setLoadingProvincesData(false));
  }, []);

  useEffect(() => {
    getProvincesData(dates);
  }, [dates]);

  const _handleChangeProvinces = useCallback(
    (key, value, type = 'text') => {
      setLoadingProvincesData(true);
      timeoutFilters.current = setTimeout(() => {
        setFiltersValue((prev) => {
          const newFilters = { ...prev, [key]: value };
          setDataProvincesFiltered(() => {
            let filtered = provinciesData.filter((el) =>
              Object.keys(newFilters).reduce(
                (acc, curr) =>
                  acc && compareValues(type, el[curr], newFilters[curr]),
                true,
              ),
            );
            if (newFilters?.eventTypes)
              filtered = filtered.map((el) => ({ ...el, isEventTypes: true }));
            setPagination((prev) => ({
              ...prev,
              total: filtered.length,
            }));

            return filtered;
          });

          return newFilters;
        });

        setLoadingProvincesData(false);
      }, 600);
    },
    [provinciesData],
  );

  const _handleClearFilters = useCallback(() => {
    setFiltersValue({});
    setDataProvincesFiltered(provinciesData);
    setPagination((prev) => ({
      ...prev,
      current: 1,
      total: provinciesData.length,
    }));
  }, [provinciesData]);

  return (
    <div className='mt-3 mb-3'>
      {isMobileScreen ? (
        <Title className='mb-3' type='bold-28'>
          Tabla de eventos naturales
        </Title>
      ) : (
        <Title className='mb-3' type='bold-20'>
          Filtrar búsqueda por
        </Title>
      )}
      {isMobileScreen ? (
        loadingProvincesData ? (
          [1, 2, 3].map((key) => (
            <Card className='mt-2' key={key}>
              <Skeleton />
            </Card>
          ))
        ) : (
          <div>
            <div className='flex justify-between items-center'>
              Se encontraron {pagination.total} eventos
              <Button
                type='link'
                onClick={() => {
                  setMobileFilters((prev) => !prev);
                }}
              >
                <IconFilter />
              </Button>
            </div>
            {provinciesDataFiltered
              .slice(
                (pagination.current - 1) * pagination.pageSize,
                pagination.current * pagination.pageSize,
              )
              .map((province) => (
                <Card key={province.id} className='d_content__card'>
                  <div className='d_content__card__body pt-2'>
                    <Button
                      type='link'
                      onClick={province.onSelectProvince}
                      style={{
                        position: 'absolute',
                        right: 24,
                        top: 20,
                      }}
                    >
                      Ver más eventos
                    </Button>
                    {columns
                      .filter((el) => el.dataIndex !== 'onSelectProvince')
                      .map(({ dataIndex, title }) => (
                        <div
                          key={`${dataIndex}-${province[dataIndex]}`}
                          className='card-detail__item'
                        >
                          <h4>{title}</h4>
                          <p>{province[dataIndex] || '-'}</p>
                        </div>
                      ))}
                  </div>
                </Card>
              ))}
            <PaginationMobile
              onChange={(page) => {
                setPagination((prev) => ({ ...prev, current: page }));
              }}
              style={{ marginBottom: 16 }}
              {...pagination}
            />
          </div>
        )
      ) : (
        <>
          <div className='flex mb-4 gap-2'>
            {filtersList.map(({ key, label }) => (
              <SelectWithLabel
                key={key}
                label={label}
                options={filtersOptions[key] ?? []}
                onChange={(select) => {
                  _handleChangeProvinces(key, select);
                }}
                value={filtersValue[key]}
              />
            ))}
            <SelectWithLabel
              key={'eventTypes'}
              label={'Eventos naturales'}
              options={eventTypes}
              onChange={(select) => {
                _handleChangeProvinces('eventTypes', select, 'list');
              }}
              value={filtersValue['eventTypes']}
            />
            <RideButton
              onClick={_handleClearFilters}
              text='Borrar filtros'
              variant='text-primary'
              glyph={<RideGlDeleteOutline />}
              glyphPosition='left'
            />
          </div>
          <TableCommon
            key={`table-${secureRandom()}`}
            loading={loadingProvincesData}
            dataSource={provinciesDataFiltered}
            pagination={{
              ...pagination,
              onChange: (page) => {
                setPagination((prev) => ({ ...prev, current: page }));
              },
            }}
            columns={columns}
          />
        </>
      )}
      <Modal
        width={554}
        centered
        footer={<></>}
        closable
        open={Boolean(eventsDetails)}
        onCancel={() => setEventsDetails(null)}
      >
        <div
          style={{ maxHeight: isMobileScreen ? 360 : 460, overflow: 'auto' }}
        >
          <div className='flex flex-col items-center mb-2'>
            <Title type={'bold-28'}>Más eventos naturales</Title>
            <Divider />
          </div>
          <Row gutter={[32, 16]} className='pl-3'>
            <Col span={14}>EVENTOS</Col>
            <Col span={10}>CANTIDAD</Col>
          </Row>
          <Divider />
          {eventsDetails
            ? eventsDetails.map(({ name, value }) => (
                <Fragment key={`${name}-${secureRandom()}`}>
                  <Row gutter={[32, 16]} className='pl-3'>
                    <Col span={14}>
                      <span>{name}</span>
                    </Col>
                    <Col span={10}>{value}</Col>
                  </Row>
                  <Divider />
                </Fragment>
              ))
            : null}
        </div>
      </Modal>
      <RightDrawer
        isMobile={isMobileScreen}
        visible={mobileFilters}
        title={'Filtra tu búsqueda'}
        hiddenDivider
        onCloseDrawer={() => {
          setMobileFilters(false);
        }}
        content={
          <div className='flex flex-col'>
            {filtersList.map(({ key, label }) => (
              <SelectWithLabel
                key={key}
                className='mt-3'
                label={label}
                options={filtersOptions[key] ?? []}
                onChange={(select) => {
                  _handleChangeProvinces(key, select);
                }}
                value={filtersValue[key]}
              />
            ))}
            <SelectWithLabel
              key={'eventTypes'}
              className='mt-3'
              label={'Eventos naturales'}
              options={eventTypes}
              onChange={(select) => {
                _handleChangeProvinces('eventTypes', select, 'list');
              }}
              value={filtersValue['eventTypes']}
            />
            <div
              style={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                left: 0,
                padding: '20px 32px',
              }}
            >
              <div className='full-width flex justify-between'>
                <Button type='link' size='large' onClick={_handleClearFilters}>
                  <DeleteFilled />
                  Limpiar
                </Button>
                <Button
                  type='primary'
                  disabled={Object.keys(filtersValue).length === 0}
                  style={{ width: 168 }}
                  size='large'
                  onClick={() => {
                    setMobileFilters(false);
                  }}
                >
                  Buscar
                </Button>
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};

export default memo(TableProvinces);
