import { get, post, put } from '../lib/ajax';
import {
  CHANGE_COMPANY_QUERY,
  LOADED_COMPANIES,
  LOADING_CREATE_USER_COMPANY,
  OPEN_FORM_COMPANY,
  LOADING_RPT_POLIZAS,
  LOADING_RPT_POLIZAS_FAILED,
  LOADED_RPT_POLIZAS,
  LOADING_RPT_DEALERS_GENERAL,
  LOADING_RPT_DEALERS_GENERAL_FAILED,
  LOADED_RPT_DEALERS_GENERAL,
  LOADED_POLIZAS_COMPANY_TRACKING,
  LOAD_COMPANIES_LOADING,
  LOADING_RPT_PROVIDERS,
  LOADING_RPT_PROVIDERS_FAILED,
  LOADED_RPT_PROVIDERS,
} from '../redux/actions/actionsType';

export const getAllCompanies = async (data = {}) => {
  const r = await post('/company/listAll', data);
  if (r.data.success) {
    return r.data.companyList;
  }

  return undefined;
};

export const getCompanies = async (dispatch, data = {}) => {
  dispatch({ type: LOAD_COMPANIES_LOADING, payload: true });
  dispatch({ type: CHANGE_COMPANY_QUERY, payload: data.query });
  const r = await post('/company/list', data);

  if (r.data.success) {
    dispatch({ type: LOADED_COMPANIES, payload: r.data.companyList });

    return r.data?.companyList?.list?.length > 0;
  }

  return false;
};

export const getCompaniesNoDispatch = async (data = {}) => {
  const r = await post('/company/list', data);

  if (r.data.success) {
    return r.data.companyList;
  }

  return false;
};

export const getPolizasOfCompany = async (company, dispatch) => {
  dispatch && dispatch({ type: LOADING_RPT_POLIZAS, payload: true });
  const r = await get(`/cpn_poliza/list?companyId=${company.id}`);

  let result = null;
  if (r.data.success) {
    result = r.data.payload.list;
  }

  dispatch && dispatch({ type: LOADING_RPT_POLIZAS, payload: false });

  return result;
};

export const getExpiredPolizasOfCompany = async (company) => {
  const r = await get(
    `/cpn_poliza/listExpiredPolizes?companyId=${company.id}&expired=true`,
  );

  let result = null;
  if (r.data.success) {
    result = r.data.payload;
  }

  return result;
};

export const createPoliza = async (poliza) => {
  const r = await post('/cpn_poliza/create', poliza);

  let result = null;
  if (r.data.success) {
    result = r.data.payload.list;
  }

  return result;
};

export const removePoliza = async (poliza) => {
  const r = await post(`/cpn_poliza/remove?id=${poliza.id}&companyId=${poliza.companyId}`);
  let result = null;
  if (r.data.success) {
    result = r.data.payload.list;
  }

  return result;
};

export const updatePoliza = async (poliza) => {
  const r = await put('/cpn_poliza/update', poliza);
  if (r.data.success) {
    return r.data.payload.list;
  }

  return false;
};

export const addInitialDiagnostic = (data) =>
  post('/cpn_poliza/addInitialDiagnostic', data);

export const reportCompanyPolizas = async (
  dispatch,
  reportType,
  params = {},
) => {
  dispatch({ type: LOADING_RPT_POLIZAS, payload: true });
  let r = await post(`/cpn_poliza/${reportType}`, params);

  if (r.data && r.data.success) {
    handleSuccessResponse(dispatch, reportType, r);
  } else {
    dispatch({ type: LOADING_RPT_POLIZAS_FAILED });
  }
};

const handleSuccessResponse = async (dispatch, reportType, r) => {
  if (r.data.payload.running) {
    const intervalId = setInterval(async () => {
      r = await get(`/cpn_poliza/${reportType}Status`);
      if (r.data && r.data.success) {
        handlePayload(dispatch, r, intervalId);
      }
    }, 6000);
  } else {
    dispatch({ type: LOADING_RPT_POLIZAS_FAILED });
  }
};

const handlePayload = (dispatch, r, intervalId) => {
  const { payload } = r.data;
  const config = payload ? JSON.parse(payload.value) : {};

  if (config.error) {
    dispatch({ type: LOADING_RPT_POLIZAS_FAILED });
    clearInterval(intervalId);
  } else if (config.fileDownloadPath) {
    dispatch({
      type: LOADED_RPT_POLIZAS,
      payload: config.fileDownloadPath,
    });
    clearInterval(intervalId);
  }
};

export const reportCompanyDealers = async (
  dispatch,
  reportType,
  params = {},
) => {
  dispatch({ type: LOADING_RPT_DEALERS_GENERAL, payload: true });
  let r = await post(`/cpn_poliza/${reportType}`, params);

  if (r.data && r.data.success) {
    handleSuccessResponseReportDealers(dispatch, reportType, r);
  } else {
    dispatch({ type: LOADING_RPT_DEALERS_GENERAL_FAILED });
  }
};

const handleSuccessResponseReportDealers = async (dispatch, reportType, r) => {
  if (r.data.payload.running) {
    const intervalId = setInterval(async () => {
      r = await get(`/cpn_poliza/${reportType}Status`);
      if (r.data && r.data.success) {
        handlePayloadReportDealers(dispatch, r, intervalId);
      }
    }, 6000);
  } else {
    dispatch({ type: LOADING_RPT_DEALERS_GENERAL_FAILED });
  }
};

const handlePayloadReportDealers = (dispatch, r, intervalId) => {
  const { payload } = r.data;
  const config = payload ? JSON.parse(payload.value) : {};

  if (config.error) {
    dispatch({ type: LOADING_RPT_DEALERS_GENERAL_FAILED });
    clearInterval(intervalId);
  } else if (config.fileDownloadPath) {
    dispatch({
      type: LOADED_RPT_DEALERS_GENERAL,
      payload: config.fileDownloadPath,
    });
    clearInterval(intervalId);
  }
};

export const reportProvider = async (dispatch, reportType, params = {}) => {
  dispatch({ type: LOADING_RPT_PROVIDERS, payload: true });
  let r = await post(`/dashboard/${reportType}`, params);

  if (r.data && r.data.success) {
    handleSuccessResponseReportProvider(dispatch, reportType, r);
  } else {
    dispatch({ type: LOADING_RPT_PROVIDERS_FAILED });
  }
};

const handleSuccessResponseReportProvider = async (dispatch, reportType, r) => {
  if (r.data.payload.running) {
    const intervalId = setInterval(async () => {
      r = await get(`/dashboard/${reportType}Status`);
      if (r.data && r.data.success) {
        handlePayloadReportProvider(dispatch, r, intervalId);
      }
    }, 6000);
  } else {
    dispatch({ type: LOADING_RPT_PROVIDERS_FAILED });
  }
};

const handlePayloadReportProvider = (dispatch, r, intervalId) => {
  const { payload } = r.data;
  const config = payload ? JSON.parse(payload.value) : {};

  if (config.error) {
    dispatch({ type: LOADING_RPT_PROVIDERS_FAILED });
    clearInterval(intervalId);
  } else if (config.fileDownloadPath) {
    dispatch({
      type: LOADED_RPT_PROVIDERS,
      payload: config.fileDownloadPath,
    });
    clearInterval(intervalId);
  }
};

export const createCompany = async (company, dispatch, pagination = {}) => {
  dispatch({ type: LOADING_CREATE_USER_COMPANY, payload: true });
  const r = await post('/company/create', company);

  if (r.data.success) {
    dispatch({ type: OPEN_FORM_COMPANY, payload: false });
    dispatch({ type: LOADING_CREATE_USER_COMPANY, payload: false });
    await getCompanies(dispatch, pagination);

    return true;
  }
  dispatch({ type: LOADING_CREATE_USER_COMPANY, payload: false });

  return false;
};

export const createCompanyNew = async (company) => {
  const r = await post('/company', company);

  return r.data;
};

export const changeStatusCompany = async (
  company,
  newState,
  dispatch,
  pagination,
) => {
  const r = await put('/company/UpdateCompanyCommand?uuid=' + company.uuid, {
    state: newState,
  });
  if (r.data.success) {
    await getCompanies(dispatch, pagination);

    return true;
  }

  return false;
};

export const getCompanyByRuc = async (ruc) => {
  const r = await get('/company/GetCompanyByRuc?ruc=' + ruc);

  return r.data;
};

export const getCompanyByUuid = async (uuid) => {
  const r = await get('/company/GetCompanyByUuidQuery?uuid=' + uuid);

  return r.data;
};

export const updateCompany = async (company, dispatch, pagination) => {
  dispatch({ type: LOADING_CREATE_USER_COMPANY, payload: true });
  const r = await put(
    '/company/UpdateCompanyCommand?uuid=' + company.uuid,
    company,
  );
  if (r.data.success) {
    dispatch({ type: OPEN_FORM_COMPANY, payload: false });
    dispatch({ type: LOADING_CREATE_USER_COMPANY, payload: false });
    await getCompanies(dispatch, pagination);

    return true;
  }
  dispatch({ type: LOADING_CREATE_USER_COMPANY, payload: false });

  return false;
};

export const updateCompanyNew = async (uuid, company) => {
  const r = await put('/company?id=' + uuid, company);
  if (r.data.success) {
    return r.data.payload;
  }

  return false;
};

export const getGiroOfCompany = async (ruc) => {
  const r = await get(`/company/defaultGiro?ruc=${ruc}`);
  if (r.data.success) {
    return r.data.payload?.giroNegocioL;
  }

  return false;
};

export const reportCompanyPolizasTracking = async (dispatch, type, ruc) => {
  dispatch({ type: LOADING_RPT_POLIZAS, payload: true });
  dispatch({ type: LOADED_POLIZAS_COMPANY_TRACKING, payload: [] });
  const query = `?rpt_company_polizas_traking=${type}`;
  let r = await get(
    `/cpn_poliza/reportTrackingCompanyPolizas${query}` +
      (ruc ? `&ruc=${ruc}` : ''),
  );
  if (r.data?.success) {
    dispatch({
      type: LOADED_POLIZAS_COMPANY_TRACKING,
      payload: r.data.payload.list,
    });
    dispatch({ type: LOADING_RPT_POLIZAS, payload: false });

    return true;
  } else {
    dispatch({ type: LOADING_RPT_POLIZAS, payload: false });

    return false;
  }
};

export const getCompanyAdminList = async (
  companyId,
  query = '',
  first = '',
) => {
  const r = await get(
    `/company/company_admin_list?companyId=${companyId}&query=${query}&first=${first}`,
  );

  let result = null;
  if (r.data.success) {
    result = r.data.payload;
  }

  return result;
};

export const getCompanyCompliance = async (companyId) => {
  const r = await get(`/company/compliance_polizas?companyId=${companyId}`);

  let result = null;
  if (r.data.success) {
    result = r.data.payload;
  }

  return result;
};

export const getPolizasToValueOffer = async (company) => {
  const r = await get(
    `/cpn_poliza/listPolizasToValueOffer?polizasValueOffer=${company.id}`,
  );

  let result = null;
  if (r.data.success) {
    result = r.data.payload;
  }

  return result;
};

export const updateCompanyAndUsers = async (company, state) => {
  const r = await put(
    `/company/UpdateCompanyCommand?uuid=${company.uuid}&disableUsers=true`,
    { state },
  );

  return r.data.success;
};

export const countUsersByCompany = async (company) => {
  const r = await get(
    `/company/countUsersByCompany?companyUuid=${company.uuid}`,
  );

  return r.data;
};

export const changePriorizitedCompany = async (
  company,
  prioritized,
  dispatch,
  pagination,
) => {
  const r = await put('/company/UpdateCompanyCommand?uuid=' + company.uuid, {
    prioritized,
  });
  if (r.data.success) {
    await getCompanies(dispatch, pagination);
    return true;
  }
  return false;
};

export const companyBulkUpdatePriority = async (rucs, dispatch) => {
  const r = await put('/company/company_priorizited_list', rucs);
  if (r.data.success) {
    await getCompanies(dispatch);

    return true;
  }

  return false;
};

export const getTemplateBulkCompanies = async () => {
  const r = await get('/company/bulk_companies_template');

  let result = null;
  if (r.data.success) {
    result = r.data.payload;
  }

  return result;
};

export const getCompaniesWithPolicies = async () => {
  const r = await get('/company/policies_required');
  if (r.data.success) {
    return r.data.payload;
  }

  return false;
};
