import React, { memo } from 'react';
import moment from 'moment';
import { Button, notification } from 'antd';
import { useNavigate } from 'react-router-dom';
import { acceptServiceByProvider } from '../../../../../services/requestServicesItems';
import { getParameterByKey } from '../../../../../services/parameter';

const ButtonSchedule = ({ date, time, requestServiceItemId }) => {
  const navigate = useNavigate();
  const _handleSchedule = async () => {
    const provider = await getParameterByKey('default_provider_id');
    const { default_provider_id: providerId } =
      provider?.value && typeof provider?.value === 'string'
        ? JSON.parse(provider?.value)
        : provider?.value;
    const mergeTime = moment(
      `${date.format('DD-MM-YYYY')} ${time.format('HH:mm')}`,
      'DD-MM-YYYY HH:mm',
    );
    const res = await acceptServiceByProvider(
      requestServiceItemId,
      providerId,
      mergeTime.unix(),
    );

    if (!res) {
      notification.warning({
        message: 'Hubo un error al agendar fecha',
      });
    } else {
      navigate('/servicio-de-riesgos');
    }
  };

  return (
    <Button
      type='primary'
      size='large'
      className='pl-4 pr-4'
      disabled={!date || !time}
      onClick={_handleSchedule}
    >
      Agendar
    </Button>
  );
};

export default memo(ButtonSchedule);
