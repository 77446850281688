import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Skeleton } from 'antd';

import {
  getMonitoringArticles,
  getRecentArticles,
} from '../../../services/articles';

import HeaderPortal from '../../../common/HeaderPortal';
import FooterPortal from '../../../common/FooterPortal';
import Button from '../components/Button';
import InformCardHome from '../components/InformCardHome';
import CarouselHome from '../components/CarouselHome';
import MainCarousel from '../components/MainCarousel';

const Home = () => {
  const [monitoringArticles, setMonitoringArticles] = useState(null);
  const [preventionArticles, setPreventionArticles] = useState([]);

  useEffect(() => {
    getMonitoringArticles().then((data) => {
      if (data?.list) {
        setMonitoringArticles(data.list);
      }
    });
    getRecentArticles().then((data) => {
      if (data?.list) {
        setPreventionArticles(data.list);
      }
    });
  }, []);

  return (
    <div className='portal-web'>
      <HeaderPortal />
      <main className='portal-home'>
        <div className='particule-yellow'></div>
        <div className='particule-orange'></div>
        <MainCarousel />
        <section className='section informs-section'>
          <div className='informs'>
            <h2 className='section--padding informs__title'>
              Accede a los informes sobre fenómenos naturales
            </h2>
            <div className='informs__list'>
              {monitoringArticles ? (
                monitoringArticles.length > 0 &&
                monitoringArticles.slice(0, 2).map((article) => (
                  <>
                    <InformCardHome
                      key={`moni-${article.id}`}
                      href={
                        article.seo_canonical
                          ? `/articles/${article.url}.html`
                          : `/articulos/${article.url}`
                      }
                      title={article.title}
                      img={`${process.env.REACT_APP_WEB_PATH_S3_NEW}${article.image}`}
                      tag={moment(article.createdAt).format('MMMM YYYY')}
                    />
                  </>
                ))
              ) : (
                <div style={{ width: 256, height: 100, marginBottom: 64 }}>
                  <Skeleton />
                </div>
              )}
            </div>
            <a className='seeMore' href='/articulos?f=monitoreo'>
              <span>Ver todos los informes</span>
              <svg
                width='15'
                height='12'
                viewBox='0 0 15 12'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M14.725 6L10.225 0.375H6.9125L10.525 4.75H0.625V7.25H10.525L6.9125 11.625H10.225L14.725 6Z'
                  fill='#3817FF'
                />
              </svg>
            </a>
          </div>
        </section>
        <section className='section risks-background'>
          <div className='risks-background__mask'></div>
          <div className='risks'>
            <div className='risks__left'>
              <h2 className='risks__title'>
                Controla los riesgos en tus operaciones
              </h2>
              <p className='risks__description'>
                El servicio de Prevención patrimonial incluye evaluaciones,
                asesorías y herramientas para el control de riesgos.
              </p>
              <div className='risks__buttons'>
                <Button href='/prevencion' className='secondary'>
                  Conoce más
                </Button>
              </div>
            </div>
          </div>
        </section>
        {preventionArticles && preventionArticles.length > 0 && (
          <section className='section articles-section'>
            <div className='articles'>
              <h2>Revisa nuestros artículos recientes</h2>
              <div className='articles__list'>
                <CarouselHome articles={preventionArticles.slice(0, 3)} />
              </div>
              <a className='seeMore' href='/articulos?f=todos'>
                <span>Ver todos los artículos</span>
                <svg
                  width='15'
                  height='12'
                  viewBox='0 0 15 12'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M14.725 6L10.225 0.375H6.9125L10.525 4.75H0.625V7.25H10.525L6.9125 11.625H10.225L14.725 6Z'
                    fill='#3817FF'
                  />
                </svg>
              </a>
            </div>
          </section>
        )}
        <section className='forms-section'>
          <div className='forms--left'>
            <div className='policy'>
              <div className='policy__decoration'>
                <img
                  src='/images/portal/home_policy_deco1.png'
                  alt=''
                  aria-hidden
                />
                <img
                  src='/images/portal/home_policy_deco2.png'
                  alt=''
                  aria-hidden
                />
                <img
                  src='/images/portal/home_policy_deco3.png'
                  alt=''
                  aria-hidden
                />
              </div>
              <h2>Solicita tu póliza</h2>
              <p className='policy__description'>
                Te ayudamos a elegir la póliza que mejor se adecúa a tu negocio,
                déjanos tus datos para contactarte.
              </p>
              <div className='policy__buttons'>
                <Button
                  href='https://www.rimac.com/empresa?rfid=publica:categoria-empresas:menu-principal:link'
                  target='_blank'
                >
                  Dejar mis datos
                </Button>
              </div>
            </div>
          </div>
        </section>
      </main>
      <FooterPortal />
    </div>
  );
};

export default Home;
