import { useMemo } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { Layout, Menu, Drawer, Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import {
  CONTROLMAP_SIDER_VISIBLE,
  CONTROLMAP_DRAWER_VISIBLE,
  CONTROLMAP_SELECTED,
  MAP_SET_ACTIVE_CUMULUS_TOOL,
  SIDEMENU_CHANGE_VISIBLE,
} from '../../../redux/actions/actionsType';
import { listSider } from './utils';
import { useScreen } from '../../../lib/screen';
import { checkModules, checkPermission } from '../../../lib/security';
import { layers, cumulusTools } from '../../SideMenu/data';

import DrawerSearch from './DrawerSearch';
import DrawerLayers from './DrawerLayers';
import DrawerFilters from './DrawerFilters';
import DrawerAlerts from './DrawerAlerts';
import DrawerForescasts from './DrawerForescasts';
import DrawerInformation from './DrawerInformation';
import DrawerUtils from './DrawerUtils';
import DrawerCumulusTools from './DrawerCumulusTools';
import DrawerTools from './DrawerTools';
import DrawerMyLayers from './DrawerMyLayers';
import DrawerMyRoutes from './DrawerMyRoutes';
import MenuItem from './MenuItem';

const { Sider } = Layout;

const layerWithFilters = ['emergencias', 'sismos', 'tsunamis', 'geological'];
let currentKey = null; // Keep track of the current key

const ControlMap = ({
  activeCumulusTool,
  controlMapSiderVisible,
  controlMapDrawerVisible,
  controlMapSelected,
  backMainDrawer,
  siderVisible,
  drawerVisible,
  selected,
}) => {
  const dispatch = useDispatch();
  const { isMobileScreen: isMobile } = useScreen();
  const map = useSelector((state) => state.maps.map);
  const activeLayer = useSelector((state) => state.maps.activeLayer);
  const activeUtilsLayer = useSelector((state) => state.maps.activeUtilsLayer);
  const user = useSelector((state) => state.auth.user);
  const isOpened = useSelector((state) => state.sideMenu.isOpened);

  const { title, descriptions } = useMemo(
    () => listSider?.find((el) => el.id === selected) ?? {},
    [selected],
  );

  const onCloseDrawer = () => {
    controlMapDrawerVisible(false);
    controlMapSelected('');
  };

  const changeMenuVisible = (isOpen) =>
    dispatch({ type: SIDEMENU_CHANGE_VISIBLE, payload: isOpen });

  const handleClick = (e) => {
    const it = listSider.find((item) => item.id === e.key);
    if (e.key === '0' && isMobile) {
      changeMenuVisible(!isOpened);
    }
    if (it && it.drawer) {
      if (currentKey === e.key) {
        controlMapDrawerVisible(!drawerVisible);
      } else {
        controlMapDrawerVisible(true);
      }
      currentKey = e.key;
      controlMapSelected(e.key);
    } else if (it) {
      if (e.key === '1' && map) {
        map.setZoom(map.getZoom() + 1);
      }

      if (e.key === '2' && map) {
        map.setZoom(map.getZoom() - 1);
      }

      controlMapDrawerVisible(false);
      currentKey = null;
    } else {
      controlMapDrawerVisible(false);
      controlMapSelected('');
      currentKey = null;
    }

    if (isMobile) {
      controlMapSiderVisible(true);
    }
  };

  const conditions = {
    cumulusTools: () => activeUtilsLayer.some((tool) => tool.key === 'cumulo'),
    'my-layers': () => checkModules(user, 'mis_capas'),
    'my-routes': () => checkModules(user, 'routing'),
    tools: () => checkModules(user, 'tools'),
    forecast: () => activeLayer?.forecast,
    6: () => layerWithFilters.includes(activeLayer?.id),
  };

  const goToLayers = () => handleClick({ key: '4' });

  return (
    <>
      <Sider
        collapsed={siderVisible}
        width={208}
        collapsedWidth={40}
        className='siderbar-map'
      >
        <Menu
          mode='inline'
          className='siderbar-map__menu'
          onClick={handleClick}
          selectedKeys={selected}
        >
          {listSider.map((item) => {
            if (item.id === '0' && !isMobile) {
              return null;
            }
            if (item.permission && !checkPermission(user, item.permission)) {
              return null;
            }
            if (conditions[item.id] && !conditions[item.id]()) {
              return null;
            }
            return (
              <Menu.Item
                key={item.id}
                style={{ marginBottom: item.style ? '49px' : '10px' }}
              >
                <MenuItem item={item} siderVisible={siderVisible} />
              </Menu.Item>
            );
          })}
        </Menu>
        <div
          className={`siderbar-map__btn-collapse${
            siderVisible ? '-visible' : ''
          }`}
          onClick={() => controlMapSiderVisible(!siderVisible)}
        >
          {siderVisible ? (
            <LeftOutlined style={{ color: '#FF1C44', fontSize: '16px' }} />
          ) : (
            <>
              <RightOutlined style={{ color: '#FF1C44', fontSize: '16px' }} />
              <span className='siderbar-map__btn-collapse__text'>Colapsar</span>
            </>
          )}
        </div>
      </Sider>
      <Drawer
        title={null}
        placement='right'
        open={drawerVisible}
        width={328}
        onClose={onCloseDrawer}
        className={`drawer-map-search ${
          !siderVisible ? 'siderbar-map-visible' : ''
        } ${selected === '7' ? 'drawer-map-forecast' : ''}`}
        mask={false}
      >
        <div className='drawer-map-search__close'>
          {activeCumulusTool?.drawer && selected === 'cumulusTools' && (
            <Button
              onClick={backMainDrawer}
              icon={<LeftOutlined />}
              type='text'
            >
              Regresar
            </Button>
          )}
        </div>
        <div className='drawer-map-search__body'>
          {!(activeCumulusTool?.drawer && selected === 'cumulusTools') ? (
            <>
              <div className='drawer-map-search__body__title'>{title}</div>
              <p className='drawer-map-search__body__text'>{descriptions}</p>
            </>
          ) : (
            <>
              <div className='drawer-map-search__body__title'>
                {activeCumulusTool?.title}
              </div>
              <p className='drawer-map-search__body__text'>
                {activeCumulusTool?.descriptions}
              </p>
            </>
          )}
          {selected === '3' && (
            <DrawerSearch activeLayer={activeLayer} user={user} />
          )}
          {selected === '4' && (
            <DrawerLayers
              isMobile={isMobile}
              layers={layers}
              controlMapDrawerVisible={controlMapDrawerVisible}
            />
          )}
          {selected === 'alerts' && <DrawerAlerts />}
          {selected === '6' && layerWithFilters.includes(activeLayer.id) && (
            <DrawerFilters isMobile={isMobile} onClose={onCloseDrawer} />
          )}
          {selected === 'forecast' && (
            <DrawerForescasts
              activeLayer={activeLayer}
              isMobile={isMobile}
              onClose={onCloseDrawer}
            />
          )}
          {selected === '10' && <DrawerInformation goToLayers={goToLayers} />}
          {selected === 'utils' && (
            <DrawerUtils controlMapDrawerVisible={controlMapDrawerVisible} />
          )}
          {selected === 'cumulusTools' && (
            <DrawerCumulusTools
              isMobile={isMobile}
              cumulusTools={cumulusTools}
              controlMapDrawerVisible={controlMapDrawerVisible}
            />
          )}
          {selected === 'tools' && (
            <DrawerTools controlMapDrawerVisible={controlMapDrawerVisible} />
          )}
          {selected === 'my-layers' && (
            <DrawerMyLayers
              isMobile={isMobile}
              controlMapDrawerVisible={controlMapDrawerVisible}
            />
          )}
          {selected === 'my-routes' && (
            <DrawerMyRoutes
              isMobile={isMobile}
              controlMapDrawerVisible={controlMapDrawerVisible}
            />
          )}
        </div>
      </Drawer>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  controlMapSiderVisible: (visible) =>
    dispatch({ type: CONTROLMAP_SIDER_VISIBLE, payload: visible }),
  controlMapDrawerVisible: (visible) =>
    dispatch({ type: CONTROLMAP_DRAWER_VISIBLE, payload: visible }),
  controlMapSelected: (visible) =>
    dispatch({ type: CONTROLMAP_SELECTED, payload: visible }),
  backMainDrawer: () =>
    dispatch({ type: MAP_SET_ACTIVE_CUMULUS_TOOL, payload: null }),
});

const mapStateToProps = (state) => ({
  siderVisible: state.controlMap.siderVisible,
  drawerVisible: state.controlMap.drawerVisible,
  selected: state.controlMap.selected,
  activeCumulusTool: state.maps.activeCumulusTool,
});

export default connect(mapStateToProps, mapDispatchToProps)(ControlMap);
