import { useState, useCallback, useMemo, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Checkbox, Radio, Input, Typography } from 'antd';
import { RideErrorMessage } from '@rimac-seguros/ride-system-components';

import { initTinyEditor } from '../../../../../lib/tinyEditorConfig';

import ImageInput from '../../../../../common/ImageInput';
import { SelectWithLabel } from '../../../../../common/InputWithLabel';
import LevelCompanyForm from '../../LevelCompany/Form';
import InputCodeConvert from './InputCodeConvert';
import MultipleSelect from '../../../../../common/MultipleSelect';

const { Title } = Typography;
const { TextArea } = Input;

const FieldsInputs = ({
  invalidField,
  name,
  value,
  type,
  options,
  title,
  loading,
  onChange,
  visible,
  formProps,
  ...props
}) => {
  const [afterBlur, setAfterBlur] = useState(false);
  const [filtersOptions, setFiltersOptions] = useState(options);

  useEffect(() => {
    setFiltersOptions(options || []);
  }, [options]);

  const handleUpdateFilters = useCallback(
    (name, value) => {
      setFiltersOptions((prev) => {
        const newcols = prev.map((el) => {
          return el?.value === value
            ? {
                ...(el ?? {}),
                selected: !el.selected,
              }
            : el;
        });

        return newcols;
      });
    },
    [options],
  );

  const handleSortFilter = (a, b) => {
    return b.selected - a.selected;
  };

  const handleChageCheckbox = useCallback(
    (val) => {
      onChange({ target: { value: val, name } });
    },
    [name, onChange],
  );

  const ComponentToRender = useMemo(() => {
    switch (type) {
      case 'radio':
        return (
          <Radio.Group
            className={`flex flex-col radio-list ${props?.className ?? ''}`}
            name={name}
            value={value}
            onChange={onChange}
          >
            {options.map(({ label, value: internalVal }) => (
              <Radio key={internalVal} value={internalVal}>
                <span className='text-base'>{label}</span>
              </Radio>
            ))}
          </Radio.Group>
        );

      case 'check':
        return (
          <Checkbox.Group
            name={name}
            value={value}
            onChange={handleChageCheckbox}
            className={`flex flex-col check-list ${props?.className ?? ''}`}
          >
            {options.map(({ label, name, id: internalVal }) => (
              <Checkbox key={internalVal} value={internalVal}>
                <span className='text-base'>{label || name}</span>
              </Checkbox>
            ))}
          </Checkbox.Group>
        );

      case 'list':
        return (
          <SelectWithLabel
            {...(props || {})}
            onSelect={(val) => {
              onChange({ target: { value: val, name } });
            }}
            style={{ width: '60%' }}
            name={name}
            value={value}
            options={options}
          />
        );
      case 'multiSelect':
        return (
          <MultipleSelect
            {...(props || {})}
            loading={loading}
            filters={filtersOptions}
            updateFilterColumns={handleUpdateFilters}
            sortFilter={handleSortFilter}
            blurEvent={(val) => {
              onChange({ target: { value: val, name } });
            }}
            showSelected={true}
            placeholder={props.label}
            name={name}
          />
        );
      case 'textarea':
        return (
          <TextArea
            onChange={onChange}
            placeholder={props.label}
            value={value}
            {...props}
            name={name}
          />
        );

      case 'image':
        return (
          <ImageInput
            onChange={onChange}
            value={value}
            className={props?.className}
          />
        );

      case 'custom-company':
        return (
          <LevelCompanyForm
            onChange={onChange}
            value={value}
            formProps={formProps}
            {...props}
          />
        );

      case 'editor':
        return (
          <Editor
            onEditorChange={(data) =>
              onChange({ target: { value: data, name } })
            }
            value={value ?? ''}
            apiKey={process.env.REACT_APP_TINYMCE_KEY}
            init={initTinyEditor}
          />
        );

      case 'component-code':
        return (
          <InputCodeConvert
            onChange={({ name, value }) => {
              onChange({ target: { name, value } });
            }}
            value={value}
            name={name}
            {...props}
          />
        );

      default:
        return (
          <Input
            {...props}
            onChange={onChange}
            type={type}
            name={name}
            placeholder={props.label || 'Ingrese un valor'}
            onBlur={() => {
              setAfterBlur(true);
            }}
            value={value || ''}
          />
        );
    }
  }, [handleChageCheckbox, name, onChange, options, props, type, value]);

  return (
    <div className='pt-1 mb-2 full-width'>
      {title ? (
        <Title level={5} className={'mb-1 mt-2'}>
          {title}
        </Title>
      ) : null}
      <div>
        {ComponentToRender}
        {props.required && invalidField && afterBlur ? (
          <RideErrorMessage className='mt-1' message='Campo requerido' />
        ) : null}
      </div>
    </div>
  );
};

export default FieldsInputs;
