import { useLayoutEffect, useMemo, useState } from 'react';

export const useScreen = () => {
  const [widthScreen, setWidthScreen] = useState();
  const [heightScreen, setHeightScreen] = useState();

  useLayoutEffect(() => {
    function updateSize() {
      setWidthScreen(window.innerWidth);
      setHeightScreen(window.innerHeight);
    }
    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const isBeforeTable = useMemo(() => widthScreen <= 1242, [widthScreen]);
  const isTabletScreen = useMemo(
    () => widthScreen <= 1242 && widthScreen > 767,
    [widthScreen],
  );
  const isMobileScreen = useMemo(() => widthScreen <= 767, [widthScreen]);

  return {
    widthScreen,
    isTabletScreen,
    heightScreen,
    isMobileScreen,
    isBeforeTable,
  };
};

export const isPhone = () => window.innerWidth <= 767;

export const isTablet = () => window.innerWidth <= 1024;
