import { memo, useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, List, notification, Popover, Skeleton } from 'antd';
import {
  EllipsisOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

import {
  getBanner,
  createSlide,
  updateSlide,
  removeSlide,
  getPresignedUrl,
  sendBannerImage,
} from '../../../../services/banner';

import TableCommon from '../../../../common/Table';
import { CardSkeleton } from '../../ui/PreventionService/Reports/utils';
import ModalConfirm from '../../../../common/ModalConfirm';
import CardItemMobile from '../common/CardItemMobile';
import MainTitle from '../common/MainTitle';

import DeleteModalImage from '../../../../images/delete_modal_image.svg';
import { iconLeft, iconRight } from '../../../../common/Icons/iconText';
import Empty from '../../../../common/Empty';

const columnsFixed = [
  {
    title: 'Texto superior',
    dataIndex: 'text',
    key: 'text',
    width: 200,
  },
  {
    title: 'Título (N)',
    dataIndex: 'header1',
    key: 'header1',
    width: 150,
  },
  {
    title: 'Título (R)',
    dataIndex: 'header2',
    key: 'header2',
    width: 150,
  },
  {
    title: 'Descripción',
    dataIndex: 'description',
    key: 'description',
    width: 400,
  },
  {
    title: 'URL',
    dataIndex: 'link',
    key: 'link',
    width: 200,
  },
  {
    title: 'Orden',
    dataIndex: 'order',
    key: 'order',
    width: 100,
  },
  {
    title: '¿URL externo?',
    dataIndex: 'target',
    key: 'target',
    width: '100px',
    render: (boolean) => (Boolean(parseInt(boolean)) ? 'Si' : 'No'),
  },
  {
    title: '¿Está activo?',
    dataIndex: 'enabled',
    key: 'enabled',
    width: '100px',
    render: (boolean) => (boolean ? 'Si' : 'No'),
  },
];

const notificationError = () =>
  notification.warning({
    className: 'notification-warning',
    message: 'Ha ocurrido un error.',
  });

const notificationSuccess = (params) =>
  notification.success({
    ...params,
  });

const Banner = ({
  onOpenForm,
  renderItem,
  queryAction,
  onModalSuccess,
  onSetAction,
  isMobileScreen,
  isBeforeTable,
}) => {
  const dispatch = useDispatch();
  const { list, isLoadingList, isLoadingCreate } = useSelector(
    (state) => state.banner,
  );
  const [itemToDelete, setItemToDelete] = useState(null);
  const [pagination, setPagination] = useState(() => ({
    pageSize: 5,
    total: 0,
    current: 1,
  }));

  useEffect(() => {
    getBanner(dispatch, {
      page: pagination.current,
      limit: pagination.pageSize,
    }).then((res) => {
      if (res?.total) setPagination((prev) => ({ ...prev, total: res.total }));
    });
  }, [isLoadingCreate]);

  const _handleTableChangeMobile = useCallback(
    (page) => () => {
      getBanner(dispatch, {
        page: page,
        limit: pagination.pageSize,
      }).then((res) => {
        if (res?.total)
          setPagination((prev) => ({
            ...prev,
            total: res.total,
            current: res.page,
          }));
      });
    },
    [dispatch, pagination],
  );

  const onCreateSlide = useCallback(
    async (slide) => {
      const created = await createSlide(slide, dispatch);
      const successInfo = {
        message: '¡Slider creado!',
      };

      if (created)
        isBeforeTable
          ? onModalSuccess(successInfo)
          : notificationSuccess(successInfo);
      else notificationError();

      onSetAction(false);
    },
    [isBeforeTable],
  );

  const onUpdateService = useCallback(
    async (slide) => {
      const update = await updateSlide(slide, dispatch);
      const successInfo = {
        message: '¡Cambios guardados!',
      };

      if (update)
        isBeforeTable
          ? onModalSuccess(successInfo)
          : notificationSuccess(successInfo);
      else notificationError();
    },
    [isBeforeTable],
  );

  const onRemoveSlide = useCallback(
    async (id) => {
      const removed = removeSlide(id, dispatch);
      const successInfo = {
        message: '¡Servicio eliminado!',
      };
      if (removed)
        isBeforeTable
          ? onModalSuccess(successInfo)
          : notificationSuccess(successInfo);
      else notificationError();
    },
    [isBeforeTable],
  );

  const _handleAction = useCallback(
    (action, item) => () => {
      if (action === 'edit')
        item = {
          ...item,
          target: Number(item?.target),
          enabled: Number(item?.enabled),
        };

      if (action === 'remove') {
        setItemToDelete(null);
        onRemoveSlide(item);
      } else {
        onOpenForm({
          action,
          ...item,
          hasFullImage: true,
          titleSection: action === 'create' ? 'Crear Slider' : 'Editar Slider',
          onAction: async (data, onFinish = () => {}) => {
            let modData = { ...data };

            if (data?.image?.file) {
              const r = await getPresignedUrl(data?.image?.file);
              modData.image_url = r.data.payload.nameKey;
              await sendBannerImage(r.data.payload.url, data?.image?.file);
              delete modData.image;
            }

            await (action === 'create'
              ? onCreateSlide(modData)
              : onUpdateService(modData));
            onFinish();
            onOpenForm(null);
          },
        });
      }
    },
    [onCreateSlide, onUpdateService, onOpenForm, onRemoveSlide],
  );

  useEffect(() => {
    if (queryAction) _handleAction('create', renderItem)();
  }, [queryAction]);

  const columns = columnsFixed.concat({
    title: 'Acciones',
    dataIndex: 'actions',
    fixed: 'right',
    key: 'actions',
    width: 80,
    render: (__, item) => (
      <Popover
        className='popover'
        placement='topRight'
        content={
          <List
            dataSource={[
              {
                action: _handleAction('edit', item),
                icon: <EditOutlined />,
                title: 'Editar',
                key: 'edit',
              },
              {
                action: () => {
                  setItemToDelete(item.id);
                },
                icon: <DeleteOutlined />,
                title: 'Eliminar',
                key: 'delete',
              },
            ]}
            renderItem={({ action, icon, key, title }) => (
              <List.Item
                style={{ width: 167, height: 40, paddingLeft: 16 }}
                className='list-item-custom'
                key={key}
                onClick={action}
              >
                {icon}
                {title}
              </List.Item>
            )}
          ></List>
        }
      >
        <Button>
          <EllipsisOutlined />
        </Button>
      </Popover>
    ),
  });

  return (
    <>
      <MainTitle
        buttonAction={_handleAction('create', renderItem)}
        renderItem={renderItem}
        isMobileScreen={isMobileScreen}
      />
      {!pagination.total && !isLoadingList ? (
        <Empty
          title='No se han agregado sliders'
          description='Cree sliders para agregarlos al banner'
        />
      ) : !isMobileScreen ? (
        <section className='show-data'>
          {isLoadingList ? (
            <Skeleton active title={false} paragraph={{ rows: 1 }} />
          ) : list.length !== 1 ? (
            <p className='info-total-data'>
              Se encontraron <span>{pagination.total}</span> slides
            </p>
          ) : (
            <p className='info-total-data'>
              Se encontró <span>{pagination.total}</span> slide
            </p>
          )}

          <TableCommon
            dataSource={list}
            columns={columns}
            loading={isLoadingList}
            locale={{ emptyText: 'No se han agregado sliders.' }}
            pagination={{
              ...pagination,
              onChange: (page) => _handleTableChangeMobile(page)(),
            }}
          />
        </section>
      ) : (
        <section className='content-mobile action-bottom'>
          {isLoadingList ? (
            <Skeleton active title={false} paragraph={{ rows: 1 }} />
          ) : list.length !== 1 ? (
            <p className='info-total-data'>
              Se encontraron <span>{pagination.total}</span> slides
            </p>
          ) : (
            <p className='info-total-data'>
              Se encontró <span>{pagination.total}</span> slide
            </p>
          )}

          {isLoadingList ? (
            [1, 2, 3, 4].map((el) => <CardSkeleton key={el} />)
          ) : list.length === 0 ? (
            <Empty
              title='No se han agregado sliders'
              description='Cree sliders para agregarlos al banner'
            />
          ) : (
            list.map((data) => (
              <CardItemMobile
                key={data.id}
                data={data}
                columns={columnsFixed}
                onEdit={_handleAction('edit', data)}
                onDelete={() => {
                  setItemToDelete(data.id);
                }}
              />
            ))
          )}

          <div className='d_content__list__pag'>
            <div
              className={'d_content__list__pag__button'}
              onClick={_handleTableChangeMobile(pagination.current - 1)}
            >
              {iconLeft({
                color: pagination.current === 1 ? '#A9AFD9' : '#4F4FFF',
              })}
            </div>

            <div className='d_content__list__pag__number color-red-1'>
              {pagination.current}
            </div>
            <div className='d_content__list__pag__sep'>/</div>
            <div className='d_content__list__pag__number color-gray-3'>
              {Math.ceil(pagination.total / pagination.pageSize)}
            </div>

            <div
              className={'d_content__list__pag__button'}
              onClick={_handleTableChangeMobile(pagination.current + 1)}
            >
              {iconRight({
                color:
                  pagination.current ===
                  Math.ceil(pagination.total / pagination.pageSize)
                    ? '#A9AFD9'
                    : '#4F4FFF',
              })}
            </div>
          </div>
        </section>
      )}

      <ModalConfirm
        visible={Boolean(itemToDelete)}
        onClose={() => setItemToDelete(null)}
        title={'¿Seguro que desea eliminar este slide del banner?'}
        image={DeleteModalImage}
        actionsContent={
          <div className='flex justify-center gap-2 mt-2'>
            <Button onClick={() => setItemToDelete(null)} size='large'>
              Cancelar
            </Button>
            <Button
              type='primary'
              onClick={_handleAction('remove', itemToDelete)}
              size='large'
            >
              Eliminar
            </Button>
          </div>
        }
      />
    </>
  );
};

export default memo(Banner);
