import {
  RideButton,
  RideGlFiltersSolid,
} from '@rimac-seguros/ride-system-components';
import { eventTypes } from '../../../../../lib/consts';

const TypeFilterPanel = ({ filters, handleTypeClick, setOpen }) => {
  return (
    <div className='flex flex-col gap-4'>
      <strong>Tipos:</strong>
      <div className='newslettersDesktopChips'>
        <div className='newslettersDesktopChips__buttons'>
          <button
            className={`newsletterTypeButton ${filters?.type ? '' : 'active'}`}
            onClick={() => handleTypeClick('')}
          >
            Todos
          </button>
          {eventTypes.map((type) => (
            <button
              className={`newsletterTypeButton ${
                filters?.type === type.id ? 'active' : ''
              }`}
              key={type.id}
              onClick={() => handleTypeClick(type.id)}
            >
              {type.name}
            </button>
          ))}
        </div>
        <RideButton
          variant='text-primary'
          size='small'
          text={'Más filtros'}
          glyphPosition='left'
          glyph={<RideGlFiltersSolid />}
          onClick={() => setOpen(true)}
        />
      </div>
    </div>
  );
};

export default TypeFilterPanel;
