import { get, post } from '../lib/ajax';

export const getPermissions = async () => {
  const { status, data } = await get('/permissions/list');
  const payload = data?.payload;

  return status === 200 ? payload : [];
};

export const getPermissionsByRol = async (rolId) => {
  const { status, data } = await post('/permissions/listRol', {
    payload: {
      rol: rolId?.toString(),
    },
  });
  const payload = data?.payload;

  return status === 200 ? payload : [];
};
