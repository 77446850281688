import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Input, Select, message, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { RideButton } from '@rimac-seguros/ride-system-components';

import { CHANGE_FILTER_HELP_CENTER } from '../../../../redux/actions/actionsType';
import {
  getRequestSupport,
  removeTicket,
  listUserRequest as listUserRequestAction,
  closeTicket,
} from '../../../../services/helpCenter';

import HelpCenterForm from './HelpCenterForm';
import Topbar from '../../../../common/ui/Topbar';
import ModalFilter from './components/ModalFilter';
import SupportList from './components/SupportList';

const { Search } = Input;
const { Option } = Select;
const { Title } = Typography;

const HelpCenter = () => {
  const dispatch = useDispatch();
  const { list, isLoading, filter, pagination, user } = useSelector(
    (state) => ({
      list: state.helpCenter.list,
      isLoading: state.helpCenter.isLoadingList,
      filter: state.helpCenter.filter,
      pagination: state.helpCenter.pagination,
      user: state.auth.user,
    }),
  );
  const [openModal, setOpenModal] = useState(false);
  const [openModalFilter, setOpenModalFilter] = useState(false);
  const [users, setUsers] = useState([]);
  const [textSearch, setTextSearch] = useState('');

  useEffect(() => {
    loadRequest({
      page: 1,
      limit: pagination.pageSize,
    });
    listUserRequestAction().then((list) => {
      setUsers(list);
    });
  }, []);

  const loadRequest = (obj) => {
    getRequestSupport(obj, dispatch);
  };

  const removeRequest = (data, obj) => {
    removeTicket(data, obj, dispatch);
  };

  const changeFilter = (filter) => {
    dispatch({ type: CHANGE_FILTER_HELP_CENTER, payload: filter });
  };

  const doCloseTicket = async (ticket, obj) => {
    const state = await closeTicket(ticket);
    if (state) {
      await getRequestSupport(obj, dispatch);
      return true;
    } else {
      message('Ha ocurrido un error.');
      return false;
    }
  };

  const find = (e) => {
    const txt = e.target.value;
    setTextSearch(txt);

    if (txt.length >= 2) {
      loadRequest({
        query: txt,
        page: 1,
        limit: pagination.pageSize,
        filter,
      });
    } else {
      loadRequest({
        query: '',
        page: 1,
        limit: pagination.pageSize,
        filter,
      });
    }
  };
  const applyFilters = (values) => {
    if (values.txt.length >= 2) {
      loadRequest({
        query: values.txt,
        page: 1,
        limit: pagination.pageSize,
        filter: values.filter,
      });
    } else {
      loadRequest({
        query: '',
        page: 1,
        limit: pagination.pageSize,
        filter: values.filter,
      });
    }
  };

  const handleChangeFilter = (field, value) => {
    loadRequest({
      query: filter.query,
      page: 1,
      limit: pagination.pageSize,
      filter: {
        ...filter,
        [field]: value,
      },
    });
  };

  const handleRemove = (data) => {
    removeRequest(data, {
      query: filter.query,
      page: 1,
      limit: pagination.pageSize,
      filter,
    });
  };
  const handleOpenCreateRequest = () => {
    setOpenModal(!openModal);
  };
  const handleClearFilter = () => {
    setTextSearch('');
    changeFilter({
      query: '',
      user_id: false,
      status: false,
    });
    loadRequest({
      page: 1,
      limit: pagination.pageSize,
    });
  };
  const handleCloseTicket = (item) => {
    return doCloseTicket(item, {
      query: filter.query,
      page: 1,
      limit: pagination.pageSize,
      filter,
    });
  };

  return (
    <div id='checkSize' className='h100vh dashboard-UI'>
      <Topbar />

      <div className='d_content'>
        <div className='flex items-center justify-between mb-4'>
          <Title
            level={2}
            style={{
              marginBottom: 0,
            }}
          >
            Centro de Ayuda
          </Title>
          {user &&
            !['admin_rimac', 'gestor_de_cuenta'].includes(user.rol.slug) && (
              <div className='d_content__actions-support'>
                <RideButton
                  onClick={handleOpenCreateRequest}
                  size='large'
                  text='Nueva solicitud'
                />
              </div>
            )}
        </div>

        <div className='filter__text mb-4'>Filtra solicitudes</div>

        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            gap: '0.5rem',
          }}
        >
          <Search
            placeholder='Palabra clave'
            style={{ maxWidth: 452, minWidth: 160 }}
            value={textSearch}
            onChange={find}
          />
          {!['usuario_cliente', 'administrador_cliente'].includes(
            user?.rol.slug,
          ) && (
            <Select
              className='mr-2'
              value={filter.user_id ? filter.user_id : ''}
              style={{ width: 244, minWidth: 128 }}
              onChange={(val) => handleChangeFilter('user_id', val)}
              placeholder='Usuario'
            >
              <Option key='user-select' value=''>
                Usuario
              </Option>
              {users.map((u) => (
                <Option key={u.id} value={u.id}>
                  {u.user}
                </Option>
              ))}
            </Select>
          )}

          <Select
            className='mr-1'
            value={filter.status ? filter.status : ''}
            style={{ minWidth: 200 }}
            onChange={(val) => handleChangeFilter('status', val)}
            placeholder='Estado'
          >
            <Option value=''>Estado</Option>
            <Option value={1}>Nuevo</Option>
            <Option value={2}>Cerrado</Option>
            <Option value={3}>En proceso</Option>
          </Select>
          <Button
            type='link'
            className='btn-clearFilter--large ml-auto'
            onClick={handleClearFilter}
            size='large'
            icon={<DeleteOutlined />}
          >
            Borrar Filtros
          </Button>
        </div>

        <div className='d_content__list'>
          <SupportList
            data={list}
            isLoading={isLoading}
            onDelete={handleRemove}
            loadData={loadRequest}
            handleOpenCreateRequest={handleOpenCreateRequest}
            setOpenModalFilter={setOpenModalFilter}
            user={user}
            doCloseTicket={handleCloseTicket}
          />
        </div>
      </div>

      <HelpCenterForm visible={openModal} openForm={handleOpenCreateRequest} />

      <ModalFilter
        visible={openModalFilter}
        onConfirm={() => setOpenModalFilter(false)}
        users={users}
        find={find}
        filter={filter}
        user={user}
        applyFilters={applyFilters}
        handleClearFilter={handleClearFilter}
      />
    </div>
  );
};

export default HelpCenter;
