import React, { useState, useEffect } from 'react';
import { Divider, Card, Table, Button } from 'antd';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import _ from 'lodash';

import ModalSurvey from './components/ModalSurvey';
import ModalFinishSurvey from './components/ModalFinishSurvey';
import Loading from './components/Loading';
import {
  getMyListRequestServiceItems,
  getServicesOfPoliza,
} from '../../../../../../services/requestServices';
import { getActiveProviders } from '../../../../../../services/providers';
import { SelectWithLabel } from '../../../../../../common/InputWithLabel';
import DeriveButton from './components/DeriveButton';
import IconNotData from '../../../../../../images/icon_info.svg';
import ModalUploadTechnicalReport from '../../../../../../common/ModalUploadTechnicalReport';
import { addResult } from '../../../../../../services/requestServicesItemsResults';

import IconPerson from './components/IconPerson';
import IconManager from './components/IconManager';
import TableInHouse from './components/TableInHouse';

const columns = [
  {
    key: 'path',
    title: 'Imagen',
    render: (el) => (
      <img
        src={`${process.env.REACT_APP_WEB_PATH_S3_NEW}${el.path}`}
        alt={el.name}
        style={{ width: '100px' }}
      />
    ),
  },
  {
    title: 'Descripción',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Material',
    key: 'material',
    render: (el) => {
      let material = [];
      for (const ind in el)
        if (
          el[ind] % 1 === 0 &&
          ind !== 'code' &&
          ind !== 'count' &&
          ind !== 'id' &&
          el[ind] !== null
        )
          material.push(`${ind}: ${el[ind]}`);

      return (
        <div dangerouslySetInnerHTML={{ __html: material.join('<br>') }}></div>
      );
    },
  },
  {
    title: 'Cantidad',
    dataIndex: 'count',
    key: 'count',
  },
];

const noData = 'Sin información';

const polizaRequestServices = (requests, polizaId, serviceId) => {
  const filtered = requests.filter(
    (el) =>
      el?.requestService?.polizaId === polizaId && el?.serviceId === serviceId,
  );

  return _.chain(filtered).groupBy('serviceId').value();
};

const mapPolizaServices = (services, values, serviceId) => {
  const [element] = services.filter((s) => s.serviceId === serviceId);
  let count;
  const prevItems = _.chain(values[serviceId])
    .map((el) => el.requestServiceItemsData)
    .reduce((result, curr) => result.concat(curr), [])
    .map((el) => JSON.parse(el.value))
    .value();

  const items = prevItems
    .reduce((a, b) => a.concat(Object.values(b)), [])
    .filter(Boolean);

  count = _.chain(items)
    .reduce((result, curr) => result.concat(curr), [])
    .map((el) => Number(el?.hours) || 0)
    .sum()
    .value();

  return {
    dbValue: count,
    prevCount: count,
    freeHours: Number(element?.count) - count,
    count,
  };
};

const Service = ({
  selected,
  loadingData,
  setCourseSelected,
  listRequestService,
  loadListServicesItems,
  loadProviders,
  doAddResult,
  pagination,
  userSlug,
  onFinishQuiz,
}) => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [finishSurvey, setFinishSurvey] = useState(false);
  const [signages, setSignages] = useState(null);
  const [freeHours, setFreeHours] = useState(0);
  const [providerId, setProviderId] = useState(null);
  const [manegeAccount, setManegeAccount] = useState('');
  const [showModalReport, setShowModalReport] = useState(false);
  const [providersList, setProvidersList] = useState([]);

  useEffect(() => {
    if (selected && listRequestService.length) {
      const managerAccountExit = listRequestService.find(
        (it) => it.requestServiceId === selected?.requestService?.id,
      );
      const u =
        managerAccountExit &&
        managerAccountExit?.requestService?.company?.companiesUsers &&
        managerAccountExit?.requestService?.company?.companiesUsers[0]
          ? managerAccountExit?.requestService?.company?.companiesUsers[0]
          : null;
      if (u) setManegeAccount(`${u?.name} ${u?.last_name1} ${u?.last_name2}`);
      else
        selected?.company?.companiesUsers &&
          selected?.company?.companiesUsers[0] &&
          setManegeAccount(selected?.company?.companiesUsers[0]?.name);
    }
  }, [listRequestService, selected]);

  useEffect(() => {
    if (selected?.service?.type)
      loadProviders({ id: Number(selected?.service?.id) })
        .then((providers) => setProvidersList(providers))
        .catch(() => setProvidersList([]));
  }, [selected?.service?.type]);

  useEffect(() => {
    if (selected?.provider_id) setProviderId(selected?.provider_id);
  }, [selected]);

  useEffect(() => {
    if (!listRequestService || !listRequestService.length)
      loadListServicesItems({ pageSize: pagination.pageSize });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listRequestService.length]);

  useEffect(() => {
    const poliza = selected?.requestService?.poliza;

    if (poliza?.id && listRequestService.length && selected?.service?.id)
      getServicesOfPoliza(poliza?.id)
        .then((services) => {
          const requests = polizaRequestServices(
            listRequestService,
            selected?.requestService?.poliza?.id,
            selected?.service?.id,
          );

          const newservices = mapPolizaServices(
            services,
            requests,
            selected?.service?.id,
          );
          setFreeHours(newservices.freeHours);
        })
        .catch(() => {
          // setLoadingServices(false)
        });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listRequestService.length, selected]);

  useEffect(() => {
    if (loadingData) return;

    setSignages([]);
    if (
      selected?.requestServiceItemsData?.length > 0 &&
      selected?.requestServiceItemsData[0]?.key === 'signals'
    ) {
      let data;
      try {
        data = JSON.parse(selected?.requestServiceItemsData[0]?.value);
      } catch (error) {
        // eslint-disable-next-line no-useless-escape
        data = JSON.parse(
          selected?.requestServiceItemsData[0]?.value
            .replace(/^\"/, '')
            .replace(/\"$/, '')
            .replace(/\"\{/g, '{')
            .replace(/\}\"/g, '}'),
        );
      }
      // const data = JSON.parse(selected?.requestServiceItemsData[0]?.value)
      if (data && typeof data === 'object') {
        for (const ind in data) {
          if (Object.prototype.hasOwnProperty.call(data, ind)) {
            const elemento =
              typeof data[ind] === 'string' ? JSON.parse(data[ind]) : data[ind];
            if (typeof elemento === 'object') {
              setSignages((prev) => [...prev, elemento]);
            }
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingData]);

  const handleShowModal = () => setShowModal((prev) => !prev);

  const _handleSelectProvider = (id) => {
    setProviderId(id);
  };

  const serviceName = (selected?.service?.name ?? '').toLowerCase();

  const isSignage = ['señaletica', 'señalética'].includes(serviceName);
  const isInHouse = ['capacitacion in house', 'capacitación in house'].includes(
    serviceName,
  );

  const handleUploadTechnicalInform = (data, item) => {
    doAddResult(data, item, {
      query: '',
      page: 1,
      limit: pagination.pageSize,
    }).then(() => navigate('/servicio-de-prevencion'));
    setShowModalReport(false);
  };

  return (
    <>
      <div className='service-detail__body mt-3'>
        {loadingData ? (
          [1, 2].map((item) => <Loading key={item} />)
        ) : (
          <>
            {['admin_rimac', 'gestor_de_cuenta'].includes(userSlug) &&
            ![6, 7].includes(selected?.state) &&
            selected.service.type !== '6' ? (
              <div
                className='service-detail__body__item'
                style={{ maxWidth: 380 }}
              >
                {selected.state > 1 ? (
                  <>
                    <div className='service-detail__body__item__upload'>
                      <Button
                        className='ml-auto'
                        size='large'
                        type='primary'
                        onClick={() => setShowModalReport(true)}
                      >
                        Subir Informe Técnico
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <p className='service-detail__body__item__name title-sections'>
                      Derivar proveedor
                    </p>
                    <SelectWithLabel
                      onChange={_handleSelectProvider}
                      value={providerId}
                      disabled={![1, 2, 3, 8, null].includes(selected?.state)}
                      options={providersList.map((prov) => ({
                        label: `${prov.name} ${prov.last_name1} ${prov.last_name2}`,
                        value: prov.id,
                      }))}
                      label='Selecciona un proveedor'
                    ></SelectWithLabel>
                  </>
                )}
              </div>
            ) : null}
            {[6, 7].includes(selected?.state) &&
            userSlug === 'administrador_cliente' &&
            !selected?.quiz &&
            !isSignage &&
            !isInHouse ? (
              <div className='service-detail__body__action'>
                <div className='service-detail__body__action--info'>
                  <img
                    src='/images/portal/icon_survey.svg'
                    alt=''
                    aria-hidden
                  />
                  <div className='service-detail__body__action--info__text'>
                    <h3>Ayúdanos a mejorar nuestros servicios</h3>
                    <p>Responde una breve encuesta en 1 minuto</p>
                  </div>
                </div>
                <Button
                  htmlType='button'
                  className='service-detail__body__action--button'
                  onClick={handleShowModal}
                >
                  Llenar encuesta
                </Button>
              </div>
            ) : null}
            <div className='service-detail__body__item'>
              <p className='service-detail__body__item__name title-sections'>
                Detalles de la solicitud
              </p>
              <div className='service-detail__body__item__card'>
                <div
                  className='service-detail__body__item__card__group'
                  style={{ width: '100%' }}
                >
                  <Card
                    className='service-detail__body__item__card__group__company flex-1'
                    style={{ border: '3px solid #D7DBF5' }}
                  >
                    <div className='service-detail__body__item__card__group__company__header'>
                      <IconPerson />
                      <p className='service-detail__body__item__card__group__company__header__title'>
                        Datos del contacto
                      </p>
                    </div>
                    <Divider className='service-detail__body__item__card__group__company__divider' />
                    <div className='service-detail__body__item__card__group__company__text'>
                      <p className='service-detail__body__item__card__group__company__text__name'>
                        Nombre:
                      </p>
                      <p className='service-detail__body__item__card__group__company__text__value'>
                        {selected?.contact || noData}
                      </p>
                    </div>
                    <div className='service-detail__body__item__card__group__company__text'>
                      <p className='service-detail__body__item__card__group__company__text__name'>
                        Celular:
                      </p>
                      <p className='service-detail__body__item__card__group__company__text__value'>
                        {selected?.number || noData}
                      </p>
                    </div>
                    <div className='service-detail__body__item__card__group__company__text'>
                      <p className='service-detail__body__item__card__group__company__text__name'>
                        Dirección:
                      </p>
                      <p className='service-detail__body__item__card__group__company__text__value'>
                        {selected?.address || noData}
                      </p>
                    </div>
                    <div className='service-detail__body__item__card__group__company__text'>
                      <p className='service-detail__body__item__card__group__company__text__name'>
                        Correo electrónico:
                      </p>
                      <p className='service-detail__body__item__card__group__company__text__value'>
                        {selected?.email || noData}
                      </p>
                    </div>
                    <div className='service-detail__body__item__card__group__company__text'>
                      <p className='service-detail__body__item__card__group__company__text__name'>
                        Cargo:
                      </p>
                      <p className='service-detail__body__item__card__group__company__text__value'>
                        {selected?.position || noData}
                      </p>
                    </div>
                    <div className='service-detail__body__item__card__group__company__text'>
                      <p className='service-detail__body__item__card__group__company__text__name'>
                        Fecha de solicitud:
                      </p>
                      <p className='service-detail__body__item__card__group__company__text__value'>
                        {selected?.createdAt
                          ? moment(selected?.createdAt).format('DD/MM/YYYY')
                          : noData}
                      </p>
                    </div>
                    {Number(selected?.requestService?.state) === 2 ? (
                      selected?.requestServiceItemsData?.length > 0 ? (
                        selected.requestServiceItemsData.map((itemData) =>
                          itemData.key === 'event_date' ? (
                            <div className='service-detail__body__item__card__group__company__text'>
                              <p className='service-detail__body__item__card__group__company__text__name'>
                                Fecha agendada:
                              </p>
                              <p className='service-detail__body__item__card__group__company__text__value'>
                                {itemData?.value
                                  ? moment
                                      .unix(itemData?.value)
                                      .format('DD/MM/YYYY')
                                  : noData}
                              </p>
                            </div>
                          ) : null,
                        )
                      ) : (
                        <div className='service-detail__body__item__card__group__company__text'>
                          <p className='service-detail__body__item__card__group__company__text__name'>
                            Fecha agendada:
                          </p>
                          <p className='service-detail__body__item__card__group__company__text__value'>
                            Pendiente
                          </p>
                        </div>
                      )
                    ) : null}
                  </Card>
                  <Card
                    className='service-detail__body__item__card__group__company contact flex-1'
                    style={{ border: '3px solid #D7DBF5' }}
                  >
                    <div className='service-detail__body__item__card__group__company__header'>
                      <IconManager />
                      <p className='service-detail__body__item__card__group__company__header__title'>
                        Contacto gestor RIMAC
                      </p>
                    </div>
                    <Divider className='service-detail__body__item__card__group__company__divider' />
                    <div className='service-detail__body__item__card__group__company__text'>
                      <p className='service-detail__body__item__card__group__company__text__name'>
                        Nombre:
                      </p>
                      <p className='service-detail__body__item__card__group__company__text__value'>
                        {manegeAccount ? manegeAccount : noData}
                      </p>
                    </div>
                    <div className='service-detail__body__item__card__group__company__text'>
                      <p className='service-detail__body__item__card__group__company__text__name'>
                        Celular:
                      </p>
                      <p className='service-detail__body__item__card__group__company__text__value'>
                        {selected?.requestService?.company?.companiesUsers
                          ?.length
                          ? selected?.requestService?.company?.companiesUsers[0]
                              .phone
                          : noData}
                      </p>
                    </div>
                    <div className='service-detail__body__item__card__group__company__text'>
                      <p className='service-detail__body__item__card__group__company__text__name'>
                        Correo electrónico:
                      </p>
                      <p className='service-detail__body__item__card__group__company__text__value'>
                        {selected?.requestService?.company?.companiesUsers
                          ?.length
                          ? selected?.requestService?.company?.companiesUsers[0]
                              .username
                          : noData}
                      </p>
                    </div>
                  </Card>
                </div>
              </div>
            </div>

            {['admin_rimac', 'gestor_de_cuenta'].includes(userSlug) &&
              selected.service.type === '6' &&
              selected.state > 1 && (
                <div className='service-detail__body__item__upload text-right'>
                  <Button
                    size='large'
                    type='primary'
                    onClick={() => setShowModalReport(true)}
                  >
                    Subir Informe Técnico
                  </Button>
                </div>
              )}

            {isInHouse &&
            selected?.requestServiceItemsData?.length &&
            selected?.requestServiceItemsData.find(
              (item) => item.key === 'courses',
            ) ? (
              selected?.requestServiceItemsData.find(
                (item) => item.key === 'courses',
              ).value !== '{}' ? (
                <div className='service-detail__body__item'>
                  <div className='service-detail__body__item__name flex justify-between title-sections'>
                    <span className='title-sections'>
                      Cursos de capacitación
                    </span>
                    {freeHours || freeHours === 0 ? (
                      <span>
                        {freeHours > 0 ? freeHours : 0}{' '}
                        {freeHours === 1 ? 'hr disponible' : 'hrs disponibles'}
                      </span>
                    ) : null}
                  </div>
                  <div className='service-detail__body__item__card'>
                    <TableInHouse
                      data={selected?.requestServiceItemsData}
                      dataResult={selected?.request_service_item_results || []}
                      setCourseSelected={setCourseSelected}
                    />
                  </div>
                </div>
              ) : (
                <div className='course-detail-info'>
                  <div className='course-header title-sections service-detail__body__item__name'>
                    <span className='title-sections'>
                      Cursos de capacitación
                    </span>
                    {freeHours || freeHours === 0 ? (
                      <span>
                        {freeHours > 0 ? freeHours : 0}{' '}
                        {freeHours === 1 ? 'hr disponible' : 'hrs disponibles'}
                      </span>
                    ) : null}
                  </div>

                  <div className='course-info'>
                    <div className='icon'>
                      <img src={IconNotData} alt='' aria-hidden />
                    </div>

                    <div className='text'>
                      <h3>Aún no tienes cursos registrados</h3>
                      <p>Registralos con tu proveedor</p>
                    </div>
                  </div>
                </div>
              )
            ) : null}

            <div className='service-detail__body__table'>
              {isSignage && (
                <Table
                  dataSource={signages}
                  columns={columns}
                  pagination={false}
                  locale={{ emptyText: 'No hay señaleticas' }}
                />
              )}
            </div>
          </>
        )}
        {['admin_rimac', 'gestor_de_cuenta'].includes(userSlug) &&
        ![2, 6, 7].includes(selected?.state) ? (
          <div
            className='mb-3'
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <DeriveButton
              style={{ justifySelf: 'flex-end' }}
              providerId={providerId}
              requestServiceItemId={selected?.id}
              currentProvider={selected?.provider_id}
              textButton={!selected?.derived ? 'Derivar' : 'Guardar cambios'}
            />
          </div>
        ) : null}
      </div>
      {showModal && (
        <ModalSurvey
          visible={showModal}
          onFinishQuiz={onFinishQuiz}
          onCancel={handleShowModal}
          setFinishSurvey={setFinishSurvey}
          requestServiceItemId={selected?.id}
        />
      )}

      {finishSurvey && (
        <ModalFinishSurvey
          visible={finishSurvey}
          onCancel={() => {
            setFinishSurvey(false);
          }}
        />
      )}

      {
        <ModalUploadTechnicalReport
          visible={showModalReport}
          setVisible={setShowModalReport}
          item={selected}
          doAddResult={handleUploadTechnicalInform}
        />
      }
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  loadListServicesItems: (data) => getMyListRequestServiceItems(dispatch, data),
  loadProviders: (parameters) => getActiveProviders(parameters),
  doAddResult: (data, item, pagination) =>
    addResult(dispatch, data, item.id, pagination),
});

const mapStateToProps = (state) => ({
  listRequestService: state.requestServices.listItems || [],
  pagination: state.requestServices.pagination || [],
  userSlug: state.auth.user?.rol?.slug || '',
});

export default connect(mapStateToProps, mapDispatchToProps)(Service);
