import {
  CUMULUS_UPDATE_FILTERS,
  CUMULUS_SET_MARKER,
  CUMULUS_SET_DRAW_LAYER,
  CUMULUS_SET_SELECTED_LEGEND,
} from '../actions/actionsType';

const initialState = {
  filters: {},
  marker: null,
  drawLayer: null,
  selectedLegend: '',
};

const cumulus = (state = initialState, action) => {
  switch (action.type) {
    case CUMULUS_UPDATE_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case CUMULUS_SET_MARKER:
      return {
        ...state,
        marker: action.payload,
      };
    case CUMULUS_SET_DRAW_LAYER:
      return {
        ...state,
        drawLayer: action.payload,
      };
    case CUMULUS_SET_SELECTED_LEGEND:
      return {
        ...state,
        selectedLegend: action.payload,
      };
    default:
      return state;
  }
};

export default cumulus;
