import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Popover, Radio } from 'antd';

import { checkPermission } from '../../../../lib/security';
import { useScreen } from '../../../../lib/screen';
import { renderIcon } from '../renderIcon';
import {
  MAP_SET_ACTIVE_UTILS_LAYER,
  CUMULUS_SET_SELECTED_LEGEND,
} from '../../../../redux/actions/actionsType';

import { IconDots } from '../../../Icons/iconText';
import { utils } from '../../../SideMenu/data';
import { RideButton } from '@rimac-seguros/ride-system-components';

const DrawerUtils = ({ controlMapDrawerVisible }) => {
  const dispatch = useDispatch();
  const { isMobileScreen } = useScreen();
  const selectedLegend = useSelector((state) => state.cumulus.selectedLegend);
  const activeUtils = useSelector((state) => state.maps.activeUtilsLayer);
  const user = useSelector((state) => state.auth.user);

  const handleSelectUtil = (layer) => {
    const foundUtil = activeUtils.findIndex(
      (activeUtil) => activeUtil.key === layer.key,
    );
    if (foundUtil === -1) {
      dispatch({
        type: MAP_SET_ACTIVE_UTILS_LAYER,
        payload: [...activeUtils, layer],
      });
    } else {
      const copyActiveUtils = structuredClone(activeUtils);
      copyActiveUtils.splice(foundUtil, 1);
      dispatch({ type: MAP_SET_ACTIVE_UTILS_LAYER, payload: copyActiveUtils });
    }

    if (isMobileScreen) {
      controlMapDrawerVisible(false);
    }
  };

  const handleChangeRadio = (e) =>
    dispatch({ type: CUMULUS_SET_SELECTED_LEGEND, payload: e.target.value });

  const handleClearLegend = () =>
    dispatch({ type: CUMULUS_SET_SELECTED_LEGEND, payload: '' });

  return (
    <div className='drawer-map-layers'>
      <div className='drawer-map-layers__list'>
        {utils.map((util) => {
          if (util.key === 'cumulo' && !checkPermission(user, 'view_cumulus')) {
            return null;
          }

          return (
            <div
              key={util.key}
              className={`drawer-map-layers__list__item ${
                activeUtils.some((utilSelect) => utilSelect.key === util.key)
                  ? 'active'
                  : ''
              }`}
              onClick={() => handleSelectUtil(util)}
            >
              <div className='drawer-map-layers__list__item__name'>
                <div
                  onClick={(e) => e.stopPropagation()}
                  className='flex items-center gap-2'
                >
                  {util.key === 'cumulo' && (
                    <Popover
                      placement='topRight'
                      trigger='click'
                      content={
                        <div className='radios-popover'>
                          <Radio.Group
                            className='flex flex-col gap-2'
                            onChange={handleChangeRadio}
                            value={selectedLegend}
                          >
                            <Radio value='inundacion'>
                              Susceptibilidad de Inundación
                            </Radio>
                            <Radio value='movimiento'>
                              Susceptibilidad de Mov. en Masa
                            </Radio>
                          </Radio.Group>
                          <RideButton
                            className='mt-2'
                            variant='fill'
                            size='small'
                            onClick={handleClearLegend}
                            text='Limpiar'
                          />
                        </div>
                      }
                      className='popover-actions'
                    >
                      <Button>
                        <IconDots />
                      </Button>
                    </Popover>
                  )}
                  {renderIcon(util.key)}
                </div>
                <div className='drawer-map-layers__list__item__name__text'>
                  {util.name}
                </div>
              </div>
              <div className='drawer-map-layers__list__item__action'>
                <Checkbox
                  className='checkbox-layer'
                  checked={activeUtils.some(
                    (utilSelect) => utilSelect.key === util.key,
                  )}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DrawerUtils;
