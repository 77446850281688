import React, { useEffect, useState, useCallback } from 'react';

import { Form, Select } from 'antd';

import { SelectWithLabel } from '../../../../../common/InputWithLabel';
import { getCompanyAdminList } from '../../../../../services/company';
import { filterSelectOptions } from '../../../../../lib/utils';

const PrincipalAdmin = ({ companyId, principalAdminId, name, rules }) => {
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(false);

  const defaultSearch = useCallback(() => {
    getCompanyAdminList(companyId, '', principalAdminId)
      .then(setAdmins)
      .finally(() => setLoading(false));
  }, [companyId, principalAdminId]);

  const handleSearch = (value = '') => {
    setLoading(true);
    value = value.toLocaleLowerCase();
    getCompanyAdminList(companyId, value, principalAdminId)
      .then(setAdmins)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (!companyId) {
      return;
    }
    setLoading(true);
    defaultSearch();
  }, [companyId, principalAdminId, defaultSearch]);

  return (
    <Form.Item name={name} initialValue={principalAdminId || ''} rules={rules}>
      <SelectWithLabel
        label='Administrador empresa principal'
        loading={loading}
        onSearch={handleSearch}
        filterOption={filterSelectOptions}
        optional={true}
      >
        {admins.map((admin) => (
          <Select.Option value={admin.id} key={`admin-${admin.id}`}>
            {`${admin.name} ${admin.last_name1} ${admin.last_name2}`}
          </Select.Option>
        ))}
      </SelectWithLabel>
    </Form.Item>
  );
};

export default PrincipalAdmin;
