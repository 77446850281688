import React from 'react';

const Circle = (props) => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M37.5 28.75C37.5 31.2688 35.5516 33.5563 32.0141 35.1891C28.7844 36.6797 24.5172 37.5 20 37.5C15.4828 37.5 11.2156 36.6797 7.98594 35.1891C4.44844 33.5563 2.5 31.2703 2.5 28.75C2.5 26.2297 4.44844 23.9438 7.98594 22.311C9.4375 21.6407 11.1 21.1078 12.9 20.7219L14.7437 22.9141C12.6141 23.2641 10.6687 23.8266 9.03438 24.5813C6.47187 25.7641 5.00156 27.2828 5.00156 28.75C5.00156 31.7063 11.1625 35 20.0016 35C24.1656 35 28.0594 34.261 30.9688 32.9188C33.5312 31.736 35.0016 30.2172 35.0016 28.75C35.0016 26.425 31.1875 23.8906 25.2578 22.9141L27.1016 20.7219C28.9016 21.1078 30.5641 21.6407 32.0156 22.311C35.5531 23.9438 37.5016 26.2297 37.5016 28.75H37.5ZM20 29.1625L18.5656 27.4563L17.6656 26.3875C15.725 26.7969 14.375 27.7 14.375 28.75C14.375 30.1844 16.8938 31.3469 20 31.3469C23.1063 31.3469 25.625 30.1844 25.625 28.75C25.625 27.7 24.275 26.7969 22.3344 26.3875L21.4344 27.4563L20 29.1625Z'
        fill='url(#paint0_linear_827_16662)'
      />
      <path
        d='M20 2.5C15.125 2.5 11.1719 6.45625 11.1719 11.3375C11.1719 14.1906 12.1781 16.9531 14.0141 19.1359L20 26.25L25.9859 19.1359C27.8219 16.9531 28.8281 14.1922 28.8281 11.3375C28.8281 6.45781 24.8766 2.5 20 2.5ZM20 14.6516C18.1719 14.6516 16.6891 13.1672 16.6891 11.3375C16.6891 9.50781 18.1719 8.02343 20 8.02343C21.8281 8.02343 23.3109 9.50781 23.3109 11.3375C23.3109 13.1672 21.8281 14.6516 20 14.6516Z'
        fill='url(#paint1_linear_827_16662)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_827_16662'
          x1='2.5'
          y1='20.7219'
          x2='7.43901'
          y2='43.9047'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.272135' stopColor='#34263B' />
          <stop offset='0.658079' stopColor='#13172C' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_827_16662'
          x1='11.6338'
          y1='4.36395'
          x2='30.3724'
          y2='18.2946'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.35' stopColor='#F7052D' />
          <stop offset='0.85' stopColor='#CA5AFA' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Circle;
