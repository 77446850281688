import { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Divider,
  Input,
  Modal,
  Switch,
  notification,
  Select,
  Typography,
} from 'antd';
import {
  RideButton,
  RideGlDeleteOutline,
} from '@rimac-seguros/ride-system-components';

import { useScreen } from '../../../../lib/screen';
import { getUserList } from '../../../../services/users';
import { getRols } from '../../../../services/rols';
import { debounce } from '../../../../lib/utils';
import { assignModules } from '../../../../services/modules';

import TableCommon from '../../../../common/Table';
import SelectCompany from '../../../client/common/SelectCompany';

const { Search } = Input;
const { Title } = Typography;

const COLUMNS = [
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
    width: 200,
    render: (_, record) => (
      <span>{`${record?.name} ${record?.last_name1}`}</span>
    ),
  },
  {
    title: 'Empresa',
    dataIndex: 'company',
    key: 'company',
    width: 250,
    render: (company) => <span>{company?.name}</span>,
  },
  {
    title: 'Rol',
    dataIndex: 'rol',
    key: 'rol',
    width: 220,
    render: (rol) => <span>{rol?.name}</span>,
  },
  {
    title: '¿Está habilitado?',
    dataIndex: 'enabled',
    key: 'enabled',
    width: 150,
    render: (_, record) => {
      return (
        <div className='flex justify-between' style={{ maxWidth: 100 }}>
          <span>{record.isEnabled ? 'Si' : 'No'}</span>
          <Switch
            defaultChecked={record.isEnabled}
            onChange={record.onChange}
          />
        </div>
      );
    },
  },
];

const ModalUserList = ({ visible, handleClose, name, slug, moduleId }) => {
  const dispatch = useDispatch();
  const { isMobileScreen } = useScreen();
  const { users, isLoadingUser, pagination, userAuthenticated, rols } =
    useSelector((state) => ({
      ...state.users,
      userAuthenticated: state.auth.user,
      rols: state.rols.rols,
    }));
  const [filters, setFilters] = useState({
    company: null,
    role: null,
  });
  const [query, setQuery] = useState('');
  const [permissionsIds, setPermissionsIds] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const hasModule = useCallback(
    (user) => {
      if (!user?.modules || user?.length === 0) {
        return false;
      }
      const foundModule = user?.modules.find((module) => module?.slug === slug);

      return Boolean(foundModule);
    },
    [slug],
  );

  const debouncedHandleChangeSearch = useCallback(
    debounce((txt) => {
      handleGetUsersList({
        limit: 5,
        page: 1,
        query: txt,
        filters,
      });
    }, 800),
    [filters],
  );

  const handleChangeSearch = (e) => {
    const txt = e.target.value.trim();
    debouncedHandleChangeSearch(txt);
    setQuery(txt);
  };

  const handleGetUsersList = (data) => {
    getUserList(dispatch, {
      ...data,
      filters: {
        ...(data?.filters ?? {}),
        states: [1],
      },
    });
  };

  const handleSave = async () => {
    setIsSubmitting(true);
    try {
      const usersActives = [];
      const usersInactive = [];

      if (typeof permissionsIds === 'object' && permissionsIds !== null) {
        Object.entries(permissionsIds).forEach(([key, value]) => {
          if (value) {
            usersActives.push(key);
          } else {
            usersInactive.push(key);
          }
        });
      }

      let updated;

      if (moduleId && (usersActives.length > 0 || usersInactive.length > 0)) {
        updated = await assignModules(moduleId, usersActives, usersInactive);
      }

      if (updated) {
        let messageDetails = [];
        if (usersActives.length > 0) {
          messageDetails.push(`Usuarios habilitados: ${usersActives.length}`);
        }
        if (usersInactive.length > 0) {
          messageDetails.push(
            `Usuarios deshabilitados: ${usersInactive.length}`,
          );
        }

        const messageDetailStr =
          messageDetails.join(', ') || 'No hubo cambios en los usuarios.';
        const finalMessage = `¡Modulo de ${name} actualizado!`;

        notification.success({
          description: messageDetailStr,
          message: finalMessage,
        });
      } else {
        throw new Error('Failed to update');
      }
    } catch (error) {
      notification.warning({
        description: 'Ha ocurrido un error.',
        message: `¡Modulo de ${name}!`,
      });
    } finally {
      setIsSubmitting(false);
      handleClose();
    }
  };

  useEffect(() => {
    handleGetUsersList({
      limit: 5,
      page: 1,
      query,
      filters,
    });
  }, [filters]);

  useEffect(() => {
    getRols(dispatch, userAuthenticated);
  }, [dispatch, userAuthenticated]);

  return (
    <Modal
      open={visible}
      title=''
      closable={true}
      centered={true}
      onCancel={handleClose}
      width={isMobileScreen ? '100%' : '80%'}
      onOk={handleSave}
      okText='Guardar'
      okButtonProps={{ loading: isSubmitting }}
    >
      <div style={{ paddingBottom: '0px' }}>
        <Title level={2}>Habilitador del módulo de {name}</Title>
        <Divider className='mt-2 mb-4' />
        <div className='flex items-center flex-wrap gap-2 mb-2'>
          <SelectCompany
            companySelected={filters['company']}
            onSelectCompany={(company) => {
              setFilters((prev) => ({
                ...prev,
                company,
              }));
            }}
            label='Empresa'
          />
          <Select
            value={filters['role']}
            style={{ width: 200 }}
            onChange={(role) => {
              setFilters((prev) => ({
                ...prev,
                role,
              }));
            }}
            placeholder='Rol'
            options={rols.map((rol) => ({
              label: rol.name,
              value: rol.id,
            }))}
          />
          <Search
            placeholder='Palabra clave'
            style={{ width: 200 }}
            onChange={handleChangeSearch}
          />
          <RideButton
            glyph={<RideGlDeleteOutline />}
            glyphPosition='left'
            text='Borrar filtros'
            variant='text-primary'
            onClick={() => {
              setFilters({
                company: null,
                role: null,
              });
              setQuery('');
            }}
          />
        </div>
        <TableCommon
          dataSource={users.map((user) => ({
            ...user,
            isEnabled: permissionsIds.hasOwnProperty(user.id)
              ? permissionsIds[user.id]
              : hasModule(user),
            onChange: (e) => {
              setPermissionsIds((prev) => ({ ...prev, [user.id]: e }));
            },
          }))}
          columns={COLUMNS}
          loading={isLoadingUser}
          locale={{ emptyText: 'No se han agregado modulos.' }}
          pagination={{
            ...pagination,
            onChange: (page) => {
              handleGetUsersList({
                limit: 5,
                page,
                query,
                filters,
              });
            },
          }}
        />
      </div>
    </Modal>
  );
};

export default ModalUserList;
