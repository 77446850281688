import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, Progress, Typography } from 'antd';
import { UpOutlined, DownOutlined } from '@ant-design/icons';

import { capitalize } from '../../../../lib/utils';
import { reportMonitoring } from '../../../../services/dashboard';
import SkeletonContent from '../../../../common/Skeleton';
import UserIcon from './UserIcon';

const { Title } = Typography;

const sum = (arr) =>
  arr.reduce((acc, num) => {
    acc += num;
    return acc;
  }, 0);

const CompanyUserBoard = () => {
  const { user } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(true);
  const [dataMonitoring, setDataMonitoring] = useState(false);
  const [itemToShow, setItemToShow] = useState();

  useEffect(() => {
    setIsLoading(true);
    reportMonitoring()
      .then((res) => {
        setDataMonitoring(res);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const totalMonitoring =
    (dataMonitoring?.newsletter?.total || 0) + dataMonitoring?.alerts?.total;

  return (
    <div className='dashboard_body dashboard_body--company-user'>
      <div className='flex flex-wrap flex-justify-justify-between'>
        <div className='flex items-center gap-2 my-2'>
          <UserIcon />
          <Title
            style={{ marginBottom: 0 }}
            level={4}
          >{`${user.name} ${user.last_name1} ${user.last_name2}`}</Title>
        </div>
      </div>
      <div className='section-services'>
        <div className='subtitle-1'>Mi información</div>
        <div className='section-services-list'>
          {!isLoading && dataMonitoring ? (
            <Card className='flex-1'>
              <div className='subtitle-2'>Monitoreo</div>
              <div className='chart'>
                <div className='chart-grafic donut'>
                  <div className='progress-title'>
                    <span className='quantity'>{totalMonitoring}</span>
                    <span className='label'>Alertas y Boletines</span>
                  </div>
                  <Progress
                    className='monitoreo-graphic'
                    percent={
                      dataMonitoring.newsletter.moreFrequently.percent +
                      dataMonitoring.newsletter.moreFrequently2.percent
                    }
                    type='circle'
                    format={() => ''}
                    size='small'
                    trailWidth={8}
                    steps={2}
                  />
                </div>
                <div className='chart-legend'>
                  <div className='chart-legend-item'>
                    <span className='indicator-content'>
                      <span className='indicator-color-2'></span>
                      {capitalize(
                        dataMonitoring.newsletter?.moreFrequently?.name.toLowerCase(),
                      )}
                    </span>
                    <span className='indicator-quantity'>
                      {dataMonitoring.newsletter?.moreFrequently?.percent}%
                    </span>
                  </div>
                  <div className='chart-legend-item'>
                    <span className='indicator-content'>
                      <span className='indicator-color-1'></span>
                      {capitalize(
                        dataMonitoring.newsletter?.moreFrequently2?.name.toLowerCase(),
                      )}
                    </span>
                    <span className='indicator-quantity'>
                      {dataMonitoring.newsletter.moreFrequently2.percent}%
                    </span>
                  </div>
                  <div className='chart-legend-item'>
                    <span className='indicator-content'>
                      <span className='indicator-color-3'></span>
                      Otros
                    </span>
                    <span className='indicator-quantity'>
                      {totalMonitoring
                        ? 100 -
                          dataMonitoring.newsletter.moreFrequently.percent -
                          dataMonitoring.newsletter.moreFrequently2.percent
                        : 0}
                      %
                    </span>
                  </div>
                </div>
              </div>
            </Card>
          ) : (
            isLoading && <SkeletonContent type='card' />
          )}
          {!isLoading && dataMonitoring ? (
            <Card className='flex-1'>
              <div className='subtitle-2'>Cursos</div>
              <div className='chart'>
                <div className='chart-grafic donut'>
                  <div className='progress-title'>
                    <span className='quantity'>
                      {sum(Object.values(dataMonitoring.courses))}
                    </span>
                    <span className='label'>Cursos</span>
                  </div>
                  <Progress
                    percent={Math.trunc(
                      ((dataMonitoring.courses.completed +
                        dataMonitoring.courses.pending) /
                        sum(Object.values(dataMonitoring.courses))) *
                        100,
                    )}
                    successPercent={Math.trunc(
                      (dataMonitoring.courses.completed /
                        sum(Object.values(dataMonitoring.courses))) *
                        100,
                    )}
                    type='circle'
                    format={() => ''}
                    strokeWidth={8}
                  />
                </div>
                <div className='chart-legend'>
                  <div className='chart-legend-item'>
                    <span className='indicator-content'>
                      <span className='indicator-color-2'></span>
                      Finalizados
                    </span>
                    <span className='indicator-quantity'>
                      {dataMonitoring.courses.completed}
                    </span>
                  </div>
                  <div className='chart-legend-item'>
                    <span className='indicator-content'>
                      <span className='indicator-color-1'></span>
                      Iniciados
                    </span>
                    <span className='indicator-quantity'>
                      {dataMonitoring.courses.pending}
                    </span>
                  </div>
                  <div className='chart-legend-item hide'>
                    <span className='indicator-content'>
                      <span className='indicator-color-3'></span>
                      Disponibles
                    </span>
                    <span className='indicator-quantity'>
                      {dataMonitoring.courses.available}
                    </span>
                  </div>
                </div>
              </div>
            </Card>
          ) : (
            isLoading && <SkeletonContent type='card' />
          )}
        </div>
      </div>
      <div className='section-activities'>
        <div className='subtitle-1'>Mi Actividad</div>
        <div className='section-activities-list'>
          {!isLoading && dataMonitoring ? (
            <Card
              className={`card-activity ${
                itemToShow === 'alerts' ? '' : 'card-activity--open'
              } `}
            >
              <div
                className='subtitle-3 flex flex-wrap flex-justify-justify-between'
                onClick={() =>
                  setItemToShow((item) => (item === 'alerts' ? '' : 'alerts'))
                }
              >
                {itemToShow === 'alerts' ? <UpOutlined /> : <DownOutlined />}
                <span className='subtitle-icon'>
                  <img src='/images/portal/icon_alarm.svg' alt='Boletines' />
                </span>
                <span className='subtitle-text'>Alertas y Boletines</span>
              </div>
              <div className='card-activity-list'>
                <div className='subtitle-4'>Alertas Recibidas</div>
                <p className='text-2'>{dataMonitoring.alerts.total}</p>
                <div className='subtitle-4'>Boletines Recibidos</div>
                <p className='text-2'>{dataMonitoring.newsletter.total}</p>
                <div className='subtitle-4'>Último boletín vigente</div>
                <div className='text-2'>
                  {dataMonitoring.newsletterCurrent || 'Ninguno vigente'}
                </div>
              </div>
            </Card>
          ) : (
            isLoading && <SkeletonContent type='card' />
          )}
          {!isLoading && dataMonitoring ? (
            <Card
              className={`card-activity ${
                itemToShow === 'interactions' ? '' : 'card-activity--open'
              } `}
            >
              <div
                className='subtitle-3 flex flex-wrap flex-justify-justify-between'
                onClick={() =>
                  setItemToShow((item) =>
                    item === 'interactions' ? '' : 'interactions',
                  )
                }
              >
                {itemToShow === 'interactions' ? (
                  <UpOutlined />
                ) : (
                  <DownOutlined />
                )}
                <span className='subtitle-icon'>
                  <img
                    src='/images/portal/icon_person-cuorse.svg'
                    alt='Interacciones'
                  />
                </span>
                <span className='subtitle-text'>Interacciones</span>
              </div>
              <div className='card-activity-list'>
                <div className='subtitle-4'>Última vez logueado</div>
                <p className='text-2'>{dataMonitoring.lastLogin}</p>
                <div className='subtitle-4'>Veces logueado por mes</div>
                <div className='text-2'>{dataMonitoring.visitsMonth}</div>
                <div className='subtitle-4'>Tiempo promedio de uso</div>
                <div className='text-2'>{dataMonitoring.avgTimeOnPage}</div>
              </div>
            </Card>
          ) : (
            isLoading && <SkeletonContent type='card' />
          )}
        </div>
      </div>
    </div>
  );
};
export default CompanyUserBoard;
