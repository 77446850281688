import React, { useState } from 'react';
import { runUnsubscribe } from '../../services/unsubscribe';
import { Button, Form, Input, Spin, Radio, message } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = (
  <LoadingOutlined
    style={{ fontSize: 56, width: 100, margin: '30px auto' }}
    spin
  />
);

const options = [
  {
    value: 'a',
    text: 'Recibo demasiados correos de RIMAC',
  },
  {
    value: 'b',
    text: 'La información que recibo no aporta valor.',
  },
  {
    value: 'c',
    text: 'La información no está relacionada al giro de mi empresa.',
  },
  {
    value: 'd',
    text: 'La información no está relacionada a mis ubicaciones de interés.',
  },
  {
    value: 'e',
    text: 'Otros (especifique)',
  },
];

const Index = ({ match }) => {
  const [form] = Form.useForm();
  const { validateFields } = form;
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(false);
  const [option, setOption] = useState('');

  const getReason = (values) => {
    if (values['radio-group'] === 'e') {
      return values['text-area'];
    }

    return options.find((option) => option.value === values['radio-group'])
      .text;
  };

  const handleUnsubscribe = async (code, reason) => {
    try {
      const resp = await runUnsubscribe(code, reason);
      if (resp.data.success) {
        setEmail(resp.data.email);
      } else {
        message.error('Ha ocurrido un error');
      }
    } catch (e) {
      message.error('Ha ocurrido un error');
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    validateFields(async (err, values) => {
      if (!err) {
        const reason = getReason(values);
        setLoading(true);
        if (match.params.code) {
          await handleUnsubscribe(match.params.code, reason);
          setLoading(false);
        }
      }
    });
  };

  const renderLoading = () => (
    <Spin
      indicator={antIcon}
      style={{ width: 100, margin: '20px auto', display: 'block' }}
    />
  );

  const renderEmail = () => (
    <div className='register_header'>
      <h1 style={{ color: '#EF3340' }}>Suscripción eliminada.</h1>
      <p>{`Se ha eliminado la suscripción de boletines para el correo ${email} correctamente.`}</p>
    </div>
  );

  const renderForm = () => (
    <div className='box'>
      <div className='unsubscribe_header'>
        <img
          className='unsubscribe_header_logo'
          src={`${process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN}images/rimac.png`}
          alt='Rimac'
        />
      </div>
      <div className='unsubscribe_content'>
        <h2>Estimado cliente:</h2>

        <p className='unsubscribe_content_info'>
          Recuerda que antes de anular la suscripción al servicio de
          notificaciones del Centro de Monitoreo y Prevención, puedes
          configurarlas en la plataforma web y activar solo las que sean de tu
          interés, por lugar o tipo de evento. Si aun así deseas anular la
          suscripción, por favor indícanos el motivo por el que lo haces:
        </p>
        <Form
          form={form}
          className='unsubscribe_content_form'
          onSubmit={handleSubmit}
        >
          <Form.Item
            name='radio-group'
            rules={[
              {
                required: !Boolean(option),
                message: 'Debes seleccionar una opción',
              },
            ]}
            initialValue=''
          >
            <Radio.Group onChange={onChangeOption}>
              {options.map((item) => (
                <Radio
                  key={item.value}
                  name='option'
                  style={radioStyle}
                  value={item.value}
                >
                  {item.text}
                </Radio>
              ))}
            </Radio.Group>

            <div className='imagen-unsubscribe'>
              <img
                alt='Rimac'
                border='0'
                hspace='0'
                vspace='0'
                width='220'
                height='220'
                src='/images/1619222739_1618263911lluvia.png'
                className='beta-editable'
              />
            </div>
          </Form.Item>
          {option === 'e' && (
            <Form.Item
              label='Corta descripción'
              name='text-area'
              rules={[
                {
                  required: option === 'e',
                  message: 'La descripción es requerida',
                },
              ]}
            >
              <Input.TextArea name='description' rows={4} />
            </Form.Item>
          )}
          <p className='unsubscribe_footer'>
            Si estás seguro que deseas dejar de recibir información de RIMAC,
            dale
            <Button
              htmlType='submit'
              type='link'
              style={{
                fontWeight: 'bold',
                color: 'rgb(239, 51, 64)',
                textDecoration: 'underline',
              }}
            >
              click aquí.
            </Button>
          </p>
        </Form>
      </div>
    </div>
  );

  const radioStyle = {
    display: 'flex',
    height: 'auto',
    lineHeight: '24px',
    fontSize: '18px',
    marginTop: '10px',
    alignItems: 'start',
  };

  const onChangeOption = (value) => {
    setOption(value.target.value);
  };

  let content;
  if (loading) {
    content = renderLoading();
  } else if (email) {
    content = renderEmail();
  } else {
    content = renderForm();
  }

  return <div className='unsubscribe'>{content}</div>;
};

export default Index;
