import React, { useEffect } from 'react';
import { List, Skeleton, Typography } from 'antd';
import { connect } from 'react-redux';

import {
  getExplorations,
  processExplorations,
} from '../../../../services/exploration';
import ListItem from '../ListItem';
import Topbar from '../../../../common/ui/Topbar';
import { RideButton } from '@rimac-seguros/ride-system-components';

const { Title } = Typography;

const Twitter = ({ list, provider, getExplorations, processExplorations }) => {
  list = list.map((item) => {
    let text = Buffer.from(item.description, 'base64').toString('utf8');
    let site_name = Buffer.from(item.site_name, 'base64').toString('utf8');

    return {
      ...item,
      site_name,
      description: text?.replace(/(#|@)(.+?)(?=[\s.,:,]|$)/g, (hashtag) => {
        return `<span style="color: #00acee;">${hashtag}</span>`;
      }),
    };
  });

  useEffect(() => {
    getExplorations();
  }, [getExplorations]);

  return (
    <div id='checkSize' className='h100vh dashboard-UI'>
      <Topbar />
      <div className='d_content service-prevention'>
        <div className='service-header mb-4'>
          <Title className='header-container'>Noticias Twitter</Title>
          <RideButton
            onClick={processExplorations}
            type='fill'
            size='medium'
            text='Actualizar'
          />
        </div>
        <div className='list exploration'>
          <div className='list_content'>
            {provider !== 'twitter' ? (
              <Skeleton active />
            ) : (
              <List
                dataSource={list}
                renderItem={(item) => <ListItem {...item} />}
                pagination={true}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getExplorations: () => getExplorations(dispatch, 'twitter'),
  processExplorations: () => processExplorations(dispatch, 'twitter'),
});

const mapStateToProps = (state) => ({
  list: state.exploration.list,
  provider: state.exploration.provider,
});

export default connect(mapStateToProps, mapDispatchToProps)(Twitter);
