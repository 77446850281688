import { useNavigate } from 'react-router-dom';
import { Button, Typography } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { RideButton } from '@rimac-seguros/ride-system-components';

import IconHome from '../../../admin/Dashboard/ProfileCompany/ui/components/icons/IconHome';
import IconArrow from '../../../admin/Dashboard/ProfileCompany/ui/components/icons/IconArrow';

const { Title } = Typography;

const BackButton = ({ onClick }) => (
  <div className='back' onClick={onClick}>
    <ArrowLeftOutlined />
    <span>Volver</span>
  </div>
);

const Breadcrumb = ({ onClick, renderItem, titleSection }) => (
  <div className='profile-company__header--nav'>
    <IconHome /> <IconArrow />{' '}
    <span style={{ cursor: 'pointer' }} onClick={onClick}>
      Configuraciones
    </span>{' '}
    <IconArrow />{' '}
    <span className='disabled'>{renderItem?.titleSection || titleSection}</span>
  </div>
);

const MainTitle = ({
  widthMainButton,
  titleSection = '',
  renderItem = {},
  isMobileScreen = '',
  titleButton = '',
  buttonAction,
}) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/configuracion');
  };

  return (
    <>
      <div className='profile-company__header'>
        {isMobileScreen ? (
          <BackButton onClick={handleBackClick} />
        ) : (
          <Breadcrumb
            history={history}
            onClick={handleBackClick}
            titleSection={titleSection}
          />
        )}
      </div>

      {buttonAction && (
        <div
          className='mb-4'
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Title className='header-container'>
            {renderItem?.titleSection || titleSection}
          </Title>
          {!isMobileScreen ? (
            <RideButton
              onClick={buttonAction}
              text={renderItem?.buttonText}
              size='large'
            />
          ) : (
            renderItem && (
              <div
                className={'fixed-bottom p-3 full-width'}
                style={{ background: '#EDEFFC' }}
              >
                <span
                  className='full-width absolute'
                  style={{
                    height: 12,
                    top: -12,
                    left: 0,
                    background:
                      'linear-gradient(to bottom, rgba(255,0,0,0), #EDEFFC)',
                  }}
                ></span>
                <Button
                  className='full-width mb-2'
                  onClick={buttonAction}
                  type='primary'
                  size='large'
                >
                  {renderItem?.buttonText || titleButton}
                </Button>
              </div>
            )
          )}
        </div>
      )}
    </>
  );
};

export default MainTitle;
