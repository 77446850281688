import {
  MY_LAYERS_SET_LIST,
  MY_LAYERS_IS_LOADING,
  MY_LAYERS_SET_ACTIVE_LAYERS,
  MY_LAYERS_IS_LOADING_CREATE,
  MY_LAYERS_IS_DRAW_MODE,
  MY_LAYERS_SET_DRAWS,
  MY_LAYERS_SELECT_FEATURE,
  MY_LAYERS_REMOVE_SELECTED_FEATURE,
  MY_LAYERS_SELECT_POLYGON_LAYER,
} from '../actions/actionsType';

const initialState = {
  list: [],
  isLoading: false,
  activeLayers: [],
  isLoadingCreate: false,
  drawMode: false,
  draws: [],
  selectedFeature: null,
};

const myLayers = (state, action) => {
  if (!state) state = initialState;

  switch (action.type) {
    case MY_LAYERS_SET_LIST:
      return {
        ...state,
        list: action.payload,
      };
    case MY_LAYERS_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case MY_LAYERS_SET_ACTIVE_LAYERS:
      return {
        ...state,
        activeLayers: action.payload,
      };
    case MY_LAYERS_IS_LOADING_CREATE:
      return {
        ...state,
        isLoadingCreate: action.payload,
      };
    case MY_LAYERS_IS_DRAW_MODE:
      return {
        ...state,
        drawMode: action.payload,
      };
    case MY_LAYERS_SET_DRAWS:
      return {
        ...state,
        draws: action.payload,
      };
    case MY_LAYERS_SELECT_FEATURE:
      return {
        ...state,
        selectedFeature: action.payload,
      };
    case MY_LAYERS_REMOVE_SELECTED_FEATURE:
      return {
        ...state,
        selectedFeature: null,
      };
    case MY_LAYERS_SELECT_POLYGON_LAYER:
      return {
        ...state,
        layerPolygon: action.payload,
      };
    default:
      return state;
  }
};

export default myLayers;
