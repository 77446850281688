import { buffer, pointsWithinPolygon } from '@turf/turf';

export const getRoutePoints = (routeGeoJSON) => {
  let routePoints = [];

  if (routeGeoJSON.type === 'FeatureCollection') {
    routeGeoJSON.features = routeGeoJSON.features.filter((feature) => {
      const { type, coordinates } = feature.geometry;

      if (type === 'Point') {
        const [lng, lat] = coordinates;
        routePoints.push({ lat, lng });
        return false;
      }

      return type === 'LineString';
    });
  }

  return routePoints;
};

export const getIntersectionPoints = async (
  geologicalPoints,
  routeGeoJSON,
  geologicalRadius,
) => {
  const pointsGeoJSON = {
    type: 'FeatureCollection',
    features: geologicalPoints,
  };

  const bufferRoute = buffer(routeGeoJSON, Number(geologicalRadius) || 5, {
    units: 'meters',
  });
  const crossPointsGeoJSON = await pointsWithinPolygon(
    pointsGeoJSON,
    bufferRoute,
  );

  return crossPointsGeoJSON.features;
};
