import React, { useEffect, useState, useRef } from 'react';
import { GeoJSON, TileLayer, useMap } from 'react-leaflet';
import { getWeatherForecastLayers } from '../../../../services/parameter';
import openNotification from '../../../../common/Notification';
import { useDispatch } from 'react-redux';
import { MAP_CHANGE_BLOCKED_CLICK } from '../../../../redux/actions/actionsType';
import L from 'leaflet';

const LayerWeatherForecast = ({ opacity, colors, type }) => {
  const dispatch = useDispatch();
  const map = useMap();
  const [weatherForecastUrl, setWeatherForecastUrl] = useState(null);
  const [geoJSONData, setGeoJSONData] = useState(null);
  const markerRef = useRef(null);

  const changeIsBlockedClick = (blocked) =>
    dispatch({ type: MAP_CHANGE_BLOCKED_CLICK, payload: blocked });

  const getColor = (feature) => {
    switch (feature.properties.escenario) {
      case 'Inferior':
        return colors[0];
      case 'Normal - Inferior':
        return colors[1];
      case 'Normal':
        return colors[2];
      case 'Normal - Superior':
        return colors[3];
      case 'Superior':
        return colors[4];
      case 'Periodo Seco':
        return colors[5];
      default:
        return colors[0];
    }
  };

  const style = (feature) => {
    return {
      fillColor: getColor(feature),
      weight: 1,
      opacity: 1,
      color: 'black',
      fillOpacity: 0.7,
    };
  };

  const getCoordinates = (sector) => {
    const sectorCoordinates = {
      'SELVA NORTE ALTA': [-5.812757, -77.365723],
      'SELVA NORTE BAJA': [-3.995781, -75.476074],
      'SELVA CENTRAL': [-9.968851, -73.959961],
      'SELVA SUR': [-12.275599, -70.664063],
      'SIERRA NORTE ORIENTAL': [-6.511815, -78.486328],
      'SIERRA NORTE OCCIDENTAL': [-7.253496, -78.859863],
      'SIERRA SUR ORIENTAL': [-14.136576, -71.542969],
      'SIERRA SUR OCCIDENTAL': [-15.982454, -71.674805],
      'SIERRA CENTRAL ORIENTAL': [-11.426187, -75.695801],
      'SIERRA CENTRAL OCCIDENTAL': [-12.087667, -76.223145],
      'COSTA NORTE': [-5.200365, -80.90332],
      'COSTA CENTRO': [-11.943981, -77.036496],
      'COSTA SUR': [-15.474857, -74.707031],
    };

    return sectorCoordinates[sector] || [0, 0];
  };

  useEffect(() => {
    const fetchDataLayer = async () => {
      const res = await getWeatherForecastLayers();
      if (res.data.payload?.some((layer) => layer.data === type)) {

     
        const weatherForecastLayer = res.data.payload.filter(
          (layer) => layer.data === type,
        ).sort((a, b) => b.id - a.id)?.[0];

        setWeatherForecastUrl(
          process.env.REACT_APP_WEB_PATH_S3_NEW + weatherForecastLayer?.url,
        );
      }
    };
    fetchDataLayer();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        changeIsBlockedClick(true);
        const response = await fetch(weatherForecastUrl);
        const data = await response.json();
        if (data) {
          markerRef.current = L.layerGroup();
          setGeoJSONData(data);
          Object.values(data.features).forEach((feature) => {
            const coordinates = getCoordinates(feature.properties.sectores);

            const customMarker = L.divIcon({
              html: `
                  <div class="chart">
                    <div class="bar bar-s" style="height: ${
                      feature.properties.superior
                    }px; background-color: ${colors[4]};"></div>
                    <div class="bar bar-n" style="height: ${
                      feature.properties.normal
                    }px; background-color: ${colors[2]};"></div>
                    <div class="bar bar-b" style="height: ${
                      feature.properties.inferior
                    }px; background-color: ${colors[0]};"></div>
                  </div>
                  <div class="label-rain">${feature.properties.sectores
                    ?.toLowerCase()
                    ?.replace(/\b\w/g, (l) => l.toUpperCase())}</div>
                `,
              className: 'custom-marker',
            });

            const marker = L.marker(coordinates, { icon: customMarker });
            markerRef.current.addLayer(marker);

            const customPopup = L.popup({
              closeButton: true,
              minWidth: 200,
              maxWidth: 400,
              className: 'custom-popup',
            }).setContent(`
                <div class="popup-header">
                  <span>ESCENARIO: ${feature.properties.escenario}</span>
                </div>
                <div class="popup-content">
                  <div class="popup-row">
                    <div class="popup-label">SECTOR:</div>
                    <div class="popup-value">${feature.properties.sectores}</div>
                  </div>
                  <div class="popup-row">
                    <div class="popup-label">INFERIOR:</div>
                    <div class="popup-value">${feature.properties.inferior} %</div>
                  </div>
                  <div class="popup-row">
                    <div class="popup-label">NORMAL:</div>
                    <div class="popup-value">${feature.properties.normal} %</div>
                  </div>
                  <div class="popup-row">
                    <div class="popup-label">SUPERIOR:</div>
                    <div class="popup-value">${feature.properties.superior} %</div>
                  </div>
                </div>
              `);

            marker.bindPopup(customPopup);
          });
        }
        markerRef.current.addTo(map);
      } catch (error) {
        console.log(error);
        openNotification(
          'error',
          'Error al cargar la capa de pronóstico del tiempo',
        );
      }
    };
    if (weatherForecastUrl) {
      fetchData();
    }

    return () => {
      if (markerRef.current) {
        map.removeLayer(markerRef.current);
      }
      changeIsBlockedClick(false);
    };
  }, [weatherForecastUrl]);

  return (
    <>
      {geoJSONData && (
        <TileLayer
          attribution='Google Maps'
          url='https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}'
          opacity={opacity}
        />
      )}
      {geoJSONData && <GeoJSON data={geoJSONData} style={style} />}
    </>
  );
};

export default LayerWeatherForecast;
