import React, { useEffect, useState } from 'react';
import { RideGlArrowUp } from '@rimac-seguros/ride-system-components';

const ScrollButton = ({ targetSelector }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [hasOverflow, setHasOverflow] = useState(false);

  const checkScroll = (e) => {
    setIsScrolled(e.target.scrollTop > 0);
  };

  const scrollContainer = (direction) => {
    const parent = document.querySelector(targetSelector);
    if (direction === 'up') {
      parent.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      parent.scrollTo({ top: parent.scrollHeight, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const parent = document.querySelector(targetSelector);
    if (parent) {
      setHasOverflow(parent.scrollHeight > parent.clientHeight);
      parent.addEventListener('scroll', checkScroll);

      return () => {
        parent.removeEventListener('scroll', checkScroll);
      };
    }
  }, [targetSelector]);

  if (!hasOverflow) {
    return null;
  }

  return (
    <button
      className={`scroll-button ${isScrolled ? 'up' : 'down'}`}
      onClick={() => scrollContainer(isScrolled ? 'up' : 'down')}
    >
      <div className='icon-container'>
        <RideGlArrowUp fill='white' size='medium' />
      </div>
    </button>
  );
};

export default ScrollButton;
