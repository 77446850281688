import { post, get } from '../lib/ajax';
import axios from 'axios';
import { getCumulusUrlToImport } from './users';

export const getPolizasByCompanyId = async (companyId) => {
  const r = await get(`/cpn_poliza/getPolizaDetail?companyId=${companyId}`);

  if (r.data.success) {
    return r.data.payload.list;
  }

  return [];
};

export const getPolizasByCompanyIds = async (companyIds) => {
  const r = await get(
    `/polizas/listPolizasByCompanies?companyIds=${companyIds}`,
  );

  if (r.data.success) {
    return r.data.payload?.list ?? [];
  }
  return [];
};

export const getPolizasByServiceId = async (serviceId) => {
  const r = await get(`/service-poliza/list?serviceId=${serviceId}`);

  if (r.data.success) {
    return r.data.payload?.list ?? [];
  }
  return [];
};

export const createPolizasDetailsByService = async (data) => {
  const r = await post('/service-poliza/create', {
    polizas: data,
  });

  if (r.data.success) {
    return r.data.payload;
  }

  return false;
};

export const savePlanning = async (data) => {
  const r = await post('/polizas/addPlanning', data);

  return r.data.success;
};

export const getOnePolizaByCompanyId = async (companyId, polizaId) => {
  const r = await get(
    `/cpn_poliza/getOnePolizaDetail?companyId=${companyId}&polizaId=${polizaId}`,
  );

  if (r.data.success) {
    return r.data.payload.list;
  }

  return null;
};

export const getPresignedUrl = (data) => {
  const payload = {
    type: data.type,
    name: data.name,
  };

  return post('/polizas/importFile', payload);
};

export const addCommentToPoliza = (data) => post('/polizas/addComment', data);

export const getAutocompleteImportedCampus = (attribute, data) => {
  return get(
    `/polizas/autocompleteAttribute?autocompleteAttribute=${attribute}&autocompleteData=${data}`,
  );
};

export const sendFilePoliza = async (data, handleProgress = () => {}) => {
  try {
    const payload = {
      type: data.type,
      name: data.name,
    };

    const res = await getCumulusUrlToImport(payload);

    if (res) {
      const url = res?.url;
      await axios.put(url, data, {
        onUploadProgress: handleProgress,
      });

      return res;
    }
    throw Error('Error al guardar archivo en historial.');
  } catch (error) {
    return error;
  }
};

export const startBulkInsertStatusPoliza = (fileName) =>
  post('/polizas/bulkimport', {
    fileName,
  });

export const getPoints = (data) => post('/polizas/point', data);

export const extractDataCircle = (circle) => {
  return {
    circle: {
      latlng: circle._latlng,
      radius: circle.getRadius(),
      bounds: {
        north: circle.getBounds().getNorth(), // -11.702668928919797,
        south: circle.getBounds().getSouth(), // -12.331952330450884,
        east: circle.getBounds().getEast(), // -76.64199829101564,
        west: circle.getBounds().getWest(), // -77.3870086669922
      },
    },
  };
};

export const extractDataPoly = (poly) => {
  return {
    poly: {
      latlng: poly.getBounds().getCenter(),
      points: poly.getLatLngs()[0],
      bounds: {
        north: poly.getBounds().getNorth(), // -11.702668928919797,
        south: poly.getBounds().getSouth(), // -12.331952330450884,
        east: poly.getBounds().getEast(), // -76.64199829101564,
        west: poly.getBounds().getWest(), // -77.3870086669922
      },
    },
  };
};

export const getCircle = (circle, filters) => {
  const circleData = extractDataCircle(circle);

  return post('/polizas/dataCircle', {
    ...circleData,
    ...{ filters },
  });
};

export const getPoly = (poly, filters) => {
  const polyData = extractDataPoly(poly);

  return post('/polizas/dataPoly', {
    ...polyData,
    ...{ filters },
  });
};

export const getGroup = (data) => post('/polizas/groupCount', data);

export const getPoint = (id) => get('/polizas/getPoint?id=' + id);

export const checkBulkInsertStatusPoliza = () => get('/polizas/importStatus');

export const bulkExport = (data) => post('/polizas/exportItems', data);

export const checkExported = (data) =>
  post('/polizas/checkExportedCircle', data);

export const getPolizasRainRisk = async () => {
  const res = await get('/cpn_poliza/getPolizasWithRainRisk');
  return res?.data?.success ? res.data.payload.list || [] : false;
};
