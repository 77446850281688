import React from 'react';

const Coordinates = (props) => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M20 2.5C18.8401 2.50062 17.6916 2.72969 16.6202 3.17415C15.5488 3.61861 14.5754 4.26975 13.7556 5.09039C12.9359 5.91102 12.2858 6.88509 11.8424 7.95697C11.3991 9.02885 11.1713 10.1776 11.1719 11.3375C11.1715 14.1905 12.178 16.9522 14.0141 19.1359L20 26.25L25.9859 19.1359C27.8218 16.9521 28.8282 14.1905 28.8281 11.3375C28.8287 10.1776 28.6009 9.02885 28.1576 7.95697C27.7142 6.88509 27.0641 5.91102 26.2444 5.09039C25.4246 4.26975 24.4512 3.61861 23.3798 3.17415C22.3084 2.72969 21.1599 2.50062 20 2.5ZM20 14.6516C19.3444 14.6522 18.7034 14.4583 18.158 14.0946C17.6126 13.7308 17.1873 13.2134 16.936 12.6079C16.6847 12.0024 16.6186 11.3359 16.7461 10.6929C16.8736 10.0498 17.189 9.459 17.6524 8.99521C18.1157 8.53142 18.7062 8.21547 19.3492 8.08735C19.9921 7.95922 20.6586 8.02467 21.2644 8.27541C21.8701 8.52615 22.3879 8.95092 22.7522 9.49598C23.1165 10.041 23.3109 10.6819 23.3109 11.3375C23.3111 11.7725 23.2257 12.2033 23.0594 12.6053C22.8931 13.0072 22.6493 13.3725 22.3418 13.6802C22.0344 13.988 21.6693 14.2322 21.2675 14.3988C20.8657 14.5655 20.435 14.6514 20 14.6516Z'
        fill='url(#paint0_linear_827_16701)'
      />
      <path
        d='M31.7 23.75H24.6172L22.5 26.2766H30.0156L31.4469 30H28.75V32.5H32.4156L32.45 32.5922L33.8562 36.2234H23.2812L20.1969 32.5922L20.0875 32.5H26.25V30H17.9875L17.9344 29.9344L14.8266 26.2766H17.5078L15.3828 23.75H8.30156L2.5 38.75H37.5L31.7 23.75ZM9.99063 26.2766H13.45L11.825 29.9875H8.55625L9.99063 26.2766ZM9.09219 36.2234H6.14531L7.57812 32.5125H10.7172L9.09219 36.2234ZM11.8141 36.2234L14.5969 29.8703L20 36.2234H11.8141Z'
        fill='url(#paint1_linear_827_16701)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_827_16701'
          x1='11.6338'
          y1='4.36395'
          x2='30.3724'
          y2='18.2946'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.35' stopColor='#F7052D' />
          <stop offset='0.85' stopColor='#CA5AFA' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_827_16701'
          x1='2.5'
          y1='23.75'
          x2='6.48249'
          y2='44.6581'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.272135' stopColor='#34263B' />
          <stop offset='0.658079' stopColor='#13172C' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Coordinates;
