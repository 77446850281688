import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Modal } from 'antd';

import {
  getAllChangelog,
  updateVersionViewed,
} from '../../../services/changelog';

import { getUserNameSimulate } from '../../../services/users';
import { CHANGE_CHANGELOG_PREVIEW_VERSION } from '../../../redux/actions/actionsType';

const ChangelogModal = () => {
  let location = useLocation();
  const dispatch = useDispatch();
  const { user, previewVersion } = useSelector((state) => ({
    user: state.auth.user,
    previewVersion: state.changelog.previewVersion,
  }));

  const [visible, setVisible] = useState(false);
  const [recent, setRecent] = useState([]);
  const [improved, setImproved] = useState([]);

  useEffect(() => {
    if (!user || user.version_viewed == null) {
      return;
    }
    if (getUserNameSimulate()) {
      return;
    }
    if (
      location.pathname !== 'administracion/resumen' &&
      location.pathname !== '/administracion/perfil-empresa' &&
      location.pathname !== '/servicio-de-prevencion-solicitud-proveedor'
    ) {
      return;
    }

    prepareModal(user.version_viewed + 0.01, user.rol_id, true);
  }, [location.pathname, user]);

  useEffect(() => {
    if (previewVersion.version > -1 && previewVersion.rolId) {
      prepareModal(previewVersion, previewVersion.rolId, false);
    }
  }, [previewVersion]);

  const prepareModal = (version, rolId, savePreview) => {
    getAllChangelog(version, rolId).then((list) => {
      if (list && list.length > 0) {
        setRecent(list.filter((log) => log.type === 1));
        setImproved(list.filter((log) => log.type === 2));
        setVisible(true);
        if (savePreview) {
          updateVersionViewed({
            version: Math.max(...list.map((log) => log.version)),
          });
        }
      }
    });
  };

  const handleClose = () => {
    dispatch({ type: CHANGE_CHANGELOG_PREVIEW_VERSION, payload: -1 });
    setVisible(false);
  };

  return (
    <Modal
      title=''
      open={visible}
      className='changelog-modal'
      centered
      onCancel={handleClose}
      footer={<div />}
    >
      <div className='changelog-modal__left'>
        <img
          src='/images/portal/fondo_risks_person.png'
          alt='Persona informando'
        />
      </div>
      <div className='changelog-modal__right'>
        <div className='changelog-modal__title'>Qué hay de nuevo</div>
        {recent && recent.length > 0 && (
          <div className='changelog-modal__section'>
            <div className='changelog-modal__tag changelog-modal__tag--acian'>
              Nuevo:
            </div>
            <ul className='changelog-modal__list'>
              {recent.map((r, i) => (
                <li key={`recent-${i}`}>
                  {r.title}
                  {r.image && (
                    <div>
                      <img
                        alt='recent-alt'
                        src={`${process.env.REACT_APP_WEB_PATH_S3_NEW}${r.image}`}
                      />
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
        {improved && improved.length > 0 && (
          <div className='changelog-modal__section'>
            <div className='changelog-modal__tag changelog-modal__tag--cyan'>
              Mejorado:
            </div>
            <ul className='changelog-modal__list'>
              {improved.map((r, i) => (
                <li key={`improved-${i}`}>
                  {r.title}
                  {r.image && (
                    <div>
                      <img
                        alt='improved-alt'
                        src={`${process.env.REACT_APP_WEB_PATH_S3_NEW}${r.image}`}
                      />
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ChangelogModal;
