import { useScreen } from '../../../../lib/screen';

const formatDate = (isoString) => {
  const date = new Date(isoString);

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  const monthName = months[parseInt(month, 10) - 1];

  return {
    compact: `${day}/${month}/${year}`,
    semiexpanded: `${day} de ${monthName} ${year}`,
    expanded: `${day} de ${monthName} del ${year}`,
  };
};

const Time = ({ dateStart, dateEnd, format }) => {
  const { isMobileScreen } = useScreen();

  const formattedStartDate = formatDate(dateStart);
  const formattedEndDate = dateEnd ? formatDate(dateEnd) : null;

  const classNames = [
    'newsletterTime',
    format === 'intermediate' && 'newsletterTime--intermediate',
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <time
      dateTime={formatDate(dateStart).compact.split('/').reverse().join('-')}
      className={classNames}
    >
      {(() => {
        if (isMobileScreen) {
          return !dateEnd
            ? formattedStartDate.compact
            : `${formattedStartDate.compact} - ${formattedEndDate.compact}`;
        } else {
          if (format === 'intermediate') {
            return !dateEnd
              ? formattedStartDate.semiexpanded
              : `${formattedStartDate.semiexpanded} - ${formattedEndDate.semiexpanded}`;
          }
          return !dateEnd
            ? formattedStartDate.expanded
            : `${formattedStartDate.expanded} - ${formattedEndDate.expanded}`;
        }
      })()}
    </time>
  );
};

export default Time;
