import React from 'react';
import { Modal, Button } from 'antd';

const ModalNewLocation = ({ visible, setVisible }) => {
  const handleOk = () => {
    setVisible(false);
  };

  return (
    <Modal
      title='Agregar nueva ubicación'
      open={visible}
      centered
      className='modal-new-location'
      footer={null}
    >
      <div>
        <p>Para agregar una nueva ubicación, sigue estos pasos:</p>
        <ol>
          <li>Haz clic en el mapa en la ubicación que deseas guardar.</li>
          <li>
            En el apartado "Ubicación favorita", ingresa un nombre para la
            ubicación.
          </li>
          <li>Presiona en Guardar.</li>
        </ol>
        <p>
          Otra alternativa es hacer clic en la lupa del lado derecho, escribir
          tu dirección, seleccionar la dirección encontrada y luego hacer clic
          en el botón de cerrar para ver el punto en el mapa.
        </p>
      </div>
      <Button type='primary' onClick={handleOk} size='small'>
        Entendido
      </Button>
    </Modal>
  );
};

export default ModalNewLocation;
