import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Popover, Tooltip } from 'antd';
import toGeoJSON from '@mapbox/togeojson';

import { deleteMyLayer, getMyLayers } from '../../../../services/maps';
import {
  MY_LAYERS_SET_ACTIVE_LAYERS,
  MY_LAYERS_SET_DRAWS,
} from '../../../../redux/actions/actionsType';

import { IconDots } from '../../../Icons/iconText';

const ListLayers = ({
  isMobile,
  controlMapDrawerVisible,
  setShowForm,
  setStep,
  setSelectForm,
}) => {
  const dispatch = useDispatch();
  const { list, activeLayers } = useSelector((state) => state.myLayers);

  const handleSelectLayer = (layer) => {
    const foundLayer = activeLayers.findIndex(
      (activeLayer) => activeLayer.id === layer.id,
    );
    if (foundLayer === -1) {
      dispatch({
        type: MY_LAYERS_SET_ACTIVE_LAYERS,
        payload: [...activeLayers, layer],
      });
    } else {
      const copyActiveUtils = structuredClone(activeLayers);
      copyActiveUtils.splice(foundLayer, 1);
      dispatch({ type: MY_LAYERS_SET_ACTIVE_LAYERS, payload: copyActiveUtils });
    }

    if (isMobile) controlMapDrawerVisible(false);
  };

  const fetchGeoJSONData = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) throw new Error('Error al obtener el archivo GeoJSON');

      const text = await response.text();
      const geoJSON = JSON.parse(text);

      return geoJSON;
    } catch (error) {
      console.error(error);

      return [];
    }
  };

  const convertToGeoJSON = async (kmlURL) => {
    try {
      const response = await fetch(kmlURL);
      const kmlData = await response.text();
      const parser = new DOMParser();
      const kml = parser.parseFromString(kmlData, 'text/xml');
      const geoJSON = toGeoJSON.kml(kml);

      return geoJSON;
    } catch (error) {
      console.error(error);

      return [];
    }
  };

  const redrawLayer = async (e, layer) => {
    if (layer.typeFile === 'geojson') {
      e.stopPropagation();
      const draws = await fetchGeoJSONData(layer.url);
      dispatch({ type: MY_LAYERS_SET_DRAWS, payload: draws.features });
      setSelectForm('create');
      setStep(3);
    } else if (layer.typeFile === 'kml') {
      e.stopPropagation();
      const geoJSON = await convertToGeoJSON(layer.url);
      dispatch({ type: MY_LAYERS_SET_DRAWS, payload: geoJSON.features });
      setSelectForm('create');
      setStep(3);
    }
  };

  const editLayer = (e, { id, name, url }) => {
    e.stopPropagation();
    const splitFileName = url.split('-');
    const [, ...restFileName] = splitFileName;
    const fileName = restFileName.join('-');
    setShowForm({ id, name, url, fileName });
    setSelectForm('upload');
    setStep(3);
  };

  const deleteLayer = async (e, id) => {
    e.stopPropagation();
    await deleteMyLayer(id, dispatch);
    dispatch({
      type: MY_LAYERS_SET_ACTIVE_LAYERS,
      payload: activeLayers.filter((activeLayer) => activeLayer.id !== id),
    });
    setStep(1);
    getMyLayers(dispatch);
  };

  return list.map((layer) => {
    return (
      <div
        key={layer?.id}
        className={`drawer-map-layers__list__item ${
          activeLayers.some((activeLayer) => activeLayer?.id === layer?.id)
            ? 'active'
            : ''
        }`}
        onClick={() => handleSelectLayer(layer)}
      >
        <div className='drawer-map-layers__list__item__name'>
          <Popover
            placement='topRight'
            trigger='click'
            content={
              <div className='buttons-popover'>
                {['geojson', 'kml', 'kmz'].includes(layer.typeFile) && (
                  <Button onClick={(e) => redrawLayer(e, layer)}>
                    <span>Editar capa</span>
                  </Button>
                )}
                <Button onClick={(e) => editLayer(e, layer)}>
                  <span>Actualizar capa</span>
                </Button>
                <Button onClick={(e) => deleteLayer(e, layer?.id)}>
                  <span>Eliminar capa</span>
                </Button>
              </div>
            }
            className='popover-actions'
          >
            <Button onClick={(e) => e.stopPropagation()}>
              <IconDots />
            </Button>
          </Popover>
          <Tooltip placement='top' title={layer?.name} mouseEnterDelay={0.5}>
            <div className='drawer-map-layers__list__item__name__text ellipsis'>
              {layer?.name}
            </div>
          </Tooltip>
        </div>
        <div className='drawer-map-layers__list__item__action'>
          <Checkbox
            className='checkbox-layer'
            checked={activeLayers.some(
              (activeLayer) => activeLayer?.id === layer?.id,
            )}
          />
        </div>
      </div>
    );
  });
};

export default ListLayers;
