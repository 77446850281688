import { Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getWeatherForecastLayers } from '../../../services/parameter';

const rainFallTypes = [
  { type: 'SUPERIOR', color: '#3333ff', name: 'Superior' },
  { type: 'NORMAL-SUPERIOR', color: '#99ccff', name: 'Normal - Superior' },
  { type: 'NORMAL', color: '#ffffff', name: 'Normal' },
  { type: 'NORMAL-INFERIOR', color: '#ffdf82', name: 'Normal - Inferior' },
  { type: 'INFERIOR', color: '#cd8742', name: 'Inferior' },
  { type: 'PERIODO SECO', color: '#eb989f', name: 'Periodo Seco' },
];

const temperatureTypes = [
  { type: 'SUPERIOR', color: '#feaa44', name: 'Superior' },
  { type: 'NORMAL-SUPERIOR', color: '#ffdf82', name: 'Normal - Superior' },
  { type: 'NORMAL', color: '#ffffff', name: 'Normal' },
  { type: 'NORMAL-INFERIOR', color: '#dbf1fd', name: 'Normal - Inferior' },
  { type: 'INFERIOR', color: '#6ee7f2', name: 'Inferior' },
];

const LegendColors = () => {
  const activeLayer = useSelector((state) => state.maps.activeLayer);
  const activeSubLayer = useSelector((state) => state.maps.activeSubLayer);
  const siderVisible = useSelector((state) => state.controlMap.siderVisible);
  const conflictTypes = useSelector((state) => state.conflict.types);
  const roadEmergencyTypes = useSelector((state) => state.roadEmergency.types);
  const firemenTypes = useSelector((state) => state.emergencies.types);
  const geologicalHazardsTypes = useSelector(
    (state) => state.geologicalHazards.types,
  );
  const isOpened = useSelector((state) => state.sideMenu.isOpened);
  const [types, setTypes] = useState([]);
  const [title, setTitle] = useState('');

  useEffect(() => {
    switch (activeSubLayer.id) {
      case 'conflictos':
        setTypes(conflictTypes);
        break;
      case 'emergencias_vial':
        setTypes(roadEmergencyTypes);
        break;
      case 'bomberos':
        setTypes(firemenTypes);
        break;
      case 'rainfall-efm':
      case 'rainfall':
        setTypes(rainFallTypes);
        break;
      case 'max_temperature':
      case 'min_temperature':
        setTypes(temperatureTypes);
        break;
      case 'peligros_geologicos':
        setTypes(geologicalHazardsTypes);
        break;
      default:
        setTypes([]);
    }
  }, [
    activeSubLayer,
    conflictTypes,
    roadEmergencyTypes,
    firemenTypes,
    geologicalHazardsTypes,
  ]);

  useEffect(() => {
    setTitle('');
    if (activeLayer.id === 'weather-forecast') {
      getWeatherForecastLayers().then((res) => {
        if (res.data.payload) {
          const rainfallLayerEFM = res.data.payload.filter(
            (layer) => layer.data === 'rainfall-efm',
          ).sort((a, b) => b.id - a.id)?.[0];
          const rainfallLayer = res.data.payload.filter(
            (layer) => layer.data === 'rainfall',
          ).sort((a, b) => b.id - a.id)?.[0];
          const maxTemperatureLayer = res.data.payload.filter(
            (layer) => layer.data === 'maxTemperature',
          ).sort((a, b) => b.id - a.id)?.[0];
          const minTemperatureLayer = res.data.payload.filter(
            (layer) => layer.data === 'minTemperature',
          ).sort((a, b) => b.id - a.id)?.[0];

          switch (activeSubLayer.id) {
            case 'rainfall-efm':
              setTitle(rainfallLayerEFM.title || 'Precipitación EFM');
              break;
            case 'rainfall':
              setTitle(rainfallLayer.title || 'Precipitación');
              break;
            case 'max_temperature':
              setTitle(maxTemperatureLayer.title || 'Temperatura máxima');
              break;
            case 'min_temperature':
              setTitle(minTemperatureLayer.title || 'Temperatura mínima');
              break;
            default:
              setTitle('');
              break;
          }
        }
      });
    }
  }, [activeLayer, activeSubLayer]);

  return (
    <div
      className={`checkboxButton ${
        !siderVisible ? 'checkboxButtonSider' : ''
      } ${isOpened ? 'checkboxButtonDrawer' : ''}`}
      style={{ zIndex: 400 }}
    >
      {title && <div className='checkboxButton__title'>{title}</div>}
      <div className='checkboxButton__modes'>
        {types &&
          types.length > 0 &&
          types.map(
            (item, idx) =>
              item.type && (
                <Tooltip key={idx} placement='right' title={item.tooltipText}>
                  <div key={idx} className={'checkboxButton__modes__item'}>
                    <span className='checkboxButton__modes__item__title'>
                      {item.nameToShow || item.name || item.type}
                    </span>
                    <div
                      className='checkboxButton__modes__item__circle'
                      style={{ background: item.color }}
                    ></div>
                  </div>
                </Tooltip>
              ),
          )}
      </div>
    </div>
  );
};

export default LegendColors;
