import {
  MAP_SET_MAP,
  MAP_SET_ACTIVE_LAYER,
  MAP_SET_ACTIVE_SUBLAYER,
  MAP_SET_ACTIVE_TOOL,
  MAP_SET_ACTIVE_UTILS_LAYER,
  MAP_SET_ACTIVE_CUMULUS_TOOL,
  MAP_CHANGE_BLOCKED_CLICK,
  MAP_CHANGE_MULTIPLE_SELECTION_MODE,
  MAP_SET_ACTIVE_MULTI_LAYERS,
} from '../actions/actionsType';

const initialState = {
  map: null,
  isBlockedClick: false,
  activeLayer: JSON.parse(localStorage.getItem('activeLayer')) || null,
  activeSubLayer: JSON.parse(localStorage.getItem('activeSubLayer')) || null,
  activeTool: null,
  activeUtilsLayer: [],
  activeCumulusTool: null,
  multiSelectMode: false,
  activeMultiLayers: [],
};

const map = (state, action) => {
  if (!state) state = initialState;

  switch (action.type) {
    case MAP_SET_MAP:
      return {
        ...state,
        map: action.payload,
      };
    case MAP_SET_ACTIVE_LAYER:
      if (action.payload)
        localStorage.setItem('activeLayer', JSON.stringify(action.payload));

      return {
        ...state,
        activeLayer: action.payload,
      };
    case MAP_SET_ACTIVE_SUBLAYER:
      if (action.payload)
        localStorage.setItem('activeSubLayer', JSON.stringify(action.payload));

      return {
        ...state,
        activeSubLayer: action.payload,
      };
    case MAP_SET_ACTIVE_TOOL:
      return {
        ...state,
        activeTool: action.payload,
      };
    case MAP_SET_ACTIVE_UTILS_LAYER:
      return {
        ...state,
        activeUtilsLayer: action.payload,
      };
    case MAP_SET_ACTIVE_CUMULUS_TOOL:
      return {
        ...state,
        activeCumulusTool: action.payload,
      };
    case MAP_CHANGE_BLOCKED_CLICK:
      return {
        ...state,
        isBlockedClick: action.payload,
      };
    case MAP_CHANGE_MULTIPLE_SELECTION_MODE:
      return {
        ...state,
        multiSelectMode: action.payload,
        activeMultiLayers: action.payload ? state.activeMultiLayers : [],
      };
    case MAP_SET_ACTIVE_MULTI_LAYERS:
      return {
        ...state,
        activeMultiLayers: action.payload,
      };
    default:
      return state;
  }
};

export default map;
