import * as React from 'react';

const Visibility = (props) => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M19.6203 27.7359C19.525 28.1766 19.4562 28.6266 19.4172 29.0844L17.9391 28.1562L17.675 26.3094L16.3578 24.1609L12.8359 18.3937H11.7656L10.8656 16.7438L11.5203 16.1625L10.2609 14.5875L12.2047 12.5141L14.3375 13.9359L13.5766 14.525L14.5047 14.9109L14.5766 15.0266L15.5531 13.0922L17.5875 12.1594L19.0297 10.7172L18.9703 10.6094L18.0641 8.75625L20.0562 8.74375L21.2672 9.45L21.3375 9.53438C21.4609 9.68281 22.4547 10.8891 22.925 11.8953L23.375 11.9922L23.9734 11.3078L24.3688 11.4406C24.7656 11.575 26.0922 12.0438 26.5375 12.4688L26.925 12.8375L26.3047 13.7344L26.7469 13.9563L27.0734 15.7516L23.3219 16.5375L22.1953 19.1203L23.2391 21.1719L24.0141 21.2266C23.5109 21.5703 23.0406 21.9578 22.6047 22.3813L22.4453 22.3703L20.8125 19.1625L22.4328 15.4469L25.6016 14.7828L24.4547 14.2094L25.2109 13.1172C24.9734 13.0078 24.6734 12.8828 24.3578 12.7656L23.8297 13.3687L21.9969 12.9766L21.8812 12.6453C21.6484 11.9781 20.8219 10.8828 20.4453 10.4187L20.2219 10.2891L20.5844 10.9297L18.3109 13.2031L16.4703 14.0469L14.6906 17.575L13.6547 15.9109L12.5531 15.4516L13.2375 16.3062L12.4391 17.0156L12.5078 17.1422H13.5375L18.8766 25.8766L19.0953 27.4062L19.6203 27.7359ZM23.9828 38.75H8.75C6.68281 38.75 5 37.0672 5 35V5C5 2.93281 6.68281 1.25 8.75 1.25H28.75C30.8172 1.25 32.5 2.93281 32.5 5V19.675C31.6984 19.4812 30.8609 19.375 30 19.375V6.25H7.5V33.125H19.8453C20.5563 35.4328 22.0359 37.4062 23.9828 38.75ZM16.875 3.75C16.875 4.09531 17.1547 4.375 17.5 4.375H20C20.3453 4.375 20.625 4.09531 20.625 3.75C20.625 3.40469 20.3453 3.125 20 3.125H17.5C17.1547 3.125 16.875 3.40469 16.875 3.75ZM20 35.625C20 34.9344 19.4406 34.375 18.75 34.375C18.0594 34.375 17.5 34.9344 17.5 35.625C17.5 36.3156 18.0594 36.875 18.75 36.875C19.4406 36.875 20 36.3156 20 35.625Z'
      fill='url(#paint0_linear_9088_26577)'
    />
    <path
      d='M30 21.25C25.1672 21.25 21.25 25.1672 21.25 30C21.25 34.8328 25.1672 38.75 30 38.75C34.8328 38.75 38.75 34.8328 38.75 30C38.75 25.1672 34.8328 21.25 30 21.25ZM30 34.0625C26.45 34.0625 23.5328 30.1422 23.4375 30C23.5328 29.8578 26.45 25.9375 30 25.9375C33.55 25.9375 36.4672 29.8578 36.5625 30C36.4672 30.1422 33.55 34.0625 30 34.0625ZM30 28.125C31.0359 28.125 31.875 28.9641 31.875 30C31.875 31.0359 31.0359 31.875 30 31.875C28.9641 31.875 28.125 31.0359 28.125 30C28.125 28.9641 28.9641 28.125 30 28.125ZM30 26.875C28.2766 26.875 26.875 28.2766 26.875 30C26.875 31.7234 28.2766 33.125 30 33.125C31.7234 33.125 33.125 31.7234 33.125 30C33.125 28.2766 31.7234 26.875 30 26.875Z'
      fill='url(#paint1_linear_9088_26577)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_9088_26577'
        x1='5'
        y1='1.25'
        x2='29.0094'
        y2='40.8655'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.272135' stopColor='#34263B' />
        <stop offset='0.658079' stopColor='#13172C' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_9088_26577'
        x1='21.7078'
        y1='22.6234'
        x2='36.1266'
        y2='37.0422'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.35' stopColor='#F7052D' />
        <stop offset='0.85' stopColor='#CA5AFA' />
      </linearGradient>
    </defs>
  </svg>
);

export default Visibility;
