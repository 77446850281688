import React from 'react';

export const GLOSARIO = [
  {
    id: 'satellite',
    name: 'Satélite',
    description: `La imagen satelital o imagen de satélite, es la representación visual de la información 
    capturada por el sensor montado en el satélite GOES 16 en tiempo real.`,
    text: [
      <span key={`${Math.random}`}>
        <strong>Visible: </strong>
        Las imágenes visibles del satélite nos brindan la capacidad de
        identificar diferentes tipos de nubes y observar fenómenos como frentes
        atmosféricos y desarrollo de nubes convectivas, entre otros. Estas
        imágenes son especialmente útiles durante el día, cuando hay luz solar
        disponible.
      </span>,
      <span key={`${Math.random}`}>
        <strong>Infrarrojo: </strong>
        Se utiliza para medir la temperatura de las nubes, identificar áreas de
        tormentas y otros fenómenos atmosféricos. Los colores representan
        diferentes temperaturas, donde los tonos cálidos indican áreas con mayor
        probabilidad de precipitaciones y los tonos fríos indican áreas con
        menor probabilidad de precipitaciones. Estas precipitaciones pueden
        manifestarse como lluvia, nieve, aguanieve, granizo o llovizna.
      </span>,
    ],
    type: 'list',
  },
  {
    id: 'wind',
    description: `Muestra un modelo meteorológico de la velocidad del viento en la superficie (a una 
    altitud de 10 m) en un momento determinado. Se expresa la velocidad del viento en Km/h. Indica qué 
    tan rápido se mueve el aire en una determinada dirección.`,
    type: 'text',
  },
  {
    id: 'hc',
    description: `Se encuentran en las capas superiores de la atmósfera y generalmente se forman a 
    altitudes superiores a 6,000 metros. Estas nubes están compuestas principalmente por cristales de 
    hielo y se denominan nubes de alta troposfera.`,
    example:
      'Ejemplos de nubes altas son las nubes cirros, cirrostratos y cirrocúmulos.',
    type: 'text',
  },
  {
    id: 'mc',
    description: `Se forman en la parte media de la atmósfera, a altitudes que van desde aproximadamente 
    2,000 a 6,000 metros. Estas nubes están compuestas principalmente por gotas de agua y se conocen 
    como nubes de la capa medio-troposférica.`,
    example:
      'Ejemplos de nubes medias son las nubes altoestratos y altocúmulos.',
    type: 'text',
  },
  {
    id: 'lc',
    description: `Se encuentran a altitudes más bajas, generalmente por debajo de 2,000 metros. 
    Estas nubes están formadas principalmente por gotas de agua y pueden estar asociadas con la 
    formación de niebla.`,
    example: 'Ejemplos de nubes bajas son las nubes estratos y estratocúmulos.',
    type: 'text',
  },
  {
    id: 'flashed',
    description: `Muestra en tiempo real cada rayo producido en los últimos 5 minutos. En la sección 
    "Mis Ubicaciones", se pueden crear alertas automáticas para ser notificado de los rayos cercanos a 
    su ubicación.`,
    type: 'text',
  },
  {
    id: 'ligthing_den',
    description: `Muestra la cantidad de rayos producidos en un área determinada, la coloración indica 
    en que lugares los rayos se producen en mayor cantidad. Es un indicador de la intensidad de la 
    tormenta.`,
    type: 'text',
  },
  {
    id: 'ligthing_ene',
    description: `Mide la energía producida por los rayos en un área determinada. Se mide en unidades 
    de energía Joules (J). Es un indicador de la intensidad de la tormenta.`,
    type: 'text',
  },
  {
    id: 'ligthing_aen',
    description: `Contiene la energía media de los flashes del GLM, acumulados en una grilla de 4 km por 
    4 km, durante un intervalo de 5 minutos. La unidad de este parámetro es el femtojoules (fj) 
    10^-15 joules.`,
    type: 'text',
  },
  {
    id: 'waves',
    description: `Un modelo meteorológico de oleaje que utiliza datos meteorológicos y oceanográficos 
    para predecir las condiciones del oleaje, como la altura y dirección de las olas, en un área 
    específica.`,
    type: 'text',
  },
  {
    id: 'pp',
    description: `Se refiere a la predicción de la precipitación líquida en forma de gotas de agua que 
    caen de las nubes hacia la superficie terrestre en un área específica. Se mide en mm, donde 1 mm de 
    lluvia se considera aproximadamente igual a 1 litro por metro cuadrado de superficie.`,
    type: 'text',
  },
  {
    id: 'pp_acc',
    description: `Se refiere a la cantidad total de precipitación en forma de gotas de agua que se 
    ha simulado o pronosticado durante un período de tiempo determinado en un área específica.`,
    type: 'text',
  },
  {
    id: 'hr',
    description: `Es la medida, expresada en porcentaje, que indica el nivel de saturación del aire 
    con respecto a su capacidad máxima de retener vapor de agua a una temperatura específica. El 
    incremento de la humedad favorece que la sensación de frío y calor se incremente.`,
    type: 'text',
  },
  {
    id: 'temp',
    description: `Modelo meteorológico de la representación de la temperatura atmosférica pronosticada 
    en un área específica. Se mide en grados Celsius C°.`,
    type: 'text',
  },
  {
    id: 'vis_horizontal',
    description: `Es un modelo meteorológico que muestra el pronóstico de la distancia que se puede 
    ver horizontalmente en una determinada área debido a condiciones atmosféricas (Se expresa en Km). 
    La visibilidad horizontal puede verse afectada por la presencia de niebla, neblina, lluvia o nieve.`,
    type: 'text',
  },
  {
    id: 'conflictos',
    description: `Muestra la actualización del listado de conflictos sociales a nivel nacional, reportados 
    por la Defensoría del Pueblo. La existencia de un conflicto social puede ser fuente de eventuales 
    bloqueos de vías, manifestaciones y huelgas.`,
    type: 'text',
  },
  {
    id: 'emergencias_vial',
    description: `Muestra la actualización del listado de emergencias viales a nivel nacional, reportados 
    por PROVÍAS Nacional, considerando las condiciones de tránsito: interrumpido, restringido o normal.`,
    type: 'text',
  },
  {
    id: 'bomberos',
    description: `Muestra los incidentes reportados por el Cuerpo General de Bomberos Voluntarios del Perú 
    (CGBVP) de las últimas 24 horas que involucren 4 o más vehículos para atender la emergencia.`,
    type: 'text',
  },
  {
    id: 'sismos',
    description: `Muestra los datos del ultimo sismo reportado por el Instituto Geofísico del Perú.`,
    component: (
      <div className='info-sismo'>
        <article className='drawer-information__card'>
          <div className='drawer-information__card__header'>
            <h3>
              Aceleración del suelo cm/s<sup>2</sup>
            </h3>
          </div>
          <div className='drawer-information__card__info'>
            <div>
              <div className='ant-list ant-list-split'>
                <div className='ant-spin-nested-loading'>
                  <div className='ant-spin-container'>
                    <li className='ant-list-item mb-1'>
                      Significa que tan rápido y fuerte tiembla el suelo durante
                      un sismo.
                    </li>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
        <p className='description'>
          Rango de magnitudes para la interpretación de sismos.
        </p>
        <h2 className='drawer-information__subtitle'>Magnitudes</h2>
        <div className='info-sismo-item'>
          <span className='info-sismo-item-label tag-green'>&nbsp;</span>
          <span className='info-sismo-item-value'>Menos de 4.5</span>
        </div>
        <div className='info-sismo-item'>
          <span className='info-sismo-item-label tag-yelow'>&nbsp;</span>
          <span className='info-sismo-item-value'>Entre 4.5 a 6.0</span>
        </div>
        <div className='info-sismo-item'>
          <span className='info-sismo-item-label tag-red'>&nbsp;</span>
          <span className='info-sismo-item-value'>Más de 6.0</span>
        </div>
      </div>
    ),
    type: 'component',
  },
  {
    id: 'tsunami',
    description: `Muestra los datos de los últimos sismos en el Pacifico y su evaluación para 
    determinar si genera un tsunami en el litoral peruano.`,
    component: (
      <div className='info-sismo'>
        <p className='description'>
          Rango de magnitudes para la interpretación de alerta de Tsunamis.
        </p>
        <h2 className='drawer-information__subtitle'>Magnitudes</h2>
        <div className='info-sismo-item'>
          <span className='info-sismo-item-label tag-green'>&nbsp;</span>
          <span className='info-sismo-item-value'>Menos de 4.5</span>
        </div>
        <div className='info-sismo-item'>
          <span className='info-sismo-item-label tag-yelow'>&nbsp;</span>
          <span className='info-sismo-item-value'>Entre 4.5 a 6.0</span>
        </div>
        <div className='info-sismo-item'>
          <span className='info-sismo-item-label tag-red'>&nbsp;</span>
          <span className='info-sismo-item-value'>Más de 6.0</span>
        </div>
      </div>
    ),
    type: 'component',
  },
  {
    id: 'geological',
    description: `Son fenómenos naturales que pueden causar daño a la salud y propiedad. El mapa 
    muestra el inventario mapeado por el Instituto Geológico Minero y Metalúrgico.`,
    type: 'text',
  },
  {
    id: 'rainfall-efm',
    description: `Pronóstico probabilístico por regiones a nivel nacional de la precipitación para los 
    meses de Enero a Marzo. El color mostaza, indica un escenario de acumulados de lluvias inferiores a lo 
    normal, el color azul sobre lo normal, y el blanco, señala un probable escenario de lluvias dentro de 
    sus rangos normales.`,
    type: 'text',
  },
  {
    id: 'rainfall',
    description: `Pronóstico probabilístico por regiones a nivel nacional de la precipitación. El color 
    mostaza, indica un escenario de acumulados de lluvias inferiores a lo normal, el color azul sobre lo 
    normal, y el blanco, señala un probable escenario de lluvias dentro de sus rangos normales.`,
    type: 'text',
  },
  {
    id: 'max_temperature',
    description: `Pronóstico probabilístico por regiones a nivel nacional de temperatura máxima. El color 
    turquesa, indica un escenario temperaturas inferiores a lo normal, el color naranja sobre lo normal, y 
    el blanco, señala un probable escenario de temperaturas dentro de sus rangos normales.`,
    type: 'text',
  },
  {
    id: 'min_temperature',
    description: `Pronóstico probabilístico por regiones a nivel nacional de temperatura mínima. El color 
    turquesa, indica un escenario temperaturas inferiores a lo normal, el color naranja sobre lo normal, y 
    el blanco, señala un probable escenario de temperaturas dentro de sus rangos normales.`,
    type: 'text',
  },
];
