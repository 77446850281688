import { get, post, put } from '../lib/ajax';
import {
  CHANGE_REQUEST_SERVICES_ITEMS_QUERY,
  LOADED_REQUEST_SERVICES,
  LOADED_REQUEST_SERVICES_ITEMS,
  LOADING_CREATE_REQUEST_SERVICE_ITEM_QUIZ,
  LOADING_UPDATE_REQUEST_SERVICE_ITEM,
  LOADED_REQUEST_SERVICES_CONTACT,
} from '../redux/actions/actionsType';
import { geRiskRequestServiceItems } from './requestServices';

export const getMyRequestServicesItems = async (dispatch, data) => {
  dispatch({
    type: CHANGE_REQUEST_SERVICES_ITEMS_QUERY,
    payload: data?.query ? data.query : '',
  });
  const r = await post('/request_service_items/list', data);
  if (r.data.success) {
    dispatch({
      type: LOADED_REQUEST_SERVICES_ITEMS,
      payload: r.data.payload,
    });
  }
};

export const getLastExecutedService = async () => {
  try {
    const r = await get('/request_services/lastExecutedService');

    if (r.data.success) return r.data.payload;
  } catch (error) {
    console.error(error);
  }
};

export const sendAcceptance = async (
  dispatch,
  requestServiceItemId,
  pagination,
) => {
  const r = await post(
    `/request_service_items/acceptance?requestServiceItemId=${requestServiceItemId}`,
  );
  if (r.data.success) getMyRequestServicesItems(dispatch, pagination);
};

export const acceptServiceByProvider = async (
  requestServiceItemId,
  providerId,
  date,
) => {
  try {
    await post(
      `/request_service_items/auto_derived?requestServiceItemId=${requestServiceItemId}&providerId=${providerId}`,
    );
    const res = await post(
      `/request_service_items/event_date?requestServiceItemId=${requestServiceItemId}`,
      {
        date,
        providerId,
      },
    );

    return res;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const sendEventDate = async (
  dispatch,
  date,
  requestServiceItemId,
  pagination,
) => {
  const r = await post(
    `/request_service_items/event_date?requestServiceItemId=${requestServiceItemId}`,
    {
      date,
    },
  );
  if (r.data.success) getMyRequestServicesItems(dispatch, pagination);
};

export const sendEventDateCapacitacion = async (
  dispatch,
  date,
  requestServiceItemId,
  course_key,
  pagination,
) => {
  try {
    const r = await post(
      `/request_service_items/event_date_capacitacion?requestServiceItemId=${requestServiceItemId}&course_key=${course_key}`,
      {
        date,
      },
    );
    if (r.data.success) getMyRequestServicesItems(dispatch, pagination);
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateRequestServiceItemData = async (
  dispatch,
  data,
  id,
  pagination,
) => {
  try {
    const r = await put(
      `/request_service_items/update_item_data?requestServiceItemId=${id}`,
      data,
    );
    if (r && r.data && r.data.success)
      getMyRequestServicesItems(dispatch, pagination);

    return r && r.data ? r.data.success : false;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const sendFinishService = async (dispatch, requestServiceItemId) => {
  try {
    const r = await post(
      `/request_service_items/send_finish_service?requestServiceItemId=${requestServiceItemId}`,
    );
    if (r && r.data && r.data.success)
      dispatch({ type: LOADED_REQUEST_SERVICES, payload: r.data.payload });

    return r && r.data ? r.data.success : false;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const saveRequestServiceItemQuiz = async (quizData, dispatch) => {
  try {
    dispatch({ type: LOADING_CREATE_REQUEST_SERVICE_ITEM_QUIZ, payload: true });
    const r = await post('/request_service_items/save_quiz', quizData);

    if (r && r.data && r.data.success) {
      dispatch({
        type: LOADING_CREATE_REQUEST_SERVICE_ITEM_QUIZ,
        payload: false,
      });
      return true;
    }

    dispatch({
      type: LOADING_CREATE_REQUEST_SERVICE_ITEM_QUIZ,
      payload: false,
    });

    return false;
  } catch (error) {
    console.error(error);
    dispatch({
      type: LOADING_CREATE_REQUEST_SERVICE_ITEM_QUIZ,
      payload: false,
    });
    throw error;
  }
};

export const updateRequestServiceItem = async (
  id,
  data,
  dispatch,
  loadList,
  isRisk,
) => {
  try {
    dispatch({ type: LOADING_UPDATE_REQUEST_SERVICE_ITEM, payload: true });
    const r = await put(
      `/request_service_items/update?requestServiceItemId=${id}`,
      data,
    );

    if (r.data.success) {
      loadList && (await loadList());
      dispatch({ type: LOADING_UPDATE_REQUEST_SERVICE_ITEM, payload: false });
      dispatch({
        type: LOADED_REQUEST_SERVICES_CONTACT,
        payload: { data, requestServiceItemId: id },
      });
      isRisk && (await geRiskRequestServiceItems(dispatch, data));

      return true;
    }

    dispatch({ type: LOADING_UPDATE_REQUEST_SERVICE_ITEM, payload: false });

    return false;
  } catch (error) {
    console.error(error);
    dispatch({ type: LOADING_UPDATE_REQUEST_SERVICE_ITEM, payload: false });
    throw error;
  }
};

export const getAdminClients = async (id) => {
  try {
    const r = await get(
      `/request_service_items/get_admin_clients?requestServiceItemId=${id}`,
    );
    if (r.data.success) return r.data.payload;
  } catch (error) {
    console.error(error);
  }

  return [];
};

export const reportInidicatorsWeb = async () => {
  try {
    const r = await post('/request_service_items/report_indicators_web');
    if (r.status === 200) return r.data.payload;
  } catch (error) {
    console.error(error);
  }

  return false;
};

export const checkPendingParticipants = async (requestServiceItemId) => {
  try {
    const r = await get(
      `/request_service_items/check_pending_participants?requestServiceItemId=${requestServiceItemId}`,
    );
    if (r.data.success) return r.data.payload;
  } catch (error) {
    console.error(error);
  }

  return false;
};

export const deleteRequestServiceItemCapacitacionEventDate = async (
  data,
  loadList,
) => {
  try {
    const r = await put(
      '/request_service_items/delete_event_date_capacitacion',
      data,
    );

    if (r.data.success) {
      loadList && (await loadList());

      return r.data.payload;
    }
  } catch (error) {
    console.error(error);
  }

  return false;
};
