export const handleZoomEnd = (
  e,
  lastZoom,
  activeSubLayer,
  subLayerRegionsObj,
  setLastZoom,
) => {
  if (lastZoom < 8 && e.target._zoom >= 8) {
    subLayerRegionsObj.current.setParams({
      layers:
        activeSubLayer.styles === 'dark'
          ? 'cmo:peru_america_sur_dark'
          : 'cmo:peru_america_sur',
    });
  }

  if (lastZoom > 7 && e.target._zoom <= 7) {
    subLayerRegionsObj.current.setParams({
      layers:
        activeSubLayer.styles === 'dark' ? 'cmo:region_dark' : 'cmo:region',
    });
  }
  setLastZoom(e.target._zoom);
};
