import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { Button, Tooltip } from 'antd';
import SkeletonContent from '../../../../../../../common/Skeleton';
import TableCommon from '../../../../../../../common/Table';
import DownloadIcon from '../../../../../../../images/icons/gl_download.svg';
import ButtonReportCertificates from '../../../../../RequestServicesItems/ModalCheckParticipants/ButtonReportCertificates';

moment.locale('es');

const ParticipantList = ({
  data,
  loadingInfo,
  courseInfo,
  status,
  setHasCertificate,
}) => {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [selected] = useState([]);

  const columns = [
    {
      title: 'PARTICIPANTE',
      dataIndex: 'name',
      width: 80,
      key: 'name',
      render: (__, record) => `${record.name} ${record.last_name}`,
    },
    {
      title: 'correo electrónico',
      dataIndex: 'email',
      key: 'email',
      width: 80,
    },
    {
      title: 'DNI',
      dataIndex: 'document',
      key: 'document',
      width: 50,
    },
    {
      title: 'CARGO',
      dataIndex: 'position',
      key: 'position',
      width: 70,
    },
    {
      title: 'NOTA',
      dataIndex: 'score',
      key: 'score',
      width: 30,
    },
    {
      title: '',
      dataIndex: 'certificate',
      width: 30,
      fixed: 'right',
      key: 'certificate',
      render: (url) =>
        url ? (
          <Tooltip title='Descargar certificado'>
            <Button
              className='download-button'
              shape='circle'
              target='_blank'
              href={`${process.env.REACT_APP_WEB_PATH_S3_NEW}${url}`}
            >
              <img alt='icon-download' src={DownloadIcon} />
            </Button>
          </Tooltip>
        ) : null,
    },
  ];

  useEffect(() => {
    setLoading(true);
    setRows(data);
    setLoading(false);
  }, [data]);

  useEffect(() => {
    if (rows.length > 0 && rows[0].certificate) setHasCertificate(true);
    else setHasCertificate(false);
  }, [rows, setHasCertificate]);

  return (
    <>
      {!loading ? (
        <>
          <div className='d_content__list__action mb-4 flex flex-row'>
            <div>
              Se {!isEmpty(selected) ? 'ha seleccionado' : 'encontraron'}{' '}
              <b>{!isEmpty(selected) ? selected.length : rows.length}</b>{' '}
              participantes
            </div>

            {status === 3 && (
              <ButtonReportCertificates
                className='button-certificates-cliente'
                {...courseInfo}
              />
            )}
          </div>

          <TableCommon
            columns={columns}
            dataSource={rows}
            rowKey={(record) => record.id}
            pagination={{ total: rows.length, pageSize: 5 }}
            onChange={null}
            onChangeRows={setRows}
            locale={{
              emptyText: <span>No se cuenta con participantes</span>,
            }}
            loading={loadingInfo}
          />
        </>
      ) : (
        <SkeletonContent type='table' totalItems={3} />
      )}
    </>
  );
};

export default ParticipantList;
