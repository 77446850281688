import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Spin, Row, Col, Button } from 'antd';
import { LoadingOutlined, LeftOutlined } from '@ant-design/icons';
import { useParams } from 'react-router';

import { LOG_IN } from '../../redux/actions/actionsType';
import { getInvitedUser, updateUser } from '../../services/register';
import { simulateLogin } from '../../lib/auth';
import useWindowDimensions from '../../hooks/useWindowDimensions';

import Disclaimer from './Disclaimer';
import DatosEntidad from './DatosEntidad';
import DatosAcceso from './DatosAcceso';

const antIconLoading = (
  <LoadingOutlined
    style={{ fontSize: 56, width: 100, margin: '30px auto' }}
    spin
  />
);

const Register = () => {
  const dispatch = useDispatch();

  const [isLoading, setLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const [maxStep, setMaxStep] = useState(0);
  const [data, setData] = useState({});
  const [user, setUser] = useState(null);
  const { width } = useWindowDimensions();
  const { code } = useParams();

  const loginUser = (data) => {
    const r = simulateLogin(data);
    dispatch({ type: LOG_IN, payload: r });
  };

  const addData = (d, callback) => {
    setData((prevData) => {
      const updatedData = { ...prevData, ...d };
      callback(updatedData);
      return updatedData;
    });
  };

  const handleRegister = async (updatedData) => {
    if (user) {
      const userMerged = { ...user, ...updatedData };
      userMerged.state = 1;
      userMerged.code_invitation = '';
      const userSaved = await updateUser(userMerged);
      await loginUser(userSaved);
      window.location.assign('/monitoreo/mapas');
    }
  };

  useEffect(async () => {
    async function effect() {
      if (code) {
        const resp = await getInvitedUser(code);
        if (resp.data.success && resp.data.user) {
          setLoading(false);
          setUser(resp.data.user);
        } else {
          window.location.assign('/');
        }
      }
    }
    effect();
  }, []);

  useEffect(() => {
    user && user.username && !user.username.endsWith('@rimac.com.pe')
      ? setMaxStep(3)
      : setMaxStep(2);
  }, [user]);

  return (
    <div className='register_content'>
      <header className='register-header'>
        <a href='/' className='logo'>
          <div className='logo__img'>
            <img src='/images/logo.svg' alt='Logo Rimac' />
          </div>
          <hr className='logo__divider' />
          <span className='logo__text'>Centro de Monitoreo y Prevención</span>
        </a>
      </header>
      <Row type='flex' className='flex_register'>
        <Col span={12} style={{ width: width > 1024 ? '37%' : '100%' }}>
          <div className='red_box'>
            <img
              className='recovery-img'
              src='/images/portal/recovery_image_desktop.png'
              alt=''
              aria-hidden
            />
          </div>
        </Col>
        <Col span={12} style={{ width: width > 1024 ? '63%' : '100%' }}>
          <Row className='register_box' direction='column' align='middle'>
            {isLoading && (
              <Spin
                indicator={antIconLoading}
                style={{ width: 100, margin: '20px auto', display: 'block' }}
              />
            )}
            {!isLoading && (
              <div className='card_register_step' bordered={false}>
                <div className='box_header'>
                  <div className='header_movil'></div>
                  <div className='page-header'>
                    <Button
                      shape='circle'
                      size='small'
                      className={currentStep > 0 ? 'btn-active' : ''}
                      onClick={() =>
                        currentStep > 0 && setCurrentStep((step) => step - 1)
                      }
                    >
                      <LeftOutlined className='text-red' />
                    </Button>
                    <span className='text-red'>
                      <span className='movile-hide'>PASO</span>{' '}
                      {currentStep + 1}
                    </span>
                    <span className='text-gray'>DE {maxStep}</span>
                  </div>
                  {currentStep === 0 && (
                    <h1 className='header_1'>
                      Aceptación de <b>Términos y condiciones</b>
                    </h1>
                  )}
                  {currentStep === 1 && (
                    <div>
                      <h1 className='header_1'>
                        Ingresa tus <b>datos de identidad</b>
                      </h1>
                      <div className='subtitle_1'>
                        Para continuar, ingresa tus datos de manera correcta.
                      </div>
                    </div>
                  )}
                  {currentStep === 2 && (
                    <div>
                      <h1 className='header_1'>
                        Crea una <b>contraseña</b>
                      </h1>
                      <p className='subtitle_1'>
                        Estás por crear tu contraseña, te sugerimos cambiarla
                        cada cierto tiempo.
                      </p>
                    </div>
                  )}
                </div>
                {currentStep === 0 && (
                  <Disclaimer setCurrentStep={setCurrentStep} />
                )}
                {currentStep === 1 && (
                  <DatosEntidad
                    setCurrentStep={setCurrentStep}
                    addData={addData}
                    user={user}
                    handleRegister={handleRegister}
                    maxStep={maxStep}
                  />
                )}
                {currentStep === 2 && (
                  <DatosAcceso
                    setCurrentStep={setCurrentStep}
                    addData={addData}
                    handleRegister={handleRegister}
                  />
                )}
              </div>
            )}
          </Row>
        </Col>
      </Row>
      <footer className='copy_right_mobile copy_right_mobile--recovery'>
        {width > 900 && (
          <img
            className='copy_right_mobile__img'
            src='/images/portal/logo-gray.svg'
            alt='Logo de Rimac'
          />
        )}
        © {new Date(Date.now()).getFullYear()} RIMAC Seguros y Reaseguros.
      </footer>
    </div>
  );
};

export default Register;
