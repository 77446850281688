import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, Switch } from 'antd';
import {
  updateCompanyAndUsers,
  getCompanies,
  countUsersByCompany,
} from '../../../../../services/company';

const StateCell = ({ value, item }) => {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [users, setUsers] = useState(0);
  const [activeUsers, setActiveUsers] = useState(0);

  // useEffect(() => {
  //   countUsersByCompany(item).then((response) => {
  //     setUsers(response.payload?.users);
  //     setActiveUsers(response.activeUsers);
  //   });
  // }, []);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    let updatedState = value === 1 ? 2 : 1;

    updateCompanyAndUsers(item, updatedState).then(() => {
      getCompanies(dispatch);
    });
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className='state-business'>
      <span>{value === 1 ? 'Activo' : 'Inactivo'}</span>
      <Switch
        checked={value === 1}
        onChange={showModal}
        disabled={item.changeState}
      />
      <Modal
        title='Cambio de Estado de la Empresa'
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        centered
        okText='Aceptar'
        cancelText='Cancelar'
        okButtonProps={{
          style: {
            color: '#fff',
          },
        }}
        cancelButtonProps={{
          style: { borderColor: '#d9d9d9', color: '#595959' },
        }}
      >
        <div
          style={{
            backgroundColor: '#f0f2f5',
            padding: '20px',
            textAlign: 'center',
            color: '#001529',
          }}
        >
          <h2>¿Estás seguro de que quieres cambiar el estado de la empresa?</h2>
          <p style={{ fontSize: '18px', margin: '10px 0' }}>
            Usuarios Totales: <strong>{users}</strong>
          </p>
          <p style={{ fontSize: '18px', margin: '10px 0' }}>
            Usuarios Activos: <strong>{activeUsers}</strong>
          </p>
        </div>
      </Modal>
    </div>
  );
};

export default StateCell;
