import React, { useEffect } from 'react';
import { Divider, Form, Input, Modal, notification } from 'antd';

import text from '../../../../config/text_es';
import Title from '../../../../common/Typograph/Title';

const ModalForm = ({
  handleClose,
  handleAction,
  data,
  visible,
  isLoadingCreate,
}) => {
  const [form] = Form.useForm();
  const { validateFields, setFieldsValue } = form;

  const handleOk = () => {
    validateFields().then((values) => {
      if (!values.name.trim()) {
        setFieldsValue({ name: '' });
        notification.destroy();
        return notification.warning({
          description: 'Agregue un nombre de póliza válido.',
          message: 'Crear curso!',
        });
      }

      if (!values.code.trim()) {
        setFieldsValue({ code: '' });
        notification.destroy();
        return notification.warning({
          description: 'Agregue un código de póliza válido.',
          message: 'Crear curso!',
        });
      }

      handleAction(values);
      form.resetFields();
    });
  };

  const handleCancel = () => {
    handleClose();
    form.resetFields();
  };

  useEffect(() => {
    const elements = document.querySelectorAll('.ant-popover');
    if (elements.length > 0) {
      elements.forEach((element) => {
        element.classList.add('ant-popover-hidden');
      });
    }
  }, [visible]);

  return (
    <Modal
      open={visible}
      closable={true}
      onCancel={handleCancel}
      centered={true}
      onOk={handleOk}
      cancelText='Cancelar'
      okText={`${data ? 'Guardar cambios' : 'Crear'}`}
    >
      <Title type='bold-28' style={{ textAlign: 'center' }}>{`${
        data ? 'Editar tipo de póliza' : 'Crear tipo de póliza'
      }`}</Title>
      <Divider className='mt-2 mb-1' />
      <Form form={form} disabled={isLoadingCreate}>
        <Form.Item
          name='id'
          initialValue={data ? data.id : ''}
          style={{
            display: 'none',
          }}
        >
          <Input type='hidden' />
        </Form.Item>

        <Form.Item
          name='name'
          label='Nombre'
          initialValue={data ? data?.name : ''}
          rules={[{ required: true, message: text.required_message }]}
        >
          <Input disabled={isLoadingCreate} />
        </Form.Item>

        <Form.Item
          name='code'
          label='Código'
          initialValue={data ? data?.code : ''}
          rules={[{ required: true, message: text.required_message }]}
        >
          <Input disabled={isLoadingCreate} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalForm;
