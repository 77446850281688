import React from 'react';
import { PATH_CONDITIONS_CLAUSES } from '../../lib/consts';

const FooterPortal = () => {
  return (
    <footer className='footer-web'>
      <div className='footer-web-top'>
        <div className='footer-web-top__logo'>
          <a href='/'>
            <img src='/images/portal/logo_blanco.svg' alt='Logo de Rimac' />
          </a>
        </div>
        <div className='footer-web-top__callMe'>
          <a href='tel:014111111'>
            <svg
              width='17'
              height='16'
              viewBox='0 0 17 16'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M13.9999 10.7051L11.9999 9.54008C11.7858 9.4125 11.5318 9.36947 11.2877 9.41942C11.0435 9.46936 10.8268 9.60869 10.6799 9.81009L9.58995 11.2601C8.58933 10.9034 7.66978 10.351 6.88494 9.63508C6.16849 8.84877 5.61605 7.92748 5.25994 6.92509L6.70995 5.84009C6.91135 5.69328 7.05067 5.47656 7.10062 5.23239C7.15056 4.98822 7.10753 4.73419 6.97995 4.5201L5.79494 2.50009C5.72691 2.37463 5.63281 2.26519 5.51897 2.17912C5.40512 2.09305 5.27418 2.03234 5.13493 2.00108C4.99567 1.96982 4.85134 1.96874 4.71164 1.99789C4.57193 2.02704 4.44008 2.08575 4.32495 2.17009L3.63995 2.67009C3.29761 2.90966 3.01831 3.22851 2.82589 3.59941C2.63348 3.97031 2.53366 4.38226 2.53495 4.80009C2.48564 6.01511 2.70216 7.22624 3.16936 8.34892C3.63657 9.4716 4.34322 10.4788 5.23995 11.3001C6.06127 12.1968 7.06843 12.9035 8.19111 13.3707C9.31379 13.8379 10.5249 14.0544 11.7399 14.0051C12.1578 14.0064 12.5697 13.9066 12.9406 13.7141C13.3115 13.5217 13.6304 13.2424 13.8699 12.9001L14.3699 12.2151C14.4601 12.0965 14.5227 11.9592 14.553 11.8133C14.5833 11.6674 14.5807 11.5165 14.5452 11.3718C14.5097 11.2271 14.4424 11.0921 14.348 10.9767C14.2537 10.8614 14.1348 10.7686 13.9999 10.7051Z'
                fill='currentColor'
              />
            </svg>
            Emergencias al 01 411 1111
          </a>
        </div>
        <div className='footer-web-top__list'>
          <span>Información</span>
          <ul>
            <li>
              <a href='/monitoreo'>Sobre Monitoreo</a>
            </li>
            <li>
              <a href='/prevencion'>Sobre Prevención</a>
            </li>
          </ul>
        </div>
        <div className='footer-web-top__list'>
          <span>Biblioteca Virtual</span>
          <ul>
            <li>
              <a href='/articulos'>Artículos</a>
            </li>
            <li>
              <a
                href={`${process.env.REACT_APP_WEB_PATH_S3_NEW}${PATH_CONDITIONS_CLAUSES}`}
              >
                Condicionados y Cláusulas
              </a>
            </li>
          </ul>
        </div>
        <div className='footer-web-top__list'>
          <span>Seguros</span>
          <ul>
            <li>
              <a
                target='_blank'
                href='https://www.rimac.com/salud/seguro-salud'
              >
                Seguro de salud
              </a>
            </li>
            <li>
              <a target='_blank' href='https://www.rimac.com/vehicular'>
                Seguro vehicular
              </a>
            </li>
            <li>
              <a target='_blank' href='https://www.rimac.com/vida'>
                Seguro de vida
              </a>
            </li>
            <li>
              <a target='_blank' href='https://www.rimac.com/inversion'>
                Seguro de inversión
              </a>
            </li>
            <li>
              <a
                target='_blank'
                href='https://www.rimac.com/vida/seguro-universitario'
              >
                Seguro universitario
              </a>
            </li>
            <li>
              <a target='_blank' href='https://www.rimac.com/consulta-soat'>
                SOAT
              </a>
            </li>
            <li>
              <a target='_blank' href='https://www.rimac.com/salud/eps'>
                EPS
              </a>
            </li>
          </ul>
        </div>
        <div className='footer-web-top__help'>
          <img src='/images/portal/footer_contact.png' alt='' aria-hidden />
          <div className='footer-web-top__help-text'>
            <span className='footer-web-top__help-'>
              ¡Estamos para ayudarte!
            </span>
            <span>Emergencias</span>
            <span>01 411 1111</span>
          </div>
        </div>
      </div>
      <div className='footer-web-down'>
        <small className='footer-web-down-copy'>
          © {new Date(Date.now()).getFullYear()} RIMAC Seguros y Reaseguros.
        </small>
      </div>
    </footer>
  );
};

export default FooterPortal;
