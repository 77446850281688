import { useState } from 'react';
import { tileLayers } from './leafletMapConfig';

const useMapState = () => {
  const [selectLayer, setSelectLayer] = useState('satellite');
  const [selectTileLayer, setSelectTileLayer] = useState('openstreetmap');

  const changeTileLayer = (newLayerKey) => {
    const selectedLayer = tileLayers.find((layer) => layer.key === newLayerKey);
    if (selectedLayer) {
      setSelectTileLayer(newLayerKey);
    }
  };

  const currentTileLayer = tileLayers.find(
    (layer) => layer.key === selectTileLayer,
  );

  return {
    selectLayer,
    setSelectLayer,
    currentTileLayer,
    changeTileLayer,
  };
};

export default useMapState;
