/* eslint-disable max-len */
import React from 'react';
import { Button } from 'antd';

const Disclaimer = ({ setCurrentStep }) => {
  return (
    <div className='card_register_content'>
      <div className='box_terms_conditions'>
        <div className='title_1'>Términos y condiciones generales de uso</div>
        <p>
          Los Términos y Condiciones de Uso que a continuación se describen son
          de carácter general y resultan aplicables al uso del contenido,
          productos y/o servicios disponibles bajo el sitio Web
          https://centrodemonitoreo.rimac.com. Al momento que el usuario
          ingresa, declara no sólo haber leído los Términos y Condiciones de
          Uso, sino también haber comprendido y aceptado el contenido de éstos.
          Si el usuario no está de acuerdo con los términos y condiciones que
          aparecen a continuación, deberá abstenerse de ingresar a esta zona, ni
          a ninguna de sus páginas.
        </p>
        <div className='title_2'>
          RESPONSABILIDAD DEL USUARIO Y DEL USUARIO ADMINISTRADOR
        </div>
        <p>
          El Usuario accederá a su cuenta personal mediante el ingreso de su
          correo electrónico corporativo y clave de seguridad personal
          (Contraseña). El Usuario se obliga a mantener la confidencialidad de
          su clave de seguridad. La cuenta creada para este sitio Web es
          personal, única e intransferible. El Usuario será responsable por el
          manejo de su cuenta, pues el acceso a la misma está restringido al
          ingreso y uso de su Clave de Seguridad, de conocimiento exclusivo del
          Usuario. El Usuario se compromete a notificar a RIMAC SEGUROS en forma
          inmediata y por medio idóneo y fehaciente, cualquier uso no autorizado
          de su Cuenta, así como el ingreso por terceros no autorizados a la
          misma. Asimismo, cada usuario administrador es responsable de
          registrar y validar por la veracidad de los datos de los usuarios de
          su empresa. El usuario administrador es el único facultado a través de
          las credenciales otorgadas a solicitar los servicios de prevención a
          nombre de su empresa.
        </p>
        <div className='title_2'>USO DE INFORMACIÓN:</div>
        <p>
          Los usuarios podrán acceder gratuitamente a través de esta Web a la
          información y servicios que tienen como finalidad la prevención
          patrimonial. Esta información y servicios forma parte de la oferta de
          valor de la Póliza Multiriesgo que su empresa mantiene contratada con
          RIMAC SEGUROS. Los informes vinculados a los servicios a los que
          pueden acceder a través de esta Web son de carácter confidencial y
          solo podrán ser descargados por los usuarios administradores con el
          usuario y contraseña que se le ha brindado. Rimac no podrá compartir
          con terceros (salvo autorización otorgada por el usuario administrador
          a través de correo electrónico con dominio de la empresa), los
          informes técnicos ni otro tipo de información que se haya ingresado a
          la Web como parte de las gestiones de inscripción de los usuarios de
          su empresa. Todos los usuarios en general a través de sus contraseñas
          pueden acceder a los cursos virtuales y son responsables de culminar
          sus cursos y de la nota que obtengan.
        </p>
        <div className='title_2'>INFORMACIÓN ENTREGADA A RÍMAC SEGUROS</div>
        <p>
          Ley 29733 – Ley de Protección de Datos Personales y su Reglamento De
          conformidad con lo establecido en la Ley 29733 - Ley de Protección de
          Datos Personales (la “Ley”) y en el Decreto Supremo 003-2013/JUS -
          Reglamento de la Ley (el “Reglamento”), la persona quien acepta esta
          política de protección de datos personales (el “usuario”), queda
          informado y da su consentimiento libre, previo, expreso, inequívoco e
          informado, para el tratamiento de sus datos personales en el banco de
          datos de titularidad de RIMAC SEGUROS (“RIMAC SEGUROS”), conjuntamente
          con cualquier otro dato personal que pudiera facilitarse como parte de
          la solicitud realizada por el usuario a lo largo de la relación
          contractual que pudieran entablar con RIMAC SEGUROS, así como aquellos
          datos obtenidos en fuentes accesibles al público (los “Datos
          Personales”). Los Datos Personales se tratarán con las finalidades de
          dar cumplimiento a la solicitud realizada por el usuario, dar
          cumplimiento y ejecutar la relación comercial y contractual que
          entablará el usuario y RIMAC SEGUROS, para fines estadísticos,
          analíticos y de comportamiento del usuario, analizar las
          circunstancias al celebrar contratos con La Aseguradora, gestionar la
          contratación y evaluar la calidad del servicio. Para el cumplimiento
          de estas finalidades, se podrá realizar un tratamiento por encargo a
          terceros. RIMAC SEGUROS utilizará los datos personales con fines
          comerciales, y publicitarios a fin de remitir, enviar o comunicar
          información, ofertas o promociones sobre productos y servicios que
          RIMAC SEGUROS considere de interés del usuario, a través de cualquier
          medio de comunicación disponible en el mercado. Para el cumplimiento
          de esta finalidad, los Datos Personales podrán ser facilitados a
          terceros a través de un tratamiento por encargo. Se podrá realizar
          transferencia de los Datos Personales en territorio nacional, así como
          al extranjero, llevándose a cabo un flujo transfronterizo, con las
          finalidades indicadas anteriormente, otorgando el usuario su
          consentimiento para que los destinatarios de los Datos Personales
          puedan realizar el tratamiento de los mismos. Al respecto, el receptor
          en el extranjero de datos personales, asumirá las mismas obligaciones
          y medidas de seguridad, técnicas y legales que le corresponden a RIMAC
          SEGUROS, conforme a lo establecido en la Ley y Reglamento. Los Datos
          Personales serán incorporados, con las mismas finalidades, en los
          bancos de datos de RIMAC SEGUROS y/u otras empresas subsidiarias,
          filiales, asociadas, afiliadas o miembros del Grupo Económico al cual
          pertenece RIMAC SEGUROS y/o terceros que realice un tratamiento por
          encargo, con quienes se entablará una relación contractual. Los bancos
          de datos donde se almacenarán los Datos Personales, cuentan con
          estrictas medidas de seguridad técnica y organizativa. En caso se
          decida no aceptar esta Política, no será posible la atención de la
          solicitud realizada a RIMAC SEGUROS o la ejecución de la relación
          contractual que ha entablado el usuario con RIMAC SEGUROS. Conforme a
          Ley, el titular de la información está facultado a ejercitar los
          derechos ARCO (información, acceso, rectificación, supresión o
          cancelación y oposición) que se detallan en la Ley y su Reglamento,
          para lo cual puede comunicarse a la central telefónica 411-1111, al
          correo electrónico atencionalcliente@rimac.com.pe o acercarse a
          cualquiera de nuestras oficinas en Lima y provincias. El titular del
          banco de datos personales es RIMAC SEGUROS Y REASEGUROS, el cual tiene
          su domicilio legal en Av. Paseo de la República 3505 Piso 11 – San
          Isidro, Lima – Perú.
        </p>
        <div className='title_2'>
          LÍMITES DE RESPONSABILIDAD POR FALLAS INFORMATICAS Y AFINES
        </div>
        <p>
          En ningún caso RIMAC se hará responsable por alguna clase de daño
          directo o indirecto, incidentales, pérdidas o gastos que surjan en
          relación a este sitio Web, o a cualquier otro sitio Web enlazado a
          éste, o en conexión al uso de éstos, debido a la falta de capacidad
          para usarlos o al mal manejo que pudiera realizar cualquier persona,
          debido a cualquier interrupción, defecto, virus de computadora, demora
          en la operación, transición o falla del sistema.
        </p>
        <div className='title_2'>DISPONIBILIDAD</div>
        <p>
          Se encuentra prohibido el uso de este sitio Web por parte de cualquier
          persona para fines contrarios a las normas de orden público.
        </p>
        <div className='title_2'>TÉRMINOS ADICIONALES</div>
        <p>
          Ciertas secciones o páginas de este sitio Web pueden contener términos
          y condiciones que sean adicionales a los presente Términos y
          Condiciones de Uso que aquí se mencionan. En caso de conflicto, los
          términos y condiciones adicionales prevalecerán en las secciones o
          páginas donde aparezcan. En el supuesto que alguna de las
          disposiciones contenidas en los presentes Términos y Condiciones
          Generales de Uso sea declarado ilegal, inválido o nulo, el resto de
          términos quedara vigente y aplicable para cualquier supuesto.
        </p>
        <div className='title_2'>LAS LEYES QUE RIGEN</div>
        <p>
          El uso de este sitio Web se regirá por todas las leyes aplicables de
          la República del Perú.
        </p>
      </div>
      <div style={{ textAlign: 'right' }}>
        <Button
          type='primary'
          onClick={() => setCurrentStep(1)}
          style={{ marginRight: '60px' }}
        >
          <b>Aceptar y continuar</b>
        </Button>
      </div>
    </div>
  );
};

export default Disclaimer;
