import { ceil } from './utils';

var axios = require('axios');
const baseLeafletApiUrl = `${process.env.REACT_APP_GEOSERVER_MAPS_NEW}?bbox=@lng,@lat,@lng2,@lat2&styles=&format=jpeg&request=GetFeatureInfo&layers=@layer&query_layers=@layer&width=2&height=2&x=1&y=1&INFO_FORMAT=application/json`;

const CancelToken = axios.CancelToken;
let cancel = CancelToken.source();

export const getDataPointLeaflet = async (point, subLayerActive) => {
  const lat = ceil(point.lat, 4),
    lng = ceil(point.lng, 4);
  const lat2 = ceil(lat + 0.0001, 4),
    lng2 = ceil(lng + 0.0001, 4);

  if (!subLayerActive) return false;

  const { layersId } = subLayerActive;
  const layer = layersId;
  const time = Number(window.currentTime);
  let ApiUrl = baseLeafletApiUrl
    .replace('@lat', lat)
    .replace('@lng', lng)
    .replace('@lat2', lat2)
    .replace('@lng2', lng2)
    .replace(/@layer/g, `cmo:${layer}_${time || '1'}`);
  let result;

  try {
    const response = await axios.get(ApiUrl, { cancelToken: cancel.token });
    result = response.data;
  } catch (e) {
    result = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          id: '',
          geometry: null,
          properties: {
            GRAY_INDEX: 20.617950439453125,
          },
        },
      ],
      totalFeatures: 'unknown',
      numberReturned: 1,
      timeStamp: '2021-12-13T17:13:10.829Z',
      crs: null,
    };
  }

  return result;
};

export const getDataPointLayer = async (point, layer) => {
  const lat = ceil(point.lat, 4),
    lng = ceil(point.lng, 4);
  const lat2 = ceil(lat + 0.0001, 4),
    lng2 = ceil(lng + 0.0001, 4);

  let ApiUrl = baseLeafletApiUrl
    .replace('@lat', lat)
    .replace('@lng', lng)
    .replace('@lat2', lat2)
    .replace('@lng2', lng2)
    .replace(/@layer/g, `cmo:${layer}`);
  let result;

  try {
    const response = await axios.get(ApiUrl);
    result = response.data;
  } catch (e) {
    result = {
      type: 'FeatureCollection',
      features: [
        {
          type: 'Feature',
          id: '',
          geometry: null,
          properties: {
            GRAY_INDEX: 20.617950439453125,
          },
        },
      ],
      totalFeatures: 'unknown',
      numberReturned: 1,
      timeStamp: '2021-12-13T17:13:10.829Z',
      crs: null,
    };
  }

  return result;
};
