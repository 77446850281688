import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { Popup } from 'react-leaflet';
import { DeleteIcon, EditIcon } from '../../utils';
import { checkPermission } from '../../../../lib/security';
import { removeRoadEmergency } from '../../../../services/roadEmergency';
import dayjs from 'dayjs';

import CODE_EQUIVALENCE from './codeEquivalence';

const antIcon = (
  <LoadingOutlined
    style={{ fontSize: 56, width: 100, margin: '30px auto' }}
    spin
  />
);

const PopupCMP = ({ point, isLoading, openForm, popUpData }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const filters = useSelector((state) => state.roadEmergency.filters);

  const authorized = ['admin_rimac', 'gestor_de_cuenta'].includes(
    user?.rol?.slug,
  );

  const removePoint = (id) => removeRoadEmergency({ id, dispatch, filters });

  return (
    <Popup>
      {isLoading ? (
        <div
          style={{
            display: 'grid',
            placeContent: 'center',
            minWidth: '299px',
            minHeight: '352px',
          }}
        >
          <Spin size='large' indicator={antIcon} />
        </div>
      ) : (
        <div className='popup-map'>
          <div className='popup-title'>
            <span style={{ fontWeight: 'bold', color: '#ea8402' }}>
              {'EMERGENCIA VIAL - CMP'}
            </span>
            {authorized &&
              checkPermission(user, 'administrate_manual_points_emergency') && (
                <>
                  <span
                    className='icon-title flex items-baseline'
                    onClick={openForm}
                  >
                    <EditIcon />
                    <span className='tooltipText ml-1'>Editar emergencia</span>
                  </span>
                  <span
                    className='icon-title flex items-baseline'
                    onClick={() => removePoint(point.id)}
                  >
                    <DeleteIcon />
                    <span className='tooltipText ml-1'>
                      Eliminar emergencia
                    </span>
                  </span>
                </>
              )}
          </div>
          <div
            className='popup-content popup-map-scrollable'
            style={{ maxHeight: 220 }}
          >
            <span className='popup-detail'>
              {popUpData?.idEmergenciaVial &&
                popUpData?.idEmergenciaVial !== '' && (
                  <>
                    <span className='popup-detail-header'>CÓDIGO:</span>
                    <span className='popup-detail-content'>
                      {popUpData?.idEmergenciaVial}
                    </span>
                  </>
                )}
              {popUpData?.fechaEmergencia &&
                popUpData?.fechaEmergencia !== '' && (
                  <>
                    <span className='popup-detail-header'>
                      FECHA OCURRENCIA:
                    </span>
                    <span className='popup-detail-content'>
                      {dayjs(popUpData?.fechaEmergencia).format('DD/MM/YYYY')}
                    </span>
                  </>
                )}
              {popUpData?.codRuta && popUpData?.codRuta !== '' && (
                <>
                  <span className='popup-detail-header'>RUTA:</span>
                  <span className='popup-detail-content'>
                    {popUpData?.codRuta}
                  </span>
                </>
              )}
              {(popUpData?.progresivaInicial || popUpData?.progresivaFinal) &&
                (popUpData?.progresivaInicial !== '' ||
                  popUpData?.progresivaFinal !== '') && (
                  <>
                    <span className='popup-detail-header'>PROGRESIVA:</span>
                    <span className='popup-detail-content'>
                      {popUpData?.progresivaInicial || ''} -{' '}
                      {popUpData?.progresivaFinal || ''}
                    </span>
                  </>
                )}
              {popUpData?.ubigeo && popUpData?.ubigeo !== '' && (
                <>
                  <span className='popup-detail-header'>UBIGEO:</span>
                  <span className='popup-detail-content'>
                    {popUpData?.ubigeo}
                  </span>
                </>
              )}
              {popUpData?.ubicacion && popUpData?.ubicacion !== '' && (
                <>
                  <span className='popup-detail-header'>UBICACIÓN:</span>
                  <span className='popup-detail-content'>
                    {popUpData?.ubicacion}{' '}
                  </span>
                </>
              )}
              {popUpData?.tipoAdministra &&
                popUpData?.tipoAdministra !== '' && (
                  <>
                    <span className='popup-detail-header'>
                      TIPO ADMINISTRACIÓN:
                    </span>
                    <span className='popup-detail-content'>
                      {popUpData?.tipoAdministra}
                    </span>
                  </>
                )}
              {popUpData?.concesion && popUpData?.concesion !== '' && (
                <>
                  <span className='popup-detail-header'>CONCESIÓN:</span>
                  <span className='popup-detail-content'>
                    {popUpData?.concesion}
                  </span>
                </>
              )}
              {popUpData?.contratista && popUpData?.contratista !== '' && (
                <>
                  <span className='popup-detail-header'>CONTRATISTA:</span>
                  <span className='popup-detail-content'>
                    {popUpData?.contratista}
                  </span>
                </>
              )}
              {popUpData?.zonal && popUpData?.zonal !== '' && (
                <>
                  <span className='popup-detail-header'>ZONAL:</span>
                  <span className='popup-detail-content'>
                    {popUpData?.zonal}
                  </span>
                </>
              )}
              {(popUpData?.fechaActualizacion ||
                popUpData?.ultimaActualizacion) && (
                <>
                  <span className='popup-detail-header'>
                    Último Reporte -{' '}
                    {dayjs(popUpData?.fechaActualizacion).format('DD/MM/YYYY')}
                  </span>
                  <span className='popup-detail-content'>
                    {popUpData?.ultimaActualizacion}
                  </span>
                </>
              )}
              {(point?.latitud || point?.longitud) &&
                (point?.latitud !== '' || point?.longitud !== '') && (
                  <>
                    <span className='popup-detail-header'>COORDENADAS</span>
                    <span className='popup-detail-content'>
                      {point?.latitud}, {point?.longitud}
                    </span>
                  </>
                )}
            </span>
          </div>
          <div className='popup-footer-road-emergency'>
            <div
              style={{
                borderTop: '3px solid #FF9800',
                display: 'inline-flex',
                width: '100%',
              }}
            >
              <div className='item '>
                <div className='popup-detail-header'>CONDICIÓN</div>
                <div className='popup-detail-content'>
                  {popUpData?.condicionTransito ??
                    CODE_EQUIVALENCE[popUpData?.condicionTransitoCodigo] ??
                    ''}
                </div>
              </div>
              <div className='item'>
                <div className='popup-detail-header'>TIPO EMERGENCIA</div>
                <div className='popup-detail-content'>
                  {popUpData?.tipoEmergencia}
                </div>
              </div>
              {popUpData?.rutasAlt && popUpData?.rutasAlt?.trim() !== '' && (
                <div
                  className='item'
                  style={{
                    borderTop: '3px solid #cc3f44',
                    width: '100%',
                  }}
                >
                  <div className='popup-detail-header'>RUTAS ALTERNAS</div>
                  <div className='popup-detail-content'>
                    {popUpData?.rutasAlt}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </Popup>
  );
};

export default PopupCMP;
