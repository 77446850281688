import React from 'react';
import MainTitle from '../../common/MainTitle';

const PrecipitationEFM = () => {
  return (
    <>
      <MainTitle title='Precipitación EFM' description='Precipitación EFM' />
    </>
  );
};

export default PrecipitationEFM;
