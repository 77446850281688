import { LOADED_ROLS } from '../actions/actionsType';

const initialState = {
  rols: [],
};

const rols = (state, action) => {
  if (!state) {
    state = initialState;
  }

  switch (
    action.type // NOSONAR
  ) {
    case LOADED_ROLS:
      return {
        ...state,
        rols: action.payload,
      };
    default:
      return state;
  }
};

export default rols;
