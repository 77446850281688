import { useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';
import axios from 'axios';
import L from 'leaflet';
import proj4 from 'proj4';

const wmsUrl = process.env.REACT_APP_GEOSERVER_MAPS_NEW;

const proj4EPSG4326 = '+proj=longlat +datum=WGS84 +no_defs';
const proj4EPSG3857 =
  '+proj=merc +lon_0=0 +k=1 +x_0=0 +y_0=0 +datum=WGS84 +units=m +no_defs';

const GeologicalHazardsLayer = () => {
  const map = useMap();
  const layerRef = useRef(null);

  const removeCurrentLayer = () => {
    if (layerRef.current) {
      map.removeLayer(layerRef.current);
      layerRef.current = null;
    }
  };

  const updateBounds = () => {
    const zoomLevel = map.getZoom();
    if (zoomLevel >= 10) {
      const bounds = map.getBounds();
      const minLatLng = bounds.getSouthWest();
      const maxLatLng = bounds.getNorthEast();

      const minXY = proj4(proj4EPSG4326, proj4EPSG3857, [
        minLatLng.lng,
        minLatLng.lat,
      ]);
      const maxXY = proj4(proj4EPSG4326, proj4EPSG3857, [
        maxLatLng.lng,
        maxLatLng.lat,
      ]);

      console.log(
        'Bounds (EPSG:3857):',
        `${minXY[0]},${minXY[1]},${maxXY[0]},${maxXY[1]}`,
      );

      const url = `${wmsUrl}?service=WFS&version=1.0.0&request=GetFeature&typeName=cmo%3Amapa_PeligrosGeologicos&outputFormat=application%2Fjson&styles=PeligrosGeologicos&bbox=${minXY[0]},${minXY[1]},${maxXY[0]},${maxXY[1]}`;
      console.log('URL:', url);

      axios
        .get(url)
        .then((response) => {
          console.log('Data:', response.data);
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  };

  useEffect(() => {
    const staticLayer = L.tileLayer.wms(wmsUrl, {
      layers: 'mapa_PeligrosGeologicos',
      styles: '',
      version: '1.3.0',
      format: 'image/png',
      tileSize: 256,
      transparent: true,
    });

    layerRef.current = staticLayer.addTo(map);

    const handleZoomEnd = () => {
      updateBounds();
      if (map.getZoom() >= 10) {
        map.on('moveend', updateBounds);
      } else {
        map.off('moveend', updateBounds);
      }
    };

    map.on('zoomend', handleZoomEnd);

    return () => {
      map.off('zoomend', handleZoomEnd);
      map.off('moveend', updateBounds);
      removeCurrentLayer();
    };
  }, [map]);

  return null;
};

export default GeologicalHazardsLayer;
