import {
  LOADED_BUSINESS_SEGMENT,
  LOAD_BUSINESS_SEGMENT_LOADING,
  LOADING_CREATE_BUSINESS_SEGMENT,
  OPEN_FORM_BUSINESS_SEGMENT,
} from '../actions/actionsType';

const initialState = {
  list: [],
  openedForm: false,
  isLoading: false,
  isLoadingCreate: false,
  page: 1,
  total: 0,
  pagination: {
    pageSize: 5,
    current: 1,
    total: 0,
  },
};

const businessSegment = (state, action) => {
  if (!state) state = initialState;

  switch (action.type) {
    case LOAD_BUSINESS_SEGMENT_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case LOADED_BUSINESS_SEGMENT:
      return {
        ...state,
        list: action.payload.list,
        page: action.payload.page,
        total: action.payload.total,
        pagination: {
          current: action.payload.page,
          pageSize: action.payload.items_per_page,
          total: action.payload.total,
        },
        isLoading: false,
      };
    case OPEN_FORM_BUSINESS_SEGMENT:
      return {
        ...state,
        openedForm: action.payload,
      };
    case LOADING_CREATE_BUSINESS_SEGMENT:
      return {
        ...state,
        isLoadingCreate: action.payload,
      };
    default:
      return state;
  }
};

export default businessSegment;
