import React from 'react';
import { Button, Upload } from 'antd';
import { UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';
import { read, utils } from 'xlsx';

const ButtonUploadParticipants = ({
  loading,
  setParticipants,
  template,
  withScore,
}) => {
  const handleUpload = (file) => {
    let reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onloadend = (e) => {
      let data = new Uint8Array(e.target.result);
      let workbook = read(data, { type: 'array' });
      try {
        workbook.SheetNames.slice(0, 1).forEach(function (sheetName) {
          const XL_row_object = utils.sheet_to_row_object_array(
            workbook.Sheets[sheetName],
          );
          const filtered = processExcelRows(XL_row_object);
          setParticipants(filtered);
        });
      } catch (err) {
        console.error(err);
      }
    };
    return false;
  };

  const processExcelRows = (rows) =>
    rows
      .filter((row) => row['NOMBRES'] || row['APELLIDOS'])
      .map((row) => ({
        key: uuidv4(),
        name: row['NOMBRES'],
        last_name: row['APELLIDOS'],
        email: row['CORREO'],
        document: row['DNI'],
        position: row['AREA ó CARGO'],
        state: 1,
        ...(withScore && { score: row['NOTA'] }),
      }));

  return (
    <>
      <Upload beforeUpload={handleUpload}>
        <Button loading={loading}>
          <UploadOutlined />
          Cargar lista de participantes
        </Button>
      </Upload>
      <p style={{ marginTop: 16 }}>
        <a
          target='_blank'
          href={
            template
              ? template
              : '/templates/PlantillaCapacitacionParticipantes.xlsx'
          }
          rel='noreferrer'
        >
          Descargar plantilla de participantes <DownloadOutlined />
        </a>
      </p>
    </>
  );
};

export default ButtonUploadParticipants;
