import axios, { signedAxios } from './axios';
import { Auth } from 'aws-amplify';
import { getUserNameSimulate, removeSimulateUser } from '../services/users';
import { getUrlAPI, isApiCanal } from './utils';

const pathAwsCognito = `https://cognito-identity.${process.env.REACT_APP_COGNITO_REGION}.amazonaws.com/`;

const generateIdentity = async () => {
  const payload = {
    IdentityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
  };
  const response = await axios.post(pathAwsCognito, payload, {
    headers: {
      'content-type': 'application/x-amz-json-1.1',
      'x-amz-target': 'AWSCognitoIdentityService.GetId',
    },
  });
  return response.data.IdentityId;
};

const generateCredentials = async (identityId) => {
  const payload = {
    IdentityId: identityId,
  };
  const response = await axios.post(pathAwsCognito, payload, {
    headers: {
      'content-type': 'application/x-amz-json-1.1',
      'x-amz-target': 'AWSCognitoIdentityService.GetCredentialsForIdentity',
    },
  });
  return response.data.Credentials;
};

export const get = async (url, withAuth = true) => {
  const pathGateway = getUrlAPI(url, withAuth);

  if (withAuth) {
    return createSignRequest('GET', pathGateway + url);
  } else {
    const identityId = await generateIdentity();
    const credentials = await generateCredentials(identityId);
    return createSignRequest('GET', pathGateway + url, false, credentials);
  }
};

export const post = async (url, data = {}, withAuth = true) => {
  const pathGateway = getUrlAPI(url, withAuth);

  if (withAuth) {
    return createSignRequest('POST', pathGateway + url, data);
  } else {
    const identityId = await generateIdentity();
    const credentials = await generateCredentials(identityId);
    return createSignRequest('POST', pathGateway + url, data, credentials);
  }
};

export const put = async (url, data = {}, withAuth = true) => {
  const pathGateway = getUrlAPI(url, withAuth);

  if (withAuth) {
    return createSignRequest('PUT', pathGateway + url, data);
  } else {
    const identityId = await generateIdentity();
    const credentials = await generateCredentials(identityId);
    return createSignRequest('PUT', pathGateway + url, data, credentials);
  }
};

export const patch = async (url, data = {}, withAuth = true) => {
  const pathGateway = getUrlAPI(url, withAuth);

  if (withAuth) {
    return createSignRequest('PATCH', pathGateway + url, data);
  } else {
    const identityId = await generateIdentity();
    const credentials = await generateCredentials(identityId);
    return createSignRequest('PATCH', pathGateway + url, data, credentials);
  }
};

export const del = async (url, withAuth = true) => {
  const pathGateway = getUrlAPI(url, withAuth);
  if (withAuth) {
    return createSignRequest('DEL', pathGateway + url);
  } else {
    const identityId = await generateIdentity();
    const credentials = await generateCredentials(identityId);
    return createSignRequest('DEL', pathGateway + url, false, credentials);
  }
};

const dataAuth = {};

export const getPathCredentials = async (path) => {
  const currentTimeSeconds = Math.round(+new Date() / 1000);
  if (dataAuth.session.getAccessToken().getExpiration() < currentTimeSeconds) {
    await refreshAccessToken(true);
    dataAuth.session = await Auth.currentSession();
    dataAuth.credentials = await Auth.currentUserCredentials();
  }

  if (localStorage.getItem('simulate_user')) {
    path += `${path.includes('?') ? '&' : '?'}su=${localStorage.getItem(
      'simulate_user',
    )}`;
  }

  return {
    url: path,
    credentials: dataAuth.credentials,
    session: dataAuth.session,
  };
};

const createSignRequest = async (
  method,
  path,
  body = false,
  credentials = null,
) => {
  const currentTimeSeconds = Math.round(+new Date() / 1000);
  const host = (
    isApiCanal(path)
      ? process.env.REACT_APP_API_BASE_URL_CANAL
      : process.env.REACT_APP_API_BASE_URL_NEW
  )
    .replace('https://', '')
    .replace('/', '');

  if (credentials) {
    return signedAxios(
      {
        host,
        method,
        url:
          (isApiCanal(path)
            ? process.env.REACT_APP_API_BASE_URL_CANAL
            : process.env.REACT_APP_API_BASE_URL_NEW) + path,
        path,
        data: body,
      },
      {
        credentials: {
          accessKeyId: credentials.AccessKeyId,
          secretAccessKey: credentials.SecretKey,
          sessionToken: credentials.SessionToken,
          expiration: credentials.Expiration,
        },
      },
    );
  }

  try {
    if (!dataAuth.session) {
      dataAuth.session = await Auth.currentSession();
      dataAuth.credentials = await Auth.currentUserCredentials();
    }

    if (dataAuth.session) {
      if (
        dataAuth.session.getAccessToken().getExpiration() < currentTimeSeconds
      ) {
        await refreshAccessToken(true);
        dataAuth.session = await Auth.currentSession();
        dataAuth.credentials = await Auth.currentUserCredentials();
      }

      if (localStorage.getItem('simulate_user')) {
        path += `${path.includes('?') ? '&' : '?'}su=${localStorage.getItem(
          'simulate_user',
        )}`;
      }

      return signedAxios(
        {
          host,
          method,
          url:
            (isApiCanal(path)
              ? process.env.REACT_APP_API_BASE_URL_CANAL
              : process.env.REACT_APP_API_BASE_URL_NEW) + path,
          path: path,
          data: body,
        },
        dataAuth,
      );
    }
  } catch (e) {
    console.error('e', e.message);

    return;
  }
};

const refreshAccessToken = (force = false) =>
  Auth.currentSession()
    .then((session) => {
      const idTokenExpire = session.getAccessToken().getExpiration();
      const refreshToken = session.getRefreshToken();
      const currentTimeSeconds = Math.round(+new Date() / 1000);
      if (force || idTokenExpire < currentTimeSeconds) {
        Auth.currentAuthenticatedUser().then((res) => {
          res.refreshSession(refreshToken, (err, data) => {
            if (err) {
              Auth.signOut();
              const currentUrl = new URL(window.location.href);
              const newUrl = new URL('/login', currentUrl);
              window.location.href = newUrl.href;
            } else {
              setAuthorizationHeader(data.getAccessToken().getJwtToken());
            }
          });
        });
      } else {
        setAuthorizationHeader(session.getAccessToken().getJwtToken());
      }
    })
    .catch(() => {
      // No logged-in user: don't set auth header
    });

export const setAuthorizationHeader = (token) => {
  axios.defaults.headers.common['Authorization'] = token;
  if (getUserNameSimulate()) {
    axios.defaults.headers.common['Authorization'] =
      `${token}\(-\)${localStorage.getItem('simulate_user')}`;
  }
};

export const clearAuthorizationHeader = () => {
  if (getUserNameSimulate()) {
    const headersAuth =
      axios.defaults.headers.common.Authorization.split(/\(-\)/);
    axios.defaults.headers.common['Authorization'] = headersAuth[0];
    removeSimulateUser();
  } else {
    const token = axios.defaults.headers.common.Authorization;
    axios.defaults.headers.common.Authorization = null;
    delete axios.defaults.headers.common.Authorization;

    return token;
  }
};
