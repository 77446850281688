import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { Divider, Modal, notification } from 'antd';

import { useScreen } from '../../../../lib/screen';
import { getReportURL } from '../../../../services/myRoutes';
import {
  MAP_SET_ACTIVE_LAYER,
  MAP_SET_ACTIVE_SUBLAYER,
} from '../../../../redux/actions/actionsType';

import { layers } from './../../../../common/SideMenu/data';
import Title from '../../../Typograph/Title';
import { IconCloseCircle } from '../../../Icons';
import { selectSublayer } from '../../../../components/Map/utils';

const ResendModal = ({ visible, onCancel, route }) => {
  const { isMobileScreen } = useScreen();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onOk = async () => {
    setLoading(true);
    try {
      const res = await axios.get(route?.url);
      if (res.status === 200) {
        const geojson = res.data;
        const points = [];

        if (!geojson.features) {
          notification.error({ message: 'No hay ruta para mostrar.' });
          return;
        }

        geojson.features.map((feature) => {
          if (feature.geometry.type === 'Point') {
            const coordinate = feature.geometry.coordinates;
            points.push({ lat: coordinate[1], lng: coordinate[0] });
          }
        });

        const url = await getReportURL({
          routePoints: points,
          uuid: route?.uuid,
        });
        window.open(url);
      } else {
        notification.error({
          message: 'Ha ocurrido un error al generar el reporte.',
        });
      }
    } catch (error) {
      console.error('Error generating report: ', error);
    } finally {
      setLoading(false);
    }
  };

  const handleGoToLayer = () => {
    const geologicalLayer = layers.find((layer) => layer.id === 'geological');
    dispatch({ type: MAP_SET_ACTIVE_LAYER, payload: geologicalLayer });
    dispatch({
      type: MAP_SET_ACTIVE_SUBLAYER,
      payload: selectSublayer(geologicalLayer),
    });
    onCancel();
  };

  return (
    <Modal
      onOk={onOk}
      onCancel={onCancel}
      open={visible}
      cancelButtonProps={{
        onClick: handleGoToLayer,
      }}
      okText='Generar reporte'
      cancelText='Ir a la capa'
      centered={true}
      destroyOnClose
      confirmLoading={loading}
      zIndex={2000}
      className={`modal-provider ${isMobileScreen ? 'modal-mobile' : ''}`}
      maskClosable={true}
    >
      <Title type='bold-28' style={{ textAlign: 'center' }}>
        Aviso
      </Title>
      <Divider className='mt-2 mb-3' />
      <div className='text-center' style={{ fontSize: '16px' }}>
        <p>
          Si deseas saber cuales son los peligros geológicos en tu ruta, te
          recomendamos que te dirijas a nuestra capa "Peligros geológicos", para
          descargar un informe detallado. Tu seguridad es nuestra prioridad.
        </p>
        <strong>¡Descarga el informe y viaja con tranquilidad!</strong>
      </div>
    </Modal>
  );
};

export default ResendModal;
