import React, { memo, useState, useCallback, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Input,
  Popover,
  List,
  notification,
  Card,
  Skeleton,
} from 'antd';
import { EllipsisOutlined, DeleteFilled } from '@ant-design/icons';

import {
  getSimulations,
  removeSimulation,
} from '../../../services/simulations';
import useDebounce from '../../../hooks/useDebounce';

import TableCommon from '../../../common/Table';
import ModalConfirm from '../../../common/ModalConfirm';
import { iconLeft, iconRight } from '../ui/PreventionService/Reports/utils';
import DrawerMoreOptionsMobile from '../../../common/DrawerMoreOptionsMobile';
import CardSimulationsMobile from './CardMobile';

import DeleteModalImage from '../../../images/delete_modal_image.svg';

const columns = [
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
    width: 100,
  },
  {
    title: 'Locales',
    dataIndex: 'cantLocation',
    key: 'cantLocation',
    width: 60,
  },
  {
    title: 'Clientes',
    dataIndex: 'countClients',
    key: 'countClients',
    width: 50,
  },
  {
    title: 'Total Declarado',
    dataIndex: 'totalDeclared',
    key: 'totalDeclared',
    width: 80,
    render: (val) =>
      val ? `$${new Intl.NumberFormat().format(val.toFixed(0))}` : '',
  },
  {
    title: 'Total Retenido',
    dataIndex: 'totalWithheld',
    key: 'latitude',
    width: 80,
    render: (val) =>
      val ? `$${new Intl.NumberFormat().format(val.toFixed(0))}` : '',
  },
];

const ListCumulo = ({ isMobile }) => {
  const dispatch = useDispatch();
  const { pagination, list, isLoading } =
    useSelector((state) => state?.simulations) || {};
  const [query, setQuery] = useState('');
  const queryDebounce = useDebounce(query);
  const [idToDelete, setVisibleDeleteModal] = useState(null);
  const [optionsMobile, setVisibleOptionsMobile] = useState(null);

  useEffect(() => {
    getSimulations(dispatch, {
      page: 1,
      limit: pagination.pageSize,
      query: queryDebounce,
    });
  }, [queryDebounce]);

  const handleToggleDeleteModal = useCallback(
    (id) => () => {
      setVisibleDeleteModal(id);
    },
    [],
  );

  const columnsList = useMemo(() => {
    return columns.concat({
      title: 'ACCIONES',
      dataIndex: '',
      key: '',
      width: 80,
      render: (_, item) => (
        <Popover
          className='popover'
          placement='topRight'
          trigger='hover'
          content={
            <List
              dataSource={[
                {
                  action: handleToggleDeleteModal(item.id),
                  icon: <DeleteFilled />,
                  title: 'Eliminar',
                  key: 'delete',
                },
              ]}
              renderItem={({ action, icon, key, title }) => (
                <List.Item
                  style={{ width: 167, height: 40, paddingLeft: 16 }}
                  key={key}
                  onClick={action}
                >
                  <span style={{ marginRight: 12 }}>{icon}</span>
                  {title}
                </List.Item>
              )}
            ></List>
          }
        >
          <Button>
            <EllipsisOutlined />
          </Button>
        </Popover>
      ),
    });
  }, [handleToggleDeleteModal]);

  const _handleChangePagination = useCallback(
    (page) => {
      getSimulations(dispatch, {
        page,
        limit: pagination.pageSize,
        query,
      });
    },
    [dispatch, pagination, query],
  );

  const handleToggleOptionsMobile = useCallback((data) => {
    setVisibleOptionsMobile(data);
  }, []);

  const _handleChangeQuery = useCallback(({ target: { value } }) => {
    setQuery(value);
  }, []);

  return (
    <div
      className={`d_content ${isMobile ? 'mt-4' : ''}`}
      style={{ paddingTop: '0' }}
    >
      <div
        className={`flex ${
          isMobile ? 'mt-4 flex-col flex-start' : 'justify-between items-center'
        }`}
      >
        <div className={`bold text-2xl ${isMobile ? 'mb-2' : ''}`}>
          <strong>Listado de simulaciones</strong>
        </div>
        {isMobile ? (
          <div className={'text-base mb-2 mt-3'}>
            <strong>Filtra tu búsqueda</strong>
          </div>
        ) : null}
        <Input.Search
          className='inputs-class'
          placeholder='Palabra clave'
          style={{
            ...(isMobile ? { width: '100%' } : { width: 255 }),
          }}
          value={query}
          onChange={_handleChangeQuery}
        />
      </div>
      <div
        className={`flex full-width flex-col justify-between mt-4 ${
          isMobile ? '' : 'pt-4'
        }`}
      >
        <div className={`text-base ${isMobile ? 'mb-2' : 'pb-2 mb-3'}`}>
          {isLoading
            ? 'Cargando...'
            : `Se encontraron ${pagination.total} simulaciones`}
        </div>
        {!isMobile ? (
          <TableCommon
            loading={isLoading}
            columns={columnsList}
            dataSource={list}
            style={{ width: '100%' }}
            pagination={{
              currentPage: pagination.current,
              limit: pagination.pageSize,
              total: pagination.total,
              onChange: _handleChangePagination,
            }}
          />
        ) : null}
        {isMobile
          ? isLoading
            ? [1, 2, 3].map((i) => (
                <Card key={`card-${i}`} className='d_content__card'>
                  <Skeleton className='rounded-2xl' />
                </Card>
              ))
            : list.map((item) => (
                <CardSimulationsMobile
                  key={item.key}
                  item={item}
                  onOpenOptionsMobile={() => handleToggleOptionsMobile(item)}
                  columns={columns}
                />
              ))
          : null}
        {isMobile ? (
          <div className='d_content__list__pag' style={{ marginBottom: 100 }}>
            <div
              className={'d_content__list__pag__button'}
              onClick={() => _handleChangePagination(pagination.current - 1)}
            >
              {iconLeft({
                color: pagination.current === 1 ? '#A9AFD9' : '#4F4FFF',
              })}
            </div>
            <div className='d_content__list__pag__number color-red-1'>
              {pagination.current}
            </div>
            <div className='d_content__list__pag__sep'>/</div>
            <div className='d_content__list__pag__number color-gray-3'>
              {Math.ceil(pagination.total / pagination.pageSize)}
            </div>
            <div
              className={'d_content__list__pag__button'}
              onClick={() => _handleChangePagination(pagination.current + 1)}
            >
              {iconRight({
                color:
                  pagination.current === pagination.pageSize
                    ? '#A9AFD9'
                    : '#4F4FFF',
              })}
            </div>
          </div>
        ) : null}
      </div>
      <ModalConfirm
        title={'¿Seguro que desea eliminar la simulación de la lista?'}
        visible={idToDelete}
        onClose={handleToggleDeleteModal(null)}
        actionsContent={
          <div className='actions-delete-modal mt-2'>
            <Button
              className='button secondary-button mr-2'
              onClick={handleToggleDeleteModal(null)}
            >
              Cancelar
            </Button>
            <Button
              className='button ml-1'
              type='primary'
              onClick={() => {
                removeSimulation(dispatch, idToDelete);
                handleToggleDeleteModal(null)();
                notification.success({
                  description:
                    'Se eliminó la simulación de la lista correctamente.',
                  message: '¡Simulación eliminada!',
                });
              }}
            >
              Eliminar
            </Button>
          </div>
        }
      />
      {isMobile ? (
        <DrawerMoreOptionsMobile
          visible={Boolean(optionsMobile)}
          onClose={() => {
            handleToggleOptionsMobile(null);
          }}
          options={[
            {
              action: () => {
                handleToggleDeleteModal(optionsMobile.id)();
                handleToggleOptionsMobile(null);
              },
              title: 'Eliminar',
              key: 'delete',
            },
          ]}
        />
      ) : null}
    </div>
  );
};

export default memo(ListCumulo);
