import React, { useState } from 'react';
import { Button, Table, Form, InputNumber } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import text from '../../config/text_es';
import Title from '../Typograph/Title';
import InputWithLabel from '../InputWithLabel';

const ListAdditionalCosts = ({ minCost }) => {
  const [list, setList] = useState([]);

  const addCost = () => {
    setList((currentList) => [
      ...currentList,
      { details: '', cost: 0, key: Date.now() },
    ]);
  };

  const delCost = (key) => {
    setList((currentList) => currentList.filter((item) => item.key !== key));
  };

  const columns = [
    {
      title: 'Detalles',
      dataIndex: 'details',
      key: 'details',
      render: (detail, record) => (
        <Form.Item
          name={`costs[${record.key}].details`}
          initialValue={detail}
          rules={[{ required: true, message: text.required_message }]}
          style={{ margin: 0 }}
        >
          <InputWithLabel />
        </Form.Item>
      ),
    },
    {
      title: 'Costo',
      dataIndex: 'cost',
      key: 'cost',
      width: 130,
      render: (cost, record) => (
        <Form.Item
          name={`costs[${record.key}].cost`}
          initialValue={cost}
          rules={[{ required: true, message: text.required_message }]}
        >
          <InputNumber
            className='input-with-label'
            min={minCost}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          />
        </Form.Item>
      ),
    },
    {
      dataIndex: 'operation',
      key: 'operation',
      render: (_, record) => (
        <span className='table-operation'>
          <Button
            onClick={() => delCost(record.key)}
            icon={<DeleteOutlined />}
            size='small'
          />
        </span>
      ),
    },
  ];

  return (
    <div>
      <div className='flex'>
        <Title type={'bold-14'} className='mr-2 items-center'>
          Costos adicionales
        </Title>
        <Button
          onClick={addCost}
          icon={<PlusOutlined />}
          type='default'
          style={{ marginBottom: 16, textTransform: 'none' }}
          size='small'
        >
          Añadir costo
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={list}
        locale={{
          emptyText: '-',
        }}
        pagination={false}
      />
    </div>
  );
};

export default ListAdditionalCosts;
