import { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { connect, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, notification, Select } from 'antd';
import { Editor } from '@tinymce/tinymce-react';
import {
  RideGlLeft,
  RideIconButton,
} from '@rimac-seguros/ride-system-components';

import InputWithLabel from '../../../../common/InputWithLabel';
import Topbar from '../../../../common/ui/Topbar';
import { filterSelectOptions } from '../../../../lib/utils';
import { getZonesWithDistricts } from '../../../../services/zones';
import {
  createNewsletters,
  getNewsletterTemplate,
  updateNewsletters,
} from '../../../../services/newsletters';
import { getRols } from '../../../../services/rols';
import { eventTypes } from '../../../../lib/consts';

const templates = [
  {
    lluvias: 'Lluvias',
    nevadas: 'Nevadas',
    quebradas: 'Activación de quebradas',
    neblinas: 'Neblinas',
    vientos: 'Vientos',
    friajes: 'Friajes',
    heladas: 'Heladas',
    temperaturas_extremas: 'Temperaturas extremas',
  },
  {
    oceanograficos_oleajes: 'Oceanográficos - Oleajes',
  },
  {
    geologico: 'Geológico',
  },
  {
    sociales: 'Sociales',
  },
  {
    tecnologicos: 'Tecnológicos',
  },
];

const { Option } = Select;

const DetailNewsManagment = ({ loadRols, create, update, rols, zones }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { resetFields } = form;
  const navigate = useNavigate();
  const [templateList, setTemplateList] = useState({});
  const [editorTiny, setEditorTiny] = useState(false);
  const [contentHtml, setContentHtml] = useState('');
  const [newsletter, setNewsletter] = useState(() =>
    localStorage.getItem('newsletter-selected')
      ? JSON.parse(localStorage.getItem('newsletter-selected'))
      : null,
  );
  const [isLoading, setIsLoading] = useState(false);
  const eventToNewsletters = localStorage.getItem('event-selected')
    ? JSON.parse(localStorage.getItem('event-selected'))
    : null;

  useEffect(() => {
    getZonesWithDistricts(dispatch);
  }, [dispatch]);

  const zonesGroup = (provinces) => {
    let zoneGroup = [];
    provinces.map((prov) => {
      const exitDepartament = zoneGroup.find(
        (d) => d.id === prov.id.toString().substring(0, 2),
      );

      if (exitDepartament) {
        if (!_.has(exitDepartament, 'children')) exitDepartament.children = [];

        exitDepartament.children.push(prov);
      } else {
        const dep = {
          id: prov.id.toString().substring(0, 2),
          children: [],
          allProvinces: false,
        };
        dep.children.push(prov);
        zoneGroup.push(dep);
      }

      zoneGroup.forEach((item) => {
        const d = zones.find((departament) => departament.key === item.id);
        if (d) {
          item.name = d.title;
          if (d.children.length === item.children.length)
            item.allProvinces = true;
        }
      });
    });

    return zoneGroup;
  };

  const processZonesHtml = () => {
    if (newsletter) {
      return newsletter.zones;
    } else if (eventToNewsletters && zones) {
      const zones = zonesGroup(eventToNewsletters.provinces);

      let closeTag =
        '</span></div></td></tr></tbody></table></td></tr></tbody></table>';

      let html = zones.reduce((pre, z) => {
        let out = `${pre}<table cellpadding="0" cellspacing="0" width="100%" role="presentation" 
          style="background-color: #FFFFFF; border: 0px; min-width: 100%; " class="stylingblock-content-wrapper">
          <tbody>
            <tr>
              <td style="padding: 0px 50px; " class="stylingblock-content-wrapper camarker-inner">
                <table border="0" cellpadding="0" cellspacing="0" style="width:100%;">
  
                  <tbody>
                    <tr>
                      <td align="center" style="padding-top:10px; padding-bottom:10px; vertical-align:top;  border-" width="15%">
                        <img alt="" data-assetid="141526" height="55" src="${process.env.REACT_APP_WEB_PATH_S3_NEW}email/images/map.png" 
                        style="text-align: left; padding: 0px; height: 55px; width: 55px; border: 0px;" width="55">
                      </td>
                      <td style="padding-left: 10px; padding-top:10px; padding-bottom:10px;" width="85%">
                        <div style="font-size: 16px; line-height: 1.3em; text-align: left;">
                          <span style="font-size:18px;"><span style="font-family:Arial,Helvetica,sans-serif;"><b>${z.name}</b></span>`;
        if (!z.allProvinces)
          out += `
            <br><span>${z.children.map((c) => c.name).join(', ')}</span>`;

        out += closeTag;

        return out;
      }, '');

      return html;
    }
  };

  const onOk = async (values) => {
    try {
      setIsLoading(true);
      if (newsletter !== null) {
        await update(values, {});
        navigate('/monitoreo/boletines?tab=newsletterManagment');
      } else {
        const keys = Object.keys(templateList);
        const valuesTemplate = Object.values(templateList);
        const index = valuesTemplate.indexOf(valuesTemplate.template);
        const templateName = keys[index];
        values.template = templateName;
        values.eventId = eventToNewsletters?.id;

        await create(values, {});
        navigate('/monitoreo/boletines?tab=newsletterManagment');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    resetFields();
    navigate(
      `/monitoreo/boletines?tab=${
        newsletter !== null ? 'newsletterManagment' : 'eventManagment'
      }`,
    );
  };

  const handleSave = () => {
    form
      .validateFields()
      .then((values) => {
        values.content = contentHtml;
        values.zones = processZonesHtml();
        if (values.roles)
          values.roles = !_.isEmpty(values.roles)
            ? JSON.stringify(values.roles)
            : null;

        onOk(values);
        setNewsletter(null);
      })
      .catch((errorInfo) => {
        // Handle form validation error
        console.log(errorInfo);
      });
  };

  const handleOnChangeType = (e) => setTemplateList(templates[e - 1]);

  const handleEditorChange = (e) => setContentHtml(e);

  const populateTemplateWithInfo = (html) => {
    setContentHtml(html);
    editorTiny.setContent(html);
  };

  const handleOnChangeTemplate = (name) => {
    const imageUrl =
      eventToNewsletters &&
      eventToNewsletters.image &&
      typeof eventToNewsletters.image === 'string'
        ? eventToNewsletters.image.indexOf('https://') !== -1
          ? eventToNewsletters.image
          : `${process.env.REACT_APP_WEB_PATH_S3_NEW}${eventToNewsletters.image}`
        : null;

    let rangeDate = '-';

    if (eventToNewsletters?.date_event_start)
      rangeDate = moment(eventToNewsletters?.date_event_start).format(
        'DD-MM-YYYY',
      );

    if (eventToNewsletters?.date_event_end)
      rangeDate +=
        ' al ' +
        moment(eventToNewsletters?.date_event_end).format('DD-MM-YYYY');

    const parameters = {
      IMAGE_NEWSLETTERS: imageUrl
        ? imageUrl
        : newsletter?.event?.image
        ? `${process.env.REACT_APP_WEB_PATH_S3_NEW}${newsletter?.event?.image}`
        : null,
      DETECTED_CONDITION: eventToNewsletters?.detected_condition
        ? eventToNewsletters?.detected_condition
        : newsletter
        ? newsletter?.detected_condition
        : '',
      DETECTED_CONDITION_RED: eventToNewsletters?.detected_condition_red
        ? eventToNewsletters?.detected_condition_red
        : newsletter
        ? newsletter?.detected_condition_red
        : '',
      DATE_EVENT_START: eventToNewsletters?.date_event_start
        ? moment(eventToNewsletters?.date_event_start).format('DD/MM/YYYY')
        : newsletter
        ? moment(newsletter?.event?.date_event_start).format('DD/MM/YYYY')
        : '',
      DATE_EVENT_END: eventToNewsletters?.date_event_end
        ? moment(eventToNewsletters?.date_event_end).format('DD/MM/YYYY')
        : newsletter
        ? moment(newsletter?.event?.date_event_end).format('DD/MM/YYYY')
        : '',
      RANGE_DATE: rangeDate,
      ZONES: processZonesHtml(),
      CONTENT: eventToNewsletters?.summary
        ? eventToNewsletters?.summary
        : newsletter
        ? newsletter?.event?.summary
        : '',
    };

    const keys = Object.keys(templateList);
    const values = Object.values(templateList);
    const index = values.indexOf(name);
    const templateName = keys[index];
    getNewsletterTemplate(name, parameters).then((template) => {
      populateTemplateWithInfo(template);
    });
  };

  useEffect(() => {
    localStorage.getItem('newsletter-selected')
      ? setNewsletter(JSON.parse(localStorage.getItem('newsletter-selected')))
      : setNewsletter(null);

    return () => {
      localStorage.removeItem('newsletter-selected');
      localStorage.removeItem('event-selected');
      setNewsletter(null);
    };
  }, []);

  useEffect(() => {
    if (newsletter) {
      loadRols();
      localStorage.removeItem('event-selected');
      const popups = document.querySelectorAll('.ant-popover');
      popups.forEach((popup) => popup.classList.add('hidden'));
    }
  }, [newsletter]);

  return (
    <div id='checkSize' className='h100vh dashboard-UI'>
      <Topbar />

      <div className='d_content'>
        <section className='section-form-newsletters'>
          <div className='return'>
            <RideIconButton
              variant='outline'
              size='small'
              glyph={<RideGlLeft />}
              onClick={handleCancel}
            />
            <h2>{newsletter ? 'Editar' : 'Crear'} boletín</h2>
          </div>

          <div className='form-content'>
            <Form form={form} disabled={isLoading}>
              <Form.Item
                name='id'
                initialValue={newsletter ? newsletter.id : ''}
              >
                <Input type='hidden' />
              </Form.Item>

              <Form.Item
                name='type'
                initialValue={newsletter ? newsletter.type : ''}
                label='Tipo'
              >
                <Select
                  placeholder='Seleccione el tipo de boletín'
                  loading={isLoading}
                  disabled={isLoading}
                  onChange={handleOnChangeType}
                  filterOption={filterSelectOptions}
                  style={{ width: '100%' }}
                >
                  {eventTypes.map((eventType) => (
                    <Option key={eventType.id} value={eventType.id}>
                      {eventType.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item name='template' label='Plantilla'>
                <Select
                  placeholder='Selecciona el tipo de plantilla'
                  loading={isLoading}
                  disabled={isLoading || Object.keys(templateList).length === 0}
                  onChange={handleOnChangeTemplate}
                  style={{ width: '100%' }}
                >
                  {Object.entries(templateList).map(([value, label]) => (
                    <Option key={value} value={value}>
                      {label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name='detected_condition'
                initialValue={
                  (eventToNewsletters
                    ? eventToNewsletters.detected_condition
                    : '') +
                  (eventToNewsletters
                    ? ' ' + eventToNewsletters.detected_condition_red
                    : '')
                }
                label='Condición detectada'
              >
                <InputWithLabel
                  disabled
                  value={
                    (eventToNewsletters
                      ? eventToNewsletters.detected_condition
                      : '') +
                    (eventToNewsletters
                      ? ' ' + eventToNewsletters.detected_condition_red
                      : '')
                  }
                />
              </Form.Item>

              <Form.Item
                name='roles'
                initialValue={
                  newsletter?.roles ? JSON.parse(newsletter.roles) : []
                }
                label='Rol'
              >
                <Select
                  className='select-roles'
                  mode='multiple'
                  placeholder='Seleciona los roles específicos para notificar el boletín (opcional)'
                  suffixIcon={null}
                >
                  {rols &&
                    rols
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((rol) => (
                        <Option key={rol.id} value={rol.id}>
                          {rol.name}
                        </Option>
                      ))}
                </Select>
              </Form.Item>

              <Form.Item>
                <Editor
                  apiKey={process.env.REACT_APP_TINYMCE_KEY}
                  init={{
                    setup: (editor) => {
                      setEditorTiny(editor);
                      if (newsletter) {
                        setTimeout(() => {
                          setContentHtml(newsletter.content);
                          editor.setContent(newsletter.content);
                        }, 2000);
                      }
                    },
                    height: 900,
                    cleanup: false,
                    menubar: true,
                    language: 'es',
                    fullpage_default_doctype: '<!DOCTYPE html>',
                    plugins: [
                      'autoresize advlist autolink lists link image charmap print preview hr anchor pagebreak',
                      'searchreplace wordcount visualblocks visualchars  fullscreen',
                      'insertdatetime media nonbreaking save table contextmenu directionality',
                      'paste textcolor code template colorpicker',
                    ],
                    toolbar:
                      'undo redo | template | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | forecolor backcolor fontselect fontsizeselect',
                  }}
                  onEditorChange={handleEditorChange}
                />
              </Form.Item>

              <div className='form-actions'>
                <Button
                  onClick={handleSave}
                  disabled={isLoading}
                  htmlType='submit'
                >
                  {newsletter ? 'Guardar cambios' : 'Crear'}
                </Button>
              </div>
            </Form>
          </div>
        </section>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  loadRols: (user) => getRols(dispatch, user),
  create: async (newsletter, data) => {
    const created = await createNewsletters(newsletter, data, dispatch, true);

    created
      ? notification.success({
          description: 'Se creo el boletín correctamente.',
          message: '¡Boletín creado!',
        })
      : notification.warning({
          description: 'Ha ocurrido un error.',
          message: '¡Gestión de boletínes!',
        });
  },
  update: async (newsletter, data) => {
    const updated = await updateNewsletters(newsletter, data, dispatch, true);

    if (updated) {
      notification.success({
        description: 'Se actualizo correctamente el boletín.',
        message: '¡Boletín creado!',
      });
    } else {
      notification.warning({
        description: 'Ha ocurrido un error.',
        message: '¡Gestión de boletínes!',
      });
    }
  },
});

const mapStateToProps = (state) => ({
  user: state.auth.user,
  rols: state.rols.rols,
  zones: state.zones.zones,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DetailNewsManagment);
