import React, { memo } from 'react';
import { Divider, Modal } from 'antd';

const RightDrawer = ({
  title,
  description,
  visible,
  onCloseDrawer,
  content,
  hiddenDivider,
}) => {
  return (
    <Modal
      title=''
      open={visible}
      onCancel={onCloseDrawer}
      footer={false}
      className='modal-update'
      style={{
        transformOrigin: 'right top',
      }}
      maskClosable={true}
    >
      <section className='modal-update__content'>
        <h2>{title}</h2>
        {!hiddenDivider ? <Divider /> : null}
        {description ? <p className='description'>{description}</p> : null}
        {content}
      </section>
    </Modal>
  );
};

export default memo(RightDrawer);
