import { useState, useRef, useEffect } from 'react';
import { Marker, Popup, GeoJSON } from 'react-leaflet';
import { useDispatch, useSelector } from 'react-redux';
import L from 'leaflet';
import dayjs from 'dayjs';

import { styleGeoJSONFunction, intensityNumberToDescription } from './utils';
import { handleSetCoordinatesOnCumulusTools } from '../../../../lib/handler';
import { CoordinateIcon, iconLastSismo, iconSismo } from '../../utils';

const PointGeoMarker = ({
  point,
  isLastPoint,
  lastEarthquake,
  setSelectedPoint,
  showAcceleration,
}) => {
  const dispatch = useDispatch();
  const activeUtilsLayer = useSelector((state) => state.maps.activeUtilsLayer);
  const isCumulusLayerActive = Boolean(
    activeUtilsLayer.find((utilLayer) => utilLayer.key === 'cumulo'),
  );
  const [pointSelected, setPointSelected] = useState(false);

  const data = JSON.parse(point.data);

  if (data?.fecha_hora) {
    const fechaHora = dayjs(data?.fecha_hora);
    data.FechaLocal = fechaHora.format('DD/MM/YYYY');
    data.HoraLocal = fechaHora.format('HH:mm:ss');
    data.FechaHoraISO = fechaHora.toISOString();
  }

  const acc =
    typeof data?.ground_acceleration === 'object'
      ? data.ground_acceleration
      : null;

  const getIcon = (isLastEarthquake) =>
    L.divIcon({
      html: isLastEarthquake ? iconLastSismo : iconSismo,
      iconSize: [27, 38],
      iconAnchor: [13, 38],
      className: 'leaflet-icon-marker-custom',
    });

  const markerRef = useRef(null);

  useEffect(() => {
    if (lastEarthquake && markerRef.current) {
      markerRef.current.openPopup();
    }
  }, [lastEarthquake]);

  return (
    <div>
      {acc && showAcceleration && (
        <GeoJSON
          key={JSON.stringify(acc)}
          data={acc}
          style={(feature) => styleGeoJSONFunction(feature, pointSelected)}
        />
      )}
      <Marker
        ref={markerRef}
        position={[data.latitud, data.longitud]}
        icon={getIcon(isLastPoint)}
        eventHandlers={{
          mouseover: () => setPointSelected(true),
          mouseout: () => setPointSelected(false),
          click: () => setSelectedPoint(point.id),
        }}
      >
        <Popup>
          <div className='popup-map-v2'>
            <div className='popup-title'>
              <span>Sismo de magnitud {data?.magnitud}</span>
              <div className='popup-title-subtitle'>{data.referencia}</div>
            </div>
            <div
              className='popup-content popup-map-scrollable'
              style={{ maxHeight: 240 }}
            >
              <div className='popup-detail'>
                <span className='popup-detail-header'>Fecha del Evento</span>
                <span className='popup-detail-content'>{data.FechaLocal}</span>
              </div>
              <div className='popup-detail'>
                <span className='popup-detail-header'>Hora local</span>
                <span className='popup-detail-content'>{data.HoraLocal}</span>
              </div>
              <div className='popup-detail'>
                <span className='popup-detail-header'>Latitud</span>
                <span className='popup-detail-content'>{data.latitud}</span>
              </div>
              <div className='popup-detail'>
                <span className='popup-detail-header'>Longitud</span>
                <span className='popup-detail-content'>{data.longitud}</span>
              </div>
              <div className='popup-detail'>
                <span className='popup-detail-header'>Profundidad (Km)</span>
                <span className='popup-detail-content'>{data.profundidad}</span>
              </div>
              <div className='popup-detail popup-detail-intensity'>
                <span className='popup-detail-header'>Intensidad</span>
                <span className='popup-detail-content'>
                  {intensityNumberToDescription(data?.intensidades)}
                </span>
              </div>
              <div className='popup-detail'>
                <span className='popup-detail-header'>Magnitud</span>
                <span className='popup-detail-content tag-info'>
                  {data?.magnitud < 4.5 && (
                    <span className='tag-magnitud tag-green'>
                      <span className='point'></span>
                      {data?.magnitud}
                    </span>
                  )}

                  {data?.magnitud >= 4.5 && data?.magnitud < 6 && (
                    <span className='tag-magnitud tag-yelow'>
                      <span className='point'></span>
                      {data?.magnitud}
                    </span>
                  )}

                  {data?.magnitud >= 6 && (
                    <span className='tag-magnitud tag-red'>
                      <span className='point'></span>
                      {data?.magnitud}
                    </span>
                  )}
                </span>
              </div>
            </div>
            {isCumulusLayerActive && (
              <div className='popup-footer'>
                <div
                  role='button'
                  className='icon-content'
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSetCoordinatesOnCumulusTools(
                      {
                        lat: data.latitud,
                        lng: data.longitud,
                      },
                      dispatch,
                    );
                  }}
                >
                  <CoordinateIcon />
                  <span className='icon-text'>Realizar simulación</span>
                </div>
              </div>
            )}
          </div>
        </Popup>
      </Marker>
    </div>
  );
};

export default PointGeoMarker;
