import { useEffect, useState } from 'react';
import { Button, Skeleton } from 'antd';

import { getModules } from '../../../../services/modules';

import MainTitle from '../common/MainTitle';
import TableCommon from '../../../../common/Table';
import ModalUserList from './ModalUserList';

const COLUMNS_FIXED = [
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
    width: 200,
  },
  {
    title: 'Descripción',
    dataIndex: 'description',
    key: 'description',
    width: 400,
  },
];

const Modules = ({ renderItem, isMobileScreen }) => {
  const [listModules, setListModules] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedModule, setSelectedModule] = useState(null);

  const openModal = (slug, name, id) => {
    setSelectedModule({ slug, name, id });
  };

  useEffect(() => {
    setIsLoading(true);
    getModules()
      .then((list) => setListModules(list))
      .catch((e) => console.error(e))
      .finally(() => setIsLoading(false));
  }, []);

  const COLUMNS = COLUMNS_FIXED.concat([
    {
      title: 'Acción',
      dataIndex: 'action',
      key: 'action',
      render: (_, record) => {
        return (
          <Button
            className='button-purple-link'
            onClick={() => openModal(record.slug, record.name, record.id)}
            type='link'
          >
            Ver lista de usuarios
          </Button>
        );
      },
    },
  ]);

  return (
    <>
      <MainTitle renderItem={renderItem} isMobileScreen={isMobileScreen} />
      <section className='show-data'>
        {isLoading ? (
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
        ) : listModules.length !== 1 ? (
          <p className='info-total-data'>
            Se encontraron <span>{listModules.length}</span> módulos
          </p>
        ) : (
          <p className='info-total-data'>
            Se encontró <span>{listModules.length}</span> módulo
          </p>
        )}
        <TableCommon
          dataSource={listModules}
          columns={COLUMNS}
          loading={isLoading}
          locale={{ emptyText: 'No se han agregado módulos.' }}
        />
      </section>
      {selectedModule && (
        <ModalUserList
          visible={Boolean(selectedModule)}
          handleClose={() => setSelectedModule(null)}
          name={selectedModule.name}
          slug={selectedModule.slug}
          moduleId={selectedModule.id}
        />
      )}
    </>
  );
};

export default Modules;
