import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RideGlRight } from '@rimac-seguros/ride-system-components';
import { SIDEMENU_CHANGE_VISIBLE } from '../../../redux/actions/actionsType';
import { useScreen } from '../../../lib/screen';

const AsideHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.auth.user);
  const isOpened = useSelector((state) => state.sideMenu.isOpened);
  const isLogged = useSelector((state) => state.auth.isLogged);
  const isMobileScreen = useScreen().isMobileScreen;

  const changeMenuVisible = (isOpen) =>
    dispatch({ type: SIDEMENU_CHANGE_VISIBLE, payload: isOpen });

  const redirectTo = () => {
    if (isLogged && user?.rol) {
      let route = '/mapas';

      if (user.rol.slug === 'proveedor') {
        route = '/servicio-de-prevencion-solicitud-proveedor';
      } else if (user.rol.slug === 'admin_rimac') {
        route = '/administracion/resumen';
      } else if (
        user.rol.slug === 'administrador_cliente' ||
        user.rol.slug === 'usuario_cliente'
      ) {
        route = '/administracion/perfil-empresa';
      } else {
        route = '/monitoreo/mapas';
      }

      navigate(route);
    }
  };

  return (
    <header className={`header-menu-container ${isOpened ? 'open' : ''}`}>
      <a onClick={redirectTo}>
        {isOpened ? (
          <img
            src={'/images/sidebar_logo.svg'}
            alt='Logo de Rimac'
            width={100}
            height={40}
          />
        ) : (
          <img
            src={'https://www.rimac.com/js/apple-touch-icon.png'}
            alt='Logo de Rimac Mobile'
            width={40}
          />
        )}
      </a>
      {isMobileScreen ? null : (
        <button
          className={`sidemenu-button ${isOpened ? 'open' : ''}`}
          onClick={() => changeMenuVisible(!isOpened)}
        >
          <RideGlRight size='large' />
        </button>
      )}
    </header>
  );
};

export default AsideHeader;
