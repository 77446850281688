import React from 'react';
import { Button } from 'antd';
import moment from 'moment-timezone';
import { RideButton } from '@rimac-seguros/ride-system-components';
moment.tz.setDefault('America/Lima');

const EQUIV_STATES = {
  1: 'Pendiente',
  2: 'Respondido por el cliente',
  3: 'Cerrado',
  4: 'Rechazo',
};

export const columns = [
  {
    title: 'Cliente',
    dataIndex: 'poliza',
    key: 'poliza',
    width: 140,
    render: (poliza) => <span>{poliza.company?.name || ''}</span>,
  },
  {
    title: 'Póliza',
    dataIndex: 'poliza_id',
    key: 'poliza_id',
    width: 120,
  },
  {
    title: 'Vence',
    dataIndex: 'poliza',
    key: 'poliza',
    width: 120,
    render: (poliza) => (
      <span>
        {moment(
          moment(poliza.start_date).add(Number(poliza.validity), 'months'),
        ).format('DD/MM/YYYY')}
      </span>
    ),
  },
  {
    title: 'Estado',
    dataIndex: 'state',
    key: 'state',
    width: 172,
    render: (state) => <span>{EQUIV_STATES[state]}</span>,
  },
  {
    title: 'Fecha de reunión',
    dataIndex: 'meeting_date',
    key: 'meeting_date',
    width: 110,
    render: (date) => moment(date).format('DD/MM/YYYY HH:mm'),
  },
  {
    title: 'Usuario creador',
    dataIndex: 'user',
    key: 'user',
    width: 120,
    render: (user) => (
      <span>{`${user.name} ${user.last_name1} ${user.last_name2}`}</span>
    ),
  },
  {
    title: 'Acción',
    dataIndex: 'action',
    key: 'action',
    fixed: 'right',
    width: 120,
    render: (_, record) => {
      return (
        <RideButton
          onClick={() => record.onViewDetils(record)}
          variant='text-primary'
          text='Ver detalles'
          size='small'
        />
      );
    },
  },
];

export const columnsDetails = [
  {
    title: 'Correo',
    dataIndex: 'email',
    key: 'email',
    width: 172,
  },
  {
    title: 'Asunto',
    dataIndex: 'subject',
    key: 'subject',
    width: 172,
  },
  {
    title: 'CC',
    dataIndex: 'description',
    key: 'description',
    width: 172,
  },
  {
    title: 'Fecha de Envío',
    dataIndex: 'sentDate',
    key: 'sentDate',
    width: 125,
    render: (date) => (date ? moment.utc(date).format('DD/MM/YYYY') : ''),
  },
  {
    title: 'Acción',
    dataIndex: 'action',
    key: 'action',
    fixed: 'right',
    width: 120,
    render: (_, record) => {
      return (
        <Button
          className='button-purple-link'
          onClick={() => record.onShowEmail(record?.content)}
          type='link'
        >
          Ver detalles
        </Button>
      );
    },
  },
];

export const filtersCommon = [
  {
    title: 'Póliza',
    dataIndex: 'poliza_id',
    key: 'poliza_id',
    type: 'search',
    inputType: 'text',
    placeholder: 'Poliza',
    value: 'id',
    label: 'name',
  },
  {
    title: 'Vigencia',
    dataIndex: 'validity',
    key: 'validity',
    type: 'search',
    inputType: 'number',
    min: '0',
    placeholder: 'Meses de vigencia',
    value: 'id',
    label: 'name',
  },
  {
    title: 'Estado',
    dataIndex: 'state',
    key: 'state',
    type: 'select',
    placeholder: 'Por estado',
    value: 'id',
    label: 'name',
  },
];
