export const styleGeoJSONFunction = (feature, pointSelected) => {
  const properties = feature.properties;

  if (properties.value < 1) {
    return { fillOpacity: 0, opacity: 0 };
  }

  const getColor = (value) => {
    const colorRanges = [
      { min: 1, max: 10, color: '#A787EB' },
      { min: 10, max: 20, color: '#3EB9FF' },
      { min: 20, max: 50, color: '#64DC00' },
      { min: 50, max: 100, color: '#FFD147' },
      { min: 100, max: 200, color: '#FF9000' },
      { min: 200, max: 500, color: '#FF001E' },
      { min: 500, max: 1000, color: '#A30013' },
      { min: 1000, max: Infinity, color: '#5E000B' },
    ];

    for (let range of colorRanges) {
      if (value >= range.min && value < range.max) {
        return range.color;
      }
    }
  };

  const getStrokeWidth = () => (pointSelected ? 5 : 2);

  return {
    color: getColor(properties.value),
    weight: getStrokeWidth(),
    opacity: 1,
    fillColor: getColor(properties.value),
    fillOpacity: 0.2,
  };
};

export const romanToArabic = (roman) => {
  const romanNumerals = { I: 1, V: 5, X: 10, L: 50, C: 100, D: 500, M: 1000 };
  let arabic = 0;
  let i = roman.length;
  while (i) {
    i--;
    if (romanNumerals[roman[i]] < romanNumerals[roman[i + 1]]) {
      arabic -= romanNumerals[roman[i]];
    } else {
      arabic += romanNumerals[roman[i]];
    }
  }
  return arabic;
};

export const getMaxRomanNumber = (str) => {
  const parts = str.split('-');
  const arabicNumbers = parts.map(romanToArabic);
  return Math.max(...arabicNumbers);
};

export const intensityDescriptions = {
  1: 'Imperceptible',
  2: 'Muy Leve',
  3: 'Leve',
  4: 'Moderado',
  5: 'Poco Fuerte',
  6: 'Fuerte',
  7: 'Muy Fuerte',
  8: 'Destructivo',
  9: 'Muy Destructivo',
  10: 'Desastroso',
  11: 'Muy Desastroso',
  12: 'Catastrófico',
};

export const describeIntensity = (intensity) =>
  intensityDescriptions[intensity] || '';

export const extractRomanNumeralAndWord = (str) => {
  const parts = str.split(' ');
  return {
    romanNumeral: parts.length > 0 ? parts[0] : null,
    word: parts.length > 1 ? parts.slice(1).join(' ') : null,
  };
};

export const intensityNumberToDescription = (intensity) => {
  const { romanNumeral, word } = extractRomanNumeralAndWord(intensity);

  let romanNumeralValue;
  if (romanNumeral.includes('-')) {
    romanNumeralValue = getMaxRomanNumber(romanNumeral);
  } else {
    romanNumeralValue = romanToArabic(romanNumeral);
  }

  romanNumeralValue = describeIntensity(romanNumeralValue);

  return `${romanNumeralValue} - ${word ?? ''}`;
};
