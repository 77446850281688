import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';

import { Button, Form, Input, Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

import { useScreen } from '../../../../../../lib/screen';

import { addCommentToPoliza } from '../../../../../../services/polizas';
import { IconCloseRed } from '../../../../ui/PreventionService/Reports/utils';

const dayWeekends = [
  'LUNES',
  'MARTES',
  'MIERCOLES',
  'JUEVES',
  'VIERNES',
  'SABADO',
  'DOMINGO',
];

const ModalComments = ({ handleClose, data, visible }) => {
  const { isMobileScreen } = useScreen();
  const [loading, setLoading] = useState(false);
  const [comments, setComments] = useState([]);

  const handleCancel = () => handleClose();

  const ChatHistory = () => {
    return (
      <div
        className='d_content_chat__history cmp_minimal-scroll-y'
        style={{
          boxShadow:
            '0px 1px 4px rgb(163 171 204 / 6%), 0px 8px 30px rgb(20 43 127 / 10%)',
        }}
      >
        {comments.map((comment) => {
          const isMessageToday =
            moment(comment?.created).format('DD/MM/YYYY') ===
            moment().format('DD/MM/YYYY');

          return (
            <>
              {comments.indexOf(comment) > 0 &&
              moment(comment?.created).format('DD/MM/YYYY') ===
                moment(comments[comments.indexOf(comment) - 1]?.created).format(
                  'DD/MM/YYYY',
                ) ? null : (
                <div className='d_content_chat__history__date'>
                  {isMessageToday
                    ? 'HOY'
                    : `${
                        dayWeekends[moment(comment?.created).isoWeekday() - 1]
                      } ${moment(comment?.created).format('DD/MM/YYYY')}`}
                </div>
              )}

              <div className='flex flex-justify-end mt-2'>
                <div className='d_content_chat_history--right'>
                  <div className='d_content_chat__history__user mr-1'>
                    <span>{comment?.user}</span>
                    <span className='mr-1 ml-1'>|</span>
                    <span>{`${moment(comment?.created).format(
                      'HH:mm A',
                    )}`}</span>
                  </div>
                  <div
                    className='flex'
                    style={{ justifyContent: 'end', marginTop: 4 }}
                  >
                    <div
                      className={
                        'd_content_chat__history__message d_content_chat__history__message--right'
                      }
                    >
                      {comment?.comment}
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    );
  };

  const FormCreateComment = () => {
    const [form] = Form.useForm();
    const { validateFields } = form;

    const handleSubmit = (e) => {
      e.preventDefault();
      validateFields((err, values) => {
        if (!err) {
          setLoading(true);
          addCommentToPoliza({ id: data.id, comment: values.comment }).then(
            (result) => {
              if (
                result.status === 200 &&
                result?.data?.payload?.comments.length > 0
              ) {
                setComments(result.data.payload.comments.reverse());
              }

              setLoading(false);
            },
          );
        }
      });
    };

    return (
      <Form form={form} onFinish={handleSubmit}>
        <div className='d_content_chat__history__textbox form-comments-poliza'>
          <Form.Item
            name='comment'
            rules={[{ required: true, message: 'Campo requerido' }]}
          >
            <Input
              className='d_content_chat__history__textbox__input'
              type='text'
              placeholder='Escribe tu mensaje aquí'
            />
          </Form.Item>

          <Button
            className='d_content_chat__history__textbox__btn'
            type='link'
            disabled={loading}
            htmlType='submit'
          >
            {loading ? (
              <LoadingOutlined style={{ fontSize: 26 }} spin />
            ) : (
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M3.4 20.4005L20.85 12.9205C21.66 12.5705 21.66 11.4305 20.85 11.0805L3.4 3.60051C2.74 3.31051 2.01 3.80051 2.01 4.51051L2 9.12051C2 9.62051 2.37 10.0505 2.87 10.1105L17 12.0005L2.87 13.8805C2.37 13.9505 2 14.3805 2 14.8805L2.01 19.4905C2.01 20.2005 2.74 20.6905 3.4 20.4005Z'
                  fill='#141938'
                />
              </svg>
            )}
          </Button>
        </div>
      </Form>
    );
  };

  useEffect(() => {
    const elements = document.querySelectorAll('.ant-popover');
    if (elements.length > 0) {
      elements.forEach((element) => {
        element.classList.add('ant-popover-hidden');
      });
    }
  }, [visible]);

  useEffect(() => {
    if (Boolean(data) && data.comment) {
      setComments(JSON.parse(data.comment).reverse());
    }
  }, [data]);

  return (
    <Modal
      open={visible}
      title=''
      closable={true}
      onCancel={() => handleCancel()}
      centered={true}
      onOk={() => handleCancel()}
      className={`modal-provider modal-centered modal-config modalPolizaCompany modal-comments ${
        isMobileScreen ? 'modal-mobile' : ''
      }`}
      cancelText='Cancelar'
      okText={'Aceptar'}
    >
      <div className='content-modal cmp_minimal-scroll-y'>
        <h2>Comentarios</h2>

        <ChatHistory />

        <FormCreateComment />
      </div>
    </Modal>
  );
};

export default ModalComments;
