import React from 'react';
import { Modal, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import InfoIcon from './InfoIcon';

const ModalMobileLegend = ({ visible, setVisible }) => {
  return (
    <Modal
      title='Leyenda'
      open={visible}
      className='modal-legend-mobile'
      onCancel={() => setVisible(false)}
      centered
      footer={null}
    >
      <table>
        <thead>
          <tr>
            <th colSpan={2} style={{ width: '90px' }}>
              Aceleración del suelo cm/s<sup>2</sup>
            </th>
            <th>Movimiento</th>
            <th>Daño</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ background: '#5500ff', width: '20px' }}></td>
            <td>1-10</td>
            <td>
              <Tooltip
                title='Movimiento apenas perceptible, puede sentirse ligeramente por algunas
              personas en reposo o edificios altos. Los objetos colgantes pueden moverse suavemente.'
              >
                <InfoIcon /> Débil
              </Tooltip>
            </td>
            <td>Ninguno</td>
          </tr>
          <tr>
            <td style={{ background: '#bd8ee7' }}></td>
            <td>10-20</td>
            <td>
              <Tooltip
                title='Vibraciones leves, perceptibles por la mayoría de las personas,
            puede sentirse como un balanceo suave y los objetos colgantes pueden oscilar un poco más.'
              >
                <InfoIcon /> Ligero
              </Tooltip>
            </td>
            <td>Ninguno</td>
          </tr>
          <tr>
            <td style={{ background: '#87f9ff' }}></td>
            <td>20-50</td>
            <td>
              <Tooltip
                title='Movimiento notorio, similar a la sensación dentro de un barco.
            Los objetos pueden caerse o desplazarse y los cristales pueden vibrar.'
              >
                <InfoIcon /> Muy Ligero
              </Tooltip>
            </td>
            <td>Muy Ligero</td>
          </tr>
          <tr>
            <td style={{ background: '#7cff87' }}></td>
            <td>50-100</td>
            <td>
              <Tooltip
                title='Vibraciones significativas que dificultan caminar,
            los objetos no asegurados pueden llegar a caer y dañar estructuras poco resistentes.'
              >
                <InfoIcon /> Fuerte
              </Tooltip>
            </td>
            <td>Ligero</td>
          </tr>
          <tr>
            <td style={{ background: '#dfff22' }}></td>
            <td>100-200</td>
            <td>
              <Tooltip
                title='Movimiento muy fuerte que hace difícil mantenerse en pie,
            los objetos se desplazan y caen con facilidad, los daños en estructuras y edificios son comunes.'
              >
                <InfoIcon /> Muy Fuerte
              </Tooltip>
            </td>
            <td>Moderado</td>
          </tr>
          <tr>
            <td style={{ background: '#ffcd00' }}></td>
            <td>200-500</td>
            <td>
              <Tooltip title='Movimiento extremadamente fuerte, los objetos se desplazan y caen con facilidad, los daños en estructuras y edificios son comunes.'>
                <InfoIcon /> Severo
              </Tooltip>
            </td>
            <td>Fuerte</td>
          </tr>
          <tr>
            <td style={{ background: '#e40000' }}></td>
            <td>500-1000</td>
            <td>
              <Tooltip
                title='Movimiento extremadamente intenso que causa daños generalizados a estructuras,
            carreteras y los edificios pueden colapsar.'
              >
                <InfoIcon /> Violento
              </Tooltip>
            </td>
            <td>Muy Fuerte</td>
          </tr>
          <tr>
            <td style={{ background: '#970607' }}></td>
            <td>&gt; 1000</td>
            <td>
              <Tooltip title='Movimiento extremadamente violento y destructivo, causando daños generalizados en áreas extensas.'>
                <InfoIcon /> Extremo
              </Tooltip>
            </td>
            <td>Extremo</td>
          </tr>
        </tbody>
      </table>
    </Modal>
  );
};

export default ModalMobileLegend;
