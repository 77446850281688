import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import {
  Alert,
  AutoComplete,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Table,
  Tooltip,
  Popconfirm,
  Badge,
  Divider,
} from 'antd';
import {
  CalendarOutlined,
  CloseSquareOutlined,
  EyeOutlined,
  WarningOutlined,
  DownloadOutlined,
  AppstoreOutlined,
} from '@ant-design/icons';
import moment from 'moment';

import cryptoUtils from '../../../../lib/crypto';
import { getAllCourses } from '../../../../services/courses';
import {
  deleteRequestServiceItemCapacitacionEventDate,
  updateRequestServiceItemData,
} from '../../../../services/requestServicesItems';
import { getRequestServicesByPoliza } from '../../../../services/requestServices';
import { getStateRequestServiceItem } from '../../RequestServicesItems';
import { checkPermission } from '../../../../lib/security';

import Title from '../../../../common/Typograph/Title';
import { useScreen } from '../../../../lib/screen';

const COURSE_STATUS_FINALIZADO = 6;

const ListCourses = ({
  user,
  itemData,
  item,
  polizaServices,
  polizaId,
  requestServiceId,
  allListCourses,
  loadAllListCourses,
  onEditEventDate,
  requestFinished,
  updateItemData,
  pagination,
  onCourseUpdated,
  canAddHours,
  loadList,
  onEditParticipants,
  requestServiceItem,
}) => {
  const [form] = Form.useForm();
  const { isMobileScreen } = useScreen();
  const [visible, setVisible] = useState(false);
  const [isProvider] = useState(user.rol.slug === 'proveedor');
  const [editableRows, setEditableRows] = useState({});
  const [courses, setCourses] = useState({});
  const [usedHours, setUsedHours] = useState(0);
  const [freeHours, setFreeHours] = useState(0);
  const [saveCoursesLoading, setSaveCoursesLoading] = useState(false);
  const [removeCourses, setRemoveCourses] = useState([]);
  const [coursesList, setCoursesList] = useState([]);
  const [loadingCoursesList, setLoadingCoursesList] = useState(true);
  const [typePolizaId, setTypePolizaId] = useState();

  useEffect(() => {
    let courses = JSON.parse(itemData.value);
    if (item?.request_service_item_results) {
      item.request_service_item_results.map((result) => {
        if (result && result.course && courses[result.course]) {
          courses[result.course].status = COURSE_STATUS_FINALIZADO;
        }

        return null;
      });
    }

    setCourses(courses);
    setUsedHours(getUsedHours());
    loadAllListCourses();
  }, [
    visible,
    itemData,
    item.request_service_item_results,
    loadAllListCourses,
  ]);

  useEffect(() => {
    setFreeHours(getFreeHours());
  }, [courses, usedHours]);

  useEffect(() => {
    if (!polizaId) {
      return;
    }
    setLoadingCoursesList(true);
    getRequestServicesByPoliza(polizaId)
      .then((poliza) => {
        setCoursesList(poliza?.requestServices);
        setTypePolizaId(poliza?.typePolizaId);
      })
      .finally(() => setLoadingCoursesList(false));
  }, [polizaId]);

  const handleOk = async () => {
    if (isProvider) {
      try {
        const values = await form.validateFields();
        if (
          (Object.keys(values).length !== 0 && freeHours >= 0) ||
          removeCourses.length ||
          (Object.keys(values).length !== 0 && canAddHours)
        ) {
          const id = itemData.requestServiceItemId;
          setSaveCoursesLoading(true);
          const data = {
            key: 'courses',
            courses,
            ...(removeCourses.length > 0 && { removeCourses }),
          };

          updateItemData(data, id, pagination)
            .then((success) => {
              setSaveCoursesLoading(false);
              if (success) {
                message.info('Cursos adicionados/removidos correctamente');
                setVisible(false);
                onCourseUpdated();
              } else {
                message.info('Ha ocurrido un error');
              }
            })
            .catch(() => {
              setSaveCoursesLoading(true);
              message.error('Ha ocurrido un error');
            });
        } else {
          setVisible(false);
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      setVisible(false);
    }
  };

  const handleCancel = () => {
    setVisible(false);
    setEditableRows({});
    setRemoveCourses([]);
    setUsedHours(getUsedHours());
    setCourses(JSON.parse(itemData.value));
  };

  const handleChange = (record, key, value, index) => {
    if (key === 'courseId') {
      record['course'] = value;
      value = allListCourses
        .filter((c) => c.typePoliza?.id === typePolizaId)
        .find((c) => c.name === value)?.id;
    } else if (key === 'course') {
      record['courseId'] =
        allListCourses
          .filter((c) => c.typePoliza?.id === typePolizaId)
          .find((c) => c.name === value)?.id || null;
    } else if (key === 'hours') {
      const diff = record.hours - value;
      setUsedHours(usedHours - diff);
    }
    record[key] = value;
    const str = Object.keys(courses)[index];
    setCourses({ ...courses, [str]: record });
  };

  const handleColRender = (key, inputType, min = 0, required = true) => {
    const defaultValue = {
      input: '',
      number: 0,
      checkbox: 0,
      hidden: 1,
      select: '',
    };
    const keys = Object.keys(courses);

    return {
      inputType,
      render: (value, record, index) => (
        <Form.Item
          name={`${keys[index]}.${key}`}
          initialValue={record[key] || defaultValue[inputType]}
          rules={required ? [{ required: true, message: '*' }] : []}
          style={{ margin: 0 }}
        >
          {inputType === 'number' ? (
            <InputNumber
              onChange={(v) => handleChange(record, key, v, index)}
              min={min}
            />
          ) : inputType === 'checkbox' ? (
            <Checkbox
              onChange={(e) =>
                handleChange(record, key, e.target.checked, index)
              }
              defaultChecked={value}
            />
          ) : inputType === 'hidden' ? (
            <Input type='hidden' />
          ) : (
            <Form.Item
              name={`${keys[index]}.${key}`}
              initialValue={
                record['course']
                  ? record['course']
                  : allListCourses.find((el) => el.id === record['courseId'])
                      ?.name || ''
              }
              rules={[{ required: true, message: '*' }]}
              style={{ margin: 0 }}
            >
              <AutoComplete
                style={{ width: 200 }}
                options={allListCourses
                  .filter((c) => c.typePoliza?.id === typePolizaId)
                  .map((el) => ({ value: el.name }))}
                onSelect={(e) => handleChange(record, 'courseId', e, index)}
                onSearch={(e) => handleChange(record, 'course', e, index)}
              />
            </Form.Item>
          )}
        </Form.Item>
      ),
    };
  };

  const handleDelete = (record, index) => {
    const hours = usedHours - record.hours;
    setUsedHours(hours);

    const tmp = { ...courses };
    const key = Object.keys(tmp)[index];
    delete tmp[key];
    setCourses(tmp);

    const el = coursesList.find((c) => c.id === requestServiceId) || {};
    if (el) {
      const items = el.requestServiceItems
        .map((rsi) =>
          rsi.requestServiceItemsData.map((co) => JSON.parse(co.value)),
        )
        .reduce((p, c) => p.concat(c), [])
        .reduce((p, c) => ({ ...p, ...c }), {});

      if (items.hasOwnProperty(key)) {
        setRemoveCourses([...removeCourses, key]);
      }
    }
  };

  const handleSelectCourse = (requestServiceItem, course) => {
    onEditParticipants(requestServiceItem, course);
    setVisible(false);
  };

  const columns = [
    {
      title: 'Curso',
      dataIndex: 'courseId',
      key: 'courseId',
      ...(isProvider && !requestFinished
        ? handleColRender('courseId', 'autocomplete')
        : {
            render: (val, item) =>
              item.course
                ? item.course
                : allListCourses.find((c) => c.id === val)?.name,
          }),
      width: 180,
    },
    {
      title: '# Horas',
      dataIndex: 'hours',
      key: 'hours',
      width: 70,
      ...(isProvider &&
        !requestFinished &&
        handleColRender('hours', 'number', 1)),
    },
    {
      title: 'Participantes',
      dataIndex: 'participants',
      key: 'participants',
      ...(isProvider &&
        !requestFinished &&
        handleColRender('participants', 'number', 0, false)),
      width: 80,
    },
    {
      title: 'Revisado',
      dataIndex: 'state',
      key: 'state',
      render: (state) => (state === 2 ? 'SI' : 'NO'),
      width: 70,
    },
    {
      title: 'Fecha de visita',
      dataIndex: 'visitDate',
      key: 'visitDate',
      width: 100,
      render: (visitDate) =>
        visitDate && moment.unix(visitDate).format('DD-MM-YY HH:mm'),
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      render: (status) =>
        status ? getStateRequestServiceItem({ state: status }) : '',
      width: 140,
    },
    {
      title: 'Acción',
      dataIndex: 'actions',
      key: 'actions',
      fixed: 'right',
      width: 120,
      render: (v, record, index) => (
        <span className='table-operation'>
          {isProvider &&
            !requestFinished &&
            record.status !== COURSE_STATUS_FINALIZADO && (
              <Tooltip title='Eliminar curso'>
                <Button
                  size='small'
                  className='btn-cmo-action-list'
                  onClick={() => handleDelete(record, index)}
                >
                  <i className='fas fa-trash'></i>
                </Button>
              </Tooltip>
            )}
          {checkPermission(user, 'allow_to_modify_schedule_date_services') &&
            record.visitDate && (
              <>
                <Tooltip title='Modifica fecha de visita'>
                  <Button
                    className='btn-cmo-action-list'
                    size='small'
                    style={{ marginRight: 8 }}
                    onClick={() => handleEditEventDate(index)}
                  >
                    <CalendarOutlined />
                  </Button>
                </Tooltip>
                {record.status !== COURSE_STATUS_FINALIZADO && (
                  <Tooltip title='Eliminar fecha de visita'>
                    <Popconfirm
                      title='¿Desea eliminar la fecha agendada?'
                      onConfirm={() => handleDeleteEventDate(index)}
                      onCancel={() => {}}
                      okText='Si'
                      cancelText='No'
                    >
                      <Button
                        className='btn-cmo-action-list'
                        size='small'
                        style={{ marginRight: 8 }}
                      >
                        <CloseSquareOutlined />
                      </Button>
                    </Popconfirm>
                  </Tooltip>
                )}
              </>
            )}
          {checkPermission(user, 'allow_to_upload_participants') &&
            requestServiceItem?.requestServiceItemsData?.find(
              (service) => service.key === 'courses',
            ) && (
              <Tooltip
                title={
                  requestServiceItem.pendingParticipant
                    ? 'Solicite sus certificados de capacitación'
                    : 'Participantes'
                }
              >
                <Badge
                  dot={requestServiceItem.pendingParticipant}
                  count={
                    requestServiceItem.pendingParticipant ? (
                      <WarningOutlined style={{ color: '#EF3340' }} />
                    ) : null
                  }
                >
                  <Button
                    size='small'
                    className='btn-cmo-action-list'
                    onClick={() =>
                      handleSelectCourse(requestServiceItem, record)
                    }
                  >
                    <AppstoreOutlined />
                  </Button>
                </Badge>
              </Tooltip>
            )}
        </span>
      ),
    },
  ].filter(Boolean);

  const getUsedHours = useCallback(() => {
    if (coursesList) {
      const obj = coursesList
        .map((e) => e.requestServiceItems)
        .reduce((p, c) => p.concat(c), [])
        .map((rsi) =>
          rsi.requestServiceItemsData.map((co) =>
            Object.values(JSON.parse(co.value)),
          ),
        )
        .reduce((p, c) => p.concat(c), [])
        .reduce((p, c) => p.concat(c), []);

      return Object.values(obj).reduce(
        (sum, co) => sum + (isNaN(co?.hours) ? 0 : Number(co?.hours)),
        0,
      );
    }

    return 0;
  }, [coursesList]);

  const getHoursLeft = () => {
    if (loadingCoursesList) return ' ';
    if (polizaServices && polizaServices.length) {
      const [service] = polizaServices || [];
      const hoursLeft = service
        ? parseInt(service.count, 10) - (usedHours || 0)
        : 0;
      const desc = ['hora disponible', 'horas disponibles'];

      return hoursLeft > 0
        ? `${hoursLeft} ${desc[+(hoursLeft > 1)]}`
        : `0 ${desc[1]}`;
    }

    return null;
  };

  const handleAddCourse = () => {
    const index = Object.keys(courses).length;
    const id = `course-${cryptoUtils.randomStr(3)}`;
    const course = {
      course: '',
      courseId: '',
      hours: 1,
      participants: 0,
      isProvince: true,
      state: 1,
    };
    setUsedHours(usedHours + 1);
    setCourses({ ...courses, [id]: course });
    setEditableRows({ ...editableRows, [`row_${index}`]: true });
  };

  const getFreeHours = () => {
    const [service] = polizaServices || [];

    return service ? parseInt(service.count, 10) - usedHours : 0;
  };

  const handleEditEventDate = (index) => {
    const tmp = { ...courses };
    const course_key = Object.keys(tmp)[index];
    onEditEventDate({ requestServiceItemDataId: itemData.id, course_key });
  };

  const handleDeleteEventDate = (index) => {
    const tmp = { ...courses };
    const course_key = Object.keys(tmp)[index];
    deleteRequestServiceItemCapacitacionEventDate(
      { requestServiceItemDataId: itemData.id, course_key },
      loadList,
    );
  };

  useEffect(() => {
    setUsedHours(getUsedHours());
  }, [coursesList, getUsedHours]);

  return (
    <div>
      <label>Cursos</label>
      <p>
        {`${Object.keys(courses).length} Cursos `}
        <Button className='ml-1' onClick={() => setVisible(true)}>
          <EyeOutlined />
        </Button>
      </p>
      <p>{getHoursLeft()}</p>
      <p>
        <a
          target='_blank'
          href={'/templates/PlantillaCapacitacionParticipantes.xlsx'}
          rel='noreferrer'
        >
          Descargar plantilla de asistencia <DownloadOutlined />
        </a>
      </p>

      <Modal
        title=''
        onOk={handleOk}
        onCancel={handleCancel}
        open={visible}
        okText='Aceptar'
        cancelText='Cancelar'
        destroyOnClose
        className={`modal-provider modal-centered ${
          isMobileScreen ? 'modal-mobile' : ''
        }`}
        maskClosable={false}
        width='80%'
        okButtonProps={{
          loading: saveCoursesLoading,
          disabled: saveCoursesLoading,
        }}
      >
        <Title type='bold-28' style={{ textAlign: 'center' }}>
          {'Detalles'}
        </Title>
        <Divider className='mt-2 mb-3' />
        <Form form={form}>
          {isProvider && !requestFinished && (
            <Row type='flex' style={{ marginBottom: 24 }}>
              <Col>
                <Button
                  onClick={handleAddCourse}
                  type='primary'
                  style={{ marginRight: 16 }}
                  disabled={freeHours <= 0 && !canAddHours}
                >
                  Añadir Curso
                </Button>
              </Col>
              {
                <Col>
                  {freeHours >= 0 ? (
                    <Alert
                      message={`Horas restantes ${freeHours}`}
                      type='info'
                    />
                  ) : (
                    <Alert
                      message='Sobrepasó el límite de horas'
                      type='warning'
                    />
                  )}
                </Col>
              }
            </Row>
          )}
          <Table
            dataSource={Object.keys(courses).map((key) => ({
              ...courses[key],
              course_key: key,
            }))}
            rowKey={(data) => data.id || data.course_key}
            columns={columns}
            scroll={{ x: 1100 }}
            className='table-participants'
            pagination={false}
          />
        </Form>
      </Modal>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  loadAllListCourses: () => getAllCourses(dispatch),
  updateItemData: (data, id, pagination) =>
    updateRequestServiceItemData(dispatch, data, id, pagination),
});

const mapStateToProps = (state) => ({
  user: state.auth.user,
  allListCourses: state.courses.list || [],
  pagination: state.requestServicesItems.pagination,
});

export default connect(mapStateToProps, mapDispatchToProps)(ListCourses);
