import React, { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from 'antd';

import { checkModules, checkPermission } from '../../../../lib/security';
import { MAP_CHANGE_MULTIPLE_SELECTION_MODE } from '../../../../redux/actions/actionsType';

const SwitchLayerMode = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const multiSelectMode = useSelector((state) => state.maps.multiSelectMode);

  const handleChangeMode = (isMultiSelectionMode) => {
    dispatch({
      type: MAP_CHANGE_MULTIPLE_SELECTION_MODE,
      payload: isMultiSelectionMode,
    });
  };

  return (
    <>
      {checkPermission(user, 'allow_multiselection_mode') &&
        checkModules(user, 'multi_select') && (
          <div className='absolute flex align-items justify-between top-0 full-width'>
            <span>Modo multiselección:</span>
            <Switch onChange={handleChangeMode} checked={multiSelectMode} />
          </div>
        )}
    </>
  );
};

export default memo(SwitchLayerMode);
