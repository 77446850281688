import axios from 'axios';
import aws4 from 'aws4';
import moment from 'moment';
import { getClockDriftLocalStore } from './auth';
import { v4 as uuidv4 } from 'uuid';

export default axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL_NEW || 'http://localhost:3022',
  headers: {
    'content-type': 'application/json',
  },
  responseType: 'json',
});

export const isApiCanal = (path) => {
  return path?.includes('api-canal');
};

export const signedAxios = async (request, { credentials }) => {
  const { headers = {} } = request;
  const { host, pathname, search } = new URL(request.url);
  const [, hostHeader] =
    Object.entries(headers).find(([prop]) => prop.toLowerCase() === 'host') ||
    [];

  request.host = hostHeader || host;
  request.path = pathname + search;

  let body = request.data;

  if (body)
    if (typeof body === 'object') {
      body = JSON.stringify(body);
      request.data = body;
      request.headers = request.headers || {};
      request.headers['Content-Type'] = 'application/json';
    }

  const clockDrift = getClockDriftLocalStore();
  const date = moment().utc(0).add(-clockDrift, 'seconds');

  const customHeaders = {
    'X-Amz-Date': date.format('YYYYMMDD[T]HHmmss[Z]'),
    'content-type': 'application/json',
    datetime: date.format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
    traceid: uuidv4(),
    'x-api-key': isApiCanal(request.path)
      ? process.env.REACT_APP_API_CANAL_WEB_KEY
      : process.env.REACT_APP_API_KEY,
    'Strict-Transport-Security': 'max-age=31536000; includeSubDomains',
    'X-Frame-Options': 'SAMEORIGIN',
    'Referrer-Policy': 'no-referrer-when-downgrade',
    'X-Content-Type-Options': 'nosniff',
    'X-XSS-Protection': '1; mode=block',
  };

  const signedRequest = aws4.sign(
    {
      ...request,
      body,
      headers: customHeaders,
      region:
        request.region ||
        `${
          isApiCanal(request.path)
            ? process.env.REACT_APP_API_CANAL_REGION
            : process.env.REACT_APP_COGNITO_REGION
        }`,
      service: request.service || 'execute-api',
    },
    credentials,
  );

  if (signedRequest.headers)
    if (!hostHeader) {
      delete signedRequest.headers.Host;
      delete signedRequest.headers['Content-Length'];
    }

  try {
    const response = await axios.request(signedRequest);
    return response;
  } catch (error) {
    if (error.response) {
      return error.response.data;
    } else {
      throw error;
    }
  }
};
