import moment from 'moment';
import { Button, Skeleton } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

export const IconClose = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={32}
    height={32}
    fill='none'
    {...props}
  >
    <circle cx={16} cy={16} r={15.5} fill='#fff' stroke='#A9AFD9' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.913 11.38a.5.5 0 0 1 .707.707L16.707 16l3.913 3.913a.5.5 0 0 1-.707.707L16 16.707l-3.913 3.913a.5.5 0 0 1-.707-.707L15.293 16l-3.913-3.913a.5.5 0 0 1 .707-.707L16 15.293l3.913-3.913Z'
      fill='#A9AFD9'
    />
  </svg>
);

export const iconRight = ({ color = '#4F4FFF' }) => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle
      cx='16'
      cy='16'
      r='15'
      transform='rotate(-90 16 16)'
      stroke={color}
      strokeWidth='2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.2532 11.2387C13.5823 10.9204 14.11 10.9204 14.4391 11.2387L18.7468 15.4054C19.0844 15.7319 19.0844 16.2681 18.7468 16.5946L14.4391 20.7613C14.11 21.0796 13.5823 21.0796 13.2532 20.7613C12.9156 20.4347 12.9156 19.8986 13.2532 19.5721L16.9462 16L13.2532 12.4279C12.9156 12.1014 12.9156 11.5653 13.2532 11.2387Z'
      fill={color}
    />
  </svg>
);

export const iconLeft = ({ color = '#4F4FFF' }) => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle
      cx='16'
      cy='16'
      r='15'
      transform='rotate(90 16 16)'
      stroke={color}
      strokeWidth='2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18.7468 11.2387C18.4177 10.9204 17.89 10.9204 17.5609 11.2387L13.2532 15.4054C12.9156 15.7319 12.9156 16.2681 13.2532 16.5946L17.5609 20.7613C17.89 21.0796 18.4177 21.0796 18.7468 20.7613C19.0844 20.4347 19.0844 19.8986 18.7468 19.5721L15.0539 16L18.7468 12.4279C19.0844 12.1014 19.0844 11.5653 18.7468 11.2387Z'
      fill={color}
    />
  </svg>
);

export const IconDownload = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={16}
    height={17}
    fill='none'
    {...props}
  >
    <path
      d='M3.53129 13.25C3.53129 13.4489 3.6103 13.6397 3.75096 13.7803C3.89161 13.921 4.08238 14 4.28129 14L11.7813 14C11.9802 14 12.171 13.921 12.3116 13.7803C12.4523 13.6397 12.5313 13.4489 12.5313 13.25C12.5313 13.0511 12.4523 12.8603 12.3116 12.7197C12.171 12.579 11.9802 12.5 11.7813 12.5L4.28129 12.5C4.08238 12.5 3.89161 12.579 3.75096 12.7197C3.6103 12.8603 3.53129 13.0511 3.53129 13.25ZM3.84254 6L5.03129 6L5.03129 3C5.03129 2.73478 5.13665 2.48043 5.32418 2.29289C5.51172 2.10536 5.76607 2 6.03129 2L10.0313 2C10.2965 2 10.5509 2.10536 10.7384 2.29289C10.9259 2.48043 11.0313 2.73478 11.0313 3L11.0313 6L12.1875 6C12.3356 6.00013 12.4804 6.0441 12.6035 6.12636C12.7267 6.20863 12.8227 6.3255 12.8795 6.46226C12.9363 6.59902 12.9514 6.74953 12.9228 6.89484C12.8942 7.04014 12.8232 7.17372 12.7188 7.27875L8.73879 11.2875C8.64588 11.3809 8.53543 11.4549 8.4138 11.5055C8.29217 11.556 8.16175 11.582 8.03004 11.582C7.89833 11.582 7.76791 11.556 7.64628 11.5055C7.52465 11.4549 7.4142 11.3809 7.32128 11.2875L3.31129 7.2775C3.20731 7.17241 3.13672 7.03893 3.10836 6.89384C3.08001 6.74875 3.09516 6.59852 3.15193 6.46202C3.20869 6.32551 3.30453 6.20883 3.42741 6.12663C3.55028 6.04443 3.6947 6.00037 3.84254 6Z'
      fill={props.color ? props.color : '#432EFF'}
    />
  </svg>
);

export const CardSkeleton = () => {
  return (
    <div className='card-skeleton'>
      <Skeleton active paragraph={{ rows: 5 }} />
    </div>
  );
};

export const CardDetail = ({ info, show }) => {
  return (
    <article className='card-detail'>
      <div className='card-detail__item'>
        <h4>Nombre de contacto</h4>
        <p>{info?.contact_name}</p>
      </div>
      <div className='card-detail__item'>
        <h4>Fecha de servicio</h4>
        <p>{moment.unix(info?.date).format('DD/MM/YYYY H:mm:ss')}</p>
      </div>

      {show && (
        <>
          <div className='card-detail__item'>
            <h4>Empresa</h4>
            <p>
              {info.request_service_item?.requestService?.company?.name || ''}
            </p>
          </div>

          <div className='card-detail__item'>
            <h4>Gestor de cuenta</h4>
            <p>
              {info.request_service_item?.requestService?.company
                ?.companiesUsers[0]?.name || ''}
            </p>
          </div>
        </>
      )}

      <div className='card-detail__item'>
        <h4>% cumplimiento</h4>
        <p>{info?.compliance_percent}</p>
      </div>

      <div className='card-detail__item'>
        <h4>Informe</h4>
        <p>{info?.filename || '-'}</p>
      </div>

      <div className='card-detail__item'>
        <h4>Tipo de servicio</h4>
        <p>{info?.request_service_item.service?.name}</p>
      </div>

      <div className='card-detail__item'>
        <Button
          className='down-button'
          type='link'
          icon={<DownloadOutlined />}
          onClick={() => {
            window.open(
              `${process.env.REACT_APP_WEB_PATH_S3_NEW}${info?.filename_uploaded}`,
              '_blank',
            );
          }}
        >
          Descargar informe
        </Button>
      </div>
    </article>
  );
};

export const IconInfo = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={64}
    height={64}
    fill='none'
    {...props}
  >
    <path
      d='m54.075 51.25-31.587 8.468c-4.243 1.132-8.605-1.378-9.747-5.622L4.273 22.509c-1.132-4.243 1.379-8.605 5.622-9.747l31.587-8.468c4.243-1.132 8.605 1.379 9.747 5.622l8.468 31.587c1.142 4.243-1.378 8.605-5.622 9.747Z'
      fill='#D7DBF5'
    />
    <path
      d='m24.842 35.615-3.023 5.78 4.303-1.123 4.037-2.265-5.317-2.392ZM53.848 17.31l-5.14 2.935-9.038 22.183 7.06 4.096c.128.07.256.109.384.168l-.936 1.24 2.866-1.162-.02-.01c.984-.314 1.802-1.151 1.96-2.303L54.3 20.304a5.612 5.612 0 0 0-.453-2.993Z'
      fill='#A0A0FA'
    />
    <path
      d='M51.643 14.889 28.022 1.252a2.828 2.828 0 0 0-2.698-.08l-2.993 1.202 1.418 1.192L20.4 27.383a5.388 5.388 0 0 0 2.648 5.435l2.669 1.546 4.618 2.669 9.344 5.415 9.038-22.183 5.14-2.935a5.48 5.48 0 0 0-2.215-2.441Z'
      fill='#C8C8FA'
    />
    <path
      d='M24.96 2.482 48.58 16.13c1.9 1.103 2.876 3.683 2.57 5.859l-3.239 23.71c-.276 2.008-2.5 3.09-4.254 2.077l-16.393-9.512-5.445 3.141.827-5.809-2.669-1.546a5.404 5.404 0 0 1-2.648-5.435L20.706 4.55c.296-1.999 2.51-3.072 4.254-2.068Z'
      fill='#FAFBFF'
    />
    <path
      d='M31.901 35.142a15.34 15.34 0 0 1-7.522-15.537c.788-5.337 6.705-8.202 11.382-5.524a14.811 14.811 0 0 1 7.296 15.016l-.098.689C42.2 34.965 36.45 37.75 31.9 35.142Z'
      fill='#D7DBF5'
    />
    <path
      d='m35.288 23.041-2.855-1.654-1.32 9.354 2.856 1.654 1.319-9.354ZM34.048 21.19a2.917 2.917 0 0 1-1.428-2.944 1.446 1.446 0 0 1 2.156-1.043 2.801 2.801 0 0 1 1.379 2.845l-.02.128a1.395 1.395 0 0 1-2.087 1.014Z'
      fill='#A0A0FA'
    />
  </svg>
);

export const IconDots = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    {...props}
  >
    <circle
      cx={20}
      cy={12}
      r={2}
      transform='rotate(180 20 12)'
      fill='#4F4FFF'
    />
    <circle
      cx={12}
      cy={12}
      r={2}
      transform='rotate(180 12 12)'
      fill='#4F4FFF'
    />
    <circle cx={4} cy={12} r={2} transform='rotate(180 4 12)' fill='#4F4FFF' />
  </svg>
);

export const IconDocument = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={16}
    height={16}
    fill='none'
    {...props}
  >
    <path
      d='M10.998 3.014H6.501a2.99 2.99 0 0 0-2.998 2.998v5.98c0 .83.668 1.499 1.499 1.499h5.996c.83 0 1.499-.668 1.499-1.5V4.514c0-.83-.668-1.499-1.499-1.499Zm-1.01 7.984H5.996a.508.508 0 0 1-.505-.505c0-.277.228-.505.505-.505h3.992c.277 0 .505.228.505.505a.508.508 0 0 1-.505.505Zm0-2.493H5.996A.508.508 0 0 1 5.49 8c0-.277.228-.505.505-.505h3.992c.277 0 .505.228.505.505a.508.508 0 0 1-.505.505Z'
      fill='#141938'
    />
  </svg>
);

export const IconEye = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={16}
    height={16}
    fill='none'
    {...props}
  >
    <path
      d='M13.822 7.442c-.094-.133-2.329-3.447-5.831-3.447-3.503 0-5.738 3.314-5.832 3.447a1 1 0 0 0 0 1.117c.094.132 2.329 3.446 5.832 3.446 3.502 0 5.737-3.314 5.832-3.446a1 1 0 0 0 0-1.117Zm-5.831 3.56A2.998 2.998 0 0 1 4.999 8.01 2.998 2.998 0 0 1 7.99 5.018a2.998 2.998 0 0 1 2.991 2.992A2.986 2.986 0 0 1 7.991 11ZM9.998 8.01c0 1.098-.89 2.007-2.007 2.007A1.996 1.996 0 0 1 5.983 8.01c0-1.118.89-2.008 2.008-2.008 1.117 0 2.007.89 2.007 2.008Z'
      fill={props.color || '#141938'}
    />
  </svg>
);

export const IconTrash = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={16}
    height={16}
    fill='none'
    {...props}
  >
    <path
      d='M11 3.5a.998.998 0 0 0-.997-.998H6a.998.998 0 0 0-.998.998H3.007v1.508h.998V12a1.5 1.5 0 0 0 1.499 1.5h5a1.5 1.5 0 0 0 1.5-1.5V5.008h.998V3.5H11ZM7 11a.5.5 0 1 1-.997 0V7.004a.5.5 0 1 1 .997 0v3.998Zm3 0a.5.5 0 0 1-.997 0V7.004a.5.5 0 0 1 .997 0v3.998Z'
      fill={props.color || '#141938'}
    />
  </svg>
);

export const IconCloseModal = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={32}
    height={32}
    fill='none'
    {...props}
  >
    <circle cx={16} cy={16} r={15.5} stroke='#A9AFD9' />
    <path d='m11 11 10 10M21 11 11 21' stroke='#A9AFD9' strokeLinecap='round' />
  </svg>
);

export const IconNotData = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={64}
    height={64}
    fill='none'
    {...props}
  >
    <path
      d='m54.173 51.25-31.586 8.467c-4.244 1.132-8.606-1.379-9.748-5.622L4.372 22.508c-1.133-4.243 1.378-8.605 5.622-9.747L41.58 4.293c4.244-1.132 8.606 1.378 9.748 5.622l8.467 31.587c1.143 4.243-1.378 8.605-5.622 9.747Z'
      fill='#D7DBF5'
    />
    <path
      d='M48.403 12.465a19.682 19.682 0 0 0-8.201-8.881c-4.392-2.471-9.364-2.215-13.283-.03l-.089.05c-.01.01-.02.01-.03.02l-3.022 1.693.227.413c-2.039 1.99-3.476 4.648-3.929 7.779l-.079.541a19.7 19.7 0 0 0 3.436 14.179 19.797 19.797 0 0 0 6.38 5.78l.01.01a13.345 13.345 0 0 0 6.568 1.762l5.425 15.675a.503.503 0 0 0 .729.276l2.008-1.162a2.699 2.699 0 0 0 1.202-3.23L41.48 34.983l1.399-.787c.118-.06.226-.128.334-.187l.276-.158c.364-.216.729-.453 1.073-.709 2.806-2.058 4.854-5.199 5.396-9.058l.079-.552c.57-3.85-.06-7.68-1.635-11.067Z'
      fill='#A0A0FA'
    />
    <path
      d='M35.407 8.36a9.896 9.896 0 0 0-4.933-1.31c-5.002 0-9.315 3.731-10.014 8.684l-.079.542c-.945 6.666 2.226 13.115 8.074 16.433 1.566.886 3.24 1.339 4.973 1.339 5.011 0 9.324-3.742 10.013-8.704l.079-.551c.935-6.676-2.245-13.125-8.113-16.434Z'
      fill='#FAFBFF'
    />
    <path
      d='M37.12 5.317a13.458 13.458 0 0 0-6.656-1.763c-6.45 0-12.475 4.658-13.48 11.688l-.078.541a19.745 19.745 0 0 0 9.816 19.959l.01.01a13.46 13.46 0 0 0 6.686 1.781c.325 0 .66-.02.984-.039l4.51 13.027a2.431 2.431 0 0 0 3.505 1.31 2.699 2.699 0 0 0 1.201-3.23l-4.302-12.416c3.889-1.871 6.892-5.554 7.571-10.349l.08-.551A19.725 19.725 0 0 0 37.12 5.317Zm6.4 19.466-.079.551c-.699 4.963-5.002 8.704-10.013 8.704-1.743 0-3.407-.443-4.973-1.339-5.848-3.318-9.019-9.767-8.073-16.433l.078-.542c.7-4.952 5.012-8.684 10.014-8.684 1.733 0 3.387.443 4.933 1.31 5.868 3.318 9.048 9.767 8.113 16.433Z'
      fill='#C8C8FA'
    />
    <path
      d='M32.61 26.27a2.097 2.097 0 0 0-.423-.512 1.654 1.654 0 0 0-.571-.325 1.363 1.363 0 0 0-.62-.06c-.198.03-.375.1-.532.198a1.293 1.293 0 0 0-.394.433 1.652 1.652 0 0 0-.207.62c-.04.236-.03.473.02.7.049.226.128.442.246.63.118.196.256.364.433.502.177.147.364.256.58.324.208.07.405.089.611.06a1.24 1.24 0 0 0 .532-.197c.158-.109.286-.247.394-.424.108-.177.177-.384.216-.62.03-.227.03-.463-.02-.7a3.03 3.03 0 0 0-.265-.63ZM36.913 17.044a4.398 4.398 0 0 0-.61-1.448 4.415 4.415 0 0 0-1.113-1.152 5.372 5.372 0 0 0-1.536-.778 5.848 5.848 0 0 0-1.201-.255 5.418 5.418 0 0 0-1.064.02c-.334.048-.65.127-.945.245-.295.118-.58.246-.837.394l.483 1.35a.68.68 0 0 0 .472.501.57.57 0 0 0 .355-.03c.137-.049.305-.108.512-.157.197-.059.433-.088.709-.098.275-.01.6.049.984.167.256.079.492.197.71.355.206.157.383.334.521.531.138.207.236.424.295.67.06.236.07.492.03.758-.06.404-.187.729-.374.975a2.808 2.808 0 0 1-.66.62 5.159 5.159 0 0 1-.797.423 6.014 6.014 0 0 0-.798.394 2.727 2.727 0 0 0-.65.502c-.187.197-.295.453-.345.778-.01.04-.01.07-.01.099v.098l-.098 1.87 1.684.543.463-1.546a.99.99 0 0 1 .393-.542c.197-.138.424-.266.69-.384.256-.118.541-.256.827-.394a3.463 3.463 0 0 0 1.546-1.457c.206-.364.354-.827.433-1.388.069-.581.05-1.133-.069-1.664Z'
      fill='#A0A0FA'
    />
    <path
      d='M23.768 15.498c-.197 0-.394-.03-.59-.109a1.723 1.723 0 0 1-1.044-2.195c.108-.296 1.103-2.915 3.682-3.683a1.73 1.73 0 1 1 .985 3.318c-.906.276-1.398 1.526-1.408 1.536a1.717 1.717 0 0 1-1.625 1.133Z'
      fill='#D7DBF5'
    />
  </svg>
);

export const IconEditTable = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={16}
    height={16}
    fill='none'
    {...props}
  >
    <path
      d='M13.894 2.104a1.717 1.717 0 0 0-1.228-.504c-.443 0-.885.16-1.228.504L6.735 6.811a1.158 1.158 0 0 0-.295.48l-.81 2.42a.521.521 0 0 0 .49.69c.05 0 .111-.013.172-.025l2.42-.812c.184-.061.343-.16.478-.295l4.704-4.707a1.744 1.744 0 0 0 0-2.458Z'
      fill={props.color ? props.color : '#141938'}
    />
    <path
      d='M12.1 11.4c0 .825-.675 1.5-1.5 1.5h-6c-.825 0-1.5-.675-1.5-1.5v-6c0-.825.675-1.5 1.5-1.5h2.385l1.5-1.5H4.6c-1.65 0-3 1.35-3 3v6c0 1.65 1.35 3 3 3h6c1.65 0 3-1.35 3-3V7.515l-1.5 1.5V11.4Z'
      fill={props.color ? props.color : '#141938'}
    />
  </svg>
);

export const IconInfoService = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={48}
    height={48}
    fill='none'
    {...props}
  >
    <path
      d='m40.544 38.435-23.69 6.36c-3.18.843-6.444-1.042-7.316-4.221L3.18 16.884c-.844-3.18 1.042-6.444 4.22-7.316L31.09 3.209c3.18-.844 6.443 1.041 7.316 4.22l6.358 23.691c.873 3.18-1.04 6.471-4.22 7.315Z'
      fill='#D7DBF5'
    />
    <path
      d='M40.375 13a4.145 4.145 0 0 0-1.66-1.829L20.99.929c-1.322-.76-2.982.057-3.179 1.548l-2.532 18.035a4.115 4.115 0 0 0 1.97 4.08l1.997 1.153-.619 4.361 4.08-2.363 7.006 4.052 6.78-16.629L40.376 13Z'
      fill='#FF858D'
    />
    <path d='m36.774 35.086-2.139.872 1.07-1.435 1.069.563Z' fill='#141938' />
    <path
      d='m40.375 13-3.854 2.195-6.781 16.628 5.29 3.067c1.322.76 2.982-.056 3.179-1.548l2.476-18.12c.14-.759 0-1.547-.31-2.222Z'
      fill='#141938'
    />
    <path d='m18.992.875-2.251.9 6.387 5.346L18.992.875Z' fill='#FF858D' />
    <path
      d='M18.71 1.859 36.436 12.1c1.435.816 2.166 2.758 1.941 4.39l-2.42 17.781c-.196 1.492-1.884 2.307-3.179 1.548l-12.295-7.147-4.108 2.392.62-4.361-1.999-1.154a4 4 0 0 1-1.97-4.08L15.56 3.434c.197-1.52 1.857-2.335 3.151-1.575Z'
      fill='#FAFBFF'
    />
    <path
      d='M26.701 27.404a5.713 5.713 0 0 1-2.926-.788c-4.22-2.448-6.5-7.119-5.796-11.958a6.04 6.04 0 0 1 3.348-4.558 6.013 6.013 0 0 1 5.655.197c4.108 2.335 6.303 6.893 5.628 11.564l-.085.506c-.281 1.942-1.49 3.573-3.263 4.446a5.879 5.879 0 0 1-2.56.59ZM23.971 10.1c-.815 0-1.603.197-2.39.563-1.633.787-2.73 2.279-3.011 4.107-.675 4.587 1.463 9.032 5.486 11.34 1.548.872 3.32.956 4.924.168 1.604-.788 2.645-2.223 2.926-3.967l.085-.506a10.75 10.75 0 0 0-5.318-10.945c-.844-.535-1.773-.76-2.701-.76Z'
      fill='#96EBCB'
    />
    <path
      d='m26.476 17.277-2.166-1.238-.985 7.006 2.138 1.266 1.013-7.034ZM25.547 15.898a2.14 2.14 0 0 1-1.069-2.195 1.088 1.088 0 0 1 1.604-.787c.76.422 1.154 1.266 1.041 2.138l-.028.084c-.084.732-.9 1.126-1.547.76Z'
      fill='#FF1C44'
    />
  </svg>
);

export const IconCopy = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={16}
    height={16}
    fill='none'
    {...props}
  >
    <path
      d='M11.5 4.498H11V3.51a1.499 1.499 0 0 0-1.5-1.498H4.506a1.5 1.5 0 0 0-1.5 1.498v6.988a1.501 1.501 0 0 0 1.5 1.5H5.51v.5a1.5 1.5 0 0 0 1.5 1.499h4.499a1.497 1.497 0 0 0 1.5-1.499v-6.5a1.5 1.5 0 0 0-1.51-1.5Zm-6 1.5v5H4.496a.5.5 0 0 1-.5-.5V3.51a.501.501 0 0 1 .51-.5h5a.502.502 0 0 1 .5.5v.988H7.001a1.5 1.5 0 0 0-1.5 1.5Zm5.001 5h-2.5a.503.503 0 0 1 0-1.006h2.5a.503.503 0 1 1 0 1.006Zm0-2.5h-2.5a.503.503 0 1 1 0-1.006h2.5a.503.503 0 1 1 0 1.006Z'
      fill='#4F4FFF'
    />
  </svg>
);

export const IconFile = (props) => (
  <svg
    width={16}
    height={20}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M14.026 0H6.015v2.996a2.999 2.999 0 0 1-2.996 2.997H0v12.01C0 19.12.903 20 1.997 20h12.006a1.994 1.994 0 0 0 1.996-1.998V1.998A1.959 1.959 0 0 0 14.026 0ZM12.03 15.03H4.018c-.547 0-.999-.452-.999-1 0-.546.452-.998.999-.998h8.011c.547 0 .999.452.999.999 0 .547-.452.999-.999.999Zm0-3.995H4.018c-.547 0-.999-.452-.999-1 0-.546.452-.998.999-.998h8.011c.547 0 .999.452.999.999 0 .547-.452.998-.999.998Z'
      fill='#141938'
    />
    <path
      d='M4.018 3.02V0L0 4.019h2.995a1.01 1.01 0 0 0 1.023-.999Z'
      fill='#141938'
    />
  </svg>
);

export const IconImage = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    {...props}
  >
    <path
      d='M18 4.5H6a3.004 3.004 0 0 0-3 3v9a3.003 3.003 0 0 0 3 3h12a3.003 3.003 0 0 0 3-3v-9a3.003 3.003 0 0 0-3-3Zm-3 2.626a1.874 1.874 0 1 1 0 3.748 1.874 1.874 0 0 1 0-3.748Zm4.5 9.374A1.5 1.5 0 0 1 18 18H6a1.5 1.5 0 0 1-1.5-1.5v-2.248l3.441-3.441a1.495 1.495 0 0 1 2.118 0l4.059 4.037 1.87-1.637a.756.756 0 0 1 1.027.035l2.503 2.502-.018.752Z'
      fill='#141938'
    />
  </svg>
);

export const IconCheck = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={16}
    height={16}
    fill='none'
    {...props}
  >
    <path
      d='M6.5 13a.992.992 0 0 1-.63-.214L2.402 9.974a1.002 1.002 0 0 1 .915-1.736 1 1 0 0 1 .346.182l2.692 2.184 5.871-7.225a1 1 0 0 1 1.552 1.26l-6.501 7.99a1 1 0 0 1-.777.37Z'
      fill='#141938'
    />
  </svg>
);

export const IconCloseRed = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={32}
    height={32}
    fill='none'
    {...props}
  >
    <circle cx={16} cy={16} r={15.5} stroke={props.color || '#FF1C44'} />
    <path
      d='m11 11 10 10M21 11 11 21'
      stroke={props.color || '#FF1C44'}
      strokeLinecap='round'
    />
  </svg>
);

export const IconPlus = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={20}
    height={20}
    fill='none'
    {...props}
  >
    <circle cx={10} cy={10} r={9} stroke='#4F4FFF' strokeWidth={2} />
    <path
      d='M10 6.875v6.25M13.125 10h-6.25'
      stroke='#4F4FFF'
      strokeWidth={1.5}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export const IconStudy = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={16}
    height={16}
    fill='none'
    {...props}
  >
    <path
      d='M15.445 4.65 9.074 1.83a2.687 2.687 0 0 0-2.184 0l-6.354 2.8c-.775.343-.663 1.445.036 1.8l6.425 2.563c.645.254 1.36.254 2.005 0l5.664-2.273v2.873c0 .381.309.672.667.672A.67.67 0 0 0 16 9.594V5.522c0-.59-.269-.748-.555-.873Z'
      fill='#432EFF'
    />
    <path
      d='M7.019 10.075 2.667 8.267v3.223c0 1.168 2.39 2.11 5.333 2.11s5.333-.942 5.333-2.11V8.266l-4.352 1.81a2.531 2.531 0 0 1-1.962 0Z'
      fill='#432EFF'
    />
  </svg>
);

export const IconCloseWithoutCircle = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={12}
    height={12}
    fill='none'
    {...props}
  >
    <path
      d='m7.061 6 2.47-2.47a.75.75 0 0 0-1.06-1.06L6 4.946 3.531 2.477a.75.75 0 0 0-1.06 1.06l2.47 2.47-2.47 2.461a.75.75 0 0 0 1.06 1.06L6 7.058l2.47 2.47a.748.748 0 0 0 1.28-.53.75.75 0 0 0-.219-.53L7.061 6Z'
      fill='#141938'
    />
  </svg>
);

export const IconEmail = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={16}
    height={16}
    fill='none'
    {...props}
  >
    <path
      d='M12 4H4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2Zm0 1c.135 0 .268.028.392.08L8.9 7.7a1.509 1.509 0 0 1-1.8 0L3.609 5.08C3.733 5.028 3.866 5 4 5H12Zm1 5a1.001 1.001 0 0 1-1 1H4a1.001 1.001 0 0 1-1-1V6c.002-.039.006-.078.012-.116L6.5 8.5a2.5 2.5 0 0 0 1.5.497A2.5 2.5 0 0 0 9.5 8.5l3.488-2.616c.006.038.01.077.012.116v4Z'
      fill='#141938'
    />
  </svg>
);

export const IconDocumentOutline = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={16}
    height={16}
    fill='none'
    {...props}
  >
    <path
      d='M10.998 13.996H5.002a2.003 2.003 0 0 1-2.004-2.005v-5.98A3.508 3.508 0 0 1 6.5 2.51h4.497c1.108 0 2.004.896 2.004 2.004v7.479a2.003 2.003 0 0 1-2.004 2.004ZM6.5 3.519a2.493 2.493 0 0 0-2.493 2.493v5.98c0 .553.456.993.994.993h5.996a.998.998 0 0 0 .994-.993v-7.48a.998.998 0 0 0-.994-.993H6.5Zm3.992 4.48a.508.508 0 0 0-.505-.504H5.996A.508.508 0 0 0 5.49 8c0 .277.228.505.505.505h3.992A.497.497 0 0 0 10.493 8Zm0 2.494a.508.508 0 0 0-.505-.506H5.996a.508.508 0 0 0-.505.505c0 .277.228.506.505.506h3.992a.497.497 0 0 0 .505-.505Z'
      fill='#141938'
    />
  </svg>
);

export const IconClip = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={20}
    height={20}
    fill='none'
    {...props}
  >
    <path
      d='M11.768 17.49a5.334 5.334 0 0 1-3.906-1.702l-4.756-5.123a.777.777 0 0 1 .301-1.263.78.78 0 0 1 .844.201l4.756 5.12a3.725 3.725 0 0 0 2.758 1.206h.02a3.729 3.729 0 0 0 2.758-1.233 3.769 3.769 0 0 0-.124-5.203L9.674 4.75a2.364 2.364 0 0 0-3.269 0 2.312 2.312 0 0 0 0 3.269l4.69 4.69a.986.986 0 0 0 1.396 0 .98.98 0 0 0 .276-.699.979.979 0 0 0-.276-.695L8.196 7.007a.78.78 0 0 1 0-1.103.786.786 0 0 1 1.103 0l4.306 4.303a2.525 2.525 0 0 1 .748 1.802 2.531 2.531 0 0 1-.748 1.804 2.554 2.554 0 0 1-3.605 0l-4.69-4.69a3.884 3.884 0 0 1 0-5.481A3.862 3.862 0 0 1 8.052 2.51a3.862 3.862 0 0 1 2.742 1.134l4.745 4.744a5.33 5.33 0 0 1-3.743 9.101h-.028Z'
      fill='#432EFF'
    />
  </svg>
);

export const IconMarker = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={20}
    height={20}
    fill='none'
    {...props}
  >
    <path
      d='M10 3.747a4.385 4.385 0 0 1 4.379 4.38 5.786 5.786 0 0 1-1.304 3.645L10 15.519l-3.075-3.747A5.786 5.786 0 0 1 5.62 8.126 4.372 4.372 0 0 1 10 3.747Zm0-1.242a5.62 5.62 0 0 0-5.621 5.621c0 1.609.55 3.177 1.588 4.44l3.076 3.747c.244.306.61.469.957.469.346 0 .713-.163.957-.469l3.076-3.747a7.01 7.01 0 0 0 1.588-4.44A5.62 5.62 0 0 0 10 2.505Zm0 3.421c-1.202 0-2.18.978-2.18 2.18 0 1.201.978 2.179 2.18 2.179 1.202 0 2.18-.978 2.18-2.18 0-1.18-.978-2.179-2.18-2.179Z'
      fill='#432EFF'
    />
  </svg>
);

export const IconEmailFilled = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={16}
    height={16}
    fill='none'
    {...props}
  >
    <path
      d='M7.1 7.7 2.588 4.316c.26-.204.581-.315.912-.316h9c.33.001.652.112.912.316L8.9 7.7a1.51 1.51 0 0 1-1.8 0Zm2.4.8a2.513 2.513 0 0 1-3 0L2.043 5.158A1.477 1.477 0 0 0 2 5.5v5A1.5 1.5 0 0 0 3.5 12h9a1.5 1.5 0 0 0 1.5-1.5v-5a1.48 1.48 0 0 0-.043-.342L9.5 8.5Z'
      fill={props.color || '#141938'}
    />
  </svg>
);
