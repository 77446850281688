import React, { useEffect } from 'react';
import { Button, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { LOADED_REPORTING_CERTIFICATES_SERVICE_ITEMS_DATA_PARTICIPANTS } from '../../../../redux/actions/actionsType';
import { reportCertificatesParticipants } from '../../../../services/requestServicesItemsDataParticipants';

const ButtonReportCertificates = ({
  courseKey,
  requestServiceItemDataId,
  className,
  disabled,
}) => {
  const dispatch = useDispatch();
  const isReportingCertificates = useSelector(
    (state) =>
      state.requestServicesItemsDataParticipants.isReportingCertificates,
  );
  const reportingCertificatesFailed = useSelector(
    (state) =>
      state.requestServicesItemsDataParticipants.reportingCertificatesFailed,
  );
  const reportingCertificatesFilePath = useSelector(
    (state) =>
      state.requestServicesItemsDataParticipants.reportingCertificatesFilePath,
  );

  useEffect(() => {
    if (reportingCertificatesFailed) message.error('Ha corrido un error');
  }, [reportingCertificatesFailed]);

  useEffect(() => {
    if (reportingCertificatesFilePath) {
      message.info('Reporte generado correctamente.');
      window.open(`${reportingCertificatesFilePath}`, '_blank');
      dispatch({
        type: LOADED_REPORTING_CERTIFICATES_SERVICE_ITEMS_DATA_PARTICIPANTS,
        payload: null,
      });
    }
  }, [reportingCertificatesFilePath, dispatch]);

  const handlerGenerateReport = () => {
    reportCertificatesParticipants(
      {
        course_key: courseKey,
        requestServiceItemDataId,
      },
      dispatch,
    );
  };

  return (
    <Button
      type='primary'
      disabled={!courseKey || !requestServiceItemDataId || disabled}
      loading={isReportingCertificates}
      onClick={handlerGenerateReport}
      className={className || ''}
      size='small'
    >
      Descargar Certificados
    </Button>
  );
};

export default ButtonReportCertificates;
