import React from 'react';
import { Table, Switch, Input, InputNumber, Form, Tooltip, Button } from 'antd';
import {
  DeleteOutlined,
  RedoOutlined,
  DownloadOutlined,
  EditOutlined,
  SaveOutlined,
} from '@ant-design/icons';

const InputCustom = ({ field, initialValue, required, disabled }) => {
  return (
    <Form.Item
      name={field}
      initialValue={initialValue}
      rules={required ? [{ required: true, message: '*' }] : []}
      style={{ margin: 0 }}
    >
      <Input size='small' style={{ padding: '16px 8px' }} disabled={disabled} />
    </Form.Item>
  );
};

const ListParticipants = ({
  form,
  loading,
  makeCertificates,
  hasTecnicalReport,
  onlyView,
  list,
  delParticipant,
  editParticipants,
  onChangeParticipants,
}) => {
  const { getFieldValue, setFieldsValue } = form;

  const columns = [
    {
      title: 'Nombres',
      dataIndex: 'name',
      key: 'name',
      width: 110,
      render: (name, record) => (
        <InputCustom
          required
          initialValue={name}
          field={`participants[${record.key}].name`}
          disabled={editParticipants ? !editParticipants : onlyView}
        />
      ),
    },
    {
      title: 'Apellidos',
      dataIndex: 'last_name',
      key: 'lastName',
      width: 110,
      render: (lastName, record) => (
        <InputCustom
          required
          initialValue={lastName}
          field={`participants[${record.key}].last_name`}
          disabled={editParticipants ? !editParticipants : onlyView}
        />
      ),
    },
    {
      title: 'Correo',
      dataIndex: 'email',
      key: 'email',
      width: 110,
      render: (email, record) => (
        <InputCustom
          initialValue={email}
          field={`participants[${record.key}].email`}
          disabled={editParticipants ? !editParticipants : onlyView}
        />
      ),
    },
    {
      title: 'Dni',
      dataIndex: 'document',
      key: 'document',
      width: 110,
      render: (document, record) => (
        <InputCustom
          initialValue={document}
          field={`participants[${record.key}].document`}
          disabled={editParticipants ? !editParticipants : onlyView}
        />
      ),
    },
    {
      title: 'Cargo',
      dataIndex: 'position',
      key: 'position',
      width: 110,
      render: (position, record) => (
        <InputCustom
          initialValue={position}
          field={`participants[${record.key}].position`}
          disabled={editParticipants ? !editParticipants : onlyView}
        />
      ),
    },
    {
      title: 'Asistencia',
      dataIndex: 'state',
      key: 'state',
      width: 110,
      render: (state, record) => (
        <>
          <Form.Item
            name={`participants[${record.key}].id`}
            initialValue={record.id}
          >
            <Input type='hidden' />
          </Form.Item>

          <Form.Item
            name={`participants[${record.key}].state`}
            valuePropName='checked'
            initialValue={state !== null && state !== 0 ? state === 1 : true}
          >
            <Switch
              disabled={editParticipants ? !editParticipants : onlyView}
              onChange={(e) =>
                !e &&
                setFieldsValue({ [`participants[${record.key}].score`]: null })
              }
            />
          </Form.Item>
        </>
      ),
    },
    {
      title: 'Nota',
      dataIndex: 'score',
      key: 'score',
      width: 90,
      render: (score, record) => (
        <Form.Item
          name={`participants[${record.key}].score`}
          initialValue={score}
          rules={
            getFieldValue('hasExam') &&
            getFieldValue(`participants[${record.key}].state`)
              ? [{ required: true, message: '*' }]
              : []
          }
          style={{ margin: 0 }}
        >
          <InputNumber
            disabled={
              editParticipants
                ? !editParticipants
                : onlyView ||
                  !getFieldValue(`participants[${record.key}].state`)
            }
            min={0}
            max={20}
          />
        </Form.Item>
      ),
    },
    {
      key: 'operation',
      fixed: 'right',
      width: 128,
      render: (_, record) =>
        record.state !== 2 ? (
          <span className='table-operation'>
            {onlyView && !record.certificate ? (
              <Tooltip
                title={
                  hasTecnicalReport
                    ? 'Generar certificados'
                    : 'Es necesario el informe técnico'
                }
              >
                <Button
                  size='small'
                  icon={<RedoOutlined />}
                  className='btn-cmo-action-list'
                  onClick={makeCertificates}
                  loading={loading}
                  disabled={!hasTecnicalReport}
                />
              </Tooltip>
            ) : null}
            {record.certificate ? (
              <>
                <Tooltip title='Descargar certificado'>
                  <Button
                    size='small'
                    target='_blank'
                    className='btn-cmo-action-list'
                    href={`${process.env.REACT_APP_WEB_PATH_S3_NEW}${record.certificate}`}
                    icon={<DownloadOutlined />}
                  />
                </Tooltip>
                <Tooltip
                  title={
                    !editParticipants
                      ? 'Modificar Participante'
                      : 'Guardar Participantes'
                  }
                >
                  <Button
                    size='small'
                    className='btn-cmo-action-list'
                    onClick={() => onChangeParticipants(true)}
                    icon={
                      !editParticipants ? <EditOutlined /> : <SaveOutlined />
                    }
                  />
                </Tooltip>
              </>
            ) : null}
            {!onlyView && (
              <Button
                size='small'
                icon={<DeleteOutlined />}
                className='btn-cmo-action-list'
                onClick={() => delParticipant(record.id || record.key)}
              />
            )}
          </span>
        ) : (
          <></>
        ),
    },
  ];

  return (
    <Form form={form}>
      <Table
        columns={columns}
        dataSource={list}
        loading={loading}
        rowKey={(row) => row.id || row.key}
        pagination={false}
        className='table-participants'
        scroll={{ x: 490, y: 780 }}
        locale={{
          emptyText: 'No hay participantes',
        }}
      />
    </Form>
  );
};

export default ListParticipants;
