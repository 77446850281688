import { combineReducers } from 'redux';
import auth from './auth';
import users from './users';
import companies from './companies';
import rols from './rols';
import newsletters from './newsletters';
import dashboard from './dashboard';
import dealers from './dealers';
import emergencies from './emergencies';
import events from './events';
import eventType from './eventType';
import exploration from './exploration';
import zones from './zones';
import sismo from './sismo';
import common from './common';
import campusImported from './campusImported';
import changelog from './changelog';
import maps from './maps';
import locations from './locations';
import sideMenu from './sideMenu';
import sentMail from './sentMail';
import simulations from './simulations';
import courses from './courses';
import providers from './providers';
import providerPayments from './providerPayments';
import typeIndustry from './typeIndustry';
import typePoliza from './typePoliza';
import service from './service';
import levelCompany from './levelCompany';
import brokers from './brokers';
import businessSegment from './businessSegment';
import requestServices from './requestServices';
import requestServiceForm from './requestServiceForm';
import requestServicesItems from './requestServicesItems';
import requestServicesItemsDataParticipants from './requestServicesItemsDataParticipants';
import requestServicesItemsResults from './requestServicesItemsResults';
import requestServicesPurchaseOrder from './requestServicesPurchaseOrder';
import articles from './articles';
import poliza from './poliza';
import survey from './survey';
import parameter from './parameter';
import conflict from './conflict';
import roadEmergency from './roadEmergency';
import valueOffer from './valueOffer';
import helpCenter from './helpCenter';
import controlMap from './controlMap';
import providerReport from './providerReport';
import banner from './banner';
import myLayers from './myLayers';
import tracking from './tracking';
import cumulus from './cumulus';
import clientTracking from './clientTracking';
import rainForm from './rainRiskForms';
import myRoutes from './myRoutes';
import geologicalHazards from './geologicalHazards';
import tsunamis from './tsunamis';

export default combineReducers({
  auth,
  users,
  companies,
  rols,
  newsletters,
  dashboard,
  dealers,
  emergencies,
  events,
  eventType,
  exploration,
  zones,
  sismo,
  common,
  campusImported,
  changelog,
  maps,
  locations,
  sideMenu,
  sentMail,
  simulations,
  courses,
  providers,
  providerPayments,
  typeIndustry,
  typePoliza,
  service,
  levelCompany,
  brokers,
  businessSegment,
  requestServices,
  requestServicesItems,
  requestServicesItemsDataParticipants,
  requestServicesItemsResults,
  requestServicesPurchaseOrder,
  articles,
  poliza,
  survey,
  parameter,
  conflict,
  roadEmergency,
  valueOffer,
  helpCenter,
  controlMap,
  requestServiceForm,
  providerReport,
  banner,
  myLayers,
  tracking,
  cumulus,
  clientTracking,
  rainForm,
  myRoutes,
  geologicalHazards,
  tsunamis,
});
