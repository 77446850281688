import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

const Item = ({ title, description, img, width, marginRight }) => {
  return (
    <div
      className='carousel-portal__item'
      style={{ width, display: 'inline-block', marginRight }}
    >
      <div className='carousel-portal__item__img'>
        <img src={img} alt='' />
      </div>
      <div className='carousel-portal__item__title'>{title}</div>
      <div className='carousel-portal__item__description'>{description}</div>
    </div>
  );
};

const Carousel = () => {
  const ITEMS = 7;
  const [width, setWidth] = useState(256);
  const [widthScreen, setWidthScreen] = useState();
  const [marginRight, setMarginRight] = useState(32);
  const [selected, setSelected] = useState(0);
  const [itemsPerLine, setItemsPerLine] = useState(2);
  const [touchPosition, setTouchPosition] = useState(0);
  const [touchStart, setTouchStart] = useState(null);
  const listRef = useRef(null);

  useEffect(() => {
    if (widthScreen && width)
      if (widthScreen > 900) {
        setItemsPerLine(3);
        setMarginRight(32);
      } else if (widthScreen > 600) {
        setItemsPerLine(2);
        setMarginRight(48);
      } else {
        setItemsPerLine(1);
        setMarginRight(0);
      }
  }, [widthScreen, width]);

  useLayoutEffect(() => {
    function updateSize() {
      if (
        !widthScreen ||
        window.innerWidth > widthScreen + 1 ||
        window.innerWidth < widthScreen - 1
      ) {
        setWidthScreen(window.innerWidth);
        setWidth(listRef.current.offsetWidth - 14);
        setSelected(0);
      }
    }
    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, [widthScreen]);

  const backSelect = () => {
    setSelected((selected) => (selected - 1 <= 0 ? 0 : selected - 1));
  };

  const nextSelect = () => {
    setSelected((selected) =>
      selected + 1 >= Math.ceil(ITEMS / itemsPerLine)
        ? Math.ceil(ITEMS / itemsPerLine) - 1
        : selected + 1,
    );
  };

  const handlerTouchStart = (e) => {
    setTouchStart(e.touches[0].clientX);
  };

  const handlerTouchMove = (e) => {
    setTouchPosition(touchStart - e.touches[0].clientX);
  };

  const handlerTouchEnd = (e) => {
    const move = e.changedTouches[0].clientX - touchStart;
    if (Math.abs(move) >= width / 3) move > 0 ? backSelect() : nextSelect();

    setTouchStart(null);
    setTouchPosition(0);
  };

  const itemWidth = Math.ceil(
    (width - (itemsPerLine - 1) * marginRight) / itemsPerLine,
  );

  return (
    <div className='carousel-portal'>
      <div
        ref={listRef}
        className='carousel-portal__list'
        onTouchStart={handlerTouchStart}
        onTouchMove={handlerTouchMove}
        onTouchEnd={handlerTouchEnd}
      >
        <div
          className='carousel-portal__items'
          style={{
            transform: `translateX(-${selected * (itemsPerLine * itemWidth + itemsPerLine * marginRight) + touchPosition}px)`,
            transition: touchPosition ? undefined : 'all 0.2s',
          }}
        >
          <Item
            width={itemWidth}
            marginRight={marginRight}
            img='/images/portal/icon_weather.svg'
            title='Fenómenos meteorológicos'
            description='Lluvias, nevadas, oleaje anómalo, temperatura, viento, nubosidad.'
          />
          <Item
            width={itemWidth}
            marginRight={marginRight}
            img='/images/portal/icon_rain.svg'
            title='Temporada de lluvias'
            description='Monitoreo del Fenómeno El Niño y La Niña así como el pronóstico climático para la temporada de lluvias.'
          />
          <Item
            width={itemWidth}
            marginRight={marginRight}
            img='/images/portal/icon_earthquake.svg'
            title='Ocurrencias de sismos'
            description='Sismos que afectan nuestro país y alertas por tsunamis.'
          />
          <Item
            width={itemWidth}
            marginRight={marginRight}
            img='/images/portal/icon_lightning-light.svg'
            title='Tormentas eléctricas'
            description='Identificación y seguimiento de tormentas eléctricas (descarga de rayos).'
          />
          <Item
            width={itemWidth}
            marginRight={marginRight}
            img='/images/portal/icon_satellite.svg'
            title='Monitoreo geológico'
            description='Reportes ante eventualidad de huaycos, deslizamientos, avalanchas y derrumbes.'
          />
          <Item
            width={itemWidth}
            marginRight={marginRight}
            img='/images/portal/icon_signage.svg'
            title='Condiciones extremas'
            description='Fenómenos meteorológicos por encima de sus parámetros normales.'
          />
          <Item
            width={itemWidth}
            marginRight={marginRight}
            img='/images/portal/icon_emergency.svg'
            title='Emergencias de Bomberos 116'
            description='Identificación de emergencias que puedan repercutir en la operación de nuestros clientes.'
          />
        </div>
      </div>
      <div className='carousel-portal__pag'>
        <div
          className={`carousel-portal__pag__button ${selected === 0 ? 'color-gray-3' : 'color-primary'}`}
          onClick={backSelect}
        >
          <i className='fas fa-chevron-left'></i>
        </div>
        <div className='carousel-portal__pag__number color-primary'>
          0{selected + 1}
        </div>
        <div className='carousel-portal__pag__sep'>/</div>
        <div className='carousel-portal__pag__number color-gray-3'>
          0{Math.ceil(ITEMS / itemsPerLine)}
        </div>
        <div
          className={`carousel-portal__pag__button ${selected + 1 >= Math.ceil(ITEMS / itemsPerLine) ? 'color-gray-3' : 'color-primary'}`}
          onClick={nextSelect}
        >
          <i className='fas fa-chevron-right'></i>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
