import { useEffect, useState } from 'react';
import { Collapse, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import {
  RideButton,
  RideGlLeft,
  RideIconButton,
} from '@rimac-seguros/ride-system-components';
import dayjs from 'dayjs';

import useQueryParams from '../../../../../hooks/useQueryParams';
import { useScreen } from '../../../../../lib/screen';
import { getAllCompanies } from '../../../../../services/company';
import {
  getPolizasByCompanyIds,
  getPolizasByServiceId,
} from '../../../../../services/polizas';

import Topbar from '../../../../../common/ui/Topbar';
import MultipleSelect from './MultipleSelect';
import Title from '../../../../../common/Typograph/Title';
import Divider from '../../../../../common/Divider';
import PanelForm from './PanelForm';

const { Panel } = Collapse;
const { Text } = Typography;

const AssignmentServiceCompany = () => {
  const navigate = useNavigate();
  const { service: serviceId } = useQueryParams();

  useEffect(() => {
    if (!serviceId) {
      navigate('/configuracion?configkey=services');
    }
  }, [serviceId, navigate]);

  const { isMobileScreen } = useScreen();
  const [companies, setCompanies] = useState([]);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [policies, setPolicies] = useState([]);
  const [policiesRegistered, setPoliciesRegistered] = useState([]);

  useEffect(() => {
    const getCompanies = async () => {
      setLoadingCompanies(true);

      const [allCompanies, allPoliciesRegistered] = await Promise.all([
        getAllCompanies(),
        getPolizasByServiceId(serviceId),
      ]);

      setPoliciesRegistered(allPoliciesRegistered);

      const companiesRegistered = allPoliciesRegistered.map(
        (policy) => policy?.poliza?.company,
      );

      setCompanies(
        allCompanies.list
          .map((x) => ({
            value: x.id?.toString(),
            selected:
              companiesRegistered.length > 0 &&
              companiesRegistered.find(
                (c) => c.id?.toString() === x.id?.toString(),
              ) !== undefined,
            label: x.name,
          }))
          .sort((a, b) => a.label.localeCompare(b.label))
          .sort((a, b) =>
            a.selected === b.selected ? 0 : a.selected ? -1 : 1,
          ),
      );

      setLoadingCompanies(false);
    };
    getCompanies();
  }, []);

  const selectedCompaniesIds = companies
    .filter((company) => company.selected)
    .map((company) => company.value);

  const onBack = () => navigate('/configuracion?configkey=services');

  const handleUpdateFilters = (_, value) => {
    console.log(_, value);
    setCompanies((prev) =>
      prev.map((el) => {
        return el?.value === value
          ? {
              ...(el ?? {}),
              selected: !el.selected,
            }
          : el;
      }),
    );
  };

  const handleGetPolicies = (ids) => {
    getPolizasByCompanyIds(ids.join(',')).then((res) => setPolicies(res));
  };

  return (
    <div id='checkSize' className='h100vh dashboard-UI'>
      <Topbar />
      <div className='d_content service-prevention flex flex-col pb-4 gap-4'>
        <div className='flex items-center gap-2'>
          <RideIconButton
            className='mr-1'
            glyph={<RideGlLeft />}
            size='small'
            variant='outline'
            onClick={onBack}
          >
            Volver
          </RideIconButton>
          <Title type={isMobileScreen ? 'bold-32' : 'bold-28'}>
            Asignar servicio a empresas
          </Title>
        </div>
        {isMobileScreen && <Divider style={{ margin: '16px 0' }} />}
        <Text style={{ fontSize: 16 }}>
          Escoge una o más empresas y para mostrar sus pólizas correspondientes.
        </Text>
        <div
          className={`flex gap-4 ${
            isMobileScreen ? 'flex-col' : 'items-center'
          }`}
        >
          <MultipleSelect
            loading={loadingCompanies}
            placeholder={'Escribir el nombre de la empresa'}
            filters={companies}
            updateFilterColumns={handleUpdateFilters}
          />
          <RideButton
            size='large'
            disabled={selectedCompaniesIds.length === 0}
            onClick={() => handleGetPolicies(selectedCompaniesIds)}
            text='Mostrar Polizas'
          />
        </div>
        {policies.length > 0 && (
          <div>
            <Text style={{ fontSize: 16 }}>
              Lista de empresas con sus respectivas pólizas
            </Text>
            <Collapse accordion className='collapse-ServiceCompany'>
              {policies.map((policy) => {
                const policyData = policiesRegistered.find(
                  (policyRegistered) => policyRegistered.polizaId === policy.id,
                );

                return (
                  <Panel
                    key={policy.id}
                    header={`Empresa ${policy.company?.name} - Póliza ${
                      policy.typePoliza?.name
                    } - ${dayjs(policy.end_date).format('YYYY-MM-DD')}`}
                  >
                    <PanelForm
                      serviceId={serviceId}
                      polizaId={policy.id}
                      policy={policyData}
                    />
                  </Panel>
                );
              })}
            </Collapse>
          </div>
        )}
      </div>
    </div>
  );
};

export default AssignmentServiceCompany;
