import { memo, useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Input, notification, Skeleton, Select } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';

import useDebounce from '../../../../../hooks/useDebounce';
import { useScreen } from '../../../../../lib/screen';
import {
  closeTicket,
  getRequestSupport,
  listUserRequest,
} from '../../../../../services/helpCenter';

import TableCommon from '../../../../../common/Table';
import Title from '../../../../../common/Typograph/Title';
import { SelectWithLabel } from '../../../../../common/InputWithLabel';
import { IconFilter } from '../../../../../common/Icons';
import DrawerMoreOptionsMobile from '../../../../../common/DrawerMoreOptionsMobile';
import RightDrawer from '../../../../../common/RightDrawer';
import PaginationMobile from '../../../../../common/PaginationMobile';
import CardUserMobile from '../../common/CardUserMobile';

const { Search } = Input;

const statusColors = {
  1: {
    class: 'active-color',
    status: 'Nuevo',
  },
  2: {
    class: 'disabled-color',
    status: 'Cerrado',
  },
  3: {
    class: 'process-color',
    status: 'En proceso',
  },
};

const columns = [
  {
    title: 'Usuario',
    dataIndex: 'user',
    width: 190,
    render: (user) => (
      <div className='flex flex-col'>
        <strong>
          {user?.name ?? ''} {user?.last_name1 ?? ''}
        </strong>
        <span>{user?.company?.name ?? ''}</span>
      </div>
    ),
  },
  {
    title: 'Nª de cotización',
    dataIndex: 'subject',
    width: 220,
    key: 'subject',
    render: (val, item) => (
      <div className='flex flex-col'>
        <strong>{val}</strong>
        <span>{moment(item.createdAt).format('DD MMM YYYY - HH:mm')}</span>
      </div>
    ),
  },
  {
    title: 'Estado',
    dataIndex: 'status',
    key: 'status',
    fixed: 'top',
    render: (value) => {
      const state = statusColors[value] ?? statusColors[0];

      return (
        <div
          className='flex flex-row flex-justify-justify-between'
          style={{ height: 20 }}
        >
          <span className={`withpoint state-${state ? state.class : ''}`}>
            <div
              className={`withpoint-circle dot-${state ? state.class : ''}`}
            ></div>
            <span>{state?.status}</span>
          </span>
        </div>
      );
    },
  },
  {
    title: 'Acciones',
    dataIndex: 'action',
    hiddenMobile: true,
    width: 80,
    render: (_, record) => (
      <Button
        type='ghost'
        style={{ height: 32 }}
        disabled={record?.status === 2}
        onClick={() =>
          record.closeIncident ? record.closeIncident() : () => {}
        }
      >
        Cerrar incidencia
      </Button>
    ),
  },
  {
    title: 'Detalle',
    dataIndex: 'detail',
    fixedMobile: 'bottom',
    render: (_, record) => (
      <Button
        type='link'
        size='small'
        onClick={() => (record.onDetail ? record.onDetail() : () => {})}
      >
        Ver detalle
      </Button>
    ),
  },
];

const filtersCommon = [
  {
    key: 'user_id',
    label: 'Usuario',
  },
  {
    key: 'status',
    label: 'Estado',
    options: [
      { value: 1, label: 'Nuevo' },
      { value: 2, label: 'Cerrado' },
      { value: 3, label: 'En proceso' },
    ],
  },
];

const Incidents = () => {
  const dispatch = useDispatch();
  const { isMobileScreen } = useScreen();
  const { isLoading, pagination, list } = useSelector((state) => ({
    list: state.helpCenter.list,
    isLoading: state.helpCenter.isLoadingList,
    pagination: state.helpCenter.pagination,
  }));
  const [querySearch, setQuerySearch] = useState('');
  const debouncedQuerySearch = useDebounce(querySearch);
  const [visibleFiltersMobile, setVisibleFiltersMobile] = useState(false);
  const [mobileActions, setMobileActions] = useState(null);
  const [userOptions, setUsersOptions] = useState();
  const [filtersLocal, setFiltersLocal] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    listUserRequest().then((list) => {
      setUsersOptions(list.map((el) => ({ value: el.id, label: el.user })));
    });
  }, []);

  const handleGetIncidents = (data) => {
    getRequestSupport(data, dispatch);
  };

  useEffect(() => {
    handleGetIncidents({
      query: debouncedQuerySearch,
      page: 1,
      limit: pagination.pageSize,
    });
  }, [debouncedQuerySearch]);

  const handleChangeSearch = (e) => setQuerySearch(e.target.value);

  const _handleCloseIncident = useCallback(
    (id) => {
      closeTicket({ id }, dispatch)
        .then((reponse) => {
          if (!reponse) {
            throw Error('Error');
          }

          notification.success({
            description: 'Se cerró la incidencia correctamente.',
            message: '¡Listo!',
          });
          handleGetIncidents({
            page: pagination.current,
            limit: pagination.pageSize,
          });
        })
        .catch(() => {
          notification.error({
            description: 'Error al cerrar la incidencia.',
            message: 'Error!',
          });
        });
    },
    [dispatch],
  );

  const _handleClearFilters = useCallback(() => {
    setFiltersLocal({});
  }, []);

  const _handleSelectOption = useCallback(
    (key, value) => {
      setFiltersLocal((prev) => {
        const newFilters = {
          ...prev,
          [key]: value,
        };
        handleGetIncidents({
          query: querySearch,
          page: 1,
          limit: pagination.pageSize,
          filters: newFilters,
        });

        return newFilters;
      });
    },
    [handleGetIncidents, pagination, querySearch],
  );

  return (
    <div>
      <div
        className={`flex mb-4 ${
          isMobileScreen ? 'flex-col pt-2' : 'items-center'
        }`}
      >
        <Title>Filtrar solicitudes</Title>
      </div>
      <div className='flex justify-between items-center'>
        {pagination?.total !== 1 ? (
          <span className='text-base'>
            Se encontraron <strong>{pagination?.total}</strong> solicitudes
          </span>
        ) : (
          <span className='text-base'>
            Se encontró <strong>{pagination?.total}</strong> solicitud
          </span>
        )}
        {isMobileScreen && list.length ? (
          <Button
            className='flex items-center'
            type='link'
            onClick={() => {
              setVisibleFiltersMobile(true);
            }}
            size='small'
          >
            <IconFilter className='mr-1' />
            Filtrar
          </Button>
        ) : null}
      </div>
      {!isMobileScreen ? (
        <div className='flex mt-4 mb-4'>
          <Search
            placeholder={'Palabras clave'}
            style={{
              width: '100%',
              maxWidth: '300px',
            }}
            onChange={handleChangeSearch}
            allowClear
          />
          {filtersCommon.map(({ key, label, options }) => (
            <Select
              key={key}
              className='mr-2'
              placeholder={label}
              options={(options ?? userOptions ?? []).map((option) => ({
                label: option.label,
                value: option.value,
              }))}
              onChange={(value) => {
                _handleSelectOption(key, value);
              }}
              value={filtersLocal[key]}
            />
          ))}
          <Button type='link' className='mt-1' onClick={_handleClearFilters}>
            <DeleteOutlined />
            Borrar filtros
          </Button>
        </div>
      ) : null}
      {isMobileScreen ? (
        isLoading ? (
          [1, 2, 3].map((i) => (
            <Card className='mt-2' key={i}>
              <Skeleton />
            </Card>
          ))
        ) : (
          <>
            {list.map((incident) => (
              <CardUserMobile
                key={incident.id}
                columns={columns}
                onAction={() => {
                  setMobileActions({ incidentId: incident.id });
                }}
                item={{
                  ...incident,
                  closeIncident: () => {
                    _handleCloseIncident(incident.id);
                  },
                  onDetail: () => {
                    navigate(
                      `/administracion/usuarios?incidentId=${incident.id}`,
                    );
                  },
                }}
              />
            ))}
            {list.length ? (
              <PaginationMobile
                {...pagination}
                onChange={(page) => {
                  handleGetIncidents({
                    limit: 5,
                    page,
                  });
                }}
              />
            ) : null}
          </>
        )
      ) : null}
      {!isMobileScreen ? (
        <TableCommon
          className={'mt-3'}
          loading={isLoading}
          columns={columns}
          dataSource={list.map((elem) => ({
            ...elem,
            closeIncident: () => {
              _handleCloseIncident(elem.id);
            },
            onDetail: () => {
              navigate(`/administracion/usuarios?incidentId=${elem.id}`);
            },
          }))}
          pagination={{
            ...pagination,
            onChange: (page) => {
              handleGetIncidents({
                limit: 5,
                page,
              });
            },
          }}
        />
      ) : null}
      {isMobileScreen ? (
        <>
          <DrawerMoreOptionsMobile
            visible={mobileActions}
            options={[
              {
                key: '',
                title: 'Cerrar incidencia',
                action: () => {
                  _handleCloseIncident(mobileActions?.incidentId);
                },
              },
            ]}
            onClose={() => {
              setMobileActions(null);
            }}
          ></DrawerMoreOptionsMobile>
          <RightDrawer
            visible={visibleFiltersMobile}
            onCloseDrawer={() => {
              setVisibleFiltersMobile(false);
            }}
            title={'Filtros'}
            content={
              <>
                <Search
                  placeholder={'Palabras clave'}
                  style={{
                    width: '100%',
                    maxWidth: '300px',
                  }}
                  onChange={handleChangeSearch}
                  allowClear
                />
                {filtersCommon.map(({ key, label, options }) => (
                  <SelectWithLabel
                    key={key}
                    className='mt-2'
                    label={label}
                    options={options ?? userOptions ?? []}
                    onChange={(selected) => {
                      _handleSelectOption(key, selected);
                    }}
                    value={filtersLocal[key]}
                  />
                ))}
                <div
                  className='absolute flex justify-between'
                  style={{ bottom: 20, left: 32, width: 'calc(100% - 60px)' }}
                >
                  <Button
                    type='link'
                    size='large'
                    onClick={_handleClearFilters}
                    icon={<DeleteOutlined />}
                  >
                    Limpiar filtros
                  </Button>
                  <Button
                    className='pl-3 pr-3'
                    type='primary'
                    onClick={() => {
                      setVisibleFiltersMobile(false);
                    }}
                    size='large'
                  >
                    Filtrar
                  </Button>
                </div>
              </>
            }
          />
        </>
      ) : null}
    </div>
  );
};

export default memo(Incidents);
