import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className='login-header'>
      <Link to='/' className='logo'>
        <img src='/images/logo.svg' alt='Logo Rimac' />
        <span className='ride-sys-text-title-small-default'>
          Centro de Monitoreo y Prevención
        </span>
      </Link>
    </header>
  );
};

export default Header;
