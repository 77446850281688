const materials = [
  {
    label: 'Sticker o Autoadhesivas',
    key: 'stickerAutoadhesivas',
    allowed: ['CI', 'OB', 'EE', 'PH', 'AD'],
    tooltip:
      'Textiles, Construcción, Editoriales, Siderúrgicas, Minería, Ganadería, Agro, Plásticos, Papel, Químicos, Metal Mecánica, Minería.',
    size: 16,
  },
  {
    label: 'Vinil Impreso con Base',
    key: 'vinilImpresoBase',
    allowed: ['CI', 'OB', 'EE', 'PH', 'COVID', 'AD'],
    tooltip:
      'Empresas de Transporte y Carga, Locales Comerciales, Pesqueras, Energía.',
    size: 16,
  },
  {
    label: 'Fotoluminiscente Ploteado, con Base',
    key: 'fotoPloteadoBase',
    allowed: ['CI', 'EE'],
    tooltip:
      'Fotoluminiscencia es la propiedad que poseen determinados elementos para emitir radiación luminosa (fotones) después de haber sido sometidos a estimulación externa.',
    size: 20,
  },
  {
    label: 'Vinil Impreso de Tránsito',
    key: 'vinilImpresoTransito',
    allowed: ['COVID'],
    tooltip: 'Sticker autoadhesivo laminado para tránsito',
    size: 16,
  },
];

const tabNames = [
  { title: 'Incendio', color: '#b94a48', code: 'CI' },
  { title: 'Obligación', color: '#3a87ad', code: 'OB' },
  { title: 'Advertencia', color: '#ff9144', code: 'AD' },
  { title: 'Evacuación y Emergencia', color: '#468847', code: 'EE' },
  { title: 'Prohibición', color: '#d71919', code: 'PH' },
  { title: 'COVID-19', color: '#11d41e', code: 'COVID' },
];

export default { materials, tabNames };
