import { useState, useEffect } from 'react';
import 'leaflet/dist/leaflet.css';
import 'leaflet-timedimension/dist/leaflet.timedimension.control.min.css';
import { MapContainer, TileLayer } from 'react-leaflet';
import SelectControls from './components/SelectControls';
import { activeLayer, timeDimensionOptions } from './utils/layers';
import useMapState from './utils/useMapState';
import TimeDimensionLayer from './components/TimeDimensionLayer';
import StaticLayer from './components/StaticLayer';
import LimitsLayer from './components/LimitsLayer';
import FlyToButton from './components/FlyToButton';
import ZoomControls from './components/ZoomControls';
import SubLayer from './components/SubLayer';
import ScenariosOpacity from './components/ScenariosOpacity';
import { RimacLogo } from './utils';

const NewMap = () => {
  const { selectLayer, setSelectLayer, changeTileLayer, currentTileLayer } =
    useMapState();

  const [showLayerPoints, setShowLayerPoints] = useState(true);
  const [timeDimension, setTimeDimension] = useState(true);
  const [timeInterval, setTimeInterval] = useState('');
  const [hasSubLayer, setHasSubLayer] = useState(false);
  const [subLayers, setSubLayers] = useState([]);
  const [checkedState, setCheckedState] = useState(null);

  const [hasTimeControl, setHasTimeControl] = useState(false);
  const [period, setPeriod] = useState('PT1H');
  const [color, setColor] = useState('#000000');

  const handleSubLayerChange = (newCheckedState) => {
    setCheckedState(newCheckedState);
  };

  useEffect(() => {
    if (selectLayer) {
      const selectedLayer = activeLayer.find(
        (layer) => layer.id === selectLayer,
      );
      if (selectedLayer) {
        const isDynamic = selectedLayer.type === 'dynamic';
        const hasSub = selectedLayer.hasSubLayers;
        const hasTime = selectedLayer.timeControl;
        const subLay = selectedLayer.subLayers;

        if (hasSub) {
          setSubLayers(subLay);
        } else {
          setCheckedState(null);
        }

        setTimeDimension(isDynamic);
        setTimeInterval(selectedLayer.timeInterval);
        setPeriod(selectedLayer.period);
        setHasSubLayer(hasSub);
        setHasTimeControl(hasTime);
      }
    }

    if (checkedState && checkedState.id) {
      setTimeDimension(checkedState.type === 'dynamic');
      setTimeInterval(checkedState.timeInterval);
      setPeriod(checkedState.period);
      setHasTimeControl(checkedState.timeControl);
    }
  }, [selectLayer, activeLayer, checkedState]);

  return (
    <div style={{ position: 'relative' }}>
      <SelectControls
        selectLayer={selectLayer}
        setSelectLayer={setSelectLayer}
        selectTileLayer={currentTileLayer}
        setSelectTileLayer={changeTileLayer}
        showLimitesWebLayer={showLayerPoints}
        setShowLimitesWebLayer={setShowLayerPoints}
        color={color}
        setColor={setColor}
      />
      <RimacLogo />
      {hasSubLayer && (
        <SubLayer
          subLayers={subLayers}
          onSubLayerChange={handleSubLayerChange}
        />
      )}
      <MapContainer
        key={period}
        center={[-8.9382, -74.8882]}
        zoom={6}
        minZoom={4}
        maxZoom={18}
        style={{ height: '100vh', width: '100%' }}
        timeDimension={timeDimension}
        timeDimensionOptions={{
          timeInterval,
          period,
        }}
        timeDimensionControl={hasTimeControl}
        timeDimensionControlOptions={timeDimensionOptions}
        zoomControl={false}
      >
        <TileLayer url={currentTileLayer.url} />
        {timeDimension ? (
          <TimeDimensionLayer
            selectLayer={checkedState ? checkedState.id : selectLayer}
            period={period}
            timeInterval={timeInterval}
          />
        ) : (
          <StaticLayer
            selectLayer={checkedState ? checkedState.id : selectLayer}
          />
        )}
        {showLayerPoints && <LimitsLayer color={color} />}
        {selectLayer === 'scenarios' && <ScenariosOpacity />}
        <ZoomControls />
        <FlyToButton />
      </MapContainer>
    </div>
  );
};

export default NewMap;
