import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TileLayer, useMap } from 'react-leaflet';
import axios from 'axios';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import 'leaflet-markers-canvas';

import { getIcon } from './utils';
import { extractShapes } from '../../../../services/shapeUtils';
import { capitalize, removeAccents } from '../../../../lib/utils';
import {
  GEOLOGICAL_HAZARDS_SET_POINTS,
  GEOLOGICAL_HAZARDS_UPDATE_FILTERS,
  MAP_CHANGE_BLOCKED_CLICK,
} from '../../../../redux/actions/actionsType';

const LayerGeologicalHazard = ({ activeSubLayer, opacity = 1 }) => {
  const dispatch = useDispatch();
  const [geojson, setGeojson] = useState(null);
  const {
    activeLayer,
    activeSubLayerFromRedux,
    filters,
    geologicalHazardsTypes,
  } = useSelector((state) => ({
    activeLayer: state.maps.activeLayer,
    activeSubLayerFromRedux: state.maps.activeSubLayer,
    geologicalHazardsTypes: state.geologicalHazards.types,
    filters: state.geologicalHazards.filters,
  }));
  const currentSubLayer = activeSubLayer ?? activeSubLayerFromRedux;
  const map = useMap();
  const markersCanvas = useRef();

  const geojsonFiltered = useMemo(() => {
    // Remove filters with empty array or empty string
    const keyValueFilters = Object.entries(filters).filter(([, value]) => {
      if (Array.isArray(value) && value.length === 0) {
        return false;
      }
      if (typeof value === 'string' && value.length === 0) {
        return false;
      }

      return true;
    });

    if (keyValueFilters.length === 0 || !geojson) {
      return geojson;
    }

    return geojson.filter(({ properties }) => {
      let conditions = keyValueFilters.map(([key, value]) => {
        if (Array.isArray(value) && value.includes(properties[key])) {
          return true;
        }
        if (
          typeof value === 'string' &&
          removeAccents(value).toLowerCase().trim() ===
            removeAccents(properties[key]).toLowerCase()
        ) {
          return true;
        }

        return false;
      });

      return conditions.every(Boolean);
    });
  }, [geojson, filters]);

  useEffect(() => {
    (async () => {
      if (currentSubLayer) {
        const res = await axios.get(currentSubLayer?.url, {
          responseType: 'arraybuffer',
          headers: {
            'Cache-Control': 'no-cache',
          },
        });
        if (res.status === 200) {
          let file = new File([res.data], 'data.zip', {
            type: 'text/plain',
          });

          const geojsonExtracted = await extractShapes([file]);

          setGeojson(geojsonExtracted);
        }
      }
    })();

    dispatch({ type: MAP_CHANGE_BLOCKED_CLICK, payload: true });

    return () => {
      dispatch({ type: MAP_CHANGE_BLOCKED_CLICK, payload: false });
      dispatch({ type: GEOLOGICAL_HAZARDS_UPDATE_FILTERS, payload: {} });
    };
  }, [currentSubLayer?.id]);

  useEffect(() => {
    if (!(map && geojsonFiltered)) {
      return;
    }

    dispatch({ type: GEOLOGICAL_HAZARDS_SET_POINTS, payload: geojsonFiltered });

    let markers = geojsonFiltered.map((feature) => {
      const { coordinates } = feature.geometry;
      const { TIPO_PELIG, GRADO_PELI, GRADO_VULN, NM_DEPA, NM_PROV, NM_DIST } =
        feature.properties;

      const type = geologicalHazardsTypes.find(
        (geologyType) =>
          geologyType.name.toLowerCase() === TIPO_PELIG.toLowerCase(),
      )?.type;

      let marker = L.marker([coordinates[1], coordinates[0]], {
        icon: getIcon(type),
      });

      return marker.bindPopup(`
        <div class='popup-map'>
          <div class='popup-title'>
            <div class='layer-name'>${activeLayer.name}</div>
          </div>
          <div class='popup-content popup-map-scrollable'>
            <div class='popup-detail'>
              <div class='popup-detail-header'>Tipo</div>
              <div class='popup-detail-content'>${TIPO_PELIG ?? ''}</div>
            </div>
            <div class='popup-detail'>
              <div class='popup-detail-header'>Grado de peligro</div>
              <div class='popup-detail-content'>${GRADO_PELI ?? ''}</div>
            </div>
            <div class='popup-detail'>
              <div class='popup-detail-header'>Grado de vulnerabilidad</div>
              <div class='popup-detail-content'>${GRADO_VULN ?? ''}</div>
            </div>
            <div class='popup-detail'>
              <div class='popup-detail-header'>Departamento</div>
              <div class='popup-detail-content'>${
                NM_DEPA ? capitalize(NM_DEPA) : ''
              }</div>
            </div>
            <div class='popup-detail'>
              <div class='popup-detail-header'>Provincia</div>
              <div class='popup-detail-content'>${
                NM_PROV ? capitalize(NM_PROV) : ''
              }</div>
            </div>
            <div class='popup-detail'>
              <div class='popup-detail-header'>Distrito</div>
              <div class='popup-detail-content'>${
                NM_DIST ? capitalize(NM_DIST) : ''
              }</div>
            </div>
          </div>
        </div>
      `);
    });

    if (markersCanvas.current) {
      markersCanvas.current.clear();
      markersCanvas.current.addMarkers(markers);
    } else {
      markersCanvas.current = new L.MarkersCanvas();
      markersCanvas.current.onAdd(map);
      markersCanvas.current.addMarkers(markers);
      markersCanvas.current.redraw();

      const currentCenter = map.getCenter();
      map.setView(currentCenter);
    }
  }, [map, geojsonFiltered]);

  useEffect(() => {
    return () => {
      try {
        if (map && map._mapPane && markersCanvas.current) {
          markersCanvas.current.clear();
        }
      } catch (error) {
        console.error('Failed to clear markers canvas:', error);
      }
    };
  }, []);

  return (
    <TileLayer
      attribution='Google Maps'
      url='https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}'
      opacity={opacity}
    />
  );
};

export default LayerGeologicalHazard;
