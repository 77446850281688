import React from 'react';

const CATEGORIES = {
  1: 'Monitoreo',
  2: 'Prevención',
  3: 'Riesgos',
};

const Header = ({ title, subtitle, category }) => {
  return (
    <header className='section'>
      <div className='head'>
        <div className='head__tag'>{CATEGORIES[category]}</div>
        <h1 className='head__title'>{title}</h1>
        <h2 className='head__description'>{subtitle}</h2>
      </div>
    </header>
  );
};

export default Header;
