import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { TileLayer } from 'react-leaflet';
import PropTypes from 'prop-types';

import {
  getTsunamis,
  getTsunamisByFilters,
} from '../../../../services/tsunami';
import MarkerComponent from './MarkerComponent';

const LayerTsunami = ({ opacity }) => {
  const filters = useSelector((state) => state.tsunamis.filters);
  const [points, setPoints] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const hasFilters =
          filters &&
          (filters?.magnitude ||
            filters?.depth ||
            filters?.date?.dateStart ||
            filters?.date?.dateEnd);
        const getTsunamisFunction = hasFilters
          ? getTsunamisByFilters
          : getTsunamis;

        const response = await getTsunamisFunction(filters);

        const igpPoints = response.igp
          ? response.igp.map((point) => ({
              ...point,
              source: 'IGP',
              time: point.createdAt,
            }))
          : [];

        const usgsPoints = response.usgs
          ? response.usgs.map((point) => ({
              ...point,
              source: 'USGS',
            }))
          : [];

        let allPoints = [...igpPoints, ...usgsPoints];

        allPoints.sort((a, b) => {
          const timeA = a.time || 0;
          const timeB = b.time || 0;
          return new Date(timeA) - new Date(timeB);
        });

        setPoints(allPoints);
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };

    fetchData();
  }, [filters]);

  return (
    <>
      <TileLayer
        attribution='Google Maps'
        url='https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}'
        opacity={opacity}
      />
      {points.length > 0 &&
        points.map((point, index) => (
          <MarkerComponent
            point={point}
            isLastEarthquake={index === points.length - 1}
          />
        ))}
    </>
  );
};

LayerTsunami.propTypes = {
  opacity: PropTypes.number.isRequired,
  filters: PropTypes.shape({
    magnitude: PropTypes.arrayOf(PropTypes.number),
    depth: PropTypes.arrayOf(PropTypes.number),
    date: PropTypes.shape({
      dateStart: PropTypes.string,
      dateEnd: PropTypes.string,
    }),
  }).isRequired,
};

export default LayerTsunami;
