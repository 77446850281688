import React from 'react';

const ArticleCardHome = ({ title, description, img, href, width, margin }) => {
  return (
    <div className='article-card' style={{ width, marginRight: margin }}>
      <div className='article-card__img-container'>
        <img className='article-card__image' src={img} alt='Artículo' />
      </div>
      <div className='article-card__text-container'>
        <div className='article-card__title'>{title}</div>
        <div className='article-card__description'>{description}</div>
        <a href={href} className='article-card__link seeMore'>
          Leer artículo
        </a>
      </div>
    </div>
  );
};

export default ArticleCardHome;
