import React from 'react';
import { PieSkeleton } from '../../utils/Loadings';
import Newsletter from './Newsletter';

const Information = ({ dataMonitoring, isLoading }) => {
  return (
    <>
      <h3>Mi información</h3>
      {dataMonitoring ? (
        <Newsletter data={dataMonitoring?.newsletter} isLoading={isLoading} />
      ) : (
        <PieSkeleton />
      )}
    </>
  );
};

export default Information;
