import {
  LOADED_VALUE_OFFER,
  LOAD_VALUE_OFFER_LOADING,
  LOADING_CREATE_VALUE_OFFER,
  OPEN_FORM_VALUE_OFFER,
  REMOVING_VALUE_OFFER,
} from '../actions/actionsType';

const initialState = {
  list: [],
  openedForm: false,
  isLoadingList: false,
  isLoadingCreate: false,
};

const valueOffer = (state, action) => {
  if (!state) state = initialState;

  switch (action.type) {
    case LOAD_VALUE_OFFER_LOADING:
      return {
        ...state,
        isLoadingList: action.payload,
      };
    case LOADED_VALUE_OFFER:
      return {
        ...state,
        list: action.payload,
        isLoadingList: false,
      };
    case OPEN_FORM_VALUE_OFFER:
      return {
        ...state,
        openedForm: action.payload,
      };
    case LOADING_CREATE_VALUE_OFFER:
      return {
        ...state,
        isLoadingCreate: action.payload,
      };
    case REMOVING_VALUE_OFFER:
      return {
        ...state,
        isLoadingList: false,
        list: state.list.filter((data) => data.id !== action.payload),
      };
    default:
      return state;
  }
};

export default valueOffer;
