import React from 'react';
import { WarningFilled } from '@ant-design/icons';

const ErrorMessage = ({ typeError }) => {
  return (
    <div className='ant-upload-error'>
      {(() => {
        switch (typeError) {
          case 'size':
            return (
              <>
                <WarningFilled />
                El archivo no se cargó porque excede la capacidad máxima (10
                MB).
              </>
            );
          case 'extension':
            return (
              <>
                <WarningFilled />
                El archivo no se cargó porque no es un formato válido.
              </>
            );
          case 'nameLength':
            return (
              <>
                <WarningFilled />
                El nombre del archivo es demasiado largo.
              </>
            );
          default:
            return (
              <>
                <WarningFilled />
                No se ha agregado archivo.
              </>
            );
        }
      })()}
    </div>
  );
};

export default ErrorMessage;
