import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import ArticleCardHome from './ArticleCardHome';

const CarouselHome = ({ articles }) => {
  const ITEMS = 3;
  const [width, setWidth] = useState(256);
  const [widthScreen, setWidthScreen] = useState();
  const [margin, setMargin] = useState(20);
  const [selected, setSelected] = useState(0);
  const [itemsPerLine, setItemsPerLine] = useState(2);
  const [touchPosition, setTouchPosition] = useState(0);
  const [touchStart, setTouchStart] = useState(null);
  const listRef = useRef(null);

  useEffect(() => {
    if (widthScreen && width)
      if (widthScreen > 900) {
        setItemsPerLine(3);
        setMargin(20);
      } else if (widthScreen > 600) {
        setItemsPerLine(2);
        setMargin(24);
      } else {
        setItemsPerLine(1);
        setMargin(16);
      }
  }, [widthScreen, width]);

  useLayoutEffect(() => {
    function updateSize() {
      if (
        !widthScreen ||
        window.innerWidth > widthScreen + 1 ||
        window.innerWidth < widthScreen - 1
      ) {
        setWidthScreen(window.innerWidth);
        setWidth(listRef.current.offsetWidth - 14);
        setSelected(0);
      }
    }
    window.addEventListener('resize', updateSize);
    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, [widthScreen]);

  const backSelect = () => {
    setSelected((selected) => (selected - 1 <= 0 ? 0 : selected - 1));
  };

  const nextSelect = () => {
    setSelected((selected) =>
      selected + 1 >= Math.ceil(ITEMS / itemsPerLine)
        ? Math.ceil(ITEMS / itemsPerLine) - 1
        : selected + 1,
    );
  };

  const handlerTouchStart = (e) => {
    setTouchStart(e.touches[0].clientX);
  };

  const handlerTouchMove = (e) => {
    setTouchPosition(touchStart - e.touches[0].clientX);
  };

  const handlerTouchEnd = (e) => {
    const move = e.changedTouches[0].clientX - touchStart;
    if (Math.abs(move) >= width / 3) move > 0 ? backSelect() : nextSelect();

    setTouchStart(null);
    setTouchPosition(0);
  };

  const itemWidth = Math.ceil(
    (width - (itemsPerLine - 1) * margin) / itemsPerLine,
  );

  return (
    <div className='carousel-portal'>
      <div
        ref={listRef}
        className='carousel-portal__list'
        onTouchStart={handlerTouchStart}
        onTouchMove={handlerTouchMove}
        onTouchEnd={handlerTouchEnd}
      >
        <div
          className='carousel-portal__items'
          style={{
            transform: `translateX(-${selected * (itemsPerLine * itemWidth + itemsPerLine * margin) + touchPosition}px)`,
            transition: touchPosition ? undefined : 'all 0.2s',
          }}
        >
          {articles.map((article, idx) => (
            <ArticleCardHome
              key={`${article.title} ${idx}`}
              href={`/articulos/${article.url}`}
              title={article.title}
              description={article.subtitle}
              img={`${process.env.REACT_APP_WEB_PATH_S3_NEW}${article.image}`}
              width={itemWidth}
              margin={margin}
            />
          ))}
        </div>
      </div>
      <div className='carousel-portal__pag'>
        <div
          className={`carousel-portal__pag__button ${selected === 0 ? 'color-gray-3' : 'color-primary'}`}
          style={selected === 0 ? { borderColor: '#A3ABCC' } : {}}
          onClick={backSelect}
        >
          <i className='fas fa-chevron-left'></i>
        </div>
        <div className='carousel-portal__pag__number color-primary'>
          0{selected + 1}
        </div>
        <div className='carousel-portal__pag__sep'>/</div>
        <div className='carousel-portal__pag__number color-gray-3'>
          0{Math.ceil(ITEMS / itemsPerLine)}
        </div>
        <div
          className={`carousel-portal__pag__button ${selected + 1 >= Math.ceil(ITEMS / itemsPerLine) ? 'color-gray-3' : 'color-primary'}`}
          style={
            selected + 1 >= Math.ceil(ITEMS / itemsPerLine)
              ? { borderColor: '#A3ABCC' }
              : {}
          }
          onClick={nextSelect}
        >
          <i className='fas fa-chevron-right'></i>
        </div>
      </div>
    </div>
  );
};

export default CarouselHome;
