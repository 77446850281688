import { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Button, notification, Skeleton, Select } from 'antd';

import useDebounce from '../../../../hooks/useDebounce';
import { getAllRols } from '../../../../services/rols';
import {
  createChangelog,
  getAllChangelog,
  getChangelog,
  removeChangelog,
  updateChangelog,
} from '../../../../services/changelog';

import PopoverActions from '../../../../common/Table/PopoverActions';
import { SelectWithLabel } from '../../../../common/InputWithLabel';
import Table from '../../../../common/Table';
import ModalConfirm from '../../../../common/ModalConfirm';

import {
  CardSkeleton,
  IconEditTable,
  IconEye,
  iconLeft,
  iconRight,
  IconTrash,
} from '../../ui/PreventionService/Reports/utils';
import FilterSection from '../../ui/PreventionService/components/FilterSection';

import MainTitle from '../common/MainTitle';
import CardItemMobile from '../common/CardItemMobile';
import ModalVersion from './ModalVersion';
import DeleteModalImage from '../../../../images/delete_modal_image.svg';
import Empty from '../../../../common/Empty';
import {
  RideButton,
  RideGlViewSolid,
} from '@rimac-seguros/ride-system-components';

const info = {
  1: {
    text: 'Nuevo',
    color: '#A7EB92',
    point: '#389E0D',
  },
  2: {
    text: 'Mejorado',
    color: '#C8C8FA',
    point: '#4F4FFF',
  },
};

const Versions = ({
  load,
  create,
  update,
  list,
  isLoading,
  renderItem,
  pagination,
  onOpenForm,
  isMobileScreen,
  onModalSuccess,
  isBeforeTable,
  queryAction,
  onSetAction,
}) => {
  const dispatch = useDispatch();
  const [rols, setRols] = useState([]);
  const [rows, setRows] = useState([]);
  const [textSearch, setTextSearch] = useState('');
  const debouncedTextSearch = useDebounce(textSearch);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [currentRol, setCurrentRol] = useState(null);

  const [modalVersion, setModalVersion] = useState(false);
  const [loadingVersion, setLoadingVersion] = useState(true);
  const [versionContent, setVersionContent] = useState(null);

  const handleViewVersion = async () => {
    setLoadingVersion(true);
    if (!currentRol) {
      return notification.warning({ message: 'Debe seleccionar un rol' });
    }

    setModalVersion(true);
    const response = await getAllChangelog(0, currentRol);
    setVersionContent(response);

    setLoadingVersion(false);
  };

  const handleFilter = (e) => setTextSearch(e.target.value);

  const handleTableChange = (pag) =>
    load({
      page: pag.current,
      limit: pag.pageSize,
      query: textSearch,
    });

  const handleTableChangeMobile = (current) => {
    if (
      current === 0 ||
      Math.ceil(pagination.total / pagination.pageSize) + 1 === current
    ) {
      return;
    }

    load({
      page: current,
      limit: pagination.pageSize,
      query: textSearch,
    });
  };

  const _handleDelete = (item) => () => setItemToDelete(item);

  const _handleAction = useCallback(
    (action, item) => async () => {
      const result = rols.length > 0 ? rols : await getAllRols(dispatch);
      onOpenForm({
        action,
        dinamycOptions: result.length > 0 ? result : [],
        ...item,
        rols: item?.rols ? item?.rols.map((rol) => rol.id) : undefined,
        keyConfig: 'versions',
        hasFullImage: true,
        titleSection: action === 'create' ? 'Crear versión' : 'Editar versión',
        onAction: async (data, onFinish = () => {}) => {
          action === 'create'
            ? create(data, { page: 1, limit: 5 }, isBeforeTable, onModalSuccess)
            : update(
                data,
                {
                  page: pagination.current,
                  limit: pagination.pageSize,
                  query: textSearch,
                },
                isBeforeTable,
                onModalSuccess,
              );
          onFinish();
          onOpenForm(null);
          action === 'create' && onSetAction(false);
        },
      });
    },
    [onOpenForm, create, update],
  );

  const columns = [
    {
      title: 'Título',
      dataIndex: 'title',
      key: 'title',
      width: '250px',
      render: (text) => (
        <span className='text-uppercase'>
          {text?.length > 50 ? `${(text || '').substring(0, 50)}...` : text}
        </span>
      ),
    },
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
      width: '150px',
      render: (type) => {
        return (
          <div className='flex flex-row flex-justify-justify-between'>
            <span
              style={{ background: info[type]?.color || '' }}
              className={'withpoint'}
            >
              <div
                style={{ background: info[type]?.point || '' }}
                className={'withpoint-circle'}
              ></div>
              <span>{info[type]?.text || ''}</span>
            </span>
          </div>
        );
      },
    },
    {
      title: 'Versión',
      dataIndex: 'version',
      key: 'version',
      render: (ver) =>
        ver ? (String(ver).indexOf('.') !== -1 ? ver : `${ver}.0`) : '',
    },
    {
      dataIndex: 'actions',
      title: 'Acciones',
      fixed: 'right',
      key: 'actions',
      width: 80,
      render: (__, item) =>
        rows.length > 0 && (
          <PopoverActions
            actions={
              <>
                <Button onClick={_handleAction('edit', item)}>
                  <IconEditTable /> <span>Editar</span>
                </Button>

                <Button onClick={_handleDelete(item)}>
                  <IconTrash /> <span>Eliminar</span>
                </Button>
              </>
            }
          />
        ),
    },
  ];

  const handleRemove = async () => {
    setLoadingDelete(true);

    if (!itemToDelete) {
      return notification.warning({
        description: 'No ha seleccionado ninguna versión para eliminar.',
        message: 'Eliminar versión',
      });
    }

    const deleted = await removeChangelog(dispatch, itemToDelete.id, {
      page: pagination.current,
      limit: pagination.pageSize,
      query: textSearch,
    });

    if (isBeforeTable && deleted) {
      setItemToDelete(null);
      setLoadingDelete(false);

      return onModalSuccess({ message: '¡Listo! Se eliminó correctamente' });
    }

    if (deleted) {
      notification.success({
        description: 'Se eliminó la versión correctamente.',
        message: 'Versión eliminada! ',
      });
    } else {
      notification.warning({
        description: 'Ha ocurrido un error.',
        message: 'Eliminar versión',
      });
    }

    setItemToDelete(null);
    setLoadingDelete(false);
  };

  useEffect(() => {
    if (debouncedTextSearch.length >= 2) {
      load({
        query: debouncedTextSearch,
        page: 1,
        limit: pagination.pageSize,
      });
    } else {
      load({
        query: '',
        page: 1,
        limit: pagination.pageSize,
      });
    }
  }, [debouncedTextSearch]);

  useEffect(() => {
    getAllRols(dispatch).then((result) => setRols(result));
  }, []);

  useEffect(() => {
    setRows(list);
  }, [list]);

  useEffect(() => {
    if (queryAction) {
      _handleAction('create', renderItem)();
    }
  }, [queryAction]);

  return (
    <>
      <MainTitle
        buttonAction={_handleAction('create', renderItem)}
        renderItem={renderItem}
        isMobileScreen={isMobileScreen}
      />

      <div className='version-list-roles'>
        <p>Selecciona un rol para previsualizar la versión</p>

        <div className='flex gap-2 items-center'>
          <Select
            placeholder='Roles'
            value={currentRol}
            onChange={setCurrentRol}
            className='w-1/2'
          >
            {rols.map((rol) => (
              <Select.Option key={rol.slug} value={rol.slug}>
                {rol.name}
              </Select.Option>
            ))}
          </Select>
          <RideButton
            variant='text-primary'
            size='small'
            onClick={handleViewVersion}
            text='Ver versión'
            glyph={<RideGlViewSolid />}
            glyphPosition='left'
          />
        </div>
      </div>

      <FilterSection
        complete={true}
        text={`${isMobileScreen ? 'Filtra tu búsqueda' : 'Filtrar lista'}`}
        handleFilter={handleFilter}
      />

      {!isMobileScreen ? (
        !isLoading && list?.length === 0 ? (
          <Empty title='No se encontraron versiones' />
        ) : (
          <section className='show-data'>
            {isLoading ? (
              <Skeleton active title={false} paragraph={{ rows: 1 }} />
            ) : (
              <p className='info-total-data'>
                Se encontraron <span>{pagination.total}</span>{' '}
                {pagination.total === 1 ? 'versión' : 'versiones'}
              </p>
            )}

            <Table
              dataSource={rows}
              columns={columns}
              rowKey={(record) => record.id}
              loading={isLoading}
              locale={{ emptyText: 'No se encontraron versiones.' }}
              pagination={pagination}
              onChangeRows={setRows}
              onChange={handleTableChange}
            />
          </section>
        )
      ) : (
        <section className='content-mobile action-bottom'>
          {isLoading ? (
            <Skeleton active title={false} paragraph={{ rows: 1 }} />
          ) : (
            <p className='info-total-data'>
              Se encontraron <span>{pagination.total}</span>{' '}
              {pagination.total === 1 ? 'versión' : 'versiones'}
            </p>
          )}

          {isLoading ? (
            [1, 2, 3, 4].map((el) => <CardSkeleton key={el} />)
          ) : rows?.length === 0 ? (
            <Empty title='No se encontraron versiones' />
          ) : (
            rows?.map((data) => (
              <CardItemMobile
                key={data.id}
                data={data}
                columns={columns.filter((el) => el.dataIndex !== 'actions')}
                onEdit={_handleAction('edit', data)}
                onDelete={setItemToDelete}
              />
            ))
          )}

          <div className='d_content__list__pag'>
            <div
              className={'d_content__list__pag__button'}
              onClick={() => handleTableChangeMobile(pagination.current - 1)}
            >
              {iconLeft({
                color: pagination.current === 1 ? '#A9AFD9' : '#4F4FFF',
              })}
            </div>

            <div className='d_content__list__pag__number color-red-1'>
              {pagination.current}
            </div>
            <div className='d_content__list__pag__sep'>/</div>
            <div className='d_content__list__pag__number color-gray-3'>
              {Math.ceil(pagination.total / pagination.pageSize)}
            </div>

            <div
              className={'d_content__list__pag__button'}
              onClick={() => handleTableChangeMobile(pagination.current + 1)}
            >
              {iconRight({
                color:
                  pagination.current ===
                  Math.ceil(pagination.total / pagination.pageSize)
                    ? '#A9AFD9'
                    : '#4F4FFF',
              })}
            </div>
          </div>
        </section>
      )}

      <ModalConfirm
        visible={itemToDelete}
        onClose={() => setItemToDelete(null)}
        title='¿Seguro que desea eliminar la versión de la lista?'
        image={DeleteModalImage}
        actionsContent={
          <div className='actions-delete-modal mt-2'>
            <Button
              className='button secondary-button mr-2'
              onClick={() => setItemToDelete(null)}
            >
              Cancelar
            </Button>

            <Button
              className='button ml-1'
              type='primary'
              disabled={loadingDelete}
              loading={loadingDelete}
              onClick={handleRemove}
            >
              Eliminar
            </Button>
          </div>
        }
      />

      <ModalVersion
        visible={modalVersion}
        data={versionContent}
        loading={loadingVersion}
        handleCancel={() => setModalVersion(false)}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  load: (pagination) => getChangelog(dispatch, pagination),
  create: async (data, pagination, isBeforeTable, onModalSuccess) => {
    const created = await createChangelog(dispatch, data, pagination);

    if (isBeforeTable && created) {
      onModalSuccess({
        message: '¡Listo! Se creó la versión correctamente',
      });

      return;
    }

    created
      ? notification.success({
          description: 'Se creo la versión correctamente.',
          message: '¡Versión creado!',
        })
      : notification.warning({
          description: 'Ha ocurrido un error.',
          message: 'Crear versión',
        });
  },
  update: async (data, pagination, isBeforeTable, onModalSuccess) => {
    const updated = await updateChangelog(dispatch, data, pagination);

    if (isBeforeTable && updated) {
      onModalSuccess({
        message: '¡Cambios guardados! Se guardaron los cambios correctamente',
      });

      return;
    }

    updated
      ? notification.success({
          description: 'Se guardaron los cambios correctamente.',
          message: '¡Cambios guardados!',
        })
      : notification.warning({
          description: 'Ha ocurrido un error.',
          message: 'Actualizar versión',
        });
  },
});

const mapStateToProps = (state) => ({
  list: state.changelog.list || [],
  pagination: state.changelog.pagination || [],
  isLoading: state.changelog.isLoading,
  isLoadingCreate: state.changelog.isLoadingCreate,
});

export default connect(mapStateToProps, mapDispatchToProps)(Versions);
