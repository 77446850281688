import { useEffect, useMemo, useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment, { locale } from 'moment';
import FileBase64 from 'react-file-base64';
import { Editor } from '@tinymce/tinymce-react';
import {
  Button,
  DatePicker,
  Form,
  Input,
  Modal,
  notification,
  Spin,
  Switch,
  TimePicker,
  TreeSelect,
  Typography,
  Select,
  Row,
  Col,
} from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { ArrowLeftOutlined, UploadOutlined } from '@ant-design/icons';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { RideButton } from '@rimac-seguros/ride-system-components';

import text from '../../../../config/text_es.json';
import { getZones } from '../../../../services/zones';
import { getEventType } from '../../../../services/eventType';
import {
  createEvents,
  getEventsImagesProvinces,
  updateEvents,
} from '../../../../services/events';
import { initTinyEditor } from '../../../../lib/tinyEditorConfig';

import Topbar from '../../../../common/ui/Topbar';
import SectionSelecZoneMap from '../../../../common/SelectZoneMap/SectionSelecZoneMap';
import ViewImage from './ViewImage';
import { filterByString, secureRandom } from '../../../../lib/utils';
import {
  IconMarker,
  IconTrash,
} from '../../ui/PreventionService/Reports/utils';
import SmallImage from '../../../../common/Attachment/SmallImage';

const { TextArea } = Input;
const { RangePicker } = DatePicker;
const { SHOW_PARENT } = TreeSelect;
const { Title, Text } = Typography;
const { Option } = Select;

const DetailEventManagment = ({
  loadZones,
  loadEventTypes,
  update,
  create,
  eventTypes,
  zones,
  pagination,
  isLoadingCreate,
}) => {
  const [form] = Form.useForm();

  const event = useMemo(
    () =>
      localStorage.getItem('event-selected')
        ? JSON.parse(localStorage.getItem('event-selected'))
        : null,
    [],
  );

  const { getFieldValue, setFieldsValue, resetFields } = form;
  const navigate = useNavigate();
  const [isRangeDate, setIsRangeDate] = useState(false);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openedSelectZoneMap, setOpenedSelectZoneMap] = useState(false);
  const [modalImage, setModalImage] = useState(false);
  const [imageSelected, setImageSelected] = useState(null);
  const [doingAction, setDoingAction] = useState(false);
  const [query, setQuery] = useState('');
  const [filteredEvents, setFilteredEvents] = useState(eventTypes);

  const handleCancel = () => {
    resetFields();
    localStorage.removeItem('event-selected');
    navigate('/monitoreo/boletines?tab=eventManagment');
  };

  const onOk = (values) => {
    setDoingAction(true);
    if (event !== null) {
      update(
        values,
        {
          page: pagination.current,
          limit: pagination.pageSize,
          query: '',
        },
        navigate,
        setDoingAction,
      );
    } else {
      create(values, navigate, setDoingAction);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();

    try {
      const values = await form.validateFields();

      let order = 1;
      const imagesClone = images?.length
        ? [...images].map((image) => ({ ...image, order: order++ }))
        : [];

      if (imagesClone.length > 0 && !imagesClone.find((image) => image.main)) {
        return notification.warning({
          message: 'Advertencia',
          description: 'Debe seleccionar una imagen principal',
        });
      }

      values.images = imagesClone;
      values.large_duration = isRangeDate;
      if (!isRangeDate) {
        values.date_event_end = null;
      }

      if (isRangeDate) {
        const fullDate = getFieldValue('range_date');
        const mergeInitTime = moment(
          `${fullDate[0].format('DD-MM-YYYY')} ${values.hour_start.format(
            'HH:mm',
          )}`,
          'DD-MM-YYYY HH:mm',
        );
        const mergeEndTime = moment(
          fullDate[1].format('DD-MM-YYYY') +
            ' ' +
            values.hour_end.format('HH:mm'),
          'DD-MM-YYYY HH:mm',
        );
        values.date_event_start = mergeInitTime;
        values.date_event_end = mergeEndTime;
      } else {
        const mergeTime = moment(
          values.date_event_start.format('DD-MM-YYYY') +
            ' ' +
            values.hour_start.format('HH:mm'),
          'DD-MM-YYYY HH:mm',
        );
        values.date_event_start = mergeTime;
      }

      if (values.provinces === null) values.provinces = [];

      onOk(values);
    } catch (err) {
      notification.error({
        description: 'Hubo un error.',
        message: 'Error',
      });
    }
  };

  const _handleSearch = useCallback((query) => {
    setQuery(query);
  }, []);

  const Check = () => (
    <section
      style={{
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
      }}
    >
      <Text>
        {isRangeDate
          ? '¿Desea editar el evento por rango de horas?'
          : '¿Desea editar el evento por rango de fechas?'}
      </Text>
      <Switch
        defaultChecked={isRangeDate}
        onChange={(checked) => setIsRangeDate(checked)}
      />
    </section>
  );

  const tProps = {
    treeData: zones.map((zone) => ({
      ...zone,
      children: zone.children?.map((children) => ({
        ...children,
        children: null,
      })),
    })),
    treeCheckable: true,
    filterTreeNode: (inputValue, treeNode) => {
      const title = treeNode.props.title;

      return title.toLowerCase().includes(inputValue.toLowerCase());
    },
    showCheckedStrategy: SHOW_PARENT,
    showArrow: true,
  };

  const addImageEvent = (image64) => {
    image64.name = `${uuidv4()}-image.${
      image64.base64?.split('')[0].split('/')[1]
    }`;
    setImages((images) => [
      ...images,
      {
        idTmp: uuidv4(),
        image64,
      },
    ]);
  };

  const changeImageText = (idTmp, text) => {
    const image = images.find((image) => image.idTmp === idTmp);
    image.name = text;
    setImages(images);
  };

  const changeMainImage = (idTmp) => {
    const newImages = images.map((image) => {
      if (image.idTmp === idTmp) {
        image.main = true;
      } else {
        image.main = false;
      }

      return image;
    });
    setImages(newImages);
  };

  const deleteImage = (idTmp) => {
    const newImages = images.filter((image) => image.idTmp !== idTmp);
    setImages([...newImages]);
  };

  const ImageCard = ({ imageUrl, base64, name, idTmp, main }) => {
    const imageUrlComplete = useMemo(() => {
      if (imageUrl && imageUrl.indexOf('https://') !== -1) {
        return `${imageUrl}?tag=${idTmp}`;
      } else if (imageUrl) {
        return `${process.env.REACT_APP_WEB_PATH_S3_NEW}${imageUrl}?tag=${idTmp}`;
      } else {
        return null;
      }
    }, [imageUrl]);

    return (
      <div className='card-image-form'>
        <div className='image__left'>
          <div
            onClick={() => {
              setModalImage(true);
              setImageSelected(base64 ? base64 : imageUrlComplete);
            }}
          >
            <SmallImage
              src={base64 ? base64 : imageUrlComplete}
              locale={true}
            />
          </div>
          <div className='input'>
            <Input
              defaultValue={name}
              id='title-imagge'
              placeholder='Ingrese el título de la imagen'
              onBlur={(e) => changeImageText(idTmp, e.target.value)}
            />
          </div>
        </div>

        <div className='image__right'>
          <Button
            type={main ? 'primary' : 'default'}
            onClick={() => changeMainImage(idTmp)}
          >
            <div className={`icon ${main && 'selected'}`}></div>{' '}
            <span>Imágen principal</span>
          </Button>
          <Button onClick={() => deleteImage(idTmp)}>
            <IconTrash color='#4F4FFF' /> <span className='text'>Eliminar</span>
          </Button>
        </div>
      </div>
    );
  };

  const handleClickImage = () =>
    document.querySelector('.preview_img input').click();

  const onSaveProvinces = ({ provinces, base64 }) => {
    const newElement = {
      idTmp: uuidv4(),
      image64: {
        base64,
        name: `${parseInt(secureRandom() * 10000)}-image.jpeg`,
      },
      provinces,
    };
    const newImages = images.concat([newElement]);
    setImages(newImages);

    const newProvinces = Array.from(
      new Set(
        newImages
          .map((image) => image.provinces)
          .filter(Boolean)
          .reduce((pre, cur) => pre.concat(cur), []),
      ),
    );
    form.setFieldsValue({
      provinces: Array.from(
        new Set([...newProvinces, ...(form.getFieldValue('provinces') || [])]),
      ),
    });
  };

  const _handleOnDragEnd = useCallback(
    (result) => {
      if (!result.destination) {
        return;
      }

      const items = Array.from(images);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);
      setImages(items);
    },
    [images],
  );

  useEffect(() => {
    if (query && query.length >= 3) {
      const result = filterByString(eventTypes, query);
      setFilteredEvents(result);
    } else {
      setFilteredEvents(eventTypes);
    }
  }, [eventTypes, query]);

  useEffect(() => {
    loadEventTypes();
    loadZones();

    return () => {
      setIsRangeDate(false);
      setOpenedSelectZoneMap(false);
    };
  }, []);

  useEffect(() => {
    event && event.large_duration === 1
      ? setIsRangeDate(true)
      : setIsRangeDate(false);
  }, [event]);

  useEffect(() => {
    if (event && event?.eventImages && event.eventImages.length > 0) {
      setLoading(true);
      getEventsImagesProvinces(event.eventImages.map((e) => e.id))
        .then((eventImagesProvinces) => {
          const eventImages = event.eventImages.map((eventImage) => ({
            ...eventImage,
            idTmp: uuidv4(),
            imageUrl: eventImage.url,
            provinces:
              eventImagesProvinces
                .find(({ id }) => id === eventImage.id)
                ?.eventImagesProvinces.map((province) => province.id) || [],
          }));
          setImages(eventImages);
        })
        .catch(console.error)
        .finally(() => setLoading(false));
    } else if (event && event?.image) {
      setImages([
        {
          imageUrl: event?.image,
          idTmp: uuidv4(),
          provinces: event.provinces?.map((p) => p.id) || [],
          main: true,
        },
      ]);
    } else {
      setImages([]);
    }
  }, [event]);

  return (
    <>
      <div id='checkSize' className='h100vh dashboard-UI'>
        <Topbar />
        <div className='d_content'>
          <section className='section-form-newsletters section-form-event'>
            <div className='return' onClick={handleCancel}>
              <ArrowLeftOutlined />
              <Title level={2}>{event ? 'Editar' : 'Crear'} evento</Title>
            </div>

            <Form form={form} disabled={isLoadingCreate}>
              <Form.Item
                name='id'
                initialValue={event ? event?.id : ''}
                style={{ height: 0, overflow: 'hidden', margin: 0, padding: 0 }}
              >
                <Input type='hidden' />
              </Form.Item>

              <Form.Item
                name='event_type_id'
                initialValue={event ? event?.eventType?.id : ''}
                label={<Text strong>Tipo</Text>}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Select
                  placeholder='Selecciona un tipo de evento'
                  loading={isLoadingCreate}
                  disabled={isLoadingCreate}
                  showSearch
                  onSearch={_handleSearch}
                  filterOption={false}
                  style={{ width: 300 }}
                  size='large'
                >
                  {filteredEvents.map((event) => (
                    <Option key={event.id} value={event.id}>
                      {event.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                name='large_duration'
                initialValue={event ? event?.large_duration : false}
              >
                <Check />
              </Form.Item>

              {!isRangeDate ? (
                <Form.Item
                  name='date_event_start'
                  initialValue={
                    event && event?.date_event_start
                      ? moment(event?.date_event_start)
                      : null
                  }
                  rules={[
                    {
                      required: isRangeDate,
                      message: text.required_message,
                    },
                  ]}
                  label={<Text strong>Editar evento por rango de fechas</Text>}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <DatePicker
                    format='DD/MM/YYYY'
                    locale={locale}
                    disabled={isLoadingCreate}
                    placeholder='Seleccionar fecha del evento'
                    style={{ width: 300 }}
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  name='range_date'
                  initialValue={
                    event
                      ? [
                          event?.date_event_start
                            ? moment(event?.date_event_start)
                            : null,
                          event?.date_event_end
                            ? moment(event?.date_event_end)
                            : null,
                        ]
                      : [null, null]
                  }
                  rules={[
                    {
                      required: isRangeDate,
                      message: text.required_message,
                    },
                  ]}
                  label={<Text strong>Editar evento por rango de fechas</Text>}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <RangePicker
                    name='date'
                    placeholder={['Fecha Inicio', 'Fecha Fin']}
                    format='DD/MM/YYYY'
                    locale={locale}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              )}

              {!isRangeDate ? (
                <Form.Item
                  name='hour_start'
                  initialValue={event ? moment(event?.date_event_start) : null}
                  rules={[
                    {
                      required: isRangeDate,
                      message: text.required_message,
                    },
                  ]}
                  label={<Text strong>Seleccionar hora</Text>}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <TimePicker
                    className='full-width'
                    placeholder='Hora de inicio'
                    format='HH:mm'
                    getPopupContainer={(e) => e}
                    use12Hours
                    locale={locale}
                    style={{ width: '200px' }}
                  />
                </Form.Item>
              ) : (
                <>
                  <Text strong>Editar evento por rango de horas</Text>
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        name='hour_start'
                        initialValue={
                          event ? moment(event?.date_event_start) : null
                        }
                        rules={[
                          {
                            required: isRangeDate,
                            message: text.required_message,
                          },
                        ]}
                      >
                        <TimePicker
                          className='full-width'
                          placeholder='Hora de inicio'
                          format='HH:mm'
                          getPopupContainer={(e) => e}
                          use12Hours
                          locale={locale}
                          style={{ width: '100%' }} // set a fixed width for the TimePicker
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name='hour_end'
                        initialValue={
                          event && event?.date_event_end
                            ? moment(event?.date_event_end)
                            : null
                        }
                        rules={[
                          {
                            required: isRangeDate,
                            message: text.required_message,
                          },
                        ]}
                      >
                        <TimePicker
                          className='full-width pl-1'
                          getPopupContainer={(e) => e}
                          placeholder='Hora de fin'
                          format='HH:mm'
                          use12Hours
                          locale={locale}
                          style={{ width: '100%' }} // set a fixed width for the TimePicker
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}

              <Form.Item
                name='detected_condition'
                initialValue={event ? event?.detected_condition : ''}
                label={<Text strong>Condición detectada (Texto Negro)</Text>}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <TextArea
                  rows={6}
                  disabled={isLoadingCreate}
                  placeholder='Ingrese condición (Texto Negro)'
                  style={{ width: '100%' }}
                />
              </Form.Item>

              <Form.Item
                name='detected_condition_red'
                initialValue={event ? event?.detected_condition_red : ''}
                label={<Text strong>Condición detectada (Texto Rojo)</Text>}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <TextArea
                  rows={6}
                  disabled={isLoadingCreate}
                  placeholder='Ingrese condición (Texto Rojo)'
                  style={{ width: '100%' }}
                />
              </Form.Item>

              <div className='regions-list'>
                <label className='regions-list__title'>
                  Selecciona las regiones afectadas ó añade una imagen del mapa
                </label>

                <div className='actions'>
                  <Button
                    type='primary'
                    size='small'
                    onClick={() => {
                      setOpenedSelectZoneMap(true);
                    }}
                  >
                    <IconMarker /> Añadir mapa
                  </Button>
                </div>

                <Form.Item
                  name='provinces'
                  {...(event &&
                    event.provinces &&
                    event.provinces.length > 0 && {
                      initialValue: event.provinces.map((p) => p.id),
                    })}
                >
                  <TreeSelect
                    {...tProps}
                    placeholder='Selecciona las regiones afectadas'
                    className={
                      'regions-list__tree ' +
                      (form.getFieldValue('provinces') &&
                      form.getFieldValue('provinces').length > 0
                        ? 'active'
                        : '')
                    }
                    allowClear
                  />
                </Form.Item>

                <label className='regions-list__title'>
                  Añade una imagen desde el ordenador
                </label>

                <div className='actions'>
                  <Button
                    className='upload'
                    type='primary'
                    size='small'
                    onClick={handleClickImage}
                  >
                    <UploadOutlined /> Añadir imágen
                  </Button>
                </div>

                <div className='show-map'></div>

                <div className='result'>
                  <div className='preview_img'>
                    <FileBase64
                      multiple={true}
                      onDone={(data) =>
                        addImageEvent({ base64: data[0].base64 })
                      }
                    />
                  </div>

                  <div className='result__image-list'>
                    {loading && <Spin />}

                    <DragDropContext onDragEnd={_handleOnDragEnd}>
                      <Droppable droppableId='images'>
                        {(provided) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {images?.map((image, key) => (
                              <Draggable
                                key={`image-${key}`}
                                draggableId={String(key)}
                                index={key}
                              >
                                {(prov) => (
                                  <div
                                    {...prov.draggableProps}
                                    {...prov.dragHandleProps}
                                    ref={prov.innerRef}
                                  >
                                    <ImageCard
                                      imageUrl={image?.imageUrl}
                                      base64={image?.image64?.base64}
                                      idTmp={image.idTmp}
                                      name={image?.name}
                                      main={image?.main}
                                      setImageSelected={setImageSelected}
                                      setModalImage={setModalImage}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                </div>
              </div>

              <Form.Item
                name='summary'
                initialValue={event ? event?.summary : ''}
                style={{ display: 'none' }}
              >
                <Input type='hidden' />
              </Form.Item>

              <Editor
                initialValue={event ? event?.summary : ''}
                apiKey={process.env.REACT_APP_TINYMCE_KEY}
                init={initTinyEditor}
                onEditorChange={(data) => setFieldsValue({ summary: data })}
              />

              <RideButton
                className='mt-4'
                onClick={handleSave}
                disabled={doingAction}
                loading={doingAction}
                text={event ? 'Guardar cambios' : 'Crear'}
                size='large'
              />
            </Form>
          </section>

          <ViewImage
            visible={modalImage}
            setVisible={setModalImage}
            file={imageSelected}
            setFile={setImageSelected}
          />
        </div>
      </div>

      <Modal
        className='modal-event-map'
        open={openedSelectZoneMap}
        onCancel={() => setOpenedSelectZoneMap(false)}
        title=''
        footer={false}
        centered={true}
      >
        {openedSelectZoneMap && (
          <div className='content-custom'>
            <SectionSelecZoneMap
              onOk={onSaveProvinces}
              visible={openedSelectZoneMap}
              setVisible={setOpenedSelectZoneMap}
            />
          </div>
        )}
      </Modal>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  loadEventTypes: () => getEventType(dispatch),
  loadZones: () => getZones(dispatch),
  create: async (event, navigate, action) => {
    const created = await createEvents(event, dispatch, true);
    action();

    created
      ? notification.success({
          description: 'Se creo correctamente el evento.',
          message: 'Evento creado!',
        })
      : notification.warning({
          description: 'Ha ocurrido un error.',
          message: '¡Gestión de boletínes!',
        });

    navigate('/monitoreo/boletines?tab=eventManagment');
  },
  update: async (event, pagination, navigate, action) => {
    const updated = await updateEvents(event, dispatch, pagination, true);
    action();

    updated
      ? notification.success({
          description: 'Se actualizo correctamente el evento.',
          message: 'Evento actualizado!',
        })
      : notification.warning({
          description: 'Ha ocurrido un error.',
          message: '¡Gestión de boletínes!',
        });

    navigate('/monitoreo/boletines?tab=eventManagment');
  },
});

const mapStateToProps = (state) => ({
  eventTypes: state.eventType.eventTypes,
  zones: state.zones.zones,
  pagination: state.events.pagination,
  isLoadingCreate: state.events.isLoadingCreate,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DetailEventManagment);
