import React from 'react';
import { useMap } from 'react-leaflet';
import {
  RideActionButton,
  RideGlPlus,
  RideGlIndeterminate,
} from '@rimac-seguros/ride-system-components';

const ZoomControls = () => {
  const map = useMap();

  const zoomIn = (event) => {
    event.stopPropagation();
    map.zoomIn();
  };

  const zoomOut = (event) => {
    event.stopPropagation();
    map.zoomOut();
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        bottom: 50,
        right: 10,
        zIndex: 1000,
        gap: '0.5rem',
      }}
    >
      <RideActionButton
        tooltipText='Aumentar zoom'
        glyph={<RideGlPlus />}
        placement='left-center'
        onClick={zoomIn}
        size='small'
      />
      <RideActionButton
        tooltipText='Disminuir zoom'
        glyph={<RideGlIndeterminate />}
        placement='left-center'
        onClick={zoomOut}
        size='small'
      />
    </div>
  );
};

export default ZoomControls;
