import React, { useRef, useState, useEffect } from 'react';
import L from 'leaflet';
import { TileLayer, MapContainer, useMapEvents, Marker } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41], // tamaño del icono
  iconAnchor: [12, 41], // punto del icono que corresponderá a la ubicación del marcador
  popupAnchor: [0, -41], // punto desde donde se abrirá el popup
});

L.Marker.prototype.options.icon = DefaultIcon;

const startPoint = [-9.27984584271891, -74.75373348350703];

const MarkerPosition = ({ onFlyTo, position, setPosition }) => {
  useMapEvents({
    click({ latlng }) {
      setPosition(latlng);
      onFlyTo(latlng);
    },
  });

  if (!position) {
    return null;
  }

  return <Marker position={position}></Marker>;
};

const MyLocationFormMap = ({ lat, lng, handleClickedMap, reset }) => {
  const setMap = useRef();
  const [position, setPosition] = useState(null);

  const drawMarker = (latLng) => {
    handleClickedMap(latLng);
  };

  useEffect(() => {
    if (reset) {
      setPosition(null);
      if (setMap.current) {
        setMap.current.flyTo(startPoint, 10);
      }
    } else if (lat && lng) {
      const newLatLng = { lat, lng };
      setPosition(newLatLng);
      drawMarker(newLatLng);
    }
  }, [reset, lat, lng]);

  useEffect(() => {
    if (setMap.current) {
      setTimeout(() => {
        setMap.current.invalidateSize();
      }, 100);
    }
  }, [setMap.current]);

  return (
    <MapContainer
      style={{ height: '400px', width: '100%' }}
      center={lat && lng ? [lat, lng] : startPoint}
      zoom={6}
      scrollWheelZoom={true}
      zoomControl={false}
      ref={setMap}
    >
      <TileLayer
        attribution='Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS, Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri (Thailand), TomTom, 2012'
        url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}'
      />
      <MarkerPosition
        onFlyTo={drawMarker}
        position={position}
        setPosition={setPosition}
      />
    </MapContainer>
  );
};

export default MyLocationFormMap;
