import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AnimatedControls from './AnimatedControls';
import moment from 'moment/moment';
import { useMapEvents } from 'react-leaflet';

import { secureRandom } from '../../../../lib/utils';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { MAP_CHANGE_BLOCKED_CLICK } from '../../../../redux/actions/actionsType';
import {
  generateTicks,
  generateMarks,
  generateRealtimeTicks,
  generateRealtimeMarks,
  removeLayers,
  updateOpacity,
  cleanupLayers,
} from './utils';
import {
  updateSceneLayers,
  checkLoadingStatus,
  handleSceneLoading,
  loadAllScenes,
} from './animationManager';
import { handleSubLayer, handleSubLayerRegions } from './subLayerManager';
import { handleZoomEnd } from './mapEvents';

const MultiLayerWMS = ({ activeSubLayer, isAnimate, opacity = 1 }) => {
  const map = useMapEvents({
    zoomend(e) {
      handleZoomEnd(
        e,
        lastZoom,
        activeSubLayer,
        subLayerRegionsObj,
        setLastZoom,
      );
    },
  });

  const subLayerObj = useRef();
  const subLayerRegionsObj = useRef();
  const menuIsOpened = useSelector((state) => state.sideMenu.isOpened);
  const dispatch = useDispatch();
  const [lastZoom, setLastZoom] = useState(map.getZoom());

  const [isPlaying, setIsPlaying] = useState(null);
  const [isLoadingScenes, setIsLoadingScenes] = useState(false);
  const [selectedSceneBar, setSelectedSceneBar] = useState(() => {
    if (activeSubLayer.animate?.days) {
      return Number(moment().format('HH'));
    }

    return activeSubLayer?.animate?.realtime
      ? activeSubLayer?.animate?.times.at(-1)
      : 1;
  });
  const [scenesLayers, setScenesLayers] = useState({});
  const [scenesLayersLoaded, setScenesLayersLoaded] = useState([]);
  const [forceGenerateTimes, setForceGenerateTimes] = useState(false);
  const intervalControl = useRef(null);
  const intervalUpdateTiles = useRef(null);
  const scenesLayersRef = useRef(null);
  scenesLayersRef.current = scenesLayers;
  const { width } = useWindowDimensions();

  const [selectedHour, setSelectedHour] = useState('12h');

  const changeIsBlockedClick = (blocked) => {
    dispatch({ type: MAP_CHANGE_BLOCKED_CLICK, payload: blocked });
  };

  const passHoursToSelecedtIdxTime = { '2h': 0, '6h': 1, '12h': 2 };
  const idxTimes = useMemo(
    () => passHoursToSelecedtIdxTime[selectedHour],
    [selectedHour],
  );

  const onPlayAction = () => {
    if (isPlaying === null) {
      setIsLoadingScenes(true);
    }
    setIsPlaying((prev) => !prev);
  };

  useEffect(() => cleanupLayers(map, subLayerObj, subLayerRegionsObj), []);

  useEffect(() => {
    if (!activeSubLayer) {
      return;
    }

    handleSubLayer(activeSubLayer, subLayerObj, map, opacity, defaultScene);
    handleSubLayerRegions(activeSubLayer, subLayerRegionsObj, map);
  }, [activeSubLayer, opacity]);

  useEffect(() => () => removeLayers(map, scenesLayersRef), []);

  useEffect(() => {
    updateOpacity(scenesLayers, opacity, setScenesLayers);
  }, [opacity]);

  const resetAnimation = () => {
    setIsPlaying(null);
    setIsLoadingScenes(false);
    setSelectedSceneBar(defaultScene);
    setScenesLayers({});
    setScenesLayersLoaded([]);
  };

  const generateMarksTimes = useMemo(() => {
    let marks = {};
    let ticks = {};
    if (activeSubLayer?.animate) {
      const { days, realtime, times, unitTime, format, splits } =
        activeSubLayer.animate;
      if (days) {
        ticks = generateTicks(times, unitTime, format, 1);
        marks = generateMarks(times, unitTime, format, 1, 24);
      } else if (realtime) {
        const INTERVAL = 10; // minutes
        const SEPARATION = times[idxTimes] / splits;
        ticks = generateRealtimeTicks(
          times[idxTimes],
          unitTime,
          format,
          INTERVAL,
        );
        marks = generateRealtimeMarks(
          times[idxTimes],
          unitTime,
          format,
          INTERVAL,
          SEPARATION,
        );
      } else {
        ticks = generateTicks(times, unitTime, format, splits);
        marks = generateMarks(times, unitTime, format, splits, splits);
      }
    }
    return {
      marks,
      ticks,
    };
  }, [activeSubLayer, forceGenerateTimes, idxTimes]);

  const formatterTooltip = (value) => generateMarksTimes.ticks[value];

  useEffect(() => {
    updateSceneLayers({
      activeSubLayer,
      selectedSceneBar,
      idxTimes,
      scenesLayers,
      setScenesLayersLoaded,
      opacity,
      setScenesLayers,
      map,
    });
  }, [selectedSceneBar, opacity]);

  useEffect(() => {
    checkLoadingStatus(scenesLayers, scenesLayersLoaded, setIsLoadingScenes);
  }, [scenesLayers, scenesLayersLoaded]);

  useEffect(() => {
    handleSceneLoading({
      isLoadingScenes,
      isPlaying,
      intervalControl,
      setSelectedSceneBar,
      activeSubLayer,
      idxTimes,
      defaultScene,
      scenesLayers,
      setForceGenerateTimes,
      secureRandom,
      intervalUpdateTiles,
    });
  }, [isLoadingScenes, isPlaying]);

  useEffect(() => {
    if (isPlaying) {
      loadAllScenes(
        activeSubLayer,
        scenesLayers,
        setScenesLayersLoaded,
        setScenesLayers,
        idxTimes,
        opacity,
        map,
      );
    } else {
      clearInterval(intervalControl.current);
      intervalControl.current = null;
      clearInterval(intervalUpdateTiles.current);
      intervalUpdateTiles.current = null;
    }
  }, [isPlaying, activeSubLayer]);

  const changeMoveOfMap = useCallback((enabled) => {
    if (enabled) {
      map.dragging.enable();
    } else {
      map.dragging.disable();
    }
  }, []);

  const onChangeBar = (val) => {
    if (isPlaying && intervalControl.current) {
      setIsPlaying(false);
    }

    changeMoveOfMap(false);
    setSelectedSceneBar(val);
  };

  const onAfterChangeBar = (val) => {
    if (isPlaying && intervalControl.current) {
      setIsPlaying(false);
    }

    changeMoveOfMap(true);
    setSelectedSceneBar(val);
  };

  const computedWidth = useMemo(() => {
    const widthLeftBar = menuIsOpened ? 268 : 30;
    const widthRightBar = 50;
    const barWith = width - widthLeftBar - widthRightBar;

    return barWith > 600 ? 600 : barWith;
  }, [menuIsOpened, width]);

  const onMouseEnterToControls = () => {
    changeIsBlockedClick(true);
  };

  const onMouseExitOfControls = () => {
    changeIsBlockedClick(false);
  };

  const defaultScene = useMemo(() => {
    if (activeSubLayer.animate?.days) {
      return Number(moment().format('HH'));
    }

    return activeSubLayer?.animate?.realtime
      ? activeSubLayer?.animate?.times[idxTimes]
      : 1;
  }, [activeSubLayer, idxTimes]);

  useEffect(() => {
    if (activeSubLayer?.animate?.realtime) {
      window.currentTime =
        activeSubLayer?.animate.times[idxTimes] -
        (selectedSceneBar || defaultScene);
    } else {
      window.currentTime = selectedSceneBar || defaultScene;
    }
  }, [selectedSceneBar, defaultScene, activeSubLayer, idxTimes]);

  const handleSelectHour = (hour) => {
    setSelectedHour(hour);
    Object.values(scenesLayersRef.current).forEach((l) => {
      map.removeLayer(l.layer);
      l.layer.removeFrom(map);
    });
    resetAnimation();
  };

  return (
    <AnimatedControls
      activeSubLayer={activeSubLayer}
      isAnimate={isAnimate}
      onMouseEnterToControls={onMouseEnterToControls}
      onMouseExitOfControls={onMouseExitOfControls}
      isLoadingScenes={isLoadingScenes}
      selectedHour={selectedHour}
      handleSelectHour={handleSelectHour}
      computedWidth={computedWidth}
      onPlayAction={onPlayAction}
      isPlaying={isPlaying}
      selectedSceneBar={selectedSceneBar}
      defaultScene={defaultScene}
      onAfterChangeBar={onAfterChangeBar}
      onChangeBar={onChangeBar}
      formatterTooltip={formatterTooltip}
      idxTimes={idxTimes}
      generateMarksTimes={generateMarksTimes}
    />
  );
};

export default MultiLayerWMS;
