import { useState, useCallback, useMemo, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Typography,
  Divider,
  notification,
  Skeleton,
  Badge,
  Select,
} from 'antd';
import { RideButton } from '@rimac-seguros/ride-system-components';
import {
  getMyPolizas,
  getServicesOfPoliza,
  updateRequesServiceInCreate,
} from '../../../../../services/requestServices';
import {
  CLEAR_FORM_REQUEST,
  OPEN_FORM_REQUEST,
  UPDATE_FROM_STORAGE,
  UPDATE_REQUEST_SERVICE_FIELD,
} from '../../../../../redux/actions/actionsType';
import CommentField from './CommentField';
import ServiceInHouse from './Inhouse/ServiceInHouse';
import Signage from './TableSignage';
import { mapPolizaServices } from '../../../ui/PreventionService/ServicesAvailable';
import { signalsKeys } from '../..';
import InHouseCoursesModal from './Inhouse/InHouseCourseModal';
import {
  getPreventionServices,
  getRisksServices,
} from '../../../../../services/service';
import { pushDigitalData } from '../../../../../lib/utils';
import { TRACK } from '../../../../../lib/consts';

const { Text } = Typography;

const openNotification = ({ title, error }) => {
  notification.warning({
    message: title,
    description: error,
  });
};

const polizaRequestServices = (requests) => {
  return requests
    .map((request) => request.requestServiceItems)
    .reduce((result, curr) => result.concat(curr), [])
    .reduce((grouped, item) => {
      (grouped[item.serviceId] = grouped[item.serviceId] || []).push(item);
      return grouped;
    }, {});
};

const matchServicesByType = (servicesCount, servicesByType) => {
  const servicesIds = servicesCount.map((el) => el.serviceId);

  return servicesByType.reduce((acc, curr) => {
    if (!servicesIds.includes(curr.id))
      acc = acc.concat({
        count: 0,
        serviceId: curr.id,
        service: curr,
      });

    return acc;
  }, []);
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ServiceRequest = ({
  service,
  action,
  updateForm,
  idForm,
  closeForm,
  onUpdateLocalStorage,
  polizaId,
  companySelected,
  onUpdateField,
  updateRequestStorage,
  requestServicesList,
  isMobile,
  updateStorageMain,
  fromRequestService,
  additionalSignals,
  requestServiceItemId,
  companyId,
  isAdminRimac,
}) => {
  let query = useQuery();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [valueSelect, setValueSelect] = useState(() => {
    const storageData = localStorage.getItem(`service-${idForm}`);

    return storageData
      ? Number(JSON.parse(storageData).serviceId)
      : Number(service?.id);
  });
  const [loadingServices, setLoadingServices] = useState(true);
  const [freeHours, setFreeHours] = useState(0);
  const [formByService, setFormByService] = useState(() => {
    return {
      ...(service.comment ? { comment: service.comment } : {}),
      ...(service.signals ? { signals: service.signals } : {}),
      derived: service.derived ?? true ? true : false,
      courses: service.courses ?? null,
    };
  });
  const [servicesList, setServicesList] = useState([]);
  const [maxSignals, setMaxSignals] = useState(500);

  useEffect(() => {
    getMyPolizas(companySelected)
      .then((data) => {
        updateStorageMain();
        localStorage.setItem('polizaId', polizaId);
        const { typePolizaId } =
          data.find((el) => String(el.id) === polizaId) || {};
        onUpdateField({ policies: data, typePolizaId, polizaId });

        return data;
      })
      .then((policies) => {
        Promise.all(
          [getServicesOfPoliza(polizaId)].concat(
            query.get('risks')
              ? getRisksServices(dispatch)
              : getPreventionServices(dispatch),
          ),
        )
          .then(([services, servicesByType]) => {
            const polizaSelected = policies?.find(
              (el) => String(el.id) === String(polizaId),
            );
            const requests = polizaRequestServices(
              polizaSelected?.requestServices,
            );

            services = mapPolizaServices(services, requests);

            // Filter service by type
            services = services.filter((service) => {
              const servicesIdsByType = servicesByType.map((el) => el.id);

              return servicesIdsByType.includes(service.serviceId);
            });

            // Add all services by type if user is admin
            if (servicesByType && servicesByType.length && isAdminRimac) {
              servicesByType = matchServicesByType(services, servicesByType);
              services = services.concat(servicesByType ?? []);
            }

            if (fromRequestService) {
              localStorage.removeItem(
                'requestServicesList-undefined-undefined',
              );
              localStorage.setItem(
                `requestServicesList-${polizaId}-${service.id}`,
                JSON.stringify([]),
              );

              services = services.filter(
                (el) => el.serviceId !== polizaId && el.count !== 0,
              );
              services.push({
                id: polizaId,
                count: additionalSignals,
                levelCompanyId: service.levelCompany,
                serviceId: service.id,
                service: {
                  id: service.id,
                  name: service.serviceName,
                  type: service.serviceType,
                },
                prevCount: 0,
              });

              setValueSelect(service.id);
            }

            setServicesList(services);
            onUpdateField({ services });
          })
          .finally(() => {
            setLoadingServices(false);
          });
      });

    return () => {
      updateRequestStorage();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (valueSelect && servicesList.length) {
      const item = servicesList.find((el) => el.serviceId === valueSelect);
      const hoursUsed = requestServicesList
        .filter((el) => el.id !== idForm)
        .reduce((acc, curr) => {
          acc += Number(curr?.service?.courses?.hours || 0);

          return acc;
        }, 0);
      if (item) setFreeHours(item?.count - hoursUsed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [servicesList, valueSelect]);

  useEffect(() => {
    onUpdateLocalStorage('service', {
      serviceId: valueSelect,
      ...formByService,
    });
  }, [formByService, onUpdateLocalStorage, valueSelect]);

  const _handleChangeSelect = useCallback((value) => {
    setValueSelect(value);
  }, []);

  const getErrros = useCallback(
    (type) => {
      switch (String(type)) {
        case '3':
          let res = [];
          const sumTotal = Object.values(formByService.signals || {}).reduce(
            (sum, curr) => {
              for (let key of signalsKeys) sum = sum + Number(curr[key] || 0);

              return sum;
            },
            0,
          );

          const reqServ = servicesList.find(
            (el) => el.service.type === String(type),
          );
          const max = reqServ.count > 425 ? 425 : reqServ.count;
          setMaxSignals(max);

          if (sumTotal < 25)
            res.push({
              error: 'Pedido debe ser mayor a 25 unidades.',
              title: 'Señaletica',
              sumTotal,
            });
          else if (sumTotal > max)
            res.push({
              error: `Pedido no debe ser mayor a ${max} unidades.`,
              title: 'Señaletica',
              sumTotal,
            });

          return res;
        case '1':
          const hours = formByService.courses['hours'];

          return formByService.derived
            ? []
            : !formByService.courses['course']
            ? [{ error: 'Curso es requerido.', title: 'Capacitación in House' }]
            : !hours
            ? [
                {
                  error: 'Horas de capacitación son requeridas.',
                  title: 'Capacitación in House',
                },
              ]
            : Number(hours) > freeHours
            ? [
                {
                  error:
                    'Horas de capacitación deben ser menor a las horas disponibles.',
                  title: 'Capacitación in House',
                },
              ]
            : [];

        default:
          return [];
      }
    },
    [formByService, freeHours, servicesList],
  );

  const _handleUpdateFormService = useCallback((others) => {
    setFormByService((prev) => ({
      ...prev,
      ...others,
    }));
  }, []);

  const _handleUpdateForm = useCallback(
    (fields = {}) =>
      () => {
        if (process.env.REACT_APP_ANALYTICS_ENABLE !== '0') {
          pushDigitalData(
            {
              form: {
                channel: 'Centro Monitoreo',
                name: `Servicios - ${
                  action === 'add' ? 'Agregar' : 'Actualizar'
                } servicio`,
              },
            },
            TRACK.FORM_SUBMIT,
          );
        }

        const serviceSelect = servicesList.find(
          (el) => el.serviceId === valueSelect,
        );
        const errors = getErrros(serviceSelect?.service?.type);

        if (errors.length) return openNotification(errors[0]);

        updateForm({
          id: idForm,
          service: {
            serviceName: serviceSelect?.service?.name,
            serviceType: serviceSelect?.service?.type,
            serviceId: serviceSelect?.serviceId,
            ...fields,
          },
        });

        closeForm();
      },
    [closeForm, getErrros, idForm, servicesList, updateForm, valueSelect],
  );

  const optionsComponents = useMemo(() => {
    return servicesList.map(({ service: currService, serviceId, count }) => {
      let value = 0;
      const name = currService?.name;

      const isInHouse = [
        'capacitacion in house',
        'capacitación in house',
      ].includes(name);

      if (count > 0) {
        const localServicesCount = requestServicesList
          .filter((e) => e.id !== idForm)
          .reduce(
            (acc, { service: srv }) =>
              acc +
              (String(srv.serviceId) === String(serviceId)
                ? isInHouse
                  ? Number(srv?.courses?.hours || 0)
                  : 1
                : 0),
            0,
          );
        count = count - localServicesCount > 0 ? count - localServicesCount : 0;
        value = isInHouse ? count + 'h' : count;
      }

      if (!count || count < 0) {
        value = isInHouse ? 0 + 'h' : 0;
      }

      setValueSelect((prev) =>
        String(prev) === String(serviceId) && !count
          ? null
          : prev || Number(serviceId),
      );

      return {
        value: serviceId,
        title: (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div>
              {name
                ? name.length > 30
                  ? `${name.slice(0, 30)}...`
                  : name
                : ''}
            </div>
            <div>
              <Badge
                overflowCount={1000000}
                showZero
                className='service-count-restriction'
                count={value}
              />
            </div>
          </div>
        ),
        label: (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div style={{ whiteSpace: 'break-spaces' }}>{name ? name : ''}</div>
            <div>
              <Badge
                overflowCount={1000000}
                showZero
                className='service-count-restriction'
                count={value}
              />
            </div>
          </div>
        ),
        type: currService?.type,
        disabled: !count && !isAdminRimac,
      };
    });
  }, [idForm, requestServicesList, servicesList, isAdminRimac]);

  const optionSelect = useMemo(
    () => optionsComponents.find((el) => Number(el.value) === valueSelect),
    [valueSelect, optionsComponents],
  );

  const handleUpdateRequestService = async () => {
    const serviceSelect = servicesList.find(
      (el) => el.serviceId === valueSelect,
    );
    serviceSelect.count =
      serviceSelect.count < additionalSignals
        ? additionalSignals
        : serviceSelect.count;
    const errors = getErrros(serviceSelect?.service?.type);

    if (errors.length) {
      return openNotification(errors[0]);
    }

    const data = [{ id: requestServiceItemId, signals: formByService.signals }];
    const response = await updateRequesServiceInCreate({ data });

    localStorage.removeItem(`requestServicesList-${polizaId}-${service}`);
    localStorage.removeItem('polizaSearch');
    localStorage.removeItem('serviceSearch');
    dispatch({
      type: OPEN_FORM_REQUEST,
      payload: { action: 'delete', id: service.id },
    });
    dispatch({ type: CLEAR_FORM_REQUEST });

    if (response) {
      navigate('/servicio-de-prevencion?redirect=request');
      notification.success({
        description: 'Servicio de señalética actualizado',
        message: '¡Servicios Solicitados!',
      });
    } else {
      navigate('/servicio-de-prevencion?redirect=request');
      notification.warning({
        description: 'Error inesperado, inténtelo más tarde',
        message: '¡Servicios Solicitados!',
      });
    }
  };

  useEffect(() => {
    return () => {
      if (fromRequestService) {
        localStorage.removeItem(
          `requestServicesList-${polizaId}-${service.id}`,
        );
        localStorage.removeItem('polizaSearch');
        localStorage.removeItem('serviceSearch');
        dispatch({
          type: OPEN_FORM_REQUEST,
          payload: { action: 'delete', id: service.id },
        });
        dispatch({ type: CLEAR_FORM_REQUEST });
      }
    };
  }, []);

  return (
    <div className='flex flex-col'>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Text className='text-subtitle-bold'>
          Elige el servicio a solicitar
        </Text>
        <div className='flex items-center'>
          <Select
            style={{
              minWidth: '300px',
            }}
            optionLabelProp='title'
            value={valueSelect}
            className='mt-3 mb-2 mr-3'
            onChange={_handleChangeSelect}
            loading={loadingServices}
          >
            {optionsComponents.map((option) => (
              <Select.Option
                key={option.value}
                value={option.value}
                title={option.label}
              >
                {option.label}
              </Select.Option>
            ))}
          </Select>
          {String(optionSelect?.type) === '1' ? <InHouseCoursesModal /> : null}
        </div>
      </div>
      <Skeleton loading={loadingServices}>
        {(() => {
          switch (String(optionSelect?.type)) {
            case '3':
              return (
                <Signage
                  isMobile={isMobile}
                  maxSignals={maxSignals}
                  onUpdateForm={_handleUpdateFormService}
                  signals={formByService.signals}
                />
              );
            case '1':
              return (
                <ServiceInHouse
                  comment={formByService.comment}
                  companyId={companyId}
                  isAdminRimac={isAdminRimac}
                  onUpdateForm={_handleUpdateFormService}
                />
              );
            default:
              return (
                <CommentField
                  comment={formByService.comment}
                  onUpdateForm={_handleUpdateFormService}
                />
              );
          }
        })()}
      </Skeleton>
      <Divider className='mt-3' />
      {fromRequestService ? (
        <RideButton
          style={{
            maxWidth: '320px',
            alignSelf: 'center',
          }}
          onClick={handleUpdateRequestService}
          text='Actualizar Servicio'
          size='large'
          disabled={!valueSelect}
        />
      ) : (
        <RideButton
          style={{
            maxWidth: '320px',
            alignSelf: 'center',
          }}
          onClick={_handleUpdateForm(formByService)}
          text={action === 'add' ? 'Agregar' : 'Actualizar'}
          disabled={!valueSelect}
          size='large'
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateForm: (payload) => {
    dispatch({
      type: OPEN_FORM_REQUEST,
      payload: {
        data: payload,
        action: 'update',
      },
    });
  },
  onUpdateField: (payload) => {
    dispatch({ type: UPDATE_REQUEST_SERVICE_FIELD, payload });
  },
  updateStorageMain: (payload) => {
    dispatch({ type: UPDATE_FROM_STORAGE, payload });
  },
});

const mapStateToProps = (state) => ({
  requestServicesList: state.requestServiceForm.requestServicesList,
  fromRequestService: state.requestServiceForm.fromRequestService,
  additionalSignals: state.requestServiceForm.additionalSignals,
  requestServiceItemId: state.requestServiceForm.requestServiceItemId,
  polizaId: state.requestServiceForm?.polizaId,
  servicesList: state.requestServiceForm.servicesList,
  policies: state?.requestServiceForm?.policies || [],
  companyId: state.auth?.user?.company_id,
  isAdminRimac: ['admin_rimac', 'gestor_de_cuenta'].includes(
    state.auth.user?.rol?.slug,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(ServiceRequest);
