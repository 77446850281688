import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { message, Tooltip } from 'antd';

import { checkPermission } from '../../../lib/security';
import { isPhone } from '../../../lib/screen';
import { getEvolLink } from '../../../services/users';
import { SIDEMENU_CHANGE_VISIBLE } from '../../../redux/actions/actionsType';

import ChildrenMenu from './ChildrenMenu';
import IconsSiderMenu from './IconsSiderMenu';
import { pushDigitalData } from '../../../lib/utils';
import { TRACK } from '../../../lib/consts';

const throwLink = (href, blank = false) => {
  const a = document.createElement('a');
  if (blank) {
    a.setAttribute('target', '_blank');
  }

  a.href = href;
  a.click();
};

const SubNavigation = ({ user, items }) => {
  let location = useLocation();
  const dispatch = useDispatch();

  const verifyPermissions = (item) =>
    item.permission &&
    (checkPermission(user, item.permission) || !item.permission);

  const changeMenuVisible = (isOpen) =>
    dispatch({ type: SIDEMENU_CHANGE_VISIBLE, payload: isOpen });

  const isOpened = useSelector((state) => state.sideMenu.isOpened);

  const handleClick = (e, item) => {
    if (process.env.REACT_APP_ANALYTICS_ENABLE !== '0') {
      pushDigitalData(
        {
          action: {
            group: 'Centro Monitoreo',
            category: 'Menú Principal',
            name: item.parent?.toUpperCase(),
            label: item.name,
          },
        },
        TRACK.ACTION,
      );

      if (item.external) {
        e.preventDefault();
        if (item.key === 'virtual-courses') {
          getEvolLink()
            .then((data) => {
              const linkTo = `${data.url}?${data.parameters}`;
              throwLink(linkTo, item.blank);
            })
            .catch(() => {
              message.error('Ha ocurrido un error');
            });
        } else {
          throwLink(item.to, item.blank);
        }
      }
    }
    if (isPhone()) {
      changeMenuVisible(false);
    }
  };

  return items.map((item) => {
    if (verifyPermissions(item) || item?.children?.find(verifyPermissions)) {
      if (item.children) {
        return <ChildrenMenu key={item.key} item={item} user={user} />;
      }

      const navLinkContent = (
        <NavLink
          to={item.to ? item.to : '#'}
          onClick={(e) => handleClick(e, item)}
        >
          <label
            className={`menu-box ${
              location.pathname.startsWith(item.to) ? 'active' : ''
            }`}
          >
            {item.icon && (
              <IconsSiderMenu
                type={item.icon.split('-')[1]}
                isActive={location.pathname.startsWith(item.to)}
              />
            )}
            {isOpened ? <span>{item.name}</span> : null}
          </label>
        </NavLink>
      );

      let content;

      if (!verifyPermissions(item)) {
        content = <></>;
      } else if (isOpened) {
        content = <li key={item.key}>{navLinkContent}</li>;
      } else {
        content = (
          <Tooltip placement='right' title={item.name} key={item.key}>
            <li>{navLinkContent}</li>
          </Tooltip>
        );
      }

      return content;
    }

    return null;
  });
};

export default SubNavigation;
