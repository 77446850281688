import React, { useState } from 'react';
import { Checkbox, AutoComplete, Button } from 'antd';
import { IconClose } from '../Icons';

const MultipleSelect = ({
  filters = [],
  updateFilterColumns,
  clearOptions = null,
  className = '',
  style = {},
  placeholder = 'Buscar',
  name,
}) => {
  const [value, setValue] = useState('');
  const [visible, setVisible] = useState(false);

  return (
    <div>
      <AutoComplete
        className={`flex items-center less-input-border select-multiple ${className}`}
        style={{ minWidth: 230, ...style }}
        open={visible}
        placeholder={placeholder}
        onSearch={(val) => setValue(val)}
        filterOption={(inputValue, option) =>
          option?.label?.toUpperCase()?.indexOf(inputValue.toUpperCase()) !== -1
        }
        defaultValue={''}
        value={value}
        onDropdownVisibleChange={(visible) => {
          setVisible(visible);
        }}
        onSelect={() => {
          setValue('');
          setVisible(true);
        }}
        options={filters
          .filter((el) => el?.value)
          .map((item) => ({
            value: item.value,
            label: (
              <Checkbox
                name={item.value}
                onChange={(ev) => updateFilterColumns(name, ev.target.name)}
                checked={item.selected}
              >
                {item.label}
              </Checkbox>
            ),
          }))}
      />
      {clearOptions && (
        <Button
          className='absolute right-0 clear-multiple-select'
          style={{ zIndex: '10' }}
          size='small'
          type='link'
          onClick={() => clearOptions(name)}
        >
          <IconClose />
        </Button>
      )}
    </div>
  );
};

export default MultipleSelect;
