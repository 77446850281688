export const newsletterStates = {
  0: {
    text: 'Borrador',
    bgPoint: '#7981B2',
    bg: '#D7DBF5',
    caseTag: 'null',
  },
  1: {
    text: 'Publicado',
    bgPoint: '#389E0D',
    bg: '#A7EB92',
    caseTag: 'success',
  },
  2: {
    text: 'Inactivo',
    bgPoint: '#FC9700',
    bg: '#FCDA6A',
    caseTag: 'warning',
  },
  3: {
    text: 'Eliminado',
    bgPoint: '#FC9700',
    bg: '#FCDA6A',
    caseTag: 'warning',
  },
};
