import React, { useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { useScreen } from '../../../lib/screen';
import { getSignage } from '../../../services/signage';
import { getLastExecutedService } from '../../../services/requestServicesItems';
import useQueryParams from '../../../hooks/useQueryParams';
import {
  UPDATE_REQUEST_SERVICE_FIELD,
  UPDATE_FROM_STORAGE,
} from '../../../redux/actions/actionsType';

import Topbar from '../../../common/ui/Topbar';
import PrevRequestServicesList from './RequestServicesList';
import RequestSteps from './Form';
import ModalSurvey from './../../client/ui/PreventionService/ServiceDetail/Service/components/ModalSurvey';
import ModalFinishSurvey from './ModalFinishSurvey';

export const signalsKeys = [
  'fotoPloteadoBase',
  'stickerAutoadhesivas',
  'vinilImpresoBase',
];

const RequestServicesForm = ({
  openedForm,
  updateStorageMain,
  signals,
  setSignages,
  userSlug,
}) => {
  const { risks } = useQueryParams();
  const navigate = useNavigate();
  const [companySelected, setCompanySelected] = useState();
  const [lastSurvey, setLastSurvey] = useState(null);
  const [finishSurvey, setFinishSurvey] = useState(false);
  const { isMobileScreen: isMobile } = useScreen();

  const _handleBack = useCallback(() => {
    navigate(`/servicio-de-${risks ? 'riesgos' : 'prevencion'}`);
  }, [navigate, risks]);

  const _handleFinishSurvey = useCallback(() => {}, []);

  useEffect(() => {
    getSignage()
      .then((data) => {
        const result = data.reduce((acc, signal) => {
          const [code] = signal.code.split('-');
          const signalsFromStorage = signals ? signals[signal.code] : null;

          acc[code] = (acc[code] || []).concat({
            ...(signalsFromStorage || {}),
            ...signal,
          });

          return acc;
        }, {});
        setSignages(result);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (userSlug === 'administrador_cliente')
      getLastExecutedService().then((service) => {
        const currentDate = new Date();
        const sixMonthsAgo = new Date();
        sixMonthsAgo.setMonth(currentDate.getMonth() - 6);

        service = service?.requestServiceItems?.[0];
        const dateExecutionService =
          service?.request_service_item_results?.[0]?.createdAt;

        if (
          service &&
          !service.quiz &&
          Number(service?.service?.type) === 2 &&
          moment(dateExecutionService).isAfter(moment(sixMonthsAgo))
        )
          setLastSurvey(service);
      });
  }, [userSlug]);

  return (
    <div className='h100vh dashboard-UI'>
      <Topbar />
      {openedForm ? (
        <RequestSteps
          data={openedForm}
          updateStorageMain={updateStorageMain}
          isMobile={isMobile}
          companySelected={companySelected}
        />
      ) : null}
      <PrevRequestServicesList
        onBack={_handleBack}
        visible={openedForm}
        updateStorageMain={updateStorageMain}
        setCompanySelected={setCompanySelected}
        companySelected={companySelected}
      />
      {lastSurvey && (
        <div className='h100vh'>
          <ModalSurvey
            visible={Boolean(lastSurvey)}
            onFinishQuiz={_handleFinishSurvey}
            onCancel={() => setLastSurvey(null)}
            setFinishSurvey={setFinishSurvey}
            initStep={0}
            requestServiceItemId={lastSurvey?.id}
            requestServiceItemName={lastSurvey ? lastSurvey?.service?.name : ''}
            closable={false}
          />
        </div>
      )}
      {finishSurvey && (
        <ModalFinishSurvey
          visible={finishSurvey}
          onCancel={() => {
            setFinishSurvey(false);
          }}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateStorageMain: (payload) => {
    dispatch({ type: UPDATE_FROM_STORAGE, payload });
  },
  setSignages: (payload) => {
    dispatch({
      type: UPDATE_REQUEST_SERVICE_FIELD,
      payload: {
        signals: payload,
      },
    });
  },
});

const mapStateToProps = (state) => ({
  openedForm: state.requestServiceForm.openedForm,
  userSlug: state.auth.user?.rol?.slug || '',
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RequestServicesForm);
