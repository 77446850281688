import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import Button from '../../components/portal/components/Button';

const NavigationRed = ({
  user,
  searchText,
  results,
  inputRef,
  showSearcher,
  handlerShowSearcher,
  handlerSearchSuggestions,
  handlerSearch,
  clearSearch,
  handleLogOut,
}) => {
  const [showUserOptions, setShowUserOptions] = useState(false);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' || event.code === 'NumpadEnter') {
      handlerSearch();
    }
  };

  useEffect(() => {
    setShowUserOptions(false);
  }, [showSearcher]);

  return (
    <>
      {showSearcher && (
        <div className='navigation-portal' style={{ backgroundColor: '#FFF' }}>
          <div className='container'>
            <div className='navigation-portal__box'>
              <a href='/' className='logo'>
                <div className='logo__img'>
                  <img src='/images/logo.svg' alt='Logo Rimac' />
                </div>
                <hr className='logo__divider' />
                <span className='logo__text'>
                  Centro de Monitoreo y Prevención
                </span>
              </a>
            </div>
            <div
              className='navigation-portal__box'
              style={{ position: 'relative' }}
            >
              <div className='buttons buttons-searcher'>
                <div className='close' onClick={handlerShowSearcher}>
                  <div className='close__text'>Cerrar</div>
                  <div className='close__icon'>
                    <i className='fas fa-times'></i>
                  </div>
                </div>
                <Button
                  className='secondary medium'
                  style={{
                    display: 'inline-block',
                    maxWidth: '180px',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                  onClick={
                    user ? () => setShowUserOptions((s) => !s) : undefined
                  }
                  href={!user ? '/login' : undefined}
                  target='_blank'
                  prefix={<i className='fas fa-user'></i>}
                >
                  {user ? `${user.name} ${user.last_name1}` : 'Iniciar sesión'}
                </Button>
              </div>
              {showUserOptions && (
                <div className='user-options'>
                  <a
                    href='/monitoreo/mapas'
                    target='_blank'
                    className='user-options__item'
                  >
                    Ir a la plataforma
                  </a>
                  <a onClick={handleLogOut} className='user-options__item'>
                    Cerrar Sesión
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div
        className={`searcher-navigation ${
          showSearcher ? 'searcher-navigation--show' : ''
        }`}
      >
        <div className='searcher-content'>
          <div className='search-button-box'>
            <div className='search-button'>
              <div
                className='search-button__back'
                onClick={handlerShowSearcher}
              >
                <i className='fas fa-chevron-left'></i>
              </div>
              <input
                ref={inputRef}
                onKeyDown={handleKeyPress}
                className='search-button__input'
                placeholder='Ingresa tu búsqueda'
                value={searchText}
                onChange={(event) => {
                  handlerSearchSuggestions(event.target.value);
                }}
              />
              {searchText && (
                <div className='search-button__clear' onClick={clearSearch}>
                  <svg
                    width='25'
                    height='24'
                    viewBox='0 0 25 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M13.8721 12.0003L23.6083 2.26411C24.1189 1.75347 24.1189 0.902406 23.6083 0.391768C23.0977 -0.118871 22.2466 -0.118871 21.736 0.391768L11.9998 10.1279L2.26362 0.391768C1.75298 -0.118871 0.901918 -0.118871 0.39128 0.391768C-0.119359 0.902406 -0.119359 1.75347 0.39128 2.26411L10.1274 12.0003L0.39128 21.7364C-0.119359 22.2471 -0.119359 23.0981 0.39128 23.6088C0.66362 23.8811 1.00405 23.9833 1.34447 23.9833C1.6849 23.9833 2.02532 23.8471 2.29766 23.6088L11.9998 13.8726L21.736 23.6088C22.0083 23.8811 22.3487 23.9833 22.6892 23.9833C23.0296 23.9833 23.37 23.8471 23.6423 23.6088C24.153 23.0981 24.153 22.2471 23.6423 21.7364L13.8721 12.0003Z'
                      fill='#C5CBE0'
                    />
                  </svg>
                </div>
              )}
              <button className='search-button__icon' onClick={handlerSearch}>
                <img
                  src='/images/portal/icon_search.svg'
                  alt='Utilizar Buscador'
                />
              </button>
            </div>
          </div>
          {searchText && results ? (
            <div className='search-results-box'>
              <div className='search-results__title'>SUGERENCIAS</div>
              <div className='search-results'>
                {results
                  ? results.map(({ title, url }) => (
                      <a
                        key={uuidv4()}
                        className='search-result'
                        href={`/articulos/${url}`}
                      >
                        <div className='search-result__icon'>
                          <svg
                            width='12'
                            height='12'
                            viewBox='0 0 12 12'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M11.6129 10.0645L9.54839 7.87097C10.0645 7.09677 10.3226 6.19355 10.3226 5.16129C10.3226 2.32258 8 0 5.16129 0C2.32258 0 0 2.32258 0 5.16129C0 8 2.32258 10.3226 5.16129 10.3226C6.19355 10.3226 7.09677 10.0645 7.87097 9.54839L10.0645 11.7419C10.3226 11.871 10.5806 12 10.8387 12C11.0968 12 11.3548 11.871 11.6129 11.6129C12.129 11.2258 12.129 10.4516 11.6129 10.0645ZM5.16129 9.03226C2.96774 9.03226 1.29032 7.35484 1.29032 5.16129C1.29032 2.96774 2.96774 1.29032 5.16129 1.29032C7.35484 1.29032 9.03226 2.96774 9.03226 5.16129C9.03226 7.35484 7.35484 9.03226 5.16129 9.03226Z'
                              fill='#C5CBE0'
                            />
                          </svg>
                        </div>
                        <div
                          className='search-result__text'
                          dangerouslySetInnerHTML={{ __html: title }}
                        ></div>
                      </a>
                    ))
                  : null}
              </div>
            </div>
          ) : (
            <div className='search-cards'>
              <div className='search-cards__title'>Te puede interesar</div>
              <div className='search-cards__list'>
                <a href='/monitoreo' className='search-cards__card'>
                  <img
                    src='/images/portal/icon_satellite.svg'
                    className='search-cards__card__img'
                    alt='Monitoreo'
                  />
                  <div className='search-cards__card__txt'>
                    Plataforma de Monitoreo
                  </div>
                  <div className='search-cards__card__icon'>
                    <i className='fas fa-chevron-right'></i>
                  </div>
                </a>
                <a href='/prevencion' className='search-cards__card'>
                  <img
                    src='/images/portal/icon_home.svg'
                    className='search-cards__card__img'
                    alt='Prevención'
                  />
                  <div className='search-cards__card__txt'>
                    Prevención patrimonial
                  </div>
                  <div className='search-cards__card__icon'>
                    <i className='fas fa-chevron-right'></i>
                  </div>
                </a>
                <a href='/articulos' className='search-cards__card'>
                  <img
                    src='/images/portal/icon_documents.svg'
                    className='search-cards__card__img'
                    alt='Artículos'
                  />
                  <div className='search-cards__card__txt'>Artículos</div>
                  <div className='search-cards__card__icon'>
                    <i className='fas fa-chevron-right'></i>
                  </div>
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
      {showSearcher && (
        <div className='searcher-overlay' onClick={handlerShowSearcher}></div>
      )}
    </>
  );
};

export default NavigationRed;
