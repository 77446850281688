import React, { memo } from 'react';
import SelectPolizas from './SelectPolizas';

const RiskEvaluationInit = ({ onNext }) => {
  return (
    <div className='d_content rain-risk-container'>
      <div id='checkSize' className='flex flex-col text-body'>
        <h1 className='typography-title-5--bold-28 text-center'>
          Evaluación de Riesgo por Lluvia
        </h1>
        <p>
          La herramienta de evaluación de riesgo por lluvia te permitirá iniciar
          un diagnóstico de tu exposición y vulnerabilidad frente a este evento
          natural y sus consecuencias (desbordes, huaicos, inundaciones), la
          cual será complementada con una reunión de asesoría contigo y los
          especialistas en riesgos del Centro de Monitoreo y Prevención de
          Rimac.
        </p>
        <p>
          Deberás marcar en cada ITEM un SI o un NO según las condiciones que
          mejor representes a tus instalaciones o un NO APLICA. La casilla
          COMENTARIOS te permitirá aclarar o profundizar en la descripción de tu
          respuesta, esto será valioso para poder brindarte mejores
          recomendaciones.
        </p>
        <p>Los aspectos evaluados son los siguientes:</p>
        <ol className='list-decimal'>
          <li>
            <b>Recurrencia:</b> daños anteriores
          </li>
          <li>
            <b>Ubicación:</b> respecto a fuentes de riesgo
          </li>
          <li>
            <b>Edificación:</b> Condiciones generales de la instalación
          </li>
          <li>
            <b>Maquinarias </b>y sistemas eléctricos: Riesgo para equipos
            críticos
          </li>
          <li>
            <b>Existencias:</b> riesgo para insumos, materias primas y productos
            almacenados
          </li>
          <li>
            <b>Lucro cesante:</b> recuperación ante un posible daño
          </li>
          <li>
            <b>Protecciones:</b> Elementos que me permiten mitigar el daño o
            responder a un evento
          </li>
          <li>
            <b>Geología:</b> condiciones del entorno
          </li>
        </ol>
        <p>
          Esta evaluación es un valor agregado (no agrega o modifica condiciones
          en las pólizas de seguro), y tiene la finalidad de aportar
          recomendaciones que te ayuden a mitigar el impacto de las lluvias en
          tu región.
        </p>
        <p>
          Si tienes alguna duda sobre alguna de las preguntas comunícate con
          nuestras especialistas al correo centrodemonitoreo@rimac.com.pe; si no
          te es posible responder alguna de las preguntas, no te preocupes, en
          la reunión de asesoría se completarán conjuntamente.
        </p>
        <p>
          El informe final te será entregado luego de la reunión de asesoría con
          los especialistas del Centro de Monitoreo.
        </p>
        <SelectPolizas onNext={onNext} />
      </div>
    </div>
  );
};

export default memo(RiskEvaluationInit);
