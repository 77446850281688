import { Slider, Typography } from 'antd';

const { Text } = Typography;

const ScenariosOpacity = () => {
  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      onMouseDown={stopPropagation}
      onTouchStart={stopPropagation}
      onMouseMove={stopPropagation}
      onTouchMove={stopPropagation}
      style={{ position: 'absolute', bottom: 120, left: 10, zIndex: 1000 }}
    >
      <Text>Cambia la opacidad de la capa</Text>
      <Slider min={0} max={1} step={0.1} defaultValue={1} />
    </div>
  );
};

export default ScenariosOpacity;
