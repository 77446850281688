import React, { memo } from 'react';
import { Progress } from 'antd';
import { SyncOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { RideButton } from '@rimac-seguros/ride-system-components';

const BoxLoader = ({
  onClose = () => {},
  onCancel = () => {},
  title = '',
  description = null,
  className = '',
  percent = 0,
  successMessage = 'Se finalizó correctamente',
}) => (
  <div
    className={`flex flex-col rounded-2xl ${className}`}
    style={{
      background: '#fff',
      border: '1px solid #D7DBF5',
      padding: '20px 16px',
    }}
  >
    {percent < 100 ? (
      <>
        <div className='flex justify-between items-center'>
          <span style={{ fontSize: 14 }}>{title}</span>
          <RideButton
            variant='text-primary'
            onClick={onClose}
            text='Cancelar'
          />
        </div>
        <Progress
          percent={Number(percent)}
          showInfo={false}
          size={4}
          strokeColor='#FC9700'
        />
        <div>
          <span>{percent}% - </span>
          {description ? description : null}
        </div>
      </>
    ) : (
      <div className='flex items-center relative'>
        <SyncOutlined className='mr-2' />
        <span style={{ fontSize: 14 }}>{successMessage}</span>
        <CloseCircleOutlined
          className='absolute'
          style={{ right: 0, fontSize: 18, color: '#A9AFD9' }}
          onClick={onClose}
        />
      </div>
    )}
  </div>
);

export default memo(BoxLoader);
