import {
  LOADED_SLIDER,
  LOADING_CREATE_SLIDER,
  LOADING_SLIDER,
} from '../actions/actionsType';

const initialState = {
  list: [],
  openedForm: false,
  isLoadingList: false,
  isLoadingCreate: false,
};

const banner = (state, action) => {
  if (!state) {
    state = initialState;
  }

  switch (action.type) {
    case LOADING_SLIDER:
      return {
        ...state,
        isLoadingList: action.payload,
      };
    case LOADED_SLIDER:
      return {
        ...state,
        list: action.payload,
        isLoadingList: false,
      };
    case LOADING_CREATE_SLIDER:
      return {
        ...state,
        isLoadingCreate: action.payload,
      };
    default:
      return state;
  }
};

export default banner;
