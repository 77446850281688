import React from 'react';

const IconGoBack = ({ onClick }) => {
  return (
    <svg
      onClick={onClick}
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ cursor: 'pointer' }}
    >
      <circle cx='16' cy='16' r='15' stroke='#4F4FFF' strokeWidth='2' />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.8485 11.1513C18.3799 10.6826 17.6201 10.6826 17.1514 11.1513L13.1514 15.1513C12.6828 15.6199 12.6828 16.3797 13.1514 16.8483L17.1514 20.8483C17.6201 21.317 18.3799 21.317 18.8485 20.8483C19.3171 20.3797 19.3171 19.6199 18.8485 19.1513L15.697 15.9998L18.8485 12.8483C19.3171 12.3797 19.3171 11.6199 18.8485 11.1513Z'
        fill='#4F4FFF'
      />
    </svg>
  );
};

export default IconGoBack;
