import * as React from 'react';

const Monitoreo = (props) => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M8.41944 14.0111L7.90226 11.5627L15.2382 10.0002L15.7569 12.4455L8.41944 14.0111ZM16.7944 17.3471L16.2757 14.9018L8.93194 16.4643L9.45069 18.9096L16.7944 17.3471ZM22.7585 27.2549L25.0335 26.0752L26.1273 31.2502L25.1569 34.0627L29.0632 35.3127L28.3757 38.7502H2.50069C2.16917 38.7502 1.85123 38.6185 1.61681 38.3841C1.38239 38.1497 1.25069 37.8317 1.25069 37.5002V31.2502H5.68663L0.880381 8.58302C0.639638 7.44798 0.859636 6.26379 1.49198 5.29096C2.12433 4.31812 3.11722 3.63631 4.25225 3.39552L15.2585 1.06115C16.3935 0.82091 17.5775 1.04134 18.55 1.67397C19.5226 2.30659 20.204 3.2996 20.4444 4.43459L21.7413 10.5471L21.7054 10.4893L20.0382 12.0065L19.5835 12.4221L17.9991 4.95334C17.9103 4.53292 17.6798 4.15576 17.3462 3.88498C17.0125 3.6142 16.596 3.46623 16.1663 3.46584C16.035 3.46604 15.904 3.48018 15.7757 3.50802L4.771 5.84084C4.5301 5.89191 4.30161 5.98993 4.0986 6.12932C3.89558 6.2687 3.72201 6.44671 3.5878 6.65318C3.45358 6.85965 3.36136 7.09054 3.31638 7.33265C3.27141 7.57477 3.27458 7.82338 3.32569 8.06427L8.24132 31.2502H12.0757L10.7538 25.0174L18.0976 23.4549L19.7413 31.2502H23.3773L23.5663 31.2096L22.7304 27.269L22.7585 27.2549ZM17.1882 31.2502L16.1648 26.4252L13.7194 26.944L14.6319 31.2502H17.1882ZM33.8851 18.3893L33.6991 19.6799L35.4585 22.1627L35.796 22.6315L36.6288 16.8815C36.6471 16.7568 36.7139 16.6445 36.8148 16.569C36.8646 16.531 36.9215 16.5035 36.9821 16.4879C37.0428 16.4723 37.1059 16.4691 37.1679 16.4783L38.7304 16.7018L39.0835 14.2268L37.521 14.0033C36.9641 13.9243 36.3963 14.005 35.8835 14.2361L34.9929 16.0861L33.8851 18.3893ZM33.2601 24.9971L32.9476 24.8783L32.0101 31.308L29.5319 33.1252L32.5007 34.6877L30.2085 38.7502H37.5007C37.8322 38.7502 38.1502 38.6185 38.3846 38.3841C38.619 38.1497 38.7507 37.8317 38.7507 37.5002V31.2502H34.5476L35.3366 25.7986L33.2601 24.9971Z'
      fill='url(#paint0_linear_9056_7869)'
    />
    <path
      d='M28.9266 17.6219L33.3016 15.2781L31.2703 19.4969L33.9266 23.2469L29.8641 21.6844L29.3953 26.5281L26.1141 23.4031L21.8953 25.5906L23.9266 21.6844L20.1766 19.0281L24.8641 18.5594L26.4266 13.7156L28.9266 17.6219ZM19.9141 14.6531L23.7672 17.5766L23.8531 17.4984L21.3016 13.3906L19.9141 14.6531ZM29.4453 12.8047L29.4062 15.8266L29.5172 15.8625L31.2359 13.3734L29.4453 12.8047Z'
      fill='url(#paint1_linear_9056_7869)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_9056_7869'
        x1='0.785156'
        y1='0.966309'
        x2='20.8567'
        y2='46.7422'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.272135' stopColor='#34263B' />
        <stop offset='0.658079' stopColor='#13172C' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_9056_7869'
        x1='20.2806'
        y1='13.8817'
        x2='31.5853'
        y2='25.4245'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.35' stopColor='#F7052D' />
        <stop offset='0.85' stopColor='#CA5AFA' />
      </linearGradient>
    </defs>
  </svg>
);

export default Monitoreo;
