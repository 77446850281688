import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Select } from 'antd';
import { checkDocumentByUser } from '../../services/register';

const DatosEntidad = ({
  addData,
  setCurrentStep,
  user,
  handleRegister,
  maxStep,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [documentError, setDocumentError] = useState(false);

  useEffect(() => {
    const allValues = form.getFieldsValue();
    const hasDocumentType = !!allValues.document_type;
    const hasDocument = !!allValues.document && allValues.document.length >= 8;
    setIsFormValid(hasDocumentType && hasDocument && !documentError);
  }, [documentError, form]);

  const handleSave = async () => {
    setLoading(true);
    try {
      const values = await form.validateFields();
      if (values) {
        addData(values, (updatedData) => {
          if (user && user.username.endsWith('@rimac.com.pe')) {
            handleRegister(updatedData);
          } else {
            setCurrentStep(2);
          }
        });
      }
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    } finally {
      setLoading(false);
    }
  };

  const onFormValuesChange = (_, allValues) => {
    const hasDocumentType = !!allValues.document_type;
    const hasDocument = !!allValues.document && allValues.document.length >= 8;
    setIsFormValid(hasDocumentType && hasDocument && !documentError);
  };

  const validateDocument = async (_, value, type) => {
    if (!value) {
      setDocumentError(true);
      return Promise.reject(new Error('Este campo es obligatorio'));
    }
    if (value.length < 8) {
      setDocumentError(true);
      return Promise.reject(
        new Error('El documento debe tener mínimo 8 caracteres.'),
      );
    } else {
      const documentExist = await checkDocumentByUser(value, type);
      if (documentExist && documentExist.data.success) {
        setDocumentError(true);
        return Promise.reject(new Error('Documento ya registrado'));
      }
    }
    setDocumentError(false);
  };

  return (
    <div className='card_register_content form' style={{ marginTop: '8px' }}>
      <Form
        form={form}
        wrapperCol={{ span: 24 }}
        onFinish={handleSave}
        style={{ maxWidth: 320 }}
        onValuesChange={onFormValuesChange}
      >
        <Form.Item
          name='document'
          rules={[
            {
              validator: async (_, value) =>
                validateDocument(_, value, form.getFieldValue('document_type')),
            },
          ]}
        >
          <Input
            style={{ width: '100%' }}
            placeholder='Nro. Documento'
            onChange={(e) => {
              form.setFieldsValue({
                document: e.target.value.replace(/\D/g, ''),
              });
            }}
            addonBefore={
              <Form.Item
                name='document_type'
                noStyle
                rules={[
                  {
                    validator: async (_, value) =>
                      validateDocument(
                        _,
                        form.getFieldValue('document'),
                        value,
                      ),
                  },
                ]}
              >
                <Select placeholder='Selecciona tipo de documento'>
                  <Select.Option value='' disabled>
                    Selecciona tipo de documento
                  </Select.Option>
                  <Select.Option key={1} value={1}>
                    DNI
                  </Select.Option>
                  <Select.Option key={2} value={2}>
                    CE
                  </Select.Option>
                </Select>
              </Form.Item>
            }
          />
        </Form.Item>
        <Form.Item
          name='phone'
          initialValue={user && user.phone}
          getValueFromEvent={(e) => e.target.value.replace(/\D/g, '')}
        >
          <Input placeholder='Número de celular' />
        </Form.Item>
        <Form.Item style={{ paddingTop: '24px' }}>
          <Button
            type='primary'
            htmlType='submit'
            loading={loading}
            disabled={!isFormValid}
          >
            <b>{maxStep === 2 ? 'Guardar' : 'Continuar'}</b>
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default DatosEntidad;
