import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  getArticleByUrl,
  getPopularArticles,
} from '../../../services/articles';

import BreadCrumbSection from '../../portal/Article/BreadcrumbSection';
import Header from '../../portal/Article/Header';
import MainSection from '../../portal/Article/MainSection';
import Loader from '../../portal/Article/Loader';

const ArticlePreview = ({ queryValue }) => {
  let navigate = useNavigate();
  const [popularArticles, setPopularArticles] = useState([]);
  const [article, setArticle] = useState();

  useEffect(() => {
    getArticleByUrl(queryValue).then((article) => {
      if (!article) {
        navigate('/configuracion?configkey=articles-list');
      }

      setArticle(article);
    });
    getPopularArticles().then((data) => {
      setPopularArticles(data.list);
    });
  }, [queryValue, navigate]);

  return (
    <div className='portal-web'>
      <main className='portal-article mt-0'>
        {article ? (
          <>
            <BreadCrumbSection
              href={'/configuracion?configkey=articles-list'}
              title={`${article.title ?? ''} ${article.title_red ?? ''}`}
            />
            <Header
              title={`${article.title} ${article.title_red}`}
              subtitle={article.subtitle}
              category={article.category}
            />
            <MainSection article={article} popularArticles={popularArticles} />
          </>
        ) : (
          <Loader />
        )}
      </main>
    </div>
  );
};

export default ArticlePreview;
