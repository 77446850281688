import { useState, useEffect } from 'react';
import { Button, Form, notification } from 'antd';

import { postEmergencyPoints } from '../../../../services/emergencies';
import { useScreen } from '../../../../lib/screen';

import ModalForm from './ModalForm';
import ModalConfirm from '../../../../common/ModalConfirm';
import MainTitle from '../common/MainTitle';
import DeleteModalImage from '../../../../images/delete_modal_image.svg';
import EmergencyTable from './EmergencyTable';

const Emergencies = ({ renderItem, queryAction }) => {
  const [form] = Form.useForm();
  const [pagination, setPagination] = useState(() => ({
    pageSize: 5,
    total: 0,
    current: 1,
  }));
  const [openForm, setOpenForm] = useState(false);
  const [uploadPoints, setUploadPoints] = useState(false);
  const [rows, setRows] = useState([]);
  const { isMobileScreen } = useScreen();

  const handleTableChange = (current) => {
    setPagination((prev) => ({ ...prev, current }));
  };

  const handleSendPoints = () => {
    form.validateFields().then((values) => {
      if (!values) {
        return notification.warning({
          description: 'Ha ocurrido un error.',
          message: 'Cargar puntos',
        });
      }

      values.emergency = Object.values(values.emergency)?.filter(Boolean);

      postEmergencyPoints(
        values.emergency.map((el) => ({
          ...el,
          source: 'manual',
          state: 1,
        })),
      )
        .then((created) => {
          if (!created) {
            throw Error('Error');
          }

          notification.success({
            description: 'Se cargo los puntos correctamente.',
            message: '¡Puntos cargados!',
          });
        })
        .catch(() => {
          notification.warning({
            description: 'Ha ocurrido un error.',
            message: 'Cargar puntos',
          });
        })
        .finally(() => {
          setOpenForm(false);
          setUploadPoints(false);
        });
    });
  };

  useEffect(() => {
    if (rows.length > 0) {
      setPagination({ ...pagination, current: 1, total: rows.length });
    }
  }, [rows]);

  useEffect(() => {
    queryAction && setOpenForm(true);
  }, [queryAction]);

  return (
    <>
      <MainTitle
        buttonAction={() => setOpenForm(true)}
        renderItem={renderItem}
        isMobileScreen={isMobileScreen}
      />
      <EmergencyTable
        rows={rows}
        pagination={{
          ...pagination,
          onChange: handleTableChange,
        }}
        form={form}
        setUploadPoints={setUploadPoints}
      />

      <ModalForm
        visible={openForm}
        handleClose={() => {
          setOpenForm(false);
        }}
        setRows={setRows}
      />
      <ModalConfirm
        visible={uploadPoints}
        onClose={() => setUploadPoints(false)}
        title='¿Seguro que desea enviar la data?'
        image={DeleteModalImage}
        actionsContent={
          <div className='actions-delete-modal mt-2'>
            <Button
              type='primary'
              size='large'
              className='pl-4 pr-4'
              onClick={handleSendPoints}
            >
              Enviar
            </Button>
          </div>
        }
      />
    </>
  );
};

export default Emergencies;
