export const getExternalVariables = (layers, lableGroup = '') => {
  const res = layers.map((layer) =>
    !layer?.mode
      ? {
          key: layer.id,
          text: lableGroup.concat(layer.name),
          selected: false,
          isMap: true,
          to: layer.type === 'layer' ? 'monitoreo/mapa' : 'monitoreo/mapas',
        }
      : getExternalVariables(layer.mode, layer.name.concat(' - ')),
  );

  return res.flat(Infinity);
};

export const adornText = (string, query) => {
  const regex = new RegExp('(' + query + ')', 'i');

  return string.replace(regex, '<b>$1</b>');
};

export const getAllPages = (pages, list = [], lableGroup = '') => {
  pages.forEach((p) => {
    if (p.key !== 'map' && p.to !== null && p.to !== undefined)
      list.push({
        to: p.to,
        name: p.name,
        label: lableGroup.concat(p.name),
      });

    if (p.children !== null && p.children !== undefined && p.key !== 'map')
      return getAllPages(p.children, list, p.name.concat(' > '));

    if (p.key === 'map')
      list.push({
        to: '/monitoreo/mapa',
        name: 'Variables Externas',
        label: lableGroup.concat('Variables Externas'),
      });
  });

  return list.flat(Infinity);
};
