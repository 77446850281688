export const onPlayAction = (isPlaying, setIsPlaying, setIsLoadingScenes) => {
  if (isPlaying === null) {
    setIsLoadingScenes(true);
  }
  setIsPlaying((prev) => !prev);
};

export const changeMoveOfMap = (enabled, map) => {
  if (enabled) {
    map.dragging.enable();
  } else {
    map.dragging.disable();
  }
};

export const onChangeBar = (
  val,
  isPlaying,
  intervalControl,
  setSelectedSceneBar,
  setIsPlaying,
  map,
) => {
  if (isPlaying && intervalControl.current) {
    setIsPlaying(false);
  }

  changeMoveOfMap(false, map);
  setSelectedSceneBar(val);
};

export const onAfterChangeBar = (
  val,
  isPlaying,
  intervalControl,
  setSelectedSceneBar,
  setIsPlaying,
  map,
) => {
  if (isPlaying && intervalControl.current) {
    setIsPlaying(false);
  }

  changeMoveOfMap(true, map);
  setSelectedSceneBar(val);
};

export const handleSelectHour = (
  hour,
  setSelectedHour,
  scenesLayers,
  map,
  resetAnimation,
) => {
  setSelectedHour(hour);
  Object.values(scenesLayers).forEach((l) => {
    map.removeLayer(l.layer);
    l.layer.removeFrom(map);
  });
  resetAnimation();
};
