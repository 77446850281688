import React from 'react';

const Button = ({
  className,
  children,
  href,
  prefix,
  suffix,
  target,
  ...props
}) => {
  return (
    <a className='button-portal' href={href ?? undefined} target={target}>
      <button className={`button ${className ?? ''}`} {...props}>
        {prefix ? <div className='prefix'>{prefix}</div> : null}
        {children}
        {suffix ? <div className='suffix'>{suffix}</div> : null}
      </button>
    </a>
  );
};

export default Button;
