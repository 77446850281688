import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getDataPointLeaflet } from '../../../lib/apiClient';
import { COMMON_SET_POSITION_LATLNG } from '../../../redux/actions/actionsType';
import PopupLayer from '../../../common/PopupLayer';

const PopupSearchResult = () => {
  const dispatch = useDispatch();
  const activeUtilsLayer = useSelector((state) => state.maps.activeUtilsLayer);
  const isCumulusLayerActive = Boolean(
    activeUtilsLayer.find((utilLayer) => utilLayer.key === 'cumulo'),
  );
  const { activeLayer, activeSubLayer } = useSelector((state) => state.maps);
  const position = useSelector((state) => state.common.positionLatLng);
  const [dataLayer, setDataLayer] = useState(null);

  useEffect(() => {
    if (position) {
      getDataPointLeaflet(position, activeSubLayer).then((data) => {
        setDataLayer(data?.features?.[0]?.properties?.GRAY_INDEX);
      });
    }
  }, [position]);

  useEffect(() => {
    return () => dispatch({ type: COMMON_SET_POSITION_LATLNG, payload: null });
  }, []);

  return (
    <PopupLayer position={position}>
      <PopupLayer.Header
        dataLayer={dataLayer}
        activeLayer={activeLayer}
        activeSubLayer={activeSubLayer}
      />
      <PopupLayer.Position />
      <PopupLayer.Footer isCumulusLayerActive={isCumulusLayerActive} />
    </PopupLayer>
  );
};

export default PopupSearchResult;
