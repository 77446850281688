import {
  GEOLOGICAL_HAZARDS_SET_POINTS,
  GEOLOGICAL_HAZARDS_UPDATE_FILTERS,
} from '../actions/actionsType';

export const geologyTypes = [
  {
    type: 'drop',
    name: 'Caida',
    nameToShow: 'Caída de rocas',
    tooltipText:
      'Caída de varios bloques roca que se desprenden de una pendiente.',
    color: '#F0544F',
  },
  {
    type: 'displacement',
    name: 'Deslizamiento',
    tooltipText: 'Movimiento pendiente abajo de una masa de suelo o roca.',
    color: '#0FFCC5',
  },
  {
    type: 'flow',
    name: 'Flujo',
    nameToShow: 'Flujo o Huaico',
    tooltipText:
      'Mezcla rocas, tierra, escombros y agua que se desplaza a lo largo de un canal o cauce con pendiente pronunciada.',
    color: '#F3C178',
  },
  {
    type: 'reptation',
    name: 'Reptacion',
    nameToShow: 'Reptación',
    tooltipText:
      'Movimiento lento y gradual de capas de suelo o terreno que suelen ocurrir en zonas inclinadas.',
    color: '#730000',
  },
];

const initialState = {
  types: geologyTypes,
  filters: {},
  points: [],
};

const geologicalHazards = (state, action) => {
  if (!state) state = initialState;

  switch (action.type) {
    case GEOLOGICAL_HAZARDS_UPDATE_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case GEOLOGICAL_HAZARDS_SET_POINTS:
      return {
        ...state,
        points: action.payload,
      };
    default:
      return state;
  }
};

export default geologicalHazards;
