import React from 'react';
import { Divider, Modal, Typography } from 'antd';
import moment from 'moment';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import { useScreen } from '../../../lib/screen';
import ListSignals from '../RequestServices/ListSignals';
import ListCourses from '../RequestServices/TrainingHomeService/ListCourses';
import TechnicalReportView from './TechnicalReportView';
import Title from '../../../common/Typograph/Title';

const { Text } = Typography;

const ModalViewDetails = ({ visible, setVisible, item }) => {
  const { isMobileScreen } = useScreen();

  const handleSave = () => setVisible(false);

  const handleCancel = () => setVisible(false);

  const getPolizaValidity = (item) => {
    const fmt = 'DD-MM-YYYY';
    const {
      poliza: { start_date, validity },
    } = item.requestService;

    return [
      <span key={uuidv4()}>Desde: {moment(start_date).format(fmt)}</span>,
      <br key={uuidv4()} />,
      <span key={uuidv4()}>
        Hasta: {moment(start_date).add('months', validity).format(fmt)}
      </span>,
    ];
  };

  const polizaStillValid = (item) => {
    const {
      poliza: { start_date, validity },
    } = item.requestService;
    const now = moment();
    const to = moment(start_date).add('months', validity);

    return now.isBefore(to) || now.isSame(to);
  };

  const getManagerAccount = (item) => {
    const [user] = item.requestService.company.companiesUsers;
    const name = `${user.name} ${user.last_name1} ${user.last_name2}`;

    return [
      <span key={uuidv4()}>{name}</span>,
      <br key={uuidv4()} />,
      <span key={uuidv4()}>{user.username}</span>,
    ];
  };

  return (
    <Modal
      title=''
      onOk={handleSave}
      onCancel={handleCancel}
      open={visible}
      okText='Aceptar'
      cancelText='Cancelar'
      destroyOnClose
      className={`modal-provider modal-centered ${
        isMobileScreen ? 'modal-mobile' : ''
      }`}
      maskClosable={false}
    >
      <Title type='bold-28' style={{ textAlign: 'center' }}>
        Detalles
      </Title>
      <Divider className='mt-2 mb-3' />
      <div className='flex request_service_items_details'>
        <div className='flex-1'>
          <label>Contacto</label>
          <p>{item?.contact && item.contact}</p>
          <label>Número</label>
          <p>{item?.number && item.number}</p>
          <label>Dirección</label>
          <p>{item?.address && item.address}</p>
          {item?.email && (
            <>
              <label>Email</label>
              <p>{item.email}</p>
            </>
          )}
          {item?.position && (
            <>
              <label>Cargo</label>
              <p>{item.position}</p>
            </>
          )}
          {item?.comment && (
            <div className='flex'>
              <label>
                Observación
                <p>
                  <Text code>{item.comment}</Text>
                </p>
              </label>
            </div>
          )}
          {item?.requestService?.poliza && (
            <>
              <label>
                Vigencia
                {polizaStillValid(item) ? (
                  <span className='poliza-still-vigente'>Vigente</span>
                ) : (
                  <span className='poliza-still-no-vigente'>No Vigente</span>
                )}
              </label>
              <p>{getPolizaValidity(item)}</p>
            </>
          )}
          {item?.requestService?.company?.companiesUsers?.length && (
            <>
              <label>Gestor de Cuenta</label>
              <p>{getManagerAccount(item)}</p>
            </>
          )}
        </div>
        <div className='flex-1'>
          {item?.requestServiceItemsData?.length > 0 &&
            item.requestServiceItemsData.map(
              (itemData) =>
                itemData.key === 'event_date' && (
                  <React.Fragment key={uuidv4()}>
                    <label>Fecha agendada</label>
                    <p>
                      {moment.unix(itemData.value).format('DD-MM-YY HH:mm')}
                    </p>
                  </React.Fragment>
                ),
            )}
          {item?.requestServiceItemsData?.length > 0 &&
            item.requestServiceItemsData.map((itemData) =>
              itemData.key === 'signals' ? (
                <ListSignals key={uuidv4()} itemData={itemData} />
              ) : (
                ''
              ),
            )}
          {item?.requestServiceItemsData?.length > 0 &&
            item.requestServiceItemsData.map((itemData) =>
              itemData.key === 'courses' ? (
                <ListCourses
                  key={`${item.id}-${itemData.id}`}
                  itemData={itemData}
                  item={item}
                  canAddHours={item?.derived}
                  onCourseUpdated={handleCancel}
                  requestFinished={item?.state === 6}
                  polizaId={_.get(item, 'requestService.poliza.id')}
                  requestServiceId={_.get(item, 'requestService.id')}
                  polizaServices={_.get(
                    item,
                    'requestService.poliza.levelCompany.levelCompanyServices',
                  )}
                />
              ) : (
                ''
              ),
            )}
        </div>
      </div>
      {item?.request_service_item_results?.map((result, idx) => (
        <TechnicalReportView
          key={idx}
          report={result}
          serviceType={parseInt(item.service?.type)}
          requestServiceItemsData={item.requestServiceItemsData}
        />
      ))}
    </Modal>
  );
};

export default ModalViewDetails;
