import React, { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Button,
  Card,
  Divider,
  Modal,
  Skeleton,
  TimePicker,
  Typography,
} from 'antd';
import { LeftCircleOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { DatePicker } from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';
import moment from 'moment';

import { useScreen } from '../../../../lib/screen';
import {
  getClientById,
  updateClient,
} from '../../../../services/clientTracking';

import Topbar from '../../../../common/ui/Topbar';
import SimpleTable from '../../../../common/Table/SimpleTable';
import CardUserMobile from '../common/CardUserMobile';
import { columnsDetails } from './columns';
import { RideButton } from '@rimac-seguros/ride-system-components';

const { Title } = Typography;

const ClientDetails = () => {
  const { isMobileScreen } = useScreen();
  const navigate = useNavigate();
  const location = useLocation();
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showEmailContent, setShowEmailContent] = useState('');
  const [date, setDate] = useState(null);
  const [hours, setHours] = useState(null);
  const id = location.pathname.split('/').at(-1);

  const handleSave = () => {
    const meeting_date = moment(date).set({
      hour: hours.get('hour'),
      minute: hours.get('minute'),
      second: 0,
      millisecond: 0,
    });
    updateClient(id, {
      meeting_date,
    });
    setShowModal(false);
  };

  const handleShowEmailContent = (content) => {
    setShowEmailContent(content);
  };

  useEffect(() => {
    setIsLoading(true);
    getClientById(id)
      .then((list) => setList(list))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <div id='checkSize' className='h100vh dashboard-UI'>
      <Topbar />
      <div className='d_content'>
        <div className=''>
          <div className='justify-between mb-3 pb-3'>
            {isLoading ? (
              <Skeleton active paragraph={false} title={{ width: '100%' }} />
            ) : (
              <div className='flex justify-between'>
                <div className='flex items-center gap-2'>
                  <LeftCircleOutlined
                    onClick={() =>
                      navigate('/administracion/seguimiento-a-clientes')
                    }
                    size={24}
                  />
                  <Title className='header-container'>Lista de correos</Title>
                </div>
                <RideButton
                  onClick={() => setShowModal(true)}
                  size='medium'
                  text='Registrar respuesta'
                  variant='fill'
                />
              </div>
            )}
          </div>
          <div>
            {!isMobileScreen ? (
              <SimpleTable
                className={'mt-4 mb-4'}
                loading={isLoading}
                columns={columnsDetails}
                dataSource={
                  list
                    ? list.map((item) => ({
                        ...item,
                        onShowEmail: handleShowEmailContent,
                      }))
                    : []
                }
              />
            ) : (
              <Fragment>
                <Divider />
                {isLoading ? (
                  [1, 2, 3].map((i) => (
                    <Card className='mt-2' key={i}>
                      <Skeleton />
                    </Card>
                  ))
                ) : (
                  <>
                    {list.map((value) => (
                      <CardUserMobile
                        key={value.id}
                        columns={columnsDetails}
                        item={value}
                      />
                    ))}
                  </>
                )}
              </Fragment>
            )}
          </div>
        </div>
      </div>
      <Modal
        open={Boolean(showEmailContent)}
        title=''
        closable={true}
        onCancel={() => setShowEmailContent('')}
        centered={true}
        styles={{
          body: isMobileScreen
            ? { width: '100%' }
            : { padding: '64px 64px 12px 64px', minWidth: 528 },
        }}
        className={`modal-provider modal-centered ${
          isMobileScreen ? 'modal-mobile' : ''
        }`}
        cancelText='Cerrar'
        okButtonProps={{ style: { display: 'none' } }}
      >
        <div>
          <Title type='bold-28' style={{ textAlign: 'center' }}>
            {'Registrar respuesta del cliente'}
          </Title>
          <Divider className='mt-2 mb-1' />
          <div dangerouslySetInnerHTML={{ __html: showEmailContent }} />
        </div>
      </Modal>
      <Modal
        open={showModal}
        title=''
        closable={true}
        onCancel={() => setShowModal(false)}
        onOk={handleSave}
        centered={true}
        styles={{
          body: isMobileScreen
            ? { width: '100%' }
            : { padding: '64px 64px 12px 64px', minWidth: 528 },
        }}
        className={`modal-provider modal-centered ${
          isMobileScreen ? 'modal-mobile' : ''
        }`}
        cancelText='Cancelar'
        okText={'Guardar'}
      >
        <div>
          <Title type='bold-28' style={{ textAlign: 'center' }}>
            {'Registrar respuesta del cliente'}
          </Title>
          <Divider className='mt-2 mb-1' />
          <DatePicker
            locale={locale}
            label={'Fecha de la reunión'}
            placeholder={'Fecha de la reunión'}
            onChange={(date) => setDate(date)}
            className={'full-width mb-2 mt-2'}
            format='DD/MM/YYYY'
          />
          <TimePicker
            className='full-width'
            placeholder='Seleccionar hora'
            format='HH:mm'
            onChange={(date) => setHours(date)}
            getPopupContainer={(e) => e}
            use12Hours
            locale={locale}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ClientDetails;
