import { Tabs as TabsAntd } from 'antd';
import PropTypes from 'prop-types';

const Tabs = ({
  onChange,
  defaultActiveKey,
  activeKey,
  items,
  destroyInactiveTabPane = false,
  ...props
}) => {
  return (
    <TabsAntd
      onChange={onChange}
      defaultActiveKey={defaultActiveKey}
      activeKey={activeKey}
      items={items}
      rootClassName='tabs'
      destroyInactiveTabPane
      {...props}
    />
  );
};

Tabs.propTypes = {
  onChange: PropTypes.func,
  defaultActiveKey: PropTypes.string,
  activeKey: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
  props: PropTypes.object,
};

export default Tabs;
