import { useState, useEffect } from 'react';
import { Skeleton, Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import { checkPermission } from '../../../../../lib/security';
import {
  getServiceDetailItemsById,
  downloadRiskRainServiceReport,
} from '../../../../../services/requestServices';
import {
  statusAdminColors,
  statusClientColors,
} from '../ServiceRequest/components/ServiceRequestList';
import { LOADED_REQUEST_SERVICE_ITEMS_DATA_PARTICIPANTS } from '../../../../../redux/actions/actionsType';

import Topbar from '../../../../../common/ui/Topbar';
import Service from './Service';
import HouseDetail from './House';
import UpdateList from './Participants/components/UpdateList';
import ModalFinish from './Participants/components/ModalFinish';
import IconGoBack from '../components/IconGoBack';

const ServiceDetail = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [courseSelected, setCourseSelected] = useState(null);
  const [selected, setSelected] = useState(null);
  const [loadingData, setLoadingData] = useState(true);
  const navigate = useNavigate();
  const { id, itemId } = useParams();
  const [openModal, setOpenModal] = useState(false);
  const [finish, setFinish] = useState(false);
  const [finishQuiz, setFinishQuiz] = useState(false);

  const setBadge = (state) => {
    const { color, status, dot } = ['admin_rimac', 'gestor_de_cuenta'].includes(
      user?.rol?.slug,
    )
      ? statusAdminColors(state)
      : statusClientColors(state);

    return (
      <div
        className='service-detail__header__badge'
        style={{ background: color }}
      >
        <div
          className='service-detail__header__badge__ico'
          style={{ background: dot }}
        ></div>
        <div className='service-detail__header__badge__name'>{status}</div>
      </div>
    );
  };

  const handleModal = () => setOpenModal((prev) => !prev);

  useEffect(() => {
    const fetchData = async () => {
      setLoadingData(true);
      if (id)
        try {
          const item = await getServiceDetailItemsById(itemId);

          if (item) setSelected(item);
          else navigate('/servicio-de-prevencion');
        } catch (error) {
          console.error(error);
        }
      else navigate('/servicio-de-prevencion');

      setLoadingData(false);
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    if (finish || finishQuiz)
      getServiceDetailItemsById(itemId).then((item) => {
        setSelected(item);
      });
  }, [finish, finishQuiz]);

  useEffect(() => {
    dispatch({
      type: LOADED_REQUEST_SERVICE_ITEMS_DATA_PARTICIPANTS,
      payload: [],
    });
  }, []);

  const getReport = () => {
    downloadRiskRainServiceReport(selected?.requestService?.poliza.id).then(
      (url) => {
        const a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      },
    );
  };

  return (
    <>
      <div id='checkSize' className='h100vh dashboard-UI '>
        <Topbar />
        <div className='service-detail'>
          {courseSelected ? (
            <HouseDetail
              selected={selected}
              itemId={itemId}
              courseSelected={courseSelected}
              setCourseSelected={setCourseSelected}
              setOpenModal={setOpenModal}
            />
          ) : (
            <>
              <div className='service-detail__header mb-3 pb-3'>
                {loadingData ? (
                  <Skeleton
                    active
                    paragraph={false}
                    title={{ width: '100%' }}
                  />
                ) : (
                  <>
                    <div className='service-detail__header__content'>
                      <IconGoBack
                        onClick={() => navigate('/servicio-de-prevencion')}
                      />
                      <div className='service-detail__header__title'>
                        {selected?.service?.name || 'Servicio sin nombre'}
                      </div>
                      {setBadge(selected?.state)}
                    </div>
                    {selected?.service.type === '6' &&
                      selected.state > 1 &&
                      checkPermission(user, 'show_all_request_services') && (
                        <Button
                          type='ghost'
                          className='link'
                          size='large'
                          icon={<DownloadOutlined />}
                          onClick={getReport}
                        >
                          Descargar Reporte
                        </Button>
                      )}
                  </>
                )}
              </div>
              <Service
                selected={selected}
                onFinishQuiz={setFinishQuiz}
                loadingData={loadingData}
                setCourseSelected={setCourseSelected}
              />
            </>
          )}
        </div>
      </div>

      {openModal && (
        <UpdateList
          visible={openModal}
          onCancel={handleModal}
          setFinish={setFinish}
          selected={selected}
        />
      )}

      {finish && <ModalFinish visible={finish} onCancel={setFinish} />}
    </>
  );
};

export default ServiceDetail;
