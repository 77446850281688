import React, { useState, useEffect } from 'react';
import { Card, Button, Badge } from 'antd';
import { UpOutlined, DownOutlined } from '@ant-design/icons';

const CardCollapse = ({
  dataLocation = [],
  type = '',
  children = null,
  title = '',
}) => {
  const [titleState, setTitleState] = useState({});
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (dataLocation) {
      const updatedTitleState = dataLocation.find((el) => el.type === type);
      setTitleState(updatedTitleState);
    }
  }, [dataLocation, type]);

  return (
    <Card
      styles={{
        body: { display: 'flex', flexDirection: 'column', width: '100%' },
      }}
    >
      <header
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h3 style={{ margin: '0' }}>{title}</h3>
        <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
          <Badge
            count={titleState?.state ? 'Configurado' : 'No configurado'}
            style={
              titleState?.state
                ? { backgroundColor: '#52c41a' }
                : { backgroundColor: '#f5222d' }
            }
          />
          <Button
            type='text'
            icon={isExpanded ? <UpOutlined /> : <DownOutlined />}
            onClick={() => setIsExpanded((prev) => !prev)}
          />
        </div>
      </header>
      <section style={{ width: '100%' }}>{isExpanded && children}</section>
    </Card>
  );
};

export default CardCollapse;
