import React, { memo, useState, useEffect, useCallback, useRef } from 'react';
import { Checkbox, Card } from 'antd';
import { getColumns, InputInsideTable } from './columns';

const RowTable = memo(
  ({
    name,
    id,
    withFotolum,
    path,
    isChecked: isCheckedInitial,
    maxSignals,
    onChangeRow,
    changesRef,
    rowValues = {},
  }) => {
    const [isChecked, setChecked] = useState(isCheckedInitial);
    const [rowInputValues, setInputsValues] = useState({});

    const _handleChangeInputs = useCallback(
      ({ target: { value, name } }) => {
        changesRef.current = true;
        setInputsValues((prev) => {
          const res = {
            ...prev,
            [name]: value,
          };
          onChangeRow(res);

          return res;
        });
      },
      [changesRef, onChangeRow],
    );

    const cols = getColumns(withFotolum).filter((el) => el.type === 'input');

    useEffect(() => {
      setInputsValues(
        cols.reduce((acc, { dataIndex }) => {
          acc[dataIndex] = rowValues[dataIndex];

          return acc;
        }, {}),
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const _handleChangeChecked = useCallback(() => {
      setChecked((prev) => {
        const result = !prev;
        if (!result) setInputsValues({});

        return result;
      });
    }, []);

    return (
      <tr style={{ borderBottom: '1px solid #e8e8e8' }}>
        <td style={{ padding: 16 }}>
          <Checkbox checked={isChecked} onChange={_handleChangeChecked} />
        </td>
        <td style={{ padding: 16 }}>
          <img
            width='100%'
            src={`${process.env.REACT_APP_WEB_PATH_S3_NEW}${path}`}
            alt='Señalética'
          />
        </td>
        <td style={{ padding: 16 }}>{name}</td>
        {cols.reduce(
          (acc, { dataIndex }) =>
            acc.concat(
              <td style={{ padding: 16 }} key={`${id}-${dataIndex}`}>
                <InputInsideTable
                  value={rowInputValues[dataIndex]}
                  dataIndex={dataIndex}
                  error={rowInputValues[dataIndex] > Number(maxSignals)}
                  key={dataIndex}
                  className={isChecked ? 'block' : 'hidden'}
                  onChangeInput={_handleChangeInputs}
                />
              </td>,
            ),
          [],
        )}
      </tr>
    );
  },
);

const SignageType = ({
  listSignals,
  onUpdateForm,
  signals,
  withFotolum,
  maxSignals,
}) => {
  const changesRef = useRef();
  const [signalsTable, setSignalsTable] = useState(() => signals ?? {});

  useEffect(() => {
    if (changesRef.current) {
      onUpdateForm({ signals: signalsTable });
      changesRef.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changesRef.current]);

  const _handleChangeRows = useCallback((code, value) => {
    setSignalsTable((prev) => ({
      ...prev,
      [code]: value,
    }));
  }, []);

  return (
    <Card
      className='mt-2 rounded-xl'
      styles={{
        body: {
          padding: 0,
          maxHeight: 540,
          overflow: 'auto',
          borderRadius: 12,
        },
      }}
    >
      <table className='full-width'>
        <thead className='pt-1'>
          <tr>
            {getColumns(withFotolum).map(({ title, ...props }) => (
              <th
                key={title}
                style={{
                  position: 'sticky',
                  top: 0,
                  zIndex: 2,
                  backgroundColor: '#f0f2f5',
                }}
                {...props}
              >
                {typeof title === 'string' ? title : title()}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {listSignals.map((row) => (
            <RowTable
              {...row}
              key={row.id}
              withFotolum={withFotolum}
              changesRef={changesRef}
              rowValues={signals[row.code]}
              onChangeRow={(value) => {
                _handleChangeRows(row.code, value);
              }}
              isChecked={Object.values(signals[row.code] ?? {}).length > 0}
              maxSignals={maxSignals}
            />
          ))}
        </tbody>
      </table>
    </Card>
  );
};

export default SignageType;
