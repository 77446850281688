import React, { useEffect } from 'react';
import { Form, Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

import { useDispatch, useSelector } from 'react-redux';
import { UPDATE_FORM_PERSONAL_DATA_VALUES } from '../../../redux/actions/actionsType';
import { createRiskRainService } from '../../../services/requestServices';

import InputWithLabel from '../../../common/InputWithLabel';

const keyForm = 'personalDataForm';

const formLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 24 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 24 } },
};

const PersonalDataForm = ({ onBack, onNext }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { polizaId, personalDataForm, user } = useSelector((store) => ({
    personalDataForm: store.rainForm.personalDataForm,
    polizaId: store.rainForm.polizaId,
    user: store.auth.user,
  }));

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      dispatch({ type: UPDATE_FORM_PERSONAL_DATA_VALUES, payload: values });
      createRiskRainService({ key: keyForm, value: values }, polizaId).then(
        () => {
          onNext();
        },
      );
    } catch (errorInfo) {
      console.error('Failed:', errorInfo);
    }
  };

  const _handleBack = () => {
    onBack();
  };

  useEffect(() => {
    form.setFieldsValue(
      !personalDataForm
        ? {
            contact: `${user?.name} ${user?.last_name1} ${user?.last_name2}`,
            email: user?.username,
            position: user?.rol.name,
            address: user?.address,
            number: user?.phone,
          }
        : {
            contact: personalDataForm?.contact,
            email: personalDataForm?.email,
            position: personalDataForm?.position,
            address: personalDataForm?.address,
            number: personalDataForm?.number,
          },
    );
  }, []);

  return (
    <div className='d_content personal-data-container'>
      <div id='checkSize' className='flex flex-col text-body'>
        <h1 className='typography-title-5--bold-28 text-center'>
          Datos personales
        </h1>
        <p>
          Por favor bríndanos los siguientes datos para enviarte los resultados
          del diagnóstico luego de finalizada la encuesta.
        </p>
        <Form
          form={form}
          {...formLayout}
          onFinish={handleSave}
          className='form-personal-data'
        >
          <Form.Item
            name='contact'
            rules={[{ required: true, message: 'Por favor ingrese su nombre' }]}
          >
            <InputWithLabel label='Nombres y Apellidos' />
          </Form.Item>
          <Form.Item
            name='email'
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Por favor ingrese un correo electrónico válido',
              },
            ]}
          >
            <InputWithLabel label='Correo Electrónico' />
          </Form.Item>
          <Form.Item
            name='position'
            rules={[{ required: true, message: 'Por favor ingrese un cargo' }]}
          >
            <InputWithLabel label='Cargo' />
          </Form.Item>
          <Form.Item
            name='address'
            rules={[
              {
                required: true,
                message: 'Por favor ingrese su dirección y/o coordenadas',
              },
            ]}
          >
            <InputWithLabel label='Dirección y/o Coordenadas' />
          </Form.Item>
          <Form.Item
            name='number'
            rules={[
              { required: true, message: 'Por favor ingrese un teléfono' },
            ]}
          >
            <InputWithLabel label='Télefono' />
          </Form.Item>
          <div className='btn-container'>
            <Button htmlType='button' onClick={_handleBack}>
              <LeftOutlined />
              Volver
            </Button>
            <Button type='primary' htmlType='submit'>
              Continuar
              <RightOutlined />
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default PersonalDataForm;
