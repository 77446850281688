import { ACCENTS } from './consts';

export const PATH_API_AUTH = process.env.REACT_APP_API_BASE_PATH_NEW;
export const PATH_API_NO_AUTH = (PATH_API_AUTH + '/public').replace('v2/', '');

export const PATH_API_CANAL_AUTH = process.env.REACT_APP_API_BASE_PATH_CANAL;
export const PATH_API_CANAL_NO_AUTH =
  process.env.REACT_APP_API_BASE_PATH_CANAL + '/public';

export const getUrlAPI = (url, auth = true) => {
  if (
    ['/users', '/rols', '/modules', '/permissions', '/banner'].filter((x) =>
      url.includes(x),
    ).length
  ) {
    return auth ? PATH_API_CANAL_AUTH : PATH_API_NO_AUTH;
  }
  return auth ? PATH_API_AUTH : PATH_API_NO_AUTH;
};

export const isApiCanal = (path) => {
  return path?.includes('api-canal');
};

export const ceil = (num, precision = 0) => {
  const multiplier = Math.pow(10, precision);
  return Math.ceil(num * multiplier) / multiplier;
};

export const secureRandom = () => {
  const buffer = new Uint32Array(1);
  crypto.getRandomValues(buffer);
  return buffer[0] / 0xffffffff;
};

export const isLatLngSearch = (search) => {
  const parts = search.split(',');

  const isPartValid = (part) => part.length > 0 && !isNaN(Number(part));

  if (parts.length === 2 && isPartValid(parts[0]) && isPartValid(parts[1])) {
    return {
      lat: parts[0],
      lng: parts[1],
    };
  }

  return null;
};

export const checkPassword = (pass) => {
  let value = 0;
  const regexUpperCase = /[A-Z]/g;
  const regexlowerCase = /[a-z]/g;
  const regexNumber = /[0-9]/g;
  const regexSpecials = /(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])/g;

  if (regexUpperCase.test(pass)) {
    value += 1;
  }
  if (regexlowerCase.test(pass)) {
    value += 1;
  }
  if (regexNumber.test(pass)) {
    value += 1;
  }
  if (regexSpecials.test(pass)) {
    value += 1;
  }
  if (pass.length >= 8) {
    value += 1;
  }

  return value;
};

export const filterByString = (list, searchText) => {
  const sanitizedSearchText = searchText.trim().toLowerCase();

  return list.filter((item) =>
    Object.values(item).some(
      (value) =>
        typeof value === 'string' &&
        value.toLowerCase().includes(sanitizedSearchText),
    ),
  );
};

export const closePopUp = () => {
  const parent = document.querySelector('.leaflet-popup-close-button');
  if (parent) {
    parent.click();
  }
};

export const filterSelectOptions = (input, option) =>
  option.props.children.toUpperCase().indexOf(input.toUpperCase()) !== -1;

export const disableRootScroll = () => {
  const element = document.getElementById('root');
  element.classList.add('disabled-scroll');
};

export const enableRootScroll = () => {
  const element = document.getElementById('root');
  element.classList.remove('disabled-scroll');
};

export const removeAccents = (text) => {
  let res = '';
  for (const char of text) {
    if (ACCENTS[char]) {
      res += ACCENTS[char];
    } else {
      res += char;
    }
  }

  return res;
};

export const randomColor = (listColorExist) => {
  const hexadecimalValues = '0123456789ABCDEF';
  let color = '#';

  const getRandomInt = (max) => Math.floor(secureRandom() * max);

  const getRandomHexChar = () =>
    hexadecimalValues[getRandomInt(hexadecimalValues.length)];

  const generateRandomColor = () => {
    for (let i = 0; i < 6; i++) {
      color += getRandomHexChar();
    }
  };

  generateRandomColor();

  while (listColorExist.includes(color)) {
    color = '#';
    generateRandomColor();
  }

  return color;
};

export const getFiltersOptions = (list, filtersCommon) => {
  const filtersCommonSelect = filtersCommon.filter(
    (item) => item?.type === 'select' || item?.type === 'selectMultiple',
  );
  let filtersOpts = {};

  list.map((item) => {
    filtersCommonSelect.forEach((element) => {
      const [key, subKey] = element.key.split('.');
      let it = item[key];
      if (it && subKey) {
        it = it[subKey];
      }

      if (it && typeof it === 'string') {
        filtersOpts = {
          ...filtersOpts,
          [element.key]: filtersOpts[element.key]?.length
            ? [
                ...filtersOpts[element.key],
                {
                  label: element.render ? element.render(it) : it,
                  value: it,
                },
              ]
            : [
                {
                  label: element.render ? element.render(it) : it,
                  value: it,
                },
              ],
        };
      }

      if (it && typeof it === 'object' && it[element?.value]) {
        filtersOpts = {
          ...filtersOpts,
          [element.key]: filtersOpts[element.key]?.length
            ? [
                ...filtersOpts[element.key],
                {
                  label: element.render
                    ? element.render(it[element.label])
                    : it[element.label],
                  value: it[element.value],
                },
              ]
            : [
                {
                  label: element.render
                    ? element.render(it[element.label])
                    : it[element.label],
                  value: it[element.value],
                },
              ],
        };
      }
    });
  });

  Object.keys(filtersOpts).forEach((key) => {
    const values = filtersOpts[key].map((item) => item.value);
    filtersOpts[key] = filtersOpts[key].filter((item, index) => {
      return values.indexOf(item.value) === index;
    });
  });

  return filtersOpts;
};

export const transformDrawsToGeoJSON = (draws) => {
  const jsonFile = {
    type: 'FeatureCollection',
    features: draws,
  };

  const jsonStr = JSON.stringify(jsonFile);

  return new File([jsonStr], 'myLayer.geojson', {
    type: 'application/json',
  });
};

export const chunkArray = (array, chunkSize) => {
  const result = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
};

export const pushDigitalData = (params, event) => {
  window.digitalData.push({
    ...params,
    event,
  });
};

export const standardizeLongitude = (value) => {
  if (value >= -180 && value <= 180) {
    return value;
  } else if (value < -180) {
    return standardizeLongitude(value + 360);
  } else {
    return standardizeLongitude(value - 360);
  }
};

export const debounce = (func, wait = 800) => {
  let timeout;

  return (...args) => {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const capitalize = (str) =>
  `${str.charAt(0).toUpperCase()}${str.slice(1).toLowerCase()}`;
