import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-draw';

import {
  MAP_CHANGE_BLOCKED_CLICK,
  MY_LAYERS_SET_DRAWS,
} from '../../../redux/actions/actionsType';
import { styleGeoJSONFunction } from '../utils';
import PopupContent from './PopupContent';

import {
  handleSaveProperties,
  handleDrawCreated,
  handleDrawEdited,
  handleDeleteLayer,
} from './mapUtils';

const CreateMyMap = () => {
  const dispatch = useDispatch();
  const draws = useSelector((state) => state.myLayers.draws);
  const [selectedDraw, setSelectedDraw] = useState(null);
  const [initProperties, setInitProperties] = useState(null);

  const map = useMap();
  const featureGroup = useRef(null);
  const geoDraws = useRef([]);

  const saveProperties = handleSaveProperties(
    selectedDraw,
    featureGroup,
    setSelectedDraw,
    geoDraws,
    dispatch,
  );

  useEffect(() => {
    featureGroup.current = new L.FeatureGroup();
    map.addLayer(featureGroup.current);

    const drawControl = new L.Control.Draw({
      edit: {
        featureGroup: featureGroup.current,
      },
      draw: {
        polygon: true,
        polyline: true,
        rectangle: true,
        circle: true,
        circlemarker: false,
        marker: false,
      },
    });

    map.addControl(drawControl);

    map.on('draw:created', handleDrawCreated(featureGroup, geoDraws, dispatch));
    map.on('draw:edited', handleDrawEdited(featureGroup, geoDraws, dispatch));
    map.on('draw:deleted', handleDeleteLayer(featureGroup, geoDraws, dispatch));

    if (draws.length > 0) {
      draws.forEach((draw) => {
        const layer = L.geoJSON(draw, {
          style: styleGeoJSONFunction,
        });

        layer.eachLayer(function (l) {
          featureGroup.current.addLayer(l);

          l.on('click', (e) => {
            const { lat, lng } = e.latlng;
            setSelectedDraw({
              layer: l.toGeoJSON(),
              layerId: l._leaflet_id,
              lat,
              lng,
            });
          });
        });
      });

      geoDraws.current = draws;
    }

    dispatch({ type: MAP_CHANGE_BLOCKED_CLICK, payload: true });

    return () => {
      map.removeControl(drawControl);
      map.removeLayer(featureGroup.current);
      dispatch({ type: MAP_CHANGE_BLOCKED_CLICK, payload: false });
      dispatch({ type: MY_LAYERS_SET_DRAWS, payload: [] });
    };
  }, [map]);

  useEffect(() => {
    if (selectedDraw) {
      setInitProperties({
        stroke: selectedDraw.layer?.properties?.['stroke'] ?? '#3388ff',
        'stroke-width': selectedDraw.layer?.properties?.['stroke-width'] ?? 3,
        'stroke-opacity':
          selectedDraw.layer?.properties?.['stroke-opacity'] ?? 1,
        fill: selectedDraw.layer?.properties?.['fill'] ?? '#3388ff',
        'fill-opacity': selectedDraw.layer?.properties?.['fill-opacity'] ?? 0.2,
      });
    }
  }, [selectedDraw]);

  return (
    selectedDraw &&
    initProperties && (
      <Popup position={[selectedDraw?.lat, selectedDraw?.lng]}>
        <PopupContent
          initProperties={initProperties}
          onSave={saveProperties}
          type={selectedDraw?.layer?.geometry?.type}
        />
      </Popup>
    )
  );
};

export default CreateMyMap;
