import {
  loadContentOfPoly,
  loadContentOfCircle,
} from '../common/PopUp/PopUpContent';
import L from 'leaflet';

export const loadCircleDetails = ({
  circle,
  filters,
  dispatch,
  fromDrawer = false,
  map,
}) => {
  const popup = L.popup().setLatLng(circle.getLatLng()).openOn(map);
  loadContentOfCircle({ circle, popup, filters, dispatch, fromDrawer });
};

export const loadPolygonDetails = ({ polygon, filters, dispatch, map }) => {
  const popup = L.popup()
    .setLatLng(polygon.getBounds().getCenter())
    .openOn(map);
  loadContentOfPoly(polygon, popup, filters, dispatch);
};

export const loadPolyDetails = ({ polygon, filters, dispatch, map }) => {
  const popup = L.popup()
    .setLatLng(polygon.getBounds().getCenter())
    .openOn(map);
  loadContentOfPoly(polygon, popup, filters, dispatch);
};
