import { get } from '../lib/ajax';
import { LOADED_ZONES } from '../redux/actions/actionsType';

export const getZones = async (dispatch) => {
  const r = await get('/zones/list');
  if (r.data.success) {
    dispatch({ type: LOADED_ZONES, payload: r.data.list });
  }
};

export const getZonesWithDistricts = async (dispatch) => {
  const r = await get('/zones/listDistrict');
  if (r.data.success) {
    dispatch({ type: LOADED_ZONES, payload: r.data.list });

    return r.data.list;
  }

  return [];
};
