import { get, put, post } from '../lib/ajax';

export const getInvitedUser = async (code_invitation) => {
  const r = await get('/invitation?code=' + code_invitation, false);

  return r;
};

export const updateUser = async (user) => {
  const r = await put('/invitation?id=' + user.id, user, false);

  return r;
};

export const checkDocumentByUser = async (document, type = 1) => {
  const r = await get(
    `/checkuser?document=${document}&document_type=${type}`,
    false,
  );

  return r;
};

export const recoveryPassword = async (value) => {
  const r = await post('/recovery', value, false);

  return r;
};

export const resetPassword = async (value) => {
  const r = await put('/recovery', value, false);

  return r;
};
