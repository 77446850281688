import { Fragment, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  RideButton,
  RideGlArrowBack,
  RideGlArrowForward,
  RideTag,
} from '@rimac-seguros/ride-system-components';
import DOMPurify from 'dompurify';

import {
  getNewsletter,
  getNewslettersToClient,
} from '../../../../../services/newsletters';
import { newsletterStates } from '../../utils';
import { eventTypes } from '../../../../../lib/consts';

import Divider from '../../../../../common/Divider';
import Time from '../Time';
import Skeleton from '../../../../../common/Skeleton';
import Carousels from './Carousels';

const NewsletterById = ({ id }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const newsletters = useSelector((state) => state.newsletters.newsletters);
  const [newsletter, setNewsletter] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const socialTypeId = eventTypes.find((event) => event.name === 'Sociales').id;

  const isActive = newsletter?.disabled
    ? false
    : Boolean(newsletter?.event?.date_to_end_diff);

  useEffect(() => {
    getNewsletter(id)
      .then((newsletter) => {
        if (!newsletter) navigate('/monitoreo/boletines');
        if (
          newsletter &&
          newsletterStates[newsletter.state].text !== 'Publicado'
        )
          navigate('monitoreo/boletines');
        setNewsletter(newsletter);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [id]);

  useEffect(() => {
    const getNewsletters = async () => {
      const { total } = await getNewslettersToClient(dispatch, {
        page: 1,
        limit: 3,
        filters: { status: 'active' },
      });
      if (!Boolean(total)) {
        await getNewslettersToClient(dispatch, {
          page: 1,
          limit: 3,
        });
      }
    };

    getNewsletters();
  }, [id]);

  const getZones = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');

    const spanElements = doc.querySelectorAll('span[style="font-size:18px;"]');

    const departments = Array.from(spanElements).map((span) => {
      const department = span.querySelector('b').textContent;

      let provinces = null;
      const spans = span.querySelectorAll('span');

      if (spans.length >= 2) {
        const span = spans[1];
        provinces = span.textContent.split(' , ');
      }

      return { department, provinces };
    });

    return departments;
  };

  useLayoutEffect(() => {
    const container = document.querySelector('.main-content');
    container.scrollTo(0, 0);
  }, [id]);

  return (
    <div className='flex flex-col items-start gap-5'>
      <RideButton
        size='small'
        text={'Ver todos los boletines'}
        variant='text-primary'
        glyph={<RideGlArrowBack />}
        glyphPosition='left'
        onClick={() => navigate(`/monitoreo/boletines`)}
      />
      <article className='newsletter'>
        {isLoading ? (
          <>
            <Skeleton type={'newsletters'} />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        ) : (
          <>
            <div className='newsletter__date-status'>
              <div className='flex flex-wrap gap-2 items-start'>
                <RideTag
                  case={'informative'}
                  text={
                    eventTypes.find((event) => event.id === newsletter?.type)
                      ?.name
                  }
                />
                <RideTag
                  type='strong'
                  case={isActive ? 'success' : 'null'}
                  text={isActive ? 'Vigente' : 'No vigente'}
                />
              </div>
              <Time
                dateStart={newsletter?.event?.date_event_start}
                dateEnd={newsletter?.event?.date_event_end}
                format={'intermediate'}
              />
            </div>
            {newsletter?.event?.eventImages.length === 1 ? (
              <img
                className='newsletter__image'
                src={
                  newsletter?.event?.image?.indexOf('https://') !== -1
                    ? newsletter?.image
                    : `${process.env.REACT_APP_WEB_PATH_S3_NEW}${newsletter?.event?.image}`
                }
                alt={newsletter?.event?.detected_condition}
              />
            ) : (
              <Carousels
                eventImages={newsletter?.event?.eventImages.sort(
                  (a, b) => Boolean(b?.main) - Boolean(a?.main),
                )}
              />
            )}
            <h2 className='newsletter__title'>{`${
              newsletter?.detected_condition ?? ''
            } ${newsletter?.detected_condition_red ?? ''}`}</h2>
            <Divider style={{ margin: '8px 0' }} />
            <h3 className='newsletter__subtitle newsletter__subtitle--zones'>
              Localidades Afectadas
            </h3>
            {newsletter?.zones === '' ? (
              <p className='newsletter__text'>No hay localidades afectadas</p>
            ) : (
              <p className='newsletter__zones'>
                {getZones(newsletter?.zones).map((zones, index, array) => {
                  return (
                    <Fragment key={index}>
                      {zones.provinces ? (
                        <>
                          <b>{zones.department}:</b>{' '}
                          {zones.provinces
                            .join(', ')
                            .replace(/, ([^,]*)$/, ' y $1')}
                        </>
                      ) : (
                        <b>{zones.department}</b>
                      )}
                      {index < array.length - 1 && ' | '}
                    </Fragment>
                  );
                })}
              </p>
            )}
            <Divider style={{ margin: '8px 0' }} />
            {socialTypeId !== newsletter.type && (
              <h3 className='newsletter__subtitle'>Pronóstico</h3>
            )}
            <p
              className='newsletter__forecast'
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(newsletter?.event?.summary),
              }}
            ></p>
          </>
        )}
      </article>
      {newsletters?.length !== 0 && (
        <aside className='moreNewsletters'>
          {isLoading ? (
            <Skeleton />
          ) : (
            <>
              <h2 className='moreNewsletters__title'>{'Otros eventos'}</h2>
              <ul className='moreNewsletters__list'>
                {newsletters
                  .filter((newsletter) => Number(newsletter.id) !== Number(id))
                  .slice(0, 2)
                  .map((newsletter) => {
                    return (
                      <li key={newsletter.id}>
                        <Link
                          to={`/monitoreo/boletines?id=${newsletter.id}`}
                        >{`${newsletter?.detected_condition ?? ''} ${
                          newsletter?.detected_condition_red ?? ''
                        }`}</Link>
                      </li>
                    );
                  })}
              </ul>
              <RideButton
                size='small'
                className='moreNewsletters__link'
                text={'Ver más eventos'}
                variant='text-primary'
                glyph={<RideGlArrowForward />}
                glyphPosition='right'
                onClick={() => navigate(`/monitoreo/boletines`)}
              />
            </>
          )}
        </aside>
      )}
    </div>
  );
};

export default NewsletterById;
