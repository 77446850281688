import React from 'react';

const OfferCard = ({ title, description, img, ellipsis, border }) => {
  return (
    <div className={`offer-card ${border ? 'offer-card--border' : ''}`}>
      <div className='offer-card__up'>
        <img src={img} className='offer-card__img' alt='' aria-hidden />
      </div>
      <div className='offer-card__down'>
        <div className='offer-card__title'>{title}</div>
        <div
          className={`offer-card__description ${ellipsis ? 'offer-card__ellipsis' : ''}`}
        >
          {description}
        </div>
      </div>
    </div>
  );
};

export default OfferCard;
