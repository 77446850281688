import React, { useEffect, useState } from 'react';
import { Select, notification } from 'antd';
import { getEmailClientsTracking } from '../../../../services/clientTracking';

const { Option } = Select;

function validateEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return emailRegex.test(email);
}

const MultiSelect = ({ company, onEmailsChange }) => {
  const [emails, setEmails] = useState([]);

  useEffect(() => {
    if (company) {
      getEmailClientsTracking(company).then((res) => {
        setEmails(res);
      });
    }
  }, [company]);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === 'Tab') {
      const newEmail = event.target.value.trim();
      if (validateEmail(newEmail) && !emails.includes(newEmail)) {
        setEmails([...emails, newEmail]);
        event.target.value = '';
      } else {
        notification.error({
          message: 'Error',
          description:
            'El correo ingresado no es válido o ya existe en la lista',
        });
      }
    }
  };

  const handleSelectChange = (value) => {
    onEmailsChange(value);
  };

  return (
    <>
      <Select
        mode='multiple'
        style={{ width: '100%' }}
        placeholder='Selecciona los correos'
        onInputKeyDown={handleKeyDown}
        onChange={handleSelectChange}
      >
        {emails
          ? emails.map((email, index) => (
              <Option key={index} value={email}>
                {email}
              </Option>
            ))
          : null}
      </Select>
    </>
  );
};

export default MultiSelect;
