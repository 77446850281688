import { useRef, useState, useEffect } from 'react';
import { Button, Upload } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import Script from 'react-load-script';
import FileBase64 from 'react-file-base64';
import { SwatchesPicker } from 'react-color';
import shp from 'shpjs';
import { polygon } from '@turf/helpers';
import intersect from '@turf/intersect';

import { provinces } from './peru_provincial_simple';

const IconVertice =
  '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M2.375 10.5C2.75317 10.5 3.09546 10.3483 3.34839 10.1046L4.73596 10.7983C4.729 10.8549 4.71875 10.9104 4.71875 10.9688C4.71875 11.7441 5.34961 12.375 6.125 12.375C6.90039 12.375 7.53125 11.7441 7.53125 10.9688C7.53125 10.7523 7.47803 10.5497 7.39026 10.3663L9.27258 8.48401C9.45593 8.57178 9.65857 8.625 9.875 8.625C10.6504 8.625 11.2812 7.99414 11.2812 7.21875C11.2812 7.07275 11.2526 6.93469 11.2111 6.80225L12.8466 5.57593C13.0697 5.72498 13.3372 5.8125 13.625 5.8125C14.4004 5.8125 15.0312 5.18164 15.0312 4.40625C15.0312 3.63086 14.4004 3 13.625 3C12.8496 3 12.2188 3.63086 12.2188 4.40625C12.2188 4.55225 12.2474 4.69031 12.2889 4.82275L10.6534 6.04907C10.4303 5.90002 10.1628 5.8125 9.875 5.8125C9.09961 5.8125 8.46875 6.44336 8.46875 7.21875C8.46875 7.43518 8.52197 7.63782 8.60974 7.82117L6.72742 9.70349C6.54407 9.61572 6.34143 9.5625 6.125 9.5625C5.74683 9.5625 5.40454 9.71423 5.15161 9.95789L3.76404 9.26416C3.771 9.20764 3.78125 9.1521 3.78125 9.09375C3.78125 8.31836 3.15039 7.6875 2.375 7.6875C1.59961 7.6875 0.96875 8.31836 0.96875 9.09375C0.96875 9.86914 1.59961 10.5 2.375 10.5Z" fill="#494F66"/></svg>';

const IconUpload =
  '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none"><path d="M11.751 3.49969H4.24921C3.83747 3.49969 3.49985 3.16207 3.49985 2.75033C3.49985 2.3386 3.83747 2.00098 4.24921 2.00098H11.751C12.1627 2.00098 12.5004 2.3386 12.5004 2.75033C12.5004 3.16207 12.1627 3.49969 11.751 3.49969ZM12.7227 8.72048L8.71241 4.71019C8.31714 4.31492 7.68307 4.32316 7.29604 4.71019L3.31869 8.72048C2.84931 9.18986 3.18693 9.99686 3.85394 9.99686H5.0068V12.9943C5.0068 13.546 5.45147 13.9907 6.00319 13.9907H10.0053C10.557 13.9907 11.0017 13.546 11.0017 12.9943V9.99686H12.1874C12.8545 9.99686 13.1921 9.18986 12.7227 8.72048Z" fill="#494F66"/></svg>';

const SectionSelecZoneMap = ({ onOk, setVisible, visible }) => {
  const map = useRef();
  const polygons = useRef([]);
  const [shape, setShape] = useState([]);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [loadedJS, setLoadedJS] = useState(0);
  const [showColorPick, setShowColorPick] = useState(false);
  const [currentLayerToColor, setCurrentLayerToColor] = useState();
  const [loadingCreateImage, setLoadingCreateImage] = useState(false);
  const [drawed, setDrawed] = useState(false);

  const removeShape = (removeFile) => {
    if (removeFile?.layers?.length > 0) {
      removeFile.layers.forEach((layer) => layer.onRemove(map.current));
    }

    setShape((shapes) =>
      shapes.filter((shape) => shape.uid !== removeFile.uid),
    );

    if (shape.length === 0) {
      setDrawed(false);
    }
  };

  const checkLoad = () => setLoadedJS(loadedJS + 1);

  const handleChange = (color) =>
    currentLayerToColor.setStyle({
      color: color.hex,
    });

  const handleChangeComplete = (color) => {
    currentLayerToColor.setStyle({
      color: color.hex,
    });
    setShowColorPick(false);
    setCurrentLayerToColor(null);
  };

  const addShapeToMap = ({ shape }) => {
    const layer = window.L.geoJson(shape, {
      style: (feature) => {
        const style = {
          fillColor: '#ff7800',
          strokeColor: '#ff7800',
          color: '#000',
          weight: 1,
          opacity: 1,
          fillOpacity: 0.8,
        };

        if (feature.properties) {
          switch (feature.properties.nivel) {
            case 'Nivel 4':
              style.fillColor = '#ff0000';
              style.color = '#ff0000';
              break;
            case 'Nivel 3':
              style.fillColor = '#FAC167';
              style.color = '#FAC167';
              break;
            case 'Nivel 2':
              style.fillColor = '#EBF54D';
              style.color = '#EBF54D';
              break;
            case 'Nivel 1':
              style.fillColor = '#00000000';
              style.color = '#00000000';
              break;
            default:
              return style;
          }
        }

        return style;
      },
    });

    layer.onAdd(map.current);
    polygons.current.push(layer);
    setDrawed(true);

    return layer;
  };

  const handleUploadShape = async (file) => {
    try {
      const name = file[0].name;
      const uid = uuidv4();
      let base64 = file[0].base64;
      base64 = base64.replace('data:' + file[0].type + ';base64,', '');
      let buff = new Buffer(base64, 'base64');
      const data = await shp(buff);

      const tmpLayers = [];
      if (data?.features) {
        const ordered = data.features.sort((a, b) => {
          return a.properties.nivel - b.properties.nivel;
        });
        ordered.forEach((item) => {
          if (item?.geometry?.type === 'MultiPolygon') {
            const coordinates = [...item.geometry.coordinates];
            coordinates.forEach((coordinate) => {
              const newItem = {
                ...item,
                geometry: {
                  ...item.geometry,
                  type: 'Polygon',
                  coordinates: coordinate,
                },
              };
              tmpLayers.push(addShapeToMap({ shape: newItem }));
            });
          } else {
            tmpLayers.push(addShapeToMap({ shape: item }));
          }
        });
      }

      setShape((shapes) => [...shapes, { name, uid, layers: tmpLayers }]);
    } catch (e) {
      console.error('error', e.message);
    }
  };

  const selectProvinces = async (base64) => {
    const promises = Object.keys(map.current._layers).map(
      (key) =>
        new Promise(async (resolve) => {
          const layer = map.current._layers[key];
          if (layer.feature?.properties?.nivel === 'Nivel 1') {
            resolve(false);
          } else if (layer instanceof window.L.Polygon) {
            const json = layer.toGeoJSON();
            if (json.geometry.coordinates.length >= 1) {
              const resp = provinces.features.map(
                (province) =>
                  new Promise((resolv) => {
                    try {
                      const poly1 = polygon(province.geometry.coordinates);
                      const poly2 = polygon(json.geometry.coordinates);
                      const result = intersect(poly2, poly1);
                      result
                        ? resolv(province.properties.FIRST_IDPR)
                        : resolv(false);
                    } catch (e) {
                      resolv(false);
                    }
                  }),
              );
              const provincesSelected = await Promise.all(resp);
              resolve(provincesSelected.filter(Boolean));
            }
          }

          resolve(false);
        }),
    );

    const provsall = await Promise.all(promises);
    let selecteds = [];
    provsall.filter(Boolean).forEach((items) => {
      if (Array.isArray(items)) selecteds = selecteds.concat(items);
    });

    onOk({ provinces: selecteds, base64 });
  };

  const handleOk = async () => {
    setLoadingCreateImage(true);
    const control = window.L.control
      .bigImage({ position: 'topright' })
      .addTo(map.current);
    const data = await control.getBase64();
    await selectProvinces(data);
    setLoadingCreateImage(false);
    setVisible(false);
  };

  const loadShape = async () =>
    document.querySelector('.upload_shape input').click();

  const checkPathDraw = (e, map) => {
    setDrawed(true);
    map.editTools.currentPolygon = e.layer;
    e.layer.on('contextmenu', (e) => {
      setShowColorPick(true);
      setCurrentLayerToColor(e.target);
    });
    map.editTools.currentPolygon.dragging.disable();
  };

  const initDraw = () => {
    const startPoint = [-12.047596236701777, -77.0946901800066];

    map.current = window.L.map('selectMap', {
      editable: true,
    }).setView(startPoint, 8);

    window.L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution:
        '&copy <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(map.current);

    window.L.EditControl = window.L.Control.extend({
      options: {
        position: 'topleft',
        callback: null,
        title: '',
        kind: '',
        html: '',
      },

      onAdd: function (map) {
        const container = window.L.DomUtil.create(
          'div',
          'leaflet-control leaflet-bar',
        );
        const link = window.L.DomUtil.create('a', '', container);
        link.href = '#';
        link.title = this.options.title;
        link.innerHTML = this.options.html;
        window.L.DomEvent.on(link, 'click', window.L.DomEvent.stop).on(
          link,
          'click',
          function () {
            window.LAYER = this.options.callback.call(map.editTools);
          },
          this,
        );

        return container;
      },
    });

    window.L.NewPolygonControl = window.L.EditControl.extend({
      options: {
        position: 'topleft',
        title: 'Crear polígono',
        callback: () => {
          if (map.current?.editTools) {
            const pol = map.current.editTools.startPolygon();
            polygons.current.push(pol);
          }
        },
        kind: 'polygon',
        html: IconVertice,
      },
    });

    window.L.AddShape = window.L.EditControl.extend({
      options: {
        position: 'topleft',
        title: 'Importar shape',
        callback: () => {
          loadShape();
        },
        kind: 'shape',
        html: IconUpload,
      },
    });
    map.current.addControl(new window.L.NewPolygonControl());
    map.current.addControl(new window.L.AddShape());
    map.current.on('editable:drawing:end', (e) => {
      checkPathDraw(e, map.current);
    });
  };

  useEffect(() => {
    if (visible) {
      setMapLoaded(true);
      setShape([]);
    }

    return () => {
      if (!visible) {
        polygons.current.map((polygon) => {
          map.current.removeLayer(polygon);
        });

        map.current.eachLayer((layer) => {
          if (layer instanceof window.L.Polygon) map.current.removeLayer(layer);
        });

        polygons.current = [];
        setDrawed(false);
        setMapLoaded(false);
        setLoadedJS(0);
      }
    };
  }, [visible]);

  useEffect(() => {
    if (loadedJS >= 1) {
      initDraw();
    }
  }, [loadedJS]);

  useEffect(() => {
    if (shape.length === 0 && !drawed) {
      setDrawed(false);
    }
  }, [shape]);

  return (
    <section className='modal-draw section-draw'>
      <div id='images'></div>

      <div className='formEventMap'>
        <Upload fileList={shape} onRemove={removeShape}></Upload>
      </div>

      <link
        rel='stylesheet'
        href={`${process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN}css/leaflet.draw.css`}
      />

      <link
        rel='stylesheet'
        href={`${process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN}css/Leaflet.BigImage.min.css`}
      />

      {mapLoaded && (
        <Script
          url={`${process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN}js/Path.Drag.js`}
        />
      )}

      {mapLoaded && (
        <Script
          url={`${process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN}js/Leaflet.Editable.js`}
          onLoad={checkLoad}
        />
      )}

      {mapLoaded && (
        <Script
          url={`${process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN}js/Leaflet.BigImage.min.js`}
          onLoad={checkLoad}
        />
      )}

      <div
        id='selectMap'
        style={{
          top: 20,
          height: '57vh',
          width: '100%',
          overflow: 'hidden',
          margin: 'auto',
          borderRadius: 9,
        }}
      ></div>

      {showColorPick && (
        <div
          style={{
            position: 'absolute',
            zIndex: 1000,
            top: '2%',
            right: '3%',
          }}
        >
          <SwatchesPicker
            onChange={handleChange}
            onChangeComplete={handleChangeComplete}
            onSwatchHover={handleChange}
          />
        </div>
      )}

      <div style={{ display: 'none' }} className='upload_shape'>
        <FileBase64 multiple={true} onDone={handleUploadShape} />
      </div>

      <div className='form-actions flex items-center' style={{ marginTop: 30 }}>
        <Button
          className='button-purple-link mr-2 mt-1'
          htmlType='button'
          type='link'
          size='large'
          onClick={() => {
            setShape([]);
            setVisible(false);
          }}
          style={{ fontSize: 20 }}
        >
          Cancelar
        </Button>

        <Button
          onClick={handleOk}
          size='large'
          type='primary'
          loading={loadingCreateImage}
          htmlType='submit'
          style={{ fontSize: 20 }}
          disabled={!drawed}
        >
          {!loadingCreateImage && 'Tomar captura'}
        </Button>
      </div>
    </section>
  );
};

export default SectionSelecZoneMap;
