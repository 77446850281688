import { loadLayer } from './utils';

export const resetAnimation = (
  setIsPlaying,
  setIsLoadingScenes,
  setSelectedSceneBar,
  defaultScene,
  setScenesLayers,
  setScenesLayersLoaded,
) => {
  setIsPlaying(null);
  setIsLoadingScenes(false);
  setSelectedSceneBar(defaultScene);
  setScenesLayers({});
  setScenesLayersLoaded([]);
};

export const updateSceneLayers = (options) => {
  const {
    activeSubLayer,
    selectedSceneBar,
    idxTimes,
    scenesLayers,
    setScenesLayersLoaded,
    opacity,
    setScenesLayers,
    map,
  } = options;
  if (activeSubLayer && selectedSceneBar) {
    const indexScene = activeSubLayer?.animate?.realtime
      ? activeSubLayer?.animate.times[idxTimes] - selectedSceneBar
      : selectedSceneBar;
    Object.values(scenesLayers).forEach((item) => {
      item.layer.getContainer().style.visibility = 'hidden';
    });
    if (scenesLayers[indexScene]) {
      scenesLayers[indexScene].layer.getContainer().style.visibility =
        'visible';
    } else {
      const item = loadLayer({
        url: activeSubLayer.url,
        layersId: activeSubLayer.layersId,
        sceneIndex: indexScene,
        setScenesLayersLoaded,
        visible: true,
        opacity,
        activeSubLayer,
        map,
      });
      setScenesLayers((prev) => ({
        ...prev,
        [indexScene]: item,
      }));
    }
  }
};

export const checkLoadingStatus = (
  scenesLayers,
  scenesLayersLoaded,
  setIsLoadingScenes,
) => {
  const someLayerIsLoading =
    Object.values(scenesLayers).length !==
    Object.values(scenesLayersLoaded).filter(Boolean).length;
  if (someLayerIsLoading) {
    setIsLoadingScenes(true);
  } else {
    setIsLoadingScenes(false);
  }
};

export const handleSceneLoading = (options) => {
  const {
    isLoadingScenes,
    isPlaying,
    intervalControl,
    setSelectedSceneBar,
    activeSubLayer,
    idxTimes,
    defaultScene,
    scenesLayers,
    setForceGenerateTimes,
    secureRandom,
    intervalUpdateTiles,
  } = options;
  if (!isLoadingScenes && isPlaying && !intervalControl.current) {
    intervalControl.current = setInterval(() => {
      setSelectedSceneBar((prev) => {
        const times = activeSubLayer?.animate?.realtime
          ? activeSubLayer?.animate.times[idxTimes]
          : activeSubLayer?.animate.times;
        if (prev === null) {
          return defaultScene;
        }
        if (prev >= times) {
          return 1;
        } else {
          return prev + 1;
        }
      });
    }, 200);
    intervalUpdateTiles.current = setInterval(
      () => {
        Object.values(scenesLayers).forEach((item) => {
          item.layer.setParams({ c: secureRandom() }, true);
          item.layer.redraw();
        });
        setForceGenerateTimes(secureRandom());
      },
      1000 * 60 * 10,
    );
  }
};

export const loadAllScenes = (
  activeSubLayer,
  scenesLayers,
  setScenesLayersLoaded,
  setScenesLayers,
  idxTimes,
  opacity,
  map,
) => {
  if (activeSubLayer?.animate?.times) {
    const times = activeSubLayer?.animate?.realtime
      ? activeSubLayer?.animate.times[idxTimes]
      : activeSubLayer?.animate.times;
    for (let i = 1; i <= times; i += 1) {
      const indexScene = activeSubLayer?.animate?.realtime ? times - i : i;
      if (!scenesLayers[i]) {
        const item = loadLayer({
          url: activeSubLayer.url,
          layersId: activeSubLayer.layersId,
          sceneIndex: indexScene,
          setScenesLayersLoaded,
          visible: true,
          opacity,
          activeSubLayer,
          map,
        });
        setScenesLayers((prev) => ({
          ...prev,
          [indexScene]: item,
        }));
      }
    }
  }
};
