export const finishFilters = (list, filters) => {
  const r = list.filter((item) => {
    let has = [];
    Object.keys(filters).forEach((filter) => {
      const [key, subKey] = filter.split('.');

      let it = item[key];
      if (it && subKey) it = it[subKey];

      if (it) {
        const { options, value } = filters[filter];

        if (
          (typeof options === 'string' || typeof options === 'number') &&
          typeof it === 'string'
        ) {
          options === it ? has.push(true) : has.push(false);
        } else if (
          (typeof options === 'string' || typeof options === 'number') &&
          typeof it === 'object'
        ) {
          options === it[value] ? has.push(true) : has.push(false);
        } else {
          const exits = options.find((option) => option.value === it[value]);
          exits ? has.push(true) : has.push(false);
        }
      } else {
        has.push(false);
      }
    });

    return !has.includes(false);
  });

  return r;
};
