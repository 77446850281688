import React, { memo } from 'react';
import { Divider, List } from 'antd';
import Drawer from '../Drawer';

const DrawerMoreOptionsMobile = ({
  visible,
  options = [],
  onClose = () => {},
  children,
}) => {
  return (
    <Drawer
      className='drawer_options'
      placement='bottom'
      onClose={onClose}
      open={visible}
    >
      <div className='flex justify-between full-width title-container'>
        <span className='text-base title-options'>Más opciones</span>
      </div>
      <Divider style={{ margin: 0 }} />
      {children ? (
        children
      ) : (
        <List className='full-width text-base'>
          {options.map(({ key, title, action }) => (
            <List.Item key={key} {...(action ? { onClick: action } : {})}>
              {title}
            </List.Item>
          ))}
        </List>
      )}
    </Drawer>
  );
};

export default memo(DrawerMoreOptionsMobile);
