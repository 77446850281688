import React from 'react';
import { Card, Button } from 'antd';
import Title from '../../../../common/Typograph/Title';
import MainTitle from '../common/MainTitle';

import Geological from './Geological';
import ModalGeological from './Geological/ModalGeological';
import Precipitation from './Precipitation';
import ModalPrecipitation from './Precipitation/ModalPrecipitation';
import MaxTemperature from './MaxTemperature';
import ModalMaxTemperature from './MaxTemperature/ModalMaxTemperature';
import MinTemperature from './MinTemperature';
import ModalMinTemperature from './MinTemperature/ModalMinTemperature';
import PrecipitationEFM from './PrecipitationEFM';
import ModalPrecipitationEFM from './PrecipitationEFM/ModalPrecipitationEFM';

import IconBanner from '../../../../images/configuration/icon_banner.svg';

const layersInfo = [
  {
    key: 'geology',
    name: 'Geología',
    description: 'Geología',
    icon: IconBanner,
    component: Geological,
  },
  {
    key: 'precipitation-efm',
    name: 'Precipitación EFM',
    description: 'Precipitación EFM',
    icon: IconBanner,
    component: PrecipitationEFM,
  },
  {
    key: 'precipitation',
    name: 'Precipitación',
    description: 'Precipitación',
    icon: IconBanner,
    component: Precipitation,
  },
  {
    key: 'max-temperature',
    name: 'Temperatura Máxima',
    description: 'Temperatura Máxima',
    icon: IconBanner,
    component: MaxTemperature,
  },
  {
    key: 'min-temperature',
    name: 'Temperatura Mínima',
    description: 'Temperatura Mínima',
    icon: IconBanner,
    component: MinTemperature,
  },
];

const ImportLayers = () => {
  const [openModal, setOpenModal] = React.useState(null);

  const handleOpenModal = (key) => {
    setOpenModal(key);
  };

  const handleCloseModal = () => {
    setOpenModal(null);
  };

  return (
    <>
      <div>
        <MainTitle titleSection='Importar Capas' />
        <div className='service-prevention-header'>
          <div className='service-prevention__title mb-4'>Importar Capas</div>
        </div>
        <div className='layer-container'>
          {layersInfo.map((layer) => (
            <Card
              key={layer.key}
              className='d_content__card flex flex-col rounded-2xl'
            >
              <img src={layer.icon} alt={layer.key} />
              <Title className={'mt-1'} type={'bold-20'}>
                {layer.name}
              </Title>
              <div className='flex justify-end mt-4'>
                <Button
                  onClick={() => handleOpenModal(layer.key)}
                  className='button-outlined-purple-link'
                >
                  Crear
                </Button>
              </div>
            </Card>
          ))}
        </div>
      </div>
      {openModal === 'geology' && (
        <ModalGeological visible={true} handleClose={handleCloseModal} />
      )}
      {openModal === 'precipitation' && (
        <ModalPrecipitation visible={true} handleClose={handleCloseModal} />
      )}
      {openModal === 'max-temperature' && (
        <ModalMaxTemperature visible={true} handleClose={handleCloseModal} />
      )}
      {openModal === 'min-temperature' && (
        <ModalMinTemperature visible={true} handleClose={handleCloseModal} />
      )}
      {openModal === 'precipitation-efm' && (
        <ModalPrecipitationEFM visible={true} handleClose={handleCloseModal} />
      )}
    </>
  );
};

export default ImportLayers;
