import dayjs from 'dayjs';
import { pushDigitalData } from '../../../../lib/utils';
import { TRACK } from '../../../../lib/consts';

export const handleUpdateFilters = (setFilters) => (e, value, key) => {
  if (e.target.type === 'checkbox') {
    if (e.target.checked) {
      setFilters((prev) => ({
        ...prev,
        [key]: [...(prev[key] ?? []), value],
      }));
    } else {
      setFilters((prev) => ({
        ...prev,
        [key]: prev[key] ? prev[key].filter((state) => state !== value) : [],
      }));
    }
  } else {
    setFilters((prev) => ({ ...prev, [key]: value }));
  }
};

export const handleChangeDate =
  (setFilters, setDate, setUpdateDate) => (field, value) => {
    if (value && value.length === 2) {
      setFilters((prev) => ({
        ...prev,
        [field]: {
          dateStart: dayjs(value[0]).format('DD-MM-YYYY'),
          dateEnd: dayjs(value[1]).format('DD-MM-YYYY'),
        },
      }));
    } else {
      setFilters({});
    }

    if (field === 'date') {
      setDate(value);
    } else if (field === 'updateDate') {
      setUpdateDate(value);
    }
  };

const handlePushDigitalData = (filters) => {
  pushDigitalData(
    {
      action: {
        group: 'Centro Monitoreo',
        category: 'Fenómenos y eventos',
        name: 'Sismos - Filtros',
        label: `magnitud: ${filters.magnitud?.join(',') || ''} - fecha: ${
          filters.date || ''
        } - aceleración: ${filters.acceleration?.join(',') || ''} `,
      },
    },
    TRACK.ACTION,
  );
};

export const handleSendFilters = (options) => () => {
  const {
    activeSubLayer,
    filters,
    setSocialFilters,
    setRoadFilters,
    setFiremenFilters,
    setSismoFilters,
    setTsunamiFilters,
    setGeologicalHazardsFilters,
  } = options;
  if (activeSubLayer.id === 'conflictos') {
    setSocialFilters(filters);
  }
  if (activeSubLayer.id === 'emergencias_vial') {
    setRoadFilters(filters);
  }
  if (activeSubLayer.id === 'bomberos') {
    setFiremenFilters(filters);
  }
  if (activeSubLayer.id === 'sismos-map') {
    handlePushDigitalData(filters);
    setSismoFilters(filters);
  }
  if (activeSubLayer.id === 'tsunamis-map') {
    if (Object.keys(filters).length === 0) {
      setTsunamiFilters({
        magnitude: [6, 9],
        depth: [0, 150],
      });
    } else {
      setTsunamiFilters(filters);
    }
  }
  if (activeSubLayer.id === 'peligros_geologicos') {
    setGeologicalHazardsFilters(filters);
  }
};

export const handleChangeRange = (setFilters) => (values, key) => {
  setFilters((prev) => ({ ...prev, [key]: values }));
};

export const handleUpdateInputFilters = (setFilters) => (e) => {
  e.persist();
  setFilters((prev) => ({ ...prev, [e.target.name]: e.target.value }));
};

export const handleUpdateSelectFilters = (setFilters) => (value, key) => {
  setFilters((prev) => ({ ...prev, [key]: value }));
};
