export const rsiHasAllReports = (requestServiceItem) => {
  if (!requestServiceItem) return false;
  const { requestServiceItemsData, request_service_item_results } =
    requestServiceItem;

  if (!requestServiceItemsData?.some(({ key }) => key === 'courses'))
    return true;

  const numberCourses = getCoursesArray(requestServiceItemsData).length;
  const numberResults = request_service_item_results
    ? request_service_item_results.length
    : 0;

  return numberCourses === numberResults;
};

export const getCoursesArray = (requestServiceItemsData) => {
  const obj = JSON.parse(
    requestServiceItemsData.find((data) => data.key === 'courses').value,
  );
  const courses = Object.values(obj);

  return courses;
};
