import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Skeleton } from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import { Marker, Popup } from 'react-leaflet';
import L from 'leaflet';

import { getDataPointLeaflet } from '../../../../lib/apiClient';
import { getLocations } from '../../../../services/locations';
import {
  CONTROLMAP_DRAWER_VISIBLE,
  CONTROLMAP_SELECTED,
  COMMON_FORECAST_POINT_DATA,
} from '../../../../redux/actions/actionsType';

import { CalendarIcon, getSvgIcon } from '../../utils';
import ModalRemoveLocation from './ModalRemoveLocation';

const LayerPoints = ({
  points,
  getPoints,
  activeSubLayer,
  activeLayer,
  onClickForecast,
  isToggled,
}) => {
  const [data, setData] = useState(null);
  const [pointToRemove, setPointToRemove] = useState(null);

  useEffect(() => {
    getPoints();
  }, []);

  const markerIcon = () =>
    L.divIcon({
      html: getSvgIcon(
        'location_fav',
        activeSubLayer.styles === 'light' ? '#141938' : '#FFD914',
      ),
      iconSize: [27, 38],
      iconAnchor: [14, 25],
      className: 'leaflet-icon-marker-custom',
    });

  const getDataOfPoint = (point) => {
    setData(null);
    getDataPointLeaflet(new L.LatLng(point.lat, point.lng), activeSubLayer)
      .then((data) => {
        setData(data?.features[0].properties.GRAY_INDEX);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      {isToggled
        ? []
        : points
            .filter((p) => p.lat && p.lng)
            .map((point) => (
              <Marker
                key={point.id}
                position={[point.lat, point.lng]}
                icon={markerIcon()}
                eventHandlers={{
                  click: () => getDataOfPoint(point),
                }}
              >
                <Popup>
                  <div className='popup-map'>
                    <div className='popup-title'>
                      <span className='layer-name'>{activeLayer.name}</span>
                      {![
                        'emergencias',
                        'sismos',
                        'tsunamis',
                        'geological',
                      ].includes(activeLayer.id) && (
                        <div className='popup-value'>
                          {data ? (
                            `${data.toFixed(2)} ${
                              activeSubLayer.unit ?? activeLayer.unit
                            }`
                          ) : (
                            <Skeleton
                              size='small'
                              paragraph={{ rows: 0, width: 80 }}
                              active
                            />
                          )}
                        </div>
                      )}
                    </div>
                    <div className='popup-content popup-map-scrollable'>
                      <span className='popup-detail'>
                        <span className='popup-detail-header'>Favorito</span>
                        <span
                          className='popup-detail-content'
                          style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            width: '100%',
                            whiteSpace: 'pre-wrap',
                            overflowWrap: 'anywhere',
                          }}
                        >
                          {point.name || 'Sin nombre'}
                        </span>
                      </span>
                      <span className='popup-detail'>
                        <span className='popup-detail-header'>Coordenadas</span>
                        <span className='popup-detail-content'>
                          {point.lat.toFixed(6)}, {point.lng.toFixed(6)}
                        </span>
                      </span>
                    </div>
                    <div className='popup-footer'>
                      {activeLayer?.forecast && (
                        <div
                          onClick={() => onClickForecast(point)}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '5px',
                            cursor: 'pointer',
                            color: '#4f4fff',
                            fontWeight: 'bold',
                          }}
                        >
                          <CalendarIcon />
                          <span className='icon-text'>
                            Pronóstico a largo plazo
                          </span>
                        </div>
                      )}
                      <div
                        className='icon-content'
                        onClick={() => setPointToRemove(point)}
                      >
                        <DeleteFilled />
                        <span className='icon-text'>Eliminar ubicación</span>
                      </div>
                    </div>
                  </div>
                </Popup>
              </Marker>
            ))}
      <ModalRemoveLocation
        visible={Boolean(pointToRemove)}
        onClose={() => setPointToRemove(null)}
        id={pointToRemove?.id}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getPoints: () => getLocations(dispatch),
  onClickForecast: (data) => {
    const { id, lat, lng } = data;
    dispatch({ type: CONTROLMAP_SELECTED, payload: 'forecast' });
    dispatch({ type: CONTROLMAP_DRAWER_VISIBLE, payload: true });
    dispatch({ type: COMMON_FORECAST_POINT_DATA, payload: { id, lat, lng } });
  },
});

const mapStateToProps = (state) => ({
  points: state.locations.list,
  activeSubLayer: state.maps.activeSubLayer,
  activeLayer: state.maps.activeLayer,
});

export default connect(mapStateToProps, mapDispatchToProps)(LayerPoints);
