export const tileLayers = [
  {
    key: 'darkGray',
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Dark_Gray_Base/MapServer/tile/{z}/{y}/{x}',
    name: 'Dark Gray',
  },
  {
    key: 'satellite',
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    name: 'Satellite',
  },
  {
    key: 'googleMaps',
    url: 'https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
    name: 'Google Maps',
  },
  {
    key: 'googleHybrid',
    url: 'https://mt1.google.com/vt/lyrs=y&x={x}&y={y}&z={z}',
    name: 'Google Hybrid',
  },
  {
    key: 'googleSatellite',
    url: 'https://mt1.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}',
    name: 'Google Satellite',
  },
  {
    key: 'googleTerrain',
    url: 'https://mt1.google.com/vt/lyrs=p&x={x}&y={y}&z={z}',
    name: 'Google Terrain',
  },
  {
    key: 'openstreetmap',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    name: 'Open Street Map',
  },
  {
    key: 'arcgisOutdoor',
    url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
    name: 'Arcgis Outdoor',
  },
  {
    key: 'arcgisStreets',
    url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}',
    name: 'Arcgis Streets',
  },
  {
    key: 'arcgisTopographic',
    url: 'https://services.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}',
    name: 'Arcgis Topographic',
  },
  {
    key: 'arcgisLightGray',
    url: 'https://services.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}',
    name: 'Arcgis Light Gray',
  },
  {
    key: 'googleStreetView',
    url: 'https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}&sv=1',
    name: 'Google Street View',
  },
];

export const convertHEXWithoutHash = (hex) => hex.replace('#', '');
