import { Switch } from 'antd';

export const COLUMNS = [
  {
    title: 'Permisos',
    dataIndex: 'slug',
  },
  {
    title: 'Descripción',
    dataIndex: 'name',
  },
  {
    title: 'Estado',
    dataIndex: '',
    render: (_, record) => (
      <div className='flex justify-between items-center'>
        <span className='mr-2'>{record.active ? 'Activo' : 'Inactivo'}</span>
        <Switch defaultChecked={record.active} onChange={record.onChange} />
      </div>
    ),
  },
];

export const filterByStatus = (list, status) => {
  return list.filter((el) => {
    const parseStatus = Number(status);

    return isNaN(parseStatus) || Boolean(parseStatus) === el.active;
  });
};
