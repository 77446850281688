import React, { useContext, useState } from 'react';
import { notification } from 'antd';
import { useDispatch } from 'react-redux';

import { createLocation } from '../../services/locations';
import { closePopUp } from '../../lib/utils';
import { handleSetCoordinatesOnCumulusTools } from '../../lib/handler';
import { PopupContext } from '.';
import { ExperimentOutlined, StarOutlined } from '@ant-design/icons';
import Button from '../../common/Button';
import Input from '../../common/Input';

import { COMMON_SET_POSITION_LATLNG } from '../../redux/actions/actionsType';

const PopupLayerFooter = ({ children, isCumulusLayerActive }) => {
  const dispatch = useDispatch();
  const position = useContext(PopupContext);
  const [showInput, setShowInput] = useState(false);
  const [inputText, setInputText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleChangeText = (e) => {
    setInputText(e.target.value);
  };

  const handleBack = () => {
    setShowInput(false);
  };

  const disabled = inputText === '' || isLoading;

  const handleSaveLocation = async (coordinate, name) => {
    setIsLoading(true);
    const success = await createLocation(
      {
        lat: coordinate.lat,
        lng: coordinate.lng,
        type: 2,
        name,
      },
      dispatch,
    );

    success
      ? notification.success({
          description: 'Se guardo satisfactoriamente',
          message: '¡Ubicación favorita!',
        })
      : notification.error({
          description: 'Hubo un error al crear ubicación',
          message: '¡Error!',
        });

    setShowInput(false);
    setInputText('');
    setIsLoading(false);
    dispatch({ type: COMMON_SET_POSITION_LATLNG, payload: null });
    closePopUp();
  };

  return (
    <div className='popup-footer'>
      {children}
      {!showInput && (
        <>
          {isCumulusLayerActive && (
            <div
              role='button'
              className='icon-content'
              onClick={(e) => {
                e.stopPropagation();
                handleSetCoordinatesOnCumulusTools(position, dispatch);
              }}
            >
              <ExperimentOutlined />
              <span className='icon-text'>Realizar simulación</span>
            </div>
          )}
          <div
            role='button'
            className='icon-content'
            onClick={(e) => {
              e.stopPropagation();
              setShowInput((prev) => !prev);
            }}
          >
            <StarOutlined />
            <span className='icon-text'>Ubicación favorita</span>
          </div>
        </>
      )}
      <div className={`popup-options ${showInput ? '' : 'hidden'}`}>
        <Input
          name='name'
          onChange={handleChangeText}
          value={inputText}
          label='Nombre de la ubicación'
          className='mb-2'
          required
        />
        <div className='popup-detail-buttons'>
          <Button
            type='button'
            variant='outline'
            onClick={handleBack}
            text='Atrás'
          />
          <Button
            loading={isLoading}
            disabled={disabled}
            type='button'
            onClick={() => handleSaveLocation(position, inputText)}
            text='Guardar'
          />
        </div>
      </div>
    </div>
  );
};

export default PopupLayerFooter;
