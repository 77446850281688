import React, { useEffect, useRef, useState } from 'react';
import { GeoJSON, useMap } from 'react-leaflet';
import axios from 'axios';
import { styleGeoJSONFunction } from '../../utils';
import {
  MAP_CHANGE_BLOCKED_CLICK,
  MY_LAYERS_SELECT_FEATURE,
  MY_LAYERS_SELECT_POLYGON_LAYER,
} from '../../../../redux/actions/actionsType';
import { connect } from 'react-redux';

const Index = ({ layer, fitBounds, selectFeature, selectPolygonLayer }) => {
  const [geojson, setGeojson] = useState(null);
  const map = useMap();
  const geoJsonLayer = useRef();

  const onEachFeature = (feature, layer) => {
    layer.on('click', function () {
      selectFeature(feature);
      if (feature.geometry.type === 'Polygon') selectPolygonLayer(layer);
    });
  };

  useEffect(() => {
    (async () => {
      if (layer) {
        const res = await axios.get(layer?.url);
        if (res.status === 200) {
          setGeojson(res.data);
          if (fitBounds)
            setTimeout(() => {
              map.fitBounds(geoJsonLayer.current.getBounds());
            }, 200);
        }
      }
    })();
  }, [layer]);

  return (
    geojson && (
      <GeoJSON
        ref={geoJsonLayer}
        onEachFeature={onEachFeature}
        data={geojson}
        style={styleGeoJSONFunction}
      />
    )
  );
};

const mapDispatchToProps = (dispatch) => ({
  changeIsBlockedClick: (blocked) =>
    dispatch({ type: MAP_CHANGE_BLOCKED_CLICK, payload: blocked }),
  selectFeature: (feature) =>
    dispatch({ type: MY_LAYERS_SELECT_FEATURE, payload: feature }),
  selectPolygonLayer: (layer) =>
    dispatch({ type: MY_LAYERS_SELECT_POLYGON_LAYER, payload: layer }),
});

const mapStateToProps = (state) => ({
  menuIsOpened: state.sideMenu.isOpened,
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
