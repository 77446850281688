import {
  LOADED_RPT_DASHBOARD_USERS,
  LOADED_RPT_DASHBOARD_USERS_RESET,
  LOADING_RPT_DASHBOARD_USERS,
  LOADING_RPT_DASHBOARD_USERS_FAILED,
} from '../actions/actionsType';

const initialState = {
  isLoadingUsers: false,
  exportUsersFailed: false,
  filePathUsers: null,
};

const dashboard = (state, action) => {
  if (!state) state = initialState;
  switch (action.type) {
    case LOADING_RPT_DASHBOARD_USERS:
      return {
        ...state,
        isLoadingUsers: action.payload,
      };
    case LOADING_RPT_DASHBOARD_USERS_FAILED:
      return {
        ...state,
        isLoadingUsers: false,
        exportUsersFailed: true,
      };
    case LOADED_RPT_DASHBOARD_USERS:
      return {
        ...state,
        isLoadingUsers: false,
        exportUsersFailed: false,
        filePathUsers: action.payload,
      };
    case LOADED_RPT_DASHBOARD_USERS_RESET:
      return {
        ...state,
        isLoadingUsers: false,
        exportUsersFailed: false,
        filePathUsers: null,
      };
    default:
      return state;
  }
};

export default dashboard;
