import { memo, useState, useCallback } from 'react';
import { Drawer, notification } from 'antd';
import {
  RideButton,
  RideGlDownloadOutline,
  RideGlUpdateOutline,
} from '@rimac-seguros/ride-system-components';

import {
  getPresignedUrl,
  sendFilePoliza,
  startBulkInsertStatusPoliza,
} from '../../../services/polizas';

import InputFile from '../../../common/InputFile';
import { createCumuluHistory } from '../../../services/users';

const DrawerAddCumulo = ({ onClose, visible, updateRunning }) => {
  const [fileData, setFileData] = useState({});

  const onUploadCumuloFile = useCallback((fileInfo) => {
    getPresignedUrl(fileInfo.file)
      .then(({ data }) => {
        if (!data?.success) throw Error('Hubo un error al subir archivo');

        setFileData({ ...data?.payload, file: fileInfo.file });
      })
      .catch(({ message }) => {
        notification.error({
          message,
        });
      });
  }, []);

  const _handleSyncInit = useCallback(() => {
    console.log('fileData', fileData);
    sendFilePoliza(fileData.file)
      .then((dta) => {
        startBulkInsertStatusPoliza(dta.nameKey).then(() => {
          updateRunning(true);
          createCumuluHistory({
            name: dta.name,
            url: dta.nameKey,
          });
        });
      })
      .catch(({ message }) => {
        notification.error({
          message,
        });
      })
      .finally(() => {
        onClose('update');
      });
  }, [fileData, onClose, updateRunning]);

  return (
    <Drawer
      key='right'
      title={'Cargar nuevo cúmulo'}
      open={visible}
      onClose={onClose}
    >
      <InputFile
        onChangeRows={() => {}}
        onChangeFile={onUploadCumuloFile}
        title={'Cúmulo'}
        withoutPreview
        allowClear={true}
      />
      <div className='flex items-center gap-2 mt-4'>
        <RideButton
          href={`${process.env.REACT_APP_WEB_PATH_TEMPLATES_NEW}cumulo.xlsx`}
          variant='text-primary'
          text='Descargar plantilla cúmulo'
          glyph={<RideGlDownloadOutline />}
          glyphPosition='left'
        />
        <RideButton
          disabled={!fileData?.file}
          text='Sincronizar'
          onClick={_handleSyncInit}
          glyph={<RideGlUpdateOutline />}
          glyphPosition='left'
        />
      </div>
    </Drawer>
  );
};

export default memo(DrawerAddCumulo);
