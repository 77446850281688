import { post } from '../lib/ajax';
import {
  TRACKING_IS_LOADING,
  TRACKING_SET_LIST,
  TRACKING_SET_PAGINATION,
} from '../redux/actions/actionsType';

export const getAlertsTracking = async (data = {}, dispatch) => {
  try {
    dispatch({ type: TRACKING_IS_LOADING, payload: true });
    const r = await post('/locations/alertsHistory', data);
    if (r.data.success) {
      const { list, page, total } = r.data.payload;
      dispatch({ type: TRACKING_SET_LIST, payload: list });
      dispatch({
        type: TRACKING_SET_PAGINATION,
        payload: { page, total, pageSize: 5 },
      });
    }
  } catch (e) {
    console.error(e);
  } finally {
    dispatch({ type: TRACKING_IS_LOADING, payload: false });
  }
};

export const downloadExcel = async (data) => {
  const r = await post('/dashboard/reportAlertsTracking', data);
  if (r.data.success) return r.data.payload.url;

  return null;
};
