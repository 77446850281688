import { useState } from 'react';
import { useMapEvents } from 'react-leaflet';

const useZoomEvents = (activeSubLayer, subLayerRegionsObj) => {
  const map = useMapEvents({
    zoomend(e) {
      if (lastZoom < 8 && e.target._zoom >= 8)
        subLayerRegionsObj.current.setParams({
          layers:
            activeSubLayer.styles === 'dark'
              ? 'cmo:lineas_level_2_dark'
              : 'cmo:lineas_level_2',
        });

      if (lastZoom > 7 && e.target._zoom <= 7)
        subLayerRegionsObj.current.setParams({
          layers:
            activeSubLayer.styles === 'dark'
              ? 'cmo:lineas_level_1_dark'
              : 'cmo:lineas_level_1',
        });
      setLastZoom(e.target._zoom);
    },
  });
  const [lastZoom, setLastZoom] = useState(map.getZoom());

  return { map, lastZoom, setLastZoom };
};

export default useZoomEvents;
