import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';

import { QuestionIcon } from '../utils';
import ModalMobileLegend from './ModalMobileLegend';
import InfoIcon from './InfoIcon';

const LegendAcceleration = () => {
  const { isOpened: leftMenuIsOpened } = useSelector((state) => state.sideMenu);
  const [modalLegendVisible, setModalLegendVisible] = useState(false);

  const handleModalLegend = () => {
    setModalLegendVisible(!modalLegendVisible);
  };

  return (
    <div
      className={`legend-sismo ${leftMenuIsOpened && 'with-left-menu-opened'}`}
    >
      <div className='legend-sismo-acceleration--mobile'>
        <button
          className='legend-sismo-icon-mobile'
          onClick={handleModalLegend}
        >
          <QuestionIcon />
        </button>
        <ModalMobileLegend
          visible={modalLegendVisible}
          setVisible={setModalLegendVisible}
        />
      </div>
      <div className='legend-sismo-acceleration'>
        <div className='legend-sismo-acceleration-row'>
          <div className='legend-sismo-acceleration-title'>&ensp;</div>
          <div className='legend-sismo-acceleration-title'>
            Aceleración del suelo cm/s<sup>2</sup>
          </div>
          <div className='legend-sismo-acceleration-title'>Movimiento</div>
          <div className='legend-sismo-acceleration-title'>Daño</div>
        </div>
        <div className='legend-sismo-acceleration-row'>
          <div className='legend-sismo-acceleration-value-1'>1</div>
          <div className='legend-sismo-acceleration-color-1'></div>
          <Tooltip
            title='Movimiento apenas perceptible, puede sentirse ligeramente por algunas personas en reposo o edificios altos.
        Los objetos colgantes pueden moverse suavemente.'
          >
            <div className='legend-sismo-acceleration-moving-1'>
              Débil <InfoIcon />
            </div>
          </Tooltip>
          <div className='legend-sismo-acceleration-damage-1'>Ninguno</div>
        </div>
        <div className='legend-sismo-acceleration-row'>
          <div className='legend-sismo-acceleration-value-2'>10</div>
          <div className='legend-sismo-acceleration-color-2'></div>
          <Tooltip
            title='Vibraciones leves, perceptibles por la mayoría de las personas,
        puede sentirse como un balanceo suave y los objetos colgantes pueden oscilar un poco más.'
          >
            <div className='legend-sismo-acceleration-moving-2'>
              Ligero <InfoIcon />
            </div>
          </Tooltip>
          <div className='legend-sismo-acceleration-damage-2'>Ninguno</div>
        </div>
        <div className='legend-sismo-acceleration-row'>
          <div className='legend-sismo-acceleration-value-3'>20</div>
          <div className='legend-sismo-acceleration-color-3'></div>
          <Tooltip
            title='Movimiento notorio, similar a la sensación dentro de un barco.
        Los objetos pueden caerse o desplazarse y los cristales pueden vibrar.'
          >
            <div className='legend-sismo-acceleration-moving-3'>
              Moderado <InfoIcon />
            </div>
          </Tooltip>
          <div className='legend-sismo-acceleration-damage-3'>Muy Ligero</div>
        </div>
        <div className='legend-sismo-acceleration-row'>
          <div className='legend-sismo-acceleration-value-4'>50</div>
          <div className='legend-sismo-acceleration-color-4'></div>
          <Tooltip
            title='Vibraciones significativas que dificultan caminar,
        los objetos no asegurados pueden llegar a caer y dañar estructuras poco resistentes.'
          >
            <div className='legend-sismo-acceleration-moving-4'>
              Fuerte <InfoIcon />
            </div>
          </Tooltip>
          <div className='legend-sismo-acceleration-damage-4'>Ligero</div>
        </div>
        <div className='legend-sismo-acceleration-row'>
          <div className='legend-sismo-acceleration-value-5'>100</div>
          <div className='legend-sismo-acceleration-color-5'></div>
          <Tooltip
            title='Movimiento muy fuerte que hace difícil mantenerse en pie,
        los objetos se desplazan y caen con facilidad, los daños en estructuras y edificios son comunes.'
          >
            <div className='legend-sismo-acceleration-moving-5'>
              Muy fuerte <InfoIcon />
            </div>
          </Tooltip>
          <div className='legend-sismo-acceleration-damage-5'>Moderado</div>
        </div>
        <div className='legend-sismo-acceleration-row'>
          <div className='legend-sismo-acceleration-value-6'>200</div>
          <div className='legend-sismo-acceleration-color-6'></div>
          <Tooltip title='Vibraciones violentas que pueden causar extensos daños a los edificios.'>
            <div className='legend-sismo-acceleration-moving-6'>
              Severo <InfoIcon />
            </div>
          </Tooltip>
          <div className='legend-sismo-acceleration-damage-6'>Fuerte</div>
        </div>
        <div className='legend-sismo-acceleration-row'>
          <div className='legend-sismo-acceleration-value-7'>500</div>
          <div className='legend-sismo-acceleration-color-7'></div>
          <Tooltip
            title='Movimiento extremadamente intenso que causa daños generalizados a estructuras,
        carreteras y los edificios pueden colapsar.'
          >
            <div className='legend-sismo-acceleration-moving-7'>
              Violento <InfoIcon />
            </div>
          </Tooltip>
          <div className='legend-sismo-acceleration-damage-7'>Muy fuerte</div>
        </div>
        <div className='legend-sismo-acceleration-row'>
          <div className='legend-sismo-acceleration-value-8'>&gt; 1000</div>
          <div className='legend-sismo-acceleration-color-8'></div>
          <Tooltip title='Movimiento extremadamente violento y destructivo, causando daños generalizados en áreas extensas.'>
            <div className='legend-sismo-acceleration-moving-8'>
              Extremo <InfoIcon />
            </div>
          </Tooltip>
          <div className='legend-sismo-acceleration-damage-8'>Extremo</div>
        </div>
      </div>
    </div>
  );
};

export default LegendAcceleration;
