import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Tooltip, Card, Button } from 'antd';
import { LoadingOutlined, DeleteOutlined } from '@ant-design/icons';

import SkeletonContent from '../../../../../common/Skeleton';
import TableMessage from '../../../../../common/Table/TableMessage';
import Table from '../../../../../common/Table/index';
import imgFilter from '../../../../../images/filter.png';
import { useScreen } from '../../../../../lib/screen';
import { checkPermission } from '../../../../../lib/security';
import ModalDelete from './ModalDelete';
import CardHeader from './CardHeader';

moment.locale('es');

const SupportList = ({
  data,
  isLoading,
  onDelete,
  loadData,
  handleOpenCreateRequest,
  setOpenModalFilter,
  user,
  doCloseTicket,
}) => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [rowSelection, setRowSelection] = useState([]);
  const { pagination, filter } = useSelector((state) => state.helpCenter);
  const [loadingClose, setLoadingClose] = useState(false);

  const { widthScreen } = useScreen();

  const isPhone = widthScreen <= 768;

  const handleConfirmDelete = (state) => {
    if (state) {
      onDelete({
        id: rowSelection,
      });
      setRowSelection([]);
    }

    setOpenModal(false);
  };

  const iconRight = ({ color = '#4F4FFF' }) => (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='16'
        cy='16'
        r='15'
        transform='rotate(-90 16 16)'
        stroke={color}
        strokeWidth='2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M13.2532 11.2387C13.5823 10.9204 14.11 10.9204 14.4391 11.2387L18.7468 15.4054C19.0844 15.7319 19.0844 16.2681 18.7468 16.5946L14.4391 20.7613C14.11 21.0796 13.5823 21.0796 13.2532 20.7613C12.9156 20.4347 12.9156 19.8986 13.2532 19.5721L16.9462 16L13.2532 12.4279C12.9156 12.1014 12.9156 11.5653 13.2532 11.2387Z'
        fill={color}
      />
    </svg>
  );

  const iconLeft = ({ color = '#4F4FFF' }) => (
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle
        cx='16'
        cy='16'
        r='15'
        transform='rotate(90 16 16)'
        stroke={color}
        strokeWidth='2'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.7468 11.2387C18.4177 10.9204 17.89 10.9204 17.5609 11.2387L13.2532 15.4054C12.9156 15.7319 12.9156 16.2681 13.2532 16.5946L17.5609 20.7613C17.89 21.0796 18.4177 21.0796 18.7468 20.7613C19.0844 20.4347 19.0844 19.8986 18.7468 19.5721L15.0539 16L18.7468 12.4279C19.0844 12.1014 19.0844 11.5653 18.7468 11.2387Z'
        fill={color}
      />
    </svg>
  );

  const handleTableChange = (pag) => {
    loadData({
      query: filter.query,
      page: pag.current,
      limit: pag.pageSize,
      filter,
    });
  };

  const handleTableChangeMobile = (current) => {
    loadData({
      query: filter.query,
      page: current,
      limit: pagination.pageSize,
      filter,
    });
  };

  const handleViewRequest = (item) => {
    navigate(`/soporte/centro-de-ayuda/${item.id}`);
  };

  const handlerCloseTicket = async (item) => {
    setLoadingClose(item.id);
    await doCloseTicket({ id: item.id });
    setLoadingClose(false);
  };

  const columns = [
    {
      title: 'Usuario',
      dataIndex: 'user',
      key: 'user',
      render: (val, item) => (
        <div onClick={() => handleViewRequest(item)}>
          <div className='table__title'>{`${val?.name} ${val?.last_name1}`}</div>
          <div className='table__subtitle'>{val?.company?.name}</div>
        </div>
      ),
    },
    {
      title: 'Asunto',
      dataIndex: 'subject',
      key: 'subject',
      render: (val, item) => (
        <div onClick={() => handleViewRequest(item)}>
          <div className='table__title'>{val}</div>
          <div className='table__subtitle'>
            {moment(item.createdAt).format('DD MMM YYYY - HH:mm')}
          </div>
        </div>
      ),
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
      render: (state, item) => {
        if (state) {
          const { color, status } = {
            1: { color: 'blue', status: 'Nuevo' },
            2: { color: 'gray', status: 'Cerrado' },
            3: { color: 'yellow', status: 'En proceso' },
          }[state];

          return (
            <div className='flex flex-row flex-justify-justify-between'>
              <span className={`tag-${color} withpoint`}>{status}</span>
              {(checkPermission(user, 'read_all_tickets') ||
                checkPermission(user, 'allows_to_manager_accounts')) &&
                item['status'] === 1 && (
                  <Tooltip title='Cerrar'>
                    <div
                      className='btn-cmp-action-delete'
                      onClick={() => handlerCloseTicket(item)}
                    >
                      {loadingClose === item.id ? (
                        <LoadingOutlined />
                      ) : (
                        <i className='fas fa-check'></i>
                      )}
                    </div>
                  </Tooltip>
                )}
            </div>
          );
        }

        return '';
      },
    },
    {
      title: 'Acciones',
      key: 'actions',
      width: 80,
      render: (__, item) => (
        <Button
          className='d_content__card__button__text view-detail-button'
          type='link'
          onClick={() => handleViewRequest(item)}
        >
          Ver detalle
        </Button>
      ),
    },
  ];

  const onChangeCheck = (e) => {
    if (e.target.checked) setRowSelection([...rowSelection, e.target.value]);
    else
      setRowSelection(rowSelection.filter((item) => item !== e.target.value));
  };

  return (
    <>
      {!isLoading ? (
        <>
          <div className='d_content__list__action flex flex-row'>
            {data.length > 0 && !isLoading && (
              <>
                {pagination.total === 1 ? (
                  <div>
                    Se encontró <b>1</b> solicitudes
                  </div>
                ) : (
                  <div>
                    Se encontraron <b>{pagination.total}</b> solicitudes
                  </div>
                )}
                <div
                  className='d_content__list__action__filter flex'
                  onClick={() => setOpenModalFilter(true)}
                >
                  <img
                    alt=''
                    src={imgFilter}
                    style={{ width: 11.52, height: 11.52 }}
                  ></img>
                  <p className='d_content__list__action__filter__text'>
                    Filtrar
                  </p>
                </div>
              </>
            )}

            {!isPhone &&
              Object.keys(rowSelection).length !== 0 &&
              user &&
              !['admin_rimac', 'gestor_de_cuenta'].includes(user.rol.slug) && (
                <div
                  className='d_content__list__action__button flex flex-row ml-2'
                  onClick={() => setOpenModal(true)}
                >
                  <span className='d_content__list__action__button__icon mr-1'>
                    <DeleteOutlined />
                  </span>
                  <span className='d_content__list__action__button__text'>
                    BORRAR
                  </span>
                </div>
              )}
          </div>

          {isPhone
            ? data.map((item, index) => {
                return (
                  <Card
                    key={index}
                    style={{ width: '100%' }}
                    className='d_content__card'
                  >
                    {item.status ? (
                      <CardHeader
                        status={item.status}
                        id={item.id}
                        rowSelection={rowSelection}
                        onChangeCheck={onChangeCheck}
                      />
                    ) : (
                      ''
                    )}

                    <div className='d_content__card__body not-gap'>
                      <h4>Solicitud</h4>
                      <div className='d_content__card__body__subject'>
                        {item.subject}
                      </div>
                      <div className='d_content__card__body__createdAt'>
                        {moment(item.createdAt).format('DD MMM YYYY - HH:mm')}
                      </div>
                    </div>
                    <div className='d_content__card__button'>
                      <Button
                        className='d_content__card__button__text'
                        type='link'
                        onClick={() => handleViewRequest(item)}
                      >
                        Ver detalle
                      </Button>
                    </div>
                  </Card>
                );
              })
            : null}

          {data.length === 0 && !isLoading ? (
            <div style={{ paddingBottom: '40px' }}>
              <TableMessage message={'solicitudes'} />
            </div>
          ) : (
            <div style={{ marginTop: '16px' }}>
              <Table
                className='table_selection'
                columns={columns}
                dataSource={data}
                rowKey={(record) => record.id}
                pagination={pagination}
                onChange={handleTableChange}
                locale={{
                  emptyText: <span>No se cuenta con solicitudes</span>,
                }}
              />
            </div>
          )}

          {data.length > 0 && (
            <div className='d_content__list__pag'>
              <div
                className={'d_content__list__pag__button'}
                onClick={() => handleTableChangeMobile(pagination.current - 1)}
              >
                {iconLeft({
                  color: pagination.current === 1 ? '#A9AFD9' : '#4F4FFF',
                })}
              </div>
              <div className='d_content__list__pag__number color-red-1'>
                {pagination.current}
              </div>
              <div className='d_content__list__pag__sep'>/</div>
              <div className='d_content__list__pag__number color-gray-3'>
                {Math.ceil(pagination.total / pagination.pageSize)}
              </div>
              <div
                className={'d_content__list__pag__button'}
                onClick={() => handleTableChangeMobile(pagination.current + 1)}
              >
                {iconRight({
                  color:
                    pagination.current === pagination.pageSize
                      ? '#A9AFD9'
                      : '#4F4FFF',
                })}
              </div>
            </div>
          )}

          {isPhone &&
          user &&
          !['admin_rimac', 'gestor_de_cuenta'].includes(user.rol.slug) ? (
            <div className='d_content__list__button'>
              <Button
                className='d_content__list__button__text'
                type='primary'
                onClick={handleOpenCreateRequest}
                style={{ marginLeft: '0' }}
              >
                Nueva Solicitud
              </Button>
            </div>
          ) : null}

          {isPhone && rowSelection.length ? (
            <>
              <div className='d_content__selected_item_gradient'></div>
              <div className='d_content__selected_item'>
                <Button
                  type='text'
                  className='d_content__selected_item__cancel'
                  onClick={() => setRowSelection([])}
                >
                  Cancelar
                </Button>

                <p className='d_content__selected_item__text'>{`${
                  rowSelection.length
                }${'  '} Seleccionada${rowSelection.length > 1 ? 's' : ''}`}</p>
                {user &&
                  !['admin_rimac', 'gestor_de_cuenta'].includes(
                    user.rol.slug,
                  ) && (
                    <Button
                      type='text'
                      className='d_content__selected_item__delete'
                      onClick={() => setOpenModal(true)}
                    >
                      Eliminar
                    </Button>
                  )}
              </div>
            </>
          ) : null}
        </>
      ) : (
        <SkeletonContent type='table' totalItems={3} />
      )}
      <ModalDelete
        visible={openModal}
        onConfirm={handleConfirmDelete}
        items={rowSelection}
      />
    </>
  );
};

export default SupportList;
