import {
  LOAD_NEWSLETTERS_LOADING,
  LOADED_NEWSLETTERS,
  CHANGE_NEWSLETTERS_QUERY,
  CHANGE_NEWSLETTERS_FILTER,
} from '../actions/actionsType';

const initialState = {
  newsletters: [],
  isLoading: false,
  page: 1,
  total: 0,
  query: '',
  filter: false,
  pagination: {
    pageSize: 5,
    current: 1,
    total: 0,
  },
};

const newsletters = (state, action) => {
  // NOSONAR
  if (!state) state = initialState;

  switch (action.type) {
    case LOAD_NEWSLETTERS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case LOADED_NEWSLETTERS:
      if (state.query && state.query !== action.payload.query) return state;

      return {
        ...state,
        newsletters: action.payload.list,
        page: action.payload.page,
        total: action.payload.total,
        pagination: {
          current: action.payload.page,
          pageSize: action.payload.items_per_page,
          total: action.payload.total,
        },
        isLoading: false,
      };
    case CHANGE_NEWSLETTERS_QUERY:
      return {
        ...state,
        query: action.payload || '',
      };
    case CHANGE_NEWSLETTERS_FILTER:
      return {
        ...state,
        filter: action.payload || '',
      };
    default:
      return state;
  }
};

export default newsletters;
