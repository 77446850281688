import React from 'react';
import PropTypes from 'prop-types';

const Paragraph = ({ type, className, children, ...props }) => {
  return (
    <div className={`typography-paragraph--${type} ${className}`} {...props}>
      {children}
    </div>
  );
};

Paragraph.defaultProps = {
  type: 'regular-16',
  className: '',
};

Paragraph.propTypes = {
  type: PropTypes.oneOf([
    'light-14',
    'light-16',
    'regular-16',
    'regular-14',
    'bold-16',
    'regular-18',
  ]),
};

export default Paragraph;
