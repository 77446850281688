import React from 'react';
import { Button } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  UserOutlined,
  ReloadOutlined,
  CopyOutlined,
} from '@ant-design/icons';

import { checkPermission } from '../../../../../../../lib/security';

const CardUser = ({
  data,
  user,
  _handleEditUser,
  _handleViewAsUser,
  _handleResendEmail,
  _handleCopyLink,
  _handleDeleteUser,
}) => {
  return (
    <article className='card-user'>
      {
        <div className='flex pos-end'>
          <span
            style={{
              background:
                data.state === 1
                  ? '#A7EB92'
                  : data.state === 3
                  ? '#f87a7e'
                  : '#D7DBF5',
            }}
            className='table-state'
          >
            <div
              style={{
                background:
                  data.state === 1
                    ? '#389E0D'
                    : data.state === 3
                    ? '#FF1C44'
                    : '#7981B2',
              }}
              className='point'
            ></div>
            <span>
              {data.state === 1
                ? 'Activo'
                : data.state === 3
                ? 'Eliminado'
                : 'Inactivo'}
            </span>
          </span>
        </div>
      }

      <div>
        <h4>Nombre completo</h4>
        <p>{`${data.name} ${data.last_name1}`}</p>
      </div>

      <div>
        <h4>Correo electrónico</h4>
        <p>{data.username}</p>
      </div>

      <div>
        <h4>Celular</h4>
        <p>{data.phone}</p>
      </div>

      <div className='actions'>
        <Button
          icon={<EditOutlined />}
          shape='circle'
          size='small'
          onClick={() => _handleEditUser(data)}
        />

        {data.state === 1 &&
          checkPermission(user, 'view_session_as_others_users') && (
            <Button
              icon={<UserOutlined />}
              shape='circle'
              size='small'
              disabled={data?.company?.state !== 1}
              onClick={() => _handleViewAsUser(data)}
            />
          )}

        {data.state !== 1 &&
          data.state !== 3 &&
          data.code_invitation !== '' &&
          checkPermission(user, 'allows_to_resend_registration_email') && (
            <Button
              icon={<ReloadOutlined />}
              shape='circle'
              size='small'
              onClick={() => _handleResendEmail(data)}
            />
          )}

        {data.state !== 1 &&
          data.state !== 3 &&
          data.code_invitation !== '' &&
          checkPermission(user, 'allows_to_resend_registration_email') && (
            <Button
              icon={<CopyOutlined />}
              shape='circle'
              size='small'
              onClick={() => _handleCopyLink(data)}
            />
          )}

        <Button
          icon={<DeleteOutlined />}
          shape='circle'
          size='small'
          onClick={() => _handleDeleteUser(data)}
          disabled={
            data.username === user.username ||
            !['Usuario Empresa', 'Administrador Cliente'].includes(
              data?.rol?.name,
            )
          }
        />
      </div>
    </article>
  );
};

export default CardUser;
