import React from 'react';

export const config = {
  lluvias: { acumulacion: 60, periodo: 24 },
  viento: { fuerza: 30 },
  temperatura: { max: 35, min: 30 },
  rayos: { alerta1: 20, alerta2: 10, alerta3: 5 },
};

export const genSliderMark = (number, metric) => {
  return (
    <>
      <div style={{ lineHeight: 1.5 }}>{number}</div>
      <div style={{ lineHeight: 1 }}>{metric}</div>
    </>
  );
};
