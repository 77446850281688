import _ from 'lodash';

export const getRequestServiceItemDataId = ({ item }) => {
  if (!item) return null;

  return item.requestServiceItemsData?.find(
    (service) => service.key === 'courses',
  )?.id;
};

export const getCourses = ({ item }) => {
  if (!item) return [];
  let courses = item?.requestServiceItemsData?.find(
    (service) => service.key === 'courses',
  );
  courses = courses?.value && JSON.parse(courses?.value);

  return _.map(courses, (value, prop) => ({ prop, value }));
};

export const getHasTechnicalReport = ({ item, courseKey }) => {
  const foundItem = item?.request_service_item_results?.find(
    (result) => result.course === courseKey,
  );

  return Boolean(foundItem);
};
