import { memo, useEffect, useMemo, useState } from 'react';
import { Button, Input } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { getParticipantsList } from '../../../../../../services/requestServices';

import ParticipanttList from './components/ParticipantList';
import SkeletonContent from '../../../../../../common/Skeleton';
import TableMessage from '../../../../../../common/Table/TableMessage';

const { Search } = Input;

const Participants = ({ courseInfo, state, setHasCertificate }) => {
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState(rows);
  const find = (e) => {
    const txt = e.target.value;
    setSearch(txt.toLowerCase());
  };

  const handleClearFilter = () => {
    setSearch('');
  };

  useEffect(() => {
    const getParticipants = async () => {
      try {
        setLoading(true);
        const participantsList = await getParticipantsList(
          courseInfo?.courseKey,
        );

        Array.isArray(participantsList)
          ? setRows(participantsList)
          : setRows([]);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    getParticipants();
  }, [courseInfo]);

  useMemo(() => {
    const result = rows.filter((row) =>
      `${row.name} ${row.email} ${row.dni} ${row.position} ${row.score}`
        .toLowerCase()
        .includes(search),
    );

    setFilteredRows(result);
  }, [rows, search]);

  return (
    <div className='cmo_container'>
      <div className='d_content'>
        {loading ? (
          <SkeletonContent type='table' totalItems={5} />
        ) : rows.length > 0 ? (
          <>
            <div className='filter__text mb-4'>Filtra participantes</div>
            <div className='filter__box flex flex-row flex-wrap pb-4 mb-4'>
              <Search
                className='mr-2'
                placeholder='Palabra clave'
                style={{ width: 432 }}
                value={search}
                onChange={find}
              />
              <Button
                type='link'
                className='btn-clearFilter'
                onClick={handleClearFilter}
              >
                <DeleteOutlined />
                Borrar Filtros
              </Button>
            </div>
            <div className='d_content__list'>
              <ParticipanttList
                data={filteredRows}
                loadingInfo={loading}
                courseInfo={courseInfo}
                status={state}
                setHasCertificate={setHasCertificate}
              />
            </div>
          </>
        ) : (
          <TableMessage
            complete={true}
            message='El proveedor de la capacitación se encuentra registrando la lista de participantes.'
          />
        )}
      </div>
    </div>
  );
};

export default memo(Participants);
