import React, { useEffect, useState } from 'react';
import { Button, Divider, Modal } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';

import { useScreen } from '../../../../lib/screen';
import { IconCloseRed } from '../../ui/PreventionService/Reports/utils';
import Title from '../../../../common/Typograph/Title';
import InputFile from '../../../../common/InputFile';
import { secureRandom } from '../../../../lib/utils';

const equivalences = {
  'FECHA DE EVENTO': 'fechaEmergencia',
  'FECHA DE ACTUALIZACION': 'fechaActualizacion',
  'CONDICION DE TRANSITO': 'condicionTransitoCodigo',
  UBIGEO: 'ubigeo',
  'TIPO DE EMERGENCIA': 'tipoEmergencia',
  ESTADO: 'estado',
  LATITUD: 'latitud',
  LONGITUD: 'longitud',
};

const ModalForm = ({ handleClose, visible, setRows }) => {
  const [points, setPoints] = useState(null);
  const { isMobileScreen } = useScreen();

  const handleOk = () => {
    if (points.length > 0) setRows(points);
    handleClose();
  };

  const handleCancel = () => {
    handleClose();
  };

  const onUploadFile = (fileInfo) => {
    let reader = new FileReader();
    reader.readAsArrayBuffer(fileInfo.file);
    reader.onloadend = (e) => {
      let data = new Uint8Array(e.target.result);
      let workbook = XLSX.read(data, { type: 'array' });
      workbook.SheetNames.slice(0, 1).forEach(function (sheetName) {
        const XL_row_object = XLSX.utils.sheet_to_row_object_array(
          workbook.Sheets[sheetName],
        );
        const result = XL_row_object.map((obj) =>
          Object.keys(obj).reduce(
            (a, b) => {
              a[equivalences[b]] = obj[b];

              return a;
            },
            { state: 1, source: 'manual', id: secureRandom() },
          ),
        );
        setPoints(result);
      });
    };
  };

  useEffect(() => {
    const elements = document.querySelectorAll('.ant-popover');
    if (elements.length > 0)
      elements.forEach((element) => {
        element.classList.add('ant-popover-hidden');
      });
  }, [visible]);

  return (
    <Modal
      open={visible}
      title=''
      closable={true}
      onCancel={() => handleCancel()}
      closeIcon={<IconCloseRed color='#A9AFD9' />}
      centered={true}
      onOk={handleOk}
      styles={{
        body: isMobileScreen
          ? { width: '100%' }
          : { padding: '64px 64px 12px 64px', minWidth: 528 },
      }}
      className={`modal-provider modal-centered ${
        isMobileScreen ? 'modal-mobile' : ''
      }`}
      cancelText='Cancelar'
      okText='Subir CSV'
    >
      <div>
        <Title type='bold-28' style={{ textAlign: 'center' }}>
          Cargar CSV con los puntos de Emergencia Vial
        </Title>
        <Divider className='mt-2 mb-4' />
        <InputFile
          onChangeFile={onUploadFile}
          title={'Emergencias Viales'}
          withoutPreview
        />
        <Button
          className='mt-3'
          href='/templates/PlantillaEmergenciasViales.xlsx'
          icon={<DownloadOutlined />}
          type='link'
        >
          Descargar plantilla de Emergencias Viales
        </Button>
      </div>
    </Modal>
  );
};

export default ModalForm;
