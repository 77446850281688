import { Drawer as DrawerAntd } from 'antd';
import { RideGlError } from '@rimac-seguros/ride-system-components';
import { useScreen } from '../../lib/screen';

const DefaultCloseIcon = () => (
  <>
    Cerrar
    <RideGlError size='large' />
  </>
);

const Drawer = ({ children, open, onClose, closeIcon, placement, footer }) => {
  const { isMobileScreen } = useScreen();

  return (
    <DrawerAntd
      open={open}
      onClose={onClose}
      closeIcon={closeIcon || <DefaultCloseIcon />}
      width={isMobileScreen ? '100%' : '432px'}
      placement={placement || 'right'}
      className='drawer'
      footer={footer}
    >
      {children}
    </DrawerAntd>
  );
};

export default Drawer;
