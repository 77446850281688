import {
  LOAD_EVENTS_LOADING,
  LOADED_EVENTS,
  LOADING_CREATE_EVENT,
  OPEN_FORM_EVENT,
  CHANGE_EVENTS_QUERY,
} from '../actions/actionsType';

const initialState = {
  events: [],
  openedForm: false,
  eventShow: null,
  isLoading: false,
  isLoadingCreate: false,
  query: '',
  pagination: {
    pageSize: 5,
    current: 1,
    total: 0,
  },
};

const events = (state, action) => {
  if (!state) state = initialState;

  switch (action.type) {
    case LOAD_EVENTS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
        isLoadingCreate: false,
      };
    case LOADED_EVENTS:
      if (state.query && state.query !== action.payload.query) return state;

      return {
        ...state,
        events: action.payload.list,
        isLoading: false,
        pagination: {
          current: action.payload.page,
          pageSize: action.payload.items_per_page,
          total: action.payload.total,
        },
      };
    case OPEN_FORM_EVENT:
      return {
        ...state,
        openedForm: action.payload,
      };
    case LOADING_CREATE_EVENT:
      return {
        ...state,
        isLoadingCreate: action.payload,
      };
    case CHANGE_EVENTS_QUERY:
      return {
        ...state,
        query: action.payload || '',
      };
    default:
      return state;
  }
};

export default events;
