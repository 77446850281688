import React, { useState, useEffect } from 'react';

const SubLayer = ({ subLayers, onSubLayerChange }) => {
  const defaultSubLayer =
    subLayers.find((subLayer) => subLayer.default) || null;
  const [selectedSubLayer, setSelectedSubLayer] = useState(defaultSubLayer);

  useEffect(() => {
    const newDefaultSubLayer =
      subLayers.find((subLayer) => subLayer.default) || null;
    if (newDefaultSubLayer && newDefaultSubLayer.id !== selectedSubLayer?.id) {
      setSelectedSubLayer(newDefaultSubLayer);
    }
  }, [subLayers]);

  useEffect(() => {
    onSubLayerChange(selectedSubLayer);
  }, [selectedSubLayer, onSubLayerChange]);

  const handleCheckboxChange = (subLayer) => {
    if (selectedSubLayer?.id === subLayer.id) {
      setSelectedSubLayer(null);
    } else {
      setSelectedSubLayer(subLayer);
    }
  };

  return (
    <div className='subLayerContainer'>
      {subLayers.map((subLayer) => (
        <div
          key={subLayer.id}
          className={`subLayerItem ${
            selectedSubLayer?.id === subLayer.id ? 'active' : ''
          }`}
        >
          <input
            type='checkbox'
            id={subLayer.id}
            checked={selectedSubLayer?.id === subLayer.id}
            onChange={() => handleCheckboxChange(subLayer)}
          />
          <label htmlFor={subLayer.id}>{subLayer.name}</label>
        </div>
      ))}
    </div>
  );
};

export default SubLayer;
