import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Typography } from 'antd';
import { checkPermission } from '../../../lib/security';
import {
  RideButton,
  RideGlUploadOutline,
} from '@rimac-seguros/ride-system-components';

import Topbar from '../../../common/ui/Topbar';
import Locations from './Locations';
import Alerts from './Alerts2';
import { TRACK } from '../../../lib/consts';
import { pushDigitalData } from '../../../lib/utils';
import Tabs from '../../../common/Tabs';

const { Title } = Typography;

const getTabs = (user) => {
  const TABS = [
    { label: 'Mis Ubicaciones', children: <Locations />, key: '1' },
    {
      label: 'Alertas',
      children: <Alerts />,
      key: '2',
      permission: checkPermission(user, 'access_subview_alerts_configuration'),
    },
  ];

  return TABS.filter(
    (tab) => !tab.hasOwnProperty('permission') || tab.permission,
  );
};

const TabsConfiguration = () => {
  const [activeTab, setActiveTab] = useState('1');
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth.user);

  const handleChange = (key) => {
    setActiveTab(key);
    handleClickTab(key === '1' ? 'Mis Ubicaciones' : 'Alertas');
  };

  const handleClickTab = (label) => {
    if (process.env.REACT_APP_ANALYTICS_ENABLE !== '0') {
      pushDigitalData(
        {
          action: {
            group: 'Centro Monitoreo',
            category: 'Configuración',
            name: 'Click',
            label: `Tab - ${label}`,
          },
        },
        TRACK.ACTION,
      );
    }
  };

  const handlePushDigital = (label) => {
    if (process.env.REACT_APP_ANALYTICS_ENABLE !== '0') {
      pushDigitalData(
        {
          action: {
            group: 'Centro Monitoreo',
            category: 'Configuración',
            name: 'Mis ubicaciones',
            label,
          },
        },
        TRACK.ACTION,
      );
    }
  };

  return (
    <div id='checkSize' className='h100vh dashboard-UI'>
      <Topbar />
      <div className='d_content'>
        <div className='flex justify-between gap-2 mb-2'>
          <Title className='header-container'>Configuración</Title>
          {activeTab === '1' &&
            checkPermission(user, 'allow_massive_upload_locations') && (
              <RideButton
                onClick={() => {
                  handlePushDigital('Carga masiva');
                  navigate('/monitoreo/configuracion/carga-masiva');
                }}
                text='Carga masiva'
                glyph={<RideGlUploadOutline />}
                glyphPosition='left'
                size='medium'
              />
            )}
        </div>
        <Tabs
          defaultActiveKey='1'
          onChange={handleChange}
          items={getTabs(user)}
          className='tabs-custom'
        />
      </div>
    </div>
  );
};

export default TabsConfiguration;
