import { useCallback, useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Skeleton, Switch } from 'antd';

import { SELECT_ITEM_COMPANY } from '../../../../../redux/actions/actionsType';

import Table from '../../../../../common/Table';
import PopoverActions from '../../../../../common/Table/PopoverActions';
import TableMessage from '../../../../../common/Table/TableMessage';

import { useScreen } from '../../../../../lib/screen';
import openNotification from '../../../../../common/Notification';

import {
  changeStatusCompany,
  getCompanies,
  changePriorizitedCompany,
} from '../../../../../services/company';
import StateCell from './StateCell';

import FilterSection from '../../../ui/PreventionService/components/FilterSection';
import {
  CardSkeleton,
  IconEditTable,
  IconEye,
  iconLeft,
  iconRight,
} from '../../../ui/PreventionService/Reports/utils';
import BusinessItem from './BusinessItem';
import { useNavigate } from 'react-router-dom';
import { CompaniesContext } from '..';

const BusinessList = () => {
  const dispatch = useDispatch();
  const { list, isLoading, pagination } = useSelector((state) => ({
    list: state.companies.companies || [],
    isLoading: state.companies.isLoadingCompanies,
    pagination: state.companies.pagination,
  }));
  const navigate = useNavigate();
  const { isMobileScreen } = useScreen();
  const userAuth = useSelector((state) => state.auth.user);
  const successMessage = localStorage.getItem('companySuccess');
  const [rows, setRows] = useState([]);
  const [textSearch, setTextSearch] = useState('');
  const [changeState, setChangeState] = useState(false);
  const { _handleAction, setOpenPolizas } = useContext(CompaniesContext);

  const columns = [
    {
      title: 'Ruc',
      dataIndex: 'ruc',
      key: 'ruc',
      width: '120px',
    },
    {
      title: 'Nombre comercial',
      dataIndex: 'name',
      key: 'name',
      width: '200px',
    },
    {
      title: 'Razón Social',
      dataIndex: 'business_name',
      key: 'business_name',
      width: '200px',
    },
    {
      title: 'Segmento comercial',
      dataIndex: 'typeIndustry',
      key: 'typeIndustry',
      width: '200px',
      render: (__, item) => `${item.businessSegment?.name || ''}`,
    },
    {
      title: 'Admin. empresa principal',
      dataIndex: 'principalAdmin',
      key: 'principalAdmin',
      width: '200px',
      render: (text) =>
        text ? `${text.name} ${text.last_name1} ${text.last_name2}` : '',
    },
    {
      title: 'Gestor de cuenta',
      dataIndex: 'companiesUsers',
      key: 'companiesUsers',
      width: '200px',
      render: (data) => {
        const user = data[0] || false;

        return user
          ? `${user?.name} ${user?.last_name1} ${user?.last_name2}`
          : '';
      },
    },
    {
      title: 'Estado',
      dataIndex: 'state',
      key: 'state',
      width: '150px',
      render: (value, item) => {
        return <StateCell value={value} item={item} />;
      },
    },
    {
      title: 'Usuarios',
      dataIndex: 'users_count',
      key: 'users_count',
      width: '120px',
      render: (__, item) => (item.users ? item.users.length : 0),
    },
    {
      title: 'Priorizado',
      dataIndex: 'prioritized',
      key: 'prioritized',
      width: '160px',
      render: (value, item) => {
        return (
          <div className='state-business'>
            <span>{value === 1 ? 'Priorizado' : 'No priorizado'}</span>
            <Switch
              checked={value === 1}
              onChange={() =>
                item.handleChangePriorizited(item, value === 1 ? 0 : 1)
              }
              disabled={item.changeState}
            />
          </div>
        );
      },
    },
    {
      dataIndex: 'actions',
      title: 'Acciones',
      fixed: 'right',
      key: 'actions',
      width: 80,
      render: (__, item) => {
        return (
          <PopoverActions
            actions={
              <>
                {item?.userAuth?.rol?.slug !== 'comercial' && (
                  <Button onClick={item.handleEdit(item)}>
                    <IconEditTable /> <span>Editar</span>
                  </Button>
                )}
                <Button onClick={item.handleEViewPolizas(item)}>
                  <IconEye /> <span>Ver Pólizas</span>
                </Button>
              </>
            }
          />
        );
      },
    },
  ];

  const load = (paginationParam) => getCompanies(dispatch, paginationParam);
  const changeStatus = (item, state, paginationParam) =>
    changeStatusCompany(item, state, dispatch, paginationParam);
  const changePriorizited = (item, state, paginationParam) =>
    changePriorizitedCompany(item, state, dispatch, paginationParam);
  const toggleSelectCompany = (item) => {
    dispatch({ type: SELECT_ITEM_COMPANY, payload: item });
  };

  const handleFilter = (e) => {
    const txt = e.target.value.trim();
    setTextSearch(txt);

    if (txt.length >= 2) {
      load({
        query: txt,
        page: 1,
        limit: pagination.pageSize,
      });
    } else {
      load({
        query: '',
        page: 1,
        limit: pagination.pageSize,
      });
    }
  };

  const handleChangeState = (item, state) => {
    setChangeState(true);
    changeStatus(item, state, {
      page: pagination.current,
      limit: pagination.pageSize,
      query: textSearch,
    });
    setChangeState(false);
  };

  const handleChangePriorizited = (item, value) => {
    setChangeState(true);
    changePriorizited(item, value, {
      page: pagination.current,
      limit: pagination.pageSize,
      query: textSearch,
    });
    setChangeState(false);
  };

  const handleEdit = useCallback(
    (item) => async () => {
      _handleAction(true, item);
    },
    [_handleAction],
  );

  const handleEViewPolizas = useCallback(
    (item) => async () => {
      toggleSelectCompany(item);
      setOpenPolizas(true);
    },
    [setOpenPolizas, toggleSelectCompany],
  );

  const handleTableChange = (pag) =>
    load({
      page: pag.current,
      limit: pag.pageSize,
      query: textSearch,
    });

  const handleTableChangeMobile = (current) => {
    if (
      current === 0 ||
      Math.ceil(pagination.total / pagination.pageSize) + 1 === current
    ) {
      return;
    }

    load({
      page: current,
      limit: pagination.pageSize,
      query: textSearch,
    });
  };

  useEffect(() => {
    load({ page: pagination.current, limit: pagination.pageSize });
  }, []);

  useEffect(() => {
    setRows(list);
  }, [list]);

  useEffect(() => {
    if (successMessage) {
      openNotification('success', 'Empresa', successMessage);
      localStorage.removeItem('companySuccess');
    }
  }, [successMessage]);

  return (
    <>
      <FilterSection
        complete={true}
        text={`${
          isMobileScreen ? 'Filtra tu búsqueda' : 'Filtrar lista de empresas'
        }`}
        handleFilter={handleFilter}
      />

      {!isMobileScreen ? (
        !isLoading && list.length === 0 ? (
          <TableMessage complete={true} message='No se encontrarón empresas' />
        ) : (
          <section className='show-data'>
            {isLoading ? (
              <Skeleton active title={false} paragraph={{ rows: 1 }} />
            ) : (
              <p className='info-total-data'>
                Se encontraron <span>{pagination.total}</span>{' '}
                {pagination.total === 1 ? 'empresa' : 'empresas'}
              </p>
            )}

            <Table
              dataSource={rows.map((row) => ({
                ...row,
                changeState,
                handleChangeState,
                handleChangePriorizited,
                handleEdit,
                handleEViewPolizas,
                userAuth,
              }))}
              columns={columns}
              rowKey={(record) => record.id}
              loading={isLoading}
              locale={{ emptyText: 'No se encontraron empresas.' }}
              pagination={pagination}
              onChangeRows={setRows}
              onChange={handleTableChange}
            />
          </section>
        )
      ) : (
        <section className='content-mobile action-bottom'>
          {isLoading ? (
            <Skeleton active title={false} paragraph={{ rows: 1 }} />
          ) : (
            <p className='info-total-data'>
              Se encontraron <span>{pagination.total}</span>{' '}
              {pagination.total === 1 ? 'empresa' : ' empresas'}
            </p>
          )}

          {isLoading ? (
            [1, 2, 3, 4].map((el) => <CardSkeleton key={el} />)
          ) : rows.length === 0 ? (
            <TableMessage
              complete={true}
              message='No se encontrarón  empresas'
            />
          ) : (
            rows.map((data) => (
              <BusinessItem
                key={data.id}
                data={data}
                onEdit={handleEdit(data)}
                onViewPolizas={handleEViewPolizas(data)}
                onChangeState={handleChangeState}
                changeState={changeState}
                userAuth={userAuth}
              />
            ))
          )}

          <div className='d_content__list__pag'>
            <div
              className={'d_content__list__pag__button'}
              onClick={() => handleTableChangeMobile(pagination.current - 1)}
            >
              {iconLeft({
                color: pagination.current === 1 ? '#A9AFD9' : '#4F4FFF',
              })}
            </div>

            <div className='d_content__list__pag__number color-red-1'>
              {pagination.current}
            </div>
            <div className='d_content__list__pag__sep'>/</div>
            <div className='d_content__list__pag__number color-gray-3'>
              {Math.ceil(pagination.total / pagination.pageSize)}
            </div>

            <div
              className={'d_content__list__pag__button'}
              onClick={() => handleTableChangeMobile(pagination.current + 1)}
            >
              {iconRight({
                color:
                  pagination.current ===
                  Math.ceil(pagination.total / pagination.pageSize)
                    ? '#A9AFD9'
                    : '#4F4FFF',
              })}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default BusinessList;
