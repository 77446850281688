import React from 'react';

const Roads = (props) => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M5 37.5H2.5V2.5H5V37.5ZM37.5 6.875H20V2.5H17.5V9.375H37.5V6.875ZM12.3438 2.5H10.1562V6.25H12.3438V2.5ZM12.3438 8.75H10.1562V12.5H12.3438V8.75ZM12.3438 15H10.1562V18.75H12.3438V15ZM12.3438 21.25H10.1562V25H12.3438V21.25ZM12.3438 27.5H10.1562V31.25H12.3438V27.5ZM12.3438 33.75H10.1562V37.5H12.3438V33.75ZM35 13.2812H31.25V15.4688H35V13.2812ZM28.75 13.2812H25V15.4688H28.75V13.2812ZM22.5 13.2812H18.75V15.4688H22.5V13.2812ZM26.8031 19.375H17.5V37.5H20V21.875H24.3188C24.3672 21.8172 24.4172 21.7609 24.4719 21.7062L26.8031 19.375Z'
        fill='url(#paint0_linear_827_16652)'
      />
      <path
        d='M38.3062 22.6781L32.9468 17.3188C32.3546 16.7266 31.3952 16.7266 30.803 17.3188L25.4437 22.6781C24.8515 23.2703 24.8515 24.2297 25.4437 24.8219L30.6249 30.0031V37.5H33.1249V30.0031L38.3062 24.8219C38.8983 24.2297 38.8983 23.2703 38.3062 22.6781ZM33.9796 24.1469L32.6577 22.825V27.5016H31.0952V22.825L29.7733 24.1469L28.6687 23.0422L31.878 19.8328L35.0874 23.0422L33.9827 24.1469H33.9796Z'
        fill='url(#paint1_linear_827_16652)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_827_16652'
          x1='2.5'
          y1='2.5'
          x2='21.2629'
          y2='44.7165'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.272135' stopColor='#34263B' />
          <stop offset='0.658079' stopColor='#13172C' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_827_16652'
          x1='25.3592'
          y1='18.4933'
          x2='41.0461'
          y2='28.9516'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.35' stopColor='#F7052D' />
          <stop offset='0.85' stopColor='#CA5AFA' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Roads;
