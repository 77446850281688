import React, { useCallback, useEffect, useState } from 'react';
import { Form, Modal, Select, Input, message, Button, Divider } from 'antd';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import text from '../../../../config/text_es';
import { getAllCourses } from '../../../../services/courses';
import {
  generateCertificates,
  getParticipants,
  updateDataParticipants,
} from '../../../../services/requestServicesItemsDataParticipants';
import ListParticipants from './ListParticipants';
import { addResult } from '../../../../services/requestServicesItemsResults';
import ButtonReportParticipants from './ButtonReportParticipants';
import ButtonReportCertificates from './ButtonReportCertificates';
import ButtonUploadParticipants from './ButtonUploadParticipants';

import {
  getCourses,
  getHasTechnicalReport,
  getRequestServiceItemDataId,
} from './utils';
import { useScreen } from '../../../../lib/screen';
import ModalUploadTechnicalReport from '../../../../common/ModalUploadTechnicalReport';
import Title from '../../../../common/Typograph/Title';

const { Option } = Select;

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 },
};

const ModalCheckParticipants = ({
  visible,
  courseSelect,
  itemId,
  itemList,
  onClose,
  update,
  loadParticipants,
  list,
  loadListCourses,
  generateFailed,
  loading,
  loadingGenerate,
  loadingUpdate,
  makeCertificates,
  doAddResult,
  pagination,
}) => {
  const [form] = Form.useForm();
  const { getFieldValue, setFieldsValue } = form;

  const { isMobileScreen } = useScreen();
  const [participants, setParticipants] = useState([]);
  const [item, setItem] = useState(null);
  const [hasTecnicalReport, setHasTecnicalReport] = useState(false);
  const [requestServiceItemDataId, setRequestServiceItemDataId] =
    useState(false);
  const [isChangeCertificateLoading, setChangeCertificateLoading] =
    useState(false);
  const [editParticipants, setEditParticipants] = useState(false);
  const [visibleUpload, setVisibleUpload] = useState(false);

  useEffect(() => {
    if (list && list.length > 0) {
      setParticipants(list.map((l) => ({ ...l, key: uuidv4() })));
    } else {
      setParticipants([]);
    }
    setEditParticipants(false);
  }, [list]);

  useEffect(() => {
    if (itemList && itemId) setItem(itemList.find(({ id }) => id === itemId));
  }, [itemList, itemId]);

  useEffect(() => {
    if (generateFailed) message.error('Ha corrido un error');
  }, [generateFailed]);

  const handlerLoadParticipants = useCallback(
    (courses, course_key, requestServiceItemDataId) => {
      setHasTecnicalReport(
        getHasTechnicalReport({ item, courseKey: course_key }),
      );
      const course = courses?.find((course) => course.prop === course_key);
      const hasExam = course?.value.hasExam;
      form.setFieldsValue({
        hasExam: Number.isInteger(hasExam) ? hasExam : '',
      });
      setParticipants([]);
      loadParticipants({
        course_key,
        requestServiceItemDataId,
      });
    },
    [form, item, loadParticipants],
  );

  useEffect(() => {
    if (!item) return;
    const rsiDataId = getRequestServiceItemDataId({ item });
    setRequestServiceItemDataId(rsiDataId);
    const course_key = courseSelect?.key;
    if (course_key) {
      const courses = getCourses({ item });
      setFieldsValue({ course_key });
      handlerLoadParticipants(courses, course_key, rsiDataId);
    }
  }, [item, courseSelect]);

  useEffect(() => {
    loadListCourses();

    return () => {
      setRequestServiceItemDataId(false);
      setItem(null);
    };
  }, []);

  const hanlderMakeCertificates = (course_key) => {
    const cb = () => loadParticipants({ course_key, requestServiceItemDataId });
    makeCertificates(
      {
        course_key,
        requestServiceItemDataId,
      },
      cb,
    );
  };

  const handlerSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const participantKeys = Object.keys(values).filter((key) =>
          key.startsWith('participants['),
        );

        const participantsGrouped = participantKeys.reduce((acc, key) => {
          const uuid = key.match(/participants\[(.*?)\]/)[1];
          const prop = key.split('.').pop();
          if (!acc[uuid]) acc[uuid] = {};
          acc[uuid][prop] = values[key];
          return acc;
        }, {});

        const participantsArray =
          Object.values(participantsGrouped).filter(Boolean);

        participantKeys.forEach((key) => {
          delete values[key];
        });

        values.participants = participantsArray;

        if (requestServiceItemDataId) {
          values.requestServiceItemDataId = requestServiceItemDataId;
        }

        update(values).then(() => {
          if (hasTecnicalReport) {
            message.info('Generando certificados...');
            hanlderMakeCertificates(values.course_key);
          } else {
            message.info(
              'Para generar los certificados es necesario el informe técnico',
            );
          }
          values.participants = Array.isArray(values.participants)
            ? values.participants.filter(Boolean)
            : Object.values(values.participants)?.filter(Boolean);
          update(values).then(() => {
            if (hasTecnicalReport) {
              message.info('Generando certificados...');
              hanlderMakeCertificates(values.course_key);
            } else {
              message.info(
                'Para generar los certificados es necesario el informe técnico',
              );
            }
          });
        });
      })
      .catch((errorInfo) => {
        console.log('Failed:', errorInfo);
      });
  };

  const addParticipant = () => {
    const newParticipant = {
      key: uuidv4(),
      name: '',
      last_name: '',
      email: '',
      document: '',
      position: '',
    };
    setParticipants((participants) => [...participants, newParticipant]);
    setEditParticipants(true);
  };

  const delParticipant = (unique) => {
    const newParticipants = [
      ...participants.filter((participant) =>
        participant.id ? participant.id !== unique : participant.key !== unique,
      ),
    ];
    setParticipants(newParticipants);
  };

  const disableSave =
    !getFieldValue('course_key') ||
    (getFieldValue('course_key') && participants.length === 0);

  const handleEditOrSaveParticipant = (obj) => {
    if (!editParticipants) {
      setEditParticipants(obj);
    } else {
      form.validateFields((err, values) => {
        if (err) {
          return;
        }
        values.participants = values.participants?.filter(Boolean);
        update(values).then(() => {
          setEditParticipants(false);
        });
      });
    }
  };

  const handlerChangeTechnicalReport = (data, item) => {
    setChangeCertificateLoading(true);
    doAddResult(data, item, pagination).then((response) => {
      response &&
        form.validateFields((err, values) => {
          if (err) {
            return;
          }
          values.participants = values.participants?.filter(Boolean);
          update(values);
        });

      setChangeCertificateLoading(true);
    });
  };

  return (
    <Modal
      title=''
      open={visible}
      onOk={handlerSubmit}
      okButtonProps={{ ...(disableSave && { style: { display: 'none' } }) }}
      okText='Guardar'
      onCancel={onClose}
      confirmLoading={loading || loadingGenerate || loadingUpdate}
      cancelText='Cancelar'
      className={`modal-provider modal-centered ${
        isMobileScreen ? 'modal-mobile' : ''
      }`}
      width='80%'
      destroyOnClose
    >
      <Title
        type='bold-28'
        style={{ margin: '0 auto', width: '90%', textAlign: 'center' }}
      >
        {`Lista de participantes${
          courseSelect ? ` - ${courseSelect.courseName}` : ''
        }`}
      </Title>
      <Divider className='mt-2 mb-3' />
      <Form form={form} {...formItemLayout}>
        <Form.Item
          name='course_key'
          initialValue=''
          rules={[{ required: true, message: text.required_message }]}
          style={{ display: 'none' }}
        >
          <Input type='hidden' />
        </Form.Item>
        <Form.Item
          name='requestServiceItemDataId'
          initialValue={requestServiceItemDataId}
          style={{ display: 'none' }}
        >
          <Input type='hidden' />
        </Form.Item>
        <Form.Item
          name='hasExam'
          initialValue=''
          rules={[{ required: true, message: text.required_message }]}
          label='Tiene examen'
        >
          <Select
            disabled={
              !editParticipants
                ? form.getFieldValue('course_key')
                  ? false
                  : true
                : !editParticipants
            }
            loading={loadingGenerate}
          >
            <Option value={1}>Si</Option>
            <Option value={0}>No</Option>
          </Select>
        </Form.Item>
        <div>
          <ButtonUploadParticipants
            onlyView={getFieldValue('hasExam') !== '' ? false : true}
            setParticipants={setParticipants}
            loading={loading || loadingUpdate}
            template='/templates/PlantillaCapacitacionParticipantes2.xlsx'
            withScore
          />
        </div>
        <div
          className='flex items-center mb-4'
          style={{
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button size='small' type='primary' onClick={addParticipant}>
            Añadir
          </Button>
          <div>
            {editParticipants && (
              <Button
                style={{ marginRight: 16 }}
                disabled={isChangeCertificateLoading}
                loading={isChangeCertificateLoading}
                onClick={() => setVisibleUpload(true)}
              >
                Modificar Informe Técnico
              </Button>
            )}
            <ButtonReportCertificates
              courseKey={getFieldValue('course_key')}
              requestServiceItemDataId={requestServiceItemDataId}
              disabled={!list || list.length === 0}
            />
            <ButtonReportParticipants
              courseKey={getFieldValue('course_key')}
              requestServiceItemDataId={requestServiceItemDataId}
              disabled={!list || list.length === 0}
            />
          </div>
        </div>
      </Form>
      <ListParticipants
        form={form}
        loading={loading || loadingGenerate || loadingUpdate}
        makeCertificates={() =>
          hanlderMakeCertificates(getFieldValue('course_key'))
        }
        hasTecnicalReport={hasTecnicalReport}
        onlyView={getFieldValue('hasExam') !== '' ? false : true}
        list={getFieldValue('course_key') ? participants : []}
        delParticipant={delParticipant}
        editParticipants={editParticipants}
        onChangeParticipants={handleEditOrSaveParticipant}
      />
      {visibleUpload && (
        <ModalUploadTechnicalReport
          visible={visibleUpload}
          setVisible={setVisibleUpload}
          courseKey={getFieldValue('course_key')}
          item={item}
          doAddResult={handlerChangeTechnicalReport}
        />
      )}
    </Modal>
  );
};

const mapDispatchToProps = (dispatch, props) => ({
  loadListCourses: () => getAllCourses(dispatch),
  loadParticipants: (data) => getParticipants(data, dispatch),
  makeCertificates: (data, loadParticipants) =>
    generateCertificates(data, loadParticipants, dispatch),
  doAddResult: (data, item, pagination) =>
    addResult(dispatch, data, item.id, pagination),
  update: async (data) => {
    const created = await updateDataParticipants(data, dispatch);
    created
      ? message.info('Participantes actualizados correctamente.') &&
        props.loadList()
      : message.warning('Ha ocurrido un error.');

    return !!created;
  },
});

const mapStateToProps = (state, props) => ({
  listCourses: state.courses.list || [],
  list: state.requestServicesItemsDataParticipants.list,
  loading:
    state.requestServicesItemsDataParticipants.isLoading || props.loading,
  loadingUpdate: state.requestServicesItemsDataParticipants.isLoadingUpdate,
  loadingGenerate:
    state.requestServicesItemsDataParticipants.isGeneratingCertificates,
  generateFailed: state.requestServicesItemsDataParticipants.generateFailed,
  pagination: state.requestServicesItems.pagination,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalCheckParticipants);
