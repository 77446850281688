import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'antd';

import {
  firemenEmergencies,
  roadEmergencies,
  sismos,
  geologicalHazards,
  tsunamis,
} from './formFilters';
import { getSocialConflict } from './socialConflict';
import { useDispatchActions } from './hooks/useDispatchActions';
import { handleSendFilters } from './filterHandler';
import FormItem from './FormItem';
import { TRACK } from '../../../../lib/consts';
import { pushDigitalData } from '../../../../lib/utils';
import {
  RideButton,
  RideGlDeleteOutline,
} from '@rimac-seguros/ride-system-components';

const DrawerFilters = () => {
  const [form] = Form.useForm();
  const { resetFields } = form;
  const [listItem, setListItem] = useState([]);
  const [filters, setFilters] = useState({});
  const [date, setDate] = useState([]);
  const [updateDate, setUpdateDate] = useState([]);

  const activeSubLayer = useSelector((state) => state.maps.activeSubLayer);
  const types = useSelector((state) => state.conflict.types);
  const activities = useSelector((state) => state.conflict.activities);

  const {
    resetAllFilters,
    setSocialFilters,
    setRoadFilters,
    setFiremenFilters,
    setSismoFilters,
    setTsunamiFilters,
    setGeologicalHazardsFilters,
    getZones,
  } = useDispatchActions();

  const socialConflict = useMemo(
    () => getSocialConflict(types, activities),
    [types, activities],
  );

  useEffect(() => {
    getZones();
  }, []);

  const sendFilters = handleSendFilters({
    activeSubLayer,
    filters,
    setSocialFilters,
    setRoadFilters,
    setFiremenFilters,
    setSismoFilters,
    setTsunamiFilters,
    setGeologicalHazardsFilters,
  });

  const reset = () => {
    setFilters({});
    setDate([]);
    setUpdateDate([]);
    resetFields();
  };

  const filterSetters = {
    conflictos: setSocialFilters,
    emergencias_vial: setRoadFilters,
    bomberos: setFiremenFilters,
    'sismos-map': setSismoFilters,
    'tsunamis-map': setTsunamiFilters,
    peligros_geologicos: setGeologicalHazardsFilters,
  };

  const listSetters = {
    conflictos: () => setListItem(socialConflict),
    emergencias_vial: () => setListItem(roadEmergencies),
    bomberos: () => setListItem(firemenEmergencies),
    'sismos-map': () => setListItem(sismos),
    'tsunamis-map': () => setListItem(tsunamis),
    peligros_geologicos: () => setListItem(geologicalHazards),
  };

  const handlePushDigitalData = (layer) => {
    pushDigitalData(
      {
        action: {
          group: 'Centro Monitoreo',
          category: 'Fenómenos y eventos',
          name: `${layer} - Filtros`,
          label: `Limpiar filtro`,
        },
      },
      TRACK.ACTION,
    );
  };

  const clearFilters = () => {
    reset();
    const setFilter = filterSetters[activeSubLayer.id];
    if (setFilter) {
      setFilter({});
      handlePushDigitalData(activeSubLayer.name);
    }
  };

  useEffect(() => {
    reset();
    resetAllFilters();

    const setList = listSetters[activeSubLayer.id];
    if (setList) {
      setList();
    }
  }, [activeSubLayer.id]);

  return (
    <Form form={form}>
      <div id='drawer-map-filter__body' className='drawer-map-filter__body'>
        {listItem.map((item) => (
          <FormItem
            key={`KEY ${item.id}`}
            item={item}
            setFilters={setFilters}
            date={date}
            updateDate={updateDate}
            setDate={setDate}
            setUpdateDate={setUpdateDate}
            filters={filters}
          />
        ))}
      </div>
      <div className={'drawer-map-filter__btn_filter'}>
        <RideButton
          variant='fill'
          size='medium'
          onClick={sendFilters}
          text='Aplicar Filtros'
        />
        <RideButton
          className='mt-2'
          variant='text-primary'
          size='medium'
          onClick={clearFilters}
          text='Limpiar Filtros'
          glyph={<RideGlDeleteOutline />}
          glyphPosition='left'
        />
      </div>
    </Form>
  );
};

export default DrawerFilters;
