import { post, put } from '../lib/ajax';
import {
  LOAD_EMERGENCY_LOADING,
  LOADED_EMERGENCY,
  LOADING_EMERGENCY_CREATE_POINT,
  LOADING_EMERGENCY_UPDATE_POINT,
} from '../redux/actions/actionsType';

export const getEmergencies = async (dispatch, data = {}) => {
  dispatch({ type: LOAD_EMERGENCY_LOADING, payload: true });
  const r = await post('/emergencies/list', data);
  if (r.data.success) {
    let listResult = r.data.payload?.list;
    const requirements = data?.requirements;

    if (requirements && requirements.length > 0) {
      listResult = listResult.filter((el) => {
        const machines = JSON.parse(el.machines ?? '');

        return requirements.some((req) => {
          if (req === 3) {
            return machines.length <= req;
          }
          if (req === 7) {
            return machines.length >= req;
          }

          return machines.length === req;
        });
      });
    }

    dispatch({ type: LOADED_EMERGENCY, payload: { list: listResult } });
  }
};

export const postEmergencyPoints = async (points) => {
  const r = await post('/emergencies/create_list?', points);
  return r?.data?.success || false;
};

export const createFiremenEmergency = async (dispatch, data = {}) => {
  dispatch({ type: LOADING_EMERGENCY_CREATE_POINT, payload: true });

  try {
    const r = await post('/emergencies/create', data);

    if (r.data.success) {
      dispatch({ type: LOADING_EMERGENCY_CREATE_POINT, payload: false });
      getEmergencies(dispatch);

      return true;
    }
    dispatch &&
      dispatch({ type: LOADING_EMERGENCY_CREATE_POINT, payload: false });

    return false;
  } catch (e) {
    dispatch &&
      dispatch({ type: LOADING_EMERGENCY_CREATE_POINT, payload: false });

    return false;
  }
};

export const updateFiremenEmergency = async (dispatch, data, filters = {}) => {
  dispatch({ type: LOADING_EMERGENCY_UPDATE_POINT, payload: true });

  try {
    const r = await post('/emergencies/update', data);

    if (r?.data?.success) {
      dispatch({ type: LOADING_EMERGENCY_UPDATE_POINT, payload: false });
      getEmergencies(dispatch, filters);

      return true;
    }
    dispatch &&
      dispatch({ type: LOADING_EMERGENCY_UPDATE_POINT, payload: false });

    return false;
  } catch (e) {
    dispatch &&
      dispatch({ type: LOADING_EMERGENCY_UPDATE_POINT, payload: false });

    return false;
  }
};

export const removeFiremenEmergency = async ({
  id,
  dispatch,
  filters = {},
}) => {
  try {
    const r = await put(`/emergencies/delete?id=${id}`);
    if (r.data.success) {
      getEmergencies(dispatch, filters);

      return true;
    }

    return false;
  } catch (e) {
    return false;
  }
};
