import React, { useEffect, useState } from 'react';
import { Divider, Modal, notification, Input } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

import { useScreen } from '../../../../../lib/screen';
import { uploadLayers } from '../../../../../services/parameter';
import { IconCloseRed } from '../../../ui/PreventionService/Reports/utils';
import Title from '../../../../../common/Typograph/Title';
import InputFile from '../../../../../common/InputFile';

const ModalMinTemperature = ({ handleClose, visible }) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(null);
  const [title, setTitle] = useState('');
  const { isMobileScreen } = useScreen();

  const handleOk = () => {
    if (!file || !title) {
      notification.warning({
        description: 'Debe completar todos los campos.',
        message: 'Cargar file',
      });
      return;
    }

    setLoading(true);
    uploadLayers(file, title, 'minTemperature')
      .then(() => {
        handleClose();
      })
      .catch(() => {
        notification.warning({
          description: 'Ha ocurrido un error.',
          message: 'Cargar file',
        });
      })
      .finally(() => {
        setLoading(false);
        notification.success({
          description: 'Se subió correctamente el archivo.',
        });
      });
  };

  const handleCancel = () => {
    handleClose();
  };

  const onUploadFile = (fileInfo) => {
    setFile(fileInfo?.file);
  };

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  useEffect(() => {
    const elements = document.querySelectorAll('.ant-popover');
    if (elements.length > 0) {
      elements.forEach((element) => {
        element.classList.add('ant-popover-hidden');
      });
    }
  }, [visible]);

  return (
    <Modal
      open={visible}
      title=''
      closable={true}
      onCancel={() => handleCancel()}
      centered={true}
      onOk={handleOk}
      okButtonProps={{ disabled: loading }}
      className={`modal-provider modal-centered ${
        isMobileScreen ? 'modal-mobile' : ''
      }`}
      cancelText='Cancelar'
      okText='Subir GeoJson'
    >
      <div
        className='flex flex-col'
        style={
          isMobileScreen
            ? { width: '100%' }
            : { padding: '64px 64px 12px 64px', minWidth: 528 }
        }
      >
        <Title type='bold-28' style={{ textAlign: 'center' }}>
          Cargar archivo de Mínima Temperatura
        </Title>
        <Divider className='mt-2 mb-4' />
        <Input
          placeholder='Título'
          value={title}
          onChange={handleTitleChange}
          style={{ marginBottom: '1rem' }}
        />
        <InputFile
          allowClear={true}
          filesAccepted={[]}
          onChangeFile={onUploadFile}
          title={'Geological file'}
          withoutPreview
        />
      </div>
    </Modal>
  );
};

export default ModalMinTemperature;
