import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { reportMonitoring } from '../../../../../../../services/dashboard';
import Activities from './components/Activities';
import Information from './components/Information';
import Main from './components/Main';
import Prevention from './components/Prevention';

const Resumen = () => {
  const { user } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(true);
  const [dataMonitoring, setDataMonitoring] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    reportMonitoring()
      .then((res) => {
        setDataMonitoring(res);
      })
      .catch(() => {
        setDataMonitoring([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <section className='pf-resumen'>
      <Main user={user} />
      <Information dataMonitoring={dataMonitoring} isLoading={isLoading} />
      <Prevention />
      <Activities dataMonitoring={dataMonitoring} isLoading={isLoading} />
    </section>
  );
};

export default Resumen;
