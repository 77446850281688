import { useState, useEffect } from 'react';

import { useScreen } from '../../../../lib/screen';

import ModalForm from './ModalForm';
import MainTitle from '../common/MainTitle';

const Locations = ({ renderItem, queryAction }) => {
  const { isMobileScreen } = useScreen();
  const [openForm, setOpenForm] = useState(false);

  useEffect(() => {
    queryAction && setOpenForm(true);
  }, [queryAction]);

  return (
    <>
      <MainTitle
        buttonAction={() => setOpenForm(true)}
        renderItem={renderItem}
        isMobileScreen={isMobileScreen}
      />

      <ModalForm
        visible={openForm}
        handleClose={() => {
          setOpenForm(false);
        }}
      />
    </>
  );
};

export default Locations;
