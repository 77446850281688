import { useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';
import { activeLayer } from '../utils/layers';
import L from 'leaflet';
import GeologicalHazardsLayer from '../layers/GeologicalHazardsLayer/GeologicalHazardsLayer';
import UVLayer from '../layers/UVLayer/UVLayer';

const wmsUrl = process.env.REACT_APP_GEOSERVER_MAPS_NEW;

const StaticLayer = ({ selectLayer }) => {
  const map = useMap();
  const layerRef = useRef(null);

  const selectedLayer = activeLayer.find((layer) => layer.id === selectLayer);

  const removeCurrentLayer = () => {
    if (layerRef.current) {
      map.removeLayer(layerRef.current);
      layerRef.current = null;
    }
  };

  useEffect(() => {
    if (
      selectLayer === '03_04_001_03_001_513_0000_00_00' ||
      selectLayer === 'mapa_PeligrosGeologicos'
    ) {
      return;
    }

    removeCurrentLayer();

    if (selectedLayer && selectedLayer.component) {
      return;
    }

    if (selectLayer) {
      const staticLayer = L.tileLayer.wms(wmsUrl, {
        layers: selectLayer,
        styles: '',
        version: '1.3.0',
        format: 'image/png',
        tileSize: 256,
        transparent: true,
      });

      layerRef.current = staticLayer.addTo(map);
    }
  }, [map, selectLayer, wmsUrl]);

  const LayerComponent = selectedLayer?.component;

  return (
    <>
      {selectLayer === 'mapa_PeligrosGeologicos' && <GeologicalHazardsLayer />}
      {selectLayer === '03_04_001_03_001_513_0000_00_00' && <UVLayer />}
      {LayerComponent ? <LayerComponent /> : null}
    </>
  );
};

export default StaticLayer;
