import React, { useState, useEffect, useCallback } from 'react';
import { connect, useSelector } from 'react-redux';
import { Button, notification, Popover, Skeleton } from 'antd';
import { OrderedListOutlined } from '@ant-design/icons';
import moment from 'moment';

import {
  LOADED_RPT_POLIZAS_RESET,
  SELECT_ITEM_COMPANY,
} from '../../../../../../redux/actions/actionsType';

import { IconMessage, IconWarning } from '../../../../../../common/Icons';
import Table from '../../../../../../common/Table';
import PopoverActions from '../../../../../../common/Table/PopoverActions';
import TableMessage from '../../../../../../common/Table/TableMessage';
import { useScreen } from '../../../../../../lib/screen';
import { checkPermission } from '../../../../../../lib/security';

import {
  createPoliza,
  getPolizasOfCompany,
  removePoliza,
  reportCompanyPolizas,
  updatePoliza,
} from '../../../../../../services/company';
import { getLevelCompany } from '../../../../../../services/levelCompany';
import { getTypePoliza } from '../../../../../../services/typePolicy';

import {
  CardSkeleton,
  IconDocument,
  IconDownload,
  IconEditTable,
  iconLeft,
  iconRight,
  IconTrash,
} from '../../../../ui/PreventionService/Reports/utils';
import HeaderForm from '../../../common/HeaderForm';
import ModalConfirm from '../../../../../../common/ModalConfirm';
import ModalForm from './ModalForm';
import PolizaCard from './PolizaCard';
import ModalDiagnostic from './ModalDiagnostic';
import ModalComments from './ModalComments';
import ModalServicesPlanning from './ModalServicesPlanning';
import IconFinish from '../../../../../../images/icon_finish.svg';
import DeleteModalImage from '../../../../../../images/delete_modal_image.svg';

const returnState = (text) => {
  return (
    <div className='flex flex-row flex-justify-justify-between'>
      <span
        style={{ background: '#C8C8FA', height: 20 }}
        className={'withpoint'}
      >
        <div
          style={{ background: '#4F4FFF' }}
          className={'withpoint-circle'}
        ></div>
        <span
          style={{
            fontFamily: 'BR Sonoma',
            fontWeight: 900,
            fontSize: 11,
            lineHeight: '16px',
          }}
        >
          {text || ''}
        </span>
      </span>
    </div>
  );
};

const PolizaList = ({
  toggleSelectCompany,
  loadTypePoliza,
  loadLevelCompany,
  deletePoliza,
  getPolizas,
  company,
  listTypePoliza,
  listLevelCompany,
  dealersReport,
  resetReportState,
  generateReport,
  generateReportManagerial,
  generateReportOperative,
  onBack,
  user,
}) => {
  const { isMobileScreen, isBeforeTable } = useScreen();
  const userAuth = useSelector((state) => state.auth.user);
  const rol = userAuth?.rol?.slug;
  const [list, setList] = useState([]);
  const [polizaSelected, setPolizaSelected] = useState(null);
  const [formOpened, setFormOpened] = useState(false);
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState(() => ({
    pageSize: 5,
    total: 0,
  }));
  const [confirmMobile, setConfirmMobile] = useState('');
  const [initialDiagnostic, setInitialDiagnostic] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingDownload, setLoadingDownload] = useState(null);
  const [itemToComments, setItemToComments] = useState(null);
  const [polizaToServicesPlanning, setPolizaToServicesPlanning] =
    useState(null);
  const [isSavedChanges, setSavedChanges] = useState(false);

  const handleReturn = useCallback(() => {
    onBack(false);
    company && company.id && toggleSelectCompany(null);
  }, [company, onBack, toggleSelectCompany]);

  const handleOpenForm = () => {
    setPolizaSelected(null);
    setFormOpened(true);
  };

  const handleFormAction = (values) => {
    setIsLoadingForm(true);

    if (polizaSelected) {
      updatePoliza(values)
        .then((response) => {
          setList(response);
          setIsLoadingForm(false);
          setFormOpened(false);

          if (isBeforeTable)
            return setConfirmMobile(
              '¡Listo! Se guardaron los cambios correctamente.',
            );

          notification.success({
            description: 'Se guardaron los cambios correctamente.',
            message: '¡Cambios guardados!',
          });
        })
        .catch(() => {
          setIsLoadingForm(false);
          notification.warning({
            description: 'Ha ocurrido un error.',
            message: 'Actualizar póliza',
          });
        });
    } else {
      createPoliza(values)
        .then((response) => {
          setList(response);
          setIsLoadingForm(false);
          setFormOpened(false);

          if (isBeforeTable)
            return setConfirmMobile('¡Listo! Se creó la póliza correctamente');

          notification.success({
            description: 'Se creo la póliza correctamente.',
            message: '¡Póliza creada!',
          });
        })
        .catch(() => {
          setIsLoadingForm(false);
          notification.warning({
            description: 'Ha ocurrido un error.',
            message: 'Crear póliza',
          });
        });
    }
  };

  const _handleEditPoliza = useCallback(
    (item) => () => {
      setPolizaSelected(item);
      setFormOpened(true);
    },
    [],
  );

  const _handleDiagnostic = useCallback(
    (item) => () => {
      setInitialDiagnostic(item);
    },
    [],
  );

  const _handleComments = useCallback(
    (item) => () => {
      setItemToComments(item);
    },
    [],
  );

  const _handleRemove = useCallback(
    (item) => () => {
      setItemToDelete(item);
    },
    [],
  );

  const _handleReportExport = useCallback(
    (item) => () => {
      setLoadingDownload(item.id);
      generateReport({ polizaId: item.id });
    },
    [generateReport],
  );

  const _handleReportGeneral = useCallback(
    (item) => () => {
      setLoadingDownload(item.id);
      generateReportManagerial({ polizaId: item.id });
    },
    [generateReportManagerial],
  );

  const _handleReportOperative = useCallback(
    (item) => () => {
      setLoadingDownload(item.id);
      generateReportOperative({ polizaId: item.id });
    },
    [generateReportOperative],
  );

  const handleConfirmDelete = () => {
    setLoadingDelete(true);

    if (!itemToDelete) {
      notification.warning({
        description: 'No ha seleccionado ninguna póliza para eliminar.',
        message: 'Eliminar póliza',
      });
      setLoadingDelete(false);

      return;
    }

    deletePoliza(
      itemToDelete,
      setList,
      isBeforeTable,
      setItemToDelete,
      setLoadingDelete,
      setConfirmMobile,
    );
  };

  const columns = [
    {
      title: 'Tipo de póliza',
      dataIndex: 'typePolizaId',
      key: 'typePolizaId',
      width: '140px',
      render: (val) => {
        if (Array.isArray(listTypePoliza) && listTypePoliza.length > 0) {
          const type = listTypePoliza.find((item) => item.id === val);
          if (type) {
            return type.name;
          }
        }

        return '';
      },
    },
    {
      title: 'ID Póliza',
      dataIndex: 'id',
      key: 'id',
      width: '100px',
    },
    {
      title: 'No. Póliza',
      dataIndex: 'no_poliza',
      key: 'no_poliza',
      width: '140px',
    },
    {
      title: 'Fecha de inicio',
      dataIndex: 'start_date',
      key: 'start_date',
      width: '140px',
      render: (val) => moment(val).format('DD-MM-YYYY'),
    },
    {
      title: 'Vigencia',
      dataIndex: 'validity',
      key: 'validity',
      width: '140px',
      render: (val) => `${val} MESES`,
    },
    {
      title: 'Nivel de empresa',
      dataIndex: 'validity',
      key: 'level',
      width: '140px',
      render: (__, item) =>
        `${item?.levelCompany?.name ? item?.levelCompany?.name : ''}`,
    },
    {
      title: 'Prima (US$)',
      dataIndex: 'prima',
      key: 'prima',
      width: '140px',
      render: (val) => `$${new Intl.NumberFormat('en-US').format(val)}`,
    },
    {
      title: 'Estado',
      dataIndex: 'state',
      key: 'state',
      width: '180px',
      render: (val, item) => {
        if (val <= 0) {
          return returnState('Inactivo');
        }

        const start = moment(item['start_date']);
        const startFormated = moment(
          start.format('DD-MM-YYYY 00:00'),
          'DD-MM-YYYY HH:mm',
        );
        const final = startFormated.clone().add(item['validity'], 'M');
        const days = final.diff(
          moment(moment().format('DD-MM-YYYY 00:00'), 'DD-MM-YYYY HH:mm'),
          'day',
        );

        if (days === 1) {
          return returnState(`${days} día vigente`);
        }
        if (days > 1) {
          return returnState(`${days} días vigentes`);
        }

        const hours = Math.floor(val / 60);
        if (hours === 1) {
          return returnState(`${hours} hora vigente`);
        }
        if (hours > 1) {
          return returnState(`${hours} horas vigentes`);
        }

        return returnState(`${val} minutos vigentes`);
      },
    },
    {
      dataIndex: 'actions',
      title: 'Acciones',
      fixed: 'right',
      key: 'actions',
      width: 80,
      render: (__, item) =>
        list.length > 0 ? (
          <PopoverActions
            actions={
              <>
                {rol !== 'comercial' && (
                  <>
                    <Button
                      onClick={() => {
                        setPolizaToServicesPlanning(item);
                      }}
                    >
                      <OrderedListOutlined />
                      <span>Planificación de servicios</span>
                    </Button>
                    <Button onClick={_handleDiagnostic(item)}>
                      <IconWarning /> <span>Diagnóstico inicial</span>
                    </Button>
                    {checkPermission(user, 'write_polizas') && (
                      <Button onClick={_handleComments(item)}>
                        <IconMessage /> <span>Comentarios</span>
                      </Button>
                    )}
                    {checkPermission(user, 'write_polizas') && (
                      <Button onClick={_handleEditPoliza(item)}>
                        <IconEditTable /> <span>Editar póliza</span>
                      </Button>
                    )}
                  </>
                )}
                <Popover
                  placement='left'
                  content={
                    <div className='buttons-popover'>
                      {rol !== 'comercial' && (
                        <Button
                          onClick={_handleReportExport(item)}
                          loading={
                            dealersReport.isLoading &&
                            loadingDownload === item.id
                          }
                          disabled={dealersReport.isLoading}
                        >
                          <IconDownload color='#141938' />{' '}
                          <span>Reporte para comerciales</span>
                        </Button>
                      )}

                      <Button
                        onClick={_handleReportOperative(item)}
                        loading={
                          dealersReport.isLoading && loadingDownload === item.id
                        }
                        disabled={dealersReport.isLoading}
                      >
                        <IconDownload color='#141938' />{' '}
                        <span>Reporte operativo</span>
                      </Button>

                      {rol !== 'comercial' && (
                        <Button
                          onClick={_handleReportGeneral(item)}
                          loading={
                            dealersReport.isLoading &&
                            loadingDownload === item.id
                          }
                          disabled={dealersReport.isLoading}
                        >
                          <IconDownload color='#141938' />{' '}
                          <span>Reporte gerencial</span>
                        </Button>
                      )}
                    </div>
                  }
                >
                  <Button
                    loading={
                      dealersReport.isLoading && loadingDownload === item.id
                    }
                    disabled={dealersReport.isLoading}
                  >
                    <IconDocument /> <span>Reportes</span>
                  </Button>
                </Popover>

                {rol !== 'comercial' && (
                  <Button onClick={_handleRemove(item)}>
                    <IconTrash /> <span>Eliminar</span>
                  </Button>
                )}
              </>
            }
          />
        ) : null,
    },
  ];

  useEffect(() => {
    if (list && list.length) {
      setPagination((prev) => ({ ...prev, total: list.length }));
    }
  }, [list]);

  useEffect(() => {
    !company && handleReturn();
  }, [company]);

  useEffect(() => {
    if (company || isSavedChanges) {
      (async () => {
        setIsLoading(true);
        const list = await getPolizas(company);
        setList(list);
        setIsLoading(false);
      })();
      setSavedChanges(false);
    }
  }, [company, getPolizas, isSavedChanges]);

  useEffect(() => {
    loadLevelCompany();
    loadTypePoliza();
  }, []);

  useEffect(() => {
    if (dealersReport.filePath) {
      notification.success({
        description: 'Reporte generado correctamente.',
        message: '¡Acción completada! ',
      });
      window.open(`${dealersReport.filePath}`, '_blank');
      resetReportState();
    }
    dealersReport.exportFailed &&
      notification.warning({
        description: 'Ha ocurrido un error.',
        message: 'Descargar reportes',
      });
  }, [dealersReport, resetReportState]);

  return (
    <>
      <div className='d_content'>
        <section className='section-form-newsletters section-form-event'>
          <HeaderForm
            title={`Pólizas de ${company?.name}`}
            buttonText='Crear póliza'
            withBorder={true}
            create={true}
            handleReturn={handleReturn}
            buttonAction={handleOpenForm}
            rol={rol}
          />
          {!isMobileScreen ? (
            !isLoading && list?.length === 0 ? (
              <TableMessage
                complete={true}
                message='No se encontrarón pólizas'
              />
            ) : (
              <section className='show-data'>
                {isLoading ? (
                  <Skeleton active title={false} paragraph={{ rows: 1 }} />
                ) : (
                  <p className='info-total-data'>
                    Se encontraron <span>{pagination.total}</span>{' '}
                    {pagination.total === 1 ? 'póliza' : 'pólizas'}
                  </p>
                )}

                <Table
                  dataSource={list}
                  columns={columns}
                  rowKey={(record) => record.id}
                  loading={isLoading}
                  locale={{ emptyText: 'No se encontraron pólizas.' }}
                  pagination={pagination}
                />
              </section>
            )
          ) : (
            <section className='content-mobile action-bottom'>
              {isLoading ? (
                <Skeleton active title={false} paragraph={{ rows: 1 }} />
              ) : (
                <p className='info-total-data'>
                  Se encontraron <span>{pagination.total}</span>{' '}
                  {pagination.total === 1 ? 'póliza' : ' pólizas'}
                </p>
              )}

              {isLoading ? (
                [1, 2, 3, 4].map((el) => <CardSkeleton key={el} />)
              ) : list.length === 0 ? (
                <TableMessage
                  complete={true}
                  message='No se encontrarón  pólizas'
                />
              ) : (
                list.map((data) => (
                  <PolizaCard
                    key={data.id}
                    data={data}
                    listTypePoliza={listTypePoliza}
                    onEdit={_handleEditPoliza(data)}
                    onDiagnostic={_handleDiagnostic(data)}
                    onDelete={_handleRemove(data)}
                    onReportComercial={_handleReportExport(data)}
                    onReportOperative={_handleReportOperative(data)}
                    onReportGeneral={_handleReportGeneral(data)}
                    rol={rol}
                  />
                ))
              )}

              <div className='d_content__list__pag'>
                <div className={'d_content__list__pag__button'}>
                  {iconLeft({
                    color: pagination.current === 1 ? '#A9AFD9' : '#4F4FFF',
                  })}
                </div>

                <div className='d_content__list__pag__number color-red-1'>
                  1
                </div>
                <div className='d_content__list__pag__sep'>/</div>
                <div className='d_content__list__pag__number color-gray-3'>
                  1
                </div>

                <div className={'d_content__list__pag__button'}>
                  {iconRight({
                    color:
                      pagination.current ===
                      Math.ceil(pagination.total / pagination.pageSize)
                        ? '#A9AFD9'
                        : '#4F4FFF',
                  })}
                </div>
              </div>
            </section>
          )}
        </section>
      </div>

      <ModalForm
        handleClose={() => {
          setFormOpened(false);
          setPolizaSelected(null);
        }}
        handleAction={handleFormAction}
        isLoadingCreate={isLoadingForm}
        visible={formOpened}
        data={polizaSelected}
        company={company}
        listTypePoliza={listTypePoliza}
        listLevelCompany={listLevelCompany}
      />

      <ModalConfirm
        visible={Boolean(confirmMobile)}
        onClose={() => setConfirmMobile('')}
        title={confirmMobile || ''}
        image={IconFinish}
        actionsContent={
          <div className='actions-delete-modal mt-2'>
            <Button
              className='button w-max'
              type='primary'
              onClick={() => setConfirmMobile('')}
            >
              Aceptar
            </Button>
          </div>
        }
      />

      <ModalConfirm
        visible={Boolean(itemToDelete)}
        onClose={() => setItemToDelete(null)}
        title='¿Seguro que desea eliminar la
        póliza de la lista ?'
        image={DeleteModalImage}
        actionsContent={
          <div className='actions-delete-modal mt-2'>
            <Button
              className='button secondary-button mr-2'
              onClick={() => setItemToDelete(null)}
            >
              Cancelar
            </Button>

            <Button
              className='button ml-1'
              type='primary'
              disabled={loadingDelete}
              loading={loadingDelete}
              onClick={handleConfirmDelete}
            >
              Aceptar
            </Button>
          </div>
        }
      />

      <ModalDiagnostic
        visible={Boolean(initialDiagnostic)}
        data={initialDiagnostic}
        handleClose={() => setInitialDiagnostic(null)}
        onSavedChanges={setSavedChanges}
      />

      <ModalComments
        visible={Boolean(itemToComments)}
        data={itemToComments}
        handleClose={() => {
          setSavedChanges(true);
          setItemToComments(null);
        }}
      />

      <ModalServicesPlanning
        visible={Boolean(polizaToServicesPlanning)}
        data={polizaToServicesPlanning}
        onSavedChanges={setSavedChanges}
        onClose={() => setPolizaToServicesPlanning(null)}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  generateReport: (params) =>
    reportCompanyPolizas(dispatch, 'reportCompanyDealers', params),
  generateReportOperative: (params) =>
    reportCompanyPolizas(dispatch, 'reportCompanyPreventionOperative', params),
  generateReportManagerial: (params) =>
    reportCompanyPolizas(dispatch, 'reportCompanyPreventionManagerial', params),
  getPolizas: (company) => getPolizasOfCompany(company, dispatch),
  toggleSelectCompany: (item) =>
    dispatch({ type: SELECT_ITEM_COMPANY, payload: item }),
  resetReportState: () => {
    dispatch({ type: LOADED_RPT_POLIZAS_RESET });
  },
  loadTypePoliza: () => getTypePoliza(dispatch),
  loadLevelCompany: () => getLevelCompany(dispatch),
  deletePoliza: async (
    item,
    setList,
    isBeforeTable,
    setItemToDelete,
    setLoadingDelete,
    setConfirmMobile,
  ) => {
    const newList = await removePoliza(item, dispatch);
    setList(newList);

    if (isBeforeTable && newList) {
      setItemToDelete(null);
      setLoadingDelete(false);

      return setConfirmMobile('¡Listo! Se eliminó la póliza correctamente');
    }

    if (newList) {
      notification.success({
        description: 'Se eliminó la póliza correctamente.',
        message: '¡Tipo de industria eliminada! ',
      });
    } else {
      notification.warning({
        description: 'Ha ocurrido un error.',
        message: 'Eliminar póliza',
      });
    }

    setItemToDelete(null);
    setLoadingDelete(false);
  },
});

const mapStateToProps = (state) => ({
  company: state.companies.companySelected,
  listTypePoliza: state.typePoliza.list || [],
  listLevelCompany: state.levelCompany.list,
  dealersReport: state.poliza,
  user: state.auth.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(PolizaList);
