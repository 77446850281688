import React, { memo, useMemo } from 'react';
import { Button, DatePicker, Form, Skeleton, Table } from 'antd';
import moment from 'moment';
import TableMessage from '../../../../common/Table/TableMessage';
import { useScreen } from '../../../../lib/screen';
import InputWithLabel from '../../../../common/InputWithLabel';
import locale from 'antd/es/date-picker/locale/es_ES';

const InputCustom = ({ field, initialValue, required, disabled }) => {
  return (
    <Form.Item
      name={field}
      initialValue={initialValue}
      rules={required ? [{ required: true, message: '*' }] : []}
      style={{ margin: 0 }}
    >
      <InputWithLabel size='small' disabled={disabled} />
    </Form.Item>
  );
};

const InputDate = ({ field, initialValue, required, disabled }) => {
  return (
    <Form.Item
      name={field}
      initialValue={initialValue}
      rules={required ? [{ required: true, message: '*' }] : []}
      className='range-picker-with-label'
      style={{ margin: 0 }}
    >
      <DatePicker
        locale={locale}
        hiddenRange
        size='small'
        format={'DD-MM-YYYY'}
        disabled={disabled}
      />
    </Form.Item>
  );
};

const EmergencyTable = ({ pagination, rows, setUploadPoints }) => {
  const { isMobileScreen } = useScreen();

  const columns = useMemo(
    () => [
      {
        title: 'Número',
        dataIndex: 'number',
        key: 'number',
        width: '200px',
        render: (value, record) => (
          <InputCustom
            required
            initialValue={value}
            field={`emergency[${record.id}].number`}
          />
        ),
      },
      {
        title: 'Fecha',
        dataIndex: 'date',
        key: 'date',
        width: '200px',
        render: (value, record) => (
          <InputDate
            required
            initialValue={moment(value, 'DD-MM-YYYY')}
            field={`emergency[${record.id}].date`}
          />
        ),
      },
      {
        title: 'Dirección',
        dataIndex: 'address',
        key: 'address',
        width: '200px',
        render: (value, record) => (
          <InputCustom
            required
            initialValue={value}
            field={`emergency[${record.id}].address`}
          />
        ),
      },
      {
        title: 'Tipo',
        dataIndex: 'type',
        key: 'type',
        width: '200px',
        render: (value, record) => (
          <InputCustom
            initialValue={value}
            field={`emergency[${record.id}].type`}
          />
        ),
      },
      {
        title: 'Estado',
        dataIndex: 'status',
        key: 'status',
        width: '200px',
        render: (value, record) => (
          <InputCustom
            required
            initialValue={value}
            field={`emergency[${record.id}].status`}
          />
        ),
      },
      {
        title: 'Máquinas',
        dataIndex: 'machines',
        key: 'machines',
        width: '300px',
        render: (value, record) => (
          <InputCustom
            required
            initialValue={value}
            field={`emergency[${record.id}].machines`}
          />
        ),
      },
      {
        title: 'latitude',
        dataIndex: 'lat',
        key: 'lat',
        width: '200px',
        render: (value, record) => (
          <InputCustom
            required
            initialValue={value}
            field={`emergency[${record.id}].lat`}
          />
        ),
      },
      {
        title: 'logitude',
        dataIndex: 'lng',
        key: 'lng',
        width: '200px',
        render: (value, record) => (
          <InputCustom
            required
            initialValue={value}
            field={`emergency[${record.id}].lng`}
          />
        ),
      },
    ],
    [],
  );

  return !isMobileScreen ? (
    rows.length === 0 ? (
      <TableMessage
        complete={true}
        message='Aún no se ha cargado puntos de emergencia'
      />
    ) : (
      <section className='show-data mt-4'>
        {rows.length === 0 ? (
          <Skeleton active title={false} paragraph={{ rows: 1 }} />
        ) : pagination.total === 1 ? (
          <p className='info-total-data'>
            Se cargo <span>{pagination.total}</span> punto de emergencia
          </p>
        ) : (
          <p className='info-total-data'>
            Se cargaron <span>{pagination.total}</span> puntos de emergencia
          </p>
        )}
        <Table
          dataSource={rows}
          columns={columns}
          className='table_common'
          rowKey={(record) => record.id}
          locale={{ emptyText: 'Aún no se ha cargado puntos de emergencia.' }}
          pagination={pagination}
        />
        <Button
          type='primary'
          size='large'
          className='ml-auto'
          style={{ display: 'block' }}
          onClick={() => setUploadPoints(true)}
        >
          Enviar puntos
        </Button>
      </section>
    )
  ) : (
    <section className='content-mobile action-bottom'>
      {rows.length === 0 ? (
        <Skeleton active title={false} paragraph={{ rows: 1 }} />
      ) : pagination.total === 1 ? (
        <p className='info-total-data'>
          Se cargo <span>{pagination.total}</span> punto de emergencia
        </p>
      ) : (
        <p className='info-total-data'>
          Se cargaron <span>{pagination.total}</span> puntos de emergencia
        </p>
      )}
    </section>
  );
};

export default memo(EmergencyTable);
