import { useNavigate } from 'react-router-dom';
import { LeftOutlined } from '@ant-design/icons';

const HeaderBack = ({ title }) => {
  const navigate = useNavigate();

  return (
    <div className='cmp_topHeaderBack'>
      <span
        className='back_link flex'
        onClick={() => navigate('/soporte/centro-de-ayuda')}
        style={{ cursor: 'pointer' }}
      >
        <span className='cmp_topHeaderBack__button'>
          <LeftOutlined />
        </span>
        <span className='cmp_topHeaderBack__title'>{title}</span>
      </span>
    </div>
  );
};

export default HeaderBack;
