import { RideFiNotFoundEmpty } from '@rimac-seguros/ride-system-components';

const Empty = ({ title = '', description = '' }) => {
  return (
    <div className='empty'>
      <RideFiNotFoundEmpty />
      <div className='flex flex-col gap-1'>
        {title && <b className='empty__title'>{title}</b>}
        {description && <p className='empty__description'>{description}</p>}
      </div>
    </div>
  );
};

export default Empty;
