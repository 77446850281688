import { Auth } from 'aws-amplify';
import {
  clearAuthorizationHeader,
  setAuthorizationHeader,
  get,
  post,
} from './ajax';
import { checkIfExistUser, getUserNameSimulate } from '../services/users';

export const setTokenIntoLocalStore = (token) =>
  setAuthorizationHeader(`Bearer ${token}`);

export const clearTokenIntoLocalStore = () => clearAuthorizationHeader();

export const setClockDriftLocalStore = (clockDrift) =>
  (window.clockDrift = clockDrift);

export const getClockDriftLocalStore = () => parseInt(window.clockDrift || 0);

export const checkUser = (username) => post('/checkuser', { username }, false);

export const addFailedAttempt = (username) =>
  post('/checkuser?addFailAttempt=true', { username }, false);

export const findUser = async (username) => {
  const user_name = getUserNameSimulate() ? getUserNameSimulate() : username;
  return { data: await checkIfExistUser(user_name) };
};

export const simulateLogin = ({ username }) => {
  setTokenIntoLocalStore('token-simulate');

  return {
    success: true,
    session: {
      username,
    },
  };
};

export const loginWithCognito = async ({ username, password }) => {
  return Auth.signIn(username, password)
    .then(async (session) => {
      if (session.challengeName === 'NEW_PASSWORD_REQUIRED') {
        await Auth.completeNewPassword(session, password, {
          email: session.email,
          family_name: session.username,
          name: session.username,
        });
      }

      return {
        success: true,
        session,
      };
    })
    .catch((e) => {
      return {
        success: false,
        e,
      };
    });
};

export const login = async () => {
  const config = Auth.configure();

  const identityProvide = config.identiyProvider;

  const { domain, redirectSignIn, responseType, scope } = config.oauth;

  const clientId = config.userPoolWebClientId;

  const url =
    'https://' +
    domain +
    '/authorize' +
    '?identity_provide=' +
    identityProvide +
    '&redirect_uri=' +
    redirectSignIn +
    '&response_type=' +
    responseType +
    '&client_id=' +
    clientId +
    '&scope=' +
    scope.join(' ');

  window.location.assign(url);
};

export const checkAuth = async () => {
  try {
    const session = await Auth.currentSession();

    return {
      success: true,
      session,
    };
  } catch (e) {
    return {
      success: false,
      error: e,
    };
  }
};

export const logout = async () => {
  clearTokenIntoLocalStore();

  return Auth.signOut();
};
