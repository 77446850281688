import {
  LOADED_PURCHASE_ORDER_LIST,
  LOADING_PURCHASE_ORDER_LIST,
} from '../actions/actionsType';

const initialState = {
  list: [],
  isLoading: false,
  pagination: {
    current: 1,
    pageSize: 5,
    total: 0,
  },
};

const requestServicesPurchaseOrder = (state, action) => {
  if (!state) state = initialState;

  switch (action.type) {
    case LOADED_PURCHASE_ORDER_LIST:
      return {
        ...state,
        list: action.payload.list,
        pagination: {
          ...state.pagination,
          total: action.payload.total,
          current: action.payload.current,
        },
      };
    case LOADING_PURCHASE_ORDER_LIST:
      return {
        ...state,
        isLoading: action.payload,
      };
    default:
      return state;
  }
};

export default requestServicesPurchaseOrder;
