import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { subMenuMaps } from '../data';

import AsideHeader from './AsideHeader';
import SubNavigation from './SubNavigation';
import { SIDEMENU_CHANGE_VISIBLE } from '../../../redux/actions/actionsType';
import {
  RideButton,
  RideGlCloseOutline,
} from '@rimac-seguros/ride-system-components';

const MobileMenu = () => {
  const dispatch = useDispatch();
  const isOpened = useSelector((state) => state.sideMenu.isOpened);
  const user = useSelector((state) => state.auth.user);

  const handleClose = () => {
    dispatch({ type: SIDEMENU_CHANGE_VISIBLE, payload: false });
  };

  if (!isOpened) {
    return null;
  }

  return (
    <div className='mobile-menu'>
      <AsideHeader />
      <RideButton
        className='close-button'
        text=''
        variant='text-primary'
        onClick={handleClose}
        glyph={<RideGlCloseOutline />}
        size='large'
      />

      <ul className='parent'>
        <SubNavigation
          user={user}
          items={subMenuMaps({ rol: user?.rol?.slug })}
        />
      </ul>
    </div>
  );
};

export default MobileMenu;
