import L from 'leaflet';
import { getLocations } from '../../services/locations';

const southWest = L.latLng(12.554564, -99.492188);
const northEast = L.latLng(-56.795161, -32.319068);
const maxBoundsDefault = L.latLngBounds(southWest, northEast);

const southWestTsunamis = L.latLng(-45.0, -270.0);
const northEastTsunamis = L.latLng(56.0, -60);
const maxBoundsTsunamis = L.latLngBounds(southWestTsunamis, northEastTsunamis);

const getMinZoom = {
  tsunamis: 2,
};

const getMaxBounds = {
  tsunamis: maxBoundsTsunamis,
};

const getMapProperties = (activeLayer) => {
  const id = activeLayer?.id;
  const minZoom = getMinZoom[id] ?? 6;
  const maxBounds = getMaxBounds[id] ?? maxBoundsDefault;

  return {
    minZoom,
    maxBounds,
  };
};

export const updateMapProperties = (map, activeLayer) => {
  if (map && activeLayer) {
    const newMaxBounds = getMapProperties(activeLayer).maxBounds;
    const newMinZoom = getMapProperties(activeLayer).minZoom;

    map.setMaxBounds(newMaxBounds);
    map.setMinZoom(newMinZoom);
  }
};

export const handleNewLocation = (dispatch, setIsModalVisible, navigate) => {
  const urlParams = new URLSearchParams(window.location.search);
  const newLocation = urlParams.get('newLocation');
  if (newLocation) {
    getLocations(dispatch).then((locations) => {
      if (locations && locations.length === 0) {
        setIsModalVisible(true);
        urlParams.delete('newLocation');
        navigate('?' + urlParams.toString(), { replace: true });
      } else {
        urlParams.delete('newLocation');
        navigate('?' + urlParams.toString(), { replace: true });
      }
    });
  }
};

export const handleLayerChange = (
  loader,
  layers,
  setActiveLayer,
  setActiveSubLayer,
  setLastEarthquake,
  navigate,
  user,
  activeLayer,
  selectSublayer,
) => {
  loader.importLibrary('maps');

  const urlParams = new URLSearchParams(window.location.search);
  const layerParam = urlParams.get('layer');

  if (layerParam === 'roadEmergency') {
    let roadEmergency = layers.find((layer) => layer.id === 'emergencias');

    roadEmergency.mode = roadEmergency.mode.map((item) => {
      if (item.default) {
        return { ...item, default: false };
      } else if (item.id === 'emergencias_vial') {
        return { ...item, default: true };
      } else {
        return item;
      }
    });

    setActiveLayer(roadEmergency);
    setActiveSubLayer(selectSublayer(roadEmergency));

    urlParams.delete('layer');
    navigate('?' + urlParams.toString(), { replace: true });
  } else if (layerParam === 'earthquake') {
    let earthquake = layers.find((layer) => layer.id === 'sismos');

    setActiveLayer(earthquake);
    setActiveSubLayer(selectSublayer(earthquake));
    if (urlParams.get('lastEarthquake') === 'true') {
      setLastEarthquake(true);
      urlParams.delete('lastEarthquake');
    } else {
      setLastEarthquake(false);
    }

    urlParams.delete('layer');
    navigate('?' + urlParams.toString(), { replace: true });
  } else if (!activeLayer) {
    const defaultLayer =
      user.rol.slug !== 'persona_natural'
        ? layers.find((layer) => layer.id === 'satellite')
        : layers.find((layer) => layer.id === 'sismos');

    setActiveLayer(defaultLayer);
    setActiveSubLayer(selectSublayer(defaultLayer));
  }
};

export default getMapProperties;
