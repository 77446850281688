import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Button, Col, Row, Select } from 'antd';

import {
  InputCounter,
  SelectWithLabel,
} from '../../../../common/InputWithLabel';
import { IconTrash } from '../../ui/PreventionService/Reports/utils';

const ServiceInput = ({
  companyLevelType,
  levelCompanyService,
  services,
  onChange,
  data,
  setData,
  removeService,
}) => {
  const [rimac, setRimac] = useState(
    services.find((service) => service.id === levelCompanyService?.serviceId)
      ?.rimac_cost || 0,
  );
  const [market, setMarket] = useState(
    services.find((service) => service.id === levelCompanyService?.serviceId)
      ?.market_cost || 0,
  );

  const handleServiceChange = (value) => {
    setRimac(
      services.find((service) => service.id === Number(value))?.rimac_cost || 0,
    );
    setMarket(
      services.find((service) => service.id === Number(value))?.market_cost ||
        0,
    );
    const newData = structuredClone(data);
    const index = newData.levelCompanyServices.findIndex(
      (el) => el.key === levelCompanyService.key,
    );
    newData.levelCompanyServices[index].serviceId = Number(value);
    setData(newData);
    onChange({
      target: {
        value: newData.levelCompanyServices,
        name: 'levelCompanyServices',
      },
    });
  };

  const handleInputChange = (e) => {
    const newData = structuredClone(data);
    const index = newData.levelCompanyServices.findIndex(
      (el) => el.key === levelCompanyService.key,
    );
    newData.levelCompanyServices[index].count = e.target.value;
    setData(newData);
    onChange({
      target: {
        value: newData.levelCompanyServices,
        name: 'levelCompanyServices',
      },
    });
  };

  return (
    <div className='service-item full-width flex align-items'>
      <Row gutter={[16, 16]} className='full-width'>
        <Col sm={12} md={16}>
          <div className='flex full-width'>
            <SelectWithLabel
              className={'full-width services-options'}
              required={true}
              label={'Elige un servicio'}
              name={'levelCompanyService.serviceId'}
              value={
                levelCompanyService?.serviceId
                  ? levelCompanyService?.serviceId.toString()
                  : null
              }
              onSelect={handleServiceChange}
            >
              {services.map((service) => (
                <Select.Option
                  key={uuidv4()}
                  value={service.id.toString()}
                  className={'services-options__option'}
                >
                  <span>{service.name}</span>
                  <div className='services-options__option-price'>
                    <span>Rimac: ${service.rimac_cost}</span>
                    <span>Mercado: ${service.market_cost}</span>
                  </div>
                </Select.Option>
              ))}
            </SelectWithLabel>

            {companyLevelType === 2 ? (
              <div className='badges'>
                <div>{`Rimac: $${rimac}`}</div>
                <div>{`Mercado: $${market}`}</div>
              </div>
            ) : null}
          </div>
        </Col>

        <Col sm={12} md={8}>
          <div className='service-actions full-width'>
            <InputCounter
              name={'levelCompanyService.count'}
              value={
                levelCompanyService?.count ? levelCompanyService?.count : 1
              }
              onChange={handleInputChange}
              className='counter'
              disabledLess={true}
            />
            <Button
              className='delete ml-2'
              type='link'
              onClick={() => removeService(levelCompanyService.key)}
            >
              <IconTrash /> Eliminar
            </Button>
          </div>
        </Col>
      </Row>

      {companyLevelType === 2 ? (
        <div className='badges'>
          <div>{`Rimac: $${rimac}`}</div>
          <div>{`Mercado: $${market}`}</div>
        </div>
      ) : null}
    </div>
  );
};

export default ServiceInput;
