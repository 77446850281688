import { Button } from 'antd';
import {
  DownloadOutlined,
  CheckOutlined,
  FileOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import React from 'react';
import { rsiHasAllReports } from '../utils';

const CardReport = ({
  report,
  showAll,
  _handleDownloadReport,
  handleFinishService,
  _handleFillSurvey,
}) => {
  const getGestor = () => {
    if (
      report.request_service_item?.requestService?.company?.companiesUsers
        ?.length
    )
      return report.request_service_item?.requestService?.company?.companiesUsers
        .map((u) => {
          return `${u.name} ${u.last_name1} ${u.last_name2}`;
        })
        .join(', ');

    return '';
  };

  return (
    <article className='card-user'>
      <div>
        <h4>Fecha de servicio</h4>
        <p>{`${moment.unix(report.date).format('DD MMM YYYY - hh:mm')}`}</p>
      </div>

      <div>
        <h4>Empresa</h4>
        <p>{`${
          report.request_service_item?.requestService?.company?.name || ''
        }`}</p>
      </div>

      <div>
        <h4>Gestor de cuenta</h4>
        <p>{getGestor()}</p>
      </div>

      <div>
        <h4>Tipo de servicio</h4>
        <p>{`${report.request_service_item?.service.name || ''}`}</p>
      </div>

      <div>
        <h4>Nombre de contacto</h4>
        <p>{`${report.contact_name}`}</p>
      </div>

      <div className='actions'>
        <Button
          disabled={!report.filename_uploaded}
          icon={<DownloadOutlined />}
          shape='circle'
          size='small'
          onClick={() => _handleDownloadReport(report)}
        />

        {report?.request_service_item?.state === 5 &&
          rsiHasAllReports(report?.request_service_item) &&
          showAll && (
            <Button
              icon={<CheckOutlined />}
              shape='circle'
              size='small'
              onClick={() => handleFinishService(report)}
            />
          )}

        <Button
          icon={<FileOutlined />}
          shape='circle'
          size='small'
          onClick={() => _handleFillSurvey(report)}
        />
      </div>
    </article>
  );
};

export default CardReport;
