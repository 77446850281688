import React from 'react';
import { Button, Divider, Modal } from 'antd';

import Title from '../../../../../../common/Typograph/Title';
import PDFViewer from './PreviewPDF';

const ModalPreview = ({
  visible,
  onCancel,
  fileName,
  requestServiceItemType,
  requestServiceItemQuiz,
  setShowModalSurvey,
}) => {
  const onDownload = () => {
    if (
      !Boolean(requestServiceItemQuiz) &&
      Number(requestServiceItemType) === 2
    )
      setShowModalSurvey(true);
    else window.open(`${process.env.REACT_APP_WEB_PATH_S3_NEW}${fileName}`);
  };

  return (
    <Modal
      title=''
      open={visible}
      onCancel={onCancel}
      footer={false}
      closable={true}
      className='modal-previewPDF'
      maskClosable={false}
    >
      <Title type='bold-28' style={{ textAlign: 'center' }}>
        Informe Técnico
      </Title>
      <Divider className='mt-2 mb-4' />
      <PDFViewer
        pdfUrl={`${process.env.REACT_APP_WEB_PATH_S3_NEW}${fileName}`}
      />
      <Button
        size='large'
        type='primary'
        style={{ display: 'block' }}
        className='mt-4 mx-auto'
        onClick={onDownload}
      >
        Descargar PDF
      </Button>
    </Modal>
  );
};

export default ModalPreview;
