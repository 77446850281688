import {
  CONTROLMAP_DRAWER_VISIBLE,
  CONTROLMAP_SIDER_VISIBLE,
  CONTROLMAP_FILTER_SELECTED,
  CONTROLMAP_DOWNLOAD_SELECTED,
  CONTROLMAP_SELECTED,
  CONTROLMAP_SET_COORDENADAS,
  CONTROLMAP_DRAW_CIRCLE,
} from '../actions/actionsType';

const initialState = {
  siderVisible: true,
  drawerVisible: false,
  checkFilter: [],
  checkDownloads: [],
  selected: '4',
  drawCircle: false,
};

const controlMap = (state, action) => {
  if (!state) state = initialState;

  switch (action.type) {
    case CONTROLMAP_DRAWER_VISIBLE:
      return {
        ...state,
        drawerVisible: action.payload,
      };
    case CONTROLMAP_SIDER_VISIBLE:
      return {
        ...state,
        siderVisible: action.payload,
      };
    case CONTROLMAP_FILTER_SELECTED:
      return {
        ...state,
        checkFilter: action.payload,
      };
    case CONTROLMAP_DOWNLOAD_SELECTED:
      return {
        ...state,
        checkDownloads: action.payload,
      };
    case CONTROLMAP_SELECTED:
      return {
        ...state,
        selected: action.payload,
      };
    case CONTROLMAP_SET_COORDENADAS:
      return {
        ...state,
        coordenadas: action.payload,
      };
    case CONTROLMAP_DRAW_CIRCLE:
      return {
        ...state,
        drawCircle: action.payload,
      };
    default:
      return state;
  }
};

export default controlMap;
