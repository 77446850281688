import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { ArrowLeftOutlined, PlusOutlined } from '@ant-design/icons';

import { getMyLayers } from '../../../../services/maps';

import SkeletonLayerList from '../components/SkeletonLayerList';
import FormUpload from './FormUpload';
import ListLayers from './ListLayers';
import LayerUpType from './LayerUpType';
import FormCreate from './FormCreate';

const DrawerMyLayers = ({ isMobile, controlMapDrawerVisible }) => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.myLayers);
  const [showForm, setShowForm] = useState(null);
  const [step, setStep] = useState(1);
  const [selectForm, setSelectForm] = useState('upload');

  useEffect(() => {
    if (step === 1) getMyLayers(dispatch);
  }, [step]);

  return (
    <div className='drawer-map-layers' style={{ paddingTop: 0 }}>
      <div className='drawer-map-layers__list'>
        {step === 1 ? (
          <Button
            type='link'
            onClick={() => {
              setShowForm({});
              setStep((prev) => prev + 1);
            }}
          >
            <PlusOutlined />
            Agregar nueva capa
          </Button>
        ) : (
          <Button
            type='link'
            onClick={() => {
              step === 2 && setShowForm(null);
              setStep((prev) => prev - 1);
            }}
          >
            <ArrowLeftOutlined />
            Regresar
          </Button>
        )}
        {step === 1 &&
          (isLoading ? (
            <SkeletonLayerList />
          ) : (
            <ListLayers
              isMobile={isMobile}
              controlMapDrawerVisible={controlMapDrawerVisible}
              setStep={setStep}
              setShowForm={setShowForm}
              setSelectForm={setSelectForm}
            />
          ))}
        {step === 2 && (
          <LayerUpType setStep={setStep} setSelectForm={setSelectForm} />
        )}
        {step === 3 &&
          (selectForm === 'upload' ? (
            <FormUpload
              setShowForm={setShowForm}
              layerData={showForm}
              setStep={setStep}
            />
          ) : (
            <FormCreate
              setStep={setStep}
              layerData={showForm}
              setShowForm={setShowForm}
            />
          ))}
      </div>
    </div>
  );
};

export default DrawerMyLayers;
