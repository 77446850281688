import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import { Button, notification, Skeleton } from 'antd';

import PopoverActions from '../../../../common/Table/PopoverActions';
import Table from '../../../../common/Table';
import ModalConfirm from '../../../../common/ModalConfirm';

import useDebounce from '../../../../hooks/useDebounce';
import {
  createArticlePag,
  getArticles,
  publishEmailArticle,
  publishWebArticle,
  removeArticlePag,
  updateArticlePag,
} from '../../../../services/articles';

import {
  CardSkeleton,
  IconCheck,
  IconEditTable,
  IconEmailFilled,
  IconEye,
  iconLeft,
  iconRight,
  IconTrash,
} from '../../ui/PreventionService/Reports/utils';
import FilterSection from '../../ui/PreventionService/components/FilterSection';

import MainTitle from '../common/MainTitle';
import CardItemMobile from '../common/CardItemMobile';
import DeleteModalImage from '../../../../images/delete_modal_image.svg';
import Empty from '../../../../common/Empty';

const ArticleList = ({
  load,
  create,
  update,
  list,
  isLoading,
  renderItem,
  pagination,
  onOpenForm,
  isMobileScreen,
  onModalSuccess,
  isBeforeTable,
  queryAction,
  onSetAction,
  publishEmail,
  publishWeb,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [textSearch, setTextSearch] = useState('');
  const debouncedTextSearch = useDebounce(textSearch);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [isLoadingPublish, setisLoadingPublish] = useState(false);

  const handleFilter = (e) => setTextSearch(e.target.value.trim());

  useEffect(() => {
    load({
      query: debouncedTextSearch.length > 2 ? debouncedTextSearch : '',
      page: 1,
      limit: pagination.pageSize,
    });
  }, [debouncedTextSearch]);

  const handleTableChange = (pag) =>
    load({
      page: pag.current,
      limit: pag.pageSize,
      query: textSearch,
    });

  const handleTableChangeMobile = (current) => {
    if (
      current === 0 ||
      Math.ceil(pagination.total / pagination.pageSize) + 1 === current
    ) {
      return;
    }

    load({
      page: current,
      limit: pagination.pageSize,
      query: textSearch,
    });
  };

  const handleDelete = (item) => () => setItemToDelete(item);

  const handleSendClients = (item) => {
    setisLoadingPublish(true);
    publishEmail(
      item.id,
      { page: pagination.current, limit: pagination.pageSize },
      isBeforeTable,
      onModalSuccess,
    ).finally(() => setisLoadingPublish(false));
  };

  const handleSeePreview = (article) =>
    navigate(`/vista-previa?articleURL=${article.url ?? ''}`);

  const handleAction = useCallback(
    (action, item) => async () => {
      onOpenForm({
        action,
        ...item,
        keyConfig: 'articles-list',
        titleSection:
          action === 'create' ? 'Crear Artículo' : 'Editar Artículo',
        onAction: async (data, onFinish = () => {}) => {
          action === 'create'
            ? create(data, { page: 1, limit: 5 }, isBeforeTable, onModalSuccess)
            : update(
                data,
                {
                  page: pagination.current,
                  limit: pagination.pageSize,
                  query: textSearch,
                },
                isBeforeTable,
                onModalSuccess,
              );
          onFinish();
          onOpenForm(null);
          action === 'create' && onSetAction(false);
        },
      });
    },
    [onOpenForm, create, update],
  );

  const handlerPublishWeb = async (id) => {
    publishWeb(id, {
      query: textSearch,
      page: pagination.current,
      limit: pagination.pageSize,
    });
  };

  const columns = [
    {
      title: 'Fecha',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '180px',
      render: (date) => {
        const dateObj = new Date(date);
        const day = dateObj.getDate();
        const month = dateObj.toLocaleString('default', { month: 'short' });
        const year = dateObj.getFullYear();
        const hour = dateObj.getHours();
        const minutes = dateObj.getMinutes();

        return date ? `${day} ${month} ${year} - ${hour}:${minutes}` : '';
      },
    },
    {
      title: 'Autor',
      key: 'author',
      width: '30%',
      render: (__, item) =>
        `${item.user?.name} ${item.user?.last_name1} ${item.user?.last_name2}`,
    },
    {
      title: 'Título',
      dataIndex: 'full_title',
      key: 'full_title',
      render: (text) => (
        <span>
          {text?.length > 40 ? `${(text || '').substring(0, 40)}...` : text}
        </span>
      ),
    },
    {
      dataIndex: 'actions',
      title: 'Acciones',
      fixed: 'right',
      key: 'actions',
      width: 80,
      render: (__, item) =>
        rows.length > 0 ? (
          <PopoverActions
            actions={
              <>
                <Button onClick={() => handleSeePreview(item)}>
                  <IconEye /> <span>Vista Previa</span>
                </Button>
                <Button onClick={handleAction('edit', item)}>
                  <IconEditTable /> <span>Editar</span>
                </Button>
                {item.state === 0 && (
                  <Button
                    onClick={() => handlerPublishWeb(item.id)}
                    loading={isLoadingPublish === item.id}
                    disabled={!!isLoadingPublish}
                  >
                    <IconCheck /> <span>Publicar en web</span>
                  </Button>
                )}

                {item.state === 1 && (
                  <Button
                    onClick={() => handleSendClients(item)}
                    loading={isLoadingPublish === item.id}
                    disabled={!!isLoadingPublish}
                  >
                    <IconEmailFilled /> <span>Enviar a clientes</span>
                  </Button>
                )}

                <Button onClick={handleDelete(item)}>
                  <IconTrash /> <span>Eliminar</span>
                </Button>
              </>
            }
          />
        ) : null,
    },
  ];

  const handleRemove = async () => {
    setLoadingDelete(true);

    if (!itemToDelete)
      return notification.warning({
        description: 'No ha seleccionado ningun artículo para eliminar.',
        message: 'Eliminar artículo',
      });

    const deleted = await removeArticlePag(dispatch, itemToDelete.id, {
      page: pagination.current,
      limit: pagination.pageSize,
      query: textSearch,
    });

    if (isBeforeTable && deleted) {
      setItemToDelete(null);
      setLoadingDelete(false);

      return onModalSuccess({ message: '¡Listo! Se eliminó correctamente' });
    }

    if (deleted)
      notification.success({
        description: 'Se eliminó el articulo correctamente.',
        message: 'Parametro eliminado! ',
      });
    else
      notification.warning({
        description: 'Ha ocurrido un error.',
        message: 'Eliminar artículo',
      });

    setItemToDelete(null);
    setLoadingDelete(false);
  };

  useEffect(() => {
    setRows(list);
  }, [list]);

  useEffect(() => {
    if (queryAction) {
      handleAction('create', renderItem)();
    }
  }, [queryAction]);

  return (
    <>
      <MainTitle
        buttonAction={handleAction('create', renderItem)}
        renderItem={renderItem}
        isMobileScreen={isMobileScreen}
      />

      <FilterSection
        complete={true}
        text={`${
          isMobileScreen
            ? 'Filtra tu búsqueda'
            : 'Filtrar lista de nivel de artículos'
        }`}
        handleFilter={handleFilter}
      />

      {!isMobileScreen ? (
        !isLoading && list?.length === 0 ? (
          <Empty title='No se encontraron articulos' />
        ) : (
          <section className='show-data'>
            {isLoading ? (
              <Skeleton active title={false} paragraph={{ rows: 1 }} />
            ) : (
              <p className='info-total-data'>
                Se econtrarón <span>{pagination?.total}</span>{' '}
                {pagination?.total === 1 ? 'artículo' : 'artículos'}
              </p>
            )}

            <Table
              dataSource={rows}
              columns={columns}
              rowKey={(record) => record.id}
              loading={isLoading}
              locale={{ emptyText: 'No se econtrarón artículos.' }}
              pagination={pagination}
              onChangeRows={setRows}
              onChange={handleTableChange}
            />
          </section>
        )
      ) : (
        <section className='content-mobile action-bottom'>
          {isLoading ? (
            <Skeleton active title={false} paragraph={{ rows: 1 }} />
          ) : (
            <p className='info-total-data'>
              {pagination?.total === 1 ? 'Se econtraró' : 'Se econtrarón'}{' '}
              <span>{pagination?.total}</span>{' '}
              {pagination?.total === 1 ? 'artículo' : 'artículos'}
            </p>
          )}

          {isLoading ? (
            [1, 2, 3, 4].map((el) => <CardSkeleton key={el} />)
          ) : rows?.length === 0 ? (
            <Empty title='No se encontraron articulos' />
          ) : (
            rows?.map((data) => (
              <CardItemMobile
                key={data.id}
                data={data}
                columns={columns.filter((el) => el.dataIndex !== 'actions')}
                onPreview={handleSeePreview}
                onEdit={handleAction('edit', data)}
                onDelete={setItemToDelete}
                onSend={handleSendClients}
                section='articles-list'
                isLoadingPublish={isLoadingPublish}
              />
            ))
          )}

          <div className='d_content__list__pag'>
            <div
              className={'d_content__list__pag__button'}
              onClick={() => handleTableChangeMobile(pagination?.current - 1)}
            >
              {iconLeft({
                color: pagination?.current === 1 ? '#A9AFD9' : '#4F4FFF',
              })}
            </div>

            <div className='d_content__list__pag__number color-red-1'>
              {pagination?.current}
            </div>
            <div className='d_content__list__pag__sep'>/</div>
            <div className='d_content__list__pag__number color-gray-3'>
              {Math.ceil(pagination?.total / pagination?.pageSize)}
            </div>

            <div
              className={'d_content__list__pag__button'}
              onClick={() => handleTableChangeMobile(pagination?.current + 1)}
            >
              {iconRight({
                color:
                  pagination.current ===
                  Math.ceil(pagination?.total / pagination?.pageSize)
                    ? '#A9AFD9'
                    : '#4F4FFF',
              })}
            </div>
          </div>
        </section>
      )}

      <ModalConfirm
        visible={itemToDelete}
        onClose={() => setItemToDelete(null)}
        title='¿Seguro que desea eliminar el articulo de la lista?'
        image={DeleteModalImage}
        actionsContent={
          <div className='actions-delete-modal mt-2'>
            <Button
              className='button secondary-button mr-2'
              onClick={() => setItemToDelete(null)}
            >
              Cancelar
            </Button>

            <Button
              className='button ml-1'
              type='primary'
              disabled={loadingDelete}
              loading={loadingDelete}
              onClick={handleRemove}
            >
              Eliminar
            </Button>
          </div>
        }
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  load: (pagination) => getArticles(dispatch, pagination),
  create: async (data, pagination, isBeforeTable, onModalSuccess) => {
    const created = await createArticlePag(dispatch, data, pagination);

    if (isBeforeTable && created) {
      onModalSuccess({
        message: '¡Listo! Se creó la artículo correctamente',
      });

      return;
    }

    created
      ? notification.success({
          description: 'Se creo la artículo correctamente.',
          message: '¡Versión creada!',
        })
      : notification.warning({
          description: 'Ha ocurrido un error.',
          message: 'Crear artículo',
        });
  },
  update: async (data, pagination, isBeforeTable, onModalSuccess) => {
    const updated = await updateArticlePag(dispatch, data, pagination);

    if (isBeforeTable && updated) {
      onModalSuccess({
        message: '¡Cambios guardados! Se guardaron los cambios correctamente',
      });

      return;
    }

    updated
      ? notification.success({
          description: 'Se guardaron los cambios correctamente.',
          message: '¡Cambios guardados!',
        })
      : notification.warning({
          description: 'Ha ocurrido un error.',
          message: 'Actualizar artículo',
        });
  },
  publishEmail: async (id, pagination, isBeforeTable, onModalSuccess) => {
    const published = await publishEmailArticle(dispatch, id, pagination);

    if (isBeforeTable && published) {
      onModalSuccess({
        message:
          '¡Artículo enviado! Se envió el articulo a los clientes correctamente',
      });

      return;
    }

    published
      ? notification.success({
          description: 'Se envió el artículo a los clientes correctamente.',
          message: '¡Artículo enviado!',
        })
      : notification.warning({
          description: 'Ha ocurrido un error.',
          message: 'Enviar artículo',
        });
  },
  publishWeb: async (id, pagination) => {
    const published = await publishWebArticle(dispatch, id, pagination);
    if (published)
      notification.success({
        message: 'Artículo publicado en la web correctamente.',
      });
    else
      notification.warning({
        message: 'Ha ocurrido un error.',
      });

    return !!published;
  },
});

const mapStateToProps = (state) => ({
  list: state.articles.list || [],
  pagination: state.articles.pagination || [],
  isLoading: state.articles.isLoading,
  isLoadingCreate: state.articles.isLoadingCreate,
});

export default connect(mapStateToProps, mapDispatchToProps)(ArticleList);
