import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Modal, notification, Select, Skeleton } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

import { getService } from '../../../../../../services/service';
import {
  addServiceToProvider,
  getServiceOfProviders,
  removeServiceToProvider,
} from '../../../../../../services/providers';
import { useScreen } from '../../../../../../lib/screen';

import { IconCloseCircle } from '../../../../../../common/Icons';
import { IconEditTable, IconTrash } from '../../Reports/utils';
import ModalEditItem from './ModalEditItem';

const ModalProvider = ({
  loadListOfService,
  listOfServices,
  visible,
  handleClose,
  provider,
  widthScreen,
}) => {
  const { isMobileScreen } = useScreen();
  const [executeAction, setExecuteAction] = useState(false);
  const [isLoadingServicesOfProvider, setIsLoadingServicesOfProvider] =
    useState(false);
  const [listServiceOfProvider, setListServiceOfProvider] = useState([]);
  const [disabledButton, setDisabledButton] = useState(true);
  const [servicesToDelete, setServicesToDelete] = useState([]);
  const [showModalZones, setShowModalZones] = useState(false);
  const [editItem, setEditItem] = useState(null);

  const listOfServicesAvailable = listOfServices.filter(
    (service) =>
      !listServiceOfProvider.find(
        (serviceProvider) => serviceProvider.id === service.id,
      ),
  );

  const _handleSelectService = (item, { props: { name } }) => {
    setListServiceOfProvider((prev) => {
      let newList = [...prev];
      newList.splice(0, 0, { id: item, name });

      return newList;
    });
  };

  const handleAddZones = (item) => {
    setEditItem(item);
    setShowModalZones(true);
  };

  const handleDelete = (item) => {
    setListServiceOfProvider(
      listServiceOfProvider.filter((service) => service.id !== item.id),
    );
    setServicesToDelete((prev) => prev.concat(item.id));
    setDisabledButton(false);
  };

  const _handleSave = () => {
    setExecuteAction(true);
    const servicesToAdd = listServiceOfProvider.filter(
      (service) => service.isAdded,
    );

    servicesToAdd.forEach((service) => {
      addServiceToProvider(
        Number(provider.id),
        Number(service.id),
        service.data,
      );
    });
    servicesToDelete.forEach((serviceId) => {
      removeServiceToProvider(provider.id, serviceId);
    });

    if (widthScreen < 768) {
      handleClose();
    }
    handleClose();
    setExecuteAction(false);

    notification.success({
      description: 'Se guardaron los cambios correctamente.',
      message: '¡Cambios guardados!',
    });
  };

  useEffect(() => {
    setIsLoadingServicesOfProvider(true);

    getServiceOfProviders(provider.id)
      .then((list) => {
        setListServiceOfProvider(list.reverse());
        setDisabledButton(true);
      })
      .finally(() => setIsLoadingServicesOfProvider(false));

    if (listOfServices.length === 0) {
      loadListOfService();
    }
  }, [provider]);

  return (
    <Modal
      open={visible}
      title=''
      closable={true}
      onCancel={() => handleClose()}
      centered={true}
      width={isMobileScreen ? '' : '70%'}
      onOk={_handleSave}
      className='modal-provider'
      cancelText='Cancelar'
      okText='Guardar'
      confirmLoading={executeAction}
      destroyOnClose
      okButtonProps={{ disabled: disabledButton }}
    >
      <div className='content-modal cmp_minimal-scroll-y'>
        <h2>Servicios del proveedor</h2>

        <div className='form'>
          <label htmlFor='listServices'>Agrega otro servicio</label>
          <Select
            id='listServices'
            value=''
            onChange={_handleSelectService}
            placeholder='Seleccione un servicio'
          >
            {listOfServicesAvailable.map((item) => (
              <Select.Option name={item.name} key={item.id}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </div>

        <ul>
          {isLoadingServicesOfProvider ? (
            <Skeleton active title={false} paragraph={{ rows: 4 }} />
          ) : (
            listServiceOfProvider.map((item) => (
              <li key={item.id}>
                <p>{item.name}</p>
                <div className='flex'>
                  <Button onClick={() => handleAddZones(item)}>
                    <IconEditTable color='#4F4FFF' /> Agregar zonas
                  </Button>
                  <Button onClick={() => handleDelete(item)}>
                    <IconTrash color='#4F4FFF' /> Eliminar
                  </Button>
                </div>
              </li>
            ))
          )}
        </ul>
        <ModalEditItem
          visible={showModalZones}
          onCancel={() => setShowModalZones(false)}
          item={editItem}
          setEditItem={setEditItem}
          setListServiceOfProvider={setListServiceOfProvider}
          setDisabledButton={setDisabledButton}
        />
      </div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  loadListOfService: () => getService(dispatch),
});

const mapStateToProps = (state) => ({
  listOfServices: state.service.list || [],
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalProvider);
