import React, { useState, useEffect, memo } from 'react';
import { Button, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  UnorderedListOutlined,
  InfoCircleOutlined,
  FileOutlined,
} from '@ant-design/icons';
import { getAllCourses } from '../../../../../../services/courses';
import Title from '../../../../../../common/Typograph/Title';

const InHouseCoursesModal = () => {
  const [visible, setVisible] = useState(false);
  const [courses, setCourses] = useState([]);
  const dispatch = useDispatch();
  const { typePolizaId, listCourses, isLoadingList } = useSelector((state) => ({
    listCourses: state.courses.list || [],
    isLoadingList: state.courses.isLoadingList,
    isLoadingCreate: state.courses.isLoadingCreate,
    typePolizaId: String(state.requestServiceForm?.typePolizaId),
  }));
  useEffect(() => {
    if (visible) {
      if (listCourses.length) {
        setCourses(
          listCourses?.filter(({ target }) => String(target) === typePolizaId),
        );
      }
    } else {
      getAllCourses(dispatch);
    }
  }, [visible, listCourses]);

  const handleClick = () => setVisible(true);

  return (
    <>
      <Button
        size='small'
        type='link'
        icon={<UnorderedListOutlined />}
        onClick={handleClick}
      >
        Ver lista de cursos
      </Button>
      <Modal
        className={'modal-courses'}
        width={756}
        open={visible}
        onCancel={() => {
          setVisible(false);
        }}
        footer={null}
        title={`Tienes ${courses.length} cursos disponibles para capacitarte`}
      >
        <hr />
        <div className='modal-content'>
          <article className='card-info'>
            <div className='card-message'>
              <InfoCircleOutlined />
              <Title type={'bold-14'}>
                En caso no encuentres el curso en el listado, puedes comunicarte
                con tu gestor de cuenta
              </Title>
            </div>
          </article>
          {isLoadingList ? (
            '...Cargando'
          ) : (
            <>
              <div className='courses'>
                {courses && courses.length
                  ? courses.map(({ id, name }, idx) => (
                      <div
                        className='course'
                        key={String(id)}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '10px',
                          padding: '10px 0',
                          ...(idx !== courses.length - 1
                            ? { borderBottom: '1px solid #E3E6FA' }
                            : {}),
                        }}
                      >
                        <FileOutlined />
                        <span className='course__text'>{name}</span>
                      </div>
                    ))
                  : null}
              </div>
              <div className='degrade'></div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

export default memo(InHouseCoursesModal);
