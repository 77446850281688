import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notification, Typography } from 'antd';
import {
  RideButton,
  RideGlEducationSolid,
} from '@rimac-seguros/ride-system-components';

import { createCoursePaginated } from '../../../../../services/courses';
import { getEvolLink } from '../../../../../services/users';
import { pushDigitalData } from '../../../../../lib/utils';
import { TRACK } from '../../../../../lib/consts';
import ModalCourse from './ModalCourse';

const { Title } = Typography;

const HeaderSection = () => {
  const dispatch = useDispatch();
  const [modalCourse, setModalCourse] = useState(false);
  const isLoadingCreate = useSelector(
    (state) => state.courses.isLoadingCreateuseSelector,
  );
  const typePolizas = useSelector((state) => state.typePoliza.list);

  const handleViewVirtualCourses = () => {
    if (process.env.REACT_APP_ANALYTICS_ENABLE !== '0') {
      pushDigitalData(
        {
          action: {
            group: 'Centro Monitoreo',
            category: 'Cursos',
            name: 'Ir a cursos virtuales',
          },
        },
        TRACK.ACTION,
      );
    }

    getEvolLink()
      .then((data) => {
        const linkTo = `${data.url}?${data.parameters}`;
        window.open(linkTo, '_blank');
      })
      .catch(() => {
        notification.error('Ha ocurrido un error');
      });
  };

  const handleAction = async (values) => {
    const created = await createCoursePaginated(dispatch, values);
    if (created) {
      notification.success({
        description: 'El curso se creo correctamente',
        message: 'Curso creado',
      });
    } else {
      notification.warning({
        description: 'Ha ocurrido un error.',
        message: 'Crear curso!',
      });
    }
    setModalCourse(false);
  };

  return (
    <div className='service-header mb-4'>
      <Title className='header-container'>Cursos</Title>

      <div className='flex items-center gap-4'>
        <RideButton
          variant='text-primary'
          size='medium'
          text='Ir a cursos virtuales'
          glyph={<RideGlEducationSolid />}
          glyphPosition='left'
          onClick={handleViewVirtualCourses}
        />
        <RideButton
          variant='fill'
          size='medium'
          onClick={() => setModalCourse(true)}
          text='Crear curso'
        />
      </div>

      {modalCourse && (
        <ModalCourse
          course={null}
          visible={modalCourse}
          isLoadingCreate={isLoadingCreate}
          typePolizas={typePolizas}
          handleClose={() => {
            setModalCourse(false);
          }}
          handleAction={handleAction}
        />
      )}
    </div>
  );
};

export default HeaderSection;
