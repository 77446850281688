import React, { useState, useEffect } from 'react';
import FileBase64 from 'react-file-base64';
import { Button } from 'antd';

import SmallImage from '../Attachment/SmallImage';
import IconUpload from '../../images/icon_upload.svg';
import ViewImage from '../../components/client/Newsletters/EventManagment/ViewImage';

const ImageInput = ({ onChange, value, className }) => {
  const [image, setImage] = useState(false);
  const [visible, setVisible] = useState(false);
  const imageUrl = value
    ? `${process.env.REACT_APP_WEB_PATH_S3_NEW}${value}`
    : null;

  useEffect(() => {
    if (typeof value === 'string') setImage({ name: value });
  }, [value]);

  const handleClickImage = () =>
    document.querySelector('.upload-image .none input').click();

  const handleDoneFileBase64 = (file) => {
    setImage(file[0]);
    onChange({ target: { value: file[0], name: 'image' } });
  };

  const handleDeleteImage = () => {
    setImage(false);
    onChange({ target: { value: null, name: 'image' } });
  };

  return (
    <div className={className}>
      {imageUrl || image?.base64 ? (
        <div className='card-image-form card-image-upload'>
          <div className='image__left'>
            <div onClick={() => setVisible(true)}>
              <SmallImage
                src={image ? image?.base64 : imageUrl}
                locale={true}
              />
            </div>
            <div className='detail-text'>
              <div className='title'>
                {image?.name?.length > 20
                  ? `${image?.name?.slice(0, 20)}...`
                  : image?.name}
              </div>
              <div className='size'>{image?.size}</div>
            </div>
          </div>

          <div className='image__right'>
            <Button onClick={handleDeleteImage}>
              <span className='text'>Eliminar</span>
            </Button>
          </div>
        </div>
      ) : (
        <div className='upload-image' onClick={handleClickImage}>
          <div className='none'>
            <FileBase64 multiple={true} onDone={handleDoneFileBase64} />
          </div>

          <img src={IconUpload} alt='' aria-hidden />

          <div className='text'>
            <h4>Adjuntar imágen</h4>
            <p>Máximo 30 MB - Formatos JPG, JPEG y PNG</p>
          </div>
        </div>
      )}

      <ViewImage
        visible={visible}
        setVisible={() => setVisible(false)}
        file={image ? image?.base64 : imageUrl}
      />
    </div>
  );
};

export default ImageInput;
