import React, { useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { Steps, Typography, Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import AddContact from './AddContact';
import AddService from './Services';
import { OPEN_FORM_REQUEST } from '../../../../redux/actions/actionsType';
import { getLastService } from '../../../../services/requestServices';
import { pushDigitalData } from '../../../../lib/utils';
import { TRACK } from '../../../../lib/consts';

const { Title } = Typography;
const { Step } = Steps;

const RequestSteps = ({
  data,
  closeForm,
  updateStorageMain,
  cleanEmptyContact,
  isMobile,
  stepRequestForm,
  fromRequestService,
  companySelected,
  companyId,
  updateForm,
}) => {
  const [currentStep, setCurrentStep] = useState(stepRequestForm || 0);

  const _handleUpdateLocalStorage = useCallback(
    (key, value) => {
      localStorage.setItem(`${key}-${data?.id}`, JSON.stringify(value));
    },
    [data],
  );

  const _handleChangeStep = useCallback(
    (dir) => () => {
      if (dir === 'prev') {
        const storageData = localStorage.getItem(`contact-${data?.id}`);

        if (storageData)
          updateForm({ contact: JSON.parse(storageData), id: data?.id });
      }

      setCurrentStep((prev) => (dir === 'next' ? prev + 1 : prev - 1));
    },
    [data, updateForm],
  );

  const _handleCloseForm = () => {
    const formContact = localStorage.getItem(`contact-${data?.id}`);
    if (!formContact) {
      cleanEmptyContact(data?.id);
    } else {
      const values = Object.values(JSON.parse(formContact)).filter(Boolean);
      if (!values.length) cleanEmptyContact(data?.id);
      else updateStorageMain();
    }
    closeForm();
  };

  useEffect(() => {
    if (process.env.REACT_APP_ANALYTICS_ENABLE !== '0') {
      pushDigitalData(
        {
          form: {
            channel: 'Centro Monitoreo',
            name: `Servicios - ${
              data?.action === 'add' ? 'Agregar' : 'Actualizar'
            } un servicio`,
          },
        },
        TRACK.FORM_VIEW,
      );
    }
    return () => {
      setCurrentStep(0);
    };
  }, []);

  useEffect(() => {
    if (
      data &&
      !Object.values(data?.contact ?? {}).length &&
      (companySelected || companyId)
    )
      getLastService(companySelected || companyId).then((service) => {
        const lastService =
          service?.requestServiceItems?.length > 0
            ? service.requestServiceItems[0]
            : null;
        if (lastService) {
          updateForm({
            contact: lastService,
            id: data.id,
          });
          _handleUpdateLocalStorage('contact', lastService);
        }
      });
  }, []);

  if (!data) return <></>;

  return (
    <div className={'d_content flex flex-col'}>
      <div className='flex flex-align-items-center flex-align-self-start mb-1'>
        <Button
          className='mr-1 clock-icon'
          icon={<LeftOutlined />}
          shape='circle'
          onClick={
            currentStep === 1 ? _handleChangeStep('prev') : _handleCloseForm
          }
        />
        <Title level={2} style={{ marginBottom: 0 }}>
          {data?.action === 'add' || fromRequestService
            ? 'Agregar un Servicio'
            : 'Actualizar un Servicio'}
        </Title>
      </div>
      <div className='formulary-container mt-3'>
        <Steps
          className='steps-blue-berry mb-3'
          size='small'
          current={currentStep}
          direction={'horizontal'}
        >
          <Step key='contact' title='Contacto' />
          <Step key='service' title='Servicio' />
        </Steps>
        <div className='flex flex-col full-width mt-2'>
          {currentStep === 0 ? (
            <AddContact
              action={data?.action}
              contactData={data?.contact}
              onNextStep={_handleChangeStep('next')}
              onUpdateLocalStorage={_handleUpdateLocalStorage}
              updateRequestStorage={updateStorageMain}
              isMobile={isMobile}
              companySelected={companySelected}
              idForm={data?.id}
            />
          ) : currentStep === 1 ? (
            <AddService
              idForm={data?.id}
              action={data?.action}
              isMobile={isMobile}
              companySelected={companySelected}
              closeForm={closeForm}
              onUpdateLocalStorage={_handleUpdateLocalStorage}
              updateRequestStorage={updateStorageMain}
              service={data?.service}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  closeForm: () => {
    dispatch({ type: OPEN_FORM_REQUEST, payload: { action: 'close' } });
  },
  cleanEmptyContact: (id) => {
    dispatch({
      type: OPEN_FORM_REQUEST,
      payload: {
        id,
        action: 'delete',
      },
    });
  },
  updateForm: (payload) => {
    dispatch({
      type: OPEN_FORM_REQUEST,
      payload: {
        data: payload,
        action: 'update',
      },
    });
  },
});

const mapStateToProps = (state) => ({
  stepRequestForm: state.requestServiceForm.currentStep,
  fromRequestService: state.requestServiceForm.fromRequestService,
  companyId: state.auth.user?.company_id,
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestSteps);
