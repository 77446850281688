import React from 'react';

const ArticleCard = ({ title, description, img, tag, href }) => {
  return (
    <div className='articulo-card'>
      <div className='articulo-card__img-container'>
        <div className='img-container-clip'>
          {tag && <span className='articulo-card__tag'>{tag}</span>}
          <img className='articulo-card__image' src={img} alt='Artículo' />
        </div>
      </div>
      <div className='articulo-card__text-container'>
        <div className='articulo-card__title'>{title}</div>
        <div className='articulo-card__description'>{description}</div>
        <a href={href} className='articulo-card__button'>
          Leer artículo
        </a>
      </div>
    </div>
  );
};

export default ArticleCard;
