import {
  LOADED_EXPLORATIONS,
  LOADING_EXPLORATIONS,
} from '../actions/actionsType';

const initialState = {
  list: [],
  isLoading: false,
  provider: null,
};

const exploration = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_EXPLORATIONS:
      return {
        ...state,
        isLoading: action.payload,
      };
    case LOADED_EXPLORATIONS:
      const { list, provider } = action.payload;

      return {
        ...state,
        isLoading: false,
        provider,
        list,
      };
    default:
      return state;
  }
};

export default exploration;
