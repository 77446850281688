import {
  COMMON_SET_POSITION_LATLNG,
  COMMON_FORECAST_POINT_DATA,
  COMMON_SELECT_LOCATION_ID,
  COMMON_SHOW_FORECAST_DAYS_DIALOG,
  COMMON_FORECAST_DAYS_DATA,
} from '../actions/actionsType';

const initialState = {
  positionLatLng: null,
  selectLocationId: null,
  latlngForecast: null,
  showForecastDaysDialog: false,
  forecastDaysData: null,
};

const common = (state, action) => {
  if (!state) {
    state = initialState;
  }

  switch (action.type) {
    case COMMON_SET_POSITION_LATLNG:
      let position = action.payload;

      if (Array.isArray(action.payload)) {
        position = {
          lat: action.payload[0],
          lng: action.payload[1],
        };
      }
      return {
        ...state,
        positionLatLng: position,
      };
    case COMMON_SELECT_LOCATION_ID:
      return {
        ...state,
        selectLocationId: action.payload,
      };
    case COMMON_FORECAST_POINT_DATA:
      const { id, lat, lng } = action.payload;

      return {
        ...state,
        selectLocationId: id,
        latlngForecast: { lat, lng },
      };
    case COMMON_FORECAST_DAYS_DATA:
      return {
        ...state,
        forecastDaysData: action.payload,
      };
    case COMMON_SHOW_FORECAST_DAYS_DIALOG:
      return {
        ...state,
        showForecastDaysDialog: action.payload,
      };
    default:
      return state;
  }
};

export default common;
