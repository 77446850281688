import { message } from 'antd';
import { post, put, get } from '../lib/ajax';
import axios from 'axios';

import {
  LOADED_VALUE_OFFER,
  LOAD_VALUE_OFFER_LOADING,
  LOADING_CREATE_VALUE_OFFER,
  OPEN_FORM_VALUE_OFFER,
  REMOVING_VALUE_OFFER,
} from '../redux/actions/actionsType';

export const getPresignedUrl = (data) => {
  const payload = {
    type: data.type,
    name: data.name,
  };

  return post('/value_offer/getUrlToImport', payload);
};

export const sendFileValueOffer = (url, data, handleProgress) =>
  axios.put(url, data, {
    onUploadProgress: handleProgress,
  });

export const getValueOffer = async (dispatch, obj) => {
  try {
    dispatch({ type: LOAD_VALUE_OFFER_LOADING, payload: true });
    const r = await post('/value_offer/listValueOffer', obj);

    if (r.data.success) {
      dispatch({
        type: LOADED_VALUE_OFFER,
        payload: r.data.payload,
      });
      dispatch({ type: LOAD_VALUE_OFFER_LOADING, payload: false });

      return true;
    }
  } catch (e) {
    console.error('error', e);
  }
  dispatch({ type: LOAD_VALUE_OFFER_LOADING, payload: false });
};

export const createValueOffer = async (file, dispatch, query) => {
  dispatch({ type: LOADING_CREATE_VALUE_OFFER, payload: true });

  try {
    const r = await post('/value_offer/createValueOffer', file);

    if (r.data.success) {
      dispatch({ type: OPEN_FORM_VALUE_OFFER, payload: false });
      dispatch({ type: LOADING_CREATE_VALUE_OFFER, payload: false });
      getValueOffer(dispatch, query);

      return true;
    }
  } catch (e) {}

  dispatch && dispatch({ type: LOADING_CREATE_VALUE_OFFER, payload: false });

  return false;
};

export const updateValueOffer = async (file, dispatch, query) => {
  dispatch({ type: LOAD_VALUE_OFFER_LOADING, payload: true });
  dispatch({ type: LOADING_CREATE_VALUE_OFFER, payload: true });

  try {
    const r = await put('/value_offer/updateValueOffer', file);
    if (r.data.success) {
      dispatch({ type: OPEN_FORM_VALUE_OFFER, payload: false });
      dispatch({ type: LOADING_CREATE_VALUE_OFFER, payload: false });
      getValueOffer(dispatch, query);

      return true;
    }
  } catch (e) {}

  dispatch({ type: LOADING_CREATE_VALUE_OFFER, payload: false });
  message.error('Problemas al actualizar');

  return false;
};

export const deleteValueOffer = async (id, dispatch) => {
  try {
    const r = await post('/value_offer/removeValueOffer?id=' + id, {});
    if (r.data.success) {
      dispatch({ type: REMOVING_VALUE_OFFER, payload: id });

      return true;
    }

    return false;
  } catch (e) {
    return false;
  }
};

export const downloadReportValueOffer = async (data) => {
  try {
    let r = await get('/value_offer/getReportDownload?type=' + data.key);
    if (r.data && r.data.success) {
      return r.data.running;
    } else {
      message.error('Ha corrido un error');
      return false;
    }
  } catch (error) {
    message.error('Ha corrido un error');
    return false;
  }
};

export const getStatusReport = async () => {
  try {
    const r = await get('/value_offer/getReportStatus');
    if (r.data && r.data.success) {
      const { payload } = r.data;
      const config = payload ? JSON.parse(payload.value) : {};
      return config;
    } else {
      message.error('Ha corrido un error');
      return false;
    }
  } catch (error) {
    message.error('Ha corrido un error');
    return false;
  }
};

export const listValueOfferDownload = async (id) => {
  try {
    const r = await get('/value_offer/listValueOfferDownload?id=' + id);
    if (r.data.success) return r.data.list;

    return [];
  } catch (e) {
    return [];
  }
};

export const registerDownloadValueOffer = async (data) => {
  try {
    const r = await post('/value_offer/createValueOfferDownload', data);
    if (r.data.success) return true;
  } catch (e) {}

  return false;
};
