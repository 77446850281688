import React, { useState } from 'react';

const FiltersSelect = ({ data, onChange, text }) => {
  const [showFilters, setShowFilters] = useState(false);
  const [filterSelected, setFilterSelected] = useState(0);

  const changeFilter = (index) => {
    setFilterSelected(index);
    setShowFilters(false);
    onChange(index);
  };

  return (
    <div className='portal-filters-select'>
      <span onClick={() => setShowFilters((s) => !s)}>
        {text}{' '}
        <i className='fas fa-chevron-down portal-filters-select__down-tablet'></i>
      </span>
      <div className='portal-filters-select__selected-box'>
        <div
          className='portal-filters-select__selected'
          onClick={() => setShowFilters((s) => !s)}
        >
          {data[filterSelected]}
          <i className='fas fa-chevron-down'></i>
        </div>
        {showFilters && (
          <div className='portal-filters-select__dropdown'>
            {data.map((filter, index) => (
              <div
                key={filter}
                className={`portal-filters-select__item ${filterSelected === index ? 'portal-filters-select__item--selected' : ''}`}
                onClick={() => changeFilter(index)}
              >
                {filter}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default FiltersSelect;
