import {
  SENTMAIL_LOADING_LIST,
  SENTMAIL_LOADED_LIST,
} from '../actions/actionsType';

const initialState = {
  list: [],
  page: 1,
  total: 0,
  pagination: {
    pageSize: 5,
  },
  isLoadingList: false,
};

const sentMail = (state, action) => {
  if (!state) state = initialState;

  switch (action.type) {
    case SENTMAIL_LOADING_LIST:
      return {
        ...state,
        isLoadingList: action.payload,
      };
    case SENTMAIL_LOADED_LIST:
      return {
        ...state,
        list: action.payload.list,
        page: action.payload.page,
        total: action.payload.total,
        pagination: {
          current: action.payload.page,
          pageSize: action.payload.items_per_page,
          total: action.payload.total,
        },
        isLoadingList: false,
      };
    default:
      return state;
  }
};

export default sentMail;
