import React, { memo, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Typography, notification, Modal } from 'antd';
import { RideButton } from '@rimac-seguros/ride-system-components';

import { useScreen } from '../../../lib/screen';
import useQueryParams from '../../../hooks/useQueryParams';
import { exportEmergencies } from '../../../services/roadEmergency';

import Topbar from '../../../common/ui/Topbar';
import FormConfiguration from './common/FormConfiguration';
import ModalConfirm from '../../../common/ModalConfirm';
import { IconReturnHtml } from '../../../common/Icons/iconText';
import TypePolizas from './TypePolizas';
import Services from './Services';
import Brokers from './Brokers';
import TypeIndustry from './TypeIndustry';
import BusinessSegment from './BusinessSegment';
import Versions from './Versions';
import Modules from './Modules';
import LevelCompany from './LevelCompany';
import Parameters from './Parameters';
import ArticleList from './ArticleList';
import RoadEmergencies from './RoadEmergencies';
import Emergencies from './Emergencies';
import Banner from './Banner';
import ImportLayers from './Layers';
import Locations from './Locations';

import IconPolicy from '../../../images/configuration/icon_policy.svg';
import IconServices from '../../../images/configuration/icon_services.svg';
import IconChecklist from '../../../images/configuration/icon_checklist.svg';
import IconBuildings from '../../../images/configuration/icon_buildings.svg';
import IconVersions from '../../../images/configuration/icon_versions.svg';
import IconModule from '../../../images/configuration/icon_modules.svg';
import IconArticles from '../../../images/configuration/icon_articles.svg';
import IconParameters from '../../../images/configuration/icon_parameters.svg';
import IconRoadEmergencies from '../../../images/configuration/icon_roadEmergencies.svg';
import IconEmergencies from '../../../images/configuration/icon_emergencies.svg';
import IconBanner from '../../../images/configuration/icon_banner.svg';
import IconFinish from '../../../images/icon_finish.svg';

const { Title } = Typography;

const configurationItems = [
  {
    titleSection: 'Tipos de póliza',
    key: 'poliza-type',
    keyConfig: 'poliza-type',
    icon: IconPolicy,
    buttonText: 'Crear',
    Component: TypePolizas,
  },
  {
    titleSection: 'Servicios',
    key: 'services',
    keyConfig: 'services',
    icon: IconServices,
    buttonText: 'Crear',
    Component: Services,
    hasFullImage: true,
  },
  {
    titleSection: 'Lista de corredores',
    key: 'corredores',
    keyConfig: 'corredores',
    icon: IconChecklist,
    buttonText: 'Crear',
    Component: Brokers,
    hasFullImage: true,
  },
  {
    titleSection: 'Tipos de industrias',
    key: 'industry-type',
    keyConfig: 'industry-type',
    icon: IconBuildings,
    buttonText: 'Crear',
    Component: TypeIndustry,
  },
  {
    titleSection: 'Nivel de empresas',
    key: 'level-company',
    keyConfig: 'level-company',
    icon: IconBuildings,
    buttonText: 'Crear',
    Component: LevelCompany,
  },
  {
    titleSection: 'Segmento comercial',
    key: 'comercial-seg',
    keyConfig: 'comercial-seg',
    icon: IconBuildings,
    buttonText: 'Crear',
    Component: BusinessSegment,
  },
  {
    titleSection: 'Versiones',
    key: 'versions',
    keyConfig: 'versions',
    icon: IconVersions,
    buttonText: 'Crear',
    Component: Versions,
    hasFullImage: true,
  },
  {
    titleSection: 'Módulos',
    key: 'modules',
    keyConfig: 'modules',
    icon: IconModule,
    withAction: false,
    Component: Modules,
  },
  {
    titleSection: 'Lista de artículos',
    key: 'articles-list',
    keyConfig: 'articles-list',
    icon: IconArticles,
    buttonText: 'Crear',
    Component: ArticleList,
  },
  {
    titleSection: 'Parámetros',
    key: 'parameters',
    keyConfig: 'parameters',
    icon: IconParameters,
    buttonText: 'Crear',
    Component: Parameters,
  },
  {
    titleSection: 'Importar E. Viales',
    key: 'road-emergencies',
    keyConfig: 'road-emergencies',
    icon: IconRoadEmergencies,
    buttonText: 'Cargar EXCEL',
    withDetails: false,
    Component: RoadEmergencies,
  },
  {
    titleSection: 'Importar Emergencias',
    key: 'emergencies',
    keyConfig: 'emergencies',
    icon: IconEmergencies,
    buttonText: 'Cargar EXCEL',
    withDetails: false,
    Component: Emergencies,
  },
  {
    titleSection: 'Banner',
    key: 'banner',
    keyConfig: 'banner',
    icon: IconBanner,
    buttonText: 'Crear',
    Component: Banner,
  },
  {
    titleSection: 'Importar Capas',
    key: 'import-layers',
    keyConfig: 'import-layers',
    icon: IconBanner,
    buttonText: 'Abrir',
    Component: ImportLayers,
    withDetails: false,
  },
  {
    titleSection: 'Exportar e. Viales',
    key: 'expviales',
    keyConfig: 'expviales',
    icon: IconEmergencies,
    buttonText: 'Exportar archivo',
    Component: () => <></>,
    withDetails: false,
    onAction: () => {
      exportEmergencies().then((data) => {
        if (data?.url) {
          let elem = document.createElement('a');
          elem.href = data?.url;
          elem.download = data?.url;
          document.body.appendChild(elem);
          elem.click();
        } else {
          notification.error({
            description: 'Error inesperado, vuelva a intentarlo.',
            message: '¡Perfil empresa!',
          });
        }
      });
    },
  },
  {
    titleSection: 'Cargar Ubicaciones',
    key: 'locations',
    keyConfig: 'locations',
    icon: IconVersions,
    buttonText: 'Cargar EXCEL',
    withDetails: false,
    Component: Locations,
  },
];

const CardConfiguration = memo(
  ({
    keyConfig,
    titleSection,
    icon,
    onSetAction,
    buttonText,
    withDetails = true,
    withAction = true,
    onAction,
  }) => {
    const navigate = useNavigate();

    const _handleSetQuery = useCallback(() => {
      navigate(`?configkey=${keyConfig}`);
    }, [navigate, keyConfig]);

    const _handleSetAction = useCallback(() => {
      onAction ? onAction() : navigate(`?configkey=${keyConfig}`);
      onSetAction(true);
    }, [navigate, keyConfig, onSetAction]);

    return (
      <Card className='d_content__card flex flex-col rounded-2xl'>
        <img src={icon} alt={keyConfig} />
        <Title level={5}>{titleSection}</Title>
        <div className='flex justify-between mt-4'>
          {withDetails ? (
            <RideButton
              onClick={_handleSetQuery}
              variant='text-primary'
              text='Ver detalles'
            />
          ) : (
            <div></div>
          )}
          {withAction && (
            <RideButton
              onClick={_handleSetAction}
              text={buttonText || 'Crear ahora'}
              variant='outline'
            />
          )}
        </div>
      </Card>
    );
  },
);

const Configuration = () => {
  const { isMobileScreen, isBeforeTable } = useScreen();
  const { configkey } = useQueryParams();
  const [openForm, setOpenForm] = useState(null);
  const [modalSuccess, setModalSuccess] = useState(null);
  const [queryAction, setQueryAction] = useState(false);

  const renderItem = configurationItems.find((el) => el.key === configkey);

  const ItemToConfig = renderItem?.Component || (() => <></>);

  return (
    <>
      <div id='checkSize' className='h100vh dashboard-UI'>
        <Topbar />
        <div className='d_content'>
          {openForm ? (
            <>
              <div className='flex title-section-form'>
                <div
                  className='mr-1'
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (queryAction) setQueryAction((prev) => !prev);
                    setOpenForm(null);
                  }}
                >
                  <IconReturnHtml />
                </div>
                <Title className='header-container'>
                  {openForm?.titleSection || 'Crear'}
                </Title>
              </div>
            </>
          ) : null}
          {!configkey ? (
            <>
              <div className='service-prevention-header mb-2'>
                <Title className='header-container'>Configuración</Title>
              </div>
              <div className='d_content__list__action mb-4 mt-3'>
                <div className='info-header'>
                  Selecciona una opción y personaliza tu configuración
                </div>
              </div>
            </>
          ) : null}
          {configkey && openForm ? (
            <FormConfiguration
              {...{
                ...openForm,
                keyValue: openForm?.key,
              }}
              onBack={() => {
                setOpenForm(null);
              }}
              isMobile={isMobileScreen}
            />
          ) : !configkey ? (
            <div className='config-container'>
              {configurationItems.map((item, index) => (
                <CardConfiguration
                  key={item.key}
                  {...item}
                  onSetAction={setQueryAction}
                />
              ))}
            </div>
          ) : null}
          <div className={`${!openForm || !configkey ? 'block' : 'hidden'}`}>
            <ItemToConfig
              renderItem={renderItem}
              onOpenForm={setOpenForm}
              isMobileScreen={isMobileScreen}
              queryAction={queryAction}
              onSetAction={setQueryAction}
              onModalSuccess={setModalSuccess}
              isBeforeTable={isBeforeTable}
            />
          </div>
        </div>
      </div>
      <Modal
        open={Boolean(modalSuccess)}
        onClose={() => setModalSuccess(null)}
        title={modalSuccess?.message}
        content={modalSuccess?.description}
        footer={
          <div className='actions-delete-modal mt-2'>
            <Button type='primary' onClick={() => setModalSuccess(null)}>
              Aceptar
            </Button>
          </div>
        }
      />
    </>
  );
};

export default memo(Configuration);
