import { TSUNAMIS_UPDATE_FILTERS } from '../actions/actionsType';

const initialState = {
  filters: {},
};

const tsunamis = (state, action) => {
  if (!state) {
    state = initialState;
  }
  switch (
    action.type // NOSONAR
  ) {
    case TSUNAMIS_UPDATE_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    default:
      return state;
  }
};

export default tsunamis;
