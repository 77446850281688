import React, { memo } from 'react';
import PropTypes from 'prop-types';

const Title = ({ level, type, className, children, ...props }) => (
  <div className={`typography-title-${level}--${type} ${className}`} {...props}>
    {children}
  </div>
);

Title.defaultProps = {
  type: 'bold-20',
  level: 5,
  className: '',
};

Title.propTypes = {
  type: PropTypes.oneOf([
    'light-20',
    'light-28',
    'light-40',
    'regular-16',
    'regular-20',
    'regular-24',
    'regular-28',
    'bold-14',
    'bold-20',
    'bold-18',
    'bold-24',
    'bold-28',
    'bold-32',
  ]),
  level: PropTypes.number,
};

export default memo(Title);
