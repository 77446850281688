import React from 'react';
import moment from 'moment';

const formatMinutes = (minutes) => {
  if (minutes >= 60) {
    const hours = Math.floor(minutes / 60);

    return `Hace ${hours} hora${hours === 1 ? '' : 's'}`;
  }
  if (minutes === 0) return 'Ahora';

  return `Hace ${Math.floor(minutes)} minutos`;
};

const ListItem = ({
  site_url,
  site_name,
  title,
  description,
  image_url,
  link,
  publish_date,
}) => {
  let ago = null;
  if (publish_date) {
    const publishDateMoment = moment(publish_date);
    const today = moment();
    const minutes = today.diff(publishDateMoment, 'minute');
    ago =
      minutes >= 60 * 24
        ? publishDateMoment.format('DD/MM HH:mm')
        : formatMinutes(minutes);
  }

  return (
    <article className='article'>
      <a href={link} className='container' target='_blank' rel='noreferrer'>
        <div className='article-content'>
          <div className='head'>
            <img
              src={`${site_url}`}
              className='icon'
              onError={(e) => {
                e.target.onerror = null;
                e.target.remove();
              }}
              alt='Articulo'
            />
            <div className='page'>{site_name}</div>
            {ago && <div className='date'>{ago}</div>}
          </div>
          <h2 className='title'>{title}</h2>
          <p
            className='description'
            dangerouslySetInnerHTML={{ __html: description }}
          ></p>
        </div>
        <div className='article-media'>
          {image_url && (
            <img
              src={image_url}
              className='image'
              onError={(e) => {
                e.target.onerror = null;
                e.target.remove();
              }}
              alt='Articulo'
            />
          )}
        </div>
      </a>
    </article>
  );
};

export default ListItem;
