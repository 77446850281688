import React, { createContext } from 'react';
import { Popup } from 'react-leaflet';
import PopupLayerHeader from './PopupLayerHeader';
import PopupLayerPosition from './PopupLayerPosition';
import PopupLayerFooter from './PopupLayerFooter';

export const PopupContext = createContext();

const PopupLayer = ({ position, children, ...rest }) => {
  let positionLatLng = position;
  if (Array.isArray(position)) {
    positionLatLng.lat = position[0];
    positionLatLng.lng = position[1];
  }

  return (
    <PopupContext.Provider value={positionLatLng}>
      <Popup position={position} interactive {...rest}>
        <div className='popup-map'>{children}</div>
      </Popup>
    </PopupContext.Provider>
  );
};

PopupLayer.Header = PopupLayerHeader;
PopupLayer.Position = PopupLayerPosition;
PopupLayer.Footer = PopupLayerFooter;

export default PopupLayer;
