import {
  LOADED_ROAD_EMERGENCY,
  LOAD_ROAD_EMERGENCY_LOADING,
  FILTER_ROAD_EMERGENCY,
  CLEAR_ROAD_EMERGENCY,
  LOADING_DOWNLOAD_ROAD_EMERGENCY,
  LOADING_DOWNLOAD_ROAD_EMERGENCY_FAILED,
  LOADED_DOWNLOAD_ROAD_EMERGENCY,
  LOADING_ROAD_EMERGENCY_UPDATE_POINT,
  LOADING_ROAD_EMERGENCY_CREATE_POINT,
} from '../actions/actionsType';

const colorItems = [
  {
    id: '01',
    type: 'Normal',
    point: '#02E037',
    color: '#02E037',
    text: '#06BD32',
  },
  {
    id: '02',
    type: 'Restringido',
    point: '#FFFF00',
    color: '#FFFF00',
    text: '#C3B007',
  },
  {
    id: '03',
    type: 'Interrumpido',
    point: '#FF0000',
    color: '#FF0000',
    text: '#EF1313',
  },
  {
    id: '04',
    type: 'Por Confirmar',
    point: '#CCCCCC',
    color: '#CCCCCC',
    text: '#575B5D',
  },
];

const initialState = {
  list: [],
  isLoadingList: false,
  filter: {},
  types: [],
  isLoadingCreatePoint: false,
  isLoadingUpdatePoint: false,
};

const roadEmergency = (state, action) => {
  if (!state) state = initialState;

  switch (action.type) {
    case LOAD_ROAD_EMERGENCY_LOADING:
      return {
        ...state,
        isLoadingList: action.payload,
      };
    case LOADED_ROAD_EMERGENCY:
      let colors = [];

      if (action.payload && action.payload.length > 0) {
        const allConditions =
          action.payload.map((el) => el.condicionTransitoCodigo) || [];
        const uniqueConditions = [...new Set(allConditions)];

        uniqueConditions.sort().forEach((el) => {
          const color = colorItems[Number(el) - 1];
          colors.push(color);
        });
      }

      return {
        ...state,
        list: action.payload,
        types: colors,
        isLoadingList: false,
      };
    case FILTER_ROAD_EMERGENCY:
      return {
        ...state,
        filter: action.payload,
      };
    case CLEAR_ROAD_EMERGENCY:
      return {
        ...state,
        list: [],
        isLoadingList: false,
        filter: {},
      };
    case LOADING_DOWNLOAD_ROAD_EMERGENCY:
      return {
        ...state,
        isLoading: action.payload,
      };
    case LOADING_DOWNLOAD_ROAD_EMERGENCY_FAILED:
      return {
        ...state,
        isLoading: false,
        exportFailed: true,
      };
    case LOADED_DOWNLOAD_ROAD_EMERGENCY:
      return {
        ...state,
        isLoading: false,
        filePath: action.payload,
      };
    case LOADING_ROAD_EMERGENCY_CREATE_POINT:
      return {
        ...state,
        isLoadingCreatePoint: action.payload,
      };
    case LOADING_ROAD_EMERGENCY_UPDATE_POINT:
      return {
        ...state,
        isLoadingUpdatePoint: action.payload,
      };
    default:
      return state;
  }
};

export default roadEmergency;
