import { memo } from 'react';
import { Card, notification } from 'antd';
import { useSelector } from 'react-redux';
import {
  RideSelect,
  RideTextField,
} from '@rimac-seguros/ride-system-components';
import { FileTextFilled } from '@ant-design/icons';

import {
  getPresignedUrl,
  sendFileValueOffer,
} from '../../../../../services/valueOffer';

import InputFile from '../../../../../common/InputFile';

export const UploadFile = memo(({ defaultValue, onChange = () => {} }) => (
  <div className='mb-2'>
    {defaultValue ? (
      <Card
        className='mb-2'
        style={{ border: '1px solid #A9AFD9', borderRadius: 8 }}
        styles={{ body: { display: 'flex', alignItems: 'center' } }}
      >
        <FileTextFilled className='mr-2' />
        {defaultValue}
      </Card>
    ) : null}
    <InputFile
      filesAccepted={[]}
      allowClear={true}
      onloadendFile={({ file }) => {
        getPresignedUrl(file)
          .then(async (res) => {
            const { success, payload } = res?.data ?? {};
            if (success && payload && payload?.url) {
              try {
                await sendFileValueOffer(payload?.url, file, () => {});
                onChange(payload?.nameKey);
              } catch (error) {
                throw Error(error);
              }
            }
          })
          .catch(() => {
            notification.error({
              message: 'Ha ocurrido un error al subir el archivo.',
            });
          });
      }}
    />
  </div>
));

export const CompaniesField = ({
  name,
  value,
  onChange,
  isUnknownRuc,
  ...props
}) => {
  const { companies, isLoadingCompanies } = useSelector(
    (state) => state.companies,
  );

  return !isUnknownRuc ? (
    <RideSelect
      name={name}
      value={value}
      className='mb-2'
      onChange={onChange}
      disabled={isLoadingCompanies}
      options={companies.map((company) => ({
        value: company.name,
        label: company.name,
      }))}
      {...props}
    />
  ) : (
    <RideTextField
      name={name}
      className='mb-2'
      onChange={onChange}
      disabled={isLoadingCompanies}
      type={'text'}
      value={value}
      {...props}
    />
  );
};
