import { get } from '../lib/ajax';
import {
  LOADED_EXPLORATIONS,
  LOADING_EXPLORATIONS,
} from '../redux/actions/actionsType';

export const getExplorations = async (dispatch, provider) => {
  dispatch({ type: LOADING_EXPLORATIONS, payload: true });
  const r = await get(`/explorations/explorations?provider=${provider}`);
  if (r.data.success) {
    dispatch({ type: LOADED_EXPLORATIONS, payload: r.data.payload });
  }
};

export const processExplorations = async (dispatch, provider) => {
  dispatch({ type: LOADING_EXPLORATIONS, payload: true });
  const r = await get(
    `/explorations/${
      provider === 'twitter' ? 'processTwitter' : 'processGoogle'
    }`,
  );
  if (r.data.success) {
    dispatch({ type: LOADED_EXPLORATIONS, payload: r.data.payload });
  }
};
