import React, { memo } from 'react';

const ServiceIcon = ({ src, text }) => {
  return (
    <div className='services__card'>
      <img className='services__card__img' src={src} alt={`Icono de ${text}`} />
      <div className='services__card__text'>{text}</div>
    </div>
  );
};

export default memo(ServiceIcon);
