import React from 'react';
import Form from './Form';
import { useDispatch, useSelector } from 'react-redux';
import openNotification from '../../../common/Notification';
import useIsMobile from '../../../hooks/useIsMobile';

import { INVALID_LOGIN_MESSAGE } from './types';
import { pushDigitalData } from '../../../lib/utils';
import { TRACK } from '../../../lib/consts';
import {
  checkAuth,
  checkUser,
  findUser,
  login,
  loginWithCognito,
  setClockDriftLocalStore,
} from '../../../lib/auth';
import {
  LOG_IN,
  LOG_IN_LOADED_USER,
  LOG_IN_WORKING,
} from '../../../redux/actions/actionsType';

const LoginForm = ({ handleShowRecovery }) => {
  const dispatch = useDispatch();
  const isMobile = useIsMobile();
  const error = useSelector((state) => state.auth.error);
  const working = useSelector((state) => state.auth.working);

  const setLoadingLogin = (isLoading) => {
    dispatch({ type: LOG_IN_WORKING, payload: isLoading });
  };

  const checkIfExistUser = async (username) => {
    const response = await checkUser(username);
    if (response.data.success) {
      if (response.data.user) {
        dispatch({ type: LOG_IN_LOADED_USER, payload: { user: { username } } });

        return true;
      } else {
        dispatch({
          type: LOG_IN,
          payload: {
            success: false,
            session: null,
            e: { message: INVALID_LOGIN_MESSAGE },
          },
        });

        return false;
      }
    } else {
      dispatch({
        type: LOG_IN,
        payload: {
          success: false,
          session: null,
          e: { message: INVALID_LOGIN_MESSAGE },
        },
      });

      return false;
    }
  };

  const doLoginCognito = async (data) => {
    const loginResponse = await loginWithCognito(data);
    if (loginResponse.success) {
      const authResult = await checkAuth();
      if (authResult.success) {
        setClockDriftLocalStore(authResult.session?.clockDrift);
        dispatch({
          type: LOG_IN,
          payload: { success: true, session: authResult.session },
        });
        const userResult = await findUser(data.username);
        if (userResult.data.success === false) {
          dispatch({
            type: LOG_IN,
            payload: {
              success: false,
              e: { message: 'No se puede iniciar sesión con su cuenta' },
            },
          });

          return;
        }
        dispatch({
          type: LOG_IN,
          payload: {
            success: true,
            session: authResult.session,
            user: userResult.data.user,
          },
        });
        if (process.env.REACT_APP_ANALYTICS_ENABLE !== '0') {
          pushDigitalData(
            {
              company: {
                name: userResult?.data?.user?.company?.name || '',
                user: userResult?.data?.user?.username || '',
              },
            },
            TRACK.LOGIN,
          );
        }
      }
    } else {
      dispatch({
        type: LOG_IN,
        payload: {
          success: false,
          e: { message: 'Usuario o contraseña inválido' },
        },
      });
    }
  };

  const doLogin = async () => {
    login();
  };

  const handleSubmitForm = async (data) => {
    setLoadingLogin(true);
    const userExist = await checkIfExistUser(data.username);
    if (userExist) {
      await doLoginCognito(data);
    } else {
      openNotification('error', 'Error', INVALID_LOGIN_MESSAGE);
    }
  };

  return (
    <div className='login-container'>
      <div className='card-login-container'>
        <h4
          className={`ride-sys-text-title-${
            isMobile ? '5xlarge' : '8xlarge'
          }-default`}
        >
          ¡Hola!
        </h4>
        <p className='ride-sys-text-paragraph-xlarge-default'>
          Si eres cliente Rimac, ingresa tus datos.
        </p>
        <Form
          onOk={handleSubmitForm}
          error={error}
          working={working}
          handleShowRecovery={handleShowRecovery}
          doLogin={doLogin}
        />
      </div>
    </div>
  );
};

export default LoginForm;
