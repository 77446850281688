import {
  LOADED_REQUEST_SERVICE_ITEMS_DATA_PARTICIPANTS,
  LOAD_REQUEST_SERVICE_ITEMS_DATA_PARTICIPANTS,
  GENERATING_CERTIFICATES_SERVICE_ITEMS_DATA_PARTICIPANTS,
  GENERATING_CERTIFICATES_SERVICE_ITEMS_DATA_PARTICIPANTS_FAILED,
  LOADING_UPDATE_REQUEST_SERVICE_ITEMS_DATA_PARTICIPANTS,
  REPORTING_PARTICIPANTS_SERVICE_ITEMS_DATA_PARTICIPANTS_FAILED,
  REPORTING_PARTICIPANTS_SERVICE_ITEMS_DATA_PARTICIPANTS,
  LOADED_REPORTING_PARTICIPANTS_SERVICE_ITEMS_DATA_PARTICIPANTS,
  REPORTING_CERTIFICATES_SERVICE_ITEMS_DATA_PARTICIPANTS,
  LOADED_REPORTING_CERTIFICATES_SERVICE_ITEMS_DATA_PARTICIPANTS,
  REPORTING_CERTIFICATES_SERVICE_ITEMS_DATA_PARTICIPANTS_FAILED,
} from '../actions/actionsType';

const initialState = {
  list: [],
  isLoading: false,
  isLoadingUpdate: false,
  isGeneratingCertificates: false,
  generateFailed: false,

  isReportingParticipants: false,
  reportingParticipantsFailed: false,
  reportingParticipantsFilePath: null,

  isReportingCertificates: false,
  reportingCertificatesFailed: false,
  reportingCertificatesFilePath: null,
};

const requestServicesItemsDataParticipants = (state, action) => {
  if (!state) state = initialState;

  switch (action.type) {
    case LOAD_REQUEST_SERVICE_ITEMS_DATA_PARTICIPANTS:
      return {
        ...state,
        isLoading: action.payload,
      };
    case LOADED_REQUEST_SERVICE_ITEMS_DATA_PARTICIPANTS:
      const payload = action.payload;

      return {
        ...state,
        isLoading: false,
        list: payload,
      };
    case LOADING_UPDATE_REQUEST_SERVICE_ITEMS_DATA_PARTICIPANTS:
      return {
        ...state,
        isLoadingUpdate: action.payload,
      };
    case GENERATING_CERTIFICATES_SERVICE_ITEMS_DATA_PARTICIPANTS:
      return {
        ...state,
        isGeneratingCertificates: action.payload,
      };
    case GENERATING_CERTIFICATES_SERVICE_ITEMS_DATA_PARTICIPANTS_FAILED:
      return {
        ...state,
        generateFailed: action.payload,
        isGeneratingCertificates: false,
      };
    case REPORTING_PARTICIPANTS_SERVICE_ITEMS_DATA_PARTICIPANTS:
      return {
        ...state,
        isReportingParticipants: action.payload,
      };
    case LOADED_REPORTING_PARTICIPANTS_SERVICE_ITEMS_DATA_PARTICIPANTS:
      return {
        ...state,
        reportingParticipantsFilePath: action.payload,
        isReportingParticipants: false,
      };
    case REPORTING_PARTICIPANTS_SERVICE_ITEMS_DATA_PARTICIPANTS_FAILED:
      return {
        ...state,
        reportingParticipantsFailed: action.payload,
        isReportingParticipants: false,
      };
    case REPORTING_CERTIFICATES_SERVICE_ITEMS_DATA_PARTICIPANTS:
      return {
        ...state,
        isReportingCertificates: action.payload,
      };
    case LOADED_REPORTING_CERTIFICATES_SERVICE_ITEMS_DATA_PARTICIPANTS:
      return {
        ...state,
        reportingCertificatesFilePath: action.payload,
        isReportingCertificates: false,
      };
    case REPORTING_CERTIFICATES_SERVICE_ITEMS_DATA_PARTICIPANTS_FAILED:
      return {
        ...state,
        reportingCertificatesFailed: action.payload,
        isReportingCertificates: false,
      };
    default:
      return state;
  }
};

export default requestServicesItemsDataParticipants;
