import { useState, useEffect, useMemo } from 'react';
import { Button, Divider, Form, Modal, Table } from 'antd';

import { useScreen } from '../../../../../../lib/screen';

import Title from '../../../../../../common/Typograph/Title';
import InputWithLabel from '../../../../../../common/InputWithLabel';
import { IconTrash } from '../../Reports/utils';

const InputCustom = ({ field, initialValue, required, disabled }) => {
  return (
    <Form.Item
      name={field}
      initialValue={initialValue}
      rules={
        required ? [{ required: true, message: 'Ingrese valor al campo' }] : []
      }
      style={{ margin: 0 }}
    >
      <InputWithLabel size='small' disabled={disabled} />
    </Form.Item>
  );
};

const ModalEditItem = ({
  visible,
  onCancel,
  item,
  setEditItem,
  setListServiceOfProvider,
  setDisabledButton,
}) => {
  const [form] = Form.useForm();
  const { isMobileScreen } = useScreen();
  const { validateFields } = form;
  const [data, setData] = useState([]);

  useEffect(() => {
    if (item?.data) {
      const dataParse = JSON.parse(item?.data);
      setData(
        dataParse.map((data) => ({ ...data, id: Date.now().toString() })),
      );

      return;
    }
    const fieldsValueString = item?.users?.[0]?.service_user_provider?.data;
    const fieldsValue = fieldsValueString ? JSON.parse(fieldsValueString) : [];
    setData(
      fieldsValue.map((fieldValue) => ({
        ...fieldValue,
        id: Date.now().toString(),
      })),
    );
  }, [item]);

  const handleSave = () => {
    validateFields((err, values) => {
      if (!err) {
        setListServiceOfProvider((prev) =>
          prev.map((itemPrev) => {
            return itemPrev.id === item.id
              ? {
                  ...itemPrev,
                  isAdded: true,
                  data: JSON.stringify(Object.values(values)),
                }
              : itemPrev;
          }),
        );
        setEditItem(null);
        setDisabledButton(false);
        onCancel();
      }
    });
  };

  const addZone = () => {
    const id = Date.now().toString();
    const fields = {
      zone: '',
      price: 0,
    };
    setData((prev) => [...prev, { ...fields, id }]);
  };

  const columns = useMemo(
    () => [
      {
        title: 'Zona',
        dataIndex: 'zone',
        key: 'zone',
        width: '200px',
        render: (value, record) => (
          <Form.Item
            name={`${record.id}.zone`}
            initialValue={value}
            rules={[{ required: true }]}
          >
            <InputCustom />
          </Form.Item>
        ),
      },
      {
        title: 'Precio',
        dataIndex: 'price',
        key: 'price',
        width: '200px',
        render: (value, record) => (
          <Form.Item
            name={`${record.id}.price`}
            initialValue={value}
            rules={[{ required: true }]}
          >
            <InputCustom />
          </Form.Item>
        ),
      },
      {
        title: 'Acción',
        dataIndex: 'action',
        key: 'action',
        width: 120,
        render: (_, record) => (
          <Button
            type='link'
            onClick={() =>
              setData((prev) => prev.filter((item) => record.id !== item.id))
            }
            className='flex items-center'
          >
            <IconTrash color='#4F4FFF' /> <span>Eliminar</span>
          </Button>
        ),
      },
    ],
    [],
  );

  return (
    <Modal
      open={visible}
      onOk={handleSave}
      onCancel={onCancel}
      width={isMobileScreen ? '' : '70%'}
      className={`modal-provider modal-centered ${
        isMobileScreen ? 'modal-mobile' : ''
      }`}
    >
      <Title
        type='bold-28'
        style={{ margin: '0 auto', width: '90%', textAlign: 'center' }}
      >
        {'Agregar zonas'}
      </Title>
      <Divider className='mt-2 mb-3' />
      <Button type='primary' size='default' onClick={addZone}>
        Agregar
      </Button>
      <Table
        dataSource={data}
        columns={columns}
        className='table_common'
        rowKey={(record) => record.id}
        locale={{ emptyText: 'Aún no se han agregado nada al listado' }}
        pagination={false}
      />
    </Modal>
  );
};

export default ModalEditItem;
