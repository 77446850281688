import { Modal } from 'antd';
import React from 'react';

function Icon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='96'
      height='128'
      fill='none'
      viewBox='0 0 96 128'
    >
      <mask
        id='mask0_624_56517'
        style={{ maskType: 'alpha' }}
        width='96'
        height='128'
        x='0'
        y='0'
        maskUnits='userSpaceOnUse'
      >
        <path fill='#C4C4C4' d='M0 0H96V128H0z'></path>
      </mask>
      <g mask='url(#mask0_624_56517)'>
        <rect
          width='74.991'
          height='75.4'
          fill='#D7DBF5'
          rx='20'
          transform='rotate(-15.035 225.259 29.727) skewX(-.071)'
        ></rect>
        <mask
          id='mask1_624_56517'
          style={{ maskType: 'alpha' }}
          width='83'
          height='83'
          x='4'
          y='45'
          maskUnits='userSpaceOnUse'
        >
          <path
            fill='#EDEFF5'
            d='M4.676 79.62c-2.834-10.654 3.496-21.584 14.15-24.435l33.658-9.004c10.687-2.859 21.67 3.495 24.513 14.181l8.914 33.511c2.834 10.653-3.496 21.584-14.15 24.434l-33.657 9.004c-10.687 2.859-21.67-3.495-24.513-14.181L4.676 79.62z'
          ></path>
        </mask>
        <g mask='url(#mask1_624_56517)'>
          <path
            fill='#FFBEAF'
            d='M25.614 102.773l-5.67 12.017c-1.164-1.219-5.68-3.862-8.141-8.087-2.235.592-4.1 4.453-3.447 6.458 4.612 5.501 8.391 12.084 10.162 13.007 1.926 1.663 4.91.592 5.825-1.242 1.343-2.676 7.856-12.927 7.856-12.927'
          ></path>
          <path
            fill='#FFBEAF'
            d='M10.983 109.175s3.304-.296 2.817-6.116c-.107-1.218-.725-3.326-.809-3.998-.07-.683.024-3.086.595-3.827.57-.74.939-1.275.475-1.822-.463-.547-2.829-.888-3.898 1.31-1.07 2.21-.511 4.066-.511 4.066s-1.89-2.255-2.853-2.255c-.416 0-4.766-.262-5.682.786-.344.398-1.236 9.089-1.105 9.522.13.432 4.315 7.221 8.415 6.537 1.89-.319 2.556-3.997 2.556-4.203z'
          ></path>
          <path
            fill='#E3747B'
            d='M2.4 105.005c.488-.16.964-.16 1.451-.183a12.94 12.94 0 011.474 0c.226.012.558.069.76.251.203.159.357.353.524.535.309.387.582.752.832 1.185.214.433.368.991.095 1.48-.274.479-.797.718-1.272.843-.5.126-.975.182-1.522.137-.546-.194-.867-.49-1.248-.854l.202-.148c.31.284.726.615 1.106.729.416.022.927-.035 1.367-.16.44-.125.88-.33 1.07-.683.202-.353.106-.809-.096-1.196a8.894 8.894 0 00-.784-1.139 3.155 3.155 0 00-.464-.49c-.154-.148-.344-.171-.594-.194a12.32 12.32 0 00-1.438 0c-.487 0-.975.092-1.462-.113z'
          ></path>
          <path
            fill='#E3747B'
            d='M2.603 102.295c.76-.103 1.51-.046 2.27.011.38.023.75.057 1.13.103.19.034.38.045.582.114.226.079.368.228.499.376.487.626.69 1.355.796 2.084a3.9 3.9 0 010 1.116c-.047.387-.25.718-.368 1.071l-.238-.114c.095-.33.274-.661.31-.991a5.043 5.043 0 00-.024-1.037c-.096-.683-.298-1.4-.714-1.936-.202-.285-.487-.353-.88-.398-.368-.046-.736-.08-1.117-.114-.748-.046-1.509-.069-2.246-.285z'
          ></path>
          <path
            fill='#E3747B'
            d='M7.666 104.87c.737-.456 1.046-1.31.94-2.118-.108-.82-.428-1.652-.975-2.301-.274-.319-.606-.592-1.01-.683-.417-.103-.868-.137-1.308-.149-.891-.056-1.819.08-2.71-.239.903.012 1.795-.102 2.722-.057.463.012.915.035 1.379.148.487.114.88.433 1.176.786.595.706.94 1.561 1.07 2.46.143.9-.285 1.925-1.129 2.392l-.155-.239z'
          ></path>
          <path
            fill='#E3747B'
            d='M8.879 97.898c.285.24.594.479.76.832.179.353.214.774.12 1.139-.084.364-.203.74-.476 1.048-.273.33-.773.421-1.13.376v-.262c.381.034.702-.035.928-.285.214-.251.345-.581.404-.923.06-.341.071-.672-.083-.99-.13-.297-.369-.582-.523-.935zM5.337 108.161c-.285.068-.57.148-.868.193-.285.057-.582.069-.88.092-.57-.012-1.247 0-1.723-.399.274.102.559.114.844.125.285 0 .57.012.856-.011.594 0 1.177-.069 1.77 0z'
          ></path>
          <path
            fill='#FFBEAF'
            d='M70.449 102.773l5.67 12.017c1.164-1.219 5.68-3.862 8.141-8.087 2.235.592 4.1 4.453 3.447 6.458-4.612 5.501-8.391 12.084-10.162 13.007-1.926 1.663-4.91.592-5.825-1.242-1.343-2.676-7.856-12.927-7.856-12.927'
          ></path>
          <path
            fill='#FFBEAF'
            d='M85.08 109.175s-3.304-.296-2.817-6.116c.107-1.218.725-3.326.808-3.998.084-.672-.023-3.086-.594-3.827-.57-.74-.939-1.275-.475-1.822.463-.547 2.828-.888 3.898 1.31 1.07 2.21.511 4.066.511 4.066s1.89-2.255 2.853-2.255c.416 0 4.766-.262 5.681.786.345.398 1.237 9.089 1.106 9.522-.13.432-4.315 7.221-8.416 6.537-1.89-.319-2.555-3.997-2.555-4.203z'
          ></path>
          <path
            fill='#E3747B'
            d='M87.184 97.898c-.154.342-.392.638-.535.934-.142.308-.13.65-.083.991.071.342.19.672.404.923.226.25.547.319.927.285v.262c-.357.045-.868-.046-1.129-.376-.273-.308-.404-.684-.475-1.048a1.651 1.651 0 01.118-1.14c.179-.352.488-.591.773-.83z'
          ></path>
          <path
            fill='#FF1C44'
            d='M61.427 90.371c3.554 1.287 7.44 4.943 12.123 17.244l-9.366 7.267-5.087-8.759 2.33-15.752z'
          ></path>
          <path
            fill='#FF1C44'
            d='M29.953 94.915c.689-4.01 4.326-6.925 8.57-6.903 2.293.012 5.158-1.628 8.01-1.264 4.054.57 11.993 2.415 14.894 3.633 4.813 10.16 2.947 31.652 5.919 46.14-6.835.661-25.424 2.176-36.455-.068 3.305-24.272-3.518-26.481-.938-41.538z'
          ></path>
          <path
            fill='#E3747B'
            d='M52.037 88.354c.024-3.246.975-7.78.975-7.78l-13.087-2.426-.713 9.5s2.58 6.959 11.03 7.665c2.009-1.458 1.795-6.96 1.795-6.96z'
          ></path>
          <path
            fill='#CC133C'
            d='M51.692 90.575c2.39 1.777 5.301 5.137 5.301 5.137s-.772-3.998-1.272-6.287c-.594-1.355-3.47-4.09-3.47-4.09s-.606 4.272-.559 5.24z'
          ></path>
          <path
            fill='#FF1C44'
            d='M29.287 91.201c2.152-1.901 6.906-2.63 6.906-2.63.594-.4 6.727 5.319 2.888 10.057-2.068 2.54-4.897 11.64-6.834 16.777-3.804-2.369-8.689-4.328-12.029-6.492 2.14-5.821 5.717-14.761 9.07-17.712z'
          ></path>
          <path
            fill='#fff'
            d='M56.732 103.308c-.12 0-.214.034-.321.171l-1.236 1.606-.476-1.572c-.047-.159-.154-.216-.261-.216-.155 0-.226.057-.31.193l-1.093 2.096h.606l.737-1.446.464 1.446h.51l1.118-1.435-.012 1.435h.523v-2.016c.012-.182-.119-.262-.25-.262zM52.31 103.672l-.487 1.925h.558l.488-1.925h-.559zM51.36 104.219c.035-.251-.12-.547-.678-.547H49.6l-.487 1.913h.547l.178-.717h.285l.487.717h.607l-.547-.763c.5-.102.666-.341.69-.603zm-1.023.33h-.392l.142-.581h.416c.226 0 .333.103.31.285-.024.182-.214.296-.476.296zM59.287 105.596h.523l-.499-1.765c-.071-.205-.202-.251-.333-.24a.37.37 0 00-.261.16l-1.32 1.856h.547l.274-.387h.986l.083.376zm-.855-.706l.487-.706.19.706h-.678zM61.498 105.246c-.451-.011-.701-.25-.665-.558.047-.41.44-.672.998-.66.166 0 .368.011.57.056l.096-.387a3.401 3.401 0 00-.595-.068c-.95 0-1.557.421-1.628 1.048-.071.558.345.956 1.224.956.178 0 .333-.011.547-.045l.107-.421a2.026 2.026 0 01-.654.079z'
          ></path>
          <path
            fill='#CC133C'
            d='M52.405 88.843l-2.662 6.07-8.059-6.104-1.878 1.936 10.495 8.132 2.83-7.118-.726-2.916z'
          ></path>
          <path
            fill='#CC133C'
            d='M39.057 85.11s3.198 4.145 7.203 7.163c-.534 1.128-5.396 4.124-5.396 4.124l-5.444-8.36 3.637-2.928zM36.645 104.266c-.298.956-.618 1.902-.94 2.847-.308.945-.665 1.879-.986 2.825-.32.945-.677 1.868-1.022 2.802-.38.922-.725 1.856-1.165 2.756l-.19.398-.25-.239-.974-.922 1.295.433-.44.148c.226-.98.523-1.937.844-2.882.333-.945.702-1.879 1.106-2.79.404-.912.796-1.834 1.272-2.734.44-.9.915-1.788 1.45-2.642zM63.864 100.348c.297.956.499 1.925.69 2.904.154.98.308 1.959.391 2.95.084.991.167 1.982.167 2.973.012.991-.024 1.993-.13 2.972l-.263-2.961c-.106-.979-.166-1.97-.297-2.95l-.309-2.95c-.083-.957-.19-1.947-.25-2.938z'
          ></path>
          <path
            fill='#141938'
            d='M71.91 17.99c5.753.41 6.395 9.875 6.395 9.875s.987 18.144-3.316 24.67c0 0-12.48-35.217-3.079-34.545z'
          ></path>
          <path
            fill='#E3747B'
            d='M70.722 55.197s1.89-7.688 7.25-4.738c5.36 2.95.048 15.923-7.785 14.454 0-.012-1.426-2.734.535-9.716z'
          ></path>
          <path
            fill='#FFBEAF'
            d='M74.33 20.376s2.103 49.249.416 52.78c-1.688 3.53-13.051 10.125-19.672 11.241-6.62 1.128-20.194-8.542-22.334-11.07-1.153-1.367-3.863-13.281-5.836-25.422-1.7-10.41-9.817-20.684-9.021-24.887-.012.011 31.462-15.24 56.446-2.642z'
          ></path>
          <path
            fill='#141938'
            d='M30.714 51.803s.63 2.597 1.782 5.172c2.841 6.31 11.625-.627 26.257 2.163 3.85.73 7.262 1.834 11.339 2.518 5.468.91 4.588-9.556 4.719-11.481.261 11.902.487 23.747-.298 25.387-1.663 3.486-13.336 9.716-22.678 9.374-7.773-.285-19.243-5.934-21.347-8.428-1.022-1.207-2.877-10.855-4.743-21.527l-1.783-6.868 6.752 3.69z'
          ></path>
          <path
            fill='#141938'
            d='M30.713 51.806s-1.985-7.005 1.581-13.713c2.615-4.909-1.569-11.89-4.005-14.306 0 0-9.973-1.036-10.472-.603-3.043 2.642-6.145 10.353 3.031 29.556l9.865-.934z'
          ></path>
          <g fill='#FF1C44' opacity='0.2'>
            <path
              d='M48.103 52.171c0 .98-1.712 1.777-3.804 1.777-2.092 0-3.791-.797-3.78-1.777.013-.98 1.712-1.776 3.804-1.776 2.092-.012 3.78.785 3.78 1.776zM71.126 51.008c.178.843-1.117 1.8-2.9 2.153s-3.388-.046-3.566-.877c-.178-.831 1.117-1.8 2.9-2.153 1.783-.364 3.376.035 3.566.877z'
              opacity='0.2'
            ></path>
          </g>
          <path
            fill='#FFBEAF'
            d='M27.635 54.664s-2.377-5.547-7.274-2.745c-4.897 2.802-2.02 17.552 9.354 12.563 0-.011 2.556-2.802-2.08-9.818z'
          ></path>
          <path
            fill='#E3747B'
            d='M20.717 55.679c.559-.456 1.2-.82 1.914-.934.701-.137 1.414.08 1.937.5.535.422.94.98 1.26 1.573.333.592.57 1.23.797 1.856l.202-.262c-.702.057-1.391.274-1.926.73a2.048 2.048 0 00-.69 1.776c.013.319.132.638.31.911.19.273.463.478.76.638.607.285 1.284.376 1.938.342-.654-.057-1.308-.183-1.83-.502a1.878 1.878 0 01-.642-.603c-.131-.25-.226-.513-.214-.797-.012-.57.19-1.14.63-1.504.44-.376 1.07-.558 1.664-.592l.285-.023-.083-.24c-.214-.66-.5-1.297-.844-1.913-.357-.603-.773-1.207-1.39-1.651-.607-.444-1.463-.638-2.212-.444a4.07 4.07 0 00-1.866 1.139z'
          ></path>
          <path
            fill='#141938'
            d='M67.323 64.126s-10.175 5.536-23.903-2.38c0 0 4.433 13.144 13.11 13.383 8.986-.626 4.933-5.911 10.793-11.003z'
          ></path>
          <path
            fill='#E3747B'
            d='M48.662 70.893c6.668-.308 10.198 1.549 12.016 3.269-.95.501-2.258.843-4.148.98-3.21-.092-5.836-1.948-7.868-4.249z'
          ></path>
          <path
            fill='#fff'
            d='M67.322 64.127c-1.783 1.538-2.127 2.415-2.674 3.28-3.97 1.14-11.589 1.264-19.505-1.731-1.771-2.95-1.723-3.918-1.723-3.918 13.74 7.904 23.902 2.369 23.902 2.369z'
          ></path>
          <path
            fill='#545D94'
            d='M67.322 64.126c-.915.479-1.901.832-2.9 1.117-.998.284-2.032.5-3.066.649-.523.068-1.034.125-1.557.17a24.112 24.112 0 01-3.138.012c-.523-.023-1.046-.08-1.57-.137-.522-.068-1.045-.125-1.556-.227-.523-.08-1.034-.194-1.545-.308a37.39 37.39 0 01-1.522-.399c-.5-.148-.998-.307-1.486-.49-.499-.17-.974-.352-1.462-.557-.487-.183-.95-.41-1.438-.627-.475-.216-.939-.456-1.402-.683l-1.367-.74.285-.217c.261.752.582 1.504.915 2.244.333.74.702 1.458 1.094 2.175.784 1.424 1.652 2.802 2.686 4.066 1.034 1.265 2.21 2.426 3.601 3.303 1.38.889 2.984 1.447 4.648 1.504h-.012c.345-.034.701-.046 1.046-.103.345-.034.69-.09 1.034-.16a7.714 7.714 0 001.01-.261c.167-.046.333-.114.488-.171.166-.057.32-.137.463-.205a4.69 4.69 0 00.856-.547c.261-.216.5-.444.701-.717.416-.524.713-1.14.963-1.766.5-1.252.915-2.574 1.593-3.758.166-.296.356-.593.547-.877.19-.285.404-.558.63-.82.225-.262.451-.524.7-.763.238-.251.512-.467.761-.707zm0 0c-.25.24-.51.467-.737.73-.237.25-.463.512-.677.774-.214.273-.416.547-.606.831-.19.285-.357.581-.523.877-.321.604-.582 1.23-.82 1.857-.238.638-.44 1.287-.69 1.925-.25.637-.535 1.275-.962 1.833a4.573 4.573 0 01-.737.763 5.624 5.624 0 01-1.38.81c-.166.068-.332.136-.498.181-.333.114-.69.206-1.034.285a15.69 15.69 0 01-1.058.182c-.357.057-.714.08-1.058.114h-.012c-1.735-.022-3.411-.626-4.838-1.526-1.438-.911-2.65-2.084-3.696-3.371-1.058-1.287-1.95-2.677-2.746-4.112a35.126 35.126 0 01-1.093-2.21c-.333-.751-.654-1.503-.916-2.278l-.154-.455.44.24 1.355.74c.463.227.915.466 1.379.683.475.205.927.433 1.414.626.475.194.95.388 1.438.558.487.183.975.33 1.474.49.5.148.998.285 1.498.41.499.114 1.01.24 1.521.33.511.103 1.022.171 1.533.251.511.057 1.034.125 1.545.148.524.046 1.035.057 1.558.057.523.011 1.034-.011 1.557-.034a23.937 23.937 0 001.557-.125 22.864 22.864 0 003.054-.57c.987-.24 1.985-.558 2.912-1.014z'
          ></path>
          <path
            fill='#545D94'
            d='M45.856 61.257s-.344-.068-.843-.068c-.12 0-.262 0-.405.011-.142 0-.285.046-.428.057-.142.046-.297.069-.44.126-.07.022-.142.045-.213.08l-.202.113c-.143.068-.25.17-.38.262-.108.103-.215.216-.322.319-.083.114-.178.228-.261.342-.071.114-.143.227-.202.33a1.864 1.864 0 00-.167.296c-.047.091-.083.17-.119.228-.07.125-.106.205-.106.205s.011-.08.035-.228c.036-.137.036-.353.143-.592.047-.114.083-.25.154-.387.072-.126.155-.262.238-.399.107-.125.214-.262.345-.387.142-.103.273-.24.44-.32l.25-.124c.082-.035.166-.057.26-.092.167-.068.345-.08.512-.114a2.32 2.32 0 01.487 0c.155 0 .297.035.428.057.5.103.796.285.796.285z'
          ></path>
          <path
            fill='#141938'
            d='M68.915 46.928a33.74 33.74 0 00-1.628-1.913c-.56-.57-1.189-1.139-1.843-1.321-.63-.205-1.272.16-1.794.752-.523.592-.963 1.31-1.427 2.027.13-.831.369-1.663.868-2.426a2.972 2.972 0 011.022-.991 2.116 2.116 0 011.58-.182c1.011.319 1.641 1.036 2.152 1.731a7.834 7.834 0 011.07 2.324zM49.422 46.829c-.618-.66-1.272-1.276-1.961-1.777-.69-.49-1.474-.831-2.152-.65-.701.16-1.248.855-1.759 1.516-.475.717-.88 1.48-1.343 2.266.06-.444.166-.877.261-1.321.107-.433.31-.854.488-1.276.44-.786.998-1.697 2.127-2.016 1.153-.273 2.187.33 2.841.968a6.708 6.708 0 011.498 2.29z'
          ></path>
          <path
            fill='#E3747B'
            d='M63.846 56.52s-6.279 10.095-12.024.44c0 0 6.165 1.265 12.024-.44z'
          ></path>
          <path
            fill='url(#paint0_linear_624_56517)'
            d='M64.037 40.537c-5.335.89-10.168-1.08-10.168-1.08 1.942 8.722-1.91 17.525-1.91 17.525 4.999 3.214 11.807-.36 11.807-.36-5.996-8.333.271-16.085.271-16.085z'
            opacity='0.6'
          ></path>
        </g>
        <path
          fill='#141938'
          d='M71.912 17.99c5.753.41 6.395 9.875 6.395 9.875s.987 18.144-3.316 24.67c0 0-12.48-35.217-3.079-34.545z'
        ></path>
        <path
          fill='#FFA496'
          d='M70.722 55.197s1.89-7.688 7.25-4.738c5.361 2.95.048 15.923-7.785 14.454 0-.012-1.426-2.734.535-9.716z'
        ></path>
        <path
          fill='#FFBEAF'
          d='M74.331 20.376S77 65.5 74.747 73.156c-1.687 3.53-13.05 10.125-19.67 11.241-6.621 1.128-20.195-8.542-22.335-11.07-1.153-1.367-3.863-13.281-5.836-25.422-1.7-10.41-9.817-20.684-9.021-24.887-.012.011 31.462-15.24 56.446-2.642z'
        ></path>
        <path
          fill='#141938'
          d='M30.714 51.803s.63 2.597 1.783 5.172c2.84 6.31 11.624-.627 26.256 2.163 3.851.73 7.263 1.834 11.34 2.518 5.467.91 5.276-9.232 5.407-11.157.261 11.903-.201 23.423-.986 25.063-1.664 3.486-13.336 9.716-22.678 9.374-7.774-.285-19.244-5.934-21.348-8.428-1.022-1.207-2.876-10.855-4.742-21.527l-1.783-6.868 6.751 3.69z'
        ></path>
        <path
          fill='#141938'
          d='M30.715 51.806s-1.985-7.005 1.58-13.713c2.616-4.909-1.568-11.89-4.005-14.306 0 0-9.972-1.036-10.471-.603-3.043 2.642-6.145 10.353 3.03 29.556l9.866-.934z'
        ></path>
        <path
          fill='#141938'
          d='M74.847 14.036c-4.469-3.565-12.92-10.729-22.476-9.875-4.743.501-8.07 1.162-11.732 3.417.357-.763 3.34-3.018 3.138-3.542-.25-.65-25.4 7.563-25.721 18.85-1.272 1.538-1.961 2.71-1.961 3.235-.012 2.46 16.474 12.54 14.988 6.56-1.486-5.034-1.985-5.604-.024-7.153 3.518-2.779 9.901-3.45 24.806-.444 6.99 1.412 28.657.547 27.659-7.449.024.012-4.196-.045-8.677-3.599z'
        ></path>
        <path
          fill='#FF1C44'
          d='M48.104 52.167c0 .98-1.712 1.777-3.804 1.777-2.092 0-3.791-.797-3.78-1.777.012-.979 1.712-1.776 3.804-1.776 2.092-.012 3.78.786 3.78 1.776zM71.128 51.008c.178.843-1.117 1.8-2.9 2.153s-3.388-.046-3.566-.877c-.178-.831 1.117-1.8 2.9-2.153 1.783-.364 3.376.035 3.566.877z'
          opacity='0.2'
        ></path>
        <path
          fill='#141938'
          d='M70.425 40.28c-1.51-1.333-3.21-2.266-6.383-2.05-1.604.296-3.316.513-2.032-1.23 2.234-2.688 9.069-.433 10.257 3.679.714 1.754-.938.626-1.842-.399zM40.509 41.304c1.236-1.571 2.733-2.779 5.895-3.109 1.64.023 3.364-.068 1.771-1.56-2.698-2.255-9.01 1.127-9.402 5.376-.38 1.856 1.034.478 1.736-.707z'
        ></path>
        <path
          fill='url(#paint1_linear_624_56517)'
          d='M62.17 40.793c-4.915.516-9.551-1.783-9.551-1.783C55.019 47.82 52 57 52 57c4.876 3.54 12-.69 12-.69-6.154-8.69-1.83-15.516-1.83-15.516z'
          opacity='0.5'
        ></path>
        <path
          fill='#FFBEAF'
          d='M27.636 54.664s-2.378-5.547-7.275-2.745c-4.897 2.802-2.02 17.552 9.355 12.563 0-.011 2.555-2.802-2.08-9.818z'
        ></path>
        <path
          fill='#E3747B'
          d='M20.718 55.679c.559-.456 1.2-.82 1.914-.934.701-.137 1.414.08 1.937.5.535.422.94.98 1.26 1.573.333.592.57 1.23.797 1.856l.202-.262c-.702.057-1.391.274-1.926.73a2.048 2.048 0 00-.69 1.776c.013.319.131.638.31.911.19.273.463.478.76.638.607.285 1.284.376 1.938.342-.654-.057-1.307-.183-1.83-.502a1.879 1.879 0 01-.642-.603c-.131-.25-.226-.513-.214-.797-.012-.57.19-1.14.63-1.504.44-.376 1.07-.558 1.664-.592l.285-.023-.083-.24c-.214-.66-.5-1.297-.844-1.913-.357-.603-.773-1.207-1.39-1.651-.607-.444-1.463-.638-2.212-.444a4.07 4.07 0 00-1.866 1.139z'
        ></path>
        <path
          fill='#141938'
          d='M67.324 64.126s-10.175 5.536-23.903-2.38c0 0 4.433 13.144 13.11 13.383 8.986-.627 4.933-5.911 10.793-11.003z'
        ></path>
        <path
          fill='#E3747B'
          d='M48.662 70.893c6.668-.308 10.198 1.549 12.017 3.269-.951.501-2.259.843-4.148.98-3.21-.092-5.836-1.948-7.869-4.249z'
        ></path>
        <path
          fill='#fff'
          d='M67.323 64.127c-1.783 1.538-2.127 2.415-2.674 3.28-3.97 1.14-11.589 1.264-19.505-1.731-1.771-2.95-1.724-3.918-1.724-3.918 13.74 7.904 23.903 2.369 23.903 2.369z'
        ></path>
        <path
          fill='#545D94'
          d='M67.323 64.126c-.915.479-1.901.832-2.9 1.117-.998.284-2.032.5-3.066.649-.523.068-1.034.125-1.557.17a24.117 24.117 0 01-3.138.012c-.523-.023-1.046-.08-1.57-.137-.522-.068-1.045-.125-1.556-.227-.523-.08-1.034-.194-1.546-.308a37.39 37.39 0 01-1.52-.399c-.5-.148-1-.307-1.487-.49-.499-.17-.974-.352-1.462-.557-.487-.183-.95-.41-1.438-.627-.475-.216-.939-.456-1.402-.683l-1.367-.74.285-.217c.262.752.582 1.504.915 2.244.333.74.701 1.458 1.094 2.175.784 1.424 1.652 2.802 2.686 4.066 1.034 1.265 2.21 2.426 3.601 3.303 1.38.889 2.984 1.447 4.648 1.504h-.012c.345-.034.701-.046 1.046-.103.345-.034.69-.09 1.034-.16a7.714 7.714 0 001.01-.261c.167-.046.333-.114.488-.171.166-.057.32-.137.463-.205a4.69 4.69 0 00.856-.547c.262-.216.5-.444.701-.717.416-.524.713-1.14.963-1.766.5-1.252.915-2.574 1.593-3.758.166-.296.356-.593.546-.877.19-.285.405-.558.63-.82.226-.262.452-.524.702-.763.237-.251.51-.467.76-.707zm0 0c-.25.24-.51.467-.736.73-.238.25-.464.512-.678.774-.214.273-.416.547-.606.831-.19.285-.357.581-.523.877a16.1 16.1 0 00-.82 1.857c-.238.638-.44 1.287-.69 1.925-.25.637-.535 1.275-.963 1.833a4.568 4.568 0 01-.736.763 5.624 5.624 0 01-1.38.81c-.166.068-.332.136-.498.181-.333.114-.69.206-1.035.285-.344.069-.7.137-1.057.182-.357.057-.713.08-1.058.114h-.012c-1.735-.022-3.411-.626-4.838-1.526-1.438-.911-2.65-2.084-3.696-3.371-1.058-1.287-1.95-2.677-2.746-4.112a35.126 35.126 0 01-1.093-2.21c-.333-.751-.654-1.503-.916-2.278l-.154-.455.44.24 1.355.74c.463.227.915.466 1.378.683.476.205.928.433 1.415.626.475.194.95.388 1.438.558.487.183.975.33 1.474.49.5.148.998.285 1.498.41.499.114 1.01.24 1.521.33.511.103 1.022.171 1.533.251.511.057 1.034.125 1.545.148.523.046 1.035.057 1.558.057.523.011 1.034-.011 1.557-.034a23.937 23.937 0 001.557-.125 22.864 22.864 0 003.054-.57c.987-.24 1.985-.558 2.912-1.014z'
        ></path>
        <path
          fill='#545D94'
          d='M45.857 61.257s-.344-.068-.844-.068c-.118 0-.261 0-.404.011-.142 0-.285.046-.428.057-.142.046-.297.069-.44.126-.07.022-.142.045-.213.08l-.202.113c-.143.068-.25.17-.38.262-.108.103-.215.216-.322.319-.083.114-.178.228-.261.342a11.65 11.65 0 00-.202.33 1.856 1.856 0 00-.167.296c-.047.091-.083.17-.118.228-.072.125-.107.205-.107.205s.011-.08.035-.228c.036-.137.036-.353.143-.592.047-.114.083-.25.154-.387.072-.126.155-.262.238-.399.107-.125.214-.262.345-.387.142-.103.273-.24.44-.32l.25-.124c.082-.035.165-.057.26-.092.167-.068.345-.08.512-.114a2.32 2.32 0 01.487 0c.155 0 .297.035.428.057.5.103.796.285.796.285z'
        ></path>
        <path
          fill='#141938'
          d='M68.917 46.928a33.659 33.659 0 00-1.629-1.913c-.558-.57-1.188-1.139-1.842-1.321-.63-.205-1.272.16-1.795.752-.523.592-.962 1.31-1.426 2.027.13-.831.368-1.663.868-2.426a2.97 2.97 0 011.022-.991 2.116 2.116 0 011.58-.182c1.011.319 1.641 1.036 2.152 1.731a7.832 7.832 0 011.07 2.324zM49.423 46.829c-.618-.66-1.272-1.276-1.96-1.777-.69-.49-1.475-.831-2.152-.65-.702.16-1.248.855-1.76 1.516-.475.717-.879 1.48-1.343 2.266.06-.444.167-.877.262-1.321.107-.433.309-.854.487-1.276.44-.786.999-1.697 2.128-2.016 1.153-.273 2.187.33 2.84.968a6.708 6.708 0 011.498 2.29z'
        ></path>
        <path
          fill='#E3747B'
          d='M63.848 56.52s-6.279 10.095-12.024.44c0 0 6.165 1.265 12.024-.44z'
        ></path>
        <path
          fill='#FFBEAF'
          d='M21.667 111.706l-1.523 3.265c-1.108-1.202-5.402-3.806-7.741-7.971-2.125.584-3.899 4.39-3.277 6.365 4.384 5.423 7.978 11.912 9.662 12.821 1.83 1.639 4.667.584 5.537-1.224 1.277-2.638 4.675-8.306 4.675-8.306M73.526 109.209l2.492 5.16c1.19-1.261 5.808-3.996 8.323-8.369 2.284.613 4.192 4.609 3.524 6.684-4.715 5.693-8.578 12.506-10.389 13.461-1.968 1.721-5.018.613-5.954-1.285-1.372-2.77-5.522-9.414-5.522-9.414'
        ></path>
        <path
          fill='#FFBEAF'
          d='M10.984 109.222s3.304-.296 2.817-6.116c-.107-1.219-.725-3.326-.809-3.998-.07-.683.024-3.086.595-3.827.57-.74.939-1.275.475-1.822-.463-.547-2.829-.888-3.898 1.31-1.07 2.21-.511 4.066-.511 4.066S7.763 96.58 6.8 96.58c-.416 0-4.766-.262-5.682.786-.344.398-1.236 9.089-1.105 9.521.13.433 4.315 7.222 8.415 6.538 1.89-.319 2.556-3.998 2.556-4.203z'
        ></path>
        <path
          fill='#E3747B'
          d='M2.401 105.051c.488-.159.963-.159 1.45-.182.488-.023.975-.034 1.474 0 .226.012.559.069.761.251.202.159.357.353.523.535.31.387.582.752.832 1.185.214.432.369.991.095 1.48-.273.479-.796.718-1.272.843-.499.125-.974.182-1.521.137-.547-.194-.868-.49-1.248-.854l.202-.148c.309.284.725.615 1.105.728a4.4 4.4 0 001.367-.159c.44-.125.88-.33 1.07-.683.202-.353.107-.809-.095-1.196a8.825 8.825 0 00-.785-1.139 3.155 3.155 0 00-.463-.49c-.155-.148-.345-.171-.594-.194a12.32 12.32 0 00-1.439 0c-.487 0-.974.091-1.462-.114z'
        ></path>
        <path
          fill='#E3747B'
          d='M2.604 102.342c.76-.103 1.509-.046 2.27.011.38.023.749.057 1.129.103.19.034.38.045.582.114.226.079.369.227.5.375.487.627.689 1.356.796 2.085a3.9 3.9 0 010 1.116c-.048.387-.25.718-.369 1.071l-.237-.114c.095-.331.273-.661.309-.991a5.045 5.045 0 00-.024-1.037c-.095-.683-.297-1.401-.713-1.936-.202-.285-.488-.353-.88-.399a34.62 34.62 0 00-1.117-.113c-.749-.046-1.51-.069-2.246-.285z'
        ></path>
        <path
          fill='#E3747B'
          d='M7.667 104.917c.737-.456 1.046-1.31.939-2.119-.107-.82-.428-1.651-.975-2.3-.273-.319-.606-.593-1.01-.684-.416-.102-.868-.136-1.307-.148-.892-.057-1.819.08-2.71-.239.903.011 1.794-.102 2.721-.057.464.012.916.034 1.38.148.487.114.879.433 1.176.786.594.706.939 1.561 1.07 2.46.142.9-.286 1.925-1.13 2.392l-.154-.239z'
        ></path>
        <path
          fill='#E3747B'
          d='M8.88 97.945c.285.24.594.479.76.832.179.353.214.774.12 1.139-.084.364-.203.74-.476 1.048-.273.33-.773.421-1.13.375v-.262c.381.035.702-.034.928-.284.214-.251.345-.581.404-.923.06-.342.071-.672-.083-.99-.131-.297-.369-.582-.523-.935zM5.337 108.208c-.285.068-.57.148-.867.193-.286.057-.583.069-.88.091-.57-.011-1.248 0-1.723-.398.273.102.558.114.844.125.285 0 .57.011.855-.011.595 0 1.177-.069 1.771 0z'
        ></path>
        <path
          fill='#FFBEAF'
          d='M84.628 108.554s-3.304-.296-2.817-6.116c.107-1.219.725-3.326.809-3.998.083-.672-.024-3.086-.595-3.827-.57-.74-.939-1.275-.475-1.822.463-.547 2.829-.888 3.898 1.31 1.07 2.21.512 4.066.512 4.066s1.89-2.255 2.852-2.255c.416 0 4.767-.262 5.682.786.344.398 1.236 9.089 1.105 9.522-.13.432-4.315 7.221-8.415 6.537-1.89-.319-2.556-3.998-2.556-4.203z'
        ></path>
        <path
          fill='#E3747B'
          d='M93.21 104.384c-.487.216-.987.114-1.462.114-.487-.012-.975-.034-1.438 0-.25.023-.428.045-.594.193a3.738 3.738 0 00-.464.49c-.285.353-.57.752-.784 1.139-.203.387-.298.854-.096 1.196.19.353.63.558 1.07.683.44.126.951.194 1.367.16.38-.114.808-.456 1.105-.729l.202.148c-.38.364-.713.672-1.248.854-.546.057-1.022-.011-1.521-.136-.475-.126-.998-.365-1.272-.843-.273-.49-.119-1.06.095-1.481.25-.433.523-.809.832-1.184.155-.183.31-.365.523-.536.202-.193.535-.239.761-.25.5-.035.986-.012 1.474 0 .5.022.974.022 1.45.182z'
        ></path>
        <path
          fill='#E3747B'
          d='M93.007 101.673c-.737.217-1.497.239-2.246.296-.368.034-.749.057-1.117.114-.393.046-.678.114-.88.399-.416.535-.618 1.253-.713 1.936-.036.342-.071.695-.024 1.037.024.341.214.66.31.99l-.238.114c-.12-.353-.321-.683-.369-1.07a3.523 3.523 0 010-1.117c.119-.728.321-1.457.796-2.084.131-.148.274-.307.5-.376.202-.068.392-.091.582-.114.38-.045.76-.079 1.13-.102.76-.068 1.509-.125 2.27-.023z'
        ></path>
        <path
          fill='#E3747B'
          d='M87.79 104.485c-.845-.478-1.26-1.492-1.13-2.392a4.771 4.771 0 011.07-2.46c.297-.342.689-.66 1.176-.786.464-.114.927-.136 1.38-.148.914-.045 1.818.057 2.721.057-.88.319-1.819.182-2.71.24-.452.022-.903.045-1.307.147-.405.091-.737.365-1.01.684-.536.649-.868 1.48-.975 2.3-.107.821.202 1.675.939 2.119l-.155.239z'
        ></path>
        <path
          fill='#E3747B'
          d='M86.732 97.277c-.155.342-.392.638-.535.934-.143.308-.13.65-.083.991.071.342.19.672.404.923.226.25.547.319.927.285v.261c-.357.046-.868-.045-1.13-.375-.272-.308-.403-.684-.475-1.048a1.652 1.652 0 01.12-1.14c.178-.352.487-.592.772-.83zM90.274 107.54c.594-.068 1.177.011 1.76.011.284.023.57.012.855.012.285-.012.57-.023.844-.125-.476.398-1.141.387-1.724.398-.297-.023-.594-.045-.88-.091-.285-.057-.57-.148-.855-.205z'
        ></path>
      </g>
      <defs>
        <linearGradient
          id='paint0_linear_624_56517'
          x1='50.846'
          x2='65.228'
          y1='50.181'
          y2='47.82'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FAC2A8'></stop>
          <stop offset='1' stopColor='#DC918B'></stop>
        </linearGradient>
        <linearGradient
          id='paint1_linear_624_56517'
          x1='57.836'
          x2='56.217'
          y1='40.588'
          y2='55.957'
          gradientUnits='userSpaceOnUse'
        >
          <stop stopColor='#FFBEAF'></stop>
          <stop offset='1' stopColor='#E3747B'></stop>
        </linearGradient>
      </defs>
    </svg>
  );
}

const ModalFinish = ({ visible, onCancel }) => {
  return (
    <Modal
      title=''
      open={visible}
      onCancel={() => onCancel(false)}
      footer={false}
      className='modal-finish'
      centered={true}
    >
      <div className='finish'>
        <Icon />

        <h2>¡Listo! La lista de participantes ha sido actualizada</h2>

        <button onClick={() => onCancel(false)}>Aceptar</button>
      </div>
    </Modal>
  );
};

export default ModalFinish;
