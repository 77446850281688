import { useEffect, useState } from 'react';
import { getListManagerCompanies } from '../../../../../services/users';
import { getAllCompanies } from '../../../../../services/company';

export const useCompaniesUsers = () => {
  const [companiesUsers, setCompaniesUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 5,
    total: 0,
  });

  const loadCompaniesUsers = (data) => {
    setLoading(true);
    getListManagerCompanies(data)
      .then((res) => {
        if (!res) return;
        setCompaniesUsers(res.list);
        setPagination({
          current: res.page,
          pageSize: res.items_per_page,
          query: res.query,
          total: res.total,
        });
      })
      .finally(() => setLoading(false));
  };

  return {
    loadCompaniesUsers,
    companiesUsers,
    pagination,
    loading,
  };
};

export const useCompaniesNoManager = () => {
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState();

  useEffect(() => {
    setLoading(true);
    getAllCompanies({ query, page: 1, limit: 50 })
      .then((res) => {
        if (!res) return;
        const list = res.list.filter((c) => !c?.companiesUsers?.length);
        setCompanies(list);
      })
      .finally(() => setLoading(false));
  }, [query]);

  return {
    setQuery,
    companies,
    companiesLoading: loading,
  };
};
