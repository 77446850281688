import {
  LOADED_REQUEST_SERVICES,
  LOAD_REQUEST_SERVICES_LOADING,
  LOADING_CREATE_REQUEST_SERVICES,
  OPEN_FORM_REQUEST_SERVICES,
  UPDATE_REQUEST_SERVICES_ROW,
  CHANGE_REQUEST_SERVICES_FILTERS,
  LOADED_REQUEST_SERVICES_MANAGER_ACCOUNT,
  LOADED_REQUEST_SERVICES_USERS_CREATE,
  LOADED_REQUEST_SERVICES_CONTACT,
} from '../actions/actionsType';

const initialState = {
  list: [],
  listItems: [],
  riskItems: [],
  openedForm: false,
  isLoading: false,
  isLoadingCreate: false,
  page: 1,
  total: 0,
  pagination: {
    pageSize: 5,
    current: 1,
    total: 0,
    page: 1,
  },
  filters: {},
  managersAccount: null,
  usersCreate: null,
};

const requestServices = (state, action) => {
  if (!state) state = initialState;

  switch (action.type) {
    case LOAD_REQUEST_SERVICES_LOADING:
      return {
        ...state,
        isLoadingList: action.payload,
      };
    case LOADED_REQUEST_SERVICES:
      let objToUpd = {};

      for (let k in action.payload) objToUpd[k] = action.payload[k];

      return {
        ...state,
        ...objToUpd,
        page: action.payload.page,
        total: action.payload.total,
        pagination: {
          current: action.payload.page,
          pageSize: action.payload.items_per_page,
          total: action.payload.total,
        },
        isLoading: false,
      };
    case OPEN_FORM_REQUEST_SERVICES:
      return {
        ...state,
        openedForm: action.payload,
      };
    case LOADING_CREATE_REQUEST_SERVICES:
      return {
        ...state,
        isLoadingCreate: action.payload,
      };
    case UPDATE_REQUEST_SERVICES_ROW: {
      const serviceUpdated = action.payload;
      const newList = [...state.list].map((service) => {
        if (service.id === serviceUpdated.id) {
          serviceUpdated.requestServiceItems.map((rsiNew) => {
            const indexRsi = service.requestServiceItems?.findIndex(
              (rsiOld) => rsiOld.id === rsiNew.id,
            );
            if (indexRsi >= 0)
              service.requestServiceItems[indexRsi].provider_id =
                rsiNew.provider_id;
          });

          return {
            ...service,
            state: serviceUpdated.state,
          };
        }

        return service;
      });

      return {
        ...state,
        list: newList,
      };
    }
    case CHANGE_REQUEST_SERVICES_FILTERS:
      return {
        ...state,
        filters: action.payload || [],
      };
    case LOADED_REQUEST_SERVICES_MANAGER_ACCOUNT:
      return {
        ...state,
        managersAccount: action.payload || null,
      };
    case LOADED_REQUEST_SERVICES_USERS_CREATE:
      return {
        ...state,
        usersCreate: action.payload || null,
      };
    case LOADED_REQUEST_SERVICES_CONTACT:
      const newItems = state.listItems.map((el) =>
        action?.payload?.requestServiceItemId === el?.id
          ? {
              ...el,
              ...action?.payload?.data,
            }
          : el,
      );

      return {
        ...state,
        listItems: newItems,
      };
    default:
      return state;
  }
};

export default requestServices;
