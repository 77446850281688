import React from 'react';
import { Input, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

export const signageTypes = [
  {
    id: 1,
    title: 'Evacuación y Emergencia',
    key: 'EE',
    withFotolum: true,
  },
  {
    id: 2,
    title: 'Prohibición',
    key: 'PH',
  },
  {
    id: 3,
    title: 'COVID-19',
    key: 'COVID',
  },
  {
    id: 4,
    title: 'Incendio',
    key: 'CI',
    withFotolum: true,
  },
  {
    id: 5,
    title: 'Obligación',
    key: 'OB',
  },
  {
    id: 6,
    title: 'Advertencia',
    key: 'AD',
  },
];

export const getTootltip = (text) => (
  <Tooltip placement='top' title={text}>
    <InfoCircleOutlined
      style={{
        background: '#494F66',
        color: 'white',
        marginTop: 8,
        height: 14,
        width: 14,
        padding: 2,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
      }}
    />
  </Tooltip>
);

export const InputInsideTable = ({
  value,
  dataIndex,
  onChangeInput,
  error,
  className,
}) => (
  <div className={className}>
    <Input
      className={`input-inside-table ${
        error ? 'input-inside-table-error' : ''
      }`}
      name={dataIndex}
      key={dataIndex}
      value={Number(value)}
      onChange={onChangeInput}
      type='number'
      min={0}
      status={error ? 'error' : ''}
    />
    {error ? <span className={'color-error'}>*Error en campo</span> : null}
  </div>
);

export const getColumns = (withFotolum) =>
  [
    {
      title: '',
      width: '40px',
      dataIndex: 'check',
    },
    {
      title: 'imagen',
      dataIndex: 'image',
      key: 'image',
      width: '130px',
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description',
      width: '130px',
    },
    {
      title: () => (
        <div style={{ display: 'flex', cursor: 'pointer', maxWidth: '120px' }}>
          Sticker o Autoadhesivas
          {getTootltip(
            'Textiles, Construcción, Editoriales, Siderúrgicas, Minería, Ganadería, Agro, Plásticos, Papel, Químicos, Metal Mecánica, Minería.',
          )}
        </div>
      ),
      dataIndex: 'stickerAutoadhesivas',
      key: 'stickerAutoadhesivas',
      type: 'input',
      width: '125px',
      info: 'Textiles, Construcción, Editoriales, Siderúrgicas, Minería, Ganadería, Agro, Plásticos, Papel, Químicos, Metal Mecánica, Minería.',
    },
    {
      title: () => (
        <div style={{ display: 'flex', cursor: 'pointer', maxWidth: '120px' }}>
          Vinil Impreso con Base
          {getTootltip(
            'Empresas de Transporte y Carga, Locales Comerciales, Pesqueras, Energía.',
          )}
        </div>
      ),
      dataIndex: 'vinilImpresoBase',
      key: 'vinilImpresoBase',
      type: 'input',
      width: '125px',
    },
  ].concat(
    withFotolum
      ? {
          title: () => (
            <div
              style={{ display: 'flex', cursor: 'pointer', maxWidth: '120px' }}
            >
              Fotoluminiscente Ploteado, con Base
              {getTootltip(
                'Fotoluminiscencia es la propiedad que poseen determinados elementos para emitir radiación luminosa (fotones) después de haber sido sometidos a estimulación externa.',
              )}
            </div>
          ),
          dataIndex: 'fotoPloteadoBase',
          key: 'fotoPloteadoBase',
          type: 'input',
          width: '125px',
        }
      : [],
  );
