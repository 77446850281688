import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RideSpinner } from '@rimac-seguros/ride-system-components';
import SideMenu from './SideMenu';

const Template = ({ children }) => {
  const user = useSelector((state) => state.auth.user);
  const rol = user?.rol?.slug || '';
  const isOpened = useSelector((state) => state.sideMenu.isOpened);
  const location = useLocation();
  const navigate = useNavigate();
  const isLogged = useSelector((state) => state.auth.isLogged);
  const loginLoading = useSelector((state) => state.auth.loading);

  useEffect(() => {
    if (!isLogged && !loginLoading) {
      const fullUrl = window.location.href;
      const monitoreoIndex = fullUrl.indexOf('/monitoreo');
      if (monitoreoIndex !== -1) {
        const monitoreoPath = fullUrl.substring(monitoreoIndex);
        localStorage.setItem('preLoginUrl', monitoreoPath);
      }
      navigate('/login');
    } else if (isLogged && user) {
      const preLoginUrl = localStorage.getItem('preLoginUrl');
      if (preLoginUrl) {
        localStorage.removeItem('preLoginUrl');
        navigate(preLoginUrl);
      } else if (
        rol === 'proveedor' &&
        location.pathname === '/monitoreo/mapas'
      ) {
        navigate('/servicio-de-prevencion-solicitud-proveedor');
      }
    }
  }, [isLogged, navigate, loginLoading, rol, user, location.pathname]);

  if (isLogged && user) {
    return (
      <div className='dashboard-layout'>
        <SideMenu />
        <main className={`main-content ${isOpened ? 'content-pushed' : ''}`}>
          {children}
        </main>
      </div>
    );
  }

  if ((!isLogged && loginLoading) || (isLogged && !user)) {
    return (
      <div className='main-spin'>
        <RideSpinner size='large' />
      </div>
    );
  }

  return null;
};

export default Template;
