import * as turf from '@turf/turf';
import L from 'leaflet';
import { styleGeoJSONFunction } from '../utils';

export const handleSaveProperties =
  (selectedDraw, featureGroup, setSelectedDraw, geoDraws, dispatch) =>
  (props) => {
    const drawClone = structuredClone(selectedDraw.layer);

    if (drawClone?.properties) {
      drawClone.properties = {
        ...drawClone?.properties,
        ...props,
      };
    }

    const allDraws = [];

    featureGroup.current.eachLayer((layer) => {
      const geoJSON = layer.toGeoJSON();

      if (layer._leaflet_id !== selectedDraw.layerId) {
        allDraws.push(geoJSON);
      } else {
        const updateLayer = L.geoJSON(drawClone, {
          style: styleGeoJSONFunction,
        });

        updateLayer.eachLayer(function (l) {
          featureGroup.current.addLayer(l);

          l.on('click', (e) => {
            const { lat, lng } = e.latlng;
            setSelectedDraw({
              layer: l.toGeoJSON(),
              layerId: l._leaflet_id,
              lat,
              lng,
            });
          });
        });
        featureGroup.current.removeLayer(layer);

        allDraws.push(drawClone);
      }
    });

    geoDraws.current = allDraws;
    dispatch({ type: 'MY_LAYERS_SET_DRAWS', payload: geoDraws.current });
    setSelectedDraw(null);
  };

export const handleDrawCreated =
  (featureGroup, geoDraws, dispatch) =>
  ({ layer, layerType }) => {
    let geoJSON;

    if (layerType === 'circle') {
      const center = layer.getLatLng();
      const radius = layer.getRadius() / 1000;

      const circle = turf.circle([center.lng, center.lat], radius, {
        steps: 64,
      });
      const polygon = turf.polygon(circle.geometry.coordinates);

      featureGroup.current.addLayer(layer);
      geoJSON = {
        type: 'Feature',
        properties: {},
        geometry: polygon.geometry,
      };
    } else {
      featureGroup.current.addLayer(layer);
      geoJSON = layer.toGeoJSON();
    }

    geoDraws.current = [...geoDraws.current, geoJSON];
    dispatch({ type: 'MY_LAYERS_SET_DRAWS', payload: geoDraws.current });
  };

export const handleDrawEdited = (featureGroup, geoDraws, dispatch) => () => {
  const allDraws = [];

  featureGroup.current.eachLayer((layer) => {
    const geoJSON = layer.toGeoJSON();
    allDraws.push(geoJSON);
  });

  geoDraws.current = allDraws;
  dispatch({ type: 'MY_LAYERS_SET_DRAWS', payload: geoDraws.current });
};

export const handleDeleteLayer = (featureGroup, geoDraws, dispatch) => () => {
  featureGroup.current.clearLayers();
  geoDraws.current = [];
  dispatch({ type: 'MY_LAYERS_SET_DRAWS', payload: [] });
};
