import React from 'react';
import Header from '../Login/Header';

const LeftSection = () => {
  return (
    <section>
      <Header />
      <div className='information-content'>
        <footer className='ride-sys-text-paragraph-small-default'>
          &copy; {new Date().getFullYear()} RIMAC Seguros y Reaseguros.
        </footer>
      </div>
    </section>
  );
};

export default LeftSection;
