import { memo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useScreen } from '../../../lib/screen';
import { getSimulations } from '../../../services/simulations';

import ListOfSImulations from './ListOfSimulations';
import Topbar from '../../../common/ui/Topbar';
import Title from '../../../common/Typograph/Title';
import Tabs from '../../../common/Tabs';

const TABS = [
  {
    key: 'list',
    label: 'Simulaciones',
    children: <ListOfSImulations />,
  },
];

const ListSimulations = () => {
  const { isMobileScreen } = useScreen();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(TABS[0]);

  useEffect(() => {
    getSimulations(dispatch, {
      page: 1,
      limit: 5,
    });
  }, []);

  return (
    <div id='checkSize' className='h100vh dashboard-UI'>
      <Topbar />
      <div className='d_content service-prevention'>
        <div className='service-prevention-header header-administration'>
          <div className='flex justify-between items-center'>
            <Title type={isMobileScreen ? 'bold-32' : 'bold-28'}>
              {activeTab?.label}
            </Title>
          </div>
        </div>
        <Tabs
          activeKey={activeTab.key}
          onChange={(activeKey) =>
            setActiveTab(TABS.find((tab) => tab.key === activeKey))
          }
          items={TABS}
        />
      </div>
    </div>
  );
};

export default memo(ListSimulations);
