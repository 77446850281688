import React from 'react';
import { useSelector } from 'react-redux';

const CUMULUS_LEGEND = [
  { name: 'Muy alto', color: '#FF0000' },
  { name: 'Alto', color: '#FFAA00' },
  { name: 'Medio', color: '#FFFF00' },
  { name: 'Bajo', color: '#41A800' },
  { name: 'Muy bajo', color: '#6DC1FF' },
];

const LEGEND_TITLE = {
  inundacion: 'Susceptibilidad de Inundación',
  movimiento: 'Susceptibilidad de Mov. en Masa',
};

const LegendTop = () => {
  const { siderVisible, drawerVisible, selected, selectedLegend } = useSelector(
    (state) => ({
      drawerVisible: state.controlMap.drawerVisible,
      siderVisible: state.controlMap.siderVisible,
      selected: state.controlMap.selected,
      selectedLegend: state.cumulus.selectedLegend,
    }),
  );

  return (
    <div
      className={`
    legendTop
    ${selected === '7' ? 'legendTopForescasts' : ''}
    ${!siderVisible ? 'legendTopSider' : ''}
    ${drawerVisible ? 'legendTopDrawer' : ''}`}
    >
      <div className='legendTop__body'>
        <div className='legendTop__item'>
          <span className='legendTop__item-name legendTop__item-name--title'>
            {LEGEND_TITLE[selectedLegend]}
          </span>
        </div>
        {CUMULUS_LEGEND.map((legend) => (
          <div key={legend.name} className='legendTop__item'>
            <span className='legendTop__item-name'>{legend.name}</span>
            <div
              className='legendTop__item-circle'
              style={{ background: legend.color }}
            ></div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LegendTop;
