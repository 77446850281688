import React, { useEffect, useRef, useState } from 'react';
import { Carousel, Progress, Skeleton } from 'antd';

import Slide from './Slide';
import Pause from './Pause';
import Play from './Play';
import { getBannerWithoutAuth } from '../../../../services/banner';

const PrevArrow = ({ onClick, currentSlide, number }) => (
  <div
    className='slick-slider-arrow slick-slider-arrow--prev'
    data-value={'0' + number}
  >
    <svg
      width='32'
      height='32'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={() => {
        if (currentSlide !== 0) onClick();
      }}
    >
      <circle
        cx='19.9995'
        cy='20'
        r='19'
        stroke={currentSlide === 0 ? '#A9AFD1' : '#03050F'}
        strokeWidth='2'
      />
      <path
        d='M26.8745 21.25V18.75H16.9745L20.587 14.375H17.2745L12.7745 20L17.2745 25.625H20.587L16.9745 21.25H26.8745Z'
        fill={currentSlide === 0 ? '#A9AFD1' : '#03050F'}
      />
    </svg>
  </div>
);

const NextArrow = ({ onClick, currentSlide, number }) => (
  <div
    className='slick-slider-arrow slick-slider-arrow--next'
    data-value={'0' + number}
  >
    <svg
      width='32'
      height='32'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      onClick={() => {
        if (currentSlide !== number - 1) onClick();
      }}
    >
      <circle
        cx='20.0005'
        cy='20'
        r='19'
        stroke={currentSlide === number - 1 ? '#A9AFD1' : '#03050F'}
        strokeWidth='2'
      />
      <path
        d='M27.2255 20L22.7255 14.375H19.413L23.0255 18.75H13.1255V21.25H23.0255L19.413 25.625H22.7255L27.2255 20Z'
        fill={currentSlide === number - 1 ? '#A9AFD1' : '#03050F'}
      />
    </svg>
  </div>
);

const MainCarousel = () => {
  const [slides, setSlides] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);
  const sliderRef = useRef(null);

  const setting = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: false,
    nextArrow:
      slides.length > 1 ? (
        <NextArrow currentSlide={currentSlide} number={slides.length} />
      ) : null,
    prevArrow:
      slides.length > 1 ? (
        <PrevArrow currentSlide={currentSlide} number={currentSlide + 1} />
      ) : null,
    afterChange: (current) => setCurrentSlide(current),
  };

  useEffect(() => {
    (() => {
      setIsLoading(true);
      getBannerWithoutAuth()
        .then((data) => setSlides(data))
        .finally(() => setIsLoading(false));
    })();
  }, []);

  return (
    <div className='relative'>
      {isLoading ? (
        <div
          style={{ margin: '48px auto 0', padding: '24px', maxWidth: '1240px' }}
        >
          <Skeleton active paragraph={{ rows: 12 }} />
        </div>
      ) : (
        <>
          <Carousel ref={sliderRef} arrows effect='fade' {...setting}>
            {slides.map(
              ({
                id,
                text,
                header1,
                header2,
                description,
                link,
                target,
                image_url: src,
              }) => (
                <Slide
                  key={id}
                  subtitle={text}
                  title={header1}
                  titleRed={header2}
                  description={description}
                  href={link}
                  target={Boolean(parseInt(target))}
                  src={`${process.env.REACT_APP_WEB_PATH_S3_NEW}banner/${src}`}
                />
              ),
            )}
          </Carousel>
          {slides.length > 1 && (
            <>
              <Progress
                className='carousel-progress-bar'
                percent={100 * ((currentSlide + 1) / slides.length)}
                size='small'
              />
              <div
                className='carousel-pause'
                onClick={() => {
                  setAutoPlay((prev) => !prev);
                  !autoPlay
                    ? sliderRef.current.slick.slickPlay()
                    : sliderRef.current.slick.slickPause();
                }}
              >
                {autoPlay ? <Pause /> : <Play />}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default MainCarousel;
