import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Button from '../../../common/Button';
import Input from '../../../common/Input';

const schema = yup.object().shape({
  email: yup
    .string()
    .required('Por favor inserta el correo eléctronico')
    .email('Por favor inserta un correo correcto'),
});

const PasswordResetForm = ({ handleSubmit, loading, handleShowRecovery }) => {
  const formRef = useRef();
  const {
    register,
    errors,
    handleSubmit: submitForm,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
  });

  register('email');

  return (
    <>
      <p className='recovery-pass-text'>
        Ingresa tu correo y en breve recibirás un mensaje para que puedas crear
        tu nueva contraseña.
      </p>
      <form ref={formRef} onSubmit={submitForm(handleSubmit)}>
        <Input
          name='email'
          placeholder='Correo eléctronico'
          onChange={(e) => setValue('email', e.target.value)}
          value={watch('email')}
        />
        {errors && errors.email && <p>{errors.email.message}</p>}
        <div className='flex space-around mt-4'>
          <Button
            text='Cancelar'
            variant='text-primary'
            size='large'
            onClick={() => handleShowRecovery(false)}
          />
          <Button text='Enviar' size='large' type='submit' loading={loading} />
        </div>
      </form>
    </>
  );
};

export default PasswordResetForm;
