import * as React from 'react';

const Temperature = (props) => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M21.25 24.7625V6.875C21.25 3.77344 18.7266 1.25 15.625 1.25C12.5234 1.25 10 3.77344 10 6.875V24.7625C8.40469 26.2922 7.5 28.3969 7.5 30.625C7.5 35.1047 11.1453 38.75 15.625 38.75C20.1047 38.75 23.75 35.1047 23.75 30.625C23.75 28.3953 22.8453 26.2906 21.25 24.7625ZM15.625 3.75C17.3484 3.75 18.75 5.15156 18.75 6.875V17.5H12.5V6.875C12.5 5.15156 13.9016 3.75 15.625 3.75ZM15.625 36.25C12.5234 36.25 10 33.7266 10 30.625C10 28.9406 10.7453 27.3594 12.0453 26.2859L12.5 25.9109V20H18.75V25.9109L19.2047 26.2859C20.5047 27.3594 21.25 28.9422 21.25 30.625C21.25 33.7266 18.7266 36.25 15.625 36.25Z'
      fill='url(#paint0_linear_9056_7844)'
    />
    <path
      d='M23.125 6.25H30V8.75H23.125V6.25ZM23.125 13.75H27.5V11.25H23.125V13.75ZM23.125 18.75H30V16.25H23.125V18.75ZM23.125 23.75H27.5V21.25H23.125V23.75Z'
      fill='url(#paint1_linear_9056_7844)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_9056_7844'
        x1='7.5'
        y1='1.25'
        x2='34.5747'
        y2='27.6478'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.272135' stopColor='#34263B' />
        <stop offset='0.658079' stopColor='#13172C' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_9056_7844'
        x1='23.3049'
        y1='7.62344'
        x2='33.1192'
        y2='11.4791'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.35' stopColor='#F7052D' />
        <stop offset='0.85' stopColor='#CA5AFA' />
      </linearGradient>
    </defs>
  </svg>
);

export default Temperature;
