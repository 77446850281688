import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Card,
  Divider,
  List,
  notification,
  Popover,
  Skeleton,
  Select,
  Modal,
  Typography,
  Alert,
} from 'antd';
import {
  PlusCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  LeftOutlined,
  PlusOutlined,
  MoreOutlined,
  CalendarOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';

import { useScreen } from '../../../lib/screen';
import SelectCompany from '../common/SelectCompany';
import { getPolizasByCompanyId } from '../../../services/polizas';
import { useDispatch } from 'react-redux';
import {
  OPEN_FORM_REQUEST,
  UPDATE_REQUEST_SERVICE_FIELD,
} from '../../../redux/actions/actionsType';
import {
  createRequestServiceWithParticipants,
  getMyPolizas,
} from '../../../services/requestServices';
import ModalConfirm from '../../../common/ModalConfirm';

import CalendarIcon from '../../../images/calendar_svg.svg';
import IconInformation from '../../../images/icon_info.svg';
import ConfirmModalImage from '../../../images/icon_send-info.svg';
import { signalsKeys } from '.';
import cryptoUtils from '../../../lib/crypto';
import { pushDigitalData } from '../../../lib/utils';
import { TRACK } from '../../../lib/consts';
import { RideButton } from '@rimac-seguros/ride-system-components';

const { Title, Text } = Typography;

const PopoverOptions = ({ onUpdate, onDelete }) => {
  const [visible, setVisible] = useState(false);

  const _handleVisibleToogle = () => {
    setVisible((prev) => !prev);
  };

  return (
    <Popover
      open={visible}
      className='popover'
      placement='topRight'
      content={
        <List
          className='list-custom'
          title='Item 1'
          dataSource={[
            {
              action: onUpdate,
              icon: <EditOutlined />,
              title: 'Editar',
              key: 'edit',
            },
            {
              action: () => {
                setVisible(false);
                onDelete();
              },
              icon: <DeleteOutlined />,
              title: 'Eliminar',
              key: 'delete',
            },
          ]}
          renderItem={({ action, icon, key, title }) => (
            <List.Item
              style={{ width: 132, height: 40, paddingLeft: 16 }}
              className='list-item-custom'
              key={key}
              onClick={action}
            >
              <span style={{ marginRight: 12 }}>{icon}</span>
              {title}
            </List.Item>
          )}
        ></List>
      }
    >
      <Button
        type='text'
        icon={<MoreOutlined />}
        onClick={_handleVisibleToogle}
      />
    </Popover>
  );
};

const validRequestService = (list) => {
  const errors = list.map(({ service, contact }) => {
    if (!service.serviceType || !service.serviceId || !contact.contact)
      return true;

    return false;
  });

  return errors.some(Boolean);
};

const serviceInHouseKeys = {
  hours: 'Número de horas',
  course: 'Capacitación',
};

const contactKeys = {
  contact: 'Contacto',
  number: 'Celular',
  address: 'Dirección',
  email: 'Correo electrónico',
  position: 'Cargo',
};

const RequestServicesList = ({
  onBack,
  visible,
  companySelected,
  setCompanySelected,
  updateStorageMain,
}) => {
  const { isMobile } = useScreen();
  const [loadingPolicies, setLoadingPolicies] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [modalSuccessful, setModalSuccessful] = useState(false);

  const dispatch = useDispatch();
  const { userSlug, policies, polizaId, requestList, signals, companyId } =
    useSelector((state) => ({
      userSlug: state.auth.user?.rol?.slug || '',
      policies: state.requestServiceForm.policies,
      polizaId: state.requestServiceForm.polizaId,
      requestList: state.requestServiceForm.requestServicesList,
      signals: state.requestServiceForm.signals,
      companyId: state.auth?.user?.company_id,
    }));

  const allSignals = useMemo(
    () =>
      Object.values(signals || [])
        .reduce((a, b) => a.concat(b), [])
        .reduce((acc, curr) => {
          acc[curr.code] = curr;

          return acc;
        }, {}),
    [signals],
  );
  const [searchParams] = useSearchParams();
  const search = searchParams.toString();

  const query = useMemo(() => {
    const q = new URLSearchParams(search);
    localStorage.setItem('polizaSearch', q.get('poliza') ?? undefined);
    localStorage.setItem('serviceSearch', q.get('service') ?? undefined);

    return q;
  }, [search]);

  useEffect(() => {
    if (companySelected) {
      setLoadingPolicies(true);
      getPolizasByCompanyId(companySelected)
        .then((data) => {
          dispatch({
            type: UPDATE_REQUEST_SERVICE_FIELD,
            payload: { policies: data },
          });
        })
        .finally(() => {
          setLoadingPolicies(false);
        });
    }
  }, [companySelected]);

  const getPolizas = useCallback(() => {
    setLoadingPolicies(true);

    return getMyPolizas()
      .then((data) => {
        updateStorageMain();

        const polizaId = query.get('poliza') ?? String(data[0]?.id);

        if (polizaId) {
          localStorage.setItem('polizaId', polizaId);
          const { typePolizaId } =
            data.find((el) => String(el.id) === polizaId) || {};
          dispatch({
            type: UPDATE_REQUEST_SERVICE_FIELD,
            payload: { policies: data, typePolizaId, polizaId },
          });
        }
      })
      .finally(() => {
        setLoadingPolicies(false);
      });
  }, [dispatch, query, updateStorageMain]);

  useEffect(() => {
    if (!['admin_rimac', 'gestor_de_cuenta'].includes(userSlug)) getPolizas();
  }, [userSlug]);

  const _handleSelectPolicy = useCallback(
    (poliza) => {
      localStorage.setItem('polizaId', poliza);
      const { typePolizaId } =
        policies.find((el) => String(el.id) === polizaId) || {};
      dispatch({
        type: UPDATE_REQUEST_SERVICE_FIELD,
        payload: { polizaId: poliza, typePolizaId },
      });
    },
    [dispatch, policies, polizaId],
  );

  const createRequestService = useCallback(
    async (data, onSuccess = () => {}) => {
      const created = await createRequestServiceWithParticipants(
        data,
        dispatch,
      );
      if (created) {
        onSuccess();

        return true;
      } else {
        notification.warning({
          className: 'notification-warning',
          message: 'Ha ocurrido un error.',
        });

        return false;
      }
    },
    [dispatch],
  );

  const _handleToggleSuccessfulModal = useCallback(() => {
    handlePushDigitalData('Solicitar servicio');

    const isError = validRequestService(requestList);
    try {
      if (!isError) {
        setLoadingRequest(true);
        const data = requestList.map(({ contact, service }) => {
          let signals = undefined;
          const parseContact = Object.keys(contactKeys).reduce(
            (a, b) => ({ ...a, [b]: contact[b] }),
            {},
          );

          if (service.signals)
            signals = Object.keys(service.signals).reduce((acc, key) => {
              let counter = Object.values(service.signals[key]);
              counter = counter
                ? Object.values(counter).filter(Boolean).length > 0
                : null;
              if (counter) acc[key] = service.signals[key];

              return acc;
            }, {});
          const parseService = {
            ...service,
            ...(service?.serviceType === '5'
              ? {
                  derived: true,
                  courses: {
                    course_key: `workshop-${cryptoUtils.randomStr(3)}`,
                    derived: true,
                    hours: 1,
                    state: 1,
                    course: service.serviceName,
                    courseId: null,
                    hasExam: 0,
                    participants: [],
                  },
                }
              : {}),
            ...(service?.serviceType === '3' && signals && Object.keys(signals)
              ? {
                  signals: Object.keys(signals).reduce((acc, key) => {
                    let count = 0;
                    for (let signal of signalsKeys)
                      count = count + Number(signals[key][signal] || 0);

                    acc[key] = JSON.stringify({
                      ...allSignals[key],
                      ...signals[key],
                      count,
                      createdAt: null,
                      updatedAt: null,
                    });

                    return acc;
                  }, {}),
                }
              : null),
            ...(service?.serviceType === '2'
              ? { courses: null, derived: false }
              : service?.serviceType === '1'
              ? { courses: {}, hours: 0, derived: true }
              : service?.serviceType === '3'
              ? { courses: null, derived: false }
              : { derived: false }),
          };

          return { ...parseContact, ...parseService };
        });

        createRequestService(
          {
            polizaId,
            companyId: ['admin_rimac', 'gestor_de_cuenta'].includes(userSlug)
              ? companySelected
              : companyId,
            data,
          },
          () => {
            localStorage.removeItem(
              `requestServicesList-${query.get('poliza') || undefined}-${
                query.get('service') || undefined
              }`,
            );
            localStorage.removeItem('polizaSearch');
            localStorage.removeItem('serviceSearch');
            dispatch({
              type: UPDATE_REQUEST_SERVICE_FIELD,
              payload: {
                polizaId: null,
                requestServicesList: [],
              },
            });

            setModalSuccessful((prev) => !prev);
            setLoadingRequest(false);
          },
        );
      } else {
        notification.warning({
          className: 'notification-warning',
          message: 'Contacto o servicio es obligatorio.',
        });
      }
    } catch (error) {
      notification.warning({
        className: 'notification-warning',
        message: 'Hubo un error al solicitar servicio.',
      });
    }
  }, [
    allSignals,
    companyId,
    companySelected,
    createRequestService,
    dispatch,
    polizaId,
    query,
    requestList,
    userSlug,
  ]);

  const _handleServiceForm = (payload) => {
    dispatch({ type: OPEN_FORM_REQUEST, payload });
  };

  const _handleDeleteService = useCallback(
    (id) => () => {
      setIdToDelete(id);
    },
    [],
  );

  const handlePushDigitalData = (label) => {
    if (process.env.REACT_APP_ANALYTICS_ENABLE !== '0') {
      pushDigitalData(
        {
          action: {
            group: 'Centro Monitoreo',
            category: 'Servicios de Prevención',
            name: 'Lista de Servicios',
            label,
          },
        },
        TRACK.ACTION,
      );
    }
  };

  return (
    <>
      <div
        className={`d_content flex flex-1 pb-3 ${
          isMobile ? 'flex-col' : 'flex-justify-justify-between'
        } ${visible ? 'hidden' : 'block'}`}
      >
        <div className='flex flex-col flex-1'>
          <div className='flex flex-align-items-center mb-3'>
            <Button
              className='mr-1 clock-icon'
              icon={<LeftOutlined />}
              shape='circle'
              onClick={onBack}
            />
            {['admin_rimac', 'gestor_de_cuenta'].includes(userSlug) ? (
              <Title level={2} style={{ marginBottom: 0 }}>
                Agregar un servicio
              </Title>
            ) : (
              <Title level={2} style={{ marginBottom: 0 }}>
                Solicitar un servicio
              </Title>
            )}
          </div>
          {['admin_rimac', 'gestor_de_cuenta'].includes(userSlug) ? (
            <>
              <span className='subtitle mt-3'>
                Selecciona una de las empresas disponibles
              </span>
              <SelectCompany
                className='mt-2 mb-1'
                companySelected={companySelected}
                onSelectCompany={setCompanySelected}
              />
            </>
          ) : null}
          <Text strong className='mt-3'>
            Elige la póliza de donde se solicitará el servicio
          </Text>
          <Select
            className='mb-4'
            style={{ maxWidth: '321px' }}
            value={loadingPolicies ? null : polizaId}
            placeholder='Selecciona una póliza'
            size='large'
            disabled={
              ['admin_rimac', 'gestor_de_cuenta'].includes(userSlug) &&
              !companySelected
            }
            loading={loadingPolicies}
            onChange={_handleSelectPolicy}
          >
            {policies.map((pol) => (
              <Select.Option key={pol.id} value={String(pol.id)}>
                {`${pol?.typePoliza?.name} ${moment(pol.start_date).format(
                  'L',
                )}`}
              </Select.Option>
            ))}
          </Select>
          <Title level={4}>Información del Servicio</Title>
          <Text>Puedes agregar más de 1 servicio</Text>
          <Skeleton loading={loadingPolicies}>
            {requestList && requestList.length ? (
              <div className='service-list-container'>
                {requestList.map((data) => (
                  <Card className='flex flex-col card-services' key={data.id}>
                    <div className='flex justify-between align-items'>
                      <div>
                        <CalendarOutlined style={{ marginRight: '8px' }} />
                        <Text strong>{data?.service?.serviceName}</Text>
                      </div>
                      <PopoverOptions
                        onUpdate={() =>
                          _handleServiceForm({ action: 'update', data })
                        }
                        onDelete={_handleDeleteService(data.id)}
                      />
                    </div>
                    <Divider className='mt-2 mb-1' />
                    <div className='flex flex-col'>
                      {Object.keys(contactKeys).map((key) => (
                        <div className='flex mt-1' key={key}>
                          <span className='item-label'>
                            {contactKeys[key]}:
                          </span>
                          <span className='item-value ml-2'>
                            {data?.contact[key]}
                          </span>
                        </div>
                      ))}
                    </div>
                    {data?.service &&
                    data?.service?.serviceType === '1' &&
                    data?.service?.courses &&
                    !data?.service?.derived ? (
                      <>
                        <Divider className='mt-2 mb-1' />
                        <div className='flex flex-col'>
                          {Object.keys(serviceInHouseKeys).map((key) => (
                            <div className='flex mt-1' key={key}>
                              <span className='item-label'>
                                {serviceInHouseKeys[key]}:
                              </span>
                              <span className='item-value ml-2'>
                                {data?.service?.courses[key]}
                              </span>
                            </div>
                          ))}
                        </div>
                      </>
                    ) : null}
                  </Card>
                ))}
                <Button
                  className='btn-plus'
                  type='link'
                  onClick={() => _handleServiceForm({ action: 'add' })}
                >
                  Agregar otro servicio <PlusCircleOutlined />
                </Button>
              </div>
            ) : (
              <Card className='mt-3 mb-3'>
                <div className='flex flex-col'>
                  <img
                    src={IconInformation}
                    alt='request_service_form'
                    width={80}
                    style={{ alignSelf: 'center' }}
                  />
                  <span className='text-align-center'>
                    Por ahora no tienes un servicio registrado para realizar la
                    solicitud
                  </span>
                  <Button
                    className='btn-add mt-2'
                    icon={<PlusOutlined />}
                    type='link'
                    disabled={!polizaId}
                    onClick={() => _handleServiceForm({ action: 'add' })}
                  >
                    AGREGAR SERVICIO
                  </Button>
                </div>
              </Card>
            )}
          </Skeleton>
          <Divider className='mt-2 mb-2' />
          <RideButton
            style={{
              maxWidth: '321px',
              alignSelf: 'center',
            }}
            onClick={_handleToggleSuccessfulModal}
            disabled={!requestList?.length || !polizaId}
            loading={loadingRequest}
            text='Solicitar servicio'
            size='large'
          />
        </div>
      </div>
      <Modal
        open={!!idToDelete}
        onCancel={_handleDeleteService(null)}
        title='¿Seguro de eliminar la información?'
        footer={
          <div className='actions-delete-modal mt-2'>
            <Button
              className='button secondary-button mr-2'
              onClick={_handleDeleteService(null)}
            >
              Cancelar
            </Button>
            <Button
              className='button ml-1'
              type='primary'
              onClick={() => {
                _handleServiceForm({ action: 'delete', id: idToDelete });
                setIdToDelete(null);
              }}
            >
              Aceptar
            </Button>
          </div>
        }
      />
      <ModalConfirm
        visible={modalSuccessful}
        image={ConfirmModalImage}
        onClose={onBack}
        title='¡Listo! Tu solicitud ha sido enviada'
        content={
          <span className='text-subtitle-bold text-center mb-2'>
            Para coordinar los siguientes pasos de tu solicitud. Nos estaremos
            contactando en un plazo máximo de 3 días útiles mediante los correos
            de los servicios solicitados.
          </span>
        }
        actionsContent={
          <div className='flex items-center full-width mt-2 justify-center'>
            <Button className='button-accept' type='primary' onClick={onBack}>
              Aceptar
            </Button>
          </div>
        }
      />
    </>
  );
};

export default RequestServicesList;
