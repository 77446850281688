import * as React from 'react';

const Rain = (props) => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M33.6828 26.2499H36.3187L35.0687 29.9999H32.4328L33.6828 26.2499ZM27.8812 29.9999L29.1312 26.2499H26.4953L25.2453 29.9999H27.8812ZM20.6938 29.9999L21.9438 26.2499H19.3078L18.0578 29.9999H20.6938ZM13.5063 29.9999L14.7563 26.2499H12.1203L10.8703 29.9999H13.5063ZM33.6828 32.4999L32.4328 36.2499H35.0687L36.3187 32.4999H33.6828ZM26.1828 32.4999L24.9328 36.2499H27.5687L28.8187 32.4999H26.1828ZM18.6828 32.4999L17.4328 36.2499H20.0688L21.3188 32.4999H18.6828ZM7.56875 26.2499H4.93281L3.68281 29.9999H6.31875L7.56875 26.2499ZM6.31875 32.4999H3.68281L2.43281 36.2499H5.06875L6.31875 32.4999ZM11.1828 32.4999L9.93281 36.2499H12.5688L13.8188 32.4999H11.1828ZM32.7563 11.2577H31.8469C30.8703 9.48119 28.9875 8.33899 26.9172 8.33899C25.8438 8.33899 24.8219 8.64681 23.9484 9.189C23.1219 5.48744 19.8844 2.67181 15.9703 2.50775C13.6156 2.40931 11.3844 3.25149 9.68906 4.87805C8.29531 6.21399 7.41562 7.93118 7.14531 9.80305C6.66406 9.77961 6.17813 9.82024 5.69375 9.92493C3.11875 10.4827 1.25 12.8406 1.25 15.5312V17.1421C1.9125 16.7015 2.68125 16.4062 3.50781 16.3046C3.58594 16.2234 3.66719 16.1468 3.75 16.0718V15.5312C3.75 14.0093 4.79063 12.6796 6.22344 12.3687C6.78438 12.2468 7.34844 12.2812 7.89844 12.4718L9.22812 12.9312C9.33594 12.8577 9.44375 12.7874 9.55625 12.7202V11.0468C9.55625 9.38273 10.2172 7.83274 11.4188 6.68118C12.6188 5.52962 14.1984 4.93431 15.8656 5.00462C18.9812 5.13431 21.5766 7.77962 21.6516 10.8999C21.6625 11.3562 21.6219 11.8124 21.5312 12.2577L21.2281 13.7562H23.6766L23.9719 12.9234C24.4141 11.6749 25.5984 10.8374 26.9188 10.8374C28.2391 10.8374 29.4234 11.6765 29.8656 12.9234L30.1609 13.7562H32.7578C34.6844 13.7562 36.2516 15.3234 36.2516 17.2499C36.2516 18.6577 35.1063 19.8046 33.6969 19.8046H22.3687C22.4125 20.0749 22.4359 20.3531 22.4359 20.6343V21.5046C22.4359 21.7781 22.4078 22.0452 22.3578 22.3046H33.6969C36.4844 22.3046 38.7516 20.0374 38.7516 17.2499C38.7516 13.9452 36.0625 11.2562 32.7578 11.2562L32.7563 11.2577Z'
      fill='url(#paint0_linear_9056_7807)'
    />
    <path
      d='M18.0031 17.4561C17.8344 17.4218 17.6641 17.4014 17.4953 17.3968C17.3031 16.4671 16.8313 15.6046 16.1359 14.9452C15.1984 14.0546 13.9688 13.6015 12.6719 13.6686C10.6875 13.7718 9.02813 15.114 8.45938 16.9405C8.05156 16.7515 7.6 16.6483 7.13282 16.6483C6.025 16.6483 5.0125 17.228 4.44062 18.1421H4.14532C2.29063 18.1421 0.78125 19.6515 0.78125 21.5061C0.78125 22.7436 1.7875 23.7499 3.025 23.7499H18.3156C19.5531 23.7499 20.5594 22.7436 20.5594 21.5061V20.6358C20.5594 19.0905 19.4828 17.753 18.0016 17.4561H18.0031Z'
      fill='url(#paint1_linear_9056_7807)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_9056_7807'
        x1='1.25'
        y1='2.5'
        x2='18.0598'
        y2='44.5263'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.272135' stopColor='#34263B' />
        <stop offset='0.658079' stopColor='#13172C' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_9056_7807'
        x1='1.29866'
        y1='14.4538'
        x2='8.02694'
        y2='27.6453'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.35' stopColor='#F7052D' />
        <stop offset='0.85' stopColor='#CA5AFA' />
      </linearGradient>
    </defs>
  </svg>
);

export default Rain;
