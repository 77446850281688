import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Typography,
  Divider,
  Row,
  Col,
  notification,
  Input,
} from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { RideButton } from '@rimac-seguros/ride-system-components';
import {
  OPEN_FORM_REQUEST,
  SAVE_CONTACTS,
} from '../../../../redux/actions/actionsType';

const { Text } = Typography;

const fieldsRight = [
  {
    label: 'Celular*',
    key: 'number',
    valid: 'text',
    type: 'number',
    required: true,
    order: 3,
  },
  {
    label: 'Dirección',
    key: 'address',
    order: 4,
  },
];

const fieldsLeft = [
  {
    label: 'Nombre de contacto*',
    key: 'contact',
    required: true,
    valid: 'text',
    order: 2,
  },
  {
    label: 'Correo electrónico*',
    key: 'email',
    valid: 'email',
    required: true,
    order: 5,
  },
  {
    label: 'Cargo en la empresa (opcional)',
    key: 'position',
    required: false,
    order: 6,
  },
];

const isValidField = (type, value) => {
  switch (type) {
    case 'number':
    case 'text':
      return value && value.length;
    case 'email':
      const regExp = new RegExp(
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
      );

      return regExp.test(value?.trim()) && value;
    default:
      return true;
  }
};

const FieldsInputs = ({
  invalidField,
  name,
  value,
  onChange,
  type,
  ...props
}) => {
  const [afterBlur, setAfterBlur] = useState(false);

  return (
    <>
      <Input
        {...(props || {})}
        className='mt-3'
        onChange={onChange}
        placeholder={props.label}
        type={type}
        name={name}
        onBlur={() => {
          setAfterBlur(true);
        }}
        value={value || ''}
      />
      {props.required && invalidField && afterBlur ? (
        <Typography.Text
          style={{
            color: 'red',
          }}
        >
          Campo requerido
        </Typography.Text>
      ) : null}
    </>
  );
};

const AddContact = ({
  idForm,
  contactData = {},
  onNextStep = () => {},
  savedContacts = [],
  onSaveContact,
  onUpdateLocalStorage,
  isMobile,
  buttonText,
  buttonTextFromStorage,
}) => {
  const [formData, setFormData] = useState(() => {
    const storageData = localStorage.getItem(`contact-${idForm}`);

    return storageData ? JSON.parse(storageData) : contactData;
  });
  const [contactSelect, setSelectContact] = useState();

  useEffect(() => {
    setFormData(contactData);
  }, [JSON.stringify(contactData)]);

  useEffect(() => {
    onUpdateLocalStorage('contact', formData);
  }, [formData]);

  const disabledNext = useMemo(() => {
    return fieldsLeft.concat(fieldsRight).reduce((result, elem) => {
      if (elem.required)
        return result || !isValidField(elem.valid, formData[elem.key]);

      return result;
    }, false);
  }, [formData]);

  const _handleChangeField = useCallback(({ target: { value, name } }) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const _handleSaveContact = useCallback(() => {
    onSaveContact({ ...formData, id: idForm });
    setSelectContact(savedContacts.length + 1);
    notification.success({
      message: '¡Contacto guardado!',
      description: 'Se guardó correctamente el contacto ingresado.',
    });
  }, [formData, idForm, onSaveContact, savedContacts.length]);

  return (
    <div className='flex flex-col'>
      <Row gutter={16}>
        <Col span={isMobile ? 24 : 12}>
          <Text className='subtitle mt-3 mb-2'>
            Ingresa la información del contacto
          </Text>
        </Col>
      </Row>
      {isMobile ? (
        <Row>
          <Col span={24}>
            {fieldsLeft
              .concat(fieldsRight)
              .sort((a, b) => a.order - b.order)
              .map(({ key, type = 'text', ...props }) => (
                <FieldsInputs
                  key={key}
                  name={key}
                  value={formData[key]}
                  type={type}
                  disabled={Boolean(contactSelect)}
                  onChange={_handleChangeField}
                  invalidField={!isValidField(type, formData[key])}
                  {...props}
                />
              ))}
            {!contactSelect && !disabledNext ? (
              <Button
                className='btn-add-form mt-2 mb-2'
                icon={<PlusCircleOutlined />}
                onClick={_handleSaveContact}
                type='link'
              >
                Guardar contacto
              </Button>
            ) : null}
          </Col>
        </Row>
      ) : (
        <Row gutter={16}>
          <Col span={12}>
            {fieldsLeft.map(({ key, type = 'text', ...props }) => (
              <FieldsInputs
                name={key}
                key={key}
                value={formData[key]}
                type={type}
                disabled={Boolean(contactSelect)}
                onChange={_handleChangeField}
                invalidField={!isValidField(type, formData[key])}
                {...props}
              />
            ))}
            <div className='btn-add-form mt-2 mb-2'></div>
          </Col>
          <Col span={12}>
            {fieldsRight.map(({ key, type = 'text', ...props }) => (
              <FieldsInputs
                key={key}
                name={key}
                value={formData[key]}
                type={type}
                disabled={Boolean(contactSelect)}
                onChange={_handleChangeField}
                invalidField={!isValidField(type, formData[key])}
                {...props}
              />
            ))}
          </Col>
        </Row>
      )}
      <Divider className='mt-3' />
      <RideButton
        style={{
          maxWidth: '320px',
          alignSelf: 'center',
        }}
        disabled={disabledNext}
        onClick={onNextStep}
        size='large'
        text={buttonText || buttonTextFromStorage || 'Continuar'}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateForm: (payload) => {
    dispatch({
      type: OPEN_FORM_REQUEST,
      payload: {
        data: payload,
        action: 'update',
      },
    });
  },
  onSaveContact: (payload) => {
    dispatch({ type: SAVE_CONTACTS, payload });
  },
});

const mapStateToProps = (state) => ({
  savedContacts: state.requestServiceForm.savedContacts,
  buttonTextFromStorage: state.requestServiceForm.buttonText,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddContact);
