import React, { useEffect, useState, useCallback } from 'react';
import { getCompanyCompliance } from '../../../../../services/company';

const CompliancePolizas = ({ companyId }) => {
  const [compliances, setCompliances] = useState([]);

  const loadCompliance = useCallback(() => {
    getCompanyCompliance(companyId)
      .then((data) => setCompliances(data))
      .catch(console.error);
  }, [companyId]);

  useEffect(() => {
    loadCompliance();
  }, [loadCompliance]);

  return (
    compliances &&
    compliances.map((compliance) => (
      <div key={compliance.idPoliza} className='complianceList'>
        <p>
          {compliance?.typePoliza}: {compliance?.compliance}%
        </p>
      </div>
    ))
  );
};

export default CompliancePolizas;
