import React, { memo } from 'react';
import { Divider, Modal } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import { useScreen } from '../../../../../lib/screen';

import Title from '../../../../../common/Typograph/Title';

const RenewPolicyModal = ({ visible, onClose }) => {
  const { isMobileScreen } = useScreen();

  return (
    <Modal
      width={620}
      className='modal-confirm'
      centered
      footer={<></>}
      closable
      open={Boolean(visible)}
    >
      <div
        style={{
          maxHeight: isMobileScreen ? 360 : 460,
          overflow: 'hidden auto',
        }}
      >
        <div className='flex flex-col items-center mb-2'>
          <Title type={'bold-28'}>Renovar póliza</Title>
          <Divider />
        </div>
      </div>
    </Modal>
  );
};

export default memo(RenewPolicyModal);
