import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLocations } from '../../../../services/locations';
import { Circle } from 'react-leaflet';
import { ringsCreate } from './utils';

const RingsFlash = ({ isToggled }) => {
  const dispatch = useDispatch();
  const points = useSelector((state) => state.locations.list || []);

  useEffect(() => {
    getLocations(dispatch);
  }, []);

  const rings = points
    .map((p) => ringsCreate(p))
    .filter(Boolean)
    .reduce((prev, next) => prev.concat(next), []);

  return (
    <>
      {isToggled
        ? []
        : rings.map((ring) => (
            <Circle
              key={`${ring.id}-${ring.radius}-${ring.pathOptions?.color}`}
              center={ring.center}
              radius={ring.radius}
              pathOptions={ring.pathOptions}
            />
          ))}
    </>
  );
};
export default RingsFlash;
