import { useState } from 'react';
import { Select, Switch, ColorPicker } from 'antd';
import { tileLayers } from '../utils/leafletMapConfig';
import { activeLayer } from '../utils/layers';
import { debounce } from '../../../lib/utils';
import {
  RideGlUp,
  RideGlDown,
  RideGlSearchSolid,
  RideGlToolsSolid,
  RideActionButton,
} from '@rimac-seguros/ride-system-components';

const { Option } = Select;

const SelectControls = ({
  selectLayer,
  setSelectLayer,
  selectTileLayer,
  setSelectTileLayer,
  showLimitesWebLayer,
  setShowLimitesWebLayer,
  color,
  setColor,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const onChangeColor = debounce((color) => {
    setColor(color.toHexString());
  }, 500);

  return (
    <>
      <div
        style={{
          position: 'absolute',
          top: 10,
          right: 10,
          zIndex: 1000,
          display: 'flex',
          gap: '0.5rem',
        }}
      >
        <Select
          onChange={setSelectLayer}
          value={selectLayer}
          size='medium'
          className='select-layer'
          suffixIcon={dropdownOpen ? <RideGlUp /> : <RideGlDown />}
          onDropdownVisibleChange={(open) => setDropdownOpen(open)}
        >
          {activeLayer.map((layer) => (
            <Option key={layer.id} value={layer.id}>
              <label className='flex items-center gap-2'>
                {layer.icon && <layer.icon size='large' />}
                {layer.name}
              </label>
            </Option>
          ))}
        </Select>
        <RideActionButton
          glyph={<RideGlSearchSolid />}
          tooltipText='Buscar'
          placement='left-bottom'
          size='small'
        />
        <RideActionButton
          glyph={<RideGlToolsSolid />}
          tooltipText='Herramientas'
          placement='left-bottom'
          size='small'
        />
      </div>
      <Select
        onChange={setSelectTileLayer}
        value={selectTileLayer}
        style={{
          position: 'absolute',
          top: 70,
          right: 10,
          zIndex: 1000,
          width: 200,
        }}
      >
        {tileLayers.map((layer) => (
          <Option key={layer.key} value={layer.key}>
            {layer.name}
          </Option>
        ))}
      </Select>
      <div
        style={{ position: 'absolute', top: 190, right: 10, zIndex: 1000 }}
        className='flex items-center gap-2'
      >
        <label htmlFor='limitesWebLayerSwitch'>Mostrar Limites Web Layer</label>
        <Switch
          id='limitesWebLayerSwitch'
          checked={showLimitesWebLayer}
          onChange={setShowLimitesWebLayer}
        />
        <ColorPicker
          value={color}
          onChange={onChangeColor}
          defaultFormat='hex'
          format='hex'
        />
      </div>
    </>
  );
};

export default SelectControls;
