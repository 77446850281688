import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'antd';

import { removeLocation } from '../../../../services/locations';
import ModalConfirm from '../../../../common/ModalConfirm';
import DeleteModalImage from '../../../../images/delete_modal_image.svg';

const ModalRemoveLocation = ({ visible, onClose, id }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleRemoveLocation = async (id) => {
    setIsLoading(true);
    await removeLocation(id, dispatch);
    onClose();
    setIsLoading(false);
  };

  return (
    <ModalConfirm
      visible={visible}
      onClose={onClose}
      title={'¿Seguro que desea eliminar esta ubicación?'}
      image={DeleteModalImage}
      actionsContent={
        <div className='actions-delete-modal mt-2'>
          <Button
            className='button secondary-button mr-2'
            onClick={onClose}
            disabled={isLoading}
          >
            Cancelar
          </Button>
          <Button
            className='button ml-1'
            type='primary'
            loading={isLoading}
            onClick={() => handleRemoveLocation(id)}
          >
            Eliminar
          </Button>
        </div>
      }
    />
  );
};

export default ModalRemoveLocation;
