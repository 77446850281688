import { useDispatch } from 'react-redux';
import { getZonesWithDistricts } from '../../../../../services/zones';
import {
  CONFLICT_CHANGE_FILTERS,
  FILTER_ROAD_EMERGENCY,
  CHANGE_MAP_FILTERS_EMERGENCY,
  CHANGE_SISMO_FILTERS,
  TSUNAMIS_UPDATE_FILTERS,
  GEOLOGICAL_HAZARDS_UPDATE_FILTERS,
} from '../../../../../redux/actions/actionsType';

export const useDispatchActions = () => {
  const dispatch = useDispatch();

  const resetAllFilters = () => {
    dispatch({ type: CONFLICT_CHANGE_FILTERS, payload: {} });
    dispatch({ type: FILTER_ROAD_EMERGENCY, payload: {} });
    dispatch({ type: CHANGE_MAP_FILTERS_EMERGENCY, payload: {} });
    dispatch({ type: CHANGE_SISMO_FILTERS, payload: {} });
    dispatch({ type: GEOLOGICAL_HAZARDS_UPDATE_FILTERS, payload: {} });
  };

  const setSocialFilters = (filters) =>
    dispatch({ type: CONFLICT_CHANGE_FILTERS, payload: filters });

  const setRoadFilters = (filters) =>
    dispatch({ type: FILTER_ROAD_EMERGENCY, payload: filters });

  const setFiremenFilters = (filters) => {
    const { date, ...rest } = filters;
    dispatch({
      type: CHANGE_MAP_FILTERS_EMERGENCY,
      payload: { ...date, ...rest },
    });
  };

  const setSismoFilters = (filters) =>
    dispatch({ type: CHANGE_SISMO_FILTERS, payload: filters });

  const setTsunamiFilters = (filters) => {
    let payload;
    if (Object.keys(filters).length === 0) {
      payload = {};
    } else {
      payload = {
        magnitude: filters?.magnitude || [6, 9],
        depth: filters?.depth || [0, 150],
      };
      if (Object.keys(filters?.date || {}).length > 0) {
        payload.date = filters.date;
      }
    }
    dispatch({
      type: TSUNAMIS_UPDATE_FILTERS,
      payload,
    });
  };

  const setGeologicalHazardsFilters = (filters) =>
    dispatch({ type: GEOLOGICAL_HAZARDS_UPDATE_FILTERS, payload: filters });

  const getZones = () => getZonesWithDistricts(dispatch);

  return {
    resetAllFilters,
    setSocialFilters,
    setRoadFilters,
    setFiremenFilters,
    setSismoFilters,
    setTsunamiFilters,
    setGeologicalHazardsFilters,
    getZones,
  };
};
