import React, { useState } from 'react';
import { rateArticles } from '../../../services/articles';
import FeedBack from '../components/Feedback';

const FeedbackButton = ({ article }) => {
  const [showFeedBack, setShowFeedback] = useState(false);

  const sendFeedback = (rate, message) => {
    if (article && article.id && rate) {
      rateArticles({
        id: article.id,
        rate,
        message,
      });
    }
  };

  return (
    <>
      <button
        className='suggest-evaluation'
        onClick={() => setShowFeedback(true)}
      >
        <img
          className='suggest-evaluation__img'
          src='/images/portal/article_evaluation.svg'
          alt='Evaluación del artículo'
        />
        <div className='suggest-evaluation__txt'>
          ¡Hola! ¿Te pareció útil este contenido?
        </div>
      </button>
      <FeedBack
        visible={showFeedBack}
        onClose={() => setShowFeedback(false)}
        onOk={sendFeedback}
      />
    </>
  );
};

export default FeedbackButton;
