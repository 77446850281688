import React, { useEffect, useState } from 'react';
import { Card, message, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { RideSpinner, RideButton } from '@rimac-seguros/ride-system-components';

import FormReset from './FormReset';
import { getInvitedUser, resetPassword } from '../../services/register';

const { Title, Text } = Typography;

const Recovery = () => {
  const { code } = useParams();
  const [isloading, setIsloading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [isValidCode, setIsValidCode] = useState(false);
  const [isValidCodeLoading, setIsValidCodeLoading] = useState(false);

  const handlerResetPW = async (value) => {
    const data = { ...value, code };
    setIsloading(true);
    const response = await resetPassword(data);
    if (response && response.data && response.data.success) setIsChanged(true);
    else message.error('Ha ocurrido un error.');

    setIsloading(false);
  };

  const boxReset = (
    <Card className='recovery-password-form' bordered={false}>
      <Title level={3}>Cambia tu contraseña</Title>
      <Text>
        Por favor, ingresa tu nueva contraseña. Recuerda que debe tener al menos
        8 caracteres, una letra mayúscula, una minúscula, un número y un
        carácter especial.
      </Text>
      <FormReset onOk={handlerResetPW} loading={isloading} />
    </Card>
  );

  useEffect(async () => {
    if (!isValidCode) {
      setIsValidCodeLoading(true);
      const exist = await getInvitedUser(code);
      if (exist?.data?.success && exist?.data?.user) setIsValidCode(true);
      else window.location.assign('/');

      setIsValidCodeLoading(false);
    }
  }, []);

  const boxReseted = (
    <Card className='recovery-password-form' bordered={false}>
      <Title level={3}>¡Listo!</Title>
      <Title level={4}>¡Tu contraseña fue cambiada con éxito!</Title>
      <Text>Ahora puedes ingresar a tu cuenta con tu nueva contraseña.</Text>
      <RideButton
        variat='fill'
        href='/'
        text='Ir al login'
        size='large'
        className='mt-2'
      />
    </Card>
  );

  return isValidCodeLoading ? (
    <div className='loading-content-recovery-password'>
      <RideSpinner size='large' />
    </div>
  ) : (
    <main className='recovery-password-content'>
      <section className='recovery-password-form-container'>
        <div className='img'></div>
        {isChanged ? boxReseted : boxReset}
      </section>
    </main>
  );
};

export default Recovery;
