import * as React from 'react';

const IconHome = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={16}
    height={16}
    fill='none'
    {...props}
  >
    <path
      d='M13.961 6.33 8.847 1.917a1.266 1.266 0 0 0-1.676 0L2.039 6.33a1.276 1.276 0 0 0-.359 1.403c.188.49.667.812 1.197.812v3.787c0 .693.565 1.268 1.283 1.268h7.68c.701 0 1.283-.558 1.283-1.268V8.545c.53 0 1.01-.321 1.197-.812.188-.49.034-1.048-.359-1.403Zm-4.687 6.019H6.71V9.813c0-.693.564-1.268 1.283-1.268.718 0 1.282.558 1.282 1.268v2.536Z'
      fill='#A9AFD9'
    />
  </svg>
);

export default IconHome;
