import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { Skeleton } from 'antd';

import Topbar from '../../../common/ui/Topbar';
import HeaderBack from '../../../common/ui/HeaderBack';
import Chat from './Chat';
import Attachments from '../../../common/Attachment';
import Tabs from '../../../common/Tabs';

import {
  getTicket,
  listComments,
  createComment,
} from '../../../services/helpCenter';

const SupportChat = ({ incidentId, title, onBack }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => ({
    user: state.auth.user,
  }));
  const navigate = useNavigate();
  const { id } = useParams();
  const [isUploading, setIsUploading] = useState(false);
  const [, setErrorFile] = useState(false);
  const [message, setMessage] = useState('');
  const [supportTicket, setSupportTicket] = useState(false);
  const [tabActive, setTabActive] = useState('chat');
  const [comments, setComments] = useState({
    isLoading: false,
    isLoadingCreate: false,
    list: [],
  });
  const [isSendMessage, setIsSendMessage] = useState(false);
  const idSelect = incidentId || id;

  const loadTicket = (id) => getTicket(id, dispatch);
  const loadListComments = (id) => listComments(id, dispatch);

  const tagState = (status) => {
    return {
      1: { color: 'blue', status: 'Nuevo' },
      2: { color: 'gray', status: 'Cerrado' },
      3: { color: 'yellow', status: 'En proceso' },
    }[status];
  };

  const loadTicketAndComments = async (idSelect) => {
    setComments((prevComments) => ({ ...prevComments, isLoading: true }));
    const ticket = await loadTicket(idSelect);
    if (!ticket) {
      handleNavigation();
      return;
    }
    setSupportTicket(ticket);
    const list = await loadListComments(idSelect);
    setComments((prevComments) => ({
      ...prevComments,
      isLoading: false,
      list,
    }));
  };

  const handleNavigation = () => {
    if (onBack) {
      onBack();
    } else {
      navigate('/soporte/centro-de-ayuda');
    }
  };

  useEffect(() => {
    if (idSelect) {
      loadTicketAndComments(idSelect);
    } else {
      handleNavigation();
    }
  }, [id, incidentId]);

  const handleFile = async (file) => {
    return new Promise((resolve, reject) => {
      setErrorFile(false);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const beforeUpload = (file) => {
    if (file.size > 10 * 1024 * 1024) {
      setErrorFile(true);
    } else {
      setIsUploading(true);
      setComments({
        ...comments,
        isLoadingCreate: true,
      });
      handleFile(file)
        .then((base64) => {
          handleSendMessage({
            name: file.name,
            base64,
            type: file.type,
          });
        })
        .finally(() => {
          setIsUploading(false);
        });
    }

    return false;
  };

  const handleSendMessage = async (file_uploaded = false) => {
    setIsSendMessage(true);

    try {
      await createComment({
        message,
        file_uploaded,
        support_ticket_id: incidentId || id,
      });
      setMessage('');
      const list = await loadListComments(idSelect);
      setComments((prevComments) => ({
        ...prevComments,
        isLoadingCreate: false,
        list,
      }));
      setIsSendMessage(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handlerDownloadFile = (file) => {
    window.open(`${process.env.REACT_APP_WEB_PATH_S3_NEW}${file}`, '_blank');
  };

  const tabItems = [
    {
      label: 'Mensajes',
      key: 'chat',
      children: !comments.isLoading ? (
        <Chat
          comments={comments}
          user={user}
          handlerDownloadFile={handlerDownloadFile}
          beforeUpload={beforeUpload}
          isUploading={isUploading}
          message={message}
          setMessage={setMessage}
          handleSendMessage={handleSendMessage}
          isSendMessage={isSendMessage}
        />
      ) : (
        <Skeleton
          className='d_content_chat__history--skeleton  mb-2'
          paragraph={{ rows: 1 }}
          title={false}
          active
        />
      ),
    },
    {
      label: 'Adjuntos',
      key: 'attachment',
      children: !comments.isLoading ? (
        <Attachments
          comments={comments.list}
          user={user}
          info={supportTicket}
          handlerDownloadFile={handlerDownloadFile}
        />
      ) : (
        <Skeleton
          className='d_content_chat__history--skeleton  mb-2'
          paragraph={{ rows: 1 }}
          title={false}
          active
        />
      ),
    },
  ];

  return (
    <div id='checkSize' className='h100vh dashboard-UI'>
      <Topbar />

      <div className='d_content'>
        <HeaderBack
          title={title ?? 'Centro de Ayuda'}
          onBack={() => navigate('/soporte/centro-de-ayuda')}
        />

        <div className='d_content_chat pt-4'>
          {supportTicket ? (
            <section className='header-description'>
              <div className='d_content_chat__info'>
                <div className='status'>
                  <div className='d_content_chat__info__title mb-1'>ESTADO</div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '0.5rem',
                    }}
                  >
                    <span
                      style={{
                        backgroundColor: tagState(supportTicket?.status)?.color,
                        width: '10px',
                        height: '10px',
                        borderRadius: '50%',
                      }}
                    ></span>
                    {tagState(supportTicket?.status)?.status}
                  </div>
                </div>
                <div className='request'>
                  <div className='d_content_chat__info__title mb-1'>
                    SOLICITUD
                  </div>
                  <div className='table__title'>{supportTicket.subject}</div>
                  <div className='table__subtitle'>
                    {moment(supportTicket.createdAt).format(
                      'DD MMM YYYY - HH:mm',
                    )}
                  </div>
                </div>
                <div>
                  <div className='d_content_chat__info__title mb-1'>
                    USUARIO
                  </div>
                  <div className='table__title'>{`${supportTicket?.user?.name} ${supportTicket?.user?.last_name1}`}</div>
                  <div className='table__subtitle'>
                    {supportTicket?.user?.company?.name}
                  </div>
                </div>
              </div>

              <div className='description-con'>
                <div className='line'></div>
                <h4>Descripción</h4>
                <p>{supportTicket.message}</p>
              </div>
            </section>
          ) : (
            <Skeleton
              className='d_content_chat__info--skeleton  mb-4'
              paragraph={{ rows: 1 }}
              title={false}
              active
            />
          )}

          <div className='d_content_tab'>
            <Tabs
              activeKey={tabActive}
              onChange={(activeKey) => setTabActive(activeKey)}
              items={tabItems}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportChat;
