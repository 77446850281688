import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { PlusOutlined, ArrowLeftOutlined } from '@ant-design/icons';

import { getMyRoutes } from '../../../../services/myRoutes';

import SkeletonLayerList from '../components/SkeletonLayerList';
import FormRouteByGoogle from './FormRouteByGoogle';
import ListRoutes from './ListRoutes';

const DrawerMyRoutes = ({ isMobile, controlMapDrawerVisible }) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const { isLoadingList } = useSelector((state) => state.myRoutes);

  useEffect(() => {
    if (step === 1) getMyRoutes(dispatch);
  }, [step]);

  return (
    <div className='drawer-map-layers' style={{ paddingTop: 0 }}>
      <div className='drawer-map-layers__list'>
        {step === 1 ? (
          <Button type='link' onClick={() => setStep((prev) => prev + 1)}>
            <PlusOutlined />
            Agregar nueva ruta
          </Button>
        ) : (
          <Button type='link' onClick={() => setStep((prev) => prev - 1)}>
            <ArrowLeftOutlined />
            Regresar
          </Button>
        )}
      </div>
      {step === 1 &&
        (isLoadingList ? (
          <SkeletonLayerList />
        ) : (
          <ListRoutes
            isMobile={isMobile}
            controlMapDrawerVisible={controlMapDrawerVisible}
            setStep={setStep}
          />
        ))}
      {step === 2 && <FormRouteByGoogle setStep={setStep} />}
    </div>
  );
};

export default DrawerMyRoutes;
