export const ringsCreate = (point) => {
  let result = null;
  const flashConfig = point.forecastConfigs?.find(
    (fc) => fc.type === 'flash' && fc.state === 1,
  );

  if (flashConfig) {
    let { lat, lng } = point;
    if (lat && lng) {
      const rings = JSON.parse(flashConfig.value).rings;

      result = rings.reverse().map((ring, index) => ({
        id: point.id,
        center: [lat, lng],
        radius: ring * 1000,
        pathOptions: {
          color: index === 0 ? '#018f39' : index === 1 ? '#ffb600' : '#e5001b',
          fillColor: '#e5001b00',
          weight: 1.5,
        },
      }));
    }
  }

  return result;
};
