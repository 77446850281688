import React, { useRef, useCallback, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';

import Select from '../../../../../common/Select';
import Input from '../../../../../common/Input';
import RadioButton from '../../../../../common/RadioButton';
import Button from '../../../../../common/Button';
import openNotification from '../../../../../common/Notification';

import * as Yup from 'yup';

import { getAllBrokers } from '../../../../../services/brokers';
import { getAllBusinessSegmentNew } from '../../../../../services/businessSegment';
import { getTypeIndustryNew } from '../../../../../services/typeIndustry';
import {
  getCompanyByRuc,
  getCompanyByUuid,
  createCompanyNew,
  updateCompanyNew,
} from '../../../../../services/company';

import { debounce } from '../../../../../lib/utils';

const initialValues = {
  ruc: '',
  giro: '',
  business_name: '',
  name: '',
  economic_group: '',
  businessSegmentId: null,
  typeIndustryId: null,
  brokerId: null,
  visible_in_reports: null,
  state: null,
};

const validationSchema = Yup.object().shape({
  ruc: Yup.string()
    .matches(/^[0-9]*$/, 'El RUC debe ser un número')
    .required('El RUC es requerido')
    .min(11, 'El RUC debe tener 11 caracteres.')
    .max(11, 'El RUC debe tener 11 caracteres.')
    .test('RUC exists', 'El RUC ya existe', async (value) => {
      if (!value || value.length !== 11) {
        return true;
      }
      try {
        const response = await getCompanyByRuc(value);
        return !response.success;
      } catch (error) {
        return false;
      }
    }),
  giro: Yup.string()
    .nullable()
    .test(
      'min',
      'El giro debe tener al menos 3 caracteres.',
      (value) => !value || value.length >= 3,
    )
    .test(
      'max',
      'El giro no debe tener más de 50 caracteres.',
      (value) => !value || value.length <= 50,
    ),
  business_name: Yup.string()
    .required('El nombre comercial es requerido')
    .min(3, 'El nombre comercial debe tener al menos 3 caracteres.')
    .max(50, 'El nombre comercial no debe tener más de 50 caracteres.'),
  name: Yup.string()
    .required('El nombre es requerido')
    .min(3, 'El nombre debe tener al menos 3 caracteres.')
    .max(50, 'El nombre no debe tener más de 50 caracteres.'),
  economic_group: Yup.string()
    .nullable()
    .test(
      'min',
      'El grupo económico debe tener al menos 3 caracteres.',
      (value) => !value || value.length >= 3,
    )
    .test(
      'max',
      'El grupo económico no debe tener más de 50 caracteres.',
      (value) => !value || value.length <= 50,
    ),
  businessSegmentId: Yup.number().required(
    'El segmento de negocio es requerido',
  ),
  typeIndustryId: Yup.number().required('El tipo de industria es requerido'),
  brokerId: Yup.number().required('El broker es requerido'),
  visible_in_reports: Yup.string().required('Visible en reportes es requerido'),
  state: Yup.string().required('Estado es requerido'),
});

const FormBusiness = () => {
  const formRef = useRef();
  const navigate = useNavigate();
  const { uuid } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const [loadingCompany, setLoadingCompany] = useState(false);

  const [brokers, setBrokers] = useState([]);
  const [queryBrokers, setQueryBrokers] = useState('');
  const [loadingBrokers, setLoadingBrokers] = useState(false);
  const [filteredBrokers, setFilteredBrokers] = useState([]);

  const [businessSegments, setBusinessSegments] = useState([]);
  const [loadingBusinessSegments, setLoadingBusinessSegments] = useState(false);

  const [typeIndustries, setTypeIndustries] = useState([]);
  const [loadingTypeIndustries, setLoadingTypeIndustries] = useState(false);

  const fetchBrokers = useCallback(async () => {
    setLoadingBrokers(true);
    try {
      const response = await getAllBrokers();
      setBrokers(response);
    } catch (error) {
      openNotification('error', 'Error', 'Error al obtener los brokers');
    } finally {
      setLoadingBrokers(false);
    }
  }, []);

  const debouncedSearchBrokers = useCallback(
    debounce((query) => {
      setQueryBrokers(query);
    }, 300),
    [],
  );

  const filterBrokers = useCallback(() => {
    if (queryBrokers) {
      const filtered = brokers.filter((broker) =>
        broker.name.toLowerCase().includes(queryBrokers.toLowerCase()),
      );
      setFilteredBrokers(filtered);
    } else {
      setFilteredBrokers(brokers);
    }
  }, [queryBrokers, brokers]);

  const fetchBusinessSegments = useCallback(async () => {
    setLoadingBusinessSegments(true);
    try {
      const response = await getAllBusinessSegmentNew();
      setBusinessSegments(response);
    } catch (error) {
      openNotification(
        'error',
        'Error',
        'Error al obtener los segmentos de negocio',
      );
    } finally {
      setLoadingBusinessSegments(false);
    }
  }, []);

  const fetchTypeIndustries = useCallback(async () => {
    setLoadingTypeIndustries(true);
    try {
      const response = await getTypeIndustryNew();
      setTypeIndustries(response);
    } catch (error) {
      openNotification(
        'error',
        'Error',
        'Error al obtener los tipos de industria',
      );
    } finally {
      setLoadingTypeIndustries(false);
    }
  }, []);

  const fetchCompany = useCallback(async () => {
    if (uuid) {
      setLoadingCompany(true);
      try {
        const response = await getCompanyByUuid(uuid);
        const company = response.company;
        fields.forEach((field) => {
          let value = company[field];
          if (typeof value === 'string') {
            value = value.trim();
            value = value.toLowerCase();
            value = value.charAt(0).toUpperCase() + value.slice(1);
          }
          setValue(field, value);
        });
      } catch (error) {
        openNotification('error', 'Error', 'Error al obtener la empresa');
      } finally {
        setLoadingCompany(false);
      }
    } else {
      fields.forEach((field) => setValue(field, ''));
    }
  }, [uuid, setValue]);

  useEffect(() => {
    fetchBrokers();
    fetchBusinessSegments();
    fetchTypeIndustries();
    fetchCompany();
  }, [fetchBrokers, fetchBusinessSegments, fetchTypeIndustries, fetchCompany]);

  useEffect(() => {
    filterBrokers();
  }, [filterBrokers]);

  const fields = [
    'ruc',
    'giro',
    'business_name',
    'name',
    'economic_group',
    'businessSegmentId',
    'typeIndustryId',
    'brokerId',
    'visible_in_reports',
    'state',
  ];

  fields.forEach((field) => register(field));

  const onSubmit = (data) => {
    if (uuid) {
      updateCompanyNew(uuid, data)
        .then((response) => {
          if (response.success) {
            openNotification('success', 'Éxito', 'Empresa actualizada');
            navigate('/administration/companies');
          } else {
            openNotification(
              'error',
              'Error',
              'Error al actualizar la empresa',
            );
          }
        })
        .catch(() => {
          openNotification('error', 'Error', 'Error al actualizar la empresa');
        });
    } else {
      createCompanyNew(data)
        .then((response) => {
          if (response.success) {
            openNotification('success', 'Éxito', 'Empresa creada');
            navigate('/administration/companies');
          } else {
            openNotification('error', 'Error', 'Error al crear la empresa');
          }
        })
        .catch(() => {
          openNotification('error', 'Error', 'Error al crear la empresa');
        });
    }
  };

  return (
    <div
      className='d_content'
      style={{ background: '#EDEFFC', padding: '64px 96px' }}
    >
      <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
        <Input
          label='RUC'
          name='ruc'
          status={errors.ruc ? 'error' : 'success'}
          onChange={(e) => setValue('ruc', e.target.value)}
          defaultValue={initialValues.ruc}
          error={errors.ruc}
          errorMessage={errors.ruc?.message}
          value={watch('ruc')}
          required={false}
        />
        <Input
          label='Giro Predominante'
          name='giro'
          status={errors.giro ? 'error' : 'success'}
          onChange={(e) => setValue('giro', e.target.value)}
          defaultValue={initialValues.giro}
          error={errors.giro}
          errorMessage={errors.giro?.message}
          value={watch('giro')}
        />
        <Input
          label='Nombre o Razón Social'
          name='business_name'
          status={errors.business_name ? 'error' : 'success'}
          onChange={(e) => setValue('business_name', e.target.value)}
          defaultValue={initialValues.business_name}
          error={errors.business_name}
          errorMessage={errors.business_name?.message}
          value={watch('business_name')}
        />
        <Input
          label='Nombre Comercial'
          name='name'
          status={errors.name ? 'error' : 'success'}
          onChange={(e) => setValue('name', e.target.value)}
          defaultValue={initialValues.name}
          error={errors.name}
          errorMessage={errors.name?.message}
          value={watch('name')}
        />
        <Input
          label='Grupo Económico'
          name='economic_group'
          status={errors.economic_group ? 'error' : 'success'}
          onChange={(e) => setValue('economic_group', e.target.value)}
          defaultValue={initialValues.economic_group}
          error={errors.economic_group}
          errorMessage={errors.economic_group?.message}
          value={watch('economic_group')}
        />
        <Select
          label='Segmento Comercial'
          name='businessSegmentId'
          status={errors.businessSegmentId ? 'error' : 'success'}
          loading={loadingBusinessSegments}
          filterOption={false}
          value={watch('businessSegmentId')}
          error={errors.businessSegmentId}
          defaultValue={initialValues.businessSegmentId}
          onChange={(value) => setValue('businessSegmentId', value)}
          options={businessSegments.map((segment) => ({
            value: segment.id,
            label: segment.name,
          }))}
        />
        <Select
          label='Tipo de Industria'
          name='typeIndustryId'
          status={errors.typeIndustryId ? 'error' : 'success'}
          loading={loadingTypeIndustries}
          filterOption={false}
          value={watch('typeIndustryId')}
          defaultValue={initialValues.typeIndustryId}
          onChange={(value) => setValue('typeIndustryId', value)}
          options={typeIndustries.map((industry) => ({
            value: industry.id,
            label: industry.name,
          }))}
        />
        {errors.typeIndustryId && (
          <div style={{ color: 'red' }}>{errors.typeIndustryId.message}</div>
        )}
        <Select
          label='Corredor'
          showSearch
          name='brokerId'
          status={errors.brokerId ? 'error' : 'success'}
          loading={loadingBrokers}
          filterOption={false}
          value={watch('brokerId')}
          defaultValue={initialValues.brokerId}
          onSearch={debouncedSearchBrokers}
          onChange={(value) => setValue('brokerId', value)}
          options={filteredBrokers.map((broker) => ({
            value: broker.id,
            label: broker.name,
          }))}
        />
        {errors.brokerId && (
          <div style={{ color: 'red' }}>{errors.brokerId.message}</div>
        )}
        <RadioButton
          name='visible_in_reports'
          label='¿Visible en los reportes?'
          onChange={(e) =>
            setValue('visible_in_reports', String(e.target.value))
          }
          value={watch('visible_in_reports')}
          options={[
            { value: '1', label: 'Sí' },
            { value: '0', label: 'No' },
          ]}
        />
        {errors.visible_in_reports && (
          <div style={{ color: 'red' }}>
            {errors.visible_in_reports.message}
          </div>
        )}
        <RadioButton
          name='state'
          label='Estado'
          onChange={(e) => setValue('state', String(e.target.value))}
          value={watch('state')}
          options={[
            { value: '1', label: 'Activo' },
            { value: '2', label: 'Inactivo' },
          ]}
        />
        {errors.state && (
          <div style={{ color: 'red' }}>{errors.state.message}</div>
        )}
        <Button
          type='primary'
          onClick={() => formRef.current && formRef.current.requestSubmit()}
        >
          Guardar
        </Button>
      </form>
    </div>
  );
};

export default FormBusiness;
