import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card, Col, notification, Skeleton } from 'antd';

import { getEventType } from '../../../../../../services/eventType';
import {
  getDataDashboard,
  reportDashboardCompany,
  reportDashboardUsers,
} from '../../../../../../services/dashboard';
import { useScreen } from '../../../../../../lib/screen';

import Title from '../../../../../../common/Typograph/Title';
import BasicWidget from './BasicWidget';
import DonutWidget from './DonutWidget';
import {
  RideButton,
  RideGlDownloadOutline,
} from '@rimac-seguros/ride-system-components';

const Dashboards = ({ setUsers, dates }) => {
  const { isMobileScreen } = useScreen();
  const dispatch = useDispatch();
  const { filePathUsers, isLoadingUsers } = useSelector(
    (state) => state.dashboard,
  );
  const [loading, setLoading] = useState(true);
  const [dataFiltered, setDataFiltered] = useState({});

  const getDataFiltered = useCallback((dates) => {
    setLoading(true);
    getDataDashboard(dates)
      .then((stats) => {
        setDataFiltered(stats);
        setUsers(stats ? stats['usersChar'] ?? [] : []);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (filePathUsers) {
      notification.success({
        message: 'Reporte generado correctamente.',
      });
      window.open(`${filePathUsers}`, '_blank');
    }
  }, [filePathUsers]);

  useEffect(() => {
    getEventType(dispatch);
    getDataFiltered(dates);
  }, [dates]);

  return (
    <>
      <div {...(!isMobileScreen ? { className: 'flex' } : {})}>
        <Col
          key={'key-1'}
          {...(!isMobileScreen
            ? { className: 'pr-2', span: 8, style: { flex: 1 } }
            : { className: 'pb-3' })}
        >
          <BasicWidget
            loading={loading}
            data={dataFiltered?.users ?? {}}
            details={[
              {
                key: 'actives-users',
                title: 'Activos',
                type: 'success',
                renderValue: (data) =>
                  (data?.total ?? 0) - (data?.total_disabled ?? 0),
              },
              {
                key: 'disabled-users',
                title: 'Inactivos',
                type: 'default',
                renderValue: (data) => data?.total_disabled ?? 0,
              },
            ]}
            title={'Usuarios'}
            available={dataFiltered?.users?.total_disabled}
            action={
              <RideButton
                className='mt-4'
                variant='text-primary'
                disabled={isLoadingUsers}
                onClick={() => {
                  reportDashboardUsers(dispatch);
                }}
                glyph={<RideGlDownloadOutline />}
                text='Descargar'
                glyphPosition='left'
              />
            }
          />
        </Col>
        <Col
          {...(!isMobileScreen
            ? { className: 'pl-1 pr-1', span: 8, style: { flex: 1 } }
            : { className: 'pb-3' })}
        >
          <BasicWidget
            loading={loading}
            data={dataFiltered?.companies ?? {}}
            aditional={dataFiltered?.companies?.withActiveProgram}
            available={
              dataFiltered?.companies?.total -
              dataFiltered?.companies?.withActiveProgram -
              dataFiltered?.companies?.total_active
            }
            details={[
              {
                key: 'actives-companies',
                title: 'Activas',
                type: 'success',
                renderValue: (data) => data?.total_active ?? 0,
              },
              {
                key: 'disabled-companies',
                title: 'Sin pólizas vigentes',
                type: 'default',
                renderValue: (data) =>
                  data?.total - data?.total_active - data?.withActiveProgram ??
                  0,
              },
              {
                key: 'polizas-actives',
                title: 'Póliza vigente',
                type: 'percent',
                renderValue: (data) => data?.withActiveProgram ?? 0,
              },
            ]}
            title={'Empresas'}
            action={
              <RideButton
                className='mt-4'
                variant='text-primary'
                disabled={isLoadingUsers}
                onClick={() => {
                  reportDashboardCompany(dispatch);
                }}
                glyph={<RideGlDownloadOutline />}
                text='Descargar'
                glyphPosition='left'
              />
            }
          />
        </Col>
        <Col
          {...(!isMobileScreen
            ? { span: 8, className: 'pl-2', style: { flex: 1 } }
            : { className: 'pb-3' })}
        >
          <BasicWidget
            loading={loading}
            data={dataFiltered?.supportTicket ?? {}}
            available={dataFiltered?.supportTicket?.closed}
            details={[
              {
                key: 'actives-tickets',
                title: 'Abiertos',
                type: 'success',
                renderValue: (data) => (data?.total ?? 0) - (data?.closed ?? 0),
              },
              {
                key: 'disabled-tickets',
                type: 'default',
                title: 'Cerrados',
                renderValue: (data) => data?.closed ?? 0,
              },
            ]}
            title={'Tickets'}
          />
        </Col>
      </div>
      <div className='flex mt-2'>
        <Col
          {...(!isMobileScreen
            ? { className: 'pr-1', span: 16 }
            : { className: 'pb-3', span: 24 })}
        >
          <DonutWidget
            title='Boletines enviados'
            total={dataFiltered?.newsletters?.total}
            loading={loading}
            data={{
              'Largo plazo': dataFiltered?.newsletters?.largo_plazo_total,
              'Corto plazo': dataFiltered?.newsletters?.corto_plazo_total,
              Geológico: dataFiltered?.newsletters?.geologico_total,
              Alerta: dataFiltered?.newsletters?.alerta_total,
            }}
          />
        </Col>
        <Col
          {...(!isMobileScreen
            ? { className: 'pl-2', span: 8 }
            : { className: 'pb-3', span: 24 })}
        >
          <BasicWidget
            loading={loading}
            type='line'
            data={dataFiltered?.requestServiceItems ?? {}}
            aditional={dataFiltered?.requestServiceItems?.finished}
            details={[
              {
                key: 'actives-tickets',
                title: 'Finalizados',
                type: 'percent',
                renderValue: (data) => data?.finished,
              },
              {
                key: 'whithout-finish',
                type: 'default',
                title: 'Sin finalizar',
                renderValue: (data) => data?.total - data?.finished,
              },
            ]}
            title={'Servicios'}
          />
        </Col>
      </div>
      <div className={'flex mt-2'}>
        <Col
          {...(!isMobileScreen
            ? { className: 'pr-2', span: 12 }
            : { className: 'pb-3', span: 24 })}
        >
          {loading ? (
            <Card>
              <Skeleton />
            </Card>
          ) : (
            <Card style={{ height: '100%' }}>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <img alt='bell' src={'/images/portal/icon_alarm.svg'} />
                <div className='felx flex-col ml-1'>
                  <Title type={'bold-20'}>Notificaciones personalizadas</Title>
                  <Title type='bold-28'>
                    {dataFiltered?.customNotifications?.total}
                  </Title>
                </div>
              </div>
            </Card>
          )}
        </Col>
        <Col
          {...(!isMobileScreen
            ? { className: 'pl-1', span: 12 }
            : { className: 'pb-3', span: 24 })}
        >
          {loading ? (
            <Card>
              <Skeleton />
            </Card>
          ) : (
            <Card style={{ height: '100%' }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                }}
              >
                <img alt='bell' src={'/images/portal/icon_simulations.svg'} />
                <div className='flex flex-col ml-1' style={{ flex: 1 }}>
                  <Title type={'bold-20'}>Simulaciones realizadas</Title>
                  <Title type='bold-28'>
                    {dataFiltered?.simulations?.total}
                  </Title>
                </div>
              </div>
            </Card>
          )}
        </Col>
      </div>
    </>
  );
};

export default memo(Dashboards);
