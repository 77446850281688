import React from 'react';

const IconPerson = () => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.8637 15.0109C16.8637 16.0496 16.0286 16.8847 14.9899 16.8847H4.98993C3.95124 16.8847 3.11621 16.0496 3.11621 15.0109C3.11621 12.2411 4.7659 9.87856 7.10806 8.77877C7.88199 9.4305 8.87996 9.83783 9.97975 9.83783C11.0795 9.83783 12.0775 9.4305 12.8514 8.77877C15.2343 9.8582 16.8637 12.2411 16.8637 15.0109ZM13.1162 5.54048C13.1162 3.87042 11.7109 2.50586 10.0001 2.50586C8.26896 2.50586 6.88403 3.87042 6.88403 5.54048C6.88403 7.21054 8.28933 8.5751 10.0001 8.5751C11.7109 8.5751 13.1162 7.23091 13.1162 5.54048Z'
        fill='#141938'
      />
    </svg>
  );
};

export default IconPerson;
