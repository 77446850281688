import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import { Button, Card, Skeleton } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import { getConflictsHistory } from '../../../../../services/conflict';
import SimpleTable from '../../../../../common/Table/SimpleTable';
import { UPDATE_PAGINATION_HISTORY_CONFLICTS } from '../../../../../redux/actions/actionsType';
import { useScreen } from '../../../../../lib/screen';
import CardHistoryMobile from '../../../Cumulo/CardHistoryMobile';
import PaginationMobile from '../../../../../common/PaginationMobile';

moment.locale('es');

const HISTORY_COLUMNS = [
  {
    dataIndex: 'name',
    key: 'name',
    title: 'NOMBRE DE ACTUALIZACIÓN',
  },
  {
    dataIndex: 'user',
    key: 'user',
    title: 'USUARIO CREADOR',
    render: (user) => `${user?.name} ${user?.last_name1}`,
  },
  {
    dataIndex: 'createdAt',
    key: 'createdAt',
    title: 'FECHA DE SUBIDA',
    render: (createdAt) => moment(createdAt).format('DD/MM/YYYY'),
  },
  {
    dataIndex: 'url',
    key: 'url',
    title: 'LINK DE DESCARGA',
    render: (url) => (
      <Button
        style={{ textAlign: 'start', border: 'none', borderRadius: 0 }}
        type='link'
        className='link'
        icon={<DownloadOutlined />}
        onClick={() => {
          window.open(
            `${process.env.REACT_APP_WEB_PATH_S3_NEW}my_cumulus/${
              url.split('/')[url.split('/').length - 1]
            }`,
            '_blank',
          );
        }}
      >
        Descargar
      </Button>
    ),
  },
];

const Register = () => {
  const { isMobileScreen } = useScreen();
  const dispatch = useDispatch();
  const { historyPagination, historyData, isLoadingHistory } = useSelector(
    (state) => ({
      historyPagination: state.conflict.paginationHistory,
      historyData: state.conflict.listHistory,
      isLoadingHistory: state.conflict.loadingHistory,
    }),
  );

  const _handleGetHistoryList = useCallback((page) => {
    dispatch({
      type: UPDATE_PAGINATION_HISTORY_CONFLICTS,
      payload: { current: page },
    });
    getConflictsHistory({ limit: historyPagination.limit, page }, dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getConflictsHistory(historyPagination, dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div>
        {historyPagination?.total === 1 ? (
          <>
            Se encontró <b>1</b> registro
          </>
        ) : (
          <>
            Se encontraron <b>{historyPagination?.total}</b> registros
          </>
        )}
      </div>
      {!isMobileScreen ? (
        <SimpleTable
          key='sc-history-table-common'
          className={'mt-4'}
          loading={isLoadingHistory}
          rowKey={({ id }) => id}
          columns={HISTORY_COLUMNS}
          dataSource={historyData}
          pagination={{
            ...historyPagination,
            onChange: _handleGetHistoryList,
          }}
        />
      ) : isLoadingHistory ? (
        [1, 2, 3].map((number) => (
          <Card className='d_content__card' key={number}>
            <Skeleton className='rounded-2xl' />
          </Card>
        ))
      ) : (
        historyData.map((item) => (
          <CardHistoryMobile key={item.id} data={item} />
        ))
      )}
      <PaginationMobile
        onChange={_handleGetHistoryList}
        style={{ marginBottom: 20 }}
        total={historyPagination?.total}
        current={historyPagination?.current}
        pageSize={historyPagination?.pageSize}
      />
    </>
  );
};

export default Register;
