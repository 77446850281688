export const LAYERS = {
  INFRA: {
    unitMeasurement: '°C',
    values: [
      [48, '#141938', '#FFF'],
      [15, '#4853A2', '#000'],
      [-5, '#7D86C5', '#000'],
      [-10, '#A9AFD9', '#000'],
      [-15, '#FF6400', '#000'],
      [-20, '#FF9300', '#000'],
      [-25, '#FFD100', '#000'],
      [-30, '#FFFF00', '#000'],
      [-35, '#93FF00', '#000'],
      [-40, '#0CBB29', '#000'],
      [-45, '#00FFFF', '#000'],
      [-50, '#64BBFC', '#000'],
      [-55, '#0000FF', '#000'],
      [-60, '#FF00FF', '#000'],
      [-65, '#B700D6', '#000'],
      [-70, '#FF5BCB', '#000'],
      [-75, '#FF5B5B', '#000'],
      [-80, '#FF0000', '#000'],
      [-85, '#810000', '#FFF'],
    ],
  },
  VISIBLE: {
    unitMeasurement: '%',
    values: [
      [0.1, '#141938', '#fff'],
      [0.5, '#7981B2', '#fff'],
      [0.9, '#6CBDFF', '#141938'],
      [1, '#EDEFFC', '#141938'],
    ],
  },
  wind: {
    unitMeasurement: 'km/h',
    values: [
      [0, '#FFFF00', '#141938'],
      [10, '#FFCC00', '#141938'],
      [20, '#FF9930', '#141938'],
      [30, '#FB6707', '#141938'],
      [40, '#FF4142', '#141938'],
      [50, '#FB0709', '#141938'],
      [65, '#C902D1', '#141938'],
      [80, '#9C01C9', '#fff'],
      [95, '#6801CC', '#fff'],
    ],
  },
  hc: {
    unitMeasurement: '%',
    values: [
      [0, '#6CBDFF', '#141938'],
      [25, '#47A3FA', '#141938'],
      [50, '#DADBF9', '#141938'],
      [75, '#AAAFD5', '#141938'],
      [100, '#F8FCFF', '#141938'],
    ],
  },
  mc: {
    unitMeasurement: '%',
    values: [
      [0, '#6CBDFF', '#141938'],
      [25, '#47A3FA', '#141938'],
      [50, '#DADBF9', '#141938'],
      [75, '#AAAFD5', '#141938'],
      [100, '#F8FCFF', '#141938'],
    ],
  },
  lc: {
    unitMeasurement: '%',
    values: [
      [0, '#6CBDFF', '#141938'],
      [25, '#47A3FA', '#141938'],
      [50, '#DADBF9', '#141938'],
      [75, '#AAAFD5', '#141938'],
      [100, '#F8FCFF', '#141938'],
    ],
  },
  waves: {
    unitMeasurement: 'm',
    values: [
      [0, '#00EBFC', '#fff'],
      [0.5, '#0CB1F3', '#fff'],
      [1, '#2378C9', '#fff'],
      [1.5, '#3230E9', '#fff'],
      [2, '#7D46AE', '#fff'],
      [6, '#A60287', '#fff'],
      [9, '#7D1468', '#fff'],
    ],
  },
  pp: {
    unitMeasurement: 'mm',
    values: [
      [0, '#5B72A3', '#fff'],
      [1.5, '#5A48FF', '#fff'],
      [2, '#558EF2', '#141938'],
      [3, '#44CCDC', '#141938'],
      [7, '#4ECDAA', '#141938'],
      [10, '#7DDE32', '#141938'],
      [30, '#E78C05', '#141938'],
    ],
  },
  pp_acc_12: {
    unitMeasurement: 'mm',
    values: [
      [0, '#5B72A3', '#fff'],
      [5, '#5A48FF', '#fff'],
      [10, '#558EF2', '#141938'],
      [20, '#44CCDC', '#141938'],
      [40, '#4ECDAA', '#141938'],
      [60, '#7DDE32', '#141938'],
      [80, '#E78C05', '#141938'],
      [100, '#e75005', '#141938'],
    ],
  },
  pp_acc_24: {
    unitMeasurement: 'mm',
    values: [
      [0, '#5B72A3', '#fff'],
      [5, '#5A48FF', '#fff'],
      [10, '#558EF2', '#141938'],
      [20, '#44CCDC', '#141938'],
      [40, '#4ECDAA', '#141938'],
      [60, '#7DDE32', '#141938'],
      [80, '#E78C05', '#141938'],
      [100, '#e75005', '#141938'],
    ],
  },
  pp_acc_72: {
    unitMeasurement: 'mm',
    values: [
      [0, '#5B72A3', '#fff'],
      [5, '#5A48FF', '#fff'],
      [10, '#558EF2', '#141938'],
      [20, '#44CCDC', '#141938'],
      [40, '#4ECDAA', '#141938'],
      [60, '#7DDE32', '#141938'],
      [80, '#E78C05', '#141938'],
      [100, '#e75005', '#141938'],
    ],
  },
  pp_acc_120: {
    unitMeasurement: 'mm',
    values: [
      [0, '#5B72A3', '#fff'],
      [5, '#5A48FF', '#fff'],
      [10, '#558EF2', '#141938'],
      [20, '#44CCDC', '#141938'],
      [40, '#4ECDAA', '#141938'],
      [60, '#7DDE32', '#141938'],
      [80, '#E78C05', '#141938'],
      [100, '#e75005', '#141938'],
    ],
  },
  hr: {
    unitMeasurement: '%',
    values: [
      [0, '#0965C5', '#fff'],
      [30, '#4C98E1', '#fff'],
      [80, '#BDD5E8', '#141938'],
      [90, '#DFDFDF', '#141938'],
      [100, '#E7E7E7', '#141938'],
    ],
  },
  temp: {
    unitMeasurement: '°C',
    values: [
      [-5, '#B1FCD9', '#141938'],
      [0, '#CEFFC8', '#141938'],
      [5, '#E5FEB1', '#141938'],
      [10, '#EFFFA3', '#141938'],
      [15, '#FFFF8F', '#141938'],
      [20, '#FDF069', '#141938'],
      [25, '#FED32F', '#fff'],
      [30, '#FEB001', '#fff'],
      [35, '#FE8F02', '#fff'],
      [40, '#FF6103', '#fff'],
    ],
  },
  'vis_horizontal+': {
    unitMeasurement: 'km',
    values: [
      [0, '#A359A4', '#fff'],
      [1.6, '#A159A3', '#fff'],
      [3.6, '#A75656', '#fff'],
      [7, '#5961A3', '#fff'],
      [9, '#3CBC49', '#fff'],
      [15, '#53A74B', '#fff'],
      [20, '#21266D', '#fff'],
    ],
  },
  flashed: {
    unitMeasurement: 'min',
    values: [
      [1, '#fe0000', '#141938'],
      [2, '#ffff01', '#141938'],
      [3, '#92d14f', '#fff'],
      [4, '#00af4f', '#fff'],
      [5, '#01b0f1', '#fff'],
    ],
  },
  ligthing_den: {
    unitMeasurement: 'Cant',
    values: [
      [1, '#FFFF00', '#141938'],
      [3, '#FFF600', '#141938'],
      [5, '#FFEC00', '#141938'],
      [7, '#FFE000', '#141938'],
      [10, '#FFC000', '#141938'],
      [15, '#FF9B00', '#141938'],
      [20, '#FF7500', '#fff'],
      [25, '#FF4C00', '#fff'],
      [30, '#F10606', '#fff'],
      [40, '#D70505', '#fff'],
      [50, '#9D0202', '#fff'],
      [60, '#800000', '#fff'],
    ],
  },
  ligthing_ene: {
    unitMeasurement: (
      <span>
        10<sup>-15</sup>J
      </span>
    ),
    values: [
      [0, '#440154', '#fff'],
      [200, '#482878', '#fff'],
      [400, '#3E4989', '#fff'],
      [600, '#31688E', '#fff'],
      [800, '#26828E', '#fff'],
      [1000, '#1F9E89', '#141938'],
      [1500, '#35B779', '#141938'],
      [2000, '#6ECE58', '#141938'],
      [2500, '#B5DE2B', '#141938'],
      [3000, '#FDE725', '#141938'],
    ],
  },
  ligthing_aen: {
    unitMeasurement: (
      <span>
        10<sup>-15</sup>J
      </span>
    ),
    values: [
      [0, '#0D0887', '#fff'],
      [200, '#46039F', '#fff'],
      [400, '#7201A8', '#fff'],
      [600, '#9C179E', '#fff'],
      [800, '#BD3786', '#fff'],
      [1000, '#D8576B', '#141938'],
      [1500, '#ED7953', '#141938'],
      [2000, '#FB9F3A', '#141938'],
      [2500, '#FDCA26', '#141938'],
      [3000, '#F0F921', '#141938'],
    ],
  },
};
