import React from 'react';
import MainTitle from '../../common/MainTitle';

const MinTemperature = () => {
  return (
    <>
      <MainTitle title='Temperatura Mínima' description='Temperatura Mínima' />
    </>
  );
};

export default MinTemperature;
