import { Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import FooterPortal from '../../../common/FooterPortal';
import HeaderPortal from '../../../common/HeaderPortal';
import { getMonitoringArticles } from '../../../services/articles';
import Button from '../components/Button';
import Carousel from '../components/Carousel';
import InformCard from '../components/InformCard';
import OfferCard from '../components/OfferCard';
import moment from 'moment';
import InformationCard from './InformationCard';

const Monitoring = () => {
  const [monitoringArticles, setMonitoringArticles] = useState(null);

  useEffect(() => {
    getMonitoringArticles().then((data) => {
      if (data?.list) setMonitoringArticles(data.list);
    });
  }, []);

  return (
    <div className='portal-web'>
      <HeaderPortal />
      <div className='portal-monitoring'>
        <div className='section'>
          <div className='main'>
            <div className='main__left'>
              <h1 className='main__title'>
                Controla todos los{' '}
                <span className='main__title--strong'>
                  riesgos operativos de tu empresa
                </span>
              </h1>
              <p className='main__description'>
                Nuestras instalaciones físicas y la plataforma digital monitorea
                fenómenos potencialmente peligrosos evitando pérdidas
                patrimoniales y retrasos operativos.
              </p>
              <div className='main__buttons'>
                <Button
                  href='https://www.rimac.com/empresa?rfid=publica:categoria-empresas:menu-principal:tab'
                  target='blank'
                >
                  Adquirir póliza
                </Button>
              </div>
            </div>
            <div className='main__right'>
              <img
                src='/images/portal/fondo_person_monitoring.png'
                className='main__img'
                alt='Persona Felíz'
              />
            </div>
          </div>
        </div>
        <div className='section back-color-gray-7'>
          <div className='offers'>
            <h4 className='section__title'>
              Conoce nuestra{' '}
              <span className='section__title--red'>oferta de valor</span>
            </h4>
            <p className='offers__subtitle'>
              Prevemos la ocurrencia de fenómenos geometeorológicos y/o humanos
              potencialmente peligrosos a nivel nacional y global.
            </p>
            <div className='offers__list'>
              <OfferCard
                img='/images/portal/icon_alarm.svg'
                title='Alertas eventos peligrosos'
                description='Sismos, lluvias extremas, huaycos, inundaciones y cierre de vías principales.'
                ellipsis
              />
              <OfferCard
                img='/images/portal/icon_calendar.svg'
                title='Operatividad'
                description='Funciona las 24 horas / 7 días a la semana.'
                ellipsis
              />
              <OfferCard
                img='/images/portal/icon_world-wide.svg'
                title='Tecnología'
                description='Moderna tecnología satelital y staff de profesionales de nivel científico.'
                ellipsis
              />
              <OfferCard
                img='/images/portal/icon_satellite.svg'
                title='Datos satelitales'
                description='Estaciones propias y de agencias científicas nacionales e internacionales.'
                ellipsis
              />
            </div>
          </div>
        </div>
        <div className='section factors-section'>
          <div className='factors'>
            <h4 className='section__title section--padding'>
              Podrás monitorear{' '}
              <span className='section__title--red'>
                diariamente estos factores
              </span>
            </h4>
            <div className='factors__carousel'>
              <Carousel />
            </div>
          </div>
        </div>
        <div className='section back-color-gray-7'>
          <div className='information'>
            <div className='information__left'>
              <div className='information__title'>
                Te brindamos toda{' '}
                <span className='information__title--strong'>
                  la información que necesitas
                </span>
              </div>
            </div>
            <div className='information__right'>
              <InformationCard
                img='/images/portal/icon_gps.svg'
                title='Mapas'
                description='Mapas interactivos de vistas satelitales en tiempo real y de pronósticos de las principales variables meteorológicas'
              />
              <InformationCard
                img='/images/portal/icon_calendar.svg'
                title='Forecasting'
                description='Pronóstico de condiciones meteorológicas a nivel nacional de manera semanal y de los principales sectores económicos'
              />
              <InformationCard
                img='/images/portal/icon_clock.svg'
                title='Nowcasting'
                description='Pronóstico corto de las siguientes 24 horas ante eventos geológicos y meteorológicos potencialmente peligrosos a nivel nacional'
              />
              <InformationCard
                img='/images/portal/icon_satellite.svg'
                title='Alertas'
                description='Ante la materialización de un evento geológicos, meteorológico o antrópico importante como sismos, erupciones volcánicas, huaycos, inundaciones, grandes incendios.'
              />
              <InformationCard
                img='/images/portal/icon_info-inclusion.svg'
                title='Boletines'
                description='Boletines con actualización de eventos catastróficos durante la emergencia y hasta su cierre.'
              />
              <InformationCard
                img='/images/portal/icon_documents.svg'
                title='Publicaciones periódicas'
                description='Documentos técnicos donde se difunden temas de interés para la gestión de riesgos en las empresas.'
              />
            </div>
          </div>
        </div>
        <div className='section informs-section'>
          <div className='informs'>
            <h4 className='section--padding section__title'>
              Accede a los informes de{' '}
              <span className='section__title--strong'>
                eventos y fenómenos naturales
              </span>
            </h4>
            <div className='informs__list'>
              {monitoringArticles ? (
                monitoringArticles.length > 0 ? (
                  monitoringArticles
                    .slice(0, 2)
                    .map((article) => (
                      <InformCard
                        key={`moni-${article.id}`}
                        href={`/articulos/${article.url}`}
                        title={article.title}
                        img={`${process.env.REACT_APP_WEB_PATH_S3_NEW}${article.image}`}
                        tag={moment(article.createdAt).format('MMMM YYYY')}
                      />
                    ))
                ) : (
                  ''
                )
              ) : (
                <div style={{ width: 256, height: 100, marginBottom: 64 }}>
                  <Skeleton />
                </div>
              )}
              <div className='informs__list--fix'>&nbsp;&nbsp;&nbsp;&nbsp;</div>
            </div>
            <a
              className='section--padding section__button-more'
              href='/articulos?f=monitoreo'
            >
              <span>ver todos los informes</span>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <circle
                  cx='12'
                  cy='12'
                  r='11.5'
                  stroke='#6F7DFF'
                  strokeOpacity='0.4'
                />
                <path
                  fillRule='evenodd'
                  clipRule='evenodd'
                  d='M9.93988 15.5709C10.1867 15.8097 10.5825 15.8097 10.8293 15.5709L14.0601 12.4459C14.3133 12.2011 14.3133 11.7989 14.0601 11.5541L10.8293 8.42905C10.5825 8.19032 10.1867 8.19032 9.93988 8.42905C9.6867 8.67394 9.6867 9.07606 9.93988 9.32095L12.7096 12L9.93988 14.6791C9.68671 14.9239 9.68671 15.3261 9.93988 15.5709Z'
                  fill='#6F7DFF'
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
      <FooterPortal />
    </div>
  );
};

export default Monitoring;
