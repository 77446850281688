import React, { useMemo } from 'react';
import { List } from 'antd';

import { useSelector } from 'react-redux';
import { GLOSARIO } from './utils';
import { renderIcon } from '../renderIcon';

const DrawerInformation = ({ goToLayers }) => {
  const { activeLayer, activeSubLayer } = useSelector((state) => state.maps);

  const componentByLayer = useMemo(() => {
    const currentInfo = GLOSARIO.find(
      (item) => item.id === activeSubLayer.id || item.id === activeLayer.id,
    );

    return currentInfo ? (
      <div>
        {currentInfo.description && (
          <List key={'description'}>
            <List.Item className='mb-1'>{currentInfo.description}</List.Item>
          </List>
        )}
        {currentInfo.type === 'list' && (
          <List key={'text'}>
            {currentInfo.text.map((txt, index) => (
              <List.Item key={`info-${index}`}>{txt}</List.Item>
            ))}
          </List>
        )}
        {currentInfo.example && (
          <List key={'example'}>
            <List.Item className='mb-1'>{currentInfo.example}</List.Item>
          </List>
        )}
      </div>
    ) : (
      ''
    );
  }, [activeSubLayer]);

  const renderLegend = (id) => {
    const currentInfo = GLOSARIO.find((item) => item.id === id);

    return currentInfo.component;
  };

  return (
    <section className='drawer-information'>
      <article className='drawer-information__card'>
        <div className='drawer-information__card__header'>
          <h3 style={{ textTransform: 'capitalize' }}>
            {['satellite', 'pp_acc', 'sismos', 'tsunami'].includes(
              activeLayer.id,
            )
              ? activeLayer.name
              : activeSubLayer.name}
          </h3>
          <figure>{renderIcon(activeLayer.id)}</figure>
        </div>

        <div className='drawer-information__card__info'>{componentByLayer}</div>
      </article>

      {['sismos', 'tsunami'].includes(activeLayer.id) && (
        <article className='drawer-information__legend'>
          <div className='info'>{renderLegend(activeLayer.id)}</div>
        </article>
      )}

      <p className='drawer-information__info'>
        Puedes visualizar la información de otras capas cuando las selecciones
      </p>

      <button className='drawer-information__action' onClick={goToLayers}>
        Ver capas <i className='icon'></i>
      </button>
    </section>
  );
};

export default DrawerInformation;
