import React from 'react';
import FormForgotPassword from './FormForgotPassword';

const RightSection = () => {
  return (
    <section>
      <h3>¿Olvidaste contraseña?</h3>
      <p>Para crear una nueva contraseña, ingresa tu correo electrónico.</p>
      <FormForgotPassword />
    </section>
  );
};

export default RightSection;
