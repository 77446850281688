import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import L from 'leaflet';
import 'leaflet-draw';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';

import {
  CUMULUS_SET_DRAW_LAYER,
  CUMULUS_SET_MARKER,
  MAP_CHANGE_BLOCKED_CLICK,
  MAP_SET_ACTIVE_CUMULUS_TOOL,
} from '../../../../redux/actions/actionsType';
import {
  loadCircleDetails,
  loadPolygonDetails,
} from '../../../../lib/panelTool';

import { circleStyle } from './utils';
import { closePopUp } from '../../../../lib/utils';
import { renderIcon } from '../renderIcon';
import DrawerMarker from './DrawerMarker';
import DrawerFilters from './DrawerFilters';

let drawBaseLayer;

const DrawerCumulusTools = ({
  cumulusTools,
  isMobile,
  controlMapDrawerVisible,
}) => {
  const dispatch = useDispatch();
  const activeTool = useSelector((state) => state.maps.activeCumulusTool);
  const { filters } = useSelector((state) => state.cumulus);
  const map = useSelector((state) => state.maps.map);
  const drawRef = useRef(null);
  const drawLayerRef = useRef(null);

  const setMarket = (marker) =>
    dispatch({ type: CUMULUS_SET_MARKER, payload: marker });

  const doGetCircleDetails = (circle) => {
    loadCircleDetails({ circle, filters, dispatch, fromDrawer: true, map });
  };

  const loadPolyDetails = (polygon) => {
    loadPolygonDetails({ polygon, filters, dispatch, map });
  };

  const handleSelectTool = (tool) => {
    if (tool.key === 'clear') {
      closePopUp();
      if (drawLayerRef.current) {
        drawBaseLayer.removeLayer(drawLayerRef.current);
      }
      dispatch({ type: MAP_SET_ACTIVE_CUMULUS_TOOL, payload: null });

      return;
    }

    if (activeTool?.key === tool.key) {
      dispatch({ type: MAP_SET_ACTIVE_CUMULUS_TOOL, payload: null });
    } else {
      dispatch({ type: MAP_SET_ACTIVE_CUMULUS_TOOL, payload: tool });
    }

    if (isMobile) {
      controlMapDrawerVisible(false);
    }
  };

  useEffect(() => {
    if (map) {
      drawBaseLayer = new L.FeatureGroup();
      map.addLayer(drawBaseLayer);

      const drawControl = new L.Control.Draw({
        draw: {
          polygon: true,
          polyline: false,
          circle: true,
          circlemarker: false,
          marker: false,
          rectangle: false,
        },
        edit: {
          featureGroup: drawBaseLayer,
        },
      });

      map.addControl(drawControl);

      const controlContainerElement = document.querySelector(
        '.leaflet-control-container',
      );

      controlContainerElement.style.visibility = 'hidden';

      const onDrawCreated = ({ layer, layerType }) => {
        drawLayerRef.current = layer;
        drawBaseLayer.addLayer(layer);
        dispatch({ type: CUMULUS_SET_DRAW_LAYER, payload: layer });

        if (layerType === 'circle') {
          const coordinate = {
            lat: layer._latlng.lat.toFixed(6),
            lng: layer._latlng.lng.toFixed(6),
          };
          setMarket({
            ...coordinate,
            radius: (layer.getRadius() / 1000).toFixed(2),
          });
          doGetCircleDetails(layer);

          layer.on('click', (e) => {
            L.DomEvent.stopPropagation(e);
            doGetCircleDetails(layer);
          });
        }
        if (layerType === 'polygon') {
          loadPolyDetails(layer);

          layer.on('click', (e) => {
            L.DomEvent.stopPropagation(e);
            loadPolyDetails(layer);
          });
        }

        dispatch({ type: MAP_CHANGE_BLOCKED_CLICK, payload: false });
        dispatch({ type: MAP_SET_ACTIVE_CUMULUS_TOOL, payload: null });
      };

      const onDrawStart = () => {
        closePopUp();
        dispatch({ type: MAP_CHANGE_BLOCKED_CLICK, payload: true });
        if (drawLayerRef.current) {
          drawBaseLayer.removeLayer(drawLayerRef.current);
        }
      };

      map.on('draw:created', onDrawCreated);
      map.on('draw:drawstart', onDrawStart);

      return () => {
        if (controlContainerElement?.style) {
          controlContainerElement.style.visibility = 'visible';
        }
        map.off('draw:created', onDrawCreated);
        map.off('draw:drawstart', onDrawStart);
        map.removeControl(drawControl);
        map.removeLayer(drawBaseLayer);
      };
    }
  }, [map, filters]);

  useEffect(() => {
    if (map) {
      if (drawRef.current) {
        drawRef.current.disable();
      }

      if (['polygon', 'circle'].includes(activeTool?.key)) {
        if (activeTool?.key === 'polygon') {
          drawRef.current = new L.Draw.Polygon(map, {
            shapeOptions: {
              ...circleStyle,
              icon: new L.DivIcon({
                className: 'custom-div-icon',
                html: '<div class="outer-circle"><div class="inner-circle"></div></div>',
                iconSize: [12, 12],
              }),
            },
          });
        }
        if (activeTool?.key === 'circle') {
          drawRef.current = new L.Draw.Circle(map, {
            shapeOptions: circleStyle,
          });
        }
        drawRef.current.enable();
      }
    }
  }, [map, activeTool?.key]);

  return (
    <>
      {!activeTool?.drawer && (
        <div className='drawer-map-layers'>
          <div className='drawer-map-layers__list'>
            {cumulusTools.map((cTool) => {
              return (
                <div
                  key={cTool.key}
                  className={`drawer-map-layers__list__item ${
                    activeTool?.key === cTool.key ? 'active' : ''
                  }`}
                  onClick={() => handleSelectTool(cTool)}
                >
                  <div className='drawer-map-layers__list__item__name'>
                    {renderIcon(cTool.key)}
                    <div className='drawer-map-layers__list__item__name__text'>
                      {cTool.name}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {activeTool?.key === 'marker' && <DrawerMarker />}
      {activeTool?.key === 'filter' && <DrawerFilters />}
    </>
  );
};

export default DrawerCumulusTools;
