import React from 'react';
import moment from 'moment';

const MainSection = ({ article, popularArticles }) => {
  return (
    <div className='section'>
      <div className='article-container'>
        <section className='article'>
          <div className='article__img'>
            <img
              src={`${process.env.REACT_APP_WEB_PATH_S3_NEW}${article.image}`}
              alt='Artículo'
            />
          </div>
          <div className='article__img-description'>
            {article.image_subtitle}
          </div>
          <div className='article__date'>
            Publicado el {moment(article.createdAt).format('DD/MM/YYYY')}
          </div>
          <div
            className='article__content'
            dangerouslySetInnerHTML={{ __html: article.message }}
          ></div>
        </section>
        <aside className='side'>
          <h3 className='side__head'>Artículos populares</h3>
          {popularArticles &&
            popularArticles.length > 0 &&
            popularArticles.slice(0, 3).map((articule, index) => (
              <article key={`${articule.id}-${index}-popular`}>
                <a href={`/articulos/${articule.url}`} className='side__card'>
                  <span className='side__tag'>
                    {articule.category === 1 ? 'monitoreo' : 'prevención'}
                  </span>
                  <div className='side__title'>{articule.title}</div>
                  <div className='side__description'>{articule.subtitle}</div>
                </a>
              </article>
            ))}
        </aside>
      </div>
    </div>
  );
};

export default MainSection;
