import React, { useState } from 'react';
import { Modal } from 'antd';

import { IconCloseCircle } from '../../../../../common/Icons';
import DeleteModalImage from '../../../../../images/delete_modal_image.svg';

const ModalDelete = ({ visible, onConfirm, items }) => {
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const handleCancel = () => {
    onConfirm(false);
  };

  return (
    <Modal
      onOk={() => {
        setLoadingConfirm(true);
        onConfirm(true, () => {
          setLoadingConfirm(false);
        });
      }}
      onCancel={handleCancel}
      open={visible}
      okText='Eliminar'
      cancelText='Cancelar'
      destroyOnClose
      okButtonProps={{
        disabled: loadingConfirm,
        loading: loadingConfirm,
      }}
      maskClosable={false}
      className='mdl-helpCenter-delete'
    >
      <div className='mdl-helpcenter-delete__boxMessage'>
        <img
          src={DeleteModalImage}
          alt=''
          className='mdl-helpcenter-delete__boxMessage__image'
          aria-hidden
        />
        <div className='mdl-helpcenter-delete__boxMessage__title'>
          ¿Estás seguro que deseas <span>eliminar?</span>
        </div>
        {items.length ? (
          <div className='mdl-helpcenter-delete__boxMessage__message'>
            Eliminarás ({items.length}) fila seleccionada y no podrás volverla a
            ver.
          </div>
        ) : (
          <div className='mdl-helpcenter-delete__boxMessage__message'>
            Eliminarás esta solicitud y no podrás volverla a ver.
          </div>
        )}
      </div>
    </Modal>
  );
};
export default ModalDelete;
