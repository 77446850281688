import React, { useState, useEffect, memo } from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { Button, Skeleton } from 'antd';

import {
  getPreventionServicesOfPoliza,
  getMyPolizas,
  getRiskServicesOfPoliza,
} from '../../../../../services/requestServices';
import { useScreen } from '../../../../../lib/screen';
import renderIcon from './utils';
import nomenclatures from '../../../RequestServices/nomenclatures';

import CardEmptyContact from '../../../../../common/CardEmptyContact';

const filterPolizaServices = (values, key) => {
  return _.chain(values)
    .map((el) => el.requestServiceItemsData)
    .reduce((result, curr) => result.concat(curr), [])
    .filter({ key })
    .map((el) => {
      let value = JSON.parse(el.value);
      if (!Array.isArray(value)) {
        value = [value];
      }

      return {
        ...el,
        value: value.map((v) =>
          el.key === 'signals' && typeof v === 'string' ? JSON.parse(v) : v,
        ),
      };
    })
    .value();
};

const polizaRequestServices = (requests) => {
  return _.chain(requests)
    .map('requestServiceItems')
    .reduce((result, curr) => result.concat(curr), [])
    .groupBy('serviceId')
    .value();
};

export const mapPolizaServices = (services, values) => {
  return services
    ? services.map((el) => {
        let count = 0;
        let res = {};
        switch (el?.service?.type) {
          case '1':
            const items = filterPolizaServices(values[el.serviceId], 'courses');

            count = _.chain(items)
              .map('value')
              .reduce((result, curr) => result.concat(curr), [])
              .map(
                (el) =>
                  (el?.hours || 0) * (el?.repetitions ? el?.repetitions : 1),
              )
              .sum()
              .value();

            res = {
              ...el,
              dbValue: count,
              prevCount: count,
            };
            break;
          case '3':
            let signals = filterPolizaServices(values[el.serviceId], 'signals');
            signals = _.chain(signals)
              .map('value')
              .reduce((result, curr) => result.concat(curr), [])
              .value();

            _.each(nomenclatures.materials, (mat) => {
              count += _.sumBy(signals, (sig) => Number(sig[mat.key] || 0));
            });

            res = {
              ...el,
              dbValue: count,
              prevCount: count,
            };
            break;
          default:
            res = {
              ...el,
              prevCount: _.size(values[el.serviceId]) || 0,
            };
            break;
        }

        count = parseInt(el.count, 10) - res.prevCount;

        return {
          ...res,
          count: count < 0 ? 0 : count,
        };
      })
    : [];
};

const ServiceAvailable = ({ isAdminRimac, companyId, isRisks }) => {
  const navigate = useNavigate();
  const [selectedPoliza, setSelectedPoliza] = useState(null);
  const [polizas, setPolizas] = useState([]);
  const [listServices, setListServices] = useState([]);
  const [loadingPoliza, setLoadingPoliza] = useState(true);
  const [loadingServices, setLoadingServices] = useState(false);
  const { widthScreen } = useScreen();

  useEffect(() => {
    getMyPolizas()
      .then(setPolizas)
      .finally(() => {
        setLoadingPoliza(false);
      });
  }, []);

  useEffect(() => {
    polizas?.length > 0 && setSelectedPoliza(polizas[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [polizas]);

  useEffect(() => {
    if (selectedPoliza) {
      setLoadingServices(true);
      const request = isRisks
        ? getRiskServicesOfPoliza(selectedPoliza.id)
        : getPreventionServicesOfPoliza(selectedPoliza.id);
      request
        .then((services) => {
          const requests = polizaRequestServices(
            selectedPoliza.requestServices,
          );
          const lisToServices = mapPolizaServices(services, requests);
          setListServices(lisToServices);
        })
        .finally(() => {
          setLoadingServices(false);
        });
    }

    return () => {
      setListServices([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPoliza]);

  return (
    <div className='service-prevention__body'>
      <div className='service-prevention__body__button-groups'>
        {loadingPoliza || loadingServices ? (
          <div className='title-loading'>
            <Skeleton
              active
              title={false}
              paragraph={{ rows: 1, width: '100%' }}
              className='title'
            />
            <div className='buttons'>
              {[1, 2, 3].map((el) => (
                <Skeleton
                  active
                  title
                  paragraph={false}
                  key={el}
                  className='poliza'
                />
              ))}
            </div>
          </div>
        ) : polizas && polizas.length ? (
          <>
            <p className='service-prevention__body__button-groups__name'>
              Pólizas
            </p>
            <div className='service-prevention__body__button-groups__buttons'>
              {polizas.map((item) => (
                <Button
                  key={item?.id}
                  className={selectedPoliza?.id === item?.id ? 'active' : ''}
                  onClick={() => setSelectedPoliza(item)}
                >
                  {`${item?.typePoliza?.name}`}
                </Button>
              ))}
            </div>
          </>
        ) : (
          <CardEmptyContact isAdminRimac={isAdminRimac} companyId={companyId} />
        )}
      </div>
      {loadingServices ? (
        <div className='content-skeletons'>
          {[1, 2, 3, 4, 5, 6].map((item) => (
            <div className='skeleton' key={item}>
              <Skeleton
                active
                className='avatar'
                avatar={{ size: 'large', shape: 'circle' }}
                title={false}
                paragraph={false}
              />
              <Skeleton active paragraph={{ rows: 5 }} />
            </div>
          ))}
        </div>
      ) : listServices && listServices.length ? (
        <>
          <p className='service-prevention__body__text mb-4'>
            Beneficios disponibles para tu empresa:
          </p>
          <div className='service-prevention__body__card-list'>
            {listServices.map((item, index) => {
              return (
                <article
                  key={index}
                  className={`service-prevention__body__card-list__item ${
                    !item.count ? 'disabled' : ''
                  }`}
                  style={
                    listServices?.length < 3 && widthScreen >= 768
                      ? { width: '260px' }
                      : {}
                  }
                >
                  <div className='service-prevention__body__card-list__item-img'>
                    {renderIcon(item?.service?.name)}
                  </div>

                  <div className='service-prevention__body__card-list__item-body'>
                    <h3>{item?.service?.name}</h3>

                    <div>
                      <p className='title'>DISPONIBLE</p>
                      {item?.service?.name.toLowerCase() ===
                        'capacitacion in house' ||
                      item?.service?.name.toLowerCase() ===
                        'capacitación in house' ? (
                        <p className='value'>{`${item.count} ${
                          item.count
                            ? item.count > 1
                              ? 'horas'
                              : 'hora'
                            : '0 horas'
                        }`}</p>
                      ) : (
                        <p className='value'>{`${item.count} ${
                          item.count > 1 ? 'solicitudes' : 'solicitud'
                        }`}</p>
                      )}
                    </div>
                    <div>
                      <p className='title'>VALOR DE MERCADO</p>
                      <p className='value'>US$ {item?.service?.rimac_cost}</p>
                    </div>
                    {item?.service?.core_benefit && (
                      <div>
                        <p className='title'>BENEFICIO PRINCIPAL</p>
                        <p className='value'>{item?.service?.core_benefit}</p>
                      </div>
                    )}
                    <Button
                      disabled={!Boolean(item?.count)}
                      onClick={() =>
                        navigate(
                          `/solicitar-servicio?poliza=${
                            selectedPoliza?.id
                          }&service=${item?.serviceId}${
                            isRisks ? '&risks=true' : ''
                          }`,
                        )
                      }
                      type={'link'}
                    >
                      Solicitar <i className='fas fa-chevron-right'></i>
                    </Button>
                  </div>
                </article>
              );
            })}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default memo(ServiceAvailable);
