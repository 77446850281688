import * as React from 'react';

const Satelite = (props) => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M8.38438 13.3845C7.89688 12.897 7.89688 12.1048 8.38438 11.6173L11.6172 8.38447C11.8609 8.14072 12.1812 8.01887 12.5016 8.01887C12.8219 8.01887 13.1406 8.14072 13.3859 8.38447L25.2437 20.2423C26.1141 19.8517 27.0406 19.5876 28 19.4626L25.1531 16.6157L26.5891 15.1798L30.3391 16.4298L38.0188 8.75011L30.0016 0.73291L22.3219 8.41262L23.5719 12.1626L22.1359 13.5985L15.1531 6.61575C14.4453 5.90793 13.5031 5.5173 12.5016 5.5173C11.5 5.5173 10.5578 5.90793 9.85 6.61575L6.61719 9.84855C5.15469 11.311 5.15469 13.6892 6.61719 15.1517L13.6 22.1345L12.1641 23.5704L8.41406 22.3204L0.734375 30.0001L8.75156 38.0173L16.4313 30.3376L15.1813 26.5876L16.6172 25.1517L19.4641 27.9985C19.5891 27.0392 19.8531 26.1126 20.2437 25.2423L8.38594 13.3845H8.38438ZM30 4.26887L34.4828 8.75167L29.6625 13.572L26.3016 12.4517L25.1813 9.09072L30.0016 4.27043L30 4.26887ZM8.75 34.4829L4.26719 30.0001L9.0875 25.1798L12.4484 26.3001L13.5688 29.6611L8.74844 34.4813L8.75 34.4829Z'
      fill='url(#paint0_linear_9056_16283)'
    />
    <path
      d='M28.9219 34.8956C29.7969 35.7706 31.2141 35.7706 32.0891 34.8956L34.8937 32.0909C35.7687 31.2159 35.7687 29.7972 34.8937 28.9238L33.6141 27.644L27.6406 33.6175L28.9203 34.8972L28.9219 34.8956Z'
      fill='url(#paint1_linear_9056_16283)'
    />
    <path
      d='M23.609 23.6095C20.4637 26.7548 20.4637 31.8548 23.609 35.0001L34.9996 23.6095C31.8543 20.4642 26.7543 20.4642 23.609 23.6095Z'
      fill='url(#paint2_linear_9056_16283)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_9056_16283'
        x1='0.734375'
        y1='0.73291'
        x2='20.7219'
        y2='45.7048'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.272135' stopColor='#34263B' />
        <stop offset='0.658079' stopColor='#13172C' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_9056_16283'
        x1='27.6406'
        y1='27.644'
        x2='31.8793'
        y2='37.183'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.272135' stopColor='#34263B' />
        <stop offset='0.658079' stopColor='#13172C' />
      </linearGradient>
      <linearGradient
        id='paint2_linear_9056_16283'
        x1='21.6097'
        y1='22.3296'
        x2='32.9384'
        y2='33.6583'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.35' stopColor='#F7052D' />
        <stop offset='0.85' stopColor='#CA5AFA' />
      </linearGradient>
    </defs>
  </svg>
);

export default Satelite;
