import { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Button, Input, Select, Typography } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { LOAD_REQUEST_SERVICES_LOADING } from '../../../../../redux/actions/actionsType';

import useDebounce from '../../../../../hooks/useDebounce';
import { useScreen } from '../../../../../lib/screen';
import {
  geRiskRequestServiceItems,
  getMyListRequestServiceItems,
  getRequestServicesManagerAccounts,
  getRequestServicesUsersCreate,
} from '../../../../../services/requestServices';

import { IconFilter } from '../../../../../common/Icons';

import ServiceRequestList from './components/ServiceRequestList';
import {
  getPreventionServices,
  getRisksServices,
} from '../../../../../services/service';
import { IconClose } from '../Reports/utils';

const { Search } = Input;
const { Option } = Select;
const { Title } = Typography;

const ServiceRequest = ({
  user,
  loadList,
  loadListService,
  filters,
  pagination,
  loadingList,
  isActive,
  listManagerAccount,
  loadListManagerAccount,
  loadListUsersCreate,
  setEditContact,
  isRisks,
}) => {
  const listServices = useSelector((state) => state.service.list || []);
  const riskServiceList = useSelector((state) => state.service.riskList || []);
  const listUsersCreate = useSelector(
    (state) => state.requestServices.usersCreate || [],
  );
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query);
  const [filtersLocal, setFiltersLocal] = useState({});
  const [filterMobile, setFilterMobile] = useState(false);
  const { isMobileScreen: isPhone } = useScreen();

  const handleModalFilter = () => setFilterMobile((prev) => !prev);

  const handleClear = () => {
    setQuery('');
    setFiltersLocal({});
    loadList(
      {
        page: 1,
        limit: pagination.pageSize,
        query: '',
        filters: {},
      },
      isRisks,
    );
  };
  const handleClearFilter = () => {
    setQuery('');
    setFiltersLocal({});
  };

  useEffect(() => {
    handleClearFilter();
  }, [isRisks]);

  useEffect(() => {
    loadList(
      {
        query: debouncedQuery.length >= 2 ? debouncedQuery : '',
        page: 1,
        limit: pagination.pageSize,
        filters,
      },
      isRisks,
    );
  }, [debouncedQuery]);

  useEffect(() => {
    if (isActive) {
      loadingList(true);
      loadListUsersCreate();
      loadListManagerAccount();
      loadListService(isRisks);
    }

    return () => {
      handleClearFilter();
    };
  }, [isActive]);

  const handleTableChange = (pagination) => {
    loadingList(true);
    loadList(
      {
        query: query,
        page: pagination.current,
        limit: pagination.pageSize,
        filters: filters,
      },
      isRisks,
    );
  };

  const handleChangeQuery = (e) => setQuery(e.target.value);

  const handleChangeFilter = (value, key) => {
    setFiltersLocal((prev) => ({
      ...prev,
      [key]: value,
    }));

    loadList(
      {
        query: query,
        page: 1,
        limit: pagination.pageSize,
        filters: { ...filters, [key]: value },
      },
      isRisks,
    );
  };

  return (
    <div className='d_content' style={{ paddingTop: '24px' }}>
      <div
        className='flex full-width justify-between mb-2'
        style={{ marginTop: -24 }}
      >
        <Title level={5} style={{ marginBottom: 0 }}>
          {['admin_rimac', 'gestor_de_cuenta'].includes(user?.rol?.slug)
            ? 'Filtrar lista de servicios'
            : 'Filtra solicitudes'}
        </Title>
        {['admin_rimac', 'gestor_de_cuenta'].includes(user?.rol?.slug) &&
          !isPhone && (
            <Search
              placeholder='Palabras clave'
              value={query}
              allowClear
              onChange={handleChangeQuery}
              style={{
                width: '100%',
                maxWidth: '300px',
              }}
            />
          )}
      </div>

      {isPhone && (
        <Search
          placeholder='Palabras clave'
          value={query}
          allowClear
          onChange={handleChangeQuery}
          style={{
            width: '100%',
            maxWidth: '300px',
          }}
        />
      )}

      <div
        className={`filter__box flex flex-row flex-wrap pb-4 mb-4 ${
          filterMobile ? 'modal__filter' : ''
        }`}
      >
        {filterMobile && (
          <>
            <div className='modal__filter--close' onClick={handleModalFilter}>
              <span>Cerrar</span>
              <IconClose />
            </div>

            <h3 className='modal__filter--title'>Filtrar lista de servicios</h3>
          </>
        )}

        {!['admin_rimac', 'gestor_de_cuenta'].includes(user?.rol?.slug) && (
          <Search
            className='mr-2 inputs-class'
            placeholder='Palabras clave'
            style={{
              width: '100%',
              maxWidth: '300px',
            }}
            value={query}
            onChange={handleChangeQuery}
          />
        )}

        <Select
          className='mr-2 inputs-class'
          value={filtersLocal['service_id']}
          style={!filterMobile ? { width: 210 } : {}}
          onChange={(val) => handleChangeFilter(val, 'service_id')}
          placeholder='Por servicios'
        >
          {(isRisks ? riskServiceList : listServices).map(({ name, id }) => (
            <Option value={id} key={id}>
              {name}
            </Option>
          ))}
        </Select>
        {['admin_rimac', 'gestor_de_cuenta'].includes(user?.rol?.slug) ? (
          <>
            <Select
              className='mr-2 inputs-class'
              value={filtersLocal['managerAccount']}
              style={!filterMobile ? { width: 210 } : {}}
              onChange={(val) => handleChangeFilter(val, 'managerAccount')}
              placeholder='Por gestor de cuenta'
            >
              {listManagerAccount && listManagerAccount.length
                ? listManagerAccount.map((u) => (
                    <Option
                      value={u.username}
                      key={u.username}
                    >{`${u.name} ${u.last_name1} ${u.last_name2}`}</Option>
                  ))
                : null}
            </Select>

            <Select
              className='mr-2 inputs-class'
              value={filtersLocal['userCreate']}
              style={!filterMobile ? { width: 210 } : {}}
              onChange={(val) => handleChangeFilter(val, 'userCreate')}
              placeholder='Por usuario creador'
            >
              {listUsersCreate?.map((u) => (
                <Option value={u.username} key={u.username}>
                  {`${u.name} ${u.last_name1} ${u.last_name2}`}
                </Option>
              ))}
            </Select>
          </>
        ) : (
          <Select
            className='mr-2 inputs-class'
            value={filtersLocal['status']}
            style={!filterMobile ? { width: 152, minWidth: 120 } : {}}
            onChange={(val) => handleChangeFilter(val, 'status')}
            placeholder='Estado'
          >
            <Option value={1}>Nuevo</Option>
            <Option value={2}>En proceso</Option>
            <Option value={3}>Ejecutado</Option>
          </Select>
        )}
        <Button type='link' className='btn-clearFilter' onClick={handleClear}>
          <DeleteOutlined />
          Borrar Filtros
        </Button>
        {filterMobile && (
          <button
            className='modal__filter--button'
            type='button'
            disabled={
              query === '' &&
              filtersLocal['status'] === '' &&
              filtersLocal['status'] === ''
            }
            onClick={handleModalFilter}
          >
            Filtrar
          </button>
        )}
      </div>

      <div className='d_content__list__action mb-4 flex flex-row'>
        <div className='info-header'>
          <div>
            {!isPhone && pagination.total === 1 ? (
              <>
                Se encontró <b>1</b> solicitud
              </>
            ) : (
              <>
                Se encontraron <b>{pagination.total}</b> solicitudes
              </>
            )}
          </div>
        </div>

        <div className='filter-action' onClick={handleModalFilter}>
          <IconFilter />
          <span>Filtrar</span>
        </div>
      </div>

      <div className='d_content__list'>
        <ServiceRequestList
          isRisk={isRisks}
          getListServicesRequest={() => {
            loadList(
              {
                query: query,
                page: 1,
                limit: pagination.pageSize,
                filters,
              },
              isRisks,
            );
          }}
          onDelete={() => {}}
          setEditContact={setEditContact}
          loadData={handleTableChange}
          handleOpenCreateRequest={() => {}}
          userSlug={user?.rol?.slug}
          doCloseTicket={() => {}}
        />
      </div>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => ({
  loadingList: (payload) =>
    dispatch({ type: LOAD_REQUEST_SERVICES_LOADING, payload: payload }),
  loadList: async (data, isRisks) => {
    dispatch({ type: LOAD_REQUEST_SERVICES_LOADING, payload: true });
    const request = isRisks
      ? geRiskRequestServiceItems
      : getMyListRequestServiceItems;
    await request(dispatch, data);
    dispatch({ type: LOAD_REQUEST_SERVICES_LOADING, payload: false });
  },
  loadListManagerAccount: () => getRequestServicesManagerAccounts(dispatch),
  loadListUsersCreate: () => getRequestServicesUsersCreate(dispatch),
  loadListService: (isRisks) =>
    isRisks ? getRisksServices(dispatch) : getPreventionServices(dispatch),
});
const mapStateToProps = (state) => ({
  pagination: state.requestServices.pagination,
  filters: state.requestServices.filters,
  isLoading: state.requestServices.isLoadingList,
  user: state.auth.user,
  listManagerAccount: state.requestServices.managersAccount,
});
export default connect(mapStateToProps, mapDispatchToProps)(ServiceRequest);
