import * as React from 'react';

const Thunder = (props) => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M34.375 23.75H27.4672L24.9938 31.25H27.4672L24.375 38.75L33.6516 28.7828H30.5469L34.375 23.75Z'
      fill='url(#paint0_linear_9056_7825)'
    />
    <path
      d='M32.7563 11.2577H31.8469C30.8703 9.48119 28.9875 8.33901 26.9172 8.33901C25.8438 8.33901 24.8219 8.64681 23.9484 9.189C23.1219 5.48744 19.8844 2.67181 15.9703 2.50775C13.6156 2.40931 11.3844 3.25149 9.68906 4.87805C8.29531 6.21399 7.41562 7.93118 7.14531 9.80305C6.66406 9.77961 6.17813 9.82024 5.69375 9.92493C3.11875 10.4827 1.25 12.8405 1.25 15.5312V17.3062C1.25 20.064 3.49375 22.3062 6.25 22.3062H33.6953C36.4828 22.3062 38.75 20.039 38.75 17.2515C38.75 13.9468 36.0609 11.2577 32.7563 11.2577ZM33.6953 19.8062H6.25C4.87188 19.8062 3.75 18.6843 3.75 17.3062V15.5312C3.75 14.0093 4.79063 12.6796 6.22344 12.3687C6.78438 12.2468 7.34844 12.2812 7.89844 12.4718L9.55625 13.0437V11.0468C9.55625 9.38273 10.2172 7.83274 11.4188 6.68118C12.6188 5.52962 14.1984 4.93431 15.8656 5.00462C18.9812 5.13431 21.5766 7.77962 21.6516 10.8999C21.6547 11.0156 21.6531 11.1327 21.65 11.2484H19.3125V13.7484H23.6766L23.9719 12.9234C24.4141 11.6749 25.5984 10.8374 26.9188 10.8374C28.2391 10.8374 29.4234 11.6765 29.8656 12.9234L30.1609 13.7562H32.7578C34.6844 13.7562 36.2516 15.3234 36.2516 17.2499C36.2516 18.6577 35.1063 19.8046 33.6969 19.8046L33.6953 19.8062ZM18.0578 29.9999H20.6938L21.9438 26.2499H19.3078L18.0578 29.9999ZM13.5063 29.9999L14.7563 26.2499H12.1203L10.8703 29.9999H13.5063ZM33.6828 32.4999L32.4328 36.2499H35.0687L36.3187 32.4999H33.6828ZM18.6828 32.4999L17.4328 36.2499H20.0688L21.3188 32.4999H18.6828ZM7.56875 26.2499H4.93281L3.68281 29.9999H6.31875L7.56875 26.2499ZM6.31875 32.4999H3.68281L2.43281 36.2499H5.06875L6.31875 32.4999ZM11.1828 32.4999L9.93281 36.2499H12.5688L13.8188 32.4999H11.1828Z'
      fill='url(#paint1_linear_9056_7825)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_9056_7825'
        x1='24.6366'
        y1='24.9272'
        x2='36.0448'
        y2='32.5327'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.35' stopColor='#F7052D' />
        <stop offset='0.85' stopColor='#CA5AFA' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_9056_7825'
        x1='1.25'
        y1='2.5'
        x2='18.0603'
        y2='44.5258'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.272135' stopColor='#34263B' />
        <stop offset='0.658079' stopColor='#13172C' />
      </linearGradient>
    </defs>
  </svg>
);

export default Thunder;
