import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Drawer, Button, Checkbox, Progress, Typography } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { RideButton } from '@rimac-seguros/ride-system-components';

import {
  startSyncClaims,
  synCarnat,
  syncRisks,
  syncsCancelStatus,
  updateCumuloStep,
} from '../../../services/campusImported';
import InputFile from '../../../common/InputFile';

const { Text } = Typography;

const defaultSteps = {
  upload_file: {
    description: 'Subir cúmulo',
  },
  register: {
    description: 'Registro de cúmulo',
  },
  synBoletines: {
    description: 'Sincronizar afectaciones de boletines',
    type: 'newsletter',
  },
  synCarnat: {
    description: 'Sincronizar cúmulo carnat',
    type: 'risk',
  },
  synClaims: {
    description: 'Sincronizar Claims',
  },
  synInspat: {
    description: 'Sincronizar Inspat',
    type: 'inspat',
  },
};

const DrawerUpdateCumulo = ({
  visible,
  onClose,
  status,
  updateRunning,
  onReloadData = () => {},
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [statusCheckbox, setStatusCheckbox] = useState([]);
  const [stepsLoader, setStepsLoader] = useState([]);
  const [isUploadRisk, setUploadRisk] = useState(false);
  const [riskData, setRiskData] = useState(null);
  const [nameRiskData, setNameRiskData] = useState(null);

  useEffect(() => {
    if (status?.steps)
      setStepsLoader(
        (status?.steps || []).map((step) => ({
          ...step,
          ...defaultSteps[step.id],
        })),
      );

    setLoading(false);
  }, [status]);

  const _handleToogleCheckSync = useCallback(
    (id) => () => {
      const indexId = Object.keys(defaultSteps).findIndex((el) => el === id);
      setStatusCheckbox(() => Object.keys(defaultSteps).slice(indexId));
    },
    [],
  );

  const _handleSyncUpdate = useCallback(() => {
    updateCumuloStep().then(() => {
      if (riskData && riskData?.length)
        syncRisks(nameRiskData, riskData).then((res) => {
          onReloadData();
          if (res?.data.payload.running) updateRunning(true);
        });
      if (statusCheckbox && statusCheckbox.length) {
        const typeParameter = defaultSteps[statusCheckbox[0]]?.type;
        if (typeParameter) synCarnat(dispatch, { type: typeParameter });
        else startSyncClaims();
        updateRunning(true);
      }
    });
    onClose();
  }, [
    dispatch,
    onClose,
    statusCheckbox,
    updateRunning,
    riskData,
    onReloadData,
  ]);

  const _handleCancelUpdate = () => {
    syncsCancelStatus();
  };

  const _handleCleanCheckboxes = () => {
    setStatusCheckbox([]);
    setUploadRisk(false);
  };

  let content;
  if (loading) {
    content = 'Cargando...';
  } else if (status?.running) {
    content = (
      <div>
        <span>Actualizando...</span>
        {stepsLoader.map(({ description, status, percent, errors }, index) => (
          <div className='mt-3' key={`${description}-${index}`}>
            <Progress
              percent={percent}
              showInfo={false}
              size='small'
              strokeColor={status === 'completed' ? '#389E0D' : '#FC9700'}
            />
            <div>
              <span>{percent}% - </span>
              {description ? description : 'Sincronizar Cenepred'}
            </div>
            {errors && errors.length ? (
              <span style={{ color: 'red' }}>
                <strong>Error: </strong>
                {errors[0].message}
              </span>
            ) : null}
          </div>
        ))}
      </div>
    );
  } else {
    content = (
      <>
        {Object.keys(defaultSteps)
          .slice(2)
          .map((id) => (
            <div className='mb-2' key={id}>
              <Checkbox
                className='flex items-center checkbox-success-color'
                checked={statusCheckbox.includes(id)}
                onClick={_handleToogleCheckSync(id)}
                disabled={isUploadRisk}
              >
                <Text>{defaultSteps[id]?.description}</Text>
              </Checkbox>
            </div>
          ))}
        <div className='mb-2' key={'syncRisk'}>
          <Checkbox
            className='flex items-center checkbox-success-color'
            checked={isUploadRisk}
            onClick={() => {
              setUploadRisk((prev) => !prev);
            }}
            disabled={statusCheckbox.length > 0}
          >
            <Text>Sincronizar Cenepred</Text>
          </Checkbox>
        </div>
      </>
    );
  }

  let buttonContent = null;

  if (!loading && !status?.running) {
    buttonContent = (
      <RideButton
        variant='text-primary'
        onClick={_handleCleanCheckboxes}
        text='Limpiar selección'
      />
    );
  }

  return (
    <Drawer
      title={'Actualiza tus filtros'}
      placement='right'
      closable={true}
      onClose={onClose}
      open={visible}
    >
      <div className='flex flex-col'>
        {buttonContent}
        {content}
        {isUploadRisk && !status?.running ? (
          <div>
            <InputFile
              onChangeFile={(file) => {
                const selectedCode = {
                  name: file.file.name,
                };
                setNameRiskData(selectedCode);
              }}
              onChangeRows={(file) => {
                setRiskData(file);
              }}
              withoutPreview
              allowClear={true}
            />
            <Button
              href={'/templates/PlantillaCenepred.xlsx'}
              icon={<DownloadOutlined />}
              type='link'
            >
              Descargar plantilla cenepred
            </Button>
          </div>
        ) : null}
        <div className='flex justify-end gap-2 mt-4'>
          <RideButton
            variant='text-primary'
            disabled={!status?.running}
            onClick={_handleCancelUpdate}
            text='Cancelar subida'
          />
          <RideButton
            disabled={
              status?.running || (statusCheckbox.length === 0 && !riskData)
            }
            onClick={_handleSyncUpdate}
            text='Actualizar'
          />
        </div>
      </div>
    </Drawer>
  );
};

export default DrawerUpdateCumulo;
