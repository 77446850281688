import React, { useState } from 'react';
import { Checkbox, AutoComplete } from 'antd';

const ColumnsFilter = ({ filters, updateFilterColumns, className = '' }) => {
  const [value, setValue] = useState('');
  const [visible, setVisible] = useState(false);

  return (
    <AutoComplete
      className={`flex items-center less-input-border ${className}`}
      open={visible}
      placeholder='Mostrar/Ocultar columnas'
      onSearch={(val) => setValue(val)}
      filterOption={(inputValue, option) =>
        option?.label
          ?.toUpperCase()
          ?.indexOf((inputValue ?? '').toUpperCase()) !== -1
      }
      defaultValue={''}
      value={value}
      onDropdownVisibleChange={(visible) => {
        setVisible(visible);
      }}
      onSelect={() => {
        setValue('');
        setVisible(true);
      }}
      options={filters
        .filter((el) => el?.title)
        .map((item) => ({
          value: item.key,
          label: (
            <Checkbox
              name={item.key}
              onChange={(ev) => updateFilterColumns(ev.target.name)}
              checked={item.visible}
            >
              {item.title}
            </Checkbox>
          ),
        }))}
    />
  );
};

export default ColumnsFilter;
