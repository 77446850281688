import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { notification } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { CircleMarker, Polyline, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import moment from 'moment';
import axios from 'axios';

import { getForecastDataPoint } from '../../../services/myRoutes';
import {
  MY_ROUTES_SET_GEOJSON_ROUTE,
  MY_ROUTES_SET_POINTS_TO_GENERATE_REPORT,
} from '../../../redux/actions/actionsType';
import {
  RainIcon,
  VisHorizontalIcon,
  WindIcon,
  transformMarkersToGeoJSON,
  transformRouteToGeoJSON,
} from './utils';

const CreateMyRoute = ({
  origin,
  destination,
  getDataPoint,
  setPointsToGenerateReport,
  convertToGeoJSON,
}) => {
  const map = useMap();
  const [route, setRoute] = useState(null);
  const [routeMarkers, setRouteMarkers] = useState([]);
  const [dataPoint, setDataPoint] = useState(null);

  useEffect(() => {
    setRoute(null);
    setRouteMarkers([]);
    if (origin && destination) {
      calculateRoute(origin, destination);
    }
  }, [origin, destination]);

  const calculateRoute = async (origin, destination) => {
    const requestData = {
      origin: {
        address: origin,
      },
      destination: {
        address: destination,
      },
      travelMode: 'DRIVE',
      routingPreference: 'ROUTING_PREFERENCE_UNSPECIFIED',
      computeAlternativeRoutes: false,
      units: 'METRIC',
    };

    const headers = {
      'Content-Type': 'application/json',
      'X-Goog-Api-Key': process.env.REACT_APP_GOOGLE_API_KEY,
      'X-Goog-FieldMask':
        'routes.description,routes.duration,routes.distanceMeters,routes.polyline.encodedPolyline,routes.legs.startLocation,routes.legs.endLocation',
    };

    class NoRouteError extends Error {
      constructor(message) {
        super(message);
        this.name = 'NoRouteError';
      }
    }

    try {
      const geometry = await window.google.maps.importLibrary('geometry');
      const response = await axios.post(
        'https://routes.googleapis.com/directions/v2:computeRoutes',
        requestData,
        { headers },
      );
      const routeData = response.data;

      if (!routeData.routes) {
        throw new NoRouteError(
          'No existe ruta posible desde este origen a este destino',
        );
      }

      const encodedPolyline = routeData.routes[0].polyline.encodedPolyline;
      const decodedPath = geometry.encoding.decodePath(encodedPolyline);

      const route = decodedPath.map((point) => ({
        lat: point.lat(),
        lng: point.lng(),
      }));
      setRoute(route);

      const distanceInterval = 30_000;
      let distanceAccumulated = 0;
      let markers = [];

      for (let i = 0; i < route.length - 1; i++) {
        const startPoint = route[i];
        const endPoint = route[i + 1];

        const distanceBetweenPoints = geometry.spherical.computeDistanceBetween(
          new window.google.maps.LatLng(startPoint.lat, startPoint.lng),
          new window.google.maps.LatLng(endPoint.lat, endPoint.lng),
        );

        if (distanceAccumulated + distanceBetweenPoints >= distanceInterval) {
          markers.push({ lat: endPoint.lat, lng: endPoint.lng });
          distanceAccumulated = 0;
        } else {
          distanceAccumulated += distanceBetweenPoints;
        }
      }

      markers.unshift(route[0]);
      markers.push(route[route.length - 1]);
      if (markers.length > 0) {
        setRouteMarkers(markers);
      }
    } catch (error) {
      if (error instanceof NoRouteError) {
        console.error('Error:', error.message);
        notification.error({
          description:
            'No existe ruta posible desde este origen a este destino.',
          message: 'Error',
        });
      } else {
        console.error('Error:', error.message);
      }
    }
  };

  useEffect(() => {
    if (route) {
      const bounds = L.latLngBounds(route);
      map.fitBounds(bounds);
    }
  }, [route, map]);

  useEffect(() => {
    if (origin && destination && route) {
      setPointsToGenerateReport(routeMarkers);
      convertToGeoJSON(route, routeMarkers);
    }
  }, [routeMarkers]);

  const handleClickOnMarker = (marker) => {
    getDataPoint(marker).then((data) => setDataPoint(data));
  };

  return (
    <>
      {route && <Polyline positions={route} weight={10} />}
      {routeMarkers.map((marker, index) => (
        <CircleMarker
          key={index}
          center={marker}
          color='#141938'
          stroke
          className='route-marker'
          fillOpacity={1}
          fillColor='#FFF'
          radius={7}
          eventHandlers={{ click: () => handleClickOnMarker(marker) }}
        >
          <Popup>
            <div className='route-popUp'>
              {dataPoint && (
                <>
                  <strong className='route-popUp__title'>Pronósticos:</strong>
                  <ul className='route-popUp__list'>
                    {dataPoint.map((forecast, idx) => {
                      return (
                        <li key={idx} className='route-popUp__list-item'>
                          {moment(forecast?.time).format('DD/MM/YY HH:mm a')}
                          <ul className='forecasts'>
                            <li className='forecasts__item'>
                              <RainIcon />
                              <span>{forecast?.rain}</span>
                            </li>
                            <li className='forecasts__item'>
                              <VisHorizontalIcon />
                              <span>{forecast?.vis_horizontal}</span>
                            </li>
                            <li className='forecasts__item'>
                              <WindIcon />
                              <span>{forecast?.wind}</span>
                            </li>
                          </ul>
                        </li>
                      );
                    })}
                  </ul>
                </>
              )}
            </div>
          </Popup>
        </CircleMarker>
      ))}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getDataPoint: (marker) => getForecastDataPoint(marker),
  setPointsToGenerateReport: (data) =>
    dispatch({ type: MY_ROUTES_SET_POINTS_TO_GENERATE_REPORT, payload: data }),
  convertToGeoJSON: (route, markers) => {
    const features = [
      transformRouteToGeoJSON(route),
      ...transformMarkersToGeoJSON(markers),
    ];

    dispatch({ type: MY_ROUTES_SET_GEOJSON_ROUTE, payload: features });
  },
});

const mapStateToProps = (state) => ({
  origin: state.myRoutes.origin,
  destination: state.myRoutes.destination,
});

export default connect(mapStateToProps, mapDispatchToProps)(CreateMyRoute);
