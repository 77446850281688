import React, { useEffect, useState } from 'react';
import { Badge, Button, Tooltip } from 'antd';
import { ExclamationCircleOutlined, ProfileOutlined } from '@ant-design/icons';
import { checkPendingParticipants } from '../../../../services/requestServicesItems';

const ButtonRsiParticipants = ({ onClick, requestServiceItemId }) => {
  const [participantsAlerts, setParticipantsAlerts] = useState('');

  useEffect(() => {
    checkPendingParticipants(requestServiceItemId).then((data) => {
      if (data.existCoursePendingRating || data.pendingParticipant)
        setParticipantsAlerts('Calificaciones pendientes');
    });
  }, [requestServiceItemId]);

  return (
    <Tooltip title={participantsAlerts ? participantsAlerts : 'Participantes'}>
      <Badge
        dot={participantsAlerts}
        count={
          participantsAlerts ? (
            <ExclamationCircleOutlined style={{ color: '#EF3340' }} />
          ) : null
        }
      >
        <Button size='small' className='btn-cmo-action-list' onClick={onClick}>
          <ProfileOutlined />
        </Button>
      </Badge>
    </Tooltip>
  );
};

export default ButtonRsiParticipants;
