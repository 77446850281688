import React, { useState, useCallback, useMemo, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Divider, notification } from 'antd';
import { LeftCircleOutlined, LeftOutlined } from '@ant-design/icons';

import { createUser, updateUser } from '../../../../../../../services/users';
import { getRols } from '../../../../../../../services/rols';
import { checkPermission } from '../../../../../../../lib/security';
import { useScreen } from '../../../../../../../lib/screen';

import InputWithLabel, {
  SelectWithLabel,
} from '../../../../../../../common/InputWithLabel';
import Title from '../../../../../../../common/Typograph/Title';

const isValidField = (type, value) => {
  switch (type) {
    case 'number':
      return Boolean(value);
    case 'text':
      return Boolean(value?.trim().length);
    case 'email':
      const regExp = new RegExp(
        /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
      );

      return regExp.test(value?.trim());
    default:
      return true;
  }
};

const fields = [
  {
    key: 'name',
    type: 'text',
    label: 'Nombres',
    valid: 'text',
    required: true,
  },
  {
    key: 'last_name1',
    type: 'text',
    label: 'Apellido paterno',
    valid: 'text',
    required: true,
  },
  {
    key: 'last_name2',
    type: 'text',
    label: 'Apellido materno',
    valid: 'text',
    required: true,
  },
  {
    key: 'username',
    type: 'text',
    label: 'Correo del usuario',
    valid: 'email',
    required: true,
  },
  {
    key: 'phone',
    type: 'number',
    label: 'Celular',
    optional: true,
  },
  {
    key: 'position',
    type: 'text',
    label: 'Cargo',
    valid: 'text',
    required: true,
  },
  {
    key: 'rol_id',
    type: 'text',
    valid: 'number',
    label: 'Rol',
    required: true,
  },
  {
    key: 'state',
    type: 'text',
    label: 'Estado',
  },
];

const UserForm = ({ onBack, userData = {} }) => {
  const dispatch = useDispatch();
  const { isMobileScreen } = useScreen();
  const [loadingForm, setLoading] = useState(false);
  const [formData, setFormData] = useState(() => userData || {});
  const { userAuthenticated, rols, totalUsers } = useSelector((state) => ({
    rols: state.rols.rols,
    userAuthenticated: state.auth.user,
    errors: state.users.errors,
    isLoadingCreate: state.users.isLoadingCreate,
    totalUsers: state.users.totalUsers,
  }));

  useEffect(() => {
    getRols(dispatch, userAuthenticated);
  }, []);

  const rolsListMemo = useMemo(() => {
    return rols.map((rol) => ({
      disabled:
        rol.slug === 'admin_rimac' &&
        !checkPermission(
          userAuthenticated,
          'allow_to_create_users_admin_rimac',
        ),
      ...rol,
    }));
  }, [rols, userAuthenticated]);

  const disabledNext = useMemo(() => {
    if (userData?.id) {
      return fields
        .map((field) => formData[field.key] === userData[field.key])
        .every((isEqual) => isEqual);
    }

    return (
      fields?.reduce((result, elem) => {
        if (elem.required) {
          return result || !isValidField(elem.valid, formData[elem.key]);
        }

        return result;
      }, false) || false
    );
  }, [formData, userData]);

  const _handleChangeField = useCallback(({ target: { value, name } }) => {
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }, []);

  const createNewUser = useCallback(
    async (user, totalUsers) => {
      user = Object.fromEntries(
        Object.entries(user).filter(([key, value]) => key !== 'state'),
      );
      //trim all values from the object before sending it to the backend
      Object.keys(user).forEach((key) => {
        if (typeof user[key] === 'string') {
          user[key] = user[key].trim();
        }
      });
      const created = await createUser(
        user,
        dispatch,
        { limit: 5, page: 1 },
        totalUsers,
      );
      if (created) {
        notification.success({
          description: 'Usuario creado correctamente.',
          message: '¡Perfil empresa!',
        });
        onBack();
      } else {
        notification.warning({
          description: 'Ha ocurrido un error.',
          message: '¡Perfil empresa!',
        });
      }
      setLoading(false);
    },
    [dispatch, onBack],
  );

  const updateUserInfo = useCallback(
    async (user) => {
      const updated = await updateUser(
        user,
        dispatch,
        { pageSize: 5, current: 1 },
        userAuthenticated,
      );

      if (updated) {
        notification.success({
          description: 'Usuario actualizado correctamente.',
          message: '¡Perfil empresa!',
        });
        onBack();
      } else {
        notification.warning({
          description: 'Ha ocurrido un error.',
          message: '¡Perfil empresa!',
        });
      }
      setLoading(false);
    },
    [dispatch, userAuthenticated, onBack],
  );

  const _handleAction = useCallback(() => {
    setLoading(true);

    const data = {
      ...formData,
      state: Number(formData.state),
    };

    if (data.id) updateUserInfo(data);
    else createNewUser(data, totalUsers);
  }, [createNewUser, formData, totalUsers, updateUserInfo]);

  return (
    <div className='d_content service-prevention'>
      {isMobileScreen ? (
        <Button
          icon={<LeftCircleOutlined />}
          type='link'
          size='large'
          onClick={onBack}
        >
          Volver
        </Button>
      ) : null}
      <div
        className={`flex ${
          isMobileScreen ? 'flex-col' : 'items-center justify-between'
        }`}
      >
        <div className='flex items-center'>
          {!isMobileScreen ? (
            <Button
              className='mr-1 flex items-center'
              type='link'
              size='large'
              onClick={onBack}
            >
              <LeftOutlined />
            </Button>
          ) : null}
          <Title type={isMobileScreen ? 'bold-32' : 'bold-28'}>
            {userData?.id ? 'Editar' : 'Crear'} Usuario
          </Title>
        </div>
      </div>
      <Divider />
      <div
        className={'flex flex-col full-width'}
        style={{ paddingBottom: isMobileScreen ? 132 : 116 }}
      >
        <div className={`flex ${isMobileScreen ? 'flex-col' : ''}`}>
          <div
            className='flex-1'
            style={{
              maxWidth: 400,
            }}
          >
            {fields.map(({ key, type = 'text', label, ...props }) => {
              switch (key) {
                case 'rol_id':
                  return (
                    <SelectWithLabel
                      className='mb-2'
                      key={key}
                      name={key}
                      value={formData[key]}
                      onChange={(selected) => {
                        setFormData((prev) => ({
                          ...prev,
                          [key]: selected,
                        }));
                      }}
                      options={key === 'rol_id' ? rolsListMemo : []}
                      label={label}
                      disabled={key === 'rol_id' ? Boolean(userData.id) : false}
                      style={{
                        width: '100%',
                      }}
                      {...props}
                    />
                  );
                case 'state':
                  if (!userData?.id) {
                    return null;
                  }

                  return (
                    <SelectWithLabel
                      className='mb-2'
                      key={key}
                      name={key}
                      disabled={
                        ![1, 3].includes(userData.state) &&
                        !Boolean(userData.activation_date)
                      }
                      value={String(formData[key])}
                      onChange={(selected) => {
                        setFormData((prev) => ({
                          ...prev,
                          [key]: selected,
                        }));
                      }}
                      options={[
                        { value: '1', label: 'Activo' },
                        { value: '0', label: 'No activo' },
                        { value: '3', label: 'Eliminado' },
                      ]}
                      label={label}
                    />
                  );
                default:
                  return (
                    <InputWithLabel
                      className='mb-2'
                      key={key}
                      name={key}
                      type={type}
                      onChange={_handleChangeField}
                      value={formData[key]}
                      disabled={
                        key === 'username' ? Boolean(userData?.id) : false
                      }
                      label={label}
                      {...props}
                    />
                  );
              }
            })}
          </div>
        </div>
      </div>
      <Button
        disabled={disabledNext}
        style={{
          width: 200,
        }}
        loading={loadingForm}
        type='primary'
        size='large'
        onClick={_handleAction}
      >
        {userData?.id ? 'Editar' : 'Crear'}
      </Button>
    </div>
  );
};

export default memo(UserForm);
