import React, { useEffect } from 'react';
import { Form, Radio, Button, Input } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import {
  createRiskRainService,
  presignedRainRiskService,
} from '../../../../services/requestServices';
import UploadEvidenceFile from './UploadEvidenceFile';
import cryptoUtils from '../../../../lib/crypto';

const { Item } = Form;

const keyForm = 'stockForm';

const questionsLabels = [
  {
    label:
      'Las existencias suceptibles a daño por agua (de lluvia o inundación) se encuentran debidamente protegidas, bajo techo y/o a un nivel razonable del suelo',
    key: 'stockQuestion1',
  },
  {
    label:
      'Las existencias que se almacenan a la intemperie no se ven afectadas por las lluvias',
    key: 'stockQuestion2',
  },
  {
    label: 'Contenido de oficinas no expuesto',
    key: 'stockQuestion3',
  },
];

const StockForm = ({ setCurrent, current }) => {
  const [form] = Form.useForm();
  const data = useSelector((state) => ({
    stockForm: state.rainForm.stockForm,
    polizaId: state.rainForm.polizaId,
  }));
  const dispatch = useDispatch();

  const createFormulary = (formValues) => {
    createRiskRainService(
      {
        key: keyForm,
        value: formValues.map(({ key, label, value, file, comment }) => ({
          key,
          label,
          value,
          file,
          comment,
        })),
      },
      data.polizaId,
    ).then(() => {
      setCurrent(current + 1);
      const topBarDiv = document.getElementsByClassName('topBar')[0];
      if (topBarDiv) {
        topBarDiv.scrollIntoView({ behavior: 'smooth' });
      }
    });
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      if (values) {
        dispatch({
          type: 'UPDATE_FORM_STOCK_VALUES',
          payload: { ...(data?.stockForm || {}), ...values },
        });

        const formValues = questionsLabels.map((question) => ({
          key: question.key,
          label: question.label,
          value: values[question.key],
          comment: values[`${question.key}-comment`],
          fileObject: values[`${question.key}-fileObject`],
          file: values[`${question.key}-fileObject`]
            ? `${cryptoUtils.randomStr(5)}-${
                values[question.key + '-fileObject'].name
              }`
            : null,
        }));

        const files = formValues.filter(
          (e) => e.fileObject && typeof e.fileObject === 'object',
        );
        if (files && files.length) {
          Promise.all(
            files.map(({ fileObject, file }) =>
              presignedRainRiskService(fileObject, file),
            ),
          ).then(() => {
            createFormulary(formValues);
          });
        } else {
          createFormulary(formValues);
        }
      }
    });
  };

  useEffect(() => {
    form.setFieldsValue(data.stockForm);

    questionsLabels.forEach((question) => {
      if (data.stockForm[`${question.key}-file`]) {
        const name = data.stockForm[`${question.key}-file`];
        const fileUrl = `${process.env.REACT_APP_WEB_PATH_S3_NEW}rain_risk/${name}`;
        fetch(fileUrl)
          .then((res) => res.blob())
          .then((blob) => {
            const file = new File([blob], name);
            form.setFieldsValue({ [`${question.key}-fileObject`]: file });
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });
  }, []);

  return (
    <Form form={form} onFinish={handleSubmit} layout='vertical'>
      {questionsLabels.map((question) => (
        <React.Fragment key={question.key}>
          <Item label={question.label} name={question.key}>
            <Radio.Group>
              <Radio value='1'>Cumple</Radio>
              <Radio value='2'>No cumple</Radio>
              <Radio value='3'>No aplica</Radio>
            </Radio.Group>
          </Item>
          <UploadEvidenceFile
            form={form}
            keyValue={question.key}
            value={form.getFieldValue(`${question.key}-file`)}
            onUpdateFile={(file) => {
              form.setFieldsValue({ [`${question.key}-fileObject`]: file });
            }}
          />
          <Item label='Comentario' name={`${question.key}-comment`}>
            <Input.TextArea />
          </Item>
        </React.Fragment>
      ))}
      <div className='flex gap-2 justify-center mb-3'>
        <Button
          type='primary'
          size='large'
          onClick={() => setCurrent(current - 1)}
        >
          <LeftOutlined />
          Anterior
        </Button>
        <Button type='primary' size='large' htmlType='submit'>
          Siguiente
          <RightOutlined />
        </Button>
      </div>
    </Form>
  );
};

export default StockForm;
