import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import Input from '../../../common/Input';
import Button from '../../../common/Button';

const FormForgotPassword = () => {
  const emailRef = useRef();
  const { register, handleSubmit } = useForm();
  return (
    <form onSubmit={handleSubmit(() => console.log('submit'))}>
      <Input
        ref={emailRef}
        name='email'
        type='email'
        label='Correo electrónico'
        register={register}
      />
      <Button type='submit'>Enviar</Button>
    </form>
  );
};

export default FormForgotPassword;
