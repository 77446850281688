import React, { useState } from 'react';
import { Button, Divider, Modal, notification } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import { useScreen } from '../../../../lib/screen';
import { formatLocationRows } from './utils';
import { createBulkLocationsAdmin } from '../../../../services/locations';

import Title from '../../../../common/Typograph/Title';
import InputFile from '../../../../common/InputFile';

const ModalForm = ({ handleClose, visible }) => {
  const [locationsData, setLocationsData] = useState(null);
  const [isloading, setIsLoading] = useState(false);
  const { isMobileScreen } = useScreen();

  const handleProcessExcelData = (rows) => {
    const rowsFormatted = formatLocationRows(rows, { isAdmin: true });
    setLocationsData(rowsFormatted);
  };

  const handleOk = async () => {
    try {
      setIsLoading(true);
      const areCreated = await createBulkLocationsAdmin(locationsData);
      areCreated
        ? notification.success({
            message: '¡Archivo subido!',
            description:
              locationsData.length === 1
                ? `Se registró 1 ubicación.`
                : `Se registraron ${locationsData.length} ubicaciones.`,
          })
        : notification.error({
            description: 'Hubo un error al subir archivo.',
            message: 'Error',
          });

      handleClose();
    } catch (error) {
      notification.error({
        description: 'Hubo un error al subir archivo.',
        message: 'Error',
      });
    } finally {
      setIsLoading(false);
      setLocationsData(null);
    }
  };

  return (
    <Modal
      open={visible}
      title=''
      closable={true}
      onCancel={handleClose}
      centered={true}
      onOk={handleOk}
      className={`modal-provider modal-centered ${
        isMobileScreen ? 'modal-mobile' : ''
      }`}
      cancelText='Cancelar'
      okText='Subir CSV'
    >
      <div
        style={
          isMobileScreen
            ? { width: '100%' }
            : { padding: '64px 64px 12px 64px', minWidth: 528 }
        }
      >
        <Title type='bold-28' style={{ textAlign: 'center' }}>
          Cargar CSV con las Ubicaciones Favoritas
        </Title>
        <Divider className='mt-2 mb-4' />
        <InputFile
          allowClear={true}
          title={'Ubicaciones Favoritas'}
          withoutPreview
          processExcelData={handleProcessExcelData}
        />
        <Button
          className='mt-3'
          href='/templates/PlantillaCargaDeUbicacionesAdm.xlsx'
          icon={<DownloadOutlined />}
          type='link'
          loading={isloading}
        >
          Descargar plantilla de Ubicaciones Favoritas
        </Button>
      </div>
    </Modal>
  );
};

export default ModalForm;
