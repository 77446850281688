import React, { memo, useState } from 'react';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DownloadOutlined, PlusOutlined } from '@ant-design/icons';

import { useScreen } from '../../../../lib/screen';
import { downloadClientsTracking } from '../../../../services/clientTracking';

import Title from '../../../../common/Typograph/Title';
import Topbar from '../../../../common/ui/Topbar';
import ListClients from './ListClients';
import Tabs from '../../../../common/Tabs';
import {
  RideButton,
  RideGlDownloadOutline,
} from '@rimac-seguros/ride-system-components';

const LIST_TABS = [
  {
    key: 'tracking',
    label: 'Seguimiento',
    children: <ListClients />,
  },
];

const ClientTraking = () => {
  const { isMobileScreen } = useScreen();
  const navigate = useNavigate();
  const filters = useSelector((state) => state.clientTracking.filters);
  const [activeTab, setActiveTab] = useState(LIST_TABS[0]);
  const [isLoadingReport, setIsLoadingReport] = useState(false);

  const handleDownload = async () => {
    setIsLoadingReport(true);
    await downloadClientsTracking(filters)
      .then((excel) => window.open(excel))
      .finally(() => setIsLoadingReport(false));
  };

  return (
    <div id='checkSize' className='h100vh dashboard-UI'>
      <Topbar />
      <div className='d_content'>
        <div className='flex justify-between items-center mb-2'>
          <div className='flex justify-between items-center'>
            <Title className='header-container'>Clientes</Title>
          </div>
          {!isMobileScreen ? (
            <div className='flex gap-4'>
              <RideButton
                variant='text-primary'
                onClick={handleDownload}
                text='Descargar'
                glyph={<RideGlDownloadOutline />}
                loading={isLoadingReport}
                size='medium'
                glyphPosition='left'
              />
              <RideButton
                variant='fill'
                onClick={() => {
                  navigate('/administracion/seguimiento-a-clientes/form');
                }}
                text='Realizar Seguimiento'
                size='medium'
              />
            </div>
          ) : null}
        </div>

        <Tabs
          activeKey={activeTab.key}
          onChange={(activeKey) =>
            setActiveTab(LIST_TABS.find((el) => el.key === activeKey))
          }
          items={LIST_TABS}
        />
      </div>

      {isMobileScreen && (
        <div
          className={'fixed-bottom p-3 full-width'}
          style={{ background: '#EDEFFC' }}
        >
          <span
            className='full-width absolute'
            style={{
              display: 'flex',
              justifyContent: 'center',
              height: 20,
              top: -12,
              left: 0,
              background:
                'linear-gradient(to bottom, rgba(255,0,0,0), #EDEFFC)',
            }}
          >
            {isMobileScreen && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 8,
                  alignItems: 'center',
                  maxWidth: 300,
                }}
              >
                <Button
                  type='link'
                  className='mr-3'
                  style={{
                    cursor: isLoadingReport ? 'none' : 'pointer',
                  }}
                  onClick={handleDownload}
                  icon={<DownloadOutlined />}
                >
                  Descargar
                </Button>
                <Button
                  type='primary'
                  size={isMobileScreen ? 'small' : 'large'}
                  onClick={() => {
                    navigate('/administracion/seguimiento-a-clientes/form');
                  }}
                  icon={<PlusOutlined />}
                >
                  Realizar Seguimiento
                </Button>
              </div>
            )}
          </span>
        </div>
      )}
    </div>
  );
};

export default memo(ClientTraking);
