import React, { useEffect, useRef, useState } from 'react';
import { GeoJSON, useMap } from 'react-leaflet';
import axios from 'axios';
import JSZip from 'jszip';
import 'leaflet/dist/leaflet.css';

import { extractShapes } from '../../../../services/shapeUtils';

const Index = ({ layer, fitBounds }) => {
  const [geojson, setGeojson] = useState(null);
  const map = useMap();
  const shapeLayer = useRef();

  useEffect(() => {
    (async () => {
      if (layer) {
        const res = await axios.get(layer?.url, {
          responseType: 'arraybuffer',
        });
        if (res.status === 200) {
          let file = new File([res.data], 'data.zip', {
            type: 'text/plain',
          });

          if (layer.typeFile === 'shp') {
            const filezip = new JSZip();
            filezip.file('file.shp', res.data);
            file = await filezip.generateAsync();
          }

          const geojson = await extractShapes([file]);

          setGeojson(geojson);

          if (fitBounds)
            setTimeout(() => {
              map.fitBounds(shapeLayer.current.getBounds());
            }, 200);
        }
      }
    })();
  }, [layer]);

  return geojson ? <GeoJSON ref={shapeLayer} data={geojson} /> : null;
};

export default Index;
