import { get, post } from '../lib/ajax';
import axios from 'axios';
import {
  MY_ROUTES_IS_LOADING_LIST,
  MY_ROUTES_SET_LIST,
} from '../redux/actions/actionsType';

export const getPresignedMyRoutesUrl = (payload) =>
  post('/my_routes/get_my_routes_url_to_import', payload);

export const sendGeoJSONFile = (url, data) => axios.put(url, data);

export const createMyRoute = async (data) => {
  try {
    const r = await post('/my_routes/create', data);
    if (r.data.success) {
      return null;
    }

    return data;
  } catch (e) {
    console.error(e);
    return null;
  }
};

export const getMyRoutes = async (dispatch) => {
  try {
    dispatch({ type: MY_ROUTES_IS_LOADING_LIST, payload: true });
    const r = await get('/my_routes/list');

    if (r.data.success) {
      dispatch({ type: MY_ROUTES_SET_LIST, payload: r.data.list });
    }
  } catch (e) {
    console.error(e);
  } finally {
    dispatch({ type: MY_ROUTES_IS_LOADING_LIST, payload: false });
  }
};

export const deleteMyRoute = async (dispatch, id) => {
  try {
    dispatch({ type: MY_ROUTES_IS_LOADING_LIST, payload: true });
    const r = await post(`/my_routes/delete?id=${id}`);
    return !r.data.success;
  } catch (e) {
    console.error(e);
    return true;
  } finally {
    dispatch({ type: MY_ROUTES_IS_LOADING_LIST, payload: false });
  }
};

export const getForecastDataPoint = async (point) => {
  try {
    const r = await post('/my_routes/forecastPoint', point);

    if (r?.data?.success) {
      return r.data.payload;
    }
    return [];
  } catch (error) {
    console.error(error);

    return [];
  }
};

export const getReportURL = async (points) => {
  try {
    const r = await post('/my_routes/forecast', points);

    if (r?.data?.success) {
      return r.data.result?.url;
    }

    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};
