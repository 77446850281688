import React from 'react';
import { Select, Slider } from 'antd';
import { PauseOutlined, CaretRightFilled } from '@ant-design/icons';

const AnimatedControls = ({
  activeSubLayer,
  isAnimate,
  onMouseEnterToControls,
  onMouseExitOfControls,
  isLoadingScenes,
  selectedHour,
  handleSelectHour,
  computedWidth,
  onPlayAction,
  isPlaying,
  selectedSceneBar,
  defaultScene,
  onAfterChangeBar,
  onChangeBar,
  formatterTooltip,
  idxTimes,
  generateMarksTimes,
}) => {
  return activeSubLayer?.animate && isAnimate ? (
    <a
      data-testid='animated-controls-link'
      onTouchStart={onMouseEnterToControls}
      onTouchEnd={onMouseExitOfControls}
      onMouseEnter={onMouseEnterToControls}
      onMouseLeave={onMouseExitOfControls}
      className={`animated-controls-layers ${isLoadingScenes ? 'loading' : ''}`}
    >
      {activeSubLayer?.animate?.realtime && (
        <div className='select-controls'>
          <Select value={selectedHour} onChange={handleSelectHour}>
            <Select.Option value={'12h'}>12 h</Select.Option>
            <Select.Option value={'6h'}>6 h</Select.Option>
            <Select.Option value={'2h'}>2 h</Select.Option>
          </Select>
        </div>
      )}
      <div data-testid='play-controls' className='play-controls'>
        <button className='play-pause' onClick={() => onPlayAction()}>
          {isPlaying ? (
            <PauseOutlined className='action-pause' data-testid='pause-icon' />
          ) : (
            <CaretRightFilled className='action-play' data-testid='play-icon' />
          )}
        </button>
        <div className='bar-controls'>
          <Slider
            data-testid='slider'
            tooltip={{
              open: true,
              formatter: formatterTooltip,
              color: 'rgba(0, 0, 0, 0.55)',
              overlayStyle: {
                fontSize: '10px',
              },
            }}
            value={selectedSceneBar || defaultScene}
            onChangeComplete={onAfterChangeBar}
            onChange={onChangeBar}
            min={1}
            max={
              activeSubLayer?.animate?.realtime
                ? activeSubLayer?.animate.times[idxTimes]
                : activeSubLayer?.animate.times
            }
            marks={generateMarksTimes.marks}
          />
        </div>
      </div>
    </a>
  ) : null;
};

export default AnimatedControls;
