import React from 'react';
import { Button, Card } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';

const CardSimulationsMobile = ({ item, onOpenOptionsMobile, columns }) => {
  return (
    <Card style={{ width: '100%' }} className='d_content__card'>
      <div className='d_content__card__button'>
        <Button onClick={onOpenOptionsMobile}>
          <EllipsisOutlined />
        </Button>
      </div>
      <div className='d_content__card__body' style={{ marginTop: -12 }}>
        {columns.map(({ key, dataIndex, title, render }) => (
          <div key={key} className='card-detail__item'>
            <h4>{title}</h4>
            <p>{(render ? render(item[dataIndex]) : item[dataIndex]) || '-'}</p>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default CardSimulationsMobile;
