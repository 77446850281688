import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Card, Skeleton, Divider, Select } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import { useScreen } from '../../../../lib/screen';
import { getAlertsTracking } from '../../../../services/tracking';
import { TRACKING_UPDATE_FILTERS } from '../../../../redux/actions/actionsType';

import { columns, filtersCommon } from './columns';
import SimpleTable from '../../../../common/Table/SimpleTable';
import Title from '../../../../common/Typograph/Title';
import FiltersMobile from '../../../../common/FiltersMobile';
import PaginationMobile from '../../../../common/PaginationMobile';
import CardUserMobile from '../common/CardUserMobile';
import {
  RideButton,
  RideGlDeleteSolid,
} from '@rimac-seguros/ride-system-components';

const initialFilterOptions = {
  isActive: [
    { label: 'Activo', value: 1 },
    { label: 'Inactivo', value: '0' },
  ],
  typeSend: [
    { label: 'Boletín', value: 'boletin' },
    { label: 'Alerta', value: 'alerta' },
  ],
};

const ListAlertsNewsletters = ({
  list,
  isLoading,
  pagination,
  filters,
  zones,
  setFilters,
}) => {
  const dispatch = useDispatch();
  const { isMobileScreen } = useScreen();
  const [filtersOptions, setFiltersOptions] = useState(initialFilterOptions);

  const transformDates = (dateFilters) => {
    const dates = dateFilters.date;
    if (dates.length > 0) {
      const dateInitial = moment(dates[0]).format('YYYY-MM-DDT00:00:00-05:00');

      const dateFinal = moment(dates[1]).format('YYYY-MM-DDT23:59:59-05:00');

      return {
        end: dateFinal,
        start: dateInitial,
      };
    }
  };

  const getAlertsByFilters = useCallback(async (alertFilters, page) => {
    await getAlertsTracking({ filters: alertFilters, page }, dispatch);
  }, []);

  const handleTableChange = async (pag) => {
    const copyFilter = structuredClone(filters);
    if (filters.hasOwnProperty('date')) {
      copyFilter.date = transformDates(filters);
    }
    await getAlertsTracking(
      { filters: copyFilter, page: pag.current },
      dispatch,
    );
  };

  const handleMobileChange = async (pag) => {
    const copyFilter = structuredClone(filters);
    if (filters.hasOwnProperty('date')) {
      copyFilter.date = transformDates(filters);
    }
    await getAlertsTracking({ filters: copyFilter, page: pag }, dispatch);
  };

  useEffect(() => {
    getAlertsByFilters();
  }, []);

  useEffect(() => {
    setFiltersOptions((prev) => ({
      ...prev,
      departments: zones,
      provinces: zones
        .map((zone) => zone.children)
        .flat()
        .sort((a, b) => a.label.localeCompare(b.label)),
    }));
  }, [zones]);

  const _handleSelectOption = useCallback(
    (key, selected, filtered) => {
      switch (key) {
        case 'departments':
          const provinces = zones
            .find((zone) => zone.value === selected)
            .children.sort((a, b) => a.label.localeCompare(b.label));
          setFiltersOptions((prev) => ({ ...prev, provinces }));
          if (filters.hasOwnProperty('provinces')) {
            const { provinces, ...rest } = filters;
            setFilters({ ...rest, [key]: selected });
          } else {
            setFilters({ ...filters, [key]: selected });
          }

          break;
        case 'date':
          if (selected && selected.length > 0) {
            setFilters({
              ...filters,
              [key]: selected,
            });
          }

          break;
        default:
          setFilters({ ...filters, [key]: selected });
          if (filtered) {
            getAlertsByFilters({ ...filters, [key]: selected });
          }
      }
    },
    [filters, zones],
  );

  const onFinishFilters = useCallback(() => {
    const allFilters = { ...filtersOptions, ...filters };
    let filtersOpts = {};

    Object.keys(allFilters).forEach((key) => {
      if (typeof allFilters[key] !== 'object') {
        filtersOpts[key] = allFilters[key];
      } else {
        const filterOpts = allFilters[key].filter((item) => item.selected);
        if (filterOpts?.length) {
          filtersOpts[key] = allFilters[key].filter((item) => item.selected);
        }
      }
    });

    if (allFilters.hasOwnProperty('date')) {
      filtersOpts.date = transformDates(allFilters);
    }

    getAlertsByFilters(filtersOpts);
  }, [filters, filtersOptions]);

  const onClearFilters = () => {
    setFilters({});
    Object.keys(filtersOptions).forEach((name) => {
      setFiltersOptions((prev) => {
        const newcols = prev[name].map((el) => {
          return {
            ...el,
            selected: false,
          };
        });

        return { ...prev, [name]: newcols };
      });
    });
    getAlertsByFilters({});
  };

  return (
    <div>
      {!isMobileScreen ? (
        <>
          <div
            className={`flex justify-between ${
              isMobileScreen ? 'flex-col pt-2 mb-3' : 'items-center mt-1 mb-4'
            }`}
          >
            <Title>Filtrar</Title>
          </div>
          <div className='flex mb-4' style={{ gap: '20px' }}>
            {filtersCommon.slice(0, 2).map(({ key, placeholder, type }) => (
              <Fragment key={key}>
                {type === 'select' && (
                  <Select
                    key={key}
                    style={{ width: '100%', maxWidth: '150px' }}
                    placeholder={placeholder}
                    options={filtersOptions[key].map((option) => ({
                      label: option.label,
                      value: option.value,
                    }))}
                    onChange={(value) => _handleSelectOption(key, value, 1)}
                    value={filters[key]}
                  />
                )}
              </Fragment>
            ))}
            <div className='flex items-center flex-1 gap-2'>
              <FiltersMobile
                buttonTitle={'Más filtro'}
                onClearFilters={onClearFilters}
                onFinishFilters={onFinishFilters}
                filters={filtersCommon.map(({ key, type, placeholder }) => ({
                  key,
                  label: placeholder,
                  typeFilter: type,
                  options: filtersOptions[key] || [],
                  onChange: (selected) => {
                    _handleSelectOption(key, selected);
                  },
                  value: filters[key],
                  placeholder,
                }))}
              />
              <RideButton
                variant='text-primary'
                onClick={onClearFilters}
                size='small'
                text='Borrar filtros'
                glyph={<RideGlDeleteSolid />}
                glyphPosition='left'
              />
            </div>
          </div>
        </>
      ) : null}

      <div className='flex justify-between items-center mt-2 mb-2'>
        {isMobileScreen ? (
          <FiltersMobile
            onClearFilters={onClearFilters}
            onFinishFilters={onFinishFilters}
            filters={filtersCommon.map(({ key, placeholder, type }) => ({
              key,
              label: placeholder,
              typeFilter: type,
              options: filtersOptions[key] || [],
              onChange: (selected) => {
                _handleSelectOption(key, selected);
              },
              value: filters[key],
              placeholder,
            }))}
          />
        ) : null}
      </div>
      {isMobileScreen ? (
        <Fragment>
          <Divider />
          {isLoading ? (
            [1, 2, 3].map((i) => (
              <Card className='mt-2' key={i}>
                <Skeleton />
              </Card>
            ))
          ) : (
            <>
              {list.map((value) => (
                <CardUserMobile key={value.id} columns={columns} item={value} />
              ))}
              <PaginationMobile
                current={pagination.page}
                total={pagination.total}
                pageSize={5}
                onChange={handleMobileChange}
              />
            </>
          )}
        </Fragment>
      ) : null}
      {!isMobileScreen && (
        <SimpleTable
          className={'mt-4 mb-4'}
          loading={isLoading}
          columns={columns}
          dataSource={
            list
              ? list.map((el) => ({
                  ...el,
                  id: uuidv4(),
                }))
              : []
          }
          pagination={{
            current: pagination.page,
            total: pagination.total,
          }}
          onChange={handleTableChange}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setFilters: (data) =>
    dispatch({ type: TRACKING_UPDATE_FILTERS, payload: data }),
});

const mapStateToProps = (state) => ({
  list: state.tracking.list,
  isLoading: state.tracking.isLoading,
  pagination: state.tracking.pagination,
  filters: state.tracking.filters,
  zones: state.zones.zones,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(memo(ListAlertsNewsletters));
