export const circleStyle = {
  color: '#A0A0FA',
  fillColor: '#A0A0FA',
  fillOpacity: 0.32,
  border: '6px solid #432EFF',
  weight: 6,
};

export const ramoOptions = [
  {
    value: 'TMTO',
    text: 'Terremoto',
  },
  {
    value: 'CONS',
    text: 'Contrucción',
  },
  {
    value: 'OBCT',
    text: 'Obras civiles terminadas',
  },
  {
    value: 'MONT',
    text: 'Montaje',
  },
  {
    value: 'EQEL',
    text: 'Equipo Eléctrico',
  },
  {
    value: 'TREC',
    text: 'Equipo y maquinaria de contratista',
  },
];

export const tipoGeoOptions = [
  {
    value: '0',
    text: 'Gps',
  },
  {
    value: '1',
    text: 'Georeferencia exacta',
  },
  {
    value: '1C',
    text: 'Georeferencia exacta (C)',
  },
  {
    value: '2',
    text: 'Centro de la vía',
  },
  {
    value: '2C',
    text: 'Centro de la vía (C)',
  },
  {
    value: '3',
    text: 'Centro del distrito',
  },
  {
    value: '4',
    text: 'Centro del CCHH',
  },
  {
    value: '5',
    text: 'Centro de la Manzana',
  },
];

export const yearsOptions = () => {
  const now = new Date().getUTCFullYear();

  return Array(now - (now - 100))
    .fill('')
    .map((v, idx) => now - idx);
};
