import React, { memo, useState } from 'react';
import Topbar from '../../../common/ui/Topbar';
import RiskEvaluationInit from './RiskEvaluationInit';
import PersonalDataForm from './PersonalDataForm';
import FormStepper from './FormStepper';
import LastStepForm from './LastStepForm';
import DoneView from './DoneView';

const RiskAssessment = () => {
  const [step, setStep] = useState(1);

  const _handleNext = (value) => {
    setStep(value ? value : step + 1);
  };

  const _handleBack = (value) => {
    setStep(value ? value : step - 1);
  };

  return (
    <div id='checkSize' className='h100vh dashboard-UI'>
      <Topbar />
      {step === 1 && <RiskEvaluationInit onNext={_handleNext} />}
      {step === 2 && (
        <PersonalDataForm onBack={_handleBack} onNext={_handleNext} />
      )}
      {step === 3 && <FormStepper onBack={_handleBack} onNext={_handleNext} />}
      {step === 4 && <LastStepForm />}
      {step === 5 && <DoneView />}
    </div>
  );
};

export default memo(RiskAssessment);
