import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DownOutlined } from '@ant-design/icons';

import { layers } from '../../../common/SideMenu/data';
import { TRACK } from '../../../lib/consts';
import { closePopUp, pushDigitalData } from '../../../lib/utils';
import { useScreen } from '../../../lib/screen';
import { MAP_SET_ACTIVE_SUBLAYER } from '../../../redux/actions/actionsType';
import { renderIcon } from '../../../common/ui/ControlMap/renderIcon';

const SublayerMapTop = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const activeLayer = useSelector((state) => state.maps.activeLayer);
  const activeSubLayer = useSelector((state) => state.maps.activeSubLayer);
  const drawerVisible = useSelector((state) => state.controlMap.drawerVisible);
  const siderVisible = useSelector((state) => state.controlMap.siderVisible);
  const selected = useSelector((state) => state.controlMap.selected);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [options, setOptions] = useState([]);
  const [optionsSelect, setOptionsSelect] = useState(null);
  const { widthScreen } = useScreen();

  const rol = user.rol?.slug;

  useEffect(() => {
    if (activeLayer && activeLayer.mode) {
      if (activeLayer.id === 'satellite' && rol === 'persona_natural') {
        setOptions([activeLayer.mode[1]]);
        setOptionsSelect(activeLayer.mode[1]);
      } else {
        setOptions([...activeLayer.mode]);

        const subLayerActive = activeLayer.mode.find((subLayer) => {
          if (subLayer?.id && activeSubLayer?.id) {
            return subLayer?.id === activeSubLayer?.id;
          }
        });

        subLayerActive
          ? setOptionsSelect(subLayerActive)
          : setOptionsSelect(activeLayer.mode[0]);
      }
    } else {
      setOptions([]);
    }
  }, [activeLayer]);

  useEffect(() => {
    if (widthScreen <= 768) {
      setIsMobile(true);
      setOpenDropdown(false);
    } else {
      setIsMobile(false);
    }
  }, [widthScreen]);

  const setActiveSubLayer = (layer) =>
    dispatch({ type: MAP_SET_ACTIVE_SUBLAYER, payload: layer });

  const handlePushDigitalData = (layer) => {
    if (process.env.REACT_APP_ANALYTICS_ENABLE !== '0') {
      const parent = layers.find((item) =>
        item.mode.find((x) => x.id === layer.id),
      )?.name;

      pushDigitalData(
        {
          action: {
            group: 'Centro Monitoreo',
            category: 'Fenómenos y eventos',
            name: parent,
            label: layer.name,
          },
        },
        TRACK.ACTION,
      );
    }
  };

  const handleClickOptions = (item) => {
    handlePushDigitalData(item);
    closePopUp();
    if (!isMobile) {
      setOptionsSelect(item);
      setActiveSubLayer(item);

      return;
    }

    // Mobile
    if (openDropdown) {
      setOpenDropdown(false);
      setOptionsSelect(item);
      setActiveSubLayer(item);
    } else {
      isMobile && setOpenDropdown(true);
    }
  };

  const getDropdownClass = (item) => {
    if (isMobile) {
      if (item.name === optionsSelect?.name) {
        return 'dropdown';
      } else {
        return `dropdown_item ${!openDropdown ? 'dropdown_closed' : ''}`;
      }
    }
    return '';
  };

  return (
    <div
      className={`subLayerMap ${
        selected === '7' ? 'subLayerMapForescasts' : ''
      }${drawerVisible ? 'subLayerMapDrawer' : ''}${
        !siderVisible ? 'subLayerMapSider' : ''
      }`}
    >
      <menu className='subLayerMap__modes'>
        {options &&
          options.length > 1 &&
          options.map((item) => (
            <li
              key={item.id}
              className={`subLayerMap__modes__item ${
                item.name === optionsSelect?.name ? 'active' : ''
              } ${getDropdownClass(item)}`}
              onClick={() => handleClickOptions(item)}
            >
              <span>{item.name}</span>
              {item.name === optionsSelect?.name && (
                <span className='subLayerMap__modes__item__button'>
                  <DownOutlined />
                </span>
              )}
            </li>
          ))}
      </menu>
      {activeLayer && (
        <div className='drawer-map-layers__list__item'>
          <div className='drawer-map-layers__list__item__name'>
            <div className='icon'>{renderIcon(activeLayer.id)}</div>
            <div className='drawer-map-layers__list__item__name__text'>
              {activeLayer.name}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SublayerMapTop;
