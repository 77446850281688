import React, { useEffect, useState } from 'react';

import FloatWindows from '../../../common/FloatWindows';
import openNotification from '../../../common/Notification';
import PasswordResetForm from './PasswordResetForm';
import SuccessMessage from './SuccessMessage';
import { recoveryPassword } from '../../../services/register';

const RecoveryPassword = ({ show, handleShowRecovery }) => {
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    try {
      values.email = values.email.toLowerCase().trim();
      setLoading(true);
      const response = await recoveryPassword(values);
      setLoading(false);
      if (
        response &&
        response.data &&
        response.data.success &&
        response.data.message
      ) {
        setEmailSent(true);
      }
    } catch (err) {
      setLoading(false);
      openNotification(
        'error',
        'Error',
        err.message || 'Error al enviar email de recuperación de contraseña',
      );
    }
  };

  useEffect(() => {
    if (show) {
      setEmailSent(false);
    }
  }, [show]);

  return (
    <FloatWindows
      title={emailSent ? '' : 'Olvidé mi Contraseña'}
      show={show}
      handleShow={handleShowRecovery}
    >
      {emailSent ? (
        <SuccessMessage handleShowRecovery={handleShowRecovery} />
      ) : (
        <PasswordResetForm
          handleSubmit={handleSubmit}
          loading={loading}
          handleShowRecovery={handleShowRecovery}
        />
      )}
    </FloatWindows>
  );
};

export default RecoveryPassword;
