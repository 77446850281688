const rulerOptions = {
  position: 'topleft',
  // Posición para mostrar el control. Valores: 'topright', 'topleft', 'bottomright', 'bottomleft'
  unit: 'kilometres',
  // Unidad predeterminada en la que se muestran las distancias. Valores: 'kilometres', 'landmiles', 'nauticalmiles'
  useSubunits: true,
  // Usar subunidades (metros/pies) en las descripciones emergentes si las distancias son inferiores a 1 kilómetro/milla terrestre
  clearMeasurementsOnStop: true,
  // Borrar todas las mediciones cuando se desactive el control de medida
  showBearings: false,
  // Si se muestran los rumbos en las descripciones emergentes
  bearingTextIn: 'Hacia',
  // Etiqueta dependiente del idioma para rumbos entrantes
  bearingTextOut: 'Salida',
  // Etiqueta dependiente del idioma para rumbos salientes
  tooltipTextFinish: 'Haz clic para <b>finalizar la línea</b><br>',
  tooltipTextDelete:
    'Presiona la tecla SHIFT y haz clic para <b>eliminar un punto</b>',
  tooltipTextMove: 'Haz clic y arrastra para <b>mover un punto</b><br>',
  tooltipTextResume:
    '<br>Presiona la tecla CTRL y haz clic para <b>continuar la línea</b>',
  tooltipTextAdd:
    'Presiona la tecla CTRL y haz clic para <b>añadir un punto</b>',
  // Etiquetas dependientes del idioma para las descripciones emergentes de los puntos
  measureControlTitleOn: 'Activar PolylineMeasure',
  // Título para el control de medida cuando se va a activar
  measureControlTitleOff: 'Desactivar PolylineMeasure',
  // Título para el control de medida cuando se va a desactivar
  measureControlLabel: '&#8614;',
  // Etiqueta del control de medida (se pueden usar símbolos Unicode)
  measureControlClasses: [],
  // Clases aplicadas al control de medida
  showClearControl: false,
  // Mostrar un control para borrar todas las mediciones
  clearControlTitle: 'Borrar Mediciones',
  // Texto del título para el control de borrado
  clearControlLabel: '&times;',
  // Etiqueta del control de borrado (se pueden usar símbolos Unicode)
  clearControlClasses: [],
  // Clases aplicadas al control de borrado
  showUnitControl: false,
  // Mostrar un control para cambiar las unidades de medida
  unitControlUnits: ['kilometres', 'landmiles', 'nauticalmiles'],
  // Unidades de medida que se van alternando mediante el control de unidades
  unitControlTitle: {
    text: 'Cambiar Unidades',
    kilometres: 'kilómetros',
    landmiles: 'millas terrestres',
    nauticalmiles: 'millas náuticas',
  },
  // Textos del título para el control de unidades
  unitControlLabel: {
    metres: 'm',
    kilometres: 'km',
    feet: 'pies',
    landmiles: 'mi',
    nauticalmiles: 'nm',
  },
  // Símbolos de unidades para mostrar en el control de unidades y etiquetas de medida
  unitControlClasses: [],
  // Clases aplicadas al control de unidades
  tempLine: {
    color: '#00f',
    // Color de la línea punteada temporal
    weight: 2,
    // Grosor de la línea punteada temporal
  },
  fixedLine: {
    color: '#006',
    // Color de la línea sólida
    weight: 2,
    // Grosor de la línea sólida
  },
  arrow: {
    color: '#000',
    // Color de la flecha intermedia
  },
  startCircle: {
    color: '#000',
    // Color del borde del círculo indicador del punto de inicio de la polilínea
    weight: 1,
    // Grosor del círculo
    fillColor: '#0f0',
    // Color de relleno del círculo
    fillOpacity: 1,
    // Opacidad de relleno del círculo
    radius: 3,
    // Radio del círculo
  },
  intermedCircle: {
    color: '#000',
    // Color del borde del círculo
    weight: 1,
    // Grosor del círculo
    fillColor: '#ff0',
    // Color de relleno del círculo
    fillOpacity: 1,
    // Opacidad de relleno del círculo
    radius: 3,
    // Radio del círculo
  },
  currentCircle: {
    color: '#000',
    // Color del borde del círculo
    weight: 1,
    // Grosor del círculo
    fillColor: '#f0f',
    // Color de relleno del círculo
    fillOpacity: 1,
    // Opacidad de relleno del círculo
    radius: 6,
    // Radio del círculo
  },
  endCircle: {
    color: '#000',
    // Color del borde del círculo
    weight: 1,
    // Grosor del círculo
    fillColor: '#f00',
    // Color de relleno del círculo
    fillOpacity: 1,
    // Opacidad de relleno del círculo
    radius: 3,
    // Radio del círculo
  },
};

const elevationOptions = {
  translation: {
    distance: 'Distancia',
    elevation: 'Elevación',
    segment_length: 'Longitud',
    type: 'Tipo',
    legend: ' ',
  },
};
export { rulerOptions, elevationOptions };
