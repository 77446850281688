import { useState, useCallback } from 'react';
import { Typography } from 'antd';
import { useSelector } from 'react-redux';

import { signageTypes } from './columns';
import SignageType from './SignageType';
import Tabs from '../../../../../../common/Tabs';

const { Text } = Typography;

const Signage = ({ onUpdateForm, signals = {}, isMobile, maxSignals }) => {
  const [tabSelected, setTabSelected] = useState('EE');
  const { listSignals } = useSelector((state) => ({
    listSignals: state.requestServiceForm.signals,
  }));

  const _handleChangeTab = useCallback((activeKey) => {
    setTabSelected(activeKey);
  }, []);

  return (
    <div className='d_content' style={{ paddingTop: '20px' }}>
      <Text className='text-subtitle-bold mb-1'>Tipo de señaletica</Text>
      <Tabs
        className='tabs-custom mt-1 mb-3 pt-1'
        size='large'
        onChange={_handleChangeTab}
        items={signageTypes.map((element) => ({
          label: element.title,
          key: element.key,
          children: (
            <>
              <span className='text-signage'>Señales(Tamaño 30x20cm)</span>
              {listSignals && listSignals[tabSelected] && (
                <SignageType
                  signals={signals}
                  withFotolum={element.withFotolum}
                  isMobile={isMobile}
                  maxSignals={maxSignals}
                  listSignals={listSignals[tabSelected]}
                  onUpdateForm={onUpdateForm}
                />
              )}
            </>
          ),
        }))}
      />
    </div>
  );
};

export default Signage;
