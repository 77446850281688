import { notification } from 'antd';

export const formatLocationRows = (rows, properties) => {
  if (!rows) {
    return [];
  }

  if (!Array.isArray(rows)) {
    throw new Error('Los filas deben estar dentro de un array');
  }

  if (rows.length === 0) {
    return [];
  }

  return rows.map((row, index) => {
    const lat = Number(row['LATITUD (*)']);
    if (isNaN(lat)) {
      notification.error({
        description: `El campo "Latitud" es obligatorio y debe ser numérico para la ubicación en la fila ${
          index + 2
        }.`,
        message: 'Error!',
        duration: 5,
      });
      throw new Error(
        `El campo "Latitud" es obligatorio y debe ser numérico para la ubicación en la fila ${
          index + 2
        }.`,
      );
    }
    const lng = Number(row['LONGITUD (*)']);

    if (isNaN(lng)) {
      notification.error({
        description: `El campo "Longitud" es obligatorio y debe ser numérico para la ubicación en la fila ${
          index + 2
        }.`,
        message: 'Error!',
        duration: 5,
      });
      throw new Error(
        `El campo "Longitud" es obligatorio y debe ser numérico para la ubicación en la fila ${
          index + 2
        }.`,
      );
    }

    const name = row['NOMBRE (*)'];
    if (
      !name ||
      !/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\d\s]+$/.test(name) ||
      name.trim() === ''
    ) {
      notification.error({
        description: `El campo "Nombre" es obligatorio y debe contener solo letras en la fila ${
          index + 2
        }.`,
        message: 'Error!',
        duration: 5,
      });
      throw new Error(
        `El campo "Nombre" es obligatorio y debe contener solo letras para la ubicación en la fila ${
          index + 2
        }.`,
      );
    }

    let email;
    if (properties?.isAdmin) {
      email = row['CORREO (*)']?.trim();
      if (!email || email === '') {
        notification.error({
          description: `El campo "Correo" es obligatorio para el usuario en la fila ${
            index + 2
          }.`,
          message: 'Error!',
          duration: 5,
        });
        throw new Error(
          `El campo "Correo" es obligatorio para el usuario en la fila ${
            index + 2
          }.`,
        );
      }
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        notification.error({
          description: `El campo "Correo" debe ser una dirección de correo electrónico válida
                          para el usuario con correo ${email} en la fila ${
            index + 2
          }.`,
          message: 'Error!',
          duration: 5,
        });
        throw new Error(`El campo "Correo" debe ser una dirección de correo electrónico válida
                          para el usuario con correo ${email} en la fila ${
          index + 2
        }.`);
      }
    }

    if (properties?.isAdmin) {
      return {
        lat,
        lng,
        name,
        username: email,
      };
    } else {
      return {
        lat,
        lng,
        name,
      };
    }
  });
};
