import { Skeleton } from 'antd';
import React from 'react';

const Loading = () => {
  return (
    <div className='skeleton-service'>
      <Skeleton title={{ width: '50%' }} active paragraph={false} />

      <div className='skeleton-service__cards'>
        <div className='card'>
          <Skeleton
            active
            avatar={{ shape: 'circle', size: 'default' }}
            title
            paragraph={false}
          />
          <div className='border'></div>
          <Skeleton
            active
            title={false}
            paragraph={{ rows: 4, width: '100%' }}
          />
        </div>
        <div className='card'>
          <Skeleton
            active
            avatar={{ shape: 'circle', size: 'default' }}
            title
            paragraph={false}
          />
          <div className='border'></div>
          <Skeleton
            active
            title={false}
            paragraph={{ rows: 4, width: '100%' }}
          />
        </div>
      </div>
    </div>
  );
};

export default Loading;
