import {
  OPEN_FORM_REQUEST,
  UPDATE_REQUEST_SERVICE_FIELD,
  SAVE_CONTACTS,
  UPDATE_FROM_STORAGE,
  CLEAR_FORM_REQUEST,
} from '../actions/actionsType';

const getStorageRequests = (polizaId, serviceId) => {
  const requestServiceListFromStorage = localStorage.getItem(
    `requestServicesList-${polizaId}-${serviceId}`,
  );
  if (requestServiceListFromStorage) {
    return JSON.parse(requestServiceListFromStorage).map((data) => {
      const contact = localStorage.getItem(`contact-${data.id}`);
      const service = localStorage.getItem(`service-${data.id}`);

      return {
        id: data.id,
        contact: contact ? JSON.parse(contact) : data.contact,
        service: service
          ? {
              ...(data?.service || {}),
              ...JSON.parse(service),
            }
          : data.service,
      };
    });
  } else {
    localStorage.setItem(
      `requestServicesList-${polizaId}-${serviceId}`,
      JSON.stringify([]),
    );

    return [];
  }
};

export const initialState = {
  companyId: null,
  servicesList: [],
  requestServicesList: [],
  signals: [],
  polizaId: null,
  polizaSearch: null,
  serviceSearch: null,
  currentStep: 0,
  fromRequestService: false,
  additionalSignals: 0,
  requestServiceItemId: null,
  typePolizaId: null,
  savedContacts: [],
  policies: [],
  openedForm: null,
  isLoading: false,
  isLoadingCreate: false,
  query: '',
  page: 1,
  total: 0,
  pagination: {
    pageSize: 5,
    current: 1,
    total: 0,
  },
  usersCreate: null,
};

const requestServiceForm = (state, action) => {
  if (!state) state = initialState;

  const polizaSearch = localStorage.getItem('polizaSearch');
  const serviceSearch = localStorage.getItem('serviceSearch');
  switch (action.type) {
    case OPEN_FORM_REQUEST:
      let updServicesList = state.requestServicesList;
      let formToOpen = null;
      let toUpdate = {};

      switch (action.payload.action) {
        case 'add':
          const newService = {
            id: Date.now(),
            contact: {},
            service: {
              id: serviceSearch,
            },
          };

          formToOpen = { ...newService, action: 'add' };
          updServicesList.push(newService);
          break;
        case 'add-exists':
          const { data: dataExists } = action.payload || { data: {} };

          toUpdate = {
            ...toUpdate,
            currentStep: dataExists.step,
            fromRequestService: true,
            additionalSignals: dataExists.additional,
            requestServiceItemId: dataExists.requestServiceItemId,
            polizaId: dataExists.polizaId,
            buttonText: 'Guardar',
          };

          const serviceExists = {
            id: dataExists.id,
            contact: dataExists.contact,
            service: dataExists.service,
          };

          formToOpen = serviceExists;
          updServicesList.push(serviceExists);
          break;
        case 'update':
          const { data } = action.payload || { data: {} };
          let serviceToUpdt = {};

          updServicesList = updServicesList.map((form) => {
            if (String(form.id) === String(data.id)) {
              serviceToUpdt = {
                ...form,
                ...data,
              };

              return { ...serviceToUpdt, action: 'update' };
            }

            return form;
          });

          formToOpen = serviceToUpdt;
          break;
        case 'close':
          formToOpen = null;
          break;
        case 'delete':
          updServicesList = updServicesList.filter(
            ({ id }) => id !== action.payload.id,
          );

          break;
        default:
          break;
      }

      if (serviceSearch && polizaSearch)
        localStorage.setItem(
          `requestServicesList-${polizaSearch}-${serviceSearch}`,
          JSON.stringify(updServicesList),
        );

      return {
        ...state,
        ...toUpdate,
        requestServicesList: updServicesList,
        openedForm: formToOpen,
      };
    case UPDATE_FROM_STORAGE:
      const storageData = getStorageRequests(polizaSearch, serviceSearch);

      if (storageData)
        return {
          ...state,
          requestServicesList: storageData,
        };
      else return state;

    case UPDATE_REQUEST_SERVICE_FIELD:
      let toUpd = {};
      for (let key in action.payload) toUpd[key] = action.payload[key];

      return {
        ...state,
        ...toUpd,
      };
    case SAVE_CONTACTS:
      return {
        ...state,
        savedContacts: state.savedContacts.concat(action.payload),
      };
    case CLEAR_FORM_REQUEST:
      return initialState;
    default:
      return state;
  }
};

export default requestServiceForm;
