import moment from 'moment';

export const metrics = [
  {
    key: 1,
    indicator: 'Clientes Atendidos Prevención',
    graphTitle: 'Clientes Atendidos',
    unit: 'N° clientes',
    graphKey: 'attendedClients',
  },
  {
    key: 2,
    graphTitle: 'Avance Programa',
    indicator: 'Avance Programa de Prevención',
    unit: '% promedio de Cumplimiento OV',
    graphKey: 'advancePolizas',
  },
  {
    key: 3,
    indicator: 'Empresas notificadas por el CMP',
    graphTitle: 'Empresas notificadas',
    unit: 'N° clientes con usuario en la WEB',
    graphKey: 'notifiedCompanies',
  },
  {
    key: 4,
    graphTitle: 'Interacción con la Plataforma',
    indicator: 'Interacción con la Plataforma Web',
    unit: 'N° Promedio de Visitas Mensual de Usuarios',
    graphKey: 'visits',
  },
  {
    key: 5,
    graphTitle: 'Logueados Web',
    indicator: 'Logueados Web',
    unit: 'N° de empresas con usuarios activos',
    graphKey: 'logged',
  },
];

export const generateColumns = [
  {
    title: 'Indicador',
    key: 'indicator',
    width: 150,
    dataIndex: 'indicator',
  },
  {
    title: 'Unidad de medida',
    key: 'unit',
    dataIndex: 'unit',
    width: 150,
  },
  {
    title: 'Meta Anual',
    key: 'annualygoal_key',
    colSpan: 2,
    width: 120,
    dataIndex: 'annualygoal_key',
  },
  {
    title: 'Meta Anual',
    key: 'annualygoal_value',
    colSpan: 0,
    dataIndex: 'annualygoal_value',
    width: 100,
  },
  ...[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
    .filter((m, i) => i <= new Date().getMonth())
    .map((month) => [
      {
        title: `Real ${moment().set('month', month).format('MMMM')}`,
        key: `month_real_${month}`,
        dataIndex: `month_real_${month}`,
        align: 'center',
        width: 100,
      },
      {
        title: `Meta ${moment().set('month', month).format('MMMM')}`,
        key: `month_monthlyGoal_${month}`,
        dataIndex: `month_monthlyGoal_${month}`,
        align: 'center',
        width: 100,
      },
      {
        title: 'Cumplimiento',
        key: `month_compliance_${month}`,
        dataIndex: `month_compliance_${month}`,
        align: 'center',
        width: 100,
      },
    ])
    .flat(),
  {
    title: 'Avance acumulado',
    key: 'accumulatedAdvance',
    dataIndex: 'accumulatedAdvance',
    width: 140,
  },
  {
    title: 'Meta esperada',
    key: 'monthlyGoal',
    dataIndex: 'monthlyGoal',
    width: 140,
  },
  {
    title: 'Cumplimiento',
    key: 'compliance',
    dataIndex: 'compliance',
    width: 140,
  },
];

export const processMonthlyData = (row, months) => {
  months.forEach((month) => {
    row = {
      ...row,
      [`month_real_${month.month}`]: Math.round(month.real),
      [`month_monthlyGoal_${month.month}`]:
        month.monthlyGoal && Math.round(month.monthlyGoal),
      [`month_compliance_${month.month}`]:
        month.compliance != null ? `${Math.round(month.compliance)}%` : null,
    };
  });
  return row;
};

export const processRowsExpand = ({
  attendedClients,
  advancePolizas,
  notifiedCompanies,
  visits,
  logged,
}) => {
  const newData = [];
  let firstRow = {
    ...metrics[0],
    expandData: [],
  };

  attendedClients.forEach((attended, key) => {
    let row = {
      key,
      annualygoal_key: attended.name,
      annualygoal_value:
        attended.annualGoal != null ? Math.round(attended.annualGoal) : null,
      monthlyGoal:
        attended.expectedGoal != null
          ? `${attended.name}: ${Math.round(attended.expectedGoal)}`
          : null,
      accumulatedAdvance:
        attended.accumulatedAdvance != null
          ? `${attended.name}: ${Math.round(attended.accumulatedAdvance)}`
          : null,
      compliance:
        attended.compliance != null
          ? `${attended.name}: ${Math.round(attended.compliance)}%`
          : null,
    };

    processMonthlyData(row, attended.months);

    if (row.annualygoal_key === 'Total')
      firstRow = {
        ...row,
        ...firstRow,
      };
    else firstRow.expandData = firstRow.expandData.concat(row);
  });

  newData.push(firstRow);

  let secondRow = {
    ...metrics[1],
    expandData: [],
  };

  advancePolizas.forEach((poliza, key) => {
    let row = {
      key,
      annualygoal_key: poliza.name,
      annualygoal_value:
        poliza.annualGoal != null ? `${Math.round(poliza.annualGoal)}%` : null,
      monthlyGoal:
        poliza.monthlyGoal != null
          ? `${Math.round(poliza.monthlyGoal)}%`
          : null,
      accumulatedAdvance:
        poliza.accumulatedAdvance != null
          ? `${Math.round(poliza.accumulatedAdvance)}%`
          : null,
      compliance:
        poliza.compliance != null ? `${Math.round(poliza.compliance)}%` : null,
    };

    poliza.months.forEach((month) => {
      row = {
        ...row,
        [`month_real_${month.month}`]: `${Math.round(month.real)}%`,
        [`month_monthlyGoal_${month.month}`]: `${
          month.monthlyGoal && Math.round(month.monthlyGoal)
        }%`,
        [`month_compliance_${month.month}`]: `${
          month.compliance && Math.round(month.compliance)
        }%`,
      };
    });

    if (row.annualygoal_key === 'Total')
      secondRow = {
        ...row,
        ...secondRow,
      };
    else secondRow.expandData = secondRow.expandData.concat(row);
  });

  newData.push(secondRow);

  let thirdRow = {
    ...metrics[2],
    expandData: [],
  };

  notifiedCompanies.forEach((notified, key) => {
    let row = {
      key,
      annualygoal_key: notified.name,
      annualygoal_value:
        notified.annualGoal != null ? Math.round(notified.annualGoal) : null,
      monthlyGoal:
        notified.expectedGoal != null
          ? Math.round(notified.expectedGoal)
          : null,
      accumulatedAdvance:
        notified.accumulatedAdvance != null
          ? Math.round(notified.accumulatedAdvance)
          : null,
      compliance:
        notified.compliance != null
          ? `${Math.round(notified.compliance)}%`
          : null,
    };

    processMonthlyData(row, notified.months);

    if (row.annualygoal_key === 'Total')
      thirdRow = {
        ...row,
        ...thirdRow,
      };
    else thirdRow.expandData = thirdRow.expandData.concat(row);
  });

  newData.push(thirdRow);

  let fourthRow = {
    ...metrics[3],
    annualygoal_key: '',
    annualygoal_value:
      visits.annualGoal != null ? Math.round(visits.annualGoal) : null,
    monthlyGoal:
      visits.expectedGoal != null ? Math.round(visits.expectedGoal) : null,
    accumulatedAdvance:
      visits.accumulatedAdvance != null
        ? Math.round(visits.accumulatedAdvance)
        : null,
    compliance:
      visits.compliance != null ? `${Math.round(visits.compliance)}%` : null,
  };

  if (visits && visits.months) {
    visits.months.forEach((month) => {
      fourthRow = {
        ...fourthRow,
        [`month_real_${month.month}`]: Math.round(month.real),
        [`month_monthlyGoal_${month.month}`]:
          month.monthlyGoal && Math.round(month.monthlyGoal),
        [`month_compliance_${month.month}`]:
          month.compliance && `${Math.round(month.compliance)}%`,
      };
    });
  }

  newData.push(fourthRow);

  let fifthRow = {
    ...metrics[4],
    expandData: [],
  };

  logged.forEach((log, key) => {
    let row = {
      key,
      indicator: 'Logueados Web',
      unit: 'N° de empresas con usuarios activos',
      annualygoal_key: log.name,
      annualygoal_value:
        log.annualGoal != null ? Math.round(log.annualGoal) : null,
      monthlyGoal:
        log.expectedGoal != null ? Math.round(log.expectedGoal) : null,
      accumulatedAdvance:
        log.accumulatedAdvance != null
          ? Math.round(log.accumulatedAdvance)
          : null,
      compliance:
        log.compliance != null ? `${Math.round(log.compliance)}%` : null,
    };

    processMonthlyData(row, log.months);

    if (row.annualygoal_key === 'Total')
      fifthRow = {
        ...row,
        ...fifthRow,
      };
    else fifthRow.expandData = fifthRow.expandData.concat(row);
  });

  newData.push(fifthRow);

  return newData
    .reduce((result, item) => {
      if (result.indexOf(item.indicator) < 0) {
        result.push(item.indicator);
      }

      return result;
    }, [])
    .reduce((result, indicator) => {
      const children = newData.filter((item) => item.indicator === indicator);
      result = result.concat(
        children.map((item, index) => ({
          ...item,
          rowSpan: index === 0 ? children.length : 0,
        })),
      );

      return result;
    }, []);
};
