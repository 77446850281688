import React, { memo, useCallback, useRef, useState } from 'react';
import Axios from 'axios';
import { useSelector } from 'react-redux';
import { Button, Divider, notification } from 'antd';
import { DownloadOutlined, LeftCircleOutlined } from '@ant-design/icons';
import { RideGlWarningCircleOutline } from '@rimac-seguros/ride-system-components';

import { getPathCredentials } from '../../../../../lib/ajax';
import { signedAxios } from '../../../../../lib/axios';
import { PATH_API_CANAL_AUTH, chunkArray } from '../../../../../lib/utils';

import BoxLoader from '../../../../../common/BoxLoader';
import InputFile from '../../../../../common/InputFile';
import Title from '../../../../../common/Typograph/Title';

const urlQuery = `${process.env.REACT_APP_API_BASE_URL_CANAL}${PATH_API_CANAL_AUTH}`;

const EMAIL_KEY = 'CORREO (*)';

const MasiveUpload = ({ isMobileScreen, onBack }) => {
  const cancelToken = useRef();
  const { user } = useSelector((store) => store.auth);
  const [loader, setLoader] = useState();
  const [fileName, setFileName] = useState('');
  const [errorMessages, setErrorMessages] = useState([]);

  const formatRows = useCallback(
    (rows) => {
      if (!rows || rows.length === 0) {
        return [];
      }

      return rows
        .map((row, index) => {
          let rol_id = null;

          const email = row[EMAIL_KEY]?.trim();
          if (!email || email === '') {
            notification.error({
              description: `El campo "Correo" es obligatorio para el usuario en la fila ${
                index + 2
              }.`,
              message: 'Error!',
              duration: 5,
            });
            throw new Error(
              `El campo "Correo" es obligatorio para el usuario en la fila ${
                index + 2
              }.`,
            );
          } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
            notification.error({
              description: `El campo "Correo" debe ser una dirección de correo electrónico válida
                        para el usuario con correo ${email} en la fila ${
                index + 2
              }.`,
              message: 'Error!',
              duration: 5,
            });
            throw new Error(`El campo "Correo" debe ser una dirección de correo electrónico válida
                        para el usuario con correo ${email} en la fila ${
              index + 2
            }.`);
          }

          switch (row['TIPO USUARIO (*)'].toUpperCase().trim()) {
            case 'ADMINISTRADOR':
            case 'ADMINISTRADOR CLIENTE':
              rol_id = 3;
              break;
            case 'USUARIO':
            case 'USUARIO EMPRESA':
            case 'USUARIO CLIENTE':
              rol_id = 4;
              break;
            case 'PERSONA NATURAL':
              rol_id = 14;
              break;
            default:
              notification.error({
                description: `Tipo de usuario para ${
                  row[EMAIL_KEY]
                } inválido en la fila ${index + 2}.`,
                message: 'Error!',
              });
              throw new Error(
                `Tipo de usuario para ${row[EMAIL_KEY]} inválido en la fila ${
                  index + 2
                }.`,
              );
          }

          const ruc =
            user.rol.slug === 'administrador_cliente'
              ? user.company?.ruc
              : row['RUC (*)'];
          if (!ruc || !/^\d{11}$/.test(ruc)) {
            notification.error({
              description: `El campo "RUC" es obligatorio y debe tener 11 dígitos para el usuario con correo ${
                row[EMAIL_KEY]
              } en la fila ${index + 2}.`,
              message: 'Error!',
              duration: 5,
            });
            throw new Error(
              `El campo "RUC" es obligatorio y debe tener 11 dígitos para el usuario con correo ${
                row[EMAIL_KEY]
              } en la fila ${index + 2}.`,
            );
          }

          const name = row['NOMBRES (*)'];
          if (!name || !/^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s]+$/.test(name)) {
            notification.error({
              description: `El campo "Nombres" es obligatorio y debe contener solo letras para el usuario con correo ${
                row[EMAIL_KEY]
              } en la fila ${index + 2}.`,
              message: 'Error!',
              duration: 5,
            });
            throw new Error(
              `El campo "Nombres" es obligatorio y debe contener solo letras para el usuario con correo ${
                row[EMAIL_KEY]
              } en la fila ${index + 2}.`,
            );
          }

          const last_name1 = row['APELLIDO PATERNO (*)']?.trim();
          if (
            !last_name1 ||
            last_name1 === '' ||
            !/^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s]+$/.test(last_name1)
          ) {
            notification.error({
              description: `El campo "Apellido Paterno" es obligatorio y debe contener solo letras para el usuario con correo ${
                row[EMAIL_KEY]
              } en la fila ${index + 2}.`,
              message: 'Error!',
              duration: 5,
            });
            throw new Error(
              `El campo "Apellido Paterno" es obligatorio y debe contener solo letras para el usuario con correo ${
                row[EMAIL_KEY]
              } en la fila ${index + 2}.`,
            );
          }

          const last_name2 = row['APELLIDO MATERNO (*)']?.trim();
          if (last_name2 && !/^[a-zA-ZáéíóúÁÉÍÓÚñÑüÜ\s]+$/.test(last_name2)) {
            notification.error({
              description: `El campo "Apellido Materno" debe contener solo letras para el usuario con correo ${
                row[EMAIL_KEY]
              } en la fila ${index + 2}.`,
              message: 'Error!',
              duration: 5,
            });
            throw new Error(
              `El campo "Apellido Materno" debe contener solo letras para el usuario con correo ${
                row[EMAIL_KEY]
              } en la fila ${index + 2}.`,
            );
          }

          return {
            ruc,
            rol_id,
            username: email,
            name,
            last_name1,
            last_name2,
            position: row['CARGO'],
          };
        })
        .filter(Boolean);
    },
    [user.company.ruc, user.rol.slug],
  );

  const bulkUsersExecute = useCallback(async (users) => {
    cancelToken.current = Axios.CancelToken.source();

    return getPathCredentials(`${urlQuery}/users/bulk`)
      .then(({ url, credentials, session }) => {
        return signedAxios(
          {
            method: 'POST',
            url,
            cancelToken: cancelToken.current.token,
            data: { payload: { users } },
            onUploadProgress: ({ loaded, total }) => {
              setLoader((loaded / total) * 100);
            },
          },
          { credentials, session },
        );
      })
      .catch(() => {
        notification.error({
          description: 'Hubo un error al subir archivo.',
          message: 'Error',
        });

        return { success: false };
      });
  }, []);

  const _handleChangeFile = useCallback(({ file }) => {
    setFileName(file.name);
  }, []);

  const _handleChangeData = useCallback(
    (data) => {
      setLoader(0);
      const dataToSend = formatRows(data);
      const chunkedData = chunkArray(dataToSend, 20);

      const promises = chunkedData.map((chunk) => bulkUsersExecute(chunk));

      Promise.allSettled(promises).then((results) => {
        let userSuccess = 0;
        let errorMessages = [];

        results.forEach((result) => {
          if (result.status === 'fulfilled') {
            const { value } = result;
            const payload = value.data.payload;

            if (payload?.result?.messages?.length > 0) {
              errorMessages.push(...payload.result.messages);
            }

            userSuccess += payload?.result?.usersRegistered ?? 0;
          } else {
            notification.error({
              description: 'Hubo un error al subir archivo.',
              message: 'Error',
            });
          }
        });

        if (errorMessages.length > 0) {
          setErrorMessages(errorMessages);
        }

        notification.info({
          message: '¡Archivo subido!',
          description:
            userSuccess === 1
              ? 'Se registró 1 usuario.'
              : `Se registraron ${userSuccess} usuarios.`,
        });
      });
    },
    [bulkUsersExecute, formatRows],
  );

  const handleCancel = useCallback(() => {
    if (cancelToken?.current) {
      cancelToken.current.cancel('Cancel uploading');
    }
    setFileName(null);
    setLoader();
    notification.warning({
      message: 'Operación cancelada',
    });
  }, []);

  return (
    <div className='d_content service-prevention'>
      {isMobileScreen ? (
        <Button
          className='mr-1 flex items-center'
          icon={<LeftCircleOutlined />}
          type='link'
          size='large'
          onClick={onBack}
        >
          Volver
        </Button>
      ) : null}
      <div
        className={`flex ${
          isMobileScreen ? 'flex-col' : 'items-center justify-between'
        }`}
      >
        <div className='flex items-center'>
          {!isMobileScreen && (
            <Button
              className='mr-1 flex items-center'
              type='link'
              size='large'
              onClick={onBack}
            >
              <LeftCircleOutlined style={{ fontSize: 24 }} />
            </Button>
          )}
          <Title type={isMobileScreen ? 'bold-32' : 'bold-28'}>
            Carga masiva de usuarios
          </Title>
        </div>
        {isMobileScreen ? <Divider /> : null}
        <Button
          type='link'
          icon={<DownloadOutlined />}
          href='/templates/PlantillaCargaDeUsuarios.xlsx'
          style={{ alignSelf: 'flex-start' }}
          size={isMobileScreen ? 'small' : 'large'}
          onClick={() => {}}
        >
          Descargar plantilla
        </Button>
      </div>
      {isNaN(loader) ? (
        <InputFile
          className={'mt-3'}
          withoutPreview
          onChangeFile={_handleChangeFile}
          processExcelData={_handleChangeData}
          allowClear={true}
        />
      ) : (
        <BoxLoader
          className='mt-3'
          percent={loader}
          title='Cargando...'
          description={fileName}
          onCancel={handleCancel}
          onClose={() => {
            setLoader();
            setErrorMessages([]);
          }}
        />
      )}
      {errorMessages.length > 0 && (
        <section className='masiveUploadErrors'>
          <strong>{`${
            errorMessages.length === 1
              ? 'Se presentaro un error en el siguiente usuario:'
              : 'Se presentaron errores en los siguiente usuarios:'
          }`}</strong>
          <ul>
            {errorMessages.map((message, index) => (
              <li key={index} className='flex items-center gap-2'>
                <RideGlWarningCircleOutline />
                {message}
              </li>
            ))}
          </ul>
        </section>
      )}
    </div>
  );
};

export default memo(MasiveUpload);
