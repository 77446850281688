import React from 'react';
import { Card, Skeleton } from 'antd';
import Chart from 'react-apexcharts';
import Title from '../../../../../../common/Typograph/Title';
import { useScreen } from '../../../../../../lib/screen';

const TimeSerieWidget = ({ loading, data = {}, title }) => {
  const { isMobileScreen } = useScreen();

  if (loading)
    return (
      <Card>
        <Skeleton />
      </Card>
    );

  const seriesChart = [
    {
      name: 'Total',
      data: Array.isArray(data)
        ? data.map((item) => item?.number?.toString() ?? '')
        : [],
    },
  ];

  return (
    <Card className='mb-3' style={{ height: '100%' }}>
      <Title type='bold-18'>{title}</Title>
      <div className={isMobileScreen ? 'mt-2' : 'mt-3'}>
        <Chart
          options={{
            chart: {
              id: 'basic-bar',
              toolbar: {
                show: true,
                offsetY: isMobileScreen ? -15 : -40,
                tools: { download: false },
              },
            },
            colors: ['#432EFF'],
            xaxis: {
              categories: Array.isArray(data)
                ? data.map((item) => item?.label?.toString() ?? '')
                : [],
              range: isMobileScreen ? 7 : 14,
              labels: {
                style: {
                  colors: '#141938',
                  fontSize: 10,
                  fontWeight: 400,
                },
              },
            },
            yaxis: {
              labels: {
                style: {
                  fontWeight: 400,
                  fontFamily: 'inherit',
                  fontSize: 10,
                  colors: '#141938',
                },
              },
            },
          }}
          series={seriesChart}
          type={'line'}
          height='250'
          className='time-serie'
        />
      </div>
    </Card>
  );
};

export default TimeSerieWidget;
