import {
  LOAD_CHANGELOG_LOADING,
  LOADED_CHANGELOG,
  LOADING_CREATE_CHANGELOG,
  CHANGE_CHANGELOG_QUERY,
  CHANGE_CHANGELOG_PREVIEW_VERSION,
} from '../actions/actionsType';

const initialState = {
  list: [],
  isLoading: false,
  isLoadingCreate: false,
  previewVersion: -1,
  query: '',
  pagination: {
    pageSize: 5,
    current: 1,
    total: 0,
  },
};

const changelog = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_CHANGELOG_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case LOADING_CREATE_CHANGELOG:
      return {
        ...state,
        isLoadingCreate: action.payload,
      };
    case LOADED_CHANGELOG:
      if (state.query && state.query !== action.payload.query) return state;

      return {
        ...state,
        list: action.payload.list,
        pagination: {
          current: action.payload.page,
          pageSize: action.payload.items_per_page,
          total: action.payload.total,
        },
        isLoading: false,
      };

    case CHANGE_CHANGELOG_PREVIEW_VERSION:
      return {
        ...state,
        previewVersion: action.payload,
      };

    case CHANGE_CHANGELOG_QUERY:
      return {
        ...state,
        query: action.payload || '',
      };
    default:
      return state;
  }
};

export default changelog;
