import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getEventType } from '../../../services/eventType';

import Topbar from '../../../common/ui/Topbar';
import CompanyUserBoard from './CompanyUserBoard';
import ProfileCompany from './ProfileCompany/ui';

const Dashboard = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    getEventType(dispatch);
  }, [dispatch]);

  return (
    <>
      {['administrador_cliente', 'admin_rimac', 'gestor_de_cuenta'].includes(
        user.rol.slug,
      ) ? (
        <ProfileCompany />
      ) : (
        <div id='checkSize' className={'h100vh dashboard-UI'}>
          <Topbar />
          <div className='dashboard-UI' style={{ width: '100%' }}>
            <div className='d_content'>
              <div className='profile-company__header--title pt-2'>
                <h1>Resumen</h1>
              </div>
              <CompanyUserBoard />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
