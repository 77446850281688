import { useEffect, useState, useCallback } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Button, notification, Skeleton } from 'antd';

import useDebounce from '../../../../hooks/useDebounce';
import {
  createBrokersPag,
  getBrokers,
  getUserManagers,
  removeBrokersPag,
  updateBrokersPag,
} from '../../../../services/brokers';

import {
  CardSkeleton,
  IconEditTable,
  iconLeft,
  iconRight,
  IconTrash,
} from '../../ui/PreventionService/Reports/utils';
import ModalConfirm from '../../../../common/ModalConfirm';
import Table from '../../../../common/Table';
import PopoverActions from '../../../../common/Table/PopoverActions';
import FilterSection from '../../ui/PreventionService/components/FilterSection';
import CardItemMobile from '../common/CardItemMobile';
import MainTitle from '../common/MainTitle';
import DeleteModalImage from '../../../../images/delete_modal_image.svg';
import Empty from '../../../../common/Empty';

const Brokers = ({
  load,
  create,
  update,
  list,
  isLoading,
  renderItem,
  pagination,
  onOpenForm,
  isMobileScreen,
  onModalSuccess,
  isBeforeTable,
  queryAction,
  onSetAction,
}) => {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [textSearch, setTextSearch] = useState('');
  const debouncedTextSearch = useDebounce(textSearch);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const handleFilter = (e) => setTextSearch(e.target.value);

  const handleTableChange = (pag) =>
    load({
      page: pag.current,
      limit: pag.pageSize,
      query: textSearch,
    });

  const handleTableChangeMobile = (current) => {
    if (
      current === 0 ||
      Math.ceil(pagination.total / pagination.pageSize) + 1 === current
    )
      return;

    load({
      page: current,
      limit: pagination.pageSize,
      query: textSearch,
    });
  };

  const _handleDelete = (item) => () => setItemToDelete(item);

  const _handleAction = useCallback(
    (action, item) => async () => {
      const result = await getUserManagers();
      onOpenForm({
        action,
        dinamycOptions: result,
        ...item,
        keyConfig: 'corredores',
        hasFullImage: true,
        titleSection:
          action === 'create' ? 'Crear corredor' : 'Editar corredor',
        onAction: async (data, onFinish = () => {}) => {
          action === 'create'
            ? create(data, { page: 1, limit: 5 }, isBeforeTable, onModalSuccess)
            : update(
                data,
                {
                  page: pagination.current,
                  limit: pagination.pageSize,
                  query: textSearch,
                },
                isBeforeTable,
                onModalSuccess,
              );
          onFinish();
          onOpenForm(null);
          action === 'create' && onSetAction(false);
        },
      });
    },
    [onOpenForm, create, update],
  );

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      width: '240px',
      render: (text) => (
        <span className='text-uppercase'>
          {text?.length > 50 ? `${(text || '').substring(0, 50)}...` : text}
        </span>
      ),
    },
    {
      title: 'Código',
      dataIndex: 'code',
      key: 'code',
      width: '150px',
      render: (text) => <span className='text-uppercase'>{text || ''}</span>,
    },
    {
      dataIndex: 'actions',
      title: 'Acciones',
      fixed: 'right',
      key: 'actions',
      width: 80,
      render: (__, item) =>
        rows.length > 0 ? (
          <PopoverActions
            actions={
              <>
                <Button onClick={_handleAction('edit', item)}>
                  <IconEditTable /> <span>Editar</span>
                </Button>

                <Button onClick={_handleDelete(item)}>
                  <IconTrash /> <span>Eliminar</span>
                </Button>
              </>
            }
          />
        ) : null,
    },
  ];

  const handleRemove = async () => {
    setLoadingDelete(true);

    if (!itemToDelete)
      return notification.warning({
        description: 'No ha seleccionado ningun corredor para eliminar.',
        message: 'Eliminar póliza',
      });

    const deleted = await removeBrokersPag(itemToDelete.id, dispatch, {
      page: pagination.current,
      limit: pagination.pageSize,
      query: textSearch,
    });

    if (isBeforeTable && deleted) {
      setItemToDelete(null);
      setLoadingDelete(false);

      return onModalSuccess({ message: '¡Listo! Se eliminó correctamente' });
    }

    if (deleted)
      notification.success({
        description: 'Se eliminó el corredor correctamente.',
        message: '¡Corredor eliminado! ',
      });
    else
      notification.warning({
        description: 'Ha ocurrido un error.',
        message: 'Eliminar corredor',
      });

    setItemToDelete(null);
    setLoadingDelete(false);
  };

  useEffect(() => {
    load({
      query: debouncedTextSearch.length >= 2 ? debouncedTextSearch : '',
      page: 1,
      limit: pagination.pageSize,
    });
  }, [debouncedTextSearch]);

  useEffect(() => {
    setRows(list);
  }, [list]);

  useEffect(() => {
    if (queryAction) _handleAction('create', renderItem)();
  }, [queryAction]);

  return (
    <>
      <MainTitle
        buttonAction={_handleAction('create', renderItem)}
        renderItem={renderItem}
        isMobileScreen={isMobileScreen}
      />

      <FilterSection
        complete={true}
        text={`${
          isMobileScreen ? 'Filtra tu búsqueda' : 'Filtrar lista de corredores'
        }`}
        handleFilter={handleFilter}
      />

      {!isMobileScreen ? (
        !isLoading && list?.length === 0 ? (
          <Empty title='No se encontraron articulos' />
        ) : (
          <section className='show-data'>
            {isLoading ? (
              <Skeleton active title={false} paragraph={{ rows: 1 }} />
            ) : (
              <p className='info-total-data'>
                Se encontraron <span>{pagination.total}</span>{' '}
                {pagination.total === 1 ? 'corredor' : 'corredores'}
              </p>
            )}

            <Table
              dataSource={rows}
              columns={columns}
              rowKey={(record) => record.id}
              loading={isLoading}
              locale={{ emptyText: 'No se encontraron corredores.' }}
              pagination={pagination}
              onChangeRows={setRows}
              onChange={handleTableChange}
            />
          </section>
        )
      ) : (
        <section className='content-mobile action-bottom'>
          {isLoading ? (
            <Skeleton active title={false} paragraph={{ rows: 1 }} />
          ) : (
            <p className='info-total-data'>
              Se encontraron <span>{pagination.total}</span>{' '}
              {pagination.total === 1 ? 'corredor' : 'corredores'}
            </p>
          )}

          {isLoading ? (
            [1, 2, 3, 4].map((el) => <CardSkeleton key={el} />)
          ) : rows?.length === 0 ? (
            <Empty title='No se encontraron articulos' />
          ) : (
            rows?.map((data) => (
              <CardItemMobile
                key={data.id}
                data={data}
                columns={columns.filter((el) => el.dataIndex !== 'actions')}
                onEdit={_handleAction('edit', data)}
                onDelete={setItemToDelete}
              />
            ))
          )}

          <div className='d_content__list__pag'>
            <div
              className={'d_content__list__pag__button'}
              onClick={() => handleTableChangeMobile(pagination.current - 1)}
            >
              {iconLeft({
                color: pagination.current === 1 ? '#A9AFD9' : '#4F4FFF',
              })}
            </div>

            <div className='d_content__list__pag__number color-red-1'>
              {pagination.current}
            </div>
            <div className='d_content__list__pag__sep'>/</div>
            <div className='d_content__list__pag__number color-gray-3'>
              {Math.ceil(pagination.total / pagination.pageSize)}
            </div>

            <div
              className={'d_content__list__pag__button'}
              onClick={() => handleTableChangeMobile(pagination.current + 1)}
            >
              {iconRight({
                color:
                  pagination.current ===
                  Math.ceil(pagination.total / pagination.pageSize)
                    ? '#A9AFD9'
                    : '#4F4FFF',
              })}
            </div>
          </div>
        </section>
      )}

      <ModalConfirm
        visible={itemToDelete}
        onClose={() => setItemToDelete(null)}
        title='¿Seguro que desea eliminar el corredor de la lista?'
        image={DeleteModalImage}
        actionsContent={
          <div className='actions-delete-modal mt-2'>
            <Button
              className='button secondary-button mr-2'
              onClick={() => setItemToDelete(null)}
            >
              Cancelar
            </Button>

            <Button
              className='button ml-1'
              type='primary'
              disabled={loadingDelete}
              loading={loadingDelete}
              onClick={handleRemove}
            >
              Eliminar
            </Button>
          </div>
        }
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  load: (data) => getBrokers(dispatch, data),
  create: async (broker, pagination, isBeforeTable, onModalSuccess) => {
    const created = await createBrokersPag(broker, dispatch, pagination);

    if (isBeforeTable && created) {
      onModalSuccess({
        message: '¡Listo! Se creó el corredor correctamente',
      });

      return;
    }

    created
      ? notification.success({
          description: 'Se creo el corredor correctamente.',
          message: '¡Corredor creado!',
        })
      : notification.warning({
          description: 'Ha ocurrido un error.',
          message: 'Crear corredor',
        });
  },
  update: async (broker, pagination, isBeforeTable, onModalSuccess) => {
    const updated = await updateBrokersPag(broker, dispatch, pagination);

    if (isBeforeTable && updated) {
      onModalSuccess({
        message: '¡Cambios guardados! Se guardaron los cambios correctamente',
      });

      return;
    }

    updated
      ? notification.success({
          description: 'Se guardaron los cambios correctamente.',
          message: '¡Cambios guardados!',
        })
      : notification.warning({
          description: 'Ha ocurrido un error.',
          message: 'Actualizar corredor',
        });
  },
});

const mapStateToProps = (state) => ({
  list: state.brokers.list || [],
  pagination: state.brokers.pagination,
  isLoading: state.brokers.isLoading,
  isLoadingCreate: state.brokers.isLoadingCreate,
  openedForm: state.brokers.openedForm,
});

export default connect(mapStateToProps, mapDispatchToProps)(Brokers);
