import React from 'react';
import { useSelector } from 'react-redux';
import { Divider, Form, Input, Modal } from 'antd';

import texts from './../../../../common/texts.json';
import text from './../../../../config/text_es.json';
import Title from '../../../../common/Typograph/Title';

const formLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 24 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 24 } },
};

const ConflictForm = ({ onOk, object, visible, openForm }) => {
  const [form] = Form.useForm();
  const { validateFields } = form;

  const { isLoadingCreate } = useSelector((state) => state.conflict);

  const handleSave = () => {
    validateFields().then((values) => {
      onOk(object.id ? { ...values, id: object.id } : values);
    });
  };

  const handleCancel = () => {
    openForm(false);
  };

  return (
    <Modal
      title={''}
      onOk={handleSave}
      onCancel={handleCancel}
      open={visible}
      okText={object?.id ? 'Editar' : 'Crear'}
      cancelText='Cancelar'
      destroyOnClose
      confirmLoading={isLoadingCreate}
      cancelButtonProps={isLoadingCreate}
      maskClosable={false}
    >
      <Title type='bold-28' style={{ textAlign: 'center' }}>{`${
        object?.id ? 'Editar' : 'Crear'
      } Conflicto`}</Title>
      <Divider className='mt-2 mb-3' />
      <Form
        {...formLayout}
        form={form}
        disabled={isLoadingCreate}
        className='form-emergencies'
      >
        <Form.Item name='id' initialValue={object ? object.id : ''}>
          <Input type='hidden' />
        </Form.Item>
        <Form.Item name='name' initialValue={object ? object.name : ''}>
          <Input
            className='mb-2'
            addonBefore={texts['map_conflict_name']}
            placeholder={texts['map_conflict_name']}
            disabled={isLoadingCreate}
          />
        </Form.Item>
        <Form.Item name='country' initialValue={object ? object.country : ''}>
          <Input
            addonBefore={texts['map_conflict_country']}
            placeholder={texts['map_conflict_country']}
            className='mb-2'
            disabled={isLoadingCreate}
          />
        </Form.Item>
        <Form.Item
          name='department'
          initialValue={object ? object.department : ''}
        >
          <Input
            addonBefore={texts['map_conflict_department']}
            placeholder={texts['map_conflict_department']}
            className='mb-2'
            disabled={isLoadingCreate}
          />
        </Form.Item>
        <Form.Item
          name='multiregional_or_nationalcd'
          initialValue={object ? object.multiregional_or_nationalcd : ''}
        >
          <Input
            addonBefore={texts['map_conflict_multiregional_or_nationalcd']}
            placeholder={texts['map_conflict_multiregional_or_nationalcd']}
            className='mb-2'
            disabled={isLoadingCreate}
          />
        </Form.Item>
        <Form.Item name='province' initialValue={object ? object.province : ''}>
          <Input
            addonBefore={texts['map_conflict_province']}
            placeholder={texts['map_conflict_province']}
            className='mb-2'
            disabled={isLoadingCreate}
          />
        </Form.Item>
        <Form.Item name='district' initialValue={object ? object.district : ''}>
          <Input
            addonBefore={texts['map_conflict_district']}
            placeholder={texts['map_conflict_district']}
            className='mb-2'
            disabled={isLoadingCreate}
          />
        </Form.Item>
        <Form.Item name='location' initialValue={object ? object.location : ''}>
          <Input
            addonBefore={texts['map_conflict_location']}
            placeholder={texts['map_conflict_location']}
            className='mb-2'
            disabled={isLoadingCreate}
          />
        </Form.Item>
        <Form.Item
          name='other_places_involved'
          initialValue={object ? object.other_places_involved : ''}
        >
          <Input
            addonBefore={texts['map_conflict_other_places_involved']}
            placeholder={texts['map_conflict_other_places_involved']}
            className='mb-2'
            disabled={isLoadingCreate}
          />
        </Form.Item>
        <Form.Item
          name='company_involved'
          initialValue={object ? object.company_involved : ''}
        >
          <Input
            addonBefore={texts['map_conflict_company_involved']}
            placeholder={texts['map_conflict_company_involved']}
            className='mb-2'
            disabled={isLoadingCreate}
          />
        </Form.Item>
        <Form.Item name='type' initialValue={object ? object.type : ''}>
          <Input
            addonBefore={texts['map_conflict_type']}
            placeholder={texts['map_conflict_type']}
            className='mb-2'
            disabled={isLoadingCreate}
          />
        </Form.Item>
        <Form.Item name='activity' initialValue={object ? object.activity : ''}>
          <Input
            addonBefore={texts['map_conflict_activity']}
            placeholder={texts['map_conflict_activity']}
            className='mb-2'
            disabled={isLoadingCreate}
          />
        </Form.Item>
        <Form.Item name='state' initialValue={object ? object.state : ''}>
          <Input
            addonBefore={texts['map_conflict_state']}
            placeholder={texts['map_conflict_state']}
            className='mb-2'
            disabled={isLoadingCreate}
          />
        </Form.Item>
        <Form.Item
          name='active_phases'
          initialValue={object ? object.active_phases : ''}
        >
          <Input
            addonBefore={texts['map_conflict_active_phases']}
            placeholder={texts['map_conflict_active_phases']}
            className='mb-2'
            disabled={isLoadingCreate}
          />
        </Form.Item>
        <Form.Item
          name='time_of_dialogue'
          initialValue={object ? object.time_of_dialogue : ''}
        >
          <Input
            addonBefore={texts['map_conflict_time_of_dialogue']}
            placeholder={texts['map_conflict_time_of_dialogue']}
            className='mb-2'
            disabled={isLoadingCreate}
          />
        </Form.Item>
        <Form.Item
          name='dialogue_mechanism'
          initialValue={object ? object.dialogue_mechanism : ''}
        >
          <Input
            addonBefore={texts['map_conflict_dialogue_mechanism']}
            placeholder={texts['map_conflict_dialogue_mechanism']}
            className='mb-2'
            disabled={isLoadingCreate}
          />
        </Form.Item>
        <Form.Item
          name='participation_dialogue'
          initialValue={object ? object.participation_dialogue : ''}
        >
          <Input
            addonBefore={texts['map_conflict_participation_dialogue']}
            placeholder={texts['map_conflict_participation_dialogue']}
            className='mb-2'
            disabled={isLoadingCreate}
          />
        </Form.Item>
        <Form.Item
          name='incident_of_violence'
          initialValue={object ? object.incident_of_violence : ''}
        >
          <Input
            addonBefore={texts['map_conflict_incident_of_violence']}
            placeholder={texts['map_conflict_incident_of_violence']}
            className='mb-2'
            disabled={isLoadingCreate}
          />
        </Form.Item>
        <Form.Item
          name='dialogue_after_violence'
          initialValue={object ? object.dialogue_after_violence : ''}
        >
          <Input
            addonBefore={texts['map_conflict_dialogue_after_violence']}
            placeholder={texts['map_conflict_dialogue_after_violence']}
            className='mb-2'
            disabled={isLoadingCreate}
          />
        </Form.Item>
        <Form.Item
          name='ombudsman_office'
          initialValue={object ? object.ombudsman_office : ''}
        >
          <Input
            addonBefore={texts['map_conflict_ombudsman_office']}
            placeholder={texts['map_conflict_ombudsman_office']}
            className='mb-2'
            disabled={isLoadingCreate}
          />
        </Form.Item>
        <Form.Item
          name='main_government'
          initialValue={object ? object.main_government : ''}
        >
          <Input
            addonBefore={texts['map_conflict_main_government']}
            placeholder={texts['map_conflict_main_government']}
            className='mb-2'
            disabled={isLoadingCreate}
          />
        </Form.Item>
        <Form.Item
          name='latitude'
          initialValue={object ? object.lat ?? object.latitude : ''}
          rules={[{ required: true, message: text.required_message }]}
        >
          <Input
            addonBefore={texts['map_conflict_latitude']}
            placeholder={texts['map_conflict_latitude']}
            className='mb-2'
            disabled={isLoadingCreate}
          />
        </Form.Item>
        <Form.Item
          name='longitude'
          initialValue={object ? object.lng ?? object.longitude : ''}
          rules={[{ required: true, message: text.required_message }]}
        >
          <Input
            addonBefore={texts['map_conflict_longitude']}
            placeholder={texts['map_conflict_longitude']}
            className='mb-2'
            disabled={isLoadingCreate}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ConflictForm;
