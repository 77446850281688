import {
  LOADED_BROKERS,
  LOAD_BROKERS_LOADING,
  LOADING_CREATE_BROKERS,
  OPEN_FORM_BROKERS,
  CHANGE_BROKERS_QUERY,
} from '../actions/actionsType';

const initialState = {
  list: [],
  openedForm: false,
  isLoading: false,
  isLoadingCreate: false,
  page: 1,
  total: 0,
  query: '',
  pagination: {
    pageSize: 5,
    current: 1,
    total: 0,
  },
};

const brokers = (state, action) => {
  if (!state) state = initialState;

  switch (action.type) {
    case LOAD_BROKERS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case LOADED_BROKERS:
      if (state.query && state.query !== action.payload.query) return state;

      return {
        ...state,
        list: action.payload.list,
        page: action.payload.page,
        total: action.payload.total,
        pagination: {
          current: action.payload.page,
          pageSize: action.payload.items_per_page,
          total: action.payload.total,
        },
        isLoading: false,
      };
    case OPEN_FORM_BROKERS:
      return {
        ...state,
        openedForm: action.payload,
      };
    case LOADING_CREATE_BROKERS:
      return {
        ...state,
        isLoadingCreate: action.payload,
      };
    case CHANGE_BROKERS_QUERY:
      return {
        ...state,
        query: action.payload || '',
      };
    default:
      return state;
  }
};

export default brokers;
