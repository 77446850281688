import { useEffect, useRef, useState } from 'react';
import { notification } from 'antd';
import axios from 'axios';
import ReactLeafletKml from 'react-leaflet-kml';
import { useMap } from 'react-leaflet';
import JSZip from 'jszip';

const MAX_DECOMPRESSED_SIZE = 30 * 1024 * 1024; // 30MB
const MAX_FILES = 10; // 10 files
export const FILE_TOO_LARGE_MESSAGE = 'Archivo demasiado grande';
export const FILE_TOO_LARGE_DESCRIPTION =
  'El archivo KMZ contiene demasiados archivos.';
export const KML_TOO_LARGE_DESCRIPTION =
  'El archivo KML es demasiado grande para ser mostrado.';

const LayerKML = ({ layer, fitBounds }) => {
  const [kml, setKml] = useState(null);
  const map = useMap();
  const kmlLayer = useRef();

  const handleKMZ = async (url) => {
    const res = await axios.get(url, { responseType: 'arraybuffer' });
    const zip = await JSZip.loadAsync(res.data); // NOSONAR
    const kmlFile = zip.file(/\.kml$/i)[0];

    if (zip.files.length > MAX_FILES) {
      notification.error({
        message: FILE_TOO_LARGE_MESSAGE,
        description: FILE_TOO_LARGE_DESCRIPTION,
        duration: 5,
      });
      return;
    }

    if (!kmlFile) {
      notification.error({
        message: 'No existe archivo KML.',
        description: 'No se encontró un archivo KML en el archivo KMZ.',
        duration: 5,
      });
      return;
    }

    if (kmlFile.uncompressedSize > MAX_DECOMPRESSED_SIZE) {
      notification.error({
        message: FILE_TOO_LARGE_MESSAGE,
        description: KML_TOO_LARGE_DESCRIPTION,
        duration: 5,
      });
      return;
    }

    const kmlData = await kmlFile.async('text');
    const parser = new DOMParser();
    const kmlParsed = parser.parseFromString(kmlData, 'text/xml');
    setKml(kmlParsed);
  };

  const handleKML = async (url) => {
    const res = await axios.get(url);
    if (res.status === 200) {
      const parser = new DOMParser();
      const kmlParsed = parser.parseFromString(res.data, 'text/xml');
      setKml(kmlParsed);
    }
  };

  useEffect(() => {
    (async () => {
      if (layer) {
        if (layer.typeFile === 'kmz') {
          await handleKMZ(layer.url);
        } else {
          await handleKML(layer.url);
        }

        if (fitBounds && kmlLayer.current && kmlLayer.current?.getBounds) {
          setTimeout(() => {
            map.fitBounds(kmlLayer.current.getBounds());
          }, 200);
        }
      }
    })();
  }, [layer]);

  return kml && <ReactLeafletKml ref={kmlLayer} kml={kml} />;
};

export default LayerKML;
