import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import L from 'leaflet';
import 'leaflet.polylinemeasure';
import 'leaflet.heightgraph/dist/L.Control.Heightgraph';
import 'leaflet.heightgraph/dist/L.Control.Heightgraph.min.css';

import { rulerOptions, elevationOptions } from './options';
import { getElevation } from '../../../lib/google';

const Ruler = () => {
  const { map, activeLayer, activeSubLayer } = useSelector((state) => ({
    map: state.maps.map,
    activeLayer: state.maps.activeLayer,
    activeSubLayer: state.maps.activeSubLayer,
  }));

  const controlRef = useRef(null);
  const points = useRef([]);
  const hg = useRef(null);

  const startMeasurement = () => (points.current = []);

  const addPoint = (e) => {
    points.current = [...points.current, [e.latlng.lat, e.latlng.lng, 56]];
  };

  const renderElevation = async () => {
    const elevation = await getElevation(points.current);

    if (hg.current) hg.current.remove();

    hg.current = L.control.heightgraph(elevationOptions);
    hg.current.addTo(map);
    hg.current.addData([elevation]);
  };

  useEffect(() => {
    document.querySelector('.leaflet-control-container').style.visibility =
      'visible';
    document.querySelector('.leaflet-bottom.leaflet-right').style.display =
      'inherit';

    return () => {
      if (document.querySelector('.leaflet-control-container')?.style) {
        document.querySelector('.leaflet-control-container').style.visibility =
          'hidden';
        document.querySelector('.leaflet-bottom.leaflet-right').style.display =
          'none';
      }
    };
  }, []);

  useEffect(() => {
    if (map && activeLayer && activeSubLayer && !controlRef.current) {
      map.on('polylinemeasure:start', startMeasurement);
      map.on('polylinemeasure:add', addPoint);
      map.on('polylinemeasure:finish', renderElevation);
      controlRef.current = L.control.polylineMeasure(rulerOptions).addTo(map);
      controlRef.current._toggleMeasure();

      const container = map.getContainer();
      const controlContainer = container.querySelector('.leaflet-bar');
      controlContainer.classList.add('hidden');
    }

    return () => {
      if (controlRef.current) {
        map.removeControl(controlRef.current);
        if (hg.current) hg.current.remove();
        map.off('polylinemeasure:start', startMeasurement);
        map.off('polylinemeasure:add', addPoint);
        map.off('polylinemeasure:finish', renderElevation);
      }
    };
  }, []);

  return null;
};

export default Ruler;
