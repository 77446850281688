import useQueryParams from '../../../../hooks/useQueryParams';
import NewsletterById from './NewsletterById';
import NewsletterCardList from './NewsletterCardList';

const NewsletterList = () => {
  const { id } = useQueryParams();

  return (
    <div className='newsletterContainer'>
      {id ? <NewsletterById id={id} /> : <NewsletterCardList />}
    </div>
  );
};

export default NewsletterList;
