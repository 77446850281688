import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { message } from 'antd';
import L from 'leaflet';
import { Marker, Popup, TileLayer } from 'react-leaflet';

import {
  getConflict,
  removeConflict,
  updateConflict,
} from '../../../../services/conflict';
import { checkPermission } from '../../../../lib/security';

import { DeleteIcon, EditIcon, getSvgIcon } from '../../utils';
import texts from '../../../../common/texts.json';
import ConflictForm from './ConflictForm';

const Index = ({
  points,
  types,
  filters,
  user,
  getPoints,
  updateSocialConflictPoint,
  removeSocialConflictPoint,
  opacity = 1,
}) => {
  const [isOpenForm, setIsOpenForm] = useState(null);
  const authorized = ['admin_rimac', 'gestor_de_cuenta'].includes(
    user?.rol?.slug,
  );

  useEffect(() => {
    getPoints(filters);
  }, [filters]);

  const getIcon = (point) =>
    L.divIcon({
      html: getSvgIcon(
        'socials',
        types.find((ct) => ct.type === point.type)?.color,
      ),
      iconSize: [27, 38],
      iconAnchor: [13, 38],
      className: 'leaflet-icon-marker-custom',
    });

  const onOk = async (data) => {
    const update = await updateSocialConflictPoint(data, filters);
    setIsOpenForm(false);

    const ContentMessage = ({ children }) => (
      <div className='pt-1 pb-1 pr-2 pl-2'>{children}</div>
    );

    if (update) {
      message.success({
        content: (
          <ContentMessage>Emergencia actualizada correctamente.</ContentMessage>
        ),
        icon: <></>,
      });

      return true;
    } else {
      message.warning({
        content: <ContentMessage>Ha ocurrido un error.</ContentMessage>,
        icon: <></>,
      });

      return false;
    }
  };

  const removePoint = (id) => removeSocialConflictPoint(id, filters);

  return (
    <>
      <TileLayer
        attribution='Google Maps'
        url='https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}'
        opacity={opacity}
      />
      {points.map((point) => (
        <Marker
          key={point.id}
          position={[point.latitude, point.longitude]}
          icon={getIcon(point)}
        >
          <Popup>
            <div className='popup-map'>
              <div className='popup-title'>
                <span className='layer-name'>Detalles</span>
                {authorized &&
                  point?.source === 'CMP' &&
                  checkPermission(
                    user,
                    'administrate_manual_points_emergency',
                  ) && (
                    <>
                      <span
                        className='icon-title flex items-baseline'
                        onClick={() =>
                          setIsOpenForm({
                            ...point,
                            id: point.id,
                            action: 'edit',
                          })
                        }
                      >
                        <EditIcon />
                        <span className='popup-value ml-1'>
                          Editar conflicto
                        </span>
                      </span>
                      <span
                        className='icon-title flex items-baseline'
                        onClick={() => removePoint(point.id)}
                      >
                        <DeleteIcon />
                        <span className='popup-value ml-1'>
                          Eliminar conflicto
                        </span>
                      </span>
                    </>
                  )}
              </div>
              <div className='popup-content popup-map-scrollable'>
                {Object.keys(point)
                  .filter(
                    (key) =>
                      ![
                        'latitude',
                        'longitude',
                        'id',
                        'createdAt',
                        'updatedAt',
                      ].includes(key),
                  )
                  .map(
                    (key) =>
                      texts[`map_conflict_${key}`] !== undefined && (
                        <span key={key} className='popup-detail'>
                          <span className='popup-detail-header'>
                            {texts[`map_conflict_${key}`]}
                          </span>
                          <span className='popup-detail-content'>
                            {point[key] !== undefined && point[key] !== null
                              ? point[key]
                              : ''}
                          </span>
                        </span>
                      ),
                  )}
                <span className='popup-detail'>
                  <span className='popup-detail-header'>Coordenadas</span>
                  <span className='popup-detail-content'>
                    {point.latitude}, {point.longitude}
                  </span>
                </span>
              </div>
            </div>
          </Popup>
        </Marker>
      ))}
      <ConflictForm
        visible={Boolean(isOpenForm)}
        openForm={setIsOpenForm}
        onOk={onOk}
        object={isOpenForm}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getPoints: (filters) => getConflict({ dispatch, ...filters }),
  updateSocialConflictPoint: (data, filters) =>
    updateConflict({ data, dispatch, filters }),
  removeSocialConflictPoint: (id, filters) =>
    removeConflict({ id, dispatch, filters }),
});

const mapStateToProps = (state) => ({
  points: state.conflict.list,
  types: state.conflict.types,
  filters: state.conflict.filter,
  user: state.auth.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
