import React from 'react';
import { Button, Modal } from 'antd';

import IconFinish from '../../../../../../../images/icon_finish.svg';

const ModalFinishSurvey = ({ visible, onCancel }) => {
  return (
    <Modal
      title=''
      open={visible}
      onCancel={onCancel}
      footer={false}
      className='modal-finish'
      centered={true}
    >
      <div className='finish'>
        <img src={IconFinish} alt='' aria-hidden />
        <h2>¡Listo! Acabaste la encuesta</h2>
        <p>
          Gracias por tu tiempo y por ayudarnos a crear una mejor experiencia.
        </p>
        <Button type='primary' size='large' onClick={onCancel}>
          Entendido
        </Button>
      </div>
    </Modal>
  );
};

export default ModalFinishSurvey;
