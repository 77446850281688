import { useState, memo, useCallback, useMemo } from 'react';
import { Table, Button, Popover, Checkbox, Row, Typography } from 'antd';
import { UnorderedListOutlined, CloseOutlined } from '@ant-design/icons';
import { capitalize, secureRandom } from '../../lib/utils';
import Skeleton from '../Skeleton';

const { Text } = Typography;

const TableCommon = ({
  actions = [],
  columns,
  withDinamicCols,
  dataSource,
  onChangeRows = () => {},
  pagination,
  rowClassName,
  loading,
  className,
  ...props
}) => {
  const [columnsSelected, setColumnsSelected] = useState(columns);
  const [columnsChecked, setColumnsChecked] = useState(
    columns.map((el) => (el.visible ? el.key : false)).filter(Boolean),
  );
  const [openPopover, setOpenPopover] = useState(false);

  const _handleChangeColumns = useCallback((checkedValues) => {
    setColumnsSelected((prev) =>
      prev.map((el) =>
        checkedValues.includes(el.key)
          ? { ...el, visible: true }
          : { ...el, visible: false },
      ),
    );
    setColumnsChecked(checkedValues);
  }, []);

  const ContentPopover = useMemo(
    () => (
      <div className='popover-content'>
        <Text className='color-neutral-7' strong>
          Mostrar/Ocultar Columnas
        </Text>
        <Checkbox.Group value={columnsChecked} onChange={_handleChangeColumns}>
          {columns
            .filter((el) => el.title)
            .map((col) => (
              <Row span={8} key={col?.key}>
                <Checkbox
                  disabled={col.disabled}
                  className={`checkbox-margin ${
                    col.visible
                      ? col.disabled
                        ? 'checkbox-disabled'
                        : 'checkbox-checked'
                      : 'checkbox-color'
                  }`}
                  value={col?.key}
                >
                  {typeof col?.title === 'string'
                    ? capitalize(col?.title)
                    : col?.title}
                </Checkbox>
              </Row>
            ))}
        </Checkbox.Group>
      </div>
    ),
    [_handleChangeColumns, columns, columnsChecked],
  );

  const _handleTooglePopover = useCallback((visible) => {
    setOpenPopover(visible);
  }, []);

  const actionsColumns = useMemo(
    () =>
      withDinamicCols
        ? (actions || [])?.concat({
            width: '80px',
            fixed: 'right',
            dataIndex: 'action',
            key: 'action',
            title: () => (
              <Popover
                content={ContentPopover}
                placement='bottomRight'
                onOpenChange={_handleTooglePopover}
                trigger='click'
              >
                <Button
                  className='button-action'
                  shape='circle'
                  icon={
                    openPopover ? <CloseOutlined /> : <UnorderedListOutlined />
                  }
                  size='small'
                />
              </Popover>
            ),
          })
        : actions,
    [
      ContentPopover,
      _handleTooglePopover,
      actions,
      openPopover,
      withDinamicCols,
    ],
  );

  const _handleMouseEnter = useCallback(
    (rowIndex) => () => {
      const columns = actionsColumns.filter((el) => el.rowHoverable);

      if (columns && columns.length)
        onChangeRows((prev) =>
          prev.map((el, index) =>
            rowIndex === index
              ? {
                  ...el,
                  ...columns.reduce((acc, curr) => {
                    acc[curr.dataIndex] = curr?.onAction(rowIndex);

                    return acc;
                  }, {}),
                }
              : el,
          ),
        );
    },
    [actionsColumns, onChangeRows],
  );

  const _handleMouseLeave = useCallback(
    (rowIndex) => () => {
      const columns = actionsColumns.filter((el) => el.rowHoverable);

      if (columns && columns.length)
        onChangeRows((prev) =>
          prev.map((el, index) =>
            rowIndex === index
              ? {
                  ...el,
                  ...columns.reduce((acc, curr) => {
                    acc[curr.dataIndex] = null;

                    return acc;
                  }, {}),
                }
              : el,
          ),
        );
    },
    [actionsColumns, onChangeRows],
  );

  return loading ? (
    <Skeleton type='table' className={className} totalItems={6} />
  ) : (
    <Table
      key={`table-${secureRandom()}`}
      {...(withDinamicCols
        ? {
            columns: columnsSelected
              ?.filter((el) => el?.visible)
              ?.concat(actionsColumns),
            onRow: (_, rowIndex) => ({
              onMouseEnter: _handleMouseEnter(rowIndex),
              onMouseLeave: _handleMouseLeave(rowIndex),
            }),
          }
        : actionsColumns?.length
        ? {
            columns: columns?.concat(actionsColumns || []),
            onRow: (_, rowIndex) => ({
              onMouseEnter: _handleMouseEnter(rowIndex),
              onMouseLeave: _handleMouseLeave(rowIndex),
            }),
          }
        : { columns })}
      rowClassName={`row-style ${rowClassName}`}
      dataSource={dataSource}
      rowKey={({ rowKey, id, key }) => rowKey || id || key}
      scroll={{ x: 'max-content' }}
      pagination={
        pagination
          ? {
              className: 'table-pagination',
              ...(pagination || {}),
            }
          : false
      }
      {...props}
    />
  );
};

export default memo(TableCommon);
