import React, { useEffect, useState } from 'react';

const PopupContent = ({ initProperties, onSave, type }) => {
  const [properties, setProperties] = useState(initProperties);

  const handleOnChangeProperties = (e) => {
    e.persist();
    setProperties((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    setProperties(initProperties);
  }, [initProperties]);

  return (
    <div className='popup-map'>
      <div className='popup-title'>
        <span className='layer-name'>Propiedades del dibujo</span>
      </div>
      <div className='popup-content popup-map-scrollable'>
        <span className='popup-detail'>
          <span className='popup-detail-header'>Color de la linea</span>
          <span className='popup-detail-content'>{properties?.stroke}</span>
          <input
            className='popup-color-input'
            name='stroke'
            type='color'
            value={properties?.stroke}
            onChange={handleOnChangeProperties}
          />
          <br />
          <span className='popup-detail-header'>Grosor de la linea</span>
          <span className='popup-detail-content'>
            {properties?.['stroke-width']}
          </span>
          <input
            className='ant-input ant-input-sm'
            name='stroke-width'
            type='number'
            min={0}
            step={0.1}
            value={properties?.['stroke-width']}
            onChange={handleOnChangeProperties}
          />
          <br />
          <span className='popup-detail-header'>Opacidad de la linea</span>
          <span className='popup-detail-content'>
            {properties?.['stroke-opacity']}
          </span>
          <input
            name='stroke-opacity'
            type='range'
            min={0}
            max={1}
            step={0.1}
            value={properties?.['stroke-opacity']}
            onChange={handleOnChangeProperties}
          />
          {type !== 'LineString' && (
            <>
              <br />
              <span className='popup-detail-header'>Color del relleno</span>
              <span className='popup-detail-content'>{properties?.fill}</span>
              <input
                name='fill'
                type='color'
                value={properties?.fill}
                onChange={handleOnChangeProperties}
              />
              <br />
              <span className='popup-detail-header'>Opacidad del relleno</span>
              <span className='popup-detail-content'>
                {properties?.['fill-opacity']}
              </span>
              <input
                name='fill-opacity'
                type='range'
                min={0}
                max={1}
                step={0.1}
                value={properties?.['fill-opacity']}
                onChange={handleOnChangeProperties}
              />
            </>
          )}
        </span>
      </div>
      <div className='popup-footer'>
        <div className='icon-content'>
          <span
            className='btn-calendar icon-title'
            onClick={() => onSave(properties)}
          >
            <span className='icon-text'>Editar</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PopupContent;
