import { useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-timedimension';

const wmsUrl = process.env.REACT_APP_GEOSERVER_MAPS_NEW;

const TimeDimensionLayer = ({ selectLayer }) => {
  const map = useMap();
  const layerRef = useRef(null);
  const intervalRef = useRef(null);

  const removeCurrentLayer = () => {
    if (layerRef.current) {
      map.removeLayer(layerRef.current);
      layerRef.current = null;
    }
  };

  const addLayer = () => {
    const dynamicLayer = L.tileLayer.wms(wmsUrl, {
      layers: selectLayer,
      styles: '',
      version: '1.3.0',
      format: 'image/png',
      tileSize: 256,
      transparent: true,
      tiled: true,
    });

    const layer = L.timeDimension.layer.wms(dynamicLayer, {
      updateTimeDimension: true,
      updateTimeDimensionMode: 'replace',
      cache: 2,
    });

    layerRef.current = layer.addTo(map);
  };

  const updateTimes = (offset) => {
    const timeDimension = map.timeDimension;
    if (timeDimension) {
      const availableTimes = timeDimension.getAvailableTimes();
      const updatedTimes = availableTimes.map((time) => time + offset);
      timeDimension.setAvailableTimes(updatedTimes, 'replace');
    }
  };

  const getInterval = () => {
    return selectLayer.includes('satellite') ? 10 * 60 * 1000 : 60 * 60 * 1000;
  };

  const updateTime = () => {
    updateTimes(getInterval());
  };

  useEffect(() => {
    removeCurrentLayer();
    addLayer();

    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    intervalRef.current = setInterval(updateTime, getInterval());

    return () => {
      clearInterval(intervalRef.current);
    };
  }, [map, selectLayer]);

  return null;
};

export default TimeDimensionLayer;
