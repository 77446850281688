import React, { memo, useCallback } from 'react';
import { Typography, Input } from 'antd';

const { TextArea } = Input;
const { Text } = Typography;

const CommentField = ({ onUpdateForm, comment, className }) => {
  const _handleChangeArea = useCallback(
    ({ target: { value } }) => {
      if (value?.length <= 500) onUpdateForm({ comment: value });
      else onUpdateForm({ comment: value.slice(0, 500) });
    },
    [onUpdateForm],
  );

  return (
    <div className={`flex flex-col mt-3 ${className ?? ''}`}>
      <Text className='text-subtitle-bold mb-3'>
        Observaciones o información adicional
      </Text>
      <div className='text-area-container'>
        <TextArea
          className='text-area-custom'
          placeholder='Observaciones'
          onChange={_handleChangeArea}
          value={comment}
        />
        <Text className='sub-text'>{comment?.length ?? 0}/500</Text>
      </div>
    </div>
  );
};

export default memo(CommentField);
