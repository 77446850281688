import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Button, notification, Skeleton } from 'antd';

import useDebounce from '../../../../hooks/useDebounce';
import { useScreen } from '../../../../lib/screen';
import { OPEN_FORM_BUSINESS_SEGMENT } from '../../../../redux/actions/actionsType';
import {
  createBusinessSegmentPag,
  getPagBusinessSegment,
  removeBusinessSegmentPag,
  updateBusinessSegmentPag,
} from '../../../../services/businessSegment';

import FilterSection from '../../ui/PreventionService/components/FilterSection';
import {
  CardSkeleton,
  IconEditTable,
  iconLeft,
  iconRight,
  IconTrash,
} from '../../ui/PreventionService/Reports/utils';
import CardItemMobile from '../common/CardItemMobile';
import ModalForm from './ModalForm';
import ModalConfirm from '../../../../common/ModalConfirm';
import Table from '../../../../common/Table';
import PopoverActions from '../../../../common/Table/PopoverActions';
import DeleteModalImage from '../../../../images/delete_modal_image.svg';
import IconCheck from '../../../../images/icon_check.svg';
import MainTitle from '../common/MainTitle';
import Empty from '../../../../common/Empty';

const BusinessSegment = ({
  load,
  create,
  update,
  toggleModal,
  renderItem,
  list,
  isLoading,
  pagination,
  openedForm,
  isLoadingCreate,
  queryAction,
}) => {
  const dispatch = useDispatch();
  const { isMobileScreen, widthScreen } = useScreen();
  const [rows, setRows] = useState([]);
  const [textSearch, setTextSearch] = useState('');
  const debouncedTextSearch = useDebounce(textSearch);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [confirmMobile, setConfirmMobile] = useState('');
  const [itemSelected, setItemSelected] = useState(null);

  const handleFilter = (e) => setTextSearch(e.target.value.trim());

  const _handleEdit = (item) => () => {
    setItemSelected(item);
    toggleModal(true);
  };

  const _handleDelete = (item) => () => setItemToDelete(item);

  const columns = [
    {
      title: 'Tipo',
      dataIndex: 'name',
      key: 'name',
      width: '220px',
    },
    {
      dataIndex: 'actions',
      title: 'Acciones',
      fixed: 'right',
      key: 'actions',
      width: 80,
      render: (__, item) =>
        rows.length > 0 && (
          <PopoverActions
            actions={
              <>
                <Button onClick={_handleEdit(item)}>
                  <IconEditTable /> <span>Editar</span>
                </Button>

                <Button onClick={_handleDelete(item)}>
                  <IconTrash /> <span>Eliminar</span>
                </Button>
              </>
            }
          />
        ),
    },
  ];

  const handleTableChange = (pag) =>
    load({
      page: pag.current,
      limit: pag.pageSize,
      query: textSearch,
    });

  const handleTableChangeMobile = (current) => {
    if (
      current === 0 ||
      Math.ceil(pagination.total / pagination.pageSize) + 1 === current
    )
      return;

    load({
      page: current,
      limit: pagination.pageSize,
      query: textSearch,
    });
  };

  const handleAction = async (values) => {
    itemSelected
      ? await update(
          values,
          {
            page: pagination.current,
            limit: pagination.pageSize,
            query: textSearch,
          },
          widthScreen,
          setConfirmMobile,
        )
      : await create(values, widthScreen, setConfirmMobile);

    setItemSelected(null);
  };

  const handleRemove = async () => {
    setLoadingDelete(true);

    if (!itemToDelete)
      return notification.warning({
        description:
          'No ha seleccionado ningún segmento comercial para eliminar.',
        message: 'Eliminar segmento comercial',
      });

    const deleted = await removeBusinessSegmentPag(itemToDelete.id, dispatch, {
      page: pagination.current,
      limit: pagination.pageSize,
      query: textSearch,
    });

    if (widthScreen < 1024 && deleted) {
      setItemToDelete(null);
      setLoadingDelete(false);

      return setConfirmMobile('¡Listo! Se eliminó correctamente');
    }

    if (deleted) {
      notification.success({
        description: 'Se eliminó el segmento comercial correctamente.',
        message: '¡Segmento comercial eliminado!',
      });
    } else {
      notification.warning({
        description: 'Ha ocurrido un error.',
        message: 'Eliminar segmento comercial',
      });
    }

    setItemToDelete(null);
    setLoadingDelete(false);
  };

  useEffect(() => {
    load({
      query: debouncedTextSearch.length > 2 ? debouncedTextSearch : '',
      page: 1,
      limit: pagination.pageSize,
    });
  }, [debouncedTextSearch]);

  useEffect(() => {
    setRows(list);
  }, [list]);

  useEffect(() => {
    queryAction && toggleModal(true);
  }, [queryAction]);

  return (
    <>
      <MainTitle
        buttonAction={() => toggleModal(true)}
        renderItem={renderItem}
        isMobileScreen={isMobileScreen}
        widthMainButton={281}
      />

      <FilterSection
        complete={true}
        text={`${
          isMobileScreen
            ? 'Filtra tu búsqueda'
            : 'Filtrar lista de segmento comercial'
        }`}
        handleFilter={handleFilter}
      />

      {!isMobileScreen ? (
        !isLoading && list.length === 0 ? (
          <Empty title='No se encontraron segmentos comerciales' />
        ) : (
          <section className='show-data'>
            {isLoading ? (
              <Skeleton active title={false} paragraph={{ rows: 1 }} />
            ) : (
              <p className='info-total-data'>
                Se encontraron <span>{pagination.total}</span>{' '}
                {pagination.total === 1
                  ? 'segmento comercial'
                  : 'segmentos comerciales'}
              </p>
            )}

            <Table
              dataSource={rows}
              columns={columns}
              rowKey={(record) => record.id}
              loading={isLoading}
              locale={{ emptyText: 'No se encontraron segmentos comerciales.' }}
              pagination={pagination}
              onChangeRows={setRows}
              onChange={handleTableChange}
            />
          </section>
        )
      ) : (
        <section className='content-mobile action-bottom'>
          {isLoading ? (
            <Skeleton active title={false} paragraph={{ rows: 1 }} />
          ) : (
            <p className='info-total-data'>
              Se encontraron <span>{pagination.total}</span>{' '}
              {pagination.total === 1
                ? 'segmento comercial'
                : 'segmentos comerciales'}
            </p>
          )}

          {isLoading ? (
            [1, 2, 3, 4].map((el) => <CardSkeleton key={el} />)
          ) : rows.length === 0 ? (
            <Empty title='No se encontraron segmentos comerciales' />
          ) : (
            rows.map((data) => (
              <CardItemMobile
                key={data.id}
                data={data}
                columns={columns.filter((el) => el.dataIndex !== 'actions')}
                onEdit={(item) => {
                  setItemSelected(item);
                  toggleModal(true);
                }}
                onDelete={setItemToDelete}
              />
            ))
          )}

          <div className='d_content__list__pag'>
            <div
              className={'d_content__list__pag__button'}
              onClick={() => handleTableChangeMobile(pagination.current - 1)}
            >
              {iconLeft({
                color: pagination.current === 1 ? '#A9AFD9' : '#4F4FFF',
              })}
            </div>

            <div className='d_content__list__pag__number color-red-1'>
              {pagination.current}
            </div>
            <div className='d_content__list__pag__sep'>/</div>
            <div className='d_content__list__pag__number color-gray-3'>
              {Math.ceil(pagination.total / pagination.pageSize)}
            </div>

            <div
              className={'d_content__list__pag__button'}
              onClick={() => handleTableChangeMobile(pagination.current + 1)}
            >
              {iconRight({
                color:
                  pagination.current ===
                  Math.ceil(pagination.total / pagination.pageSize)
                    ? '#A9AFD9'
                    : '#4F4FFF',
              })}
            </div>
          </div>
        </section>
      )}

      <ModalForm
        visible={openedForm}
        handleClose={() => {
          setItemSelected(null);
          toggleModal(false);
        }}
        isLoadingCreate={isLoadingCreate}
        data={itemSelected}
        handleAction={handleAction}
      />

      <ModalConfirm
        visible={itemToDelete}
        onClose={() => setItemToDelete(null)}
        title='¿Seguro que desea eliminar el
        segmento comercial de la lista ?'
        image={DeleteModalImage}
        actionsContent={
          <div className='actions-delete-modal mt-2'>
            <Button
              className='button secondary-button mr-2'
              onClick={() => setItemToDelete(null)}
            >
              Cancelar
            </Button>

            <Button
              className='button ml-1'
              type='primary'
              disabled={loadingDelete}
              loading={loadingDelete}
              onClick={handleRemove}
            >
              Eliminar
            </Button>
          </div>
        }
      />

      <ModalConfirm
        visible={confirmMobile}
        onClose={() => setConfirmMobile('')}
        title={confirmMobile || ''}
        image={IconCheck}
        actionsContent={
          <div className='actions-delete-modal mt-2'>
            <Button
              className='button w-max'
              type='primary'
              onClick={() => setConfirmMobile('')}
            >
              Aceptar
            </Button>
          </div>
        }
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  load: (pagination) => getPagBusinessSegment(dispatch, pagination),
  toggleModal: (opened) =>
    dispatch({ type: OPEN_FORM_BUSINESS_SEGMENT, payload: opened }),
  create: async (typeIndustry, widthScreen, setConfirmMobile) => {
    const created = await createBusinessSegmentPag(typeIndustry, dispatch);

    if (widthScreen < 1024 && created) {
      return setConfirmMobile(
        '¡Listo! Segmento comercial creado correctamente',
      );
    }

    created
      ? notification.success({
          description: 'Se creo el segmento comercial correctamente.',
          message: '¡Segmento comercial creado!',
        })
      : notification.warning({
          description: 'Ha ocurrido un error.',
          message: 'Crear segmento comercial',
        });
  },
  update: async (typeIndustry, pagination, widthScreen, setConfirmMobile) => {
    const updated = await updateBusinessSegmentPag(
      typeIndustry,
      dispatch,
      pagination,
    );

    if (widthScreen < 1024 && updated) {
      return setConfirmMobile(
        '¡Cambios guardados! Se guardaron los cambios correctamente',
      );
    }

    updated
      ? notification.success({
          description: 'Se guardaron los cambios correctamente.',
          message: '¡Cambios guardados!',
        })
      : notification.warning({
          description: 'Ha ocurrido un error.',
          message: 'Actualizar segmento comercial',
        });
  },
});

const mapStateToProps = (state) => ({
  list: state.businessSegment.list || [],
  pagination: state.businessSegment.pagination,
  isLoading: state.businessSegment.isLoading,
  isLoadingCreate: state.businessSegment.isLoadingCreate,
  openedForm: state.businessSegment.openedForm,
});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessSegment);
