import React, { useState } from 'react';
import { Steps } from 'antd';

import RecurrenceForm from './Questions/RecurrenceForm';
import LocationForm from './Questions/LocationForm';
import EdificationForm from './Questions/EdificationForm';
import MachineForm from './Questions/MachineForm';
import StockForm from './Questions/StockForm';
import ProfitLossForm from './Questions/ProfitLossForm';
import ProtectionForm from './Questions/ProtectionForm';
import GeologyForm from './Questions/GeologyForm';

const { Step } = Steps;

const FormStepper = ({ onBack, onNext }) => {
  const [current, setCurrent] = useState(0);
  const steps = [
    {
      title: 'Recurrencia',
      content: (
        <RecurrenceForm
          setCurrent={setCurrent}
          current={current}
          onBack={onBack}
        />
      ),
    },
    {
      title: 'Ubicación',
      content: <LocationForm setCurrent={setCurrent} current={current} />,
    },
    {
      title: 'Edificación',
      content: <EdificationForm setCurrent={setCurrent} current={current} />,
    },
    {
      title: 'Maquinarias y sistemas eléctricos',
      content: <MachineForm setCurrent={setCurrent} current={current} />,
    },
    {
      title: 'Existencias',
      content: <StockForm setCurrent={setCurrent} current={current} />,
    },
    {
      title: 'Lucro cesante',
      content: <ProfitLossForm setCurrent={setCurrent} current={current} />,
    },
    {
      title: 'Protecciones',
      content: <ProtectionForm setCurrent={setCurrent} current={current} />,
    },
    {
      title: 'Geología',
      content: (
        <GeologyForm
          setCurrent={setCurrent}
          current={current}
          onNext={onNext}
        />
      ),
    },
  ];

  return (
    <div className='d_content block stepper-content'>
      <div className='flex flex-col text-body'>
        <Steps current={current} className='steps'>
          {steps.map((item) => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className='step-content'>{steps[current].content}</div>
      </div>
    </div>
  );
};

export default FormStepper;
