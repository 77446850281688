import { Button } from 'antd';
import React, { memo } from 'react';
import { iconLeft, iconRight } from '../Icons/iconText';

const PaginationMobile = ({
  onChange,
  style,
  total = 5,
  current = 1,
  pageSize = 5,
}) => (
  <div
    className='d_content__list__pag items-center'
    style={style ? style : { marginBottom: 120 }}
  >
    <Button
      type='link'
      disabled={current === 1}
      onClick={() => {
        onChange(current - 1);
      }}
    >
      {iconLeft({ color: current === 1 ? '#A9AFD9' : '#4F4FFF' })}
    </Button>
    <span className={'ml-2 mr-1 text-base'}>{current}</span>
    <span className='text-base'>/</span>
    <span className={'ml-1 mr-2 text-base'}>
      {Math.ceil(total / pageSize) || 1}
    </span>
    <Button
      type='link'
      disabled={current === Math.ceil(total / pageSize)}
      onClick={() => onChange(current + 1)}
    >
      {iconRight({
        color: current === Math.ceil(total / pageSize) ? '#A9AFD9' : '#4F4FFF',
      })}
    </Button>
  </div>
);

export default memo(PaginationMobile);
