import React, { useEffect, useState } from 'react';
import L from 'leaflet';
import { GeoJSON } from 'react-leaflet';
import moment from 'moment/moment';
import { secureRandom } from '../../../../lib/utils';

const fetchGeoJSON = async (url) => {
  const response = await fetch(url, {
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });
  if (!response.ok) throw new Error('Network response was not ok');
  return response.json();
};

const getColorForTimePoint = (timePoint) => {
  const now = moment.utc();
  const timeOld = (now.unix() - timePoint.unix()) / 60 / 2;
  if (timeOld < 1) return '#fe0000';
  if (timeOld <= 2) return '#ffff01';
  if (timeOld <= 3) return '#92d14f';
  if (timeOld <= 4) return '#00af4f';
  if (timeOld <= 5) return '#01b0f1';
  return '';
};

const pointToLayer = (feature, latlng) => {
  let color = '';
  let geojsonMarkerOptions = {
    radius: 3,
    fillColor: '#01b0f1',
    color: '#000',
    weight: 1,
    opacity: 1,
    fillOpacity: 0.8,
  };
  if (feature.properties.datetime) {
    const timePoint = moment.utc(feature.properties.datetime);
    color = getColorForTimePoint(timePoint);
  }

  if (color) {
    geojsonMarkerOptions = {
      ...geojsonMarkerOptions,
      fillColor: color,
    };
  }

  return L.circleMarker(latlng, geojsonMarkerOptions);
};

const LightningLayer = () => {
  const [geojson, setGeojson] = useState(null);

  useEffect(() => {
    const url = `${process.env.REACT_APP_WEB_PATH_MAPS_NEW}rayos/flash_pts.geojson?u=${secureRandom}`;
    fetchGeoJSON(url)
      .then(setGeojson)
      .catch((error) => console.error('Error fetching geojson:', error));
  }, []);

  return (
    <>{geojson && <GeoJSON data={geojson} pointToLayer={pointToLayer} />}</>
  );
};

export default React.memo(LightningLayer);
