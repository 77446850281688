export const eventsKeys = {
  temp: 'Temperatura',
  pp: 'Lluvias',
  ws: 'Vientos',
};

export const processData = (inputData = []) => {
  let groupedByDay = inputData?.reduce((accumulator, currentItem) => {
    accumulator[currentItem['day']] = [
      ...(accumulator[currentItem['day']] ?? []),
      currentItem,
    ];

    return accumulator;
  }, {});

  return Object.keys(groupedByDay).reduce((accumulator, currentDay) => {
    accumulator[currentDay] = groupedByDay[currentDay].reduce(
      (innerAccumulator, currentItem) => {
        innerAccumulator[currentItem['hour']] = [
          ...(innerAccumulator[currentItem['hour']] ?? []),
          currentItem,
        ];

        return innerAccumulator;
      },
      {},
    );

    return accumulator;
  }, {});
};

export const calculateAverage = (forecastData, day, key) => {
  const hours = forecastData[day] ? Object.keys(forecastData[day]) : [];
  return (
    hours.reduce((sum, hour) => {
      const dataFin = forecastData[day][hour].reduce((a, b) => {
        a[b.type] = b['GRAY_INDEX'];
        return a;
      }, {});

      if (!Object.keys(dataFin).includes(key)) {
        return sum;
      }

      let value = 0;
      if (key === 'ws') {
        value = Math.round(dataFin[key] * 10) / 10;
      } else {
        value = Number(dataFin[key]);
      }

      return sum + value;
    }, 0) / hours.length
  );
};
