import {
  LOAD_PROVIDER_LOADING,
  LOADED_PROVIDER,
  CHANGE_PROVIDER_QUERY,
} from '../actions/actionsType';

const initialState = {
  list: [],
  isLoadingList: false,
  page: 1,
  total: 0,
  query: '',
  pagination: {
    pageSize: 5,
    current: 1,
    total: 0,
  },
};

const providers = (state, action) => {
  if (!state) state = initialState;

  switch (action.type) {
    case LOAD_PROVIDER_LOADING:
      return {
        ...state,
        isLoadingList: action.payload,
      };
    case LOADED_PROVIDER:
      if (state.query && state.query !== action.payload.query) return state;

      return {
        ...state,
        list: action.payload.list,
        page: action.payload.page,
        total: action.payload.total,
        pagination: {
          current: action.payload.page,
          pageSize: action.payload.items_per_page,
          total: action.payload.total,
        },
        isLoadingList: false,
      };
    case CHANGE_PROVIDER_QUERY:
      return {
        ...state,
        query: action.payload || '',
      };
    default:
      return state;
  }
};

export default providers;
