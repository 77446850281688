import React from 'react';
import IconNoInformation from '../../images/icon_faq.svg';

const TableMessage = ({ message, complete, className = '' }) => {
  return (
    <div className={`content-info ${className}`}>
      <img src={IconNoInformation} alt='' aria-hidden />
      {complete ? <p>{message}</p> : <p>No se encontraron {message}.</p>}
    </div>
  );
};

export default TableMessage;
