const MenuItem = ({ item, siderVisible }) => {
  return (
    <span className={'siderbar-map__list__item'}>
      <img
        src={require(`../../../images/siders/${item.icon}.svg`)}
        alt='Menu'
        className='siderbar-map__list__item__img'
      />
      <span>{item.title}</span>
    </span>
  );
};

export default MenuItem;
