import React from 'react';

const OfferList = ({ children, src, alt, title, description }) => {
  return (
    <div className='offer-list'>
      <img src={src} className='offer-list__img' alt={alt} aria-hidden />
      <div>
        <span className='offer-list__title'>{title}</span>
        <p>{description}</p>
        <div>{children}</div>
      </div>
    </div>
  );
};

export default OfferList;
