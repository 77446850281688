import React, { memo, useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input, Card, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import { useScreen } from '../../../../../lib/screen';
import { filterByString } from '../../../../../lib/utils';
import { getRols } from '../../../../../services/rols';
import UserListContext from '../context/UserListContext';

import Title from '../../../../../common/Typograph/Title';

const { Search } = Input;

const Rols = () => {
  const dispatch = useDispatch();
  const { isMobileScreen } = useScreen();
  const { userAuth, rols } = useSelector((state) => ({
    userAuth: state.auth.user,
    rols: state.rols.rols ?? [],
  }));
  const [query, setQuery] = useState('');
  const { openEditRol } = useContext(UserListContext);

  const rolsFiltered = filterByString(rols, query);

  const handleGetUsersRols = (data) => getRols(dispatch, data);

  useEffect(() => {
    handleGetUsersRols(userAuth);
  }, []);

  const handleChangeSearch = ({ target: { value } }) => setQuery(value);

  return (
    <div className='flex flex-col'>
      <div
        className={`flex justify-between ${
          isMobileScreen ? 'flex-col pt-2 mb-3' : 'items-center mt-1 mb-4'
        }`}
      >
        <Title>Filtrar roles</Title>
        <Search
          placeholder={'Palabras clave'}
          style={{
            width: '100%',
            maxWidth: '300px',
          }}
          onChange={handleChangeSearch}
          allowClear
        />
      </div>
      {rolsFiltered.length !== 1 ? (
        <span className='text-base'>
          Se encontraron <strong>{rolsFiltered.length}</strong> roles
        </span>
      ) : (
        <span className='text-base'>
          Se encontró <strong>{rolsFiltered.length}</strong> rol
        </span>
      )}
      <div
        className='mt-4 mb-4'
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
          gap: '20px',
        }}
      >
        {rolsFiltered.map((rol, i) => (
          <Card key={rol.id || i}>
            <div className='flex items-center'>
              <UserOutlined style={{ marginRight: 8 }} />
              <Title type={'bold-14'}>{rol.name}</Title>
            </div>
            <Button
              onClick={() => {
                openEditRol(rol);
              }}
              type='link'
            >
              Editar
            </Button>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default memo(Rols);
