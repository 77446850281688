import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Spin } from 'antd';
import {
  LoadingOutlined,
  RightOutlined,
  UserOutlined,
} from '@ant-design/icons';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

import {
  checkAuth,
  clearTokenIntoLocalStore,
  logout,
  setClockDriftLocalStore,
} from '../../lib/auth';

import {
  disableRootScroll,
  enableRootScroll,
  pushDigitalData,
  removeAccents,
} from '../../lib/utils';

import { getSearchArticles } from '../../services/articles';
import { checkIfExistUser } from '../../services/users';
import NavigationRed from './NavigationRed';
import { LOG_IN } from '../../redux/actions/actionsType';
import {
  PATH_CONDITIONS_CLAUSES,
  PATH_CONDITIONS_CLAUSES_PUBLIC_WORKS,
  TRACK,
} from '../../lib/consts';
import Button from '../../components/portal/components/Button';

const antIcon = <LoadingOutlined style={{ fontSize: 56 }} spin />;

const HeaderPortal = ({ secondary, showSearcherParam }) => {
  let query = useQuery();
  const dispatch = useDispatch();
  const isLogged = useSelector((state) => state.auth.isLogged);
  const user = useSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const location = useLocation();

  const [isBackSAML] = useState(location.search.startsWith('?code'));
  const [isCheking] = useState(location.search.startsWith('?checking'));

  const checkLogin = async () => {
    const result = await checkAuth();
    if (result.success) {
      setClockDriftLocalStore(result.session?.clockDrift);
      dispatch({
        type: LOG_IN,
        payload: { success: true, session: result.session },
      });
    } else {
      navigate('/login');
    }

    return result;
  };

  const [menu, setMenu] = useState([
    { name: 'Monitoreo', key: '1', href: '/monitoreo' },
    { name: 'Prevención', key: '2', href: '/prevencion' },
    { name: 'Riesgos', key: '3', href: '/riesgos' },
    {
      name: 'Biblioteca',
      key: '4',
      href: '#',
      subMenu: [
        { name: 'Artículos', href: '/articulos', key: '4.1' },
        {
          name: 'Condicionados y Cláusulas',
          href: `${process.env.REACT_APP_WEB_PATH_S3_NEW}${PATH_CONDITIONS_CLAUSES}`,
          key: '3.2',
        },
        {
          name: 'Condiciones y Cláusulas Seguro de Caución',
          href: `${process.env.REACT_APP_WEB_PATH_S3_NEW}${PATH_CONDITIONS_CLAUSES_PUBLIC_WORKS}`,
          key: '3.3',
        },
      ],
    },
  ]);

  const [searchText, setSearchText] = useState(query.get('p') ?? '');
  const [results, setResults] = useState(null);
  const [showMenuMobile, setShowMenuMobile] = useState(false);
  const [showSearcher, setShowSearcher] = useState(false);
  const [showUserOptions, setShowUserOptions] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  let isAuthenticated = localStorage.getItem('isAuth');
  const inputRef = useRef(null);

  window.addEventListener('storage', function () {
    const isAuth = localStorage.getItem('isAuth');
    if (!isBackSAML && !isCheking && isAuth !== isAuthenticated)
      window.location.reload();
  });

  useEffect(() => {
    if (isBackSAML) {
      setTimeout(function () {
        window.location.assign('/?checking=true');
      }, 3000);
    }

    if (isCheking) {
      checkLogin().then(async (result) => {
        if (result.success) {
          checkIfExistUser(result.session.idToken.payload.email).then(
            (user) => {
              if (user && process.env.REACT_APP_ANALYTICS_ENABLE !== '0') {
                pushDigitalData(
                  {
                    company: {
                      name: user?.user?.company?.name || '',
                      user: user?.user?.username || '',
                    },
                  },
                  TRACK.LOGIN,
                );
              } else {
                localStorage.clear();
                localStorage.setItem('errorTakeSession', true);
                navigate('/login');
              }
            },
          );
        }
      });
    }
  }, [checkLogin, isBackSAML, isCheking]);

  useEffect(() => {
    if (isCheking)
      if (isLogged) {
        const route =
          user?.rol?.slug === 'proveedor'
            ? '/servicio-de-prevencion-solicitud-proveedor'
            : '/monitoreo/mapas';
        navigate(route);
      }
  }, [isCheking, isLogged, user]);

  useEffect(() => {
    if (showSearcherParam > 0) handlerShowSearcher();
  }, [showSearcherParam]);

  useEffect(() => {
    setShowUserOptions(false);
  }, [showSearcherParam, showMenuMobile, showSearcher]);

  const handlerDropdown = (key, status) => {
    let newMenu = [...menu];
    newMenu = newMenu.map((item) => {
      if (item.key === key)
        item.active = status !== undefined ? status : !item.active;

      return item;
    });
    setMenu(newMenu);
  };

  const handlerShowSearcher = () => {
    const newShowSearcher = !showSearcher;
    if (newShowSearcher) handlerDisableScroll();
    else enableRootScroll();

    setShowSearcher(newShowSearcher);
  };

  const handlerDisableScroll = () => {
    disableRootScroll();
    if (query.get('p')) {
      setSearchText(query.get('p'));
      setResults(null);
      inputRef.current.focus();
    } else {
      clearSearch();
    }
  };

  const handlerSearchSuggestions = async (text) => {
    setSearchText(text);
    text = text.trim();
    const { list } = await getSearchArticles({ query: text });

    const resNoAccents = list.map((item) => ({
      ...item,
      title: removeAccents(item.title),
    }));
    const newRes = resNoAccents.map((item) => {
      const { title } = item;
      const index = title.search(new RegExp(text, 'gi'));

      let newTitle = '';
      if (index !== -1)
        newTitle =
          title.substring(0, index) +
          `<span class='search-result__text--selected'>
        ${title.substring(index, index + text.length)}</span>` +
          title.substring(index + text.length, title.length);
      else newTitle = title;

      return {
        ...item,
        title: newTitle,
      };
    });
    setResults(newRes);
  };

  const handlerSearch = () => {
    if (searchText) {
      window.open(`/resultados-de-busqueda?p=${searchText}`, '_self');
    }
  };

  const clearSearch = () => {
    setSearchText('');
    setResults(null);
  };

  const handleLogOut = async () => {
    await logout();
    clearTokenIntoLocalStore();
    setTimeout(() => {
      window.location.assign('/');
    }, 200);
    localStorage.setItem('isAuth', false);
  };

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return isBackSAML || isCheking ? (
    <div
      style={{
        backgroundColor: 'white',
        position: 'fixed',
        zIndex: 100,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
      }}
    >
      <Spin
        indicator={antIcon}
        style={{ width: 100, margin: '20px auto', display: 'block' }}
      />
    </div>
  ) : (
    <>
      <nav
        className={`navigation-portal ${
          secondary ? 'navigation-portal--secondary' : ''
        } ${prevScrollPos === 0 ? '' : 'bg-blur'}`}
      >
        <div className='container'>
          <div className='navigation-portal__box'>
            <a href='/' className='logo'>
              <div className='logo__img'>
                <img src='/images/logo.svg' alt='Logo Rimac' />
              </div>
              <hr className='logo__divider' />
              <span className='logo__text'>
                Centro de Monitoreo y Prevención
              </span>
            </a>
          </div>
          <div className='navigation-portal__box navigation-portal__links'>
            <ul className='nav'>
              {menu.map((item) => (
                <li
                  key={item.key}
                  className='nav__item'
                  onMouseEnter={
                    item.subMenu
                      ? () => handlerDropdown(item.key, true)
                      : undefined
                  }
                  onMouseLeave={
                    item.subMenu
                      ? () => handlerDropdown(item.key, false)
                      : undefined
                  }
                  onClick={
                    item.subMenu ? () => handlerDropdown(item.key) : undefined
                  }
                >
                  <a href={item.subMenu ? undefined : item.href}>
                    {item.name}
                    {item.subMenu && (
                      <svg
                        className='icon'
                        width='10'
                        height='6'
                        viewBox='0 0 10 6'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          fillRule='evenodd'
                          clipRule='evenodd'
                          d='M9.76126 0.253179C10.0796 0.582269 10.0796 1.11004 9.76126 1.43913L5.5946 5.74682C5.26808 6.08439 4.73192 6.08439 4.4054 5.74682L0.238737 1.43913C-0.0795794 1.11004 -0.0795794 0.582268 0.238737 0.253178C0.565257 -0.0843933 1.10141 -0.0843933 1.42793 0.253178L5 3.94615L8.57207 0.253179C8.89859 -0.0843929 9.43474 -0.0843929 9.76126 0.253179Z'
                        />
                      </svg>
                    )}
                  </a>
                  {item.subMenu && (
                    <ul
                      className={`nav__second ${
                        item.active ? 'nav__second--active' : ''
                      }`}
                    >
                      {item.subMenu.map((subItem) => (
                        <li key={subItem.key} className='nav__second__item'>
                          <a href={subItem.href ?? '#'}>{subItem.name}</a>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
            <div
              className='nav-mobile--fix-shadow'
              style={{ display: showMenuMobile ? 'block' : 'none' }}
            ></div>
            <ul
              className={`nav-mobile ${
                showMenuMobile ? 'nav-mobile--actived' : ''
              }`}
            >
              {menu.map((item) => (
                <li
                  key={item.key}
                  className={`nav-mobile__item ${
                    item.active ? 'nav-mobile__item-full' : ''
                  }`}
                  onClick={
                    item.subMenu ? () => handlerDropdown(item.key) : undefined
                  }
                >
                  <a href={item.subMenu ? undefined : item.href}>
                    {item.name}
                    {item.subMenu && <RightOutlined />}
                  </a>
                  {item.subMenu && (
                    <ul
                      className={`nav-mobile__second ${
                        item.active ? 'nav-mobile__second--actived' : ''
                      }`}
                    >
                      {item.subMenu.map((subItem) => (
                        <li
                          key={subItem.key}
                          className='nav-mobile__second__item'
                        >
                          <a href={subItem.href ?? '#'}>{subItem.name}</a>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </div>
          <div
            className='navigation-portal__box'
            style={{ position: 'relative' }}
          >
            <div className='buttons'>
              <button className='buttons__search' onClick={handlerShowSearcher}>
                <img
                  src='/images/portal/icon_search.svg'
                  alt='Utilizar Buscador'
                />
              </button>
              <a href='tel:014111111'>
                <img
                  src='/images/portal/icon_telephone.svg'
                  alt='Realizar llamada'
                />
              </a>
              <Button
                className='secondary medium'
                style={{
                  maxWidth: '180px',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
                onClick={user ? () => setShowUserOptions((s) => !s) : undefined}
                href={!user ? '/login' : undefined}
                target='_self'
                prefix={<UserOutlined />}
              >
                {user ? `${user.name} ${user.last_name1}` : 'Iniciar sesión'}
              </Button>
            </div>
            <div className='buttons-mobile'>
              <a href='tel:014111111' className='buttons-mobile__item'>
                <img
                  src='/images/portal/icon_telephone.svg'
                  alt='Realizar llamada'
                />
              </a>
              <a
                href={!user ? '/login' : undefined}
                onClick={user ? () => setShowUserOptions((s) => !s) : undefined}
                rel='noreferrer'
              >
                <div className='buttons-mobile__item buttons-mobile__user'>
                  <svg
                    width='28'
                    height='28'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M20.2636 17.996C20.2587 16.5477 19.8722 15.1262 19.143 13.8748C18.4138 12.6234 17.3677 11.5863 16.1101 10.8679C16.3743 10.2847 16.5117 9.65206 16.5131 9.01182V7.51216C16.5117 6.3194 16.0372 5.17591 15.1938 4.33251C14.3504 3.4891 13.2069 3.01463 12.0141 3.01318C10.8211 3.01463 9.67735 3.48901 8.83351 4.33234C7.98967 5.17567 7.5146 6.31915 7.51242 7.51216V9.01182C7.51312 9.65155 7.65151 10.2836 7.91818 10.8651C6.65791 11.5854 5.61014 12.6256 4.88082 13.8806C4.15149 15.1356 3.76646 16.561 3.76465 18.0125V21.0118H20.2636V17.996ZM9.02304 7.50119C9.02736 6.70705 9.34475 5.94668 9.9063 5.38513C10.4678 4.82358 11.2282 4.50619 12.0224 4.50187C12.8127 4.51192 13.5675 4.83184 14.1243 5.39277C14.6811 5.9537 14.9955 6.71082 14.9997 7.50119V9.00085C14.9954 9.79498 14.678 10.5554 14.1165 11.1169C13.5549 11.6785 12.7946 11.9958 12.0004 12.0002C11.2063 11.9958 10.4459 11.6785 9.88436 11.1169C9.32282 10.5554 9.00543 9.79498 9.00111 9.00085L9.02304 7.50119ZM5.27253 19.5012V18.0015C5.2724 16.7977 5.59477 15.6157 6.20613 14.5786C6.81749 13.5416 7.6955 12.6872 8.74888 12.1043C9.16801 12.5451 9.67237 12.8962 10.2313 13.136C10.7903 13.3759 11.3922 13.4997 12.0004 13.4998C12.6083 13.4998 13.2098 13.3761 13.7683 13.1362C14.3269 12.8963 14.8307 12.5452 15.2492 12.1043C16.3064 12.6835 17.1889 13.5356 17.8046 14.5719C18.4204 15.6082 18.747 16.7906 18.7503 17.996V19.5012H5.27253Z'
                      fill='#03050F'
                    />
                  </svg>
                </div>
              </a>
              <div
                className='buttons-mobile__item buttons-mobile__menu'
                onClick={() => setShowMenuMobile((s) => !s)}
              >
                <span
                  className={`buttons-mobile__nav-icon ${
                    showMenuMobile ? 'buttons-mobile__nav-icon--opened' : ''
                  }`}
                >
                  &nbsp;
                </span>
              </div>
            </div>
            {showUserOptions && (
              <div className='user-options'>
                <a
                  href='/monitoreo/mapas'
                  target='_blank'
                  className='user-options__item'
                >
                  Ir a la plataforma
                </a>
                <a onClick={handleLogOut} className='user-options__item'>
                  Cerrar Sesión
                </a>
              </div>
            )}
          </div>
        </div>
      </nav>
      <NavigationRed
        user={user}
        results={results}
        inputRef={inputRef}
        showSearcher={showSearcher}
        handlerShowSearcher={handlerShowSearcher}
        searchText={searchText}
        handlerSearchSuggestions={handlerSearchSuggestions}
        handlerSearch={handlerSearch}
        clearSearch={clearSearch}
        handleLogOut={handleLogOut}
      />
    </>
  );
};

export default HeaderPortal;
