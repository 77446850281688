import React, { useState } from 'react';
import { Button, Input, Select, Modal } from 'antd';
import { IconCloseCircle } from '../../../../../common/Icons';

const { Search } = Input;
const { Option } = Select;

const ModalDelete = ({ visible, onConfirm, users, applyFilters, user }) => {
  const [search, setSearch] = useState('');
  const [filterSearch, setFilterSearch] = useState({});

  const handleCancel = () => {
    setSearch('');
    setFilterSearch({});
    applyFilters({ txt: '', filter: {} });
    onConfirm(false);
  };

  const handleChangeFilter = (field, value) => {
    setFilterSearch({
      ...filterSearch,
      [field]: value,
    });
  };

  const handdleApplyFilters = () => {
    applyFilters({ txt: search, filter: filterSearch });
    onConfirm(false);
  };

  return (
    <Modal
      onOk={null}
      onCancel={handleCancel}
      open={visible}
      okText={null}
      cancelText={null}
      destroyOnClose
      maskClosable={false}
      className='mdl-helpCenter-filter'
      footer={null}
      centered={true}
    >
      <div className='filter__text mb-4'>Filtra tu búsqueda</div>
      <div className='filter__description'>Realiza una búsqueda específica</div>
      <div className='filter__box flex flex-row flex-wrap pb-4 mb-4'>
        <Search
          placeholder='Palabra clave'
          style={{ width: '100%' }}
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        />

        {user && user.rol.slug !== 'usuario_cliente' && (
          <Select
            className='filter__select'
            value={filterSearch.user_id}
            onChange={(val) => handleChangeFilter('user_id', val)}
            placeholder='Usuario'
          >
            <Option key='user' value={false}>
              Usuario
            </Option>
            {users.map((u) => (
              <Option key={u.id} value={u.id}>
                {u.user}
              </Option>
            ))}
          </Select>
        )}

        <Select
          className='filter__select'
          value={filterSearch.status}
          onChange={(val) => handleChangeFilter('status', val)}
          placeholder='Estado'
        >
          <Option value={false}>Estado</Option>
          <Option value={1}>Nuevo</Option>
          <Option value={2}>Cerrado</Option>
          <Option value={3}>En proceso</Option>
        </Select>
        <Button
          type='primary'
          className='btn-applyFilter'
          onClick={handdleApplyFilters}
        >
          Aplicar Filtros
        </Button>
      </div>
    </Modal>
  );
};

export default ModalDelete;
