import React from 'react';

export const IconReturnHtml = () => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle cx='16' cy='16' r='15' stroke='#4F4FFF' strokeWidth='2' />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18.8485 11.1513C18.3799 10.6826 17.6201 10.6826 17.1514 11.1513L13.1514 15.1513C12.6828 15.6199
      12.6828 16.3797 13.1514 16.8483L17.1514 20.8483C17.6201 21.317 18.3799 21.317 18.8485 20.8483C19.3171
      20.3797 19.3171 19.6199 18.8485 19.1513L15.697 15.9998L18.8485 12.8483C19.3171 12.3797 19.3171 11.6199
      18.8485 11.1513Z'
      fill='#4F4FFF'
    />
  </svg>
);

export const IconSearchHtml = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
  >
    <path
      d='M15.4839 13.4194L12.7312 10.4946C13.4194 9.46237 13.7634 8.25807 13.7634 6.88172C13.7634 3.09677
       10.6667 0 6.88172 0C3.09677 0 0 3.09677 0 6.88172C0 10.6667 3.09677 13.7634 6.88172 13.7634C8.25807
        13.7634 9.46237 13.4194 10.4946 12.7312L13.4194 15.6559C13.7634 15.828 14.1075 16 14.4516 16C14.7957
         16 15.1398 15.828 15.4839 15.4839C16.172 14.9677 16.172 13.9355 15.4839 13.4194ZM6.88172 12.043C3.95699
          12.043 1.72043 9.80645 1.72043 6.88172C1.72043 3.95699 3.95699 1.72043 6.88172 1.72043C9.80645 1.72043
           12.043 3.95699 12.043 6.88172C12.043 9.80645 9.80645 12.043 6.88172 12.043Z'
      fill='currentColor'
    />
  </svg>
);

export const IconRange = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    fill='none'
    viewBox='0 0 16 16'
  >
    <path
      fill='#141938'
      d='M14.384 2.667h-.808v-.834c0-.466-.356-.833-.808-.833-.453 0-.808.367-.808.833v.834H8.727v-.834c0-.466-.355-.833-.808-.833-.452
       0-.808.367-.808.833v.834H3.88v-.834c0-.466-.356-.833-.808-.833-.453 0-.808.367-.808.833v.834h-.647C.727 2.667 0 3.417 0 4.333v10C0
        15.25.727 16 1.616 16h12.768c.889 0 1.616-.75 1.616-1.667v-10c0-.916-.727-1.666-1.616-1.666zM4.283 13.5h-.808c-.453 0-.808-.367-.808-.833
         0-.467.355-.834.808-.834h.808c.452 0 .808.367.808.834 0 .466-.356.833-.808.833zm0-3.333h-.808c-.453
          0-.808-.367-.808-.834 0-.466.355-.833.808-.833h.808c.452 0 .808.367.808.833 0 .467-.356.834-.808.834zm4.04
           3.333h-.808c-.452 0-.808-.367-.808-.833 0-.467.356-.834.808-.834h.808c.453 0 .808.367.808.834 0
            .466-.355.833-.808.833zm0-3.333h-.808c-.452 0-.808-.367-.808-.834 0-.466.356-.833.808-.833h.808c.453 0 .808.367.808.833 0
             .467-.355.834-.808.834zm4.04 3.333h-.807c-.453 0-.808-.367-.808-.833 0-.467.355-.834.808-.834h.808c.452 0 .808.367.808.834 0
              .466-.356.833-.808.833zm0-3.333h-.807c-.453 0-.808-.367-.808-.834 0-.466.355-.833.808-.833h.808c.452 0 .808.367.808.833 0 .467-.356.834-.808.834z'
    ></path>
  </svg>
);

export const IconArrowSelect = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12.7613 5.25318C13.0796 5.58227 13.0796 6.11004 12.7613 6.43913L8.5946 10.7468C8.26808 11.0844
       7.73192 11.0844 7.4054 10.7468L3.23874 6.43913C2.92042 6.11004 2.92042 5.58227 3.23874 5.25318C3.56526
        4.91561 4.10141 4.91561 4.42793 5.25318L8 8.94615L11.5721 5.25318C11.8986 4.91561 12.4347 4.91561 12.7613 5.25318Z'
      fill='currentColor'
    />
  </svg>
);

export const iconRight = ({ color = '#4F4FFF' }) => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle
      cx='16'
      cy='16'
      r='15'
      transform='rotate(-90 16 16)'
      stroke={color}
      strokeWidth='2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M13.2532 11.2387C13.5823 10.9204 14.11 10.9204 14.4391 11.2387L18.7468 15.4054C19.0844
       15.7319 19.0844 16.2681 18.7468 16.5946L14.4391 20.7613C14.11 21.0796 13.5823 21.0796
        13.2532 20.7613C12.9156 20.4347 12.9156 19.8986 13.2532 19.5721L16.9462 16L13.2532
         12.4279C12.9156 12.1014 12.9156 11.5653 13.2532 11.2387Z'
      fill={color}
    />
  </svg>
);

export const iconLeft = ({ color = '#4F4FFF' }) => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 32 32'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <circle
      cx='16'
      cy='16'
      r='15'
      transform='rotate(90 16 16)'
      stroke={color}
      strokeWidth='2'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M18.7468 11.2387C18.4177 10.9204 17.89 10.9204 17.5609 11.2387L13.2532
       15.4054C12.9156 15.7319 12.9156 16.2681 13.2532 16.5946L17.5609 20.7613C17.89
        21.0796 18.4177 21.0796 18.7468 20.7613C19.0844 20.4347 19.0844 19.8986 18.7468
         19.5721L15.0539 16L18.7468 12.4279C19.0844 12.1014 19.0844 11.5653 18.7468 11.2387Z'
      fill={color}
    />
  </svg>
);

export const IconDots = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={24}
    height={24}
    fill='none'
    {...props}
  >
    <circle
      cx={20}
      cy={12}
      r={2}
      transform='rotate(180 20 12)'
      fill={props.disabled ? '#A9AFD9' : '#4F4FFF'}
    />
    <circle
      cx={12}
      cy={12}
      r={2}
      transform='rotate(180 12 12)'
      fill={props.disabled ? '#A9AFD9' : '#4F4FFF'}
    />
    <circle
      cx={4}
      cy={12}
      r={2}
      transform='rotate(180 4 12)'
      fill={props.disabled ? '#A9AFD9' : '#4F4FFF'}
    />
  </svg>
);

export const IconInfoService = (props) => (
  <svg
    width='48'
    height='48'
    viewBox='0 0 48 48'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M40.275 24.0449H26.1094C24.8567 24.0449 23.6554 24.5425 22.7696
       25.4283C21.8838 26.3141 21.3862 27.5154 21.3862 28.768V35.8518C21.3862
        37.1044 21.8838 38.3058 22.7696 39.1916C23.6554 40.0773 24.8567 40.5749
         26.1094 40.5749H33.7687L40.275 44.9999V40.5749C40.8954 40.5752 41.5098
          40.4532 42.083 40.2159C42.6563 39.9787 43.1772 39.6308 43.6159 39.1922C44.0547
           38.7536 44.4028 38.2328 44.6403 37.6597C44.8777 37.0865 45 36.4722 45 35.8518V28.768C45
            28.1476 44.8777 27.5333 44.6403 26.9602C44.4028 26.387 44.0547 25.8662 43.6159
             25.4276C43.1772 24.989 42.6563 24.6412 42.083 24.4039C41.5098 24.1667 40.8954
              24.0447 40.275 24.0449ZM39.1875 35.9999H27.1875V33.7499H39.1875V35.9999ZM39.1875
               30.7499H27.1875V28.4999H39.1875V30.7499Z'
      fill='url(#paint0_linear_4838_72036)'
    />
    <path
      d='M33.75 3C35.1424 3 36.4777 3.55312 37.4623 4.53769C38.4469 5.52226
       39 6.85761 39 8.25V21.795H36V8.25C36 7.65326 35.7629 7.08097 35.341 6.65901C34.919
        6.23705 34.3467 6 33.75 6H8.25C7.65326 6 7.08097 6.23705 6.65901 6.65901C6.23705 7.08097
         6 7.65326 6 8.25V24.75C6 25.3467 6.23705 25.919 6.65901 26.341C7.08097 26.7629 7.65326
          27 8.25 27H19.1456C19.1456 27.0881 19.1456 27.1763 19.1456 27.2663V30H17.775L9 37.3125V30H8.25C6.85761
           30 5.52226 29.4469 4.53769 28.4623C3.55313 27.4777 3 26.1424 3 24.75V8.25C3 6.85761 3.55313 5.52226
            4.53769 4.53769C5.52226 3.55312 6.85761 3 8.25 3H33.75ZM22.9388
             20.3756V13.4006H19.2637V15.5006H20.8388V20.3756H19.2637V22.4756H24.5137V20.3756H22.9388ZM21.3638 12.3506C21.6753
              12.3506 21.9798 12.2583 22.2388 12.0852C22.4978 11.9121 22.6996 11.6661 22.8189 11.3784C22.9381 11.0906 22.9693
               10.7739 22.9085 10.4684C22.8477 10.1628 22.6977 9.8822 22.4774 9.66193C22.2572 9.44166 21.9765 9.29166 21.671
                9.23089C21.3655 9.17012 21.0488 9.20131 20.761 9.32051C20.4732 9.43972 20.2272 9.64159 20.0542 9.9006C19.8811
                 10.1596 19.7887 10.4641 19.7887 10.7756C19.7887 11.1933 19.9547 11.5939 20.2501 11.8893C20.5454 12.1847
                  20.946 12.3506 21.3638 12.3506Z'
      fill='url(#paint1_linear_4838_72036)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_4838_72036'
        x1='22.004'
        y1='25.6895'
        x2='39.147'
        y2='45.0076'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.35' stopColor='#F7052D' />
        <stop offset='0.85' stopColor='#CA5AFA' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_4838_72036'
        x1='3'
        y1='3'
        x2='20.8009'
        y2='45.0218'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.272135' stopColor='#34263B' />
        <stop offset='0.658079' stopColor='#13172C' />
      </linearGradient>
    </defs>
  </svg>
);

export const IconCopy = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={16}
    height={16}
    fill='none'
    {...props}
  >
    <path
      d='M11.5 4.498H11V3.51a1.499 1.499 0 0 0-1.5-1.498H4.506a1.5 1.5 0 0 0-1.5 1.498v6.988a1.501 1.501 0 0
       0 1.5 1.5H5.51v.5a1.5 1.5 0 0 0 1.5 1.499h4.499a1.497 1.497 0 0 0 1.5-1.499v-6.5a1.5 1.5 0 0 0-1.51-1.5Zm-6 1.5v5H4.496a.5.5 0
        0 1-.5-.5V3.51a.501.501 0 0 1 .51-.5h5a.502.502 0 0 1 .5.5v.988H7.001a1.5 1.5 0 0 0-1.5 1.5Zm5.001 5h-2.5a.503.503 0 0
         1 0-1.006h2.5a.503.503 0 1 1 0 1.006Zm0-2.5h-2.5a.503.503 0 1 1 0-1.006h2.5a.503.503 0 1 1 0 1.006Z'
      fill='#4F4FFF'
    />
  </svg>
);
