import { Radio as AntRadio } from 'antd';

const RadioButton = ({ label, options, onChange, value, ...props }) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <label>{label}</label>
      <AntRadio.Group onChange={onChange} value={value} {...props}>
        {options.map((option) => (
          <AntRadio key={option.value} value={option.value}>
            {option.label}
          </AntRadio>
        ))}
      </AntRadio.Group>
    </div>
  );
};
export default RadioButton;
