import {
  LOADED_RPT_QUIZZES,
  LOADING_RPT_QUIZZES_FAILED,
} from '../redux/actions/actionsType';
import { get } from '../lib/ajax';

export const handleSuccessResponse = (dispatch, reportType, r) => {
  if (r.data.payload.running) {
    const intervalId = setInterval(() => {
      get(`/request_service_items/${reportType}Status`)
        .then((r) => {
          handleIntervalResponse(dispatch, r, intervalId);
        })
        .catch((error) => {
          console.error(error);
          clearInterval(intervalId);
        });
    }, 6000);
  } else {
    dispatch({ type: LOADING_RPT_QUIZZES_FAILED });
  }
};

export const handleIntervalResponse = (dispatch, r, intervalId) => {
  if (r?.data?.success) {
    const { payload } = r.data;
    const config = payload ? JSON.parse(payload.value) : {};

    if (config.error) {
      dispatch({ type: LOADING_RPT_QUIZZES_FAILED });
      clearInterval(intervalId);
    } else if (config.fileDownloadPath) {
      dispatch({
        type: LOADED_RPT_QUIZZES,
        payload: config.fileDownloadPath,
      });
      clearInterval(intervalId);
    }
  }
};
