import {
  LOADED_SERVICE,
  LOAD_SERVICE_LOADING,
  LOADING_CREATE_SERVICE,
  OPEN_FORM_SERVICE,
} from '../actions/actionsType';

const initialState = {
  list: [],
  listWithFixed: [],
  openedForm: false,
  isLoadingList: false,
  isLoadingCreate: false,
};

const service = (state, action) => {
  if (!state) state = initialState;

  switch (action.type) {
    case LOAD_SERVICE_LOADING:
      let toUpdt = {};
      for (let key in action.payload) toUpdt[key] = action.payload[key];

      return {
        ...state,
        ...toUpdt,
      };
    case LOADED_SERVICE:
      return {
        ...state,
        list: action.payload.filter((service) => !service.fixed),
        listWithFixed: action.payload,
        isLoadingList: false,
      };
    case OPEN_FORM_SERVICE:
      return {
        ...state,
        openedForm: action.payload,
      };
    case LOADING_CREATE_SERVICE:
      return {
        ...state,
        isLoadingCreate: action.payload,
      };
    default:
      return state;
  }
};

export default service;
