import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, notification } from 'antd';

import {
  createMyLayer,
  getMyLayers,
  getPresignedCustomLayersUrl,
} from '../../../../services/maps';
import { sendBannerImage } from '../../../../services/banner';
import {
  MY_LAYERS_IS_DRAW_MODE,
  MY_LAYERS_IS_LOADING_CREATE,
  MY_LAYERS_SET_ACTIVE_LAYERS,
  MY_LAYERS_SET_DRAWS,
} from '../../../../redux/actions/actionsType';

import { transformDrawsToGeoJSON } from '../../../../lib/utils';
import InputWithLabel from '../../../InputWithLabel';

const FormCreate = ({ setStep, setShowForm }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const isLoadingCreate = useSelector(
    (state) => state.myLayers.isLoadingCreate,
  );
  const draws = useSelector((state) => state.myLayers.draws);
  const [isErrorFile, setErrorFile] = useState(false);

  const handleSendLayer = async () => {
    try {
      const data = await form.validateFields();
      let file = transformDrawsToGeoJSON(draws);

      if (!file) {
        setErrorFile(true);
        return;
      }

      if (file.name) {
        dispatch({ type: MY_LAYERS_IS_LOADING_CREATE, payload: true });

        const res = await getPresignedCustomLayersUrl({ name: file.name });
        const { success, payload } = res?.data ?? {};
        const typeFile = file.name.split('.').pop();

        if (success && payload && payload?.url) {
          await sendBannerImage(payload?.url, file);

          await createMyLayer({
            ...data,
            typeFile,
            url: payload.nameKey,
          });

          await getMyLayers(dispatch);
        }
      }
    } catch (error) {
      notification.error({
        message: 'Ha ocurrido un error al subir el archivo.',
      });
    } finally {
      dispatch({ type: MY_LAYERS_IS_LOADING_CREATE, payload: false });
      dispatch({ type: MY_LAYERS_SET_DRAWS, payload: [] });
      setStep(1);
      setShowForm(false);
    }
  };

  useEffect(() => {
    dispatch({ type: MY_LAYERS_SET_ACTIVE_LAYERS, payload: [] });
    dispatch({ type: MY_LAYERS_IS_DRAW_MODE, payload: true });

    return () => dispatch({ type: MY_LAYERS_IS_DRAW_MODE, payload: false });
  }, [dispatch]);

  return (
    <Form className='form-simulations' style={{ marginTop: '8px' }} form={form}>
      <Form.Item
        name='name'
        initialValue=''
        rules={[{ required: true, message: 'Este campo es requerido' }]}
      >
        <InputWithLabel label={'Nombre para la capa'} />
      </Form.Item>
      <div className='form-actions'>
        <Button
          type='submit'
          className='form-button-success'
          onClick={handleSendLayer}
          disabled={isLoadingCreate || isErrorFile}
        >
          Crear Capa
        </Button>
      </div>
    </Form>
  );
};

export default FormCreate;
