import React from 'react';
import { Form, Input, Modal, notification } from 'antd';

import text from '../../../../../config/text_es';
import InputWithLabel, {
  SelectWithLabel,
} from '../../../../../common/InputWithLabel';
import { IconCloseCircle } from '../../../../../common/Icons';

const ModalCourse = ({
  handleClose,
  handleAction,
  course,
  visible,
  typePolizas,
  isLoadingCreate,
}) => {
  const [form] = Form.useForm();
  const { validateFields } = form;

  const handleOk = () => {
    validateFields().then((values) => {
      if (values.name.trim() === '') {
        notification.destroy();

        return notification.warning({
          description: 'Agregue un nombre de curso válido.',
          message: 'Crear curso!',
        });
      }

      handleAction(values);
    });
  };

  return (
    <Modal
      open={visible}
      title=''
      closable={true}
      onCancel={() => handleClose()}
      centered={true}
      onOk={handleOk}
      className='modal-provider'
      cancelText='Cancelar'
      okText={`${course ? 'Guardar cambios' : 'Crear curso'}`}
      confirmLoading={isLoadingCreate}
    >
      <div className='content-modal cmp_minimal-scroll-y'>
        <h2>{`${course ? 'Editar curso' : 'Crear curso'}`}</h2>

        <Form form={form} disabled={isLoadingCreate} className='form-action'>
          <Form.Item name='id' initialValue={course ? course.id : ''}>
            <Input type='hidden' />
          </Form.Item>

          <Form.Item
            name='name'
            label='Nombre del curso:'
            initialValue={course ? course?.name : ''}
            rules={[{ required: true, message: text.required_message }]}
          >
            <InputWithLabel label='Curso' disabled={isLoadingCreate} />
          </Form.Item>

          <Form.Item
            name='target'
            label='Tipo de curso:'
            initialValue={course ? course?.typePoliza?.name : ''}
            rules={[{ required: true, message: text.required_message }]}
          >
            <SelectWithLabel
              label='Escoje el tipo de curso'
              disabled={isLoadingCreate}
              options={typePolizas}
            />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default ModalCourse;
