import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, useLocation } from 'react-router-dom';
import { checkPermission } from './lib/security';

import ChangelogModal from './components/admin/Changelog/ChangelogModal';
import EmergencyPopup from './common/EmergencyPopup/EmergencyPopup';
import Login from './components/portal/Login/Login';
import ForgotPassword from './components/portal/ForgotPassword';
import Register from './common/Register/Register';
import Recovery from './common/Recovery';
import Template from './common/Template';
import Unsubscribe from './common/Unsubscribe';

import Home from './components/portal/Home';
import Monitoring from './components/portal/Monitoring';
import Prevention from './components/portal/Prevention';
import Risks from './components/portal/Risks';
import SearchResults from './components/portal/SearchResults';
import Articles from './components/portal/Articles';
import Article from './components/portal/Article';

import AdminRoutes from './components/admin/AdminRoutes';

import HelpCenterList from './components/admin/Support/ui';
import HelpCenterChat from './components/admin/SupportChat';
import ReminderModal from './common/ReminderModal';
import MapContainer from './components/Map';
import NewMap from './components/NewMap';
import NotFound from './components/admin/NotFound';

function App() {
  const locationReact = useLocation();
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (window.s) {
      const pathname = locationReact.pathname
        .split('/')
        .join(':')
        .toLowerCase();
      const pagename =
        pathname.lastIndexOf(':') === pathname.length - 1
          ? pathname.slice(0, -1)
          : pathname;
      const page = pathname !== ':' ? 'mon' + pagename : 'mon:home';
      window.s.pageName = page;
      window.s.page = page;
      window.s.pageURL = window.location.href;
      setTimeout(() => {
        window.s.t();
      }, 1000);
    }
  }, [locationReact]);

  return (
    <Routes>
      <Route path='login' element={<Login />} />
      <Route path='olvide-contrasena' element={<ForgotPassword />} />

      <Route path='/register/:code' element={<Register />} />
      <Route path='/recovery/:code' element={<Recovery />} />
      <Route path='/unsubscribe/:code' element={<Unsubscribe />} />

      <Route path='/' element={<Home />} />
      <Route path='/monitoreo' element={<Monitoring />} />
      <Route path='/prevencion' element={<Prevention />} />
      <Route path='/riesgos' element={<Risks />} />
      <Route path='/resultados-de-busqueda' element={<SearchResults />} />
      <Route path='/articulos' element={<Articles />} />
      <Route path='/articulos/:article_url' element={<Article />} />

      <Route
        path='*'
        element={
          <Template>
            <ChangelogModal />
            <Routes>
              <Route path='/*' element={<AdminRoutes />} />
              <Route path='monitoreo/mapa' element={<MapContainer />} />
              <Route path='monitoreo/mapas' element={<MapContainer />} />
              <Route path='monitoreo/new-map' element={<NewMap />} />
              <Route
                path='soporte/centro-de-ayuda'
                element={<HelpCenterList />}
              />
              <Route
                path='soporte/centro-de-ayuda/:id'
                element={<HelpCenterChat />}
              />
              <Route path='*' element={<NotFound />} />
            </Routes>
            {checkPermission(user, 'allow_reminder_modal') && <ReminderModal />}
            <EmergencyPopup />
          </Template>
        }
      />
    </Routes>
  );
}

export default App;
