import React, { memo, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, notification } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { derivarRequestServicesItem } from '../../../../../../../services/requestServices';

const DeriveButton = ({
  providerId,
  requestServiceItemId,
  currentProvider,
  style,
  textButton = 'Derivar',
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const _handleDerivarProvider = useCallback(async () => {
    setLoading(true);
    const result = await derivarRequestServicesItem(
      requestServiceItemId,
      providerId,
      dispatch,
    );
    if (result) {
      setLoading(false);
      notification.success({
        description: 'Se guardaron correctamente los cambios',
        message: 'Cambios guardados',
      });
      navigate('/servicio-de-prevencion');
    }
  }, [dispatch, providerId, requestServiceItemId]);

  const disabledButton = useMemo(() => {
    return !providerId || loading || currentProvider === providerId;
  }, [loading, providerId, currentProvider]);

  return (
    <Button
      type='primary'
      size='large'
      style={style ?? {}}
      className='pl-4 pr-4'
      disabled={disabledButton}
      onClick={_handleDerivarProvider}
    >
      {textButton}
    </Button>
  );
};

export default memo(DeriveButton);
