import React, { memo, useState } from 'react';
import moment from 'moment';

import { Button } from 'antd';

import {
  IconCloseWithoutCircle,
  IconDots,
} from '../../../../ui/PreventionService/Reports/utils';

const renderTypePoliza = (data, listTypePoliza) => {
  if (Array.isArray(listTypePoliza) && listTypePoliza.length > 0) {
    const type = listTypePoliza.find((item) => item.id === data?.typePolizaId);
    if (type) return type.name;
  }

  return '';
};

const renderTextState = (data) => {
  if (data?.state <= 0) return <p className='tag-red'>Inactivo</p>;

  const start = moment(data['start_date']);
  const startFormated = moment(
    start.format('DD-MM-YYYY 00:00'),
    'DD-MM-YYYY HH:mm',
  );
  const final = startFormated.clone().add(data['validity'], 'M');
  const days = final.diff(
    moment(moment().format('DD-MM-YYYY 00:00'), 'DD-MM-YYYY HH:mm'),
    'day',
  );

  if (days === 1) return `${days} día vigente`;
  if (days > 1) return `${days} días vigentes`;

  const hours = Math.floor(data?.state / 60);
  if (hours === 1) return `${hours} hora vigente`;
  if (hours > 1) return `${hours} horas vigentes`;

  return `${data?.state} minutos vigentes`;
};

const PolizaCard = ({
  data,
  listTypePoliza,
  onEdit,
  onDiagnostic,
  onComment,
  onReportComercial,
  onReportOperative,
  onReportGeneral,
  onDelete,
  rol,
}) => {
  const [opened, setOpened] = useState(false);
  const [moreAction, setMoreAction] = useState(false);

  return (
    <>
      <article className='card-user card-external-action'>
        <div className={'flex pos-justify'}>
          <div className='flex flex-row flex-justify-justify-between'>
            <span
              style={{ background: '#C8C8FA', height: 20 }}
              className={'withpoint'}
            >
              <div
                style={{ background: '#4F4FFF' }}
                className={'withpoint-circle'}
              ></div>
              <span
                style={{
                  fontFamily: 'BR Sonoma',
                  fontWeight: 900,
                  fontSize: 11,
                  lineHeight: 16,
                }}
              >
                {renderTextState(data)}
              </span>
            </span>
          </div>

          <div className='action'>
            <Button
              style={{ borderRadius: 8, borderColor: 'transparent' }}
              className='button-popover'
              onClick={() => setOpened(true)}
            >
              <IconDots />
            </Button>
          </div>
        </div>

        <div>
          <h4>tipo de póliza</h4>
          <p>{renderTypePoliza(data, listTypePoliza)}</p>
        </div>

        <div>
          <h4>no. póliza</h4>
          <p>{data?.no_poliza}</p>
        </div>

        <div>
          <h4>fecha de inicio</h4>
          <p>{moment(data?.start_date).format('DD-MM-YYYY')}</p>
        </div>

        <div>
          <h4>vigencia</h4>
          <p>{data?.validity} meses</p>
        </div>

        <div>
          <h4>nivel de empresas</h4>
          <p>{data?.levelCompany?.name ? data?.levelCompany?.name : ''}</p>
        </div>

        <div>
          <h4>prima ($)</h4>
          <p>${new Intl.NumberFormat('en-US').format(data?.prima)}</p>
        </div>
      </article>

      {opened && (
        <div className='action-complete'>
          <div className='content'>
            <div className='title'>
              <h3>Más opciones</h3>
              <div
                className='icon'
                onClick={() => {
                  moreAction ? setMoreAction(false) : setOpened(false);
                }}
              >
                <IconCloseWithoutCircle />
              </div>
            </div>

            <div className='buttons'>
              {moreAction ? (
                <>
                  {rol !== 'comercial' && (
                    <div
                      className='button'
                      onClick={() => onReportComercial(data)}
                    >
                      <span>Descargar reportes para comerciales</span>
                    </div>
                  )}
                  <div
                    className='button'
                    onClick={() => onReportOperative(data)}
                  >
                    <span>Descargar reporte operativo</span>
                  </div>
                  {rol !== 'comercial' && (
                    <div
                      className='button'
                      onClick={() => onReportGeneral(data)}
                    >
                      <span>Descargar reporte gerencial</span>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {rol !== 'comercial' && (
                    <>
                      <div
                        className='button'
                        onClick={() => onDiagnostic(data)}
                      >
                        <span>Diagnostico inicial</span>
                      </div>
                      <div className='button' onClick={() => onComment(data)}>
                        <span>Comentarios</span>
                      </div>
                      <div className='button' onClick={() => onEdit(data)}>
                        <span>Editar</span>
                      </div>
                    </>
                  )}
                  <div className='button' onClick={() => setMoreAction(true)}>
                    <span>Reportes</span>
                  </div>
                  {rol !== 'comercial' && (
                    <div className='button' onClick={() => onDelete(data)}>
                      <span>Eliminar</span>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(PolizaCard);
