import React, { memo, useCallback, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Divider, notification } from 'antd';
import { LeftCircleOutlined, DownloadOutlined } from '@ant-design/icons';
import Axios from 'axios';

import {
  companyBulkUpdatePriority,
  getTemplateBulkCompanies,
} from '../../../../../services/company';
import { getPathCredentials } from '../../../../../lib/ajax';
import { signedAxios } from '../../../../../lib/axios';

import BoxLoader from '../../../../../common/BoxLoader';
import InputFile from '../../../../../common/InputFile';
import Title from '../../../../../common/Typograph/Title';

const urlQuery = `${process.env.REACT_APP_API_BASE_URL_NEW}${process.env.REACT_APP_API_BASE_PATH_NEW}`;

const MasiveUpload = ({ isMobileScreen, onBack, keyUpload }) => {
  const cancelToken = useRef();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState();
  const [fileName, setFileName] = useState('');
  const [isLoadingTemplate, setLoadingTemplate] = useState(false);

  const clearText = (text) => (text ? `${text}`.trim() : '');

  const formatRows = useCallback((rows) => {
    if (!rows || rows.lenght === 0) {
      return [];
    }

    const newRows = rows.filter((company) => company['RUC (*)']);

    return newRows.map((company) => {
      return {
        ruc: clearText(company['RUC (*)']),
        business_name: clearText(company['RAZÓN SOCIAL (*)']).toUpperCase(),
        name: clearText(company['NOMBRE COMERCIAL'])
          ? clearText(company['NOMBRE COMERCIAL']).toUpperCase()
          : clearText(company['RAZÓN SOCIAL (*)']).toUpperCase(),
        businessSegment: clearText(company['SEGMENTO (*)']).toLowerCase(),
        typeIndustry: clearText(company['TIPO DE INDUSTRIA']).toLowerCase(),
        economic_group: clearText(company['GRUPO ECONÓMICO']).toUpperCase(),
        broker: clearText(company['CORREDOR']).toLowerCase(),
        gestor: clearText(company['CORREO DEL GESTOR']).toLowerCase(),
      };
    });
  }, []);

  const bulkCompanyExecute = useCallback((dataToSend) => {
    cancelToken.current = Axios.CancelToken.source();

    return getPathCredentials(`${urlQuery}/company?bulkCompanies=1`)
      .then(({ url, credentials }) => {
        return signedAxios(
          {
            method: 'POST',
            url,
            cancelToken: cancelToken.current.token,
            data: dataToSend,
            onUploadProgress: ({ loaded, total }) => {
              setLoader((loaded / total) * 100);
            },
          },
          { credentials },
        );
      })
      .catch(() => {
        notification.error({
          description: 'Hubo un error al subir archivo.',
          message: 'Error',
        });

        return { success: false };
      });
  }, []);

  const _handleChangeFile = useCallback(({ file }) => {
    setFileName(file.name);
  }, []);

  const _handleChangeData = useCallback(
    (data) => {
      setLoader(0);
      if (keyUpload === 'massive_prioritized') {
        const newRows = data
          .filter((cmp) => cmp['RUC (*)'] || cmp['RUC'] || cmp['ruc'])
          .map((cmp) => cmp['RUC (*)'] || cmp['RUC'] || cmp['ruc']);
        companyBulkUpdatePriority(newRows, dispatch)
          .then((response) => {
            if (!response) {
              throw Error('Error');
            }

            notification.success({
              message: '¡Clientes priorizados correctamente!',
            });
          })
          .catch(() => {
            notification.error({
              description:
                'Hubo un error al actualizar los clientes priorizados.',
              message: 'Error',
            });
          })
          .finally(() => {
            onBack();
          });
      } else {
        const dataToSend = formatRows(data);

        bulkCompanyExecute(dataToSend)
          .then(({ data } = {}) => {
            if (data?.success) {
              if (data?.result?.messages?.length) {
                for (const message of data?.result?.messages) {
                  notification.warning({
                    description: message,
                    message: 'Error',
                  });
                }
              }
            } else {
              notification.success({
                message: '¡Archivo subido!',
                description: `Se registraron ${
                  data?.result?.usersRegistered ?? 0
                } empresas.`,
              });
            }
          })
          .catch(() => {
            notification.error({
              description: 'Hubo un error al subir archivo.',
              message: 'Error',
            });
          });
      }
    },
    [bulkCompanyExecute, dispatch, formatRows, keyUpload, onBack],
  );

  const handleCancel = useCallback(() => {
    if (cancelToken?.current) {
      cancelToken.current.cancel('Cancel uploading');
    }
    setFileName(null);
    setLoader();
    notification.warning({
      message: 'Operación cancelada',
    });
  }, []);

  const handleDownloadTemplate = () => {
    setLoadingTemplate(true);
    getTemplateBulkCompanies().then((response) => {
      let mediaType =
        'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,';
      let a = document.createElement('a');
      a.href = mediaType + response;
      a.download = 'PlantillaCargaDeEmpresas.xlsx';
      a.textContent = 'Download file!';
      a.click();

      setLoadingTemplate(false);
    });
  };

  return (
    <div className='d_content service-prevention'>
      {isMobileScreen ? (
        <Button
          className='mr-1 flex items-center'
          icon={<LeftCircleOutlined />}
          type='link'
          size='large'
          onClick={onBack}
        >
          Volver
        </Button>
      ) : null}
      <div
        className={`flex ${
          isMobileScreen ? 'flex-col' : 'items-center justify-between'
        }`}
      >
        <div className='flex items-center'>
          {!isMobileScreen ? (
            <Button
              className='mr-1 flex items-center'
              type='link'
              size='large'
              onClick={onBack}
            >
              <LeftCircleOutlined style={{ fontSize: 24 }} />
            </Button>
          ) : null}
          <Title type={isMobileScreen ? 'bold-32' : 'bold-28'}>
            {keyUpload === 'massive_prioritized'
              ? 'Carga masiva de clientes priorizados'
              : 'Carga masiva'}
          </Title>
        </div>
        {isMobileScreen ? <Divider /> : null}
        <Button
          type='link'
          icon={<DownloadOutlined />}
          disabled={isLoadingTemplate}
          style={{ alignSelf: 'flex-start' }}
          size={isMobileScreen ? 'small' : 'large'}
          {...(keyUpload === 'massive_prioritized'
            ? {
                href: '/templates/PlantillaCargaClientesPriorizados.xlsx',
              }
            : {
                onClick: handleDownloadTemplate,
              })}
        >
          Descargar plantilla
        </Button>
      </div>
      {isNaN(loader) ? (
        <InputFile
          className={'mt-3'}
          withoutPreview
          onChangeFile={_handleChangeFile}
          processExcelData={_handleChangeData}
          allowClear={true}
        />
      ) : null}
      {!isNaN(loader) ? (
        <BoxLoader
          className='mt-3'
          percent={loader}
          title='Cargando...'
          description={fileName}
          onCancel={
            keyUpload === 'massive_prioritized' ? () => {} : handleCancel
          }
          onClose={() => {
            setLoader();
          }}
        />
      ) : null}
    </div>
  );
};

export default memo(MasiveUpload);
