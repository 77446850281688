import React, { memo, useCallback, useEffect, useState } from 'react';
import { Select } from 'antd';
import { SelectWithLabel } from '../../../common/InputWithLabel';
import { getAllCompanies } from '../../../services/company';
import { filterByString } from '../../../lib/utils';

const SelectCompany = ({
  companySelected,
  onSelectCompany,
  className,
  label,
}) => {
  const [loadingCompanies, setLoadingCompanies] = useState(true);
  const [allCompanies, setAllCompanies] = useState([]);
  const [companiesList, setCompaniesList] = useState([]);
  const [query, setQuery] = useState('');

  const getCompanies = () =>
    getAllCompanies()
      .then(({ list }) => {
        localStorage.setItem('companies-list', JSON.stringify(list));
        setAllCompanies(list);
        const index = list.findIndex((el) => el.id === companySelected);
        if (index !== -1)
          setCompaniesList(list?.slice(0, 100).concat(list[index]));
        else setCompaniesList(list?.slice(0, 100));
      })
      .finally(() => {
        setLoadingCompanies(false);
      });

  useEffect(() => {
    let companiesStorage = localStorage.getItem('companies-list');
    companiesStorage = companiesStorage ? JSON.parse(companiesStorage) : null;

    if (
      companiesStorage &&
      companiesStorage.length &&
      companiesStorage.length > 100
    ) {
      const index = companiesStorage.findIndex(
        (el) => el.id === companySelected,
      );
      if (index !== -1)
        setCompaniesList(
          companiesStorage?.slice(0, 100).concat(companiesStorage[index]),
        );
      else setCompaniesList(companiesStorage?.slice(0, 100));

      setAllCompanies(companiesStorage);
      setLoadingCompanies(false);
    } else {
      getCompanies();
    }
  }, []);

  useEffect(() => {
    if (query && query.length >= 3) {
      const result = filterByString(allCompanies, query);
      setCompaniesList(result);
    }
    if (query && query.length === 0)
      setCompaniesList(allCompanies.slice(0, 100));
  }, [query]);

  const _handleSearch = useCallback((query) => {
    setQuery(query);
  }, []);

  const _handleSelectCompany = useCallback(
    (value) => {
      onSelectCompany(value);
    },
    [onSelectCompany],
  );

  return (
    <Select
      disabled={loadingCompanies}
      showSearch
      onSearch={_handleSearch}
      filterOption={false}
      onChange={_handleSelectCompany}
      notFoundContent='No Encontrado'
      value={loadingCompanies ? undefined : companySelected}
      defaultValue={companySelected}
      loading={loadingCompanies}
      placeholder='Selecciona una empresa'
    >
      {companiesList.map((company) => (
        <Select.Option key={company.id} value={company.id}>
          {company.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default memo(SelectCompany);
