import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { message } from 'antd';
import L from 'leaflet';
import { Marker, Popup, TileLayer } from 'react-leaflet';

import {
  getEmergencies,
  removeFiremenEmergency,
  updateFiremenEmergency,
} from '../../../../services/emergencies';
import { checkPermission } from '../../../../lib/security';
import { handleSetCoordinatesOnCumulusTools } from '../../../../lib/handler';

import { CoordinateIcon, DeleteIcon, EditIcon, getSvgIcon } from '../../utils';
import texts from '../../../../common/texts.json';
import FiremenEmergenciesForm from './FiremenEmergenciesForm';

const Index = ({
  points,
  types,
  filters,
  user,
  activeUtilsLayer,
  getPoints,
  removeFiremenEmergencyPoint,
  opacity = 1,
}) => {
  const dispatch = useDispatch();
  const isCumulusLayerActive = Boolean(
    activeUtilsLayer.find((utilLayer) => utilLayer.key === 'cumulo'),
  );
  const [isOpenForm, setIsOpenForm] = useState(null);

  const authorized = ['admin_rimac', 'gestor_de_cuenta'].includes(
    user?.rol?.slug,
  );

  useEffect(() => {
    getPoints(filters);
  }, [filters]);

  const getIcon = (point) =>
    L.divIcon({
      html: getSvgIcon(
        'firemen_emergencies',
        types.find(
          (ct) =>
            ct.type.toLowerCase() === point.type.split(' / ')[0].toLowerCase(),
        )?.color,
      ),
      iconSize: [27, 38],
      iconAnchor: [13, 38],
      className: 'leaflet-icon-marker-custom',
    });

  const onOk = async (data) => {
    const update = await updateFiremenEmergency(dispatch, data, filters);
    setIsOpenForm(false);

    const ContentMessage = ({ children }) => (
      <div className='pt-1 pb-1 pr-2 pl-2'>{children}</div>
    );

    if (update) {
      message.success({
        content: (
          <ContentMessage>Emergencia actualizada correctamente.</ContentMessage>
        ),
        icon: <></>,
      });

      return true;
    } else {
      message.warning({
        content: <ContentMessage>Ha ocurrido un error.</ContentMessage>,
        icon: <></>,
      });

      return false;
    }
  };

  const removePoint = (id) => removeFiremenEmergencyPoint(id, filters);

  return (
    <>
      <TileLayer
        attribution='Google Maps'
        url='https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}'
        opacity={opacity}
      />
      {points.map((point) => (
        <Marker
          key={point.id}
          position={[point.lat, point.lng]}
          icon={getIcon(point)}
        >
          <Popup>
            <div className='popup-map'>
              <div className='popup-title'>
                <span style={{ fontWeight: 'bold', color: '#ea8402' }}>
                  EMERGENCIAS BOMBEROS
                </span>
                {authorized &&
                  point?.source === 'manual' &&
                  checkPermission(
                    user,
                    'administrate_manual_points_emergency',
                  ) && (
                    <>
                      <span
                        className='icon-title flex items-baseline'
                        onClick={() =>
                          setIsOpenForm({
                            ...point,
                            id: point.id,
                            action: 'edit',
                          })
                        }
                      >
                        <EditIcon />
                        <span className='tooltipText ml-1'>
                          Editar emergencia
                        </span>
                      </span>
                      <span
                        className='icon-title flex items-baseline'
                        onClick={() => removePoint(point.id)}
                      >
                        <DeleteIcon />
                        <span className='tooltipText ml-1'>
                          Eliminar emergencia
                        </span>
                      </span>
                    </>
                  )}
              </div>
              <div
                className='popup-content popup-map-scrollable'
                style={{ maxHeight: 400 }}
              >
                <span className='popup-detail'>
                  <span className='popup-detail-header'>
                    {texts['map_firemen_number']}:
                  </span>
                  <span className='popup-detail-content'>{point?.number}</span>
                  <span className='popup-detail-header'>
                    {texts['map_firemen_date']}:
                  </span>
                  <span className='popup-detail-content'>
                    {point.date_parse}
                  </span>
                  <span className='popup-detail-header'>
                    {texts['map_firemen_address']}:
                  </span>
                  <span className='popup-detail-content'>{point?.address}</span>
                  <span className='popup-detail-header'>
                    {texts['map_firemen_type']}:
                  </span>
                  <span className='popup-detail-content'>{point?.type}</span>
                  <span className='popup-detail-header'>
                    {texts['map_firemen_status']}:
                  </span>
                  <span className='popup-detail-content'>{point?.status}</span>
                  <span className='popup-detail-header'>
                    {texts['map_firemen_machines']}:
                  </span>
                  <span className='popup-detail-content'>
                    {point?.machines}
                  </span>
                  <span className='popup-detail-header'>
                    {texts['map_firemen_latitude_logitude']}:
                  </span>
                  <span className='popup-detail-content'>
                    {point?.lat}, {point?.lng}
                  </span>
                </span>
              </div>
              {isCumulusLayerActive && (
                <div className='popup-footer'>
                  <div
                    role='button'
                    className='icon-content'
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSetCoordinatesOnCumulusTools(point, dispatch);
                    }}
                  >
                    <CoordinateIcon />
                    <span className='icon-text'>Realizar simulación</span>
                  </div>
                </div>
              )}
            </div>
          </Popup>
        </Marker>
      ))}
      <FiremenEmergenciesForm
        visible={Boolean(isOpenForm)}
        openForm={setIsOpenForm}
        onOk={onOk}
        object={isOpenForm}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getPoints: (filters) => getEmergencies(dispatch, filters),
  removeFiremenEmergencyPoint: (id, filters) =>
    removeFiremenEmergency({ id, dispatch, filters }),
});

const mapStateToProps = (state) => ({
  points: state.emergencies.list,
  filters: state.emergencies.mapFilters,
  types: state.emergencies.types,
  user: state.auth.user,
  activeUtilsLayer: state.maps.activeUtilsLayer,
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
