import { notification } from 'antd';
import { cumulusTools } from '../common/SideMenu/data';
import {
  CONTROLMAP_DRAWER_VISIBLE,
  CONTROLMAP_SELECTED,
  CUMULUS_SET_MARKER,
  MAP_SET_ACTIVE_CUMULUS_TOOL,
} from '../redux/actions/actionsType';

export const handleSetCoordinatesOnCumulusTools = (coordinate, dispatch) => {
  const markerCumulusTool = cumulusTools.find(
    (cTool) => cTool.key === 'marker',
  );

  dispatch({ type: CONTROLMAP_DRAWER_VISIBLE, payload: true });
  dispatch({ type: CONTROLMAP_SELECTED, payload: 'cumulusTools' });
  dispatch({
    type: MAP_SET_ACTIVE_CUMULUS_TOOL,
    payload: markerCumulusTool,
  });
  dispatch({
    type: CUMULUS_SET_MARKER,
    payload: {
      lat: coordinate.lat,
      lng: coordinate.lng,
    },
  });
};

export const handleCopyCodeRegister = (user) => {
  navigator.clipboard.writeText(
    `${process.env.REACT_APP_DOMAIN}/register/${user.code_invitation}`,
  );
  notification.success({
    description: 'Se copió el link de registro.',
    message: '¡Usuario - Copiar link!',
  });
};
