import React from 'react';
import { Skeleton as SkeletonAntd } from 'antd';

const Skeleton = ({ type, className, totalItems = 1 }) => {
  let list = [];

  for (let i = 1; i <= totalItems; i++) {
    list.push(i);
  }

  return (
    <div className={`skeletonList ${className ?? ''}`}>
      {(() => { // NOSONAR
        switch (type) {
          case 'newsletters':
            return list.map((_e, idx) => (
              <SkeletonAntd
                paragraph={{ rows: 1 }}
                title={false}
                key={idx}
                active
                className='skeletonListNewsletters'
              />
            ));
          case 'table':
            return list.map((_e, idx) => (
              <SkeletonAntd
                paragraph={{ rows: 1 }}
                title={false}
                key={idx}
                active
                className={`${idx === 0 ? 'skeletonTable-initial' : ''} ${
                  idx + 1 === list.length ? 'skeletonTable-final' : ''
                } 
                  skeletonTable`}
              />
            ));
          case 'title':
            return (
              <SkeletonAntd
                paragraph={{ rows: 1 }}
                title={false}
                active
                className='skeletonTitle'
              />
            );
          case 'card':
            return (
              <SkeletonAntd
                paragraph={{ rows: 1 }}
                title={false}
                active
                className='skeletonCard'
              />
            );
          case 'skeletonCardDashboard':
            return (
              <SkeletonAntd
                paragraph={{ rows: 1 }}
                title={false}
                active
                className='skeletonCardDashboard'
              />
            );
          default:
            return <SkeletonAntd paragraph={{ rows: 4 }} active />;
        }
      })()}
    </div>
  );
};

export default Skeleton;
