import { memo, useState, useEffect } from 'react';
import { Divider } from 'antd';
import dayjs from 'dayjs';
import {
  RideButton,
  RideGlDeleteOutline,
} from '@rimac-seguros/ride-system-components';

import { useScreen } from '../../../../../lib/screen';

import Title from '../../../../../common/Typograph/Title';
import TableProvinces from './TableProvinces';
import Dashboards from './Dashboard';
import TimeSerieWidget from './Dashboard/TimeSerieWidget';
import DatePicker from '../../../../../common/DatePicker';

const RangePicker = DatePicker.RangePicker;

const ResumeDashboard = () => {
  const { isMobileScreen } = useScreen();
  const [datesFilter, setDateFilter] = useState({});
  const [dateValue, setDateValue] = useState([]);
  const [usersChar, setUsersChar] = useState([]);

  const handleChangeDates = (dates = []) => {
    if (dates && dates.length > 0) {
      const dateIni = dayjs(dates[0]).format('YYYY-MM-DDT00:00:00-05:00');
      const dateFin = dayjs(dates[1]).format('YYYY-MM-DDT23:59:59-05:00');
      setDateFilter({ dateIni, dateFin });
    }
    setDateValue(dates);
  };

  useEffect(() => {
    return () => {
      setDateFilter({});
      setDateValue([]);
    };
  }, []);

  return (
    <div>
      <div
        className={`flex justify-between mb-4 pt-2 ${
          isMobileScreen ? 'flex-col' : 'items-center'
        }`}
      >
        <Title type='bold-20'>Seleccione un rango de fechas</Title>
        <div className='flex items-center gap-2'>
          <RangePicker
            placeholder={['Fecha de inicio', 'Fecha de fin']}
            value={dateValue}
            onChange={handleChangeDates}
          />
          <RideButton
            onClick={() => {
              setDateFilter({});
              setDateValue([]);
            }}
            variant='text-primary'
            text='Borrar filtro'
            glyph={<RideGlDeleteOutline />}
            glyphPosition='left'
          />
        </div>
      </div>
      <Dashboards dates={datesFilter} setUsers={setUsersChar} />
      <Divider />
      <TableProvinces dates={datesFilter} />
      <Divider />
      <TimeSerieWidget title='Crecimiento de Usuarios' data={usersChar ?? []} />
    </div>
  );
};

export default memo(ResumeDashboard);
