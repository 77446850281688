const roadEmergencies = [
  {
    key: 'source',
    title: 'POR ORIGEN',
    checkbox: [
      // { id: 'provias', name: 'Provías', value: 'provias', group: 'road' },
      { id: 'sutran', name: 'Sutran', value: 'sutran', group: 'road' },
      { id: 'cmp', name: 'CMP', value: 'manual', group: 'road' },
    ],
  },
  {
    key: 'condition',
    title: 'TIPOS DE RESULTADO EN TRÁNSITO',
    checkbox: [
      { id: 'normal', name: 'Normal', value: '01', group: 'road' },
      { id: 'restringido', name: 'Restringido', value: '02', group: 'road' },
      { id: 'interrumpido', name: 'Interrumpido', value: '03', group: 'road' },
      {
        id: 'por_confirmar',
        name: 'Por confirmar',
        value: '04',
        group: 'road',
      },
    ],
  },
  {
    key: 'tipoEmergencia',
    title: 'TIPOS DE EMERGENCIA',
    checkbox: [
      { id: 'humano', name: 'Humano', value: 'humano', group: 'road' },
      {
        id: 'climatologico',
        name: 'Climatológico',
        value: 'climatologico',
        group: 'road',
      },
      {
        id: 'accidentes',
        name: 'Accidentes',
        value: 'accidentes',
        group: 'road',
      },
      {
        id: 'infraestructura',
        name: 'Infraestructura',
        value: 'infraestructura',
        group: 'road',
      },
    ],
  },
  {
    key: 'date',
    title: 'POR RANGO DE FECHA DE SINIESTRO',
    date: true,
  },
  {
    key: 'updateDate',
    title: 'POR RANGO DE FECHA DE ACTUALIZACIÓN',
    updateDate: true,
  },
];

const firemenEmergencies = [
  {
    key: 'requirements',
    title: 'REQUERIMIENTO DE EMERGENCIA',
    checkbox: [
      {
        id: 'lessthanthree',
        name: 'Menos de 4 máquinas asignadas',
        value: 3,
        group: 'fires_machines',
      },
      {
        id: 'four',
        name: '4 máquinas asignadas',
        value: 4,
        group: 'fires_machines',
      },
      {
        id: 'five',
        name: '5 máquinas asignadas',
        value: 5,
        group: 'fires_machines',
      },
      {
        id: 'six',
        name: '6 máquinas asignadas',
        value: 6,
        group: 'fires_machines',
      },
      {
        id: 'morethanseven',
        name: 'Más de 6 máquinas asignadas',
        value: 7,
        group: 'fires_machines',
      },
    ],
  },
  {
    key: 'date',
    title: 'POR RANGO DE FECHA DE SINIESTRO',
    date: true,
  },
  {
    key: 'query',
    title: 'POR DISTRITO O DIRECCIÓN',
    name: 'query',
    label: 'Nombre de la empresa',
    input: true,
  },
  {
    key: 'states',
    title: 'POR ESTADO',
    checkbox: [
      {
        id: 'attended',
        name: 'Atendiendo',
        value: 'atendiendo',
        group: 'fires_status',
      },
      {
        id: 'closed',
        name: 'Cerrado',
        value: 'cerrado',
        group: 'fires_status',
      },
    ],
  },
  {
    key: 'types',
    title: 'TIPO DE EMERGENCIA',
    checkbox: [
      {
        id: 'fires',
        name: 'Incendio',
        value: 'INCENDIO',
        group: 'fires_types',
      },
      {
        id: 'vehicular',
        name: 'Accidente vehicular',
        value: 'ACCIDENTE VEHICULAR',
        group: 'fires_types',
      },
      {
        id: 'materiales',
        name: 'Materiales Peligrosos',
        value: 'MATERIALES PELIGROSOS (INCIDENTE)',
        group: 'fires_types',
      },
      {
        id: 'rescate',
        name: 'Rescate',
        value: 'RESCATE',
        group: 'fires_types',
      },
      {
        id: 'servicios_especiales',
        name: 'Servicio Especial',
        value: 'SERVICIO ESPECIAL',
        group: 'fires_types',
      },
      {
        id: 'emergencia_medica',
        name: 'Emergencia Médica',
        value: 'EMERGENCIA MEDICA',
        group: 'fires_types',
      },
    ],
  },
];

const sismos = [
  {
    key: 'magnitud',
    title: 'MAGNITUD',
    checkbox: [
      {
        id: 'leve',
        name: 'Leve',
        value: 'leve',
        valueToSet: [0, 4.5],
        group: 'sismo_mag',
      },
      {
        id: 'moderado',
        name: 'Moderado',
        value: 'moderado',
        valueToSet: [4.5, 6],
        group: 'sismo_mag',
      },
      {
        id: 'critico',
        name: 'Crítico',
        value: 'critico',
        valueToSet: [6, 20],
        group: 'sismo_mag',
      },
    ],
  },
  {
    key: 'date',
    title: 'FECHAS',
    radio: [
      {
        id: 'last',
        name: 'Último sismo',
        value: 'last',
        valueToSet: 'last',
        group: 'sismo_date',
      },
      {
        id: '7days',
        name: 'Última semana',
        value: '7days',
        valueToSet: [-7, 'days'],
        group: 'sismo_date',
      },
      {
        id: '3months',
        name: 'Últimos 3 meses',
        value: '3months',
        valueToSet: [-3, 'months'],
        group: 'sismo_date',
      },
    ],
  },
  {
    key: 'acceleration',
    title: 'ACELERACIÓN DEL SUELO',
    checkbox: [
      {
        id: 'interval1',
        name: '1-10',
        value: [1, 10],
        valueToSet: [1, 10],
        group: 'sismo_acceleration',
      },
      {
        id: 'interval2',
        name: '10-20',
        value: [10, 20],
        valueToSet: [10, 20],
        group: 'sismo_acceleration',
      },
      {
        id: 'interval3',
        name: '20-50',
        value: [20, 50],
        valueToSet: [20, 50],
        group: 'sismo_acceleration',
      },
      {
        id: 'interval4',
        name: '50-100',
        value: [50, 100],
        valueToSet: [50, 100],
        group: 'sismo_acceleration',
      },
      {
        id: 'interval5',
        name: '100-200',
        value: [100, 200],
        valueToSet: [100, 200],
        group: 'sismo_acceleration',
      },
      {
        id: 'interval6',
        name: '200-500',
        value: [200, 500],
        valueToSet: [200, 500],
        group: 'sismo_acceleration',
      },
      {
        id: 'interval7',
        name: '500-1000',
        value: [500, 1000],
        valueToSet: [500, 1000],
        group: 'sismo_acceleration',
      },
      {
        id: 'interval8',
        name: '>1000',
        value: 'moreThan1000',
        valueToSet: 'moreThan1000',
        group: 'sismo_acceleration',
      },
    ],
  },
];

const tsunamis = [
  {
    key: 'magnitude',
    title: 'MAGNITUD MOMENTO',
    range: true,
    min: 5,
    max: 10,
    step: 0.1,
    defaultValue: [6, 9],
  },
  {
    key: 'depth',
    title: 'PROFUNDIDAD',
    range: true,
    min: 0,
    max: 150,
    step: 1,
    defaultValue: [0, 150],
  },
  {
    key: 'date',
    title: 'POR RANGO DE FECHA',
    date: true,
  },
];

const geologicalHazards = [
  {
    key: 'TIPO_PELIG',
    title: 'TIPO DE PELIGRO',
    checkbox: [
      { id: 'caida', name: 'Caida', value: 'Caida', group: 'type' },
      {
        id: 'deslizamiento',
        name: 'Deslizamiento',
        value: 'Deslizamiento',
        group: 'type',
      },
      { id: 'flujo', name: 'Flujo o Huaico', value: 'Flujo', group: 'type' },
      { id: 'reptacion', name: 'Reptación', value: 'Reptacion', group: 'type' },
    ],
  },
  {
    key: 'GRADO_PELI',
    title: 'GRADO DE PELIGRO',
    checkbox: [
      {
        id: 'peligro_muy_alto',
        name: 'Muy Alto',
        value: 'Muy Alto',
      },
      {
        id: 'peligro_alto',
        name: 'Alto',
        value: 'Alto',
      },
      {
        id: 'peligro_medio',
        name: 'Medio',
        value: 'Medio',
      },
      {
        id: 'peligro_bajo',
        name: 'Bajo',
        value: 'Bajo',
      },
    ],
  },
  {
    key: 'GRADO_VULN',
    title: 'GRADO DE VULNERABILIDAD',
    checkbox: [
      {
        id: 'vulnerabilidad_muy_alto',
        name: 'Muy Alto',
        value: 'Muy Alto',
      },
      {
        id: 'vulnerabilidad_alto',
        name: 'Alto',
        value: 'Alto',
      },
      {
        id: 'vulnerabilidad_medio',
        name: 'Medio',
        value: 'Medio',
      },
      {
        id: 'vulnerabilidad_bajo',
        name: 'Bajo',
        value: 'Bajo',
      },
    ],
  },
  {
    key: 'DPTO',
    title: 'POR DEPARTAMENTO',
    label: 'Departamento',
    select: true,
  },
  {
    key: 'PROV',
    title: 'POR PROVINCIA',
    label: 'Provincia',
    autocomplete: true,
    typeAutocomplete: 'provinces',
  },
  {
    key: 'DIST',
    title: 'POR DISTRITO',
    label: 'Distrito',
    autocomplete: true,
    typeAutocomplete: 'districts',
  },
];

export {
  roadEmergencies,
  firemenEmergencies,
  sismos,
  tsunamis,
  geologicalHazards,
};
