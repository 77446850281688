import React from 'react';
import moment from 'moment';
import { Button } from 'antd';
import { EditOutlined, FileOutlined } from '@ant-design/icons';

const Card = ({ data, handleEdit, handleCreate }) => {
  return (
    <article className='card-user'>
      <div className='flex pos-end'>
        <span className='table-state' style={{ background: '#FCDA6A' }}>
          <div className='point' style={{ background: '#FC9700' }}></div>
          <span>
            {data.provinces.length}{' '}
            {data.provinces.lenght === 1
              ? 'REGiÓN AFECTADA'
              : 'REGIONES AFECTADAS'}
          </span>
        </span>
      </div>

      <div>
        <h4>Tipo</h4>
        <p>{data?.eventType?.name || ''}</p>
      </div>

      <div>
        <h4>Fecha de evento</h4>
        <p>
          {data?.date_event_end
            ? moment(data?.date_event_start).format('DD/MM/YYYY - HH:mm') +
              `${
                data.date_event_end
                  ? ` a ${moment(data?.date_event_end).format(
                      'DD/MM/YYYY - HH:mm',
                    )}`
                  : ''
              }`
            : moment(data?.date_event_start).format('DD MMM YYYY - HH:mm')}
        </p>
      </div>

      <div>
        <h4>Condición detectada</h4>
        <p>{data.detected_condition}</p>
      </div>

      <div className='actions'>
        <Button
          icon={<EditOutlined />}
          shape='circle'
          size='small'
          title='Editar evento'
          onClick={() => handleEdit(data)}
        />

        <Button
          icon={<FileOutlined />}
          shape='circle'
          size='small'
          title='Crear boletín'
          onClick={() => handleCreate(data)}
        />
      </div>
    </article>
  );
};

export default Card;
