import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Radio } from 'antd';

import { renderIcon } from '../renderIcon';
import { useScreen } from '../../../../lib/screen';
import { tools } from '../../../SideMenu/data';
import { MAP_SET_ACTIVE_TOOL } from '../../../../redux/actions/actionsType';

const DrawerTools = ({ controlMapDrawerVisible }) => {
  const dispatch = useDispatch();
  const { isMobileScreen } = useScreen();
  const activeTool = useSelector((state) => state.maps.activeTool);

  const handleSelectTool = (tool) => {
    dispatch({ type: MAP_SET_ACTIVE_TOOL, payload: tool.key });
    if (isMobileScreen) {
      controlMapDrawerVisible(false);
    }
  };

  return (
    <div className='drawer-map-layers'>
      <div className='drawer-map-layers__list'>
        {tools.map((tool) => (
          <div
            key={tool.key}
            className={`drawer-map-layers__list__item ${
              activeTool === tool?.key ? 'active' : ''
            }`}
            onClick={() => handleSelectTool(tool)}
          >
            <div className='drawer-map-layers__list__item__name'>
              {renderIcon(tool.key)}
              <div className='drawer-map-layers__list__item__name__text'>
                {tool.name}
              </div>
            </div>
            <div className='drawer-map-layers__list__item__action'>
              <Radio
                className='radio-layer'
                checked={activeTool === tool.key}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DrawerTools;
