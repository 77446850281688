import { useSelector } from 'react-redux';

const LegendEarthquakes = () => {
  const activeSubLayer = useSelector((state) => state.maps.activeSubLayer);
  const { isOpened: leftMenuIsOpened } = useSelector((state) => state.sideMenu);

  return (
    <div
      className={`legend-sismo ${leftMenuIsOpened && 'with-left-menu-opened'} ${
        activeSubLayer?.id === 'sismos-map' && 'for-sismos-map'
      }`}
    >
      <div className='legend-sismo-item'>
        <span className='legend-sismo-text'>Ultimo sismo</span>
        <span className='legend-sismo-icon'>
          <img
            alt='legend-last-earthquake'
            src='/images/icons/legend-last-earthquake.png'
          />
        </span>
      </div>
      <div className='legend-sismo-item'>
        <span className='legend-sismo-text'>
          {activeSubLayer?.id === 'sismos-map'
            ? 'Casos reportados'
            : 'Sismos reportados'}
        </span>
        <span className='legend-sismo-icon'>
          <img
            alt='legend-earthquake'
            src='/images/icons/legend-earthquake.png'
          />
        </span>
      </div>
    </div>
  );
};

export default LegendEarthquakes;
