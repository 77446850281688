import React from 'react';
import { Button } from 'antd';
import { useScreen } from '../../../../lib/screen';
import { UploadOutlined } from '@ant-design/icons';

import {
  RideButton,
  RideGlUploadOutline,
} from '@rimac-seguros/ride-system-components';

const HeaderButtons = ({ activeTab, handleAction, setIsUploadView }) => {
  const { isMobileScreen } = useScreen();

  if (isMobileScreen) {
    return null;
  }

  return (
    <div
      className='flex gap-4'
      style={{
        display: 'flex',
        gap: '1rem',
        alignItems: 'center',
      }}
    >
      {activeTab?.link && (
        <RideButton
          type='primary'
          glyph={<RideGlUploadOutline />}
          onClick={() => {
            setIsUploadView((prev) => !prev);
          }}
          size='medium'
          glyphPosition='left'
          variant='text-primary'
          text={activeTab?.link}
        />
      )}
      {activeTab?.create && (
        <RideButton
          type='primary'
          onClick={() => {
            handleAction({});
          }}
          size='medium'
          text={activeTab?.create}
        />
      )}
    </div>
  );
};

export default HeaderButtons;
