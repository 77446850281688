import React, { Fragment, memo } from 'react';
import { Card, Divider, Progress, Skeleton } from 'antd';

import Title from '../../../../../../common/Typograph/Title';

const colorsBadges = {
  success: '#30DB9D',
  default: '#D7DBF5',
  percent: '#FFCA61',
};

const BasicWidget = ({
  data,
  action,
  title,
  details,
  loading,
  available,
  aditional = 0,
  type = 'circle',
}) => {
  if (loading)
    return (
      <Card>
        <Skeleton />
      </Card>
    );

  const percentAvailable = Math.round((available / data?.total) * 100);
  const percent = Math.ceil((aditional / data?.total) * 100);

  return (
    <Card style={{ height: '100%' }}>
      <div
        className={`flex justify-between ${
          type !== 'circle' ? 'flex-col' : ''
        }`}
      >
        <div>
          <Title type='bold-18'>{title}</Title>
          <Title type='bold-28'>{data?.total ?? 0}</Title>
        </div>
        <div>
          <Progress
            success={{ percent: percent ? percent : 0 }}
            percent={
              percent
                ? 100 - percentAvailable + percent
                : 100 - percentAvailable
            }
            type={type}
            strokeColor='#30DB9D'
            trailColor='#D7DBF5'
            format={() => ''}
          />
        </div>
      </div>
      <div className='flex flex-col'>
        {details
          ? details.map(({ key, title, renderValue, value, type }) => (
              <Fragment key={key}>
                <Divider />
                <div className='flex justify-between items-center'>
                  <div className='flex items-center'>
                    <span
                      style={{
                        width: 6,
                        height: 6,
                        background: colorsBadges[type],
                        borderRadius: '50%',
                      }}
                    ></span>
                    <span className='ml-2'>{title}</span>
                  </div>
                  <span>{renderValue ? renderValue(data) : value || null}</span>
                </div>
              </Fragment>
            ))
          : null}
      </div>
      {action ?? null}
    </Card>
  );
};

export default memo(BasicWidget);
