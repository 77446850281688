import { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { kml } from '@tmcw/togeojson';
import { Button, Form, notification } from 'antd';

import { useScreen } from '../../../../../lib/screen';
import { removeAccents } from '../../../../../lib/utils';
import { addRandSpaceToLatLng } from './utils';
import {
  bulkConflicts,
  registerConflict,
} from '../../../../../services/conflict';
import { getPresignedUrl } from '../../../../../services/polizas';
import { sendFileTecnicalReport } from '../../../../../services/requestServicesItemsResults';

import ModalConfirm from '../../../../../common/ModalConfirm';
import InputFile from '../../../../../common/InputFile';
import InputWithLabel from '../../../../../common/InputWithLabel';
import Title from '../../../../../common/Typograph/Title';
import Paragraph from '../../../../../common/Typograph/Paragraph';
import BoxLoader from '../../../../../common/BoxLoader';
import IconDocument from '../../../../../images/ic_document.svg';
import IconFinish from '../../../../../images/icon_finish.svg';

const SocialConflicts = ({
  loading,
  bulkConflicts,
  filter,
  conflictsProgress,
}) => {
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const { isMobileScreen } = useScreen();
  const [file, setFile] = useState();
  const [modalConfirm, setModalConfirm] = useState({});
  const { validateFields, resetFields, getFieldValue } = form;
  const [loadingUpload, setLoadingUpload] = useState(false);

  const handleUpload = async () => {
    try {
      await form.validateFields();
      let reader = new FileReader();
      reader.readAsText(file);

      reader.onloadend = (e) => {
        setLoadingUpload(true);
        const parse = new DOMParser().parseFromString(
          e.target.result,
          'text/xml',
        );
        const geojson = kml(parse);
        import('../departments_locations.json')
          .then(async (districtsLocations) => {
            const data = processData({
              features: geojson.features,
              districtsLocations: districtsLocations.default,
            });
            const response = await bulkConflicts(data, filter.states);

            await getPresignedUrl({
              type: 'application/vnd.google-earth.kml+xml',
              name: file ? file.name : '',
            }).then((r) => {
              sendFileTecnicalReport(r.data.payload.url, file, () => {});
              registerConflict(
                {
                  name: form.getFieldValue('name'),
                  url: r.data.payload.nameKey,
                },
                dispatch,
              );
            });

            const { success, newDataCount, oldDataCount } = response;

            if (success) {
              setFile();
              form.resetFields();
              if (isMobileScreen)
                setModalConfirm({
                  visible: true,
                  description: (
                    <Title
                      type={'bold-28'}
                      className='mb-2 mt-3'
                      style={{ textAlign: 'center' }}
                    >
                      ¡Listo! <br /> Se registró {newDataCount} conflictos, se
                      actualizó {oldDataCount} conflictos
                    </Title>
                  ),
                });
              else
                notification.success({
                  description:
                    'Se registraron los conflictos sociales de manera exitosa.',
                  message: '¡Listo!',
                });
            } else {
              if (isMobileScreen) setModalConfirm(true);
              else
                notification.warning({
                  description: 'Ha ocurrido un error.',
                  message: 'Actualizar versión',
                });
            }
          })
          .finally(() => {
            setLoadingUpload(false);
          });
      };
    } catch (error) {
      notification.error({
        description: 'Ha ocurrido un error.',
        message: 'Error',
      });
    }
  };

  const processData = ({ features, districtsLocations }) => {
    const hashes = [];
    const list = features
      .map(({ properties }) => {
        const department = removeAccents(properties['Dpto.'])
          .toLowerCase()
          .trim();
        const provincia = removeAccents(properties['Provincia'])
          .toLowerCase()
          .trim();
        const distrito = removeAccents(properties['Distrito'])
          .toLowerCase()
          .trim();
        const actividad = removeAccents(properties['Actividad'])
          .toLowerCase()
          .trim();
        const tipo = removeAccents(properties['Tipo']).toLowerCase().trim();
        const name = removeAccents(properties['name']).toLowerCase().trim();

        if (!department) return false;

        const hash = `${department}-${provincia}-${distrito}-${actividad}-${tipo}-${name}`;

        const index = hashes.findIndex((h) => h === hash);
        if (index !== -1) return false;

        let latitude, longitude;
        const deparmentLocation = districtsLocations.find(
          (d) => removeAccents(d.deparment) === department,
        );
        const provinceLocation =
          deparmentLocation &&
          deparmentLocation.provinces.find(
            (p) => removeAccents(p.province) === provincia,
          );
        const districtLocation =
          provinceLocation &&
          provinceLocation.districts.find(
            (p) => removeAccents(p.district) === distrito,
          );
        if (districtLocation) {
          const coordinate = addRandSpaceToLatLng(
            districtLocation.lat,
            districtLocation.lng,
          );
          latitude = coordinate.latitude;
          longitude = coordinate.longitude;
        } else if (provinceLocation) {
          const coordinate = addRandSpaceToLatLng(
            provinceLocation.lat,
            provinceLocation.lng,
            0.002,
          );
          latitude = coordinate.latitude;
          longitude = coordinate.longitude;
        } else if (deparmentLocation) {
          const coordinate = addRandSpaceToLatLng(
            deparmentLocation.lat,
            deparmentLocation.lng,
            0.003,
          );
          latitude = coordinate.latitude;
          longitude = coordinate.longitude;
        } else {
          return null;
        }

        hashes.push(hash);

        return {
          name: properties['name'],
          country: properties['País'],
          department: properties['Dpto.'],
          multiregional_or_nationalcd: properties['Multiregión o nacional'],
          province: properties['Provincia'],
          district: properties['Distrito'],
          location: properties['Localidad'],
          other_places_involved: properties['Otros lugares involucrados'],
          company_involved: properties['Empresa involucrada'],
          type: properties['Tipo'],
          activity: properties['Actividad'],
          state: properties['Estado'],
          active_phases: properties['Fases activos'],
          time_of_dialogue: properties['Momento del diálogo'],
          dialogue_mechanism: properties['Mecanismo del diálogo'],
          participation_dialogue:
            properties['Participación de la DP en el espacio de diálogo'],
          incident_of_violence:
            properties['Al menos tuvieron un hecho de violencia'],
          dialogue_after_violence:
            properties['Diálogo después de hecho de violencia (crisis)'],
          ombudsman_office: properties['Presencia de la Defensoria del Pueblo'],
          main_government:
            properties['Principal competencias por nivel de gobierno'],
          latitude: latitude,
          longitude: longitude,
          hash: hash,
        };
      })
      .filter(Boolean);

    return list;
  };

  return (
    <>
      <div>
        {!loading && modalConfirm?.visible ? (
          <>
            <BoxLoader percent={conflictsProgress} />
          </>
        ) : (
          <>
            <Paragraph type={'bold-16'} className='mb-2 mt-3'>
              Sube el archivo
            </Paragraph>
            {!file?.name ? (
              <InputFile
                withoutPreview
                filesAccepted={['.kml']}
                onChangeFile={(file) => setFile(file.file)}
                allowClear={true}
              />
            ) : (
              <>
                <div
                  className='container-previsualization mb-2 mt-2 flex flex-col pb-3 pl-3 pr-3 pt-3'
                  style={{ background: 'white', borderRadius: 16 }}
                >
                  <div className='items-center flex'>
                    <img
                      className='mr-2'
                      src={IconDocument}
                      width='16px'
                      alt='icon_document'
                    />
                    <Paragraph type={'regular-16'}>{file?.name}</Paragraph>
                  </div>
                </div>

                <Form form={form} className='form-name-conflictoSocial'>
                  <Paragraph type={'bold-16'} className='mb-2 mt-2'>
                    Ingresa el nombre de la actualización
                  </Paragraph>

                  <Form.Item
                    name='name'
                    initialValue=''
                    rules={[
                      {
                        required: true,
                        message: 'Ingrese Nombre de la actualización',
                      },
                    ]}
                  >
                    <InputWithLabel
                      style={{ with: '50%' }}
                      className='mb-2'
                      label={'Nombre de la actualización'}
                    />
                  </Form.Item>
                  <div
                    className={`flex flex-1 ${
                      isMobileScreen
                        ? 'flex-justify-center'
                        : 'flex-justify-end'
                    }  mt-4`}
                  >
                    <Button
                      type='primary'
                      onClick={handleUpload}
                      size='large'
                      disabled={!file?.name || loadingUpload}
                      style={{ ...(isMobileScreen && { width: '100%' }) }}
                    >
                      Subir y actualizar
                    </Button>
                  </div>
                </Form>
              </>
            )}
          </>
        )}
      </div>
      <ModalConfirm
        visible={modalConfirm?.visible}
        onClose={() => setModalConfirm({})}
        title={modalConfirm?.description}
        image={IconFinish}
        actionsContent={
          <div className='actions-delete-modal mt-2'>
            <Button
              className='button w-max'
              type='primary'
              onClick={() => setModalConfirm({})}
            >
              Aceptar
            </Button>
          </div>
        }
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  bulkConflicts: (data, states) => bulkConflicts(data, dispatch, states),
});

const mapStateToProps = (state) => ({
  loading: state.conflict.isLoadingBulk,
  list: state.conflict.list,
  filter: state.conflict.filter,
  conflictsProgress: state.conflict.conflictsProgress,
});

export default connect(mapStateToProps, mapDispatchToProps)(SocialConflicts);
