import React, { memo } from 'react';
import { IconViewImage } from '../Icons';

const SmallImage = ({ src, locale = false }) => {
  return (
    <div className='small-image'>
      <img
        className='image'
        src={locale ? src : `${process.env.REACT_APP_WEB_PATH_S3_NEW}${src}`}
        alt={src}
      />

      <div className='view'>
        <IconViewImage />
      </div>
    </div>
  );
};

export default memo(SmallImage);
