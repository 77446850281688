import { Divider, Modal } from 'antd';
import React from 'react';

import Title from '../../../common/Typograph/Title';
import { useScreen } from '../../../lib/screen';

import TechnicalReportView from './TechnicalReportView';

const ModalViewDetailsCapacitacion = ({
  reportCapacitacion,
  visible,
  item,
  setVisible,
}) => {
  const { isMobileScreen } = useScreen();

  return (
    <Modal
      onOk={() => setVisible(false)}
      onCancel={() => setVisible(false)}
      open={visible}
      okText='Aceptar'
      cancelButtonProps={{ style: { display: 'none' } }}
      destroyOnClose
      className={`modal-provider modal-centered ${
        isMobileScreen ? 'modal-mobile' : ''
      }`}
      maskClosable={false}
    >
      <Title type='bold-28' style={{ textAlign: 'center' }}>
        Reporte
      </Title>
      <Divider className='mt-2 mb-3' />
      {item && visible && (
        <TechnicalReportView
          report={reportCapacitacion}
          serviceType={parseInt(item.service?.type)}
          requestServiceItemsData={item.requestServiceItemsData}
        />
      )}
    </Modal>
  );
};

export default ModalViewDetailsCapacitacion;
