import defaultIcon from './default.svg';
import dropIcon from './drop.svg';
import displacementIcon from './displacement.svg';
import flowIcon from './flow.svg';
import reptationIcon from './reptation.svg';
import L from 'leaflet';

const ICON = {
  drop: dropIcon,
  displacement: displacementIcon,
  flow: flowIcon,
  reptation: reptationIcon,
};

export const getIcon = (type) => {
  return L.icon({
    iconUrl: ICON[type] ?? defaultIcon,
    iconSize: [12, 12],
    iconAnchor: [6, 4],
  });
};
