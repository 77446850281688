import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/es'; // importa el locale español

import { getIncompleteSurveys } from '../../../../../../../services/survey';
import { Button, Card } from 'antd';

import SimpleTable from '../../../../../../../common/Table/SimpleTable';
import ModalSurvey from '../../../../../../client/ui/PreventionService/ServiceDetail/Service/components/ModalSurvey';
import ModalFinishSurvey from '../../../../../../client/ui/PreventionService/ServiceDetail/Service/components/ModalFinishSurvey';
import { useScreen } from '../../../../../../../lib/screen';
import { CardSkeleton } from '../../../../../../client/ui/PreventionService/Reports/utils';
import {
  iconLeft,
  iconRight,
} from '../../../../../../../common/Icons/iconText';
import Empty from '../../../../../../../common/Empty';

dayjs.locale('es');

const columns = [
  {
    title: 'SERVICIO',
    dataIndex: 'service',
    key: 'service',
    visible: true,
  },
  {
    title: 'CONTACTO',
    dataIndex: 'contact',
    key: 'contact',
    visible: true,
  },
  {
    title: 'PROVEEDOR',
    dataIndex: 'provider',
    key: 'provider',
    visible: true,
  },
  {
    title: 'FECHA DE SOLICITUD',
    dataIndex: 'createdAt',
    key: 'createdAt',
    render: (createdAt) => dayjs(createdAt).format('DD/MM/YYYY'),
    visible: true,
  },
  {
    title: 'ACCIONES',
    dataIndex: 'eye',
    editable: false,
    key: 'eye',
    width: 80,
    render: (_, record) =>
      !record.quiz ? (
        <Button
          type='link'
          className='link link-table'
          style={{ border: 'none' }}
          onClick={record._handleShowModal}
        >
          <span>Llenar Encuesta</span>
        </Button>
      ) : null,
  },
];

const Surveys = () => {
  const { isMobileScreen } = useScreen();
  const [isLoading, setIsLoading] = useState(true);
  const [surveys, setSurveys] = useState([]);
  const [listMobile, setListMobile] = useState([]);
  const [pagination, setPagination] = useState(() => ({
    pageSize: 5,
    total: 0,
    current: 1,
  }));
  const [showModal, setShowModal] = useState(null);
  const [finishSurvey, setFinishSurvey] = useState(false);
  const [finishQuiz, setFinishQuiz] = useState(false);

  const handleTableChangeMobile = (current) => {
    if (
      current === 0 ||
      Math.ceil(pagination.total / pagination.pageSize) + 1 === current
    )
      return;

    setListMobile(
      surveys.slice(
        (current - 1) * pagination.pageSize,
        current * pagination.pageSize,
      ),
    );
    setPagination({ ...pagination, current });
  };

  const handleTableChange = (pagination) => {
    setListMobile(
      surveys.slice(
        (pagination.current - 1) * pagination.pageSize,
        pagination.current * pagination.pageSize,
      ),
    );
    setPagination({ ...pagination, current: pagination.current });
  };

  useEffect(() => {
    getIncompleteSurveys()
      .then((data) => {
        const surveys = data.map((el) => {
          const {
            id,
            service: { name: service },
            contact,
            provider: { name: provider },
            quiz,
            createdAt,
          } = el;

          return {
            id,
            service,
            contact,
            createdAt,
            provider,
            quiz,
            _handleShowModal: () => setShowModal(id),
          };
        });
        setSurveys(surveys);
        setListMobile(surveys.slice(0, 5));
        setPagination((prev) => ({ ...prev, total: surveys.length }));
        setIsLoading(false);
      })
      .catch(() => {
        setSurveys([]);
        setListMobile([]);
        setIsLoading(false);
      });
  }, [finishQuiz]);

  const _handleFinishSurvey = useCallback(
    (value) => {
      setFinishQuiz(!value);
      setSurveys((prev) => prev.filter((el) => el.id !== showModal));
      setPagination((prev) => ({ ...prev, total: prev.total - 1 }));
    },
    [showModal],
  );

  return (
    <section className='profile-company__users'>
      {pagination?.total !== 1 ? (
        <span className='text-base'>
          Se encontraron <strong>{pagination?.total}</strong> encuestas
        </span>
      ) : (
        <span className='text-base'>
          Se encontró <strong>{pagination?.total}</strong> encuesta
        </span>
      )}
      {!isMobileScreen ? (
        surveys.length === 0 && !isLoading ? (
          <Empty title='No se encontraron encuestas realizadas' />
        ) : (
          <SimpleTable
            dataSource={surveys}
            columns={columns}
            rowKey={(record) => record.id}
            loading={isLoading}
            pagination={pagination}
            onChange={handleTableChange}
            className='pt-3'
            locale={{ emptyText: 'No se encontraron encuestas realizadas.' }}
          />
        )
      ) : isLoading ? (
        [1, 2, 3, 4].map((el) => <CardSkeleton key={el} />)
      ) : listMobile.length === 0 ? null : (
        listMobile.map((survey) => (
          <Card
            key={survey.id}
            style={{ width: '100%' }}
            className='d_content__card'
          >
            <div className='d_content__card__body'>
              <div className='card-detail__item'>
                <h4>SERVICIO</h4>
                <p>{survey?.service}</p>
              </div>
              <div className='card-detail__item'>
                <h4>CONTACTO</h4>
                <p>{survey?.contact}</p>
              </div>
              <div className='card-detail__item'>
                <h4>PROVEEDOR</h4>
                <p>{survey?.provider}</p>
              </div>
              <div className='card-detail__item'>
                <h4>FECHA DE SOLICITUD</h4>
                <p>{dayjs(survey?.createdAt).format('DD/MM/YYYY')}</p>
              </div>
            </div>
            <div className='d_content__card__button'>
              <Button
                className='d_content__card__button__text'
                type='link'
                onClick={() => setShowModal(survey.id)}
              >
                Llenar Encuesta <i className='fas fa-chevron-right'></i>
              </Button>
            </div>
          </Card>
        ))
      )}

      <div className='d_content__list__pag'>
        <div
          className={'d_content__list__pag__button'}
          onClick={() => handleTableChangeMobile(pagination.current - 1)}
        >
          {iconLeft({
            color: pagination.current === 1 ? '#A9AFD9' : '#4F4FFF',
          })}
        </div>

        <div className='d_content__list__pag__number color-red-1'>
          {pagination.current}
        </div>
        <div className='d_content__list__pag__sep'>/</div>
        <div className='d_content__list__pag__number color-gray-3'>
          {Math.ceil(pagination.total / pagination.pageSize)}
        </div>

        <div
          className={'d_content__list__pag__button'}
          onClick={() => handleTableChangeMobile(pagination.current + 1)}
        >
          {iconRight({
            color:
              pagination.current ===
              Math.ceil(pagination.total / pagination.pageSize)
                ? '#A9AFD9'
                : '#4F4FFF',
          })}
        </div>
      </div>

      {showModal && (
        <div className='h100vh'>
          <ModalSurvey
            visible={Boolean(showModal)}
            onFinishQuiz={_handleFinishSurvey}
            onCancel={() => setShowModal(null)}
            setFinishSurvey={setFinishSurvey}
            requestServiceItemId={showModal}
          />
        </div>
      )}

      {finishSurvey && (
        <ModalFinishSurvey
          visible={finishSurvey}
          onCancel={() => {
            setFinishSurvey(false);
          }}
        />
      )}
    </section>
  );
};

export default Surveys;
