import React from 'react';
import LeftSection from './LeftSection';
import RightSection from './RightSection';

const index = () => {
  return (
    <main>
      <LeftSection />
      <RightSection />
    </main>
  );
};

export default index;
