import React, { memo, useCallback, useState } from 'react';
import { Steps, Typography, Button, Divider, notification } from 'antd';
import { CheckOutlined, LeftOutlined } from '@ant-design/icons';

import AddContact from '../../../../RequestServiceForm/Form/AddContact';
import { useDispatch } from 'react-redux';
import { updateRequestServiceItem } from '../../../../../../services/requestServicesItems';

const { Text } = Typography;
const { Step } = Steps;

const ModalEditContact = ({ editContact, onBack, isRisk }) => {
  const dispatch = useDispatch();
  const [contact, setContact] = useState({});
  const [loading, setLoading] = useState(false);

  const _handleSendContactData = useCallback(() => {
    setLoading(true);
    updateRequestServiceItem(editContact?.id, contact, dispatch, null, isRisk)
      .then(() => {
        notification.success({
          message: '¡Contacto actualizado!',
        });
      })
      .catch(() => {
        notification.error({
          message: 'Error al actualizar contacto',
        });
      })
      .finally(() => {
        setLoading(false);
        onBack();
      });
  }, [contact, dispatch, editContact, isRisk, onBack]);

  return Boolean(editContact) ? (
    <div className='cmo_container'>
      <div className='d_content'>
        <div className='flex flex-col'>
          <div className='flex flex-align-items-center mb-1'>
            <Button className='mr-1 clock-icon' shape='circle' onClick={onBack}>
              <LeftOutlined />
            </Button>
            <Text className='title'>Editar</Text>
          </div>
          <Divider className='mt-2 mb-2' />
          <div className='formulary-container mt-3'>
            <Steps
              className='steps-blue-berry mb-3'
              size='small'
              current={0}
              direction={'horizontal'}
            >
              <Step key='contact' title='Contacto' />
            </Steps>
            <div className='flex flex-col full-width mt-2'>
              <AddContact
                contactData={{
                  address: editContact?.address,
                  contact: editContact?.contact,
                  number: editContact?.number,
                  email: editContact?.email,
                  position: editContact?.position,
                  ...contact,
                }}
                idForm={editContact?.id}
                buttonText={loading ? 'Guardando...' : 'Guardar'}
                onNextStep={_handleSendContactData}
                onUpdateLocalStorage={(_, data) => {
                  setContact(data);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default memo(ModalEditContact);
