import { memo, useState, useCallback, useMemo, createContext } from 'react';

import Topbar from '../../../../common/ui/Topbar';
import { useScreen } from '../../../../lib/screen';
import Header from '../common/Header';

import BusinessList from './BusinessList';
import PolizaByDefeat from './PolizasByDefeat';
import PolizasDefeated from './PolizasDefeated';
import MasiveUpload from './BusinessList/MasiveUpload';
import CreateBusiness from './BusinessList/BusinessForm';
import ListPolizas from './BusinessList/Poliza/PolizaList';
import { useSelector } from 'react-redux';
import Tabs from '../../../../common/Tabs';

const LIST_TABS_DEFAULT = [
  {
    key: 'business',
    label: 'Lista de empresas',
    create: 'Crear empresa',
    link: [
      { text: 'Carga masiva', key: 'massive_upload' },
      { text: 'Actualizar priorizados', key: 'massive_prioritized' },
    ],
    children: <BusinessList />,
  },
  {
    key: 'polizas',
    label: 'Pólizas por vencer',
    children: <PolizaByDefeat />,
  },
  {
    key: 'polizas-defeated',
    label: 'Pólizas vencidas',
    children: <PolizasDefeated />,
  },
];

const LIST_TABS_COMERCIAL = [
  {
    key: 'business',
    label: 'Lista de empresas',
    children: <BusinessList />,
  },
];

export const CompaniesContext = createContext({});

const CompaniesAdmin = () => {
  const user = useSelector((state) => state.auth.user);
  const rol = user?.rol?.slug;
  const LIST_TABS = useMemo(
    () => (rol !== 'comercial' ? LIST_TABS_DEFAULT : LIST_TABS_COMERCIAL),
    [rol],
  );
  const [activeTab, setActiveTab] = useState(LIST_TABS[0]);
  const [isUploadView, setIsUploadView] = useState(false);
  const { isMobileScreen } = useScreen();
  const [openBussinessForm, setOpenBussinessForm] = useState(null);
  const [itemEdit, setItemEdit] = useState(null);
  const [openPolizas, setOpenPolizas] = useState(null);

  const _handleAction = useCallback((action, item) => {
    setItemEdit(item);
    setOpenBussinessForm(action);
  }, []);

  return (
    <CompaniesContext.Provider value={{ _handleAction, setOpenPolizas }}>
      <div id='checkSize' className='h100vh dashboard-UI'>
        <Topbar />
        {openBussinessForm && (
          <CreateBusiness
            company={itemEdit}
            onBack={_handleAction}
            isMobileScreen={isMobileScreen}
          />
        )}
        {openPolizas && <ListPolizas onBack={setOpenPolizas} />}
        {isUploadView && (
          <MasiveUpload
            keyUpload={isUploadView}
            onBack={(key) => {
              setIsUploadView((prev) => (prev ? null : key));
            }}
            isMobileScreen={isMobileScreen}
          />
        )}
        <div
          className={`d_content service-prevention ${
            isUploadView || openBussinessForm || openPolizas
              ? 'hidden'
              : 'block'
          }`}
        >
          <Header
            active={activeTab}
            setIsUploadView={setIsUploadView}
            setOpenBussinessForm={setOpenBussinessForm}
            setOpenPolizas={setOpenPolizas}
          />
          <Tabs
            activeKey={activeTab.key}
            onChange={(activeKey) =>
              setActiveTab(LIST_TABS.find((el) => el.key === activeKey))
            }
            items={LIST_TABS}
          />
        </div>
      </div>
    </CompaniesContext.Provider>
  );
};

export default memo(CompaniesAdmin);
