import React from 'react';

const Cumulus = (props) => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M36.2501 29.6656L27.061 32.1203V10.3078L30.9626 9.275C31.9626 8.18594 33.4032 7.50157 35.0001 7.50157C36.4501 7.50157 37.7704 8.06719 38.7501 8.98594V6.25157C38.7501 5.86407 38.5704 5.49688 38.2626 5.26094C37.9548 5.02501 37.5548 4.94375 37.1798 5.04375L25.8048 8.05469L14.5673 5.05938C14.511 5.04219 14.4548 5.02969 14.397 5.02032C14.1954 4.98594 13.9892 5.00313 13.797 5.06719L13.3767 5.17969C13.5157 5.65938 13.5923 6.16563 13.5923 6.68907C13.5923 7.71094 13.3657 8.71407 12.9376 9.62969V29.6969L3.74854 32.1297V10.4313C3.27666 9.69063 2.9501 8.86876 2.78291 8.00938L2.17666 8.17188C1.62979 8.31875 1.24854 8.81407 1.24854 9.37969V33.7547C1.24854 34.1422 1.42822 34.5094 1.73604 34.7453C1.95635 34.9156 2.2251 35.0047 2.49854 35.0047C2.60479 35.0047 2.7126 34.9906 2.81885 34.9625L14.1938 31.9516L16.3032 32.5141C15.3595 31.2969 14.8438 29.7953 14.8438 28.2531C14.8438 27.3672 15.0595 26.5297 15.4392 25.7906V7.88126L24.5595 10.3125V24.8344C25.322 25.7688 25.7798 26.9578 25.7798 28.2531C25.7798 29.8984 25.1923 31.4984 24.1235 32.7563L22.847 34.2594L25.4282 34.9469C25.4845 34.9641 25.5407 34.9766 25.5985 34.9859C25.8017 35.0203 26.0063 35.0031 26.1985 34.9391L37.8188 31.8359C38.3657 31.6891 38.747 31.1938 38.747 30.6281V17.5172L36.247 20.4625V29.6688L36.2501 29.6656Z'
        fill='url(#paint0_linear_827_16657)'
      />
      <path
        d='M8.125 2.5C5.79531 2.5 3.90625 4.37344 3.90625 6.68594C3.90625 8.0375 4.3875 9.34687 5.26406 10.3797L8.125 13.75L10.9859 10.3797C11.8641 9.34531 12.3438 8.0375 12.3438 6.68594C12.3438 4.375 10.4547 2.5 8.125 2.5ZM8.125 8.25625C7.25156 8.25625 6.54219 7.55313 6.54219 6.68594C6.54219 5.81875 7.25 5.11562 8.125 5.11562C9 5.11562 9.70781 5.81875 9.70781 6.68594C9.70781 7.55313 9 8.25625 8.125 8.25625ZM35 8.75C32.6703 8.75 30.7812 10.6234 30.7812 12.9359C30.7812 14.2875 31.2625 15.5969 32.1391 16.6297L35 20L37.8609 16.6297C38.7391 15.5953 39.2188 14.2875 39.2188 12.9359C39.2188 10.625 37.3297 8.75 35 8.75ZM35 14.5063C34.1266 14.5063 33.4172 13.8031 33.4172 12.9359C33.4172 12.0687 34.125 11.3656 35 11.3656C35.875 11.3656 36.5828 12.0687 36.5828 12.9359C36.5828 13.8031 35.875 14.5063 35 14.5063ZM20.3125 24.0625C17.9828 24.0625 16.0938 25.9359 16.0938 28.2484C16.0938 29.6 16.575 30.9094 17.4516 31.9422L20.3125 35.3125L23.1734 31.9422C24.0516 30.9078 24.5312 29.6 24.5312 28.2484C24.5312 25.9375 22.6422 24.0625 20.3125 24.0625ZM20.3125 29.8188C19.4391 29.8188 18.7297 29.1156 18.7297 28.2484C18.7297 27.3812 19.4375 26.6781 20.3125 26.6781C21.1875 26.6781 21.8953 27.3812 21.8953 28.2484C21.8953 29.1156 21.1875 29.8188 20.3125 29.8188Z'
        fill='url(#paint1_linear_827_16657)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_827_16657'
          x1='1.24854'
          y1='5.0018'
          x2='14.9286'
          y2='43.475'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.272135' stopColor='#34263B' />
          <stop offset='0.658079' stopColor='#13172C' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_827_16657'
          x1='4.83005'
          y1='5.0752'
          x2='31.7925'
          y2='34.0919'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.35' stopColor='#F7052D' />
          <stop offset='0.85' stopColor='#CA5AFA' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Cumulus;
