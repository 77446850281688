import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Modal, Button, Upload, message, Input } from 'antd';
import { FileFilled } from '@ant-design/icons';
import { RideSpinner } from '@rimac-seguros/ride-system-components';

import { createTicket } from './../../../../services/helpCenter';
import text from './../../../../config/text_es';
import IconSendInfo from './../../../../images/icon_send-info.svg';
import { validateFile } from '../../../../lib/security';
import ErrorMessage from './ErrorMessage';

const { Dragger } = Upload;

const HelpCenterForm = ({ visible, openForm }) => {
  const dispatch = useDispatch();
  const { isLoadingCreate, pagination } = useSelector((state) => ({
    isLoadingCreate: state.helpCenter.isLoadingCreate,
    pagination: state.helpCenter.pagination,
  }));

  const create = async (data, obj) => {
    const created = await createTicket({ ...data, status: 1 }, dispatch, obj);
    return !!created;
  };

  const [form] = Form.useForm();

  const [isUploading, setIsUploading] = useState(false);
  const [file, setFile] = useState(false);
  const [isErrorFile, setErrorFile] = useState(null);
  const [isCreateTicket, setIsCreateTicket] = useState(false);

  useEffect(() => {
    if (!visible) {
      setFile(false);
      setIsCreateTicket(false);
      setErrorFile(null);
    }
  }, [visible]);

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      if (file) {
        values.file_uploaded = file;
      }

      const res = await create(values, {
        page: 1,
        limit: pagination.pageSize,
      });
      setIsCreateTicket(res);
    } catch (err) {
      console.error('Failed to save:', err);
    }
  };

  const handleCancel = () => {
    openForm(false);
  };

  const handleFile = async (file) => {
    return new Promise((resolve, reject) => {
      setErrorFile(null);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const allowedExtensions = ['.png', '.jpeg', '.jpg', '.pdf'];

  const beforeUpload = (file) => {
    const validations = {
      maxSize: 10 * 1024 * 1024, // 10 MB
      allowedExtensions: ['.jpg', '.jpeg', '.png', '.pdf'], // Example extensions
      maxNameLength: 50,
    };
    const { isValid, errors } = validateFile(file, validations);

    if (!isValid) {
      const errorType = Object.keys(errors)[0];
      setErrorFile({ type: errorType });
      return;
    }

    setIsUploading(true);
    handleFile(file)
      .then((base64) => {
        setFile({
          name: file.name,
          base64,
          type: file.type,
        });
        form.setFieldsValue({
          file: file.name,
        });
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  const defaultProps = {
    name: 'file',
    multiple: false,
    beforeUpload,
    disabled: isUploading,
    accept: allowedExtensions.join(','),
  };

  return (
    <Modal
      title='Nueva solicitud'
      onOk={handleSave}
      onCancel={handleCancel}
      open={visible}
      okButtonProps={{
        style: {
          textTransform: 'capitalize',
        },
        size: 'large',
      }}
      cancelButtonProps={{
        style: {
          textTransform: 'capitalize',
        },
        size: 'large',
        isLoadingCreate,
      }}
      okText='Enviar'
      cancelText='Cancelar'
      destroyOnClose
      confirmLoading={isLoadingCreate}
      maskClosable={false}
      footer={
        !isCreateTicket ? (
          <>
            <Button
              key='back'
              onClick={handleCancel}
              size='large'
              style={{ textTransform: 'capitalize' }}
            >
              Cancelar
            </Button>
            <Button
              key='submit'
              type='primary'
              loading={isLoadingCreate}
              onClick={handleSave}
              size='large'
              style={{ textTransform: 'capitalize' }}
            >
              Enviar
            </Button>
          </>
        ) : null
      }
    >
      {!isCreateTicket ? (
        <>
          <div className='mdl-helpcenter--text mb-2'>
            Para enviar una solicitud al Centro de Ayuda, ingresa tu correo
            electrónico que es por donde se efectuará la comunicación y una
            descripción detallando el problema detectado. De ser necesario,
            adjuntar fotos.
          </div>
          <Form form={form} disabled={isLoadingCreate}>
            <Form.Item
              name='subject'
              rules={[
                { required: true, message: 'Por favor ingresa el asunto' },
              ]}
            >
              <Input placeholder='Asunto' maxLength={30} />
            </Form.Item>
            <Form.Item
              name='message'
              rules={[
                { required: true, message: 'Por favor ingresa la descripción' },
              ]}
            >
              <Input.TextArea
                placeholder='Descripción'
                disabled={isLoadingCreate}
                maxLength={500}
                rows={4}
              />
            </Form.Item>
            <Form.Item
              name='file'
              initialValue=''
              rules={[{ message: text.required_message }]}
            >
              {file ? (
                <div className='progressbar-summary'>
                  <div className='progressbar-summary--file'>
                    <FileFilled style={{ fontSize: '16px' }} />
                    <span>{file.name}</span>
                  </div>
                  <div className='progressbar-summary--action'>
                    <Button type='link' onClick={() => setFile(false)}>
                      ELIMINAR
                    </Button>
                  </div>
                </div>
              ) : (
                <Dragger {...defaultProps}>
                  <div className='ant-upload-text'>
                    Toma foto a tus archivos o{' '}
                    <span className='ant-upload-text--link'>
                      adjuntalos aquí
                    </span>
                  </div>
                  <div className='ant-upload-text-secondary'>
                    Máximo 10 MB - Formatos JPG, PNG y PDF
                  </div>
                </Dragger>
              )}
              {isUploading && <RideSpinner size='large' />}

              {isErrorFile && <ErrorMessage typeError={isErrorFile.type} />}
            </Form.Item>
          </Form>
        </>
      ) : (
        <div className='mdl-helpcenter-confirm__boxMessage'>
          <img
            className='mdl-helpcenter-confirm__boxMessage__image'
            src={IconSendInfo}
            alt='Help Center'
          />
          <div className='mdl-helpcenter-confirm__boxMessage__title'>
            <span>¡Listo!</span> Tu solicitud ha sido enviada
          </div>
          <div className='mdl-helpcenter-confirm__boxMessage__message'>
            Enviaremos la información a tu correo electrónico.
            <br />
            Recuerda que esta será resuelta dentro de 3 a 4 días útiles
          </div>
          <div className='mdl-helpcenter-confirm__boxMessage__button'>
            <Button
              type='primary'
              size='large'
              className='mdl-helpcenter-confirm__action'
              style={{ textTransform: 'capitalize' }}
              onClick={handleCancel}
            >
              Aceptar
            </Button>
          </div>
        </div>
      )}
    </Modal>
  );
};

export default HelpCenterForm;
