import React, { memo, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { useScreen } from '../../../../lib/screen';
import { downloadExcel } from '../../../../services/tracking';

import Title from '../../../../common/Typograph/Title';
import Topbar from '../../../../common/ui/Topbar';
import ListAlertsNewsletters from './ListAlertsNewsletters';
import Tabs from '../../../../common/Tabs';
import {
  RideButton,
  RideGlDownloadSolid,
} from '@rimac-seguros/ride-system-components';

const LIST_TABS = [
  {
    key: 'alerts',
    label: 'Alertas y Boletines',
    children: <ListAlertsNewsletters />,
  },
];

const transformFilters = (filters) => {
  const filtersTransform = { ...filters };

  if (filtersTransform.hasOwnProperty('isActive')) {
    filtersTransform.isActive = filtersTransform.isActive === 1;
  }
  if (filtersTransform.hasOwnProperty('date')) {
    const dates = filtersTransform.date;
    if (dates.length > 0) {
      const dateInitial = moment(dates[0]).format('YYYY-MM-DDT00:00:00-05:00');

      const dateFinal = moment(dates[1]).format('YYYY-MM-DDT23:59:59-05:00');

      filtersTransform.date = {
        end: dateFinal,
        start: dateInitial,
      };
    }
  }

  return filtersTransform;
};

const Tracking = () => {
  const filters = useSelector((state) => state.tracking.filters);
  const { isMobileScreen } = useScreen();
  const [activeTab, setActiveTab] = useState(LIST_TABS[0]);
  const [loadingReport, setLoadingReport] = useState(false);

  const _handleDownloadAll = useCallback(() => {
    const filtersTransform = transformFilters(filters);

    setLoadingReport(true);

    downloadExcel(filtersTransform)
      .then((excel) => window.open(excel))
      .finally(() => setLoadingReport(false));
  }, [activeTab, filters]);

  return (
    <div id='checkSize' className='h100vh dashboard-UI'>
      <Topbar />
      <div className='d_content service-prevention block'>
        <div className='flex service-prevention-header justify-between mb-2'>
          <Title className='header-container'>Tracking</Title>
          {!isMobileScreen && (
            <div className='flex gap-4'>
              <RideButton
                variant='fill'
                size='medium'
                onClick={_handleDownloadAll}
                text='Descargar'
                disabled={loadingReport}
                glyph={<RideGlDownloadSolid />}
                glyphPosition='left'
              />
            </div>
          )}
        </div>
        <Tabs
          activeKey={activeTab.key}
          onChange={(activeKey) =>
            setActiveTab(LIST_TABS.find((el) => el.key === activeKey))
          }
          items={LIST_TABS}
        />
        {isMobileScreen ? (
          <div
            className={'fixed-bottom p-3 full-width'}
            style={{ background: '#EDEFFC' }}
          >
            <span
              className='full-width absolute'
              style={{
                height: 12,
                top: -12,
                left: 0,
                background:
                  'linear-gradient(to bottom, rgba(255,0,0,0), #EDEFFC)',
              }}
            ></span>
            <RideButton
              variant='fill'
              className='full-width'
              size='medium'
              onClick={_handleDownloadAll}
              text='Descargar'
              disabled={loadingReport}
              glyph={<RideGlDownloadSolid />}
              glyphPosition='left'
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default memo(Tracking);
