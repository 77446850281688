const UserIcon = () => {
  return (
    <svg
      width='16'
      height='21'
      viewBox='0 0 16 21'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12 20H4C1.8 20 0 20.2 0 18V14C0 11.8 1.8 10 4 10H12C14.2 10 16 11.8 16 14V18C16 20.2 14.2 20 12 20Z'
        fill='#141938'
      />
      <path
        d='M8 8C10.2091 8 12 6.20914 12 4C12 1.79086 10.2091 0 8 0C5.79086 0 4 1.79086 4 4C4 6.20914 5.79086 8 8 8Z'
        fill='#141938'
      />
    </svg>
  );
};

export default UserIcon;
