import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Button, Form, Modal, notification, DatePicker } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

import text from '../../../../../../config/text_es';
import locale from 'antd/es/date-picker/locale/es_ES';

import ModalConfirm from '../../../../../../common/ModalConfirm';

import { useScreen } from '../../../../../../lib/screen';

import { IconCloseRed } from '../../../../ui/PreventionService/Reports/utils';
import { addInitialDiagnostic } from '../../../../../../services/company';
import { IconRange } from '../../../../../../common/Icons/iconText';
import IconFinish from '../../../../../../images/icon_finish.svg';

const ModalDiagnostic = ({ handleClose, data, visible, onSavedChanges }) => {
  const { isMobileScreen, isBeforeTable } = useScreen();
  const [form] = Form.useForm();
  const { validateFields } = form;
  const [loading, setLoading] = useState(false);
  const [confirmMobile, setConfirmMobile] = useState('');

  const handleCancel = () => {
    handleClose();
    form.resetFields();
  };

  const handleSave = () => {
    validateFields((err, values) => {
      if (!err) {
        setLoading(true);
        addInitialDiagnostic({
          id: data.id,
          initial_diagnostic_date: values.date,
        })
          .then((res) => {
            if (res.status === 200 && res?.data?.success) {
              setLoading(false);
              onSavedChanges(true);
              handleCancel();

              if (isBeforeTable) {
                return setConfirmMobile(
                  '¡Listo! Se creó el diagnóstico inicial correctamente',
                );
              }

              notification.success({
                description: 'Se agregó el diagnóstico correctamente.',
                message: '¡Diagnóstico agregado!',
              });
            }
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
            notification.warning({
              description: 'Ha ocurrido un error.',
              message: 'Agregar diagnóstico! ',
            });
          });
      }
    });
  };

  useEffect(() => {
    const elements = document.querySelectorAll('.ant-popover');
    if (elements.length > 0) {
      elements.forEach((element) => {
        element.classList.add('ant-popover-hidden');
      });
    }
  }, [visible]);

  return (
    <>
      <Modal
        open={visible}
        title=''
        closable={true}
        onCancel={() => handleCancel()}
        centered={true}
        onOk={handleSave}
        className={`modal-provider modal-centered modal-config modalPolizaCompany ${
          isMobileScreen ? 'modal-mobile' : ''
        }`}
        cancelText={'Cancelar'}
        okText={'Actualizar'}
        confirmLoading={loading}
      >
        <div className='content-modal cmp_minimal-scroll-y'>
          <h2>Diagnóstico inicial</h2>

          <Form form={form} className='form-action' style={{ marginTop: 32 }}>
            <Form.Item
              name='date'
              initialValue={
                data && data.initial_diagnostic
                  ? moment(data.initial_diagnostic)
                  : ''
              }
              rules={[{ required: true, message: text.required_message }]}
            >
              <DatePicker
                locale={locale}
                pickerInputClass={'rounded-2xl'}
                className={'full-width'}
                format={['DD/MM/YYYY', 'DD/MM/YY']}
                placeholder={'Fecha de diagnóstico inicial'}
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>

      <ModalConfirm
        visible={Boolean(confirmMobile)}
        onClose={() => setConfirmMobile('')}
        title={confirmMobile || ''}
        image={IconFinish}
        actionsContent={
          <div className='actions-delete-modal mt-2'>
            <Button
              className='button w-max'
              type='primary'
              onClick={() => setConfirmMobile('')}
            >
              Aceptar
            </Button>
          </div>
        }
      />
    </>
  );
};

export default ModalDiagnostic;
