import React from 'react';
import { Button } from 'antd';

const LayerUpType = ({ setStep, setSelectForm }) => {
  const enabledUploadMode = () => {
    setStep((prev) => prev + 1);
    setSelectForm('upload');
  };

  const enabledCreateMode = () => {
    setStep((prev) => prev + 1);
    setSelectForm('create');
  };

  return (
    <div className='flex flex-col gap-3 mt-3'>
      <Button type='primary' size='large' onClick={enabledUploadMode}>
        Subir capa
      </Button>
      <Button type='primary' size='large' onClick={enabledCreateMode}>
        Crear capa
      </Button>
    </div>
  );
};

export default LayerUpType;
