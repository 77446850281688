/* eslint-disable no-mixed-operators */
import React, { useEffect, useState } from 'react';
import { useScreen } from '../../../../lib/screen';

const List = ({
  data,
  itemsPerPage = 6,
  pagination,
  render,
  reRender,
  hideSummary,
  onChange,
  className = '',
  paginationSize,
}) => {
  const [page, setPage] = useState(1);
  const [paginationList, setPaginationList] = useState([]);
  const { isTabletScreen } = useScreen();

  const totalPages =
    pagination && pagination.total
      ? Math.ceil(pagination.total / itemsPerPage)
      : Math.ceil(data.length / itemsPerPage);

  useEffect(() => {
    setPage(1);
  }, [reRender]);

  const backPage = () => {
    const newPage = page > 1 ? page - 1 : page;
    setPage(newPage);
    onChange(newPage);
  };

  const nextPage = () => {
    const newPage = page + 1 > totalPages ? page : page + 1;
    setPage(newPage);
    onChange(newPage);
  };

  const toPage = (page) => {
    setPage(page);
    onChange(page);
  };

  const getPaginationLarge = () => {
    let pagTmp = [];
    if (totalPages <= 5) for (let i = 1; i <= totalPages; i++) pagTmp.push(i);
    else if (page <= 3) pagTmp = [1, 2, 3, 4, '...', totalPages];
    else if (page > totalPages - 3)
      pagTmp = [
        1,
        '...',
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
        totalPages,
      ];
    else pagTmp = [1, '...', page - 1, page, page + 1, '...', totalPages];

    return pagTmp;
  };

  const getPaginationSmall = () => {
    let pagTmp = [];
    if (totalPages <= 3) for (let i = 1; i <= totalPages; i++) pagTmp.push(i);
    else if (page <= 2) pagTmp = [1, 2, '...', totalPages];
    else if (page >= totalPages - 1)
      pagTmp = [1, '...', totalPages - 1, totalPages];
    else pagTmp = [1, '...', page, '...', totalPages];

    return pagTmp;
  };

  useEffect(() => {
    if (data.length > 0)
      switch (paginationSize) {
        case 'small':
          setPaginationList(getPaginationSmall());
          break;
        case 'large':
          setPaginationList(getPaginationLarge());
          break;
        default:
          setPaginationList(
            isTabletScreen ? getPaginationSmall() : getPaginationLarge(),
          );
      }
  }, [page, data, isTabletScreen, pagination, paginationSize]);

  return (
    <div className='list-rimac'>
      <div className={`list-rimac__cards ${className}`}>
        {data.map((result, index) =>
          pagination ||
          (index >= (page - 1) * itemsPerPage && index < page * itemsPerPage)
            ? render(result, index)
            : null,
        )}
      </div>
      <div className='list-rimac__pagination'>
        <div
          className={`list-rimac__pagination__button ${page === 1 ? 'list-rimac__pagination__button--disabled' : ''}`}
          onClick={backPage}
        >
          <svg
            width='9'
            height='14'
            viewBox='0 0 9 14'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M6.61882 13.5062L0.106323 6.9999L6.61882 0.493652L8.38133 2.25615L3.64383 6.9999L8.38133 11.7437L6.61882 13.5062Z'
              fill='currentColor'
            />
          </svg>
        </div>
        {paginationList.map((item, i) => (
          <div
            key={`pag-${i}`}
            className={`list-rimac__pagination__number ${item === page ? 'color-primary' : 'color-gray-3'}`}
            onClick={Number.isInteger(item) ? () => toPage(item) : undefined}
          >
            {Number.isInteger(item) ? `${item < 10 ? '0' : ''}${item}` : item}
          </div>
        ))}
        <div
          className={`list-rimac__pagination__button ${page >= totalPages ? 'list-rimac__pagination__button--disabled' : ''}`}
          onClick={nextPage}
        >
          <svg
            width='9'
            height='14'
            viewBox='0 0 9 14'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M2.38128 13.5062L0.618774 11.7437L5.35628 6.9999L0.618774 2.25615L2.38128 0.493652L8.89378 6.9999L2.38128 13.5062Z'
              fill='currentColor'
            />
          </svg>
        </div>
      </div>
      {!hideSummary && (
        <div className='list-rimac__summary'>{`Resultados mostrados ${(page - 1) * itemsPerPage + 1}-${page === totalPages ? data.length : page * itemsPerPage} de ${data.length}`}</div>
      )}
    </div>
  );
};

export default List;
