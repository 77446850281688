import React from 'react';
import Header from './Header';
import useIsMobile from '../../../hooks/useIsMobile';
import { RideSpinner } from '@rimac-seguros/ride-system-components';

const LeftContainer = ({ isLoading }) => {
  const isMobile = useIsMobile();
  return (
    <div className='left-side-login-container'>
      <Header />
      <div className='information-content'>
        <h4
          className={`ride-sys-text-title-${
            isMobile ? '2xlarge' : '8xlarge'
          }-default`}
        >
          Centro de Monitoreo y Prevención
        </h4>
        <span
          className={`ride-sys-text-paragraph-${
            isMobile ? 'medium' : 'xlarge'
          }-regular`}
        >
          Conoce el estado del tiempo y fenómenos naturales
        </span>
        {isLoading && (
          <div className='flex gap-2 items-center my-2'>
            <RideSpinner inverse size='medium' />
            <span
              className={`ride-sys-text-paragraph-${
                isMobile ? 'medium' : 'xlarge'
              }-regular`}
            >
              Accediendo al sistema...
            </span>
          </div>
        )}
        <footer className='ride-sys-text-paragraph-small-default'>
          &copy; {new Date().getFullYear()} RIMAC Seguros y Reaseguros.
        </footer>
      </div>
    </div>
  );
};

export default LeftContainer;
