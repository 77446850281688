import React from 'react';

/* Icons string */
export const iconSismo = `<svg width="16" height="27" viewBox="0 0 16 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="16" height="16" rx="8" fill="#FF1C44"/>
<circle cx="8" cy="8" r="2" fill="white"/>
<rect x="7" y="16" width="2" height="11" fill="#FF1C44"/>
</svg>
`;

export const iconLastSismo = `<svg width="16" height="27" viewBox="0 0 16 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="16" height="16" rx="8" fill="#4F4FFF"/>
<circle cx="8" cy="8" r="2" fill="white"/>
<rect x="7" y="16" width="2" height="11" fill="#4F4FFF"/>
</svg>`;

/* Functions */
export const selectSublayer = (layer) => {
  const sublayerAvailables = layer.mode;
  if (sublayerAvailables.length > 0) {
    const subLayerDefault = sublayerAvailables.find((i) => i.default);

    return subLayerDefault || sublayerAvailables[0];
  }

  return null;
};

export const compareLayersIds = (firstLayer, secondLayer) => {
  const firstLayerId = firstLayer?.id;
  const secondLayerId = secondLayer?.id;

  return firstLayerId === secondLayerId;
};

export const styleGeoJSONFunction = (feature) => {
  const properties = feature.properties;
  const strokeColor = properties.stroke || '#3388ff';
  const strokeWidth = properties['stroke-width'] || 3;
  const strokeOpacity = properties['stroke-opacity'] || 1;
  const fillColor = properties.fill || '#3388ff';
  const fillOpacity = properties['fill-opacity'] || 0.2;

  return {
    color: strokeColor,
    weight: strokeWidth,
    opacity: strokeOpacity,
    fillColor,
    fillOpacity,
  };
};

export const hexToRgb = (hex) => {
  const isValidHex = /^#?([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/i.test(hex);

  if (!hex || !isValidHex) {
    return { r: 0, g: 0, b: 0 };
  }

  if (hex[0] === '#') {
    hex = hex.substring(1);
  }

  return hex.length === 3
    ? {
        r: parseInt(hex[0] + hex[0], 16) / 255,
        g: parseInt(hex[1] + hex[1], 16) / 255,
        b: parseInt(hex[2] + hex[2], 16) / 255,
      }
    : {
        r: parseInt(hex[0] + hex[1], 16) / 255,
        g: parseInt(hex[2] + hex[3], 16) / 255,
        b: parseInt(hex[4] + hex[5], 16) / 255,
      };
};

/* Form PopUp icons in the conflict layer */
export const EditIcon = () => (
  <span
    role='img'
    aria-label='circle'
    className='btn-create-cicle-popup pointer'
  >
    <svg
      t='1621475187938'
      className='icon'
      viewBox='64 64 896 896'
      version='1.1'
      xmlns='http://www.w3.org/2000/svg'
      p-id='1206'
      width='1em'
      height='1em'
    >
      <path
        d={`M932.16 288L729.6 82.56l-635.84 640v202.56H96V928h832v-64H360z m-90.24
        0L736 393.6l-112.64-113.6 106.24-106.56zM157.76 747.84l420.48-422.4 112.96
        113.6-416 420.8H157.76z`}
        p-id='1207'
      ></path>
    </svg>
  </span>
);

export const QuestionIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    version='1.1'
    x='0px'
    y='0px'
    viewBox='0 0 256 256'
    enableBackground='new 0 0 256 256'
    width='25'
    height='25'
  >
    <g>
      <g>
        <path
          fill='#000000'
          d='M42.2,74.4C23.9,74.4,10,60.4,10,42.2C10,23.9,23.9,10,42.2,10c18.2,0,32.2,13.9,32.2,32.2C74.4,60.4,60.4,74.4,42.2,74.4L42.2,74.4z M42.2,31.4c-6.4,0-10.7,4.3-10.7,10.7c0,6.4,4.3,10.7,10.7,10.7c6.4,0,10.7-4.3,10.7-10.7C52.9,35.7,48.6,31.4,42.2,31.4L42.2,31.4z M42.2,246C23.9,246,10,232.1,10,213.8s13.9-32.2,32.2-32.2c18.2,0,32.2,13.9,32.2,32.2C74.4,232.1,60.4,246,42.2,246L42.2,246z M42.2,203.1c-6.4,0-10.7,4.3-10.7,10.7c0,6.4,4.3,10.7,10.7,10.7c6.4,0,10.7-4.3,10.7-10.7C52.9,207.4,48.6,203.1,42.2,203.1L42.2,203.1z M42.2,160.2C23.9,160.2,10,146.2,10,128c0-18.2,13.9-32.2,32.2-32.2c18.2,0,32.2,13.9,32.2,32.2C74.4,146.2,60.4,160.2,42.2,160.2L42.2,160.2z M42.2,117.3c-6.4,0-10.7,4.3-10.7,10.7c0,6.4,4.3,10.7,10.7,10.7c6.4,0,10.7-4.3,10.7-10.7C52.9,121.6,48.6,117.3,42.2,117.3L42.2,117.3z M235.3,52.9H106.6c-6.4,0-10.7-4.3-10.7-10.7c0-6.4,4.3-10.7,10.7-10.7h128.7c6.4,0,10.7,4.3,10.7,10.7C246,48.6,241.7,52.9,235.3,52.9L235.3,52.9z M170.9,138.7h-64.4c-6.4,0-10.7-4.3-10.7-10.7c0-6.4,4.3-10.7,10.7-10.7h64.4c6.4,0,10.7,4.3,10.7,10.7C181.6,134.4,177.4,138.7,170.9,138.7L170.9,138.7z M203.1,224.6h-96.6c-6.4,0-10.7-4.3-10.7-10.7s4.3-10.7,10.7-10.7h96.6c6.4,0,10.7,4.3,10.7,10.7C213.8,220.3,209.5,224.6,203.1,224.6L203.1,224.6z'
        />
      </g>
    </g>
  </svg>
);

export const DeleteIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    height='1em'
    viewBox='0 0 448 512'
    style={{ cursor: 'pointer' }}
  >
    <path
      d={`M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7
     0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2
     0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45
     47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z`}
    />
  </svg>
);

/* PopUp icons */
export const CalendarIcon = () => (
  <span
    role='img'
    aria-label='circle'
    className='btn-create-cicle-popup pointer'
  >
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
    >
      <path
        d={`M11.9905 4.00818H11.7461V3.79636C11.7461 3.38903 11.404 3.04688
        10.9966 3.04688C10.5893 3.04688 10.2471 3.38903 10.2471 3.79636V4.00818H8.74814V3.79636C8.74814
        3.38903 8.40598 3.04688 7.99865 3.04688C7.59132 3.04688 7.24916
        3.38903 7.24916 3.79636V4.00818H5.75018V3.79636C5.75018 3.38903 5.40802 3.04688 5.00069
        3.04688C4.59336 3.04688 4.2512 3.38903 4.2512 3.79636V4.00818H4.0068C3.17584 4.00818 2.50781
        4.6762 2.50781 5.50716V11.5031C2.50781 12.3341 3.17584 13.0021 4.0068 13.0021H12.0068C12.8378
        13.0021 13.5058 12.3341 13.5058 11.5031V5.50716C13.4895 4.6762 12.8215 4.00818 11.9905
        4.00818ZM6.49967 10.4929H5.50578C5.22879 10.4929 5.00069 10.2648 5.00069 9.98781C5.00069
        9.71083 5.22879 9.48272 5.50578 9.48272H6.49967C6.77665 9.48272 7.00476 9.71083 7.00476
        9.98781C6.98847 10.2811 6.77665 10.4929 6.49967 10.4929ZM6.49967 8.00004H5.50578C5.22879
        8.00004 5.00069 7.77194 5.00069 7.49495C5.00069 7.21796 5.22879 6.98986 5.50578
        6.98986H6.49967C6.77665 6.98986 7.00476 7.21796 7.00476 7.49495C6.98847 7.77194
        6.77665 8.00004 6.49967 8.00004ZM10.5567 10.4929H9.48134C9.20435 10.4929 8.97625
        10.2648 8.97625 9.98781C8.97625 9.71083 9.20435 9.48272 9.48134 9.48272H10.5567C10.8337
        9.48272 11.0618 9.71083 11.0618 9.98781C11.0618 10.2811 10.8337 10.4929 10.5567 10.4929ZM10.5567
        8.00004H9.48134C9.20435 8.00004 8.97625 7.77194 8.97625 7.49495C8.97625 7.21796 9.20435 6.98986
        9.48134 6.98986H10.5567C10.8337 6.98986 11.0618 7.21796 11.0618 7.49495C11.0618 7.77194 10.8337
        8.00004 10.5567 8.00004Z`}
        fill='currentColor'
      />
    </svg>
  </span>
);

export const StarIcon = () => (
  <span
    role='img'
    aria-label='star'
    className='icon-favorite anticon anticon-heart'
  >
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
    >
      <path
        d={`M8.90269 3.18641L9.90761 5.33754C9.98611 5.51026 10.1431 5.62017 10.3158
        5.65157L12.6554 6.01271C13.4562 6.13832 13.7859 7.11183 13.2207 7.69279L11.4778
        9.48278C11.3522 9.60839 11.305 9.78111 11.3365 9.95383L11.7447 12.4347C11.886
        13.2512 11.0067 13.8792 10.2687 13.471L8.25893 12.3562C8.08621 12.262 7.89779
        12.262 7.72507 12.3562L5.71526 13.471C4.99298 13.8792 4.11369 13.2669 4.2393
        12.4347L4.64755 9.95383C4.67895 9.78111 4.61614 9.60839 4.50623 9.48278L2.76335
        7.69279C2.19809 7.11183 2.52782 6.13832 3.3286 6.01271L5.66815 5.65157C5.85657
        5.62017 5.99789 5.51026 6.07639 5.33754L7.0813 3.18641C7.45814 2.41703 8.55726
        2.41703 8.90269 3.18641Z`}
        fill='currentColor'
      />
    </svg>
  </span>
);

export const CoordinateIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    style={{
      width: '1em',
      height: '1em',
      verticalAlign: 'middle',
      fill: 'currentColor',
      overflow: 'hidden',
    }}
    viewBox='0 0 1024 1024'
    data-testid='coordinateIcon'
  >
    <path
      d='M893.653333 554.666667c-19.669333 177.984-161.002667 319.317333-338.986666 338.986666V938.666667a42.666667 42.666667 0 1 1-85.333334
      0v-45.013334C291.349333 873.984 150.016 732.650667 130.346667 554.666667H85.333333a42.666667 42.666667 0 1 1 0-85.333334h45.013334C150.016 291.349333
      291.349333 150.016 469.333333 130.346667V85.333333a42.666667 42.666667 0 1 1 85.333334 0v45.013334c177.984 19.669333 319.317333 161.002667 338.986666
      338.986666H938.666667a42.666667 42.666667 0 1 1 0 85.333334h-45.013334zM512 810.666667c164.949333 0 298.666667-133.717333
      298.666667-298.666667s-133.717333-298.666667-298.666667-298.666667-298.666667 133.717333-298.666667 298.666667 133.717333 298.666667 298.666667
      298.666667z m0-128a170.666667 170.666667 0 1 1 0-341.333334 170.666667 170.666667 0 0 1 0 341.333334z m0-85.333334a85.333333 85.333333 0 1 0 0-170.666666
      85.333333 85.333333 0 0 0 0 170.666666z'
      fill='currentColor'
    />
  </svg>
);

export const getSvgIcon = (type, color = '#D7423B') => {
  switch (type) {
    case 'firemen_emergencies':
      return `
            <svg width="27" height="38" viewBox="0 0 166 239">
            <path d="M0 84.8741C4.84995 18.7384 58.971 0 83.2207 0C121.8 0 173.275 31.0838 171.953 84.8741C170.631
            138.617 114.551 214.438 86.6021 245.721C86.6808 245.85 86.6583 245.879 86.5275 245.804C86.5523 245.776
            86.5772 245.749 86.6021 245.721C84.3601 242.044 0 157.286 0 84.8741Z" fill="white"/>
            <path d="M8 85.4043C12.4 25.4043 61.5 8.4043 83.5 8.4043C118.5 8.4043 165.2 36.6043 164 85.4043C162.801
            134.161 111.924 202.948 86.5677 231.329C86.6391 231.446 86.6187 231.472 86.5 231.404C86.5225 231.379
            86.5451 231.354 86.5677 231.329C84.5337 227.993 8 151.099 8 85.4043Z" fill='${color}'/>
            <path d="M84.44 34C27.0452 74.6899 56.3405 116.954 78.1624 133C58.7912 98.8495 74.2761 81.2294 84.44
            76.6881C81.4509 82.7431 78.8805 97.9413 92.5118 110.294C95.2021 108.477 99.3872 101.817 100.583
            97.578C104.469 99.0917 109.91 108.114 100.583 132.092C127.128 116.106 122.508 87.9623 115.828
            76.6881C113.138 72.1468 105.964 62.3376 105.964 48.5321C100.583 48.5321 93.4086 60.6422 90.7182
            67.6055C86.8319 63.6697 80.1354 51.4385 84.44 34Z" fill="#FDFDFD"/>
            </svg>
            `;
    case 'socials':
      return `
            <svg width="27" height="38" viewBox="0 0 27 38" fill="none" >
            <path d="M0.303345 13.4946C1.07447 2.97933 9.67949 0 13.5351 0C19.669 0 27.8533 4.94219 27.6432
            13.4946C27.433 22.0395 18.5165 34.0947 14.0727 39.0686C14.0852 39.0891 14.0817 39.0937 14.0609
            39.0818C14.0648 39.0774 14.0688 39.0731 14.0727 39.0686C13.7162 38.484 0.303345 25.0078 0.303345
            13.4946Z" fill="white"/>
            <path d="M1.57532 13.5789C2.2749 4.03918 10.0816 1.33625 13.5795 1.33625C19.1444 1.33625 26.5695 5.81993
            26.3787 13.5789C26.188 21.331 18.0988 32.2679 14.0673 36.7803C14.0786 36.7989 14.0754 36.8031 14.0565
            36.7923C14.0601 36.7883 14.0637 36.7843 14.0673 36.7803C13.7439 36.2499 1.57532 24.0241 1.57532
            13.5789Z" fill='${color}'/>
            <path d="M20.778 8.085C20.8462 8.13064 20.9022 8.19237 20.9409 8.26475C20.9796 8.33712 20.9999 8.41792
            21 8.5V16C21 16.0999 20.9701 16.1974 20.9141 16.2801C20.8581 16.3628 20.7787 16.4269 20.686
            16.464L20.5 16L20.686 16.464L20.683 16.465L20.677 16.468L20.654 16.477C20.5225 16.5293 20.3902 16.5793
            20.257 16.627C19.993 16.722 19.626 16.85 19.21 16.977C18.394 17.229 17.331 17.5 16.5 17.5C15.653 17.5
            14.952 17.22 14.342 16.975L14.314 16.965C13.68 16.71 13.14 16.5 12.5 16.5C11.8 16.5 10.862 16.73
            10.063 16.977C9.70526 17.0885 9.35078 17.2102 9 17.342V23.5C9 23.6326 8.94732 23.7598 8.85355
            23.8536C8.75979 23.9473 8.63261 24 8.5 24C8.36739 24 8.24021 23.9473 8.14645 23.8536C8.05268
            23.7598 8 23.6326 8 23.5V8.5C8 8.36739 8.05268 8.24021 8.14645 8.14645C8.24021 8.05268 8.36739
            8 8.5 8C8.63261 8 8.75979 8.05268 8.85355 8.14645C8.94732 8.24021 9 8.36739 9 8.5V8.782C9.226
            8.703 9.496 8.612 9.79 8.522C10.606 8.272 11.67 8 12.5 8C13.34 8 14.024 8.277 14.621 8.519L14.664
            8.537C15.286 8.788 15.828 9 16.5 9C17.2 9 18.138 8.77 18.937 8.523C19.3923 8.38081 19.8423 8.22204
            20.286 8.047L20.305 8.04L20.309 8.038H20.31" fill="white"/>
            </svg>
            `;
    case 'emergenciavial':
      return `
            <svg width="27" height="38" viewBox="0 0 27 38" fill="none" >
            <path d="M0.303345 13.4946C1.07447 2.97933 9.67949 0 13.5351 0C19.669 0 27.8533 4.94219 27.6432
            13.4946C27.433 22.0395 18.5165 34.0947 14.0727 39.0686C14.0852 39.0891 14.0817 39.0937 14.0609
            39.0818C14.0648 39.0774 14.0688 39.0731 14.0727 39.0686C13.7162 38.484 0.303345 25.0078 0.303345
            13.4946Z" fill="white"/>
            <path d="M1.57532 13.5789C2.2749 4.03918 10.0816 1.33625 13.5795 1.33625C19.1444 1.33625 26.5695 5.81993
            26.3787 13.5789C26.188 21.331 18.0988 32.2679 14.0673 36.7803C14.0786 36.7989 14.0754 36.8031 14.0565
            36.7923C14.0601 36.7883 14.0637 36.7843 14.0673 36.7803C13.7439 36.2499 1.57532 24.0241 1.57532 13.5789Z" fill='${color}'/>
            <path d="M20.138 5H16.867L16.541 5.33L14.997 8.272L14.511 8.599H9V13.094H12.03L12.3 13.421L9
            19.726V22.999H12.272L12.599 22.669L14.142 19.726L14.628 19.4H20.139V14.905H17.109L16.84 14.576L20.139
            8.273L20.138 5Z" fill="white"/>
            </svg>
            `;
    case 'sutran':
      return `
            <svg width="27" height="38" viewBox="0 0 27 38" fill="none" >
            <path d="M0.303345 13.4946C1.07447 2.97933 9.67949 0 13.5351 0C19.669 0 27.8533 4.94219 27.6432
            13.4946C27.433 22.0395 18.5165 34.0947 14.0727 39.0686C14.0852 39.0891 14.0817 39.0937 14.0609
            39.0818C14.0648 39.0774 14.0688 39.0731 14.0727 39.0686C13.7162 38.484 0.303345 25.0078 0.303345 13.4946Z" fill="white"/>
            <path d="M1.57532 13.5789C2.2749 4.03918 10.0816 1.33625 13.5795 1.33625C19.1444 1.33625 26.5695 5.81993
            26.3787 13.5789C26.188 21.331 18.0988 32.2679 14.0673 36.7803C14.0786 36.7989 14.0754 36.8031 14.0565
            36.7923C14.0601 36.7883 14.0637 36.7843 14.0673 36.7803C13.7439 36.2499 1.57532 24.0241 1.57532 13.5789Z" fill='${color}'/>
            <path d="M20.138 5H16.867L16.541 5.33L14.997 8.272L14.511 8.599H9V13.094H12.03L12.3 13.421L9
            19.726V22.999H12.272L12.599 22.669L14.142 19.726L14.628 19.4H20.139V14.905H17.109L16.84 14.576L20.139
            8.273L20.138 5Z" fill="white"/>
            </svg>
            `;
    case 'location_fav':
      return `
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="512.000000pt" height="512.000000pt" viewBox="0 0 512.000000 512.000000"
            preserveAspectRatio="xMidYMid meet">
            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
            fill='${color}' stroke="black">
            <path stroke-width="200" d="M2449 4995 c-66 -21 -138 -72 -177 -125 -18 -25 -159 -301 -314 -615
            -154 -313 -289 -582 -300 -598 -11 -15 -33 -34 -48 -42 -17 -9 -304 -55 -678
            -109 -422 -62 -667 -102 -701 -115 -196 -74 -285 -311 -189 -501 29 -57 87
            -116 503 -523 258 -252 479 -473 490 -490 13 -21 19 -46 18 -81 -1 -28 -49
            -327 -107 -664 -58 -338 -106 -635 -106 -661 0 -245 258 -424 486 -339 34 13
            313 156 620 318 536 283 561 295 614 295 53 0 77 -12 614 -295 308 -162 585
            -305 615 -317 228 -90 491 92 491 340 0 25 -50 335 -110 688 -96 559 -109 646
            -100 681 13 47 13 47 567 588 362 352 416 409 443 463 92 187 8 414 -183 496
            -34 15 -236 48 -703 116 -375 54 -667 101 -684 110 -16 8 -37 27 -48 42 -11
            16 -144 282 -297 593 -153 311 -291 584 -306 606 -65 95 -161 146 -284 151
            -51 2 -97 -3 -126 -12z"/>
            </g>
            </svg>
            `;
    default:
      return '';
  }
};
