import {
  LOADING_COURSES,
  LOADED_COURSES,
  LOADING_CREATE_COURSE,
  LOADED_COURSES_PAGINATE,
} from '../actions/actionsType';

const initialState = {
  list: [],
  courses: [],
  isLoadingList: false,
  isLoadingCreate: false,
  errors: null,
  page: 1,
  total: 0,
  pagination: {
    pageSize: 5,
    current: 1,
    total: 0,
  },
};

const courses = (state, action) => {
  if (!state) state = initialState;

  switch (action.type) {
    case LOADING_COURSES:
      return {
        ...state,
        isLoadingList: action.payload,
      };
    case LOADED_COURSES_PAGINATE:
      return {
        ...state,
        courses: action.payload.list,
        isLoadingList: false,
        isLoadingCreate: false,
        page: action.payload.page,
        total: action.payload.total,
        pagination: {
          current: action.payload.page,
          pageSize: action.payload.items_per_page,
          total: action.payload.total,
        },
      };
    case LOADED_COURSES:
      return {
        ...state,
        list: action.payload,
        isLoadingList: false,
      };
    case LOADING_CREATE_COURSE:
      return {
        ...state,
        isLoadingCreate: action.payload,
      };
    default:
      return state;
  }
};

export default courses;
