import React from 'react';

const IconManager = () => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 20 20'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M16.7886 11.001H15.1924C13.4252 11.001 12 12.4553 12 14.2586V18.001H16.8076C18.5748 18.001 20 16.5467 20 14.7434V14.278C20 12.4553 18.5558 11.001 16.7886 11.001Z'
        fill='#141938'
      />
      <path
        d='M15.5 10.001C16.8807 10.001 18 8.88169 18 7.50098C18 6.12027 16.8807 5.00098 15.5 5.00098C14.1193 5.00098 13 6.12027 13 7.50098C13 8.88169 14.1193 10.001 15.5 10.001Z'
        fill='#141938'
      />
      <path
        d='M6.5 8.00098C8.433 8.00098 10 6.43397 10 4.50098C10 2.56798 8.433 1.00098 6.5 1.00098C4.567 1.00098 3 2.56798 3 4.50098C3 6.43397 4.567 8.00098 6.5 8.00098Z'
        fill='#141938'
      />
      <path
        d='M12 10.6471C11.399 10.248 10.6778 10.001 9.89649 10.001H3.60601C1.6227 10.001 0 11.5402 0 13.4214V14.8086C0 16.5758 1.5025 18.001 3.36561 18.001H10.1169V14.3335C10.1169 12.8513 10.8581 11.5212 12 10.6471Z'
        fill='#141938'
      />
    </svg>
  );
};

export default IconManager;
