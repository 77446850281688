import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import Marquee from 'react-fast-marquee';

import { getNewslettersToClient } from '../../../services/newsletters';

const MarqueeContainer = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const list = useSelector((state) => state.newsletters.newsletters);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadNewsLetters = async (data, setIsLoading) => {
      setIsLoading(true);
      const { total } = await getNewslettersToClient(dispatch, data);
      if (total === 0) {
        await getNewslettersToClient(dispatch, {
          ...data,
          filters: { status: 'active', scope: 'all' },
        });
      }
      setIsLoading(false);
    };

    loadNewsLetters(
      {
        query: '',
        page: 1,
        limit: 10,
        filters: { status: 'active', scope: 'own' },
      },
      setIsLoading,
    );
  }, []);

  const handleClick = (id) => navigate(`/monitoreo/boletines?id=${id}`);

  return (
    <section className='marquee-container'>
      {isLoading && <Spin size='small' />}
      {!isLoading &&
        (list.length > 0 ? (
          <Marquee className='marquee' autoFill pauseOnHover>
            {list.map(({ detected_condition, detected_condition_red, id }) => (
              <span
                className='marquee__item'
                key={id}
                onClick={() => handleClick(id)}
              >
                {`${detected_condition ?? ''} ${detected_condition_red ?? ''}`}
              </span>
            ))}
          </Marquee>
        ) : (
          <span className='marquee__item'>
            No hay boletines activos en tus zonas afectadas
          </span>
        ))}
    </section>
  );
};

export default MarqueeContainer;
