import {
  OPEN_FORM_ARTICLE,
  LOADING_CREATE_ARTICLE,
  LOADED_ARTICLES,
  LOAD_ARTICLES_LOADING,
  LOADING_PUBLISH_ARTICLE,
  CHANGE_ARTICLE_QUERY,
} from '../actions/actionsType';

const initialState = {
  list: [],
  openedForm: false,
  isLoading: false,
  isLoadingCreate: false,
  isLoadingPublish: false,
  page: 1,
  query: '',
  pagination: {
    pageSize: 5,
    current: 1,
    total: 0,
  },
};

const articles = (state = initialState, action) => {
  switch (action.type) {
    case LOAD_ARTICLES_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case LOADED_ARTICLES:
      const { list, page, total, items_per_page } = action.payload;

      return {
        ...state,
        list,
        total,
        page,
        isLoading: false,
        pagination: {
          total,
          current: page,
          pageSize: items_per_page,
        },
      };

    case OPEN_FORM_ARTICLE:
      return {
        ...state,
        openedForm: action.payload,
      };

    case LOADING_CREATE_ARTICLE:
      return {
        ...state,
        isLoadingCreate: action.payload,
      };

    case LOADING_PUBLISH_ARTICLE:
      return {
        ...state,
        isLoadingPublish: action.payload,
      };
    case CHANGE_ARTICLE_QUERY:
      return {
        ...state,
        query: action.payload || '',
      };
    default:
      return state;
  }
};

export default articles;
