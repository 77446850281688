import {
  CHANGE_REQUEST_SERVICES_ITEMS_QUERY,
  LOADED_REQUEST_SERVICES_ITEMS,
  LOADING_CREATE_REQUEST_SERVICE_ITEM_QUIZ,
  LOADING_UPDATE_REQUEST_SERVICE_ITEM,
  LOAD_REQUEST_SERVICES_ITEMS_LOADING,
} from '../actions/actionsType';

const initialState = {
  list: [],
  isLoading: false,
  isLoadingUpdate: false,
  isLoadingCreateQuiz: false,
  query: '',
  page: 1,
  total: 0,
  pagination: {
    pageSize: 5,
    current: 1,
    total: 0,
  },
};

const requestServicesItems = (state, action) => {
  if (!state) state = initialState;

  switch (action.type) {
    case LOAD_REQUEST_SERVICES_ITEMS_LOADING:
      return {
        ...state,
        isLoadingList: action.payload,
      };
    case LOADED_REQUEST_SERVICES_ITEMS:
      if (state.query && state.query !== action.payload.query) return state;

      return {
        ...state,
        list: action.payload.list,
        page: action.payload.page,
        total: action.payload.total,
        pagination: {
          current: action.payload.page,
          pageSize: action.payload.items_per_page,
          total: action.payload.total,
        },
        isLoading: false,
      };
    case LOADING_CREATE_REQUEST_SERVICE_ITEM_QUIZ:
      return {
        ...state,
        isLoadingCreateQuiz: action.payload,
      };

    case LOADING_UPDATE_REQUEST_SERVICE_ITEM:
      return {
        ...state,
        isLoadingUpdate: action.payload,
      };
    case CHANGE_REQUEST_SERVICES_ITEMS_QUERY:
      return {
        ...state,
        query: action.payload,
      };
    default:
      return state;
  }
};

export default requestServicesItems;
