import React, { useEffect, useRef } from 'react';
import moment from 'moment';

import { Button, Input, Upload, Skeleton } from 'antd';
import {
  LoadingOutlined,
  FilePdfFilled,
  PaperClipOutlined,
} from '@ant-design/icons';

const dayWeekends = [
  'LUNES',
  'MARTES',
  'MIERCOLES',
  'JUEVES',
  'VIERNES',
  'SABADO',
  'DOMINGO',
];
const iconLoading = <LoadingOutlined style={{ fontSize: 26 }} spin />;

const Chat = ({
  comments,
  user,
  handlerDownloadFile,
  beforeUpload,
  isUploading,
  message,
  setMessage,
  handleSendMessage,
  isSendMessage,
}) => {
  const inputRef = useRef(null);

  const defaultProps = {
    name: 'file',
    multiple: false,
    beforeUpload,
    disabled: isUploading,
    accept: '.png,.jpeg,.jpg,.pdf',
  };

  useEffect(() => {
    if (inputRef.current && !isSendMessage) {
      const myElement = document.getElementById('d_content_chat__history');
      const offsetHeight = myElement.offsetHeight;
      myElement.scrollTop = offsetHeight + 200;
    }
  }, [inputRef.current, isSendMessage]);

  return (
    <>
      <div
        id='d_content_chat__history'
        className='d_content_chat__history minimal-scroll-y'
        ref={inputRef}
      >
        {comments.list.map((group, index) => {
          const isMessageToday = group.date === moment().format('YYYY-MM-DD');

          return (
            <React.Fragment key={index}>
              <div
                className={`d_content_chat__history__date mb-2 pb-2 ${
                  index !== 0 ? 'pt-4' : ''
                }`}
              >
                {isMessageToday
                  ? 'HOY'
                  : `${
                      dayWeekends[moment(group.date).isoWeekday() - 1]
                    } ${moment(group.date).format('DD/MM/YYYY')}`}
              </div>
              {group.comments.map((comment, idx) =>
                comment.user.id === user.id ? (
                  <div className='flex flex-justify-end mt-2' key={idx}>
                    <div className='d_content_chat__history--right'>
                      <div className='d_content_chat__history__user mr-1'>
                        <span className='mr-1'>{`${comment?.user?.name} ${comment?.user?.last_name1}`}</span>
                        <span>
                          {moment(comment.createdAt).format('HH:mm A')}
                        </span>
                      </div>
                      <div className='flex' style={{ justifyContent: 'end' }}>
                        <div
                          className={`d_content_chat__history__message d_content_chat__history__message--right ${
                            comment.message
                              ? ''
                              : 'd_content_chat__history__message--image'
                          }`}
                        >
                          {!comment.file ? (
                            comment.message
                          ) : comment.isImage ? (
                            <img
                              className='d_content_chat__history__message__image special-margin--right'
                              src={`${process.env.REACT_APP_WEB_PATH_S3_NEW}${comment.file}`}
                              alt='Imagen centro de ayuda'
                            />
                          ) : (
                            <span
                              className='d_content_chat__history__message__file'
                              onClick={() => handlerDownloadFile(comment.file)}
                            >
                              <FilePdfFilled style={{ fontSize: '48px' }} />
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className='d_content_chat__history--left mt-2' key={idx}>
                    <div className='d_content_chat__history__user ml-1'>
                      <span className='mr-1'>{`${comment?.user?.name} ${comment?.user?.last_name1}`}</span>
                      <span>{moment(comment.createdAt).format('HH:mm A')}</span>
                    </div>
                    <div className='flex'>
                      <div
                        className={`d_content_chat__history__message d_content_chat__history__message--left ${
                          comment.message
                            ? ''
                            : 'd_content_chat__history__message--image'
                        }`}
                      >
                        {!comment.file ? (
                          comment.message
                        ) : comment.isImage ? (
                          <img
                            className='d_content_chat__history__message__image special-margin--left'
                            src={`${process.env.REACT_APP_WEB_PATH_S3_NEW}${comment.file}`}
                            alt={comment.file}
                          />
                        ) : (
                          <span
                            className='d_content_chat__history__message__file'
                            onClick={() => handlerDownloadFile(comment.file)}
                          >
                            <FilePdfFilled style={{ fontSize: '48px' }} />
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                ),
              )}
            </React.Fragment>
          );
        })}
        {comments.isLoadingCreate && (
          <div className='flex flex-justify-end mb-2'>
            <div className='d_content_chat__history--right'>
              <Skeleton
                className='d_content_chat__history--skeletonMessage'
                paragraph={{ rows: 1 }}
                title={false}
                active
              />
            </div>
          </div>
        )}
      </div>
      <div className='d_content_chat__history__textbox mt-1 flex flex-row '>
        <Upload {...defaultProps}>
          <Button
            className='d_content_chat__history__textbox__btn d_content_chat__history__textbox__btn--left'
            type='link'
          >
            <PaperClipOutlined style={{ fontSize: '24px' }} />
          </Button>
        </Upload>
        <Input
          className='d_content_chat__history__textbox__input'
          type='text'
          placeholder='Escribe tu mensaje aquí...'
          value={message}
          onChange={(event) => setMessage(event.target.value)}
        />
        <Button
          className='d_content_chat__history__textbox__btn'
          type='link'
          onClick={() => handleSendMessage()}
          disabled={!message}
        >
          {isSendMessage ? (
            iconLoading
          ) : (
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                d='M3.4 20.4005L20.85 12.9205C21.66 12.5705 21.66 11.4305 20.85 11.0805L3.4 3.60051C2.74 3.31051 2.01 3.80051 2.01 4.51051L2 9.12051C2 9.62051 2.37 10.0505 2.87 10.1105L17 12.0005L2.87 13.8805C2.37 13.9505 2 14.3805 2 14.8805L2.01 19.4905C2.01 20.2005 2.74 20.6905 3.4 20.4005Z'
                fill='#141938'
              />
            </svg>
          )}
        </Button>
      </div>
    </>
  );
};

export default Chat;
