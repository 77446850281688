import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input, Card, Skeleton, Button, Divider, Select } from 'antd';
import { DeleteFilled } from '@ant-design/icons';

import { useScreen } from '../../../../../lib/screen';
import { getFiltersOptions, filterByString } from '../../../../../lib/utils';
import { finishFilters } from './utils';
import {
  deleteValueOffer,
  registerDownloadValueOffer,
} from '../../../../../services/valueOffer';
import { columnsRenovationOffer, ListActions, filtersCommon } from './colums';

import SimpleTable from '../../../../../common/Table/SimpleTable';
import Title from '../../../../../common/Typograph/Title';
import FiltersMobile from '../../../../../common/FiltersMobile';
import DrawerMoreOptionsMobile from '../../../../../common/DrawerMoreOptionsMobile';
import PaginationMobile from '../../../../../common/PaginationMobile';
import ModalConfirm from '../../../../../common/ModalConfirm';
import CardUserMobile from '../../common/CardUserMobile';
import RenewPolicyModal from './RenewPolicyModal';
import MultipleSelect from '../../../../../common/MultipleSelect';

const { Search } = Input;

const OVRenovation = ({ openOpenForm = () => {} }) => {
  const dispatch = useDispatch();
  const { isMobileScreen } = useScreen();
  const { valueOfferList, userAuth, isLoadingData } = useSelector((state) => ({
    valueOfferList: state.valueOffer.list,
    userAuth: state.auth.user,
    isLoadingData: state.valueOffer.isLoadingList,
    isLoadingCreate: state.valueOffer.isLoadingCreate,
  }));
  const [mobileActions, setMobileActions] = useState(null);
  const [offerListFiltered, setOfferListFiltered] = useState([]);
  const [pagination, setPagination] = useState({ pageSize: 5, current: 1 });
  const [offerToDelete, setOfferToDelete] = useState();
  const [policyToRenew, setPolicyToRenew] = useState();

  const [visibleFilters, setVisibleFilters] = useState();
  const [filtersLocal, setFiltersLocal] = useState({});
  const [filtersOptions, setFiltersOptions] = useState({});

  useEffect(() => {
    setOfferListFiltered(valueOfferList);
    if (valueOfferList.length) {
      const filtersOpts = getFiltersOptions(valueOfferList, filtersCommon);
      setFiltersOptions(filtersOpts);
    }
  }, [valueOfferList]);

  const _handleChangeSearch = useCallback(
    ({ target: { value } }) => {
      setOfferListFiltered(filterByString(valueOfferList, value));
    },
    [valueOfferList],
  );

  const _handleRenewPolicy = useCallback((data) => {
    setPolicyToRenew((prev) => (prev ? null : data));
  }, []);

  const _handleSelectOption = useCallback(
    (key, selected) => {
      setFiltersLocal({ ...filtersLocal, [key]: selected });
    },
    [filtersLocal],
  );

  const _handleDownloadItem = useCallback((item) => {
    window.open(
      `${process.env.REACT_APP_WEB_PATH_S3_NEW}${item.file}`,
      '_blank',
    );
    registerDownloadValueOffer({
      valueOfferId: item.id,
    });
  }, []);

  const _handleDeleteOfferValue = useCallback((id) => {
    setOfferToDelete((prev) => (prev ? null : id));
  }, []);

  const _handleUpdateFilters = useCallback((name, value) => {
    setFiltersOptions((prev) => {
      const newcols = prev[name].map((el) => {
        return el?.value === value
          ? {
              ...(el ?? {}),
              selected: !el.selected,
            }
          : el;
      });

      return { ...prev, [name]: newcols };
    });
  }, []);

  const onFinishFilters = useCallback(() => {
    const allFilters = { ...filtersOptions, ...filtersLocal };
    let filtersOpts = {};
    let filter = false;

    Object.keys(allFilters).forEach((key) => {
      if (typeof allFilters[key] !== 'object') {
        filtersOpts[key] = {
          options: allFilters[key],
          value: filtersCommon.find((f) => f.key === key)?.value,
        };
        filter = true;
      } else {
        const filterOpts = allFilters[key].filter((item) => item.selected);
        if (filterOpts.length) {
          filtersOpts[key] = {
            options: filterOpts,
            value: filtersCommon.find((f) => f.key === key)?.value,
          };
          filter = true;
        }
      }
    });
    if (filter) {
      const responseFilter = finishFilters(valueOfferList, filtersOpts);
      setOfferListFiltered(responseFilter);
    } else {
      setOfferListFiltered(valueOfferList);
    }
  }, [filtersLocal, filtersOptions, valueOfferList]);

  useEffect(() => {
    if (!visibleFilters) {
      onFinishFilters();
    }
  }, [filtersLocal, filtersOptions]);

  const onClearFilters = () => {
    setFiltersLocal({});
    setOfferListFiltered(valueOfferList);
    Object.keys(filtersOptions).forEach((name) => {
      setFiltersOptions((prev) => {
        const newcols = prev[name].map((el) => {
          return {
            ...el,
            selected: false,
          };
        });

        return { ...prev, [name]: newcols };
      });
    });
  };

  return (
    <div>
      {!isMobileScreen ? (
        <>
          <div
            className={`flex justify-between ${
              isMobileScreen ? 'flex-col pt-2 mb-3' : 'items-center mt-1 mb-4'
            }`}
          >
            <Title>Filtrar</Title>
            <Search
              className={`search ${isMobileScreen ? 'mt-2' : ''}`}
              placeholder={'Palabras clave'}
              style={{
                width: '100%',
                maxWidth: '300px',
              }}
              onChange={_handleChangeSearch}
            />
          </div>
          <div className='flex mb-4' style={{ gap: '20px' }}>
            {filtersCommon.slice(0, 2).map(({ key, label, type, ...item }) => (
              <Fragment key={key}>
                {type === 'select' ? (
                  <Select
                    key={key}
                    placeholder={label}
                    options={filtersOptions[key] || []}
                    onChange={(selected) => {
                      _handleSelectOption(key, selected);
                    }}
                    value={filtersLocal[key]}
                  />
                ) : null}
                {type === 'selectMultiple' ? (
                  <MultipleSelect
                    filters={filtersOptions[key] || []}
                    updateFilterColumns={_handleUpdateFilters}
                    placeholder={item?.placeholder}
                    name={key}
                  />
                ) : null}
              </Fragment>
            ))}
            <div className='flex items-center flex-1 gap-2'>
              <FiltersMobile
                buttonTitle={'Más filtro'}
                onClearFilters={onClearFilters}
                setVisibleFilter={setVisibleFilters}
                onFinishFilters={onFinishFilters}
                filters={filtersCommon.map(({ key, type, placeholder }) => ({
                  key,
                  label: placeholder,
                  typeFilter: type,
                  options: filtersOptions[key] || [],
                  onChange: (selected) => {
                    _handleSelectOption(key, selected);
                  },
                  updateFilterColumns: _handleUpdateFilters,
                  value: filtersLocal[key],
                  placeholder,
                }))}
              />
              <Button size='small' type='link' onClick={onClearFilters}>
                <DeleteFilled />
                Borrar filtros
              </Button>
            </div>
          </div>
        </>
      ) : null}
      <Divider />

      <div className='flex justify-between items-center mt-2 mb-2'>
        <span className='text-base'>
          Se encontraron <strong>{offerListFiltered?.length}</strong> ofertas de
          valor
        </span>
        {isMobileScreen ? (
          <FiltersMobile
            onClearFilters={onClearFilters}
            setVisibleFilter={setVisibleFilters}
            onFinishFilters={onFinishFilters}
            filters={[
              {
                typeFilter: 'search',
                className: 'search',
                placeholder: 'Palabras clave',
                onChange: _handleChangeSearch,
              },
            ].concat(
              filtersCommon.map(({ key, placeholder, type }) => ({
                key,
                label: placeholder,
                typeFilter: type,
                options: filtersOptions[key] || [],
                onChange: (selected) => {
                  _handleSelectOption(key, selected);
                },
                updateFilterColumns: _handleUpdateFilters,
                value: filtersLocal[key],
                placeholder,
              })),
            )}
          />
        ) : null}
      </div>
      {isMobileScreen ? (
        <Fragment>
          <Divider />
          {isLoadingData ? (
            [1, 2, 3].map((i) => (
              <Card className='mt-2' key={i}>
                <Skeleton />
              </Card>
            ))
          ) : (
            <>
              {offerListFiltered
                .slice(
                  pagination.pageSize * (pagination.current - 1),
                  pagination.pageSize * pagination.current,
                )
                .map((value) => (
                  <CardUserMobile
                    key={value.id}
                    columns={columnsRenovationOffer}
                    onAction={() => {
                      setMobileActions({
                        ...value,
                        onEdit: () => {
                          openOpenForm(value);
                          setMobileActions(null);
                        },
                        onDownload: () => {
                          _handleDownloadItem(value);
                        },
                        onReniewPolicy: () => {
                          _handleRenewPolicy(value);
                        },
                        onSeeDownloads: () => {
                          setMobileActions(null);
                        },
                        onDelete: () => {
                          _handleDeleteOfferValue(value.id);
                        },
                        userAuth,
                      });
                    }}
                    item={value}
                  />
                ))}
              <PaginationMobile
                {...pagination}
                total={offerListFiltered.length}
                onChange={(page) => {
                  setPagination((prev) => ({
                    ...prev,
                    limit: 5,
                    current: page,
                  }));
                }}
              />
            </>
          )}
        </Fragment>
      ) : null}
      {!isMobileScreen ? (
        <SimpleTable
          className={'mt-4'}
          loading={isLoadingData}
          columns={columnsRenovationOffer}
          dataSource={
            offerListFiltered
              ? offerListFiltered.map((el) => ({
                  ...el,
                  onEdit: () => {
                    openOpenForm({
                      ...el,
                      businessSegment: el?.businessSegment?.id,
                    });
                  },
                  onDownload: () => {
                    _handleDownloadItem(el);
                  },
                  onReniewPolicy: () => {
                    _handleRenewPolicy(el);
                  },
                  onDelete: () => {
                    _handleDeleteOfferValue(el.id);
                  },
                  userAuth,
                }))
              : []
          }
          pagination={{
            ...pagination,
            total: offerListFiltered.length,
            onChange: (page) => {
              setPagination((prev) => ({
                ...prev,
                limit: 5,
                current: page,
              }));
            },
          }}
        />
      ) : null}
      {isMobileScreen && (
        <DrawerMoreOptionsMobile
          visible={mobileActions}
          onClose={() => {
            setMobileActions(null);
          }}
        >
          <ListActions isMobile {...(mobileActions ?? {})} />
        </DrawerMoreOptionsMobile>
      )}
      <ModalConfirm
        defaultImage
        actionsContent={
          <div className='actions-delete-modal mt-2'>
            <Button
              className='secondary-button mr-2'
              size='large'
              onClick={_handleDeleteOfferValue}
            >
              Cancelar
            </Button>
            <Button
              type='primary'
              size='large'
              onClick={() => {
                deleteValueOffer(offerToDelete, dispatch);
                _handleDeleteOfferValue();
              }}
            >
              Aceptar
            </Button>
          </div>
        }
        title={'¿Seguro que desea eliminar la oferta de valor de la lista ?'}
        onClose={_handleDeleteOfferValue}
        visible={offerToDelete}
      />
      <RenewPolicyModal visible={policyToRenew} onClose={_handleRenewPolicy} />
    </div>
  );
};

export default memo(OVRenovation);
