import Topbar from '../../../../common/ui/Topbar';
import CoursesList from './components/CoursesList';
import HeaderSection from './components/HeaderSection';
import Tabs from '../../../../common/Tabs';

const TABS = [
  {
    key: 'list',
    label: 'Lista de cursos',
    children: <CoursesList />,
  },
];

const Courses = () => {
  return (
    <div id='checkSize' className='h100vh dashboard-UI'>
      <Topbar />

      <div className='d_content service-prevention'>
        <HeaderSection />

        <Tabs defaultActiveKey={'list'} items={TABS} />
      </div>
    </div>
  );
};

export default Courses;
