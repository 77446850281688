import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Input, Skeleton, Card, Typography } from 'antd';
import { DownloadOutlined, SyncOutlined } from '@ant-design/icons';
import moment from 'moment';

import { useScreen } from '../../../lib/screen';
import { getCumulo, syncsStatus } from '../../../services/campusImported';
import {
  RideButton,
  RideGlUpdateOutline,
  RideGlFiltersOutline,
  RideGlDeleteOutline,
  RideGlDownloadOutline,
} from '@rimac-seguros/ride-system-components';

import {
  IconClose,
  iconLeft,
  iconRight,
} from '../ui/PreventionService/Reports/utils';
import Topbar from '../../../common/ui/Topbar';
import { IconFilter } from '../../../common/Icons';
import SimpleTable from '../../../common/Table/SimpleTable';
import ColumnsFilter from './ColumnsFilters';
import ModalDownloadCumulo from './ModalDownloadCumulo';
import DrawerFiltersCumulus from './DrawerFiltersCumulus';
import DrawerAddCumulo from './DrawerAddCumulo';
import DrawerUpdateCumulo from './DrawerUpdateCumulo';
import CardCumuloMobile from './CardCumuloMobile';
import CardHistoryMobile from './CardHistoryMobile';

import { getListCumulus } from '../../../services/users';
import PaginationMobile from '../../../common/PaginationMobile';
import { cumulusColumns } from './utils';

moment.locale('es');

const { Search } = Input;
const { Title } = Typography;

const pageSize = 5;

const HISTORY_COLUMNS = [
  {
    dataIndex: 'name',
    key: 'name',
    title: 'NOMBRE DE ACTUALIZACIÓN',
  },
  {
    dataIndex: 'user',
    key: 'user',
    title: 'USUARIO CREADOR',
    render: (user) => `${user.name} ${user.last_name1}`,
  },
  {
    dataIndex: 'createdAt',
    key: 'createdAt',
    title: 'FECHA DE SUBIDA',
    render: (createdAt) => moment(createdAt).format('DD/MM/YYYY'),
  },
  {
    dataIndex: 'url',
    key: 'url',
    title: 'LINK DE DESCARGA',
    render: (url) => (
      <Button
        style={{ textAlign: 'start', border: 'none', borderRadius: 0 }}
        type='link'
        className='link'
        icon={<DownloadOutlined />}
        onClick={() => {
          window.open(
            `${process.env.REACT_APP_WEB_PATH_S3_NEW}my_cumulus/${url}`,
            '_blank',
          );
        }}
      >
        Descargar
      </Button>
    ),
  },
];

const Cumulus = ({
  userAuth,
  pagination,
  loadCampusImported,
  listOfCumulo,
  isLoading,
}) => {
  const { isMobileScreen: isPhone } = useScreen();
  const [filterMobile, setFilterMobile] = useState(false);
  const [text, setText] = useState('');
  const [filtersLocal, setFiltersLocal] = useState([]);
  const [filterColumns, setFilterColumns] = useState([]);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [openModalDownload, setOpenModalDownload] = useState(null);
  const [drawerFiltersVisible, setDrawerFilterVisible] = useState(false);
  const [status, setStatus] = useState({});
  const [showHistory, setShowHistory] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [historyPagination, setHistoryPagination] = useState(() => ({
    current: 1,
    pageSize: 5,
    total: 0,
  }));
  const [isLoadingHistory, setIsLoadingHistory] = useState(false);

  useEffect(() => {
    const cumulo_columns_local = localStorage.getItem('cumulo_columns');
    if (cumulo_columns_local) {
      const list_of_cumul0 = JSON.parse(cumulo_columns_local);
      setFilterColumns(list_of_cumul0);
    } else {
      setFilterColumns(cumulusColumns);
    }
  }, []);

  let debounceTimer;
  const debounceLoadCampusImported = ({ text, pageSize, filters }) => {
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => {
      loadCampusImported({
        query: text || '',
        page: 1,
        limit: pageSize,
        filters,
      });
    }, 1000);
  };

  const _handleModalFilter = useCallback(() => {
    setFilterMobile((prev) => !prev);
  }, []);
  const _handleToggleCumulusDrawer = useCallback((action) => {
    setDrawerVisible((prev) => (prev ? null : action));
  }, []);
  const _handleToggleDownload = useCallback(() => {
    setOpenModalDownload((prev) => !prev);
  }, []);
  const _handleToggleFiltersDrawer = useCallback(() => {
    setDrawerFilterVisible((prev) => !prev);
  }, []);
  const _handleChangeQuery = useCallback(({ target: { value } }) => {
    setText(value);
  }, []);

  const checkStatus = useCallback(async () => {
    const status = await syncsStatus();
    setStatus(status?.syncStatus ?? {});
  }, []);

  const handleGetHistoryList = async (page) => {
    setIsLoadingHistory(true);
    const res = await getListCumulus({ page });
    setHistoryData(res.list);
    setHistoryPagination({
      ...historyPagination,
      current: page,
      total: res.total,
    });
    setIsLoadingHistory(false);
  };

  const getListOfCumulus = () => {
    setIsLoadingHistory(true);

    getListCumulus({ page: historyPagination?.current })
      .then((res) => {
        if (res) {
          setHistoryData(res?.list);
          setHistoryPagination((prev) => ({ ...prev, total: res.total }));
        }
      })
      .finally(() => {
        setIsLoadingHistory(false);
      });
  };

  useEffect(() => {
    checkStatus();
  }, []);

  useEffect(() => {
    if (status?.running) {
      let tempCheckStatus = setInterval(checkStatus, 5000);

      return () => clearTimeout(tempCheckStatus);
    }
  }, [status]);

  useEffect(() => {
    if (text.length === 0) {
      debounceLoadCampusImported({
        text: '',
        pageSize,
        filters: filtersLocal,
      });
    }

    if (text && text.length > 2) {
      debounceLoadCampusImported({
        text,
        pageSize,
        filters: filtersLocal,
      });
    }
  }, [text]);

  useEffect(() => {
    loadCampusImported({
      query: '',
      page: 1,
      limit: pageSize,
    });
  }, []);

  useEffect(() => {
    loadCampusImported({
      query: text || '',
      page: 1,
      limit: pageSize,
      filters: filtersLocal,
    });
  }, [filtersLocal]);

  const handleClearFilter = useCallback(() => {
    setText('');
    setFiltersLocal([]);
    loadCampusImported({
      page: pagination.currentPage,
      limit: pageSize,
      query: '',
      filters: [],
    });
  }, [loadCampusImported, pagination]);

  const _handleUpdateFilters = useCallback((key) => {
    setFilterColumns((prev) => {
      const newcols = prev.map((el) =>
        el.key === key
          ? {
              ...el,
              visible: !el.visible,
            }
          : el,
      );

      localStorage.setItem('cumulo_columns', JSON.stringify(newcols));

      return newcols;
    });
  }, []);

  const _handleTableChangeMobile = useCallback(
    (page) => () => {
      loadCampusImported({
        page,
        limit: pageSize,
        query: text,
        filters: filtersLocal,
      });
    },
    [filtersLocal, loadCampusImported, text],
  );

  const _handleCleanFilters = useCallback(() => {
    loadCampusImported({
      page: 1,
      limit: pageSize,
      query: '',
      filters: [],
    });
    _handleToggleFiltersDrawer();
  }, [loadCampusImported, _handleToggleFiltersDrawer]);

  return (
    <>
      <div id='checkSize' className='h100vh dashboard-UI'>
        <Topbar />
        <div className='d_content'>
          <div
            className='mb-2'
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <div className='flex gap-2 items-center'>
              <Title className='header-container'>Cúmulo</Title>
              <RideButton
                variant='outline'
                onClick={() => {
                  if (!historyData?.length && !showHistory) {
                    getListOfCumulus();
                  }
                  setShowHistory(!showHistory);
                }}
                text={!showHistory ? 'Ver historial' : 'Ver listado'}
              />
            </div>
            {!isPhone && userAuth?.rol?.slug === 'admin_rimac' ? (
              <div className='flex gap-2' style={{ justifySelf: 'flex-end' }}>
                <RideButton
                  variant='text-primary'
                  onClick={() => {
                    _handleToggleCumulusDrawer('update');
                  }}
                  glyph={
                    <RideGlUpdateOutline
                      className={status?.running ? 'rotate-icon' : ''}
                    />
                  }
                  glyphPosition='left'
                  text='Actualizar cúmulo'
                />
                <RideButton
                  disabled={status?.running}
                  onClick={() => {
                    _handleToggleCumulusDrawer('create');
                  }}
                  text='Cargar cúmulo'
                />
              </div>
            ) : null}
            {isPhone ? (
              <div
                className={'fixed-bottom p-3 full-width'}
                style={{ background: '#EDEFFC', paddingTop: 0 }}
              >
                <span
                  className='full-width absolute'
                  style={{
                    height: 12,
                    top: -12,
                    left: 0,
                    background:
                      'linear-gradient(to bottom, rgba(255,0,0,0), #EDEFFC)',
                  }}
                ></span>
                <Button
                  className='full-width'
                  type='link'
                  size='large'
                  onClick={() => {
                    _handleToggleCumulusDrawer('update');
                  }}
                >
                  <SyncOutlined />
                  Actualizar cúmulo
                </Button>
                <Button
                  disabled={status?.running}
                  className='full-width'
                  type='primary'
                  size='large'
                  onClick={() => {
                    _handleToggleCumulusDrawer('create');
                  }}
                >
                  Cargar cúmulo
                </Button>
              </div>
            ) : null}
          </div>
          <div className='d_content'>
            {!showHistory && (
              <>
                <div
                  className='flex full-width justify-between mb-4'
                  style={{ marginTop: -24 }}
                >
                  <div className='text-subtitle-bold mt-1'>
                    Filtrar lista de cúmulo
                  </div>
                </div>

                <div
                  className={`filter__box flex flex-row flex-wrap pb-4 mb-4 gap-2 ${
                    filterMobile ? 'modal__filter' : ''
                  }`}
                >
                  {filterMobile && (
                    <>
                      <div
                        className='modal__filter--close'
                        onClick={_handleModalFilter}
                      >
                        <span>Cerrar</span>
                        <IconClose />
                      </div>

                      <h3 className='modal__filter--title'>
                        Filtrar lista de cúmulo
                      </h3>
                    </>
                  )}
                  <Search
                    placeholder='Palabra clave'
                    style={!filterMobile ? { width: 255, minWidth: 160 } : {}}
                    value={text}
                    onChange={_handleChangeQuery}
                  />
                  <ColumnsFilter
                    filters={filterColumns}
                    updateFilterColumns={_handleUpdateFilters}
                  />
                  <RideButton
                    variant='text-primary'
                    onClick={_handleModalFilter}
                    text='Filtrar'
                    glyph={<RideGlFiltersOutline />}
                    glyphPosition='left'
                  />
                  <RideButton
                    variant='text-primary'
                    onClick={handleClearFilter}
                    glyph={<RideGlDeleteOutline />}
                    text='Borrar Filtros'
                    glyphPosition='left'
                  />

                  {filterMobile ? (
                    <button
                      className='modal__filter--button'
                      type='button'
                      disabled={
                        text === '' &&
                        filtersLocal['status'] === '' &&
                        filtersLocal['status'] === ''
                      }
                      onClick={_handleModalFilter}
                    >
                      Filtrar
                    </button>
                  ) : null}
                </div>
              </>
            )}
            <div
              className='d_content__list__action mb-4 flex flex-row gap-2'
              style={{ justifyContent: 'justify-between' }}
            >
              <div className='info-header'>
                {!showHistory ? (
                  <div>
                    {pagination?.total === 1 ? (
                      <>
                        Se encontró <b>1</b> registros
                      </>
                    ) : (
                      <>
                        Se encontraron <b>{pagination?.total}</b> registros
                      </>
                    )}
                  </div>
                ) : (
                  <div>
                    {historyPagination?.total === 1 ? (
                      <>
                        Se encontró <b>1</b> registro
                      </>
                    ) : (
                      <>
                        Se encontraron <b>{historyPagination?.total}</b>{' '}
                        registros
                      </>
                    )}
                  </div>
                )}
              </div>
              {!showHistory && (
                <div
                  className='filter-action'
                  onClick={_handleToggleFiltersDrawer}
                >
                  <IconFilter />
                  <span>Filtrar</span>
                </div>
              )}
              <RideButton
                variant='text-primary'
                onClick={_handleToggleDownload}
                text='Descargar cúmulo'
                glyph={<RideGlDownloadOutline />}
                glyphPosition='left'
              />
            </div>

            {!showHistory ? (
              <div className='d_content__list'>
                {!isPhone ? (
                  isLoading ? (
                    <Skeleton />
                  ) : (
                    <SimpleTable
                      key='cumulo-table-common'
                      rowKey={({ id }) => id}
                      dataSource={listOfCumulo}
                      columns={filterColumns
                        .filter((el) => el.visible)
                        .map(({ title, dataIndex, key, width }) => ({
                          title,
                          dataIndex,
                          key,
                          width,
                        }))}
                      pagination={{
                        ...pagination,
                        onChange: (page) => _handleTableChangeMobile(page)(),
                      }}
                    />
                  )
                ) : isLoading ? (
                  [1, 2, 3].map((number) => (
                    <Card className='d_content__card' key={`loading-${number}`}>
                      <Skeleton className='rounded-2xl' />
                    </Card>
                  ))
                ) : (
                  listOfCumulo.map((item, index) => (
                    <CardCumuloMobile
                      key={`cumulo-${item.id || index}`}
                      item={item}
                      filterColumns={filterColumns.filter((el) => el.visible)}
                    />
                  ))
                )}
              </div>
            ) : (
              <div className='d_content__list'>
                {!isPhone ? (
                  isLoadingHistory ? (
                    <Skeleton />
                  ) : (
                    <SimpleTable
                      key='history-cumulo-table-common'
                      loading={isLoadingHistory}
                      rowKey={({ id }) => id}
                      columns={HISTORY_COLUMNS}
                      dataSource={historyData}
                      pagination={{
                        ...historyPagination,
                        onChange: (page) => handleGetHistoryList(page),
                      }}
                    />
                  )
                ) : isLoadingHistory ? (
                  [1, 2, 3].map((number) => (
                    <Card className='d_content__card' key={`loading-${number}`}>
                      <Skeleton className='rounded-2xl' />
                    </Card>
                  ))
                ) : (
                  historyData.map((item, index) => (
                    <CardHistoryMobile
                      key={`history-${item.id || index}`}
                      data={item}
                    />
                  ))
                )}
              </div>
            )}

            {isPhone ? (
              !showHistory ? (
                <div
                  className='d_content__list__pag'
                  style={{ marginBottom: 150 }}
                >
                  <div
                    className={'d_content__list__pag__button'}
                    onClick={_handleTableChangeMobile(pagination.current - 1)}
                  >
                    {iconLeft({
                      color: pagination.current === 1 ? '#A9AFD9' : '#4F4FFF',
                    })}
                  </div>
                  <div className='d_content__list__pag__number color-red-1'>
                    {pagination.current}
                  </div>
                  <div className='d_content__list__pag__sep'>/</div>
                  <div className='d_content__list__pag__number color-gray-3'>
                    {Math.ceil(pagination.total / pagination.pageSize)}
                  </div>
                  <div
                    className={'d_content__list__pag__button'}
                    onClick={_handleTableChangeMobile(pagination.current + 1)}
                  >
                    {iconRight({
                      color:
                        pagination.current === pagination.pageSize
                          ? '#A9AFD9'
                          : '#4F4FFF',
                    })}
                  </div>
                </div>
              ) : (
                <PaginationMobile
                  onChange={handleGetHistoryList}
                  style={{ marginBottom: 150 }}
                  total={historyPagination?.total}
                  current={historyPagination?.current}
                  pageSize={historyPagination?.pageSize}
                />
              )
            ) : null}
          </div>
        </div>
      </div>
      <ModalDownloadCumulo
        visible={Boolean(openModalDownload)}
        onClose={_handleToggleDownload}
        columns={filterColumns}
        filters={filtersLocal}
      />
      <DrawerFiltersCumulus
        visible={Boolean(drawerFiltersVisible)}
        onClose={_handleToggleFiltersDrawer}
        filtersLocal={filtersLocal}
        onCleanFilters={_handleCleanFilters}
        othersFilters={
          isPhone ? (
            <div className='full-width flex-col flex'>
              <Search
                className='inputs-class full-width mb-2'
                placeholder='Palabra clave'
                value={text}
                onChange={_handleChangeQuery}
              />
              <div>
                <ColumnsFilter
                  filters={filterColumns}
                  updateFilterColumns={_handleUpdateFilters}
                />
              </div>
              <span className='mt-3'>Realiza una búsqueda específica</span>
            </div>
          ) : null
        }
        setFiltersLocal={setFiltersLocal}
      />

      <DrawerAddCumulo
        key='drawer-add-cumulo'
        visible={drawerVisible === 'create'}
        onClose={_handleToggleCumulusDrawer}
        updateRunning={(running) => {
          setStatus((prev) => ({ ...prev, running }));
        }}
      />

      <DrawerUpdateCumulo
        onReloadData={() => {
          loadCampusImported({
            query: text || '',
            page: 1,
            limit: pageSize,
            filters: filtersLocal,
          });
        }}
        key='drawer-update-cumulo'
        visible={drawerVisible === 'update'}
        onClose={_handleToggleCumulusDrawer}
        updateRunning={(running) => {
          setStatus((prev) => ({ ...prev, running }));
        }}
        status={status}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  loadCampusImported: (parameters) => getCumulo(dispatch, parameters),
});

const mapStateToProps = (state) => ({
  userAuth: state.auth.user,
  listOfCumulo: state.campusImported.list,
  isLoading: state.campusImported.isLoadingList,
  pagination: state.campusImported.pagination,
});

export default connect(mapStateToProps, mapDispatchToProps)(Cumulus);
