import React, { memo, useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Card, Col, notification, Typography } from 'antd';
import { RideButton } from '@rimac-seguros/ride-system-components';

import IconReport from '../../../../../images/icon_document.svg';
import {
  reportCompanyDealers,
  reportCompanyPolizas,
  reportProvider,
} from '../../../../../services/company';
import { reportQuizzes } from '../../../../../services/survey';
import {
  downloadEmergencies,
  downloadRoadEmergencies,
} from '../../../../../services/dashboard';
import {
  LOADED_RPT_DEALERS_GENERAL,
  LOADED_RPT_POLIZAS,
  LOADED_RPT_PROVIDERS,
  LOADED_RPT_QUIZZES,
} from '../../../../../redux/actions/actionsType';

const { Title } = Typography;

const reports = [
  {
    title: 'Reporte de pólizas de empresas',
    key: 'companyPolizas',
    props: { className: 'full-width' },
  },
  {
    title: 'Reporte para comerciales',
    key: 'dealers',
    props: { className: 'full-width' },
  },
  {
    title: 'Reporte de encuestas',
    key: 'survey',
    props: { className: 'full-width' },
  },
  {
    title: 'Reporte de proveedores',
    key: 'providers',
    props: { className: 'full-width' },
  },
  {
    title: 'Descargar emergencias',
    key: 'emergencies',
    props: { className: 'full-width' },
  },
  {
    title: 'Descargar emergencias viales',
    key: 'road_emergencies',
    props: { className: 'full-width' },
  },
];

const Reports = () => {
  const dispatch = useDispatch();
  const selectorData = useSelector((state) => ({
    dealers: state.dealers,
    survey: state.survey,
    companyPolizas: state.poliza,
    providers: state.providerReport,
    emergencies: state.emergencies,
    road_emergencies: state.roadEmergency,
  }));
  const [keysDownloading, setKeysDownloading] = useState([]);
  const { exportFailed, isLoading, filePath } = keysDownloading.length
    ? selectorData[keysDownloading[0]] ?? {}
    : {};

  useEffect(() => {
    if (keysDownloading[0])
      if (exportFailed) {
        notification.error({
          message: 'Ha ocurrido un error al generar reporte.',
        });
        setKeysDownloading((prev) => prev.splice(1));
      } else if (!isLoading && filePath) {
        window.open(`${filePath}`, '_blank');
        notification.success({
          description: 'Reporte generado correctamente.',
          message: '¡Reporte generado!',
        });
        dispatch({
          type:
            keysDownloading[0] === 'companyPolizas'
              ? LOADED_RPT_POLIZAS
              : keysDownloading[0] === 'survey'
              ? LOADED_RPT_QUIZZES
              : keysDownloading[0] === 'providers'
              ? LOADED_RPT_PROVIDERS
              : LOADED_RPT_DEALERS_GENERAL,
          payload: null,
        });
        setKeysDownloading((prev) => prev.splice(1));
      }
  }, [filePath, exportFailed]);

  const _handleGenerateReport = useCallback(
    (key) => () => {
      setKeysDownloading((prev) => prev.concat(key));
      switch (key) {
        case 'companyPolizas':
          reportCompanyPolizas(dispatch, 'reportCompanyPolizas');
          break;
        case 'dealers':
          reportCompanyDealers(dispatch, 'reportCompanyDealersGeneral');
          break;
        case 'survey':
          reportQuizzes(dispatch, 'survey');
          break;
        case 'emergencies':
          downloadEmergencies(dispatch);
          break;
        case 'road_emergencies':
          downloadRoadEmergencies(dispatch);
          break;
        case 'providers':
          reportProvider(dispatch, 'reportProviders');
          break;
        default:
          break;
      }
    },
    [dispatch],
  );

  return (
    <div className='reports'>
      {reports.map(({ title, key, props }) => (
        <Card key={key} className='reports__card' {...props}>
          <img src={IconReport} alt='' aria-hidden width={'64px'} />
          <Title className={'mt-1 flex-1'} level={4}>
            {title}
          </Title>
          <RideButton
            className='mt-2'
            onClick={_handleGenerateReport(key)}
            loading={selectorData[key]?.isLoading}
            disabled={selectorData[key]?.isLoading}
            variant='text-primary'
            text='Generar reporte'
            size='medium'
          />
        </Card>
      ))}
    </div>
  );
};

export default memo(Reports);
