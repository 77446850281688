import { memo, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import { useScreen } from '../../../../lib/screen';
import useQueryParams from '../../../../hooks/useQueryParams';
import UserListContext from './context/UserListContext';

import Topbar from '../../../../common/ui/Topbar';
import Title from '../../../../common/Typograph/Title';
import UserList from './UserList';
import EmailsSent from './EmailsSent';
import Rols from './Rols';
import Incidents from './Incidents';
import MasiveUpload from './UserList/MasiveUpload';
import SupportChat from '../../../admin/SupportChat';
import RolEditing from './Rols/RolEditing';
import HeaderButtons from './HeaderButtons';
import Tabs from '../../../../common/Tabs';

const LIST_TABS_DEFAULT = [
  {
    key: 'upload_users',
    label: 'Lista de usuarios',
    create: 'Crear usuario',
    link: 'Carga masiva',
    children: <UserList />,
  },
  {
    key: 'mail_sended',
    label: 'Correos enviados',
    children: <EmailsSent />,
  },
  {
    key: 'incidents',
    label: 'Incidencias',
    children: <Incidents />,
  },
  {
    key: 'rols',
    label: 'Roles',
    children: <Rols />,
  },
];

const LIST_TABS_COMERCIAL = [
  {
    key: 'upload_users',
    label: 'Lista de usuarios',
    children: <UserList />,
  },
];

const Users = () => {
  const navigate = useNavigate();
  const { isMobileScreen } = useScreen();
  const { incidentId } = useQueryParams();
  const user = useSelector((state) => state.auth.user);
  const rol = user?.rol?.slug;
  const LIST_TABS = useMemo(
    () => (rol !== 'comercial' ? LIST_TABS_DEFAULT : LIST_TABS_COMERCIAL),
    [rol],
  );
  const [activeTab, setActiveTab] = useState(LIST_TABS[0]);
  const [isUploadView, setIsUploadView] = useState(false);
  const [rolEditing, setRolEditing] = useState(null);

  const handleAction = () => {
    navigate('/administracion/usuarios/formulario-usuario');
  };

  if (incidentId) {
    return (
      <SupportChat
        title={'Incidencias'}
        incidentId={incidentId}
        onBack={() => {
          navigate('/administracion/usuarios');
          setActiveTab(LIST_TABS[2]);
        }}
      />
    );
  }

  return (
    <UserListContext.Provider value={{ openEditRol: setRolEditing }}>
      <div id='checkSize' className='h100vh dashboard-UI'>
        <Topbar />
        {isUploadView && (
          <MasiveUpload
            onBack={() => {
              setIsUploadView((prev) => !prev);
            }}
            isMobileScreen={isMobileScreen}
          />
        )}
        {rolEditing && (
          <RolEditing
            onBack={() => setRolEditing((prev) => !prev)}
            rol={rolEditing}
          />
        )}
        <div
          className={`d_content ${
            isUploadView || rolEditing ? 'hidden' : 'block'
          }`}
        >
          <div className='flex justify-between items-center gap-2 mb-2'>
            <div className='flex justify-between items-center gap-2'>
              <Title className='header-container'>{activeTab?.label}</Title>
              {activeTab?.link && isMobileScreen && (
                <Button
                  type='link'
                  size='large'
                  onClick={() => {
                    setIsUploadView((prev) => !prev);
                  }}
                >
                  <UploadOutlined />
                  {activeTab?.link}
                </Button>
              )}
            </div>
            <HeaderButtons
              activeTab={activeTab}
              handleAction={handleAction}
              setIsUploadView={setIsUploadView}
            />
          </div>
          <Tabs
            activeKey={activeTab.key}
            onChange={(activeKey) =>
              setActiveTab(LIST_TABS.find((el) => el.key === activeKey))
            }
            items={LIST_TABS}
          />
          {activeTab?.create && isMobileScreen && (
            <div
              className={'fixed-bottom p-3 full-width'}
              style={{ background: '#EDEFFC' }}
            >
              <span
                className='full-width absolute'
                style={{
                  height: 12,
                  top: -12,
                  left: 0,
                  background:
                    'linear-gradient(to bottom, rgba(255,0,0,0), #EDEFFC)',
                }}
              ></span>
              <Button
                type='primary'
                size='large'
                className='full-width'
                onClick={() => {
                  handleAction();
                }}
              >
                {activeTab?.create}
              </Button>
            </div>
          )}
        </div>
      </div>
    </UserListContext.Provider>
  );
};

export default memo(Users);
