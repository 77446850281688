import { ConfigProvider, DatePicker as DatePickerAntd } from 'antd';
import {
  RideGlCalendarSolid,
  RideGlError,
} from '@rimac-seguros/ride-system-components';

import locale from 'antd/locale/es_ES';
import dayjs from 'dayjs';
import 'dayjs/locale/es';

dayjs.locale('es');

const { RangePicker: RangePickerAntd } = DatePickerAntd;

const DatePicker = ({
  placeholder,
  id,
  size = 'large',
  name,
  value,
  onChange,
  disabled = false,
}) => (
  <ConfigProvider locale={locale}>
    <div className='datePicker'>
      <DatePickerAntd
        placeholder={placeholder}
        id={id || name}
        size={size}
        name={name}
        value={value}
        onChange={onChange}
        suffixIcon={<RideGlCalendarSolid size='large' />}
        allowClear={{ clearIcon: <RideGlError size='large' /> }}
        format={'DD/MM/YYYY'}
        disabled={disabled}
      />
    </div>
  </ConfigProvider>
);

const RangePicker = ({
  label,
  id,
  size = 'large',
  name,
  value,
  onChange,
  disabledDate,
}) => (
  <ConfigProvider locale={locale}>
    <div className='rangePicker'>
      {label && <label htmlFor={id || name}>{label}</label>}
      <RangePickerAntd
        id={id || name}
        size={size}
        name={name}
        value={value}
        onChange={onChange}
        separator={null}
        suffixIcon={<RideGlCalendarSolid size='large' />}
        allowClear={{ clearIcon: <RideGlError size='large' /> }}
        format={'DD/MM/YYYY'}
        disabledDate={disabledDate}
      />
    </div>
  </ConfigProvider>
);

DatePicker.RangePicker = RangePicker;

export default DatePicker;
