export const getSocialConflict = (types, activities) => [
  {
    key: 'types',
    title: 'TIPOS DE CONFLICTO SOCIAL',
    checkbox: types
      .filter((type) => type.type && type.type.length > 0)
      .map((type, id) => ({ id, name: type.type, group: 'type' })),
  },
  {
    key: 'origin',
    title: 'POR ORIGEN DE CONFLICTO',
    checkbox: [
      { id: 'cmp', name: 'CMP', group: 'origin' },
      { id: 'defensoria', name: 'Defensoría', group: 'origin' },
    ],
  },
  {
    key: 'department',
    title: 'POR DEPARTAMENTO',
    label: 'Departamento',
    select: true,
  },
  {
    key: 'company',
    title: 'POR EMPRESAS INVOLUCRADAS',
    name: 'company',
    label: 'Nombre de la empresa',
    input: true,
  },
  {
    key: 'activities',
    title: 'POR ACTIVIDAD',
    checkbox: activities.map((activity, id) => ({
      id: `activities-${id}`,
      name: activity,
      value: activity,
      group: 'activities',
    })),
  },
  {
    key: 'states',
    title: 'POR ESTADO',
    name: 'types',
    checkbox: [
      { id: 'active', name: 'Activo', group: 'status' },
      { id: 'latent', name: 'Latente', group: 'status' },
      { id: 'retired', name: 'Retirado', group: 'status' },
      { id: 'resolved', name: 'Resuelto', group: 'status' },
    ],
  },
];
