import React, { memo } from 'react';

import { Button } from 'antd';

import { IconArrowRight } from '../../../../../../common/Icons';

const PolizaCard = ({ data, onAction, defeated = false }) => {
  return (
    <article className='card-user card-external-action'>
      <div className={'flex pos-justify'}>
        <div className='flex flex-row flex-justify-justify-between'>
          <span
            style={{ background: defeated ? '#FFC2C6' : '#FCDA6A', height: 20 }}
            className={'withpoint'}
          >
            <div
              style={{ background: defeated ? '#FA0A32' : '#FC9700' }}
              className={'withpoint-circle'}
            ></div>
            <span
              style={{
                fontFamily: 'BR Sonoma',
                fontWeight: 900,
                fontSize: 11,
                lineHeight: 16,
              }}
            >
              {data?.validate}
            </span>
          </span>
        </div>

        <div className='action'></div>
      </div>

      <div>
        <h4>ruc</h4>
        <p>{data?.ruc}</p>
      </div>

      <div>
        <h4>nombre o razón social</h4>
        <p className='text-uppercase'>{data?.business_name}</p>
      </div>

      <div>
        <h4>TIPO DE PÓLIZA</h4>
        <p>{data?.typePoliza}</p>
      </div>

      <div>
        <h4>no. póliza</h4>
        <p>{data?.noPoliza}</p>
      </div>

      <div>
        <h4>nivel de emrpesa</h4>
        <p className='text-uppercase'>{data?.segment}</p>
      </div>

      <div>
        <h4>GESTOR DE CUENTA</h4>
        <p>{data?.gestor}</p>
      </div>

      <div>
        <h4>fecha de inicio</h4>
        <p>{data?.startDate}</p>
      </div>

      <div>
        <h4>fecha de fin</h4>
        <p>{data?.endDate}</p>
      </div>

      <div>
        <h4>prima (u$$)</h4>
        <p>{data?.validate}</p>
      </div>

      <div>
        <Button
          style={{
            textAlign: 'start',
            border: 'none',
            borderRadius: 0,
            background: 'transparent',
            display: 'flex',
            alignItems: 'center',
            columnGap: 8,
          }}
          type='link'
          className='link'
          onClick={() => onAction(data)}
        >
          Renovar póliza <IconArrowRight />
        </Button>
      </div>
    </article>
  );
};

export default memo(PolizaCard);
