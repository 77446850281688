import React, { memo } from 'react';
import { Table } from 'antd';
import Skeleton from '../Skeleton';
import { secureRandom } from '../../lib/utils';

const SimpleTable = ({
  pagination,
  rowClassName,
  loading,
  className,
  ...props
}) => {
  return loading ? (
    <Skeleton type='table' className={className} totalItems={6} />
  ) : (
    <Table
      key={`table-${secureRandom()}`}
      className={`table_common ${className || ''}`}
      rowClassName={`row-style ${rowClassName}`}
      rowKey={({ id, key }) => id || key}
      pagination={
        pagination
          ? {
              className: 'table-pagination',
              ...(pagination || {}),
            }
          : false
      }
      scroll={{ x: 'max-content' }}
      {...props}
    />
  );
};

export default memo(SimpleTable);
