import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { notification, Skeleton } from 'antd';

import { useScreen } from '../../../../../lib/screen';
import useDebounce from '../../../../../hooks/useDebounce';
import {
  createCoursePaginated,
  getCourses,
  updateCoursePaginated,
} from '../../../../../services/courses';
import { getTypePoliza } from '../../../../../services/typePolicy';

import {
  CardSkeleton,
  IconEditTable,
  iconLeft,
  iconRight,
} from '../../PreventionService/Reports/utils';
import FilterSection from '../../PreventionService/components/FilterSection';
import Table from '../../../../../common/Table';
import CardCourse from './CardCourse';
import ModalCourse from './ModalCourse';
import Empty from '../../../../../common/Empty';

const CoursesList = ({
  loadList,
  getListTypePolizas,
  createCourse,
  update,
  pagination,
  list,
  isLoading = false,
  isLoadingCreate,
  typePolizas,
}) => {
  const { widthScreen } = useScreen();
  const [rows, setRows] = useState([]);
  const [textSearch, setTextSearch] = useState('');
  const debouncedTextSearch = useDebounce(textSearch);
  const [courseSelected, setCourseSelected] = useState(null);
  const [modalCourse, setModalCourse] = useState(false);

  const columns = [
    {
      title: 'Nombre',
      key: 'name',
      dataIndex: 'name',
      width: '240px',
      render: (text) => {
        return text.length > 32 ? text.substring(0, 32) + '...' : text;
      },
    },
    {
      title: 'Tipo',
      dataIndex: 'target',
      key: 'target',
      width:
        widthScreen >= 768 && widthScreen < 900
          ? '290px'
          : widthScreen < 1200
          ? '250px'
          : '476px',
      render: (__, record) => record.typePoliza?.name,
    },
    {
      title: 'Acciones',
      key: 'actions',
      width: 80,
      render: (__, item) => {
        return (
          <div className='edit-item' onClick={() => handleEdit(item)}>
            <IconEditTable color={'#7981B2'} />
          </div>
        );
      },
    },
  ];

  const handleEdit = (item) => {
    setCourseSelected(item);
    setModalCourse(true);
  };

  const handleAction = async (values) => {
    if (isNaN(values.target))
      values.target = typePolizas.find(
        (type) => type.name === values.target,
      ).id;

    await update(values, { ...pagination, query: textSearch });
    setModalCourse(false);
  };

  const handleFilter = (e) => setTextSearch(e.target.value.trim());

  const handleTableChange = (pag) =>
    loadList({
      page: pag.current,
      limit: pag.pageSize,
      query: textSearch,
    });

  const handleTableChangeMobile = (current) => {
    if (
      current === 0 ||
      Math.ceil(pagination.total / pagination.pageSize) + 1 === current
    )
      return;

    loadList({
      page: current,
      limit: pagination.pageSize,
    });
  };

  useEffect(() => {
    setRows(list);
  }, [list]);

  useEffect(() => {
    if (debouncedTextSearch.length >= 2)
      loadList({
        query: debouncedTextSearch,
        page: 1,
        limit: pagination.pageSize,
      });
    else
      loadList({
        query: '',
        page: 1,
        limit: pagination.pageSize,
      });
  }, [debouncedTextSearch]);

  useEffect(() => {
    getListTypePolizas();
  }, []);

  return (
    <>
      <FilterSection
        complete={true}
        text={'Filtrar cursos'}
        handleFilter={handleFilter}
      />

      {widthScreen > 767 ? (
        !isLoading && list.length === 0 ? (
          <Empty title='No se encontraron cursos' />
        ) : (
          <section className='show-data'>
            {isLoading ? (
              <Skeleton active title={false} paragraph={{ rows: 1 }} />
            ) : (
              <p className='info-total-data'>
                Se encontraron <span>{pagination.total}</span> cursos
              </p>
            )}

            <Table
              dataSource={rows}
              columns={columns}
              rowKey={(record) => record.id}
              loading={isLoading}
              locale={{ emptyText: 'No se encontraron cursos.' }}
              pagination={pagination}
              onChangeRows={setRows}
              onChange={handleTableChange}
            />
          </section>
        )
      ) : isLoading ? (
        [1, 2, 3].map((el) => <CardSkeleton key={el} />)
      ) : !isLoading && list.length === 0 ? (
        <Empty title='No se encontraron cursos' />
      ) : (
        <section className='content-mobile'>
          {rows.map((course) => (
            <CardCourse
              key={course.id}
              course={course}
              handleEdit={handleEdit}
            />
          ))}

          <div className='d_content__list__pag'>
            <div
              className={'d_content__list__pag__button'}
              onClick={() => handleTableChangeMobile(pagination.current - 1)}
            >
              {iconLeft({
                color: pagination.current === 1 ? '#A9AFD9' : '#4F4FFF',
              })}
            </div>

            <div className='d_content__list__pag__number color-red-1'>
              {pagination.current}
            </div>
            <div className='d_content__list__pag__sep'>/</div>
            <div className='d_content__list__pag__number color-gray-3'>
              {Math.ceil(pagination.total / pagination.pageSize)}
            </div>

            <div
              className={'d_content__list__pag__button'}
              onClick={() => handleTableChangeMobile(pagination.current + 1)}
            >
              {iconRight({
                color:
                  pagination.current ===
                  Math.ceil(pagination.total / pagination.pageSize)
                    ? '#A9AFD9'
                    : '#4F4FFF',
              })}
            </div>
          </div>
        </section>
      )}

      {modalCourse && (
        <ModalCourse
          course={courseSelected}
          visible={modalCourse}
          isLoadingCreate={isLoadingCreate}
          typePolizas={typePolizas}
          handleClose={() => {
            setModalCourse(false);
            setCourseSelected(null);
          }}
          handleAction={handleAction}
        />
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  loadList: (data) => getCourses(dispatch, data),
  createCourse: async (data) => {
    const created = await createCoursePaginated(dispatch, data);
    if (created) {
      notification.success({
        description: 'El curso se creo correctamente',
        message: 'Curso creado',
      });

      return true;
    } else {
      notification.warning({
        description: 'Ha ocurrido un error.',
        message: 'Crear curso!',
      });

      return false;
    }
  },
  update: async (data, pagination) => {
    const updated = await updateCoursePaginated(dispatch, data, pagination);
    if (updated) {
      notification.success({
        description: 'Se guardaron correctamente los cambios',
        message: 'Cambios guardados',
      });

      return true;
    } else {
      notification.warning({
        description: 'Ha ocurrido un error.',
        message: 'Actualizar curso!',
      });
    }
  },
  getListTypePolizas: () => getTypePoliza(dispatch),
});

const mapStateToProps = (state) => ({
  list: state.courses.courses || [],
  isLoading: state.courses.isLoadingList,
  pagination: state.courses.pagination,
  isLoadingCreate: state.courses.isLoadingCreate,
  typePolizas: state.typePoliza.list,
});

export default connect(mapStateToProps, mapDispatchToProps)(CoursesList);
