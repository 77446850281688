import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Modal } from 'antd';

import { useScreen } from '../lib/screen';
import { getLocations } from '../services/locations';

import Title from './Typograph/Title';
import { IconCloseCircle } from './Icons';

const ReminderModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobileScreen } = useScreen();
  const locations = useSelector((state) => state.locations.list || []);
  const [showModal, setShowModal] = useState(false);
  const [hasLocations, setHasLocations] = useState(false);

  const onOk = () => {
    setShowModal(false);
    navigate('/monitoreo/configuracion');
  };

  const favoriteLocations = useMemo(
    () => locations.filter((location) => Number(location.type) === 2),
    [locations],
  );

  const onCancel = () => setShowModal(false);

  const getLastExecution = () => {
    const lastExecution = localStorage.getItem('lastExecution');
    if (lastExecution) {
      const timeElapsed = new Date().getTime() - parseInt(lastExecution);
      if (timeElapsed > 24 * 60 * 60 * 1000) {
        setShowModal(true);
        updateLastExecution();
      }
    } else {
      setShowModal(true);
      updateLastExecution();
    }
  };

  const updateLastExecution = () => {
    const currentTimestamp = new Date().getTime();
    localStorage.setItem('lastExecution', currentTimestamp.toString());
  };

  useEffect(() => {
    getLocations(dispatch).then((locations) => {
      if (Array.isArray(locations)) setHasLocations(true);
    });
  }, []);

  useEffect(() => {
    if (favoriteLocations.length === 0 && hasLocations) getLastExecution();
  }, [favoriteLocations.length, hasLocations]);

  return (
    <Modal
      onOk={onOk}
      onCancel={onCancel}
      open={showModal}
      okText='Agregar ubicación favorita'
      cancelText='En otro momento'
      centered={true}
      destroyOnClose
      zIndex={2000}
      className={`modal-provider ${isMobileScreen ? 'modal-mobile' : ''}`}
      maskClosable={false}
    >
      <Title type='bold-28' style={{ textAlign: 'center' }}>
        Recordatorio
      </Title>
      <Divider className='mt-2 mb-3' />
      <div className='text-center' style={{ fontSize: '16px' }}>
        <p>
          <strong>Registra tus ubicaciones favoritas</strong> para tener una
          experiencia personalizada y recibir boletines sobre potenciales
          eventos naturales que afecten tus ubicaciones guardadas
        </p>
        <strong>¡Solo tú puedes ver las ubicaciones que registres!</strong>
      </div>
    </Modal>
  );
};

export default ReminderModal;
