import React from 'react';
import { Divider, Modal } from 'antd';

import { useScreen } from '../../../lib/screen';
import Title from '../../../common/Typograph/Title';
import SimpleTable from '../../../common/Table/SimpleTable';
import moment from 'moment';

const columnsTable = [
  {
    title: 'Empresa',
    dataIndex: 'company',
    key: 'company',
    width: 150,
  },
  {
    title: 'Servicio',
    dataIndex: 'service',
    key: 'service',
    width: 120,
  },
  {
    title: 'Precio',
    dataIndex: 'price',
    key: 'price',
    width: 120,
  },
  {
    title: 'Fecha de servicio',
    dataIndex: 'date_service',
    key: 'date_service',
    width: 120,
    render: (date) => {
      return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    },
  },
];

const ModalViewDetails = ({ visible, setVisible, item, isLoading }) => {
  const { isMobileScreen } = useScreen();

  const handleSave = () => {
    setVisible(null);
  };

  const handleCancel = () => {
    setVisible(null);
  };

  return (
    <Modal
      title=''
      onOk={handleSave}
      onCancel={handleCancel}
      open={visible}
      okText='Aceptar'
      cancelText='Cancelar'
      destroyOnClose
      confirmLoading={isLoading}
      cancelButtonProps={isLoading}
      className={`modal-provider modal-centered ${
        isMobileScreen ? 'modal-mobile' : ''
      }`}
      width={'80%'}
      maskClosable={false}
    >
      <Title type='bold-28' style={{ textAlign: 'center' }}>
        Detalles
      </Title>
      <Divider className='mt-2 mb-3' />
      <div className='flex request_service_items_details'>
        <SimpleTable
          className={'full-width'}
          columns={columnsTable}
          dataSource={item?.purchase_order_items || []}
        />
      </div>
    </Modal>
  );
};

export default ModalViewDetails;
