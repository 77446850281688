import React, { memo, useState, useEffect, Fragment } from 'react';
import { Button, Card, Skeleton } from 'antd';
import { UpOutlined, DownOutlined } from '@ant-design/icons';

import { generateColumns, metrics, processRowsExpand } from './utils';
import { useScreen } from '../../../../../lib/screen';
import { reportInidicatorsWeb } from '../../../../../services/requestServicesItems';

import TableCommon from '../../../../../common/Table';
import IndicatorChart from './IndicatorChart';
import { secureRandom } from '../../../../../lib/utils';

const CardMobile = ({ indicator }) => {
  const [showMore, setShowMore] = useState(false);

  return (
    <Card className='mb-3' styles={{ body: { padding: '0px 24px 40px 24px' } }}>
      <div className='d_content__card__body pt-2'>
        {(showMore ? generateColumns : generateColumns.slice(0, 7)).map(
          ({ dataIndex, title }) => (
            <div
              key={`${dataIndex}-${indicator[dataIndex]}`}
              className='card-detail__item'
            >
              <h4>{title}</h4>
              {!['unit', 'indicator'].includes(dataIndex) ? (
                <Fragment key={`row-${dataIndex}`}>
                  {indicator.annualygoal_key ? (
                    <p>
                      {indicator.annualygoal_key}: {indicator[dataIndex] || '0'}
                    </p>
                  ) : null}
                  {(indicator.expandData ?? []).map((internalIndicator) => (
                    <Fragment
                      key={`${dataIndex}-${internalIndicator[dataIndex]}`}
                    >
                      <h4>{internalIndicator.title}</h4>
                      {internalIndicator.annualygoal_key ? (
                        <p>
                          {internalIndicator.annualygoal_key}:{' '}
                          {internalIndicator[dataIndex] || '0'}
                        </p>
                      ) : null}
                    </Fragment>
                  ))}
                </Fragment>
              ) : (
                <p>{indicator[dataIndex]}</p>
              )}
            </div>
          ),
        )}
        <Button
          type='link'
          onClick={() => {
            setShowMore((prev) => !prev);
          }}
          icon={!showMore ? <DownOutlined /> : <UpOutlined />}
          style={{
            position: 'absolute',
            left: 24,
            bottom: 10,
          }}
        >
          {!showMore ? 'Ver más' : 'Ver menos'}
        </Button>
      </div>
    </Card>
  );
};

const Indicators = ({ showTable }) => {
  const { isMobileScreen } = useScreen();
  const [indicators, setIndicators] = useState({});
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [indicatorSelected, setIndicatorSelected] = useState('attendedClients');

  useEffect(() => {
    reportInidicatorsWeb().then((indicators) => {
      setIndicators(indicators);
      setTableData(processRowsExpand(indicators));
      setLoading(false);
    });
  }, []);

  const TableComponent = isMobileScreen ? (
    loading ? (
      [1, 2, 3].map((key) => (
        <Card className='mt-2' key={key}>
          <Skeleton />
        </Card>
      ))
    ) : (
      <div>
        {tableData.map((indicator) => (
          <CardMobile key={indicator.id} indicator={indicator} />
        ))}
      </div>
    )
  ) : (
    <TableCommon
      key={`table-${secureRandom()}`}
      loading={loading}
      columns={generateColumns}
      dataSource={tableData}
      expandRowByClick
      expandedRowRender={(row) => (
        <TableCommon
          key={`table-${secureRandom()}`}
          rowClassName={'rows-bg-expanded'}
          showHeader={false}
          columns={generateColumns}
          dataSource={row.expandData}
        />
      )}
      expandIcon={(props) =>
        props.record.key === 4 ? null : (
          <Button
            size='small'
            type='link'
            icon={props.expanded ? <UpOutlined /> : <DownOutlined />}
            style={{ color: '#7981B2' }}
            onClick={props.onExpand}
          />
        )
      }
      pagination={{ total: 1 }}
    />
  );

  return (
    <div className='mt-3' style={isMobileScreen ? { paddingBottom: 120 } : {}}>
      {showTable ? (
        TableComponent
      ) : (
        <div>
          <div className='flex pb-3' style={{ overflow: 'auto' }}>
            {metrics.map(({ graphKey, graphTitle }) => (
              <div
                key={`radio-${graphKey}`}
                className={`chip-button flex-1 mr-2 ${
                  indicatorSelected === graphKey ? 'chip-button-select' : ''
                }`}
              >
                <Button
                  className='full-width'
                  type='ghost'
                  size='small'
                  onClick={() => {
                    setIndicatorSelected(graphKey);
                  }}
                >
                  {graphTitle}
                </Button>
              </div>
            ))}
          </div>
          {loading ? (
            [1, 2, 3].map((key) => (
              <Card className='mt-2' key={key}>
                <Skeleton />
              </Card>
            ))
          ) : (
            <div>
              {indicators[indicatorSelected] && (
                <IndicatorChart
                  title={
                    metrics.find((el) => el.graphKey === indicatorSelected)
                      ?.indicator ?? ''
                  }
                  data={
                    indicatorSelected === 'visits'
                      ? [indicators[indicatorSelected]]
                      : indicators[indicatorSelected]
                  }
                  percent={indicatorSelected === 'advancePolizas'}
                />
              )}
              {(indicators[indicatorSelected] ?? []).map((indicator, index) => (
                <IndicatorChart
                  title={indicator.name}
                  data={indicator.months}
                  key={`${indicator.graphKey}-${index}`}
                  months={indicator.months?.length}
                  height={300}
                  percent={indicatorSelected === 'advancePolizas'}
                />
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default memo(Indicators);
