import React from 'react';

const Logo = () => {
  return (
    <svg
      width='74'
      height='36'
      viewBox='0 0 74 36'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='map-logo'
    >
      <path
        d='M8.6779 24.1317H2.69584L0.012207 35.8024H3.06423L4.07425 31.4112H5.65028L8.36806 35.8024H11.7104L8.66326
        31.1551C11.3664 30.4525 12.2862 29.0034 12.4569 27.4543C12.6277 25.9052 11.7641 24.1293 8.67546 24.1293L8.6779
        24.1317ZM9.4098 27.6227C9.28294 28.7644 8.21193 29.4377 6.72617 29.4377H4.53047L5.34532 25.9174H7.66056C8.88771
        25.9174 9.52691 26.5176 9.40736 27.6227H9.4098ZM17.2167 24.1342H20.2883L19.5735 27.2323L17.5973 35.7975H14.4916L17.2167
        24.1342ZM43.1894 23.4877L43.2114 35.7951H40.2838L40.3155 27.0128L34.0968 35.7951H31.2595L28.6856 26.964L24.565
        35.7951H21.1617L27.2486 22.9851C27.6902 22.1581 28.1172 21.7898 28.9564 21.7849C29.5127 21.7849 30.1177 22.1045 30.3934
        23.0754L33.0502 32.6847L39.9617 22.8632C40.5497 22.0313 41.045 21.7776 41.7281 21.7947C42.46 21.8142 43.1919 22.2948
        43.1919 23.4877H43.1894ZM63.8998 30.233C63.6924 32.1089 65.1196 33.5873 67.6178 33.6239C68.8455 33.6581 70.0706 33.4933
        71.2456 33.136L70.6527 35.6999C69.6419 35.8874 68.6166 35.9854 67.5885 35.9927C62.675 35.9927 60.3915 33.5531 60.7575
        30.1744C61.1722 26.359 64.5389 23.7926 69.8428 23.7926C70.9691 23.8094 72.0907 23.9419 73.19 24.1878L72.6533
        26.5371C71.6149 26.2871 70.5497 26.1658 69.4817 26.176C66.3882 26.1516 64.1779 27.6959 63.9022 30.233H63.8998ZM51.2306
        31.4356L54.0874 27.4031L54.9803 31.4356H51.2306ZM56.0245 35.7975H58.9911L56.1514 25.6369C55.7805 24.4049 55.0511 24.0805
        54.2923 24.1464C53.8166 24.1878 53.3165 24.3903 52.8139 25.11L45.4461 35.7999H48.4689L49.9668 33.4092H55.4585L56.0294
        35.8073L56.0245 35.7975ZM61.243 9.69464C60.6208 9.90687 33.1673 17.5011 32.7647 17.5962C32.492 17.6812 32.2017 17.6925
        31.9232 17.6291C31.6448 17.5657 31.388 17.4298 31.1789 17.2352C30.447 16.5692 30.7471 15.6446 30.9545 15.1762C31.1985
        14.6249 36.4852 3.50313 36.4852 3.50313L15.3797 12.3513C14.4989 12.6587 14.1989 11.9341 14.1989 11.9341C14.1989 11.9341
        13.8305 11.1315 14.7917 10.6778L37.9685 0.26833C38.3491 0.0975639 39.0639 -0.23909 39.6202 0.26833C40.1764 0.77575
        39.9593 1.54176 39.7885 2.01503C39.7056 2.23946 35.9095 12.7831 35.9095 12.7831C35.9095 12.7831 58.5032 3.08842 59.2351
        2.81275C60.6672 2.26874 61.97 2.59075 62.8946 3.66902C63.307 4.16244 63.5944 4.74799 63.7324 5.37606C63.8705 6.00413
        63.8551 6.65622 63.6875 7.27707C63.3679 8.36754 62.6628 9.20185 61.2478 9.68488L61.243 9.69464Z'
        fill='#FFFFFF'
      />
    </svg>
  );
};

export default Logo;
