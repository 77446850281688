import React from 'react';

const CODE_EQUIVALENCE = {
  '01': { label: 'Tránsito Normal', color: 'rgb(2, 224, 55)' },
  '02': { label: 'Tránsito Restringido', color: 'rgb(255, 255, 0)' },
  '03': { label: 'Tránsito Interrumpido', color: 'rgb(255, 0, 0)' },
  '04': { label: 'Por confirmar', color: 'rgb(204, 204, 204)' },
};

const formatUbigeo = (ubigeo) => {
  if (!ubigeo) {
    return '';
  }

  return ubigeo
    .split('/')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('/');
};

const EmergencyInfo = ({ emergency }) => {
  const { id, ubigeo, condicionTransitoCodigo, distance } = emergency;
  const condition = condicionTransitoCodigo;
  const conditionColor = CODE_EQUIVALENCE[condition]?.color;
  const conditionLabel = CODE_EQUIVALENCE[condition]?.label ?? '';

  return (
    <div className='emergency-info-container' key={id}>
      <span>
        - <strong>{formatUbigeo(ubigeo)}</strong> a {Math.round(distance)} mt.
      </span>
      <div className='emergency-info'>
        <div
          className='emergency-info-dot'
          style={{ background: conditionColor }}
        />
        <span>{conditionLabel}</span>
      </div>
    </div>
  );
};

export default EmergencyInfo;
