import { Modal, Radio, notification, Button } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { memo, useCallback, useState } from 'react';

const checks = [
  {
    value: 'Recibo demasiados correos de RIMAC',
    id: 1,
  },
  {
    value: 'La información que recibo no aporta valor',
    id: 2,
  },
  {
    value: 'La información no esta relacionada al giro de mi empresa',
    id: 3,
  },
  {
    value: 'La información no está relacionada a mis ubicaciones de interés',
    id: 4,
  },
  {
    value: 'Otros (especifique)',
    id: 5,
  },
];

const ModalSubscription = ({ visible, handleClose, onAccept }) => {
  const [reasonValue, setReasonValue] = useState('');
  const [comment, setComment] = useState('');
  const [otherActive, setOtherActive] = useState(false);

  const handleTogleReason = useCallback(({ target: { value } }) => {
    setComment('');
    setOtherActive(false);
    value === 5 && setOtherActive(true);

    setReasonValue(value);
  }, []);

  const handleSubmit = () => {
    if (otherActive)
      comment.trim().length > 5
        ? onAccept(comment)
        : notification.warning({
            message: 'Configuración',
            description: 'Agregue un comentario para poder desactivar.',
          });
    else onAccept(checks.find((el) => el.id == reasonValue)?.value);
    setOtherActive(false);
    setComment('');
    setReasonValue('');
  };

  return (
    <Modal
      title={false}
      open={visible}
      footer={false}
      className='modal-subscription'
      onCancel={handleClose}
    >
      <h2 className='title'>Desactivar alertas</h2>

      <p className='info'>
        Recuerda que antes de desactivar todas las alertas del Centro de
        Monitoreo y Prevención, puedes configurar solo las que sean de tu
        interés por lugar o tipo de evento. Si aún así deseas desactivarlas,
        porfavor indícanos el motivo por el que lo haces:
      </p>
      <Radio.Group
        className='options'
        value={reasonValue}
        onChange={handleTogleReason}
      >
        {checks.map((check) => (
          <Radio className='option' key={check.id} value={check.id}>
            {check.value}
          </Radio>
        ))}
      </Radio.Group>

      {otherActive && (
        <div className='comment'>
          <p className='info'>
            <span>*</span>Corta descripción
          </p>
          <TextArea
            className='text-comment'
            cols={5}
            onChange={({ target: { value } }) => {
              setComment(value);
            }}
          >
            {comment}
          </TextArea>
        </div>
      )}

      <p className='info'>
        ¿Estas seguro que deseas dejar de recibir información de RIMAC?
      </p>

      <div className='actions'>
        <Button type='link' size='large' onClick={handleClose}>
          Cancelar
        </Button>
        <Button
          type='primary'
          size='large'
          disabled={
            otherActive
              ? reasonValue !== '' && comment.trim().length < 5
              : reasonValue === ''
          }
          onClick={handleSubmit}
        >
          Si, confirmar
        </Button>
      </div>
    </Modal>
  );
};

export default memo(ModalSubscription);
