import React, { useState, useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Select } from 'antd';

import { getRiskRainService } from '../../../services/requestServices';
import { getPolizasRainRisk } from '../../../services/polizas';

const SelectPolizas = ({ onNext }) => {
  const { companyId, steps } = useSelector((state) => ({
    companyId: state?.auth?.user?.company?.id,
    steps: state.rainForm.steps,
  }));
  const dispatch = useDispatch();
  const [polizas, setPolizas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedValue, setSelectedValue] = useState();

  useEffect(() => {
    const fetchPolizas = async () => {
      setLoading(true);
      const res = await getPolizasRainRisk(companyId);
      setPolizas(
        res.map((e) => ({
          value: e.id,
          label: `${e?.typePoliza?.name} ${e.no_poliza}`,
        })) || [],
      );
      setLoading(false);
    };
    if (companyId) fetchPolizas();
  }, [companyId]);

  const _handleNext = () => {
    getRiskRainService(selectedValue, dispatch).then((res) => {
      if (res.payload.state > 1) {
        onNext(5);
      } else if (!res.lastItemData) {
        onNext();
      } else {
        const currentStep = steps[res.lastItemData.key];
        onNext(currentStep ? currentStep + 1 : null);
      }
    });
  };

  const handleChange = (obj) => {
    setSelectedValue(obj.value);
  };

  return (
    <div
      className='flex gap-2 align-items-center'
      style={{
        flexDirection: 'column',
        maxWidth: '400px',
        width: '100%',
        alignSelf: 'center',
      }}
    >
      <strong>Selecciona una poliza:</strong>
      <Select
        labelInValue
        value={selectedValue}
        name='poliza'
        onChange={handleChange}
        loading={loading}
        placeholder='Selecciona una poliza'
      >
        {polizas.map((poliza) => (
          <Select.Option key={poliza.value} value={poliza.value}>
            {poliza.label}
          </Select.Option>
        ))}
      </Select>
      <Button
        type='primary'
        className='btn-back'
        disabled={!selectedValue}
        onClick={_handleNext}
      >
        Iniciar evaluación
      </Button>
    </div>
  );
};

export default memo(SelectPolizas);
