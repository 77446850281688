import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import FooterPortal from '../../../common/FooterPortal';
import HeaderPortal from '../../../common/HeaderPortal';
import { removeAccents } from '../../../lib/utils';
import { useScreen } from '../../../lib/screen';
import List from '../components/List';
import FiltersSelect from '../components/FiltersSelect';
import { getSearchArticles } from '../../../services/articles';

const filters = ['Todos', 'Monitoreo', 'Prevención', 'Riesgos'];

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const SearchResults = () => {
  let query = useQuery();
  const [showSearcher, setShowSearcher] = useState(0);
  const { widthScreen } = useScreen();
  const [scrollY, setScrollY] = useState();
  const [results, setResults] = useState([]);
  const [reRender, setReRender] = useState(false);
  const inputRef = useRef(null);
  const resultsRef = useRef(null);

  useEffect(() => {
    getSearchArticles({ query: query.get('p') ?? '' }).then(({ list }) => {
      setResults(formatRes(list));
    });
  }, []);

  useLayoutEffect(() => {
    function updateScroll() {
      if (inputRef.current) setScrollY(window.scrollY);
    }
    window.addEventListener('scroll', updateScroll);
    updateScroll();

    return () => window.removeEventListener('scroll', updateScroll);
  }, []);

  const formatRes = (data) => {
    return data.map((item) => ({
      ...item,
      title: formatText(item.title ?? '', query.get('p') ?? ''),
      subtitle: formatText(item.subtitle ?? '', query.get('p') ?? ''),
    }));
  };

  const formatText = (fullText, pattern) => {
    pattern = pattern.trim();
    const textNoAccents = removeAccents(fullText ?? '');
    const index = textNoAccents.search(new RegExp(pattern, 'gi'));

    if (index !== -1)
      return (
        fullText.substring(0, index) +
        `<span class='results__card--bold'>${fullText.substring(
          index,
          index + pattern.length,
        )}</span>` +
        fullText.substring(index + pattern.length, fullText.length)
      );
    else return fullText;
  };

  const handleChangeFilter = (index) => {
    if (index >= 1)
      getSearchArticles({ query: query.get('p') ?? '', category: index }).then(
        ({ list }) => {
          setResults(formatRes(list));
        },
      );
    else
      getSearchArticles({ query: query.get('p') ?? '' }).then(({ list }) => {
        setResults(formatRes(list));
      });

    setReRender((s) => !s);
  };

  const scrollToTop = () => {
    let top = 180;
    if (widthScreen > 900) top = 320;
    else if (widthScreen > 600) top = 150;

    window.scrollTo({
      top: top,
      behavior: 'smooth',
    });
  };

  const isInputOnTop = scrollY >= 120 && widthScreen <= 900;

  return (
    <div className='portal-web'>
      <HeaderPortal secondary showSearcherParam={showSearcher} />
      <div className='portal-search-results'>
        <div
          className={`section back-color-gray-7 ${
            isInputOnTop ? 'main--on-top' : ''
          }`}
        >
          <div className='main'>
            <div className='main__title'>
              Resultados de{' '}
              <span className='main__title--strong'>búsqueda</span>
            </div>
            <div className='main__description'>
              Estos son los resultados que más coinciden con tu búsqueda
            </div>
            <div
              className='main__input'
              ref={inputRef}
              onClick={() => setShowSearcher((s) => s + 1)}
            >
              <div className='main__input--text'>{query.get('p')}</div>
              <div className='main__input--icon'>
                <i className='fas fa-search'></i>
              </div>
            </div>
          </div>
        </div>
        <div
          className='section'
          style={{ marginTop: isInputOnTop ? 320 : undefined }}
        >
          <div className='results'>
            <div className='results__tabs' ref={resultsRef}>
              <div className='results__total'>{results.length} resultados</div>
              <FiltersSelect
                data={filters}
                text='Ordenar por:'
                onChange={handleChangeFilter}
              />
            </div>
            <div className='results__cards'>
              {results && (
                <List
                  data={results}
                  render={(item, key) => (
                    <div className={'results__card'} key={key}>
                      <a href={`/articulos/${item.url}`}>
                        <span>{filters[item.category]}</span>
                        <h5
                          dangerouslySetInnerHTML={{ __html: item.title }}
                        ></h5>
                        <p
                          dangerouslySetInnerHTML={{ __html: item.subtitle }}
                        ></p>
                      </a>
                    </div>
                  )}
                  onChange={scrollToTop}
                  itemsPerPage={6}
                  reRender={reRender}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <FooterPortal />
    </div>
  );
};

export default SearchResults;
