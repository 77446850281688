import React, { useEffect } from 'react';
import { ErrorIcon } from '../../common/Icons';
import { Button } from 'antd';
import { useNavigate, useLocation } from 'react-router-dom';
import { RideButton } from '@rimac-seguros/ride-system-components';

const NotFound = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    console.log('Navigated to NotFound from:', location.pathname);
    navigate('/404', { replace: true });
  }, [navigate]);

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <section className='not-found-section'>
      <div>
        <ErrorIcon />
        <h1>Error 404</h1>
        <p>La página que estás buscando no se ha encontrado o ya no existe.</p>
        <RideButton text='Volver' onClick={handleBack} size='large' />
      </div>
    </section>
  );
};

export default NotFound;
