import React from 'react';
import { Carousel, Col, Row } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { eventsKeys } from './utils';
import Title from '../../../Typograph/Title';

const ForecastInfo = ({ slides }) => {
  const setting = {
    dots: false,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: true,
    nextArrow: <RightOutlined />,
    prevArrow: <LeftOutlined />,
  };

  return (
    <Carousel arrows effect='fade' {...setting}>
      {slides.map(({ currentDayHours, currentData }, index) => (
        <div key={index} className='forecast-content'>
          <Row gutter={16}>
            {currentDayHours.map((hour, idx) => (
              <Col key={idx} className='hour' span={4}>
                <div>{hour}:00</div>
              </Col>
            ))}
          </Row>
          <Title className='forecast-title'>
            {eventsKeys['temp']}
            <span>°C</span>
          </Title>
          <Row gutter={16} style={{ minHeight: '18px' }}>
            {currentDayHours.map((hour, idx) => {
              let tempValue = currentData[hour]?.find(
                (el) => el.type === 'temp',
              )?.GRAY_INDEX;
              tempValue = tempValue ? Math.round(tempValue, 10) : '-';
              return (
                <Col key={idx} className='forecast-value' span={4}>
                  {tempValue !== '-' ? `${tempValue}°` : '-'}
                </Col>
              );
            })}
          </Row>
          <Title className='forecast-title'>
            {eventsKeys['pp']} <span>mm</span>
          </Title>
          <Row gutter={16} style={{ minHeight: '18px' }}>
            {currentDayHours.map((hour, idx) => (
              <Col key={idx} className='forecast-value' span={4}>
                {currentData[hour]
                  ? (
                      currentData[hour].find((el) => el.type === 'pp')
                        ?.GRAY_INDEX || 0
                    ).toFixed(2)
                  : '-'}
              </Col>
            ))}
          </Row>
          <Title className='forecast-title'>
            {eventsKeys['ws']}
            <span>km/h</span>
          </Title>
          <Row gutter={16} style={{ minHeight: '18px' }}>
            {currentDayHours.map((hour, idx) => {
              let wsValue = currentData[hour]?.find(
                (el) => el.type === 'ws',
              )?.GRAY_INDEX;
              wsValue = wsValue ? Math.round(wsValue * 10) / 10 : '-';
              return (
                <Col key={idx} className='forecast-value' span={4}>
                  {wsValue}
                </Col>
              );
            })}
          </Row>
        </div>
      ))}
    </Carousel>
  );
};

export default ForecastInfo;
