import React from 'react';
import { Result } from 'antd';

const LastStep = () => {
  return (
    <Result
      status='success'
      title='¡Gracias por completar la autoevaluación!'
      subTitle='Nos pondremos en contacto para presentarle el informe.'
    />
  );
};

export default LastStep;
