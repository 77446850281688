import React from 'react';
import { Card, Skeleton } from 'antd';
import Chart from 'react-apexcharts';
import Title from '../../../../../../common/Typograph/Title';
import { useScreen } from '../../../../../../lib/screen';

const DonutWidget = ({
  title,
  data,
  total = 0,
  loading,
  colors = ['#B041FA', '#6CBDFF', '#FF3D5D', '#30DB9D'],
}) => {
  const { isMobileScreen } = useScreen();
  if (loading)
    return (
      <Card>
        <Skeleton />
      </Card>
    );

  const series = Object.values(data ?? {});
  const labels = Object.keys(data ?? {});

  return (
    <Card style={{ height: '100%' }}>
      {title && <Title type='bold-18'>{title}</Title>}
      <div
        className={`flex full-width donut-chart ${
          isMobileScreen ? 'flex-col mt-2' : 'justify-between'
        }`}
      >
        {series.filter(Boolean).length > 0 ? (
          <Chart
            type='donut'
            series={series}
            width={225}
            style={{ display: 'grid', placeContent: 'center' }}
            options={{
              labels,
              colors,
              chartOptions: {
                labels,
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                width: 0,
              },
              legend: {
                show: false,
                // fontFamily: 'BR Sonoma',
                // fontWeight: 400,
                // formatter: (seriesName, opts) => `
                //  <div style="display:flex;justify-content:justify-between; margin-top: -14px; height: 20px">
                //  <span style="margin-left:8px">${seriesName}</span><span>${opts.w.config.series[opts.seriesIndex]}</span>
                //  </div>`,
                // position: isMobileScreen ? 'bottom' : 'right',
                // showForSingleSeries: true,
                // labels: {
                //     colors: ['#B041FA', '#6CBDFF', '#FF3D5D', '#30DB9D'],
                //     useSeriesColors: false
                // },
                // markers: {
                //     width: 6,
                //     height: 6,
                //     strokeColor: '#fff',
                //     radius: '50%',
                //     offsetX: -8,
                //     offsetY: 21,
                // },
                // itemMargin: {
                //     horizontal: 0,
                //     vertical: 0
                // },
              },
              plotOptions: {
                pie: {
                  size: 132,
                  donut: {
                    labels: {
                      show: true,
                      total: {
                        show: true,
                        label: String(
                          new Intl.NumberFormat('en-IN', {
                            maximumSignificantDigits: 3,
                          }).format(total),
                        ),
                        color: '#141938',
                        fontSize: '28px',
                        fontWeight: 900,
                      },
                      name: {
                        show: true,
                        offsetY: 9,
                      },
                      value: {
                        show: false,
                      },
                    },
                    size: '80%',
                  },
                },
              },
            }}
          />
        ) : null}
        <div
          className='flex flex-col full-width'
          style={
            !isMobileScreen && series.filter(Boolean).length
              ? { marginLeft: -40 }
              : {}
          }
        >
          {labels.map((label, index) => (
            <div
              key={label}
              style={
                index !== labels.length - 1
                  ? { borderBottom: '1px solid #D7DBF5' }
                  : null
              }
              className='flex justify-between pb-2 pt-2'
            >
              <span className='flex items-center'>
                <div
                  style={{
                    width: 6,
                    height: 6,
                    background: colors[index],
                    borderRadius: '50%',
                    marginRight: 12,
                  }}
                ></div>
                {label}
              </span>
              <span>{series[index]}</span>
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
};

export default DonutWidget;
