import React, { memo, useState } from 'react';
import { Button, Switch } from 'antd';

import {
  IconCloseWithoutCircle,
  IconDots,
} from '../../../ui/PreventionService/Reports/utils';
import CompliancePolizas from './CompliancePolizas';

const BusinessItem = ({
  data,
  onEdit,
  onViewPolizas,
  onChangeState,
  changeState,
  userAuth,
}) => {
  const [opened, setOpened] = useState(false);
  const rol = userAuth?.rol?.slug;

  return (
    <>
      <article className='card-user card-external-action'>
        <div className={'flex pos-end'}>
          <div className='action'>
            <Button className='button-popover' onClick={() => setOpened(true)}>
              <IconDots />
            </Button>
          </div>
        </div>

        <div>
          <h4>RUC</h4>
          <p>{data?.ruc}</p>
        </div>

        <div>
          <h4>NOMBRE O RAZÓN SOCIAL</h4>
          <p className='text-uppercase'>{data?.business_name}</p>
        </div>

        <div>
          <h4>SEGMENTO COMERCIAL</h4>
          <p>{`${data?.businessSegment?.name || ''}`}</p>
        </div>

        <div>
          <h4>ADMIN EMPRESA PRINCIPAL</h4>
          <p>{`${data?.principalAdmin?.name} ${data?.principalAdmin?.last_name1} ${data?.principalAdmin?.last_name2}`}</p>
        </div>

        <div>
          <h4>GESTOR DE CUENTA</h4>
          <p>
            {data?.companiesUsers.length > 0
              ? `${data?.companiesUsers[0].name} ${data?.companiesUsers[0].last_name1} ${data?.companiesUsers[0].last_name2}`
              : ''}
          </p>
        </div>

        <div>
          <h4>AVANCE DE PÓLIZAS</h4>
          <CompliancePolizas companyId={data?.id} />
        </div>

        <div className='justify-between'>
          <h4>ESTADO</h4>
          <Switch
            className='switch-mobile'
            checked={data?.state === 1}
            onChange={() => onChangeState(data, data?.state === 1 ? 2 : 1)}
            disabled={changeState}
          />
        </div>

        <div>
          <h4>USUARIO</h4>
          <p>{data?.users ? data?.users.length : 0}</p>
        </div>
      </article>

      {opened && (
        <div className='action-complete'>
          <div className='content'>
            <div className='title'>
              <h3>Más opciones</h3>
              <div className='icon' onClick={() => setOpened(false)}>
                <IconCloseWithoutCircle />
              </div>
            </div>

            <div className='buttons'>
              <div className='button' onClick={() => onViewPolizas(data)}>
                <span>Ver pólizas</span>
              </div>
              {rol !== 'comercial' && (
                <div className='button' onClick={() => onEdit(data)}>
                  <span>Editar</span>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(BusinessItem);
