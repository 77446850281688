import React from 'react';
import moment from 'moment';
import DonutWidget from './DonutWidget';

const colorsNewsletters = [
  '#0369AF',
  '#6CBDFF',
  '#30DB9D',
  '#FFCA61',
  '#FF3D5D',
];

const eventTypes = {
  1: 'Hidrometeorológicos',
  2: 'Oceanográficos',
  3: 'Geológico',
  4: 'Sociales',
  5: 'Tecnológicos',
};

const Newsletter = ({ data, isLoading }) => {
  return (
    <div className='card pdf-resumen__information'>
      <p className='title'>Boletines</p>
      <DonutWidget
        total={data?.statistics?.total ?? 0}
        loading={isLoading}
        data={data?.statistics?.data?.reduce((acc, event) => {
          acc[eventTypes[event?.type]] = event.count ?? 0;

          return acc;
        }, {})}
        colors={colorsNewsletters}
      />

      <div className='info mt-2'>
        <ul className='info__header'>
          <li>Evento</li>
          <li>Fecha y Hora</li>
          <li>Tipo</li>
        </ul>

        <div className='info__body'>
          {data?.events?.map((ev) => (
            <ul key={ev.id} className='row'>
              <li>{ev?.detected_condition}</li>
              <li>{moment(ev?.createdAt).format('DD/MM/YYYY - HH:mm')}</li>
              <li>
                <span className='alert'>{eventTypes[ev?.type]}</span>
              </li>
            </ul>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
