import { randomColor } from '../../lib/utils';

import {
  LOADED_CONFLICT,
  LOAD_CONFLICT_LOADING,
  CLEAR_CONFLICT,
  CONFLICT_CHANGE_FILTERS,
  OPEN_FORM_CONFLICT,
  LOADING_CREATE_CONFLICT,
  LOADING_BULK_CONFLICTS,
  CONFLICTS_PROGRESS,
  UPDATE_CONFLICT_STORE,
  UPDATE_LIST_HISTORY_CONFLICT,
  UPDATE_PAGINATION_HISTORY_CONFLICTS,
} from '../actions/actionsType';

const initialState = {
  list: [],
  types: [],
  activities: [],
  filter: {
    states: [],
  },
  openedForm: false,
  isLoadingList: false,
  isLoadingCreate: false,
  isLoadingBulk: false,
  conflictsProgress: 0,
  listHistory: [],
  paginationHistory: {
    total: 0,
    limit: 5,
    pageSize: 5,
    current: 1,
  },
};

const conflict = (state, action) => {
  if (!state) {
    state = initialState;
  }

  switch (action.type) {
    case LOAD_CONFLICT_LOADING:
      return {
        ...state,
        isLoadingList: action.payload,
      };
    case LOADED_CONFLICT:
      let colors = [];
      let activities = [];
      if (action.payload && action.payload.length > 0) {
        Object.keys(
          action.payload.reduce((grouped, item) => {
            (grouped[item.type] = grouped[item.type] || []).push(item);
            return grouped;
          }, {}),
        )
          .sort()
          .forEach((type) => {
            const color = randomColor(colors.map((c) => c.color));
            colors.push({
              color,
              type,
            });
          });
        Object.keys(
          action.payload.reduce((grouped, item) => {
            if (item.activity && item.activity !== 'null') {
              (grouped[item.activity] = grouped[item.activity] || []).push(
                item,
              );
            }
            return grouped;
          }, {}),
        )
          .sort()
          .forEach((activity) => {
            if (activity && activity !== 'null') {
              activities.push(activity);
            }
          });
      }

      return {
        ...state,
        list: action.payload,
        types: colors,
        activities,
        isLoadingList: false,
      };
    case CLEAR_CONFLICT:
      return {
        ...state,
        list: [],
        filter: {},
      };
    case OPEN_FORM_CONFLICT:
      return {
        ...state,
        openedForm: action.payload,
      };
    case CONFLICT_CHANGE_FILTERS:
      return {
        ...state,
        filter: action.payload,
      };
    case LOADING_CREATE_CONFLICT:
      return {
        ...state,
        isLoadingCreate: action.payload,
      };
    case LOADING_BULK_CONFLICTS:
      return {
        ...state,
        isLoadingBulk: action.payload,
      };
    case CONFLICTS_PROGRESS:
      return {
        ...state,
        conflictsProgress: action.payload,
      };
    case UPDATE_LIST_HISTORY_CONFLICT:
      return {
        ...state,
        listHistory: state.listHistory.concat(action.payload),
      };
    case UPDATE_PAGINATION_HISTORY_CONFLICTS:
      return {
        ...state,
        paginationHistory: {
          ...state.paginationHistory,
          ...action.payload,
        },
      };
    case UPDATE_CONFLICT_STORE:
      let toUpdate = {};
      for (let k in action.payload) {
        toUpdate[k] = action.payload[k];
      }

      return {
        ...state,
        ...toUpdate,
      };
    default:
      return state;
  }
};

export default conflict;
