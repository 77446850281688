import React, { memo, useState } from 'react';
import { Button } from 'antd';
import {
  RideButton,
  RideGlViewOutline,
} from '@rimac-seguros/ride-system-components';

import { useScreen } from '../../../../lib/screen';

import Topbar from '../../../../common/ui/Topbar';
import Title from '../../../../common/Typograph/Title';
import { reportIndicators } from '../../../../services/indicators';
import ResumeDashboard from './Dashboards';
import Indicators from './Indicators';
import Reports from './Reports';
import Tabs from '../../../../common/Tabs';

const getTabs = (isIndicatorsTable) => [
  {
    key: 'resumen',
    label: 'Resumen',
    children: <ResumeDashboard />,
  },
  {
    key: 'indicators',
    label: 'Indicadores',
    children: <Indicators showTable={isIndicatorsTable} />,
    create: 'Descargar reporte',
    link: 'Ver gráficos',
  },
  {
    key: 'reports',
    label: 'Reportes',
    children: <Reports />,
  },
];

const Resumen = () => {
  const { isMobileScreen } = useScreen();
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(getTabs()[0]);
  const [isIndicatorsTable, setIsIndicatorsTable] = useState(true);

  const handleAction = () => {
    setIsLoading(true);
    reportIndicators()
      .then((url) => {
        const a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        a.click();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div id='checkSize' className='h100vh dashboard-UI'>
      <Topbar />
      <div className='d_content'>
        <div className='flex justify-between items-center mb-2'>
          <div className='flex justify-between items-center'>
            <Title className='header-container'>{activeTab?.label}</Title>
            {activeTab?.link && isMobileScreen ? (
              <RideButton
                text={
                  activeTab?.link && isIndicatorsTable
                    ? 'Ver gráficos'
                    : 'Ver tabla'
                }
                size='large'
                onClick={() => {
                  setIsIndicatorsTable((prev) => !prev);
                }}
                glyph={<RideGlViewOutline />}
                glyphPosition='left'
              />
            ) : null}
          </div>
          {!isMobileScreen ? (
            <div className='flex gap-4'>
              {activeTab?.link && (
                <RideButton
                  text={
                    activeTab?.link && isIndicatorsTable
                      ? 'Ver gráficos'
                      : 'Ver tabla'
                  }
                  variant='text-primary'
                  onClick={() => {
                    setIsIndicatorsTable((prev) => !prev);
                  }}
                  glyph={<RideGlViewOutline />}
                  glyphPosition='left'
                />
              )}
              {activeTab?.create && (
                <RideButton
                  text={activeTab?.create}
                  onClick={handleAction}
                  loading={isLoading}
                  disabled={isLoading}
                />
              )}
            </div>
          ) : null}
        </div>
        <Tabs
          activeKey={activeTab.key}
          onChange={(activeKey) =>
            setActiveTab(
              getTabs(isIndicatorsTable).find((el) => el.key === activeKey),
            )
          }
          items={getTabs(isIndicatorsTable)}
        />
        {activeTab?.create && isMobileScreen ? (
          <div
            className={'fixed-bottom p-3 full-width'}
            style={{ background: '#EDEFFC' }}
          >
            <span
              className='full-width absolute'
              style={{
                height: 12,
                top: -12,
                left: 0,
                background:
                  'linear-gradient(to bottom, rgba(255,0,0,0), #EDEFFC)',
              }}
            ></span>
            <Button
              type='primary'
              size='large'
              className='full-width'
              loading={isLoading}
              disabled={isLoading}
              onClick={handleAction}
            >
              {activeTab?.create}
            </Button>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default memo(Resumen);
