import {
  LOADED_RPT_PROVIDERS,
  LOADING_RPT_PROVIDERS,
  LOADING_RPT_PROVIDER_PAYMENTS_FAILED,
} from '../actions/actionsType';

const initialState = {
  isLoading: false,
  exportFailed: false,
  filePath: null,
};

const providerReport = (state, action) => {
  if (!state) state = initialState;
  switch (action.type) {
    case LOADING_RPT_PROVIDERS:
      return {
        ...state,
        isLoading: action.payload,
      };
    case LOADING_RPT_PROVIDER_PAYMENTS_FAILED:
      return {
        ...state,
        isLoading: false,
        exportFailed: true,
      };
    case LOADED_RPT_PROVIDERS:
      return {
        ...state,
        isLoading: false,
        exportFailed: false,
        filePath: action.payload,
      };
    default:
      return state;
  }
};

export default providerReport;
