import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Popover, Skeleton } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  MinusSquareOutlined,
  PlusSquareOutlined,
} from '@ant-design/icons';

import {
  getPurchaseOrderList,
  updateOrder,
} from '../../../services/requestServicesPurchaseOrder';

import SimpleTable from '../../../common/Table/SimpleTable';
import Topbar from '../../../common/ui/Topbar';
import { IconDots } from '../../../common/Icons/iconText';
import { IconEye } from '../ui/PreventionService/Reports/utils';
import ModalViewDetails from './ModalViewDetails';

const STATUS = {
  1: {
    color: '#FCDA6A',
    dot: '#FC9700',
    status: 'Pendiente',
  },
  2: {
    color: '#A7EB92',
    dot: '#389E0D',
    status: 'Aceptado',
  },
  3: {
    color: '#D7DBF5',
    dot: '#7981B2',
    status: 'Rechazado',
  },
};

const commonColumns = [
  {
    title: 'Cant. Servicios',
    dataIndex: 'cant_serv',
    key: 'cant_serv',
    width: 150,
    render: (_, record) => record?.purchase_order_items?.length,
  },
  {
    title: 'Monto',
    dataIndex: 'total',
    key: 'total',
    width: 120,
  },
  {
    title: 'Mes',
    dataIndex: 'month',
    key: 'month',
    width: 120,
  },
  {
    title: 'Estado',
    dataIndex: 'state',
    key: 'state',
    width: 120,
    render: (state) => {
      const { color, status, dot } = STATUS[state] || {};

      return (
        <div
          className='flex flex-row flex-justify-justify-between'
          style={{ height: '20px' }}
        >
          <span
            style={{ background: color, borderRadius: 6, padding: '2px 8px' }}
            className={'withpoint'}
          >
            <div
              style={{ background: dot }}
              className={'withpoint-circle'}
            ></div>
            <span>{status}</span>
          </span>
        </div>
      );
    },
  },
];

const adminColumns = commonColumns.concat({
  title: 'Provedor',
  dataIndex: 'user',
  key: 'user',
  width: 150,
  render: (user) => `${user?.name} ${user?.last_name1} ${user?.last_name2}`,
});

const PurchaseOrder = ({
  list,
  getList,
  isLoading,
  pagination,
  userSlug,
  acceptOrder,
  rejectOrder,
}) => {
  const [selectOrder, setSelectOrder] = useState(null);

  useEffect(() => {
    getList({
      page: pagination.current,
      limit: pagination.pageSize,
    });
  }, []);

  const handleTableChange = (pag) => {
    getList({
      page: pag.current,
      limit: pag.pageSize,
    });
  };

  const _handleAcceptOrder = async (recordId) => {
    await acceptOrder(recordId);
  };

  const _handleRejectOrder = async (recordId) => {
    await rejectOrder(recordId);
  };

  const columns = (
    userSlug === 'admin_rimac' ? adminColumns : commonColumns
  ).concat({
    title: 'Acciones',
    dataIndex: 'operation',
    key: 'operation',
    editable: false,
    fixed: 'right',
    width: 80,
    render: (_, record) => {
      return (
        <Popover
          placement='topRight'
          trigger='click'
          content={
            <div className='buttons-popover'>
              <Button onClick={() => setSelectOrder(record)}>
                <IconEye />
                <span style={{ marginLeft: 6 }}>Ver detalles</span>
              </Button>
              {userSlug === 'admin_rimac' && (
                <>
                  <Button onClick={() => _handleAcceptOrder(record.id)}>
                    <CheckOutlined />
                    <span>Aceptar orden</span>
                  </Button>
                  <Button onClick={() => _handleRejectOrder(record.id)}>
                    <CloseOutlined />
                    <span>Rechazar orden</span>
                  </Button>
                </>
              )}
            </div>
          }
          className='popover-actions'
        >
          {
            <Button>
              <IconDots />
            </Button>
          }
        </Popover>
      );
    },
  });

  return (
    <div id='checkSize' className='h100vh dashboard-UI'>
      {userSlug === 'admin_rimac' && <Topbar />}
      <div className='d_content service-prevention'>
        <div className='service-prevention-header mb-4'>
          <div className='service-prevention__title'>
            Solicitudes de Orden de Compra
          </div>
        </div>
        <div className='service-prevention__body pt-3'>
          {isLoading ? (
            <Skeleton active />
          ) : (
            <SimpleTable
              rowKey={(row) => row.id}
              columns={columns}
              dataSource={list}
              expandRowByClick
              pagination={pagination}
              expandIcon={({ expanded, onExpand, record }) => {
                if (record?.service?.type !== '1') {
                  return null;
                }

                if (expanded) {
                  return (
                    <MinusSquareOutlined onClick={(e) => onExpand(record, e)} />
                  );
                }

                return (
                  <PlusSquareOutlined onClick={(e) => onExpand(record, e)} />
                );
              }}
              onChange={handleTableChange}
            />
          )}
          <ModalViewDetails
            visible={Boolean(selectOrder)}
            setVisible={setSelectOrder}
            item={selectOrder}
          />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getList: async (data) => getPurchaseOrderList(data, dispatch),
  acceptOrder: (order) => updateOrder(order, 'approved', dispatch),
  rejectOrder: (order) => updateOrder(order, 'rejected', dispatch),
});

const mapStateToProps = (state) => ({
  list: state.requestServicesPurchaseOrder.list || [],
  pagination: state.requestServicesPurchaseOrder.pagination,
  isLoading: state.requestServicesPurchaseOrder.isLoading,
  userSlug: state.auth.user?.rol?.slug || '',
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrder);
