import React from 'react';
import MainTitle from '../../common/MainTitle';

const Precipitation = () => {
  return (
    <>
      <MainTitle title='Precipitación' description='Precipitación' />
    </>
  );
};

export default Precipitation;
