import * as React from 'react';

const Cloudy = (props) => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M34.3063 12.5826C33.8219 12.478 33.3375 12.4373 32.8547 12.4608C32.5844 10.5873 31.7047 8.8717 30.3109 7.53576C28.6141 5.9092 26.3844 5.06703 24.0297 5.16546C20.1156 5.32953 16.8766 8.14513 16.0516 11.8467C15.1797 11.3029 14.1562 10.9967 13.0828 10.9967C11.0141 10.9967 9.13125 12.1389 8.15312 13.9155H7.24375C3.93906 13.9155 1.25 16.6045 1.25 19.9092C1.25 21.8405 2.33906 23.5217 3.93594 24.3733C4.50156 23.9795 5.13594 23.6764 5.82188 23.4904C5.95625 23.1373 6.11719 22.7951 6.29844 22.4639C4.89219 22.4608 3.75 21.317 3.75 19.9092C3.75 17.9826 5.31719 16.4155 7.24375 16.4155H9.84062L10.1359 15.5826C10.5781 14.3342 11.7625 13.4967 13.0828 13.4967C14.4031 13.4967 15.5875 14.3358 16.0297 15.5826L16.325 16.4155H19.8703V13.9155H18.3516C18.3469 13.7967 18.3469 13.678 18.3484 13.5592C18.4234 10.4389 21.0188 7.79359 24.1344 7.6639C25.8016 7.59359 27.3813 8.19046 28.5813 9.34046C29.7828 10.492 30.4437 12.042 30.4437 13.7061V15.703L32.1016 15.1311C32.6531 14.9404 33.2156 14.9061 33.7766 15.028C35.2094 15.3389 36.25 16.6686 36.25 18.1905V19.9639C36.25 21.342 35.1281 22.4639 33.75 22.4639H22.9313C24.0969 22.7717 25.1609 23.4155 25.9719 24.3155C26.8031 24.3826 27.5891 24.6092 28.3016 24.9639H33.75C36.5062 24.9639 38.75 22.7201 38.75 19.9639V18.1905C38.75 15.4998 36.8813 13.142 34.3063 12.5842V12.5826Z'
      fill='url(#paint0_linear_9056_7816)'
    />
    <path
      d='M26.9719 33.7502H6.15469C4.48438 33.7502 3.125 32.3908 3.125 30.7205V29.5361C3.125 27.4439 4.58126 25.6346 6.58751 25.233C6.82188 25.1861 7.05626 25.158 7.29063 25.1517C7.54219 23.8814 8.17813 22.7221 9.14219 21.8064C10.4141 20.5986 12.0812 19.9846 13.8375 20.0752C16.5375 20.2158 18.7937 22.0486 19.5578 24.5408C20.1172 24.2783 20.7375 24.133 21.3797 24.133C22.8828 24.133 24.2563 24.922 25.0297 26.1658H25.4453C27.9563 26.1658 29.9984 28.208 29.9984 30.7189C29.9984 32.3892 28.6391 33.7486 26.9687 33.7486L26.9719 33.7502ZM7.425 27.6517C7.30938 27.6517 7.19375 27.6627 7.07812 27.6861C6.23594 27.8549 5.62656 28.633 5.62656 29.5377V30.7221C5.62656 31.0142 5.86407 31.2517 6.15625 31.2517H26.9734C27.2656 31.2517 27.5031 31.0142 27.5031 30.7221C27.5031 29.5892 26.5813 28.6689 25.45 28.6689H23.3734L23.0781 27.8361C22.8234 27.1174 22.1437 26.6361 21.3844 26.6361C20.625 26.6361 19.9438 27.1189 19.6906 27.8361L19.3953 28.6689H16.9578L17.2609 27.1705C17.3297 26.8299 17.3531 26.4799 17.3281 26.1299C17.1969 24.2346 15.6078 22.6736 13.7109 22.5752C12.6453 22.5205 11.6359 22.8924 10.8656 23.6221C10.0953 24.3533 9.67188 25.3408 9.67188 26.4017V28.3252L8.01407 27.7533C7.82032 27.6861 7.62344 27.6533 7.42657 27.6533L7.425 27.6517Z'
      fill='url(#paint1_linear_9056_7816)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_9056_7816'
        x1='1.25'
        y1='5.15771'
        x2='7.61493'
        y2='32.2725'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.272135' stopColor='#34263B' />
        <stop offset='0.658079' stopColor='#13172C' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_9056_7816'
        x1='3.82803'
        y1='21.1403'
        x2='12.9458'
        y2='39.0466'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.35' stopColor='#F7052D' />
        <stop offset='0.85' stopColor='#CA5AFA' />
      </linearGradient>
    </defs>
  </svg>
);

export default Cloudy;
