/* eslint-disable no-unreachable */
import React, { useState, useEffect } from 'react';

import { useScreen } from '../../../../../lib/screen';

import ModalForm from './ModalGeological';
import MainTitle from '../../common/MainTitle';

const Geological = ({ renderItem, queryAction }) => {
  const [openForm, setOpenForm] = useState(false);
  const { isMobileScreen } = useScreen();

  useEffect(() => {
    queryAction && setOpenForm(true);
  }, [queryAction]);

  return (
    <>
      <MainTitle
        buttonAction={() => setOpenForm(true)}
        renderItem={renderItem}
        isMobileScreen={isMobileScreen}
      />

      <ModalForm
        visible={openForm}
        handleClose={() => {
          setOpenForm(false);
        }}
      />
    </>
  );
};

export default Geological;
