import {
  LOADED_HELP_CENTER,
  LOAD_HELP_CENTER_LOADING,
  LOADING_CREATE_HELP_CENTER,
  OPEN_FORM_HELP_CENTER,
  CHANGE_FILTER_HELP_CENTER,
} from '../actions/actionsType';

const initialState = {
  list: [],
  openedForm: false,
  isLoadingList: false,
  isLoadingCreate: false,
  filter: {
    query: '',
    user_id: false,
    status: false,
  },
  pagination: {
    pageSize: 5,
    current: 1,
    total: 0,
  },
};

const helpCenter = (state, action) => {
  if (!state) state = initialState;

  switch (action.type) {
    case LOAD_HELP_CENTER_LOADING:
      return {
        ...state,
        isLoadingList: action.payload,
      };
    case LOADED_HELP_CENTER:
      return {
        ...state,
        list: action.payload.list,
        isLoadingList: false,
        filter: {
          query: action.payload.filter?.query || '',
          user_id: action.payload.filter?.user_id || false,
          status: action.payload.filter?.status || false,
        },
        pagination: {
          current: action.payload.page,
          pageSize: action.payload.items_per_page,
          total: action.payload.total,
        },
      };
    case OPEN_FORM_HELP_CENTER:
      return {
        ...state,
        openedForm: action.payload,
      };
    case LOADING_CREATE_HELP_CENTER:
      return {
        ...state,
        isLoadingCreate: action.payload,
      };
    case CHANGE_FILTER_HELP_CENTER:
      return {
        ...state,
        filter: action.payload,
      };
    default:
      return state;
  }
};

export default helpCenter;
