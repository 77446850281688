import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Input, Tooltip, Skeleton, DatePicker, Typography } from 'antd';
import {
  MinusSquareOutlined,
  PlusSquareOutlined,
  EyeOutlined,
  CalendarOutlined,
  CheckOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import moment from 'moment';

import { LOAD_REQUEST_SERVICES_ITEMS_LOADING } from '../../../../src/redux/actions/actionsType';
import {
  getMyRequestServicesItems,
  sendAcceptance,
  sendEventDate,
  sendEventDateCapacitacion,
} from '../../../services/requestServicesItems';
import { addResult } from '../../../services/requestServicesItemsResults';
import { getAllCourses } from '../../../services/courses';
import { getServiceDate } from './utils';

import FormCreateEventDate from './FormCreateEventDate';
import ModalViewDetails from './ModalViewDetails';
import ModalCheckParticipants from './ModalCheckParticipants';
import ExpandedCapacitacion from './ExpandedCapacitacion';
import ModalViewDetailsCapacitacion from './ModalViewDetailsCapacitacion';
import ModalUploadTechnicalReport from '../../../common/ModalUploadTechnicalReport';
import ModalPurchaseOrder from './ModalPurchaseOrder';
import SimpleTable from '../../../common/Table/SimpleTable';
import Topbar from '../../../common/ui/Topbar';

const { MonthPicker } = DatePicker;
const { Title } = Typography;
const { Search } = Input;

export const getStateRequestServiceItem = (state) => {
  switch (state) {
    case 1:
      return { color: '#FCDA6A', dot: '#FC9700', status: 'Derivado' };
    case 2:
      return { color: '#A7EB92', dot: '#389E0D', status: 'Aceptado' };
    case 3:
      return {
        color: '#FCDA6A',
        dot: '#FC9700',
        status: 'Fecha de visita solicitada',
      };
    case 4:
      return { color: '#A7EB92', dot: '#389E0D', status: 'Fecha Programada' };
    case 5:
      return { color: '#FCDA6A', dot: '#FC9700', status: 'En Proceso' };
    case 6:
      return { color: '#D7DBF5', dot: '#7981B2', status: 'Ejecutado' };
    case 7:
      return { color: '#D7DBF5', dot: '#7981B2', status: 'Cancelado' };
    case 8:
      return { color: '#D7DBF5', dot: '#7981B2', status: 'Rechazado' };
    case 9:
      return {
        color: '#FCDA6A',
        dot: '#FC9700',
        status: 'Pendiente de aprobación',
      };
    default:
      return { color: '#B2DFFF', dot: '#42A7FA', status: 'Nuevo' };
  }
};

const BusinessSegment = ({
  loadingList,
  loadList,
  list,
  doSendAcceptance,
  doSendEventDate,
  doSendEventDateCapacitacion,
  doAddResult,
  isLoading,
  pagination,
  query,
  allListCourses,
  loadAllListCourses,
  userSlug,
  id,
}) => {
  const location = useLocation();
  const [visibleFormEventDate, setVisibleFormEventDate] = useState(false);
  const [visibleFormEventDateEdit, setVisibleFormEventDateEdit] =
    useState(false);
  const [visibleDetails, setVisibleDetails] = useState(false);
  const [visibleDetailsCapacitacion, setVisibleDetailsCapacitacion] =
    useState(false);
  const [visibleUpload, setVisibleUpload] = useState(false);
  const [visibleCheckParticipants, setVisibleCheckParticipants] =
    useState(false);
  const [visiblePurchaseOrder, setVisiblePurchaseOrder] = useState(false);
  const [itemSelected, setItemSelected] = useState(null);
  const [courseSelect, setCourseSelect] = useState(null);
  const [reportCapacitacion, setReportCapacitacion] = useState(null);
  const [courseKey, setCourseKey] = useState(null);
  const [date, setDate] = useState(null);

  let isPreliquidation = useMemo(
    () => location.pathname.includes('preliquidacion'),
    [location.pathname],
  );

  let filters = useMemo(() => {
    return isPreliquidation
      ? { status: [6, 7], date: date ?? '' }
      : { status: '', date: '' };
  }, [date, isPreliquidation]);

  const find = (e) => {
    const txt = e.target.value.trim();
    if (txt === query) return;
    if (txt.length >= 2)
      loadList({
        query: txt,
        page: 1,
        limit: pagination.pageSize,
        filters: filters,
      });
    else
      loadList({
        query: '',
        page: 1,
        limit: pagination.pageSize,
        filters: filters,
      });
  };

  useEffect(() => {
    loadingList(true);
    loadAllListCourses();
    loadList({
      page: 1,
      limit: pagination.pageSize,
      filters: filters,
    });
  }, [isPreliquidation, date]);

  const loadListCb = useCallback(
    () =>
      loadList({
        query,
        page: pagination.current,
        limit: pagination.pageSize,
        filters: filters,
      }),
    [query, pagination],
  );

  const handleTableChange = (pagination) => {
    loadingList(true);
    loadList({
      query,
      page: pagination.current,
      limit: pagination.pageSize,
      filters: filters,
    });
  };

  const handlerSendAcceptance = (item) => {
    doSendAcceptance(item, {
      query,
      page: pagination.current,
      limit: pagination.pageSize,
    });
  };

  const handlerSendEventDate = (date, item) => {
    doSendEventDate(date, item, {
      query,
      page: pagination.current,
      limit: pagination.pageSize,
      filters: filters,
    });
  };

  const handlerSendEventDateCapacitacion = (date, item, course_key) => {
    doSendEventDateCapacitacion(date, item, course_key, {
      query,
      page: pagination.current,
      limit: pagination.pageSize,
    });
  };

  const handlerAddResult = (data, item) => {
    doAddResult(data, item, {
      query,
      page: pagination.current,
      limit: pagination.pageSize,
    });
  };

  const handlerCreateEventDate = (item) => {
    setItemSelected(item);
    setVisibleFormEventDate(true);
  };

  const handlerCreateEventDateEdit = (item) => {
    setItemSelected(item);
    setVisibleFormEventDate(true);
    setVisibleFormEventDateEdit(true);
  };

  const handlerShowDetails = (item) => {
    setItemSelected(item);
    setVisibleDetails(true);
  };

  const handlerShowDetailsCapacitacion = (item, report) => {
    setItemSelected(item);
    setVisibleDetailsCapacitacion(true);
    setReportCapacitacion(report);
  };

  const handlerUploadTechnicalReport = (item, courseKey) => {
    setItemSelected(item);
    setVisibleUpload(true);
    setCourseKey(courseKey);
  };

  const handlerCheckParticipants = (item, course) => {
    setCourseSelect(course);
    setItemSelected(item.id);
    setVisibleCheckParticipants(true);
  };

  useEffect(() => {
    if (!visibleCheckParticipants) {
      setItemSelected(null);
      setCourseSelect(null);
    }
  }, [visibleCheckParticipants]);

  const getReportCreatedDate = (item) => {
    const technicalReports = item.request_service_item_results;
    if (technicalReports && technicalReports.length > 0) {
      const technicalReport = technicalReports[technicalReports.length - 1];

      return moment(technicalReport.createdAt);
    }
  };

  const columns = [
    {
      title: 'Fecha',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 120,
      align: 'center',
      render: (date) => {
        if (date)
          return (
            new Date(date).toLocaleDateString('es-PE') +
            ' ' +
            new Date(date).toLocaleTimeString('es-PE')
          );

        return '';
      },
    },
    {
      title: 'Servicio',
      dataIndex: 'requestServiceItems',
      key: 'requestServiceItemsServicio',
      width: 220,
      render: (field, item) =>
        `${item?.service?.name ? item.service.name : ''}`,
    },
    ...(isPreliquidation
      ? [
          {
            title: 'Zona',
            dataIndex: 'request_service_item_results',
            key: 'request_service_item_results_zone',
            width: 200,
            render: (_, item) =>
              `${item?.request_service_item_results?.[0]?.zone ?? ''}`,
          },
          {
            title: 'Precio',
            dataIndex: 'request_service_item_results',
            key: 'request_service_item_results_price',
            width: 120,
            render: (_, item) =>
              `${item?.request_service_item_results?.[0]?.price ?? ''}`,
          },
        ]
      : []),
    {
      title: 'Empresa',
      dataIndex: 'requestServiceItems',
      key: 'requestServiceItemsEmpresa',
      width: 200,
      render: (field, item) =>
        `${
          item?.requestService?.company?.name
            ? item?.requestService?.company?.name
            : ''
        }`,
    },
    {
      title: 'Estado',
      dataIndex: 'state',
      key: 'state',
      render: (state) => {
        const { color, status, dot } = getStateRequestServiceItem(state) || {};

        return (
          <div
            className='flex flex-row flex-justify-justify-between'
            style={{ height: '20px' }}
          >
            <span
              style={{ background: color, borderRadius: 6, padding: '2px 8px' }}
              className={'withpoint'}
            >
              <div
                style={{ background: dot }}
                className={'withpoint-circle'}
              ></div>
              <span>{status}</span>
            </span>
          </div>
        );
      },
    },
    {
      title: 'F.Servicio',
      key: 'fservice',
      width: 110,
      align: 'center',
      render: (_, item) => {
        const date = getServiceDate(item);
        if (date && date.isValid() && item?.service?.type !== '1')
          return (
            <div>
              {date.format('DD/MM/YYYY')}{' '}
              {item.request_service_item_results?.length > 0 ? '' : <i>T</i>}
            </div>
          );
      },
    },
    {
      title: 'Días',
      key: 'fserviceDays',
      width: 60,
      align: 'center',
      render: (_, item) => {
        const serviceData = getServiceDate(item);
        if (
          item.date_derivative &&
          serviceData &&
          serviceData.isValid() &&
          item?.service?.type !== '1'
        )
          return moment(
            serviceData.format('DD-MM-YYYY 00:00'),
            'DD-MM-YYYY HH:mm',
          ).diff(
            moment(
              moment(item.date_derivative).format('DD-MM-YYYY 00:00'),
              'DD-MM-YYYY HH:mm',
            ),
            'days',
          );
      },
    },
    {
      title: 'F.Informe',
      key: 'finforme',
      width: 110,
      align: 'center',
      render: (_, item) =>
        item?.service?.type !== '1' &&
        getReportCreatedDate(item)?.format('DD/MM/YYYY'),
    },
    {
      title: 'Días',
      key: 'finformedays',
      width: 60,
      align: 'center',
      render: (_, item) => {
        const serviceDate = getServiceDate(item);
        const reportCreated = getReportCreatedDate(item);
        if (item?.service?.type !== '1' && reportCreated && serviceDate)
          return moment(
            reportCreated.format('DD-MM-YYYY 00:00'),
            'DD-MM-YYYY HH:mm',
          ).diff(
            moment(serviceDate.format('DD-MM-YYYY 00:00'), 'DD-MM-YYYY HH:mm'),
            'days',
          );
      },
    },
    {
      title: 'Subacción',
      width: 120,
    },
    {
      title: 'Acción',
      dataIndex: 'operation',
      key: 'operation',
      width: 120,
      render: (_, item) => {
        return (
          <span className='table-operation'>
            <Tooltip title='Detalles'>
              <Button
                size='small'
                type='link'
                onClick={() => handlerShowDetails(item)}
              >
                <EyeOutlined />
              </Button>
            </Tooltip>
            {item.state === 1 && (
              <Tooltip title='Aceptar servicio'>
                <Button
                  icon={<CheckOutlined />}
                  size='small'
                  type='link'
                  onClick={() => handlerSendAcceptance(item)}
                />
              </Tooltip>
            )}
            {item?.service?.type !== '1' && (
              <>
                {(item.state === 2 || item.state === 3) && (
                  <Tooltip title='Agendar fecha de visita'>
                    <Button
                      icon={<CalendarOutlined />}
                      size='small'
                      type='link'
                      onClick={() => handlerCreateEventDate(item)}
                    ></Button>
                  </Tooltip>
                )}
                {userSlug === 'proveedor' && (
                  <>
                    {item.state !== 6 &&
                      item.state !== 2 &&
                      item.state !== 3 && (
                        <Tooltip title='Agendar fecha de visita'>
                          <Button
                            size='small'
                            className='btn-cmo-action-list'
                            onClick={() => handlerCreateEventDateEdit(item)}
                          >
                            <CalendarOutlined />
                          </Button>
                        </Tooltip>
                      )}
                  </>
                )}
              </>
            )}
            {(item.state === 4 || item.state === 5 || item.editable) &&
              item.service?.type !== '1' && (
                <Tooltip
                  title={
                    item.state === 4
                      ? 'Subir informe técnico'
                      : 'Cambiar informe técnico'
                  }
                >
                  <Button
                    icon={<UploadOutlined />}
                    size='small'
                    type='link'
                    onClick={() => handlerUploadTechnicalReport(item)}
                  ></Button>
                </Tooltip>
              )}
          </span>
        );
      },
    },
  ];

  return (
    <div id='checkSize' className='h100vh dashboard-UI'>
      <Topbar />
      <div className='d_content'>
        <div className='flex justify-between items-center mb-4'>
          <Title className='header-container'>
            {isPreliquidation
              ? 'Servicios Ejecutados'
              : 'Servicios Solicitados'}
          </Title>
          {isPreliquidation ? (
            <Button
              type='primary'
              size='large'
              style={{ width: '300px' }}
              disabled={isLoading || !date || list.length === 0}
              onClick={() => setVisiblePurchaseOrder(true)}
            >
              Generar orden de compra
            </Button>
          ) : (
            <Search
              placeholder='Buscar'
              onChange={find}
              style={{
                width: '300px',
              }}
            />
          )}
        </div>
        <div className='service-prevention__body'>
          {isPreliquidation && (
            <div className={'flex justify-between items-center mt-1 mb-4'}>
              <Title level={4}>Filtrar servicios por mes</Title>
              <MonthPicker
                placeholder='Seleccione mes y año'
                onChange={(_, date) => setDate(date)}
              />
            </div>
          )}
          {isLoading ? (
            <Skeleton active />
          ) : (
            <SimpleTable
              rowKey={(row) => row.id}
              columns={columns}
              dataSource={list}
              expandRowByClick
              pagination={pagination}
              expandedRowRender={(record) =>
                record?.service?.type === '1' && (
                  <ExpandedCapacitacion
                    allListCourses={allListCourses}
                    item={record}
                    handlerSendEventDateCapacitacion={
                      handlerSendEventDateCapacitacion
                    }
                    handlerShowDetailsCapacitacion={
                      handlerShowDetailsCapacitacion
                    }
                    handlerUploadTechnicalReport={handlerUploadTechnicalReport}
                    handlerCheckParticipants={handlerCheckParticipants}
                    userSlug={userSlug}
                    isPreliquidation={isPreliquidation}
                  />
                )
              }
              expandIcon={({ expanded, onExpand, record }) =>
                record?.service?.type === '1' ? (
                  expanded ? (
                    <MinusSquareOutlined onClick={(e) => onExpand(record, e)} />
                  ) : (
                    <PlusSquareOutlined onClick={(e) => onExpand(record, e)} />
                  )
                ) : null
              }
              onChange={handleTableChange}
            />
          )}
          <FormCreateEventDate
            visible={visibleFormEventDate}
            item={itemSelected}
            setVisible={setVisibleFormEventDate}
            setVisibleFormEventDateEdit={setVisibleFormEventDateEdit}
            visibleFormEventDateEdit={visibleFormEventDateEdit}
            doSendEventDate={handlerSendEventDate}
          />
          <ModalViewDetails
            visible={visibleDetails}
            item={itemSelected}
            setVisible={setVisibleDetails}
          />
          <ModalViewDetailsCapacitacion
            visible={visibleDetailsCapacitacion}
            item={itemSelected}
            setVisible={setVisibleDetailsCapacitacion}
            reportCapacitacion={reportCapacitacion}
          />
          <ModalUploadTechnicalReport
            visible={visibleUpload}
            setVisible={setVisibleUpload}
            courseKey={courseKey}
            item={itemSelected}
            doAddResult={handlerAddResult}
          />
          <ModalCheckParticipants
            visible={visibleCheckParticipants}
            courseSelect={courseSelect}
            itemId={itemSelected}
            itemList={list}
            loadList={loadListCb}
            loading={isLoading}
            onClose={() => setVisibleCheckParticipants(false)}
          />
          <ModalPurchaseOrder
            visible={visiblePurchaseOrder}
            list={list}
            providerId={id}
            onClose={() => setVisiblePurchaseOrder(false)}
          />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  loadingList: (payload) =>
    dispatch({ type: LOAD_REQUEST_SERVICES_ITEMS_LOADING, payload: payload }),
  loadList: async (data) => {
    dispatch({ type: LOAD_REQUEST_SERVICES_ITEMS_LOADING, payload: true });
    await getMyRequestServicesItems(dispatch, data);
    dispatch({ type: LOAD_REQUEST_SERVICES_ITEMS_LOADING, payload: false });
  },
  doSendAcceptance: (item, pagination) => {
    sendAcceptance(dispatch, item.id, pagination);
  },
  doSendEventDate: (date, item, pagination) => {
    sendEventDate(dispatch, date, item.id, pagination);
  },
  doSendEventDateCapacitacion: (date, item, course_key, pagination) => {
    sendEventDateCapacitacion(dispatch, date, item.id, course_key, pagination);
  },
  doAddResult: (data, item, pagination) => {
    addResult(dispatch, data, item.id, pagination);
  },
  loadAllListCourses: () => getAllCourses(dispatch),
});

const mapStateToProps = (state) => ({
  list: state.requestServicesItems.list || [],
  query: state.requestServicesItems.query,
  pagination: state.requestServicesItems.pagination,
  isLoading: state.requestServicesItems.isLoadingList,
  allListCourses: state.courses.list || [],
  userSlug: state.auth.user?.rol?.slug || '',
  id: state.auth.user?.rol?.id,
});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessSegment);
