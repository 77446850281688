import React, { useMemo } from 'react';
import { Select, Slider } from 'antd';
import { PauseCircleOutlined, CaretRightFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { computeWidth } from './utils';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';
import { MAP_CHANGE_BLOCKED_CLICK } from '../../../../redux/actions/actionsType';

const ControlLayer = ({
  isLoadingScenes,
  selectedHour,
  handleSelectHour,
  onPlayAction,
  isPlaying,
  selectedSceneBar,
  defaultScene,
  onAfterChangeBar,
  onChangeBar,
  idxTimes,
  marks,
  ticks,
}) => {
  const dispatch = useDispatch();

  const changeIsBlockedClick = (blocked) =>
    dispatch({ type: MAP_CHANGE_BLOCKED_CLICK, payload: blocked });

  const activeSubLayer = useSelector((state) => state.maps.activeSubLayer);
  const menuIsOpened = useSelector((state) => state.sideMenu.isOpened);
  const { width } = useWindowDimensions();

  const computedWidth = useMemo(
    () => computeWidth(menuIsOpened, width),
    [menuIsOpened, width],
  );

  const onMouseEnterToControls = () => {
    changeIsBlockedClick(true);
  };

  const onMouseExitOfControls = () => {
    changeIsBlockedClick(false);
  };

  const formatterTooltip = (value) => {
    return ticks[value];
  };

  return (
    <div
      onTouchStart={onMouseEnterToControls}
      onTouchEnd={onMouseExitOfControls}
      onMouseEnter={onMouseEnterToControls}
      onMouseLeave={onMouseExitOfControls}
      className={`animated-controls-layers ${isLoadingScenes ? 'loading' : ''}`}
    >
      {activeSubLayer?.animate?.realtime && (
        <div className='select-controls'>
          <Select value={selectedHour} onChange={handleSelectHour}>
            <Select.Option value={'12h'}>12 h</Select.Option>
            <Select.Option value={'6h'}>6 h</Select.Option>
            <Select.Option value={'2h'}>2 h</Select.Option>
          </Select>
        </div>
      )}
      <div className='play-controls'>
        <div className='play-pause' onClick={() => onPlayAction()}>
          {isPlaying ? (
            <PauseCircleOutlined className='action-pause' />
          ) : (
            <CaretRightFilled className='action-play' />
          )}
        </div>
        <div className='bar-controls'>
          <Slider
            tooltip={{
              open: true,
              formatter: formatterTooltip,
              color: 'rgba(0, 0, 0, 0.55)',
              overlayStyle: {
                fontSize: '10px',
                cursor: 'grab',
              },
            }}
            value={selectedSceneBar || defaultScene}
            onChangeComplete={onAfterChangeBar}
            onChange={onChangeBar}
            min={1}
            max={
              activeSubLayer?.animate?.realtime
                ? activeSubLayer?.animate.times[idxTimes]
                : activeSubLayer?.animate.times
            }
            marks={marks}
          />
        </div>
      </div>
    </div>
  );
};

export default ControlLayer;
