let aws_exports = {
  Auth: {
    region: `${process.env.REACT_APP_COGNITO_REGION}`,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: `${process.env.REACT_APP_COGNITO_APP_CLIENT_ID}`,
    mandatorySignIn: false,
    identiyProvider: `${process.env.REACT_APP_COGNITO_OAUTH_PROVIDER}`,
    identityPoolId: `${process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID}`,
    oauth: {
      domain: `${process.env.REACT_APP_COGNITO_USER_POOL_DOMAIN_NAME}`,
      scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: `${process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN}`,
      redirectSignOut: `${process.env.REACT_APP_COGNITO_REDIRECT_SIGN_OUT}`,
      responseType: `${process.env.REACT_APP_COGNITO_OAUTH_RESPONSE_TYPE}`,
    },
  },
  API: {
    endpoints: [
      {
        name: 'API',
        endpoint: `${process.env.REACT_APP_API_BASE_URL_NEW}`,
        region: `${process.env.REACT_APP_COGNITO_REGION}`,
      },
    ],
  },
};

export default aws_exports;
