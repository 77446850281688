import React, { useState } from 'react';

const FloatLabel = (props) => {
  const [focus, setFocus] = useState(false);
  const { children, label, value, placeholder } = props;

  const labelClass =
    focus || (value && value.length !== 0) ? 'label label-float' : 'label';

  let labelText;

  if (focus || (value && value.length !== 0)) {
    labelText = label;
  } else if (placeholder) {
    labelText = placeholder;
  } else {
    labelText = label;
  }

  return (
    <div
      className='float-label'
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      {children}
      <label className={labelClass}>{labelText}</label>
    </div>
  );
};

export default FloatLabel;
