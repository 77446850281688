import React from 'react';
import { Result } from 'antd';

const LastStep = () => {
  return (
    <Result
      status='success'
      title='¡Usted ya completó la evaluación de riesgos!'
      subTitle='Ya ha completado la autoevaluación anteriormente. Nos pondremos en contacto para presentarle el informe.'
    />
  );
};

export default LastStep;
