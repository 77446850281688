import React, { memo, useEffect, useRef } from 'react';
import Button from '../Button';

const Slide = ({
  subtitle,
  title,
  titleRed,
  description,
  href,
  target,
  src,
}) => {
  let descriptionRef = useRef(null);

  useEffect(() => {
    if (descriptionRef.current) descriptionRef.current.innerHTML = description;
  }, [description]);

  return (
    <section className='section'>
      <div className='main'>
        <div className='main__left'>
          <div className='main__title'>
            <span className='main__title--subtitle'>{subtitle}</span>
            <h1>
              <span className='main__title'>{title}</span>
              <br />
              <span className='main__title--red'>{titleRed}</span>
            </h1>
          </div>
          <p ref={descriptionRef} className='main__description'></p>
          <div className='main__buttons'>
            <Button
              href={href}
              target={`${Boolean(Number(target)) ? '_blank' : '_self'}`}
            >
              Conoce más
            </Button>
          </div>
        </div>
        <div className='main__right'>
          <img src={src} className='main__img' alt='' />
        </div>
      </div>
    </section>
  );
};

export default memo(Slide);
