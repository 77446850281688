import React from 'react';
import { useSelector } from 'react-redux';
import LayerGeoJson from '../LayersTypes/LayerGeoJson';
import LayerShape from '../LayersTypes/LayerShape';
import LayerKML from '../LayersTypes/LayerKML';

const Index = () => {
  const myLayersActive = useSelector((state) => state.myLayers.activeLayers);

  return myLayersActive.map((l) => {
    if (l.typeFile === 'geojson') {
      return <LayerGeoJson key={l.id} layer={l} fitBounds={true} />;
    }
    if (l.typeFile === 'zip' || l.typeFile === 'shp') {
      return <LayerShape key={l.id} layer={l} fitBounds={true} />;
    }
    if (l.typeFile === 'kml' || l.typeFile === 'kmz') {
      return <LayerKML key={l.id} layer={l} fitBounds={true} />;
    }
  });
};

export default Index;
