import { useRef } from 'react';
import { Form, notification } from 'antd';

import { createPolizasDetailsByService } from '../../../../../services/polizas';
import text from '../../../../../config/text_es';
import InputWithLabel from '../../../../../common/InputWithLabel';
import { RideButton } from '@rimac-seguros/ride-system-components';

const { useForm } = Form;

const PanelForm = ({ policy, serviceId, polizaId }) => {
  const [form] = useForm();
  const { validateFields } = form;
  const uuidRef = useRef(policy?.uuid);

  const handleSave = async () => {
    try {
      const values = await validateFields();
      values.count = values.base;

      let data = uuidRef.current
        ? { ...values, polizaId, serviceId, uuid: uuidRef.current }
        : { ...values, polizaId, serviceId };

      const res = await createPolizasDetailsByService([data]);

      if (res?.data?.[0]?.uuid) {
        uuidRef.current = res?.data?.[0].uuid;
      }

      if (res.success) {
        notification.success({
          description: 'Se guardo la información de la póliza.',
          message: '¡Proceso realizado!',
        });
      }
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  };

  return (
    <Form form={form} className='form'>
      <div className='form__items'>
        <Form.Item
          name='base'
          label='Base'
          className='form__item'
          initialValue={policy ? policy.base : 0}
          rules={[{ required: true, message: text.required_message }]}
        >
          <InputWithLabel type='number' min='0' />
        </Form.Item>
        <Form.Item
          name='required'
          label='Requerido'
          className='form__item'
          initialValue={policy ? policy.required : 0}
          rules={[{ required: true, message: text.required_message }]}
        >
          <InputWithLabel type='number' min='0' />
        </Form.Item>
        <Form.Item
          name='planned'
          label='Planificado'
          className='form__item'
          initialValue={policy ? policy.planned : 0}
          rules={[{ required: true, message: text.required_message }]}
        >
          <InputWithLabel type='number' min='0' />
        </Form.Item>
        <Form.Item
          name='executed'
          label='Ejecutado'
          className='form__item'
          initialValue={policy ? policy.executed : 0}
          rules={[{ required: true, message: text.required_message }]}
        >
          <InputWithLabel type='number' min='0' />
        </Form.Item>
      </div>
      <RideButton
        size='large'
        className='form__button'
        onClick={handleSave}
        text='Guardar'
      />
    </Form>
  );
};

export default PanelForm;
