import { Button } from 'antd';
import React from 'react';
import { RideButton } from '@rimac-seguros/ride-system-components';

import { IconReturnHtml } from '../../../../common/Icons/iconText';
import { useScreen } from '../../../../lib/screen';

const HeaderForm = ({
  title = '',
  buttonText = '',
  create = false,
  withBorder = false,
  handleReturn = () => {},
  buttonAction = () => {},
  rol,
}) => {
  const { isMobileScreen } = useScreen();

  return (
    <div className='profile-company__header'>
      {isMobileScreen ? (
        <>
          <div className='back' onClick={handleReturn}>
            <IconReturnHtml />
            <span>Volver</span>
          </div>
          <h2 className={`title ${withBorder ? 'border' : ''}`}>{title}</h2>
        </>
      ) : (
        <div
          className={`profile-company__header--action ${
            withBorder ? 'border' : ''
          } ${create ? 'create' : ''}`}
        >
          <div className='title-section'>
            <div className='back' onClick={handleReturn}>
              <IconReturnHtml />
            </div>
            <h2 className={'title'}>{title}</h2>
          </div>

          {rol !== 'comercial' && create && (
            <div className='action-section'>
              <RideButton
                onClick={buttonAction}
                variant='fill'
                size='medium'
                text={buttonText}
              />
            </div>
          )}
        </div>
      )}

      {isMobileScreen && rol !== 'comercial' && create && (
        <div
          className={'fixed-bottom p-3 full-width'}
          style={{ background: '#EDEFFC' }}
        >
          <span
            className='full-width absolute'
            style={{
              height: 12,
              top: -12,
              left: 0,
              background:
                'linear-gradient(to bottom, rgba(255,0,0,0), #EDEFFC)',
            }}
          ></span>

          <Button
            className='full-width'
            onClick={() => buttonAction()}
            type='primary'
            style={{ height: 48, fontSize: 18 }}
          >
            {buttonText}
          </Button>
        </div>
      )}
    </div>
  );
};

export default HeaderForm;
