import React from 'react';

const IconLocation = () => {
  return (
    <svg
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d={`M8 0C4.692 0 2 2.57952 2 5.75092C2 6.75473 2.308 7.91852 2.92 9.21027C3.404 10.2301 
        4.076 11.3339 4.916 12.4897C6.344 14.4493 7.796 15.909 7.812 15.925C7.912 16.025 8.088 
        16.025 8.188 15.925C8.204 15.909 9.652 14.4493 11.084 12.4897C11.928 11.3339 12.596 10.2301 
        13.08 9.21027C13.692 7.92252 14 6.75873 14 5.75092C14 2.57952 11.308 0 8 0Z`}
        fill='#141938'
      />
      <circle cx='8' cy='6' r='3' fill='white' />
    </svg>
  );
};

export default IconLocation;
