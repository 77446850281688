import React, { useState, useEffect, useCallback, useMemo } from 'react';
import moment from 'moment';
import { Modal } from 'antd';
import { DownloadOutlined, FileOutlined } from '@ant-design/icons';
import { secureRandom } from '../../lib/utils';

import SmallImage from './SmallImage';

const Attachments = ({ comments, info, handlerDownloadFile }) => {
  const mainFile = useMemo(() => {
    if (info && info?.file)
      return {
        date: moment(info.createdAt).format('MMMM'),
        comments: [{ file: info.file }],
      };

    return null;
  }, []);

  const [attachment, setAttachment] = useState([]);
  const [visible, setVisible] = useState(false);
  const [file, setFile] = useState(null);

  const handleOpenModal = useCallback((url) => {
    setVisible(true);
    setFile(url);
  }, []);

  const download = async (url) => {
    const a = document.createElement('a');
    a.href = await toDataURL(url);
    a.download = file;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const toDataURL = async (url) => {
    return fetch(url)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        return URL.createObjectURL(blob);
      })
      .catch(() => {
        window.open(url, '_blank');
      });
  };

  const onClick = async (url) => {
    await download(`${process.env.REACT_APP_WEB_PATH_S3_NEW}${url}`);
  };

  useEffect(() => {
    if (!comments.length) {
      if (mainFile) setAttachment([mainFile]);
      return;
    }

    const commentsWithAttachment = comments
      .map((group) => ({
        date: 'Adjuntos',
        comments: group.comments.filter((c) => c.file),
      }))
      .filter((group) => group.comments.length > 0);

    if (commentsWithAttachment.length === 0) {
      setAttachment((prev) => [...prev]);
      return;
    }

    const groupedByMonths = commentsWithAttachment.reduce(
      (acc, { date, comments }) => {
        if (!acc[date]) acc[date] = [];
        acc[date].push(...comments);
        return acc;
      },
      {},
    );

    const groups = Object.entries(groupedByMonths).map(([date, comments]) => ({
      date,
      comments,
    }));

    if (mainFile) groups.unshift({ date: 'Main File', comments: [mainFile] });
    setAttachment(groups);
  }, [comments, mainFile]);

  return (
    <div
      id='d_content_chat__history'
      className='d_content_chat__history minimal-scroll-y'
    >
      {attachment.map((group, index) => {
        return (
          <React.Fragment key={index}>
            <div
              className={`d_content_chat__history__date mb-2 pb-2 ${
                index !== 0 ? 'pt-4' : ''
              }`}
              key={index}
            >
              {group.date.toUpperCase()}
            </div>

            <section className='files-content'>
              {group.comments.map((comment) => (
                <article
                  key={`${secureRandom()}`}
                  className={'d_content_chat__history__file'}
                  onClick={() => {
                    !['png', 'jpeg', 'jpg'].includes(
                      comment.file.split('.').pop(),
                    )
                      ? handlerDownloadFile(comment.file)
                      : handleOpenModal(comment.file);
                  }}
                >
                  <div className='d_content_chat__history__file__icon'>
                    {['png', 'jpeg', 'jpg'].includes(
                      comment.file.split('.').pop(),
                    ) ? (
                      <SmallImage src={comment.file} />
                    ) : (
                      <FileOutlined />
                    )}
                  </div>
                  <p className='d_content_chat__history__file__name'>
                    {comment.file.length > 32
                      ? comment.file.substring(0, 27) +
                        '...' +
                        comment.file.split('.').pop()
                      : comment.file}
                  </p>
                </article>
              ))}
            </section>
          </React.Fragment>
        );
      })}

      <Modal
        open={visible}
        footer={null}
        onCancel={() => setVisible(false)}
        centered={true}
        closable={true}
        className='modal-image'
      >
        <div className='content-preview'>
          <img
            src={`${process.env.REACT_APP_WEB_PATH_S3_NEW}${file}`}
            alt={file}
          />

          <div className='download mt-2 mb-3'>
            <div className='down-file' onClick={() => onClick(file)}>
              Descargar imágen <DownloadOutlined />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Attachments;
