import React from 'react';

const Pause = () => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='20.001' cy='20' r='20' fill='#CCD1EE' />
      <path
        d='M16.6657 26.6677C15.7452 26.6677 14.9995 25.922 14.9995 25.0015V14.9986C14.9995 14.0781 15.7452 13.3324 16.6657 13.3324C17.5862 13.3324 18.3319 14.0781 18.3319 14.9986V25.0015C18.3319 25.922 17.5862 26.6677 16.6657 26.6677ZM25.0024 25.0015V14.9986C25.0024 14.0781 24.2567 13.3324 23.3362 13.3324C22.4158 13.3324 21.6701 14.0781 21.6701 14.9986V25.0015C21.6701 25.922 22.4158 26.6677 23.3362 26.6677C24.2567 26.6677 25.0024 25.922 25.0024 25.0015Z'
        fill='#03050F'
      />
    </svg>
  );
};

export default Pause;
