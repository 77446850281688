import {
  SENTMAIL_LOADED_LIST,
  SENTMAIL_LOADING_LIST,
} from '../redux/actions/actionsType';
import { post } from '../lib/ajax';

export const getSentMails = async (dispatch, parameters) => {
  dispatch({ type: SENTMAIL_LOADING_LIST, payload: true });
  const r = await post('/sent_mails/list', parameters);
  if (r?.data?.success)
    dispatch({ type: SENTMAIL_LOADED_LIST, payload: r.data.payload });
};

export const updateStatusSentMails = async (id, parameters) => {
  const r = await post(`/sent_mails/update?id=${id}`, parameters);

  if (r?.data?.success) return true;
  return false;
};

export const reSentMail = async (id, parameters) => {
  const r = await post(`/sent_mails/resend?id=${id}`, parameters);

  if (r?.data?.success) return true;
  return false;
};
