import { Skeleton } from 'antd';
import React from 'react';

export const MainSkeleton = () => {
  return (
    <div className='card card-loading'>
      <Skeleton active title={false} paragraph={{ rows: 5 }} />
    </div>
  );
};

export const PieSkeleton = () => {
  return (
    <>
      <Skeleton active title paragraph={false} />

      <div className='card card-loading'>
        <Skeleton
          active
          avatar={{ size: 'large' }}
          title={false}
          paragraph={false}
        />
        <Skeleton active title={false} paragraph={{ rows: 5 }} />
      </div>
    </>
  );
};
