import React from 'react';

const Play = () => {
  return (
    <svg
      width='40'
      height='44'
      viewBox='0 0 40 44'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='20' cy='20' r='20' fill='#CCD1EE' />
      <path
        d='M16.1362 13.7904C16.0594 13.7404 15.9613 13.7365 15.8808 13.7803C15.8002 13.824 15.75 13.9083 15.75 14V27C15.75 27.0917 15.8002 27.176 15.8808 27.2197C15.9613 27.2635 16.0594 27.2596 16.1362 27.2096L26.1362 20.7096C26.2072 20.6635 26.25 20.5846 26.25 20.5C26.25 20.4154 26.2072 20.3365 26.1362 20.2904L16.1362 13.7904Z'
        fill='#03050F'
        stroke='#03050F'
        strokeWidth='0.5'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default Play;
