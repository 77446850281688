import { useState, useEffect, useMemo } from 'react';
import { Button, DatePicker, Form, notification, Skeleton, Table } from 'antd';
import locale from 'antd/es/date-picker/locale/es_ES';

import { postRoadEmergencyPoints } from '../../../../services/roadEmergency';
import { useScreen } from '../../../../lib/screen';

import ModalForm from './ModalForm';
import ModalConfirm from '../../../../common/ModalConfirm';
import TableMessage from '../../../../common/Table/TableMessage';
import MainTitle from '../common/MainTitle';
import DeleteModalImage from '../../../../images/delete_modal_image.svg';
import InputWithLabel from '../../../../common/InputWithLabel';
import moment from 'moment';

const InputCustom = ({ name, initialValue, required, disabled }) => {
  return (
    <Form.Item
      name={name}
      initialValue={initialValue}
      rules={required ? [{ required: true, message: '*' }] : []}
      style={{ margin: 0 }}
    >
      <InputWithLabel size='small' disabled={disabled} />
    </Form.Item>
  );
};

const InputDate = ({ field, initialValue, required, disabled }) => {
  return (
    <Form.Item
      name={field}
      initialValue={initialValue}
      rules={required ? [{ required: true, message: '*' }] : []}
      className='range-picker-with-label'
      style={{ margin: 0 }}
    >
      <DatePicker
        locale={locale}
        size='small'
        format={'DD/MM/YYYY'}
        disabled={disabled}
      />
    </Form.Item>
  );
};

const RoadEmergencies = ({ renderItem, queryAction }) => {
  const [form] = Form.useForm();
  const { widthScreen, isMobileScreen } = useScreen();
  const [rows, setRows] = useState([]);
  const [pagination, setPagination] = useState(() => ({
    pageSize: 5,
    total: 0,
    current: 1,
  }));
  const [openForm, setOpenForm] = useState(false);
  const [uploadPoints, setUploadPoints] = useState(false);

  const columns = useMemo(
    () => [
      {
        title: 'FECHA DE EVENTO',
        dataIndex: 'fechaEmergencia',
        key: 'fechaEmergencia',
        width: '200px',
        render: (value, record) => (
          <InputDate
            required
            initialValue={moment(value, 'DD/MM/YYYY')}
            format={'DD/MM/YYYY'}
            field={`road_emergency[${record.id}].fechaEmergencia`}
          />
        ),
      },
      {
        title: 'FECHA DE ACTUALIZACION',
        dataIndex: 'fechaActualizacion',
        key: 'fechaActualizacion',
        width: '200px',
        render: (value, record) => (
          <InputDate
            required
            initialValue={moment(value, 'DD/MM/YYYY')}
            format={'DD/MM/YYYY'}
            field={`road_emergency[${record.id}].fechaActualizacion`}
          />
        ),
      },
      {
        title: 'CONDICION DE TRANSITO',
        dataIndex: 'condicionTransitoCodigo',
        key: 'condicionTransitoCodigo',
        width: '200px',
        render: (value, record) => (
          <InputCustom
            required
            initialValue={value}
            field={`road_emergency[${record.id}].condicionTransitoCodigo`}
          />
        ),
      },
      {
        title: 'UBIGEO',
        dataIndex: 'ubigeo',
        key: 'ubigeo',
        width: '200px',
        render: (value, record) => (
          <InputCustom
            required
            initialValue={value}
            field={`road_emergency[${record.id}].ubigeo`}
          />
        ),
      },
      {
        title: 'TIPO DE EMERGENCIA',
        dataIndex: 'tipoEmergencia',
        key: 'tipoEmergencia',
        width: '200px',
        render: (value, record) => (
          <InputCustom
            required
            initialValue={value}
            field={`road_emergency[${record.id}].tipoEmergencia`}
          />
        ),
      },
      {
        title: 'ESTADO',
        dataIndex: 'estado',
        key: 'estado',
        width: '200px',
        render: (value, record) => (
          <InputCustom
            required
            initialValue={value}
            field={`road_emergency[${record.id}].estado`}
          />
        ),
      },
      {
        title: 'LATITUD',
        dataIndex: 'latitud',
        key: 'latitud',
        width: '200px',
        render: (value, record) => (
          <InputCustom
            required
            initialValue={value}
            field={`road_emergency[${record.id}].latitud`}
          />
        ),
      },
      {
        title: 'LONGITUD',
        dataIndex: 'longitud',
        key: 'longitud',
        width: '200px',
        render: (value, record) => (
          <InputCustom
            required
            initialValue={value}
            field={`road_emergency[${record.id}].longitud`}
          />
        ),
      },
    ],
    [],
  );

  const handleTableChange = (current) => {
    setPagination((prev) => ({ ...prev, current }));
  };

  const handleSendPoints = () => {
    form.validateFields((err, values) => {
      if (err)
        return notification.warning({
          description: 'Ha ocurrido un error.',
          message: 'Cargar puntos',
        });

      values.road_emergency = Object.values(values.road_emergency)
        ?.filter(Boolean)
        .map((el) => ({
          ...el,
          source: 'manual',
          fechaActualizacion: el.fechaActualizacion
            ? moment(el.fechaActualizacion).format('DD/MM/YYYY')
            : undefined,
          fechaEmergencia: el.fechaEmergencia
            ? moment(el.fechaEmergencia).format('DD/MM/YYYY')
            : undefined,
          state: 1,
          idEmergenciaVial: 0,
        }));

      postRoadEmergencyPoints(values.road_emergency)
        .then((created) => {
          if (!created) throw Error('Error');

          notification.success({
            description: 'Se cargo los puntos correctamente.',
            message: '¡Puntos cargados!',
          });
        })
        .catch(() => {
          notification.warning({
            description: 'Ha ocurrido un error.',
            message: 'Cargar puntos',
          });
        })
        .finally(() => {
          setOpenForm(false);
          setUploadPoints(false);
        });
    });
  };

  useEffect(() => {
    if (rows.length > 0)
      setPagination({ ...pagination, current: 1, total: rows.length });
  }, [rows]);

  useEffect(() => {
    queryAction && setOpenForm(true);
  }, [queryAction]);

  return (
    <>
      <MainTitle
        buttonAction={() => setOpenForm(true)}
        renderItem={renderItem}
        isMobileScreen={isMobileScreen}
      />

      {widthScreen > 767 ? (
        rows.length === 0 ? (
          <TableMessage
            complete={true}
            message='Aún no se ha cargado puntos de emergencia vial'
          />
        ) : (
          <section className='show-data mt-4'>
            {rows.length === 0 ? (
              <Skeleton active title={false} paragraph={{ rows: 1 }} />
            ) : pagination.total === 1 ? (
              <p className='info-total-data'>
                Se cargo <span>{pagination.total}</span> punto de emergencia
                vial
              </p>
            ) : (
              <p className='info-total-data'>
                Se cargaron <span>{pagination.total}</span> puntos de emergencia
                vial
              </p>
            )}
            <Table
              dataSource={rows}
              className='table_common'
              columns={columns}
              locale={{
                emptyText: 'Aún no se ha cargado puntos de emergencia vial.',
              }}
              pagination={{
                ...pagination,
                onChange: handleTableChange,
                className: 'table-pagination',
              }}
            />
            <Button
              type='primary'
              size='large'
              className='ml-auto'
              style={{ display: 'block' }}
              onClick={() => setUploadPoints(true)}
            >
              Enviar puntos
            </Button>
          </section>
        )
      ) : (
        <section className='content-mobile action-bottom'>
          {rows.length === 0 ? (
            <Skeleton active title={false} paragraph={{ rows: 1 }} />
          ) : pagination.total === 1 ? (
            <p className='info-total-data'>
              Se cargo <span>{pagination.total}</span> punto de emergencia vial
            </p>
          ) : (
            <p className='info-total-data'>
              Se cargaron <span>{pagination.total}</span> puntos de emergencia
              vial
            </p>
          )}
        </section>
      )}

      <ModalForm
        visible={openForm}
        columns={columns}
        handleClose={() => {
          setOpenForm(false);
        }}
        setRows={setRows}
      />

      <ModalConfirm
        visible={uploadPoints}
        onClose={() => setUploadPoints(false)}
        title='¿Seguro que desea enviar la data?'
        image={DeleteModalImage}
        actionsContent={
          <div className='actions-delete-modal mt-2'>
            <Button
              type='primary'
              size='large'
              className='pl-4 pr-4'
              onClick={handleSendPoints}
            >
              Enviar
            </Button>
          </div>
        }
      />
    </>
  );
};

export default RoadEmergencies;
