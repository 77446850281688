import { useEffect, useRef } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

const LayerRoad = ({ layerKey }) => {
  const map = useMap();
  const refUtilLayer = useRef(null);

  useEffect(() => {
    if (!refUtilLayer.current) {
      refUtilLayer.current = L.tileLayer.wms(
        `${process.env.REACT_APP_GEOSERVER_MAPS_NEW}`,
        {
          layers: `cmo:${layerKey}`,
          tileSize: 4096,
          format: 'image/png',
          tiled: true,
          transparent: true,
          tileBase: true,
        },
      );

      refUtilLayer.current.addTo(map);
      refUtilLayer.current.getContainer().style.zIndex = 201;
    }

    return () => {
      if (refUtilLayer.current) {
        map.removeLayer(refUtilLayer.current);
      }
    };
  }, []);

  return null;
};

export default LayerRoad;
