import { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  RideButton,
  RideSelect,
  RideTextField,
} from '@rimac-seguros/ride-system-components';
import { useForm } from 'react-hook-form';
import {
  getCompaniesNoDispatch,
  getCompanyByUuid,
} from '../../../../../services/company';
import { listAllRols, listFormRols } from '../../../../../services/rols';
import {
  updateUserNew,
  createUserNew,
  getUserByUuid,
} from '../../../../../services/users';
import { checkUser } from '../../../../../lib/auth';
import openNotification from '../../../../../common/Notification';
import { yupResolver } from '@hookform/resolvers/yup';
import { createValidationSchema } from './utils';
import { debounce } from '../../../../../lib/utils';
import { Skeleton } from 'antd';

const FormUser = () => {
  const companiesRef = useRef([]);
  const user = useSelector((state) => state.auth.user);
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    watch,
    clearErrors,
    formState: { errors },
  } = useForm({ resolver: yupResolver(createValidationSchema()) });
  const { uuid } = useParams();
  const navigate = useNavigate();

  const [state, setState] = useState({
    companies: [],
    rols: [],
    loadingCompanies: false,
    loadingRoles: false,
    loadingUser: false,
    loading: false,
  });

  const setLoading = (field, value) =>
    setState((prev) => ({ ...prev, [field]: value }));

  const checkEmailExistence = debounce(async (email) => {
    const response = await checkUser(email);
    if (response.data.success) {
      setError('username', {
        type: 'manual',
        message: 'Este correo electrónico ya ha sido registrado.',
      });
    } else {
      clearErrors('username');
    }
  }, 700);

  const fetchCompanies = useCallback(async (query = '') => {
    setLoading('loadingCompanies', true);
    try {
      const response = await getCompaniesNoDispatch({
        page: 1,
        limit: 20,
        query,
      });
      setState((prev) => ({ ...prev, companies: response.list }));
    } catch {
      openNotification('error', 'Error', 'Error al obtener las empresas');
    } finally {
      setLoading('loadingCompanies', false);
    }
  }, []);

  const fetchRols = useCallback(async () => {
    setLoading('loadingRoles', true);
    try {
      const response =
        user.rol.slug === 'admin_rimac'
          ? await listAllRols()
          : await listFormRols();
      setState((prev) => ({ ...prev, rols: response }));
    } catch {
      openNotification('error', 'Error', 'Error al obtener los roles');
    } finally {
      setLoading('loadingRoles', false);
    }
  }, [user]);

  const fetchUser = useCallback(async () => {
    if (!uuid) return;
    setLoading('loadingUser', true);
    try {
      const response = await getUserByUuid(uuid);
      const user = response.payload.user;
      const companyExists = companiesRef.current.some(
        (company) => company.id === user.company.uuid,
      );

      if (!companyExists) {
        const companyResponse = await getCompanyByUuid(user.company.uuid);
        companiesRef.current.push(companyResponse.company);
        setState((prev) => ({
          ...prev,
          companies: [...prev.companies, companyResponse.company],
        }));
      }

      Object.keys(user).forEach((field) => setValue(field, user[field]));
      setValue('company_id', user.company_id);
      setValue('rol_id', user.rol_id);
      setValue('state', user.state);
    } catch {
      openNotification('error', 'Error', 'Error al obtener el usuario');
    } finally {
      setLoading('loadingUser', false);
    }
  }, [uuid, setValue]);

  const onSubmit = async (data) => {
    setLoading('loading', true);
    try {
      const updateData = {
        name: data.name,
        last_name1: data.last_name1,
        last_name2: data.last_name2,
        position: data.position,
        phone: data.phone,
        company_id: data.company_id,
        rol_id: data.rol_id,
        state: data.state,
      };
      const response = uuid
        ? await updateUserNew({ ...updateData, uuid })
        : await createUserNew({ ...updateData, username: data.username });

      const success = uuid
        ? response.payload.success
        : response.data.payload.success;

      if (!success) {
        setError('username', {
          type: 'manual',
          message: 'Este correo electrónico ya ha sido registrado.',
        });
        return;
      }

      localStorage.setItem(
        'userSuccess',
        uuid
          ? 'Usuario actualizado correctamente.'
          : 'Usuario creado correctamente.',
      );
      navigate(
        user.rol.slug === 'admin_rimac'
          ? '/administracion/usuarios'
          : '/administracion/perfil-empresa',
      );
    } catch (error) {
      openNotification(
        'error',
        'Error',
        uuid ? 'Error al actualizar el usuario' : 'Error al crear el usuario',
      );
    } finally {
      setLoading('loading', false);
    }
  };

  useEffect(() => {
    fetchUser();
    fetchCompanies();
    fetchRols();
  }, [fetchCompanies, fetchRols, fetchUser]);

  if (state.loadingUser) {
    return <Skeleton active style={{ width: '376px', alignSelf: 'start' }} />;
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {[
        { label: 'Nombre', name: 'name', required: true },
        { label: 'Apellido Paterno', name: 'last_name1', required: true },
        { label: 'Apellido Materno', name: 'last_name2' },
        {
          label: 'Correo electrónico',
          name: 'username',
          required: true,
          disabled: !!uuid,
          onChange: (e) => checkEmailExistence(e.target.value),
        },
        { label: 'Teléfono', name: 'phone' },
        { label: 'Cargo', name: 'position', required: true },
      ].map(({ label, name, required, ...props }) => (
        <RideTextField
          key={name}
          className='mb-2'
          label={label}
          {...register(name)}
          required={required}
          error={errors[name]}
          errorMessage={errors[name]?.message}
          {...props}
        />
      ))}

      <RideSelect
        {...register('company_id')}
        label='Selecciona una empresa'
        options={state.companies.map((option) => ({
          label: option.name,
          value: option.id,
        }))}
        className='mb-2'
        combobox={true}
        watchFieldRHF={watch('company_id')}
        loading={state.loadingCompanies}
        onInput={debounce((e) => fetchCompanies(e.target.value), 500)}
        required={true}
        error={errors.company_id}
        errorMessage={errors.company_id?.message}
      />

      <RideSelect
        {...register('rol_id')}
        label='Selecciona un rol'
        options={state.rols.map((option) => ({
          label: option.name,
          value: option.id,
        }))}
        className='mb-2'
        watchFieldRHF={watch('rol_id')}
        loading={state.loadingRoles}
        required={true}
        error={errors.rol_id}
        errorMessage={errors.rol_id?.message}
      />

      {uuid && user.rol.slug === 'admin_rimac' && (
        <RideSelect
          {...register('state')}
          label='Estado'
          options={[
            { label: 'Activo', value: 1 },
            { label: 'Inactivo', value: 2 },
            { label: 'Eliminado', value: 3 },
          ]}
          className='mb-2'
          watchFieldRHF={watch('state')}
          required={true}
          error={errors.state}
          errorMessage={errors.state?.message}
        />
      )}

      <RideButton
        type='primary'
        size='x-large'
        htmlType='submit'
        fullWidth
        loading={state.loading}
        disabled={
          state.loading ||
          [
            'name',
            'last_name1',
            'username',
            'position',
            'company_id',
            'rol_id',
          ].some((field) => !watch(field)) ||
          !!Object.keys(errors).length
        }
        text={uuid ? 'Actualizar usuario' : 'Crear usuario'}
      />
    </form>
  );
};

export default FormUser;
