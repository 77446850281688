import React, { useState } from 'react';

const FiltersList = ({ defaultFilter, data, onChange, className }) => {
  const [filterSelected, setFilterSelected] = useState(
    defaultFilter !== undefined ? defaultFilter : 0,
  );

  const changeFilter = (index) => {
    setFilterSelected(index);
    onChange(index);
  };

  return (
    <div className='portal-filters-list'>
      <div className='portal-filters-list-box'>
        <div className={`portal-filters-list-box-2 ${className ?? ''}`}>
          {data.map((item, index) => (
            <div
              key={`filterList-${item}-${index}`}
              className={`portal-filters-list__item ${filterSelected === index ? 'portal-filters-list__item--selected' : ''}`}
              onClick={() => changeFilter(index)}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FiltersList;
