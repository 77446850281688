import { get, post } from '../lib/ajax';

export const reportIndicators = () =>
  new Promise(async (resolve, reject) => {
    let r = await post('/request_service_items/report_indicators');
    if (!r.data?.success) {
      reject(new Error('La petición falló'));
    }

    if (!r.data?.running) {
      reject(new Error('El reporte no se está ejecutando'));
    }

    const intervalId = setInterval(async () => {
      r = await get('/request_service_items/report_indicators_status');

      if (!r.data || !r.data.success) {
        clearInterval(intervalId);
        reject(new Error('Falló la petición de estado del reporte'));
      }

      const { payload } = r.data;
      const config = payload ? JSON.parse(payload.value) : {};

      if (config?.error) {
        clearInterval(intervalId);
        reject(new Error('Error al generar el reporte'));
      } else if (config?.fileDownloadPath) {
        clearInterval(intervalId);
        resolve(config.fileDownloadPath);
      }
    }, 6000);
  });
