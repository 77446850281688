import React, { useCallback } from 'react';
import { Button, Form } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import { optionsFilter } from './utils';
import RightDrawer from '../../../common/RightDrawer';
import InputWithLabel, {
  RangePickerWithLabel,
  SelectWithLabel,
} from '../../../common/InputWithLabel';

const listOfFilters = [
  {
    key: 'prod',
    type: 'int',
    label: 'Prod',
  },
  {
    key: 'ruc',
    type: 'str',
    label: 'Ruc',
  },
  {
    key: 'nombre_cliente',
    type: 'str',
    label: 'Cliente',
  },
  {
    key: 'direccion_del_riesgo',
    type: 'str',
    label: 'Dirección del riesgo',
  },
  {
    key: 'prod_descripcion',
    type: 'str',
    label: 'Prod Descripcion',
  },
  {
    key: 'poliza',
    type: 'str',
    label: 'Póliza',
  },
  {
    key: 'vigencia_inicio',
    type: 'date',
    label: 'Inicio de Vigencia',
  },
  {
    key: 'vigencia_fin',
    type: 'date',
    label: 'Fin de Vigencia',
  },
  {
    key: 'monto_valor',
    type: 'int',
    label: 'Monto Valor',
  },
  {
    key: 'valor_declarado_np',
    type: 'int',
    label: 'Valor Declarado NP',
  },
];

const DrawerFiltersCumulus = ({
  onClose,
  visible,
  setFiltersLocal,
  filtersLocal,
  othersFilters,
  onCleanFilters,
}) => {
  const [form] = Form.useForm();
  const { getFieldsValue } = form;

  const handleSearch = useCallback(() => {
    const valuesSelect = getFieldsValue(
      listOfFilters.map((el) => `op-${el.key}`),
    );
    const valuesInputs = getFieldsValue(listOfFilters.map((el) => el.key));

    let filters = [];

    for (let { key, type } of listOfFilters) {
      if (valuesSelect[`op-${key}`] && valuesInputs[key]) {
        filters.push({
          key,
          type,
          op: valuesSelect[`op-${key}`],
          value: valuesInputs[key],
        });
      }
    }
    setFiltersLocal(filters);
    onClose();
  }, [getFieldsValue, setFiltersLocal, onClose]);

  const renderFormItem = (type, key, filtersLocal) => {
    const initialValue = filtersLocal.find((el) => el.key === key)?.value;

    if (type === 'number') {
      return (
        <Form.Item name={key} initialValue={initialValue || 0}>
          <InputWithLabel label='Valor' type='number' />
        </Form.Item>
      );
    }

    if (type === 'date') {
      return (
        <Form.Item name={key} initialValue={initialValue || undefined}>
          <RangePickerWithLabel
            label='Valor'
            hiddenRange
            style={{ width: '100%' }}
          />
        </Form.Item>
      );
    }

    return (
      <Form.Item name={key} initialValue={initialValue || ''}>
        <InputWithLabel label='Valor' />
      </Form.Item>
    );
  };

  return (
    <RightDrawer
      visible={visible}
      title={'Filtra tu búsqueda'}
      hiddenDivider
      onCloseDrawer={onClose}
      content={
        <div>
          {othersFilters}
          {listOfFilters.map(({ type, key, label }) => (
            <>
              <Form.Item
                key={key}
                name={`op-${key}`}
                initialValue={filtersLocal.find((el) => el.key === key)?.op}
              >
                <SelectWithLabel
                  options={optionsFilter(type).map(({ lable, ...options }) => ({
                    ...options,
                    label: lable,
                  }))}
                  label={label}
                />
              </Form.Item>
              {renderFormItem(type, key, filtersLocal)}
            </>
          ))}
          <div className='flex justify-between mt-3 mb-3'>
            <Button
              type='link'
              icon={<DeleteOutlined />}
              className='button-purple-link'
              onClick={onCleanFilters}
            >
              Limpiar
            </Button>
            <Button
              className='rounded-4xl text-base'
              type='primary'
              style={{ textTransform: 'capitalize', fontWeight: 'bold' }}
              onClick={handleSearch}
            >
              Buscar
            </Button>
          </div>
        </div>
      }
    />
  );
};

export default DrawerFiltersCumulus;
