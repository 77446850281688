import { statusAdminColors, statusClientColors } from './ServiceRequestList';

const CardHeader = ({ state, userSlug }) => {
  const { color, status, dot } = ['admin_rimac', 'gestor_de_cuenta'].includes(
    userSlug,
  )
    ? statusAdminColors(state)
    : statusClientColors(state);

  return (
    <div
      className='flex flex-row flex-justify-justify-between'
      style={{ height: '20px' }}
    >
      <span
        style={{ background: color, borderRadius: 6, padding: '2px 8px' }}
        className={'withpoint'}
      >
        <div style={{ background: dot }} className={'withpoint-circle'}></div>
        <span>{status}</span>
      </span>
    </div>
  );
};
export default CardHeader;
