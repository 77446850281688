import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Card } from 'antd';
import {
  RideButton,
  RideGlError,
  RideGlFiltersSolid,
} from '@rimac-seguros/ride-system-components';
import dayjs from 'dayjs';

import { eventTypes } from '../../../../../lib/consts';
import { useScreen } from '../../../../../lib/screen';
import { getNewslettersToClient } from '../../../../../services/newsletters';
import { getLocations } from '../../../../../services/locations';

import Skeleton from '../../../../../common/Skeleton';
import Pagination from '../../../../../common/Pagination';
import Empty from '../../../../../common/Empty';
import Divider from '../../../../../common/Divider';
import NewsletterCard from './NewsletterCard';
import TypeFilterPanel from './TypeFilterPanel';
import NewsletterDrawer from './NewsletterDrawer';

export const FILTERS = [
  {
    type: 'select',
    name: 'type',
    label: 'Tipos de boletín',
    placeholder: 'Todos los tipos',
    options: eventTypes.map((type) => ({ label: type.name, value: type.id })),
  },
  {
    type: 'radioGroup',
    name: 'status',
    label: 'Vigencia del boletín',
    options: [
      { text: 'Todos los boletines', value: '' },
      { text: 'Solo vigentes', value: 'active' },
      { text: 'Solo no vigentes', value: 'inactive' },
    ],
  },
  {
    type: 'radioGroup',
    name: 'scope',
    label: 'Impacto según ubicaciones',
    options: [
      { text: 'Afecta todas las ubicaciones', value: 'all' },
      { text: 'Afectan mis ubicaciones', value: 'own' },
    ],
  },
  { type: 'rangePicker', name: 'date', label: 'Fecha del boletín' },
];

export const initialFilters = { scope: 'own', status: '', date: ['', ''] };

const NewsletterCardList = () => {
  const { isMobileScreen } = useScreen();
  const dispatch = useDispatch();

  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const locations = useSelector((state) => state.locations.list || []);
  const { current, pageSize, total } = useSelector(
    (state) => state.newsletters.pagination,
  );
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState(initialFilters);

  const parseFilters = (filters) => {
    const { type, status, date, ...rest } = filters;

    return {
      ...rest,
      ...(type ? { type: [Number(type)] } : {}),
      ...(status ? { status } : {}),
      ...(Boolean(date?.[0]) && Boolean(date?.[1])
        ? { date: { start: date[0], end: date[1] } }
        : {}),
    };
  };

  const fetchNewsletters = async ({ page, filters }) => {
    setIsLoading(true);
    const { list } = await getNewslettersToClient(dispatch, {
      page,
      limit: pageSize,
      filters: parseFilters(filters),
    });
    setList(list);
    setIsLoading(false);
  };

  const handleChangePagination = async (page) => {
    await fetchNewsletters({ page, filters });
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await getLocations(dispatch);
      await fetchNewsletters({ page: 1, filters });
    };

    fetchData();
  }, []);

  const handleTypeClick = async (typeId) => {
    const updatedFilters = { ...filters, type: typeId };
    setFilters(updatedFilters);
    await fetchNewsletters({ page: 1, filters: updatedFilters });
  };

  const handleGetNewsletters = async (filters) => {
    setOpen(false);
    setFilters(filters);
    await fetchNewsletters({ page: 1, filters });
  };

  return (
    <div className='newsletterList'>
      <p className='newsletterList__description'>
        Información detallada de los últimos eventos desde el más reciente
      </p>
      {isMobileScreen ? (
        <RideButton
          className='w-full'
          text='Todos los filtros'
          size='large'
          glyphPosition='left'
          glyph={<RideGlFiltersSolid />}
          onClick={() => setOpen(true)}
        />
      ) : (
        <TypeFilterPanel
          filters={filters}
          handleTypeClick={handleTypeClick}
          setOpen={setOpen}
        />
      )}
      <NewsletterDrawer
        open={open}
        onClose={() => setOpen(false)}
        onSave={(filters) => handleGetNewsletters(filters)}
        filters={filters}
      />
      {isMobileScreen && (
        <>
          <div className='flex flex-wrap gap-2'>
            {Object.entries(filters).map((keyValue) => {
              const [key, value] = keyValue;
              let text;

              if (key === 'date' && (!value[0] || !value[1])) return null;

              if (key === 'type') {
                text = eventTypes.find(
                  (type) => type.id === Number(value),
                )?.name;
              }

              if (key === 'scope' || key === 'status') {
                const options = FILTERS.find(
                  (filter) => filter.name === key,
                )?.options;
                text = options.find((option) => option.value === value)?.text;
              }

              if (key === 'date') {
                const dateStart = dayjs(value[0], 'DD/MM/YYYY').format(
                  'DD/MM/YYYY',
                );
                const dateEnd = dayjs(value[1], 'DD/MM/YYYY').format(
                  'DD/MM/YYYY',
                );

                text = `${dateStart} - ${dateEnd}`;
              }

              return (
                <span className='newslettersMobileChips'>
                  {text}
                  <button
                    className='newslettersMobileChips__close'
                    aria-label={`Eliminar filtro: ${text}`}
                    onClick={() =>
                      setFilters((prev) => {
                        const { [key]: _, ...rest } = prev;
                        return rest;
                      })
                    }
                  >
                    <RideGlError />
                  </button>
                </span>
              );
            })}
          </div>
          <Divider style={{ margin: '12px 0 24px' }} />
        </>
      )}
      {isLoading ? (
        <>
          {Array.from({ length: 5 }, (_, i) => i + 1).map((_, key) => (
            <Card key={key}>
              <Skeleton paragraph={{ rows: 6 }} />
            </Card>
          ))}
        </>
      ) : (
        <div className='flex flex-col gap-6'>
          {list.length === 0 ? (
            locations.length === 0 ? (
              <Empty
                title={'Aún no tienes boletines disponibles'}
                description={'Agrega una o más ubicaciones para ver boletines'}
              />
            ) : (
              <Empty
                title={'No se encontraron boletines'}
                description={'Prueba usando otros filtros para más resultados'}
              />
            )
          ) : (
            <>
              {list.map(
                ({
                  id,
                  detected_condition,
                  detected_condition_red,
                  event,
                  type,
                  disabled,
                }) => (
                  <NewsletterCard
                    title={`${detected_condition ?? ''} ${
                      detected_condition_red ?? ''
                    }`}
                    id={id}
                    key={id}
                    event={event}
                    type={type}
                    disabled={disabled}
                  />
                ),
              )}
              <Pagination
                current={current}
                pageSize={pageSize}
                total={total}
                onChange={handleChangePagination}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default NewsletterCardList;
