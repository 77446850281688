import { post, put, get } from '../lib/ajax';

import {
  LOADED_HELP_CENTER,
  LOAD_HELP_CENTER_LOADING,
  LOADING_CREATE_HELP_CENTER,
} from '../redux/actions/actionsType';

export const getRequestSupport = async (obj, dispatch) => {
  let success = false;
  try {
    dispatch({ type: LOAD_HELP_CENTER_LOADING, payload: true });
    const r = await post('/help_center/list', obj);
    if (r.data.success) {
      dispatch({
        type: LOADED_HELP_CENTER,
        payload: r.data.payload,
      });
      success = true;
    }
  } catch (e) {
    console.error('error', e);
  } finally {
    dispatch({ type: LOAD_HELP_CENTER_LOADING, payload: false });
  }
  return success;
};

export const getTicket = async (id, dispatch) => {
  let ticket = null;
  dispatch({ type: LOAD_HELP_CENTER_LOADING, payload: true });
  const r = await get('/help_center/getTicketById?id=' + id);
  if (r.data.success) {
    ticket = r.data.ticket;
  }
  dispatch({ type: LOAD_HELP_CENTER_LOADING, payload: false });
  return ticket;
};

export const createTicket = async (ticket, dispatch, obj) => {
  dispatch && dispatch({ type: LOADING_CREATE_HELP_CENTER, payload: true });
  const r = await post('/support_tickets/create', ticket);
  if (r.data.success) {
    await getRequestSupport(obj, dispatch);
    dispatch && dispatch({ type: LOADING_CREATE_HELP_CENTER, payload: false });

    return true;
  }

  dispatch && dispatch({ type: LOADING_CREATE_HELP_CENTER, payload: false });

  return false;
};

export const removeTicket = async (data, obj, dispatch) => {
  dispatch({ type: LOAD_HELP_CENTER_LOADING, payload: true });
  const r = await post('/help_center/remove', data);
  if (r.data.success) {
    await getRequestSupport(obj, dispatch);
  }
};

export const listUserRequest = async () => {
  try {
    const r = await get('/help_center/listUsers');
    let payload = null;
    if (r.data.success) {
      payload = r.data.payload;
    }
    return payload;
  } catch (e) {
    return null;
  }
};

export const listComments = async (id) => {
  try {
    const r = await get(`/help_center/listComments?id=${id}`);
    if (r.data.success) {
      return r.data.payload;
    }

    return false;
  } catch (e) {
    return false;
  }
};

export const createComment = async (data) => {
  try {
    const r = await post('/help_center/createComment', data);
    let payload = null;

    if (r.data.success) {
      payload = r.data.payload;
    }

    return payload;
  } catch (e) {
    return null;
  }
};

export const closeTicket = async (ticket) => {
  try {
    const r = await put('/support_tickets/closeTicket', ticket);
    return r.data.success;
  } catch (e) {
    return false;
  }
};
