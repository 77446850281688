import React, { useState } from 'react';
import Button from '../Button';

const Star = ({ className, onMouseOver, onMouseLeave, onClick }) => (
  <div
    onClick={onClick}
    className={`feedback__star ${className}`}
    onMouseOver={onMouseOver}
    onMouseLeave={onMouseLeave}
  >
    <svg
      width='32'
      height='32'
      viewBox='0 0 32 32'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M16.3279 25.7969L22.3828 29.1214C23.6509 29.8179 25.1408 28.6781 24.8872 27.2217L23.746 20.1928C23.7143 19.9395 23.7777 19.6862 23.9679 19.5279L28.8181 14.557C29.8326 13.5121 29.262 11.7074 27.8671 11.4858L21.1148 10.4726C20.8612 10.4409 20.671 10.2826 20.5759 10.061L17.5643 3.66531C16.9302 2.33552 15.0916 2.33552 14.4576 3.66531L11.446 10.061C11.3509 10.2826 11.129 10.4409 10.907 10.4726L4.15472 11.4858C2.72817 11.7074 2.15755 13.5121 3.20369 14.557L8.08565 19.5279C8.24415 19.7178 8.33926 19.9711 8.30756 20.1928L7.10292 27.2217C6.84931 28.7098 8.33926 29.8179 9.6073 29.1214L15.6622 25.7969C15.8524 25.7019 16.106 25.7019 16.3279 25.7969Z' />
    </svg>
  </div>
);

const Feedback = ({ visible = true, onClose, onOk }) => {
  const [comment, setComment] = useState('');
  const [starHover, setStarHover] = useState(0);
  const [starSelected, setStarSelected] = useState(0);
  const [finished, setFinished] = useState(false);

  const handleSendFeedback = () => {
    onOk(starSelected, comment);
    setFinished(true);
  };

  const getText = (star) => {
    if (star <= 0) return '';

    if (star <= 2) return 'Cuéntanos, ¿Qué fue lo que no te gustó?';
    else if (star === 3) return 'Cuéntanos, ¿En qué podríamos mejorar?';
    else
      return '¿Qué fue lo que más te gustó? ¿Qué otros temas te gustaría encontrar?';
  };

  return (
    visible && (
      <div>
        <div className='article-feedback'>
          <div className='content'>
            <div className='close-box'>
              <div className='close-icon' onClick={onClose}>
                <svg
                  width='32'
                  height='32'
                  viewBox='0 0 32 32'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <circle cx='16' cy='16' r='15.5' />
                  <path d='M11 11L20.9996 20.9996' strokeLinecap='round' />
                  <path d='M21 11L11.0004 20.9996' strokeLinecap='round' />
                </svg>
              </div>
            </div>
            {!finished ? (
              <>
                <div className='title'>
                  ¿Qué tan útil consideras este contenido?
                </div>
                <div className='feedback'>
                  <div className='feedback__stars'>
                    {[1, 2, 3, 4, 5].map((star) => (
                      <Star
                        key={star + '-star'}
                        className={
                          starHover >= star ||
                          (starHover === 0 && starSelected >= star)
                            ? 'feedback__star--selected'
                            : ''
                        }
                        onClick={() => setStarSelected(star)}
                        onMouseOver={() => setStarHover(star)}
                        onMouseLeave={() => setStarHover(0)}
                      />
                    ))}
                  </div>
                  <div className='feedback__categories'>
                    <div className='feedback__category'>POCO ÚTIL</div>
                    <div className='feedback__category'>MUY ÚTIL</div>
                  </div>
                </div>
                <div className='form'>
                  {starSelected > 0 && (
                    <>
                      <p className='form__question'>{getText(starSelected)}</p>
                      <textarea
                        className='form__textarea'
                        placeholder='Escribe tu comentario (Opcional)'
                        value={comment}
                        onChange={(event) => {
                          setComment(event.target.value);
                        }}
                      />
                      <Button className='medium' onClick={handleSendFeedback}>
                        &nbsp;ENVIAR&nbsp;
                      </Button>
                    </>
                  )}
                </div>
              </>
            ) : (
              <div className='finished'>
                <img
                  className='finished__img'
                  src='/images/portal/article_finish-evaluation.svg'
                  alt=''
                  aria-hidden
                />
                <div className='finished__title'>
                  <span className='finished__title--strong'>¡Listo!</span> Hemos
                  recibido tu respuesta
                </div>
                <div className='finished__description'>
                  Gracias por tu tiempo y por ayudarnos a generar mejor
                  contenido para ti.
                </div>
                <div className='finished__button-container'>
                  <Button className='medium' onClick={onClose}>
                    ENTENDIDO
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          onClick={onClose}
          style={{
            position: 'fixed',
            backgroundColor: '#494F66',
            opacity: 0.75,
            left: 0,
            top: 0,
            width: '100%',
            height: '100vh',
            zIndex: 5,
          }}
        ></div>
      </div>
    )
  );
};

export default Feedback;
