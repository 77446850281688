import React, { memo } from 'react';
import moment from 'moment';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

moment.locale('es');

const CardHistoryMobile = ({ data }) => {
  return (
    <article
      className='card-user card-external-action'
      style={{
        margin: '16px 0px',
      }}
    >
      <div>
        <h4>NOMBRE DE ACTUALIZACIÓN</h4>
        <p>{data?.name}</p>
      </div>

      <div>
        <h4>USUARIO CREADOR</h4>
        <p>{`${data?.user?.name} ${data?.user?.last_name1}`}</p>
      </div>

      <div>
        <h4>FECHA DE SUBIDA</h4>
        <p>{moment(data?.createdAt).format('DD/MM/YYYY')}</p>
      </div>

      <Button
        style={{
          textAlign: 'start',
          border: 'none',
          borderRadius: 0,
          background: 'none',
          marginLeft: 'auto',
        }}
        type='link'
        className='link'
        onClick={() => {
          window.open(`${data?.url}`, '_blank');
        }}
      >
        <DownloadOutlined />
        Descargar
      </Button>
    </article>
  );
};

export default memo(CardHistoryMobile);
