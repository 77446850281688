import { patch, post, put, getPathCredentials } from '../lib/ajax';
import { signedAxios } from '../lib/axios';
import { PATH_API_AUTH } from '../lib/utils';

import {
  LOAD_CONFLICT_LOADING,
  LOADED_CONFLICT,
  LOADING_CREATE_CONFLICT,
  OPEN_FORM_CONFLICT,
  LOADING_BULK_CONFLICTS,
  CONFLICTS_PROGRESS,
  UPDATE_CONFLICT_STORE,
  UPDATE_PAGINATION_HISTORY_CONFLICTS,
} from '../redux/actions/actionsType';

const urlQuery = `${process.env.REACT_APP_API_BASE_URL_NEW}${PATH_API_AUTH}`;

export const getConflict = async ({
  dispatch,
  states = [],
  origin = [],
  types = [],
  company = '',
  activities = [],
  date = [],
  department = '',
}) => {
  try {
    dispatch({ type: LOAD_CONFLICT_LOADING, payload: true });
    const r = await post(`/conflict/listFiltered`, {
      states,
      origin,
      types,
      company,
      activities,
      date,
      department,
    });
    if (r.data.success) {
      dispatch({ type: LOADED_CONFLICT, payload: r.data.payload.list });
      dispatch({ type: LOAD_CONFLICT_LOADING, payload: false });
    }
  } catch (e) {
    dispatch({ type: LOAD_CONFLICT_LOADING, payload: false });
  }
};

export const createConflict = async (conflict, dispatch, states) => {
  dispatch({ type: LOADING_CREATE_CONFLICT, payload: true });

  try {
    const r = await post('/conflict/create', conflict);

    if (r.data.success) {
      dispatch({ type: OPEN_FORM_CONFLICT, payload: false });
      dispatch({ type: LOADING_CREATE_CONFLICT, payload: false });
      getConflict({ dispatch, states });

      return true;
    }
    dispatch && dispatch({ type: LOADING_CREATE_CONFLICT, payload: false });

    return false;
  } catch (e) {
    dispatch && dispatch({ type: LOADING_CREATE_CONFLICT, payload: false });

    return false;
  }
};

export const updateConflict = async ({ data, dispatch, filters }) => {
  dispatch({ type: LOADING_CREATE_CONFLICT, payload: true });

  try {
    const r = await put('/conflict/update', data);

    if (r.data.success) {
      dispatch({ type: OPEN_FORM_CONFLICT, payload: false });
      dispatch({ type: LOADING_CREATE_CONFLICT, payload: false });
      getConflict({ dispatch, ...filters });

      return true;
    }

    dispatch({ type: LOADING_CREATE_CONFLICT, payload: false });

    return false;
  } catch (e) {
    dispatch({ type: LOADING_CREATE_CONFLICT, payload: false });

    return false;
  }
};

export const removeConflict = async ({ id, dispatch, filters }) => {
  try {
    const r = await patch('/conflict/remove?id=' + id);
    if (r.data.success) {
      getConflict({ dispatch, ...filters });

      return true;
    }

    return false;
  } catch (e) {
    return false;
  }
};

export const bulkConflicts = async (data, dispatch, states) => {
  dispatch({ type: LOADING_BULK_CONFLICTS, payload: true });

  try {
    const { url, credentials } = await getPathCredentials(
      `${urlQuery}/conflict/bulk_conflicts`,
    );
    const r = await signedAxios(
      {
        method: 'POST',
        url,
        data,
        onUploadProgress: ({ loaded, total }) => {
          dispatch({
            type: CONFLICTS_PROGRESS,
            payload: (loaded / total) * 100,
          });
        },
      },
      { credentials },
    );

    dispatch({ type: LOADING_BULK_CONFLICTS, payload: false });

    if (r.data.success) {
      getConflict({ dispatch, states });
      return { ...r.data.payload, success: true };
    } else {
      return { success: false };
    }
  } catch (error) {
    return { success: false };
  }
};

export const getConflictsHistory = async (data, dispatch) => {
  dispatch({
    type: UPDATE_CONFLICT_STORE,
    payload: {
      loadingHistory: true,
    },
  });
  const r = await post('/conflict/list_history', data);
  if (r.data && r.data.success && r.data.payload) {
    dispatch({
      type: UPDATE_CONFLICT_STORE,
      payload: {
        listHistory: r.data.payload.list,
      },
    });
    dispatch({
      type: UPDATE_PAGINATION_HISTORY_CONFLICTS,
      payload: {
        total: r.data.payload.total,
      },
    });
  }

  dispatch({
    type: UPDATE_CONFLICT_STORE,
    payload: {
      loadingHistory: false,
    },
  });
};

export const registerConflict = async (data, dispatch) => {
  try {
    const r = await post('/conflict/registerConflict', data);
    if (r.data.success) {
      getConflictsHistory({ page: 1 }, dispatch);
    }
  } catch (error) {
    throw error;
  }
};
