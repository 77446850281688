import React, { Fragment, memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Card, Skeleton, Select, Divider, Input, message } from 'antd';
import { debounce } from '../../../../lib/utils';
import moment from 'moment';

import { useScreen } from '../../../../lib/screen';
import { getListClients } from '../../../../services/clientTracking';
import { CLIENT_TRACKING_SET_FILTERS } from '../../../../redux/actions/actionsType';

import { columns, filtersCommon } from './columns';
import SimpleTable from '../../../../common/Table/SimpleTable';
import Title from '../../../../common/Typograph/Title';
import FiltersMobile from '../../../../common/FiltersMobile';
import PaginationMobile from '../../../../common/PaginationMobile';
import CardUserMobile from '../common/CardUserMobile';
import {
  RideButton,
  RideGlDeleteSolid,
} from '@rimac-seguros/ride-system-components';

const initialFilterOptions = {
  state: [
    { label: 'Pendiente', value: 1 },
    { label: 'Respondido por el clientes', value: 2 },
    { label: 'Cerrado', value: 3 },
    { label: 'Rechazo', value: 4 },
  ],
};

const ListClients = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobileScreen } = useScreen();
  const [filtersOptions, setFiltersOptions] = useState(initialFilterOptions);
  const { list, isLoading, filters, pagination, zones } = useSelector(
    (state) => ({
      list: state.clientTracking.list,
      isLoading: state.clientTracking.isLoading,
      filters: state.clientTracking.filters,
      pagination: state.clientTracking.pagination,
      zones: state.zones.zones,
    }),
  );

  const setFilters = (data) =>
    dispatch({ type: CLIENT_TRACKING_SET_FILTERS, payload: data });

  const transformDates = (filters) => {
    const dates = filters.date;
    if (dates.length > 0) {
      const dateInitial = moment(dates[0]).format('YYYY-MM-DDT00:00:00-05:00');

      const dateFinal = moment(dates[1]).format('YYYY-MM-DDT23:59:59-05:00');

      return {
        end: dateFinal,
        start: dateInitial,
      };
    }
  };

  const getAlertsByFilters = useCallback(
    debounce(async (filters, page) => {
      await getListClients(dispatch, { filters, page });
    }, 800),
    [],
  );

  const handleViewDetails = (id) => {
    id
      ? navigate(`/administracion/seguimiento-a-clientes/${id}`)
      : message.info('No hay detalles para esta solicitud');
  };

  const handleTableChange = async (pag) => {
    const copyFilter = structuredClone(filters);
    if (filters.hasOwnProperty('date')) {
      copyFilter.date = transformDates(filters);
    }
    await getListClients(dispatch, { filters: copyFilter, page: pag.current });
  };

  const handleMobileChange = async (pag) => {
    const copyFilter = structuredClone(filters);
    if (filters.hasOwnProperty('date')) {
      copyFilter.date = transformDates(filters);
    }
    await getListClients(dispatch, { filters: copyFilter, page: pag });
  };

  useEffect(() => {
    getListClients(dispatch);
  }, []);

  const handleSelectOption = useCallback(
    (key, selected, filtered) => {
      switch (key) {
        case 'departments':
          const provinces = zones
            .find((zone) => zone.value === selected)
            .children.sort((a, b) => a.label.localeCompare(b.label));
          setFiltersOptions((prev) => ({ ...prev, provinces }));
          if (filters.hasOwnProperty('provinces')) {
            const { provinces, ...rest } = filters;
            setFilters({ ...rest, [key]: selected });
          } else {
            setFilters({ ...filters, [key]: selected });
          }

          break;
        case 'date':
          if (selected && selected.length > 0) {
            setFilters({
              ...filters,
              [key]: selected,
            });
          }

          break;
        default:
          setFilters({ ...filters, [key]: selected });
          if (filtered) {
            getAlertsByFilters({ ...filters, [key]: selected });
          }
      }
    },
    [filters, zones],
  );

  const onFinishFilters = useCallback(() => {
    const allFilters = { ...filtersOptions, ...filters };
    let filtersOpts = {};

    Object.keys(allFilters).forEach((key) => {
      if (typeof allFilters[key] !== 'object') {
        filtersOpts[key] = allFilters[key];
      } else {
        const filterOpts = allFilters[key].filter((item) => item.selected);
        if (filterOpts?.length) {
          filtersOpts[key] = filterOpts;
        }
      }
    });

    if (allFilters.hasOwnProperty('date')) {
      filtersOpts.date = transformDates(allFilters);
    }

    getAlertsByFilters(filtersOpts);
  }, [filters, filtersOptions]);

  const onClearFilters = () => {
    setFilters({});
    Object.keys(filtersOptions).forEach((name) => {
      setFiltersOptions((prev) => {
        const newcols = prev[name].map((el) => {
          return {
            ...el,
            selected: false,
          };
        });

        return { ...prev, [name]: newcols };
      });
    });
    getAlertsByFilters({});
  };

  return (
    <div>
      {!isMobileScreen ? (
        <>
          <div
            className={`flex justify-between ${
              isMobileScreen ? 'flex-col pt-2 mb-3' : 'items-center mt-1 mb-4'
            }`}
          >
            <Title>Filtrar</Title>
          </div>
          <div className='flex mb-4 gap-2'>
            {filtersCommon.map(({ key, placeholder, type, inputType, min }) => {
              switch (type) {
                case 'search':
                  return (
                    <Input.Search
                      key={key}
                      placeholder={placeholder}
                      label={placeholder}
                      type={inputType}
                      min={inputType === 'number' ? min : undefined}
                      onChange={(e) =>
                        handleSelectOption(key, e.target.value, 1)
                      }
                      style={{ width: 200 }}
                    />
                  );
                case 'select':
                  return (
                    <Select
                      key={key}
                      placeholder={placeholder}
                      options={filtersOptions[key] || []}
                      onChange={(value) => handleSelectOption(key, value, 1)}
                      value={filters[key]}
                      style={{ width: 200 }}
                    />
                  );
                default:
                  return null;
              }
            })}
            <RideButton
              variant='text-primary'
              onClick={onClearFilters}
              size='small'
              text='Borrar Filtros'
              glyph={<RideGlDeleteSolid />}
              glyphPosition='left'
            />
          </div>
        </>
      ) : null}

      <div className='flex justify-between items-center mt-2 mb-2'>
        {isMobileScreen ? (
          <FiltersMobile
            onClearFilters={onClearFilters}
            onFinishFilters={onFinishFilters}
            filters={filtersCommon.map(({ key, placeholder, type }) => ({
              key,
              label: placeholder,
              typeFilter: type,
              options: filtersOptions[key] || [],
              onChange: (selected) => {
                handleSelectOption(key, selected);
              },
              value: filters[key],
              placeholder,
            }))}
          />
        ) : null}
      </div>
      {isMobileScreen ? (
        <Fragment>
          <Divider />
          {isLoading ? (
            [1, 2, 3].map((i) => (
              <Card className='mt-2' key={i}>
                <Skeleton />
              </Card>
            ))
          ) : (
            <>
              {list.map((value) => (
                <CardUserMobile
                  key={value.id}
                  columns={columns}
                  item={{ ...value, 'poliza.company': value.poliza?.company }}
                />
              ))}
              <PaginationMobile
                current={pagination.page}
                total={pagination.total}
                pageSize={5}
                onChange={handleMobileChange}
              />
            </>
          )}
        </Fragment>
      ) : null}
      {!isMobileScreen ? (
        <SimpleTable
          className={'mt-4 mb-4'}
          loading={isLoading}
          columns={columns}
          dataSource={
            list
              ? list.map((item) => ({
                  ...item,
                  onViewDetils: () => handleViewDetails(item.id),
                }))
              : []
          }
          pagination={{
            current: pagination.page,
            total: pagination.total,
          }}
          onChange={handleTableChange}
        />
      ) : null}
    </div>
  );
};

export default memo(ListClients);
