import React, {
  useCallback,
  useMemo,
  useRef,
  useEffect,
  useState,
} from 'react';
import { USER_TYPE_LOCAL } from './types';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  RideTextField,
  RideButton,
  RideErrorMessage,
  RideEiMicrosoft,
  RideGlViewOutline,
  RideGlViewOffOutline,
} from '@rimac-seguros/ride-system-components';

const Form = ({ onOk, error, working, handleShowRecovery, doLogin }) => {
  const username = useRef();
  const userType = useSelector((state) => state.auth.userType || null);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const { handleSubmit, register, errors, setValue, watch } = useForm({
    mode: 'onBlur',
    defaultValues: {
      username: '',
      password: '',
    },
  });

  username.current = watch('username', '');

  const onSubmit = useCallback(
    (data) => {
      data.username = data.username.toLowerCase().trim();
      onOk(data);
    },
    [onOk],
  );

  const usernameRules = useMemo(
    () => ({
      required: 'Por favor inserta el usuario',
      pattern: {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        message: 'Por favor inserta un correo correcto',
      },
    }),
    [],
  );

  const passwordRules = useMemo(
    () => ({
      required:
        userType === USER_TYPE_LOCAL
          ? 'Por favor inserta la contraseña'
          : false,
    }),
    [userType],
  );

  useEffect(() => {
    register('username', usernameRules);
    register('password', passwordRules);
  }, [register, usernameRules, passwordRules]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='login-form'>
      {error && <RideErrorMessage message={error} />}
      <RideTextField
        className='mb-1'
        name='username'
        label='Correo electrónico'
        onChange={(e) => setValue('username', e.target.value)}
        error={errors?.username ? 'error' : ''}
        errorMessage={errors?.username ? errors?.username.message : null}
        required
      />
      <RideTextField
        className='mb-1'
        name='password'
        label='Contraseña'
        type={isPasswordVisible ? 'text' : 'password'}
        glyph={
          isPasswordVisible ? <RideGlViewOffOutline /> : <RideGlViewOutline />
        }
        glyphPosition='right'
        onGlyphClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setIsPasswordVisible((prev) => !prev);
        }}
        onChange={(e) => setValue('password', e.target.value)}
        error={errors?.password ? 'error' : ''}
        errorMessage={errors?.password ? errors?.password.message : null}
        required
      />
      <a
        className='ride-sys-text-label-medium-default'
        onClick={handleShowRecovery}
      >
        ¿Olvidaste tu contraseña?
      </a>
      <RideButton
        className='mt-3'
        text='Ingresar'
        type='submit'
        size='x-large'
        loading={working}
        disabled={watch('username') === '' || watch('password') === ''}
      />
      <div className='hr-container mb-1 mt-1'>
        <hr style={{ width: '20%' }} />
        <span className='ride-sys-text-title-small-default'>
          Si eres colaborador Rimac
        </span>
        <hr style={{ width: '20%' }} />
      </div>
      <RideButton
        glyph={<RideEiMicrosoft />}
        variant='outline'
        glyphPosition='left'
        text='Ingresar con Microsoft'
        size='x-large'
        onClick={doLogin}
      />
    </form>
  );
};

export default Form;
