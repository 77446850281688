/* eslint-disable max-len */
/* eslint-disable no-sparse-arrays */
const provinces = {
  type: 'FeatureCollection',
  crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
  features: [
    {
      type: 'Feature',
      properties: {
        COUNT: 9,
        FIRST_IDPR: '0301',
        NOMBPROV: 'ABANCAY',
        FIRST_NOMB: 'APURIMAC',
        LAST_DCTO: 'LEY',
        LAST_LEY: 'S/N',
        FIRST_FECH: '28/12/1961',
        LAST_FECHA: '21/11/1893',
        MIN_SHAPE_: 345827.33624,
        ha: 345827.34,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.77286310729582, -13.44888060615733],
            [-72.74544885727006, -13.48289535618898],
            [-72.65676810718655, -13.513354981217296],
            [-72.63687585716781, -13.542142481244067],
            [-72.56304210709817, -13.551416231252636],
            [-72.42139785696428, -13.606079731303355],
            [-72.39982223194383, -13.641849606336626],
            [-72.43722248197923, -13.680828106372985],
            [-72.4603832320011, -13.790212606474867],
            [-72.5014251070399, -13.746939856434642],
            [-72.57356248210807, -13.73184960642066],
            [-72.66958073219861, -13.7703394814566],
            [-72.6401103571708, -13.818876981501763],
            [-72.64976373217992, -13.858234356538452],
            [-72.73580073226093, -13.914496106590908],
            [-72.72685410725249, -13.947320606621442],
            [-72.78892148231088, -13.980461606652353],
            [-72.81720523233749, -14.095454981759454],
            [-72.85123060736944, -14.094065606758203],
            [-72.89235385740804, -14.170393856829284],
            [-72.98028910749053, -14.118336106780857],
            [-73.01973635752752, -14.112623106775542],
            [-73.02449048253197, -13.992040856663243],
            [-73.03760348254426, -13.905277356582472],
            [-73.0737414825781, -13.896989731574765],
            [-73.17189510766997, -13.803636981487854],
            [-73.16030335765913, -13.776788481462841],
            [-73.10308035760559, -13.763053606450056],
            [-73.06053485756574, -13.72044373141036],
            [-72.97774010748815, -13.70026973139154],
            [-72.94969923246187, -13.656394481350656],
            [-73.04174998254814, -13.541160106243368],
            [-73.1263957326274, -13.48646235619247],
            [-73.160164357659, -13.443338231152328],
            [-73.10338123260587, -13.448589231157188],
            [-73.0151257325232, -13.42666885613675],
            [-72.97146198248227, -13.390228481102813],
            [-72.8940861074097, -13.390126606102687],
            [-72.88630810740239, -13.415865356126659],
            [-72.79341323231516, -13.426124481136167],
            [-72.77286310729582, -13.44888060615733],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 8,
        FIRST_IDPR: '0902',
        NOMBPROV: 'ACOBAMBA',
        FIRST_NOMB: 'HUANCAVELICA',
        LAST_DCTO: 'LEY',
        LAST_LEY: '5292',
        FIRST_FECH: '23/11/1925',
        LAST_FECHA: '23/11/1925',
        MIN_SHAPE_: 92636.7425352,
        ha: 92636.74,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.32361998374415, -12.832509980583527],
            [-74.31944998374027, -12.887279980634533],
            [-74.37409185879116, -12.93537310567933],
            [-74.45317573386482, -12.966566355708375],
            [-74.47503910888517, -12.930904355675155],
            [-74.53459760894064, -12.900158230646522],
            [-74.59273448399479, -12.894712855641448],
            [-74.69918360909394, -12.843642605593883],
            [-74.72607235911896, -12.841449605591869],
            [-74.78597373417476, -12.78410698053844],
            [-74.76728435915736, -12.676474730438203],
            [-74.778727359168, -12.63270923039746],
            [-74.71162960910551, -12.634272855398917],
            [-74.68646285908208, -12.589855480357544],
            [-74.63990035903872, -12.645996105409811],
            [-74.5444277339498, -12.722841855481406],
            [-74.54245898394798, -12.743378855500513],
            [-74.4656073588764, -12.804886730557799],
            [-74.3962773588118, -12.806066355558892],
            [-74.32361998374415, -12.832509980583527],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 7,
        FIRST_IDPR: '0802',
        NOMBPROV: 'ACOMAYO',
        FIRST_NOMB: 'CUSCO',
        LAST_DCTO: 'LEY',
        LAST_LEY: 'S/N',
        FIRST_FECH: '11/11/1964',
        LAST_FECHA: '02/01/1857',
        MIN_SHAPE_: 93285.8080742,
        ha: 93285.81,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.51687885609567, -13.967218731635247],
            [-71.44033985602026, -14.10366598176137],
            [-71.4407147310206, -14.104997856762607],
            [-71.4469459810267, -14.10816373176563],
            [-71.4485539810283, -14.108264231765743],
            [-71.45563948103523, -14.10630448176398],
            [-71.45827773103781, -14.107183856764836],
            [-71.46091598104039, -14.108741606766317],
            [-71.46141848104088, -14.110173856767641],
            [-71.46056435604004, -14.111832106769182],
            [-71.45915710603865, -14.115601106772667],
            [-71.46722085604647, -14.128808356785063],
            [-71.47030460604947, -14.130423606786609],
            [-71.47669210605572, -14.133874481789885],
            [-71.50288560608135, -14.125624106782487],
            [-71.50571285608414, -14.122087731779251],
            [-71.51023073108857, -14.121262731778511],
            [-71.51123785608955, -14.12098073177826],
            [-71.5144607310927, -14.11940960677686],
            [-71.52083823109895, -14.114160231772034],
            [-71.52596260610396, -14.109905981768105],
            [-71.53176398110965, -14.10513598176372],
            [-71.534181106112, -14.102750981761535],
            [-71.53566373111347, -14.09965698175868],
            [-71.53621160611401, -14.098851231757918],
            [-71.53862885611638, -14.097046356756273],
            [-71.539263231117, -14.096455231755733],
            [-71.544381981122, -14.091887856751539],
            [-71.54847898112602, -14.088701106748605],
            [-71.54961710612712, -14.088132106748086],
            [-71.55279410613025, -14.085278231745468],
            [-71.55739360613472, -14.083456731743814],
            [-71.5581677311355, -14.083866606744198],
            [-71.55926060613653, -14.086097981746283],
            [-71.56171973113894, -14.088875856748917],
            [-71.5684596061455, -14.092655606752484],
            [-71.57357798115048, -14.096593106756195],
            [-71.57622448115302, -14.098735481758215],
            [-71.5811394811578, -14.100499856759903],
            [-71.58428998116086, -14.101823106761154],
            [-71.58447910616106, -14.10339848176263],
            [-71.58378598116037, -14.105603856764679],
            [-71.58391198116047, -14.10900648176784],
            [-71.58918210616561, -14.116404856774775],
            [-71.5902414811666, -14.122231231780214],
            [-71.59156448116788, -14.12584573178359],
            [-71.59258398116887, -14.127689481785314],
            [-71.59332010616959, -14.129778981787258],
            [-71.59456448117079, -14.13289848179017],
            [-71.59483985617103, -14.139105481795962],
            [-71.59581835617197, -14.14274610679934],
            [-71.59538910617151, -14.146529356802859],
            [-71.59477348117092, -14.148880856805043],
            [-71.59572660617184, -14.149886731805992],
            [-71.69970898127299, -14.116285106775605],
            [-71.72938285630211, -13.975175731644455],
            [-71.80913673137937, -13.894519481569873],
            [-71.8010137313716, -13.851103481529433],
            [-71.8344422314039, -13.811415356492661],
            [-71.78976173136108, -13.698355481387182],
            [-71.70330285627745, -13.759524481443542],
            [-71.7016819812757, -13.819790606499637],
            [-71.64755360622327, -13.82337048150257],
            [-71.62017573119647, -13.897267856571096],
            [-71.5629361061408, -13.905745856578493],
            [-71.56239473114017, -13.934712356605424],
            [-71.51687885609567, -13.967218731635247],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 5,
        FIRST_IDPR: '0202',
        NOMBPROV: 'AIJA',
        FIRST_NOMB: 'ANCASH',
        LAST_DCTO: 'LEY',
        LAST_LEY: '8188',
        FIRST_FECH: '21/12/1907',
        LAST_FECHA: '05/03/1936',
        MIN_SHAPE_: 69639.6314397,
        ha: 69639.63,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.96140236214384, -9.780244102740122],
            [-77.86785611205548, -9.763784477724977],
            [-77.78079836197332, -9.800669477759476],
            [-77.76036136195404, -9.787990227747677],
            [-77.72481636192046, -9.696347602662387],
            [-77.62129886182282, -9.620830102592189],
            [-77.54161136174778, -9.705886727671476],
            [-77.53625148674276, -9.737866352701255],
            [-77.52145311172883, -9.792271727751924],
            [-77.48895311169824, -9.819837852777638],
            [-77.47890423668876, -9.873769477827864],
            [-77.55249023675806, -9.872179727826332],
            [-77.73470898692986, -9.84685347780254],
            [-77.77979098697244, -9.905056602856686],
            [-77.82553323701563, -9.908369102859693],
            [-77.85388511204232, -9.817092602774652],
            [-77.90312111208885, -9.822988227780039],
            [-77.96140236214384, -9.780244102740122],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 6,
        FIRST_IDPR: '1602',
        NOMBPROV: 'ALTO AMAZONAS',
        FIRST_NOMB: 'LORETO',
        LAST_DCTO: 'LEY',
        LAST_LEY: 'S/N',
        FIRST_FECH: '08/09/1964',
        LAST_FECHA: '02/01/1857',
        MIN_SHAPE_: 1917212.65818,
        ha: 1917212.66,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.28970223557552, -3.691222972070084],
            [-76.24528636053412, -3.751056972125797],
            [-76.24734523553605, -3.785567222157947],
            [-76.20639511049788, -3.844648972212966],
            [-76.20315036049483, -3.924626972287452],
            [-76.17262423546639, -3.95543672231616],
            [-76.14975723544508, -4.06320447241652],
            [-76.05806636035962, -4.156654222503569],
            [-76.02307223532699, -4.218744097561393],
            [-75.97630648528342, -4.331656597666558],
            [-75.95586661026437, -4.42507197275357],
            [-75.90108148521334, -4.457598847783846],
            [-75.86261798517751, -4.520229722842191],
            [-75.89053886020353, -4.556810472876259],
            [-75.8816254851952, -4.617766597933035],
            [-75.85139261016703, -4.658876972971304],
            [-75.74308198506615, -4.734873098042089],
            [-75.737573485061, -4.825937598126899],
            [-75.72233436004684, -4.871654723169476],
            [-75.664541109993, -4.933185848226792],
            [-75.5286093598664, -4.93751947323082],
            [-75.41660935976209, -4.987201098277091],
            [-75.51004485984909, -5.1122930983936],
            [-75.59948235993241, -5.16702934844457],
            [-75.6180272349497, -5.202722598477811],
            [-75.56015810989577, -5.267109348537792],
            [-75.56966310990462, -5.300831723569197],
            [-75.63333423496394, -5.410136223670977],
            [-75.61679873494853, -5.462345723719612],
            [-75.64013423497028, -5.496783848751689],
            [-75.57234960990715, -5.572287098822007],
            [-75.49732223483724, -5.588177723836802],
            [-75.4547519847976, -5.573701223823329],
            [-75.51278710985166, -5.6784667239209],
            [-75.51447998485321, -5.739259973977536],
            [-75.53414648487156, -5.902345724129417],
            [-75.56700973490216, -5.958365224181591],
            [-75.56892960990395, -6.00038859922073],
            [-75.61389448494583, -6.023240224242013],
            [-75.62895998495986, -6.082119974296855],
            [-75.68036998500774, -6.095629974309417],
            [-75.71501998504002, -6.138269974349131],
            [-75.81482986013299, -6.100899974314328],
            [-75.83445061015126, -6.139095474349911],
            [-75.91589186022712, -6.141157849351833],
            [-75.96543523527328, -6.095198099309019],
            [-76.03210261033543, -6.09966409931318],
            [-76.12721098542403, -6.176705099384955],
            [-76.22363086051394, -6.166031224374997],
            [-76.27324086056016, -6.104386349317592],
            [-76.3182966106022, -6.08609497430054],
            [-76.37278711065302, -5.973759724195926],
            [-76.41226948568983, -5.950664099174414],
            [-76.54845298581694, -6.022454724241259],
            [-76.64001136090242, -6.002604724222798],
            [-76.71076948596853, -6.013665974233096],
            [-76.75443473600934, -5.998390724218856],
            [-76.74672811100213, -5.954027599177535],
            [-76.81805123606883, -5.862144724091966],
            [-76.83545111108508, -5.745555348983378],
            [-76.89453711114034, -5.746232473984006],
            [-76.86950823611693, -5.703556098944266],
            [-76.8294762360795, -5.695789473937041],
            [-76.81443286106543, -5.656720973900633],
            [-76.84445111109348, -5.608960848856155],
            [-76.81246686106358, -5.590994098839415],
            [-76.85081836109946, -5.532111348784587],
            [-76.77507811102865, -5.449205098707379],
            [-76.71691873597429, -5.481269848737254],
            [-76.64085548590323, -5.448052723706301],
            [-76.62539661088877, -5.367423098631214],
            [-76.58753898585343, -5.314335848581791],
            [-76.56601948583332, -5.233083973506099],
            [-76.52227648579249, -5.240088473512627],
            [-76.48307311075591, -5.124099348404608],
            [-76.46959173574334, -5.005667973294288],
            [-76.35927148564042, -5.03158984831845],
            [-76.22447236051472, -5.018454473306206],
            [-76.16939648546337, -5.021734348309256],
            [-76.07173086037233, -5.083402848366697],
            [-76.00115423530659, -5.100263598382385],
            [-75.91916986023018, -5.084498098367698],
            [-75.88914698520222, -5.048344973334047],
            [-75.84009086015652, -5.02503572331232],
            [-75.82950198514666, -4.976644473267271],
            [-76.00712498531215, -4.85198247315116],
            [-76.03447448533764, -4.816308598117915],
            [-76.07092411037158, -4.833702223134139],
            [-76.10782223540599, -4.789224598092705],
            [-76.17194136046574, -4.76883009807372],
            [-76.16238086045684, -4.70469922301398],
            [-76.20438473549599, -4.674285097985645],
            [-76.19700786048911, -4.624333972939144],
            [-76.26324223555086, -4.528945347850291],
            [-76.32253698560616, -4.470795597796132],
            [-76.3379129856205, -4.418653222747578],
            [-76.31995311060373, -4.35841409769146],
            [-76.22721286051727, -4.245290972586101],
            [-76.23650848552595, -4.187121347531929],
            [-76.33046673561354, -4.11448634746429],
            [-76.34947261063127, -4.044148472398773],
            [-76.39073236066976, -4.002261722359761],
            [-76.42235348569926, -3.925494097288253],
            [-76.41400386069148, -3.864357847231319],
            [-76.375977110656, -3.80131109717261],
            [-76.33251048561546, -3.762375097136347],
            [-76.32775973561102, -3.699384722077674],
            [-76.28970223557552, -3.691222972070084],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 8,
        FIRST_IDPR: '1002',
        NOMBPROV: 'AMBO',
        FIRST_NOMB: 'HUANUCO',
        LAST_DCTO: 'LEY',
        LAST_LEY: '9165',
        FIRST_FECH: '21/09/1943',
        LAST_FECHA: '05/09/1940',
        MIN_SHAPE_: 157735.850657,
        ha: 157735.85,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.49397611076607, -10.310531228234836],
            [-76.5128944857837, -10.291843728217437],
            [-76.52091986079121, -10.194937478127184],
            [-76.51000786078103, -10.152273353087468],
            [-76.41305861069056, -10.140865228076832],
            [-76.37437298565446, -10.149460978084836],
            [-76.28678323557278, -10.071123103011885],
            [-76.17037886046427, -9.985285102931941],
            [-76.13620898543243, -9.986281227932889],
            [-76.12531248542227, -10.066923853007975],
            [-76.04364448534616, -10.09904097803788],
            [-76.06381048536495, -10.1308378530675],
            [-76.06738086036829, -10.194599603126884],
            [-76.03937498534219, -10.236693353166073],
            [-76.04340348534592, -10.329727103252717],
            [-76.02595111032969, -10.426138728342499],
            [-76.031669860335, -10.45592772837027],
            [-76.13048636042707, -10.429730478345846],
            [-76.22303898551336, -10.374882853294764],
            [-76.27112148555818, -10.371095728291243],
            [-76.30587998559061, -10.412169978329517],
            [-76.33578998561849, -10.286319978212289],
            [-76.44393998571938, -10.320529978244155],
            [-76.49397611076607, -10.310531228234836],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 19,
        FIRST_IDPR: '0302',
        NOMBPROV: 'ANDAHUAYLAS',
        FIRST_NOMB: 'APURIMAC',
        LAST_DCTO: 'LEY',
        LAST_LEY: '9910',
        FIRST_FECH: 'EPOCA INDEP.',
        LAST_FECHA: '20/01/1944',
        MIN_SHAPE_: 403553.439719,
        ha: 403553.44,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.74788773320773, -13.763366106450404],
            [-73.67371348313856, -13.730739356420004],
            [-73.62896098309682, -13.736580106425436],
            [-73.57040298304223, -13.596258356294763],
            [-73.52844123300305, -13.615618106312779],
            [-73.49207560796911, -13.57732948127714],
            [-73.48346485796107, -13.506310606211006],
            [-73.44538985792552, -13.430359981140288],
            [-73.40332335788626, -13.444750606153669],
            [-73.34716335783382, -13.434832231144412],
            [-73.23390323272797, -13.476586981183294],
            [-73.21192923270742, -13.419101856129755],
            [-73.160164357659, -13.443338231152328],
            [-73.1263957326274, -13.48646235619247],
            [-73.04174998254814, -13.541160106243368],
            [-72.94969923246187, -13.656394481350656],
            [-72.97774010748815, -13.70026973139154],
            [-73.06053485756574, -13.72044373141036],
            [-73.10308035760559, -13.763053606450056],
            [-73.16030335765913, -13.776788481462841],
            [-73.17189510766997, -13.803636981487854],
            [-73.24501035773834, -13.815059356498514],
            [-73.28557410777627, -13.893540606571596],
            [-73.35454173284072, -13.92655548160234],
            [-73.35380423284003, -13.984338856656159],
            [-73.37875085786332, -14.033554231701999],
            [-73.37865360786323, -14.091851106756279],
            [-73.40981248289233, -14.107335981770714],
            [-73.36876623285399, -14.310735231960118],
            [-73.4090347328916, -14.336091231983755],
            [-73.45312823293278, -14.402548607045622],
            [-73.50701173298306, -14.544308607177644],
            [-73.5089759829849, -14.541253232174789],
            [-73.51605610799149, -14.536246607170145],
            [-73.52224548299729, -14.526986107161518],
            [-73.52237185799738, -14.524459107159183],
            [-73.52199510799706, -14.51812723215327],
            [-73.52283673299782, -14.513788732149234],
            [-73.52385648299877, -14.511588232147176],
            [-73.53117235800562, -14.508448232144252],
            [-73.53162760800605, -14.507880357143739],
            [-73.53246223300683, -14.507242232143122],
            [-73.58174998305279, -14.416134732058289],
            [-73.5338354830081, -14.354294107000687],
            [-73.51224998298795, -14.26815823192049],
            [-73.61110160808018, -14.088718731753378],
            [-73.6183222330869, -14.026134731695112],
            [-73.65647073312249, -13.92995123160554],
            [-73.67915035814366, -13.916318356592841],
            [-73.697779358161, -13.847415981528691],
            [-73.74788773320773, -13.763366106450404],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 12,
        FIRST_IDPR: '0903',
        NOMBPROV: 'ANGARAES',
        FIRST_NOMB: 'HUANCAVELICA',
        LAST_DCTO: 'LEY',
        LAST_LEY: '9354',
        FIRST_FECH: '30/10/1984',
        LAST_FECHA: '28/02/1941',
        MIN_SHAPE_: 194061.71484,
        ha: 194061.71,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.27165148369573, -13.066972105801867],
            [-74.34587635876488, -13.02584460576358],
            [-74.38622998380245, -13.029309980766811],
            [-74.40800998382277, -13.069269980804023],
            [-74.4204257338343, -13.159539105888078],
            [-74.45843885886971, -13.190038105916477],
            [-74.54447260894983, -13.199394480925186],
            [-74.57098148397453, -13.184670480911484],
            [-74.64465423404313, -13.274136730994808],
            [-74.6860564840817, -13.286370981006202],
            [-74.72212410911528, -13.289642106009259],
            [-74.72164085911486, -13.179189605906389],
            [-74.8414236092264, -13.143722980873365],
            [-74.9132578592933, -13.16746685589545],
            [-74.91705835929683, -13.096578605829448],
            [-74.88493110926693, -12.926299605670868],
            [-74.82710923421307, -12.943060105686463],
            [-74.78293710917193, -12.877952855625825],
            [-74.73343948412584, -12.879488230627281],
            [-74.69918360909394, -12.843642605593883],
            [-74.59273448399479, -12.894712855641448],
            [-74.53459760894064, -12.900158230646522],
            [-74.47503910888517, -12.930904355675155],
            [-74.45317573386482, -12.966566355708375],
            [-74.37409185879116, -12.93537310567933],
            [-74.31944998374027, -12.887279980634533],
            [-74.29655323371892, -12.93500873067899],
            [-74.27165148369573, -13.066972105801867],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 9,
        FIRST_IDPR: '0803',
        NOMBPROV: 'ANTA',
        FIRST_NOMB: 'CUSCO',
        LAST_DCTO: 'LEY',
        LAST_LEY: 'S/N',
        FIRST_FECH: '01/10/1941',
        LAST_FECHA: '14/11/1896',
        MIN_SHAPE_: 194375.589563,
        ha: 194375.59,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.00060323156397, -13.444038231151426],
            [-72.05142148161251, -13.48983823119425],
            [-72.06146085662206, -13.52794385622976],
            [-72.1030896066618, -13.553344981253552],
            [-72.1612033567172, -13.548673606249377],
            [-72.16457760672039, -13.606284856303041],
            [-72.13220698168949, -13.639304731333665],
            [-72.16785310672347, -13.649528481343275],
            [-72.22867410678134, -13.714055356403511],
            [-72.2774178568277, -13.684148481375788],
            [-72.29916585684835, -13.704445356394737],
            [-72.40174810694565, -13.659505856353073],
            [-72.39982223194383, -13.641849606336626],
            [-72.42139785696428, -13.606079731303355],
            [-72.56304210709817, -13.551416231252636],
            [-72.63687585716781, -13.542142481244067],
            [-72.65676810718655, -13.513354981217296],
            [-72.74544885727006, -13.48289535618898],
            [-72.77286310729582, -13.44888060615733],
            [-72.72208198224808, -13.404720731116186],
            [-72.61639910714852, -13.382089731095013],
            [-72.54668160708277, -13.332609356048883],
            [-72.53335198207019, -13.372895356086365],
            [-72.44368298198543, -13.344675731059972],
            [-72.36914335691488, -13.358814606073032],
            [-72.38579910693066, -13.304489731022493],
            [-72.26712448181812, -13.330239606046215],
            [-72.21952173177286, -13.326280981042464],
            [-72.20996035676374, -13.366779481080131],
            [-72.1155499816738, -13.398214481109157],
            [-72.0986953566577, -13.431034606139658],
            [-72.05065435661183, -13.453669981160575],
            [-72.00060323156397, -13.444038231151426],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 7,
        FIRST_IDPR: '0303',
        NOMBPROV: 'ANTABAMBA',
        FIRST_NOMB: 'APURIMAC',
        LAST_DCTO: 'LEY',
        LAST_LEY: '13677',
        FIRST_FECH: '12/12/1942',
        LAST_FECHA: '18/08/1961',
        MIN_SHAPE_: 323079.869369,
        ha: 323079.87,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.01973635752752, -14.112623106775542],
            [-72.98028910749053, -14.118336106780857],
            [-72.89235385740804, -14.170393856829284],
            [-72.89929610741454, -14.217398731873054],
            [-72.8621652323797, -14.277481231929011],
            [-72.7517468572759, -14.260727356913335],
            [-72.70960598223625, -14.275555731927081],
            [-72.70775048223449, -14.34069060698775],
            [-72.67044823219935, -14.362172357007715],
            [-72.62719698215858, -14.341202231988166],
            [-72.61411698214626, -14.267104481919159],
            [-72.5698926071045, -14.254576231907414],
            [-72.47975648201933, -14.201673856858053],
            [-72.44344260698497, -14.226377606880995],
            [-72.41714148196002, -14.27960648193051],
            [-72.34184198188859, -14.318082106966193],
            [-72.36846873191384, -14.350917981996806],
            [-72.35762535690353, -14.435963857076006],
            [-72.39308198193714, -14.481849607118782],
            [-72.42746098196976, -14.479023482116217],
            [-72.50291023204115, -14.551661982183989],
            [-72.46169948200213, -14.605869607234386],
            [-72.47177123201166, -14.661398857286095],
            [-72.57724760711137, -14.686616857309744],
            [-72.64482110717515, -14.667281107291819],
            [-72.72470860725043, -14.675900482299905],
            [-72.7896957323116, -14.650213232276062],
            [-72.82857510734813, -14.702628107324887],
            [-72.84682460736528, -14.759823857378157],
            [-72.85200985737012, -14.748026857367185],
            [-72.85583698237373, -14.738699607358487],
            [-72.85428260737228, -14.731954232352196],
            [-72.85574223237366, -14.729164107349625],
            [-72.86563698238295, -14.710684107332396],
            [-72.87388098239067, -14.707973732329876],
            [-72.87440135739116, -14.708169607330085],
            [-72.8843459824005, -14.706392732328418],
            [-72.88539910740153, -14.705970107328032],
            [-72.89537048241087, -14.699224732321767],
            [-72.91223735742669, -14.696147232318895],
            [-72.91766985743182, -14.68536835730886],
            [-72.92127448243517, -14.685537982309022],
            [-72.93276310744595, -14.694385732317263],
            [-72.93443460744753, -14.695646857318446],
            [-72.95094648246304, -14.685386982308906],
            [-72.95150248246352, -14.685199232308722],
            [-72.95877060747037, -14.67719273230126],
            [-72.96502798247622, -14.666667982291484],
            [-72.97216010748294, -14.658021482283425],
            [-72.9830274824931, -14.655828857281387],
            [-72.98459935749456, -14.66000510728528],
            [-72.98593673249582, -14.660380482285625],
            [-72.99123910750082, -14.66061510728585],
            [-72.99596098250522, -14.662341857287455],
            [-72.99815798250731, -14.662616982287718],
            [-73.00121048251016, -14.658644357284007],
            [-73.01311998252132, -14.63267798225983],
            [-73.01466610752274, -14.630905857258181],
            [-73.01740035752533, -14.630714857258011],
            [-73.01638360752436, -14.620780857248757],
            [-73.01328548252147, -14.61434310724276],
            [-73.0142748575224, -14.585390982215797],
            [-72.99743910750662, -14.581401232212082],
            [-72.99776185750693, -14.482081107119583],
            [-72.98050010749074, -14.44114760708147],
            [-73.03806398254468, -14.390179357034022],
            [-73.04752348255353, -14.345033231991994],
            [-73.08264098258645, -14.328776106976846],
            [-73.04577535755189, -14.263098356915677],
            [-73.07634373258054, -14.206306606862814],
            [-73.04985848255573, -14.122137231784432],
            [-73.01973635752752, -14.112623106775542],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 6,
        FIRST_IDPR: '0203',
        NOMBPROV: 'ANTONIO RAYMONDI',
        FIRST_NOMB: 'ANCASH',
        LAST_DCTO: 'LEY',
        LAST_LEY: '15187',
        FIRST_FECH: '27/05/1987',
        LAST_FECHA: '26/10/1964',
        MIN_SHAPE_: 55914.8157329,
        ha: 55914.82,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.17387123640206, -9.159956227163216],
            [-77.14411373637415, -9.095664727103372],
            [-77.15239398638192, -9.033718977045675],
            [-77.11132611134339, -9.008357477022063],
            [-77.10314261133573, -8.972433601988612],
            [-77.03718748627394, -8.999289102013647],
            [-76.98010161122043, -9.062918102072903],
            [-76.94496473618754, -9.074415977083627],
            [-76.94181048618461, -9.128992227134438],
            [-76.95973436120138, -9.207748102207782],
            [-77.02686523626424, -9.30091997729456],
            [-77.07659761131085, -9.273109352268632],
            [-77.09353711132673, -9.212511727212197],
            [-77.14221286137236, -9.202418102202781],
            [-77.17387123640206, -9.159956227163216],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 29,
        FIRST_IDPR: '0401',
        NOMBPROV: 'AREQUIPA',
        FIRST_NOMB: 'AREQUIPA',
        LAST_DCTO: 'LEY',
        LAST_LEY: '14124',
        FIRST_FECH: 'EPOCA INDEP.',
        LAST_FECHA: '15/06/1962',
        MIN_SHAPE_: 962246.884826,
        ha: 962246.88,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.84327698040043, -15.923255858439164],
            [-70.84387973040103, -15.926207483441935],
            [-70.84111023039807, -15.930231233445568],
            [-70.8375473553942, -15.939167983453716],
            [-70.83683010539342, -15.940015733454477],
            [-70.83669285539327, -15.940960858455348],
            [-70.8359451053924, -15.945914233459925],
            [-70.83673098039309, -15.954703358468104],
            [-70.83934023039578, -15.95686723347023],
            [-70.8402328553967, -15.958013483471323],
            [-70.84130860539763, -15.971826608484191],
            [-70.8372344803933, -15.974691358486702],
            [-70.83437348039028, -15.97437285848628],
            [-70.82801060538353, -15.97825523348963],
            [-70.820869480376, -15.977913858489034],
            [-70.82340235537862, -15.982151983493079],
            [-70.82825473038358, -15.991058358501537],
            [-70.82910160538441, -15.995490108505688],
            [-70.82365423037858, -16.00087548351047],
            [-70.82097623037531, -16.02867698353617],
            [-70.81546785536939, -16.03558735854235],
            [-70.80408473035705, -16.053827233558817],
            [-70.80451198035747, -16.055133983560058],
            [-70.83006285538448, -16.05545235856139],
            [-70.83898160539388, -16.055112858561422],
            [-70.84516910540042, -16.05552098356204],
            [-70.86032110541626, -16.061744733568403],
            [-70.8653107304214, -16.068719858575065],
            [-70.86857610542467, -16.08103560858662],
            [-70.8781051054347, -16.081443733587363],
            [-70.8782196054348, -16.08298685858879],
            [-70.87648010543288, -16.087772358593174],
            [-70.87547298043181, -16.089256233594526],
            [-70.87468723043091, -16.093425733598366],
            [-70.88480385544143, -16.10090060860568],
            [-70.88548273044215, -16.100917858605715],
            [-70.89376073045084, -16.102830858607792],
            [-70.89498135545209, -16.104389233609282],
            [-70.89570610545282, -16.106281233611057],
            [-70.8943709804514, -16.107505858612154],
            [-70.88356798043986, -16.12002185862338],
            [-70.88006585543616, -16.122217233625285],
            [-70.86758423042295, -16.12759785862981],
            [-70.86732485542264, -16.129478483631562],
            [-70.8688354804242, -16.131776858633746],
            [-70.8707733554262, -16.1342316086361],
            [-70.87200923042741, -16.141185733642605],
            [-70.87193298042732, -16.14287948364418],
            [-70.86746973042254, -16.14669410864755],
            [-70.86708073042212, -16.148456608649155],
            [-70.86782835542282, -16.153865858654218],
            [-70.87294010542813, -16.16014098366023],
            [-70.87417598042938, -16.161077483661153],
            [-70.87775423043313, -16.163766858663788],
            [-70.87824248043363, -16.164709108664674],
            [-70.87804410543336, -16.16799348366772],
            [-70.8743057304294, -16.171035733670404],
            [-70.87059023042546, -16.171380983670577],
            [-70.86509710541966, -16.172801983671693],
            [-70.86289210541732, -16.173803358672544],
            [-70.85982510541406, -16.176219983674656],
            [-70.85865023041278, -16.17822460867648],
            [-70.85487360540861, -16.189601858686895],
            [-70.86687473042079, -16.222392983717807],
            [-70.86632535542017, -16.225765233720914],
            [-70.86577610541958, -16.22627073372136],
            [-70.8621902304158, -16.226650233721575],
            [-70.86067960541422, -16.2257652337207],
            [-70.84447473039693, -16.23587798372944],
            [-70.86347198041676, -16.2545299837475],
            [-70.86757660542101, -16.258640233751482],
            [-70.86936948042288, -16.261642483754336],
            [-70.87078860542434, -16.26364323375625],
            [-70.87405398042765, -16.272651733764743],
            [-70.87553410542914, -16.278814358770504],
            [-70.88979335544414, -16.28267285877463],
            [-70.90342698045859, -16.27761073377044],
            [-70.92333223047939, -16.289125483781838],
            [-70.93962860549632, -16.30436710879657],
            [-70.9399413554966, -16.30747035879945],
            [-70.940353230497, -16.310317983802122],
            [-70.94625085550314, -16.313835108805577],
            [-70.95111848050831, -16.309747733801952],
            [-70.96125798051895, -16.308246483800897],
            [-70.96430973052216, -16.308765358801477],
            [-70.96891785552695, -16.308713858801596],
            [-70.97225948053047, -16.3088226088018],
            [-70.97508235553342, -16.308490733801595],
            [-70.98577885554451, -16.318965858811666],
            [-70.98635860554508, -16.320190483812826],
            [-70.98386385554241, -16.326950108819016],
            [-70.98001098053831, -16.33179860882339],
            [-70.97867585553674, -16.34721185883768],
            [-70.97276310553046, -16.353748358843543],
            [-70.9736099805313, -16.356397608846038],
            [-70.97576135553354, -16.36033435884976],
            [-70.9737548555314, -16.36396785885308],
            [-70.97190098052943, -16.365579608854514],
            [-70.9692764805266, -16.37110135885954],
            [-70.97309885553052, -16.378292108866347],
            [-70.97509760553247, -16.391029358878246],
            [-70.9745864805319, -16.392820358879902],
            [-70.97142785552859, -16.395166358881962],
            [-70.96881098052582, -16.395837733882498],
            [-70.96631623052319, -16.39714435888363],
            [-70.965194730522, -16.397367483883798],
            [-70.96446223052122, -16.3984622338848],
            [-70.964248605521, -16.39904210888533],
            [-70.96381373052051, -16.400220858886403],
            [-70.96424860552096, -16.401481608887593],
            [-70.9659271055227, -16.40426823389024],
            [-70.96741485552423, -16.40687560889271],
            [-70.96752935552435, -16.407636608893412],
            [-70.97480773053192, -16.410848608896647],
            [-70.97435760553132, -16.421895983906897],
            [-70.97370148053061, -16.42586535891055],
            [-70.9672088555237, -16.432941483916903],
            [-70.96021273051609, -16.45242123393475],
            [-70.9539718555094, -16.46459960894585],
            [-70.9560852305115, -16.47252085895328],
            [-70.95840448051395, -16.473495483954263],
            [-70.95957948051516, -16.47456360895529],
            [-70.9658813555217, -16.480720483961235],
            [-70.97169498052783, -16.480329483961068],
            [-70.97288510552909, -16.480005233960807],
            [-70.98401648054072, -16.48516660896596],
            [-70.98645785554322, -16.488836233969465],
            [-71.00309748056058, -16.497428858977973],
            [-71.00391385556145, -16.496959733977558],
            [-71.00804135556578, -16.496362733977143],
            [-71.00949098056728, -16.49725723397803],
            [-71.014007605572, -16.497339233978252],
            [-71.0151138555732, -16.49563598397669],
            [-71.01593785557405, -16.494914983976052],
            [-71.01606748057418, -16.494424983975602],
            [-71.0169829805752, -16.49105073397249],
            [-71.0171508555754, -16.490001733971525],
            [-71.02072148057914, -16.48675923396862],
            [-71.02574923058441, -16.486717233968736],
            [-71.03040310558927, -16.485847483968087],
            [-71.03440098059346, -16.485643358968012],
            [-71.04255673060207, -16.47435573395777],
            [-71.0428467306024, -16.473400108956895],
            [-71.04367060560324, -16.4727324839563],
            [-71.04389185560348, -16.4725093589561],
            [-71.04459385560426, -16.470630608954373],
            [-71.0418243556014, -16.46648973395045],
            [-71.04402160560373, -16.462669358946965],
            [-71.04746248060734, -16.460439733945],
            [-71.04759210560748, -16.458911858943587],
            [-71.04717260560707, -16.45674710894156],
            [-71.04811098060807, -16.455078108940043],
            [-71.05304723061315, -16.461566983946202],
            [-71.06391910562446, -16.4616469839466],
            [-71.07596585563695, -16.462625483947857],
            [-71.0782699806394, -16.45856473394416],
            [-71.08235173064365, -16.456935858942746],
            [-71.08310698064443, -16.457426108943242],
            [-71.08599848064743, -16.458564733944378],
            [-71.08690648064837, -16.458177483944034],
            [-71.09161373065328, -16.45758248394362],
            [-71.09308623065476, -16.460945108946774],
            [-71.0944747306562, -16.462999358948736],
            [-71.09915923066102, -16.46417048394995],
            [-71.10202785566402, -16.46380610894969],
            [-71.10546873066761, -16.461219733947374],
            [-71.11132810567364, -16.465383483951413],
            [-71.1134262306758, -16.46400835895018],
            [-71.11830898068088, -16.46279160894919],
            [-71.12753298069042, -16.462688483949336],
            [-71.12890623069184, -16.463918733950525],
            [-71.13225560569525, -16.466722483953212],
            [-71.13442998069749, -16.467670483954148],
            [-71.1361694806993, -16.469041858955478],
            [-71.13861085570174, -16.474670358960754],
            [-71.13951873070269, -16.47497373396107],
            [-71.14181523070506, -16.47454835896073],
            [-71.14623260570964, -16.47368235896004],
            [-71.1471557307106, -16.473724358960098],
            [-71.14860535571206, -16.473676733960094],
            [-71.1493072307128, -16.473560358960004],
            [-71.15203848071562, -16.47316360895971],
            [-71.15629573072002, -16.47136310895814],
            [-71.16759485573166, -16.4717693589588],
            [-71.17270660573693, -16.47159385895877],
            [-71.18269348074719, -16.472631483959987],
            [-71.18828585575295, -16.47230723395981],
            [-71.19405360575888, -16.47200773395967],
            [-71.2245254807902, -16.460559858949747],
            [-71.22750085579324, -16.46140860895059],
            [-71.23358148079944, -16.46544260895448],
            [-71.23620610580214, -16.466009108955074],
            [-71.23698423080292, -16.465831733954932],
            [-71.24270635580878, -16.463928233953276],
            [-71.24604798081216, -16.467639983956804],
            [-71.24358373080959, -16.47560310896415],
            [-71.24877935581489, -16.48184585897008],
            [-71.24934385581544, -16.482358983970553],
            [-71.24198148080782, -16.494029858981246],
            [-71.23916623080491, -16.49892048398572],
            [-71.23706823080275, -16.502115233988647],
            [-71.23613735580176, -16.50648298399268],
            [-71.24245448080812, -16.524251984009346],
            [-71.24279023080842, -16.527234984012118],
            [-71.24331660580887, -16.53954123402358],
            [-71.24713135581268, -16.554355609037433],
            [-71.25230410581784, -16.57092660905295],
            [-71.2527542308183, -16.572566984054475],
            [-71.26300048082854, -16.606939359086653],
            [-71.2669448558326, -16.606660859086485],
            [-71.27417760583997, -16.607513484087434],
            [-71.27751923084337, -16.611362484091085],
            [-71.28035735584612, -16.630117359108574],
            [-71.2804183558462, -16.63116460910955],
            [-71.28070835584634, -16.653860109130648],
            [-71.2812957308469, -16.658885984135342],
            [-71.28368373084922, -16.677421609152624],
            [-71.28879548085438, -16.68646998416114],
            [-71.29290773085857, -16.68996998416448],
            [-71.29398348085965, -16.691574109165987],
            [-71.29429623085996, -16.69422910916847],
            [-71.29477685586042, -16.696514109170604],
            [-71.2956619808613, -16.70294760917659],
            [-71.29601285586162, -16.7062663591797],
            [-71.29254135585805, -16.712400359185317],
            [-71.29045873085592, -16.713285484186112],
            [-71.28634648085169, -16.717388109189823],
            [-71.28800960585338, -16.719585359191907],
            [-71.28927610585465, -16.722717234194835],
            [-71.2975844808631, -16.732696359204294],
            [-71.29914098086465, -16.733238234204833],
            [-71.30091860586647, -16.73427385920583],
            [-71.30524448087087, -16.735603359207147],
            [-71.30578610587143, -16.73576160920732],
            [-71.31017298087589, -16.738191609209665],
            [-71.31241610587814, -16.742969484214157],
            [-71.31855010588437, -16.746442734217496],
            [-71.31888585588473, -16.746931109217968],
            [-71.3203811058862, -16.748233734219205],
            [-71.32358548088949, -16.750255609221153],
            [-71.32608035589199, -16.75217623422299],
            [-71.32796473089392, -16.75177760922265],
            [-71.3287048558947, -16.75082210922179],
            [-71.34367373090993, -16.744884484216563],
            [-71.34577173091206, -16.74306298421491],
            [-71.3473511059137, -16.741786984213743],
            [-71.34853360591492, -16.736440609208806],
            [-71.3485031059149, -16.734224359206742],
            [-71.35066223091712, -16.73003010920288],
            [-71.35052485591699, -16.727600234200615],
            [-71.35256960591906, -16.725616484198817],
            [-71.35633848092289, -16.72462273419796],
            [-71.35752110592412, -16.723731984197148],
            [-71.37158960593838, -16.720495234194413],
            [-71.37859348094544, -16.724588359198346],
            [-71.38037873094729, -16.725110984198864],
            [-71.38140873094831, -16.72558210919932],
            [-71.39233398095934, -16.730510734204103],
            [-71.39456173096158, -16.731199234204773],
            [-71.40030673096742, -16.728733109202597],
            [-71.40785235597504, -16.730543109204415],
            [-71.41075910597795, -16.732353234206133],
            [-71.41313173098033, -16.734121359207823],
            [-71.41469573098192, -16.73554610920917],
            [-71.41801448098524, -16.738429984211916],
            [-71.4229278559902, -16.7378978592115],
            [-71.42349235599077, -16.738626484212197],
            [-71.42395023099124, -16.739126234212666],
            [-71.42592623099321, -16.74137685921478],
            [-71.42753598099485, -16.740768484214264],
            [-71.43209835599946, -16.736165984210047],
            [-71.43444823100182, -16.734449359208494],
            [-71.43567660600306, -16.733610234207738],
            [-71.43845360600588, -16.731134359205466],
            [-71.43953710600698, -16.73059648420499],
            [-71.44240573100986, -16.73087498420529],
            [-71.44674685601422, -16.731220234205686],
            [-71.44787598101536, -16.731788609206248],
            [-71.45568085602318, -16.74249648421632],
            [-71.45368960602117, -16.745067609218673],
            [-71.45311735602058, -16.745506234219082],
            [-71.45210273101955, -16.747369734220793],
            [-71.45234685601979, -16.749578484222848],
            [-71.4538574810213, -16.753894859226893],
            [-71.4581298560256, -16.7572727342301],
            [-71.46319585603067, -16.758233984231076],
            [-71.46952060603701, -16.76240535923506],
            [-71.47020710603769, -16.762441609235097],
            [-71.48122410604876, -16.763805359236542],
            [-71.48538973105292, -16.765150109237855],
            [-71.48949435605705, -16.766376484239057],
            [-71.49079135605832, -16.766731234239412],
            [-71.49211885605966, -16.767555234240195],
            [-71.49511723106268, -16.767614359240294],
            [-71.49739835606498, -16.766899109239656],
            [-71.50061798106819, -16.766618734239454],
            [-71.50363148107122, -16.764163984237214],
            [-71.5040054810716, -16.76334573423646],
            [-71.5046921060723, -16.762302359235502],
            [-71.50632473107395, -16.76057435923391],
            [-71.50857548107619, -16.759012234232483],
            [-71.51078035607841, -16.75782585923143],
            [-71.51197810607962, -16.755989109229738],
            [-71.51207735607971, -16.754985859228803],
            [-71.51490023108254, -16.752241109226304],
            [-71.51309198108079, -16.744283734218865],
            [-71.51358035608126, -16.743127859217793],
            [-71.51889798108661, -16.73836523421345],
            [-71.5217056060894, -16.735872234211172],
            [-71.52418523109189, -16.73510935921049],
            [-71.52548223109319, -16.73509210921049],
            [-71.53112035609884, -16.7294178592053],
            [-71.5314712310992, -16.72634885920246],
            [-71.5310363560988, -16.723220859199532],
            [-71.53042598109819, -16.719127484195713],
            [-71.53007510609787, -16.716247609193037],
            [-71.53063960609843, -16.714817109191713],
            [-71.53160098109939, -16.71213910918923],
            [-71.53445435610227, -16.71037098418764],
            [-71.5350723561029, -16.703556109181296],
            [-71.53520198110303, -16.702249484180093],
            [-71.5354767311033, -16.700756109178702],
            [-71.5372542311051, -16.698114359176262],
            [-71.53967285610753, -16.69650073417481],
            [-71.55698398112483, -16.686511984165744],
            [-71.55810535612594, -16.687734609166895],
            [-71.56215673112996, -16.69244198417134],
            [-71.56524660613302, -16.69564623417436],
            [-71.56915285613691, -16.697267484175914],
            [-71.57134248113908, -16.699325609177855],
            [-71.57255548114027, -16.699888234178395],
            [-71.57429510614203, -16.696405484175173],
            [-71.57484435614258, -16.695957234174767],
            [-71.57639310614411, -16.695442234174312],
            [-71.58384723115155, -16.691095359170365],
            [-71.59796148116561, -16.687643109167333],
            [-71.59988398116751, -16.687032734166795],
            [-71.60297398117058, -16.686017984165883],
            [-71.60397335617157, -16.684993734164944],
            [-71.6111526061787, -16.683816859163937],
            [-71.61176298117933, -16.683362984163512],
            [-71.61782835618534, -16.68245698416276],
            [-71.62266535619014, -16.682029734162413],
            [-71.6249618561924, -16.68297198416331],
            [-71.62755585619499, -16.68328660916365],
            [-71.62973023119714, -16.685426734165652],
            [-71.63216398119954, -16.692344609172128],
            [-71.63621510620354, -16.696874609176394],
            [-71.64093785620818, -16.705482484184454],
            [-71.64132685620855, -16.704761609183794],
            [-71.64119723120844, -16.701345484180603],
            [-71.64159398120883, -16.700492859179814],
            [-71.64585873121308, -16.696420609176073],
            [-71.65120698121837, -16.695415484175214],
            [-71.65273285621987, -16.697524984177193],
            [-71.65385435622099, -16.698850609178432],
            [-71.65835573122546, -16.7002697341798],
            [-71.65914148122621, -16.700885734180382],
            [-71.6631851062302, -16.701477109180974],
            [-71.66965485623659, -16.704645109183993],
            [-71.67354585624044, -16.705087609184456],
            [-71.7103576062768, -16.699279734179438],
            [-71.71470648128106, -16.699417109179617],
            [-71.72447210629068, -16.699655484179946],
            [-71.76523585633073, -16.708244359188335],
            [-71.86933898143249, -16.763486859240633],
            [-71.87007898143325, -16.74328798422184],
            [-71.86997223143315, -16.734374984213545],
            [-71.87044523143365, -16.7215423592016],
            [-71.87072748143393, -16.71343423419406],
            [-71.87832635644136, -16.706088984187275],
            [-71.88351448144641, -16.700956359182534],
            [-71.88845060645124, -16.69634623417829],
            [-71.89698023145954, -16.68824760917081],
            [-71.90996548147218, -16.67504498415863],
            [-71.92423248148603, -16.696062109178285],
            [-71.92816923148986, -16.70168873418355],
            [-71.93440248149592, -16.71004298419136],
            [-71.93617248149761, -16.712392859193567],
            [-71.98719023154702, -16.78414723426068],
            [-71.99019623154992, -16.788839234265073],
            [-71.9910964815508, -16.79036523426649],
            [-71.99336248155298, -16.79401010926991],
            [-71.9992752315587, -16.797306109273],
            [-72.00116735656054, -16.79664998427241],
            [-72.00235748156167, -16.796482109272258],
            [-72.00327298156259, -16.79689973427266],
            [-72.00806423156722, -16.797029484272805],
            [-72.01239785657143, -16.797609359273366],
            [-72.01449585657345, -16.796791109272615],
            [-72.0192947315781, -16.792640734268794],
            [-72.02689360658542, -16.79149623426777],
            [-72.02925110658771, -16.791542109267823],
            [-72.03234098159069, -16.790906859267242],
            [-72.03488160659316, -16.791723234268026],
            [-72.03833773159649, -16.79224398426852],
            [-72.03846735659663, -16.79195023426825],
            [-72.04450973160246, -16.78182410925886],
            [-72.04622648160414, -16.780935234258045],
            [-72.0499192316077, -16.779518109256745],
            [-72.05266560661033, -16.780796109257945],
            [-72.05308535661074, -16.78119273425833],
            [-72.05371098161135, -16.781328234258453],
            [-72.05618285661373, -16.780269609257477],
            [-72.05748748161498, -16.779972109257212],
            [-72.05826573161575, -16.78013798425736],
            [-72.06146998161884, -16.780509984257733],
            [-72.0623627316197, -16.780507984257735],
            [-72.06449885662175, -16.781612359258776],
            [-72.06636048162355, -16.783643734260675],
            [-72.0669097316241, -16.784431484261404],
            [-72.08029935663698, -16.773948609251732],
            [-72.08260348163923, -16.770315109248347],
            [-72.08444973164102, -16.76946635924758],
            [-72.08880610664521, -16.76867673424687],
            [-72.08968348164606, -16.76858535924678],
            [-72.09182735664811, -16.76840585924663],
            [-72.09381860665003, -16.76779560924608],
            [-72.09986110665585, -16.769668609247844],
            [-72.10609435666184, -16.771387109249474],
            [-72.10895535666461, -16.77600098425378],
            [-72.11350248166897, -16.777156859254877],
            [-72.12040710667564, -16.779584859257174],
            [-72.12461085667968, -16.772609734250693],
            [-72.13247685668723, -16.765035609243682],
            [-72.12759398168255, -16.75658610923579],
            [-72.13180548168661, -16.7528152342323],
            [-72.13214110668693, -16.75034898423001],
            [-72.13257598168737, -16.743678859223806],
            [-72.1337814816885, -16.743541734223683],
            [-72.13600160669066, -16.743678859223817],
            [-72.13789373169246, -16.744337109224436],
            [-72.14373773169808, -16.7442607342244],
            [-72.14723210670142, -16.742067359222368],
            [-72.15334323170731, -16.73722835921791],
            [-72.15574648170961, -16.733026484214005],
            [-72.161361731715, -16.730874984212015],
            [-72.16420748171774, -16.72426223420587],
            [-72.1671828567206, -16.716999109199133],
            [-72.17127985672455, -16.715524734197775],
            [-72.1726913567259, -16.712324109194803],
            [-72.17881773173178, -16.708038359190848],
            [-72.19419860674654, -16.703950859187085],
            [-72.19640348174865, -16.700639734184016],
            [-72.19442748174677, -16.69254110917648],
            [-72.19427485674663, -16.69026373417436],
            [-72.19849398175066, -16.686553984170924],
            [-72.20500948175692, -16.685096734169587],
            [-72.22619623177721, -16.66874310915445],
            [-72.22803498177898, -16.667089484152918],
            [-72.23072810678156, -16.66607660915198],
            [-72.23448948178513, -16.66623498415214],
            [-72.23534410678597, -16.666368484152265],
            [-72.2444687317947, -16.66846848415426],
            [-72.24857335679863, -16.668250984154074],
            [-72.25192260680183, -16.667560609153444],
            [-72.25506585680482, -16.666639359152597],
            [-72.2558593568056, -16.66617773415217],
            [-72.26026148180978, -16.661804234148107],
            [-72.26483923181416, -16.65913585914564],
            [-72.26951598181864, -16.658834484145377],
            [-72.27298748182197, -16.656822234143522],
            [-72.27635960682517, -16.651731484138793],
            [-72.28339385683192, -16.635309234123532],
            [-72.28208160683066, -16.625751484114613],
            [-72.28289035683142, -16.622711359111793],
            [-72.28289035683142, -16.621410359110588],
            [-72.28099823182964, -16.614677484104302],
            [-72.27967073182835, -16.612136859101938],
            [-72.27529910682418, -16.606553984096735],
            [-72.27408598182303, -16.600795734091367],
            [-72.24761198179775, -16.566545484059386],
            [-72.24292760679329, -16.561462359054634],
            [-72.23262785678344, -16.54099848403554],
            [-72.22044373177177, -16.525035859020633],
            [-72.21938323177078, -16.523223859018948],
            [-72.21192173176362, -16.511611984008116],
            [-72.19699098174935, -16.488145858986208],
            [-72.19510648174753, -16.483339358981727],
            [-72.19728085674961, -16.48024373397885],
            [-72.19606785674847, -16.469589233968932],
            [-72.19593048174835, -16.468282858967704],
            [-72.19757848174991, -16.468902608968296],
            [-72.19961548175189, -16.457708358957877],
            [-72.18334198173632, -16.4153576089184],
            [-72.18050385673358, -16.408594108912084],
            [-72.17442323172776, -16.398277233902455],
            [-72.16706848172072, -16.385751733890757],
            [-72.16457373171835, -16.37951848388495],
            [-72.16295623171679, -16.375232733880946],
            [-72.15697473171106, -16.364580108871014],
            [-72.15528110670942, -16.362396233868964],
            [-72.14904785670345, -16.355817733862814],
            [-72.1478653567023, -16.35400960886114],
            [-72.14582823170036, -16.35152810885881],
            [-72.14431760669892, -16.34871298385619],
            [-72.14437098169898, -16.3430366088509],
            [-72.14336398169803, -16.33821110884641],
            [-72.13887785669372, -16.329420108838203],
            [-72.13841248169325, -16.328372983837223],
            [-72.13758848169246, -16.32665823383563],
            [-72.1368789816918, -16.325286858834335],
            [-72.13598648169092, -16.323734233832898],
            [-72.13383485668888, -16.317449608827037],
            [-72.1310424816862, -16.310846358820875],
            [-72.12939448168461, -16.308544108818726],
            [-72.12532810668071, -16.303482108813988],
            [-72.1201934816758, -16.297615108808518],
            [-72.1128158566687, -16.28365710879548],
            [-72.1034088566597, -16.272737483785274],
            [-72.10014348165654, -16.26764873378053],
            [-72.09502410665162, -16.260032608773415],
            [-72.08425135664127, -16.239040358753815],
            [-72.0796889816369, -16.22526735874098],
            [-72.07630923163367, -16.22087285873686],
            [-72.07651523163386, -16.22180173373773],
            [-72.07402798163145, -16.21825223373442],
            [-72.07331085663077, -16.21145060872808],
            [-72.08723448164417, -16.20115085871857],
            [-72.08486173164192, -16.19031523370845],
            [-72.08405298164112, -16.190010108708172],
            [-72.07944498163668, -16.191305108709354],
            [-72.07815548163543, -16.19226260871024],
            [-72.07469173163211, -16.195569983713305],
            [-72.06887060662652, -16.19920160871665],
            [-72.06848148162614, -16.199367483716813],
            [-72.06433873162214, -16.200025608717407],
            [-72.06185148161975, -16.201854733719088],
            [-72.05764773161567, -16.205337483722307],
            [-72.05731198161537, -16.20615010872305],
            [-72.05660248161468, -16.20748898372431],
            [-72.0541076066123, -16.203151733720254],
            [-72.05309298161131, -16.20145410871867],
            [-72.05139173160968, -16.19895935871635],
            [-72.04741673160584, -16.193578733711313],
            [-72.03973398159845, -16.184797233703094],
            [-72.03779598159659, -16.183023358701444],
            [-72.03426360659316, -16.180086108698692],
            [-72.03270723159167, -16.177822108696574],
            [-72.03068548158973, -16.174102733693097],
            [-72.029914856589, -16.17134285869052],
            [-72.02731323158648, -16.169420233688726],
            [-72.0250778565843, -16.168687858688035],
            [-72.02072910658015, -16.16540910868495],
            [-72.01799010657751, -16.163343483683022],
            [-72.0166854815762, -16.162656733682365],
            [-72.01382448157348, -16.16189760868165],
            [-72.00891110656875, -16.157234233677276],
            [-72.00625610656617, -16.15259748367295],
            [-72.00550848156544, -16.152189233672566],
            [-72.00513460656508, -16.151502608671926],
            [-72.0048217315648, -16.149053608669636],
            [-72.00306698156308, -16.147178608667883],
            [-72.00160223156168, -16.144985233665835],
            [-71.99623110655651, -16.13861273365987],
            [-71.98715973154773, -16.138034858659296],
            [-71.9825592315433, -16.139182983660326],
            [-71.97806548153895, -16.140281733661322],
            [-71.96300985652438, -16.137472983658625],
            [-71.96082623152228, -16.140414108661343],
            [-71.95866123152018, -16.14239910866318],
            [-71.95639035651797, -16.144327108664964],
            [-71.95414735651579, -16.14498710866556],
            [-71.95327935651497, -16.144951233665523],
            [-71.94472273150669, -16.14278323366346],
            [-71.93979885650191, -16.144541108665067],
            [-71.91072660647369, -16.159076233678405],
            [-71.90826560647129, -16.15878323367812],
            [-71.88839648145203, -16.145595733665708],
            [-71.87668110644064, -16.134436608655236],
            [-71.86807073143231, -16.122962233644493],
            [-71.86770698143195, -16.121505858643133],
            [-71.86592848143022, -16.11466985863676],
            [-71.86574560643007, -16.111324358633656],
            [-71.84812160641295, -16.094455733617817],
            [-71.84258273140755, -16.088273983612034],
            [-71.83904260640412, -16.082473608606595],
            [-71.83858485640366, -16.079164483603506],
            [-71.83754735640267, -16.073804858598525],
            [-71.83691410640206, -16.07044985859539],
            [-71.83039860639572, -16.0650348585903],
            [-71.82846073139383, -16.06158060858708],
            [-71.82807923139346, -16.060623108586174],
            [-71.82637785639183, -16.05872923358441],
            [-71.82078548138635, -16.061567358587016],
            [-71.8155898563813, -16.057723983583394],
            [-71.81266785637845, -16.050601983576737],
            [-71.81136323137717, -16.050229983576386],
            [-71.80739598137333, -16.04961973357578],
            [-71.79375460636003, -16.04113385856777],
            [-71.7917938563581, -16.03939823356614],
            [-71.7893066063557, -16.037950483564774],
            [-71.78388210635039, -16.03718560856402],
            [-71.78150935634808, -16.036102233563],
            [-71.77894598134556, -16.034896858561854],
            [-71.77650448134317, -16.03404423356103],
            [-71.76927948133613, -16.032506983559543],
            [-71.74535373131275, -16.022605858550115],
            [-71.74426273131168, -16.02093123354855],
            [-71.73865510630617, -16.016742733544607],
            [-71.73796085630552, -16.016197233544094],
            [-71.73723598130479, -16.012590358540738],
            [-71.73581698130343, -16.01149935853969],
            [-71.7352676063029, -16.006000483534585],
            [-71.73566435630329, -16.00388723353262],
            [-71.72838598129617, -15.99541948352468],
            [-71.72743985629526, -15.993146858522545],
            [-71.72678373129459, -15.991958608521442],
            [-71.7248458562927, -15.989298858518943],
            [-71.72407535629198, -15.98901273351868],
            [-71.71385198128195, -15.986416858516163],
            [-71.7097168562779, -15.985404983515181],
            [-71.70656573127484, -15.97572323350615],
            [-71.70581060627411, -15.970977733501712],
            [-71.706726106275, -15.965801233496903],
            [-71.71008298127833, -15.952910483484938],
            [-71.70629123127468, -15.936469108469613],
            [-71.68762223125631, -15.939047858471833],
            [-71.6857757312545, -15.942860608475357],
            [-71.68534085625406, -15.943370858475827],
            [-71.678154106247, -15.948716233480736],
            [-71.67649835624535, -15.949305483481272],
            [-71.66233060623138, -15.962648358493547],
            [-71.66113285623022, -15.964471858495223],
            [-71.6598129812289, -15.970036483500389],
            [-71.65818010622729, -15.973621358503705],
            [-71.65757748122668, -15.974375733504408],
            [-71.65629573122541, -15.976167733506067],
            [-71.6533432312225, -15.979969983509564],
            [-71.6530457312222, -15.980371483509941],
            [-71.65205385622123, -15.98320098351256],
            [-71.65240473122157, -15.983957233513276],
            [-71.65267948122181, -15.985792108514975],
            [-71.65061185621977, -15.9884147335174],
            [-71.6487121062179, -15.988889733517825],
            [-71.64662935621585, -15.988090483517052],
            [-71.64125823121059, -15.986537983515557],
            [-71.60408023117392, -15.982088108511004],
            [-71.59857173116846, -15.983091358511865],
            [-71.59693148116682, -15.984164233512852],
            [-71.59068298116067, -15.987210233515611],
            [-71.58110810615116, -15.990211483518296],
            [-71.57831573114841, -15.990782733518795],
            [-71.56430810613459, -15.97956660850819],
            [-71.55770873112806, -15.973285608502275],
            [-71.55712123112745, -15.972845983501857],
            [-71.55603773112641, -15.971046483500167],
            [-71.55520635612558, -15.96955485849876],
            [-71.55479435612517, -15.968921608498166],
            [-71.54997248112042, -15.962875358492493],
            [-71.54695898111746, -15.959187483489027],
            [-71.54624173111675, -15.958634358488483],
            [-71.54369348111423, -15.957469108487375],
            [-71.542442356113, -15.95238298348263],
            [-71.54180148111237, -15.951193858481515],
            [-71.54121398111178, -15.950683733481041],
            [-71.53859710610918, -15.948618858479088],
            [-71.53723148110785, -15.947857858478363],
            [-71.53433985610499, -15.944097483474824],
            [-71.5317001061024, -15.935039483466362],
            [-71.5227051060935, -15.928296983459964],
            [-71.51920323109003, -15.927614233459288],
            [-71.50430298107523, -15.925427483457065],
            [-71.50243373107334, -15.926018733457585],
            [-71.49278260606374, -15.930596358461726],
            [-71.49009710606109, -15.932156608463133],
            [-71.48667923105768, -15.931694983462659],
            [-71.48295598105398, -15.929326108460401],
            [-71.47978973105083, -15.928372358459468],
            [-71.47227473104334, -15.9267949834579],
            [-71.4659957310371, -15.926888483457898],
            [-71.45317823102427, -15.932948108463355],
            [-71.4444656060156, -15.92743498345809],
            [-71.432838481004, -15.927702858458169],
            [-71.4292449810004, -15.92938423345968],
            [-71.42247773099362, -15.93040560846052],
            [-71.41759485598874, -15.930341733460391],
            [-71.4123839809835, -15.928809108458879],
            [-71.41012573098126, -15.927132608457278],
            [-71.36686710593786, -15.92945385845875],
            [-71.36496735593596, -15.930419983459613],
            [-71.3506164809215, -15.935276983463877],
            [-71.34152235591232, -15.93927960846743],
            [-71.32198335589264, -15.941599858469248],
            [-71.31419373088474, -15.947256108474365],
            [-71.30707548087753, -15.954428733480906],
            [-71.30554198087597, -15.953512233480021],
            [-71.28357485585377, -15.957048108482887],
            [-71.2740806058441, -15.963244108488468],
            [-71.27398073084402, -15.96584273349089],
            [-71.26894373083883, -15.975126233499404],
            [-71.26908873083897, -15.979008733503033],
            [-71.26637273083615, -15.98216248350591],
            [-71.26000385582967, -15.988179108511366],
            [-71.2483518558178, -15.998536358520768],
            [-71.23852198080779, -16.000598358522463],
            [-71.23156910580073, -16.001940983523575],
            [-71.22854823079763, -16.004482358525877],
            [-71.22308348079203, -16.009437608530366],
            [-71.21759798078641, -16.014629233535054],
            [-71.21627810578504, -16.01707648353732],
            [-71.21196748078066, -16.020309483540224],
            [-71.20168310577013, -16.025598483544908],
            [-71.19742585576583, -16.020505858540098],
            [-71.19159698075994, -16.01472660853458],
            [-71.18240360575058, -16.01052098353045],
            [-71.18025973074839, -16.010259608530173],
            [-71.17407985574211, -16.006683358526697],
            [-71.16038173072813, -16.004753358524592],
            [-71.15596773072363, -16.00307460852292],
            [-71.15154260571917, -15.996675483516864],
            [-71.14953610571715, -15.991220483511741],
            [-71.14519510571273, -15.987470608508152],
            [-71.14456935571212, -15.985576608506381],
            [-71.144454980712, -15.983701733504633],
            [-71.12216185568916, -15.984615358504925],
            [-71.1170578556839, -15.988044733507992],
            [-71.10846723067507, -15.986961358506768],
            [-71.10568998067224, -15.984902358504783],
            [-71.09597773066227, -15.984142358503817],
            [-71.08666998065274, -15.981054358500703],
            [-71.08537298065136, -15.9849586085043],
            [-71.08623510565222, -15.987698608506859],
            [-71.08661648065257, -15.992138858511003],
            [-71.08654785565248, -15.992697733511518],
            [-71.08595273065181, -15.998011608516437],
            [-71.08133698064705, -15.9996204835178],
            [-71.07689660564246, -16.00419998352194],
            [-71.06652823063175, -16.00368885852119],
            [-71.06163785562677, -15.99818323351594],
            [-71.05138398061628, -15.991138483509102],
            [-71.04703523061185, -15.984318733502642],
            [-71.0360946056006, -15.980232233498528],
            [-71.01191710557566, -15.972017233490186],
            [-70.99826810556155, -15.967929858485977],
            [-70.98519898054802, -15.969083733486647],
            [-70.96321110552513, -15.969236358486096],
            [-70.95281985551443, -15.961989358479022],
            [-70.95084385551239, -15.961837733478825],
            [-70.94835660550979, -15.96166035847857],
            [-70.94494623050623, -15.962502483479243],
            [-70.9202041054806, -15.950683733467438],
            [-70.90960698046962, -15.944540983461359],
            [-70.90248873046215, -15.945558608462052],
            [-70.87798310543648, -15.94797610846342],
            [-70.87424473043262, -15.945562358461034],
            [-70.87348173043182, -15.944279608459828],
            [-70.86895748042714, -15.940552733456196],
            [-70.85599523041378, -15.925342608441595],
            [-70.85451510541223, -15.924734108440969],
            [-70.84327698040043, -15.923255858439164],
          ],
          [
            [-71.16252106578182, -16.351876094515983],
            [-71.13716885570093, -16.399095483890502],
            [-71.08733373064946, -16.388957983879735],
            [-71.12092585568479, -16.32782548382384],
            [-71.16252106578182, -16.351876094515983],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 8,
        FIRST_IDPR: '1302',
        NOMBPROV: 'ASCOPE',
        FIRST_NOMB: 'LA LIBERTAD',
        LAST_DCTO: 'LEY',
        LAST_LEY: '26916',
        FIRST_FECH: 'EPOCA INDEP.',
        LAST_FECHA: '21/01/1998',
        MIN_SHAPE_: 265013.609011,
        ha: 265013.61,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.45482373845223, -7.638024600729803],
            [-79.3867803634013, -7.598589100694982],
            [-79.3584397383792, -7.559088850658973],
            [-79.31623198834725, -7.581806600681072],
            [-79.26767236330794, -7.489731100596527],
            [-79.18732936324247, -7.388664100504079],
            [-79.15272398821503, -7.439820100552244],
            [-79.06542811314307, -7.472708600584261],
            [-79.05984723813916, -7.546675475653174],
            [-79.04123248812398, -7.599887475702938],
            [-78.99214873808276, -7.660949350760457],
            [-78.94275261303984, -7.622670475725467],
            [-78.90776348800924, -7.598070100702992],
            [-78.79854298791302, -7.586603475693449],
            [-78.80376911291796, -7.63410647573761],
            [-78.89597923799933, -7.645388975747138],
            [-78.91228511301388, -7.686081975784825],
            [-78.89307048799758, -7.748966850843589],
            [-78.81625973793015, -7.796873100888981],
            [-78.88475398799102, -7.841976475930221],
            [-78.9033668630077, -7.887382975972284],
            [-78.99057111308366, -7.900325100983207],
            [-79.040006238127, -7.988320976064378],
            [-79.10588798818279, -7.981977476057408],
            [-79.14951123821875, -7.943663101021017],
            [-79.2104961132697, -7.99903122607135],
            [-79.30729098834613, -7.925970726001308],
            [-79.3757461133976, -7.84209760092164],
            [-79.39242573840916, -7.787808600870753],
            [-79.46570698846168, -7.711933600798175],
            [-79.43528911343915, -7.693359350781793],
            [-79.45482373845223, -7.638024600729803],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 2,
        FIRST_IDPR: '0204',
        NOMBPROV: 'ASUNCION',
        FIRST_NOMB: 'ANCASH',
        LAST_DCTO: 'LEY',
        LAST_LEY: '23764',
        FIRST_FECH: 'EPOCA INDEP.',
        LAST_FECHA: '30/12/1983',
        MIN_SHAPE_: 52824.6237496,
        ha: 52824.62,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.36200198657869, -9.064992227074734],
            [-77.3622084865789, -9.112180852118694],
            [-77.26205161148481, -9.20778722720776],
            [-77.27529661149727, -9.278269477273383],
            [-77.3191841115385, -9.324105602316077],
            [-77.32568248654461, -9.31004960230295],
            [-77.48036723669001, -9.255315102251918],
            [-77.5108271117187, -9.254347227250973],
            [-77.53354723674005, -9.173116727175325],
            [-77.49046036169948, -9.106671852113488],
            [-77.43734573664952, -9.059246102069354],
            [-77.36200198657869, -9.064992227074734],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 4,
        FIRST_IDPR: '2502',
        NOMBPROV: 'ATALAYA',
        FIRST_NOMB: 'UCAYALI',
        LAST_DCTO: 'LEY',
        LAST_LEY: '9815',
        FIRST_FECH: '02/07/1943',
        LAST_FECHA: '02/07/1943',
        MIN_SHAPE_: 3904975.4755,
        ha: 3904975.48,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.18180860673868, -11.0097246038853],
            [-72.17536910673256, -11.044263728917446],
            [-72.18486498174153, -11.06581047893752],
            [-72.1214062316814, -11.107140603975898],
            [-72.200240231756, -11.205296854067436],
            [-72.198183356754, -11.261696854119975],
            [-72.21766410677242, -11.30018172915584],
            [-72.20462335676005, -11.347432729199811],
            [-72.23925835679285, -11.345060979197648],
            [-72.30770898185762, -11.30699997916233],
            [-72.37883010692482, -11.332638729186295],
            [-72.42512710696852, -11.368487354219724],
            [-72.47986810702017, -11.387420604237432],
            [-72.53508560707222, -11.435080479281861],
            [-72.6099874821428, -11.428238354275534],
            [-72.63212423216363, -11.406400854255223],
            [-72.70656135723364, -11.381505354232067],
            [-72.73823760726343, -11.347429354200365],
            [-72.7942939823161, -11.38429072923471],
            [-72.89701898241249, -11.357926104210184],
            [-72.95190860746398, -11.330629604184779],
            [-72.939126107452, -11.282077854139564],
            [-72.96858360747962, -11.264121229122859],
            [-72.98966460749936, -11.198796854062017],
            [-73.02650885753387, -11.26408460412282],
            [-73.13737448263765, -11.293773104150501],
            [-73.20330073269935, -11.289990354146976],
            [-73.25744573274996, -11.31270885416815],
            [-73.331330607819, -11.311264729166817],
            [-73.41974948290158, -11.383938229234493],
            [-73.48408748296166, -11.31934397917434],
            [-73.46011023293927, -11.302711354158843],
            [-73.45009860792992, -11.224749479086228],
            [-73.48260348296024, -11.065242228937686],
            [-73.54257873301621, -11.047424478921112],
            [-73.5714909830432, -10.958093728837918],
            [-73.56026148303272, -10.860609228747126],
            [-73.6103962330795, -10.846573853734071],
            [-73.6961931081595, -10.773029228665553],
            [-73.75720010821641, -10.73590472863101],
            [-73.81421685826955, -10.79989647869059],
            [-73.8234072332781, -10.921072728803427],
            [-73.85411035830674, -10.954905978834947],
            [-73.86657348331835, -11.037352853911724],
            [-73.86130360831343, -11.14553022901247],
            [-73.91648723336485, -11.14310197901021],
            [-73.97827173342243, -11.120626978989277],
            [-73.97873848342287, -11.070033103942157],
            [-74.0054982334478, -10.993192603870618],
            [-74.09177835852817, -10.986814603864666],
            [-74.20147460863036, -11.019663728895253],
            [-74.25643598368156, -11.018222728893917],
            [-74.37365473379074, -11.056522353929575],
            [-74.44541048385757, -11.004852478881471],
            [-74.50008098390849, -10.995998478873222],
            [-74.50838373391623, -10.950299728830647],
            [-74.5729951089764, -10.91260197879554],
            [-74.51004098391775, -10.88034960376551],
            [-74.48492223389438, -10.836456353724635],
            [-74.38568073380193, -10.811767353701642],
            [-74.37426910879131, -10.758109478651681],
            [-74.31730560873825, -10.726707228622416],
            [-74.28113473370456, -10.674858853574138],
            [-74.22985148365679, -10.635495353537497],
            [-74.16496035859635, -10.63844960354025],
            [-74.1326734835663, -10.604910853508994],
            [-74.18178835861201, -10.504043228415059],
            [-74.21873423364646, -10.4521128533667],
            [-74.2551796086804, -10.452479103367056],
            [-74.27671098370045, -10.37407422829402],
            [-74.22434198365168, -10.279230478205696],
            [-74.23166060865849, -10.185051603117996],
            [-74.3039374837258, -10.163594978098002],
            [-74.32775523374801, -10.109652728047791],
            [-74.36696673378452, -10.112896478050791],
            [-74.40060810881585, -10.070518978011332],
            [-74.42403460883767, -9.98944260293583],
            [-74.41232623382675, -9.95395510290276],
            [-74.33306773375296, -9.860098102815359],
            [-74.31695898373795, -9.801581977760854],
            [-74.25341810867876, -9.771726602733045],
            [-74.18398173361408, -9.763799352725664],
            [-74.15240448358466, -9.709681102675296],
            [-74.06579498350398, -9.705536852671427],
            [-74.04755073348699, -9.655833977625115],
            [-73.95975385840518, -9.610787102583178],
            [-73.8755408583267, -9.596558602569925],
            [-73.82096160827584, -9.531970727509757],
            [-73.7433906082035, -9.540347602517585],
            [-73.68452548314863, -9.529840602507793],
            [-73.60918648307836, -9.644408352614477],
            [-73.57007685804186, -9.657448477626644],
            [-73.53777935801175, -9.707669977673381],
            [-73.46937598294791, -9.750780102713538],
            [-73.45934873293854, -9.795609602755281],
            [-73.39347260787703, -9.822573477780422],
            [-73.35523885784131, -9.872702227827084],
            [-73.30922098279834, -9.892989852845975],
            [-73.21930460771429, -9.99931172794498],
            [-73.15274435765205, -10.03952960298243],
            [-73.11252860761441, -10.01775485296215],
            [-73.09318360759632, -9.949542477898628],
            [-73.1032002326057, -9.875227352829416],
            [-73.22848685772287, -9.846510977802698],
            [-73.21775773271285, -9.827471852784955],
            [-73.24293235773638, -9.731095227695208],
            [-73.27340185776487, -9.664594102633275],
            [-73.22713998272162, -9.656846727626046],
            [-73.2124751077079, -9.574153352549036],
            [-73.18962885768654, -9.526796727504934],
            [-73.20616998270202, -9.484320352465389],
            [-73.20043948269665, -9.41139647739748],
            [-72.71691210724359, -9.412185602398099],
            [-72.69770173222553, -9.435962352420235],
            [-72.64225460717343, -9.442316852426124],
            [-72.53039698206823, -9.48230972746333],
            [-72.4070624819521, -9.47729110245855],
            [-72.34082360688967, -9.50659510248576],
            [-72.31798410686814, -9.544138102520726],
            [-72.28132623183359, -9.542298852518954],
            [-72.28832223184014, -9.602816352575324],
            [-72.24601760680022, -9.657279352625965],
            [-72.25899410681244, -9.712191352677143],
            [-72.21486910677075, -9.778816352739113],
            [-72.1513611067108, -9.797416352756338],
            [-72.16205623172087, -9.831068227787691],
            [-72.15071173171009, -9.905741852857231],
            [-72.17388810673198, -9.930192602880018],
            [-72.18040435673807, -9.999671852944719],
            [-72.1893534817465, -10.020873102964474],
            [-72.14657435670603, -10.09846247803666],
            [-72.1569524817158, -10.165256853098898],
            [-72.21413335676984, -10.159707853093812],
            [-72.24254848179663, -10.18880972812096],
            [-72.33609598188495, -10.179478728112384],
            [-72.381767231928, -10.22249647815248],
            [-72.44472148198736, -10.255042478182869],
            [-72.47305635701406, -10.301707353226357],
            [-72.47819523201886, -10.36770847828781],
            [-72.4224488569663, -10.44647222836111],
            [-72.40788510695255, -10.517047728426823],
            [-72.38080235692699, -10.537832228446149],
            [-72.4060971069508, -10.64946222855012],
            [-72.44336985698598, -10.636491978538087],
            [-72.48434760702456, -10.792189478683111],
            [-72.43721098198009, -10.842589853730004],
            [-72.40006485694502, -10.929813978811188],
            [-72.32029685686967, -10.980279353858078],
            [-72.26212623181469, -10.971675853849995],
            [-72.21027335676561, -11.013279853888651],
            [-72.18180860673868, -11.0097246038853],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 10,
        FIRST_IDPR: '2002',
        NOMBPROV: 'AYABACA',
        FIRST_NOMB: 'PIURA',
        LAST_DCTO: 'LEY',
        LAST_LEY: 'S/N',
        FIRST_FECH: 'EPOCA INDEP.',
        LAST_FECHA: '02/01/1857',
        MIN_SHAPE_: 522900.137013,
        ha: 522900.14,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.38914261335815, -4.834281223125202],
            [-79.39345698836168, -4.880789098168366],
            [-79.45140236340085, -4.95474022323603],
            [-79.48210161342148, -5.024745973300489],
            [-79.49240823842891, -5.085261723356512],
            [-79.55598236346738, -5.076001848346493],
            [-79.64220898851659, -5.10721684837335],
            [-79.67825586353518, -5.093546848359692],
            [-79.78848436358439, -4.990822223261035],
            [-79.86243361361286, -4.972044848241117],
            [-79.90916798863014, -5.019400473283282],
            [-79.9593183636455, -5.036699223297305],
            [-80.00224611365542, -5.015972598276303],
            [-80.03880073866331, -5.02635934828428],
            [-80.08256236366948, -5.004466848261908],
            [-80.10244923866881, -4.929037098191134],
            [-80.11708973866831, -4.882369098147221],
            [-80.09639848866455, -4.838447223107594],
            [-80.07732223865818, -4.731552723009573],
            [-80.02569323864971, -4.718234347999537],
            [-80.03555273864929, -4.656345722941771],
            [-80.12356636366033, -4.673011722953215],
            [-80.15341986365885, -4.604191347888086],
            [-80.22142573865689, -4.595451222876476],
            [-80.20148048865678, -4.566990222851233],
            [-80.23380473864897, -4.441824222733854],
            [-80.28987698864074, -4.433238222722817],
            [-80.19486136364769, -4.346361347647633],
            [-80.1787051136466, -4.30377734760907],
            [-80.10424023864454, -4.290956972600616],
            [-79.99176948863193, -4.379960972687596],
            [-79.92069923861486, -4.385609347695392],
            [-79.87134961360056, -4.412982472722418],
            [-79.83984373859114, -4.467603472774063],
            [-79.79388473857428, -4.492982472799001],
            [-79.7087597385368, -4.471492222781422],
            [-79.65545898851009, -4.434160097748081],
            [-79.62814061349611, -4.439109347753328],
            [-79.56339061346195, -4.514269472824548],
            [-79.49793948842351, -4.521228472832306],
            [-79.4793906134129, -4.572849597880632],
            [-79.48824998841916, -4.625990222929841],
            [-79.38914261335815, -4.834281223125202],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 17,
        FIRST_IDPR: '0304',
        NOMBPROV: 'AYMARAES',
        FIRST_NOMB: 'APURIMAC',
        LAST_DCTO: 'LEY',
        LAST_LEY: '13787',
        FIRST_FECH: '01/06/1914',
        LAST_FECHA: '27/12/1961',
        MIN_SHAPE_: 412862.544002,
        ha: 412862.54,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.17189510766997, -13.803636981487854],
            [-73.0737414825781, -13.896989731574765],
            [-73.03760348254426, -13.905277356582472],
            [-73.02449048253197, -13.992040856663243],
            [-73.01973635752752, -14.112623106775542],
            [-73.04985848255573, -14.122137231784432],
            [-73.07634373258054, -14.206306606862814],
            [-73.04577535755189, -14.263098356915677],
            [-73.08264098258645, -14.328776106976846],
            [-73.04752348255353, -14.345033231991994],
            [-73.03806398254468, -14.390179357034022],
            [-72.98050010749074, -14.44114760708147],
            [-72.99776185750693, -14.482081107119583],
            [-72.99743910750662, -14.581401232212082],
            [-73.0142748575224, -14.585390982215797],
            [-73.01328548252147, -14.61434310724276],
            [-73.01638360752436, -14.620780857248757],
            [-73.01740035752533, -14.630714857258011],
            [-73.02203435752966, -14.627318982254861],
            [-73.03315835754009, -14.626319107253932],
            [-73.03436610754122, -14.62537010725303],
            [-73.03958548254612, -14.62433485725207],
            [-73.04361048254991, -14.625669357253338],
            [-73.04140348254782, -14.646952732273137],
            [-73.04039423254686, -14.649386357275413],
            [-73.04419148255042, -14.657813607283252],
            [-73.05220985755794, -14.659214732284559],
            [-73.05439235755998, -14.659884732285198],
            [-73.06312498256817, -14.66580473229072],
            [-73.06489648256984, -14.665566357290484],
            [-73.06937498257402, -14.661673732286857],
            [-73.07447073257879, -14.658318357283749],
            [-73.0784268575825, -14.656055232281629],
            [-73.09631810759923, -14.650481732276443],
            [-73.10444210760686, -14.667874232292641],
            [-73.10668710760895, -14.668864732293578],
            [-73.10675110760901, -14.676950107301092],
            [-73.10464023260705, -14.682994857306724],
            [-73.1211473576225, -14.691578607314723],
            [-73.12207185762335, -14.690257982313499],
            [-73.12352448262473, -14.681938357305748],
            [-73.1319567326326, -14.681526232305357],
            [-73.13338135763394, -14.680951607304834],
            [-73.14729485764697, -14.67777860730189],
            [-73.17222385767028, -14.685013732308622],
            [-73.17962598267722, -14.68818398231158],
            [-73.18183160767929, -14.691766857314914],
            [-73.18398748268129, -14.696898607319694],
            [-73.18800960768506, -14.703392107325758],
            [-73.19041160768731, -14.705774732327974],
            [-73.20229673269843, -14.704520232326786],
            [-73.21827473271337, -14.701611607324086],
            [-73.21987710771485, -14.703001982325395],
            [-73.22548573272012, -14.707479357329568],
            [-73.22737085772187, -14.708421982330446],
            [-73.23298323272711, -14.710433857332315],
            [-73.2390371077328, -14.711070357332897],
            [-73.25122410774418, -14.711387232333191],
            [-73.26721585775911, -14.71810035733945],
            [-73.27005573276176, -14.721312357342452],
            [-73.27305298276458, -14.723842232344817],
            [-73.27640898276772, -14.727584607348307],
            [-73.27750335776872, -14.72853110734919],
            [-73.28972660778017, -14.7327343573531],
            [-73.32173823281008, -14.716210982337717],
            [-73.32833010781624, -14.717361357338767],
            [-73.33770698282501, -14.710673857332557],
            [-73.3519433578383, -14.70265435732509],
            [-73.35220310783853, -14.701634732324159],
            [-73.35472848284088, -14.691962857315138],
            [-73.35636135784243, -14.686968732310477],
            [-73.37009423285525, -14.688158607311587],
            [-73.3705838578557, -14.694029107317055],
            [-73.37544473286025, -14.70110323232365],
            [-73.3843046078685, -14.70817973233023],
            [-73.40062348288374, -14.743925482363528],
            [-73.40454885788743, -14.76549623238361],
            [-73.4153393578975, -14.771192982388937],
            [-73.42482573290636, -14.77775060739504],
            [-73.43291785791392, -14.781818607398826],
            [-73.43788548291855, -14.784871482401666],
            [-73.44011373292062, -14.788061107404635],
            [-73.4524916079322, -14.802837607418406],
            [-73.45425948293382, -14.810738107425752],
            [-73.46069598293984, -14.829246482442995],
            [-73.46562110794446, -14.840259732453257],
            [-73.46827335794691, -14.838996982452088],
            [-73.47450823295274, -14.839909482452935],
            [-73.47613798295426, -14.840489607453469],
            [-73.47994460795779, -14.841703357454595],
            [-73.48016960795803, -14.841917607454803],
            [-73.48502735796255, -14.842570357455408],
            [-73.48632810796377, -14.841587857454497],
            [-73.49152348296862, -14.83754685745072],
            [-73.49240035796943, -14.83695310745018],
            [-73.49486135797173, -14.83463085744801],
            [-73.49736323297407, -14.831271482444883],
            [-73.49860348297523, -14.82937698244311],
            [-73.49994723297648, -14.828451232442259],
            [-73.50107223297753, -14.828263732442073],
            [-73.50783598298382, -14.825337857439361],
            [-73.51236723298805, -14.826328107440288],
            [-73.51484373299041, -14.824669982438728],
            [-73.51432035798987, -14.822181607436411],
            [-73.51980273299502, -14.81310160742797],
            [-73.52029885799546, -14.812042982426973],
            [-73.51999410799517, -14.811117232426115],
            [-73.5202051079954, -14.8106659824257],
            [-73.52218748299724, -14.808273482423479],
            [-73.52316598299814, -14.807517607422763],
            [-73.52695698300167, -14.803484357419011],
            [-73.52818748300282, -14.801703107417351],
            [-73.52981248300436, -14.800785107416505],
            [-73.53247848300684, -14.799923857415695],
            [-73.5345996080088, -14.798906232414753],
            [-73.54117185801496, -14.800501982416238],
            [-73.54901173302225, -14.801611357417276],
            [-73.55227535802533, -14.79516210741126],
            [-73.55368360802662, -14.79085935740726],
            [-73.55351948302646, -14.789355482405856],
            [-73.55721485802991, -14.78568560740243],
            [-73.55844335803107, -14.784474607401306],
            [-73.55891598303151, -14.783191357400115],
            [-73.56033598303283, -14.782597607399564],
            [-73.56269535803503, -14.780177732397314],
            [-73.56574998303789, -14.772408232390063],
            [-73.56441410803663, -14.768955107386864],
            [-73.56404885803627, -14.76604098238415],
            [-73.563722608036, -14.764835982383023],
            [-73.56408010803632, -14.760376982378862],
            [-73.56305460803536, -14.754783232373661],
            [-73.5623418580347, -14.753515607372494],
            [-73.56314260803545, -14.751449232370554],
            [-73.56449023303672, -14.747662107367024],
            [-73.56368948303594, -14.746244107365696],
            [-73.5625429830349, -14.74411323236372],
            [-73.56255073303491, -14.743601607363258],
            [-73.56280073303512, -14.742158232361906],
            [-73.56370510803598, -14.736861357356966],
            [-73.56585160803797, -14.73359573235393],
            [-73.5679316080399, -14.733433607353781],
            [-73.57074810804254, -14.728230482348948],
            [-73.57149023304324, -14.726794982347592],
            [-73.57089060804266, -14.725419982346315],
            [-73.57054885804236, -14.72544335734633],
            [-73.56949023304138, -14.71975585734105],
            [-73.57285348304448, -14.720531232341749],
            [-73.57570310804718, -14.718177732339566],
            [-73.57690435804831, -14.715509732337088],
            [-73.57677535804818, -14.71303123233477],
            [-73.57673248304812, -14.711685607333516],
            [-73.57155473304331, -14.706363232328581],
            [-73.57332223304493, -14.70302148232545],
            [-73.57471685804624, -14.701552732324105],
            [-73.57502148304651, -14.701414107323954],
            [-73.5750996080466, -14.699580107322243],
            [-73.57363873304523, -14.698103482320867],
            [-73.57360935804522, -14.697550732320357],
            [-73.57418948304574, -14.696201232319112],
            [-73.57459573304611, -14.695802732318725],
            [-73.57537885804685, -14.694923857317919],
            [-73.57428510804583, -14.694992232317986],
            [-73.57088085804267, -14.694130857317184],
            [-73.57049998304231, -14.693117232316233],
            [-73.5707148580425, -14.6926699823158],
            [-73.57210348304379, -14.688554732311982],
            [-73.57109898304286, -14.687271607310807],
            [-73.56337148303564, -14.681040482304981],
            [-73.539079358013, -14.673278857297753],
            [-73.53735510801137, -14.673407107297887],
            [-73.53574810800988, -14.673593732298055],
            [-73.510151982986, -14.646261357272595],
            [-73.52992398300445, -14.594923732224801],
            [-73.53085773300533, -14.593558357223515],
            [-73.53332648300763, -14.587073732217476],
            [-73.53337885800768, -14.58102148221184],
            [-73.51911910799437, -14.570671857202205],
            [-73.51537310799087, -14.56769135719943],
            [-73.50998635798582, -14.547454982180568],
            [-73.50701173298306, -14.544308607177644],
            [-73.45312823293278, -14.402548607045622],
            [-73.4090347328916, -14.336091231983755],
            [-73.36876623285399, -14.310735231960118],
            [-73.40981248289233, -14.107335981770714],
            [-73.37865360786323, -14.091851106756279],
            [-73.37875085786332, -14.033554231701999],
            [-73.35380423284003, -13.984338856656159],
            [-73.35454173284072, -13.92655548160234],
            [-73.28557410777627, -13.893540606571596],
            [-73.24501035773834, -13.815059356498514],
            [-73.17189510766997, -13.803636981487854],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 15,
        FIRST_IDPR: '2102',
        NOMBPROV: 'AZANGARO',
        FIRST_NOMB: 'PUNO',
        LAST_DCTO: 'DEC.',
        LAST_LEY: 'S/N',
        FIRST_FECH: 'EPOCA INDEP.',
        LAST_FECHA: '02/05/1854',
        MIN_SHAPE_: 512576.432696,
        ha: 512576.43,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.88987110446688, -14.435685606990443],
            [-69.89059372946757, -14.436056606990872],
            [-69.89087310446779, -14.436716856991518],
            [-69.89128322946803, -14.43909960699377],
            [-69.89185160446848, -14.440124981994778],
            [-69.89291210446892, -14.448304732002478],
            [-69.8927654794687, -14.449535107003602],
            [-69.89083597946635, -14.455681607009023],
            [-69.88982222946514, -14.458945357011919],
            [-69.8901347294653, -14.460947232013813],
            [-69.8901152294649, -14.466206982018669],
            [-69.89011722946483, -14.466970732019353],
            [-69.890611354465, -14.47117773202331],
            [-69.89193447946606, -14.474292732026353],
            [-69.89302547946698, -14.47598610702805],
            [-69.895256979469, -14.478569857030712],
            [-69.89718647947082, -14.479039607031417],
            [-69.89973197947322, -14.48048610703303],
            [-69.90369060447706, -14.48069598203372],
            [-69.9050277294784, -14.480250232033466],
            [-69.91050660448352, -14.483710732037343],
            [-69.91530410448813, -14.484575857038735],
            [-69.91885735449168, -14.483683857038319],
            [-69.92048472949324, -14.483893857038712],
            [-69.9331753545054, -14.488303982044322],
            [-69.94064572951253, -14.491229232047873],
            [-69.94487822951668, -14.491712482048827],
            [-69.94866610452041, -14.491384232048956],
            [-69.95040997952204, -14.49262498205032],
            [-69.95030472952193, -14.492878857050526],
            [-69.95013285452173, -14.493462857051044],
            [-69.95000960452157, -14.493630857051196],
            [-69.95004297952156, -14.494642607052137],
            [-69.95000772952139, -14.496027357053414],
            [-69.95065822952193, -14.498165982055474],
            [-69.95178122952301, -14.498382857055812],
            [-69.95207035452326, -14.498785107056207],
            [-69.95266797952374, -14.500572232057905],
            [-69.95286735452389, -14.501261732058582],
            [-69.9529082295239, -14.501605482058912],
            [-69.95334572952426, -14.50244723205973],
            [-69.95404310452487, -14.503904357061163],
            [-69.95599222952671, -14.504935732062341],
            [-69.9564316045271, -14.505537107062963],
            [-69.95691797952759, -14.505701232063156],
            [-69.95770497952836, -14.505308607062876],
            [-69.9613066045318, -14.507322232065146],
            [-69.96202347953252, -14.50743748206534],
            [-69.96522072953543, -14.510824232068824],
            [-69.96549610453569, -14.511173857069188],
            [-69.9661621045362, -14.513302732071223],
            [-69.96711722953707, -14.514445357072395],
            [-69.96809572953804, -14.5146503570727],
            [-69.97051760454043, -14.514484357072831],
            [-69.97171872954165, -14.51424023207271],
            [-69.97373435454361, -14.514474607073168],
            [-69.97450385454435, -14.514931732073688],
            [-69.97620122954586, -14.517466857076212],
            [-69.97820510454746, -14.523453107081972],
            [-69.97908985454825, -14.524474607083002],
            [-69.98161910455076, -14.524929732083711],
            [-69.98508197955428, -14.523423857082724],
            [-69.98636910455551, -14.52400973208339],
            [-69.98756635455659, -14.52568160708507],
            [-69.98924997955815, -14.527302732086769],
            [-69.9900722295589, -14.528285107087758],
            [-69.99113472955989, -14.529388607088896],
            [-69.99370372956213, -14.53469010709409],
            [-69.99610447956435, -14.537327607096763],
            [-70.0006693545686, -14.54172348210133],
            [-70.00330697957068, -14.550650482109893],
            [-70.00305572957035, -14.551782232110916],
            [-70.00935185457621, -14.55841823211771],
            [-70.01091322957778, -14.558493857117947],
            [-70.00900560457578, -14.559993482119134],
            [-70.00345535456987, -14.565865482123963],
            [-70.00371110457004, -14.566972482125031],
            [-70.0019308545681, -14.5700944821277],
            [-70.00033122956644, -14.570803982128208],
            [-69.99918310456519, -14.572429357129582],
            [-69.99857672956443, -14.574712732131621],
            [-69.99197135455762, -14.578650232134537],
            [-69.99160860455724, -14.578791232134622],
            [-69.99106110455662, -14.579656232135363],
            [-69.98859260455394, -14.583318732138462],
            [-69.9875772295529, -14.58366923213868],
            [-69.98582360455113, -14.583790107138599],
            [-69.98449322954981, -14.583790107138448],
            [-69.97951385454493, -14.582597107136785],
            [-69.9707769795367, -14.575303732129079],
            [-69.96287147952896, -14.573542357126538],
            [-69.9615338545276, -14.574010607126812],
            [-69.956757479523, -14.571796857124227],
            [-69.95132397951768, -14.570800357122684],
            [-69.94307097950941, -14.57183723212266],
            [-69.93797135450451, -14.569599732119991],
            [-69.93277085449942, -14.568793482118629],
            [-69.92200710448837, -14.574614357122721],
            [-69.92046247948666, -14.577163857124882],
            [-69.91902960448515, -14.578503732125968],
            [-69.91162310447781, -14.578466482125016],
            [-69.90970635447601, -14.57710798212353],
            [-69.9077151044742, -14.57506098212139],
            [-69.90181210446903, -14.565940607112253],
            [-69.89938222946662, -14.566230482112196],
            [-69.89224860445938, -14.569106232113972],
            [-69.88675622945337, -14.577688857121206],
            [-69.88973822945582, -14.584476607127868],
            [-69.88976172945578, -14.585199232128522],
            [-69.88481447945036, -14.5925646071347],
            [-69.87882035444399, -14.598980482139876],
            [-69.87683397944186, -14.601290982141759],
            [-69.87653122944157, -14.601042982141488],
            [-69.87403135443918, -14.59973635713995],
            [-69.87277735443799, -14.59945910713955],
            [-69.87074222943629, -14.595453107135565],
            [-69.86939647943511, -14.593425732133518],
            [-69.86843360443439, -14.590515607130717],
            [-69.86856060443469, -14.587999982128425],
            [-69.86706447943342, -14.585536982125944],
            [-69.86430072943058, -14.587121107127047],
            [-69.86374810442997, -14.58807423212787],
            [-69.85992572942568, -14.59477148213354],
            [-69.8601953544257, -14.598259732136793],
            [-69.85967572942506, -14.599789107138152],
            [-69.85908597942434, -14.601749982139891],
            [-69.8587851044238, -14.604929732142763],
            [-69.85800585442297, -14.606083982143742],
            [-69.85665235442153, -14.607257857144646],
            [-69.85647260442128, -14.608066232145374],
            [-69.8552266044198, -14.611503857148373],
            [-69.85751997942174, -14.61560360715247],
            [-69.85954935442345, -14.619166357156015],
            [-69.85910135442269, -14.623600982160063],
            [-69.85853410442203, -14.624978357161261],
            [-69.85667060441985, -14.629151107164857],
            [-69.8566706044189, -14.64158860717633],
            [-69.85602235441813, -14.643128107177683],
            [-69.85642747941837, -14.64531585717976],
            [-69.8551448544167, -14.65080210718465],
            [-69.85441147941586, -14.652115607185772],
            [-69.85180147941315, -14.6539407321871],
            [-69.85047097941181, -14.654179607187158],
            [-69.84859460440983, -14.655800107188407],
            [-69.85144722941196, -14.66437748219669],
            [-69.85227385441266, -14.666225107198493],
            [-69.85020222940987, -14.675721107206998],
            [-69.85066860441017, -14.677553607208754],
            [-69.85256772941183, -14.680718607211928],
            [-69.85591810441501, -14.68265785721418],
            [-69.85772122941667, -14.683984857215618],
            [-69.8586646044175, -14.685549482217198],
            [-69.85644310441496, -14.689706357220743],
            [-69.8557457294142, -14.690647732221523],
            [-69.85522272941289, -14.70107323223106],
            [-69.84919060440603, -14.712161357240495],
            [-69.84803985440469, -14.714881107242862],
            [-69.84849310440484, -14.718890857246603],
            [-69.84568197940187, -14.720705857247914],
            [-69.84123910439732, -14.722665857249108],
            [-69.83798172939348, -14.730414357255848],
            [-69.83838747939369, -14.73271348225802],
            [-69.83831022939357, -14.733235232258474],
            [-69.83616447939096, -14.739239857263737],
            [-69.83649122939117, -14.740720732265153],
            [-69.83690497939143, -14.74265910726699],
            [-69.83821172939255, -14.744749857269106],
            [-69.83920022939347, -14.745726607270127],
            [-69.84106485439516, -14.748256357272727],
            [-69.84905772940243, -14.756706607281597],
            [-69.85169435440497, -14.757991982283139],
            [-69.85320772940639, -14.759274607284524],
            [-69.85397135440672, -14.764713357289653],
            [-69.85417772940686, -14.7660429822909],
            [-69.85348435440602, -14.767458982292123],
            [-69.85372247940614, -14.768826232293407],
            [-69.85358197940597, -14.769417982293932],
            [-69.85299797940526, -14.771009857295326],
            [-69.85785397940968, -14.77685710730137],
            [-69.85712185440879, -14.778732482303003],
            [-69.85092635440195, -14.787303357310092],
            [-69.8495667294004, -14.789686482312101],
            [-69.84953510440032, -14.789901982312305],
            [-69.84956635440035, -14.790081982312483],
            [-69.8499499794007, -14.79046785731288],
            [-69.85131010440197, -14.791527482314043],
            [-69.85209085440272, -14.792127982314698],
            [-69.90436960445238, -14.832750607358994],
            [-69.90323285445082, -14.838503357364155],
            [-69.90166435444876, -14.844826357369794],
            [-69.89761272944418, -14.851567107375512],
            [-69.9072174794533, -14.860262482384739],
            [-69.92029922946641, -14.863115357389008],
            [-69.93154685447875, -14.850104732378368],
            [-69.94872985449629, -14.849752982380123],
            [-69.96229047950999, -14.851447982383288],
            [-69.96994335451723, -14.860255857392318],
            [-69.97331447952052, -14.862468732394744],
            [-69.97575197952278, -14.866115232398409],
            [-69.97721872952424, -14.866789107399187],
            [-69.98116210452822, -14.8673887324002],
            [-69.98384110453031, -14.877856482410172],
            [-69.97981110452591, -14.882095232413624],
            [-69.97656097952147, -14.899070232428931],
            [-69.97671997952143, -14.902638107432255],
            [-69.97690760452143, -14.905370982434807],
            [-69.96756847951075, -14.922295732449356],
            [-69.96672772950978, -14.923821857450674],
            [-69.96868335451158, -14.927003607453834],
            [-69.97516447951702, -14.9461739824723],
            [-69.97083047951234, -14.949579357474946],
            [-69.966805979508, -14.952365482477049],
            [-69.96420560450514, -14.95484210747902],
            [-69.96321497950404, -14.956018482479996],
            [-69.95827685449834, -14.965180357487874],
            [-69.96042535450042, -14.967668607490442],
            [-69.96131872950126, -14.968719607491508],
            [-69.96158147950095, -14.977758232499886],
            [-69.95978510449889, -14.980637732502343],
            [-69.96021560449927, -14.981417982503109],
            [-69.9611573545001, -14.983947107505562],
            [-69.9643103545026, -14.995181232516313],
            [-69.95849610449368, -15.03923498255632],
            [-69.95587597949071, -15.04316523255963],
            [-69.94331122947672, -15.05698048257087],
            [-69.93556997946861, -15.058409607571251],
            [-69.92949610446229, -15.058528732570613],
            [-69.92229072945476, -15.059153982570292],
            [-69.9209508544525, -15.071718607581735],
            [-69.9188964794503, -15.072671232582348],
            [-69.91604210444687, -15.079529357588317],
            [-69.92187772945212, -15.090980607599628],
            [-69.91575835444517, -15.099036107606288],
            [-69.91110610444065, -15.095081732602052],
            [-69.91042272943996, -15.094542607601463],
            [-69.90619047943511, -15.100794107606703],
            [-69.90569260443445, -15.102868732608549],
            [-69.90201347943037, -15.106215732611165],
            [-69.9006582294287, -15.109894732614388],
            [-69.89825697942615, -15.110800982614917],
            [-69.8838971044118, -15.102675607605542],
            [-69.88229685440999, -15.104421107606948],
            [-69.87925197940606, -15.11515285761646],
            [-69.88019197940692, -15.116719357618022],
            [-69.8902962294165, -15.12933023263099],
            [-69.89147122941759, -15.130975107632665],
            [-69.89143210441755, -15.13128848263295],
            [-69.89663447942185, -15.146752732647894],
            [-69.89750397942211, -15.155764107656319],
            [-69.89750397942207, -15.156560482657056],
            [-69.89943360442301, -15.171443357671055],
            [-69.89931222942263, -15.174923607674243],
            [-69.89934572942266, -15.174939482674265],
            [-69.89423822941671, -15.18341598268142],
            [-69.88256635440375, -15.193482607689184],
            [-69.91069522943307, -15.195920857695105],
            [-70.02035547955114, -15.153633857669117],
            [-70.0449518545795, -15.11065835763209],
            [-70.11616435465129, -15.167929982692371],
            [-70.06054360459113, -15.19404710771083],
            [-69.95181922947474, -15.219721357722209],
            [-69.89148672941215, -15.206475357702333],
            [-69.89362097941415, -15.209831982705722],
            [-69.89685997941699, -15.217972732713664],
            [-69.89704485441655, -15.226680607721715],
            [-69.90111022942035, -15.23337473272842],
            [-69.90340347942261, -15.235492357730664],
            [-69.90555622942496, -15.234309107729871],
            [-69.91437847943432, -15.233411982730162],
            [-69.9207403544411, -15.232501232730144],
            [-69.92218135444257, -15.232922607730696],
            [-69.92708872944749, -15.23720473273528],
            [-69.93010422945028, -15.24263723274068],
            [-69.93883997945954, -15.241829982741026],
            [-69.99836310452025, -15.278426107781941],
            [-70.0040957295254, -15.294068357797046],
            [-70.00352935452474, -15.294645857797503],
            [-70.00418560452408, -15.316777357818033],
            [-70.00599897952539, -15.32709423282778],
            [-70.00389985452304, -15.328905357829203],
            [-69.99740485451562, -15.336669482835632],
            [-69.995091729513, -15.339240232837737],
            [-69.99179160450919, -15.344024732841762],
            [-69.98914622950626, -15.345637732842958],
            [-69.9887591045058, -15.346003357843246],
            [-69.98874172950558, -15.349940232846873],
            [-69.98907310450585, -15.350555732847486],
            [-69.99058897950724, -15.354593732851386],
            [-69.98705810450286, -15.363947232859621],
            [-69.97770810449191, -15.378777232872201],
            [-69.9760476044899, -15.38218010787514],
            [-69.97538510448915, -15.383410357876198],
            [-69.97526685448884, -15.385847482878443],
            [-69.97803497949165, -15.388852107881531],
            [-69.98183610449554, -15.391156232884123],
            [-69.98229372949602, -15.391714982884697],
            [-69.98473072949852, -15.393442107886584],
            [-69.9880903545022, -15.39221173288584],
            [-69.99466760450923, -15.392542982886908],
            [-69.99549597951011, -15.3928506078873],
            [-69.99970722951446, -15.395524107890248],
            [-70.01086860452617, -15.399957607895635],
            [-70.0225176045387, -15.399031107896116],
            [-70.02545447954192, -15.398367732895831],
            [-70.03092910454819, -15.391542107890135],
            [-70.07296535459503, -15.360560857866071],
            [-70.08581785460804, -15.373529607879401],
            [-70.13419722965962, -15.378935607889277],
            [-70.1447911046715, -15.368552357880711],
            [-70.15690035468424, -15.373011732885978],
            [-70.15504397968466, -15.324016732840475],
            [-70.15370197968342, -15.320214232836845],
            [-70.14808597967779, -15.312634732829292],
            [-70.15265960468292, -15.3071876078247],
            [-70.1583637296897, -15.293458732812532],
            [-70.16364497969526, -15.294304607813798],
            [-70.16426222969588, -15.295241982814723],
            [-70.16702860469871, -15.29784835781739],
            [-70.16938347970115, -15.299174357818844],
            [-70.17203547970384, -15.301963607821667],
            [-70.17317860470503, -15.302717982822484],
            [-70.17782197971006, -15.30122810782152],
            [-70.1800754797125, -15.300336107820897],
            [-70.18371410471637, -15.300171732821077],
            [-70.18958610472264, -15.300034607821482],
            [-70.19543047972888, -15.29926960782131],
            [-70.20428710473836, -15.298203107821116],
            [-70.20717410474228, -15.279321357803891],
            [-70.20781647974326, -15.273205107798285],
            [-70.22113072975839, -15.251351357779212],
            [-70.227386729765, -15.251970732780332],
            [-70.22613347976437, -15.235575107765028],
            [-70.22677935476545, -15.226320357756546],
            [-70.22619535476491, -15.224701232754983],
            [-70.22757222976705, -15.209656232741175],
            [-70.23333610477361, -15.199267607732056],
            [-70.27879097982296, -15.167248107706115],
            [-70.28672460483135, -15.167097607706614],
            [-70.3163301048626, -15.166136732707997],
            [-70.35509422990349, -15.164601232709424],
            [-70.35691060490548, -15.162553607707661],
            [-70.35710885490573, -15.160951982706182],
            [-70.35420260490275, -15.158821732703993],
            [-70.35050372989892, -15.15755023270256],
            [-70.34994222989833, -15.156856732701872],
            [-70.34913310489758, -15.155403607700467],
            [-70.3490506048975, -15.154610982699722],
            [-70.35035510489911, -15.147807732693519],
            [-70.35179172990065, -15.146536232692437],
            [-70.35378972990277, -15.146453732692503],
            [-70.35804047990727, -15.145704357692125],
            [-70.35971997990906, -15.144988732691582],
            [-70.34739385489634, -15.138460482684643],
            [-70.34721860489618, -15.137759482683993],
            [-70.34775897989687, -15.134809482681291],
            [-70.34901497989826, -15.132823232679531],
            [-70.35758772990735, -15.130384232677894],
            [-70.3590919799092, -15.122935982671104],
            [-70.35799660490808, -15.12170923266989],
            [-70.35643397990648, -15.12100823266913],
            [-70.35536785490537, -15.120789107668852],
            [-70.34813347989777, -15.120922857668447],
            [-70.35098347990095, -15.115549232663668],
            [-70.35564485490573, -15.118180607666451],
            [-70.36008085491045, -15.117074357665732],
            [-70.36015585491062, -15.114442982663302],
            [-70.35987660491037, -15.113325982662237],
            [-70.35767760490836, -15.105128232654504],
            [-70.35681347990752, -15.10338060765282],
            [-70.35220447990302, -15.094028107643823],
            [-70.3516402299025, -15.091914232641825],
            [-70.34936622990041, -15.083476857633839],
            [-70.3466978548976, -15.08390385763405],
            [-70.3454303548963, -15.08299660763311],
            [-70.34797872989904, -15.08186248263226],
            [-70.34599072989714, -15.076765857627388],
            [-70.34476322989593, -15.074591107625281],
            [-70.34753847989897, -15.070615107621792],
            [-70.34920610490076, -15.069320982620711],
            [-70.35227485490417, -15.064891357616832],
            [-70.35488972990703, -15.060595232613034],
            [-70.35482310490708, -15.058113607610732],
            [-70.35494322990724, -15.056886232609607],
            [-70.35671772990918, -15.05417773260723],
            [-70.3585989799112, -15.052456607605759],
            [-70.36060022991349, -15.048187107601949],
            [-70.36015997991305, -15.047293232601083],
            [-70.35693122990969, -15.045945732599609],
            [-70.35423610490695, -15.04430460759789],
            [-70.35402260490687, -15.040141857594021],
            [-70.36165435491516, -15.03137610758643],
            [-70.35898585491238, -15.031723107586577],
            [-70.35611735490932, -15.033284107587816],
            [-70.35483647990797, -15.033364107587806],
            [-70.35284847990589, -15.033684357587962],
            [-70.34520347989793, -15.032456857586274],
            [-70.34507297989781, -15.03197148258581],
            [-70.3479487299009, -15.029578857583799],
            [-70.34891497990193, -15.029164732583485],
            [-70.35676660491033, -15.023726107579005],
            [-70.35968910491344, -15.022215607577804],
            [-70.36095322991478, -15.021345482577098],
            [-70.36284135491687, -15.018718482574794],
            [-70.36453247991875, -15.015172107571617],
            [-70.36523847991957, -15.013595982570207],
            [-70.36540260491978, -15.011937732568684],
            [-70.36515635491955, -15.011100357567889],
            [-70.36283160491723, -15.007904857564775],
            [-70.36392772991879, -14.996679107554447],
            [-70.36487222991978, -14.996440107554285],
            [-70.36776247992276, -14.996918107554931],
            [-70.3705389799255, -15.001810982559661],
            [-70.37373660492878, -15.003074107561044],
            [-70.38415985493991, -14.9967473575559],
            [-70.38378435493952, -14.995962232555152],
            [-70.38342022993918, -14.994938107554177],
            [-70.3743054799296, -14.99695223255541],
            [-70.37328135492876, -14.99071635754957],
            [-70.37449897993002, -14.991046357549955],
            [-70.3779014799335, -14.992491607551536],
            [-70.37921010493503, -14.988576982547993],
            [-70.37819722993397, -14.987826107547225],
            [-70.37562547993141, -14.985083732544505],
            [-70.3832154799394, -14.982955857543047],
            [-70.38561647994186, -14.983297232543539],
            [-70.39027047994678, -14.981726857542396],
            [-70.39154347994817, -14.979811357540699],
            [-70.38995872994658, -14.977648607538594],
            [-70.3860058549426, -14.97382348253477],
            [-70.38583472994243, -14.973512357534485],
            [-70.39159110494849, -14.971318732532827],
            [-70.39269572994962, -14.971956607533484],
            [-70.3962118549532, -14.974523607536108],
            [-70.39765872995474, -14.974134482535847],
            [-70.39937010495659, -14.971303107533341],
            [-70.40063022995798, -14.969249482531506],
            [-70.40136147995877, -14.968860482531198],
            [-70.4021549799597, -14.96592010752852],
            [-70.40156372995915, -14.964255357526941],
            [-70.40257497996035, -14.959432357522552],
            [-70.40398385496182, -14.958434482521714],
            [-70.4046897299626, -14.958099607521438],
            [-70.40639985496433, -14.959009232522405],
            [-70.40674910496467, -14.959722357523079],
            [-70.41137147996928, -14.96585198252907],
            [-70.41347497997147, -14.965954357529304],
            [-70.4158058549739, -14.965931607529424],
            [-70.4205243549789, -14.963555232527524],
            [-70.42780072998663, -14.958142607522976],
            [-70.42791772998676, -14.957533732522426],
            [-70.4278241049867, -14.956409482521362],
            [-70.42818710498715, -14.955121357520204],
            [-70.42857347998755, -14.954266607519429],
            [-70.42975622998884, -14.952861357518207],
            [-70.43002560498913, -14.9524163575178],
            [-70.43929810499871, -14.954198107520051],
            [-70.44389060500353, -14.952313607518576],
            [-70.44771473000762, -14.949697107516393],
            [-70.44820873000815, -14.948617607515418],
            [-70.44608260500604, -14.94544123251235],
            [-70.44541548000535, -14.94490748251181],
            [-70.44823985500842, -14.940526232507935],
            [-70.44891823000914, -14.940270482507719],
            [-70.46088323002174, -14.93494398250352],
            [-70.46453048002553, -14.933565232502469],
            [-70.46553135502667, -14.931385732500507],
            [-70.46499760502614, -14.930396107499545],
            [-70.46407460502519, -14.92948423249865],
            [-70.46285148002394, -14.928716982497866],
            [-70.4629483550241, -14.927671482496896],
            [-70.4636224800248, -14.927640607496913],
            [-70.46551698002673, -14.928152732497491],
            [-70.46569535502695, -14.927992232497356],
            [-70.4679257300293, -14.926017607495664],
            [-70.46616998002754, -14.924224607493896],
            [-70.46592973002734, -14.922831982492582],
            [-70.4668222300283, -14.921230482491167],
            [-70.46613873002762, -14.9206718574906],
            [-70.46524073002698, -14.911359607481904],
            [-70.4638872300257, -14.906406357477241],
            [-70.4629512300249, -14.901114607472278],
            [-70.46101760502319, -14.892139107463837],
            [-70.46103785502322, -14.891121357462884],
            [-70.4575433550198, -14.884680607456701],
            [-70.45627985501856, -14.882948232455023],
            [-70.45598023001833, -14.88081198245302],
            [-70.4493371050116, -14.874898232447137],
            [-70.44884210501112, -14.87405160744632],
            [-70.44386685500606, -14.870973232443166],
            [-70.44111385500331, -14.867625857439883],
            [-70.43767247999997, -14.860899607433433],
            [-70.43732835499965, -14.860242607432816],
            [-70.43748485499995, -14.855518732428445],
            [-70.43814185500065, -14.855205857428196],
            [-70.44020660500286, -14.852734357426032],
            [-70.44318673000646, -14.836613107411262],
            [-70.44206710500539, -14.833462732408275],
            [-70.45042748001421, -14.82758623240334],
            [-70.45192723001578, -14.82741960740327],
            [-70.45396635501793, -14.825838607401932],
            [-70.45297598001706, -14.820380107396804],
            [-70.44846485501272, -14.810647857387503],
            [-70.44673798001118, -14.803529607380801],
            [-70.44681273001132, -14.801163857378612],
            [-70.44551773001007, -14.798125857375712],
            [-70.44554273001043, -14.787990482366325],
            [-70.43055660499546, -14.773503857351963],
            [-70.42672247999165, -14.76866935734724],
            [-70.41767185498348, -14.73375198231429],
            [-70.41184935497772, -14.727483982308117],
            [-70.40421297997008, -14.720606857301249],
            [-70.40198635496789, -14.71847023229913],
            [-70.39852722996481, -14.703517357285039],
            [-70.38847897995521, -14.683671857265995],
            [-70.38596685495277, -14.679715357262161],
            [-70.38475447995162, -14.676955857259513],
            [-70.38359360495055, -14.673318482256056],
            [-70.38273397994978, -14.670400982253314],
            [-70.3820954799492, -14.667874357250922],
            [-70.38385322995117, -14.663233732246736],
            [-70.38578635495335, -14.65822748224221],
            [-70.38763047995566, -14.6452517322303],
            [-70.38772047995585, -14.642641732227895],
            [-70.38774685495609, -14.636712982222395],
            [-70.3919337299605, -14.633248607219457],
            [-70.39525635496389, -14.632650107219138],
            [-70.4032017299723, -14.62451898221209],
            [-70.41108572998058, -14.618315857206868],
            [-70.4144577299841, -14.61659323220548],
            [-70.41643697998612, -14.615823482204872],
            [-70.418096604988, -14.611173357200661],
            [-70.41793972998784, -14.610179107199723],
            [-70.41596047998596, -14.605817482195553],
            [-70.42428060499516, -14.584962482176739],
            [-70.42486697999577, -14.584009482175887],
            [-70.4234766049947, -14.573740232166296],
            [-70.40578397997702, -14.563278607155473],
            [-70.40403597997525, -14.562299732154449],
            [-70.3909041049616, -14.570190732160937],
            [-70.38956560496021, -14.571213357161794],
            [-70.388422604959, -14.572160857162604],
            [-70.38729460495782, -14.573619732163879],
            [-70.37682735494676, -14.584554107173325],
            [-70.37061560494014, -14.592223982180027],
            [-70.36846360493786, -14.594942232182417],
            [-70.36467310493389, -14.597316982184362],
            [-70.36252585493176, -14.595612107182623],
            [-70.35618160492557, -14.588683607175767],
            [-70.35506247992443, -14.588029357175103],
            [-70.34493397991426, -14.583428482170142],
            [-70.34349372991292, -14.580547982167351],
            [-70.34206447991157, -14.578033232164943],
            [-70.3428111049125, -14.573300982160593],
            [-70.35134447992172, -14.558737732147677],
            [-70.35397847992444, -14.557672857146873],
            [-70.3652988549362, -14.551143982141584],
            [-70.36664410493759, -14.550667607141218],
            [-70.36874560493997, -14.54346623213469],
            [-70.36920472994055, -14.541050732132504],
            [-70.36139560493268, -14.539021732130095],
            [-70.35429572992567, -14.533284232124283],
            [-70.352560604924, -14.530404482121506],
            [-70.3526244799241, -14.529839982120976],
            [-70.35258185492408, -14.528711107119946],
            [-70.35331672992494, -14.525867607117352],
            [-70.35396635492566, -14.523939982115614],
            [-70.35437097992616, -14.521820607113671],
            [-70.35406222992586, -14.521160357113024],
            [-70.3529119799249, -14.516101732108282],
            [-70.35252860492454, -14.514365857106624],
            [-70.35214522992423, -14.51205485710446],
            [-70.352777979925, -14.509162482101832],
            [-70.35284797992509, -14.508952482101622],
            [-70.35280010492511, -14.507666732100438],
            [-70.35241697992471, -14.506944732099745],
            [-70.3515898549239, -14.506216857099018],
            [-70.349668729922, -14.504822857097595],
            [-70.3493474799217, -14.504094857096897],
            [-70.3490073549214, -14.503158482096017],
            [-70.34854797992097, -14.502433982095306],
            [-70.34836685492077, -14.502213107095072],
            [-70.34745247991988, -14.501599107094458],
            [-70.34679497991924, -14.501269482094095],
            [-70.34557222991805, -14.499806482092659],
            [-70.34524222991777, -14.497839857090836],
            [-70.34271685491528, -14.495888732088835],
            [-70.33828122991109, -14.48861323208178],
            [-70.33779885491063, -14.486581982079866],
            [-70.3380527299111, -14.481966857075605],
            [-70.33821685491127, -14.48126173207496],
            [-70.33635922990953, -14.47757210707142],
            [-70.332679729906, -14.472078107066068],
            [-70.33058585490397, -14.46977148206379],
            [-70.33039647990383, -14.469109357063159],
            [-70.3296426049031, -14.468261732062304],
            [-70.32922660490289, -14.46235348205681],
            [-70.33027935490406, -14.459472607054208],
            [-70.33017772990401, -14.458048857052889],
            [-70.33083597990473, -14.456814482051774],
            [-70.33163285490556, -14.456289107051356],
            [-70.33215435490614, -14.454982482050195],
            [-70.33396485490803, -14.453287107048732],
            [-70.3340058549081, -14.452638732048154],
            [-70.332818354907, -14.449826232045464],
            [-70.33253122990675, -14.44886923204454],
            [-70.33199997990626, -14.448025357043726],
            [-70.33191410490618, -14.44757035704331],
            [-70.33221685490652, -14.447005857042798],
            [-70.33152535490584, -14.445980482041797],
            [-70.33066210490509, -14.44330273203925],
            [-70.32978322990434, -14.439148482035336],
            [-70.32985160490449, -14.437003982033351],
            [-70.32926947990408, -14.432927732029556],
            [-70.32909960490397, -14.43116210702791],
            [-70.32838285490323, -14.430878857027587],
            [-70.32669335490168, -14.427406232024243],
            [-70.32645897990147, -14.426337857023247],
            [-70.32667572990181, -14.423183607020327],
            [-70.32717385490247, -14.41929685701676],
            [-70.32679885490211, -14.418849607016307],
            [-70.32443885489992, -14.414126107011784],
            [-70.32320922989872, -14.412850857010506],
            [-70.31802872989377, -14.406008232003803],
            [-70.31826010489404, -14.404924232002825],
            [-70.31935310489538, -14.398162231996627],
            [-70.3193239798954, -14.397039981995594],
            [-70.3194843548957, -14.393586106992391],
            [-70.31904710489532, -14.392143356991038],
            [-70.31795410489438, -14.387800356986942],
            [-70.3176043548941, -14.38648885698567],
            [-70.31546197989222, -14.379318606978877],
            [-70.31992147989685, -14.37547123197563],
            [-70.32289835489992, -14.37319410697375],
            [-70.32363222990077, -14.3706428569714],
            [-70.32360860490091, -14.36612960696725],
            [-70.3239729799014, -14.363564481964872],
            [-70.32356647990102, -14.3621002319635],
            [-70.32341622990091, -14.361198606962649],
            [-70.32664735490434, -14.356640231958638],
            [-70.3266473549046, -14.349902731952398],
            [-70.32228910490036, -14.345895356948379],
            [-70.31752635489572, -14.34262660694503],
            [-70.31668835489491, -14.341887231944266],
            [-70.31473310489302, -14.340244231942636],
            [-70.31428947989257, -14.340112731942485],
            [-70.30128272987938, -14.343567481944744],
            [-70.2919374798702, -14.33887823193971],
            [-70.28615085486462, -14.333656981934446],
            [-70.2835524798621, -14.331480731932253],
            [-70.28113510485977, -14.329403856930144],
            [-70.2802264798589, -14.328285606929043],
            [-70.27650660485543, -14.32255898192346],
            [-70.27383097985307, -14.314341231915648],
            [-70.27309697985244, -14.31220935691361],
            [-70.27267047985208, -14.31063260691211],
            [-70.27168035485111, -14.309500981911],
            [-70.27117185485069, -14.308076981909638],
            [-70.26902522984874, -14.303337731905097],
            [-70.26809672984791, -14.300939231902802],
            [-70.26760322984761, -14.296706856898846],
            [-70.26726472984737, -14.294075856896388],
            [-70.266687229847, -14.289500106892115],
            [-70.26753122984799, -14.285723981888658],
            [-70.26824210484881, -14.282969606886137],
            [-70.26644097984702, -14.283050606886084],
            [-70.25129397983095, -14.305445106905692],
            [-70.24978472982939, -14.306169356906276],
            [-70.244411854824, -14.307256106906856],
            [-70.24060860482014, -14.308765231907964],
            [-70.23861635481813, -14.309127481908144],
            [-70.22591772980576, -14.30283560690129],
            [-70.22340335480364, -14.29475960689361],
            [-70.22370522980411, -14.290473356889683],
            [-70.22261272980337, -14.28267523188236],
            [-70.22072147980171, -14.277510606877433],
            [-70.20937372979087, -14.26841785686809],
            [-70.20406522978531, -14.274613981873411],
            [-70.20183047978294, -14.278105606876446],
            [-70.19857160477953, -14.281317856879165],
            [-70.19396272977471, -14.286718231883798],
            [-70.18907447976973, -14.289697731886136],
            [-70.17976947976028, -14.293914106889241],
            [-70.18034960476054, -14.30107223189593],
            [-70.18038672976053, -14.302183606896966],
            [-70.18192972976168, -14.309662106904018],
            [-70.18183785476145, -14.312226606906394],
            [-70.18273247976221, -14.315384731909393],
            [-70.18296685476241, -14.316070356910037],
            [-70.18302147976237, -14.317578106911462],
            [-70.18283397976217, -14.318080106911898],
            [-70.18327735476257, -14.319322231913066],
            [-70.18141597976076, -14.318572231912238],
            [-70.1807909797601, -14.319330106912881],
            [-70.18066210475997, -14.31941785691296],
            [-70.17976372975899, -14.321195356914528],
            [-70.17792572975712, -14.32151373191466],
            [-70.1758516047551, -14.321152356914135],
            [-70.1753418547546, -14.320496106913502],
            [-70.17265235475203, -14.318722606911619],
            [-70.17213285475148, -14.31900385691183],
            [-70.17149222975083, -14.319767606912492],
            [-70.17110160475036, -14.3212069819138],
            [-70.17117760475041, -14.321539106914095],
            [-70.17054635474969, -14.323582481915942],
            [-70.16921097974841, -14.322890606915202],
            [-70.16912497974832, -14.322572106914885],
            [-70.17034572974966, -14.320359356912945],
            [-70.16822260474768, -14.31768148191029],
            [-70.16830860474768, -14.319146481911641],
            [-70.16830272974767, -14.319478481911954],
            [-70.16764647974682, -14.323074231915246],
            [-70.1670527297462, -14.323937481915983],
            [-70.16637310474557, -14.323220731915246],
            [-70.16669335474596, -14.321695356913855],
            [-70.16625985474555, -14.321199231913376],
            [-70.16462885474394, -14.321269481913289],
            [-70.16417185474343, -14.321966856913884],
            [-70.16410160474328, -14.323964856915731],
            [-70.15797472973713, -14.325037106916211],
            [-70.15819535473734, -14.325388731916544],
            [-70.1596503547387, -14.326845731918029],
            [-70.16004685473905, -14.328111356919246],
            [-70.15978910473875, -14.32857423191962],
            [-70.15938672973822, -14.331570356922363],
            [-70.15820310473697, -14.333240231923822],
            [-70.15769922973654, -14.33158010692223],
            [-70.15764260473648, -14.331296856921979],
            [-70.15567972973447, -14.332402356922806],
            [-70.15620697973493, -14.333826231924196],
            [-70.15527535473393, -14.33520698192539],
            [-70.15259372973125, -14.336150356926026],
            [-70.15084760472931, -14.33991585692937],
            [-70.14880272972725, -14.340699231929912],
            [-70.14818560472669, -14.339160106928427],
            [-70.14621672972484, -14.337078106926313],
            [-70.14406247972266, -14.338343731927294],
            [-70.14487310472333, -14.34120898193003],
            [-70.14242572972084, -14.342062481930615],
            [-70.14130660471959, -14.344972606933197],
            [-70.13933197971753, -14.346703231934628],
            [-70.1375078547157, -14.347033231934772],
            [-70.13754685471558, -14.350310481937813],
            [-70.13764847971551, -14.353642606940895],
            [-70.1354159797132, -14.355140606942063],
            [-70.13504097971284, -14.35495510694189],
            [-70.13458397971252, -14.352621106939674],
            [-70.1326093547107, -14.349841856936923],
            [-70.13202935471016, -14.349449231936513],
            [-70.1301191047081, -14.352031231938732],
            [-70.12948810470748, -14.352474606939083],
            [-70.1259121047039, -14.352775356939038],
            [-70.12502522970303, -14.353042981939202],
            [-70.12006247969796, -14.355292981940808],
            [-70.11977735469785, -14.352394481938127],
            [-70.1197207296978, -14.352238231937969],
            [-70.11864260469676, -14.351574231937246],
            [-70.11702347969515, -14.352146481937641],
            [-70.11658397969467, -14.352488231937896],
            [-70.11685935469481, -14.354716856940003],
            [-70.11663472969458, -14.355099606940325],
            [-70.11493747969291, -14.354701231939789],
            [-70.11403122969202, -14.354912106939933],
            [-70.11344922969135, -14.35621673194108],
            [-70.11296097969084, -14.357390606942111],
            [-70.11130660468912, -14.358734356943184],
            [-70.1105957296884, -14.358789106943188],
            [-70.10700022968483, -14.358929106942986],
            [-70.10635935468416, -14.359215981943166],
            [-70.10290622968071, -14.359982481943572],
            [-70.10201385467983, -14.360312481943792],
            [-70.1012968546791, -14.360365231943765],
            [-70.09897260467679, -14.360337856943515],
            [-70.09755072967548, -14.359070356942217],
            [-70.09222847967007, -14.36112110694362],
            [-70.08938285466725, -14.361656231943835],
            [-70.08815822966596, -14.36295898194494],
            [-70.08563085466344, -14.363421856945095],
            [-70.08358785466145, -14.36271685694427],
            [-70.08163472965953, -14.36227148194366],
            [-70.07930060465723, -14.362271481943434],
            [-70.078078104656, -14.362681606943688],
            [-70.07750385465536, -14.363968731944817],
            [-70.07637697965421, -14.364570356945276],
            [-70.07319335465102, -14.365230481945595],
            [-70.06918160464699, -14.366357481946238],
            [-70.0658318546437, -14.366298856945832],
            [-70.0636796046415, -14.367800856947028],
            [-70.06285747964067, -14.368037106947163],
            [-70.06211135463992, -14.368187481947212],
            [-70.06054097963842, -14.367367231946334],
            [-70.06008010463799, -14.367415981946321],
            [-70.05600972963386, -14.368945356947314],
            [-70.0538906046317, -14.369949231948043],
            [-70.05234022963018, -14.370100106948012],
            [-70.05010635462801, -14.369325981947073],
            [-70.04853572962648, -14.369060481946681],
            [-70.04550547962359, -14.367755481945176],
            [-70.04473122962293, -14.366030106943494],
            [-70.04192210462021, -14.36530023194251],
            [-70.03942260461766, -14.366848606943696],
            [-70.03710010461525, -14.368529606945028],
            [-70.02688097960524, -14.367976606943436],
            [-70.02538122960378, -14.367941981943277],
            [-70.0219922296005, -14.367156231942197],
            [-70.02023347959879, -14.366759981941644],
            [-70.01783510459644, -14.367005231941624],
            [-70.01581822959442, -14.36741398194176],
            [-70.01170435459036, -14.36770448194161],
            [-70.01029672958899, -14.367835481941599],
            [-70.0046016045833, -14.36948823194252],
            [-70.00365822958231, -14.370185606943048],
            [-70.00258172958127, -14.36993898194269],
            [-70.00136285458012, -14.369537731942211],
            [-69.99998635457868, -14.370556606943008],
            [-69.99693060457582, -14.36869498194095],
            [-69.99425960457319, -14.368885856940844],
            [-69.99115247957013, -14.369294606940883],
            [-69.98663360456577, -14.368581231939714],
            [-69.98472235456391, -14.368103356939086],
            [-69.97591710455535, -14.36783035693782],
            [-69.97427885455372, -14.367830356937645],
            [-69.9689547295483, -14.371448106940404],
            [-69.96294797954229, -14.37376885694189],
            [-69.93580472951406, -14.400003856963014],
            [-69.9354902295137, -14.400910106963805],
            [-69.93213797951039, -14.401592106964046],
            [-69.92554460450378, -14.404260731965733],
            [-69.92127460449957, -14.405296856966173],
            [-69.91750010449577, -14.407242856967535],
            [-69.91575185449405, -14.407524106967575],
            [-69.9040369794822, -14.414074856972219],
            [-69.90224860448039, -14.415139856972974],
            [-69.89719497947497, -14.42219085697889],
            [-69.89774147947537, -14.424312856980913],
            [-69.89550710447304, -14.42651510698268],
            [-69.89469335447222, -14.426818356982837],
            [-69.891697104469, -14.43095310698629],
            [-69.88987110446688, -14.435685606990443],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 6,
        FIRST_IDPR: '0102',
        NOMBPROV: 'BAGUA',
        FIRST_NOMB: 'AMAZONAS',
        LAST_DCTO: 'LEY',
        LAST_LEY: 'S/N',
        FIRST_FECH: '01/09/1941',
        LAST_FECHA: '05/02/1861',
        MIN_SHAPE_: 573354.36072,
        ha: 573354.36,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.58981148771682, -5.324943598589535],
            [-78.68424411280036, -5.267490223535599],
            [-78.70911323782185, -5.179628848453651],
            [-78.7006242378141, -5.104082723383374],
            [-78.65771411277623, -5.067422973349459],
            [-78.61963073774234, -5.000525098287385],
            [-78.64582023776538, -4.962265598251644],
            [-78.60064648772477, -4.773386723076015],
            [-78.64466136276363, -4.736452348041433],
            [-78.63793773775745, -4.657981847968406],
            [-78.705955112817, -4.62387309793632],
            [-78.66080598777737, -4.586063097901368],
            [-78.66112336277747, -4.530080972849242],
            [-78.63575023775503, -4.500882472822165],
            [-78.59891761272249, -4.509091222829963],
            [-78.5887153627136, -4.561966472879238],
            [-78.52242998765449, -4.585746097901617],
            [-78.46583011260371, -4.648492222960219],
            [-78.41692573755948, -4.659531097970655],
            [-78.3612611125088, -4.647703597959793],
            [-78.32948048747983, -4.679537097989519],
            [-78.2772073624319, -4.66275172297401],
            [-78.25334836241004, -4.705210473013595],
            [-78.21889923737828, -4.658537722970214],
            [-78.1472857373121, -4.643187597956048],
            [-78.12014111228703, -4.699513348008532],
            [-78.14523986231033, -4.758099348063056],
            [-78.11941261228651, -4.840238973139591],
            [-78.19484098735651, -5.037893598323512],
            [-78.15881136232325, -5.109946973390659],
            [-78.09932248726814, -5.14608997342442],
            [-78.05689711222878, -5.20859172348272],
            [-78.06761323723877, -5.265271473535475],
            [-78.10614648727467, -5.341998098606854],
            [-78.14627998731191, -5.339879973604808],
            [-78.19221936235458, -5.41721384867676],
            [-78.23886961239776, -5.470393723726164],
            [-78.311830362465, -5.485992598740495],
            [-78.32613186247826, -5.56619047381515],
            [-78.31650736246951, -5.617634223863054],
            [-78.36813073751688, -5.62679147387144],
            [-78.40557061255124, -5.69025734893041],
            [-78.45945661260042, -5.715840973954031],
            [-78.50331123764013, -5.691557098931262],
            [-78.56536173769577, -5.587881973834442],
            [-78.55474736268606, -5.538074723788134],
            [-78.55851523768932, -5.499089348751822],
            [-78.51517811265019, -5.454349223710349],
            [-78.51840236265295, -5.399279348659072],
            [-78.58981148771682, -5.324943598589535],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 5,
        FIRST_IDPR: '1502',
        NOMBPROV: 'BARRANCA',
        FIRST_NOMB: 'LIMA',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: '23/11/1976',
        LAST_FECHA: 'EPOC.INDEP.',
        MIN_SHAPE_: 137025.082121,
        ha: 137025.08,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.38557036160107, -10.707593728604433],
            [-77.40249273661703, -10.76797572866067],
            [-77.45815423666944, -10.809955103699703],
            [-77.4622207366733, -10.87006635375567],
            [-77.44429298665642, -10.916097603798569],
            [-77.52571098673312, -10.931980478813294],
            [-77.56138636176678, -10.87839785376334],
            [-77.59952711180276, -10.860028353746221],
            [-77.70151561189904, -10.886117353770366],
            [-77.70366411190109, -10.857728478743958],
            [-77.750183611945, -10.817740228706633],
            [-77.74403511193921, -10.794537103685037],
            [-77.81065623700212, -10.699894478596795],
            [-77.8862714870736, -10.611919978514722],
            [-77.83298923702316, -10.567483103473453],
            [-77.77137648696491, -10.571614603477421],
            [-77.77244336196587, -10.512478228422333],
            [-77.79617773698831, -10.483292853395128],
            [-77.76021161195428, -10.43022122834576],
            [-77.75704098695124, -10.344216853265666],
            [-77.69875173689618, -10.322104103245161],
            [-77.64920436184943, -10.34346747826511],
            [-77.61942723682132, -10.327825353250587],
            [-77.59937298680242, -10.38333397830229],
            [-77.68391998688222, -10.431385978346931],
            [-77.7367812369322, -10.56321210346964],
            [-77.64425973684482, -10.527409103436403],
            [-77.64073036184152, -10.596271728500533],
            [-77.57665036178108, -10.58374810348894],
            [-77.58637223679024, -10.646944853547794],
            [-77.5771744867816, -10.728570228623814],
            [-77.49495986170409, -10.7461026036402],
            [-77.38557036160107, -10.707593728604433],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 6,
        FIRST_IDPR: '2202',
        NOMBPROV: 'BELLAVISTA',
        FIRST_NOMB: 'SAN MARTIN',
        LAST_DCTO: 'LEY',
        LAST_LEY: '10164',
        FIRST_FECH: '05/01/1945',
        LAST_FECHA: '05/01/1945',
        MIN_SHAPE_: 806890.527195,
        ha: 806890.53,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.98496286029147, -8.322150351383064],
            [-76.08717573538672, -8.336906226396806],
            [-76.10916598540722, -8.379613226436579],
            [-76.14578123544136, -8.367545101425337],
            [-76.16583061046003, -8.311226476372884],
            [-76.19709561048919, -8.294439226357259],
            [-76.23672873552614, -8.211309476279819],
            [-76.2996521105848, -8.217456101285553],
            [-76.33320698561607, -8.203689476272718],
            [-76.33081273561385, -8.153002226225523],
            [-76.39273548567161, -8.120216726194991],
            [-76.4569238607315, -8.054822226134075],
            [-76.52004686079042, -8.03136322611224],
            [-76.56328486083076, -7.99212210107568],
            [-76.61433011087844, -7.985707101069708],
            [-76.62452348588796, -7.906803225996232],
            [-76.60572736087039, -7.881425975972592],
            [-76.63165648589462, -7.842609475936433],
            [-76.6302448608933, -7.753482100853446],
            [-76.59771673586293, -7.689289100793657],
            [-76.56560948583295, -7.657190975763745],
            [-76.5796758608461, -7.60761610071758],
            [-76.55147998581974, -7.504289975621347],
            [-76.57430998584108, -7.386819975511948],
            [-76.55778998582566, -7.307079975437702],
            [-76.6144999858786, -7.213509975350561],
            [-76.63376998589659, -7.152279975293512],
            [-76.67040373593082, -7.127247100270212],
            [-76.63264823589556, -7.098967600243876],
            [-76.63391986089675, -6.986267725138903],
            [-76.71080173596856, -6.872230225032689],
            [-76.73446686099068, -6.802689474967942],
            [-76.68552998594495, -6.810219849974947],
            [-76.62120998588487, -6.77616997494323],
            [-76.59626998586158, -6.794449974960259],
            [-76.52387311079399, -6.725382849895949],
            [-76.48149023575444, -6.807419974972341],
            [-76.47135161074496, -6.855978475017574],
            [-76.54864361081711, -6.928329225084966],
            [-76.52350998579364, -6.976039975129376],
            [-76.4176757356949, -7.018316100168775],
            [-76.38063911066035, -7.014832475165505],
            [-76.31900061060283, -7.058452475206157],
            [-76.18599186047885, -7.075912600222397],
            [-76.15776198545251, -7.102745725247394],
            [-76.1579426104527, -7.198294600336381],
            [-76.14352523543924, -7.203962475341661],
            [-76.16666148546082, -7.267596350400923],
            [-76.2131113605041, -7.310142600440558],
            [-76.1960382354882, -7.333528100462317],
            [-76.21455061050544, -7.404520725528457],
            [-76.16614511046032, -7.494307100612066],
            [-76.09137361039065, -7.560355725673569],
            [-76.07989048537992, -7.591160100702259],
            [-76.09768373539652, -7.642242475749818],
            [-76.10159261040017, -7.722663975824744],
            [-76.0848799853846, -7.761814725861192],
            [-76.04380298534632, -7.79234360088963],
            [-75.96697948527472, -7.960359351046109],
            [-75.85938623517448, -7.921168976009612],
            [-75.82949286014664, -7.973495851058327],
            [-75.84493273516101, -8.059638101138562],
            [-75.91700598522817, -8.071776351149868],
            [-75.94959273525853, -8.099232976175465],
            [-75.94116486025068, -8.18618397625643],
            [-75.91336173522477, -8.254386851319952],
            [-75.93488923524482, -8.31155172637318],
            [-75.98496286029147, -8.322150351383064],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 6,
        FIRST_IDPR: '1303',
        NOMBPROV: 'BOLIVAR',
        FIRST_NOMB: 'LA LIBERTAD',
        LAST_DCTO: 'LEY',
        LAST_LEY: 'S/N',
        FIRST_FECH: '20/11/1916',
        LAST_FECHA: '02/01/1857',
        MIN_SHAPE_: 173630.95565,
        ha: 173630.96,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.53094861173722, -7.654479850761039],
            [-77.54556098675097, -7.672182475777507],
            [-77.60593748680772, -7.647134725754142],
            [-77.62204486182286, -7.622169975730906],
            [-77.70093161189698, -7.59353710070415],
            [-77.74598273693927, -7.479159725597616],
            [-77.77198648696368, -7.418458225541034],
            [-77.8134449870026, -7.392539850516848],
            [-77.82960248701775, -7.347638600475015],
            [-77.83105023701911, -7.310030975440013],
            [-77.92268923710502, -7.157463475297789],
            [-77.9511497371316, -7.064613475211298],
            [-77.99950648717684, -6.970531600123599],
            [-77.92752798710941, -6.986709225138778],
            [-77.88034173706515, -6.962503850116303],
            [-77.84586473703281, -6.976398100129279],
            [-77.75893361195118, -6.964507850118296],
            [-77.71337861190848, -7.107887100251869],
            [-77.64353448684288, -7.149000600290216],
            [-77.6055302368072, -7.21916185035557],
            [-77.64674586184593, -7.240740100375643],
            [-77.62864598682894, -7.320024225449508],
            [-77.6357093618356, -7.35744610048435],
            [-77.61027086181173, -7.434858350556446],
            [-77.55953711176404, -7.464817100584384],
            [-77.52691773673341, -7.504839350621698],
            [-77.5367046117426, -7.582280975693791],
            [-77.53094861173722, -7.654479850761039],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 15,
        FIRST_IDPR: '0205',
        NOMBPROV: 'BOLOGNESI',
        FIRST_NOMB: 'ANCASH',
        LAST_DCTO: 'LEY',
        LAST_LEY: 'S/N',
        FIRST_FECH: '29/01/1965',
        LAST_FECHA: '02/01/1857',
        MIN_SHAPE_: 312887.533362,
        ha: 312887.53,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.18713473641454, -9.836212852793032],
            [-77.09279373632603, -9.836754477793544],
            [-77.02779886126513, -9.764121102725909],
            [-77.02384173626143, -9.704326227670249],
            [-76.98104298622133, -9.724568352689095],
            [-76.94538861118794, -9.70256835266862],
            [-76.90138286114676, -9.770496102731885],
            [-76.895210986141, -9.815601602773867],
            [-76.81367573606471, -9.961572227909839],
            [-76.82404686107441, -10.010998102955881],
            [-76.80842186105978, -10.08484572802465],
            [-76.91546486115996, -10.119914103057296],
            [-76.90624611115133, -10.274185603200953],
            [-76.96545148620675, -10.305378603229993],
            [-77.00672023624541, -10.284231728210298],
            [-77.02471161126226, -10.327526728250616],
            [-77.0716116113062, -10.338103978260454],
            [-77.11098636134314, -10.403341853321216],
            [-77.17603998640416, -10.483099978395474],
            [-77.17287111140118, -10.40086722831887],
            [-77.15820898638744, -10.361919978282607],
            [-77.22968323645452, -10.330003353252877],
            [-77.24375623646772, -10.26716610319436],
            [-77.35950386157651, -10.292033228217445],
            [-77.39914648661379, -10.272048853198799],
            [-77.46252148667342, -10.275658353202129],
            [-77.4814782366913, -10.346228603267855],
            [-77.52363848673103, -10.38739972830616],
            [-77.59937298680242, -10.38333397830229],
            [-77.61942723682132, -10.327825353250587],
            [-77.64920436184943, -10.34346747826511],
            [-77.69875173689618, -10.322104103245161],
            [-77.69642736189395, -10.256850353184392],
            [-77.67440436187314, -10.20671147813773],
            [-77.5753231117797, -10.24986022817802],
            [-77.55465286176022, -10.188464603120876],
            [-77.51526361172309, -10.147400353082649],
            [-77.45866598666977, -10.15616210309087],
            [-77.40064848661517, -10.132154353068527],
            [-77.3458071115636, -10.160530853094997],
            [-77.32052961153984, -10.127044228063808],
            [-77.28366798650524, -10.154211728089141],
            [-77.21277498643863, -10.133711603070084],
            [-77.20159961142814, -10.09338285303253],
            [-77.21411911143989, -10.023732477967666],
            [-77.20560411143188, -9.967025102914862],
            [-77.17378986140204, -9.913134227864669],
            [-77.18713473641454, -9.836212852793032],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 12,
        FIRST_IDPR: '0103',
        NOMBPROV: 'BONGARA',
        FIRST_NOMB: 'AMAZONAS',
        LAST_DCTO: 'LEY',
        LAST_LEY: 'S/N',
        FIRST_FECH: '03/11/1933',
        LAST_FECHA: '26/12/1870',
        MIN_SHAPE_: 283842.079303,
        ha: 283842.08,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.89094923707454, -6.102691349315582],
            [-77.98208011215966, -5.96846684919046],
            [-78.05030611222332, -5.977173724198451],
            [-78.0744531122458, -5.920373099145533],
            [-78.13080473729819, -5.902560599128826],
            [-78.07635686224741, -5.769425724004951],
            [-78.10397261227308, -5.755042973991507],
            [-77.98808398716497, -5.567720723817248],
            [-77.99126761216787, -5.514076223767271],
            [-77.94142311212133, -5.489394973744362],
            [-77.93136173711191, -5.460812598717755],
            [-77.95551511213445, -5.399720598660827],
            [-77.74528511193766, -5.133017598412655],
            [-77.69597786189145, -5.163660223441226],
            [-77.73955961193231, -5.212597348486766],
            [-77.69308011188878, -5.267685473538094],
            [-77.68206648687847, -5.33839559860396],
            [-77.69595848689151, -5.411053098671616],
            [-77.74482773693734, -5.406899098667711],
            [-77.76845123695946, -5.438048723696728],
            [-77.76576173695699, -5.538982473790724],
            [-77.73689061192995, -5.605138848852346],
            [-77.77370711196448, -5.685207098926874],
            [-77.76392911195539, -5.819708724052145],
            [-77.67788286187472, -5.859646599089411],
            [-77.65189061185036, -5.935331974159911],
            [-77.67167486186892, -5.974104349195999],
            [-77.71725786191168, -5.944195349168114],
            [-77.77450323696536, -5.97431684919612],
            [-77.80623436199514, -6.031873099249705],
            [-77.82187498700986, -6.130499974341552],
            [-77.89094923707454, -6.102691349315582],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 4,
        FIRST_IDPR: '0602',
        NOMBPROV: 'CAJABAMBA',
        FIRST_NOMB: 'CAJAMARCA',
        LAST_DCTO: 'DEC.',
        LAST_LEY: 'S/N',
        FIRST_FECH: 'EPOCA INDEP.',
        LAST_FECHA: '11/02/1855',
        MIN_SHAPE_: 178954.55983,
        ha: 178954.56,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.26115436242236, -7.754023975852518],
            [-78.302802737461, -7.696462725798789],
            [-78.34796498750292, -7.685716475788603],
            [-78.37606448752885, -7.639958975745895],
            [-78.35679223751083, -7.574006100684548],
            [-78.36082723751447, -7.529961350643546],
            [-78.33583098749122, -7.495248225611308],
            [-78.34367398749829, -7.417378100538767],
            [-78.2773828624367, -7.373447350498122],
            [-78.21984173738318, -7.387624225511494],
            [-78.17438411234092, -7.457267100576462],
            [-78.13174798730111, -7.480312475598042],
            [-78.08496673725732, -7.454312475573923],
            [-78.01331836219026, -7.462164100581388],
            [-77.9826336121614, -7.387463100511884],
            [-77.94255136212378, -7.345664600473027],
            [-77.883101612068, -7.364187475490372],
            [-77.82960248701775, -7.347638600475015],
            [-77.8134449870026, -7.392539850516848],
            [-77.77198648696368, -7.418458225541034],
            [-77.74598273693927, -7.479159725597616],
            [-77.84416798703154, -7.514380850630297],
            [-77.87043748705628, -7.585330100696316],
            [-77.91278073709607, -7.624903475733109],
            [-77.9986422371767, -7.679073975783405],
            [-78.08122111225416, -7.696168850799141],
            [-78.11605336228669, -7.654007350759825],
            [-78.22465311238835, -7.759331475857596],
            [-78.26115436242236, -7.754023975852518],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 12,
        FIRST_IDPR: '0601',
        NOMBPROV: 'CAJAMARCA',
        FIRST_NOMB: 'CAJAMARCA',
        LAST_DCTO: 'LEY',
        LAST_LEY: 'S/N',
        FIRST_FECH: '14/12/1870',
        LAST_FECHA: '02/01/1857',
        MIN_SHAPE_: 298330.882677,
        ha: 298330.88,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.45347761259796, -6.834194974995317],
            [-78.42663586257362, -6.931160100085711],
            [-78.38619336253639, -6.925795600080896],
            [-78.34051061249431, -6.954177225107491],
            [-78.29145161244907, -7.023271975172009],
            [-78.21945786238228, -7.071584850217192],
            [-78.18369448734911, -7.149147475289518],
            [-78.23956248740122, -7.206537100342805],
            [-78.21620898737966, -7.296535225426664],
            [-78.27175198743132, -7.293609350423774],
            [-78.30729686246438, -7.333624975460938],
            [-78.2773828624367, -7.373447350498122],
            [-78.34367398749829, -7.417378100538767],
            [-78.33583098749122, -7.495248225611308],
            [-78.415632862565, -7.485070350601496],
            [-78.49139261263497, -7.554873100666126],
            [-78.62457223775658, -7.548558600659431],
            [-78.64959561277914, -7.529017100641082],
            [-78.62984273776088, -7.445499975563473],
            [-78.69622661282062, -7.41336322553308],
            [-78.66540886279272, -7.379005600501299],
            [-78.62164986275276, -7.285361725414437],
            [-78.64984573777811, -7.255634725386574],
            [-78.74599411286411, -7.219331975352074],
            [-78.71999611284068, -7.170818350307104],
            [-78.6617148627881, -7.102990225244382],
            [-78.62368361275342, -7.010656225158698],
            [-78.60498236273648, -6.997251850146332],
            [-78.61350611274385, -6.920726350075052],
            [-78.60137873773269, -6.867628850025682],
            [-78.5309551126686, -6.799365224962532],
            [-78.46596336260933, -6.806838474969781],
            [-78.45347761259796, -6.834194974995317],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 5,
        FIRST_IDPR: '1503',
        NOMBPROV: 'CAJATAMBO',
        FIRST_NOMB: 'LIMA',
        LAST_DCTO: 'L.R.',
        LAST_LEY: '457',
        FIRST_FECH: '25/09/1868',
        LAST_FECHA: '22/01/1921',
        MIN_SHAPE_: 152718.474354,
        ha: 152718.47,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.79989648605182, -10.419126978335964],
            [-76.82817298607826, -10.428980603345124],
            [-76.85720423610545, -10.49335872840507],
            [-76.86330861111112, -10.621822353524724],
            [-76.87947261112626, -10.656218728556757],
            [-76.95340836119546, -10.694613353592494],
            [-77.01096648624939, -10.695993103593766],
            [-77.05509361129073, -10.691384103589481],
            [-77.12272298635416, -10.648956103549933],
            [-77.19722998642409, -10.686829853585198],
            [-77.29432823651528, -10.66039235356053],
            [-77.34470323656262, -10.607980478511678],
            [-77.31062886153059, -10.567691353474201],
            [-77.25187111147538, -10.545242228453294],
            [-77.19098823641822, -10.552132853459764],
            [-77.17603998640416, -10.483099978395474],
            [-77.11098636134314, -10.403341853321216],
            [-77.0716116113062, -10.338103978260454],
            [-77.02471161126226, -10.327526728250616],
            [-77.00672023624541, -10.284231728210298],
            [-76.96545148620675, -10.305378603229993],
            [-76.90624611115133, -10.274185603200953],
            [-76.85639448610468, -10.377265603296964],
            [-76.79989648605182, -10.419126978335964],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 8,
        FIRST_IDPR: '0804',
        NOMBPROV: 'CALCA',
        FIRST_NOMB: 'CUSCO',
        LAST_DCTO: 'LEY',
        LAST_LEY: '23383',
        FIRST_FECH: '28/02/1946',
        LAST_FECHA: '18/05/1982',
        MIN_SHAPE_: 353947.81538,
        ha: 353947.82,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.71952348129365, -13.552474606250927],
            [-71.73767160631112, -13.570121606267481],
            [-71.80462598137579, -13.508258106210283],
            [-71.87545510644391, -13.499350981202388],
            [-71.98021685654447, -13.42949610613781],
            [-71.96867898153347, -13.398602106108989],
            [-72.04412773160574, -13.290567856008682],
            [-72.05768298161877, -13.219448980942534],
            [-72.14880298170569, -13.177080230903346],
            [-72.15242160670914, -13.138597855867534],
            [-72.19063460674556, -13.096150355828097],
            [-72.1693167317253, -13.02424335576108],
            [-72.21589160676963, -12.991326730730547],
            [-72.22619273177946, -12.953121605695014],
            [-72.31194410686082, -12.923645605667732],
            [-72.36225623190856, -12.824412605575409],
            [-72.36288673190921, -12.718562480476843],
            [-72.31641673186522, -12.648935105411942],
            [-72.22658373178001, -12.664842105426573],
            [-72.18576760674124, -12.657139730419305],
            [-72.13537723169335, -12.616971355381791],
            [-72.12451123168309, -12.54849698031801],
            [-72.07113235663235, -12.511484230283388],
            [-72.03145885659451, -12.524772730295647],
            [-71.98139610654673, -12.580490480347352],
            [-71.95638098152273, -12.654872605416532],
            [-71.90035160646909, -12.733355480489367],
            [-71.89140648146036, -12.833209855582298],
            [-71.82850348139988, -12.95422798069466],
            [-71.90636773147438, -13.020791230757006],
            [-71.88889048145758, -13.053487605787351],
            [-71.86384098143326, -13.227775605949505],
            [-71.82129698139222, -13.319599356034773],
            [-71.73968110631351, -13.385590731095729],
            [-71.74649510632001, -13.420927856128653],
            [-71.71952348129365, -13.552474606250927],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 6,
        FIRST_IDPR: '0701',
        NOMBPROV: 'CALLAO',
        FIRST_NOMB: 'CALLAO',
        LAST_DCTO: 'D.L.',
        LAST_LEY: '17392',
        FIRST_FECH: '06/10/1915',
        LAST_FECHA: '28/01/1969',
        MIN_SHAPE_: 14140.9543718,
        ha: 14140.95,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.1115332363437, -12.079492229882156],
            [-77.15112698638087, -12.067031229870546],
            [-77.1327422363636, -11.976492229786215],
            [-77.15683011138621, -11.874302729691054],
            [-77.18709761141463, -11.828361354648262],
            [-77.1260484863573, -11.820788104641217],
            [-77.0899043613234, -11.901786729716678],
            [-77.11754961134935, -11.956969604768062],
            [-77.08294048631687, -12.036417854842059],
            [-77.1115332363437, -12.079492229882156],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 8,
        FIRST_IDPR: '0402',
        NOMBPROV: 'CAMANA',
        FIRST_NOMB: 'AREQUIPA',
        LAST_DCTO: 'LEY',
        LAST_LEY: '9999',
        FIRST_FECH: 'EPOCA INDEP.',
        LAST_FECHA: '03/11/1944',
        MIN_SHAPE_: 399892.39742,
        ha: 399892.4,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.5936279821266, -16.430480983933577],
            [-72.46635435700613, -16.383155858889257],
            [-72.48471073202349, -16.451091733952552],
            [-72.5119552320493, -16.49141698399016],
            [-72.57157135710574, -16.5120754840095],
            [-72.58299260711654, -16.559476859053653],
            [-72.49843598203647, -16.571594234064776],
            [-72.453636106994, -16.558967484052936],
            [-72.43433385697567, -16.60572248409641],
            [-72.28870385683699, -16.58620648407783],
            [-72.25422673180408, -16.533414859028557],
            [-72.1993333567516, -16.483812358982185],
            [-72.19950860675176, -16.45872110895883],
            [-72.19757848174991, -16.468902608968296],
            [-72.19593048174835, -16.468282858967704],
            [-72.19606785674847, -16.469589233968932],
            [-72.19728085674961, -16.48024373397885],
            [-72.19510648174753, -16.483339358981727],
            [-72.19699098174935, -16.488145858986208],
            [-72.21192173176362, -16.511611984008116],
            [-72.21938323177078, -16.523223859018948],
            [-72.22044373177177, -16.525035859020633],
            [-72.23262785678344, -16.54099848403554],
            [-72.24292760679329, -16.561462359054634],
            [-72.24761198179775, -16.566545484059386],
            [-72.27408598182303, -16.600795734091367],
            [-72.27529910682418, -16.606553984096735],
            [-72.27967073182835, -16.612136859101938],
            [-72.28099823182964, -16.614677484104302],
            [-72.28289035683142, -16.621410359110588],
            [-72.28289035683142, -16.622711359111793],
            [-72.28208160683066, -16.625751484114613],
            [-72.28339385683192, -16.635309234123532],
            [-72.27635960682517, -16.651731484138793],
            [-72.27298748182197, -16.656822234143522],
            [-72.26951598181864, -16.658834484145377],
            [-72.26483923181416, -16.65913585914564],
            [-72.26026148180978, -16.661804234148107],
            [-72.2558593568056, -16.66617773415217],
            [-72.25506585680482, -16.666639359152597],
            [-72.25192260680183, -16.667560609153444],
            [-72.24857335679863, -16.668250984154074],
            [-72.2444687317947, -16.66846848415426],
            [-72.23534410678597, -16.666368484152265],
            [-72.23448948178513, -16.66623498415214],
            [-72.23072810678156, -16.66607660915198],
            [-72.22803498177898, -16.667089484152918],
            [-72.22619623177721, -16.66874310915445],
            [-72.20500948175692, -16.685096734169587],
            [-72.19849398175066, -16.686553984170924],
            [-72.19427485674663, -16.69026373417436],
            [-72.19442748174677, -16.69254110917648],
            [-72.19640348174865, -16.700639734184016],
            [-72.19419860674654, -16.703950859187085],
            [-72.17881773173178, -16.708038359190848],
            [-72.1726913567259, -16.712324109194803],
            [-72.17127985672455, -16.715524734197775],
            [-72.1671828567206, -16.716999109199133],
            [-72.16420748171774, -16.72426223420587],
            [-72.161361731715, -16.730874984212015],
            [-72.15574648170961, -16.733026484214005],
            [-72.15334323170731, -16.73722835921791],
            [-72.14723210670142, -16.742067359222368],
            [-72.14373773169808, -16.7442607342244],
            [-72.13789373169246, -16.744337109224436],
            [-72.13600160669066, -16.743678859223817],
            [-72.1337814816885, -16.743541734223683],
            [-72.13257598168737, -16.743678859223806],
            [-72.13214110668693, -16.75034898423001],
            [-72.13180548168661, -16.7528152342323],
            [-72.12759398168255, -16.75658610923579],
            [-72.13247685668723, -16.765035609243682],
            [-72.1319046066867, -16.766656859245202],
            [-72.1529464817069, -16.794681609271375],
            [-72.20558160675739, -16.799032234275657],
            [-72.22383885677488, -16.834545109308788],
            [-72.17198948172513, -16.84988598432286],
            [-72.1844863567371, -16.924770359392625],
            [-72.28827673183648, -16.875253734346927],
            [-72.2954177318433, -16.82741548430242],
            [-72.35928348190424, -16.764152484243727],
            [-72.44721985698787, -16.703956609187912],
            [-72.54318998207886, -16.678058609163998],
            [-72.70904535723552, -16.652692734140647],
            [-72.77190398229475, -16.62880323411849],
            [-72.91629023243046, -16.520372234017643],
            [-72.98684698249669, -16.517435109014958],
            [-73.05334473255901, -16.48940273398889],
            [-73.15915685765808, -16.41992573392425],
            [-73.21523285771053, -16.40015798390585],
            [-73.29160310778194, -16.40540698391077],
            [-73.24226373273584, -16.356311733865027],
            [-73.19631198269283, -16.280515608794435],
            [-73.20323185769931, -16.15299410867568],
            [-73.2220382327169, -16.119234108644246],
            [-73.24028773273399, -15.998206108531539],
            [-73.22499085771966, -15.984971983519225],
            [-73.23324585772738, -15.874050108415931],
            [-73.17404173267201, -15.912110358451356],
            [-73.1511688576506, -15.979671483514268],
            [-73.06491848256987, -16.018592858550463],
            [-72.9121474824266, -16.03020098356119],
            [-72.87580110739245, -16.057521983586614],
            [-72.84333798236194, -16.12618448365053],
            [-72.84122473235995, -16.174461358695478],
            [-72.81012723233073, -16.221448858739205],
            [-72.79792785731924, -16.308504108820255],
            [-72.72128298224709, -16.35523973386369],
            [-72.66989898219863, -16.424426983928047],
            [-72.6233521071547, -16.45700648395832],
            [-72.5936279821266, -16.430480983933577],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 8,
        FIRST_IDPR: '0805',
        NOMBPROV: 'CANAS',
        FIRST_NOMB: 'CUSCO',
        LAST_DCTO: 'LEY',
        LAST_LEY: '9363',
        FIRST_FECH: '02/01/1857',
        LAST_FECHA: '01/09/1941',
        MIN_SHAPE_: 208744.198647,
        ha: 208744.2,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.4370898560171, -14.101447231759266],
            [-71.43618935601623, -14.102382856760109],
            [-71.4352597310153, -14.103072231760724],
            [-71.43491010601495, -14.103126981760784],
            [-71.43427148101432, -14.103531231761153],
            [-71.43333985601339, -14.104394481761945],
            [-71.4330369810131, -14.104515606762043],
            [-71.43276560601284, -14.104554856762087],
            [-71.4309824810111, -14.104820356762296],
            [-71.42955660600968, -14.10613473176352],
            [-71.42799810600818, -14.106576231763908],
            [-71.4244726060047, -14.106378856763687],
            [-71.42069335600097, -14.110888856767826],
            [-71.42057423100084, -14.11172660676861],
            [-71.42039060600068, -14.112554731769375],
            [-71.41682623099717, -14.115121106771724],
            [-71.41674023099708, -14.1153203567719],
            [-71.42274998100295, -14.118896481775327],
            [-71.42633785600646, -14.123288981779458],
            [-71.42652735600663, -14.123687481779816],
            [-71.42664648100674, -14.125847606781821],
            [-71.42694923100704, -14.12614860678211],
            [-71.42721485600728, -14.126767606782678],
            [-71.4273476060074, -14.12816410678399],
            [-71.42719135600726, -14.129382731785132],
            [-71.42727535600734, -14.129552731785278],
            [-71.42744923100749, -14.131800731787385],
            [-71.43002935601, -14.137689481792886],
            [-71.43041210601038, -14.13829498179344],
            [-71.43555860601539, -14.142486356797429],
            [-71.43622848101604, -14.14307810679798],
            [-71.43662048101643, -14.144519106799327],
            [-71.4354722310153, -14.145177231799915],
            [-71.43531485601514, -14.145349106800087],
            [-71.43492335601475, -14.147147481801753],
            [-71.43416135601399, -14.150091481804477],
            [-71.43639535601619, -14.150888106805246],
            [-71.43970310601941, -14.151753981806086],
            [-71.44091748102058, -14.15212785680645],
            [-71.43638748101608, -14.16379023181726],
            [-71.43306223101281, -14.170103356823091],
            [-71.43127910601105, -14.173235856825986],
            [-71.42843360600824, -14.177417981829846],
            [-71.42747198100726, -14.180609106832772],
            [-71.42579635600562, -14.183771606835714],
            [-71.42529373100511, -14.18537110683718],
            [-71.42442548100426, -14.186353356838106],
            [-71.4224377310023, -14.187267356838914],
            [-71.4213182310012, -14.187907106839516],
            [-71.4188734809988, -14.189574981841027],
            [-71.41823373099815, -14.190351856841744],
            [-71.41827260599818, -14.191953481843212],
            [-71.419131605999, -14.194347856845459],
            [-71.41902198099892, -14.195243606846308],
            [-71.41827260599815, -14.196998231847923],
            [-71.41689285599678, -14.200085731850768],
            [-71.41737698099728, -14.202022856852574],
            [-71.41692123099678, -14.205127856855476],
            [-71.41600960599588, -14.209885106859883],
            [-71.41498410599486, -14.209286981859309],
            [-71.4144998559944, -14.207292856857453],
            [-71.41267673099262, -14.205498231855756],
            [-71.40991348098991, -14.20376048185411],
            [-71.4095716059896, -14.203390231853758],
            [-71.4086601059887, -14.20148160685196],
            [-71.40834673098838, -14.201139731851638],
            [-71.40535560598545, -14.200655481851175],
            [-71.4049852309851, -14.200797856851276],
            [-71.40051285598068, -14.202165231852502],
            [-71.39951585597971, -14.201908856852246],
            [-71.39760723097785, -14.200569981850975],
            [-71.39678110597706, -14.200854856851228],
            [-71.39692348097716, -14.202022856852322],
            [-71.39732235597754, -14.204985481855084],
            [-71.39735085597756, -14.207606231857529],
            [-71.39632535597654, -14.209714231859463],
            [-71.39450210597472, -14.212192606861757],
            [-71.39059960597089, -14.21566798186493],
            [-71.38516660596551, -14.219072481868038],
            [-71.38423560596459, -14.221507106870297],
            [-71.3825171059629, -14.222939106871603],
            [-71.38022573096065, -14.224192231872735],
            [-71.37471210595523, -14.225373731873741],
            [-71.36684285594743, -14.232661481880422],
            [-71.36721473094778, -14.236596856884075],
            [-71.36744973094798, -14.239709856886988],
            [-71.36707773094757, -14.24501560689192],
            [-71.36705823094755, -14.249224981895852],
            [-71.36801760594848, -14.249459981896061],
            [-71.36887898094932, -14.251339481897846],
            [-71.36815460594862, -14.253943481900228],
            [-71.36762598094806, -14.255157481901369],
            [-71.36750848094796, -14.256880231902953],
            [-71.36755598094794, -14.263546106909173],
            [-71.36915998094953, -14.264307981909907],
            [-71.37058535595091, -14.26786185691322],
            [-71.371752855952, -14.278168856922834],
            [-71.36781673094812, -14.280770856925203],
            [-71.36574860594608, -14.279269856923793],
            [-71.36509573094544, -14.27924810692374],
            [-71.36454785594489, -14.279703481924162],
            [-71.36351373094388, -14.28237198192662],
            [-71.36268898094303, -14.283731481927894],
            [-71.35673435593716, -14.28644998193032],
            [-71.3501972309307, -14.287226606930949],
            [-71.34217998092285, -14.285125106928898],
            [-71.33759710591835, -14.280542231924564],
            [-71.33493598091573, -14.279359481923414],
            [-71.33367935591447, -14.279950731923954],
            [-71.33027923091112, -14.281096606924965],
            [-71.32854210590942, -14.2836467319273],
            [-71.32765510590849, -14.288636231931932],
            [-71.32710798090794, -14.290073481933277],
            [-71.32717285590797, -14.295201356938037],
            [-71.32645885590723, -14.299095981941663],
            [-71.32632910590712, -14.300166981942638],
            [-71.32649135590721, -14.308864856950734],
            [-71.32912023090981, -14.310130606951946],
            [-71.3295421059102, -14.310227981952059],
            [-71.32932435590999, -14.31272235695435],
            [-71.32996673091058, -14.315934731957354],
            [-71.33103748091166, -14.316719856958109],
            [-71.33296485591354, -14.319717981960949],
            [-71.33277423091334, -14.32088685696202],
            [-71.33404898091459, -14.322628856963648],
            [-71.33545123091595, -14.323393731964373],
            [-71.33732073091781, -14.324583481965513],
            [-71.33754598091802, -14.324936106965868],
            [-71.3373419809178, -14.327927481968647],
            [-71.33611823091655, -14.33098673197147],
            [-71.3353364809158, -14.331360606971806],
            [-71.33261710591312, -14.331258731971667],
            [-71.331495230912, -14.33102073197142],
            [-71.32537673090597, -14.330102981970462],
            [-71.31986998090058, -14.328199356968629],
            [-71.31606285589682, -14.324936106965556],
            [-71.31704860589781, -14.323474481964169],
            [-71.31701448089778, -14.32204673196287],
            [-71.31684460589761, -14.321536856962373],
            [-71.31585873089665, -14.320653106961544],
            [-71.31565485589645, -14.320211231961112],
            [-71.31402323089486, -14.3185796069596],
            [-71.30593310588685, -14.318749481959628],
            [-71.30372360588467, -14.320381106961117],
            [-71.30114023088213, -14.319157481959921],
            [-71.29736710587844, -14.314092606955153],
            [-71.29604135587714, -14.313514731954584],
            [-71.29291410587402, -14.313548731954565],
            [-71.2923702308735, -14.314058606955053],
            [-71.28982073087097, -14.316539981957305],
            [-71.28810910586927, -14.317966606958606],
            [-71.2850302308662, -14.320091481960535],
            [-71.28385948086505, -14.320568481960958],
            [-71.27861235585985, -14.31983123196019],
            [-71.2711969808525, -14.325902231965735],
            [-71.26859510584991, -14.32572885696552],
            [-71.2671641058485, -14.325078356964898],
            [-71.26516935584654, -14.325425231965188],
            [-71.2607896058422, -14.325295231964983],
            [-71.25883823084025, -14.325121731964792],
            [-71.25691498083835, -14.32520848196484],
            [-71.25603510583748, -14.325359356964961],
            [-71.2502298558317, -14.330490981969648],
            [-71.2488184808303, -14.331267106970312],
            [-71.24937523083084, -14.336369606975092],
            [-71.24937523083084, -14.337313106975987],
            [-71.24954173083097, -14.339616731978111],
            [-71.25015223083155, -14.34169823198005],
            [-71.24993023083131, -14.344001731982187],
            [-71.25030848083166, -14.347370856985354],
            [-71.25009348083144, -14.348661106986546],
            [-71.24963260583095, -14.350995981988705],
            [-71.25003198083134, -14.352224856989855],
            [-71.25009348083141, -14.352685731990276],
            [-71.24831160582961, -14.357754731994962],
            [-71.24898748083024, -14.361072731998041],
            [-71.24920248083046, -14.362362981999258],
            [-71.2475434808288, -14.365435232002097],
            [-71.24588460582714, -14.367401232003894],
            [-71.24625323082749, -14.36856873200499],
            [-71.24803510582925, -14.368998857005433],
            [-71.25331923083446, -14.369674732006121],
            [-71.25671398083784, -14.369771732006269],
            [-71.25853848083963, -14.373206107009507],
            [-71.25880685583986, -14.376318607012411],
            [-71.25869948083974, -14.377552857013555],
            [-71.25327948083431, -14.38560248202095],
            [-71.25145498083249, -14.385655982020973],
            [-71.24930848083035, -14.387748857022881],
            [-71.2473227308284, -14.389305107024292],
            [-71.23972148082083, -14.393866357028394],
            [-71.23510898081622, -14.39797198203213],
            [-71.23455148081565, -14.398647857032762],
            [-71.23314910581426, -14.399357357033395],
            [-71.23259148081372, -14.399881232033863],
            [-71.23203385581313, -14.401587607035445],
            [-71.23211848081321, -14.403108232036876],
            [-71.23144260581252, -14.404814732038432],
            [-71.2315288558126, -14.406097107039631],
            [-71.23188023081293, -14.407137732040622],
            [-71.23227223081334, -14.408124482041535],
            [-71.23223160581327, -14.408448857041833],
            [-71.2313531058124, -14.40996273204321],
            [-71.23102873081208, -14.410611607043826],
            [-71.2308664808119, -14.41253073204561],
            [-71.2291634808102, -14.413544482046518],
            [-71.22883898080987, -14.413936607046876],
            [-71.2267747308078, -14.417470107050125],
            [-71.2262746058073, -14.417970232050576],
            [-71.2234304808045, -14.416970107049602],
            [-71.22208398080316, -14.415523482048213],
            [-71.2197954808009, -14.416463732049076],
            [-71.21923185580033, -14.418753857051188],
            [-71.21789310579896, -14.42111398205335],
            [-71.21391223079497, -14.424249482056213],
            [-71.2047173557858, -14.429956607061321],
            [-71.20045460578157, -14.42907585706044],
            [-71.19929198078039, -14.432457857063552],
            [-71.19837610577949, -14.433268107064304],
            [-71.19104835577221, -14.429392982060552],
            [-71.19050235577167, -14.42986148206098],
            [-71.18982023077099, -14.431453107062442],
            [-71.1897413557709, -14.43310260706399],
            [-71.18821948076936, -14.434427232065172],
            [-71.18638748076752, -14.435385482066039],
            [-71.18387923076502, -14.437640107068066],
            [-71.1775498557587, -14.440346982070471],
            [-71.17698960575815, -14.440584732070683],
            [-71.17345773075462, -14.442418482072323],
            [-71.17240498075356, -14.443114732072974],
            [-71.1697561057509, -14.445067357074722],
            [-71.16824498074939, -14.445950357075507],
            [-71.16639410574757, -14.445610732075156],
            [-71.16496785574613, -14.445440982074961],
            [-71.1625397307437, -14.44493148207444],
            [-71.16063798074184, -14.443437357073009],
            [-71.15888910574012, -14.441620482071293],
            [-71.15849848073974, -14.441111107070792],
            [-71.15549323073675, -14.438852732068641],
            [-71.15459323073587, -14.438513107068331],
            [-71.15046710573176, -14.439294232068953],
            [-71.14929548073059, -14.440669482070204],
            [-71.14664660572791, -14.441263857070723],
            [-71.14583160572711, -14.441128107070565],
            [-71.14542410572672, -14.441399732070817],
            [-71.14425248072556, -14.441858232071219],
            [-71.1438279807251, -14.441790232071153],
            [-71.14121310572254, -14.44121298207055],
            [-71.1399736057213, -14.441348732070663],
            [-71.13910760572038, -14.44628985707522],
            [-71.13888685572014, -14.448819857077588],
            [-71.13881898072006, -14.45050085707913],
            [-71.13550785571672, -14.452215857080684],
            [-71.13564760571687, -14.453424232081792],
            [-71.13470623071592, -14.455635482083832],
            [-71.13534610571651, -14.45932948208727],
            [-71.13650935571766, -14.460434732088343],
            [-71.13581148071694, -14.46357598209125],
            [-71.1331908557143, -14.46447460709201],
            [-71.13232985571344, -14.464614357092124],
            [-71.12853710570965, -14.464591107092032],
            [-71.1214401057026, -14.461030982088559],
            [-71.1211143557023, -14.461193857088695],
            [-71.12009048070126, -14.462729607090111],
            [-71.11818248069936, -14.463357857090676],
            [-71.11646060569765, -14.462473607089798],
            [-71.11415698069536, -14.460612107088027],
            [-71.11355198069474, -14.459960607087416],
            [-71.11103885569223, -14.460705357088033],
            [-71.10978248069097, -14.461310232088563],
            [-71.10852598068972, -14.461147357088402],
            [-71.10733923068855, -14.45965810708699],
            [-71.10423885568545, -14.459165607086463],
            [-71.1015594806828, -14.458625482085905],
            [-71.09916298068042, -14.458168857085436],
            [-71.0958661056771, -14.458963232086093],
            [-71.09217210567337, -14.463014732089755],
            [-71.09149685567269, -14.463689982090393],
            [-71.08986835567103, -14.466708732093167],
            [-71.08093135566205, -14.47111760709704],
            [-71.08132848066249, -14.467542857093745],
            [-71.0815271056627, -14.46631148209259],
            [-71.08078348066206, -14.458303482085153],
            [-71.0802332306615, -14.457818107084659],
            [-71.07646435565776, -14.45421348208123],
            [-71.07555285565687, -14.452887857079961],
            [-71.07227860565362, -14.4499854820772],
            [-71.07045985565182, -14.449659482076843],
            [-71.06297685564434, -14.450440857077403],
            [-71.06019460564154, -14.451125732077987],
            [-71.05733248063866, -14.451654982078407],
            [-71.05098273063231, -14.451678982078283],
            [-71.04980423063114, -14.451606857078188],
            [-71.04831298062963, -14.451967607078492],
            [-71.04650910562783, -14.45285748207925],
            [-71.04463310562593, -14.452496732078869],
            [-71.04275198062408, -14.452400357078742],
            [-71.04090498062223, -14.45203985707835],
            [-71.03999110562131, -14.452039857078326],
            [-71.03787435561921, -14.452184107078441],
            [-71.03647948061777, -14.451654982077905],
            [-71.03580598061717, -14.449490232075881],
            [-71.03174123061314, -14.444174857070823],
            [-71.03065898061207, -14.443188732069904],
            [-71.03046648061186, -14.442635482069358],
            [-71.03080323061224, -14.44063923206754],
            [-71.03109185561256, -14.43950873206648],
            [-71.03015385561163, -14.438787232065774],
            [-71.02789310560938, -14.436021232063155],
            [-71.027508105609, -14.434770482061984],
            [-71.02714735560865, -14.434169232061427],
            [-71.02669198060823, -14.430132107057648],
            [-71.02355973060511, -14.428997357056513],
            [-71.01307360559464, -14.427453732054811],
            [-71.01084923059241, -14.42790773205517],
            [-71.00892398059048, -14.42796098205519],
            [-71.0081413555897, -14.428220982055388],
            [-71.00045110558199, -14.42894260705588],
            [-70.99836048057988, -14.429141857056017],
            [-70.99549848057703, -14.428544482055397],
            [-70.99450298057602, -14.428867982055646],
            [-70.98254073056411, -14.42104773204806],
            [-70.98105598056266, -14.420019857047079],
            [-70.97816910555979, -14.41714685704432],
            [-70.97704860555866, -14.417464982044603],
            [-70.9769862305586, -14.41748223204463],
            [-70.97596923055757, -14.41778735704485],
            [-70.97357185555516, -14.418324982045297],
            [-70.97186273055341, -14.421342482048056],
            [-70.9727837305543, -14.424084107050628],
            [-70.97325498055473, -14.426525857052921],
            [-70.97387610555533, -14.428132232054432],
            [-70.97376898055523, -14.429288857055496],
            [-70.97312648055458, -14.429545857055714],
            [-70.97158423055302, -14.430188482056273],
            [-70.97083448055227, -14.43063823205666],
            [-70.97004210555144, -14.431580607057539],
            [-70.96841435554978, -14.433444107059215],
            [-70.96595135554728, -14.438948607064242],
            [-70.96490160554617, -14.44154035706665],
            [-70.96368085554494, -14.442504107067515],
            [-70.96235298054359, -14.444538982069366],
            [-70.9642804805455, -14.44516010706999],
            [-70.9690783555503, -14.447237732072045],
            [-70.97376898055501, -14.447109232072048],
            [-70.98137260556261, -14.449015482074032],
            [-70.98336460556459, -14.45057898207553],
            [-70.98754698056872, -14.454905107079691],
            [-70.98781848056896, -14.456967982081588],
            [-70.98806685556919, -14.460199732084604],
            [-70.98834023056946, -14.460860607085236],
            [-70.98818085556928, -14.461886232086178],
            [-70.98635748056745, -14.462980357087158],
            [-70.98571948056677, -14.464188232088254],
            [-70.98501273056605, -14.46539623208936],
            [-70.98471660556575, -14.466626982090515],
            [-70.98467085556567, -14.468062857091844],
            [-70.98469373056568, -14.468792232092506],
            [-70.98382760556478, -14.47066110709423],
            [-70.98346285556443, -14.471549982095034],
            [-70.98259685556353, -14.47433048209759],
            [-70.98339448056427, -14.476700982099837],
            [-70.98485323056573, -14.479116982102102],
            [-70.98922923057, -14.48882623211125],
            [-70.98916085556992, -14.489441607111804],
            [-70.98865948056942, -14.490490107112777],
            [-70.98747423056817, -14.493065607115142],
            [-70.98736035556804, -14.494296357116287],
            [-70.98658535556726, -14.49573223211761],
            [-70.98658535556723, -14.498717982120386],
            [-70.98731473056795, -14.499219357120845],
            [-70.99340010557404, -14.500222232121967],
            [-70.9946309805753, -14.50072360712245],
            [-70.99809523057874, -14.501680857123448],
            [-70.9991664805798, -14.502273482124004],
            [-70.99932610557997, -14.502843232124551],
            [-70.99802685557864, -14.503914607125493],
            [-70.99598698057657, -14.507389357128687],
            [-70.99492323057545, -14.51054373213159],
            [-70.99220910557267, -14.516192232136774],
            [-70.98861460556896, -14.524371482144259],
            [-70.98840210556872, -14.525671607145473],
            [-70.98584610556611, -14.529093482148605],
            [-70.98447598056471, -14.530779857150117],
            [-70.98360635556384, -14.531570232150829],
            [-70.98298723056321, -14.532532107151699],
            [-70.98108998056124, -14.536642482155496],
            [-70.97906110555914, -14.540568607159061],
            [-70.97927198055933, -14.542123232160527],
            [-70.98236798056243, -14.543071732161488],
            [-70.98264460556271, -14.544389232162738],
            [-70.98184923056185, -14.54775485716581],
            [-70.97817698055813, -14.550491857168273],
            [-70.97061723055042, -14.560161607177056],
            [-70.96902410554877, -14.562292982178999],
            [-70.96799060554771, -14.56483348218133],
            [-70.96722523054692, -14.56629035718265],
            [-70.9664344805461, -14.56750223218376],
            [-70.96689860554656, -14.568911857185084],
            [-70.97323285555291, -14.570518982186748],
            [-70.97350785555318, -14.571369857187555],
            [-70.97343060555308, -14.57222935718834],
            [-70.97770323055724, -14.583021107198501],
            [-70.98470785556432, -14.580227732196096],
            [-70.9898217305695, -14.579078232195165],
            [-70.99273923057248, -14.575768357192164],
            [-71.00261973058244, -14.572344857189218],
            [-71.0164004805962, -14.579235232196],
            [-71.01969398059946, -14.583395357199946],
            [-71.01965060559941, -14.585952232202336],
            [-71.01510523059478, -14.58828510720438],
            [-71.01231248059196, -14.591579982207369],
            [-71.0100531055896, -14.597541982212848],
            [-71.0248956056046, -14.591925107207999],
            [-71.03877035561845, -14.598193857214188],
            [-71.05016985562979, -14.610144982225563],
            [-71.05312360563268, -14.61515810723032],
            [-71.05387960563344, -14.615709857230847],
            [-71.05492173063448, -14.616854107231937],
            [-71.06054073064007, -14.620266357235241],
            [-71.06564748064515, -14.624096857238905],
            [-71.06936760564886, -14.626045732240817],
            [-71.07548273065494, -14.631944732246438],
            [-71.08068698066019, -14.62989623224465],
            [-71.09399010567348, -14.63252460724741],
            [-71.0957971056753, -14.633037107247938],
            [-71.09900698067852, -14.632686482247665],
            [-71.10911848068862, -14.63395085724909],
            [-71.10992760568945, -14.63469248224978],
            [-71.10992760568944, -14.635670357250705],
            [-71.11245635569189, -14.643492607258027],
            [-71.11297848069242, -14.643925732258428],
            [-71.11794060569734, -14.648917732263184],
            [-71.12270423070213, -14.646510232261042],
            [-71.12811860570756, -14.643431357258317],
            [-71.14712210572661, -14.640840982256302],
            [-71.17325598075288, -14.621194482238568],
            [-71.17577523075543, -14.618011232235666],
            [-71.1861466057657, -14.625623107242943],
            [-71.18688748076644, -14.62584085724318],
            [-71.18793335576748, -14.627976482245174],
            [-71.19508060577455, -14.634949357251788],
            [-71.20802398078737, -14.642227232258813],
            [-71.21543285579475, -14.64824135726452],
            [-71.22362585580291, -14.647849107264332],
            [-71.24892885582814, -14.6411883572586],
            [-71.24918410582838, -14.64425260726143],
            [-71.25191998083106, -14.649010607265915],
            [-71.25296798083208, -14.649709232266579],
            [-71.26130835584037, -14.65224210726909],
            [-71.26715960584622, -14.64857398226577],
            [-71.27143898085043, -14.650495232267641],
            [-71.2809147308599, -14.64630323226389],
            [-71.2856306058646, -14.644032607261874],
            [-71.28906948086801, -14.642976982260942],
            [-71.29685673087575, -14.64159798225978],
            [-71.2992497308781, -14.643706982261799],
            [-71.30865935588736, -14.659524857276663],
            [-71.31032223088901, -14.658997607276177],
            [-71.31376973089242, -14.658794857276064],
            [-71.33125048090994, -14.626672357246452],
            [-71.33392473091266, -14.618093982238506],
            [-71.3557883559343, -14.618014732238759],
            [-71.357531105936, -14.618410857239157],
            [-71.36156310594001, -14.617746107238586],
            [-71.36768935594606, -14.619382857240199],
            [-71.38239460596063, -14.610892607232518],
            [-71.39169535596983, -14.606556607228624],
            [-71.39382223097192, -14.606449232228545],
            [-71.39432810597242, -14.606417982228518],
            [-71.39630273097438, -14.607226607229297],
            [-71.40299023098098, -14.607144482229332],
            [-71.40410748098208, -14.606373107228604],
            [-71.40670123098464, -14.605578107227918],
            [-71.40743373098537, -14.604087857226528],
            [-71.40827935598622, -14.602689482225246],
            [-71.40953910598746, -14.60283598222541],
            [-71.4104707309884, -14.603109357225653],
            [-71.41072085598864, -14.603461357225996],
            [-71.41166935598955, -14.603736357226277],
            [-71.4183241059961, -14.606465232228889],
            [-71.41868098099646, -14.607417107229772],
            [-71.42986448100741, -14.62127735724283],
            [-71.43041960600797, -14.618834357240571],
            [-71.43515260601268, -14.613466732235613],
            [-71.43765748101515, -14.612550232234806],
            [-71.44202573101944, -14.613008482235301],
            [-71.44522835602261, -14.61244560723481],
            [-71.4471643560245, -14.61184585723427],
            [-71.44795473102528, -14.611556107234021],
            [-71.44887523102621, -14.610912857233409],
            [-71.45547460603274, -14.60053510722383],
            [-71.46116535603834, -14.597726232221293],
            [-71.46842998104552, -14.595617982219427],
            [-71.47370098105071, -14.59426585721823],
            [-71.48048448105736, -14.590576232214891],
            [-71.48245535605932, -14.589109607213542],
            [-71.48770335606451, -14.581546857206558],
            [-71.488207606065, -14.579278107204479],
            [-71.48845960606526, -14.57898010720418],
            [-71.490224231067, -14.57854473220381],
            [-71.49648060607313, -14.580194732205394],
            [-71.49758060607422, -14.580630232205818],
            [-71.50592248108242, -14.579644732205013],
            [-71.50887885608533, -14.576780107202378],
            [-71.51914573109542, -14.574373857200246],
            [-71.52074985609698, -14.572540482198573],
            [-71.52434785610053, -14.571761232197874],
            [-71.5252646061014, -14.571921732198016],
            [-71.52845010610456, -14.571669607197826],
            [-71.52945848110554, -14.571188357197403],
            [-71.53122310610728, -14.570271607196554],
            [-71.53305648110907, -14.565940357192561],
            [-71.53561435611161, -14.560632982187654],
            [-71.53972335611563, -14.555912232183298],
            [-71.54033523111625, -14.553027232180611],
            [-71.53876173111473, -14.547607107175546],
            [-71.53832448111433, -14.543498232171723],
            [-71.54356985611949, -14.537990732166648],
            [-71.54899010612478, -14.537815857166558],
            [-71.55920648113478, -14.539697607168423],
            [-71.5743424811496, -14.545246732173723],
            [-71.57679023115196, -14.546033482174462],
            [-71.5918632311667, -14.545964482174554],
            [-71.5924656061673, -14.545776107174397],
            [-71.59468698116945, -14.546529107175107],
            [-71.59875310617343, -14.54562548217433],
            [-71.60187798117649, -14.546114982174792],
            [-71.60846673118293, -14.54411948217301],
            [-71.61316823118753, -14.537582607166955],
            [-71.61401548118839, -14.534523482164127],
            [-71.61650960619083, -14.533488107163173],
            [-71.62215710619631, -14.533535232163286],
            [-71.6260162312001, -14.532076357161968],
            [-71.63284023120676, -14.529393732159525],
            [-71.64775885622129, -14.532170482162238],
            [-71.64874723122226, -14.530664482160846],
            [-71.65048848122397, -14.529017232159317],
            [-71.65119435622465, -14.52859373215894],
            [-71.65138260622486, -14.52468760715529],
            [-71.64879423122237, -14.517863482148936],
            [-71.64959435622315, -14.515275107146536],
            [-71.64898248122255, -14.513063232144463],
            [-71.64618660621983, -14.507986232139725],
            [-71.6444942312182, -14.500833357133061],
            [-71.63790410621179, -14.497498607129886],
            [-71.62813810620229, -14.499483607131648],
            [-71.62319335619745, -14.497237857129493],
            [-71.61620648119064, -14.49003498212275],
            [-71.60547410618024, -14.47858223211197],
            [-71.60496998117975, -14.477573857111029],
            [-71.60569010618046, -14.474116482107817],
            [-71.60626648118102, -14.472531857106354],
            [-71.60691473118165, -14.470442982104426],
            [-71.609291731184, -14.459062357093844],
            [-71.6094738561842, -14.457946982092816],
            [-71.60787910618264, -14.45569235709071],
            [-71.609253856184, -14.45112810708646],
            [-71.60883585618359, -14.448282107083804],
            [-71.60930035618406, -14.446344107082021],
            [-71.60781685618265, -14.44357773207943],
            [-71.60015910617516, -14.441813607077728],
            [-71.59807423117311, -14.440851357076808],
            [-71.59418523116932, -14.438966982075012],
            [-71.58720898116253, -14.437082607073197],
            [-71.58636698116173, -14.435478857071706],
            [-71.5859259811613, -14.431790232068256],
            [-71.58227748115773, -14.429905857066467],
            [-71.58155585615704, -14.429384732065975],
            [-71.5761432311518, -14.420644357057794],
            [-71.57642385615206, -14.419762357056962],
            [-71.57619535615183, -14.41849998205579],
            [-71.5673227311432, -14.414389857051871],
            [-71.56555860614147, -14.414309607051775],
            [-71.56291248113888, -14.414510107051967],
            [-71.5621106061381, -14.414149232051594],
            [-71.55441273113058, -14.410741357048344],
            [-71.55316985612936, -14.409378232047077],
            [-71.55185748112808, -14.407884732045671],
            [-71.55117935612742, -14.407332482045135],
            [-71.54974610612604, -14.405521482043449],
            [-71.55117935612743, -14.40280523204094],
            [-71.55179960612807, -14.40187485704008],
            [-71.55504785613125, -14.395138107033835],
            [-71.5534198561297, -14.38986648202893],
            [-71.55318723112947, -14.387463232026676],
            [-71.56452060614059, -14.379515482019391],
            [-71.56785148114383, -14.377324232017383],
            [-71.56820210614418, -14.373818107014149],
            [-71.5675884811436, -14.372152607012577],
            [-71.55987498113609, -14.365753982006526],
            [-71.55636885613265, -14.364439107005293],
            [-71.55522935613156, -14.363474982004364],
            [-71.55484648113124, -14.353153481994754],
            [-71.55432160613073, -14.349741356991593],
            [-71.55506523113144, -14.3489976069909],
            [-71.56013998113644, -14.345760231987942],
            [-71.56022748113651, -14.34414160698644],
            [-71.55935248113568, -14.33941698198203],
            [-71.55852135613488, -14.335173481978083],
            [-71.55808385613446, -14.334473481977431],
            [-71.55681523113323, -14.3307112319739],
            [-71.5541028561306, -14.327911356971265],
            [-71.55611523113258, -14.322311731966082],
            [-71.55812760613455, -14.320211856964145],
            [-71.5580401061345, -14.315837106960066],
            [-71.55751510613399, -14.31273110695717],
            [-71.55677148113325, -14.31185610695637],
            [-71.55436535613093, -14.309799981954415],
            [-71.55007810612678, -14.300919231946123],
            [-71.5538841061305, -14.294663356940317],
            [-71.55449510613113, -14.292106731937958],
            [-71.55412573113074, -14.291262231937157],
            [-71.55217285612888, -14.286670481932877],
            [-71.5514867311282, -14.28302873192947],
            [-71.55016723112693, -14.279861981926523],
            [-71.54900610612584, -14.266772981914311],
            [-71.54911723112598, -14.26238473191025],
            [-71.54378110612076, -14.263500606911228],
            [-71.53640648111354, -14.265228606912755],
            [-71.53121898110848, -14.264814606912326],
            [-71.53048523110778, -14.264598481912108],
            [-71.52906135610638, -14.264863606912352],
            [-71.52685348110421, -14.265980481913369],
            [-71.5234472311009, -14.264253856911727],
            [-71.51973823109729, -14.258999981906781],
            [-71.51515235609283, -14.252962856901137],
            [-71.51512410609278, -14.252268231900484],
            [-71.51954435609717, -14.241643606890634],
            [-71.52031560609792, -14.238053606887322],
            [-71.5206742310983, -14.236476981885827],
            [-71.52173298109932, -14.233885481883432],
            [-71.52138098109897, -14.23287323188249],
            [-71.5211884810988, -14.232277856881934],
            [-71.52087973109849, -14.230483481880277],
            [-71.52147710609911, -14.228374106878318],
            [-71.52179948109942, -14.226806606876846],
            [-71.52177398109939, -14.225728856875852],
            [-71.52180460609942, -14.224534231874735],
            [-71.5224653561001, -14.221886481872277],
            [-71.52337723110098, -14.221169981871626],
            [-71.52377835610136, -14.221024106871495],
            [-71.52392723110152, -14.22093435687142],
            [-71.52500798110256, -14.220746231871269],
            [-71.52529660610286, -14.22062810687115],
            [-71.52682323110434, -14.219807981870378],
            [-71.52762523110516, -14.218986606869642],
            [-71.5280721061056, -14.218433856869131],
            [-71.52905085610656, -14.216417606867276],
            [-71.52932885610683, -14.214938981865883],
            [-71.52938660610688, -14.214623231865593],
            [-71.52987598110735, -14.213528981864577],
            [-71.53059860610809, -14.212592731863714],
            [-71.5334686061109, -14.20995560686129],
            [-71.53401423111144, -14.209331231860709],
            [-71.53455198111196, -14.208388856859841],
            [-71.53613185611353, -14.20461685685636],
            [-71.53684673111421, -14.203327856855168],
            [-71.53846610611579, -14.200659731852689],
            [-71.53867860611601, -14.200206856852258],
            [-71.5397763561171, -14.199125981851305],
            [-71.5401808561175, -14.198838856851024],
            [-71.54169048111898, -14.198133481850373],
            [-71.54183485611911, -14.19807010685033],
            [-71.54296210612021, -14.19720985684954],
            [-71.54311498112035, -14.197110981849463],
            [-71.5443632311216, -14.194535106847063],
            [-71.54426023112151, -14.192963356845603],
            [-71.54358848112085, -14.18957735684244],
            [-71.54367110612094, -14.188454356841376],
            [-71.543710481121, -14.187932731840897],
            [-71.54408198112135, -14.186708981839788],
            [-71.54653260612375, -14.183872981837167],
            [-71.54735185612456, -14.182194231835618],
            [-71.54753510612474, -14.181564856835028],
            [-71.54766335612487, -14.180891856834402],
            [-71.54853010612572, -14.179000731832632],
            [-71.54888285612607, -14.178224606831906],
            [-71.54893148112613, -14.17802435683174],
            [-71.5490248561262, -14.177457731831225],
            [-71.54929060612648, -14.176518231830322],
            [-71.54966210612683, -14.175710231829584],
            [-71.54987835612705, -14.175195356829109],
            [-71.5511618561283, -14.173599356827637],
            [-71.55140323112855, -14.1734384818275],
            [-71.5533072311304, -14.173179481827262],
            [-71.55363023113073, -14.173020981827117],
            [-71.55414173113121, -14.172870231827002],
            [-71.55564473113269, -14.171590606825827],
            [-71.55594810613297, -14.171171981825422],
            [-71.55609110613314, -14.171002606825265],
            [-71.55730310613431, -14.16996898182432],
            [-71.55841473113541, -14.168176731822658],
            [-71.5585986061356, -14.166407481821027],
            [-71.55905773113605, -14.165324481820013],
            [-71.56026323113724, -14.163782856818596],
            [-71.56276723113969, -14.161706106816691],
            [-71.5646154811415, -14.160537606815632],
            [-71.56708998114391, -14.159070856814271],
            [-71.56758585614438, -14.158862231814071],
            [-71.56886060614562, -14.158247606813521],
            [-71.56903210614578, -14.158192856813494],
            [-71.57003210614678, -14.158111481813414],
            [-71.57051385614726, -14.158256481813549],
            [-71.57304860614971, -14.15891860681419],
            [-71.57359260615026, -14.158965856814257],
            [-71.57408998115073, -14.158898606814166],
            [-71.57485573115147, -14.158934481814223],
            [-71.57505473115167, -14.158897106814186],
            [-71.57544898115205, -14.158735481814043],
            [-71.57595198115254, -14.158481856813824],
            [-71.57774810615429, -14.157560481812967],
            [-71.57886998115538, -14.157044856812508],
            [-71.57964423115614, -14.156629981812118],
            [-71.5807302311572, -14.156149981811694],
            [-71.58084748115733, -14.156104606811665],
            [-71.58106760615755, -14.155990981811538],
            [-71.58116235615762, -14.155915606811483],
            [-71.5818411061583, -14.154653481810312],
            [-71.5822499811587, -14.15396885680967],
            [-71.58380085616021, -14.151742981807631],
            [-71.58569185616206, -14.150352981806343],
            [-71.58739760616375, -14.14940685680547],
            [-71.58751910616384, -14.149326356805386],
            [-71.58823210616454, -14.149115106805215],
            [-71.59238810616858, -14.149379606805505],
            [-71.59477348117092, -14.148880856805043],
            [-71.59538910617151, -14.146529356802859],
            [-71.59581835617197, -14.14274610679934],
            [-71.59483985617103, -14.139105481795962],
            [-71.59456448117079, -14.13289848179017],
            [-71.59332010616959, -14.129778981787258],
            [-71.59258398116887, -14.127689481785314],
            [-71.59156448116788, -14.12584573178359],
            [-71.5902414811666, -14.122231231780214],
            [-71.58918210616561, -14.116404856774775],
            [-71.58391198116047, -14.10900648176784],
            [-71.58378598116037, -14.105603856764679],
            [-71.58447910616106, -14.10339848176263],
            [-71.58428998116086, -14.101823106761154],
            [-71.5811394811578, -14.100499856759903],
            [-71.57622448115302, -14.098735481758215],
            [-71.57357798115048, -14.096593106756195],
            [-71.5684596061455, -14.092655606752484],
            [-71.56171973113894, -14.088875856748917],
            [-71.55926060613653, -14.086097981746283],
            [-71.5581677311355, -14.083866606744198],
            [-71.55739360613472, -14.083456731743814],
            [-71.55279410613025, -14.085278231745468],
            [-71.54961710612712, -14.088132106748086],
            [-71.54847898112602, -14.088701106748605],
            [-71.544381981122, -14.091887856751539],
            [-71.539263231117, -14.096455231755733],
            [-71.53862885611638, -14.097046356756273],
            [-71.53621160611401, -14.098851231757918],
            [-71.53566373111347, -14.09965698175868],
            [-71.534181106112, -14.102750981761535],
            [-71.53176398110965, -14.10513598176372],
            [-71.52596260610396, -14.109905981768105],
            [-71.52083823109895, -14.114160231772034],
            [-71.5144607310927, -14.11940960677686],
            [-71.51123785608955, -14.12098073177826],
            [-71.51023073108857, -14.121262731778511],
            [-71.50571285608414, -14.122087731779251],
            [-71.50288560608135, -14.125624106782487],
            [-71.47669210605572, -14.133874481789885],
            [-71.47030460604947, -14.130423606786609],
            [-71.46722085604647, -14.128808356785063],
            [-71.45915710603865, -14.115601106772667],
            [-71.46056435604004, -14.111832106769182],
            [-71.46141848104088, -14.110173856767641],
            [-71.46091598104039, -14.108741606766317],
            [-71.45827773103781, -14.107183856764836],
            [-71.45563948103523, -14.10630448176398],
            [-71.4485539810283, -14.108264231765743],
            [-71.4469459810267, -14.10816373176563],
            [-71.4407147310206, -14.104997856762607],
            [-71.4370898560171, -14.101447231759266],
          ],
          [
            [-71.22440428093078, -14.502324457808708],
            [-71.15651448073724, -14.500000107125503],
            [-71.24816660582886, -14.438878982070403],
            [-71.26771098084805, -14.465595107095604],
            [-71.22440428093078, -14.502324457808708],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 8,
        FIRST_IDPR: '0806',
        NOMBPROV: 'CANCHIS',
        FIRST_NOMB: 'CUSCO',
        LAST_DCTO: 'LEY',
        LAST_LEY: '629',
        FIRST_FECH: '29/08/1834',
        LAST_FECHA: '11/11/1907',
        MIN_SHAPE_: 396207.017226,
        ha: 396207.02,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.86250235545317, -13.800291856467704],
            [-70.88122535547122, -13.832837731498511],
            [-70.83127298042132, -13.85893173152123],
            [-70.83303910542179, -13.938099731594859],
            [-70.86181423044897, -14.02622760667767],
            [-70.84818723043493, -14.055551481704487],
            [-70.78981835537634, -14.078816356724149],
            [-70.80892960539464, -14.124199356766972],
            [-70.86683010545178, -14.169080106810553],
            [-70.89934885548338, -14.229301981867522],
            [-70.88058085546399, -14.270881606905599],
            [-70.9129677304956, -14.327579981959264],
            [-70.96257723054488, -14.361845231992497],
            [-70.97872460556036, -14.416664107043902],
            [-70.98105598056266, -14.420019857047079],
            [-70.98254073056411, -14.42104773204806],
            [-70.99450298057602, -14.428867982055646],
            [-70.99549848057703, -14.428544482055397],
            [-70.99836048057988, -14.429141857056017],
            [-71.00045110558199, -14.42894260705588],
            [-71.0081413555897, -14.428220982055388],
            [-71.00892398059048, -14.42796098205519],
            [-71.01084923059241, -14.42790773205517],
            [-71.01307360559464, -14.427453732054811],
            [-71.02355973060511, -14.428997357056513],
            [-71.02669198060823, -14.430132107057648],
            [-71.02714735560865, -14.434169232061427],
            [-71.027508105609, -14.434770482061984],
            [-71.02789310560938, -14.436021232063155],
            [-71.03015385561163, -14.438787232065774],
            [-71.03109185561256, -14.43950873206648],
            [-71.03080323061224, -14.44063923206754],
            [-71.03046648061186, -14.442635482069358],
            [-71.03065898061207, -14.443188732069904],
            [-71.03174123061314, -14.444174857070823],
            [-71.03580598061717, -14.449490232075881],
            [-71.03647948061777, -14.451654982077905],
            [-71.03787435561921, -14.452184107078441],
            [-71.03999110562131, -14.452039857078326],
            [-71.04090498062223, -14.45203985707835],
            [-71.04275198062408, -14.452400357078742],
            [-71.04463310562593, -14.452496732078869],
            [-71.04650910562783, -14.45285748207925],
            [-71.04831298062963, -14.451967607078492],
            [-71.04980423063114, -14.451606857078188],
            [-71.05098273063231, -14.451678982078283],
            [-71.05733248063866, -14.451654982078407],
            [-71.06019460564154, -14.451125732077987],
            [-71.06297685564434, -14.450440857077403],
            [-71.07045985565182, -14.449659482076843],
            [-71.07227860565362, -14.4499854820772],
            [-71.07555285565687, -14.452887857079961],
            [-71.07646435565776, -14.45421348208123],
            [-71.0802332306615, -14.457818107084659],
            [-71.08078348066206, -14.458303482085153],
            [-71.0815271056627, -14.46631148209259],
            [-71.08132848066249, -14.467542857093745],
            [-71.08093135566205, -14.47111760709704],
            [-71.08986835567103, -14.466708732093167],
            [-71.09149685567269, -14.463689982090393],
            [-71.09217210567337, -14.463014732089755],
            [-71.0958661056771, -14.458963232086093],
            [-71.09916298068042, -14.458168857085436],
            [-71.1015594806828, -14.458625482085905],
            [-71.10423885568545, -14.459165607086463],
            [-71.10733923068855, -14.45965810708699],
            [-71.10852598068972, -14.461147357088402],
            [-71.10978248069097, -14.461310232088563],
            [-71.11103885569223, -14.460705357088033],
            [-71.11355198069474, -14.459960607087416],
            [-71.11415698069536, -14.460612107088027],
            [-71.11646060569765, -14.462473607089798],
            [-71.11818248069936, -14.463357857090676],
            [-71.12009048070126, -14.462729607090111],
            [-71.1211143557023, -14.461193857088695],
            [-71.1214401057026, -14.461030982088559],
            [-71.12853710570965, -14.464591107092032],
            [-71.13232985571344, -14.464614357092124],
            [-71.1331908557143, -14.46447460709201],
            [-71.13581148071694, -14.46357598209125],
            [-71.13650935571766, -14.460434732088343],
            [-71.13534610571651, -14.45932948208727],
            [-71.13470623071592, -14.455635482083832],
            [-71.13564760571687, -14.453424232081792],
            [-71.13550785571672, -14.452215857080684],
            [-71.13881898072006, -14.45050085707913],
            [-71.13888685572014, -14.448819857077588],
            [-71.13910760572038, -14.44628985707522],
            [-71.1399736057213, -14.441348732070663],
            [-71.14121310572254, -14.44121298207055],
            [-71.1438279807251, -14.441790232071153],
            [-71.14425248072556, -14.441858232071219],
            [-71.14542410572672, -14.441399732070817],
            [-71.14583160572711, -14.441128107070565],
            [-71.14664660572791, -14.441263857070723],
            [-71.14929548073059, -14.440669482070204],
            [-71.15046710573176, -14.439294232068953],
            [-71.15459323073587, -14.438513107068331],
            [-71.15549323073675, -14.438852732068641],
            [-71.15849848073974, -14.441111107070792],
            [-71.15888910574012, -14.441620482071293],
            [-71.16063798074184, -14.443437357073009],
            [-71.1625397307437, -14.44493148207444],
            [-71.16496785574613, -14.445440982074961],
            [-71.16639410574757, -14.445610732075156],
            [-71.16824498074939, -14.445950357075507],
            [-71.1697561057509, -14.445067357074722],
            [-71.17240498075356, -14.443114732072974],
            [-71.17345773075462, -14.442418482072323],
            [-71.17698960575815, -14.440584732070683],
            [-71.1775498557587, -14.440346982070471],
            [-71.18387923076502, -14.437640107068066],
            [-71.18638748076752, -14.435385482066039],
            [-71.18821948076936, -14.434427232065172],
            [-71.1897413557709, -14.43310260706399],
            [-71.18982023077099, -14.431453107062442],
            [-71.19050235577167, -14.42986148206098],
            [-71.19104835577221, -14.429392982060552],
            [-71.19837610577949, -14.433268107064304],
            [-71.19929198078039, -14.432457857063552],
            [-71.20045460578157, -14.42907585706044],
            [-71.2047173557858, -14.429956607061321],
            [-71.21391223079497, -14.424249482056213],
            [-71.21789310579896, -14.42111398205335],
            [-71.21923185580033, -14.418753857051188],
            [-71.2197954808009, -14.416463732049076],
            [-71.22208398080316, -14.415523482048213],
            [-71.2234304808045, -14.416970107049602],
            [-71.2262746058073, -14.417970232050576],
            [-71.2267747308078, -14.417470107050125],
            [-71.22883898080987, -14.413936607046876],
            [-71.2291634808102, -14.413544482046518],
            [-71.2308664808119, -14.41253073204561],
            [-71.23102873081208, -14.410611607043826],
            [-71.2313531058124, -14.40996273204321],
            [-71.23223160581327, -14.408448857041833],
            [-71.23227223081334, -14.408124482041535],
            [-71.23188023081293, -14.407137732040622],
            [-71.2315288558126, -14.406097107039631],
            [-71.23144260581252, -14.404814732038432],
            [-71.23211848081321, -14.403108232036876],
            [-71.23203385581313, -14.401587607035445],
            [-71.23259148081372, -14.399881232033863],
            [-71.23314910581426, -14.399357357033395],
            [-71.23455148081565, -14.398647857032762],
            [-71.23510898081622, -14.39797198203213],
            [-71.23972148082083, -14.393866357028394],
            [-71.2473227308284, -14.389305107024292],
            [-71.24930848083035, -14.387748857022881],
            [-71.25145498083249, -14.385655982020973],
            [-71.25327948083431, -14.38560248202095],
            [-71.25869948083974, -14.377552857013555],
            [-71.25880685583986, -14.376318607012411],
            [-71.25853848083963, -14.373206107009507],
            [-71.25671398083784, -14.369771732006269],
            [-71.25331923083446, -14.369674732006121],
            [-71.24803510582925, -14.368998857005433],
            [-71.24625323082749, -14.36856873200499],
            [-71.24588460582714, -14.367401232003894],
            [-71.2475434808288, -14.365435232002097],
            [-71.24920248083046, -14.362362981999258],
            [-71.24898748083024, -14.361072731998041],
            [-71.24831160582961, -14.357754731994962],
            [-71.25009348083141, -14.352685731990276],
            [-71.25003198083134, -14.352224856989855],
            [-71.24963260583095, -14.350995981988705],
            [-71.25009348083144, -14.348661106986546],
            [-71.25030848083166, -14.347370856985354],
            [-71.24993023083131, -14.344001731982187],
            [-71.25015223083155, -14.34169823198005],
            [-71.24954173083097, -14.339616731978111],
            [-71.24937523083084, -14.337313106975987],
            [-71.24937523083084, -14.336369606975092],
            [-71.2488184808303, -14.331267106970312],
            [-71.2502298558317, -14.330490981969648],
            [-71.25603510583748, -14.325359356964961],
            [-71.25691498083835, -14.32520848196484],
            [-71.25883823084025, -14.325121731964792],
            [-71.2607896058422, -14.325295231964983],
            [-71.26516935584654, -14.325425231965188],
            [-71.2671641058485, -14.325078356964898],
            [-71.26859510584991, -14.32572885696552],
            [-71.2711969808525, -14.325902231965735],
            [-71.27861235585985, -14.31983123196019],
            [-71.28385948086505, -14.320568481960958],
            [-71.2850302308662, -14.320091481960535],
            [-71.28810910586927, -14.317966606958606],
            [-71.28982073087097, -14.316539981957305],
            [-71.2923702308735, -14.314058606955053],
            [-71.29291410587402, -14.313548731954565],
            [-71.29604135587714, -14.313514731954584],
            [-71.29736710587844, -14.314092606955153],
            [-71.30114023088213, -14.319157481959921],
            [-71.30372360588467, -14.320381106961117],
            [-71.30593310588685, -14.318749481959628],
            [-71.31402323089486, -14.3185796069596],
            [-71.31565485589645, -14.320211231961112],
            [-71.31585873089665, -14.320653106961544],
            [-71.31684460589761, -14.321536856962373],
            [-71.31701448089778, -14.32204673196287],
            [-71.31704860589781, -14.323474481964169],
            [-71.31606285589682, -14.324936106965556],
            [-71.31986998090058, -14.328199356968629],
            [-71.32537673090597, -14.330102981970462],
            [-71.331495230912, -14.33102073197142],
            [-71.33261710591312, -14.331258731971667],
            [-71.3353364809158, -14.331360606971806],
            [-71.33611823091655, -14.33098673197147],
            [-71.3373419809178, -14.327927481968647],
            [-71.33754598091802, -14.324936106965868],
            [-71.33732073091781, -14.324583481965513],
            [-71.33545123091595, -14.323393731964373],
            [-71.33404898091459, -14.322628856963648],
            [-71.33277423091334, -14.32088685696202],
            [-71.33296485591354, -14.319717981960949],
            [-71.33103748091166, -14.316719856958109],
            [-71.32996673091058, -14.315934731957354],
            [-71.32932435590999, -14.31272235695435],
            [-71.3295421059102, -14.310227981952059],
            [-71.32912023090981, -14.310130606951946],
            [-71.32649135590721, -14.308864856950734],
            [-71.32632910590712, -14.300166981942638],
            [-71.32645885590723, -14.299095981941663],
            [-71.32717285590797, -14.295201356938037],
            [-71.32710798090794, -14.290073481933277],
            [-71.32765510590849, -14.288636231931932],
            [-71.32854210590942, -14.2836467319273],
            [-71.33027923091112, -14.281096606924965],
            [-71.33367935591447, -14.279950731923954],
            [-71.33493598091573, -14.279359481923414],
            [-71.33759710591835, -14.280542231924564],
            [-71.34217998092285, -14.285125106928898],
            [-71.3501972309307, -14.287226606930949],
            [-71.35673435593716, -14.28644998193032],
            [-71.36268898094303, -14.283731481927894],
            [-71.36351373094388, -14.28237198192662],
            [-71.36454785594489, -14.279703481924162],
            [-71.36509573094544, -14.27924810692374],
            [-71.36574860594608, -14.279269856923793],
            [-71.36781673094812, -14.280770856925203],
            [-71.371752855952, -14.278168856922834],
            [-71.37058535595091, -14.26786185691322],
            [-71.36915998094953, -14.264307981909907],
            [-71.36755598094794, -14.263546106909173],
            [-71.36750848094796, -14.256880231902953],
            [-71.36762598094806, -14.255157481901369],
            [-71.36815460594862, -14.253943481900228],
            [-71.36887898094932, -14.251339481897846],
            [-71.36801760594848, -14.249459981896061],
            [-71.36705823094755, -14.249224981895852],
            [-71.36707773094757, -14.24501560689192],
            [-71.36744973094798, -14.239709856886988],
            [-71.36721473094778, -14.236596856884075],
            [-71.36684285594743, -14.232661481880422],
            [-71.37471210595523, -14.225373731873741],
            [-71.38022573096065, -14.224192231872735],
            [-71.3825171059629, -14.222939106871603],
            [-71.38423560596459, -14.221507106870297],
            [-71.38516660596551, -14.219072481868038],
            [-71.39059960597089, -14.21566798186493],
            [-71.39450210597472, -14.212192606861757],
            [-71.39632535597654, -14.209714231859463],
            [-71.39735085597756, -14.207606231857529],
            [-71.39732235597754, -14.204985481855084],
            [-71.39692348097716, -14.202022856852322],
            [-71.39678110597706, -14.200854856851228],
            [-71.39760723097785, -14.200569981850975],
            [-71.39951585597971, -14.201908856852246],
            [-71.40051285598068, -14.202165231852502],
            [-71.4049852309851, -14.200797856851276],
            [-71.40535560598545, -14.200655481851175],
            [-71.40834673098838, -14.201139731851638],
            [-71.4086601059887, -14.20148160685196],
            [-71.4095716059896, -14.203390231853758],
            [-71.40991348098991, -14.20376048185411],
            [-71.41267673099262, -14.205498231855756],
            [-71.4144998559944, -14.207292856857453],
            [-71.41498410599486, -14.209286981859309],
            [-71.41600960599588, -14.209885106859883],
            [-71.41692123099678, -14.205127856855476],
            [-71.41737698099728, -14.202022856852574],
            [-71.41689285599678, -14.200085731850768],
            [-71.41827260599815, -14.196998231847923],
            [-71.41902198099892, -14.195243606846308],
            [-71.419131605999, -14.194347856845459],
            [-71.41827260599818, -14.191953481843212],
            [-71.41823373099815, -14.190351856841744],
            [-71.4188734809988, -14.189574981841027],
            [-71.4213182310012, -14.187907106839516],
            [-71.4224377310023, -14.187267356838914],
            [-71.42442548100426, -14.186353356838106],
            [-71.42529373100511, -14.18537110683718],
            [-71.42579635600562, -14.183771606835714],
            [-71.42747198100726, -14.180609106832772],
            [-71.42843360600824, -14.177417981829846],
            [-71.43127910601105, -14.173235856825986],
            [-71.43306223101281, -14.170103356823091],
            [-71.43638748101608, -14.16379023181726],
            [-71.44091748102058, -14.15212785680645],
            [-71.43970310601941, -14.151753981806086],
            [-71.43639535601619, -14.150888106805246],
            [-71.43416135601399, -14.150091481804477],
            [-71.43492335601475, -14.147147481801753],
            [-71.43531485601514, -14.145349106800087],
            [-71.4354722310153, -14.145177231799915],
            [-71.43662048101643, -14.144519106799327],
            [-71.43622848101604, -14.14307810679798],
            [-71.43555860601539, -14.142486356797429],
            [-71.43041210601038, -14.13829498179344],
            [-71.43002935601, -14.137689481792886],
            [-71.42744923100749, -14.131800731787385],
            [-71.42727535600734, -14.129552731785278],
            [-71.42719135600726, -14.129382731785132],
            [-71.4273476060074, -14.12816410678399],
            [-71.42721485600728, -14.126767606782678],
            [-71.42694923100704, -14.12614860678211],
            [-71.42664648100674, -14.125847606781821],
            [-71.42652735600663, -14.123687481779816],
            [-71.42633785600646, -14.123288981779458],
            [-71.42274998100295, -14.118896481775327],
            [-71.41674023099708, -14.1153203567719],
            [-71.41682623099717, -14.115121106771724],
            [-71.42039060600068, -14.112554731769375],
            [-71.42057423100084, -14.11172660676861],
            [-71.42069335600097, -14.110888856767826],
            [-71.4244726060047, -14.106378856763687],
            [-71.42799810600818, -14.106576231763908],
            [-71.42955660600968, -14.10613473176352],
            [-71.4309824810111, -14.104820356762296],
            [-71.43276560601284, -14.104554856762087],
            [-71.4330369810131, -14.104515606762043],
            [-71.43333985601339, -14.104394481761945],
            [-71.43427148101432, -14.103531231761153],
            [-71.43491010601495, -14.103126981760784],
            [-71.4352597310153, -14.103072231760724],
            [-71.43618935601623, -14.102382856760109],
            [-71.4370898560171, -14.101447231759266],
            [-71.44033985602026, -14.10366598176137],
            [-71.51687885609567, -13.967218731635247],
            [-71.47847973105839, -13.929779106599984],
            [-71.3734561059557, -13.920335356589927],
            [-71.30781773089153, -13.890009606560794],
            [-71.28931235587376, -13.827706231502566],
            [-71.19309823077951, -13.79315498146884],
            [-71.16376948075111, -13.743197231421862],
            [-71.10353710569167, -13.76037973143665],
            [-71.08097073066982, -13.725501981403756],
            [-70.99163585558134, -13.761338856435023],
            [-70.95654598054612, -13.804714481474461],
            [-70.86250235545317, -13.800291856467704],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 6,
        FIRST_IDPR: '2302',
        NOMBPROV: 'CANDARAVE',
        FIRST_NOMB: 'TACNA',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: '05/02/1875',
        LAST_FECHA: 'EPOCA INDEP.',
        MIN_SHAPE_: 232161.56518,
        ha: 232161.57,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.14662497959776, -16.938730484331685],
            [-70.09577022953498, -16.985226109368824],
            [-70.07315247950532, -17.034914234411943],
            [-70.07000410449915, -17.08118335945421],
            [-70.09530685452755, -17.122885984495714],
            [-70.09331535451686, -17.290048109649604],
            [-70.12446685455332, -17.319910109680855],
            [-70.19438072963764, -17.32430498469272],
            [-70.2438014796955, -17.35871348472962],
            [-70.34578710481297, -17.460740234833548],
            [-70.40051372987851, -17.421234359801737],
            [-70.36390622983716, -17.373914109754885],
            [-70.43528547992152, -17.33468348472449],
            [-70.45427147994444, -17.300642609694464],
            [-70.57250785508195, -17.194109359604138],
            [-70.52779860503202, -17.168308359577278],
            [-70.4918556049932, -17.091373734503563],
            [-70.44957935494529, -17.081292359491144],
            [-70.4647226049664, -16.94568360936675],
            [-70.44160547994319, -16.841226609268368],
            [-70.3076113547925, -16.77304685919447],
            [-70.20557422967289, -16.80812498421729],
            [-70.1840672296466, -16.834687484239634],
            [-70.24376760471526, -16.860242234269197],
            [-70.2590663547314, -16.90203323430926],
            [-70.24718185471554, -16.9524439843547],
            [-70.18128922963902, -16.9321196093293],
            [-70.14662497959776, -16.938730484331685],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 6,
        FIRST_IDPR: '0502',
        NOMBPROV: 'CANGALLO',
        FIRST_NOMB: 'AYACUCHO',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: 'EPOCA INDEP.',
        LAST_FECHA: 'EPOCA INDEP.',
        MIN_SHAPE_: 186904.18511,
        ha: 186904.19,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.63282998403214, -13.344780106060606],
            [-74.56689448397073, -13.341226606057264],
            [-74.57168035897516, -13.387120106100012],
            [-74.47575198388584, -13.453138731161497],
            [-74.43175973384486, -13.44911910615775],
            [-74.37838473379514, -13.41903710612973],
            [-74.3360176087557, -13.438749981148105],
            [-74.26212698368687, -13.396345731108598],
            [-74.20180860863069, -13.40618948111776],
            [-74.16012885859186, -13.497011731202345],
            [-74.12335748355761, -13.530953106233959],
            [-74.05562885849449, -13.535736356238422],
            [-74.03932223347931, -13.604837856302785],
            [-74.05007085848935, -13.682645606375226],
            [-74.13948010857263, -13.632939356328956],
            [-74.30132848372337, -13.632719106328743],
            [-74.36838673378583, -13.60280073130087],
            [-74.44375385885601, -13.58553323128481],
            [-74.51653910892381, -13.602298856300424],
            [-74.59927348400088, -13.577900356277688],
            [-74.62717385902685, -13.55674610625799],
            [-74.7094941091035, -13.58515623128445],
            [-74.78241998417145, -13.57850998127827],
            [-74.86217998424573, -13.481789981188177],
            [-74.90209998428291, -13.466439981173885],
            [-74.89772010927884, -13.395309981107657],
            [-74.86874998425186, -13.35989998107467],
            [-74.76386323415416, -13.34139260605744],
            [-74.69945998409419, -13.363509981078044],
            [-74.63282998403214, -13.344780106060606],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 7,
        FIRST_IDPR: '1504',
        NOMBPROV: 'CANTA',
        FIRST_NOMB: 'LIMA',
        LAST_DCTO: 'LEY',
        LAST_LEY: '10151',
        FIRST_FECH: 'EPOC.INDEP.',
        LAST_FECHA: '30/12/1944',
        MIN_SHAPE_: 169044.426619,
        ha: 169044.43,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.80486761105647, -11.755873854580864],
            [-76.89691661114259, -11.729377604556172],
            [-76.93268448617607, -11.755304479580307],
            [-76.9803358612207, -11.724424479551525],
            [-76.97608186121673, -11.685384729515196],
            [-77.03498761127192, -11.652399854484441],
            [-77.00489061124371, -11.613472604448216],
            [-76.85909011110718, -11.508337354350319],
            [-76.89225361113823, -11.492188604335263],
            [-76.76827911102227, -11.42044997926849],
            [-76.69457061095338, -11.429327104276734],
            [-76.59817673586333, -11.326581979181059],
            [-76.54607486081468, -11.316090979171287],
            [-76.48348211075624, -11.344419729197678],
            [-76.45702348573157, -11.334427729188377],
            [-76.42268361069952, -11.35485547920741],
            [-76.41183186068942, -11.41459735426304],
            [-76.37150998565178, -11.475523229319775],
            [-76.37683911065677, -11.50148860434396],
            [-76.40058498567893, -11.507174354349269],
            [-76.48810011076057, -11.45750610430301],
            [-76.54292011081174, -11.517492979358861],
            [-76.53611311080536, -11.55909485439761],
            [-76.57498236084167, -11.632384729465874],
            [-76.6654896109262, -11.658767229490431],
            [-76.74005086099585, -11.69811972952707],
            [-76.75339211100832, -11.731529604558165],
            [-76.80486761105647, -11.755873854580864],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 16,
        FIRST_IDPR: '1505',
        NOMBPROV: 'CAÑETE',
        FIRST_NOMB: 'LIMA',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: '22/06/1962',
        LAST_FECHA: 'EPOC.INDEP.',
        MIN_SHAPE_: 451700.757242,
        ha: 451700.76,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.78416423603711, -12.51993160529239],
            [-76.75168161100673, -12.462689480239103],
            [-76.75548361101029, -12.398213355179044],
            [-76.68341148594291, -12.382109730164053],
            [-76.66799223592851, -12.36023048014369],
            [-76.6166824858806, -12.372826855155418],
            [-76.59583011086113, -12.314867230101452],
            [-76.55733398582517, -12.275728480064975],
            [-76.53765748580682, -12.339368605124282],
            [-76.50119986077279, -12.40064998018134],
            [-76.45276561072758, -12.404263730184685],
            [-76.3938276106726, -12.323642355109623],
            [-76.345997735628, -12.337726480122736],
            [-76.34539586062743, -12.388004105169582],
            [-76.31354686059771, -12.430623105209255],
            [-76.3521152356337, -12.520017605292491],
            [-76.29103961057672, -12.57423910534301],
            [-76.25166936054002, -12.591592230359154],
            [-76.29955423558468, -12.715343480474427],
            [-76.28301136056926, -12.752124730508655],
            [-76.19936611049127, -12.813454980565778],
            [-76.14444286044008, -12.82042485557228],
            [-76.04516911034757, -12.781957480536462],
            [-75.98392836029052, -12.782298855536773],
            [-75.94253373525196, -12.802141605555255],
            [-75.95940998526767, -12.84896998059887],
            [-76.00854711031344, -12.85386685560342],
            [-75.98998236029615, -12.922085980666928],
            [-75.95743098526582, -12.969891355711459],
            [-75.96140236026953, -13.001378855740784],
            [-75.96919923527678, -13.041638730778281],
            [-76.01193673531661, -13.08549935581913],
            [-76.15597061045082, -13.19372898091991],
            [-76.2073199854987, -13.306099981024573],
            [-76.24479286053362, -13.323513731040771],
            [-76.43421098571028, -13.090331980823615],
            [-76.48978323576212, -13.031845730769144],
            [-76.51380661078456, -12.952091855694892],
            [-76.50578711077706, -12.9258144806704],
            [-76.52986136079952, -12.83966798059016],
            [-76.63568361089833, -12.74333773050047],
            [-76.67243361093267, -12.62777535539285],
            [-76.74675786100214, -12.539121105310262],
            [-76.78416423603711, -12.51993160529239],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 10,
        FIRST_IDPR: '2103',
        NOMBPROV: 'CARABAYA',
        FIRST_NOMB: 'PUNO',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: 'EPOCA INDEP.',
        LAST_FECHA: 'EPOCA INDEP.',
        MIN_SHAPE_: 1179106.03863,
        ha: 1179106.04,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.69096147926986, -14.517818732038062],
            [-69.72740035430508, -14.50873435703538],
            [-69.77774222935818, -14.448595356987386],
            [-69.80840035438682, -14.455623106998164],
            [-69.88987510446701, -14.434274356989132],
            [-69.891697104469, -14.43095310698629],
            [-69.89469335447222, -14.426818356982837],
            [-69.89550710447304, -14.42651510698268],
            [-69.89774147947537, -14.424312856980913],
            [-69.89719497947497, -14.42219085697889],
            [-69.90224860448039, -14.415139856972974],
            [-69.9040369794822, -14.414074856972219],
            [-69.91575185449405, -14.407524106967575],
            [-69.91750010449577, -14.407242856967535],
            [-69.92127460449957, -14.405296856966173],
            [-69.92554460450378, -14.404260731965733],
            [-69.93213797951039, -14.401592106964046],
            [-69.9354902295137, -14.400910106963805],
            [-69.93580472951406, -14.400003856963014],
            [-69.96294797954229, -14.37376885694189],
            [-69.9689547295483, -14.371448106940404],
            [-69.97427885455372, -14.367830356937645],
            [-69.97591710455535, -14.36783035693782],
            [-69.98472235456391, -14.368103356939086],
            [-69.98663360456577, -14.368581231939714],
            [-69.99115247957013, -14.369294606940883],
            [-69.99425960457319, -14.368885856940844],
            [-69.99693060457582, -14.36869498194095],
            [-69.99998635457868, -14.370556606943008],
            [-70.00136285458012, -14.369537731942211],
            [-70.00258172958127, -14.36993898194269],
            [-70.00365822958231, -14.370185606943048],
            [-70.0046016045833, -14.36948823194252],
            [-70.01029672958899, -14.367835481941599],
            [-70.01170435459036, -14.36770448194161],
            [-70.01581822959442, -14.36741398194176],
            [-70.01783510459644, -14.367005231941624],
            [-70.02023347959879, -14.366759981941644],
            [-70.0219922296005, -14.367156231942197],
            [-70.02538122960378, -14.367941981943277],
            [-70.02688097960524, -14.367976606943436],
            [-70.03710010461525, -14.368529606945028],
            [-70.03942260461766, -14.366848606943696],
            [-70.04192210462021, -14.36530023194251],
            [-70.04473122962293, -14.366030106943494],
            [-70.04550547962359, -14.367755481945176],
            [-70.04853572962648, -14.369060481946681],
            [-70.05010635462801, -14.369325981947073],
            [-70.05234022963018, -14.370100106948012],
            [-70.0538906046317, -14.369949231948043],
            [-70.05600972963386, -14.368945356947314],
            [-70.06008010463799, -14.367415981946321],
            [-70.06054097963842, -14.367367231946334],
            [-70.06211135463992, -14.368187481947212],
            [-70.06285747964067, -14.368037106947163],
            [-70.0636796046415, -14.367800856947028],
            [-70.0658318546437, -14.366298856945832],
            [-70.06918160464699, -14.366357481946238],
            [-70.07319335465102, -14.365230481945595],
            [-70.07637697965421, -14.364570356945276],
            [-70.07750385465536, -14.363968731944817],
            [-70.078078104656, -14.362681606943688],
            [-70.07930060465723, -14.362271481943434],
            [-70.08163472965953, -14.36227148194366],
            [-70.08358785466145, -14.36271685694427],
            [-70.08563085466344, -14.363421856945095],
            [-70.08815822966596, -14.36295898194494],
            [-70.08938285466725, -14.361656231943835],
            [-70.09222847967007, -14.36112110694362],
            [-70.09755072967548, -14.359070356942217],
            [-70.09897260467679, -14.360337856943515],
            [-70.1012968546791, -14.360365231943765],
            [-70.10201385467983, -14.360312481943792],
            [-70.10290622968071, -14.359982481943572],
            [-70.10635935468416, -14.359215981943166],
            [-70.10700022968483, -14.358929106942986],
            [-70.1105957296884, -14.358789106943188],
            [-70.11130660468912, -14.358734356943184],
            [-70.11296097969084, -14.357390606942111],
            [-70.11344922969135, -14.35621673194108],
            [-70.11403122969202, -14.354912106939933],
            [-70.11493747969291, -14.354701231939789],
            [-70.11663472969458, -14.355099606940325],
            [-70.11685935469481, -14.354716856940003],
            [-70.11658397969467, -14.352488231937896],
            [-70.11702347969515, -14.352146481937641],
            [-70.11864260469676, -14.351574231937246],
            [-70.1197207296978, -14.352238231937969],
            [-70.11977735469785, -14.352394481938127],
            [-70.12006247969796, -14.355292981940808],
            [-70.12502522970303, -14.353042981939202],
            [-70.1259121047039, -14.352775356939038],
            [-70.12948810470748, -14.352474606939083],
            [-70.1301191047081, -14.352031231938732],
            [-70.13202935471016, -14.349449231936513],
            [-70.1326093547107, -14.349841856936923],
            [-70.13458397971252, -14.352621106939674],
            [-70.13504097971284, -14.35495510694189],
            [-70.1354159797132, -14.355140606942063],
            [-70.13764847971551, -14.353642606940895],
            [-70.13754685471558, -14.350310481937813],
            [-70.1375078547157, -14.347033231934772],
            [-70.13933197971753, -14.346703231934628],
            [-70.14130660471959, -14.344972606933197],
            [-70.14242572972084, -14.342062481930615],
            [-70.14487310472333, -14.34120898193003],
            [-70.14406247972266, -14.338343731927294],
            [-70.14621672972484, -14.337078106926313],
            [-70.14818560472669, -14.339160106928427],
            [-70.14880272972725, -14.340699231929912],
            [-70.15084760472931, -14.33991585692937],
            [-70.15259372973125, -14.336150356926026],
            [-70.15527535473393, -14.33520698192539],
            [-70.15620697973493, -14.333826231924196],
            [-70.15567972973447, -14.332402356922806],
            [-70.15764260473648, -14.331296856921979],
            [-70.15769922973654, -14.33158010692223],
            [-70.15820310473697, -14.333240231923822],
            [-70.15938672973822, -14.331570356922363],
            [-70.15978910473875, -14.32857423191962],
            [-70.16004685473905, -14.328111356919246],
            [-70.1596503547387, -14.326845731918029],
            [-70.15819535473734, -14.325388731916544],
            [-70.15797472973713, -14.325037106916211],
            [-70.16410160474328, -14.323964856915731],
            [-70.16417185474343, -14.321966856913884],
            [-70.16462885474394, -14.321269481913289],
            [-70.16625985474555, -14.321199231913376],
            [-70.16669335474596, -14.321695356913855],
            [-70.16637310474557, -14.323220731915246],
            [-70.1670527297462, -14.323937481915983],
            [-70.16764647974682, -14.323074231915246],
            [-70.16830272974767, -14.319478481911954],
            [-70.16830860474768, -14.319146481911641],
            [-70.16822260474768, -14.31768148191029],
            [-70.17034572974966, -14.320359356912945],
            [-70.16912497974832, -14.322572106914885],
            [-70.16921097974841, -14.322890606915202],
            [-70.17054635474969, -14.323582481915942],
            [-70.17117760475041, -14.321539106914095],
            [-70.17110160475036, -14.3212069819138],
            [-70.17149222975083, -14.319767606912492],
            [-70.17213285475148, -14.31900385691183],
            [-70.17265235475203, -14.318722606911619],
            [-70.1753418547546, -14.320496106913502],
            [-70.1758516047551, -14.321152356914135],
            [-70.17792572975712, -14.32151373191466],
            [-70.17976372975899, -14.321195356914528],
            [-70.18066210475997, -14.31941785691296],
            [-70.1807909797601, -14.319330106912881],
            [-70.18141597976076, -14.318572231912238],
            [-70.18327735476257, -14.319322231913066],
            [-70.18283397976217, -14.318080106911898],
            [-70.18302147976237, -14.317578106911462],
            [-70.18296685476241, -14.316070356910037],
            [-70.18273247976221, -14.315384731909393],
            [-70.18183785476145, -14.312226606906394],
            [-70.18192972976168, -14.309662106904018],
            [-70.18038672976053, -14.302183606896966],
            [-70.18034960476054, -14.30107223189593],
            [-70.17976947976028, -14.293914106889241],
            [-70.18907447976973, -14.289697731886136],
            [-70.19396272977471, -14.286718231883798],
            [-70.19857160477953, -14.281317856879165],
            [-70.20183047978294, -14.278105606876446],
            [-70.20406522978531, -14.274613981873411],
            [-70.20937372979087, -14.26841785686809],
            [-70.22072147980171, -14.277510606877433],
            [-70.22261272980337, -14.28267523188236],
            [-70.22370522980411, -14.290473356889683],
            [-70.22340335480364, -14.29475960689361],
            [-70.22591772980576, -14.30283560690129],
            [-70.23861635481813, -14.309127481908144],
            [-70.24060860482014, -14.308765231907964],
            [-70.244411854824, -14.307256106906856],
            [-70.24978472982939, -14.306169356906276],
            [-70.25129397983095, -14.305445106905692],
            [-70.26644097984702, -14.283050606886084],
            [-70.26824210484881, -14.282969606886137],
            [-70.32946222991413, -14.175177356790623],
            [-70.38691410497061, -14.196015481813719],
            [-70.4251697300094, -14.178093856799467],
            [-70.47963248006249, -14.223145731844378],
            [-70.54118560512514, -14.18970885681662],
            [-70.55104823013595, -14.155318481785205],
            [-70.61929148020415, -14.159357481792194],
            [-70.65014035523585, -14.12456510676123],
            [-70.69202435527775, -14.123915106762375],
            [-70.71985060530656, -14.074981481718044],
            [-70.78981835537634, -14.078816356724149],
            [-70.84818723043493, -14.055551481704487],
            [-70.86181423044897, -14.02622760667767],
            [-70.83303910542179, -13.938099731594859],
            [-70.83127298042132, -13.85893173152123],
            [-70.88122535547122, -13.832837731498511],
            [-70.86250235545317, -13.800291856467704],
            [-70.8265547304172, -13.827236356491618],
            [-70.76837498035982, -13.81433210647768],
            [-70.72136135531301, -13.829341856489908],
            [-70.69885823029128, -13.803167606464726],
            [-70.73474710532801, -13.739016731406489],
            [-70.71512885531024, -13.662253981334466],
            [-70.6539128552502, -13.655936106326184],
            [-70.6161908552143, -13.609062481281075],
            [-70.54172373014245, -13.57448898124557],
            [-70.53403173013632, -13.525270106199494],
            [-70.4526179800599, -13.447918356123512],
            [-70.44255010505196, -13.393359856072353],
            [-70.40007310501208, -13.371738606049892],
            [-70.39283623000719, -13.31416073099605],
            [-70.42040835503526, -13.258570105946022],
            [-70.38846685500734, -13.19238873088278],
            [-70.40286810502359, -13.114412855811256],
            [-70.03255472967997, -13.223748105885067],
            [-69.9741621046275, -13.245101605899071],
            [-69.64986322935322, -13.341716855946913],
            [-69.63275610433335, -13.406301981003786],
            [-69.68163685436197, -13.499835481097707],
            [-69.71313522938681, -13.505575481107686],
            [-69.72653122938573, -13.633746106227946],
            [-69.74946272940174, -13.669223606263982],
            [-69.75371960439915, -13.737892231327988],
            [-69.78574385442563, -13.75072148134426],
            [-69.73282210437225, -13.83609398141564],
            [-69.66349960430836, -13.884636231449893],
            [-69.72319997935077, -13.976969981544224],
            [-69.72519335434862, -14.018781231583118],
            [-69.76319535438134, -14.030593731599518],
            [-69.80589897941547, -14.082832481653659],
            [-69.85092422944277, -14.26271610682574],
            [-69.7993657293939, -14.268325731824023],
            [-69.78323335437511, -14.311986856862077],
            [-69.72786047932021, -14.349689356888783],
            [-69.72407310431035, -14.418077731951279],
            [-69.65978047924727, -14.453333731973544],
            [-69.69096147926986, -14.517818732038062],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 13,
        FIRST_IDPR: '0403',
        NOMBPROV: 'CARAVELI',
        FIRST_NOMB: 'AREQUIPA',
        LAST_DCTO: 'LEY',
        LAST_LEY: '12450',
        FIRST_FECH: '09/12/1897',
        LAST_FECHA: '24/11/1955',
        MIN_SHAPE_: 1300147.81174,
        ha: 1300147.81,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.6035079830731, -15.411378857985119],
            [-73.59972385806957, -15.407066357981101],
            [-73.59410860806436, -15.403695107977962],
            [-73.58769985805834, -15.404820482979014],
            [-73.57463835804617, -15.407257107981286],
            [-73.54157260801533, -15.400896982975356],
            [-73.53663635801072, -15.397923482972592],
            [-73.49362185797058, -15.382230732957959],
            [-73.48940273296664, -15.381867357957633],
            [-73.48588560796337, -15.38019373295607],
            [-73.48374173296138, -15.379715857955626],
            [-73.48188023295963, -15.379525232955443],
            [-73.45661160793604, -15.364637357941573],
            [-73.44697585792703, -15.357982607935387],
            [-73.44110210792157, -15.355723732933285],
            [-73.39216610787585, -15.398736982973318],
            [-73.33628085782367, -15.405148482979294],
            [-73.27479548276622, -15.39295385796792],
            [-73.20541385770134, -15.510603858077449],
            [-73.22634123272094, -15.570303983133067],
            [-73.21730798271247, -15.64639473320392],
            [-73.23897548273274, -15.680068983235284],
            [-73.26244348275469, -15.825497608370718],
            [-73.23324585772738, -15.874050108415931],
            [-73.22499085771966, -15.984971983519225],
            [-73.24028773273399, -15.998206108531539],
            [-73.2220382327169, -16.119234108644246],
            [-73.20323185769931, -16.15299410867568],
            [-73.19631198269283, -16.280515608794435],
            [-73.24226373273584, -16.356311733865027],
            [-73.29160310778194, -16.40540698391077],
            [-73.31420135780306, -16.338703108848645],
            [-73.45125585793106, -16.281307108795232],
            [-73.61992648308843, -16.230085358747537],
            [-73.69937123316251, -16.220703108738814],
            [-73.7278823581891, -16.195247608715107],
            [-73.86935423332096, -16.13554385865951],
            [-73.88938898333963, -16.10935210863512],
            [-74.03308110847352, -16.018058733550117],
            [-74.05445098349342, -15.954523108490942],
            [-74.16719073359845, -15.900853108440955],
            [-74.24159998366775, -15.878699358420322],
            [-74.27519985869904, -15.840464608384721],
            [-74.32790373374814, -15.848716733392415],
            [-74.39330285880907, -15.829308483374323],
            [-74.43929285885189, -15.79456048334198],
            [-74.46282960887379, -15.726521483278617],
            [-74.66127773405863, -15.65283585820999],
            [-74.74967960914095, -15.60038085816114],
            [-74.83074948421647, -15.567194983130237],
            [-74.90997310929025, -15.512113608078954],
            [-75.00684360938045, -15.460041108030461],
            [-75.07332610944235, -15.442941608014523],
            [-75.04965210942034, -15.434503608006674],
            [-75.00387973437769, -15.330230357909564],
            [-74.96820073434448, -15.299422232880879],
            [-74.93986510931808, -15.206011732793893],
            [-74.87060198425358, -15.151229107742875],
            [-74.81591798420264, -15.139497732731936],
            [-74.76229860915272, -15.09805485769335],
            [-74.71860498411202, -15.135662982728377],
            [-74.60597223400713, -15.148497607740325],
            [-74.55252073395734, -15.139941232732351],
            [-74.45142135886319, -15.175972732765905],
            [-74.3987197338141, -15.181463232771021],
            [-74.387878358804, -15.251779607836493],
            [-74.4094619838241, -15.308493607889314],
            [-74.30971848373119, -15.358937232936293],
            [-74.32843135874862, -15.390993732966146],
            [-74.30012360872226, -15.47839460804754],
            [-74.24117573366735, -15.481039108050004],
            [-74.234359608661, -15.485704483054354],
            [-74.2043991086331, -15.488726608057169],
            [-74.19622798362549, -15.491357858059619],
            [-74.19374085862317, -15.491660108059891],
            [-74.1620941085937, -15.479524608048598],
            [-74.15969085859145, -15.472780233042315],
            [-74.15013123358253, -15.451024108022056],
            [-74.1517792335841, -15.447794858019044],
            [-74.15364835858584, -15.442526858014133],
            [-74.15374760858593, -15.440718608012451],
            [-74.1512603585836, -15.428750983001317],
            [-74.146324108579, -15.426400982999121],
            [-74.13723760857053, -15.424598732997442],
            [-74.128074608562, -15.412535482986204],
            [-74.12194823355628, -15.411347357985099],
            [-74.11500548354982, -15.411566732985316],
            [-74.11472323354957, -15.410816232984608],
            [-74.11496735854979, -15.409098607982997],
            [-74.11307523354802, -15.404983482979185],
            [-74.08166498351878, -15.395749107970586],
            [-74.08087923351802, -15.396698982971465],
            [-74.07775110851513, -15.398432732973067],
            [-74.07523348351278, -15.398823732973446],
            [-74.06542973350365, -15.402194982976575],
            [-74.06491848350318, -15.403035107977368],
            [-74.06189723350035, -15.406675357980747],
            [-74.06041723349897, -15.40754323298155],
            [-74.0562591084951, -15.4148902329884],
            [-74.05513760849406, -15.416220607989633],
            [-74.05373385849275, -15.417725607991038],
            [-74.05171198349088, -15.42476560799761],
            [-74.04553223348509, -15.443377483014931],
            [-74.04187010848167, -15.448463483019665],
            [-74.03847498347852, -15.450760858021816],
            [-74.03784185847795, -15.454154983024967],
            [-74.03729248347742, -15.456077608026764],
            [-74.03758235847769, -15.456623108027264],
            [-74.04171748348153, -15.46220873303247],
            [-74.04257198348233, -15.464806608034898],
            [-74.04241185848218, -15.466713858036657],
            [-74.04269410848246, -15.467931733037803],
            [-74.04094698348085, -15.474872608044265],
            [-74.03887935847891, -15.479539858048607],
            [-74.0386352334787, -15.48159035805052],
            [-74.04234310848214, -15.4884404830569],
            [-74.04303735848276, -15.489962608058319],
            [-74.04437260848404, -15.492308608060505],
            [-74.04512785848475, -15.492815983060956],
            [-74.0585022334972, -15.49814610806594],
            [-74.07441710851202, -15.502659858070132],
            [-74.075477608513, -15.504605233071958],
            [-74.075477608513, -15.505577108072856],
            [-74.07460023351219, -15.508587733075657],
            [-74.07464598351221, -15.509606358076612],
            [-74.07594298351343, -15.511088358077988],
            [-74.07779698351517, -15.51298810807975],
            [-74.07872010851601, -15.517017358083505],
            [-74.07626348351374, -15.522621108088726],
            [-74.0745086085121, -15.526094483091967],
            [-74.07149498350928, -15.529567733095185],
            [-74.07144923350926, -15.531096483096615],
            [-74.07126623350908, -15.53415310809947],
            [-74.07080073350866, -15.535264983100497],
            [-74.06816098350619, -15.537626233102696],
            [-74.06820673350623, -15.541285483106105],
            [-74.07205210850982, -15.544897983109474],
            [-74.07237248351011, -15.545870733110375],
            [-74.07654573351398, -15.552864108116891],
            [-74.07654573351398, -15.554808608118702],
            [-74.0740891085117, -15.561616858125054],
            [-74.07395173351159, -15.563053108126375],
            [-74.07701110851445, -15.567452483130483],
            [-74.07746885851485, -15.569305358132205],
            [-74.07881160851612, -15.575974483138422],
            [-74.07918548351645, -15.58097648314307],
            [-74.08089448351804, -15.582272483144282],
            [-74.08390823352087, -15.589961108151437],
            [-74.08460235852151, -15.59352685815476],
            [-74.08390823352087, -15.59667685815769],
            [-74.08358760852055, -15.597834608158768],
            [-74.08256535851962, -15.608255358168469],
            [-74.08196260851905, -15.610384983170459],
            [-74.0815887335187, -15.611681983171671],
            [-74.06060023349916, -15.628196733187044],
            [-74.05832673349703, -15.626461983185424],
            [-74.05232235849142, -15.623126858182319],
            [-74.05094910849014, -15.622281983181546],
            [-74.04992673348922, -15.619969358179386],
            [-74.02854160846928, -15.606561608166892],
            [-74.02313998346423, -15.60042098316118],
            [-74.02166748346286, -15.600383733161147],
            [-74.01943210846079, -15.598378233159277],
            [-74.01943210846079, -15.597582858158535],
            [-74.01837160845982, -15.593685108154904],
            [-74.01916498346054, -15.591490733152856],
            [-74.01486973345656, -15.582417483144424],
            [-74.01327510845505, -15.571353858134115],
            [-74.01083373345278, -15.569384608132273],
            [-74.01090998345285, -15.565029108128225],
            [-74.01117710845311, -15.564076483127337],
            [-74.00043485844309, -15.553952233117906],
            [-73.99532323343834, -15.55198385811607],
            [-73.99439235843745, -15.550391233114592],
            [-73.99387360843697, -15.549690233113935],
            [-73.99286648343603, -15.548411358112743],
            [-73.99191298343516, -15.547106608111534],
            [-73.98479460842852, -15.54092885810578],
            [-73.97801973342223, -15.537620483102694],
            [-73.9774551084217, -15.537214233102313],
            [-73.97261810841718, -15.534092858099411],
            [-73.97055810841525, -15.533656233099002],
            [-73.96849823341334, -15.53131485809682],
            [-73.96391298340906, -15.525852233091738],
            [-73.95872498340422, -15.519952733086239],
            [-73.95421598340003, -15.510927233077833],
            [-73.95147710839748, -15.505274733072564],
            [-73.94992823339605, -15.502988858070449],
            [-73.94989773339601, -15.501898733069417],
            [-73.95007323339615, -15.499752983067435],
            [-73.94618223339255, -15.49390985806198],
            [-73.94267273338929, -15.486734358055303],
            [-73.94089510838762, -15.484251983053],
            [-73.93986510838667, -15.482957858051792],
            [-73.93857573338545, -15.482525733051391],
            [-73.93485273338199, -15.480637608049628],
            [-73.93290710838018, -15.478101733047266],
            [-73.92729948337495, -15.472382483041935],
            [-73.92071535836881, -15.464829483034906],
            [-73.91477960836328, -15.458247233028771],
            [-73.89127348334137, -15.44456398301604],
            [-73.8874358583378, -15.438777983010644],
            [-73.87808985832909, -15.43808935801001],
            [-73.87686923332797, -15.438777983010644],
            [-73.87655635832766, -15.439152858010996],
            [-73.86951448332108, -15.428933108001473],
            [-73.86808010831977, -15.421436357994496],
            [-73.86879735832044, -15.417262982990609],
            [-73.87016298332169, -15.403767607978049],
            [-73.86977385832135, -15.401616107976043],
            [-73.8740082333253, -15.393531732968519],
            [-73.87518310832637, -15.391901982966989],
            [-73.87212373332355, -15.383621232959278],
            [-73.87081910832232, -15.379905732955827],
            [-73.87440485832568, -15.36406235794107],
            [-73.8748627333261, -15.355065357932698],
            [-73.88388823333449, -15.337916357916715],
            [-73.88407910833467, -15.33745673291629],
            [-73.88028710833115, -15.33738035791623],
            [-73.87936398333028, -15.337226857916075],
            [-73.87710573332818, -15.336231232915154],
            [-73.87093348332242, -15.33293623291209],
            [-73.8656539833175, -15.324163482903902],
            [-73.864044233316, -15.324699357904418],
            [-73.86296848331499, -15.325312482904986],
            [-73.86005398331228, -15.326117482905728],
            [-73.8251953582798, -15.325997357905615],
            [-73.81546785827075, -15.325529232905177],
            [-73.8009568582572, -15.32856660790801],
            [-73.79919435825558, -15.333788857912875],
            [-73.79857635825499, -15.33469010791371],
            [-73.79598998325258, -15.336660357915546],
            [-73.78928373324631, -15.338968232917695],
            [-73.78585048324314, -15.338011732916803],
            [-73.77988435823758, -15.338405607917167],
            [-73.77808385823587, -15.33874323291748],
            [-73.77645110823435, -15.340319607918946],
            [-73.77954860823725, -15.342740982921208],
            [-73.77931973323703, -15.346400357924606],
            [-73.77515410823317, -15.351242107929126],
            [-73.77205660823027, -15.354281482931958],
            [-73.76623535822483, -15.367715857944464],
            [-73.76285548322168, -15.376090107952269],
            [-73.75971985821877, -15.383140607958827],
            [-73.72684473318812, -15.409983607983829],
            [-73.71743010817936, -15.41420560798775],
            [-73.70432285816713, -15.423002232995955],
            [-73.69455723315802, -15.434776358006912],
            [-73.67452235813933, -15.431467108003819],
            [-73.65975185812556, -15.423498107996402],
            [-73.65161135811796, -15.416278857989692],
            [-73.64204410810906, -15.417789482991092],
            [-73.63011935809793, -15.418964357992186],
            [-73.62802123309598, -15.4200563579932],
            [-73.6035079830731, -15.411378857985119],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 11,
        FIRST_IDPR: '0206',
        NOMBPROV: 'CARHUAZ',
        FIRST_NOMB: 'ANCASH',
        LAST_DCTO: 'LEY',
        LAST_LEY: '7951',
        FIRST_FECH: '22/11/1868',
        LAST_FECHA: '14/12/1934',
        MIN_SHAPE_: 81429.38542640001,
        ha: 81429.39,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.3191841115385, -9.324105602316077],
            [-77.33009086154874, -9.382391727370344],
            [-77.40209173661643, -9.347337852337668],
            [-77.46847011167885, -9.367335602356235],
            [-77.57639761178045, -9.374120977362482],
            [-77.61300586181495, -9.422083977407116],
            [-77.65553611185501, -9.442273852425874],
            [-77.69048161188795, -9.377838477365826],
            [-77.74419598693856, -9.370708977359131],
            [-77.77125273696406, -9.333952352324863],
            [-77.76564848695872, -9.2923163522861],
            [-77.72450748691996, -9.276399102271311],
            [-77.68929673688673, -9.224164727222714],
            [-77.66137598686045, -9.239836727237353],
            [-77.61990011182135, -9.214448352213747],
            [-77.58891836179217, -9.15870022716186],
            [-77.60131998680382, -9.108935227115486],
            [-77.530343736737, -9.082921977091324],
            [-77.49046036169948, -9.106671852113488],
            [-77.53354723674005, -9.173116727175325],
            [-77.5108271117187, -9.254347227250973],
            [-77.48036723669001, -9.255315102251918],
            [-77.32568248654461, -9.31004960230295],
            [-77.3191841115385, -9.324105602316077],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 3,
        FIRST_IDPR: '0207',
        NOMBPROV: 'CARLOS FERMIN FITZCARRALD',
        FIRST_NOMB: 'ANCASH',
        LAST_DCTO: 'LEY',
        LAST_LEY: '23609',
        FIRST_FECH: 'EPOCA INDEP.',
        LAST_FECHA: '06/06/1983',
        MIN_SHAPE_: 62742.6841026,
        ha: 62742.68,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.35949611157633, -8.977238226993013],
            [-77.32046873653967, -8.945441351963408],
            [-77.18857223641584, -8.928382851947573],
            [-77.15953323638861, -8.897103476918451],
            [-77.10314261133573, -8.972433601988612],
            [-77.11132611134339, -9.008357477022063],
            [-77.15239398638192, -9.033718977045675],
            [-77.14411373637415, -9.095664727103372],
            [-77.17387123640206, -9.159956227163216],
            [-77.2215568614468, -9.16757797717032],
            [-77.26205161148481, -9.20778722720776],
            [-77.3622084865789, -9.112180852118694],
            [-77.36200198657869, -9.064992227074734],
            [-77.35949611157633, -8.977238226993013],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 4,
        FIRST_IDPR: '0208',
        NOMBPROV: 'CASMA',
        FIRST_NOMB: 'ANCASH',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: '21/10/1870',
        LAST_FECHA: 'EPOCA INDEP.',
        MIN_SHAPE_: 227590.80181,
        ha: 227590.8,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.05609948723257, -9.274701227269121],
            [-78.0597479872361, -9.33084960232142],
            [-78.00331648718294, -9.380970602368217],
            [-78.03017911220833, -9.418492352403105],
            [-77.99294948717323, -9.446605602429386],
            [-77.93290848711656, -9.398981977385146],
            [-77.90099611208649, -9.442234352425498],
            [-77.9363338621199, -9.528074352505357],
            [-77.98432936216521, -9.540384852516713],
            [-77.96079261214307, -9.615372352586611],
            [-78.00609373718594, -9.680642602647248],
            [-78.04189061221972, -9.689902352655787],
            [-78.08740423726272, -9.720489977684156],
            [-78.1448277373169, -9.714579102678476],
            [-78.22621098739384, -9.795281227753321],
            [-78.2720097374369, -9.756070352716616],
            [-78.32181636248356, -9.66174810262858],
            [-78.3648534875239, -9.620470727589936],
            [-78.36798823752665, -9.55531047752926],
            [-78.39773823755426, -9.472115227451631],
            [-78.3801113625377, -9.445490227426928],
            [-78.42794136258239, -9.41128910239484],
            [-78.42789448758212, -9.349300727337123],
            [-78.40381236255959, -9.347127852335232],
            [-78.35965436251797, -9.263016977257163],
            [-78.26080436242509, -9.200922727199771],
            [-78.21128461237856, -9.208968102207454],
            [-78.14388873731525, -9.267190102261909],
            [-78.05609948723257, -9.274701227269121],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 14,
        FIRST_IDPR: '0404',
        NOMBPROV: 'CASTILLA',
        FIRST_NOMB: 'AREQUIPA',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: 'EPOCA INDEP.',
        LAST_FECHA: 'EPOCA INDEP.',
        MIN_SHAPE_: 692911.42571,
        ha: 692911.43,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.95185085651534, -14.837326107448318],
            [-71.93957523150338, -14.919787357525014],
            [-71.91380310647845, -14.957285982559789],
            [-71.9519189815152, -14.988465982589016],
            [-71.98238373154443, -15.063702607659204],
            [-71.94702910651031, -15.110272357702378],
            [-71.96975710653217, -15.135966357726417],
            [-71.90141298146615, -15.170798357758466],
            [-71.94597623150912, -15.226960232810981],
            [-71.98410035654577, -15.33786873291443],
            [-72.00102235656192, -15.461908358029987],
            [-72.00339510656407, -15.581884358141679],
            [-72.10131073165823, -15.603425983162188],
            [-72.1631546067175, -15.659712733214834],
            [-72.20906835676142, -15.681509983235294],
            [-72.27619173182543, -15.78339285833036],
            [-72.2961883568445, -15.830810608374563],
            [-72.29653173184478, -15.92103673345857],
            [-72.24127198179201, -15.949439108484839],
            [-72.25285348180299, -16.094285983619727],
            [-72.27404023182321, -16.138677608661133],
            [-72.35475160690014, -16.158090608679437],
            [-72.37502285691939, -16.28212360879497],
            [-72.4424971069835, -16.331415233841035],
            [-72.46635435700613, -16.383155858889257],
            [-72.5936279821266, -16.430480983933577],
            [-72.65554048218509, -16.291116733803896],
            [-72.65108485718089, -16.247810358763562],
            [-72.68740860721516, -16.218505858736325],
            [-72.68720248221496, -16.119810108644415],
            [-72.7131152322394, -16.053790608582975],
            [-72.70158610722855, -16.008401608540698],
            [-72.60309598213561, -16.0172462335488],
            [-72.57911685711298, -15.937534358474547],
            [-72.51864623205577, -15.908813483447693],
            [-72.54256435707843, -15.81330960835882],
            [-72.62319185715462, -15.704617483257731],
            [-72.66332248219251, -15.71060185826335],
            [-72.72351835724925, -15.603018733163239],
            [-72.66069798219002, -15.545888858109976],
            [-72.60881810714106, -15.559671358122753],
            [-72.54360960707946, -15.534378983099105],
            [-72.50759123204539, -15.475064233043799],
            [-72.51167298204925, -15.398737857972753],
            [-72.42713910696916, -15.36814023294409],
            [-72.43841548197989, -15.301561357882123],
            [-72.41959385696205, -15.270532607853195],
            [-72.36466985690991, -15.241896607826419],
            [-72.37693023192156, -15.197789232785375],
            [-72.35557560690131, -15.09783748269227],
            [-72.31700135686462, -15.094398482688968],
            [-72.2654723568156, -15.043250107641207],
            [-72.2533569818041, -14.946263232550885],
            [-72.14771273170327, -14.881403857490167],
            [-72.07192248163064, -14.937385607542016],
            [-72.02683260658742, -14.876018482484689],
            [-72.00493623156638, -14.886319982494188],
            [-71.95185085651534, -14.837326107448318],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 13,
        FIRST_IDPR: '0904',
        NOMBPROV: 'CASTROVIRREYNA',
        FIRST_NOMB: 'HUANCAVELICA',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: '06/09/1920',
        LAST_FECHA: 'EPOCA INDEP.',
        MIN_SHAPE_: 395269.60603,
        ha: 395269.61,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.61439310994628, -12.964933355706847],
            [-75.63612460996653, -12.916175230661457],
            [-75.61309160994507, -12.866975355615637],
            [-75.53108185986869, -12.822207855573941],
            [-75.51389260985268, -12.788652355542677],
            [-75.44200335978573, -12.775222230530176],
            [-75.37130885971989, -12.825651605577146],
            [-75.38097535972891, -12.842558355592871],
            [-75.33477910968587, -12.936174355680071],
            [-75.24981973460675, -12.879239980627036],
            [-75.19208173455299, -12.947288605690423],
            [-75.1494711095133, -12.96005635570231],
            [-75.12065035948645, -12.931834855676021],
            [-75.0572287344274, -12.914255105659667],
            [-75.03703110940857, -12.939632980683283],
            [-75.02919485940127, -13.105861730838114],
            [-74.98895498436379, -13.141519105871298],
            [-75.04910548441981, -13.186556480913232],
            [-75.16799998453054, -13.224511730948576],
            [-75.16392885952676, -13.27393685599462],
            [-75.20416748456422, -13.311974856030025],
            [-75.20550548456548, -13.344433856060254],
            [-75.25582735961235, -13.417471981128275],
            [-75.29113260964523, -13.401675481113585],
            [-75.31034773466313, -13.450247106158795],
            [-75.43461135977884, -13.46964060617688],
            [-75.4773104848186, -13.525484356228866],
            [-75.46577535980786, -13.432160106141964],
            [-75.50291598484245, -13.365950106080303],
            [-75.538917734876, -13.405012481116671],
            [-75.58512210991903, -13.402668231114486],
            [-75.68928098501604, -13.439670106148949],
            [-75.71216286003734, -13.416420231127317],
            [-75.80598523512474, -13.401433356113353],
            [-75.79020536011004, -13.342509856058495],
            [-75.70648148503207, -13.288286856007977],
            [-75.70633673503193, -13.186761605913443],
            [-75.69377398502021, -13.147504355876876],
            [-75.7187682350435, -13.109764730841723],
            [-75.69496248502132, -13.074792480809164],
            [-75.62860823495953, -13.05706673079265],
            [-75.61439310994628, -12.964933355706847],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 20,
        FIRST_IDPR: '0405',
        NOMBPROV: 'CAYLLOMA',
        FIRST_NOMB: 'AREQUIPA',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: '20/12/1999',
        LAST_FECHA: 'EPOCA INDEP.',
        MIN_SHAPE_: 1410845.90829,
        ha: 1410845.91,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.890541105451, -15.851253483374048],
            [-70.84533835540263, -15.921928483438025],
            [-70.85451510541223, -15.924734108440969],
            [-70.85599523041378, -15.925342608441595],
            [-70.86895748042714, -15.940552733456196],
            [-70.87348173043182, -15.944279608459828],
            [-70.87424473043262, -15.945562358461034],
            [-70.87798310543648, -15.94797610846342],
            [-70.90248873046215, -15.945558608462052],
            [-70.90960698046962, -15.944540983461359],
            [-70.9202041054806, -15.950683733467438],
            [-70.94494623050623, -15.962502483479243],
            [-70.94835660550979, -15.96166035847857],
            [-70.95084385551239, -15.961837733478825],
            [-70.95281985551443, -15.961989358479022],
            [-70.96321110552513, -15.969236358486096],
            [-70.98519898054802, -15.969083733486647],
            [-70.99826810556155, -15.967929858485977],
            [-71.01191710557566, -15.972017233490186],
            [-71.0360946056006, -15.980232233498528],
            [-71.04703523061185, -15.984318733502642],
            [-71.05138398061628, -15.991138483509102],
            [-71.06163785562677, -15.99818323351594],
            [-71.06652823063175, -16.00368885852119],
            [-71.07689660564246, -16.00419998352194],
            [-71.08133698064705, -15.9996204835178],
            [-71.08595273065181, -15.998011608516437],
            [-71.08654785565248, -15.992697733511518],
            [-71.08661648065257, -15.992138858511003],
            [-71.08623510565222, -15.987698608506859],
            [-71.08537298065136, -15.9849586085043],
            [-71.08666998065274, -15.981054358500703],
            [-71.09597773066227, -15.984142358503817],
            [-71.10568998067224, -15.984902358504783],
            [-71.10846723067507, -15.986961358506768],
            [-71.1170578556839, -15.988044733507992],
            [-71.12216185568916, -15.984615358504925],
            [-71.144454980712, -15.983701733504633],
            [-71.14456935571212, -15.985576608506381],
            [-71.14519510571273, -15.987470608508152],
            [-71.14953610571715, -15.991220483511741],
            [-71.15154260571917, -15.996675483516864],
            [-71.15596773072363, -16.00307460852292],
            [-71.16038173072813, -16.004753358524592],
            [-71.17407985574211, -16.006683358526697],
            [-71.18025973074839, -16.010259608530173],
            [-71.18240360575058, -16.01052098353045],
            [-71.19159698075994, -16.01472660853458],
            [-71.19742585576583, -16.020505858540098],
            [-71.20168310577013, -16.025598483544908],
            [-71.21196748078066, -16.020309483540224],
            [-71.21627810578504, -16.01707648353732],
            [-71.21759798078641, -16.014629233535054],
            [-71.22308348079203, -16.009437608530366],
            [-71.22854823079763, -16.004482358525877],
            [-71.23156910580073, -16.001940983523575],
            [-71.23852198080779, -16.000598358522463],
            [-71.2483518558178, -15.998536358520768],
            [-71.26000385582967, -15.988179108511366],
            [-71.26637273083615, -15.98216248350591],
            [-71.26908873083897, -15.979008733503033],
            [-71.26894373083883, -15.975126233499404],
            [-71.27398073084402, -15.96584273349089],
            [-71.2740806058441, -15.963244108488468],
            [-71.28357485585377, -15.957048108482887],
            [-71.30554198087597, -15.953512233480021],
            [-71.30707548087753, -15.954428733480906],
            [-71.31419373088474, -15.947256108474365],
            [-71.32198335589264, -15.941599858469248],
            [-71.34152235591232, -15.93927960846743],
            [-71.3506164809215, -15.935276983463877],
            [-71.36496735593596, -15.930419983459613],
            [-71.36686710593786, -15.92945385845875],
            [-71.41012573098126, -15.927132608457278],
            [-71.4123839809835, -15.928809108458879],
            [-71.41759485598874, -15.930341733460391],
            [-71.42247773099362, -15.93040560846052],
            [-71.4292449810004, -15.92938423345968],
            [-71.432838481004, -15.927702858458169],
            [-71.4444656060156, -15.92743498345809],
            [-71.45317823102427, -15.932948108463355],
            [-71.4659957310371, -15.926888483457898],
            [-71.47227473104334, -15.9267949834579],
            [-71.47978973105083, -15.928372358459468],
            [-71.48295598105398, -15.929326108460401],
            [-71.48667923105768, -15.931694983462659],
            [-71.49009710606109, -15.932156608463133],
            [-71.49278260606374, -15.930596358461726],
            [-71.50243373107334, -15.926018733457585],
            [-71.50430298107523, -15.925427483457065],
            [-71.51920323109003, -15.927614233459288],
            [-71.5227051060935, -15.928296983459964],
            [-71.5317001061024, -15.935039483466362],
            [-71.53433985610499, -15.944097483474824],
            [-71.53723148110785, -15.947857858478363],
            [-71.53859710610918, -15.948618858479088],
            [-71.54121398111178, -15.950683733481041],
            [-71.54180148111237, -15.951193858481515],
            [-71.542442356113, -15.95238298348263],
            [-71.54369348111423, -15.957469108487375],
            [-71.54624173111675, -15.958634358488483],
            [-71.54695898111746, -15.959187483489027],
            [-71.54997248112042, -15.962875358492493],
            [-71.55479435612517, -15.968921608498166],
            [-71.55520635612558, -15.96955485849876],
            [-71.55603773112641, -15.971046483500167],
            [-71.55712123112745, -15.972845983501857],
            [-71.55770873112806, -15.973285608502275],
            [-71.56430810613459, -15.97956660850819],
            [-71.57831573114841, -15.990782733518795],
            [-71.58110810615116, -15.990211483518296],
            [-71.59068298116067, -15.987210233515611],
            [-71.59693148116682, -15.984164233512852],
            [-71.59857173116846, -15.983091358511865],
            [-71.60408023117392, -15.982088108511004],
            [-71.64125823121059, -15.986537983515557],
            [-71.64662935621585, -15.988090483517052],
            [-71.6487121062179, -15.988889733517825],
            [-71.65061185621977, -15.9884147335174],
            [-71.65267948122181, -15.985792108514975],
            [-71.65240473122157, -15.983957233513276],
            [-71.65205385622123, -15.98320098351256],
            [-71.6530457312222, -15.980371483509941],
            [-71.6533432312225, -15.979969983509564],
            [-71.65629573122541, -15.976167733506067],
            [-71.65757748122668, -15.974375733504408],
            [-71.65818010622729, -15.973621358503705],
            [-71.6598129812289, -15.970036483500389],
            [-71.66113285623022, -15.964471858495223],
            [-71.66233060623138, -15.962648358493547],
            [-71.67649835624535, -15.949305483481272],
            [-71.678154106247, -15.948716233480736],
            [-71.68534085625406, -15.943370858475827],
            [-71.6857757312545, -15.942860608475357],
            [-71.68762223125631, -15.939047858471833],
            [-71.70629123127468, -15.936469108469613],
            [-71.71008298127833, -15.952910483484938],
            [-71.706726106275, -15.965801233496903],
            [-71.70581060627411, -15.970977733501712],
            [-71.70656573127484, -15.97572323350615],
            [-71.7097168562779, -15.985404983515181],
            [-71.71385198128195, -15.986416858516163],
            [-71.72407535629198, -15.98901273351868],
            [-71.7248458562927, -15.989298858518943],
            [-71.72678373129459, -15.991958608521442],
            [-71.72743985629526, -15.993146858522545],
            [-71.72838598129617, -15.99541948352468],
            [-71.73566435630329, -16.00388723353262],
            [-71.7352676063029, -16.006000483534585],
            [-71.73581698130343, -16.01149935853969],
            [-71.73723598130479, -16.012590358540738],
            [-71.73796085630552, -16.016197233544094],
            [-71.73865510630617, -16.016742733544607],
            [-71.74426273131168, -16.02093123354855],
            [-71.74535373131275, -16.022605858550115],
            [-71.76927948133613, -16.032506983559543],
            [-71.77650448134317, -16.03404423356103],
            [-71.77894598134556, -16.034896858561854],
            [-71.78150935634808, -16.036102233563],
            [-71.78388210635039, -16.03718560856402],
            [-71.7893066063557, -16.037950483564774],
            [-71.7917938563581, -16.03939823356614],
            [-71.79375460636003, -16.04113385856777],
            [-71.80739598137333, -16.04961973357578],
            [-71.81136323137717, -16.050229983576386],
            [-71.81266785637845, -16.050601983576737],
            [-71.8155898563813, -16.057723983583394],
            [-71.82078548138635, -16.061567358587016],
            [-71.82637785639183, -16.05872923358441],
            [-71.82807923139346, -16.060623108586174],
            [-71.82846073139383, -16.06158060858708],
            [-71.83039860639572, -16.0650348585903],
            [-71.83691410640206, -16.07044985859539],
            [-71.83754735640267, -16.073804858598525],
            [-71.83858485640366, -16.079164483603506],
            [-71.83904260640412, -16.082473608606595],
            [-71.84258273140755, -16.088273983612034],
            [-71.84812160641295, -16.094455733617817],
            [-71.86574560643007, -16.111324358633656],
            [-71.86592848143022, -16.11466985863676],
            [-71.86770698143195, -16.121505858643133],
            [-71.86807073143231, -16.122962233644493],
            [-71.87668110644064, -16.134436608655236],
            [-71.88839648145203, -16.145595733665708],
            [-71.90826560647129, -16.15878323367812],
            [-71.91072660647369, -16.159076233678405],
            [-71.93979885650191, -16.144541108665067],
            [-71.94472273150669, -16.14278323366346],
            [-71.95327935651497, -16.144951233665523],
            [-71.95414735651579, -16.14498710866556],
            [-71.95639035651797, -16.144327108664964],
            [-71.95866123152018, -16.14239910866318],
            [-71.96082623152228, -16.140414108661343],
            [-71.96300985652438, -16.137472983658625],
            [-71.97806548153895, -16.140281733661322],
            [-71.9825592315433, -16.139182983660326],
            [-71.98715973154773, -16.138034858659296],
            [-71.99623110655651, -16.13861273365987],
            [-72.00160223156168, -16.144985233665835],
            [-72.00306698156308, -16.147178608667883],
            [-72.0048217315648, -16.149053608669636],
            [-72.00513460656508, -16.151502608671926],
            [-72.00550848156544, -16.152189233672566],
            [-72.00625610656617, -16.15259748367295],
            [-72.00891110656875, -16.157234233677276],
            [-72.01382448157348, -16.16189760868165],
            [-72.0166854815762, -16.162656733682365],
            [-72.01799010657751, -16.163343483683022],
            [-72.02072910658015, -16.16540910868495],
            [-72.0250778565843, -16.168687858688035],
            [-72.02731323158648, -16.169420233688726],
            [-72.029914856589, -16.17134285869052],
            [-72.03068548158973, -16.174102733693097],
            [-72.03270723159167, -16.177822108696574],
            [-72.03426360659316, -16.180086108698692],
            [-72.03779598159659, -16.183023358701444],
            [-72.03973398159845, -16.184797233703094],
            [-72.04741673160584, -16.193578733711313],
            [-72.05139173160968, -16.19895935871635],
            [-72.05309298161131, -16.20145410871867],
            [-72.0541076066123, -16.203151733720254],
            [-72.05660248161468, -16.20748898372431],
            [-72.05731198161537, -16.20615010872305],
            [-72.05764773161567, -16.205337483722307],
            [-72.06185148161975, -16.201854733719088],
            [-72.06433873162214, -16.200025608717407],
            [-72.06848148162614, -16.199367483716813],
            [-72.06887060662652, -16.19920160871665],
            [-72.07469173163211, -16.195569983713305],
            [-72.07815548163543, -16.19226260871024],
            [-72.07944498163668, -16.191305108709354],
            [-72.08405298164112, -16.190010108708172],
            [-72.08486173164192, -16.19031523370845],
            [-72.08723448164417, -16.20115085871857],
            [-72.07331085663077, -16.21145060872808],
            [-72.07402798163145, -16.21825223373442],
            [-72.07651523163386, -16.22180173373773],
            [-72.07630923163367, -16.22087285873686],
            [-72.0796889816369, -16.22526735874098],
            [-72.08425135664127, -16.239040358753815],
            [-72.09502410665162, -16.260032608773415],
            [-72.10014348165654, -16.26764873378053],
            [-72.1034088566597, -16.272737483785274],
            [-72.1128158566687, -16.28365710879548],
            [-72.1201934816758, -16.297615108808518],
            [-72.12532810668071, -16.303482108813988],
            [-72.12939448168461, -16.308544108818726],
            [-72.1310424816862, -16.310846358820875],
            [-72.13383485668888, -16.317449608827037],
            [-72.13598648169092, -16.323734233832898],
            [-72.1368789816918, -16.325286858834335],
            [-72.13758848169246, -16.32665823383563],
            [-72.13841248169325, -16.328372983837223],
            [-72.13887785669372, -16.329420108838203],
            [-72.14336398169803, -16.33821110884641],
            [-72.14437098169898, -16.3430366088509],
            [-72.14431760669892, -16.34871298385619],
            [-72.14582823170036, -16.35152810885881],
            [-72.1478653567023, -16.35400960886114],
            [-72.14904785670345, -16.355817733862814],
            [-72.15528110670942, -16.362396233868964],
            [-72.15697473171106, -16.364580108871014],
            [-72.16295623171679, -16.375232733880946],
            [-72.16457373171835, -16.37951848388495],
            [-72.16706848172072, -16.385751733890757],
            [-72.17442323172776, -16.398277233902455],
            [-72.18050385673358, -16.408594108912084],
            [-72.18334198173632, -16.4153576089184],
            [-72.19961548175189, -16.457708358957877],
            [-72.19950860675176, -16.45872110895883],
            [-72.1993333567516, -16.483812358982185],
            [-72.25422673180408, -16.533414859028557],
            [-72.28870385683699, -16.58620648407783],
            [-72.43433385697567, -16.60572248409641],
            [-72.453636106994, -16.558967484052936],
            [-72.49843598203647, -16.571594234064776],
            [-72.58299260711654, -16.559476859053653],
            [-72.57157135710574, -16.5120754840095],
            [-72.5119552320493, -16.49141698399016],
            [-72.48471073202349, -16.451091733952552],
            [-72.46635435700613, -16.383155858889257],
            [-72.4424971069835, -16.331415233841035],
            [-72.37502285691939, -16.28212360879497],
            [-72.35475160690014, -16.158090608679437],
            [-72.27404023182321, -16.138677608661133],
            [-72.25285348180299, -16.094285983619727],
            [-72.24127198179201, -15.949439108484839],
            [-72.29653173184478, -15.92103673345857],
            [-72.2961883568445, -15.830810608374563],
            [-72.27619173182543, -15.78339285833036],
            [-72.20906835676142, -15.681509983235294],
            [-72.1631546067175, -15.659712733214834],
            [-72.10131073165823, -15.603425983162188],
            [-72.00339510656407, -15.581884358141679],
            [-72.00102235656192, -15.461908358029987],
            [-71.98410035654577, -15.33786873291443],
            [-71.94597623150912, -15.226960232810981],
            [-71.90141298146615, -15.170798357758466],
            [-71.96975710653217, -15.135966357726417],
            [-71.94702910651031, -15.110272357702378],
            [-71.98238373154443, -15.063702607659204],
            [-71.9519189815152, -14.988465982589016],
            [-71.91380310647845, -14.957285982559789],
            [-71.93957523150338, -14.919787357525014],
            [-71.95185085651534, -14.837326107448318],
            [-71.9174804814822, -14.831417982442627],
            [-71.89389860645952, -14.78960123240359],
            [-71.87667073144286, -14.800437982413575],
            [-71.85208898141893, -14.888090107495014],
            [-71.79762273136612, -14.895981732502001],
            [-71.74595998131583, -14.939869982542472],
            [-71.7674561063366, -15.010523982608396],
            [-71.84931948141596, -15.045089732641125],
            [-71.80295560637094, -15.06744098266162],
            [-71.66795348123938, -15.091896107683313],
            [-71.63602448120798, -15.13913923272698],
            [-71.58760073116052, -15.125595107713895],
            [-71.57062723114409, -15.061207232653802],
            [-71.51171110608603, -15.079447732670113],
            [-71.48587035606062, -15.059104857650878],
            [-71.50704960608184, -14.97627548257407],
            [-71.43081660600647, -14.998084107593396],
            [-71.37268048094839, -15.079110107667935],
            [-71.31562810589134, -15.120224982705263],
            [-71.26022523083627, -15.076908107664018],
            [-71.20684048078279, -15.071888982658333],
            [-71.14357760571875, -15.120560607702265],
            [-71.18038935575547, -15.162620482742165],
            [-71.16032860573486, -15.207768232783705],
            [-71.2018356057764, -15.245445232819618],
            [-71.16539760573929, -15.288882232859228],
            [-71.15115360572422, -15.363941232928694],
            [-71.1743316057472, -15.424456607985453],
            [-71.07174685564274, -15.449273108006102],
            [-71.03897098060976, -15.405165732964244],
            [-70.99395748056315, -15.454841608009149],
            [-71.01517485558448, -15.489165358041653],
            [-71.00817873057646, -15.56396860811096],
            [-70.91798398048242, -15.643800733182328],
            [-70.90013123046293, -15.712146733245207],
            [-70.93614198049937, -15.784839608313934],
            [-70.890541105451, -15.851253483374048],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 12,
        FIRST_IDPR: '0603',
        NOMBPROV: 'CELENDIN',
        FIRST_NOMB: 'CAJAMARCA',
        LAST_DCTO: 'LEY',
        LAST_LEY: '7855',
        FIRST_FECH: '27/12/1923',
        LAST_FECHA: '16/10/1933',
        MIN_SHAPE_: 265469.006981,
        ha: 265469.01,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.45347761259796, -6.834194974995317],
            [-78.40197486255065, -6.815057349977717],
            [-78.404842737553, -6.711530099881299],
            [-78.38546873753515, -6.685251974856903],
            [-78.41297261256017, -6.59585747477357],
            [-78.35260286250461, -6.564633224744723],
            [-78.36545511251622, -6.472150474658569],
            [-78.32134573747554, -6.449972599638074],
            [-78.27793186243534, -6.38896147458141],
            [-78.19002948735395, -6.457016099644991],
            [-78.13602773730382, -6.526301974709643],
            [-78.10759911227748, -6.642332224817756],
            [-78.05819536223143, -6.676150224849358],
            [-78.03586886221062, -6.743240724911875],
            [-78.01406386219031, -6.822303224985542],
            [-77.99950648717684, -6.970531600123599],
            [-77.9511497371316, -7.064613475211298],
            [-78.01594536219234, -7.096804725241149],
            [-78.05652723723034, -7.134790975276447],
            [-78.18369448734911, -7.149147475289518],
            [-78.21945786238228, -7.071584850217192],
            [-78.29145161244907, -7.023271975172009],
            [-78.34051061249431, -6.954177225107491],
            [-78.38619336253639, -6.925795600080896],
            [-78.42663586257362, -6.931160100085711],
            [-78.45347761259796, -6.834194974995317],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 21,
        FIRST_IDPR: '0101',
        NOMBPROV: 'CHACHAPOYAS',
        FIRST_NOMB: 'AMAZONAS',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: 'EPOCA INDEP.',
        LAST_FECHA: 'EPOCA INDEP.',
        MIN_SHAPE_: 298037.227229,
        ha: 298037.23,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.62666411182686, -6.612740224790802],
            [-77.69336323688951, -6.67852534985203],
            [-77.74560648693856, -6.665158224839535],
            [-77.74671373693963, -6.751838474920241],
            [-77.76171761195374, -6.807748349972326],
            [-77.72332486191772, -6.893455975052171],
            [-77.75893361195118, -6.964507850118296],
            [-77.84586473703281, -6.976398100129279],
            [-77.88034173706515, -6.962503850116303],
            [-77.92752798710941, -6.986709225138778],
            [-77.99950648717684, -6.970531600123599],
            [-78.01406386219031, -6.822303224985542],
            [-78.03586886221062, -6.743240724911875],
            [-77.9359668621171, -6.689789099862256],
            [-77.85315823703948, -6.67654097485001],
            [-77.8022478619917, -6.65570309983068],
            [-77.85140823703775, -6.508980474693979],
            [-77.86258398704817, -6.445498099634862],
            [-77.91988861210179, -6.368501974563086],
            [-77.94201173712241, -6.268826224470213],
            [-77.90203123708498, -6.19934759940557],
            [-77.89094923707454, -6.102691349315582],
            [-77.82187498700986, -6.130499974341552],
            [-77.80623436199514, -6.031873099249705],
            [-77.77450323696536, -5.97431684919612],
            [-77.71725786191168, -5.944195349168114],
            [-77.67167486186892, -5.974104349195999],
            [-77.59345636179553, -6.009347349228896],
            [-77.53380886173956, -6.063371349279222],
            [-77.50992061171713, -6.086494974300795],
            [-77.49976811170764, -6.153001974362732],
            [-77.46981148667952, -6.182348599390064],
            [-77.5297597367358, -6.245707974449046],
            [-77.56788286177161, -6.309462599508397],
            [-77.61757348681823, -6.28075597448165],
            [-77.63520323683478, -6.316106599514543],
            [-77.6990278618947, -6.335594599532665],
            [-77.7522632369447, -6.403551849595889],
            [-77.73260473692625, -6.453146474642089],
            [-77.72617823692028, -6.552055599734221],
            [-77.62666411182686, -6.612740224790802],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 6,
        FIRST_IDPR: '1203',
        NOMBPROV: 'CHANCHAMAYO',
        FIRST_NOMB: 'JUNIN',
        LAST_DCTO: 'LEY',
        LAST_LEY: '24445',
        FIRST_FECH: '27/01/1871',
        LAST_FECHA: '14/01/1986',
        MIN_SHAPE_: 467686.179106,
        ha: 467686.18,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.434789609779, -11.258207854117398],
            [-75.50672660984601, -11.22407622908561],
            [-75.57930998491362, -11.228729979089941],
            [-75.58837260992206, -11.17712797904191],
            [-75.63767598496798, -11.164202104029856],
            [-75.66738985999567, -11.09827335396845],
            [-75.58439985991835, -11.051399353924793],
            [-75.5277702348656, -11.050058353923555],
            [-75.46411523480633, -11.024212853899504],
            [-75.42349935976851, -10.936894353818179],
            [-75.45335010979628, -10.900295353784081],
            [-75.40637585975254, -10.856126478742958],
            [-75.31730423466959, -10.863567228749902],
            [-75.2623599846184, -10.825289978714236],
            [-75.24404998460138, -10.766949978659905],
            [-75.19101673455198, -10.715708353612175],
            [-75.11980498448567, -10.730220103625706],
            [-75.07431648444329, -10.6658104785657],
            [-75.0166587343896, -10.677134353576264],
            [-74.97925760935478, -10.746886853641232],
            [-74.95648160933355, -10.7327913536281],
            [-74.89330760927471, -10.769133228661927],
            [-74.83131098421698, -10.780504103672547],
            [-74.79180285918018, -10.831125228719678],
            [-74.73268560912513, -10.83572647872396],
            [-74.70017385909485, -10.877892728763243],
            [-74.6974399840923, -10.955359978835384],
            [-74.62261798402263, -10.975863228854484],
            [-74.64742098404571, -11.009049603885373],
            [-74.72964085912228, -11.017230353893014],
            [-74.74593998413746, -11.067429978939735],
            [-74.87693998425947, -11.155669979021908],
            [-74.91104998429124, -11.213079979075378],
            [-74.89121998427278, -11.27062997912897],
            [-74.96823998434449, -11.300299854156622],
            [-75.04735135941817, -11.301642354157856],
            [-75.02840423440053, -11.350232479203106],
            [-75.30658998465962, -11.300849979157118],
            [-75.434789609779, -11.258207854117398],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 3,
        FIRST_IDPR: '1304',
        NOMBPROV: 'CHEPEN',
        FIRST_NOMB: 'LA LIBERTAD',
        LAST_DCTO: 'LEY',
        LAST_LEY: '9222',
        FIRST_FECH: '18/04/1935',
        LAST_FECHA: '05/12/1940',
        MIN_SHAPE_: 115282.3327,
        ha: 115282.33,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.32258598834301, -7.025683600163807],
            [-79.3053012383318, -7.147146225277148],
            [-79.25180373829116, -7.198348600325831],
            [-79.28262698831634, -7.275060600396576],
            [-79.36927536338314, -7.324124975440243],
            [-79.40396673840843, -7.302349600419152],
            [-79.45879298844643, -7.225460975346243],
            [-79.54180548850435, -7.253925475370274],
            [-79.56108786351807, -7.288546850401847],
            [-79.59972261354326, -7.28574810039795],
            [-79.69062111359632, -7.177126975293759],
            [-79.49638698846724, -6.971710850109365],
            [-79.45500536343809, -6.946313600086884],
            [-79.40076173839965, -6.956927725098115],
            [-79.32258598834301, -7.025683600163807],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 20,
        FIRST_IDPR: '1401',
        NOMBPROV: 'CHICLAYO',
        FIRST_NOMB: 'LAMBAYEQUE',
        LAST_DCTO: 'DEC.',
        LAST_LEY: 'S/N',
        FIRST_FECH: '19/12/1906',
        LAST_FECHA: '30/07/1840',
        MIN_SHAPE_: 334599.052528,
        ha: 334599.05,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.4338808634145, -6.481265849655182],
            [-79.40327536339404, -6.54073234971116],
            [-79.36698948836815, -6.549522224720154],
            [-79.29641586331785, -6.675671724838891],
            [-79.22906036326614, -6.671405974836204],
            [-79.21379098825454, -6.695630849858996],
            [-79.14156211319724, -6.70154172486567],
            [-79.12084773818134, -6.776969974936145],
            [-79.20708786325088, -6.82143947497614],
            [-79.22907323826865, -6.86046622501205],
            [-79.2627403632948, -6.859156225010184],
            [-79.3110088633323, -6.90134172504846],
            [-79.2966343633229, -6.999833725140353],
            [-79.32258598834301, -7.025683600163807],
            [-79.40076173839965, -6.956927725098115],
            [-79.45500536343809, -6.946313600086884],
            [-79.49638698846724, -6.971710850109365],
            [-79.69062111359632, -7.177126975293759],
            [-79.68346673859054, -7.12489647524554],
            [-79.73983786362068, -7.057540975180803],
            [-79.93184573870595, -6.873306475000978],
            [-79.94239648870789, -6.820499974951464],
            [-79.89342573868434, -6.728828099868956],
            [-79.82948048865543, -6.721712849865331],
            [-79.78142973863051, -6.679587849828297],
            [-79.69775936358603, -6.684727599836311],
            [-79.62542461354424, -6.701821724854704],
            [-79.59267136352221, -6.624335599783799],
            [-79.64147536355051, -6.572899224734435],
            [-79.62424061353877, -6.513258099679602],
            [-79.56287848850073, -6.503837224672732],
            [-79.49116698845434, -6.520306974690023],
            [-79.4338808634145, -6.481265849655182],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 11,
        FIRST_IDPR: '1102',
        NOMBPROV: 'CHINCHA',
        FIRST_NOMB: 'ICA',
        LAST_DCTO: 'LEY',
        LAST_LEY: '11620',
        FIRST_FECH: 'EPOCA INDEP.',
        LAST_FECHA: '22/09/1951',
        MIN_SHAPE_: 290803.738705,
        ha: 290803.74,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.68928098501604, -13.439670106148949],
            [-75.81446098513263, -13.6195624813165],
            [-75.86384961017863, -13.611429731308924],
            [-75.93556448524544, -13.631050731327193],
            [-75.98696873529335, -13.571644481271868],
            [-76.08849023538794, -13.564482481265198],
            [-76.19161323548406, -13.59449985629312],
            [-76.19146873548391, -13.4239336061343],
            [-76.24479286053362, -13.323513731040771],
            [-76.2073199854987, -13.306099981024573],
            [-76.15597061045082, -13.19372898091991],
            [-76.01193673531661, -13.08549935581913],
            [-75.96919923527678, -13.041638730778281],
            [-75.96140236026953, -13.001378855740784],
            [-75.93768286024742, -12.991324855731415],
            [-75.80206248512107, -13.060429730795773],
            [-75.76215848508393, -13.018888605757096],
            [-75.72546436004973, -13.038124980775018],
            [-75.67036123499841, -13.022611730760554],
            [-75.66323598499179, -12.994035730733955],
            [-75.61439310994628, -12.964933355706847],
            [-75.62860823495953, -13.05706673079265],
            [-75.69496248502132, -13.074792480809164],
            [-75.7187682350435, -13.109764730841723],
            [-75.69377398502021, -13.147504355876876],
            [-75.70633673503193, -13.186761605913443],
            [-75.70648148503207, -13.288286856007977],
            [-75.79020536011004, -13.342509856058495],
            [-75.80598523512474, -13.401433356113353],
            [-75.71216286003734, -13.416420231127317],
            [-75.68928098501604, -13.439670106148949],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 8,
        FIRST_IDPR: '0306',
        NOMBPROV: 'CHINCHEROS',
        FIRST_NOMB: 'APURIMAC',
        LAST_DCTO: 'LEY',
        LAST_LEY: '24167',
        FIRST_FECH: '19/11/1985',
        LAST_FECHA: '12/06/1985',
        MIN_SHAPE_: 150788.010794,
        ha: 150788.01,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.44538985792552, -13.430359981140288],
            [-73.48346485796107, -13.506310606211006],
            [-73.49207560796911, -13.57732948127714],
            [-73.52844123300305, -13.615618106312779],
            [-73.57040298304223, -13.596258356294763],
            [-73.62896098309682, -13.736580106425436],
            [-73.67371348313856, -13.730739356420004],
            [-73.74361973320376, -13.655562481349993],
            [-73.79192085824879, -13.640959606336413],
            [-73.81424373326959, -13.509212481213714],
            [-73.83348798328753, -13.471785856178844],
            [-73.83802935829173, -13.384433606097497],
            [-73.82282023327755, -13.285052106004972],
            [-73.79702935825354, -13.199943355925692],
            [-73.75643960821569, -13.170714855898474],
            [-73.72622260818753, -13.268662105989717],
            [-73.64194923310896, -13.299478481018385],
            [-73.57175973304346, -13.34183398105783],
            [-73.4809101079587, -13.431371106141212],
            [-73.44538985792552, -13.430359981140288],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 19,
        FIRST_IDPR: '0604',
        NOMBPROV: 'CHOTA',
        FIRST_NOMB: 'CAJAMARCA',
        LAST_DCTO: 'LEY',
        LAST_LEY: 'S/N',
        FIRST_FECH: '02/01/1857',
        LAST_FECHA: '02/01/1857',
        MIN_SHAPE_: 377270.637208,
        ha: 377270.64,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.22906036326614, -6.671405974836204],
            [-79.29641586331785, -6.675671724838891],
            [-79.36698948836815, -6.549522224720154],
            [-79.40327536339404, -6.54073234971116],
            [-79.4338808634145, -6.481265849655182],
            [-79.42288823840632, -6.45188197462814],
            [-79.45348986342646, -6.396841599576239],
            [-79.42265773840367, -6.310430099496656],
            [-79.35356136335452, -6.304291224492455],
            [-79.32178936333071, -6.263057974454757],
            [-79.24916748827576, -6.22177684941768],
            [-79.22228186325508, -6.21198334940901],
            [-79.19915823823787, -6.270406224463727],
            [-79.11965236317523, -6.291197224484258],
            [-79.08561798814803, -6.320028849511567],
            [-78.98685161306634, -6.295914099490316],
            [-78.91776761300856, -6.368191349558296],
            [-78.89296261298779, -6.431175474617171],
            [-78.79580111290367, -6.441442599627591],
            [-78.83470898793792, -6.495827974677859],
            [-78.78668748789627, -6.537679724717246],
            [-78.75687236287003, -6.535601099715534],
            [-78.74534961285931, -6.424431599612137],
            [-78.71132573782906, -6.391899599582096],
            [-78.66773823778996, -6.315040974510858],
            [-78.5150566126525, -6.2898222244882],
            [-78.5096777376475, -6.245755849447199],
            [-78.53407036266944, -6.191652349396705],
            [-78.50906423764646, -6.085308349297821],
            [-78.41972811256537, -6.188424599394203],
            [-78.33538611248822, -6.318679474515776],
            [-78.33373498748678, -6.350977724545862],
            [-78.27793186243534, -6.38896147458141],
            [-78.32134573747554, -6.449972599638074],
            [-78.36545511251622, -6.472150474658569],
            [-78.35260286250461, -6.564633224744723],
            [-78.41297261256017, -6.59585747477357],
            [-78.45827536260157, -6.560466849740431],
            [-78.52965898766678, -6.609122849785409],
            [-78.56040048769461, -6.597847724774755],
            [-78.64370448777002, -6.675643599846694],
            [-78.72309361284063, -6.629455474803158],
            [-78.75801948787156, -6.632123099805367],
            [-78.77751361288836, -6.564289099742084],
            [-78.82326261292815, -6.525738974705821],
            [-78.95234373803912, -6.527244099705961],
            [-78.98917573807005, -6.512015599691376],
            [-79.05594336312562, -6.516363224694615],
            [-79.11840236317775, -6.628496099798054],
            [-79.24023898827411, -6.617656599785997],
            [-79.22906036326614, -6.671405974836204],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 7,
        FIRST_IDPR: '2104',
        NOMBPROV: 'CHUCUITO',
        FIRST_NOMB: 'PUNO',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: 'EPOCA INDEP.',
        LAST_FECHA: 'EPOCA INDEP.',
        MIN_SHAPE_: 397892.169056,
        ha: 397892.17,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.56279847885737, -17.174983234454626],
            [-69.60287060391671, -17.097457734392435],
            [-69.53476710383353, -17.060860359343863],
            [-69.53573485384491, -16.96768860925884],
            [-69.56822785389123, -16.91781510922047],
            [-69.56587110389718, -16.833443359142695],
            [-69.51148635383984, -16.74239773404715],
            [-69.54214972888553, -16.670604858988366],
            [-69.50174647883733, -16.66059348397004],
            [-69.48783785382457, -16.624943358934168],
            [-69.48828247883684, -16.526352108844044],
            [-69.47159347882503, -16.45870110877822],
            [-69.48380010384318, -16.42874373375366],
            [-69.55577147892669, -16.445369108784973],
            [-69.66070122905327, -16.425925733788343],
            [-69.68401947908491, -16.38255473375288],
            [-69.64530860404624, -16.311124983680013],
            [-69.64453322904534, -16.31113673367987],
            [-69.58639735398113, -16.274736483634953],
            [-69.57191610397513, -16.174275358539802],
            [-69.5138888539078, -16.17996923353262],
            [-69.49432422888196, -16.208061358553987],
            [-69.42646647880788, -16.178819108511238],
            [-69.35804685371996, -16.250136733559064],
            [-69.29527197864779, -16.253902608545282],
            [-69.27868685362338, -16.289259358572767],
            [-69.20017160353875, -16.26418623352617],
            [-69.1909384785196, -16.314549983569098],
            [-69.14194035346148, -16.330993733567965],
            [-69.04295097833312, -16.42040173361385],
            [-69.08401397837085, -16.462650483667346],
            [-69.08456060336465, -16.497131608698847],
            [-69.03692135329733, -16.559710608737852],
            [-69.03130272828122, -16.605808608777465],
            [-68.99610160322958, -16.656576233809584],
            [-69.04528922828054, -16.688328108857586],
            [-69.17001372842259, -16.72753510893787],
            [-69.21924472846459, -16.836130984052737],
            [-69.30467497855915, -16.915840984151494],
            [-69.34363872859839, -16.98686785922744],
            [-69.39463085365857, -17.02387498427522],
            [-69.37373885362521, -17.073123359314454],
            [-69.40626847866365, -17.10028948434804],
            [-69.4491293537193, -17.097750734356875],
            [-69.56279847885737, -17.174983234454626],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 8,
        FIRST_IDPR: '0807',
        NOMBPROV: 'CHUMBIVILCAS',
        FIRST_NOMB: 'CUSCO',
        LAST_DCTO: 'LEY',
        LAST_LEY: 'S/N',
        FIRST_FECH: 'EPOCA INDEP.',
        LAST_FECHA: '02/01/1857',
        MIN_SHAPE_: 535593.198221,
        ha: 535593.2,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.69970898127299, -14.116285106775605],
            [-71.59572660617184, -14.149886731805992],
            [-71.59238810616858, -14.149379606805505],
            [-71.58823210616454, -14.149115106805215],
            [-71.58751910616384, -14.149326356805386],
            [-71.58739760616375, -14.14940685680547],
            [-71.58569185616206, -14.150352981806343],
            [-71.58380085616021, -14.151742981807631],
            [-71.5822499811587, -14.15396885680967],
            [-71.5818411061583, -14.154653481810312],
            [-71.58116235615762, -14.155915606811483],
            [-71.58106760615755, -14.155990981811538],
            [-71.58084748115733, -14.156104606811665],
            [-71.5807302311572, -14.156149981811694],
            [-71.57964423115614, -14.156629981812118],
            [-71.57886998115538, -14.157044856812508],
            [-71.57774810615429, -14.157560481812967],
            [-71.57595198115254, -14.158481856813824],
            [-71.57544898115205, -14.158735481814043],
            [-71.57505473115167, -14.158897106814186],
            [-71.57485573115147, -14.158934481814223],
            [-71.57408998115073, -14.158898606814166],
            [-71.57359260615026, -14.158965856814257],
            [-71.57304860614971, -14.15891860681419],
            [-71.57051385614726, -14.158256481813549],
            [-71.57003210614678, -14.158111481813414],
            [-71.56903210614578, -14.158192856813494],
            [-71.56886060614562, -14.158247606813521],
            [-71.56758585614438, -14.158862231814071],
            [-71.56708998114391, -14.159070856814271],
            [-71.5646154811415, -14.160537606815632],
            [-71.56276723113969, -14.161706106816691],
            [-71.56026323113724, -14.163782856818596],
            [-71.55905773113605, -14.165324481820013],
            [-71.5585986061356, -14.166407481821027],
            [-71.55841473113541, -14.168176731822658],
            [-71.55730310613431, -14.16996898182432],
            [-71.55609110613314, -14.171002606825265],
            [-71.55594810613297, -14.171171981825422],
            [-71.55564473113269, -14.171590606825827],
            [-71.55414173113121, -14.172870231827002],
            [-71.55363023113073, -14.173020981827117],
            [-71.5533072311304, -14.173179481827262],
            [-71.55140323112855, -14.1734384818275],
            [-71.5511618561283, -14.173599356827637],
            [-71.54987835612705, -14.175195356829109],
            [-71.54966210612683, -14.175710231829584],
            [-71.54929060612648, -14.176518231830322],
            [-71.5490248561262, -14.177457731831225],
            [-71.54893148112613, -14.17802435683174],
            [-71.54888285612607, -14.178224606831906],
            [-71.54853010612572, -14.179000731832632],
            [-71.54766335612487, -14.180891856834402],
            [-71.54753510612474, -14.181564856835028],
            [-71.54735185612456, -14.182194231835618],
            [-71.54653260612375, -14.183872981837167],
            [-71.54408198112135, -14.186708981839788],
            [-71.543710481121, -14.187932731840897],
            [-71.54367110612094, -14.188454356841376],
            [-71.54358848112085, -14.18957735684244],
            [-71.54426023112151, -14.192963356845603],
            [-71.5443632311216, -14.194535106847063],
            [-71.54311498112035, -14.197110981849463],
            [-71.54296210612021, -14.19720985684954],
            [-71.54183485611911, -14.19807010685033],
            [-71.54169048111898, -14.198133481850373],
            [-71.5401808561175, -14.198838856851024],
            [-71.5397763561171, -14.199125981851305],
            [-71.53867860611601, -14.200206856852258],
            [-71.53846610611579, -14.200659731852689],
            [-71.53684673111421, -14.203327856855168],
            [-71.53613185611353, -14.20461685685636],
            [-71.53455198111196, -14.208388856859841],
            [-71.53401423111144, -14.209331231860709],
            [-71.5334686061109, -14.20995560686129],
            [-71.53059860610809, -14.212592731863714],
            [-71.52987598110735, -14.213528981864577],
            [-71.52938660610688, -14.214623231865593],
            [-71.52932885610683, -14.214938981865883],
            [-71.52905085610656, -14.216417606867276],
            [-71.5280721061056, -14.218433856869131],
            [-71.52762523110516, -14.218986606869642],
            [-71.52682323110434, -14.219807981870378],
            [-71.52529660610286, -14.22062810687115],
            [-71.52500798110256, -14.220746231871269],
            [-71.52392723110152, -14.22093435687142],
            [-71.52377835610136, -14.221024106871495],
            [-71.52337723110098, -14.221169981871626],
            [-71.5224653561001, -14.221886481872277],
            [-71.52180460609942, -14.224534231874735],
            [-71.52177398109939, -14.225728856875852],
            [-71.52179948109942, -14.226806606876846],
            [-71.52147710609911, -14.228374106878318],
            [-71.52087973109849, -14.230483481880277],
            [-71.5211884810988, -14.232277856881934],
            [-71.52138098109897, -14.23287323188249],
            [-71.52173298109932, -14.233885481883432],
            [-71.5206742310983, -14.236476981885827],
            [-71.52031560609792, -14.238053606887322],
            [-71.51954435609717, -14.241643606890634],
            [-71.51512410609278, -14.252268231900484],
            [-71.51515235609283, -14.252962856901137],
            [-71.51973823109729, -14.258999981906781],
            [-71.5234472311009, -14.264253856911727],
            [-71.52685348110421, -14.265980481913369],
            [-71.52906135610638, -14.264863606912352],
            [-71.53048523110778, -14.264598481912108],
            [-71.53121898110848, -14.264814606912326],
            [-71.53640648111354, -14.265228606912755],
            [-71.54378110612076, -14.263500606911228],
            [-71.54911723112598, -14.26238473191025],
            [-71.54900610612584, -14.266772981914311],
            [-71.55016723112693, -14.279861981926523],
            [-71.5514867311282, -14.28302873192947],
            [-71.55217285612888, -14.286670481932877],
            [-71.55412573113074, -14.291262231937157],
            [-71.55449510613113, -14.292106731937958],
            [-71.5538841061305, -14.294663356940317],
            [-71.55007810612678, -14.300919231946123],
            [-71.55436535613093, -14.309799981954415],
            [-71.55677148113325, -14.31185610695637],
            [-71.55751510613399, -14.31273110695717],
            [-71.5580401061345, -14.315837106960066],
            [-71.55812760613455, -14.320211856964145],
            [-71.55611523113258, -14.322311731966082],
            [-71.5541028561306, -14.327911356971265],
            [-71.55681523113323, -14.3307112319739],
            [-71.55808385613446, -14.334473481977431],
            [-71.55852135613488, -14.335173481978083],
            [-71.55935248113568, -14.33941698198203],
            [-71.56022748113651, -14.34414160698644],
            [-71.56013998113644, -14.345760231987942],
            [-71.55506523113144, -14.3489976069909],
            [-71.55432160613073, -14.349741356991593],
            [-71.55484648113124, -14.353153481994754],
            [-71.55522935613156, -14.363474982004364],
            [-71.55636885613265, -14.364439107005293],
            [-71.55987498113609, -14.365753982006526],
            [-71.5675884811436, -14.372152607012577],
            [-71.56820210614418, -14.373818107014149],
            [-71.56785148114383, -14.377324232017383],
            [-71.56452060614059, -14.379515482019391],
            [-71.55318723112947, -14.387463232026676],
            [-71.5534198561297, -14.38986648202893],
            [-71.55504785613125, -14.395138107033835],
            [-71.55179960612807, -14.40187485704008],
            [-71.55117935612743, -14.40280523204094],
            [-71.54974610612604, -14.405521482043449],
            [-71.55117935612742, -14.407332482045135],
            [-71.55185748112808, -14.407884732045671],
            [-71.55316985612936, -14.409378232047077],
            [-71.55441273113058, -14.410741357048344],
            [-71.5621106061381, -14.414149232051594],
            [-71.56291248113888, -14.414510107051967],
            [-71.56555860614147, -14.414309607051775],
            [-71.5673227311432, -14.414389857051871],
            [-71.57619535615183, -14.41849998205579],
            [-71.57642385615206, -14.419762357056962],
            [-71.5761432311518, -14.420644357057794],
            [-71.58155585615704, -14.429384732065975],
            [-71.58227748115773, -14.429905857066467],
            [-71.5859259811613, -14.431790232068256],
            [-71.58636698116173, -14.435478857071706],
            [-71.58720898116253, -14.437082607073197],
            [-71.59418523116932, -14.438966982075012],
            [-71.59807423117311, -14.440851357076808],
            [-71.60015910617516, -14.441813607077728],
            [-71.60781685618265, -14.44357773207943],
            [-71.60930035618406, -14.446344107082021],
            [-71.60883585618359, -14.448282107083804],
            [-71.609253856184, -14.45112810708646],
            [-71.60787910618264, -14.45569235709071],
            [-71.6094738561842, -14.457946982092816],
            [-71.609291731184, -14.459062357093844],
            [-71.60691473118165, -14.470442982104426],
            [-71.60626648118102, -14.472531857106354],
            [-71.60569010618046, -14.474116482107817],
            [-71.60496998117975, -14.477573857111029],
            [-71.60547410618024, -14.47858223211197],
            [-71.61620648119064, -14.49003498212275],
            [-71.62319335619745, -14.497237857129493],
            [-71.62813810620229, -14.499483607131648],
            [-71.63790410621179, -14.497498607129886],
            [-71.6444942312182, -14.500833357133061],
            [-71.64618660621983, -14.507986232139725],
            [-71.64898248122255, -14.513063232144463],
            [-71.64959435622315, -14.515275107146536],
            [-71.64879423122237, -14.517863482148936],
            [-71.65138260622486, -14.52468760715529],
            [-71.65119435622465, -14.52859373215894],
            [-71.65048848122397, -14.529017232159317],
            [-71.64874723122226, -14.530664482160846],
            [-71.64775885622129, -14.532170482162238],
            [-71.66201310623511, -14.564533232192478],
            [-71.71239460628408, -14.582342482209471],
            [-71.76034373133041, -14.679334857300082],
            [-71.8020376063708, -14.70928960732827],
            [-71.85814698142512, -14.692313607312823],
            [-71.8806239814468, -14.71036960732975],
            [-71.89389860645952, -14.78960123240359],
            [-71.95863348152201, -14.72801585734659],
            [-71.9412154815053, -14.685324607306772],
            [-71.9879149815503, -14.632949857258227],
            [-72.04186248160205, -14.694371232315635],
            [-72.03425598159463, -14.811989732425122],
            [-72.04712673160691, -14.875062982483891],
            [-72.12869260668514, -14.83060173244281],
            [-72.14469910670047, -14.777712857393622],
            [-72.25228885680316, -14.803659482418112],
            [-72.31544485686328, -14.833863232446383],
            [-72.40647885694973, -14.827085482440271],
            [-72.47094735701083, -14.774774607391675],
            [-72.48612973202525, -14.712543482333754],
            [-72.47177123201166, -14.661398857286095],
            [-72.46169948200213, -14.605869607234386],
            [-72.50291023204115, -14.551661982183989],
            [-72.42746098196976, -14.479023482116217],
            [-72.39308198193714, -14.481849607118782],
            [-72.35762535690353, -14.435963857076006],
            [-72.31719923186513, -14.440689482080328],
            [-72.24950523180073, -14.40292623204499],
            [-72.23951560679126, -14.348472356994273],
            [-72.18710685674132, -14.313223606961294],
            [-72.0814882316405, -14.202302731857687],
            [-72.09519535665368, -14.173734356831133],
            [-72.05666985661685, -14.12881060678919],
            [-72.06790235662766, -14.076542981740534],
            [-72.0516738566122, -14.012386731680762],
            [-72.0556757316161, -13.934853481608597],
            [-71.96803898153209, -13.960337231631986],
            [-71.91022298147641, -14.059352356723892],
            [-71.90855073147465, -14.149336481807643],
            [-71.87699410644417, -14.200259731854871],
            [-71.7637159813348, -14.192424981846916],
            [-71.7484922313199, -14.266109356915388],
            [-71.6926601062657, -14.278734356926714],
            [-71.6768418562505, -14.222841856874588],
            [-71.69970898127299, -14.116285106775605],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 9,
        FIRST_IDPR: '1209',
        NOMBPROV: 'CHUPACA',
        FIRST_NOMB: 'JUNIN',
        LAST_DCTO: 'LEY',
        LAST_LEY: '9274',
        FIRST_FECH: '05/10/1854',
        LAST_FECHA: '08/01/1941',
        MIN_SHAPE_: 116365.682055,
        ha: 116365.68,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.55958998489525, -12.410719980190715],
            [-75.56409610989944, -12.33628898012142],
            [-75.66412435999263, -12.194053604988946],
            [-75.62752348495852, -12.141740229940213],
            [-75.5924082349258, -12.144669354942955],
            [-75.54669198488322, -12.183461104979084],
            [-75.51820998485671, -12.121729979921595],
            [-75.48661998482729, -12.111669979912225],
            [-75.4409037347847, -12.040350979845797],
            [-75.41472648476032, -12.04772022985268],
            [-75.33069473468207, -12.010312979817835],
            [-75.31851173467074, -12.041021479846426],
            [-75.23878623459647, -12.063067104866949],
            [-75.23184248459002, -12.120401854920356],
            [-75.26768910962339, -12.183168604978812],
            [-75.24444535960176, -12.241820355033438],
            [-75.31352685966608, -12.269804230059494],
            [-75.37665998472487, -12.339569980124484],
            [-75.4716099848133, -12.392279855173559],
            [-75.55958998489525, -12.410719980190715],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 10,
        FIRST_IDPR: '0905',
        NOMBPROV: 'CHURCAMPA',
        FIRST_NOMB: 'HUANCAVELICA',
        LAST_DCTO: 'LEY',
        LAST_LEY: '13841',
        FIRST_FECH: '22/06/1962',
        LAST_FECHA: '11/01/1962',
        MIN_SHAPE_: 121798.44124,
        ha: 121798.44,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.32361998374415, -12.832509980583527],
            [-74.3962773588118, -12.806066355558892],
            [-74.4656073588764, -12.804886730557799],
            [-74.54245898394798, -12.743378855500513],
            [-74.5444277339498, -12.722841855481406],
            [-74.63990035903872, -12.645996105409811],
            [-74.68646285908208, -12.589855480357544],
            [-74.69205473408726, -12.521859355294218],
            [-74.59733235899907, -12.466094605242283],
            [-74.59729498399905, -12.410015605190056],
            [-74.5759899839792, -12.373169980155753],
            [-74.54842998395353, -12.375140105157586],
            [-74.50353998391172, -12.449809980227132],
            [-74.42244998383619, -12.44520998022284],
            [-74.41511998382937, -12.490659980265168],
            [-74.34081998376016, -12.63445998039909],
            [-74.32752998374778, -12.681269980442671],
            [-74.34876998376757, -12.712429980471688],
            [-74.28919998371208, -12.758009980514156],
            [-74.29239998371506, -12.824759980576315],
            [-74.32361998374415, -12.832509980583527],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 15,
        FIRST_IDPR: '1202',
        NOMBPROV: 'CONCEPCION',
        FIRST_NOMB: 'JUNIN',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: '28/06/1955',
        LAST_FECHA: 'EPOCA INDEP.',
        MIN_SHAPE_: 224488.03638,
        ha: 224488.04,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.62752348495852, -12.141740229940213],
            [-75.69430473502072, -12.042691354848007],
            [-75.65652535998552, -12.008665979816314],
            [-75.58803710992173, -12.000380854808594],
            [-75.54335998488013, -12.027259979833623],
            [-75.48950998482998, -12.00745997981518],
            [-75.49828998483817, -11.968169979778573],
            [-75.39308198474016, -11.926054729739368],
            [-75.40099498474756, -11.874244479691125],
            [-75.31234010966499, -11.849788979668347],
            [-75.26244060961851, -11.79056335461319],
            [-75.20024998456057, -11.750639979576015],
            [-75.19229485955317, -11.70763472953596],
            [-75.22409810958278, -11.661176479492685],
            [-75.2026341095628, -11.599202104434974],
            [-75.16345948452631, -11.53622160437632],
            [-75.03561135940724, -11.472789104317235],
            [-74.99901273437315, -11.427013854274616],
            [-74.97293748434889, -11.438175979285017],
            [-74.91598310929585, -11.48821822933161],
            [-74.95414285933138, -11.503947354346256],
            [-74.97496173435076, -11.550761729389851],
            [-74.94148173431958, -11.56487347940299],
            [-74.91707885929686, -11.528412354369053],
            [-74.82024623420666, -11.588361979424883],
            [-74.72514173411811, -11.61399847944875],
            [-74.72101460911423, -11.664661104495925],
            [-74.76532698415552, -11.707609729535935],
            [-74.7699999841599, -11.762349979586928],
            [-74.83860998422375, -11.762869979587409],
            [-74.8759946092586, -11.830151854650053],
            [-74.92148623430096, -11.846087104664909],
            [-75.01560373438862, -11.821251854641769],
            [-75.06248585943227, -11.888961104704826],
            [-75.09218698445993, -11.834144229653788],
            [-75.12928173449448, -11.842238854661328],
            [-75.17239848453464, -11.797658229619795],
            [-75.25070910960756, -11.80993560463121],
            [-75.31277535966537, -11.938003104750477],
            [-75.27920110963412, -11.980519479790097],
            [-75.33069473468207, -12.010312979817835],
            [-75.41472648476032, -12.04772022985268],
            [-75.4409037347847, -12.040350979845797],
            [-75.48661998482729, -12.111669979912225],
            [-75.51820998485671, -12.121729979921595],
            [-75.54669198488322, -12.183461104979084],
            [-75.5924082349258, -12.144669354942955],
            [-75.62752348495852, -12.141740229940213],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 8,
        FIRST_IDPR: '0406',
        NOMBPROV: 'CONDESUYOS',
        FIRST_NOMB: 'AREQUIPA',
        LAST_DCTO: 'LEY',
        LAST_LEY: 'S/N',
        FIRST_FECH: '26/11/1917',
        LAST_FECHA: '02/01/1857',
        MIN_SHAPE_: 685288.240588,
        ha: 685288.24,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.89389860645952, -14.78960123240359],
            [-71.9174804814822, -14.831417982442627],
            [-71.95185085651534, -14.837326107448318],
            [-72.00493623156638, -14.886319982494188],
            [-72.02683260658742, -14.876018482484689],
            [-72.07192248163064, -14.937385607542016],
            [-72.14771273170327, -14.881403857490167],
            [-72.2533569818041, -14.946263232550885],
            [-72.2654723568156, -15.043250107641207],
            [-72.31700135686462, -15.094398482688968],
            [-72.35557560690131, -15.09783748269227],
            [-72.37693023192156, -15.197789232785375],
            [-72.36466985690991, -15.241896607826419],
            [-72.41959385696205, -15.270532607853195],
            [-72.43841548197989, -15.301561357882123],
            [-72.42713910696916, -15.36814023294409],
            [-72.51167298204925, -15.398737857972753],
            [-72.50759123204539, -15.475064233043799],
            [-72.54360960707946, -15.534378983099105],
            [-72.60881810714106, -15.559671358122753],
            [-72.66069798219002, -15.545888858109976],
            [-72.72351835724925, -15.603018733163239],
            [-72.66332248219251, -15.71060185826335],
            [-72.62319185715462, -15.704617483257731],
            [-72.54256435707843, -15.81330960835882],
            [-72.51864623205577, -15.908813483447693],
            [-72.57911685711298, -15.937534358474547],
            [-72.60309598213561, -16.0172462335488],
            [-72.70158610722855, -16.008401608540698],
            [-72.7131152322394, -16.053790608582975],
            [-72.68720248221496, -16.119810108644415],
            [-72.68740860721516, -16.218505858736325],
            [-72.65108485718089, -16.247810358763562],
            [-72.65554048218509, -16.291116733803896],
            [-72.5936279821266, -16.430480983933577],
            [-72.6233521071547, -16.45700648395832],
            [-72.66989898219863, -16.424426983928047],
            [-72.72128298224709, -16.35523973386369],
            [-72.79792785731924, -16.308504108820255],
            [-72.81012723233073, -16.221448858739205],
            [-72.84122473235995, -16.174461358695478],
            [-72.84333798236194, -16.12618448365053],
            [-72.87580110739245, -16.057521983586614],
            [-72.9121474824266, -16.03020098356119],
            [-73.06491848256987, -16.018592858550463],
            [-73.1511688576506, -15.979671483514268],
            [-73.17404173267201, -15.912110358451356],
            [-73.23324585772738, -15.874050108415931],
            [-73.26244348275469, -15.825497608370718],
            [-73.23897548273274, -15.680068983235284],
            [-73.21730798271247, -15.64639473320392],
            [-73.22634123272094, -15.570303983133067],
            [-73.20541385770134, -15.510603858077449],
            [-73.11695860761859, -15.572004358134617],
            [-73.08298498258677, -15.561493858124813],
            [-72.99749773250666, -15.503433233070714],
            [-72.97207635748283, -15.406104982980057],
            [-72.93378448244691, -15.394479732969215],
            [-72.87067410738766, -15.404218732978242],
            [-72.83719635735619, -15.317884482897824],
            [-72.78847498231039, -15.274204232857112],
            [-72.73963160726443, -15.253165232837475],
            [-72.64055635717108, -15.235960982821348],
            [-72.50601960704395, -15.17798810776718],
            [-72.48750310702643, -15.111144107704913],
            [-72.52692410706375, -15.058617607656059],
            [-72.41031648195332, -14.993303357595046],
            [-72.39916223194277, -14.957482357561666],
            [-72.32307435687048, -14.930916732536772],
            [-72.30193323185041, -14.895695732503933],
            [-72.31544485686328, -14.833863232446383],
            [-72.25228885680316, -14.803659482418112],
            [-72.14469910670047, -14.777712857393622],
            [-72.12869260668514, -14.83060173244281],
            [-72.04712673160691, -14.875062982483891],
            [-72.03425598159463, -14.811989732425122],
            [-72.04186248160205, -14.694371232315635],
            [-71.9879149815503, -14.632949857258227],
            [-71.9412154815053, -14.685324607306772],
            [-71.95863348152201, -14.72801585734659],
            [-71.89389860645952, -14.78960123240359],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 3,
        FIRST_IDPR: '2402',
        NOMBPROV: 'CONTRALMIRANTE VILLAR',
        FIRST_NOMB: 'TUMBES',
        LAST_DCTO: 'LEY',
        LAST_LEY: '9667',
        FIRST_FECH: '03/04/2006',
        LAST_FECHA: '25/11/1942',
        MIN_SHAPE_: 214653.616043,
        ha: 214653.62,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.66645111342764, -4.21505884749484],
            [-80.70032036339256, -4.187800597466814],
            [-80.80330661327326, -4.175966847445973],
            [-80.8828222381637, -4.19280072245271],
            [-80.93638473807678, -4.178242222433163],
            [-80.97132223801057, -4.127117222382325],
            [-81.04141798787164, -4.087546847337321],
            [-80.98738848796587, -3.985711097251692],
            [-80.95654486301893, -3.95619922222855],
            [-80.83364648820607, -3.875757847168505],
            [-80.79461911324934, -3.760203097066657],
            [-80.75359961329742, -3.718169972031884],
            [-80.63518936341815, -3.667068346994626],
            [-80.60473261344286, -3.6371963469694],
            [-80.60384173844565, -3.670581847000141],
            [-80.55398186348808, -3.733548722061469],
            [-80.5880762384686, -3.834814847152125],
            [-80.54498411350045, -3.835913347156186],
            [-80.55056961349936, -3.88261909719875],
            [-80.5229976135252, -4.007034722315105],
            [-80.55865623850447, -4.056615222358096],
            [-80.58348498849182, -4.138908097431848],
            [-80.66645111342764, -4.21505884749484],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 8,
        FIRST_IDPR: '0605',
        NOMBPROV: 'CONTUMAZA',
        FIRST_NOMB: 'CAJAMARCA',
        LAST_DCTO: 'LEY',
        LAST_LEY: '15046',
        FIRST_FECH: '19/11/1888',
        LAST_FECHA: '05/06/1964',
        MIN_SHAPE_: 207828.799349,
        ha: 207828.8,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.91954298801639, -7.210945350342575],
            [-78.89762886299755, -7.228392600359065],
            [-78.74599411286411, -7.219331975352074],
            [-78.64984573777811, -7.255634725386574],
            [-78.62164986275276, -7.285361725414437],
            [-78.66540886279272, -7.379005600501299],
            [-78.69622661282062, -7.41336322553308],
            [-78.76012498787775, -7.401984350521967],
            [-78.84161936295011, -7.437617350554367],
            [-78.88668511298995, -7.484101600597149],
            [-78.8959083629984, -7.537437350646702],
            [-78.94434373804101, -7.598417975702858],
            [-78.94275261303984, -7.622670475725467],
            [-78.99214873808276, -7.660949350760457],
            [-79.04123248812398, -7.599887475702938],
            [-79.05984723813916, -7.546675475653174],
            [-79.06542811314307, -7.472708600584261],
            [-79.15272398821503, -7.439820100552244],
            [-79.18732936324247, -7.388664100504079],
            [-79.25633411329738, -7.381863725496412],
            [-79.36927536338314, -7.324124975440243],
            [-79.28262698831634, -7.275060600396576],
            [-79.25180373829116, -7.198348600325831],
            [-79.12440236318875, -7.180578100311592],
            [-79.05884573813513, -7.240298850368129],
            [-79.0178632381001, -7.179261725311896],
            [-78.9583729880496, -7.187734350320525],
            [-78.91954298801639, -7.210945350342575],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 7,
        FIRST_IDPR: '2501',
        NOMBPROV: 'CORONEL PORTILLO',
        FIRST_NOMB: 'UCAYALI',
        LAST_DCTO: 'LEY',
        LAST_LEY: '9815',
        FIRST_FECH: '02/07/1943',
        LAST_FECHA: '13/10/1900',
        MIN_SHAPE_: 3836958.81192,
        ha: 3836958.81,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.98303323342684, -7.534361350649385],
            [-73.9584042334039, -7.564437475677375],
            [-73.88945698333964, -7.603179725713447],
            [-73.90017773334965, -7.638964850746775],
            [-73.83727135829102, -7.670595725776237],
            [-73.80329323325935, -7.71398522581664],
            [-73.71402873317611, -7.742963850843645],
            [-73.67631373314094, -7.800026850896806],
            [-73.67815973314269, -7.857964100950747],
            [-73.7354511081961, -7.878810600970176],
            [-73.7670137332255, -7.910412100999587],
            [-73.74536910820535, -7.949488226036],
            [-73.70618360816881, -7.961763726047413],
            [-73.66889710813405, -8.012293851094467],
            [-73.64029798310737, -8.006513851089082],
            [-73.58477223305557, -8.126838851201157],
            [-73.59546960806554, -8.165799476237442],
            [-73.56299223303523, -8.245497851311669],
            [-73.52600210800072, -8.274359351338541],
            [-73.54290598301651, -8.347587601406739],
            [-73.44131135792168, -8.425401101479206],
            [-73.40775973289036, -8.43060935148404],
            [-73.37312310785802, -8.471789101522411],
            [-73.33097748281864, -8.47586360152619],
            [-73.34413973283095, -8.602744476644377],
            [-73.31967185780809, -8.610622726651721],
            [-73.25119223274409, -8.690643726726224],
            [-73.1672414826656, -8.698297726733356],
            [-73.11425648261604, -8.785514351814575],
            [-73.11201373261392, -8.81929760184604],
            [-73.03710135754385, -8.916179726936244],
            [-73.00127035751025, -8.916906476936932],
            [-72.94794985746032, -9.028872227041195],
            [-72.93999410745286, -9.093248102101123],
            [-72.96354198247492, -9.143764977148198],
            [-73.0640856075691, -9.227694602226357],
            [-73.15619723265527, -9.356734352346535],
            [-73.18875973268574, -9.363222602352597],
            [-73.20043948269665, -9.41139647739748],
            [-73.20616998270202, -9.484320352465389],
            [-73.18962885768654, -9.526796727504934],
            [-73.2124751077079, -9.574153352549036],
            [-73.22713998272162, -9.656846727626046],
            [-73.27340185776487, -9.664594102633275],
            [-73.24293235773638, -9.731095227695208],
            [-73.21775773271285, -9.827471852784955],
            [-73.22848685772287, -9.846510977802698],
            [-73.1032002326057, -9.875227352829416],
            [-73.09318360759632, -9.949542477898628],
            [-73.11252860761441, -10.01775485296215],
            [-73.15274435765205, -10.03952960298243],
            [-73.21930460771429, -9.99931172794498],
            [-73.30922098279834, -9.892989852845975],
            [-73.35523885784131, -9.872702227827084],
            [-73.39347260787703, -9.822573477780422],
            [-73.45934873293854, -9.795609602755281],
            [-73.46937598294791, -9.750780102713538],
            [-73.53777935801175, -9.707669977673381],
            [-73.57007685804186, -9.657448477626644],
            [-73.60918648307836, -9.644408352614477],
            [-73.68452548314863, -9.529840602507793],
            [-73.7433906082035, -9.540347602517585],
            [-73.82096160827584, -9.531970727509757],
            [-73.8755408583267, -9.596558602569925],
            [-73.95975385840518, -9.610787102583178],
            [-74.04755073348699, -9.655833977625115],
            [-74.06579498350398, -9.705536852671427],
            [-74.15240448358466, -9.709681102675296],
            [-74.18398173361408, -9.763799352725664],
            [-74.25341810867876, -9.771726602733045],
            [-74.31695898373795, -9.801581977760854],
            [-74.33306773375296, -9.860098102815359],
            [-74.41232623382675, -9.95395510290276],
            [-74.41455185882884, -9.902111852854489],
            [-74.45020335886205, -9.870225977824777],
            [-74.4475814838596, -9.77995872774073],
            [-74.48555273389498, -9.702220727668326],
            [-74.49676348390541, -9.59334985256695],
            [-74.5755607339788, -9.570486352545625],
            [-74.58456060898718, -9.49562422747594],
            [-74.67710335907337, -9.42832422741325],
            [-74.67430860907075, -9.394472602381718],
            [-74.58943935899173, -9.30317635229671],
            [-74.63956448403842, -9.159681602163067],
            [-74.64070898403948, -9.11856447712475],
            [-74.6780937340743, -9.094039102101902],
            [-74.65136610904939, -9.048768352059772],
            [-74.64431073404282, -8.918783226938721],
            [-74.60892023400986, -8.820228351846916],
            [-74.53217810893838, -8.776133226805863],
            [-74.52535123393203, -8.749316226780897],
            [-74.54713460895233, -8.584567226627435],
            [-74.61781585901814, -8.548719351594073],
            [-74.79752685918551, -8.631711726671355],
            [-74.84668348423129, -8.661235351698855],
            [-74.85055835923491, -8.756891101787929],
            [-74.94071210931888, -8.857892601881998],
            [-74.95364548433092, -8.858053476882146],
            [-74.92627748430543, -8.784378851813546],
            [-74.93228910931101, -8.729837851762749],
            [-74.9669648593433, -8.671355476708293],
            [-74.93970510931793, -8.597037226639072],
            [-74.99924410937336, -8.5051503515535],
            [-75.02946485940153, -8.417888726472217],
            [-75.00213660937608, -8.38891460144523],
            [-75.04035935941168, -8.312556601374114],
            [-75.08144973444995, -8.273900101338135],
            [-75.1426071095069, -8.272713851336995],
            [-75.21661073457581, -8.240421351306958],
            [-75.2710376096265, -8.263480726328417],
            [-75.33293935968415, -8.247763726313782],
            [-75.41573135976127, -8.24366422630994],
            [-75.45303885979602, -8.210448851279022],
            [-75.43009623477464, -8.166598226238182],
            [-75.3761469847244, -8.138777101212293],
            [-75.28780260964213, -8.125513101199925],
            [-75.24388473460122, -8.155517601227855],
            [-75.21457223457391, -8.127144101201432],
            [-75.18433773454575, -8.01660235109848],
            [-75.1424908595068, -7.97864060106314],
            [-75.03334960940514, -7.950054726036502],
            [-74.98225973435756, -7.968986351054152],
            [-74.89643848427762, -8.081268226158718],
            [-74.77125610916106, -8.036063226116621],
            [-74.72794448412073, -7.981008976065358],
            [-74.62798423402761, -7.939828101026973],
            [-74.56974510897338, -7.815980475911654],
            [-74.51571848392307, -7.777172600875497],
            [-74.55788873396234, -7.748482475848776],
            [-74.58403223398669, -7.698493725802236],
            [-74.57082048397437, -7.668488225774286],
            [-74.64314435904173, -7.562285100675365],
            [-74.6429021090415, -7.500086475617441],
            [-74.61607385901654, -7.435037600556875],
            [-74.57952135898249, -7.409929475533471],
            [-74.53572710894169, -7.294427350425917],
            [-74.50009123390852, -7.262049475395766],
            [-74.46265185887361, -7.298941225430105],
            [-74.31623210873727, -7.351270350478857],
            [-74.25902498368397, -7.344052475472134],
            [-74.18085685861115, -7.358747225485807],
            [-74.1383888585716, -7.39635235052085],
            [-74.03996598347989, -7.448154725569065],
            [-74.03379960847415, -7.484864600603267],
            [-73.98303323342684, -7.534361350649385],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 7,
        FIRST_IDPR: '0209',
        NOMBPROV: 'CORONGO',
        FIRST_NOMB: 'ANCASH',
        LAST_DCTO: 'LEY',
        LAST_LEY: '4662',
        FIRST_FECH: '21/11/1898',
        LAST_FECHA: '09/05/1923',
        MIN_SHAPE_: 101683.939033,
        ha: 101683.94,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.97202736215273, -8.692001976726722],
            [-78.00884173718735, -8.666978476703338],
            [-78.1058027372785, -8.659820351696437],
            [-78.13048998730162, -8.61714160165665],
            [-78.068173362243, -8.564176851607469],
            [-77.99279886217211, -8.53241010157807],
            [-77.93371673711643, -8.454826226505938],
            [-77.87945511206539, -8.427865226480916],
            [-77.87688873706293, -8.398090851453192],
            [-77.79885936198949, -8.381187351437573],
            [-77.76481473695748, -8.440994476493323],
            [-77.7041566119004, -8.50987122655752],
            [-77.68866061188587, -8.576356976619445],
            [-77.72073023691607, -8.65653147669407],
            [-77.7724706119648, -8.673824476710127],
            [-77.81097273700107, -8.662019226699073],
            [-77.87995111206604, -8.684037101719479],
            [-77.91535736209941, -8.74011322677164],
            [-77.97202736215273, -8.692001976726722],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 6,
        FIRST_IDPR: '0305',
        NOMBPROV: 'COTABAMBAS',
        FIRST_NOMB: 'APURIMAC',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: '02/01/1857',
        LAST_FECHA: 'EPOCA INDEP.',
        MIN_SHAPE_: 262307.476533,
        ha: 262307.48,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.4603832320011, -13.790212606474867],
            [-72.43722248197923, -13.680828106372985],
            [-72.39982223194383, -13.641849606336626],
            [-72.40174810694565, -13.659505856353073],
            [-72.29916585684835, -13.704445356394737],
            [-72.2774178568277, -13.684148481375788],
            [-72.22867410678134, -13.714055356403511],
            [-72.16635935672194, -13.747415106434426],
            [-72.13533210669235, -13.783064731467512],
            [-72.10473048166307, -13.856078106535403],
            [-72.0556757316161, -13.934853481608597],
            [-72.0516738566122, -14.012386731680762],
            [-72.06790235662766, -14.076542981740534],
            [-72.05666985661685, -14.12881060678919],
            [-72.09519535665368, -14.173734356831133],
            [-72.0814882316405, -14.202302731857687],
            [-72.18710685674132, -14.313223606961294],
            [-72.23951560679126, -14.348472356994273],
            [-72.24950523180073, -14.40292623204499],
            [-72.31719923186513, -14.440689482080328],
            [-72.35762535690353, -14.435963857076006],
            [-72.36846873191384, -14.350917981996806],
            [-72.34184198188859, -14.318082106966193],
            [-72.41714148196002, -14.27960648193051],
            [-72.44344260698497, -14.226377606880995],
            [-72.47975648201933, -14.201673856858053],
            [-72.38633598193084, -14.206683606862555],
            [-72.35173635689804, -14.139706981800124],
            [-72.3734821069187, -14.07567960674056],
            [-72.42229060696498, -14.066965731732529],
            [-72.42045660696327, -14.028550356696742],
            [-72.45645698199736, -13.968937481641303],
            [-72.44999023199122, -13.922314481597867],
            [-72.4808887320205, -13.879525356558092],
            [-72.4603832320011, -13.790212606474867],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 8,
        FIRST_IDPR: '0801',
        NOMBPROV: 'CUSCO',
        FIRST_NOMB: 'CUSCO',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: '14/01/1942',
        LAST_FECHA: 'EPOCA INDEP.',
        MIN_SHAPE_: 53018.3725551,
        ha: 53018.37,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.80462598137579, -13.508258106210283],
            [-71.82089848139144, -13.521749981222955],
            [-71.81981760639022, -13.60775773130301],
            [-71.84315885641263, -13.63079473132457],
            [-71.91613110648284, -13.593687856290414],
            [-71.94786248151324, -13.617410106312608],
            [-72.02364710658583, -13.613942231309704],
            [-72.09136723165051, -13.64916210634272],
            [-72.13220698168949, -13.639304731333665],
            [-72.16457760672039, -13.606284856303041],
            [-72.1612033567172, -13.548673606249377],
            [-72.1030896066618, -13.553344981253552],
            [-72.06146085662206, -13.52794385622976],
            [-72.05142148161251, -13.48983823119425],
            [-72.00060323156397, -13.444038231151426],
            [-71.98021685654447, -13.42949610613781],
            [-71.87545510644391, -13.499350981202388],
            [-71.80462598137579, -13.508258106210283],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 15,
        FIRST_IDPR: '0606',
        NOMBPROV: 'CUTERVO',
        FIRST_NOMB: 'CAJAMARCA',
        LAST_DCTO: 'LEY',
        LAST_LEY: 'S/N',
        FIRST_FECH: 'EPOCA INDEP.',
        LAST_FECHA: '02/01/1857',
        MIN_SHAPE_: 303737.127226,
        ha: 303737.13,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.24916748827576, -6.22177684941768],
            [-79.27264598829284, -6.168794724367998],
            [-79.23389761326253, -6.093067974298235],
            [-79.21320898824673, -6.098874974303973],
            [-79.1726542382156, -6.134064474337324],
            [-79.08677736314695, -6.1105819743167],
            [-79.07972461314081, -6.064031224273454],
            [-79.02010498809176, -6.027786599240452],
            [-78.96508586304593, -6.012576724226894],
            [-78.90005698799126, -6.04644559925905],
            [-78.86312548795955, -6.017908724232837],
            [-78.8380391129373, -5.90040822412366],
            [-78.74126236285284, -5.835532349064007],
            [-78.70057048781682, -5.785220849017423],
            [-78.7120153628271, -5.830443099059446],
            [-78.66032036278189, -5.955558599176236],
            [-78.61227161273924, -6.018182474234825],
            [-78.589442737719, -6.076535849289256],
            [-78.50906423764646, -6.085308349297821],
            [-78.53407036266944, -6.191652349396705],
            [-78.5096777376475, -6.245755849447199],
            [-78.5150566126525, -6.2898222244882],
            [-78.66773823778996, -6.315040974510858],
            [-78.71132573782906, -6.391899599582096],
            [-78.74534961285931, -6.424431599612137],
            [-78.75687236287003, -6.535601099715534],
            [-78.78668748789627, -6.537679724717246],
            [-78.83470898793792, -6.495827974677859],
            [-78.79580111290367, -6.441442599627591],
            [-78.89296261298779, -6.431175474617171],
            [-78.91776761300856, -6.368191349558296],
            [-78.98685161306634, -6.295914099490316],
            [-79.08561798814803, -6.320028849511567],
            [-79.11965236317523, -6.291197224484258],
            [-79.19915823823787, -6.270406224463727],
            [-79.22228186325508, -6.21198334940901],
            [-79.24916748827576, -6.22177684941768],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 8,
        FIRST_IDPR: '1902',
        NOMBPROV: 'DANIEL ALCIDES CARRION',
        FIRST_NOMB: 'PASCO',
        LAST_DCTO: 'LEY',
        LAST_LEY: '11905',
        FIRST_FECH: '06/09/1920',
        LAST_FECHA: '04/12/1952',
        MIN_SHAPE_: 152075.220991,
        ha: 152075.22,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.65565536091702, -10.646781853547987],
            [-76.70178823596011, -10.593544853498393],
            [-76.72505436098186, -10.485714728397976],
            [-76.69681998595549, -10.442486353357731],
            [-76.64719686090912, -10.415138728332268],
            [-76.49094111076322, -10.378896478298527],
            [-76.4728043607463, -10.337767603260215],
            [-76.49397611076607, -10.310531228234836],
            [-76.44393998571938, -10.320529978244155],
            [-76.33578998561849, -10.286319978212289],
            [-76.30587998559061, -10.412169978329517],
            [-76.24681173553553, -10.479161228391892],
            [-76.241959985531, -10.542699978451058],
            [-76.29371998557926, -10.601889978506202],
            [-76.34602998562805, -10.596059978500769],
            [-76.41960998569665, -10.653169978553937],
            [-76.45636298573095, -10.647425478548591],
            [-76.51617373578677, -10.742954978637547],
            [-76.5385104858076, -10.7043517286016],
            [-76.65565536091702, -10.646781853547987],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 6,
        FIRST_IDPR: '1607',
        NOMBPROV: 'DATEM DEL MARAÑON',
        FIRST_NOMB: 'LORETO',
        LAST_DCTO: 'LEY',
        LAST_LEY: '28593',
        FIRST_FECH: '07/02/1866',
        LAST_FECHA: '01/08/2005',
        MIN_SHAPE_: 4766879.44813,
        ha: 4766879.45,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.89453711114034, -5.746232473984006],
            [-76.96135273620285, -5.706077223946598],
            [-76.99560561123492, -5.667119098910316],
            [-77.0417301112781, -5.674708348917381],
            [-77.23194136145632, -5.595460973843555],
            [-77.33831811155605, -5.603472598850999],
            [-77.37007423658586, -5.569555848819371],
            [-77.40872936162214, -5.589160473837651],
            [-77.46194348667206, -5.584140098832951],
            [-77.51739923672405, -5.466748223723582],
            [-77.55811911176224, -5.425578098685249],
            [-77.58166936178435, -5.461175598718363],
            [-77.6703643618675, -5.409644848670322],
            [-77.69595848689151, -5.411053098671616],
            [-77.68206648687847, -5.33839559860396],
            [-77.69308011188878, -5.267685473538094],
            [-77.73955961193231, -5.212597348486766],
            [-77.69597786189145, -5.163660223441226],
            [-77.74528511193766, -5.133017598412655],
            [-77.7742867369648, -5.097177973379262],
            [-77.8222774870097, -5.081792723364894],
            [-77.82461961201189, -5.031778723318306],
            [-77.75928973695069, -4.951781348243859],
            [-77.7601517369515, -4.909345098204342],
            [-77.79357486198278, -4.849963473149002],
            [-77.79462186198369, -4.763006848068038],
            [-77.77187886196239, -4.717558598025701],
            [-77.6719453618688, -4.629468472943739],
            [-77.64901561184728, -4.5464159728664],
            [-77.65545698685331, -4.518544972840444],
            [-77.58594311178817, -4.475013097799928],
            [-77.56538686176886, -4.372462472704432],
            [-77.58555073678774, -4.251333972591607],
            [-77.54965573675409, -4.109892847459897],
            [-77.53812336174326, -4.028707972384298],
            [-77.5417728617467, -3.943334222304774],
            [-77.56940486177257, -3.872749597239043],
            [-77.65182223684975, -3.779009722151685],
            [-77.61562298681582, -3.707718722085303],
            [-77.61693561181704, -3.634301972016952],
            [-77.76053936195142, -3.459802971854373],
            [-77.7764788619663, -3.409130971807158],
            [-77.77852461196815, -3.187176221600434],
            [-77.8086159869962, -2.986125096413193],
            [-77.156984486386, -2.767845471210061],
            [-76.63186511089482, -2.589689221044146],
            [-76.53432436080377, -2.512278470972044],
            [-76.50242411077399, -2.536480470994591],
            [-76.49925436077102, -2.592622846046877],
            [-76.47064298574433, -2.604314846057765],
            [-76.36134023564233, -2.745897846189617],
            [-76.34420686062637, -2.800070346240091],
            [-76.30734236059199, -2.834851471272489],
            [-76.31686523560086, -2.897560596330903],
            [-76.35123111063294, -2.946427596376409],
            [-76.33792823562052, -2.988613596415691],
            [-76.35347073563501, -3.090419971510504],
            [-76.3892656106684, -3.127677721545202],
            [-76.3520533606337, -3.16238747157754],
            [-76.3672909856479, -3.217830096629161],
            [-76.2700267355572, -3.361134096762633],
            [-76.2614628605492, -3.475218721868892],
            [-76.20617973549767, -3.528056596918114],
            [-76.25936623554725, -3.575607346962395],
            [-76.23408198552369, -3.634831972017539],
            [-76.28970223557552, -3.691222972070084],
            [-76.32775973561102, -3.699384722077674],
            [-76.33251048561546, -3.762375097136347],
            [-76.375977110656, -3.80131109717261],
            [-76.41400386069148, -3.864357847231319],
            [-76.42235348569926, -3.925494097288253],
            [-76.39073236066976, -4.002261722359761],
            [-76.34947261063127, -4.044148472398773],
            [-76.33046673561354, -4.11448634746429],
            [-76.23650848552595, -4.187121347531929],
            [-76.22721286051727, -4.245290972586101],
            [-76.31995311060373, -4.35841409769146],
            [-76.3379129856205, -4.418653222747578],
            [-76.32253698560616, -4.470795597796132],
            [-76.26324223555086, -4.528945347850291],
            [-76.19700786048911, -4.624333972939144],
            [-76.20438473549599, -4.674285097985645],
            [-76.16238086045684, -4.70469922301398],
            [-76.17194136046574, -4.76883009807372],
            [-76.10782223540599, -4.789224598092705],
            [-76.07092411037158, -4.833702223134139],
            [-76.03447448533764, -4.816308598117915],
            [-76.00712498531215, -4.85198247315116],
            [-75.82950198514666, -4.976644473267271],
            [-75.84009086015652, -5.02503572331232],
            [-75.88914698520222, -5.048344973334047],
            [-75.91916986023018, -5.084498098367698],
            [-76.00115423530659, -5.100263598382385],
            [-76.07173086037233, -5.083402848366697],
            [-76.16939648546337, -5.021734348309256],
            [-76.22447236051472, -5.018454473306206],
            [-76.35927148564042, -5.03158984831845],
            [-76.46959173574334, -5.005667973294288],
            [-76.48307311075591, -5.124099348404608],
            [-76.52227648579249, -5.240088473512627],
            [-76.56601948583332, -5.233083973506099],
            [-76.58753898585343, -5.314335848581791],
            [-76.62539661088877, -5.367423098631214],
            [-76.64085548590323, -5.448052723706301],
            [-76.71691873597429, -5.481269848737254],
            [-76.77507811102865, -5.449205098707379],
            [-76.85081836109946, -5.532111348784587],
            [-76.81246686106358, -5.590994098839415],
            [-76.84445111109348, -5.608960848856155],
            [-76.81443286106543, -5.656720973900633],
            [-76.8294762360795, -5.695789473937041],
            [-76.86950823611693, -5.703556098944266],
            [-76.89453711114034, -5.746232473984006],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 9,
        FIRST_IDPR: '1003',
        NOMBPROV: 'DOS DE MAYO',
        FIRST_NOMB: 'HUANUCO',
        LAST_DCTO: 'LEY',
        LAST_LEY: '14072',
        FIRST_FECH: '02/01/1857',
        LAST_FECHA: '12/05/1962',
        MIN_SHAPE_: 145944.17284,
        ha: 145944.17,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.7200604859772, -9.898357477850977],
            [-76.73594923599205, -9.93392772788408],
            [-76.77990823603312, -9.957017602905616],
            [-76.77320511102688, -9.99406247794011],
            [-76.82404686107441, -10.010998102955881],
            [-76.81367573606471, -9.961572227909839],
            [-76.895210986141, -9.815601602773867],
            [-76.90138286114676, -9.770496102731885],
            [-76.94538861118794, -9.70256835266862],
            [-76.81057811106182, -9.617890602589773],
            [-76.69675386095541, -9.557720727533745],
            [-76.58304886084922, -9.552089852528495],
            [-76.4569238607315, -9.498568352478653],
            [-76.37782436065768, -9.487880852468713],
            [-76.37141411065173, -9.44314647742704],
            [-76.3114648605958, -9.416749977402473],
            [-76.29744536058271, -9.388478477376134],
            [-76.23534573552485, -9.418208977403825],
            [-76.20403511049565, -9.446710977430367],
            [-76.20340036049504, -9.499158227479196],
            [-76.27214648555916, -9.525488227503722],
            [-76.31346873559767, -9.51658010249542],
            [-76.36072661064173, -9.546806602523585],
            [-76.36502361064575, -9.577843727552507],
            [-76.4268261107034, -9.6500156026197],
            [-76.47696873575019, -9.673880852641924],
            [-76.52629486079621, -9.665648477634267],
            [-76.67339061093358, -9.681669977649184],
            [-76.72298236097994, -9.71138472767684],
            [-76.70249023596078, -9.74803910271099],
            [-76.73327536098954, -9.779392477740176],
            [-76.75801948601269, -9.842460852798933],
            [-76.7200604859772, -9.898357477850977],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 5,
        FIRST_IDPR: '2105',
        NOMBPROV: 'EL COLLAO',
        FIRST_NOMB: 'PUNO',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: '29/09/1988',
        LAST_FECHA: 'EPOCA INDEP.',
        MIN_SHAPE_: 568988.34277,
        ha: 568988.34,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.56279847885737, -17.174983234454626],
            [-69.64131997894788, -17.2874499845745],
            [-69.69359760401655, -17.2746426095733],
            [-69.73166997906378, -17.294966734599196],
            [-69.7882461041406, -17.2364433595557],
            [-69.85140035421983, -17.24373635957299],
            [-70.03971872945995, -17.127967734493552],
            [-70.04677347947238, -17.056382859428478],
            [-70.07315247950532, -17.034914234411943],
            [-70.09577022953498, -16.985226109368824],
            [-70.14662497959776, -16.938730484331685],
            [-70.09427885453793, -16.890924859281654],
            [-70.09108597953816, -16.81211785920858],
            [-70.05085685449302, -16.763656734159085],
            [-69.99556310442925, -16.729775984120884],
            [-70.02462410446935, -16.631183734033694],
            [-70.02369822946854, -16.62667973402942],
            [-70.02432585446967, -16.619645859023],
            [-70.02827347947513, -16.605400359010353],
            [-70.01884060446454, -16.59637810900087],
            [-70.01401035445889, -16.59580698399974],
            [-70.01146435445592, -16.59509323399875],
            [-70.01039360445469, -16.594783858998333],
            [-69.9886172294303, -16.57336910897581],
            [-69.9822823544232, -16.56799260897002],
            [-69.9642544794034, -16.54449698394599],
            [-69.94194585437806, -16.529516983929184],
            [-69.93739485437315, -16.522463233922075],
            [-69.93315385436826, -16.521147108920275],
            [-69.93090422936572, -16.518832358917827],
            [-69.93049372936532, -16.518116858917114],
            [-69.92950247936426, -16.516310858915293],
            [-69.92143447935487, -16.514419608912434],
            [-69.91539060434741, -16.519642608916406],
            [-69.90687910433736, -16.51948948391505],
            [-69.90224810433223, -16.514468733909755],
            [-69.89549297932503, -16.50300148389824],
            [-69.89417160432377, -16.498632233894025],
            [-69.89073810431996, -16.495252858890407],
            [-69.89003485431925, -16.49339060888859],
            [-69.88840072931755, -16.49029660888551],
            [-69.88675522931581, -16.487542858882726],
            [-69.87935335430797, -16.47462560886976],
            [-69.8792676043081, -16.47130423386669],
            [-69.88427322931452, -16.463866733860566],
            [-69.88063960431053, -16.460088983856554],
            [-69.87735597930696, -16.45568698385203],
            [-69.86907547929766, -16.449214733844844],
            [-69.85645047928446, -16.426408358821952],
            [-69.85526372928328, -16.423714858819295],
            [-69.85594135428472, -16.414324233810756],
            [-69.85476947928368, -16.41001773380662],
            [-69.85470897928394, -16.405679733802618],
            [-69.85241197928153, -16.401589858798502],
            [-69.84841597927705, -16.398759733795288],
            [-69.84653510427489, -16.39814260879444],
            [-69.84258985427041, -16.395951233791823],
            [-69.83979685426733, -16.393359358789013],
            [-69.83760935426486, -16.39215235878756],
            [-69.8317324792579, -16.393169983787605],
            [-69.82822847925377, -16.39315235878703],
            [-69.82571472925078, -16.39361523378707],
            [-69.81714847924074, -16.393763733785867],
            [-69.81048822923296, -16.392874983783997],
            [-69.80353910422512, -16.388595733778956],
            [-69.80143560422276, -16.3873769837775],
            [-69.79667385421794, -16.377675733767806],
            [-69.79688472921825, -16.376882858767114],
            [-69.79782222921955, -16.37428123376486],
            [-69.79721285421888, -16.373521483764065],
            [-69.79649610421822, -16.371384733761982],
            [-69.79549010421708, -16.371003858761483],
            [-69.78826372920925, -16.3625273587525],
            [-69.78795897920894, -16.36190435875189],
            [-69.78830272920959, -16.359076233749335],
            [-69.78957222921125, -16.35677535874742],
            [-69.78985347921169, -16.355755858746527],
            [-69.79004685421202, -16.354326233745258],
            [-69.79046872921253, -16.35409373374511],
            [-69.79151760421381, -16.353542983744767],
            [-69.79353510421626, -16.35244535874408],
            [-69.79606247921956, -16.34824410874063],
            [-69.8007207292257, -16.33927923373312],
            [-69.80070310422583, -16.33770510873168],
            [-69.80037110422559, -16.335484483729577],
            [-69.80102160422656, -16.33285160872726],
            [-69.79902797922495, -16.323934108718735],
            [-69.80618885423708, -16.276132108675885],
            [-69.79958610422962, -16.273393733672314],
            [-69.78805497921667, -16.267517358665067],
            [-69.785728479214, -16.267011733664226],
            [-69.78493560421306, -16.267095733664167],
            [-69.78359772921155, -16.26670310866359],
            [-69.77791210420533, -16.26182035865817],
            [-69.77636322920365, -16.260388858656604],
            [-69.77558197920278, -16.25992773365606],
            [-69.7751718542024, -16.25899410865513],
            [-69.77486910420207, -16.258591858654707],
            [-69.77399610420116, -16.2573242336534],
            [-69.77280272919987, -16.256048858652033],
            [-69.76847660419564, -16.2465722336426],
            [-69.76792385419509, -16.245701233641714],
            [-69.76782422919497, -16.245542983641556],
            [-69.76651172919348, -16.24497060864079],
            [-69.76564060419327, -16.235583983632033],
            [-69.76559960419326, -16.235132858631612],
            [-69.76527147919307, -16.23291598362951],
            [-69.76443747919213, -16.23247660862897],
            [-69.7641113541918, -16.23189060862837],
            [-69.76407810419184, -16.230998108627546],
            [-69.76409372919201, -16.228943358625664],
            [-69.76411722919221, -16.226982483623864],
            [-69.76333197919134, -16.226716733623483],
            [-69.76327210419129, -16.22634098362314],
            [-69.76392947919211, -16.225591858622558],
            [-69.76299235419111, -16.224515608621406],
            [-69.76288660419102, -16.22412698362103],
            [-69.7630527291913, -16.22339060862038],
            [-69.76333010419168, -16.22241998361953],
            [-69.76357622919198, -16.222210983619384],
            [-69.76355860419199, -16.221992233619176],
            [-69.76182422919007, -16.220775358617768],
            [-69.76125197918961, -16.218472608615556],
            [-69.76106435418941, -16.218177483615253],
            [-69.76105660418939, -16.218152358615225],
            [-69.7606308541891, -16.215914108613113],
            [-69.76055272918906, -16.21550385861271],
            [-69.75972422918811, -16.215383983612465],
            [-69.75886722918708, -16.215857483612755],
            [-69.75728322918532, -16.21496685861169],
            [-69.75775385418581, -16.215523483612262],
            [-69.7550586041825, -16.217779358613896],
            [-69.75338472918094, -16.213376983609564],
            [-69.7532597291808, -16.213289108609473],
            [-69.75234372917976, -16.213316358609326],
            [-69.75147847917894, -16.210746108606823],
            [-69.75093360417847, -16.209078108605187],
            [-69.74922460417658, -16.208234358604127],
            [-69.74881060417611, -16.207644483603527],
            [-69.74741222917457, -16.207015608602706],
            [-69.74517572917213, -16.205292983600753],
            [-69.74363672917043, -16.204429608599686],
            [-69.74341997917016, -16.204447233599666],
            [-69.73772547916352, -16.20523385859942],
            [-69.73512272916052, -16.204828733598603],
            [-69.73282410415787, -16.204828733598216],
            [-69.73061647915529, -16.205117858598094],
            [-69.7266991041505, -16.20828385860033],
            [-69.7254964791491, -16.208414608600247],
            [-69.72512422914869, -16.208247983600042],
            [-69.71956897914266, -16.20363460859483],
            [-69.7109644791332, -16.198424858588545],
            [-69.70984185413188, -16.198314483588238],
            [-69.70367985412616, -16.1827109835728],
            [-69.70445197912748, -16.178229233568818],
            [-69.70355960412721, -16.170020858561116],
            [-69.69156435411426, -16.160343608550082],
            [-69.69243822911568, -16.15593498354618],
            [-69.69408360411785, -16.153058983543847],
            [-69.69405922911805, -16.150315233541306],
            [-69.69363935411772, -16.148733358539786],
            [-69.69228685411619, -16.148562483539386],
            [-69.687852104111, -16.149523483539475],
            [-69.6879516041116, -16.14415685853456],
            [-69.69106547911547, -16.141175483532372],
            [-69.69133047911598, -16.138911733530346],
            [-69.68906685411393, -16.13307035852457],
            [-69.68831597911355, -16.127847358519627],
            [-69.68662310411155, -16.128340358519782],
            [-69.68443497910897, -16.129213733520185],
            [-69.68102672910516, -16.12806348351852],
            [-69.67407847909809, -16.11841060850838],
            [-69.67032422909408, -16.1155951085051],
            [-69.67010460409399, -16.113972233503574],
            [-69.67085935409489, -16.113804733503564],
            [-69.67162110409576, -16.113423858503342],
            [-69.67155860409575, -16.112861358502823],
            [-69.67302347909755, -16.11138473350173],
            [-69.67384760409857, -16.110771483501317],
            [-69.67468560409955, -16.11076560850147],
            [-69.67557422910065, -16.109617233500575],
            [-69.67666210410187, -16.10988873350103],
            [-69.67756635410299, -16.108996108500364],
            [-69.67791997910342, -16.108831983500277],
            [-69.67927535410519, -16.106427733498318],
            [-69.6802968541064, -16.10590035849802],
            [-69.68052347910668, -16.10570898349788],
            [-69.68114260410746, -16.10502735849736],
            [-69.68186135410828, -16.104999983497475],
            [-69.68472260411167, -16.103740233496826],
            [-69.68580272911288, -16.104136733497388],
            [-69.6900253541179, -16.10205273349623],
            [-69.69195697912022, -16.100681608495307],
            [-69.6969707291275, -16.084076608480945],
            [-69.68821922911748, -16.084353733479634],
            [-69.68774410411699, -16.083997483479234],
            [-69.68822260411798, -16.0790801084748],
            [-69.68705860411681, -16.077216858472877],
            [-69.68413672911385, -16.07354498346897],
            [-69.6827773541126, -16.070242233465695],
            [-69.6786387291081, -16.0679141084628],
            [-69.6774082291067, -16.067724608462395],
            [-69.66810935409603, -16.06867573346159],
            [-69.6659472290937, -16.06731835845994],
            [-69.65800197908516, -16.06221285845378],
            [-69.65758597908471, -16.061826233453356],
            [-69.6517382290789, -16.053234358444367],
            [-69.65180272907905, -16.05255473344376],
            [-69.65156435408012, -16.03868460843097],
            [-69.65066860407927, -16.036961858429216],
            [-69.65080635407986, -16.032634358425256],
            [-69.65070122907987, -16.03127348342399],
            [-69.6504628540797, -16.030220733422986],
            [-69.65121685408066, -16.02923435842221],
            [-69.65158597908118, -16.028083983421233],
            [-69.6516211040814, -16.0264023584197],
            [-69.65091797908066, -16.025820358419022],
            [-69.65170510408161, -16.024986358418413],
            [-69.6505937290807, -16.021746108415226],
            [-69.65096497908115, -16.02138673341497],
            [-69.65101560408124, -16.021238233414838],
            [-69.6506347290809, -16.020052733413674],
            [-69.65150585408199, -16.019044983412904],
            [-69.65047460408107, -16.016619108410485],
            [-69.65074810408146, -16.015798858409788],
            [-69.65079885408157, -16.01510348340916],
            [-69.64733185407795, -16.01233773340597],
            [-69.64584185407622, -16.012326233405673],
            [-69.64453510407465, -16.013369108406394],
            [-69.64323247907316, -16.013767608406514],
            [-69.64033785406976, -16.014824233406937],
            [-69.63866410406769, -16.016775358408406],
            [-69.63675785406542, -16.017706983408914],
            [-69.63413872906243, -16.01814060840881],
            [-69.63289447906106, -16.01742573340793],
            [-69.62878322905628, -16.019031233408615],
            [-69.62685547905423, -16.017730483407057],
            [-69.62649222905387, -16.017279483406565],
            [-69.62553510405291, -16.015865108405077],
            [-69.62494922905226, -16.01588285840498],
            [-69.62220697904935, -16.01395123340269],
            [-69.61694722904393, -16.008769483396904],
            [-69.61452347904132, -16.007667983395418],
            [-69.61439260404119, -16.00737698339514],
            [-69.61492385404215, -16.004138733392256],
            [-69.61509760404236, -16.00379885839199],
            [-69.61445310404173, -16.003083983391203],
            [-69.61420510404147, -16.002472608390594],
            [-69.61392385404122, -16.002132858390215],
            [-69.61353322904091, -16.00077535838889],
            [-69.60991210403728, -15.996294983384086],
            [-69.61027147903786, -15.99479685838277],
            [-69.60874997903692, -15.987552733375828],
            [-69.60992772903856, -15.98431248337308],
            [-69.60974410403844, -15.983390608372195],
            [-69.56266085398819, -15.959456608340787],
            [-69.45173547886034, -15.993610483347721],
            [-69.4231612288215, -16.047037358389826],
            [-69.50351035389947, -16.149803108502677],
            [-69.5138888539078, -16.17996923353262],
            [-69.57191610397513, -16.174275358539802],
            [-69.58639735398113, -16.274736483634953],
            [-69.64453322904534, -16.31113673367987],
            [-69.64530860404624, -16.311124983680013],
            [-69.68401947908491, -16.38255473375288],
            [-69.66070122905327, -16.425925733788343],
            [-69.55577147892669, -16.445369108784973],
            [-69.48380010384318, -16.42874373375366],
            [-69.47159347882503, -16.45870110877822],
            [-69.48828247883684, -16.526352108844044],
            [-69.48783785382457, -16.624943358934168],
            [-69.50174647883733, -16.66059348397004],
            [-69.54214972888553, -16.670604858988366],
            [-69.51148635383984, -16.74239773404715],
            [-69.56587110389718, -16.833443359142695],
            [-69.56822785389123, -16.91781510922047],
            [-69.53573485384491, -16.96768860925884],
            [-69.53476710383353, -17.060860359343863],
            [-69.60287060391671, -17.097457734392435],
            [-69.56279847885737, -17.174983234454626],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 5,
        FIRST_IDPR: '2203',
        NOMBPROV: 'EL DORADO',
        FIRST_NOMB: 'SAN MARTIN',
        LAST_DCTO: 'LEY',
        LAST_LEY: '14060',
        FIRST_FECH: '29/01/1944',
        LAST_FECHA: '06/04/1962',
        MIN_SHAPE_: 129316.591948,
        ha: 129316.59,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.5242096107943, -6.69287022486566],
            [-76.52387311079399, -6.725382849895949],
            [-76.59626998586158, -6.794449974960259],
            [-76.62120998588487, -6.77616997494323],
            [-76.68552998594495, -6.810219849974947],
            [-76.73446686099068, -6.802689474967942],
            [-76.78121573603435, -6.737406099907134],
            [-76.7969573610491, -6.645647224821676],
            [-76.85524273610359, -6.525403099709666],
            [-76.92102761116513, -6.471253724659237],
            [-76.95252148619461, -6.39716009959023],
            [-76.97181261121266, -6.322515349520715],
            [-76.94931636119159, -6.284375599485186],
            [-76.86710273611469, -6.26460859946679],
            [-76.83514448608479, -6.285152349485925],
            [-76.84320898609234, -6.369173849564182],
            [-76.78034786103355, -6.418222849609855],
            [-76.67893048593876, -6.434741849625258],
            [-76.64072461090309, -6.546896599729702],
            [-76.59124261085688, -6.594961224774466],
            [-76.53457648580398, -6.625487724802891],
            [-76.5584671108263, -6.671431474845691],
            [-76.5242096107943, -6.69287022486566],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 8,
        FIRST_IDPR: '0808',
        NOMBPROV: 'ESPINAR',
        FIRST_NOMB: 'CUSCO',
        LAST_DCTO: 'LEY',
        LAST_LEY: 'S/N',
        FIRST_FECH: '29/08/1834',
        LAST_FECHA: '29/08/1834',
        MIN_SHAPE_: 524984.683066,
        ha: 524984.68,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.89389860645952, -14.78960123240359],
            [-71.8806239814468, -14.71036960732975],
            [-71.85814698142512, -14.692313607312823],
            [-71.8020376063708, -14.70928960732827],
            [-71.76034373133041, -14.679334857300082],
            [-71.71239460628408, -14.582342482209471],
            [-71.66201310623511, -14.564533232192478],
            [-71.64775885622129, -14.532170482162238],
            [-71.63284023120676, -14.529393732159525],
            [-71.6260162312001, -14.532076357161968],
            [-71.62215710619631, -14.533535232163286],
            [-71.61650960619083, -14.533488107163173],
            [-71.61401548118839, -14.534523482164127],
            [-71.61316823118753, -14.537582607166955],
            [-71.60846673118293, -14.54411948217301],
            [-71.60187798117649, -14.546114982174792],
            [-71.59875310617343, -14.54562548217433],
            [-71.59468698116945, -14.546529107175107],
            [-71.5924656061673, -14.545776107174397],
            [-71.5918632311667, -14.545964482174554],
            [-71.57679023115196, -14.546033482174462],
            [-71.5743424811496, -14.545246732173723],
            [-71.55920648113478, -14.539697607168423],
            [-71.54899010612478, -14.537815857166558],
            [-71.54356985611949, -14.537990732166648],
            [-71.53832448111433, -14.543498232171723],
            [-71.53876173111473, -14.547607107175546],
            [-71.54033523111625, -14.553027232180611],
            [-71.53972335611563, -14.555912232183298],
            [-71.53561435611161, -14.560632982187654],
            [-71.53305648110907, -14.565940357192561],
            [-71.53122310610728, -14.570271607196554],
            [-71.52945848110554, -14.571188357197403],
            [-71.52845010610456, -14.571669607197826],
            [-71.5252646061014, -14.571921732198016],
            [-71.52434785610053, -14.571761232197874],
            [-71.52074985609698, -14.572540482198573],
            [-71.51914573109542, -14.574373857200246],
            [-71.50887885608533, -14.576780107202378],
            [-71.50592248108242, -14.579644732205013],
            [-71.49758060607422, -14.580630232205818],
            [-71.49648060607313, -14.580194732205394],
            [-71.490224231067, -14.57854473220381],
            [-71.48845960606526, -14.57898010720418],
            [-71.488207606065, -14.579278107204479],
            [-71.48770335606451, -14.581546857206558],
            [-71.48245535605932, -14.589109607213542],
            [-71.48048448105736, -14.590576232214891],
            [-71.47370098105071, -14.59426585721823],
            [-71.46842998104552, -14.595617982219427],
            [-71.46116535603834, -14.597726232221293],
            [-71.45547460603274, -14.60053510722383],
            [-71.44887523102621, -14.610912857233409],
            [-71.44795473102528, -14.611556107234021],
            [-71.4471643560245, -14.61184585723427],
            [-71.44522835602261, -14.61244560723481],
            [-71.44202573101944, -14.613008482235301],
            [-71.43765748101515, -14.612550232234806],
            [-71.43515260601268, -14.613466732235613],
            [-71.43041960600797, -14.618834357240571],
            [-71.42986448100741, -14.62127735724283],
            [-71.41868098099646, -14.607417107229772],
            [-71.4183241059961, -14.606465232228889],
            [-71.41166935598955, -14.603736357226277],
            [-71.41072085598864, -14.603461357225996],
            [-71.4104707309884, -14.603109357225653],
            [-71.40953910598746, -14.60283598222541],
            [-71.40827935598622, -14.602689482225246],
            [-71.40743373098537, -14.604087857226528],
            [-71.40670123098464, -14.605578107227918],
            [-71.40410748098208, -14.606373107228604],
            [-71.40299023098098, -14.607144482229332],
            [-71.39630273097438, -14.607226607229297],
            [-71.39432810597242, -14.606417982228518],
            [-71.39382223097192, -14.606449232228545],
            [-71.39169535596983, -14.606556607228624],
            [-71.38239460596063, -14.610892607232518],
            [-71.36768935594606, -14.619382857240199],
            [-71.36156310594001, -14.617746107238586],
            [-71.357531105936, -14.618410857239157],
            [-71.3557883559343, -14.618014732238759],
            [-71.33392473091266, -14.618093982238506],
            [-71.33125048090994, -14.626672357246452],
            [-71.31376973089242, -14.658794857276064],
            [-71.31032223088901, -14.658997607276177],
            [-71.30865935588736, -14.659524857276663],
            [-71.2992497308781, -14.643706982261799],
            [-71.29685673087575, -14.64159798225978],
            [-71.28906948086801, -14.642976982260942],
            [-71.2856306058646, -14.644032607261874],
            [-71.2809147308599, -14.64630323226389],
            [-71.27143898085043, -14.650495232267641],
            [-71.26715960584622, -14.64857398226577],
            [-71.26130835584037, -14.65224210726909],
            [-71.25296798083208, -14.649709232266579],
            [-71.25191998083106, -14.649010607265915],
            [-71.24918410582838, -14.64425260726143],
            [-71.24892885582814, -14.6411883572586],
            [-71.22362585580291, -14.647849107264332],
            [-71.21543285579475, -14.64824135726452],
            [-71.20802398078737, -14.642227232258813],
            [-71.19508060577455, -14.634949357251788],
            [-71.18793335576748, -14.627976482245174],
            [-71.18688748076644, -14.62584085724318],
            [-71.1861466057657, -14.625623107242943],
            [-71.17577523075543, -14.618011232235666],
            [-71.17325598075288, -14.621194482238568],
            [-71.14712210572661, -14.640840982256302],
            [-71.12811860570756, -14.643431357258317],
            [-71.12270423070213, -14.646510232261042],
            [-71.11794060569734, -14.648917732263184],
            [-71.08951035566818, -14.713188107322305],
            [-71.05324048063132, -14.754330482359693],
            [-71.01309348059102, -14.739693857345056],
            [-70.97548335555275, -14.764133732366764],
            [-70.99383548057051, -14.829163857427698],
            [-70.98474035555985, -14.94606848253609],
            [-71.00405010557917, -14.971877232560614],
            [-70.9936003555672, -15.084587357665066],
            [-70.96250248053387, -15.215636857785967],
            [-70.98528060555672, -15.249029607817665],
            [-70.95229923052247, -15.289843107854589],
            [-71.0122773555832, -15.343752107906438],
            [-71.03897098060976, -15.405165732964244],
            [-71.07174685564274, -15.449273108006102],
            [-71.1743316057472, -15.424456607985453],
            [-71.15115360572422, -15.363941232928694],
            [-71.16539760573929, -15.288882232859228],
            [-71.2018356057764, -15.245445232819618],
            [-71.16032860573486, -15.207768232783705],
            [-71.18038935575547, -15.162620482742165],
            [-71.14357760571875, -15.120560607702265],
            [-71.20684048078279, -15.071888982658333],
            [-71.26022523083627, -15.076908107664018],
            [-71.31562810589134, -15.120224982705263],
            [-71.37268048094839, -15.079110107667935],
            [-71.43081660600647, -14.998084107593396],
            [-71.50704960608184, -14.97627548257407],
            [-71.48587035606062, -15.059104857650878],
            [-71.51171110608603, -15.079447732670113],
            [-71.57062723114409, -15.061207232653802],
            [-71.58760073116052, -15.125595107713895],
            [-71.63602448120798, -15.13913923272698],
            [-71.66795348123938, -15.091896107683313],
            [-71.80295560637094, -15.06744098266162],
            [-71.84931948141596, -15.045089732641125],
            [-71.7674561063366, -15.010523982608396],
            [-71.74595998131583, -14.939869982542472],
            [-71.79762273136612, -14.895981732502001],
            [-71.85208898141893, -14.888090107495014],
            [-71.87667073144286, -14.800437982413575],
            [-71.89389860645952, -14.78960123240359],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 6,
        FIRST_IDPR: '1402',
        NOMBPROV: 'FERREÑAFE',
        FIRST_NOMB: 'LAMBAYEQUE',
        LAST_DCTO: 'LEY',
        LAST_LEY: '11590',
        FIRST_FECH: '17/02/1951',
        LAST_FECHA: '17/02/1951',
        MIN_SHAPE_: 158344.055954,
        ha: 158344.06,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.21320898824673, -6.098874974303973],
            [-79.23389761326253, -6.093067974298235],
            [-79.27264598829284, -6.168794724367998],
            [-79.24916748827576, -6.22177684941768],
            [-79.32178936333071, -6.263057974454757],
            [-79.35356136335452, -6.304291224492455],
            [-79.42265773840367, -6.310430099496656],
            [-79.45348986342646, -6.396841599576239],
            [-79.42288823840632, -6.45188197462814],
            [-79.4338808634145, -6.481265849655182],
            [-79.49116698845434, -6.520306974690023],
            [-79.56287848850073, -6.503837224672732],
            [-79.62424061353877, -6.513258099679602],
            [-79.64147536355051, -6.572899224734435],
            [-79.59267136352221, -6.624335599783799],
            [-79.62542461354424, -6.701821724854704],
            [-79.69775936358603, -6.684727599836311],
            [-79.78142973863051, -6.679587849828297],
            [-79.8474978636609, -6.639937474788646],
            [-79.80893748863639, -6.447826224612077],
            [-79.75268948860837, -6.439371099606508],
            [-79.65191411355362, -6.455400349624978],
            [-79.61344336353012, -6.425292974598248],
            [-79.52833786347635, -6.399812474577072],
            [-79.54328248848506, -6.357212724537087],
            [-79.481981113444, -6.306308224491405],
            [-79.4018454883868, -6.173506349369855],
            [-79.42817773840466, -6.148095724345632],
            [-79.40308373838535, -6.034022474240159],
            [-79.41721111339469, -6.005238224213097],
            [-79.34901761334633, -5.979687849190745],
            [-79.26434173828443, -6.00245959921345],
            [-79.22399498825449, -6.055006974263009],
            [-79.21320898824673, -6.098874974303973],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 11,
        FIRST_IDPR: '1802',
        NOMBPROV: 'GENERAL SANCHEZ CERRO',
        FIRST_NOMB: 'MOQUEGUA',
        LAST_DCTO: '--',
        LAST_LEY: '-',
        FIRST_FECH: '30/09/1942',
        LAST_FECHA: 'EPOCA INDEP.',
        MIN_SHAPE_: 575726.933101,
        ha: 575726.93,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.39092260595666, -16.98049160943651],
            [-71.44063573100748, -16.86522673433021],
            [-71.44430548101145, -16.804674109273943],
            [-71.42592623099321, -16.74137685921478],
            [-71.42395023099124, -16.739126234212666],
            [-71.42349235599077, -16.738626484212197],
            [-71.4229278559902, -16.7378978592115],
            [-71.41801448098524, -16.738429984211916],
            [-71.41469573098192, -16.73554610920917],
            [-71.41313173098033, -16.734121359207823],
            [-71.41075910597795, -16.732353234206133],
            [-71.40785235597504, -16.730543109204415],
            [-71.40030673096742, -16.728733109202597],
            [-71.39456173096158, -16.731199234204773],
            [-71.39233398095934, -16.730510734204103],
            [-71.38140873094831, -16.72558210919932],
            [-71.38037873094729, -16.725110984198864],
            [-71.37859348094544, -16.724588359198346],
            [-71.37158960593838, -16.720495234194413],
            [-71.35752110592412, -16.723731984197148],
            [-71.35633848092289, -16.72462273419796],
            [-71.35256960591906, -16.725616484198817],
            [-71.35052485591699, -16.727600234200615],
            [-71.35066223091712, -16.73003010920288],
            [-71.3485031059149, -16.734224359206742],
            [-71.34853360591492, -16.736440609208806],
            [-71.3473511059137, -16.741786984213743],
            [-71.34577173091206, -16.74306298421491],
            [-71.34367373090993, -16.744884484216563],
            [-71.3287048558947, -16.75082210922179],
            [-71.32796473089392, -16.75177760922265],
            [-71.32608035589199, -16.75217623422299],
            [-71.32358548088949, -16.750255609221153],
            [-71.3203811058862, -16.748233734219205],
            [-71.31888585588473, -16.746931109217968],
            [-71.31855010588437, -16.746442734217496],
            [-71.31241610587814, -16.742969484214157],
            [-71.31017298087589, -16.738191609209665],
            [-71.30578610587143, -16.73576160920732],
            [-71.30524448087087, -16.735603359207147],
            [-71.30091860586647, -16.73427385920583],
            [-71.29914098086465, -16.733238234204833],
            [-71.2975844808631, -16.732696359204294],
            [-71.28927610585465, -16.722717234194835],
            [-71.28800960585338, -16.719585359191907],
            [-71.28634648085169, -16.717388109189823],
            [-71.29045873085592, -16.713285484186112],
            [-71.29254135585805, -16.712400359185317],
            [-71.29601285586162, -16.7062663591797],
            [-71.2956619808613, -16.70294760917659],
            [-71.29477685586042, -16.696514109170604],
            [-71.29429623085996, -16.69422910916847],
            [-71.29398348085965, -16.691574109165987],
            [-71.29290773085857, -16.68996998416448],
            [-71.28879548085438, -16.68646998416114],
            [-71.28368373084922, -16.677421609152624],
            [-71.2812957308469, -16.658885984135342],
            [-71.28070835584634, -16.653860109130648],
            [-71.2804183558462, -16.63116460910955],
            [-71.28035735584612, -16.630117359108574],
            [-71.27751923084337, -16.611362484091085],
            [-71.27417760583997, -16.607513484087434],
            [-71.2669448558326, -16.606660859086485],
            [-71.26300048082854, -16.606939359086653],
            [-71.2527542308183, -16.572566984054475],
            [-71.25230410581784, -16.57092660905295],
            [-71.24713135581268, -16.554355609037433],
            [-71.24331660580887, -16.53954123402358],
            [-71.24279023080842, -16.527234984012118],
            [-71.24245448080812, -16.524251984009346],
            [-71.23613735580176, -16.50648298399268],
            [-71.23706823080275, -16.502115233988647],
            [-71.23916623080491, -16.49892048398572],
            [-71.24198148080782, -16.494029858981246],
            [-71.24934385581544, -16.482358983970553],
            [-71.24877935581489, -16.48184585897008],
            [-71.24358373080959, -16.47560310896415],
            [-71.24604798081216, -16.467639983956804],
            [-71.24270635580878, -16.463928233953276],
            [-71.23698423080292, -16.465831733954932],
            [-71.23620610580214, -16.466009108955074],
            [-71.23358148079944, -16.46544260895448],
            [-71.22750085579324, -16.46140860895059],
            [-71.2245254807902, -16.460559858949747],
            [-71.19405360575888, -16.47200773395967],
            [-71.18828585575295, -16.47230723395981],
            [-71.18269348074719, -16.472631483959987],
            [-71.17270660573693, -16.47159385895877],
            [-71.16759485573166, -16.4717693589588],
            [-71.15629573072002, -16.47136310895814],
            [-71.15203848071562, -16.47316360895971],
            [-71.1493072307128, -16.473560358960004],
            [-71.14860535571206, -16.473676733960094],
            [-71.1471557307106, -16.473724358960098],
            [-71.14623260570964, -16.47368235896004],
            [-71.14181523070506, -16.47454835896073],
            [-71.13951873070269, -16.47497373396107],
            [-71.13861085570174, -16.474670358960754],
            [-71.1361694806993, -16.469041858955478],
            [-71.13442998069749, -16.467670483954148],
            [-71.13225560569525, -16.466722483953212],
            [-71.12890623069184, -16.463918733950525],
            [-71.12753298069042, -16.462688483949336],
            [-71.11830898068088, -16.46279160894919],
            [-71.1134262306758, -16.46400835895018],
            [-71.11132810567364, -16.465383483951413],
            [-71.10546873066761, -16.461219733947374],
            [-71.10202785566402, -16.46380610894969],
            [-71.09915923066102, -16.46417048394995],
            [-71.0944747306562, -16.462999358948736],
            [-71.09308623065476, -16.460945108946774],
            [-71.09161373065328, -16.45758248394362],
            [-71.08690648064837, -16.458177483944034],
            [-71.08599848064743, -16.458564733944378],
            [-71.08310698064443, -16.457426108943242],
            [-71.08235173064365, -16.456935858942746],
            [-71.0782699806394, -16.45856473394416],
            [-71.07596585563695, -16.462625483947857],
            [-71.06391910562446, -16.4616469839466],
            [-71.05304723061315, -16.461566983946202],
            [-71.04811098060807, -16.455078108940043],
            [-71.04717260560707, -16.45674710894156],
            [-71.04759210560748, -16.458911858943587],
            [-71.04746248060734, -16.460439733945],
            [-71.04402160560373, -16.462669358946965],
            [-71.0418243556014, -16.46648973395045],
            [-71.04459385560426, -16.470630608954373],
            [-71.04389185560348, -16.4725093589561],
            [-71.04367060560324, -16.4727324839563],
            [-71.0428467306024, -16.473400108956895],
            [-71.04255673060207, -16.47435573395777],
            [-71.03440098059346, -16.485643358968012],
            [-71.03040310558927, -16.485847483968087],
            [-71.02574923058441, -16.486717233968736],
            [-71.02072148057914, -16.48675923396862],
            [-71.0171508555754, -16.490001733971525],
            [-71.0169829805752, -16.49105073397249],
            [-71.01606748057418, -16.494424983975602],
            [-71.01593785557405, -16.494914983976052],
            [-71.0151138555732, -16.49563598397669],
            [-71.014007605572, -16.497339233978252],
            [-71.00949098056728, -16.49725723397803],
            [-71.00804135556578, -16.496362733977143],
            [-71.00391385556145, -16.496959733977558],
            [-71.00309748056058, -16.497428858977973],
            [-70.98645785554322, -16.488836233969465],
            [-70.98401648054072, -16.48516660896596],
            [-70.97288510552909, -16.480005233960807],
            [-70.97169498052783, -16.480329483961068],
            [-70.9658813555217, -16.480720483961235],
            [-70.95957948051516, -16.47456360895529],
            [-70.95840448051395, -16.473495483954263],
            [-70.9560852305115, -16.47252085895328],
            [-70.9539718555094, -16.46459960894585],
            [-70.96021273051609, -16.45242123393475],
            [-70.9672088555237, -16.432941483916903],
            [-70.97370148053061, -16.42586535891055],
            [-70.97435760553132, -16.421895983906897],
            [-70.97480773053192, -16.410848608896647],
            [-70.96752935552435, -16.407636608893412],
            [-70.96741485552423, -16.40687560889271],
            [-70.9659271055227, -16.40426823389024],
            [-70.96424860552096, -16.401481608887593],
            [-70.96381373052051, -16.400220858886403],
            [-70.964248605521, -16.39904210888533],
            [-70.96446223052122, -16.3984622338848],
            [-70.965194730522, -16.397367483883798],
            [-70.96631623052319, -16.39714435888363],
            [-70.96881098052582, -16.395837733882498],
            [-70.97142785552859, -16.395166358881962],
            [-70.9745864805319, -16.392820358879902],
            [-70.97509760553247, -16.391029358878246],
            [-70.97309885553052, -16.378292108866347],
            [-70.9692764805266, -16.37110135885954],
            [-70.97190098052943, -16.365579608854514],
            [-70.9737548555314, -16.36396785885308],
            [-70.97576135553354, -16.36033435884976],
            [-70.9736099805313, -16.356397608846038],
            [-70.97276310553046, -16.353748358843543],
            [-70.97867585553674, -16.34721185883768],
            [-70.98001098053831, -16.33179860882339],
            [-70.98386385554241, -16.326950108819016],
            [-70.98635860554508, -16.320190483812826],
            [-70.98577885554451, -16.318965858811666],
            [-70.97508235553342, -16.308490733801595],
            [-70.97225948053047, -16.3088226088018],
            [-70.96891785552695, -16.308713858801596],
            [-70.96430973052216, -16.308765358801477],
            [-70.96125798051895, -16.308246483800897],
            [-70.95111848050831, -16.309747733801952],
            [-70.94625085550314, -16.313835108805577],
            [-70.940353230497, -16.310317983802122],
            [-70.9399413554966, -16.30747035879945],
            [-70.93962860549632, -16.30436710879657],
            [-70.92333223047939, -16.289125483781838],
            [-70.90342698045859, -16.27761073377044],
            [-70.88979335544414, -16.28267285877463],
            [-70.87553410542914, -16.278814358770504],
            [-70.87405398042765, -16.272651733764743],
            [-70.87078860542434, -16.26364323375625],
            [-70.86936948042288, -16.261642483754336],
            [-70.86757660542101, -16.258640233751482],
            [-70.86347198041676, -16.2545299837475],
            [-70.84447473039693, -16.23587798372944],
            [-70.86067960541422, -16.2257652337207],
            [-70.8621902304158, -16.226650233721575],
            [-70.86577610541958, -16.22627073372136],
            [-70.86632535542017, -16.225765233720914],
            [-70.86687473042079, -16.222392983717807],
            [-70.85487360540861, -16.189601858686895],
            [-70.85865023041278, -16.17822460867648],
            [-70.85982510541406, -16.176219983674656],
            [-70.86289210541732, -16.173803358672544],
            [-70.86509710541966, -16.172801983671693],
            [-70.87059023042546, -16.171380983670577],
            [-70.8743057304294, -16.171035733670404],
            [-70.87804410543336, -16.16799348366772],
            [-70.87824248043363, -16.164709108664674],
            [-70.87775423043313, -16.163766858663788],
            [-70.87417598042938, -16.161077483661153],
            [-70.87294010542813, -16.16014098366023],
            [-70.86782835542282, -16.153865858654218],
            [-70.86708073042212, -16.148456608649155],
            [-70.86746973042254, -16.14669410864755],
            [-70.87193298042732, -16.14287948364418],
            [-70.87200923042741, -16.141185733642605],
            [-70.8707733554262, -16.1342316086361],
            [-70.8688354804242, -16.131776858633746],
            [-70.86732485542264, -16.129478483631562],
            [-70.86758423042295, -16.12759785862981],
            [-70.88006585543616, -16.122217233625285],
            [-70.88356798043986, -16.12002185862338],
            [-70.8943709804514, -16.107505858612154],
            [-70.89570610545282, -16.106281233611057],
            [-70.89498135545209, -16.104389233609282],
            [-70.89376073045084, -16.102830858607792],
            [-70.88548273044215, -16.100917858605715],
            [-70.88480385544143, -16.10090060860568],
            [-70.87468723043091, -16.093425733598366],
            [-70.87547298043181, -16.089256233594526],
            [-70.87648010543288, -16.087772358593174],
            [-70.8782196054348, -16.08298685858879],
            [-70.8781051054347, -16.081443733587363],
            [-70.86857610542467, -16.08103560858662],
            [-70.8653107304214, -16.068719858575065],
            [-70.86032110541626, -16.061744733568403],
            [-70.84516910540042, -16.05552098356204],
            [-70.83898160539388, -16.055112858561422],
            [-70.83006285538448, -16.05545235856139],
            [-70.80451198035747, -16.055133983560058],
            [-70.80408473035705, -16.053827233558817],
            [-70.81546785536939, -16.03558735854235],
            [-70.82097623037531, -16.02867698353617],
            [-70.82365423037858, -16.00087548351047],
            [-70.82910160538441, -15.995490108505688],
            [-70.82825473038358, -15.991058358501537],
            [-70.82340235537862, -15.982151983493079],
            [-70.8201981053753, -15.978467983489526],
            [-70.76863998032015, -16.016799983522958],
            [-70.7054023552526, -16.03175785853398],
            [-70.64331835518708, -15.989736358491934],
            [-70.5628899801007, -15.982499983480826],
            [-70.55842410509568, -15.989923608487445],
            [-70.55126373008783, -15.99462398349138],
            [-70.54929310508568, -15.995183733491784],
            [-70.54188473007741, -16.00594535850133],
            [-70.5309162300653, -16.014159358508273],
            [-70.52970910506399, -16.014891108508877],
            [-70.52376748005717, -16.028650858521285],
            [-70.52162873005481, -16.030346983522715],
            [-70.5176584800504, -16.03336885852527],
            [-70.51449560504702, -16.031743483523574],
            [-70.51194773004428, -16.02976673352158],
            [-70.50732423003905, -16.038144483529052],
            [-70.50241210503368, -16.03791410852853],
            [-70.49907673003001, -16.039386983529685],
            [-70.48255960501203, -16.039474858528706],
            [-70.48141748001076, -16.04030948352941],
            [-70.48089035501013, -16.041495608530465],
            [-70.47135785499954, -16.048348483536195],
            [-70.46063935498788, -16.04773348353491],
            [-70.45040397997701, -16.036970983524256],
            [-70.44422497997033, -16.033996608521083],
            [-70.44112135496688, -16.03581423352255],
            [-70.43902747996451, -16.038635483525024],
            [-70.43845547996375, -16.043076983529094],
            [-70.43506935495951, -16.06015060854467],
            [-70.4342822299586, -16.061878358546213],
            [-70.42764722995113, -16.0674334835509],
            [-70.42476097994792, -16.06879735855196],
            [-70.42075272994352, -16.069613983552436],
            [-70.41632410493845, -16.07583860855789],
            [-70.41419910493613, -16.075394608557335],
            [-70.41346960493534, -16.075172483557076],
            [-70.40325647992363, -16.09049210857051],
            [-70.40357372992392, -16.091729108571684],
            [-70.40630135492673, -16.097787108577485],
            [-70.40128710492105, -16.10252735858151],
            [-70.40801297992783, -16.122016483600056],
            [-70.4076599799274, -16.123159983601088],
            [-70.40647572992593, -16.12892910860634],
            [-70.40644322992588, -16.12909773360649],
            [-70.4025507299211, -16.144036483620045],
            [-70.40074860491889, -16.150615358626006],
            [-70.38732272990379, -16.15896023363274],
            [-70.38469710490062, -16.166633858639646],
            [-70.38411147989994, -16.167548358640445],
            [-70.3802794798956, -16.17111898364347],
            [-70.36934210488306, -16.18299260865362],
            [-70.36722985488049, -16.18925585865927],
            [-70.36735760488025, -16.20092173367007],
            [-70.3622793548744, -16.20649860867484],
            [-70.36064272987257, -16.207792858675916],
            [-70.35577060486713, -16.208249608675956],
            [-70.34739872985796, -16.20416560867152],
            [-70.32856010483695, -16.205411983671162],
            [-70.32778910483587, -16.210894983676173],
            [-70.32452860483207, -16.215912983680557],
            [-70.326489354834, -16.2226091086869],
            [-70.32651710483367, -16.2321118586957],
            [-70.32952560483692, -16.23578148369935],
            [-70.33397522984183, -16.237518483701304],
            [-70.33547247984336, -16.2409469837046],
            [-70.33895697984697, -16.248941358712273],
            [-70.33915597984718, -16.249100358712447],
            [-70.34175610485006, -16.24996298371346],
            [-70.34335335485173, -16.253029483716414],
            [-70.34513485485358, -16.25610198371941],
            [-70.34499197985342, -16.257053858720273],
            [-70.34104222984843, -16.27306748373478],
            [-70.34782622985537, -16.29111135875202],
            [-70.34381072985065, -16.298027108758102],
            [-70.34513485485206, -16.299074983759176],
            [-70.34617522985293, -16.307514108767066],
            [-70.3425412298487, -16.312733233771603],
            [-70.33925760484479, -16.319633608777725],
            [-70.34213672984751, -16.332815858790152],
            [-70.34626085485188, -16.340187858797307],
            [-70.34534585485075, -16.34358148380039],
            [-70.34766410485307, -16.351823358808186],
            [-70.35079472985639, -16.356655733812907],
            [-70.35271172985847, -16.35828035881456],
            [-70.37147547987894, -16.374527858831083],
            [-70.38066022988892, -16.38399810884056],
            [-70.38237347989077, -16.385425733842013],
            [-70.38225697989063, -16.385923733842457],
            [-70.37300572988012, -16.390449858845937],
            [-70.36211935486773, -16.39583598385007],
            [-70.36154922986663, -16.409530483862692],
            [-70.36088760486585, -16.410831358863845],
            [-70.35923422986394, -16.411642358864473],
            [-70.33283985483429, -16.41147073386217],
            [-70.3225062298223, -16.421141483870265],
            [-70.31952872981876, -16.425967983874482],
            [-70.31824372981723, -16.42850385887673],
            [-70.31585760481425, -16.435696858883173],
            [-70.31641910481481, -16.438503858885827],
            [-70.3068402298035, -16.451135358896693],
            [-70.31293947981003, -16.461577983906867],
            [-70.35974810486226, -16.47763860892558],
            [-70.39663272990224, -16.52376335897115],
            [-70.4728499799866, -16.555469984006038],
            [-70.56277998008618, -16.563879984019675],
            [-70.60302985513084, -16.548079984007426],
            [-70.67902998021391, -16.548419984011897],
            [-70.72048998025757, -16.61935998407979],
            [-70.76394998030436, -16.63463998409604],
            [-70.77835998031854, -16.715169984171418],
            [-70.825858855369, -16.761503609216508],
            [-70.96568998051796, -16.777659984236966],
            [-71.06091573061838, -16.75860373422237],
            [-71.1556874807161, -16.871179734329658],
            [-71.16939848073002, -16.906970734363266],
            [-71.2858593558498, -16.943136859399665],
            [-71.33303323089798, -16.94347460940098],
            [-71.39092260595666, -16.98049160943651],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 4,
        FIRST_IDPR: '1311',
        NOMBPROV: 'GRAN CHIMU',
        FIRST_NOMB: 'LA LIBERTAD',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: '14/05/1876',
        LAST_FECHA: 'EPOCA INDEP.',
        MIN_SHAPE_: 129246.17116,
        ha: 129246.17,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.33583098749122, -7.495248225611308],
            [-78.36082723751447, -7.529961350643546],
            [-78.35679223751083, -7.574006100684548],
            [-78.37606448752885, -7.639958975745895],
            [-78.46843748761417, -7.645744100750842],
            [-78.49944923764288, -7.697402350798764],
            [-78.55091598769017, -7.726203100825302],
            [-78.5812187377178, -7.71092185081086],
            [-78.6631899877927, -7.791983475885766],
            [-78.6960508628223, -7.762677600858228],
            [-78.71245823783674, -7.703982600803469],
            [-78.80376911291796, -7.63410647573761],
            [-78.79854298791302, -7.586603475693449],
            [-78.90776348800924, -7.598070100702992],
            [-78.94275261303984, -7.622670475725467],
            [-78.94434373804101, -7.598417975702858],
            [-78.8959083629984, -7.537437350646702],
            [-78.88668511298995, -7.484101600597149],
            [-78.84161936295011, -7.437617350554367],
            [-78.76012498787775, -7.401984350521967],
            [-78.69622661282062, -7.41336322553308],
            [-78.62984273776088, -7.445499975563473],
            [-78.64959561277914, -7.529017100641082],
            [-78.62457223775658, -7.548558600659431],
            [-78.49139261263497, -7.554873100666126],
            [-78.415632862565, -7.485070350601496],
            [-78.33583098749122, -7.495248225611308],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 14,
        FIRST_IDPR: '0307',
        NOMBPROV: 'GRAU',
        FIRST_NOMB: 'APURIMAC',
        LAST_DCTO: 'LEY',
        LAST_LEY: '9687',
        FIRST_FECH: '12/06/1985',
        LAST_FECHA: '11/12/1942',
        MIN_SHAPE_: 212996.492851,
        ha: 212996.49,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.47975648201933, -14.201673856858053],
            [-72.5698926071045, -14.254576231907414],
            [-72.61411698214626, -14.267104481919159],
            [-72.62719698215858, -14.341202231988166],
            [-72.67044823219935, -14.362172357007715],
            [-72.70775048223449, -14.34069060698775],
            [-72.70960598223625, -14.275555731927081],
            [-72.7517468572759, -14.260727356913335],
            [-72.8621652323797, -14.277481231929011],
            [-72.89929610741454, -14.217398731873054],
            [-72.89235385740804, -14.170393856829284],
            [-72.85123060736944, -14.094065606758203],
            [-72.81720523233749, -14.095454981759454],
            [-72.78892148231088, -13.980461606652353],
            [-72.72685410725249, -13.947320606621442],
            [-72.73580073226093, -13.914496106590908],
            [-72.64976373217992, -13.858234356538452],
            [-72.6401103571708, -13.818876981501763],
            [-72.66958073219861, -13.7703394814566],
            [-72.57356248210807, -13.73184960642066],
            [-72.5014251070399, -13.746939856434642],
            [-72.4603832320011, -13.790212606474867],
            [-72.4808887320205, -13.879525356558092],
            [-72.44999023199122, -13.922314481597867],
            [-72.45645698199736, -13.968937481641303],
            [-72.42045660696327, -14.028550356696742],
            [-72.42229060696498, -14.066965731732529],
            [-72.3734821069187, -14.07567960674056],
            [-72.35173635689804, -14.139706981800124],
            [-72.38633598193084, -14.206683606862555],
            [-72.47975648201933, -14.201673856858053],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 4,
        FIRST_IDPR: '1004',
        NOMBPROV: 'HUACAYBAMBA',
        FIRST_NOMB: 'HUANUCO',
        LAST_DCTO: 'LEY',
        LAST_LEY: '24340',
        FIRST_FECH: '14/11/1985',
        LAST_FECHA: '14/11/1985',
        MIN_SHAPE_: 170377.692981,
        ha: 170377.69,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.47336911074684, -9.01223435202574],
            [-76.48022261075323, -9.172076227174598],
            [-76.41310548569061, -9.177386727179542],
            [-76.35837498563957, -9.197154352197943],
            [-76.3104863605949, -9.176945352179136],
            [-76.27210548555911, -9.213951227213599],
            [-76.31011323559454, -9.196384727197227],
            [-76.39587498567454, -9.238437477236406],
            [-76.43837698571417, -9.22953910222811],
            [-76.51030473578133, -9.186144352187688],
            [-76.59207236085764, -9.168951227171682],
            [-76.6535761109151, -9.124496102130282],
            [-76.76302536101736, -9.107146602114105],
            [-76.81816211106893, -9.127230477132807],
            [-76.94496473618754, -9.074415977083627],
            [-76.98010161122043, -9.062918102072903],
            [-77.03718748627394, -8.999289102013647],
            [-77.10314261133573, -8.972433601988612],
            [-77.15953323638861, -8.897103476918451],
            [-77.17482036140294, -8.833617226859324],
            [-77.14328711137337, -8.780720726810044],
            [-77.08141786131534, -8.774990226804745],
            [-77.01705473625505, -8.840585976865833],
            [-76.89501361114081, -8.86149610188533],
            [-76.82230661107278, -8.83446097686015],
            [-76.73477936099096, -8.870781226894],
            [-76.68942973594858, -8.931324226950384],
            [-76.63221486089512, -9.045404352056602],
            [-76.56146286082907, -9.021369102034237],
            [-76.47336911074684, -9.01223435202574],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 3,
        FIRST_IDPR: '0607',
        NOMBPROV: 'HUALGAYOC',
        FIRST_NOMB: 'CAJAMARCA',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: 'EPOCA INDEP.',
        LAST_FECHA: 'EPOCA INDEP.',
        MIN_SHAPE_: 78993.2440605,
        ha: 78993.24,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.71841411283702, -6.739673849905801],
            [-78.7694166128817, -6.650173224822096],
            [-78.75801948787156, -6.632123099805367],
            [-78.72309361284063, -6.629455474803158],
            [-78.64370448777002, -6.675643599846694],
            [-78.56040048769461, -6.597847724774755],
            [-78.52965898766678, -6.609122849785409],
            [-78.45827536260157, -6.560466849740431],
            [-78.41297261256017, -6.59585747477357],
            [-78.38546873753515, -6.685251974856903],
            [-78.404842737553, -6.711530099881299],
            [-78.40197486255065, -6.815057349977717],
            [-78.45347761259796, -6.834194974995317],
            [-78.46596336260933, -6.806838474969781],
            [-78.5309551126686, -6.799365224962532],
            [-78.60137873773269, -6.867628850025682],
            [-78.615083612745, -6.851611600010679],
            [-78.63838798776567, -6.77781597494184],
            [-78.71841411283702, -6.739673849905801],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 6,
        FIRST_IDPR: '2204',
        NOMBPROV: 'HUALLAGA',
        FIRST_NOMB: 'SAN MARTIN',
        LAST_DCTO: 'LEY',
        LAST_LEY: '14649',
        FIRST_FECH: '20/05/1936',
        LAST_FECHA: '13/09/1963',
        MIN_SHAPE_: 241703.40208,
        ha: 241703.4,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.27551436149723, -6.384731099578619],
            [-77.17173248639992, -6.381169974575313],
            [-77.14658286137633, -6.351112474547315],
            [-77.1154847363472, -6.399205224592112],
            [-77.00919798624766, -6.413210974605167],
            [-76.95252148619461, -6.39716009959023],
            [-76.92102761116513, -6.471253724659237],
            [-76.85524273610359, -6.525403099709666],
            [-76.7969573610491, -6.645647224821676],
            [-76.78121573603435, -6.737406099907134],
            [-76.73446686099068, -6.802689474967942],
            [-76.71080173596856, -6.872230225032689],
            [-76.63391986089675, -6.986267725138903],
            [-76.63264823589556, -7.098967600243876],
            [-76.67040373593082, -7.127247100270212],
            [-76.71529848597277, -7.097690600242653],
            [-76.78388636103688, -7.162871975303384],
            [-76.81212798606326, -7.172978850312803],
            [-76.8508679860995, -7.072905600219587],
            [-76.90707998615207, -7.086179975231938],
            [-76.90930461115416, -7.00197810015352],
            [-76.95203423619415, -6.996318975148252],
            [-76.96546273620673, -6.943187475098759],
            [-77.01443936125257, -6.837115474999978],
            [-77.03919986127576, -6.737596474907305],
            [-77.02541986126285, -6.713501974884854],
            [-77.07403911130838, -6.639706974816106],
            [-77.0466113612827, -6.565689474747191],
            [-77.15822323638724, -6.501197099687113],
            [-77.19787236142442, -6.420986099612378],
            [-77.27551436149723, -6.384731099578619],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 11,
        FIRST_IDPR: '1005',
        NOMBPROV: 'HUAMALIES',
        FIRST_NOMB: 'HUANUCO',
        LAST_DCTO: 'LEY',
        LAST_LEY: 'S/N',
        FIRST_FECH: 'EPOCA INDEP.',
        LAST_FECHA: '02/01/1857',
        MIN_SHAPE_: 315852.74197,
        ha: 315852.74,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.94538861118794, -9.70256835266862],
            [-76.98104298622133, -9.724568352689095],
            [-77.02384173626143, -9.704326227670249],
            [-77.04848823628453, -9.647460977617264],
            [-76.99527148623469, -9.603443352576285],
            [-76.98151561122177, -9.565423852540894],
            [-76.93800973618103, -9.526578102504715],
            [-76.95233398619446, -9.469697227451743],
            [-76.93523623617844, -9.398548852385492],
            [-76.84229486109147, -9.261734352258069],
            [-76.81437498606537, -9.32600785231794],
            [-76.75748823601218, -9.362197227351645],
            [-76.7257441109825, -9.256634727253342],
            [-76.73373436098998, -9.20896685220895],
            [-76.78858011104124, -9.136238227141195],
            [-76.81816211106893, -9.127230477132807],
            [-76.76302536101736, -9.107146602114105],
            [-76.6535761109151, -9.124496102130282],
            [-76.59207236085764, -9.168951227171682],
            [-76.51030473578133, -9.186144352187688],
            [-76.43837698571417, -9.22953910222811],
            [-76.39587498567454, -9.238437477236406],
            [-76.31011323559454, -9.196384727197227],
            [-76.27210548555911, -9.213951227213599],
            [-76.24416411053303, -9.146988227151251],
            [-76.21399798550495, -9.2044003522047],
            [-76.09866011039742, -9.211749977211568],
            [-76.05679298535843, -9.281722602276727],
            [-76.08320898538304, -9.304480477297924],
            [-76.1409979854369, -9.303302602296812],
            [-76.2277011105177, -9.38002935236827],
            [-76.23534573552485, -9.418208977403825],
            [-76.29744536058271, -9.388478477376134],
            [-76.3114648605958, -9.416749977402473],
            [-76.37141411065173, -9.44314647742704],
            [-76.37782436065768, -9.487880852468713],
            [-76.4569238607315, -9.498568352478653],
            [-76.58304886084922, -9.552089852528495],
            [-76.69675386095541, -9.557720727533745],
            [-76.81057811106182, -9.617890602589773],
            [-76.94538861118794, -9.70256835266862],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 15,
        FIRST_IDPR: '0501',
        NOMBPROV: 'HUAMANGA',
        FIRST_NOMB: 'AYACUCHO',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: '15/07/1936',
        LAST_FECHA: 'EPOCA INDEP.',
        MIN_SHAPE_: 295389.881109,
        ha: 295389.88,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.83802935829173, -13.384433606097497],
            [-73.83348798328753, -13.471785856178844],
            [-73.90428910835351, -13.455087856163313],
            [-73.95987185840528, -13.500893606205974],
            [-73.99047223343382, -13.480883981187327],
            [-73.979946233424, -13.42804348113814],
            [-74.0079644834501, -13.412506731123672],
            [-74.08046873351766, -13.450576231159111],
            [-74.05562885849449, -13.535736356238422],
            [-74.12335748355761, -13.530953106233959],
            [-74.16012885859186, -13.497011731202345],
            [-74.20180860863069, -13.40618948111776],
            [-74.26212698368687, -13.396345731108598],
            [-74.3360176087557, -13.438749981148105],
            [-74.37838473379514, -13.41903710612973],
            [-74.43175973384486, -13.44911910615775],
            [-74.47575198388584, -13.453138731161497],
            [-74.57168035897516, -13.387120106100012],
            [-74.56689448397073, -13.341226606057264],
            [-74.63282998403214, -13.344780106060606],
            [-74.6860564840817, -13.286370981006202],
            [-74.64465423404313, -13.274136730994808],
            [-74.57098148397453, -13.184670480911484],
            [-74.54447260894983, -13.199394480925186],
            [-74.45843885886971, -13.190038105916477],
            [-74.4204257338343, -13.159539105888078],
            [-74.40800998382277, -13.069269980804023],
            [-74.38622998380245, -13.029309980766811],
            [-74.34587635876488, -13.02584460576358],
            [-74.27165148369573, -13.066972105801867],
            [-74.22873435865576, -13.01315235575176],
            [-74.16088473359255, -13.037535105774467],
            [-74.10399448353955, -12.982063230722805],
            [-74.07742685851481, -12.984748105725302],
            [-74.05812835849683, -13.062704105797895],
            [-73.970305358415, -13.173409105900992],
            [-73.9459402333923, -13.229095855952847],
            [-73.9255407333733, -13.324075606041301],
            [-73.86984960832142, -13.349537106065023],
            [-73.83802935829173, -13.384433606097497],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 4,
        FIRST_IDPR: '0503',
        NOMBPROV: 'HUANCA SANCOS',
        FIRST_NOMB: 'AYACUCHO',
        LAST_DCTO: 'LEY',
        LAST_LEY: '15410',
        FIRST_FECH: '11/11/1961',
        LAST_FECHA: '29/01/1965',
        MIN_SHAPE_: 283707.916403,
        ha: 283707.92,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.11025773354541, -13.908167981585251],
            [-74.10097860853675, -13.952540106626564],
            [-74.11604298355078, -14.028872981697665],
            [-74.1744298586052, -14.104710856768289],
            [-74.20797598363642, -14.126899356788945],
            [-74.32944873374956, -14.128629356790569],
            [-74.39232035880812, -14.167054731826347],
            [-74.43127735884441, -14.149304731809798],
            [-74.62319535902317, -14.137142606798498],
            [-74.64055223403932, -14.094454731758754],
            [-74.69834835909315, -14.078622106743993],
            [-74.72572435911864, -14.117035731779758],
            [-74.77252935916223, -14.090318356754887],
            [-74.7838828591728, -14.016945356686556],
            [-74.76795473415797, -13.978552356650798],
            [-74.7859231091747, -13.928788356604455],
            [-74.76036373415091, -13.831487231513849],
            [-74.76652535915663, -13.772234356458657],
            [-74.70728123410147, -13.745257856433541],
            [-74.64128910904002, -13.742269481430757],
            [-74.61666410901707, -13.707757856398635],
            [-74.52608410893271, -13.715335981405692],
            [-74.51986323392693, -13.736378731425255],
            [-74.42586723383938, -13.79756060648225],
            [-74.31388823373507, -13.740335356428954],
            [-74.25405660867935, -13.758994606446326],
            [-74.2409067336671, -13.783445856469097],
            [-74.15976473359153, -13.841370356523047],
            [-74.15836060859021, -13.867979481547824],
            [-74.11025773354541, -13.908167981585251],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 8,
        FIRST_IDPR: '2003',
        NOMBPROV: 'HUANCABAMBA',
        FIRST_NOMB: 'PIURA',
        LAST_DCTO: 'LEY',
        LAST_LEY: '15248',
        FIRST_FECH: '29/01/1965',
        LAST_FECHA: '04/12/1964',
        MIN_SHAPE_: 429652.108552,
        ha: 429652.11,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.64220898851659, -5.10721684837335],
            [-79.55598236346738, -5.076001848346493],
            [-79.49240823842891, -5.085261723356512],
            [-79.48210161342148, -5.024745973300489],
            [-79.45140236340085, -4.95474022323603],
            [-79.39345698836168, -4.880789098168366],
            [-79.38914261335815, -4.834281223125202],
            [-79.3500566133321, -4.881154348169438],
            [-79.32070111331167, -4.886853473175187],
            [-79.26303923827123, -4.968660098252109],
            [-79.21007036323203, -4.965242223249656],
            [-79.24508598825842, -5.001359348282793],
            [-79.29436123829554, -5.110480473383531],
            [-79.3222988633156, -5.116908223389071],
            [-79.34330661333101, -5.165367098433765],
            [-79.37840811335565, -5.190521473456542],
            [-79.3486523633356, -5.232576223496154],
            [-79.35904886334362, -5.288626973548089],
            [-79.32807223832219, -5.315914098573979],
            [-79.3044003633062, -5.391804723644945],
            [-79.29769923830217, -5.458126973706727],
            [-79.2689609882818, -5.513285098758497],
            [-79.3289609883257, -5.535080098777748],
            [-79.3983203633743, -5.537017598778263],
            [-79.37555073835958, -5.606189473842987],
            [-79.40068948837916, -5.749509723975731],
            [-79.3898203633723, -5.791089849014611],
            [-79.33406836333432, -5.893630849111026],
            [-79.3674101133588, -5.937220724150903],
            [-79.41860936339506, -5.970099599180389],
            [-79.49893948844769, -5.915189474127525],
            [-79.60044923851106, -5.939570349147491],
            [-79.62641986352503, -5.887449224098346],
            [-79.63142773852653, -5.829029349043918],
            [-79.68271098855526, -5.846445349058528],
            [-79.73732811358329, -5.837650349048583],
            [-79.71728123857058, -5.73934759895799],
            [-79.78693361360153, -5.6465878488695],
            [-79.83943748862367, -5.630027348852201],
            [-79.80040236360512, -5.562152348790686],
            [-79.77704686359466, -5.561915973791297],
            [-79.73738086357459, -5.509001973743516],
            [-79.68160936354698, -5.530980348765692],
            [-79.64253323852495, -5.485191348724319],
            [-79.74998636357896, -5.446435598685063],
            [-79.76843361358698, -5.424527348664111],
            [-79.74035736357268, -5.380933598624571],
            [-79.66282423853329, -5.368953098615803],
            [-79.65519723852843, -5.333298848582904],
            [-79.694101613547, -5.259195473512993],
            [-79.72033198855816, -5.182089848440638],
            [-79.71491598855421, -5.126632848389334],
            [-79.64220898851659, -5.10721684837335],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 8,
        FIRST_IDPR: '2106',
        NOMBPROV: 'HUANCANE',
        FIRST_NOMB: 'PUNO',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: 'EPOCA INDEP.',
        LAST_FECHA: 'EPOCA INDEP.',
        MIN_SHAPE_: 284717.79648099997,
        ha: 284717.8,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.89949160439971, -15.498894482973217],
            [-69.90511660440524, -15.506779732981231],
            [-69.91014197941061, -15.507731732982775],
            [-69.92106285442247, -15.506819357983359],
            [-69.92273835442427, -15.507385732984101],
            [-69.9274493544292, -15.509986607987098],
            [-69.9292012294311, -15.509459232986838],
            [-69.95274772945564, -15.524158733003388],
            [-69.95746310446042, -15.529260483008688],
            [-69.95911572946211, -15.530598108010112],
            [-69.96831247947176, -15.535706983015968],
            [-70.00378347951425, -15.470411732959896],
            [-70.02904110454237, -15.455591982949098],
            [-70.0225176045387, -15.399031107896116],
            [-70.01086860452617, -15.399957607895635],
            [-69.99970722951446, -15.395524107890248],
            [-69.99549597951011, -15.3928506078873],
            [-69.99466760450923, -15.392542982886908],
            [-69.9880903545022, -15.39221173288584],
            [-69.98473072949852, -15.393442107886584],
            [-69.98229372949602, -15.391714982884697],
            [-69.98183610449554, -15.391156232884123],
            [-69.97803497949165, -15.388852107881531],
            [-69.97526685448884, -15.385847482878443],
            [-69.97538510448915, -15.383410357876198],
            [-69.9760476044899, -15.38218010787514],
            [-69.97770810449191, -15.378777232872201],
            [-69.98705810450286, -15.363947232859621],
            [-69.99058897950724, -15.354593732851386],
            [-69.98907310450585, -15.350555732847486],
            [-69.98874172950558, -15.349940232846873],
            [-69.9887591045058, -15.346003357843246],
            [-69.98914622950626, -15.345637732842958],
            [-69.99179160450919, -15.344024732841762],
            [-69.995091729513, -15.339240232837737],
            [-69.99740485451562, -15.336669482835632],
            [-70.00389985452304, -15.328905357829203],
            [-70.00599897952539, -15.32709423282778],
            [-70.00418560452408, -15.316777357818033],
            [-70.00352935452474, -15.294645857797503],
            [-70.0040957295254, -15.294068357797046],
            [-69.99836310452025, -15.278426107781941],
            [-69.93883997945954, -15.241829982741026],
            [-69.93010422945028, -15.24263723274068],
            [-69.92708872944749, -15.23720473273528],
            [-69.92218135444257, -15.232922607730696],
            [-69.9207403544411, -15.232501232730144],
            [-69.91437847943432, -15.233411982730162],
            [-69.90555622942496, -15.234309107729871],
            [-69.90340347942261, -15.235492357730664],
            [-69.90111022942035, -15.23337473272842],
            [-69.89704485441655, -15.226680607721715],
            [-69.89685997941699, -15.217972732713664],
            [-69.89362097941415, -15.209831982705722],
            [-69.89148672941215, -15.206475357702333],
            [-69.88256635440375, -15.193482607689184],
            [-69.89423822941671, -15.18341598268142],
            [-69.89934572942266, -15.174939482674265],
            [-69.89931222942263, -15.174923607674243],
            [-69.89943360442301, -15.171443357671055],
            [-69.89750397942207, -15.156560482657056],
            [-69.89750397942211, -15.155764107656319],
            [-69.89663447942185, -15.146752732647894],
            [-69.89143210441755, -15.13128848263295],
            [-69.89147122941759, -15.130975107632665],
            [-69.8902962294165, -15.12933023263099],
            [-69.88019197940692, -15.116719357618022],
            [-69.87925197940606, -15.11515285761646],
            [-69.87660935440378, -15.108755857610191],
            [-69.84733985438146, -15.00488873251041],
            [-69.78352810432055, -14.954531732454898],
            [-69.72936935426405, -14.975917357466358],
            [-69.69682622923362, -14.952990232440001],
            [-69.6208961041593, -14.944984732419616],
            [-69.61379885415565, -14.913964857389788],
            [-69.53952110408348, -14.917459107378994],
            [-69.51766297906762, -14.876171357336666],
            [-69.52966385408297, -14.842957482308538],
            [-69.46592760403298, -14.770320357228687],
            [-69.4349999789957, -14.838677732284733],
            [-69.35379685392844, -14.801665732231937],
            [-69.34387135390746, -14.882726857303831],
            [-69.36158197891332, -14.949191357369095],
            [-69.29922847884157, -15.049503857445261],
            [-69.2852226038216, -15.094972607483186],
            [-69.23577347877305, -15.120251982492714],
            [-69.13152935366081, -15.233994107565266],
            [-69.17629610369488, -15.2613597326042],
            [-69.24063472876594, -15.186843732554985],
            [-69.29174560381662, -15.162267107546514],
            [-69.3983554789097, -15.212289107619128],
            [-69.48606310398807, -15.271581732693303],
            [-69.50858397901344, -15.248673857677046],
            [-69.5484262290466, -15.308678857740313],
            [-69.62986685413479, -15.264868982715566],
            [-69.65510910416012, -15.271365857726055],
            [-69.6789886041876, -15.242629732703737],
            [-69.72825235423896, -15.239061982708607],
            [-69.77273935427928, -15.308934607779983],
            [-69.81629935432692, -15.287595232766797],
            [-69.89428972940871, -15.295113482784503],
            [-69.90246172941308, -15.355759982841535],
            [-69.94289110445348, -15.396677482884478],
            [-69.89949160439971, -15.498894482973217],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 19,
        FIRST_IDPR: '0901',
        NOMBPROV: 'HUANCAVELICA',
        FIRST_NOMB: 'HUANCAVELICA',
        LAST_DCTO: 'LEY',
        LAST_LEY: '9505',
        FIRST_FECH: '29/10/1953',
        LAST_FECHA: '12/01/1942',
        MIN_SHAPE_: 419358.186721,
        ha: 419358.19,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.69918360909394, -12.843642605593883],
            [-74.73343948412584, -12.879488230627281],
            [-74.78293710917193, -12.877952855625825],
            [-74.82710923421307, -12.943060105686463],
            [-74.88493110926693, -12.926299605670868],
            [-74.91705835929683, -13.096578605829448],
            [-74.9132578592933, -13.16746685589545],
            [-74.9551154843323, -13.181851230908864],
            [-74.98895498436379, -13.141519105871298],
            [-75.02919485940127, -13.105861730838114],
            [-75.03703110940857, -12.939632980683283],
            [-75.0572287344274, -12.914255105659667],
            [-75.12065035948645, -12.931834855676021],
            [-75.1494711095133, -12.96005635570231],
            [-75.19208173455299, -12.947288605690423],
            [-75.24981973460675, -12.879239980627036],
            [-75.33477910968587, -12.936174355680071],
            [-75.38097535972891, -12.842558355592871],
            [-75.37130885971989, -12.825651605577146],
            [-75.44200335978573, -12.775222230530176],
            [-75.51389260985268, -12.788652355542677],
            [-75.57655073491104, -12.715142480474228],
            [-75.551818109888, -12.68142673044281],
            [-75.57961323491386, -12.641484355405629],
            [-75.53874448487582, -12.621694230387208],
            [-75.49085423483122, -12.647937355411617],
            [-75.41785385976324, -12.628973730393978],
            [-75.36239123471158, -12.539600355310752],
            [-75.27835898463331, -12.467830480243919],
            [-75.21551535957481, -12.463421105239796],
            [-75.21525360957456, -12.39484273017594],
            [-75.13100998449609, -12.37849985516073],
            [-75.0666953594362, -12.393955105175108],
            [-75.02426760939667, -12.434714855213077],
            [-75.00706248438065, -12.493226605267568],
            [-74.94385348432178, -12.533050730304655],
            [-74.86297460924648, -12.522789105295077],
            [-74.77968948416891, -12.463849605240197],
            [-74.72193160911512, -12.487480355262218],
            [-74.69205473408726, -12.521859355294218],
            [-74.68646285908208, -12.589855480357544],
            [-74.71162960910551, -12.634272855398917],
            [-74.778727359168, -12.63270923039746],
            [-74.76728435915736, -12.676474730438203],
            [-74.78597373417476, -12.78410698053844],
            [-74.72607235911896, -12.841449605591869],
            [-74.69918360909394, -12.843642605593883],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 28,
        FIRST_IDPR: '1201',
        NOMBPROV: 'HUANCAYO',
        FIRST_NOMB: 'JUNIN',
        LAST_DCTO: 'L.R.',
        LAST_LEY: '342',
        FIRST_FECH: '16/01/1941',
        LAST_FECHA: '06/09/1920',
        MIN_SHAPE_: 373484.08399,
        ha: 373484.08,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.72101460911423, -11.664661104495925],
            [-74.64254073404116, -11.700954104529727],
            [-74.64848935904669, -11.7378596045641],
            [-74.59669985899848, -11.818498104639215],
            [-74.57788860898096, -11.882384729698707],
            [-74.53600785894197, -11.878362979694948],
            [-74.48669535889603, -11.942206979754411],
            [-74.50764848391556, -11.987081979796201],
            [-74.54899948395406, -11.99699035480542],
            [-74.6591077340566, -11.995782479804308],
            [-74.71690010911043, -12.059419979863554],
            [-74.74860998413995, -12.041419979846799],
            [-74.83737360922264, -12.070818479874177],
            [-74.87077410925373, -12.06599197986967],
            [-74.93037423430924, -12.022858354829514],
            [-75.04705260941792, -12.01156360481898],
            [-75.07460660944356, -12.060146729864249],
            [-75.04832723441909, -12.170329729966861],
            [-75.07162785944078, -12.235202605027279],
            [-75.14022998450466, -12.342099980126822],
            [-75.13100998449609, -12.37849985516073],
            [-75.21525360957456, -12.39484273017594],
            [-75.21551535957481, -12.463421105239796],
            [-75.27835898463331, -12.467830480243919],
            [-75.36239123471158, -12.539600355310752],
            [-75.41785385976324, -12.628973730393978],
            [-75.49085423483122, -12.647937355411617],
            [-75.53874448487582, -12.621694230387208],
            [-75.57961323491386, -12.641484355405629],
            [-75.57184060990664, -12.564067855333537],
            [-75.53620110987345, -12.517275355289954],
            [-75.55958998489525, -12.410719980190715],
            [-75.4716099848133, -12.392279855173559],
            [-75.37665998472487, -12.339569980124484],
            [-75.31352685966608, -12.269804230059494],
            [-75.24444535960176, -12.241820355033438],
            [-75.26768910962339, -12.183168604978812],
            [-75.23184248459002, -12.120401854920356],
            [-75.23878623459647, -12.063067104866949],
            [-75.31851173467074, -12.041021479846426],
            [-75.33069473468207, -12.010312979817835],
            [-75.27920110963412, -11.980519479790097],
            [-75.31277535966537, -11.938003104750477],
            [-75.25070910960756, -11.80993560463121],
            [-75.17239848453464, -11.797658229619795],
            [-75.12928173449448, -11.842238854661328],
            [-75.09218698445993, -11.834144229653788],
            [-75.06248585943227, -11.888961104704826],
            [-75.01560373438862, -11.821251854641769],
            [-74.92148623430096, -11.846087104664909],
            [-74.8759946092586, -11.830151854650053],
            [-74.83860998422375, -11.762869979587409],
            [-74.7699999841599, -11.762349979586928],
            [-74.76532698415552, -11.707609729535935],
            [-74.72101460911423, -11.664661104495925],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 8,
        FIRST_IDPR: '0504',
        NOMBPROV: 'HUANTA',
        FIRST_NOMB: 'AYACUCHO',
        LAST_DCTO: 'LEY',
        LAST_LEY: '12362',
        FIRST_FECH: '27/12/1926',
        LAST_FECHA: '20/06/1955',
        MIN_SHAPE_: 385929.93544,
        ha: 385929.94,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.10399448353955, -12.982063230722805],
            [-74.16088473359255, -13.037535105774467],
            [-74.22873435865576, -13.01315235575176],
            [-74.27165148369573, -13.066972105801867],
            [-74.29655323371892, -12.93500873067899],
            [-74.31944998374027, -12.887279980634533],
            [-74.32361998374415, -12.832509980583527],
            [-74.29239998371506, -12.824759980576315],
            [-74.28919998371208, -12.758009980514156],
            [-74.34876998376757, -12.712429980471688],
            [-74.32752998374778, -12.681269980442671],
            [-74.34081998376016, -12.63445998039909],
            [-74.41511998382937, -12.490659980265168],
            [-74.42244998383619, -12.44520998022284],
            [-74.50353998391172, -12.449809980227132],
            [-74.54842998395353, -12.375140105157586],
            [-74.5759899839792, -12.373169980155753],
            [-74.58117698398404, -12.332669355118043],
            [-74.55211723395696, -12.287339855075825],
            [-74.44396923385624, -12.271973605061499],
            [-74.41628985883047, -12.304769980092068],
            [-74.38480998380112, -12.268629980058392],
            [-74.3888599838049, -12.192669979987649],
            [-74.34594560876494, -12.173736729970017],
            [-74.32187298374251, -12.229777105022206],
            [-74.27051573369468, -12.239144730030949],
            [-74.18761823361747, -12.314375980101003],
            [-74.12143948355585, -12.333601480118912],
            [-74.09835723353432, -12.284913605073564],
            [-73.98045160842446, -12.242588355034144],
            [-73.96585898341087, -12.336451355121568],
            [-73.88679248333717, -12.40539698018576],
            [-73.83077773328498, -12.53050810530227],
            [-73.85380273330645, -12.645472605409354],
            [-73.8881074833384, -12.711048855470413],
            [-73.98808785843156, -12.74986910550655],
            [-73.99568373343867, -12.83829385558892],
            [-74.14423648357706, -12.877754105625659],
            [-74.10399448353955, -12.982063230722805],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 11,
        FIRST_IDPR: '1001',
        NOMBPROV: 'HUANUCO',
        FIRST_NOMB: 'HUANUCO',
        LAST_DCTO: 'LEY',
        LAST_LEY: 'S/N',
        FIRST_FECH: '10/05/1955',
        LAST_FECHA: '02/01/1857',
        MIN_SHAPE_: 407098.49778,
        ha: 407098.5,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.52091986079121, -10.194937478127184],
            [-76.54678511081535, -10.111697228049666],
            [-76.5957869858611, -10.10193560304058],
            [-76.60613473587074, -10.070240228011059],
            [-76.5782343608447, -10.030456977973998],
            [-76.57047461083748, -9.959232477907658],
            [-76.54877148581721, -9.922632977873588],
            [-76.45508598572977, -9.881613102835384],
            [-76.46739261074126, -9.820171852778165],
            [-76.46302348573718, -9.706246102672072],
            [-76.47696873575019, -9.673880852641924],
            [-76.4268261107034, -9.6500156026197],
            [-76.36502361064575, -9.577843727552507],
            [-76.36072661064173, -9.546806602523585],
            [-76.31346873559767, -9.51658010249542],
            [-76.27214648555916, -9.525488227503722],
            [-76.20340036049504, -9.499158227479196],
            [-76.15993548545455, -9.46944335245155],
            [-76.07173436037235, -9.47178710245373],
            [-76.01136323531607, -9.50803710248749],
            [-75.94210736025154, -9.487933602468752],
            [-75.89240236020525, -9.43315235241775],
            [-75.85313473516867, -9.426382852411423],
            [-75.8111718601296, -9.391265602378722],
            [-75.78568361010583, -9.4050878523916],
            [-75.79072661011053, -9.466005852448363],
            [-75.73836523506176, -9.60816010258074],
            [-75.72125198504582, -9.617507852589421],
            [-75.79782811011715, -9.702785102668855],
            [-75.81035548512881, -9.76413472772601],
            [-75.79458986011413, -9.809890602768595],
            [-75.82456836014208, -9.83196097778914],
            [-75.87403323518814, -9.792919977752797],
            [-76.05857423536007, -9.821220727779146],
            [-76.08680861038638, -9.881322227835133],
            [-76.11720698541471, -9.903101602855397],
            [-76.13620898543243, -9.986281227932889],
            [-76.17037886046427, -9.985285102931941],
            [-76.28678323557278, -10.071123103011885],
            [-76.37437298565446, -10.149460978084836],
            [-76.41305861069056, -10.140865228076832],
            [-76.51000786078103, -10.152273353087468],
            [-76.52091986079121, -10.194937478127184],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 12,
        FIRST_IDPR: '1506',
        NOMBPROV: 'HUARAL',
        FIRST_NOMB: 'LIMA',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: 'EPOC.INDEP.',
        LAST_FECHA: 'EPOC.INDEP.',
        MIN_SHAPE_: 365745.2239,
        ha: 365745.22,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.49121286076347, -11.089441353960218],
            [-76.51840236078887, -11.116078103985025],
            [-76.49207723576428, -11.189313979053235],
            [-76.47769611075086, -11.29352885415028],
            [-76.45702348573157, -11.334427729188377],
            [-76.48348211075624, -11.344419729197678],
            [-76.54607486081468, -11.316090979171287],
            [-76.59817673586333, -11.326581979181059],
            [-76.69457061095338, -11.429327104276734],
            [-76.76827911102227, -11.42044997926849],
            [-76.89225361113823, -11.492188604335263],
            [-76.85909011110718, -11.508337354350319],
            [-77.00489061124371, -11.613472604448216],
            [-77.05181061128766, -11.576134354413398],
            [-77.12388873635527, -11.655471104487281],
            [-77.19352348642064, -11.678322229508533],
            [-77.21603711144179, -11.638968729471861],
            [-77.28003136150194, -11.587447229423843],
            [-77.30475198652515, -11.512234354353811],
            [-77.38269336159851, -11.447179729293172],
            [-77.3255499865447, -11.388759979238813],
            [-77.3329877365517, -11.344188479197284],
            [-77.30349223652395, -11.27410160413205],
            [-77.24180661146598, -11.304335979160234],
            [-77.2254374864506, -11.34153910419487],
            [-77.1644994863934, -11.366226104217882],
            [-77.13127148636218, -11.298210979154566],
            [-77.07143823630607, -11.268425604126847],
            [-77.06002736129537, -11.195097604058551],
            [-77.08505111131883, -11.184608604048792],
            [-77.07545361130983, -11.103360603973133],
            [-77.02040998625823, -11.135209979002786],
            [-76.94267998618541, -11.07824997894977],
            [-76.87853711112538, -11.08276210395397],
            [-76.82575723607602, -11.103684353973467],
            [-76.76216298601656, -11.035665478910142],
            [-76.70486123596301, -11.055384853928507],
            [-76.57694923584353, -11.016527353892325],
            [-76.53130273580089, -11.036064478910513],
            [-76.49121286076347, -11.089441353960218],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 12,
        FIRST_IDPR: '0201',
        NOMBPROV: 'HUARAZ',
        FIRST_NOMB: 'ANCASH',
        LAST_DCTO: 'LEY',
        LAST_LEY: '12643',
        FIRST_FECH: '16/10/1933',
        LAST_FECHA: '02/02/1956',
        MIN_SHAPE_: 251023.88977,
        ha: 251023.89,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.90099611208649, -9.442234352425498],
            [-77.7620318619554, -9.417154727402359],
            [-77.74419598693856, -9.370708977359131],
            [-77.69048161188795, -9.377838477365826],
            [-77.65553611185501, -9.442273852425874],
            [-77.61300586181495, -9.422083977407116],
            [-77.57639761178045, -9.374120977362482],
            [-77.46847011167885, -9.367335602356235],
            [-77.40209173661643, -9.347337852337668],
            [-77.33009086154874, -9.382391727370344],
            [-77.3261954865451, -9.47193147745373],
            [-77.26970573649206, -9.648738852618411],
            [-77.31099436153083, -9.674310602642185],
            [-77.42438111163744, -9.679717477647173],
            [-77.5030977367115, -9.662697227631282],
            [-77.53625148674276, -9.737866352701255],
            [-77.54161136174778, -9.705886727671476],
            [-77.62129886182282, -9.620830102592189],
            [-77.72481636192046, -9.696347602662387],
            [-77.76036136195404, -9.787990227747677],
            [-77.78079836197332, -9.800669477759476],
            [-77.86785611205548, -9.763784477724977],
            [-77.96140236214384, -9.780244102740122],
            [-77.97969723716105, -9.711839852676384],
            [-78.04189061221972, -9.689902352655787],
            [-78.00609373718594, -9.680642602647248],
            [-77.96079261214307, -9.615372352586611],
            [-77.98432936216521, -9.540384852516713],
            [-77.9363338621199, -9.528074352505357],
            [-77.90099611208649, -9.442234352425498],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 16,
        FIRST_IDPR: '0210',
        NOMBPROV: 'HUARI',
        FIRST_NOMB: 'ANCASH',
        LAST_DCTO: 'LEY',
        LAST_LEY: '12313',
        FIRST_FECH: 'EPOCA INDEP.',
        LAST_FECHA: '10/05/1955',
        MIN_SHAPE_: 279161.613194,
        ha: 279161.61,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.02384173626143, -9.704326227670249],
            [-77.02779886126513, -9.764121102725909],
            [-77.09279373632603, -9.836754477793544],
            [-77.18713473641454, -9.836212852793032],
            [-77.24384773646776, -9.80573247776462],
            [-77.22777298645268, -9.713389227678633],
            [-77.26970573649206, -9.648738852618411],
            [-77.3261954865451, -9.47193147745373],
            [-77.33009086154874, -9.382391727370344],
            [-77.3191841115385, -9.324105602316077],
            [-77.27529661149727, -9.278269477273383],
            [-77.26205161148481, -9.20778722720776],
            [-77.2215568614468, -9.16757797717032],
            [-77.17387123640206, -9.159956227163216],
            [-77.14221286137236, -9.202418102202781],
            [-77.09353711132673, -9.212511727212197],
            [-77.07659761131085, -9.273109352268632],
            [-77.02686523626424, -9.30091997729456],
            [-76.95973436120138, -9.207748102207782],
            [-76.94181048618461, -9.128992227134438],
            [-76.94496473618754, -9.074415977083627],
            [-76.81816211106893, -9.127230477132807],
            [-76.78858011104124, -9.136238227141195],
            [-76.73373436098998, -9.20896685220895],
            [-76.7257441109825, -9.256634727253342],
            [-76.75748823601218, -9.362197227351645],
            [-76.81437498606537, -9.32600785231794],
            [-76.84229486109147, -9.261734352258069],
            [-76.93523623617844, -9.398548852385492],
            [-76.95233398619446, -9.469697227451743],
            [-76.93800973618103, -9.526578102504715],
            [-76.98151561122177, -9.565423852540894],
            [-76.99527148623469, -9.603443352576285],
            [-77.04848823628453, -9.647460977617264],
            [-77.02384173626143, -9.704326227670249],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 5,
        FIRST_IDPR: '0211',
        NOMBPROV: 'HUARMEY',
        FIRST_NOMB: 'ANCASH',
        LAST_DCTO: 'LEY',
        LAST_LEY: '24034',
        FIRST_FECH: '05/03/1936',
        LAST_FECHA: '20/12/1984',
        MIN_SHAPE_: 392513.35816,
        ha: 392513.36,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.04189061221972, -9.689902352655787],
            [-77.97969723716105, -9.711839852676384],
            [-77.96140236214384, -9.780244102740122],
            [-77.90312111208885, -9.822988227780039],
            [-77.85388511204232, -9.817092602774652],
            [-77.82553323701563, -9.908369102859693],
            [-77.77979098697244, -9.905056602856686],
            [-77.73470898692986, -9.84685347780254],
            [-77.55249023675806, -9.872179727826332],
            [-77.60816973681058, -9.950135977898865],
            [-77.63140823683251, -10.022017602965802],
            [-77.77527536196824, -10.004193352949006],
            [-77.72186523691786, -10.078679228018448],
            [-77.69878323689613, -10.14139447807687],
            [-77.65625761185599, -10.151978478086775],
            [-77.67440436187314, -10.20671147813773],
            [-77.69642736189395, -10.256850353184392],
            [-77.69875173689618, -10.322104103245161],
            [-77.75704098695124, -10.344216853265666],
            [-77.76021161195428, -10.43022122834576],
            [-77.79617773698831, -10.483292853395128],
            [-77.77244336196587, -10.512478228422333],
            [-77.77137648696491, -10.571614603477421],
            [-77.83298923702316, -10.567483103473453],
            [-77.8862714870736, -10.611919978514722],
            [-77.90816011209425, -10.544287103451717],
            [-77.96062298714385, -10.492519478403372],
            [-77.98034173716246, -10.439548853354003],
            [-78.01475973719499, -10.409630853326039],
            [-78.01060348719102, -10.374699103293553],
            [-78.05454686223257, -10.345955103266643],
            [-78.10225973727748, -10.200150353130757],
            [-78.16357036233536, -10.147191353081238],
            [-78.1753417373463, -10.04584960298683],
            [-78.24264848740951, -9.873646477826217],
            [-78.22621098739384, -9.795281227753321],
            [-78.1448277373169, -9.714579102678476],
            [-78.08740423726272, -9.720489977684156],
            [-78.04189061221972, -9.689902352655787],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 32,
        FIRST_IDPR: '1507',
        NOMBPROV: 'HUAROCHIRI',
        FIRST_NOMB: 'LIMA',
        LAST_DCTO: 'LEY',
        LAST_LEY: '15246',
        FIRST_FECH: '11/10/1954',
        LAST_FECHA: '04/12/1964',
        MIN_SHAPE_: 573400.84189,
        ha: 573400.84,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.345997735628, -12.337726480122736],
            [-76.3938276106726, -12.323642355109623],
            [-76.45276561072758, -12.404263730184685],
            [-76.50119986077279, -12.40064998018134],
            [-76.53765748580682, -12.339368605124282],
            [-76.55733398582517, -12.275728480064975],
            [-76.59583011086113, -12.314867230101452],
            [-76.6166824858806, -12.372826855155418],
            [-76.66799223592851, -12.36023048014369],
            [-76.67398998593413, -12.319519980105756],
            [-76.63678886089939, -12.279363980068359],
            [-76.62109961088473, -12.226883105019494],
            [-76.64862798591045, -12.208160230002058],
            [-76.71615898597352, -12.098418729899873],
            [-76.67807998593794, -12.052979854857552],
            [-76.70707811096506, -12.02949610483568],
            [-76.64696086090886, -11.952875604764317],
            [-76.72793998598455, -11.875709979692452],
            [-76.79167998604414, -11.927609979740788],
            [-76.91655198616095, -11.95983710477078],
            [-76.91210748615681, -11.88927472970508],
            [-76.94227536118507, -11.860867229678595],
            [-76.90660761115167, -11.79612397961831],
            [-76.85123773609985, -11.798149729620214],
            [-76.80486761105647, -11.755873854580864],
            [-76.75339211100832, -11.731529604558165],
            [-76.74005086099585, -11.69811972952707],
            [-76.6654896109262, -11.658767229490431],
            [-76.57498236084167, -11.632384729465874],
            [-76.53611311080536, -11.55909485439761],
            [-76.54292011081174, -11.517492979358861],
            [-76.48810011076057, -11.45750610430301],
            [-76.40058498567893, -11.507174354349269],
            [-76.37683911065677, -11.50148860434396],
            [-76.28678873557278, -11.591232604427553],
            [-76.22370511051399, -11.562733229401],
            [-76.1877071104804, -11.592789854429006],
            [-76.20488273549641, -11.659531104491146],
            [-76.20232773549405, -11.72804922955497],
            [-76.13694536043312, -11.780957229604226],
            [-76.05131111035328, -11.892237979707872],
            [-76.05186148535381, -11.938962104751385],
            [-75.99317973529912, -11.994679729803273],
            [-76.0197621103239, -12.062770854866674],
            [-76.06135073536267, -12.088517604890665],
            [-76.05679298535841, -12.13273247993184],
            [-76.08622323538583, -12.16040097995762],
            [-76.0694692353702, -12.207410980001388],
            [-76.2168636105076, -12.264251355054313],
            [-76.25030161053877, -12.29973035508738],
            [-76.32372386060722, -12.291551855079732],
            [-76.345997735628, -12.337726480122736],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 12,
        FIRST_IDPR: '1508',
        NOMBPROV: 'HUAURA',
        FIRST_NOMB: 'LIMA',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: 'EPOC.INDEP.',
        LAST_FECHA: 'EPOC.INDEP.',
        MIN_SHAPE_: 492694.12569,
        ha: 492694.13,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.70151561189904, -10.886117353770366],
            [-77.59952711180276, -10.860028353746221],
            [-77.56138636176678, -10.87839785376334],
            [-77.52571098673312, -10.931980478813294],
            [-77.44429298665642, -10.916097603798569],
            [-77.4622207366733, -10.87006635375567],
            [-77.45815423666944, -10.809955103699703],
            [-77.40249273661703, -10.76797572866067],
            [-77.38557036160107, -10.707593728604433],
            [-77.39530998661024, -10.661539978561535],
            [-77.34470323656262, -10.607980478511678],
            [-77.29432823651528, -10.66039235356053],
            [-77.19722998642409, -10.686829853585198],
            [-77.12272298635416, -10.648956103549933],
            [-77.05509361129073, -10.691384103589481],
            [-77.01096648624939, -10.695993103593766],
            [-77.03483611127176, -10.732751978627977],
            [-77.14028911137063, -10.75610347864972],
            [-77.17662111140473, -10.778074228670176],
            [-77.15649411138585, -10.84924410373645],
            [-77.16803998639668, -10.918539978800963],
            [-77.19423011142125, -10.957504478837242],
            [-77.13883998636928, -11.011229978887306],
            [-77.07763023631186, -11.012459978888474],
            [-76.96890023620998, -10.86390997875015],
            [-76.88923998613541, -10.84412997873174],
            [-76.79860998605061, -10.919069978801554],
            [-76.74311998599875, -10.934329853815775],
            [-76.69509573595386, -10.879273478764487],
            [-76.65420686091566, -10.88198247876703],
            [-76.58821161085403, -10.84505072873263],
            [-76.56507661083242, -10.966178978845438],
            [-76.57694923584353, -11.016527353892325],
            [-76.70486123596301, -11.055384853928507],
            [-76.76216298601656, -11.035665478910142],
            [-76.82575723607602, -11.103684353973467],
            [-76.87853711112538, -11.08276210395397],
            [-76.94267998618541, -11.07824997894977],
            [-77.02040998625823, -11.135209979002786],
            [-77.07545361130983, -11.103360603973133],
            [-77.08505111131883, -11.184608604048792],
            [-77.06002736129537, -11.195097604058551],
            [-77.07143823630607, -11.268425604126847],
            [-77.13127148636218, -11.298210979154566],
            [-77.1644994863934, -11.366226104217882],
            [-77.2254374864506, -11.34153910419487],
            [-77.24180661146598, -11.304335979160234],
            [-77.30349223652395, -11.27410160413205],
            [-77.3329877365517, -11.344188479197284],
            [-77.3255499865447, -11.388759979238813],
            [-77.38269336159851, -11.447179729293172],
            [-77.49814261170724, -11.377154354227864],
            [-77.64292386184388, -11.304476604160046],
            [-77.64165423684267, -11.21450972907625],
            [-77.59234961179608, -11.182080104046113],
            [-77.6334159868348, -11.054507853927259],
            [-77.66174998686154, -11.012654353888266],
            [-77.65782811185781, -10.946505853826668],
            [-77.70151561189904, -10.886117353770366],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 10,
        FIRST_IDPR: '0212',
        NOMBPROV: 'HUAYLAS',
        FIRST_NOMB: 'ANCASH',
        LAST_DCTO: 'LEY',
        LAST_LEY: '4662',
        FIRST_FECH: '02/01/1857',
        LAST_FECHA: '09/05/1923',
        MIN_SHAPE_: 229027.783013,
        ha: 229027.78,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.97202736215273, -8.692001976726722],
            [-77.91535736209941, -8.74011322677164],
            [-77.87995111206604, -8.684037101719479],
            [-77.81097273700107, -8.662019226699073],
            [-77.7724706119648, -8.673824476710127],
            [-77.72073023691607, -8.65653147669407],
            [-77.61437948681599, -8.750993601782152],
            [-77.59274311179566, -8.757665226788385],
            [-77.5812009867848, -8.771116601800916],
            [-77.60462611180688, -8.886709726908563],
            [-77.57426573677829, -8.900442976921365],
            [-77.63235723683302, -9.009759977023124],
            [-77.69981448689657, -9.056933602066968],
            [-77.78115623697323, -9.085154352093161],
            [-77.77718636196956, -9.181542602182937],
            [-77.79752161198877, -9.229190727227259],
            [-77.83947073702828, -9.217574227216396],
            [-77.86126761204886, -9.251189602247656],
            [-78.03716761221472, -9.243706977240347],
            [-78.06681148724255, -9.185971477186486],
            [-78.02132686219967, -9.171457102173076],
            [-78.06222286223814, -9.128433977132914],
            [-78.05932573723538, -9.086125727093547],
            [-77.99716311217675, -9.017221852029511],
            [-77.94352948712617, -8.97489897699023],
            [-77.9403059871231, -8.926109476944807],
            [-77.99724923717663, -8.845853351869945],
            [-78.01459411219287, -8.76635597679589],
            [-77.97202736215273, -8.692001976726722],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 16,
        FIRST_IDPR: '0906',
        NOMBPROV: 'HUAYTARA',
        FIRST_NOMB: 'HUANCAVELICA',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: '26/01/1956',
        LAST_FECHA: 'EPOCA INDEP.',
        MIN_SHAPE_: 646453.17908,
        ha: 646453.18,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.05904998442908, -14.129619981791498],
            [-75.10884173447546, -14.032884731701415],
            [-75.16567998452838, -14.072529981738306],
            [-75.21055473457017, -14.050441356717757],
            [-75.23232910959045, -14.00476998167522],
            [-75.33322773468441, -14.01598448168567],
            [-75.4338163597781, -13.913900356590597],
            [-75.54550210988212, -13.88948248156785],
            [-75.56337698489877, -13.84086923152259],
            [-75.52487848486292, -13.817899231501173],
            [-75.55458235989056, -13.749571231437574],
            [-75.52943985986717, -13.740066856428697],
            [-75.48486923482567, -13.650972231345737],
            [-75.51400710985278, -13.617092231314171],
            [-75.4773104848186, -13.525484356228866],
            [-75.43461135977884, -13.46964060617688],
            [-75.31034773466313, -13.450247106158795],
            [-75.29113260964523, -13.401675481113585],
            [-75.25582735961235, -13.417471981128275],
            [-75.20550548456548, -13.344433856060254],
            [-75.20416748456422, -13.311974856030025],
            [-75.16392885952676, -13.27393685599462],
            [-75.16799998453054, -13.224511730948576],
            [-75.04910548441981, -13.186556480913232],
            [-74.98895498436379, -13.141519105871298],
            [-74.9551154843323, -13.181851230908864],
            [-74.9132578592933, -13.16746685589545],
            [-74.8414236092264, -13.143722980873365],
            [-74.72164085911486, -13.179189605906389],
            [-74.72212410911528, -13.289642106009259],
            [-74.6860564840817, -13.286370981006202],
            [-74.63282998403214, -13.344780106060606],
            [-74.69945998409419, -13.363509981078044],
            [-74.76386323415416, -13.34139260605744],
            [-74.86874998425186, -13.35989998107467],
            [-74.89772010927884, -13.395309981107657],
            [-74.90209998428291, -13.466439981173885],
            [-74.86217998424573, -13.481789981188177],
            [-74.78241998417145, -13.57850998127827],
            [-74.76652535915663, -13.772234356458657],
            [-74.76036373415091, -13.831487231513849],
            [-74.7859231091747, -13.928788356604455],
            [-74.76795473415797, -13.978552356650798],
            [-74.7838828591728, -14.016945356686556],
            [-74.77252935916223, -14.090318356754887],
            [-74.84138473422637, -14.073402356739134],
            [-74.9423613593204, -14.094687481758962],
            [-75.02912998440121, -14.071949981737779],
            [-75.05904998442908, -14.129619981791498],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 14,
        FIRST_IDPR: '1101',
        NOMBPROV: 'ICA',
        FIRST_NOMB: 'ICA',
        LAST_DCTO: 'LEY',
        LAST_LEY: 'S/N',
        FIRST_FECH: '18/05/1984',
        LAST_FECHA: '14/06/1876',
        MIN_SHAPE_: 781989.44312,
        ha: 781989.44,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.55458235989056, -13.749571231437574],
            [-75.52487848486292, -13.817899231501173],
            [-75.56337698489877, -13.84086923152259],
            [-75.54550210988212, -13.88948248156785],
            [-75.4338163597781, -13.913900356590597],
            [-75.33322773468441, -14.01598448168567],
            [-75.23232910959045, -14.00476998167522],
            [-75.21055473457017, -14.050441356717757],
            [-75.23972073459736, -14.084046856749026],
            [-75.22616598458471, -14.12641410678849],
            [-75.2397891095974, -14.3207832319695],
            [-75.22791985958635, -14.395406232038992],
            [-75.30124998465466, -14.472537107110833],
            [-75.31685160966917, -14.530775357165071],
            [-75.3636874847128, -14.582089732212852],
            [-75.36089060971018, -14.620730482248842],
            [-75.42633973477113, -14.643058607269626],
            [-75.41061523475649, -14.68523823230892],
            [-75.43267373477705, -14.749956982369177],
            [-75.41608398476157, -14.774743982392257],
            [-75.44029885978414, -14.894900357504152],
            [-75.49404485983419, -14.929660107536533],
            [-75.57452148490914, -14.86779698247892],
            [-75.67994136000735, -14.817822357432377],
            [-75.7843554851046, -14.74828910736763],
            [-75.85556636017093, -14.716281232337826],
            [-75.8557558601711, -14.69444335731749],
            [-75.91646486022766, -14.659175732284652],
            [-75.93204686024217, -14.568281232199995],
            [-75.97419723528144, -14.525253857159917],
            [-75.97227923527966, -14.470498107108925],
            [-76.03667773533967, -14.42605473206754],
            [-76.00350198530873, -14.390206982034153],
            [-75.98617573529259, -14.296318356946733],
            [-76.03311911033633, -14.27662310692839],
            [-76.0714941103721, -14.206890606863428],
            [-75.98654098529295, -14.06948635673548],
            [-76.0254511103292, -14.005025356675448],
            [-76.02504686032883, -13.959226606632802],
            [-76.05594723535762, -13.929296856604935],
            [-76.02797848533156, -13.840119106521877],
            [-75.88454098519793, -13.855173856535886],
            [-75.85321898516874, -13.837053731519037],
            [-75.78889061010882, -13.856441356537097],
            [-75.70165036002757, -13.817195356500532],
            [-75.64799610997757, -13.80743948149144],
            [-75.55458235989056, -13.749571231437574],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 3,
        FIRST_IDPR: '1803',
        NOMBPROV: 'ILO',
        FIRST_NOMB: 'MOQUEGUA',
        LAST_DCTO: 'D.L.',
        LAST_LEY: '18298',
        FIRST_FECH: 'EPOCA INDEP.',
        LAST_FECHA: '26/05/1970',
        MIN_SHAPE_: 136344.773263,
        ha: 136344.77,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.9078148554453, -17.6472196100418],
            [-70.91249998044992, -17.687369985079258],
            [-70.99299623053693, -17.679119110074797],
            [-71.10314260565363, -17.755275360149337],
            [-71.13944335569145, -17.82148236021195],
            [-71.20564848076135, -17.768648485164803],
            [-71.37221873093414, -17.681712735088034],
            [-71.34108198090243, -17.636988235045795],
            [-71.35820885592025, -17.588826235001388],
            [-71.37256635593552, -17.475390609896255],
            [-71.39458985595839, -17.389130859816493],
            [-71.4907989810563, -17.2850056097214],
            [-71.44095610600608, -17.2517166096896],
            [-71.36936910593306, -17.312234359744508],
            [-71.37633548093999, -17.351283859780956],
            [-71.31561323087747, -17.419953109843515],
            [-71.25934573081936, -17.439945359860786],
            [-71.21587298077436, -17.434665984854778],
            [-71.07395123062513, -17.48746673489972],
            [-71.07287885562364, -17.524703109934237],
            [-70.99659760554141, -17.620787110020807],
            [-70.9078148554453, -17.6472196100418],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 6,
        FIRST_IDPR: '0407',
        NOMBPROV: 'ISLAY',
        FIRST_NOMB: 'AREQUIPA',
        LAST_DCTO: 'LEY',
        LAST_LEY: 'S/N',
        FIRST_FECH: '03/01/1879',
        LAST_FECHA: '03/01/1879',
        MIN_SHAPE_: 384689.991903,
        ha: 384689.99,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.99336248155298, -16.79401010926991],
            [-71.9910964815508, -16.79036523426649],
            [-71.99019623154992, -16.788839234265073],
            [-71.98719023154702, -16.78414723426068],
            [-71.93617248149761, -16.712392859193567],
            [-71.93440248149592, -16.71004298419136],
            [-71.92816923148986, -16.70168873418355],
            [-71.92423248148603, -16.696062109178285],
            [-71.90996548147218, -16.67504498415863],
            [-71.89698023145954, -16.68824760917081],
            [-71.88845060645124, -16.69634623417829],
            [-71.88351448144641, -16.700956359182534],
            [-71.87832635644136, -16.706088984187275],
            [-71.87072748143393, -16.71343423419406],
            [-71.87044523143365, -16.7215423592016],
            [-71.86997223143315, -16.734374984213545],
            [-71.87007898143325, -16.74328798422184],
            [-71.86933898143249, -16.763486859240633],
            [-71.76523585633075, -16.708244359188335],
            [-71.72447210629068, -16.699655484179946],
            [-71.71470648128106, -16.699417109179617],
            [-71.7103576062768, -16.699279734179438],
            [-71.67354585624044, -16.705087609184456],
            [-71.66965485623659, -16.704645109183993],
            [-71.6631851062302, -16.701477109180974],
            [-71.65914148122621, -16.700885734180382],
            [-71.65835573122546, -16.7002697341798],
            [-71.65385435622099, -16.698850609178432],
            [-71.65273285621987, -16.697524984177193],
            [-71.65120698121837, -16.695415484175214],
            [-71.64585873121308, -16.696420609176073],
            [-71.64159398120883, -16.700492859179814],
            [-71.64119723120844, -16.701345484180603],
            [-71.64132685620855, -16.704761609183794],
            [-71.64093785620818, -16.705482484184454],
            [-71.63621510620354, -16.696874609176394],
            [-71.63216398119954, -16.692344609172128],
            [-71.62973023119714, -16.685426734165652],
            [-71.62755585619499, -16.68328660916365],
            [-71.6249618561924, -16.68297198416331],
            [-71.62266535619014, -16.682029734162413],
            [-71.61782835618534, -16.68245698416276],
            [-71.61176298117933, -16.683362984163512],
            [-71.6111526061787, -16.683816859163937],
            [-71.60397335617157, -16.684993734164944],
            [-71.60297398117058, -16.686017984165883],
            [-71.59988398116751, -16.687032734166795],
            [-71.59796148116561, -16.687643109167333],
            [-71.58384723115155, -16.691095359170365],
            [-71.57639310614411, -16.695442234174312],
            [-71.57484435614258, -16.695957234174767],
            [-71.57429510614203, -16.696405484175173],
            [-71.57255548114027, -16.699888234178395],
            [-71.57134248113908, -16.699325609177855],
            [-71.56915285613691, -16.697267484175914],
            [-71.56524660613302, -16.69564623417436],
            [-71.56215673112996, -16.69244198417134],
            [-71.55810535612594, -16.687734609166895],
            [-71.55698398112483, -16.686511984165744],
            [-71.53967285610753, -16.69650073417481],
            [-71.5372542311051, -16.698114359176262],
            [-71.5354767311033, -16.700756109178702],
            [-71.53520198110303, -16.702249484180093],
            [-71.5350723561029, -16.703556109181296],
            [-71.53445435610227, -16.71037098418764],
            [-71.53160098109939, -16.71213910918923],
            [-71.53063960609843, -16.714817109191713],
            [-71.53007510609787, -16.716247609193037],
            [-71.53042598109819, -16.719127484195713],
            [-71.5310363560988, -16.723220859199532],
            [-71.5314712310992, -16.72634885920246],
            [-71.53112035609884, -16.7294178592053],
            [-71.52548223109319, -16.73509210921049],
            [-71.52418523109189, -16.73510935921049],
            [-71.5217056060894, -16.735872234211172],
            [-71.51889798108661, -16.73836523421345],
            [-71.51358035608126, -16.743127859217793],
            [-71.51309198108079, -16.744283734218865],
            [-71.51490023108254, -16.752241109226304],
            [-71.51207735607971, -16.754985859228803],
            [-71.51197810607962, -16.755989109229738],
            [-71.51078035607841, -16.75782585923143],
            [-71.50857548107619, -16.759012234232483],
            [-71.50632473107395, -16.76057435923391],
            [-71.5046921060723, -16.762302359235502],
            [-71.5040054810716, -16.76334573423646],
            [-71.50363148107122, -16.764163984237214],
            [-71.50061798106819, -16.766618734239454],
            [-71.49739835606498, -16.766899109239656],
            [-71.49511723106268, -16.767614359240294],
            [-71.49211885605966, -16.767555234240195],
            [-71.49079135605832, -16.766731234239412],
            [-71.48949435605705, -16.766376484239057],
            [-71.48538973105292, -16.765150109237855],
            [-71.48122410604876, -16.763805359236542],
            [-71.47020710603769, -16.762441609235097],
            [-71.46952060603701, -16.76240535923506],
            [-71.46319585603067, -16.758233984231076],
            [-71.4581298560256, -16.7572727342301],
            [-71.4538574810213, -16.753894859226893],
            [-71.45234685601979, -16.749578484222848],
            [-71.45210273101955, -16.747369734220793],
            [-71.45311735602058, -16.745506234219082],
            [-71.45368960602117, -16.745067609218673],
            [-71.45568085602318, -16.74249648421632],
            [-71.44787598101536, -16.731788609206248],
            [-71.44674685601422, -16.731220234205686],
            [-71.44240573100986, -16.73087498420529],
            [-71.43953710600698, -16.73059648420499],
            [-71.43845360600588, -16.731134359205466],
            [-71.43567660600306, -16.733610234207738],
            [-71.43444823100182, -16.734449359208494],
            [-71.43209835599946, -16.736165984210047],
            [-71.42753598099485, -16.740768484214264],
            [-71.42592623099321, -16.74137685921478],
            [-71.44430548101145, -16.804674109273943],
            [-71.44063573100748, -16.86522673433021],
            [-71.39092260595666, -16.98049160943651],
            [-71.38461298095005, -17.02111048447416],
            [-71.33942410590409, -17.021758984473873],
            [-71.30224610586565, -17.093851109540108],
            [-71.44095610600608, -17.2517166096896],
            [-71.4907989810563, -17.2850056097214],
            [-71.53412623109996, -17.25553135969468],
            [-71.57608798114201, -17.25355910969345],
            [-71.6903686062558, -17.211988484656235],
            [-71.81221773137591, -17.184310859631758],
            [-71.85772710642054, -17.158866859608484],
            [-71.92264560648398, -17.087505359542583],
            [-72.03899385659693, -17.017339734478053],
            [-72.07615660663281, -17.021160109481826],
            [-72.1844863567371, -16.924770359392625],
            [-72.17198948172513, -16.84988598432286],
            [-72.22383885677488, -16.834545109308788],
            [-72.20558160675739, -16.799032234275657],
            [-72.1529464817069, -16.794681609271375],
            [-72.1319046066867, -16.766656859245202],
            [-72.12461085667968, -16.772609734250693],
            [-72.12040710667564, -16.779584859257174],
            [-72.11350248166897, -16.777156859254877],
            [-72.10895535666461, -16.77600098425378],
            [-72.10609435666184, -16.771387109249474],
            [-72.09986110665585, -16.769668609247844],
            [-72.09381860665003, -16.76779560924608],
            [-72.09182735664811, -16.76840585924663],
            [-72.08968348164606, -16.76858535924678],
            [-72.08880610664521, -16.76867673424687],
            [-72.08444973164102, -16.76946635924758],
            [-72.08260348163923, -16.770315109248347],
            [-72.08029935663698, -16.773948609251732],
            [-72.0669097316241, -16.784431484261404],
            [-72.06636048162355, -16.783643734260675],
            [-72.06449885662175, -16.781612359258776],
            [-72.0623627316197, -16.780507984257735],
            [-72.06146998161884, -16.780509984257733],
            [-72.05826573161575, -16.78013798425736],
            [-72.05748748161498, -16.779972109257212],
            [-72.05618285661373, -16.780269609257477],
            [-72.05371098161135, -16.781328234258453],
            [-72.05308535661074, -16.78119273425833],
            [-72.05266560661033, -16.780796109257945],
            [-72.0499192316077, -16.779518109256745],
            [-72.04622648160414, -16.780935234258045],
            [-72.04450973160246, -16.78182410925886],
            [-72.03846735659663, -16.79195023426825],
            [-72.03833773159649, -16.79224398426852],
            [-72.03488160659316, -16.791723234268026],
            [-72.03234098159069, -16.790906859267242],
            [-72.02925110658771, -16.791542109267823],
            [-72.02689360658542, -16.79149623426777],
            [-72.0192947315781, -16.792640734268794],
            [-72.01449585657345, -16.796791109272615],
            [-72.01239785657143, -16.797609359273366],
            [-72.00806423156722, -16.797029484272805],
            [-72.00327298156259, -16.79689973427266],
            [-72.00235748156167, -16.796482109272258],
            [-72.00116735656054, -16.79664998427241],
            [-71.9992752315587, -16.797306109273],
            [-71.99336248155298, -16.79401010926991],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 12,
        FIRST_IDPR: '0608',
        NOMBPROV: 'JAEN',
        FIRST_NOMB: 'CAJAMARCA',
        LAST_DCTO: 'LEY',
        LAST_LEY: 'S/N',
        FIRST_FECH: '28/12/1943',
        LAST_FECHA: '02/01/1857',
        MIN_SHAPE_: 505726.50019,
        ha: 505726.5,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.41721111339469, -6.005238224213097],
            [-79.41860936339506, -5.970099599180389],
            [-79.3674101133588, -5.937220724150903],
            [-79.33406836333432, -5.893630849111026],
            [-79.3898203633723, -5.791089849014611],
            [-79.40068948837916, -5.749509723975731],
            [-79.37555073835958, -5.606189473842987],
            [-79.3983203633743, -5.537017598778263],
            [-79.3289609883257, -5.535080098777748],
            [-79.2689609882818, -5.513285098758497],
            [-79.21360161324033, -5.508491723754861],
            [-79.14622461318794, -5.454742223705784],
            [-79.14889448818947, -5.398009723653007],
            [-79.09834961314947, -5.365456973623325],
            [-79.05876948811851, -5.44674809869941],
            [-78.94879348802857, -5.476476598728191],
            [-78.86717811295978, -5.469723848722607],
            [-78.78634698789017, -5.428189223684546],
            [-78.7779296128827, -5.391462098650413],
            [-78.67736523779482, -5.408740223667113],
            [-78.58981148771682, -5.324943598589535],
            [-78.51840236265295, -5.399279348659072],
            [-78.51517811265019, -5.454349223710349],
            [-78.55851523768932, -5.499089348751822],
            [-78.55474736268606, -5.538074723788134],
            [-78.58073548770939, -5.548483598797706],
            [-78.60481598773114, -5.607207848852264],
            [-78.6837978628017, -5.707054098944775],
            [-78.70057048781682, -5.785220849017423],
            [-78.74126236285284, -5.835532349064007],
            [-78.8380391129373, -5.90040822412366],
            [-78.86312548795955, -6.017908724232837],
            [-78.90005698799126, -6.04644559925905],
            [-78.96508586304593, -6.012576724226894],
            [-79.02010498809176, -6.027786599240452],
            [-79.07972461314081, -6.064031224273454],
            [-79.08677736314695, -6.1105819743167],
            [-79.1726542382156, -6.134064474337324],
            [-79.21320898824673, -6.098874974303973],
            [-79.22399498825449, -6.055006974263009],
            [-79.26434173828443, -6.00245959921345],
            [-79.34901761334633, -5.979687849190745],
            [-79.41721111339469, -6.005238224213097],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 34,
        FIRST_IDPR: '1204',
        NOMBPROV: 'JAUJA',
        FIRST_NOMB: 'JUNIN',
        LAST_DCTO: 'LEY',
        LAST_LEY: '12638',
        FIRST_FECH: '02/01/1857',
        LAST_FECHA: '02/02/1956',
        MIN_SHAPE_: 435419.920339,
        ha: 435419.92,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.434789609779, -11.258207854117398],
            [-75.30658998465962, -11.300849979157118],
            [-75.02840423440053, -11.350232479203106],
            [-74.97293748434889, -11.438175979285017],
            [-74.99901273437315, -11.427013854274616],
            [-75.03561135940724, -11.472789104317235],
            [-75.16345948452631, -11.53622160437632],
            [-75.2026341095628, -11.599202104434974],
            [-75.22409810958278, -11.661176479492685],
            [-75.19229485955317, -11.70763472953596],
            [-75.20024998456057, -11.750639979576015],
            [-75.26244060961851, -11.79056335461319],
            [-75.31234010966499, -11.849788979668347],
            [-75.40099498474756, -11.874244479691125],
            [-75.39308198474016, -11.926054729739368],
            [-75.49828998483817, -11.968169979778573],
            [-75.48950998482998, -12.00745997981518],
            [-75.54335998488013, -12.027259979833623],
            [-75.58803710992173, -12.000380854808594],
            [-75.65652535998552, -12.008665979816314],
            [-75.69430473502072, -12.042691354848007],
            [-75.76566298508719, -12.05962735486376],
            [-75.82407386014157, -12.015687229822818],
            [-75.92599086023655, -11.98429422979361],
            [-75.99317973529912, -11.994679729803273],
            [-76.05186148535381, -11.938962104751385],
            [-76.02433011032817, -11.89763472971289],
            [-75.95574711026427, -11.87049285468761],
            [-75.90665423521853, -11.873640604690564],
            [-75.89307573520588, -11.783552479606671],
            [-75.83226248514924, -11.76984897959389],
            [-75.81496061013308, -11.789636104612324],
            [-75.75464523507692, -11.774746979598445],
            [-75.70038061002636, -11.712312854540334],
            [-75.7564404850786, -11.63957060447257],
            [-75.80176361012079, -11.544773104384289],
            [-75.71842148504318, -11.544296729383841],
            [-75.62502648495621, -11.589096729425567],
            [-75.51254198485142, -11.511886354353672],
            [-75.45746648480012, -11.453432604299227],
            [-75.46082110980326, -11.428304354275808],
            [-75.4096663597556, -11.38777210423806],
            [-75.43095998477544, -11.332309979186432],
            [-75.434789609779, -11.258207854117398],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 3,
        FIRST_IDPR: '2303',
        NOMBPROV: 'JORGE BASADRE',
        FIRST_NOMB: 'TACNA',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: '12/06/1961',
        LAST_FECHA: 'EPOCA INDEP.',
        MIN_SHAPE_: 274085.971057,
        ha: 274085.97,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.88947260542113, -17.97651561034647],
            [-70.94350585548086, -17.913603485290455],
            [-71.0110058555542, -17.876747985258913],
            [-71.096843730646, -17.867724610253543],
            [-71.13944335569145, -17.82148236021195],
            [-71.10314260565363, -17.755275360149337],
            [-70.99299623053693, -17.679119110074797],
            [-70.91249998044992, -17.687369985079258],
            [-70.9078148554453, -17.6472196100418],
            [-70.87964998041588, -17.555239984955268],
            [-70.84375998037714, -17.524659984925307],
            [-70.73557835526003, -17.401229984805553],
            [-70.75240410527898, -17.38057335978727],
            [-70.71591710524054, -17.27365210968625],
            [-70.6842207302063, -17.224980109639414],
            [-70.60997835512363, -17.21450385962538],
            [-70.57250785508195, -17.194109359604138],
            [-70.45427147994444, -17.300642609694464],
            [-70.43528547992152, -17.33468348472449],
            [-70.36390622983716, -17.373914109754885],
            [-70.40051372987851, -17.421234359801737],
            [-70.34578710481297, -17.460740234833548],
            [-70.42828322990907, -17.483341734861426],
            [-70.44683260492975, -17.515907234893017],
            [-70.50889648000084, -17.545339859924947],
            [-70.60841798011232, -17.656283235034458],
            [-70.68306460519563, -17.71962461009771],
            [-70.69741210521089, -17.767781235143158],
            [-70.72931635524641, -17.785091860161],
            [-70.83929498036679, -17.91151761028387],
            [-70.86333998039254, -17.96679998533626],
            [-70.88947260542113, -17.97651561034647],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 4,
        FIRST_IDPR: '1305',
        NOMBPROV: 'JULCAN',
        FIRST_NOMB: 'LA LIBERTAD',
        LAST_DCTO: 'LEY',
        LAST_LEY: '13659',
        FIRST_FECH: '19/06/1990',
        LAST_FECHA: '12/06/1961',
        MIN_SHAPE_: 111242.412374,
        ha: 111242.41,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.40614773755767, -7.990096476071772],
            [-78.31998573747782, -8.057539976134933],
            [-78.28868236244867, -8.062235476139433],
            [-78.32439261248214, -8.154115226224826],
            [-78.3041429874633, -8.168585351238383],
            [-78.30069998746028, -8.236337726301475],
            [-78.3773027375318, -8.310181476369927],
            [-78.41205661256407, -8.304135101364116],
            [-78.42985448758078, -8.360237976416268],
            [-78.47913748762642, -8.351083351407468],
            [-78.58829686272684, -8.307498101366217],
            [-78.56614261270624, -8.249533226312396],
            [-78.60697036274347, -8.195391226261703],
            [-78.65246661278493, -8.184655976251383],
            [-78.63908861277253, -8.143001226212721],
            [-78.68236961281167, -8.097219726169767],
            [-78.59572348773254, -8.061569726137224],
            [-78.53589898767778, -8.08389485115839],
            [-78.53311723767507, -8.04266997612001],
            [-78.47184698761845, -7.99716097607801],
            [-78.40614773755767, -7.990096476071772],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 4,
        FIRST_IDPR: '1205',
        NOMBPROV: 'JUNIN',
        FIRST_NOMB: 'JUNIN',
        LAST_DCTO: 'LEY',
        LAST_LEY: '5516',
        FIRST_FECH: 'EPOCA INDEP.',
        LAST_FECHA: '26/10/1926',
        MIN_SHAPE_: 237390.639034,
        ha: 237390.64,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.46591348480801, -10.905695978789112],
            [-75.46532335980744, -10.908804103792027],
            [-75.46791998480988, -10.910456603793568],
            [-75.47396323481549, -10.914640603797467],
            [-75.47553010981693, -10.915642228798392],
            [-75.48029160982138, -10.917608103800198],
            [-75.48302910982393, -10.917800228800393],
            [-75.48647510982714, -10.918181103800745],
            [-75.49460360983473, -10.923035103805255],
            [-75.49594835983596, -10.93624247881758],
            [-75.49483723483495, -10.941707228822647],
            [-75.49627885983628, -10.946443978827068],
            [-75.49602523483604, -10.948531228829008],
            [-75.4964161098364, -10.951764228832019],
            [-75.49858973483843, -10.955693353835699],
            [-75.5006068598403, -10.959055228838816],
            [-75.50337110984289, -10.962039978841606],
            [-75.50551110984487, -10.9620399788416],
            [-75.51052110984953, -10.95907997883884],
            [-75.51083110984983, -10.95854997883834],
            [-75.51215110985108, -10.957509978837374],
            [-75.51417110985294, -10.954649978834698],
            [-75.51537110985404, -10.953429978833581],
            [-75.51571110985437, -10.953249853833402],
            [-75.51656110985518, -10.953029978833191],
            [-75.51780110985634, -10.953169978833321],
            [-75.51829110985676, -10.953339978833485],
            [-75.52007110985845, -10.952089978832328],
            [-75.52252110986073, -10.950059978830433],
            [-75.52357110986169, -10.949969978830357],
            [-75.52569110986367, -10.9502199788306],
            [-75.52774110986557, -10.950139978830522],
            [-75.52873110986651, -10.950259978830617],
            [-75.5299211098676, -10.950539978830864],
            [-75.53224110986979, -10.950949978831263],
            [-75.53371998487115, -10.949789978830172],
            [-75.5349598598723, -10.949029978829474],
            [-75.53541110987273, -10.94886997882931],
            [-75.5362311098735, -10.948409978828888],
            [-75.53701110987423, -10.948349978828835],
            [-75.53747110987466, -10.948689978829181],
            [-75.53941110987644, -10.950469978830817],
            [-75.54023110987721, -10.950780103831097],
            [-75.54619110988276, -10.950129978830507],
            [-75.54708110988358, -10.949639978830042],
            [-75.54771110988419, -10.949509978829925],
            [-75.54815110988459, -10.949549978829959],
            [-75.55089110988713, -10.950469978830824],
            [-75.55187110988807, -10.95092997883125],
            [-75.55218110988832, -10.952489978832709],
            [-75.55229110988846, -10.953879978834],
            [-75.55239098488853, -10.954919978834962],
            [-75.55511110989109, -10.956409978836348],
            [-75.55833110989407, -10.957329978837196],
            [-75.56015110989574, -10.957469978837327],
            [-75.56087110989642, -10.957299978837163],
            [-75.56284110989826, -10.956790103836711],
            [-75.56486110990015, -10.956119978836071],
            [-75.5651211099004, -10.955479978835472],
            [-75.56561110990083, -10.954689978834738],
            [-75.56788110990296, -10.953909978834021],
            [-75.57023110990515, -10.954409978834489],
            [-75.57302110990774, -10.954439978834516],
            [-75.57443110990907, -10.95442997883449],
            [-75.57575110991029, -10.953919978834016],
            [-75.57816110991251, -10.95387997883401],
            [-75.58117110991533, -10.953359978833491],
            [-75.5842311099182, -10.95346997883362],
            [-75.58541110991929, -10.954229978834311],
            [-75.58700110992076, -10.955109978835134],
            [-75.59182110992526, -10.956439978836386],
            [-75.59289110992628, -10.95671997883664],
            [-75.59723123493032, -10.954979978835008],
            [-75.59855110993153, -10.954569978834652],
            [-75.59921110993216, -10.95439997883448],
            [-75.59994110993284, -10.954419978834494],
            [-75.60024110993311, -10.954489978834575],
            [-75.60158110993434, -10.954629978834706],
            [-75.60182110993458, -10.954729978834795],
            [-75.60310110993578, -10.956019978835993],
            [-75.60338110993604, -10.956259978836208],
            [-75.60773110994008, -10.958219978838024],
            [-75.60896110994123, -10.958039978837853],
            [-75.61074110994288, -10.95888997883866],
            [-75.6134311099454, -10.96038997884005],
            [-75.61652110994827, -10.960839978840475],
            [-75.61881110995039, -10.96087997884051],
            [-75.62109110995253, -10.959499978839226],
            [-75.62437110995559, -10.95892997883871],
            [-75.62700110995803, -10.959279853839021],
            [-75.6309311099617, -10.959279853839025],
            [-75.63235110996301, -10.959360103839087],
            [-75.63424123496478, -10.959229978838977],
            [-75.6359812349664, -10.959269978839016],
            [-75.63717110996751, -10.959009978838758],
            [-75.63805110996833, -10.958879978838656],
            [-75.63945110996963, -10.957949978837805],
            [-75.64088110997095, -10.957129978837024],
            [-75.64199123497201, -10.95664997883655],
            [-75.64356110997346, -10.957139978837029],
            [-75.6445711099744, -10.958399978838196],
            [-75.64500110997479, -10.95916997883892],
            [-75.64520110997498, -10.959289978839024],
            [-75.64702110997668, -10.959629978839356],
            [-75.65087110998026, -10.958789978838578],
            [-75.65227098498158, -10.958139978837966],
            [-75.65299110998225, -10.957089978836994],
            [-75.65313110998238, -10.956819978836737],
            [-75.65353110998272, -10.956259978836213],
            [-75.65492123498403, -10.954909978834957],
            [-75.65763110998657, -10.952959978833153],
            [-75.65915098498797, -10.952299978832515],
            [-75.66074110998946, -10.951949978832204],
            [-75.66144110999011, -10.951419978831696],
            [-75.66393110999243, -10.950299978830657],
            [-75.66622110999458, -10.94941997882983],
            [-75.66772110999597, -10.9492499788297],
            [-75.67055110999858, -10.951199978831502],
            [-75.67111110999912, -10.951219978831515],
            [-75.67283111000071, -10.950989978831295],
            [-75.67346111000131, -10.950659978830993],
            [-75.67443111000222, -10.950669978830996],
            [-75.6746411100024, -10.95081997883115],
            [-75.67507111000282, -10.951919978832157],
            [-75.6754811100032, -10.952869978833064],
            [-75.67619111000386, -10.953350103833506],
            [-75.67746111000503, -10.953919978834021],
            [-75.67762111000519, -10.95694997883686],
            [-75.67760111000516, -10.958479978838271],
            [-75.67767111000524, -10.958739978838526],
            [-75.67834111000585, -10.959519978839237],
            [-75.68112111000842, -10.962289978841818],
            [-75.68138111000867, -10.962899978842385],
            [-75.68148111000878, -10.963909978843324],
            [-75.68239111000963, -10.964799978844148],
            [-75.68503123501208, -10.963889978843323],
            [-75.68540111001244, -10.963939978843372],
            [-75.68596111001294, -10.964609978843983],
            [-75.68661111001356, -10.966280103845552],
            [-75.68683111001377, -10.966419978845662],
            [-75.69048111001716, -10.96655997884582],
            [-75.69192111001851, -10.967169978846375],
            [-75.69348111001995, -10.968379978847503],
            [-75.69736111002355, -10.968779978847857],
            [-75.70156111002748, -10.973869978852605],
            [-75.70185111002773, -10.974329978853024],
            [-75.70455111003024, -10.977059978855564],
            [-75.70561111003124, -10.979069978857458],
            [-75.70850111003394, -10.980309978858624],
            [-75.70910111003451, -10.980529978858799],
            [-75.71161111003684, -10.980949978859192],
            [-75.71205111003722, -10.981019978859264],
            [-75.71440111003943, -10.981289978859525],
            [-75.71494111003994, -10.981780103859988],
            [-75.71570111004063, -10.983329978861432],
            [-75.71594111004086, -10.983609978861693],
            [-75.71786111004266, -10.984519978862513],
            [-75.71838111004314, -10.984979978862944],
            [-75.71867111004339, -10.98529997886324],
            [-75.71922111004392, -10.98659997886446],
            [-75.72066111004526, -10.98928997886696],
            [-75.72097111004555, -10.989699978867343],
            [-75.72144111004599, -10.99006997886769],
            [-75.72192111004645, -10.99020997886783],
            [-75.7235011100479, -10.990039978867689],
            [-75.72445111004879, -10.990019978867641],
            [-75.72568111004993, -10.990849978868427],
            [-75.72584111005007, -10.991129978868674],
            [-75.7253111100496, -10.99238997886985],
            [-75.72526111004954, -10.993499978870876],
            [-75.7254409850497, -10.993959978871308],
            [-75.72642111005064, -10.995269978872544],
            [-75.72712111005129, -10.998149978875228],
            [-75.72729111005144, -10.998729978875748],
            [-75.72827111005236, -10.99966997887664],
            [-75.7290811100531, -10.999999978876945],
            [-75.73222111005603, -11.001489853878324],
            [-75.73289111005667, -11.001619978878463],
            [-75.73342111005715, -11.00161997887847],
            [-75.73423111005792, -11.00162997887846],
            [-75.73610111005964, -11.00129997887817],
            [-75.73953111006284, -11.000929978877817],
            [-75.73963111006293, -11.000739978877645],
            [-75.75013086007273, -11.00525897888183],
            [-75.75080973507336, -11.005626603882192],
            [-75.75489436007715, -11.008717478885076],
            [-75.75548011007768, -11.009743103886024],
            [-75.75830398508032, -11.010500103886727],
            [-75.75969298508161, -11.010235853886472],
            [-75.76673198508817, -11.005660728882214],
            [-75.7667411100882, -11.005655103882207],
            [-75.76808386008945, -11.004825478881443],
            [-75.77136186009251, -11.001794228878616],
            [-75.774026110095, -10.99974735387673],
            [-75.77463723509554, -10.999256228876234],
            [-75.7763938600972, -10.998538603875591],
            [-75.77944248510002, -10.997503728874612],
            [-75.7840609851043, -10.997493228874617],
            [-75.78430698510454, -10.997739228874849],
            [-75.78447686010469, -11.000140978877083],
            [-75.78644786010655, -11.004881353881498],
            [-75.78727998510733, -11.005446478882014],
            [-75.78875561010871, -11.005964603882513],
            [-75.78943436010933, -11.006077478882606],
            [-75.7909148601107, -11.00648422888298],
            [-75.79164698511138, -11.00698860388345],
            [-75.79267211011235, -11.00754185388395],
            [-75.79395723511355, -11.008078603884485],
            [-75.79512861011465, -11.008582978884919],
            [-75.79684773511622, -11.010543478886762],
            [-75.7975419851169, -11.011172478887339],
            [-75.79947261011868, -11.011844978887973],
            [-75.80055723511968, -11.012430728888505],
            [-75.80181536012086, -11.0135803538896],
            [-75.80231423512133, -11.01442635389039],
            [-75.80263973512164, -11.015532728891417],
            [-75.80333386012227, -11.020814853896322],
            [-75.80492461012376, -11.023562478898892],
            [-75.8057344851245, -11.025182103900388],
            [-75.80738311012605, -11.027843103902875],
            [-75.80827973512687, -11.028710853903691],
            [-75.8114323601298, -11.031198228906023],
            [-75.81576711013385, -11.033676728908306],
            [-75.81694611013496, -11.03425622890884],
            [-75.81727923513527, -11.034843728909399],
            [-75.81573148513381, -11.037321728911696],
            [-75.81421423513241, -11.040210728914376],
            [-75.81329398513155, -11.0414041039155],
            [-75.81273811013104, -11.043448103917418],
            [-75.81278861013108, -11.043970228917898],
            [-75.81285311013114, -11.044732603918607],
            [-75.81305123513134, -11.048844603922436],
            [-75.81801323513594, -11.051457853924884],
            [-75.81955036013736, -11.051211853924654],
            [-75.8215792351393, -11.049859353923374],
            [-75.8256631101431, -11.05038722892388],
            [-75.83180073514878, -11.050927228924389],
            [-75.8353372351521, -11.064654478937157],
            [-75.83617536015286, -11.067024978939363],
            [-75.83650948515319, -11.068561853940784],
            [-75.83691973515356, -11.069089353941298],
            [-75.83809923515466, -11.069657103941836],
            [-75.83833636015488, -11.070396353942524],
            [-75.83825786015481, -11.071061728943135],
            [-75.83784536015442, -11.074100228945937],
            [-75.83762548515422, -11.07484435394663],
            [-75.83701661015364, -11.078818478950334],
            [-75.83774386015433, -11.080915478952296],
            [-75.838458610155, -11.08171672895306],
            [-75.83860223515514, -11.083294978954505],
            [-75.83829461015486, -11.085527353956591],
            [-75.8324562351494, -11.094622103965047],
            [-75.83281698514973, -11.095704478966068],
            [-75.8332061101501, -11.096510853966832],
            [-75.83421998515107, -11.098545603968715],
            [-75.83733598515396, -11.103883228973691],
            [-75.83736111015398, -11.104058603973854],
            [-75.83692461015356, -11.105401603975107],
            [-75.83695686015359, -11.109891978979295],
            [-75.83795636015454, -11.117275103986156],
            [-75.83893986015543, -11.11941422898817],
            [-75.84054998515693, -11.11988847898859],
            [-75.84073486015711, -11.119832228988543],
            [-75.84388211016004, -11.120299478988976],
            [-75.84643923516244, -11.120225728988906],
            [-75.84764411016357, -11.120176478988853],
            [-75.8516567351673, -11.119728478988447],
            [-75.85204273516764, -11.123016978991522],
            [-75.85189773516751, -11.124245853992658],
            [-75.85301948516856, -11.129275978997349],
            [-75.85506123517047, -11.132560854000412],
            [-75.8553054851707, -11.135068979002728],
            [-75.8571482351724, -11.140069354007407],
            [-75.86117698517617, -11.14477447901176],
            [-75.8615643601765, -11.144848479011836],
            [-75.872823110187, -11.14107935400834],
            [-75.87363298518774, -11.140876854008138],
            [-75.87540423518941, -11.140434104007722],
            [-75.8776626101915, -11.140326479007651],
            [-75.88109373519474, -11.141447229008682],
            [-75.88269336019621, -11.142277354009442],
            [-75.8866659851999, -11.147732479014529],
            [-75.88789948520105, -11.15307485401949],
            [-75.89575286020838, -11.165219354030812],
            [-75.89694873520948, -11.166029479031572],
            [-75.89703223520958, -11.172620104037707],
            [-75.90342911021553, -11.174901479039836],
            [-75.90463648521663, -11.176014854040876],
            [-75.9045912352166, -11.176965354041748],
            [-75.90032448521262, -11.195092354058648],
            [-75.90515998521713, -11.201449979064547],
            [-75.90654998521842, -11.201479979064594],
            [-75.90978998522144, -11.203199979066184],
            [-75.91028998522192, -11.203559979066533],
            [-75.91210998522361, -11.205959979068776],
            [-75.91336998522476, -11.207729979070407],
            [-75.9138299852252, -11.208689979071318],
            [-75.91509998522639, -11.210689979073162],
            [-75.91502998522633, -11.211899979074298],
            [-75.91645998522765, -11.214499979076706],
            [-75.91948998523046, -11.217599979079592],
            [-75.9214399852323, -11.219359854081217],
            [-75.93001998524029, -11.218529854080474],
            [-75.93009998524037, -11.218429979080375],
            [-75.93081998524103, -11.217869979079845],
            [-75.93229998524242, -11.217389979079389],
            [-75.93793986024768, -11.21752997907951],
            [-75.94006998524965, -11.217349979079367],
            [-75.94616998525534, -11.218089979080066],
            [-75.95017998525906, -11.217909979079886],
            [-75.95204998526081, -11.218269979080203],
            [-75.95435998526297, -11.218019979079987],
            [-75.96011998526832, -11.217439979079467],
            [-75.96048998526867, -11.217389979079385],
            [-75.97192573527934, -11.21416597907638],
            [-75.9246718602353, -11.280818354138477],
            [-75.9138316102252, -11.298702854155138],
            [-75.91064948522224, -11.305862479161801],
            [-75.90605448521796, -11.310270854165896],
            [-75.90315061021526, -11.31235210416782],
            [-75.89873523521116, -11.31566872917092],
            [-75.89431861020702, -11.32326097917799],
            [-75.89014773520313, -11.333161479187204],
            [-75.9001016102124, -11.336894479190686],
            [-75.90523436021721, -11.335521479189412],
            [-75.90844073522018, -11.333570979187595],
            [-75.90907636022078, -11.333137604187197],
            [-75.9109278602225, -11.332339854186465],
            [-75.91653048522772, -11.332068604186205],
            [-75.9173683602285, -11.332126354186252],
            [-75.92315623523388, -11.329533229183827],
            [-75.93470136024466, -11.332009354186155],
            [-75.94009736024968, -11.334442979188395],
            [-75.9441874852535, -11.335307604189223],
            [-75.94757661025663, -11.33472910418866],
            [-75.95790923526626, -11.332414479186482],
            [-75.96082986026899, -11.333473354187515],
            [-75.9622514852703, -11.335838354189706],
            [-75.96503186027292, -11.340426854193973],
            [-75.97057761027807, -11.345972604199165],
            [-75.96938161027697, -11.348001104201028],
            [-75.96911273527672, -11.35080622920366],
            [-75.97220786027958, -11.353507979206158],
            [-75.97358161028089, -11.353530479206173],
            [-75.97514673528232, -11.35338410420606],
            [-75.97634023528343, -11.35346297920613],
            [-75.97772898528473, -11.354658354207237],
            [-75.97855498528551, -11.356314354208767],
            [-75.97895811028587, -11.357822604210195],
            [-75.97972998528661, -11.357689979210065],
            [-75.9811199852879, -11.358279979210623],
            [-75.98388761029048, -11.358382479210697],
            [-75.98503923529154, -11.358152104210506],
            [-75.98907248529531, -11.359574354211832],
            [-75.98992811029609, -11.360577604212764],
            [-75.99178448529784, -11.362403354214464],
            [-75.99816511030379, -11.36421897921615],
            [-76.00005498530554, -11.364739354216628],
            [-76.00123273530663, -11.364903729216769],
            [-76.00445936030964, -11.364598479216495],
            [-76.00653861031158, -11.3654558542173],
            [-76.00831073531323, -11.367775104219458],
            [-76.00924598531411, -11.369675854221219],
            [-76.01105623531579, -11.373296479224608],
            [-76.01331248531788, -11.375671854226809],
            [-76.01469336031919, -11.375476604226623],
            [-76.02093211032498, -11.373719354225004],
            [-76.02313548532705, -11.377505479228498],
            [-76.02656336033023, -11.379937979230764],
            [-76.0312301103346, -11.374419229225632],
            [-76.04202273534463, -11.3721073542235],
            [-76.04257561034515, -11.37179772922318],
            [-76.04517598534758, -11.371547104222948],
            [-76.04696173534924, -11.371797729223186],
            [-76.04780648535004, -11.37453360422576],
            [-76.04900073535114, -11.37680497922785],
            [-76.05133223535333, -11.377540104228533],
            [-76.0591463603606, -11.382461604233129],
            [-76.06079486036215, -11.383785479234348],
            [-76.06221423536347, -11.384629229235154],
            [-76.06271811036395, -11.384559854235091],
            [-76.06510073536616, -11.382964354233598],
            [-76.06712311036803, -11.383407729233992],
            [-76.06932061037006, -11.385837854236268],
            [-76.07075586037142, -11.38741597923775],
            [-76.07174373537235, -11.387429729237747],
            [-76.07563661037595, -11.38536097923584],
            [-76.07657398537684, -11.385097354235581],
            [-76.07891748537902, -11.385741729236186],
            [-76.08140736038135, -11.387733729238036],
            [-76.08564848538529, -11.381665354232387],
            [-76.09487448539389, -11.37226847922365],
            [-76.09514998539416, -11.371567479222968],
            [-76.09546636039445, -11.370196729221705],
            [-76.09559098539455, -11.369525854221095],
            [-76.09561973539458, -11.367560729219237],
            [-76.09586898539482, -11.365202729217048],
            [-76.09591686039488, -11.363630729215576],
            [-76.09619486039512, -11.362739354214751],
            [-76.09689461039576, -11.360908479213043],
            [-76.09977023539847, -11.358262854210585],
            [-76.10102273539964, -11.35644810420889],
            [-76.10358948540201, -11.354022979206636],
            [-76.10464273540299, -11.353624729206278],
            [-76.10557198540387, -11.35258035420529],
            [-76.10574911040403, -11.351686354204462],
            [-76.10585523540414, -11.351217229204037],
            [-76.1064499854047, -11.344599979197861],
            [-76.10660998540483, -11.3419499791954],
            [-76.10804998540617, -11.33725997919104],
            [-76.10858998540668, -11.334869979188802],
            [-76.10917998540722, -11.334219979188205],
            [-76.11049998540845, -11.332999979187075],
            [-76.1120499854099, -11.331769979185912],
            [-76.11411998541183, -11.330969979185184],
            [-76.11502998541268, -11.330749979184965],
            [-76.11682998541434, -11.330499979184749],
            [-76.11792986041536, -11.329819979184103],
            [-76.11967998541702, -11.328389979182761],
            [-76.12024998541756, -11.328009979182417],
            [-76.12092998541819, -11.327469979181911],
            [-76.12139998541862, -11.326349979180884],
            [-76.12244998541958, -11.32484997917948],
            [-76.12420998542123, -11.32580997918036],
            [-76.12513998542212, -11.324509979179153],
            [-76.12655998542341, -11.323449979178152],
            [-76.1273998604242, -11.322760104177526],
            [-76.12831998542507, -11.321559979176428],
            [-76.12992998542657, -11.318899979173933],
            [-76.13196998542846, -11.317869979172977],
            [-76.13285998542928, -11.31726997917242],
            [-76.1339399854303, -11.316049979171261],
            [-76.1360699854323, -11.315369979170649],
            [-76.13701998543316, -11.314389979169748],
            [-76.135759985432, -11.313229854168656],
            [-76.13488998543117, -11.312699979168162],
            [-76.13434998543067, -11.311909979167426],
            [-76.1340499854304, -11.31007997916573],
            [-76.13450998543084, -11.308749979164478],
            [-76.1352501104315, -11.307659979163452],
            [-76.13804998543412, -11.306379979162278],
            [-76.1387699854348, -11.30508997916108],
            [-76.14002998543599, -11.2966399791532],
            [-76.14672998544224, -11.288869979145948],
            [-76.14744998544288, -11.288499979145614],
            [-76.1502499854455, -11.287829979144995],
            [-76.15090998544612, -11.287619979144797],
            [-76.15341998544844, -11.286329979143618],
            [-76.15392998544893, -11.285599979142933],
            [-76.15436998544934, -11.2845199791419],
            [-76.15502011044993, -11.282699979140208],
            [-76.15626998545112, -11.280869979138519],
            [-76.15648998545132, -11.278699979136501],
            [-76.15995998545456, -11.275609979133609],
            [-76.1622499854567, -11.273419979131592],
            [-76.16307998545747, -11.272629979130853],
            [-76.16495998545922, -11.270789979129132],
            [-76.1661299854603, -11.265779979124463],
            [-76.1669899854611, -11.264669979123433],
            [-76.16755998546164, -11.263330104122177],
            [-76.1676099854617, -11.262459854121353],
            [-76.16757998546164, -11.257989979117196],
            [-76.16840998546242, -11.256679979115988],
            [-76.17057998546446, -11.255889979115247],
            [-76.17158998546539, -11.255259979114646],
            [-76.17251998546625, -11.25126985411096],
            [-76.17316998546687, -11.249629979109429],
            [-76.17728998547071, -11.242979979103229],
            [-76.17734998547076, -11.241849979102184],
            [-76.17692998547037, -11.239749979100209],
            [-76.17751998547092, -11.23591997909666],
            [-76.17700998547045, -11.233669979094554],
            [-76.17676998547023, -11.23320997909412],
            [-76.17402961046766, -11.230978604092043],
            [-76.1734362354671, -11.230754354091834],
            [-76.17345273546711, -11.229529604090692],
            [-76.17397686046762, -11.228352729089613],
            [-76.17395711046758, -11.22738347908871],
            [-76.173294485467, -11.226434104087822],
            [-76.17280986046653, -11.226048354087462],
            [-76.17091098546476, -11.225256979086717],
            [-76.16757798546165, -11.224564854086065],
            [-76.16756811046163, -11.221330729083075],
            [-76.1680527354621, -11.220470354082279],
            [-76.16937798546334, -11.219797854081655],
            [-76.17135598546517, -11.21958022908145],
            [-76.17195923546574, -11.219570354081421],
            [-76.17341311046708, -11.219392354081243],
            [-76.17487686046847, -11.219154979081038],
            [-76.1760933604696, -11.218986729080896],
            [-76.17816036047151, -11.21900660408092],
            [-76.18292736047596, -11.21745385407947],
            [-76.18365923547663, -11.216464854078529],
            [-76.18431198547727, -11.214694479076904],
            [-76.18418361047715, -11.21278560407511],
            [-76.18452961047745, -11.211895604074282],
            [-76.18725923547998, -11.207771479070445],
            [-76.18823836048092, -11.206960479069673],
            [-76.18853511048118, -11.206515354069277],
            [-76.18836698548103, -11.205011979067857],
            [-76.18777361048046, -11.203597729066542],
            [-76.18827798548094, -11.201599979064682],
            [-76.18935598548194, -11.200779104063933],
            [-76.19305486048539, -11.20050222906368],
            [-76.19137348548385, -11.198998979062273],
            [-76.18968236048224, -11.198069229061405],
            [-76.18869336048132, -11.196991229060394],
            [-76.18678436047956, -11.194914354058461],
            [-76.18708123547984, -11.193499979057139],
            [-76.18749661048022, -11.19244172905617],
            [-76.18761536048032, -11.191996729055758],
            [-76.1879021104806, -11.189969229053862],
            [-76.18770436048041, -11.189316479053273],
            [-76.18678998547955, -11.188009979052044],
            [-76.18508998547796, -11.185639979049846],
            [-76.18310998547615, -11.182709979047086],
            [-76.18204686047514, -11.182602229046985],
            [-76.18144948547459, -11.181718979046188],
            [-76.18272223547578, -11.180030604044607],
            [-76.18302098547605, -11.179975354044547],
            [-76.18461861047754, -11.181183354045679],
            [-76.18487186047776, -11.181650854046113],
            [-76.18551473547836, -11.182605479047007],
            [-76.18654736047934, -11.183579604047917],
            [-76.18732661048006, -11.183676979048004],
            [-76.18900211048162, -11.182663979047067],
            [-76.18999573548255, -11.181962604046408],
            [-76.19106723548354, -11.181631354046099],
            [-76.19361948548594, -11.181650854046111],
            [-76.19525598548746, -11.181475479045947],
            [-76.19712636048921, -11.180092229044655],
            [-76.19780823548983, -11.179351854043965],
            [-76.19751598548955, -11.176468479041278],
            [-76.19745761048952, -11.175124229040033],
            [-76.19741861048946, -11.173234479038268],
            [-76.1978617354899, -11.171829229036963],
            [-76.1980809854901, -11.17161010403676],
            [-76.20014123549201, -11.171595479036748],
            [-76.20211386049384, -11.171756229036893],
            [-76.20301986049468, -11.171858479036993],
            [-76.20341423549506, -11.172004604037127],
            [-76.20465623549622, -11.172589104037677],
            [-76.20588373549737, -11.172209104037327],
            [-76.20722998549863, -11.171099979036304],
            [-76.20828998549962, -11.170049979035316],
            [-76.20901998550028, -11.1677899790332],
            [-76.20831998549963, -11.166819979032294],
            [-76.20837986049969, -11.165979854031502],
            [-76.20379998549542, -11.156129979022339],
            [-76.20209998549383, -11.155259979021517],
            [-76.20140998549319, -11.154099979020438],
            [-76.20221998549393, -11.1499699790166],
            [-76.20301998549469, -11.14922997901591],
            [-76.20674998549816, -11.150339979016943],
            [-76.2126698605037, -11.152399979018892],
            [-76.21478998550566, -11.150029979016649],
            [-76.21563998550644, -11.14799997901479],
            [-76.21818998550881, -11.144099979011136],
            [-76.2186998605093, -11.14308997901021],
            [-76.2186998605093, -11.139709979007073],
            [-76.22001998551053, -11.138049979005519],
            [-76.22398998551425, -11.136199979003791],
            [-76.22479998551499, -11.134589979002277],
            [-76.22524998551542, -11.133139979000946],
            [-76.22475998551494, -11.131559853999471],
            [-76.22798998551797, -11.128779978996857],
            [-76.2308099855206, -11.124189978992593],
            [-76.2321199855218, -11.122479978990999],
            [-76.23258998552224, -11.12185997899043],
            [-76.23497998552449, -11.120379978989059],
            [-76.23618998552561, -11.118559978987362],
            [-76.23784998552716, -11.116019853984973],
            [-76.24308998553204, -11.115309978984344],
            [-76.24547998553427, -11.113309978982466],
            [-76.24595998553471, -11.111959978981202],
            [-76.24616998553492, -11.111209978980511],
            [-76.24954998553808, -11.106929978976513],
            [-76.24973986053824, -11.106289978975934],
            [-76.25024998553872, -11.104559978974308],
            [-76.25030998553876, -11.10320997897305],
            [-76.25071998553916, -11.101069978971077],
            [-76.25089998553933, -11.097779978968006],
            [-76.25123998553964, -11.096310103966655],
            [-76.2511799855396, -11.094959978965374],
            [-76.25095998553938, -11.094149978964618],
            [-76.25018998553868, -11.089129978959955],
            [-76.24793898553656, -11.085176853956268],
            [-76.24822086053685, -11.084358103955504],
            [-76.24850273553709, -11.083512478954717],
            [-76.24848923553708, -11.082787603954044],
            [-76.24703973553574, -11.081712228953036],
            [-76.24574111053451, -11.080675228952071],
            [-76.24544911053425, -11.080181978951627],
            [-76.24516723553397, -11.079064728950572],
            [-76.2443499855332, -11.077169978948827],
            [-76.24405998553294, -11.075499978947258],
            [-76.24416998553305, -11.073929978945797],
            [-76.24416998553305, -11.073229978945156],
            [-76.24393998553285, -11.072539978944512],
            [-76.24394998553285, -11.072269978944252],
            [-76.24516998553398, -11.070539978942627],
            [-76.24343998553236, -11.06812997894037],
            [-76.24331998553225, -11.066929853939278],
            [-76.2411198605302, -11.060369978933172],
            [-76.24179998553083, -11.057329978930328],
            [-76.24060998552974, -11.055259978928392],
            [-76.23963998552883, -11.054289978927487],
            [-76.23965998552885, -11.054169978927396],
            [-76.24071998552984, -11.053279978926568],
            [-76.24176998553081, -11.052059978925413],
            [-76.24101198553012, -11.051493103924908],
            [-76.24019711052935, -11.05107935392453],
            [-76.23964561052884, -11.050214478923717],
            [-76.23957036052877, -11.049199103922763],
            [-76.23997161052915, -11.047882853921541],
            [-76.2400342355292, -11.047581978921261],
            [-76.24023473552938, -11.046742228920477],
            [-76.24034761052947, -11.04659172892033],
            [-76.24073611052987, -11.046403728920168],
            [-76.24145073553052, -11.046441353920208],
            [-76.24208998553112, -11.046829978920554],
            [-76.24264148553162, -11.047030478920743],
            [-76.24405998553294, -11.046779978920496],
            [-76.24533998553414, -11.045409978919237],
            [-76.2434691105324, -11.043726603917655],
            [-76.24320411053216, -11.043414978917369],
            [-76.24321973553216, -11.041544853915633],
            [-76.2436872355326, -11.041217603915321],
            [-76.24470023553354, -11.041186478915298],
            [-76.24663261053537, -11.040781228914923],
            [-76.2488399855374, -11.04034010391452],
            [-76.25031998553877, -11.040379978914547],
            [-76.25114773553956, -11.039419228913657],
            [-76.25170136054008, -11.036745728911168],
            [-76.25199836054034, -11.036232603910685],
            [-76.25187686054024, -11.033869728908465],
            [-76.25155273553992, -11.033437603908073],
            [-76.2509857355394, -11.032330353907051],
            [-76.25229548554064, -11.031925353906656],
            [-76.25270048554101, -11.031979353906728],
            [-76.25342961054169, -11.031911728906652],
            [-76.25548211054361, -11.03122322890603],
            [-76.25669736054472, -11.030642603905472],
            [-76.25716986054518, -11.029724353904632],
            [-76.25787198554583, -11.02867110390366],
            [-76.2584931105464, -11.028198603903194],
            [-76.25901973554689, -11.027833978902851],
            [-76.2595733605474, -11.025916603901095],
            [-76.259141235547, -11.025281978900477],
            [-76.25800711054596, -11.025376478900592],
            [-76.25749398554547, -11.02502547890026],
            [-76.2540912355423, -11.023486103898817],
            [-76.2534431105417, -11.022878478898257],
            [-76.25365923554187, -11.021892853897317],
            [-76.25460436054277, -11.020461603895988],
            [-76.25683236054485, -11.01776110389349],
            [-76.25931686054719, -11.01589772889175],
            [-76.25995148554779, -11.015006478890932],
            [-76.25941136054725, -11.012846103888888],
            [-76.25887123554675, -11.012778603888835],
            [-76.25630573554436, -11.011414853887558],
            [-76.25627873554434, -11.010550603886754],
            [-76.25644073554449, -11.009537978885835],
            [-76.25734548554534, -11.00872772888506],
            [-76.25899273554685, -11.007120978883584],
            [-76.25916823554704, -11.004757978881356],
            [-76.25672436054477, -11.003907353880576],
            [-76.25581961054392, -11.002989228879711],
            [-76.25567111054379, -11.002395103879184],
            [-76.25660273554462, -11.000950228877816],
            [-76.25716986054518, -11.000234603877171],
            [-76.25731836054531, -10.999951103876898],
            [-76.25866873554658, -10.9993974788764],
            [-76.25992448554774, -10.999816103876784],
            [-76.26173373554941, -11.000288603877195],
            [-76.26389423555145, -10.998074228875167],
            [-76.26281398555045, -10.996372853873565],
            [-76.26274636055038, -10.995198103872452],
            [-76.26404273555157, -10.994468978871792],
            [-76.26454236055203, -10.99425297887159],
            [-76.26647323555386, -10.990904353868467],
            [-76.26559548555302, -10.988446853866177],
            [-76.26576061055319, -10.987396478865206],
            [-76.26628336055366, -10.985994603863887],
            [-76.26655036055394, -10.985427103863355],
            [-76.2668619855542, -10.983213103861315],
            [-76.26639461055377, -10.981833478860025],
            [-76.26581611055322, -10.980709728858983],
            [-76.26616086055354, -10.979841978858188],
            [-76.26670611055404, -10.979630603857958],
            [-76.26750723555479, -10.979619478857972],
            [-76.26797448555523, -10.979697353858052],
            [-76.2694097355566, -10.97946372885783],
            [-76.26834161055558, -10.97743885385594],
            [-76.26873111055596, -10.976137103854729],
            [-76.26927623555646, -10.975725478854333],
            [-76.27016623555728, -10.975313853853942],
            [-76.27088948555797, -10.974757478853423],
            [-76.27184623555885, -10.973578228852332],
            [-76.27261398555957, -10.972076228850934],
            [-76.2729477355599, -10.971019228849963],
            [-76.27143998555849, -10.968119978847241],
            [-76.27173998555875, -10.96615997884543],
            [-76.26963998555681, -10.967149978846335],
            [-76.2679499855552, -10.966789978845997],
            [-76.26745998555478, -10.965249978844568],
            [-76.26783998555511, -10.96430997884371],
            [-76.26844736055568, -10.964466853843831],
            [-76.26872211055594, -10.964481353843855],
            [-76.26950311055668, -10.964163353843583],
            [-76.26986473555701, -10.963555728843],
            [-76.27002373555715, -10.962615603842115],
            [-76.26902573555621, -10.961342978840939],
            [-76.26794111055523, -10.961198228840825],
            [-76.26545348555291, -10.960127978839795],
            [-76.26562711055304, -10.958566103838356],
            [-76.26578623555321, -10.958262353838068],
            [-76.26723248555454, -10.957278853837147],
            [-76.26875111055595, -10.955572228835553],
            [-76.26948861055666, -10.9551673538352],
            [-76.27120973555827, -10.956280978836238],
            [-76.27261261055959, -10.956671478836585],
            [-76.27147011055851, -10.953243728833387],
            [-76.27197611055898, -10.951247728831554],
            [-76.2729742355599, -10.9503367288307],
            [-76.27391423556077, -10.950452353830794],
            [-76.27644523556313, -10.950944103831253],
            [-76.27721186056387, -10.947342853827902],
            [-76.27750111056412, -10.945809853826477],
            [-76.27789161056451, -10.944493728825242],
            [-76.27823861056483, -10.943336728824182],
            [-76.27770348556432, -10.937855353819092],
            [-76.2769226105636, -10.935194103816587],
            [-76.27627173556299, -10.933255978814787],
            [-76.27696598556363, -10.930942103812638],
            [-76.27818098556475, -10.929076353810903],
            [-76.27873036056529, -10.92712385380908],
            [-76.27881723556537, -10.925417228807502],
            [-76.27883161056538, -10.923725103805909],
            [-76.25984573554767, -10.921431603803757],
            [-76.24801561053664, -10.933181603814695],
            [-76.24816986053676, -10.938642603819801],
            [-76.23683011052623, -10.9578476038377],
            [-76.1763301104698, -11.044214853918124],
            [-76.11439448541208, -11.109499978978919],
            [-76.02965623533312, -11.087197228958152],
            [-76.01191011031658, -11.04602147891981],
            [-76.07102148537167, -10.976029353854601],
            [-76.1222398604194, -10.96114997884077],
            [-76.12126998541851, -10.96062997884026],
            [-76.12048998541778, -10.958969978838754],
            [-76.11670998541423, -10.951619978831904],
            [-76.11567998541328, -10.950119978830482],
            [-76.11287011041065, -10.948339978828843],
            [-76.11134998540926, -10.947819978828358],
            [-76.11102998540896, -10.94764997882818],
            [-76.10998998540798, -10.943959978824754],
            [-76.10984998540785, -10.942769978823664],
            [-76.10941011040744, -10.941349978822323],
            [-76.10923998540727, -10.94016997882121],
            [-76.1091499854072, -10.938939978820084],
            [-76.10646998540469, -10.936489978817793],
            [-76.10449998540287, -10.93491997881634],
            [-76.10385998540227, -10.933739978815247],
            [-76.10370998540213, -10.933109978814656],
            [-76.1015299854001, -10.931979978813612],
            [-76.09895998539771, -10.930309978812051],
            [-76.0990399853978, -10.928859978810696],
            [-76.09877998539753, -10.925189978807273],
            [-76.09855998539732, -10.923220103805438],
            [-76.09802998539683, -10.922769978805043],
            [-76.09683998539572, -10.922949978805212],
            [-76.09595998539488, -10.921259978803613],
            [-76.09566998539464, -10.920319978802752],
            [-76.09448998539352, -10.918879978801407],
            [-76.0942299853933, -10.91838997880094],
            [-76.09410998539317, -10.917179978799828],
            [-76.09366998539276, -10.915789978798532],
            [-76.09283998539199, -10.914739978797531],
            [-76.09202998539124, -10.913499978796372],
            [-76.09227998539149, -10.911269978794303],
            [-76.09243998539162, -10.910369978793478],
            [-76.09196998539119, -10.908599978791841],
            [-76.09176998539101, -10.908249978791494],
            [-76.09163998539087, -10.906899978790252],
            [-76.0913401103906, -10.906559978789932],
            [-76.09017998538951, -10.906649978789998],
            [-76.08985998538921, -10.906449978789825],
            [-76.08825998538772, -10.904809978788295],
            [-76.08793998538742, -10.90436997878787],
            [-76.08738998538692, -10.902809978786456],
            [-76.08708998538665, -10.90214997878581],
            [-76.08585998538548, -10.900239978784047],
            [-76.08576998538541, -10.900129978783948],
            [-76.08538998538505, -10.899329978783184],
            [-76.08363998538343, -10.898429978782355],
            [-76.08319998538302, -10.897979978781935],
            [-76.08309998538292, -10.897489978781497],
            [-76.08221998538208, -10.897449978781445],
            [-76.08256998538243, -10.896569978780635],
            [-76.0824099853823, -10.895749978779856],
            [-76.08213998538204, -10.8951399787793],
            [-76.08140998538136, -10.894699978778878],
            [-76.07794998537811, -10.893349978777612],
            [-76.07728998537752, -10.89340997877766],
            [-76.07550998537587, -10.89259997877692],
            [-76.0742599853747, -10.892059978776413],
            [-76.0733299853738, -10.89176997877616],
            [-76.07278998537332, -10.89111997877554],
            [-76.07151011037213, -10.890079978774597],
            [-76.0697498603705, -10.888469978773083],
            [-76.06881998536961, -10.887059978771758],
            [-76.06688998536781, -10.886459978771194],
            [-76.0659199853669, -10.886019978770793],
            [-76.06430998536541, -10.885579978770377],
            [-76.06363998536477, -10.885369978770203],
            [-76.06274998536395, -10.885089978769942],
            [-76.06235998536359, -10.884639978769506],
            [-76.06211998536337, -10.884369853769263],
            [-76.05811998535965, -10.883399978768345],
            [-76.05684011035846, -10.883319978768302],
            [-76.05078986035281, -10.883489978768441],
            [-76.04902986035117, -10.882629978767655],
            [-76.04447998534692, -10.879519978764723],
            [-76.04431998534679, -10.87888997876416],
            [-76.04300998534558, -10.874389978759984],
            [-76.04175998534441, -10.872599978758302],
            [-76.04139998534407, -10.872029978757775],
            [-76.03834998534123, -10.868889853754833],
            [-76.03662998533963, -10.866709978752816],
            [-76.0298599853333, -10.858639978745284],
            [-76.02743998533106, -10.857079978743858],
            [-76.02438611032821, -10.854771228741699],
            [-76.02318548532709, -10.853470478740496],
            [-76.0150311103195, -10.842677228730418],
            [-76.00985336031466, -10.839075353727083],
            [-76.0084792353134, -10.83672797872489],
            [-76.00894361031384, -10.833604478721984],
            [-76.0066642353117, -10.828708103717414],
            [-76.00400498530924, -10.827357353716172],
            [-76.00330848530858, -10.827193853716004],
            [-76.00137748530678, -10.826497353715357],
            [-75.99909811030464, -10.82573760371465],
            [-75.99855986030416, -10.825136103714103],
            [-75.99726198530296, -10.823204978712312],
            [-75.99650223530223, -10.821685478710881],
            [-75.9957107353015, -10.821242228710476],
            [-75.99328898529923, -10.8208426037101],
            [-75.99267161029866, -10.820984978710243],
            [-75.99150823529757, -10.822219603711371],
            [-75.99032111029646, -10.823477978712567],
            [-75.9848779852914, -10.82794472871672],
            [-75.98144111028819, -10.830562353719158],
            [-75.9788768602858, -10.832147228720633],
            [-75.97645523528355, -10.834925228723217],
            [-75.9753154852825, -10.8362073537244],
            [-75.97365936028095, -10.838522228726559],
            [-75.97316086028047, -10.839377103727369],
            [-75.97255973527993, -10.840744228728617],
            [-75.97110773527857, -10.842578353730334],
            [-75.96108448526921, -10.844785978732398],
            [-75.96066136026882, -10.845289728732865],
            [-75.95949261026773, -10.846579353734056],
            [-75.95873198526702, -10.847755603735155],
            [-75.95832398526667, -10.849055228736383],
            [-75.95818798526652, -10.850188603737426],
            [-75.95823323526659, -10.852848478739906],
            [-75.9580669852664, -10.85384585374084],
            [-75.95743236026583, -10.854692228741609],
            [-75.95573211026424, -10.858778228745427],
            [-75.95476873526333, -10.859552728746145],
            [-75.95367311026233, -10.8602518537468],
            [-75.95250661026125, -10.861439478747902],
            [-75.95175098526053, -10.862856228749227],
            [-75.95156211026035, -10.863210478749552],
            [-75.94941323525835, -10.864698103750946],
            [-75.94034411024991, -10.869975978755855],
            [-75.93717836024696, -10.872662353758356],
            [-75.93740973524719, -10.875435353760937],
            [-75.93848111024816, -10.87888947876416],
            [-75.9385106102482, -10.881395978766502],
            [-75.93834473524804, -10.882476103767486],
            [-75.93713673524692, -10.886592853771338],
            [-75.92877061023914, -10.882948853767939],
            [-75.9227519852335, -10.8831464787681],
            [-75.91960861023058, -10.880988103766095],
            [-75.91914761023014, -10.880065978765256],
            [-75.91776448522887, -10.87528572876081],
            [-75.91638136022758, -10.873902853759509],
            [-75.91339686022481, -10.872201228757921],
            [-75.91316023522458, -10.872001103757759],
            [-75.91130198522285, -10.872208353757934],
            [-75.91043698522205, -10.87205197875778],
            [-75.9080389852198, -10.871446353757214],
            [-75.90714273521897, -10.871276853757085],
            [-75.90622261021814, -10.87101122875682],
            [-75.90401798521606, -10.870235228756082],
            [-75.90224973521441, -10.86987197875575],
            [-75.9002834852126, -10.869767353755666],
            [-75.89967686021203, -10.869631103755522],
            [-75.8960192352086, -10.868940353754871],
            [-75.89532148520799, -10.8691471037551],
            [-75.89281448520563, -10.870246103756092],
            [-75.89064361020361, -10.869767353755671],
            [-75.88963561020269, -10.869147103755086],
            [-75.88287311019637, -10.86990147875578],
            [-75.88132823519494, -10.869663853755554],
            [-75.88031873519398, -10.869044728754993],
            [-75.87404948518815, -10.867681853753712],
            [-75.87280036018699, -10.86824972875425],
            [-75.87080123518511, -10.86995335375585],
            [-75.87039248518472, -10.871316228757102],
            [-75.87032423518467, -10.872815353758503],
            [-75.8698018601842, -10.8745417287601],
            [-75.86959736018399, -10.874882478760448],
            [-75.8686207351831, -10.874927853760473],
            [-75.86784836018235, -10.87526860376078],
            [-75.86764761018217, -10.875360853760869],
            [-75.86641736018102, -10.875995478761476],
            [-75.86478186017949, -10.876585978762026],
            [-75.86402536017879, -10.875606978761114],
            [-75.8639086101787, -10.875312228760835],
            [-75.86294886017782, -10.872604853758297],
            [-75.86225648517717, -10.872082103757801],
            [-75.86176323517671, -10.871603603757357],
            [-75.86180961017675, -10.870876228756691],
            [-75.86115286017612, -10.867938978753962],
            [-75.85825936017342, -10.867856978753881],
            [-75.855633110171, -10.870323478756179],
            [-75.85505586017047, -10.870874728756688],
            [-75.85396998516944, -10.87189872875766],
            [-75.85199236016759, -10.873006228758662],
            [-75.85138911016703, -10.873488228759122],
            [-75.85043798516615, -10.87591910376139],
            [-75.850264610166, -10.877033228762427],
            [-75.84979423516553, -10.880425103765583],
            [-75.8493156101651, -10.880883103766022],
            [-75.84670773516267, -10.881180228766281],
            [-75.84568423516173, -10.88144435376653],
            [-75.83964298515609, -10.885627228770431],
            [-75.8369679851536, -10.886036728770824],
            [-75.83283361014976, -10.880224478765399],
            [-75.8322342351492, -10.879297978764521],
            [-75.82909911014627, -10.875623103761106],
            [-75.82856073514579, -10.8753974787609],
            [-75.82732798514463, -10.875189103760706],
            [-75.8174253601354, -10.873315728758962],
            [-75.81524323513337, -10.871888853757644],
            [-75.8150568601332, -10.871315228757103],
            [-75.81452998513268, -10.86998622875587],
            [-75.8150758601332, -10.866301103752443],
            [-75.8179606101359, -10.862374103748776],
            [-75.8181694851361, -10.861418978747885],
            [-75.81729111013527, -10.850906103738083],
            [-75.81622848513429, -10.848482603735853],
            [-75.81233736013064, -10.84458022873221],
            [-75.80930948512784, -10.842083228729878],
            [-75.80526548512408, -10.837986353726045],
            [-75.80254873512152, -10.83600622872421],
            [-75.80046011011959, -10.834645478722958],
            [-75.79789673511722, -10.832651603721098],
            [-75.7920899851118, -10.828019978716782],
            [-75.79092998511074, -10.827439978716257],
            [-75.78988998510975, -10.826619978715485],
            [-75.78913998510906, -10.825269978714225],
            [-75.78661998510671, -10.824809978713803],
            [-75.78598998510611, -10.824559978713546],
            [-75.78548998510564, -10.824639978713641],
            [-75.78459998510482, -10.82494997871393],
            [-75.78256998510292, -10.825319978714278],
            [-75.78063998510112, -10.82532997871427],
            [-75.78020998510074, -10.825309978714273],
            [-75.77880998509943, -10.825509978714457],
            [-75.77782998509852, -10.825859978714766],
            [-75.7759999850968, -10.825689978714621],
            [-75.77368998509466, -10.825949978714851],
            [-75.77268998509375, -10.82592997871483],
            [-75.77155998509268, -10.826419978715284],
            [-75.7700698600913, -10.826449978715322],
            [-75.7687899850901, -10.827169978715995],
            [-75.76849998508983, -10.827329978716143],
            [-75.76735998508875, -10.827569853716374],
            [-75.76571986008726, -10.827859978716651],
            [-75.76528998508684, -10.828019978716778],
            [-75.76293998508464, -10.829989978718627],
            [-75.76211998508387, -10.830359978718969],
            [-75.76136998508318, -10.830099978718719],
            [-75.76057986008247, -10.829829978718468],
            [-75.75970986008164, -10.830189978718813],
            [-75.75964998508158, -10.83016010371878],
            [-75.75772998507978, -10.829719978718375],
            [-75.75512998507736, -10.828599978717339],
            [-75.7534599850758, -10.827419978716216],
            [-75.7524599850749, -10.826849978715703],
            [-75.75067998507323, -10.825389978714329],
            [-75.75021986007282, -10.825279978714228],
            [-75.74761998507039, -10.824209978713245],
            [-75.74701998506981, -10.823709978712763],
            [-75.74310998506618, -10.82253997871169],
            [-75.74202998506517, -10.821149978710386],
            [-75.73777998506122, -10.81612997870571],
            [-75.73718998506067, -10.815629978705239],
            [-75.73539998505898, -10.814609978704286],
            [-75.734329985058, -10.812579978702415],
            [-75.73355998505727, -10.811019978700948],
            [-75.73353998505726, -10.810139978700132],
            [-75.73320573505694, -10.809409353699444],
            [-75.73237186005616, -10.80919197869926],
            [-75.7286384850527, -10.809373103699423],
            [-75.72746798505162, -10.809143603699196],
            [-75.72542911004969, -10.808719853698818],
            [-75.72393361004829, -10.8084906036986],
            [-75.72350961004791, -10.80853947869863],
            [-75.72224098504674, -10.808775103698846],
            [-75.72135286004593, -10.808793228698876],
            [-75.7201597350448, -10.809011103699076],
            [-75.71901673504374, -10.809830228699838],
            [-75.71872986004348, -10.810123228700126],
            [-75.7166149850415, -10.811903978701768],
            [-75.71410586003915, -10.813652478703407],
            [-75.71077686003603, -10.814510353704204],
            [-75.71018448503551, -10.814530853704216],
            [-75.70952586003489, -10.81473747870442],
            [-75.70905486003444, -10.815463228705074],
            [-75.70840186003386, -10.815744478705344],
            [-75.70638936003198, -10.81537997870502],
            [-75.70605636003165, -10.815325353704955],
            [-75.70475873503045, -10.815543228705163],
            [-75.70245923502831, -10.81673160370627],
            [-75.69674561002299, -10.816936603706464],
            [-75.69090673501756, -10.819478978708815],
            [-75.68995686001668, -10.823027103712116],
            [-75.69077648501744, -10.830213228718822],
            [-75.69329098501979, -10.832968728721394],
            [-75.6934359850199, -10.833703978722074],
            [-75.69217273501874, -10.836130728724337],
            [-75.69132773501794, -10.836565978724728],
            [-75.68416398501128, -10.838111228726175],
            [-75.68041623500777, -10.839282978727285],
            [-75.67765998500522, -10.83919997872721],
            [-75.67641998500405, -10.838629978726656],
            [-75.67545998500316, -10.83785997872596],
            [-75.67444998500224, -10.8377099787258],
            [-75.6741099850019, -10.837649978725755],
            [-75.6735698600014, -10.83732997872544],
            [-75.67256998500046, -10.836569978724752],
            [-75.67231673500024, -10.83625860372444],
            [-75.67230948500023, -10.836223478724433],
            [-75.67171960999967, -10.834658728722948],
            [-75.67112998499914, -10.832799978721225],
            [-75.66985110999795, -10.831952103720447],
            [-75.6691599849973, -10.831479978720013],
            [-75.66832998499652, -10.8310301037196],
            [-75.6679799849962, -10.83088997871946],
            [-75.66742123499567, -10.830356728718952],
            [-75.66696998499526, -10.829599978718253],
            [-75.66609998499445, -10.828579978717304],
            [-75.66552998499392, -10.828529978717262],
            [-75.66386998499236, -10.827709978716484],
            [-75.66167998499033, -10.82735060371615],
            [-75.66062960998937, -10.8270703537159],
            [-75.65928998498809, -10.82684997871571],
            [-75.65730010998625, -10.825759978714677],
            [-75.65501998498412, -10.825309978714275],
            [-75.65139998498077, -10.825259978714204],
            [-75.64147810997152, -10.826618728715495],
            [-75.64085060997093, -10.82678610371561],
            [-75.636639859967, -10.827054978715898],
            [-75.63239585996304, -10.825745603714658],
            [-75.62851335995944, -10.824842603713817],
            [-75.62679760995786, -10.824662103713651],
            [-75.62327598495456, -10.824075103713122],
            [-75.62219248495354, -10.823849353712893],
            [-75.61923085995079, -10.823582978712645],
            [-75.61514935994698, -10.822463353711607],
            [-75.61000860994221, -10.823564978712625],
            [-75.60598360993848, -10.82259185371172],
            [-75.60523160993775, -10.820837103710085],
            [-75.60406935993669, -10.8177378537072],
            [-75.6037548599364, -10.816899603706418],
            [-75.59834948493136, -10.81242810370227],
            [-75.59714160993022, -10.811721728701592],
            [-75.594760234928, -10.811171853701103],
            [-75.59313648492649, -10.811018103700944],
            [-75.59069373492422, -10.81156122870146],
            [-75.58999298492358, -10.811273853701172],
            [-75.58933273492293, -10.808818228698895],
            [-75.58739598492114, -10.804317228694721],
            [-75.58387585991784, -10.800797103691423],
            [-75.58183935991597, -10.792783853683973],
            [-75.58044285991467, -10.790796478682127],
            [-75.57958335991385, -10.78843310367992],
            [-75.57728360991172, -10.783028478674881],
            [-75.57656085991104, -10.780184103672235],
            [-75.5766074849111, -10.779251478671371],
            [-75.5768406099113, -10.777945978670141],
            [-75.57691060991137, -10.777173728669432],
            [-75.57619373491072, -10.776351853668672],
            [-75.57603635991056, -10.775879728668233],
            [-75.57636073491085, -10.773602728666111],
            [-75.57606848491058, -10.772657353665217],
            [-75.57546698491001, -10.772073103664669],
            [-75.57452185990915, -10.770887228663575],
            [-75.5742467349089, -10.769821853662584],
            [-75.57404048490869, -10.76896235366177],
            [-75.57386860990853, -10.767879603660756],
            [-75.57393735990861, -10.76569697865875],
            [-75.57417798490883, -10.763067228656302],
            [-75.57467998490931, -10.76162497865493],
            [-75.57571260991027, -10.759309603652783],
            [-75.57536848490993, -10.75849610365202],
            [-75.57549373491007, -10.753615103647487],
            [-75.57514948490974, -10.752676478646617],
            [-75.57452373490916, -10.750110728644216],
            [-75.57430460990894, -10.749203353643374],
            [-75.5738221099085, -10.746796103641138],
            [-75.57389785990857, -10.744416228638926],
            [-75.57315410990789, -10.74296622863759],
            [-75.57201448490679, -10.741915228636607],
            [-75.57164098490647, -10.740938603635675],
            [-75.57118698490603, -10.739667353634506],
            [-75.5705136099054, -10.7379537286329],
            [-75.56949223490447, -10.736387603631446],
            [-75.56876598490378, -10.733981728629207],
            [-75.5681304849032, -10.732642603627967],
            [-75.56825523490329, -10.731709103627084],
            [-75.56874898490375, -10.730364228625833],
            [-75.56832335990337, -10.728457728624049],
            [-75.56842548490347, -10.727946978623585],
            [-75.5683542349034, -10.727441353623108],
            [-75.56581998490104, -10.728669978624252],
            [-75.5646899849, -10.729089978624641],
            [-75.56305998489849, -10.730899978626335],
            [-75.5624499848979, -10.732299978627628],
            [-75.56228998489776, -10.733249978628526],
            [-75.56080998489638, -10.73650997863155],
            [-75.56004998489568, -10.740149978634955],
            [-75.55904998489474, -10.741619978636326],
            [-75.55814998489392, -10.74199997863667],
            [-75.55787998489366, -10.742329978636976],
            [-75.55743998489324, -10.743569978638137],
            [-75.55691998489276, -10.74406997863861],
            [-75.55677998489263, -10.744119978638645],
            [-75.55601998489193, -10.744259978638777],
            [-75.55501998489099, -10.744569978639078],
            [-75.55253998488867, -10.745199978639654],
            [-75.55117998488741, -10.745509978639944],
            [-75.54902998488541, -10.745049978639516],
            [-75.54839998488482, -10.744549978639036],
            [-75.54596998488253, -10.742459978637108],
            [-75.54414998488085, -10.741199978635937],
            [-75.5424799848793, -10.740969978635727],
            [-75.5419201098788, -10.741089978635813],
            [-75.53875998487585, -10.742289978636931],
            [-75.53712998487434, -10.742059978636712],
            [-75.53660998487383, -10.742109978636762],
            [-75.53629998487357, -10.74221997863689],
            [-75.53553998487284, -10.74273997863736],
            [-75.53321985987068, -10.743529978638094],
            [-75.531419984869, -10.743289978637872],
            [-75.53124998486886, -10.743469978638043],
            [-75.53083998486848, -10.74528997863973],
            [-75.53072998486836, -10.746009978640414],
            [-75.53061998486827, -10.746969978641284],
            [-75.52954998486727, -10.748609978642834],
            [-75.5287199848665, -10.748949978643145],
            [-75.52725998486513, -10.749499978643666],
            [-75.52370998486184, -10.7528899786468],
            [-75.52307998486124, -10.753669978647544],
            [-75.5219599848602, -10.753949978647796],
            [-75.52093998485925, -10.75430997864815],
            [-75.51719998485576, -10.7539599786478],
            [-75.51568998485435, -10.75502997864879],
            [-75.5144699848532, -10.756369978650051],
            [-75.51371998485253, -10.756429978650125],
            [-75.5134901098523, -10.756369978650051],
            [-75.51310998485197, -10.756100103649805],
            [-75.51283998485171, -10.755849978649563],
            [-75.51047998484951, -10.754519978648315],
            [-75.51001998484908, -10.7542499786481],
            [-75.50935998484847, -10.753919978647755],
            [-75.50923998484835, -10.753919978647769],
            [-75.50798998484719, -10.754619978648433],
            [-75.50524998484464, -10.756779978650423],
            [-75.50278998484235, -10.758049978651632],
            [-75.5025399848421, -10.757989978651548],
            [-75.50061998484031, -10.758059978651637],
            [-75.49999998483976, -10.758379978651924],
            [-75.49664998483662, -10.75699010365062],
            [-75.4962999848363, -10.755979978649686],
            [-75.49477998483489, -10.755159978648917],
            [-75.49453998483467, -10.755229853648991],
            [-75.49024998483067, -10.756829978650478],
            [-75.48922998482972, -10.760349853653764],
            [-75.48848998482902, -10.761389978654739],
            [-75.48484998482563, -10.764989978658097],
            [-75.48411998482496, -10.764989978658095],
            [-75.48231998482329, -10.774709978667142],
            [-75.4821399848231, -10.776389978668712],
            [-75.47788998481916, -10.780959978672973],
            [-75.47419998481571, -10.780139978672189],
            [-75.4718399848135, -10.77880997867095],
            [-75.47131998481302, -10.77819997867038],
            [-75.4701199848119, -10.776849978669144],
            [-75.46232248480464, -10.776703228668989],
            [-75.46106598480347, -10.777645603669873],
            [-75.45985098480234, -10.778959478671087],
            [-75.45948560980199, -10.780316603672354],
            [-75.4592768598018, -10.782110728674013],
            [-75.45920335980175, -10.784274478676064],
            [-75.45935710980189, -10.785414478677103],
            [-75.46009010980258, -10.786502603678121],
            [-75.46148798480385, -10.78758385367914],
            [-75.46176123480414, -10.788648728680107],
            [-75.4641972348064, -10.791367103682665],
            [-75.46996573481177, -10.79749447868835],
            [-75.47059010981236, -10.799123853689881],
            [-75.4698887348117, -10.801259853691867],
            [-75.46948648481133, -10.801941353692492],
            [-75.46805623480999, -10.80755022869773],
            [-75.46897423481086, -10.807995603698142],
            [-75.4709602348127, -10.808988728699063],
            [-75.47421510981574, -10.809763728699778],
            [-75.4790199848202, -10.815705103705318],
            [-75.47974323482087, -10.817926728707398],
            [-75.47741810981871, -10.82270072871182],
            [-75.4767753598181, -10.823955103712988],
            [-75.47456835981608, -10.829017603717709],
            [-75.47530435981675, -10.83323422872163],
            [-75.47540935981685, -10.834650978722943],
            [-75.47551423481693, -10.83989822872784],
            [-75.47514698481658, -10.842993978730732],
            [-75.476660484818, -10.844224728731888],
            [-75.47819035981944, -10.845465728733021],
            [-75.47976448482089, -10.847506603734928],
            [-75.4804072348215, -10.848719978736064],
            [-75.48030860982142, -10.853039103740093],
            [-75.47800660981927, -10.859581603746157],
            [-75.4779279848192, -10.86139185374787],
            [-75.4782666098195, -10.863712603750033],
            [-75.47791348481917, -10.864970228751181],
            [-75.47550985981694, -10.8676772287537],
            [-75.47411623481564, -10.87009297875596],
            [-75.47277923481438, -10.874437478759988],
            [-75.47263998481428, -10.875959728761433],
            [-75.47283423481444, -10.878873978764146],
            [-75.47285248481445, -10.881373853766458],
            [-75.47233335981399, -10.88282197876782],
            [-75.4695738598114, -10.88508960376993],
            [-75.4686132348105, -10.885943353770717],
            [-75.46712448480913, -10.888164728772804],
            [-75.46717423480918, -10.890367103774846],
            [-75.4674317348094, -10.890983728775403],
            [-75.46766148480961, -10.892184103776533],
            [-75.46734123480933, -10.895948103780057],
            [-75.46608648480817, -10.899641978783473],
            [-75.46591348480801, -10.905695978789112],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 10,
        FIRST_IDPR: '0809',
        NOMBPROV: 'LA CONVENCION',
        FIRST_NOMB: 'CUSCO',
        LAST_DCTO: 'LEY',
        LAST_LEY: 'S/N',
        FIRST_FECH: '17/03/1961',
        LAST_FECHA: '02/01/1857',
        MIN_SHAPE_: 3188238.47347,
        ha: 3188238.47,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.83077773328498, -12.53050810530227],
            [-73.88679248333717, -12.40539698018576],
            [-73.96585898341087, -12.336451355121568],
            [-73.98045160842446, -12.242588355034144],
            [-73.92383685837173, -12.224749230017514],
            [-73.84324998329662, -12.239429980031197],
            [-73.80189398325805, -12.22029710501337],
            [-73.73694073319751, -12.266786980056674],
            [-73.70781423317035, -12.267907230057714],
            [-73.63715048310446, -12.378367980160583],
            [-73.59046535806092, -12.376944230159253],
            [-73.57432635804585, -12.276842355066027],
            [-73.46964848294819, -12.219966480013085],
            [-73.44606060792616, -12.160289104957476],
            [-73.36188735784756, -12.135964729934836],
            [-73.40768910789032, -12.065989729869662],
            [-73.39788485788118, -12.020943479827729],
            [-73.43769435791835, -11.989631854798581],
            [-73.44113498292157, -11.944200104756256],
            [-73.49053160796767, -11.878170479694747],
            [-73.57750510804881, -11.836484729655934],
            [-73.57448123304597, -11.73414547956065],
            [-73.60826960807752, -11.660160104491741],
            [-73.55965098303216, -11.592050979428304],
            [-73.44972760792957, -11.531943104372335],
            [-73.47971623295757, -11.4713638543159],
            [-73.41974948290158, -11.383938229234493],
            [-73.331330607819, -11.311264729166817],
            [-73.25744573274996, -11.31270885416815],
            [-73.20330073269935, -11.289990354146976],
            [-73.13737448263765, -11.293773104150501],
            [-73.02650885753387, -11.26408460412282],
            [-72.98966460749936, -11.198796854062017],
            [-72.96858360747962, -11.264121229122859],
            [-72.939126107452, -11.282077854139564],
            [-72.95190860746398, -11.330629604184779],
            [-72.89701898241249, -11.357926104210184],
            [-72.7942939823161, -11.38429072923471],
            [-72.73823760726343, -11.347429354200365],
            [-72.70656135723364, -11.381505354232067],
            [-72.63212423216363, -11.406400854255223],
            [-72.6099874821428, -11.428238354275534],
            [-72.53508560707222, -11.435080479281861],
            [-72.47986810702017, -11.387420604237432],
            [-72.42512710696852, -11.368487354219724],
            [-72.37883010692482, -11.332638729186295],
            [-72.30770898185762, -11.30699997916233],
            [-72.23925835679285, -11.345060979197648],
            [-72.24789448180097, -11.385005854234858],
            [-72.29255073184324, -11.433273104279875],
            [-72.31550810686487, -11.595576854431048],
            [-72.35094460689835, -11.621804854455542],
            [-72.42873873197183, -11.708971854536797],
            [-72.42506448196835, -11.733169229559326],
            [-72.37076798191703, -11.784967354607481],
            [-72.39130173193642, -11.826346229646072],
            [-72.33215410688047, -11.855527479673144],
            [-72.34751423189499, -11.891267479706455],
            [-72.33746598188544, -11.968075229777968],
            [-72.28455073183537, -11.97643035478566],
            [-72.25930310681142, -12.006302729813424],
            [-72.26893285682053, -12.054375354858204],
            [-72.17373298173024, -12.059810354863076],
            [-72.09444660665494, -12.085190979886534],
            [-72.08755860664833, -12.142690354940031],
            [-72.10551185666529, -12.238059855028887],
            [-72.0338407315971, -12.265044855053835],
            [-71.97303123153908, -12.320543355105288],
            [-71.97601323154183, -12.398782855178156],
            [-72.06148935662321, -12.44044935521723],
            [-72.07113235663235, -12.511484230283388],
            [-72.12451123168309, -12.54849698031801],
            [-72.13537723169335, -12.616971355381791],
            [-72.18576760674124, -12.657139730419305],
            [-72.22658373178001, -12.664842105426573],
            [-72.31641673186522, -12.648935105411942],
            [-72.36288673190921, -12.718562480476843],
            [-72.36225623190856, -12.824412605575409],
            [-72.31194410686082, -12.923645605667732],
            [-72.22619273177946, -12.953121605695014],
            [-72.21589160676963, -12.991326730730547],
            [-72.1693167317253, -13.02424335576108],
            [-72.19063460674556, -13.096150355828097],
            [-72.23167448178454, -13.126289480856267],
            [-72.28129935683167, -13.10770373083907],
            [-72.32798373187593, -13.164332105891864],
            [-72.38955473193427, -13.174134730901116],
            [-72.43639360697858, -13.151616605880202],
            [-72.49028523202956, -13.09972623083197],
            [-72.54205573207844, -13.082345980815825],
            [-72.5997714821329, -13.107242230839049],
            [-72.5544238570901, -13.230080105953403],
            [-72.54668160708277, -13.332609356048883],
            [-72.61639910714852, -13.382089731095013],
            [-72.72208198224808, -13.404720731116186],
            [-72.77286310729582, -13.44888060615733],
            [-72.79341323231516, -13.426124481136167],
            [-72.88630810740239, -13.415865356126659],
            [-72.8940861074097, -13.390126606102687],
            [-72.97146198248227, -13.390228481102813],
            [-73.0151257325232, -13.42666885613675],
            [-73.10338123260587, -13.448589231157188],
            [-73.160164357659, -13.443338231152328],
            [-73.21192923270742, -13.419101856129755],
            [-73.28735385777792, -13.371942981085844],
            [-73.34836285783493, -13.298240606017213],
            [-73.42190960790361, -13.132394605862771],
            [-73.44883935792875, -13.107873605839952],
            [-73.47796685795595, -13.032258730769522],
            [-73.52166198299673, -12.990687730730805],
            [-73.52367648299861, -12.899478105645887],
            [-73.59080935806122, -12.844288605594501],
            [-73.62566723309376, -12.76499248052065],
            [-73.72536698318672, -12.640693855404892],
            [-73.7482899832081, -12.657660230420674],
            [-73.80117298325739, -12.613024230379144],
            [-73.83077773328498, -12.53050810530227],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 8,
        FIRST_IDPR: '0505',
        NOMBPROV: 'LA MAR',
        FIRST_NOMB: 'AYACUCHO',
        LAST_DCTO: 'LEY',
        LAST_LEY: '10175',
        FIRST_FECH: 'EPOCA INDEP.',
        LAST_FECHA: '17/01/1945',
        MIN_SHAPE_: 430659.408242,
        ha: 430659.41,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.83802935829173, -13.384433606097497],
            [-73.86984960832142, -13.349537106065023],
            [-73.9255407333733, -13.324075606041301],
            [-73.9459402333923, -13.229095855952847],
            [-73.970305358415, -13.173409105900992],
            [-74.05812835849683, -13.062704105797895],
            [-74.07742685851481, -12.984748105725302],
            [-74.10399448353955, -12.982063230722805],
            [-74.14423648357706, -12.877754105625659],
            [-73.99568373343867, -12.83829385558892],
            [-73.98808785843156, -12.74986910550655],
            [-73.8881074833384, -12.711048855470413],
            [-73.85380273330645, -12.645472605409354],
            [-73.83077773328498, -12.53050810530227],
            [-73.80117298325739, -12.613024230379144],
            [-73.7482899832081, -12.657660230420674],
            [-73.72536698318672, -12.640693855404892],
            [-73.62566723309376, -12.76499248052065],
            [-73.59080935806122, -12.844288605594501],
            [-73.52367648299861, -12.899478105645887],
            [-73.52166198299673, -12.990687730730805],
            [-73.47796685795595, -13.032258730769522],
            [-73.44883935792875, -13.107873605839952],
            [-73.42190960790361, -13.132394605862771],
            [-73.34836285783493, -13.298240606017213],
            [-73.28735385777792, -13.371942981085844],
            [-73.21192923270742, -13.419101856129755],
            [-73.23390323272797, -13.476586981183294],
            [-73.34716335783382, -13.434832231144412],
            [-73.40332335788626, -13.444750606153669],
            [-73.44538985792552, -13.430359981140288],
            [-73.4809101079587, -13.431371106141212],
            [-73.57175973304346, -13.34183398105783],
            [-73.64194923310896, -13.299478481018385],
            [-73.72622260818753, -13.268662105989717],
            [-73.75643960821569, -13.170714855898474],
            [-73.79702935825354, -13.199943355925692],
            [-73.82282023327755, -13.285052106004972],
            [-73.83802935829173, -13.384433606097497],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 11,
        FIRST_IDPR: '0408',
        NOMBPROV: 'LA UNION',
        FIRST_NOMB: 'AREQUIPA',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: '19/11/1954',
        LAST_FECHA: 'EPOCA INDEP.',
        MIN_SHAPE_: 489566.27444,
        ha: 489566.27,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.20541385770134, -15.510603858077449],
            [-73.27479548276622, -15.39295385796792],
            [-73.290618857781, -15.371318857947765],
            [-73.28491973277569, -15.294937107876633],
            [-73.24593348273923, -15.286091857868389],
            [-73.17454523267247, -15.306607232887476],
            [-73.11683660761845, -15.222851607809472],
            [-73.11074823261278, -15.186106732775247],
            [-73.04756160755359, -15.134347857727025],
            [-73.004241982513, -15.064126982661612],
            [-73.00534823251402, -15.00931648261057],
            [-72.9866562324965, -14.956103357561013],
            [-73.0314636075385, -14.900051107508839],
            [-73.01420598252233, -14.855708107467537],
            [-73.00379185751257, -14.832489982445912],
            [-73.0030822325119, -14.816466232430985],
            [-72.99887848250796, -14.812788982427564],
            [-72.9971161075063, -14.81157973242644],
            [-72.99482723250418, -14.806168607421396],
            [-72.99180598250133, -14.803670857419064],
            [-72.99058535750018, -14.803999857419367],
            [-72.98563385749554, -14.804850607420157],
            [-72.97740935748783, -14.80436423241971],
            [-72.97675323248723, -14.803289357418711],
            [-72.97498323248554, -14.800236732415867],
            [-72.97087873248171, -14.796828232412683],
            [-72.96926885748019, -14.795148857411128],
            [-72.95567323246743, -14.779291107396343],
            [-72.95407873246593, -14.776809732394046],
            [-72.95338435746531, -14.77641960739367],
            [-72.95207973246406, -14.775205607392552],
            [-72.94084935745353, -14.764800107382843],
            [-72.93504335744808, -14.762762107380937],
            [-72.932830857446, -14.761114107379413],
            [-72.9299239824433, -14.75816635737667],
            [-72.91870885743276, -14.759497607377892],
            [-72.91828160743236, -14.760509482378854],
            [-72.91799910743211, -14.761648232379894],
            [-72.90850073242318, -14.770218857387873],
            [-72.89982610741504, -14.770548857388178],
            [-72.89600373241146, -14.77118585738877],
            [-72.88927460740514, -14.771409982388985],
            [-72.88789373240382, -14.771859107389394],
            [-72.88709260740308, -14.771959232389488],
            [-72.8818666073982, -14.770695732388312],
            [-72.88101960739739, -14.769503607387199],
            [-72.88020323239662, -14.76651760738442],
            [-72.87990573239635, -14.766169482384088],
            [-72.87757098239415, -14.765304607383282],
            [-72.8766479823933, -14.765451482383414],
            [-72.87256623238946, -14.761568107379802],
            [-72.8724059823893, -14.761472732379712],
            [-72.86970523238676, -14.761937107380145],
            [-72.86814123238527, -14.762743982380893],
            [-72.8627167323802, -14.763011982381135],
            [-72.85891723237664, -14.763816857381885],
            [-72.85663610737448, -14.762659982380814],
            [-72.8547134823727, -14.762963357381082],
            [-72.85267635737077, -14.763077732381195],
            [-72.85183710736997, -14.762714357380862],
            [-72.85107423236927, -14.7623348573805],
            [-72.84682460736528, -14.759823857378157],
            [-72.82857510734813, -14.702628107324887],
            [-72.7896957323116, -14.650213232276062],
            [-72.72470860725043, -14.675900482299905],
            [-72.64482110717515, -14.667281107291819],
            [-72.57724760711137, -14.686616857309744],
            [-72.47177123201166, -14.661398857286095],
            [-72.48612973202525, -14.712543482333754],
            [-72.47094735701083, -14.774774607391675],
            [-72.40647885694973, -14.827085482440271],
            [-72.31544485686328, -14.833863232446383],
            [-72.30193323185041, -14.895695732503933],
            [-72.32307435687048, -14.930916732536772],
            [-72.39916223194277, -14.957482357561666],
            [-72.41031648195332, -14.993303357595046],
            [-72.52692410706375, -15.058617607656059],
            [-72.48750310702643, -15.111144107704913],
            [-72.50601960704395, -15.17798810776718],
            [-72.64055635717108, -15.235960982821348],
            [-72.73963160726443, -15.253165232837475],
            [-72.78847498231039, -15.274204232857112],
            [-72.83719635735619, -15.317884482897824],
            [-72.87067410738766, -15.404218732978242],
            [-72.93378448244691, -15.394479732969215],
            [-72.97207635748283, -15.406104982980057],
            [-72.99749773250666, -15.503433233070714],
            [-73.08298498258677, -15.561493858124813],
            [-73.11695860761859, -15.572004358134617],
            [-73.20541385770134, -15.510603858077449],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 11,
        FIRST_IDPR: '2205',
        NOMBPROV: 'LAMAS',
        FIRST_NOMB: 'SAN MARTIN',
        LAST_DCTO: 'LEY',
        LAST_LEY: 'S/N',
        FIRST_FECH: '16/10/1933',
        LAST_FECHA: '25/11/1876',
        MIN_SHAPE_: 467896.37639,
        ha: 467896.38,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.83514448608479, -6.285152349485925],
            [-76.81532998606627, -6.244319974447888],
            [-76.84035161108966, -6.104993599318144],
            [-76.88346998612998, -6.082209974296933],
            [-76.84563986109461, -6.030359974248639],
            [-76.78682998603962, -6.023879974242596],
            [-76.75443473600934, -5.998390724218856],
            [-76.71076948596853, -6.013665974233096],
            [-76.64001136090242, -6.002604724222798],
            [-76.54845298581694, -6.022454724241259],
            [-76.41226948568983, -5.950664099174414],
            [-76.37278711065302, -5.973759724195926],
            [-76.3182966106022, -6.08609497430054],
            [-76.27324086056016, -6.104386349317592],
            [-76.22363086051394, -6.166031224374997],
            [-76.12721098542403, -6.176705099384955],
            [-76.03210261033543, -6.09966409931318],
            [-75.96543523527328, -6.095198099309019],
            [-75.91589186022712, -6.141157849351833],
            [-75.93317423524324, -6.212657974418413],
            [-75.97853473528549, -6.294108974494259],
            [-75.92919573523955, -6.356835099552689],
            [-75.90199773521417, -6.459167849647993],
            [-75.9540593602627, -6.490474599677161],
            [-76.00728198531229, -6.498629474684748],
            [-76.0690168603698, -6.472735224660636],
            [-76.13257886042905, -6.499500349685572],
            [-76.18054973547375, -6.500832099686803],
            [-76.1824129854755, -6.430300599621118],
            [-76.26985136055703, -6.426474974617546],
            [-76.31705611060103, -6.364468724559801],
            [-76.35598861063733, -6.339355474536406],
            [-76.37959398565935, -6.391298349584792],
            [-76.42548236070219, -6.381304349575492],
            [-76.47825586075142, -6.483931599671047],
            [-76.46273048573694, -6.52599022471023],
            [-76.41839373569556, -6.561830224743606],
            [-76.40792761068577, -6.635648099812371],
            [-76.45631798573095, -6.680006349853672],
            [-76.5242096107943, -6.69287022486566],
            [-76.5584671108263, -6.671431474845691],
            [-76.53457648580398, -6.625487724802891],
            [-76.59124261085688, -6.594961224774466],
            [-76.64072461090309, -6.546896599729702],
            [-76.67893048593876, -6.434741849625258],
            [-76.78034786103355, -6.418222849609855],
            [-76.84320898609234, -6.369173849564182],
            [-76.83514448608479, -6.285152349485925],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 12,
        FIRST_IDPR: '1403',
        NOMBPROV: 'LAMBAYEQUE',
        FIRST_NOMB: 'LAMBAYEQUE',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: '17/11/1894',
        LAST_FECHA: 'EPOCA INDEP.',
        MIN_SHAPE_: 941287.6922700001,
        ha: 941287.69,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.94239648870789, -6.820499974951464],
            [-79.98602923872065, -6.747052724880993],
            [-80.07488673874377, -6.675523474809501],
            [-80.17732036375986, -6.606037224738394],
            [-80.28864261376286, -6.54308197467172],
            [-80.62713286365211, -6.372080099480027],
            [-80.37621873871724, -6.006968724169357],
            [-80.32741411372082, -5.947441349118387],
            [-80.19891411372186, -5.892999974077376],
            [-80.1308632387153, -5.888665974077644],
            [-80.05298436369806, -5.811244099010406],
            [-79.99483011368133, -5.751945348958449],
            [-79.96853911366736, -5.581165973801462],
            [-79.9458729886582, -5.514996098741152],
            [-79.90305486364332, -5.491449223721136],
            [-79.85398636362648, -5.531818348760535],
            [-79.86441986363151, -5.563419973789447],
            [-79.83943748862367, -5.630027348852201],
            [-79.78693361360153, -5.6465878488695],
            [-79.71728123857058, -5.73934759895799],
            [-79.73732811358329, -5.837650349048583],
            [-79.68271098855526, -5.846445349058528],
            [-79.63142773852653, -5.829029349043918],
            [-79.62641986352503, -5.887449224098346],
            [-79.60044923851106, -5.939570349147491],
            [-79.49893948844769, -5.915189474127525],
            [-79.41860936339506, -5.970099599180389],
            [-79.41721111339469, -6.005238224213097],
            [-79.40308373838535, -6.034022474240159],
            [-79.42817773840466, -6.148095724345632],
            [-79.4018454883868, -6.173506349369855],
            [-79.481981113444, -6.306308224491405],
            [-79.54328248848506, -6.357212724537087],
            [-79.52833786347635, -6.399812474577072],
            [-79.61344336353012, -6.425292974598248],
            [-79.65191411355362, -6.455400349624978],
            [-79.75268948860837, -6.439371099606508],
            [-79.80893748863639, -6.447826224612077],
            [-79.8474978636609, -6.639937474788646],
            [-79.78142973863051, -6.679587849828297],
            [-79.82948048865543, -6.721712849865331],
            [-79.89342573868434, -6.728828099868956],
            [-79.94239648870789, -6.820499974951464],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 10,
        FIRST_IDPR: '2107',
        NOMBPROV: 'LAMPA',
        FIRST_NOMB: 'PUNO',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: 'EPOCA INDEP.',
        LAST_FECHA: 'EPOCA INDEP.',
        MIN_SHAPE_: 574465.43221,
        ha: 574465.43,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.227386729765, -15.251970732780332],
            [-70.22113072975839, -15.251351357779212],
            [-70.20781647974326, -15.273205107798285],
            [-70.20717410474228, -15.279321357803891],
            [-70.20428710473836, -15.298203107821116],
            [-70.19543047972888, -15.29926960782131],
            [-70.18958610472264, -15.300034607821482],
            [-70.18371410471637, -15.300171732821077],
            [-70.1800754797125, -15.300336107820897],
            [-70.17782197971006, -15.30122810782152],
            [-70.17317860470503, -15.302717982822484],
            [-70.17203547970384, -15.301963607821667],
            [-70.16938347970115, -15.299174357818844],
            [-70.16702860469871, -15.29784835781739],
            [-70.16426222969588, -15.295241982814723],
            [-70.16364497969526, -15.294304607813798],
            [-70.1583637296897, -15.293458732812532],
            [-70.15265960468292, -15.3071876078247],
            [-70.14808597967779, -15.312634732829292],
            [-70.15370197968342, -15.320214232836845],
            [-70.15504397968466, -15.324016732840475],
            [-70.15690035468424, -15.373011732885978],
            [-70.17689497970518, -15.382839982896938],
            [-70.27426347981216, -15.314284357841919],
            [-70.30533210484242, -15.386123107910892],
            [-70.34296285488146, -15.41637110794177],
            [-70.25733422978422, -15.551551733060146],
            [-70.36518822989653, -15.662290358171182],
            [-70.43577122997218, -15.676841233189602],
            [-70.46536623000469, -15.65415698317054],
            [-70.57538135512063, -15.721438108239463],
            [-70.63381573018235, -15.744907233264339],
            [-70.6756276052258, -15.789271733307583],
            [-70.79725585535441, -15.780317608304662],
            [-70.890541105451, -15.851253483374048],
            [-70.93614198049937, -15.784839608313934],
            [-70.90013123046293, -15.712146733245207],
            [-70.91798398048242, -15.643800733182328],
            [-71.00817873057646, -15.56396860811096],
            [-71.01517485558448, -15.489165358041653],
            [-70.99395748056315, -15.454841608009149],
            [-71.03897098060976, -15.405165732964244],
            [-71.0122773555832, -15.343752107906438],
            [-70.95229923052247, -15.289843107854589],
            [-70.98528060555672, -15.249029607817665],
            [-70.96250248053387, -15.215636857785967],
            [-70.9936003555672, -15.084587357665066],
            [-70.83349460540487, -15.00208735758337],
            [-70.76082348032973, -15.030864482607365],
            [-70.65732310522064, -15.133998482698653],
            [-70.59585423015685, -15.126806732688985],
            [-70.56257973012069, -15.184009107740323],
            [-70.52008348007713, -15.15130135770763],
            [-70.55256073011338, -15.06363610762812],
            [-70.53183910509279, -15.027949857593875],
            [-70.50444985506728, -14.922429607494465],
            [-70.46551698002673, -14.928152732497491],
            [-70.4636224800248, -14.927640607496913],
            [-70.4629483550241, -14.927671482496896],
            [-70.46285148002394, -14.928716982497866],
            [-70.46407460502519, -14.92948423249865],
            [-70.46499760502614, -14.930396107499545],
            [-70.46553135502667, -14.931385732500507],
            [-70.46453048002553, -14.933565232502469],
            [-70.46088323002174, -14.93494398250352],
            [-70.44891823000914, -14.940270482507719],
            [-70.44823985500842, -14.940526232507935],
            [-70.44541548000535, -14.94490748251181],
            [-70.44608260500604, -14.94544123251235],
            [-70.44820873000815, -14.948617607515418],
            [-70.44771473000762, -14.949697107516393],
            [-70.44389060500353, -14.952313607518576],
            [-70.43929810499871, -14.954198107520051],
            [-70.43002560498913, -14.9524163575178],
            [-70.42975622998884, -14.952861357518207],
            [-70.42857347998755, -14.954266607519429],
            [-70.42818710498715, -14.955121357520204],
            [-70.4278241049867, -14.956409482521362],
            [-70.42791772998676, -14.957533732522426],
            [-70.42780072998663, -14.958142607522976],
            [-70.4205243549789, -14.963555232527524],
            [-70.4158058549739, -14.965931607529424],
            [-70.41347497997147, -14.965954357529304],
            [-70.41137147996928, -14.96585198252907],
            [-70.40674910496467, -14.959722357523079],
            [-70.40639985496433, -14.959009232522405],
            [-70.4046897299626, -14.958099607521438],
            [-70.40398385496182, -14.958434482521714],
            [-70.40257497996035, -14.959432357522552],
            [-70.40156372995915, -14.964255357526941],
            [-70.4021549799597, -14.96592010752852],
            [-70.40136147995877, -14.968860482531198],
            [-70.40063022995798, -14.969249482531506],
            [-70.39937010495659, -14.971303107533341],
            [-70.39765872995474, -14.974134482535847],
            [-70.3962118549532, -14.974523607536108],
            [-70.39269572994962, -14.971956607533484],
            [-70.39159110494849, -14.971318732532827],
            [-70.38583472994243, -14.973512357534485],
            [-70.3860058549426, -14.97382348253477],
            [-70.38995872994658, -14.977648607538594],
            [-70.39154347994817, -14.979811357540699],
            [-70.39027047994678, -14.981726857542396],
            [-70.38561647994186, -14.983297232543539],
            [-70.3832154799394, -14.982955857543047],
            [-70.37562547993141, -14.985083732544505],
            [-70.37819722993397, -14.987826107547225],
            [-70.37921010493503, -14.988576982547993],
            [-70.3779014799335, -14.992491607551536],
            [-70.37449897993002, -14.991046357549955],
            [-70.37328135492876, -14.99071635754957],
            [-70.3743054799296, -14.99695223255541],
            [-70.38342022993918, -14.994938107554177],
            [-70.38378435493952, -14.995962232555152],
            [-70.38415985493991, -14.9967473575559],
            [-70.37373660492878, -15.003074107561044],
            [-70.3705389799255, -15.001810982559661],
            [-70.36776247992276, -14.996918107554931],
            [-70.36487222991978, -14.996440107554285],
            [-70.36392772991879, -14.996679107554447],
            [-70.36283160491723, -15.007904857564775],
            [-70.36515635491955, -15.011100357567889],
            [-70.36540260491978, -15.011937732568684],
            [-70.36523847991957, -15.013595982570207],
            [-70.36453247991875, -15.015172107571617],
            [-70.36284135491687, -15.018718482574794],
            [-70.36095322991478, -15.021345482577098],
            [-70.35968910491344, -15.022215607577804],
            [-70.35676660491033, -15.023726107579005],
            [-70.34891497990193, -15.029164732583485],
            [-70.3479487299009, -15.029578857583799],
            [-70.34507297989781, -15.03197148258581],
            [-70.34520347989793, -15.032456857586274],
            [-70.35284847990589, -15.033684357587962],
            [-70.35483647990797, -15.033364107587806],
            [-70.35611735490932, -15.033284107587816],
            [-70.35898585491238, -15.031723107586577],
            [-70.36165435491516, -15.03137610758643],
            [-70.35402260490687, -15.040141857594021],
            [-70.35423610490695, -15.04430460759789],
            [-70.35693122990969, -15.045945732599609],
            [-70.36015997991305, -15.047293232601083],
            [-70.36060022991349, -15.048187107601949],
            [-70.3585989799112, -15.052456607605759],
            [-70.35671772990918, -15.05417773260723],
            [-70.35494322990724, -15.056886232609607],
            [-70.35482310490708, -15.058113607610732],
            [-70.35488972990703, -15.060595232613034],
            [-70.35227485490417, -15.064891357616832],
            [-70.34920610490076, -15.069320982620711],
            [-70.34753847989897, -15.070615107621792],
            [-70.34476322989593, -15.074591107625281],
            [-70.34599072989714, -15.076765857627388],
            [-70.34797872989904, -15.08186248263226],
            [-70.3454303548963, -15.08299660763311],
            [-70.3466978548976, -15.08390385763405],
            [-70.34936622990041, -15.083476857633839],
            [-70.3516402299025, -15.091914232641825],
            [-70.35220447990302, -15.094028107643823],
            [-70.35681347990752, -15.10338060765282],
            [-70.35767760490836, -15.105128232654504],
            [-70.35987660491037, -15.113325982662237],
            [-70.36015585491062, -15.114442982663302],
            [-70.36008085491045, -15.117074357665732],
            [-70.35564485490573, -15.118180607666451],
            [-70.35098347990095, -15.115549232663668],
            [-70.34813347989777, -15.120922857668447],
            [-70.35536785490537, -15.120789107668852],
            [-70.35643397990648, -15.12100823266913],
            [-70.35799660490808, -15.12170923266989],
            [-70.3590919799092, -15.122935982671104],
            [-70.35758772990735, -15.130384232677894],
            [-70.34901497989826, -15.132823232679531],
            [-70.34775897989687, -15.134809482681291],
            [-70.34721860489618, -15.137759482683993],
            [-70.34739385489634, -15.138460482684643],
            [-70.35971997990906, -15.144988732691582],
            [-70.35804047990727, -15.145704357692125],
            [-70.35378972990277, -15.146453732692503],
            [-70.35179172990065, -15.146536232692437],
            [-70.35035510489911, -15.147807732693519],
            [-70.3490506048975, -15.154610982699722],
            [-70.34913310489758, -15.155403607700467],
            [-70.34994222989833, -15.156856732701872],
            [-70.35050372989892, -15.15755023270256],
            [-70.35420260490275, -15.158821732703993],
            [-70.35710885490573, -15.160951982706182],
            [-70.35691060490548, -15.162553607707661],
            [-70.35509422990349, -15.164601232709424],
            [-70.3163301048626, -15.166136732707997],
            [-70.28672460483135, -15.167097607706614],
            [-70.27879097982296, -15.167248107706115],
            [-70.23333610477361, -15.199267607732056],
            [-70.22757222976705, -15.209656232741175],
            [-70.22619535476491, -15.224701232754983],
            [-70.22677935476545, -15.226320357756546],
            [-70.22613347976437, -15.235575107765028],
            [-70.227386729765, -15.251970732780332],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 7,
        FIRST_IDPR: '1010',
        NOMBPROV: 'LAURICOCHA',
        FIRST_NOMB: 'HUANUCO',
        LAST_DCTO: 'LEY',
        LAST_LEY: '7665',
        FIRST_FECH: '12/05/1962',
        LAST_FECHA: '27/12/1932',
        MIN_SHAPE_: 192235.85178,
        ha: 192235.85,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.52091986079121, -10.194937478127184],
            [-76.5128944857837, -10.291843728217437],
            [-76.49397611076607, -10.310531228234836],
            [-76.4728043607463, -10.337767603260215],
            [-76.49094111076322, -10.378896478298527],
            [-76.64719686090912, -10.415138728332268],
            [-76.69681998595549, -10.442486353357731],
            [-76.72505436098186, -10.485714728397976],
            [-76.77004098602389, -10.464798853378499],
            [-76.79989648605182, -10.419126978335964],
            [-76.85639448610468, -10.377265603296964],
            [-76.90624611115133, -10.274185603200953],
            [-76.91546486115996, -10.119914103057296],
            [-76.80842186105978, -10.08484572802465],
            [-76.82404686107441, -10.010998102955881],
            [-76.77320511102688, -9.99406247794011],
            [-76.77990823603312, -9.957017602905616],
            [-76.73594923599205, -9.93392772788408],
            [-76.7200604859772, -9.898357477850977],
            [-76.61155073587582, -9.926880852877542],
            [-76.57047461083748, -9.959232477907658],
            [-76.5782343608447, -10.030456977973998],
            [-76.60613473587074, -10.070240228011059],
            [-76.5957869858611, -10.10193560304058],
            [-76.54678511081535, -10.111697228049666],
            [-76.52091986079121, -10.194937478127184],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 6,
        FIRST_IDPR: '1006',
        NOMBPROV: 'LEONCIO PRADO',
        FIRST_NOMB: 'HUANUCO',
        LAST_DCTO: 'LEY',
        LAST_LEY: '14777',
        FIRST_FECH: '27/05/1952',
        LAST_FECHA: '26/12/1963',
        MIN_SHAPE_: 491717.69504,
        ha: 491717.7,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.62157223495299, -9.396734352383815],
            [-75.63363085996423, -9.450165977433597],
            [-75.66874798499691, -9.509593727488928],
            [-75.67758986000516, -9.560060602535934],
            [-75.71388286003894, -9.612500102584763],
            [-75.72125198504582, -9.617507852589421],
            [-75.73836523506176, -9.60816010258074],
            [-75.79072661011053, -9.466005852448363],
            [-75.78568361010583, -9.4050878523916],
            [-75.8111718601296, -9.391265602378722],
            [-75.85313473516867, -9.426382852411423],
            [-75.89240236020525, -9.43315235241775],
            [-75.94210736025154, -9.487933602468752],
            [-76.01136323531607, -9.50803710248749],
            [-76.07173436037235, -9.47178710245373],
            [-76.15993548545455, -9.46944335245155],
            [-76.20340036049504, -9.499158227479196],
            [-76.20403511049565, -9.446710977430367],
            [-76.23534573552485, -9.418208977403825],
            [-76.2277011105177, -9.38002935236827],
            [-76.1409979854369, -9.303302602296812],
            [-76.08320898538304, -9.304480477297924],
            [-76.05679298535843, -9.281722602276727],
            [-76.09866011039742, -9.211749977211568],
            [-76.21399798550495, -9.2044003522047],
            [-76.24416411053303, -9.146988227151251],
            [-76.27210548555911, -9.213951227213599],
            [-76.3104863605949, -9.176945352179136],
            [-76.35837498563957, -9.197154352197943],
            [-76.41310548569061, -9.177386727179542],
            [-76.48022261075323, -9.172076227174598],
            [-76.47336911074684, -9.01223435202574],
            [-76.41923436069634, -8.98640235200168],
            [-76.36546486064618, -9.026324227038863],
            [-76.24054311052967, -8.971101601987428],
            [-76.22123436051167, -8.925679726945122],
            [-76.2266172355167, -8.863835976887534],
            [-76.20539061049692, -8.833861351859623],
            [-76.22356048551384, -8.791425726820098],
            [-76.16643948546063, -8.756414101787476],
            [-76.13125586042781, -8.65678710169472],
            [-76.11592573541351, -8.657574226695445],
            [-76.13044123542706, -8.467211101518147],
            [-76.10916598540722, -8.379613226436579],
            [-76.08717573538672, -8.336906226396806],
            [-75.98496286029147, -8.322150351383064],
            [-75.94598436025517, -8.400874976456373],
            [-75.96490623527279, -8.451742226503761],
            [-75.9663144852741, -8.592027351634398],
            [-75.97975973528662, -8.636001976675361],
            [-75.94575011025493, -8.716138726749973],
            [-75.9216464852325, -8.780808601810225],
            [-75.91729686022845, -8.879238226901876],
            [-75.87435161018844, -8.970253851986643],
            [-75.87586136018986, -9.021458977034323],
            [-75.84439061016053, -9.0515058520623],
            [-75.80646486012519, -9.121486352127489],
            [-75.7941132351137, -9.188365227189772],
            [-75.73175786005561, -9.27226560226792],
            [-75.67083785999887, -9.396644477383749],
            [-75.62157223495299, -9.396734352383815],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 43,
        FIRST_IDPR: '1501',
        NOMBPROV: 'LIMA',
        FIRST_NOMB: 'LIMA',
        LAST_DCTO: 'LEY',
        LAST_LEY: 'S/N',
        FIRST_FECH: '22/01/1943',
        LAST_FECHA: '29/10/1874',
        MIN_SHAPE_: 269974.00005,
        ha: 269974,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.18709761141463, -11.828361354648262],
            [-77.16816598639683, -11.73910935456515],
            [-77.19352348642064, -11.678322229508533],
            [-77.12388873635527, -11.655471104487281],
            [-77.05181061128766, -11.576134354413398],
            [-77.00489061124371, -11.613472604448216],
            [-77.03498761127192, -11.652399854484441],
            [-76.97608186121673, -11.685384729515196],
            [-76.9803358612207, -11.724424479551525],
            [-76.93268448617607, -11.755304479580307],
            [-76.89691661114259, -11.729377604556172],
            [-76.80486761105647, -11.755873854580864],
            [-76.85123773609985, -11.798149729620214],
            [-76.90660761115167, -11.79612397961831],
            [-76.94227536118507, -11.860867229678595],
            [-76.91210748615681, -11.88927472970508],
            [-76.91655198616095, -11.95983710477078],
            [-76.79167998604414, -11.927609979740788],
            [-76.72793998598455, -11.875709979692452],
            [-76.64696086090886, -11.952875604764317],
            [-76.70707811096506, -12.02949610483568],
            [-76.67807998593794, -12.052979854857552],
            [-76.71615898597352, -12.098418729899873],
            [-76.64862798591045, -12.208160230002058],
            [-76.62109961088473, -12.226883105019494],
            [-76.63678886089939, -12.279363980068359],
            [-76.67398998593413, -12.319519980105756],
            [-76.66799223592851, -12.36023048014369],
            [-76.68341148594291, -12.382109730164053],
            [-76.75548361101029, -12.398213355179044],
            [-76.75168161100673, -12.462689480239103],
            [-76.78416423603711, -12.51993160529239],
            [-76.80248048605424, -12.504322230277847],
            [-76.77504098602854, -12.437871105215958],
            [-76.77736136103074, -12.394062480175188],
            [-76.83602736108561, -12.3174121051038],
            [-76.93546873617869, -12.250220730041208],
            [-77.03770898627447, -12.204083979998186],
            [-77.0310273612682, -12.134027229932977],
            [-77.1115332363437, -12.079492229882156],
            [-77.08294048631687, -12.036417854842059],
            [-77.11754961134935, -11.956969604768062],
            [-77.0899043613234, -11.901786729716678],
            [-77.1260484863573, -11.820788104641217],
            [-77.18709761141463, -11.828361354648262],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 5,
        FIRST_IDPR: '1603',
        NOMBPROV: 'LORETO',
        FIRST_NOMB: 'LORETO',
        LAST_DCTO: 'LEY',
        LAST_LEY: '9815',
        FIRST_FECH: '07/02/1866',
        LAST_FECHA: '02/07/1943',
        MIN_SHAPE_: 6854883.44542,
        ha: 6854883.45,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.51447998485321, -5.739259973977536],
            [-75.51278710985166, -5.6784667239209],
            [-75.4547519847976, -5.573701223823329],
            [-75.49732223483724, -5.588177723836802],
            [-75.57234960990715, -5.572287098822007],
            [-75.64013423497028, -5.496783848751689],
            [-75.61679873494853, -5.462345723719612],
            [-75.63333423496394, -5.410136223670977],
            [-75.56966310990462, -5.300831723569197],
            [-75.56015810989577, -5.267109348537792],
            [-75.6180272349497, -5.202722598477811],
            [-75.59948235993241, -5.16702934844457],
            [-75.51004485984909, -5.1122930983936],
            [-75.41660935976209, -4.987201098277091],
            [-75.5286093598664, -4.93751947323082],
            [-75.664541109993, -4.933185848226792],
            [-75.72233436004684, -4.871654723169476],
            [-75.737573485061, -4.825937598126899],
            [-75.74308198506615, -4.734873098042089],
            [-75.85139261016703, -4.658876972971304],
            [-75.8816254851952, -4.617766597933035],
            [-75.89053886020353, -4.556810472876259],
            [-75.86261798517751, -4.520229722842191],
            [-75.90108148521334, -4.457598847783846],
            [-75.95586661026437, -4.42507197275357],
            [-75.97630648528342, -4.331656597666558],
            [-76.02307223532699, -4.218744097561393],
            [-76.05806636035962, -4.156654222503569],
            [-76.14975723544508, -4.06320447241652],
            [-76.17262423546639, -3.95543672231616],
            [-76.20315036049483, -3.924626972287452],
            [-76.20639511049788, -3.844648972212966],
            [-76.24734523553605, -3.785567222157947],
            [-76.24528636053412, -3.751056972125797],
            [-76.28970223557552, -3.691222972070084],
            [-76.23408198552369, -3.634831972017539],
            [-76.25936623554725, -3.575607346962395],
            [-76.20617973549767, -3.528056596918114],
            [-76.2614628605492, -3.475218721868892],
            [-76.2700267355572, -3.361134096762633],
            [-76.3672909856479, -3.217830096629161],
            [-76.3520533606337, -3.16238747157754],
            [-76.3892656106684, -3.127677721545202],
            [-76.35347073563501, -3.090419971510504],
            [-76.33792823562052, -2.988613596415691],
            [-76.35123111063294, -2.946427596376409],
            [-76.31686523560086, -2.897560596330903],
            [-76.30734236059199, -2.834851471272489],
            [-76.34420686062637, -2.800070346240091],
            [-76.36134023564233, -2.745897846189617],
            [-76.47064298574433, -2.604314846057765],
            [-76.49925436077102, -2.592622846046877],
            [-76.50242411077399, -2.536480470994591],
            [-76.53432436080377, -2.512278470972044],
            [-76.0449894853474, -2.128043220614178],
            [-75.77836486009902, -1.793951345303025],
            [-75.77707811009782, -1.856474595361264],
            [-75.74592386006879, -1.930923845430606],
            [-75.57483598490946, -1.963455095460868],
            [-75.53628910987355, -1.996460970491612],
            [-75.47254098481416, -2.00385547049852],
            [-75.44490035978843, -2.034136720526721],
            [-75.33755473468845, -2.003201220497905],
            [-75.28814923464243, -2.008710720503033],
            [-75.24325235960065, -2.103102095590961],
            [-75.24618660960336, -2.133828595619575],
            [-75.16607623452875, -2.191746095673522],
            [-75.1408843595053, -2.27892634575471],
            [-75.0832284844516, -2.293470720768257],
            [-75.01715035939004, -2.351790970822582],
            [-74.96514848434164, -2.365650345835471],
            [-74.94959185932713, -2.458790970922234],
            [-74.95681048433387, -2.504294970964609],
            [-74.99119310936588, -2.516005220975509],
            [-74.95746285933446, -2.611460971064412],
            [-74.97374810934964, -2.660699221110282],
            [-75.00992660938331, -2.684673721132612],
            [-75.01026948438364, -2.766357471208694],
            [-75.05463548442495, -2.940187596370577],
            [-75.0142886093874, -3.074875721496034],
            [-75.01780660939066, -3.12898047154642],
            [-74.99161148436626, -3.190005471603249],
            [-74.89290048427434, -3.209853471621747],
            [-74.81337123420025, -3.243214846652814],
            [-74.8187792342053, -3.296851596702756],
            [-74.75394498414492, -3.334533221737858],
            [-74.7125662341064, -3.43607422183242],
            [-74.67947298407556, -3.465786471860103],
            [-74.67923073407535, -3.5093687219007],
            [-74.55454298395921, -3.633314472016151],
            [-74.50143360890975, -3.698855347077182],
            [-74.36743973378495, -3.718679847095652],
            [-74.28113385870458, -3.829804222199139],
            [-74.21913598364681, -3.853998097221659],
            [-74.19221735862175, -3.882459847248168],
            [-74.1479144835805, -3.989321972347702],
            [-74.13543748356885, -4.061880847415293],
            [-74.10170685853745, -4.177025347522552],
            [-74.05870123349736, -4.247613222588289],
            [-73.94525973339165, -4.324501972659879],
            [-73.93700198338396, -4.371898472704035],
            [-73.88351760833412, -4.388433597719438],
            [-73.83358148328757, -4.43862222276617],
            [-73.74708910820694, -4.449058222775881],
            [-73.69192385815549, -4.439775347767245],
            [-73.59267673306293, -4.381820597713282],
            [-73.54922060802237, -4.341326597675574],
            [-73.48814310796539, -4.323648597659084],
            [-73.4682128579468, -4.284902347622998],
            [-73.40029298288336, -4.238546847579833],
            [-73.380619357865, -4.25999547259979],
            [-73.44280460792305, -4.306345972642966],
            [-73.4205614829023, -4.424193347752728],
            [-73.43521773291597, -4.446356222773385],
            [-73.46483148294361, -4.515207972837501],
            [-73.5294099830039, -4.584300722901852],
            [-73.58198248305295, -4.618681597933878],
            [-73.64423348311102, -4.714238348022886],
            [-73.71154798317382, -4.735417973042609],
            [-73.84383998329713, -4.871409973169238],
            [-73.87435160832557, -4.88280859817988],
            [-73.89300098334294, -4.955575473247644],
            [-73.93324898338048, -5.01075497329904],
            [-73.9544161084002, -4.981158723271474],
            [-74.03713523347727, -4.946627098239302],
            [-74.08938785852595, -4.969160973260302],
            [-74.13116798356488, -5.015031223303032],
            [-74.17044923360146, -5.032695348319469],
            [-74.17578123360644, -5.079804723363347],
            [-74.24551360867137, -5.136191348415847],
            [-74.3506279837693, -5.153484598431955],
            [-74.41833785883236, -5.206929723481731],
            [-74.57292198397634, -5.264320098535202],
            [-74.61517023401568, -5.304329848572455],
            [-74.63801435903697, -5.354012848618726],
            [-74.82899410921483, -5.45370897371158],
            [-74.9020898592829, -5.470304348727024],
            [-74.96009760933693, -5.519859348773189],
            [-75.01946610939221, -5.496929598751835],
            [-75.06598760943554, -5.499397598754127],
            [-75.1815799845432, -5.623229973869475],
            [-75.20809998456788, -5.617099973863746],
            [-75.30493385965808, -5.686214973928125],
            [-75.38903385973641, -5.691250223932817],
            [-75.4371343597812, -5.725416723964624],
            [-75.51447998485321, -5.739259973977536],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 21,
        FIRST_IDPR: '0506',
        NOMBPROV: 'LUCANAS',
        FIRST_NOMB: 'AYACUCHO',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: 'EPOCA INDEP.',
        LAST_FECHA: 'EPOCA INDEP.',
        MIN_SHAPE_: 1446494.2235,
        ha: 1446494.22,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.58174998305279, -14.416134732058289],
            [-73.53246223300683, -14.507242232143122],
            [-73.53396223300821, -14.507218607143118],
            [-73.53655535801063, -14.508137107143979],
            [-73.54309235801674, -14.50889348214466],
            [-73.55092598302404, -14.514728232150103],
            [-73.55222260802525, -14.516727107151958],
            [-73.55540823302822, -14.518002232153155],
            [-73.55859335803119, -14.521774107156663],
            [-73.56022785803273, -14.522360857157208],
            [-73.56094035803338, -14.5224446071573],
            [-73.56304098303534, -14.520841857155808],
            [-73.56446085803665, -14.520893982155858],
            [-73.56793948303991, -14.526048982160637],
            [-73.57655085804797, -14.537489232171325],
            [-73.57766398304898, -14.538864357172576],
            [-73.58545673305626, -14.547115482180272],
            [-73.59148135806187, -14.548294232181364],
            [-73.59678573306684, -14.543972232177351],
            [-73.6005183580703, -14.541942232175456],
            [-73.60211785807181, -14.541729607175254],
            [-73.60654998307594, -14.543042857176463],
            [-73.60740473307673, -14.543819357177208],
            [-73.60756048307688, -14.544843482178162],
            [-73.60756048307688, -14.545555857178819],
            [-73.60907435807827, -14.548605857181656],
            [-73.611968483081, -14.552234732185047],
            [-73.61486260808368, -14.55735510718981],
            [-73.61902935808757, -14.566047982197897],
            [-73.62378823309201, -14.572710482204096],
            [-73.6346938581022, -14.590901732221035],
            [-73.63770160810499, -14.593007107223007],
            [-73.6774883581421, -14.622623982250593],
            [-73.69233785815592, -14.629839857257313],
            [-73.69850585816168, -14.631130857258515],
            [-73.69892573316208, -14.631609232258944],
            [-73.69976560816288, -14.63323048226047],
            [-73.70294723316583, -14.634402357261571],
            [-73.70898048317147, -14.635210982262308],
            [-73.70958010817202, -14.635751982262809],
            [-73.71276923317498, -14.637735232264655],
            [-73.71444735817656, -14.640077232266842],
            [-73.72078910818247, -14.64244535726906],
            [-73.72268773318423, -14.640456607267206],
            [-73.72445123318589, -14.638482482265351],
            [-73.72511135818651, -14.63815235726506],
            [-73.73734185819791, -14.638062482264962],
            [-73.74838748320818, -14.635101607262225],
            [-73.74873260820853, -14.636562482263576],
            [-73.7473203582072, -14.640283232267029],
            [-73.74957223320929, -14.638033232264958],
            [-73.75293360821244, -14.63656248226358],
            [-73.75480860821418, -14.639292982266122],
            [-73.75408985821349, -14.641333982268023],
            [-73.75480860821418, -14.64256448226917],
            [-73.75616010821544, -14.644185607270662],
            [-73.75643360821569, -14.644585982271037],
            [-73.75541010821473, -14.647517607273764],
            [-73.76097660821995, -14.646677732272998],
            [-73.77433198323237, -14.643374982269906],
            [-73.77524810823323, -14.642865232269436],
            [-73.78158010823914, -14.638363232265254],
            [-73.78326160824072, -14.640193357266963],
            [-73.78368160824111, -14.639953107266741],
            [-73.78491373324223, -14.639864607266649],
            [-73.7904369832474, -14.642790482269373],
            [-73.79179735824867, -14.64440148227088],
            [-73.79684523325336, -14.64533223227174],
            [-73.80518635826115, -14.642718982269301],
            [-73.80651085826238, -14.641465982268144],
            [-73.81113060826667, -14.638490357265383],
            [-73.821132233276, -14.64534510727175],
            [-73.82298748327774, -14.674185982298617],
            [-73.82141335827627, -14.678402482302557],
            [-73.82220035827702, -14.679189482303261],
            [-73.82782235828225, -14.684755357308472],
            [-73.83023985828449, -14.688072357311542],
            [-73.83611710828997, -14.704694357327023],
            [-73.8363689832902, -14.707069107329245],
            [-73.83611710828997, -14.713797482335515],
            [-73.8358292332897, -14.715668482337241],
            [-73.82561073328019, -14.725563232346452],
            [-73.82571860828027, -14.725922982346793],
            [-73.83115173328535, -14.732039732352494],
            [-73.83895960829264, -14.740567232360434],
            [-73.85077785830364, -14.7549294823738],
            [-73.85544710830797, -14.756627357375386],
            [-73.85478010830735, -14.762570107380933],
            [-73.85544710830797, -14.764207357382439],
            [-73.85829735831065, -14.766329857384422],
            [-73.86066223331285, -14.767421232385436],
            [-73.8705723583221, -14.767147107385187],
            [-73.86982623332138, -14.774288107391829],
            [-73.86975060832133, -14.775270732392746],
            [-73.87151423332297, -14.776857982394223],
            [-73.87141348332285, -14.789153232405678],
            [-73.87023048332178, -14.791165982407549],
            [-73.86897848332059, -14.793040982409304],
            [-73.87070898332222, -14.795388732411483],
            [-73.87112698332261, -14.795554732411642],
            [-73.87147460832291, -14.796791107412787],
            [-73.87095510832243, -14.798123107414026],
            [-73.87111723332258, -14.800396482416152],
            [-73.87226760832367, -14.80158010741725],
            [-73.87522260832641, -14.80253323241814],
            [-73.87809760832911, -14.803328107418883],
            [-73.8784062333294, -14.803554732419089],
            [-73.87932035833023, -14.803904357419412],
            [-73.88175198333252, -14.803812482419335],
            [-73.88521685833572, -14.804798857420252],
            [-73.88966410833987, -14.807613232422868],
            [-73.89003123334021, -14.808246107423459],
            [-73.89093948334107, -14.810689482425728],
            [-73.89354885834351, -14.810863232425897],
            [-73.89379685834373, -14.811718732426698],
            [-73.89489060834475, -14.81702735743164],
            [-73.8947461083446, -14.819388732433834],
            [-73.89459185834446, -14.820158232434549],
            [-73.89480660834465, -14.821724482436009],
            [-73.89476948334462, -14.822160107436419],
            [-73.89514448334498, -14.824880857438961],
            [-73.89568748334548, -14.826167982440154],
            [-73.89539848334522, -14.828466857442297],
            [-73.89733985834704, -14.832005857445573],
            [-73.89752348334719, -14.833343732446833],
            [-73.89875973334836, -14.837113232450347],
            [-73.89813085834777, -14.839890607452936],
            [-73.90014260834964, -14.842482482455331],
            [-73.8987929833484, -14.84751760746003],
            [-73.89646873334621, -14.858849607470589],
            [-73.89511723334496, -14.860546857472167],
            [-73.89074598334088, -14.864365232475713],
            [-73.8854121083359, -14.869228482480242],
            [-73.88384373333446, -14.871687482482535],
            [-73.88251948333323, -14.87313085748388],
            [-73.8795176083304, -14.880994107491201],
            [-73.87955273333046, -14.881609357491783],
            [-73.87917398333009, -14.883165982493237],
            [-73.87986523333075, -14.89047460750003],
            [-73.87661523332773, -14.894884732504146],
            [-73.86895898332058, -14.896306607505464],
            [-73.86376760831574, -14.90129885751012],
            [-73.86310935831513, -14.901544982510337],
            [-73.85804498331042, -14.903101607511797],
            [-73.85722848330964, -14.904374982512984],
            [-73.85806235831042, -14.908281232516616],
            [-73.8580253583104, -14.90920898251747],
            [-73.85291360830561, -14.91676498252452],
            [-73.84349098329685, -14.920533857528023],
            [-73.84176273329524, -14.932683232539338],
            [-73.8409671082945, -14.936390482542798],
            [-73.84185435829534, -14.938578982544827],
            [-73.84136523329488, -14.939572232545764],
            [-73.84090610829443, -14.941292982547361],
            [-73.84024023329383, -14.94776560755338],
            [-73.83794535829168, -14.953302732558539],
            [-73.83558198328947, -14.958894482563757],
            [-73.83220310828632, -14.96025973256503],
            [-73.83058598328482, -14.961343732566025],
            [-73.83013085828439, -14.962748107567341],
            [-73.83001173328428, -14.964126982568622],
            [-73.82820698328258, -14.965685732570064],
            [-73.82661135828111, -14.96656248257089],
            [-73.8259609832805, -14.966558607570894],
            [-73.82327148327799, -14.96664060757097],
            [-73.82110548327599, -14.968052732572273],
            [-73.8193944832744, -14.971996107575947],
            [-73.81910748327412, -14.972304732576236],
            [-73.8163066082715, -14.975677732579369],
            [-73.81342773326881, -14.977210982580804],
            [-73.81321885826863, -14.978884732582355],
            [-73.81420898326954, -14.980453107583827],
            [-73.81612110827132, -14.981208982584539],
            [-73.81707623327223, -14.985906232588905],
            [-73.8177637332729, -14.98787110759073],
            [-73.81521873327048, -14.99512885759749],
            [-73.81389060826925, -14.996447107598726],
            [-73.81396285826932, -14.996980607599216],
            [-73.81258985826804, -15.004142732605885],
            [-73.81251173326798, -15.005218732606895],
            [-73.81357223326897, -15.010427732611742],
            [-73.80917385826488, -15.017873107618666],
            [-73.80855273326429, -15.023367232623798],
            [-73.80827348326403, -15.024453232624802],
            [-73.80809373326387, -15.02608198262632],
            [-73.80799810826377, -15.027287107627442],
            [-73.80923248326492, -15.030644482630562],
            [-73.80953710826522, -15.035662107635243],
            [-73.80796873326375, -15.038529357637909],
            [-73.80775585826353, -15.038767607638134],
            [-73.80721873326303, -15.0395898576389],
            [-73.80588085826179, -15.041117232640316],
            [-73.80091410825716, -15.04651173264534],
            [-73.79992973325625, -15.049308607647946],
            [-73.79907623325543, -15.05225585765069],
            [-73.79837885825481, -15.055167982653396],
            [-73.79832810825476, -15.056119107654293],
            [-73.79845898325486, -15.056656107654783],
            [-73.798609358255, -15.060701232658554],
            [-73.79869535825507, -15.061027357658855],
            [-73.79875585825516, -15.062439482660173],
            [-73.79758985825406, -15.063621107661277],
            [-73.79562885825223, -15.067452982664838],
            [-73.79562310825223, -15.068040857665393],
            [-73.79577148325237, -15.069091857666379],
            [-73.7956972332523, -15.069339857666595],
            [-73.79550198325212, -15.07092773266808],
            [-73.79543948325205, -15.071285107668416],
            [-73.79556635825217, -15.072652357669693],
            [-73.79700973325353, -15.075132857672],
            [-73.79847848325487, -15.080113232676638],
            [-73.7984981082549, -15.080925732677395],
            [-73.80041810825671, -15.082982482679313],
            [-73.81149585826702, -15.08703123268308],
            [-73.81327935826869, -15.08739648268341],
            [-73.81712098327226, -15.09033198268615],
            [-73.8191034832741, -15.09159373268733],
            [-73.81987698327485, -15.092443357688124],
            [-73.82003335827498, -15.092831982688475],
            [-73.82611723328064, -15.101310732696371],
            [-73.82656248328108, -15.102824232697781],
            [-73.82726560828173, -15.103540982698453],
            [-73.83054885828479, -15.104810607699642],
            [-73.830804733285, -15.10544335770022],
            [-73.83074023328498, -15.10678710770147],
            [-73.83017385828443, -15.109435482703942],
            [-73.83029298328454, -15.109996107704474],
            [-73.83083598328504, -15.113277357707526],
            [-73.8323964832865, -15.117943357711857],
            [-73.83221285828633, -15.119919982713704],
            [-73.83266410828675, -15.121277357714968],
            [-73.832915858287, -15.121847607715505],
            [-73.83433598328831, -15.124861357718306],
            [-73.8347246082887, -15.12502148271846],
            [-73.83530073328922, -15.124949232718395],
            [-73.8364628582903, -15.126076232719436],
            [-73.83674998329056, -15.127781232721029],
            [-73.84070898329426, -15.13305073272594],
            [-73.84629498329946, -15.132761732725667],
            [-73.84672660829986, -15.132445232725367],
            [-73.8489277333019, -15.132470732725404],
            [-73.85986910831211, -15.132775357725693],
            [-73.86480860831672, -15.132599607725517],
            [-73.86560160831745, -15.132951232725835],
            [-73.86832035831999, -15.134765607727541],
            [-73.87028710832182, -15.13496098272771],
            [-73.87169735832312, -15.134776982727535],
            [-73.872617233324, -15.134570357727352],
            [-73.87282623332418, -15.134472607727254],
            [-73.87474410832598, -15.132874982725776],
            [-73.87747260832853, -15.13102935772405],
            [-73.8814357333322, -15.12918698272233],
            [-73.88183198333256, -15.12889648272206],
            [-73.88379098333439, -15.127806607721048],
            [-73.88590035833636, -15.12772460772097],
            [-73.8878554833382, -15.12802935772125],
            [-73.88834760833865, -15.128054732721292],
            [-73.89370710834365, -15.129131857722282],
            [-73.89780860834748, -15.129373107722513],
            [-73.8987288583483, -15.129333357722468],
            [-73.90029298334979, -15.129199232722344],
            [-73.9014999833509, -15.128986357722155],
            [-73.90400585835324, -15.128543107721738],
            [-73.904831983354, -15.128554732721746],
            [-73.9075156083565, -15.129281232722427],
            [-73.91221098336086, -15.130265607723336],
            [-73.9150214833635, -15.131333982724337],
            [-73.9170586083654, -15.131373107724382],
            [-73.92213285837013, -15.131140607724161],
            [-73.92753123337515, -15.132705107725622],
            [-73.93520698338232, -15.13585748272855],
            [-73.9409843583877, -15.137185607729782],
            [-73.94266798338927, -15.137683482730246],
            [-73.94378123339028, -15.138093732730631],
            [-73.95861323340412, -15.145607482737628],
            [-73.96373048340888, -15.144923857736986],
            [-73.96823635841311, -15.146050732738038],
            [-73.97010160841481, -15.147849607739722],
            [-73.98473635842848, -15.155218732746583],
            [-73.9967949834397, -15.16097660775194],
            [-73.99824610844105, -15.160894482751866],
            [-73.9984159834412, -15.16094535775192],
            [-74.0011016084437, -15.166839732757403],
            [-74.0048476084472, -15.170667982760973],
            [-74.00644135844867, -15.171728482761953],
            [-74.01152348345343, -15.174581982764616],
            [-74.01291798345471, -15.174626982764652],
            [-74.01692385845845, -15.178558607768315],
            [-74.01797660845945, -15.179632857769315],
            [-74.02027748346157, -15.180779357770382],
            [-74.02067385846195, -15.181376857770928],
            [-74.02100198346226, -15.182343732771846],
            [-74.02226173346345, -15.185935607775184],
            [-74.02677935846762, -15.19110160778],
            [-74.0277246084685, -15.19224223278106],
            [-74.02936323347004, -15.192607482781403],
            [-74.02956835847024, -15.192845732781624],
            [-74.03013085847077, -15.194130857782811],
            [-74.03297848347343, -15.195695357784285],
            [-74.03353310847393, -15.19590623278447],
            [-74.03434485847467, -15.19611398278468],
            [-74.03543160847568, -15.19657423278509],
            [-74.03683198347699, -15.1971249827856],
            [-74.03880473347883, -15.198078107786493],
            [-74.04137885848125, -15.200062482788347],
            [-74.04482035848443, -15.206201232794067],
            [-74.0457617334853, -15.206763857794584],
            [-74.04710760848657, -15.209119107796775],
            [-74.04824810848764, -15.21011135779771],
            [-74.049699233489, -15.21126560779877],
            [-74.05278323349187, -15.212982482800372],
            [-74.0549766084939, -15.215449232802673],
            [-74.07185160850962, -15.22801560781438],
            [-74.0720391085098, -15.228310607814654],
            [-74.073335983511, -15.229060607815345],
            [-74.07432423351192, -15.229380857815645],
            [-74.07555473351307, -15.23031635781652],
            [-74.07745123351485, -15.23097673281713],
            [-74.0836308585206, -15.232943357818955],
            [-74.08916598352575, -15.234876982820769],
            [-74.09061135852708, -15.235236357821098],
            [-74.09129498352775, -15.235144482821019],
            [-74.10524223354074, -15.237046857822778],
            [-74.10627148354169, -15.238181607823831],
            [-74.10730473354266, -15.23923248282482],
            [-74.12602935856009, -15.235681482821507],
            [-74.12828510856218, -15.234374982820297],
            [-74.13339448356696, -15.234447232820354],
            [-74.13446098356795, -15.234365232820284],
            [-74.13570123356911, -15.234283232820214],
            [-74.13738085857068, -15.233790982819762],
            [-74.13851560857174, -15.232669982818715],
            [-74.13931635857247, -15.232335982818405],
            [-74.14023248357333, -15.232331982818392],
            [-74.1433106085762, -15.230412107816612],
            [-74.14547073357821, -15.229593732815848],
            [-74.14625385857893, -15.22926173281553],
            [-74.14944535858191, -15.228654357814971],
            [-74.15367973358586, -15.229728482815979],
            [-74.15578910858783, -15.23010348281632],
            [-74.16143160859308, -15.228037232814387],
            [-74.16853910859969, -15.224437482811044],
            [-74.17419135860497, -15.222915982809631],
            [-74.17478123360551, -15.222949232809658],
            [-74.17770698360825, -15.223078107809782],
            [-74.17997848361036, -15.223341857810015],
            [-74.18094135861124, -15.223781232810431],
            [-74.18259573361279, -15.224990357811551],
            [-74.18313460861329, -15.225281607811821],
            [-74.1861523586161, -15.228255857814588],
            [-74.18647660861642, -15.228800732815104],
            [-74.18785748361769, -15.23002935781626],
            [-74.19326185862273, -15.23513673282101],
            [-74.19362885862306, -15.23551760782137],
            [-74.19619923362545, -15.23686135782261],
            [-74.2007324836297, -15.236341857822133],
            [-74.20338873363217, -15.234996107820876],
            [-74.21136910863957, -15.234429732820349],
            [-74.21283010864094, -15.233847607819799],
            [-74.21618360864407, -15.233880857819832],
            [-74.21668160864452, -15.234128857820068],
            [-74.21940623364706, -15.235376982821231],
            [-74.22227348364974, -15.235169982821027],
            [-74.22314448365056, -15.235544982821382],
            [-74.22397410865132, -15.236793232822553],
            [-74.22259810865005, -15.243437232828745],
            [-74.22207973364955, -15.244297357829542],
            [-74.22036935864797, -15.247479607832508],
            [-74.21929535864696, -15.24915023283406],
            [-74.21838048364613, -15.252014232836721],
            [-74.21762460864541, -15.256747857841138],
            [-74.21694848364477, -15.260685857844791],
            [-74.21666998364454, -15.265101107848906],
            [-74.2137662336418, -15.269556232853068],
            [-74.21285135864098, -15.271584857854949],
            [-74.2063277336349, -15.283359107865925],
            [-74.20505485863372, -15.285387857867802],
            [-74.20525373363388, -15.286660732868993],
            [-74.20628798363485, -15.287456357869731],
            [-74.20684485863536, -15.287853982870105],
            [-74.20260248363142, -15.29916960788064],
            [-74.19473548362409, -15.309712357890467],
            [-74.19163148362121, -15.314421982894842],
            [-74.19248785862202, -15.317365482897578],
            [-74.19593198362521, -15.319792982899843],
            [-74.20131060863021, -15.322004482901898],
            [-74.20103760862996, -15.323151232902966],
            [-74.2011741086301, -15.325663107905315],
            [-74.2045051086332, -15.328147732907624],
            [-74.2043958586331, -15.328912107908334],
            [-74.20534710863397, -15.333139232912272],
            [-74.20903423363741, -15.337788232916608],
            [-74.20983573363817, -15.342063107920582],
            [-74.20914110863752, -15.344681482923018],
            [-74.21105948363929, -15.348662482926722],
            [-74.21217098364035, -15.350842732928765],
            [-74.21413735864216, -15.353621357931338],
            [-74.2175147336453, -15.356442857933986],
            [-74.22149023364902, -15.361230732938434],
            [-74.23370410866039, -15.374359857950656],
            [-74.24040573366665, -15.380148107956053],
            [-74.24183398366799, -15.382033357957802],
            [-74.242919483669, -15.384318607959926],
            [-74.24470598367066, -15.3866562329621],
            [-74.24866060867433, -15.393325357968314],
            [-74.24910523367475, -15.3941677329691],
            [-74.25637885868153, -15.40209960797649],
            [-74.25717823368225, -15.403293982977607],
            [-74.2568039836819, -15.405282982979454],
            [-74.2531174836785, -15.418710482991962],
            [-74.25114410867664, -15.421999357995027],
            [-74.24991623367549, -15.424104357996987],
            [-74.24952160867514, -15.425989982998736],
            [-74.24382073366982, -15.443355858014911],
            [-74.2428393586689, -15.450401858021472],
            [-74.24117573366735, -15.481039108050004],
            [-74.30012360872226, -15.47839460804754],
            [-74.32843135874862, -15.390993732966146],
            [-74.30971848373119, -15.358937232936293],
            [-74.4094619838241, -15.308493607889314],
            [-74.387878358804, -15.251779607836493],
            [-74.3987197338141, -15.181463232771021],
            [-74.45142135886319, -15.175972732765905],
            [-74.55252073395734, -15.139941232732351],
            [-74.60597223400713, -15.148497607740325],
            [-74.71860498411202, -15.135662982728377],
            [-74.76229860915272, -15.09805485769335],
            [-74.6642669840614, -15.04469648264366],
            [-74.66345098406063, -14.907934482516294],
            [-74.69384035908895, -14.838155232451316],
            [-74.74624410913773, -14.84355473245634],
            [-74.75600710914682, -14.771130857388902],
            [-74.8026972341903, -14.752152357371223],
            [-74.84574210923041, -14.707979482330083],
            [-74.85277248423697, -14.611809107240518],
            [-74.89541785927669, -14.545080482178394],
            [-74.95891973433582, -14.606451232235537],
            [-75.05990035942988, -14.622617607250607],
            [-75.08008998444866, -14.56900998220066],
            [-75.06909735943843, -14.526934357161505],
            [-75.09813773446548, -14.485097232122536],
            [-75.10126648446841, -14.43319910707419],
            [-75.13804898450265, -14.414655732056923],
            [-75.09392685946156, -14.31242448196173],
            [-75.0399791094113, -14.27787935692954],
            [-75.09079485945865, -14.21517773187115],
            [-75.05904998442908, -14.129619981791498],
            [-75.02912998440121, -14.071949981737779],
            [-74.9423613593204, -14.094687481758962],
            [-74.84138473422637, -14.073402356739134],
            [-74.77252935916223, -14.090318356754887],
            [-74.72572435911864, -14.117035731779758],
            [-74.69834835909315, -14.078622106743993],
            [-74.64055223403932, -14.094454731758754],
            [-74.62319535902317, -14.137142606798498],
            [-74.43127735884441, -14.149304731809798],
            [-74.39232035880812, -14.167054731826347],
            [-74.32944873374956, -14.128629356790569],
            [-74.20797598363642, -14.126899356788945],
            [-74.1744298586052, -14.104710856768289],
            [-74.11604298355078, -14.028872981697665],
            [-74.07037323350825, -14.02667148169561],
            [-74.0854606085223, -14.088808981753479],
            [-74.03916898347916, -14.098499856762501],
            [-73.9991582334419, -14.15919335681901],
            [-73.95003398339614, -14.185727106843746],
            [-73.87925460833016, -14.216695481872575],
            [-73.89658398334632, -14.262831981915538],
            [-73.79882823325521, -14.293944606944502],
            [-73.73825623319874, -14.338163856985684],
            [-73.6952889831587, -14.326589981974895],
            [-73.58174998305279, -14.416134732058289],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 23,
        FIRST_IDPR: '0105',
        NOMBPROV: 'LUYA',
        FIRST_NOMB: 'AMAZONAS',
        LAST_DCTO: 'LEY',
        LAST_LEY: 'S/N',
        FIRST_FECH: '16/08/1920',
        LAST_FECHA: '05/02/1861',
        MIN_SHAPE_: 324512.97262,
        ha: 324512.97,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.89094923707454, -6.102691349315582],
            [-77.90203123708498, -6.19934759940557],
            [-77.94201173712241, -6.268826224470213],
            [-77.91988861210179, -6.368501974563086],
            [-77.86258398704817, -6.445498099634862],
            [-77.85140823703775, -6.508980474693979],
            [-77.8022478619917, -6.65570309983068],
            [-77.85315823703948, -6.67654097485001],
            [-77.9359668621171, -6.689789099862256],
            [-78.03586886221062, -6.743240724911875],
            [-78.05819536223143, -6.676150224849358],
            [-78.10759911227748, -6.642332224817756],
            [-78.13602773730382, -6.526301974709643],
            [-78.19002948735395, -6.457016099644991],
            [-78.27793186243534, -6.38896147458141],
            [-78.33373498748678, -6.350977724545862],
            [-78.33538611248822, -6.318679474515776],
            [-78.41972811256537, -6.188424599394203],
            [-78.37306448752256, -6.169783224377005],
            [-78.36811611251788, -6.108618349320072],
            [-78.2914023624472, -6.06514559927983],
            [-78.22371748738463, -6.061550849276669],
            [-78.17241411233698, -5.994150349214023],
            [-78.13080473729819, -5.902560599128826],
            [-78.0744531122458, -5.920373099145533],
            [-78.05030611222332, -5.977173724198451],
            [-77.98208011215966, -5.96846684919046],
            [-77.89094923707454, -6.102691349315582],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 4,
        FIRST_IDPR: '1702',
        NOMBPROV: 'MANU',
        FIRST_NOMB: 'MADRE DE DIOS',
        LAST_DCTO: 'LEY',
        LAST_LEY: '1782',
        FIRST_FECH: '09/06/2000',
        LAST_FECHA: '26/12/1912',
        MIN_SHAPE_: 2881533.61325,
        ha: 2881533.61,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.90035160646909, -12.733355480489367],
            [-71.95638098152273, -12.654872605416532],
            [-71.98139610654673, -12.580490480347352],
            [-72.03145885659451, -12.524772730295647],
            [-72.07113235663235, -12.511484230283388],
            [-72.06148935662321, -12.44044935521723],
            [-71.97601323154183, -12.398782855178156],
            [-71.97303123153908, -12.320543355105288],
            [-72.0338407315971, -12.265044855053835],
            [-72.10551185666529, -12.238059855028887],
            [-72.08755860664833, -12.142690354940031],
            [-72.09444660665494, -12.085190979886534],
            [-72.17373298173024, -12.059810354863076],
            [-72.26893285682053, -12.054375354858204],
            [-72.25930310681142, -12.006302729813424],
            [-72.28455073183537, -11.97643035478566],
            [-72.33746598188544, -11.968075229777968],
            [-72.34751423189499, -11.891267479706455],
            [-72.33215410688047, -11.855527479673144],
            [-72.39130173193642, -11.826346229646072],
            [-72.37076798191703, -11.784967354607481],
            [-72.42506448196835, -11.733169229559326],
            [-72.42873873197183, -11.708971854536797],
            [-72.35094460689835, -11.621804854455542],
            [-72.31550810686487, -11.595576854431048],
            [-72.29255073184324, -11.433273104279875],
            [-72.24789448180097, -11.385005854234858],
            [-72.23925835679285, -11.345060979197648],
            [-72.20462335676005, -11.347432729199811],
            [-72.16308598172073, -11.320124979174288],
            [-72.11205923167235, -11.331073354184388],
            [-72.0968737316579, -11.377597604227672],
            [-71.99935885656537, -11.37045147922077],
            [-71.94561398151428, -11.386306979235352],
            [-71.89356473146486, -11.352866479204014],
            [-71.85387498142711, -11.36815435421812],
            [-71.82613098140081, -11.315101979168597],
            [-71.75021098132858, -11.329896479182041],
            [-71.64860548123177, -11.377734479226007],
            [-71.66469848124697, -11.419385104264913],
            [-71.59114060617692, -11.431128854275368],
            [-71.57872548116497, -11.469628729311113],
            [-71.49381035608401, -11.489047479328537],
            [-71.41572898100954, -11.528847354364885],
            [-71.33936323093626, -11.6387402294664],
            [-71.32066410591823, -11.679185604503806],
            [-71.25142573085218, -11.708167979529927],
            [-71.20637110580903, -11.755534729573398],
            [-71.12629048073305, -11.753339729570136],
            [-71.07642760568564, -11.77962272959374],
            [-71.02615835563697, -11.885012229690826],
            [-70.98175423059463, -11.918297854720873],
            [-70.89056173050875, -11.924196604724305],
            [-70.85923710547873, -11.965659479762067],
            [-70.74665598037318, -11.99799235478893],
            [-70.64436548027861, -12.00574922979271],
            [-70.64036335527423, -12.037163354821738],
            [-70.54099585518163, -12.112289104887621],
            [-70.49891698014375, -12.106803854880674],
            [-70.43581560508568, -12.15441860492188],
            [-70.40993085506288, -12.147423979914054],
            [-70.34750210500573, -12.204446854963575],
            [-70.27558447993859, -12.313117605060071],
            [-70.24697260491064, -12.385302730125177],
            [-70.25678335491763, -12.421170105159101],
            [-70.22959160489194, -12.466855480199632],
            [-70.103432729782, -12.508304605228565],
            [-70.06729185474777, -12.579667855291653],
            [-70.07501897975084, -12.63935285534763],
            [-70.16154785482279, -12.6786802303911],
            [-70.19585397985071, -12.720935355432852],
            [-70.25250972989969, -12.749607480463451],
            [-70.29398560493301, -12.845782730555413],
            [-70.33296110496755, -12.870422605580746],
            [-70.35382397998508, -12.914494355622901],
            [-70.35930335498665, -13.008405605710342],
            [-70.40286810502359, -13.114412855811256],
            [-70.38846685500734, -13.19238873088278],
            [-70.46424773008005, -13.159690605856639],
            [-70.54984673016175, -13.15286435585449],
            [-70.58754010519907, -13.103129980809948],
            [-70.65975610526861, -13.088459105799304],
            [-70.71004810531657, -13.109462605820688],
            [-70.75841310536326, -13.108044230821049],
            [-70.84296098044575, -13.067048480785592],
            [-70.9213928555211, -13.110830980828462],
            [-70.94219023054067, -13.15649885587146],
            [-70.97452123057197, -13.1682856058832],
            [-71.01403535560927, -13.26718848097608],
            [-71.0641787306583, -13.256700980967425],
            [-71.1220117307149, -13.233529230947013],
            [-71.16818223076109, -13.09408860581813],
            [-71.17304248076678, -12.983334605715202],
            [-71.23875385583061, -12.971933605705631],
            [-71.30100973089115, -12.940695355677477],
            [-71.35493810594372, -12.88397873062541],
            [-71.415243856002, -12.894258605635654],
            [-71.44887223103463, -12.863340605607268],
            [-71.45104748103692, -12.824969355571568],
            [-71.5521327311349, -12.709301980464847],
            [-71.6002984811813, -12.700058105456618],
            [-71.6949687312722, -12.738793105493366],
            [-71.74694135632194, -12.796107480547002],
            [-71.81059185638311, -12.744978480499768],
            [-71.90035160646909, -12.733355480489367],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 3,
        FIRST_IDPR: '1007',
        NOMBPROV: 'MARAÑON',
        FIRST_NOMB: 'HUANUCO',
        LAST_DCTO: 'LEY',
        LAST_LEY: '1595',
        FIRST_FECH: '31/10/1955',
        LAST_FECHA: '21/10/1912',
        MIN_SHAPE_: 494965.97279,
        ha: 494965.97,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.17482036140294, -8.833617226859324],
            [-77.247361361471, -8.712763726746745],
            [-77.26059573648341, -8.64852147668691],
            [-77.29484961151557, -8.632933601672386],
            [-77.2890273615101, -8.58930472663175],
            [-77.31748636153681, -8.53015235157666],
            [-77.26407811148667, -8.467531226518368],
            [-77.20421286143048, -8.48569135153528],
            [-77.1338437363645, -8.440021476492761],
            [-77.14256536137268, -8.47582560152612],
            [-77.08887886132234, -8.482822226532646],
            [-77.09165073632494, -8.516981351564445],
            [-77.04980136128573, -8.553715226598678],
            [-76.91629836116073, -8.570159726613992],
            [-76.84726673609615, -8.546481851591974],
            [-76.83158361108148, -8.516655226564168],
            [-76.69727148595591, -8.532408226578866],
            [-76.62431636088776, -8.511953101559813],
            [-76.54268748581153, -8.509710976557743],
            [-76.46616736074012, -8.569742976613655],
            [-76.4439536107194, -8.488791851538243],
            [-76.41680098569408, -8.443436601495998],
            [-76.37578536065581, -8.422581226476602],
            [-76.33346811061634, -8.429473851482992],
            [-76.32418548560766, -8.524134601571166],
            [-76.27846473556504, -8.577460976620818],
            [-76.26668298555404, -8.655046726693065],
            [-76.22868361051862, -8.74808397677974],
            [-76.22356048551384, -8.791425726820098],
            [-76.20539061049692, -8.833861351859623],
            [-76.2266172355167, -8.863835976887534],
            [-76.22123436051167, -8.925679726945122],
            [-76.24054311052967, -8.971101601987428],
            [-76.36546486064618, -9.026324227038863],
            [-76.41923436069634, -8.98640235200168],
            [-76.47336911074684, -9.01223435202574],
            [-76.56146286082907, -9.021369102034237],
            [-76.63221486089512, -9.045404352056602],
            [-76.68942973594858, -8.931324226950384],
            [-76.73477936099096, -8.870781226894],
            [-76.82230661107278, -8.83446097686015],
            [-76.89501361114081, -8.86149610188533],
            [-77.01705473625505, -8.840585976865833],
            [-77.08141786131534, -8.774990226804745],
            [-77.14328711137337, -8.780720726810044],
            [-77.17482036140294, -8.833617226859324],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 5,
        FIRST_IDPR: '2206',
        NOMBPROV: 'MARISCAL CACERES',
        FIRST_NOMB: 'SAN MARTIN',
        LAST_DCTO: 'LEY',
        LAST_LEY: '9097',
        FIRST_FECH: '24/01/1959',
        LAST_FECHA: '07/05/1940',
        MIN_SHAPE_: 1426188.17138,
        ha: 1426188.17,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.59771673586293, -7.689289100793657],
            [-76.63418023589698, -7.686844475791377],
            [-76.65779098591902, -7.726422475828232],
            [-76.77304548602673, -7.745709225846197],
            [-76.76343461101776, -7.793464975890655],
            [-76.7192964859765, -7.864526975956834],
            [-76.80030386105221, -7.920584101009038],
            [-76.87324498612043, -7.898796975988766],
            [-76.9295299861731, -7.841739975935604],
            [-77.02084298625857, -7.8058727259022],
            [-77.07697411131116, -7.848622975941998],
            [-77.10251948633511, -7.952671976038913],
            [-77.07436311130874, -7.992300726075823],
            [-77.06870311130342, -8.039511726119777],
            [-77.23828911146242, -8.013324226095355],
            [-77.29225573651307, -8.060089351138894],
            [-77.3854726116006, -8.055386726134477],
            [-77.40712961162095, -7.991985601075431],
            [-77.37690923659255, -7.90882135099799],
            [-77.4067833616206, -7.902493100992062],
            [-77.44286111165448, -7.813641725909332],
            [-77.47983361168922, -7.803291600899668],
            [-77.52777661173428, -7.757582975857067],
            [-77.48452886169362, -7.699714475803203],
            [-77.53094861173722, -7.654479850761039],
            [-77.5367046117426, -7.582280975693791],
            [-77.52691773673341, -7.504839350621698],
            [-77.55953711176404, -7.464817100584384],
            [-77.61027086181173, -7.434858350556446],
            [-77.6357093618356, -7.35744610048435],
            [-77.62864598682894, -7.320024225449508],
            [-77.64674586184593, -7.240740100375643],
            [-77.6055302368072, -7.21916185035557],
            [-77.64353448684288, -7.149000600290216],
            [-77.71337861190848, -7.107887100251869],
            [-77.75893361195118, -6.964507850118296],
            [-77.72332486191772, -6.893455975052171],
            [-77.76171761195374, -6.807748349972326],
            [-77.74671373693963, -6.751838474920241],
            [-77.74560648693856, -6.665158224839535],
            [-77.69336323688951, -6.67852534985203],
            [-77.62666411182686, -6.612740224790802],
            [-77.56369761176776, -6.644084724820053],
            [-77.54407423674932, -6.676996099850701],
            [-77.45465898666538, -6.681688349855119],
            [-77.38173298659693, -6.697589099869944],
            [-77.43614911164799, -6.605217224783892],
            [-77.39386986160831, -6.602688224781572],
            [-77.3351523615532, -6.522328474706741],
            [-77.32635661154492, -6.468027349656161],
            [-77.27551436149723, -6.384731099578619],
            [-77.19787236142442, -6.420986099612378],
            [-77.15822323638724, -6.501197099687113],
            [-77.0466113612827, -6.565689474747191],
            [-77.07403911130838, -6.639706974816106],
            [-77.02541986126285, -6.713501974884854],
            [-77.03919986127576, -6.737596474907305],
            [-77.01443936125257, -6.837115474999978],
            [-76.96546273620673, -6.943187475098759],
            [-76.95203423619415, -6.996318975148252],
            [-76.90930461115416, -7.00197810015352],
            [-76.90707998615207, -7.086179975231938],
            [-76.8508679860995, -7.072905600219587],
            [-76.81212798606326, -7.172978850312803],
            [-76.78388636103688, -7.162871975303384],
            [-76.71529848597277, -7.097690600242653],
            [-76.67040373593082, -7.127247100270212],
            [-76.63376998589659, -7.152279975293512],
            [-76.6144999858786, -7.213509975350561],
            [-76.55778998582566, -7.307079975437702],
            [-76.57430998584108, -7.386819975511948],
            [-76.55147998581974, -7.504289975621347],
            [-76.5796758608461, -7.60761610071758],
            [-76.56560948583295, -7.657190975763745],
            [-76.59771673586293, -7.689289100793657],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 8,
        FIRST_IDPR: '0213',
        NOMBPROV: 'MARISCAL LUZURIAGA',
        FIRST_NOMB: 'ANCASH',
        LAST_DCTO: 'LEY',
        LAST_LEY: '13426',
        FIRST_FECH: '28/10/1889',
        LAST_FECHA: '05/05/1960',
        MIN_SHAPE_: 73673.6219076,
        ha: 73673.62,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.35949611157633, -8.977238226993013],
            [-77.38632811160157, -8.99508197700961],
            [-77.44514448665683, -8.990177727005003],
            [-77.48653711169577, -9.007269477020921],
            [-77.55273048675807, -8.987820352002734],
            [-77.5440027367498, -8.931291976950122],
            [-77.56345311176813, -8.903373101924089],
            [-77.40557036161962, -8.91317572693332],
            [-77.3924648616073, -8.881040976903401],
            [-77.44713286165867, -8.838320351863588],
            [-77.39265236160747, -8.767619101797777],
            [-77.27976761150143, -8.70282422673748],
            [-77.247361361471, -8.712763726746745],
            [-77.17482036140294, -8.833617226859324],
            [-77.15953323638861, -8.897103476918451],
            [-77.18857223641584, -8.928382851947573],
            [-77.32046873653967, -8.945441351963408],
            [-77.35949611157633, -8.977238226993013],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 6,
        FIRST_IDPR: '1801',
        NOMBPROV: 'MARISCAL NIETO',
        FIRST_NOMB: 'MOQUEGUA',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: '08/11/1894',
        LAST_FECHA: 'EPOCA INDEP.',
        MIN_SHAPE_: 868659.271087,
        ha: 868659.27,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.57250785508195, -17.194109359604138],
            [-70.60997835512363, -17.21450385962538],
            [-70.6842207302063, -17.224980109639414],
            [-70.71591710524054, -17.27365210968625],
            [-70.75240410527898, -17.38057335978727],
            [-70.73557835526003, -17.401229984805553],
            [-70.84375998037714, -17.524659984925307],
            [-70.87964998041588, -17.555239984955268],
            [-70.9078148554453, -17.6472196100418],
            [-70.99659760554141, -17.620787110020807],
            [-71.07287885562364, -17.524703109934237],
            [-71.07395123062513, -17.48746673489972],
            [-71.21587298077436, -17.434665984854778],
            [-71.25934573081936, -17.439945359860786],
            [-71.31561323087747, -17.419953109843515],
            [-71.37633548093999, -17.351283859780956],
            [-71.36936910593306, -17.312234359744508],
            [-71.44095610600608, -17.2517166096896],
            [-71.30224610586565, -17.093851109540108],
            [-71.33942410590409, -17.021758984473873],
            [-71.38461298095005, -17.02111048447416],
            [-71.39092260595666, -16.98049160943651],
            [-71.33303323089798, -16.94347460940098],
            [-71.2858593558498, -16.943136859399665],
            [-71.16939848073002, -16.906970734363266],
            [-71.1556874807161, -16.871179734329658],
            [-71.06091573061838, -16.75860373422237],
            [-70.96568998051796, -16.777659984236966],
            [-70.825858855369, -16.761503609216508],
            [-70.77835998031854, -16.715169984171418],
            [-70.76394998030436, -16.63463998409604],
            [-70.72048998025757, -16.61935998407979],
            [-70.67902998021391, -16.548419984011897],
            [-70.60302985513084, -16.548079984007426],
            [-70.56277998008618, -16.563879984019675],
            [-70.4728499799866, -16.555469984006038],
            [-70.39663272990224, -16.52376335897115],
            [-70.35974810486226, -16.47763860892558],
            [-70.31293947981003, -16.461577983906867],
            [-70.31375247981076, -16.466468733911462],
            [-70.30241910479751, -16.47752135892072],
            [-70.29917485479378, -16.479401608922174],
            [-70.28701560477981, -16.483310858924735],
            [-70.27223247976265, -16.49249998393192],
            [-70.24421460473027, -16.50382535893983],
            [-70.24260310472839, -16.50477085894056],
            [-70.22866622971239, -16.50607573394045],
            [-70.2271564797106, -16.50793885894203],
            [-70.22400522970683, -16.51159635894511],
            [-70.22220960470474, -16.512211108945493],
            [-70.20290435468152, -16.53693948396647],
            [-70.20099635467923, -16.539171108968354],
            [-70.19950685467751, -16.539626608968625],
            [-70.19332885467043, -16.539067358967507],
            [-70.19239397966938, -16.53879323396715],
            [-70.18538697966137, -16.537195983964978],
            [-70.18405097965976, -16.538531983966077],
            [-70.18051460465489, -16.556864733982643],
            [-70.17668360464977, -16.572164483996396],
            [-70.17582247964783, -16.592749484015314],
            [-70.17047035464135, -16.598662859020223],
            [-70.16916872963975, -16.600701234021972],
            [-70.15792110462601, -16.61600098403494],
            [-70.15440922962196, -16.615706234034295],
            [-70.14623147961238, -16.617130609034742],
            [-70.14274410460818, -16.620470609037454],
            [-70.13456610459818, -16.630662234045985],
            [-70.12616722958819, -16.634591484048695],
            [-70.1213783545825, -16.637194734050567],
            [-70.11337235457302, -16.639748734052045],
            [-70.10784685456633, -16.64412010905546],
            [-70.10367197956126, -16.6482459840588],
            [-70.09914035455584, -16.65029798406018],
            [-70.09900947955569, -16.650453359060307],
            [-70.09066022954573, -16.654095734062718],
            [-70.08678485454114, -16.65518410906327],
            [-70.08415897953815, -16.652967609060926],
            [-70.0726667295253, -16.641258984048786],
            [-70.04861160449776, -16.62770798403342],
            [-70.03326810447935, -16.634319984037642],
            [-70.03063560447623, -16.634723734037685],
            [-70.02462410446935, -16.631183734033694],
            [-69.99556310442925, -16.729775984120884],
            [-70.05085685449302, -16.763656734159085],
            [-70.09108597953816, -16.81211785920858],
            [-70.09427885453793, -16.890924859281654],
            [-70.14662497959776, -16.938730484331685],
            [-70.18128922963902, -16.9321196093293],
            [-70.24718185471554, -16.9524439843547],
            [-70.2590663547314, -16.90203323430926],
            [-70.24376760471526, -16.860242234269197],
            [-70.1840672296466, -16.834687484239634],
            [-70.20557422967289, -16.80812498421729],
            [-70.3076113547925, -16.77304685919447],
            [-70.44160547994319, -16.841226609268368],
            [-70.4647226049664, -16.94568360936675],
            [-70.44957935494529, -17.081292359491144],
            [-70.4918556049932, -17.091373734503563],
            [-70.52779860503202, -17.168308359577278],
            [-70.57250785508195, -17.194109359604138],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 4,
        FIRST_IDPR: '1604',
        NOMBPROV: 'MARISCAL RAMON CASTILLA',
        FIRST_NOMB: 'LORETO',
        LAST_DCTO: 'DEC.',
        LAST_LEY: 'S/N',
        FIRST_FECH: '02/07/1943',
        LAST_FECHA: '07/02/1866',
        MIN_SHAPE_: 3796987.88966,
        ha: 3796987.89,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.502637230339, -3.463229096849349],
            [-70.71114723047017, -3.790295722156085],
            [-70.61901998040722, -3.840059972201189],
            [-70.57367698037812, -3.830294597191479],
            [-70.49641110533028, -3.875808972232552],
            [-70.3462988552501, -3.799759722159172],
            [-70.276306605217, -3.837630847192792],
            [-70.24609373020316, -3.890367222241001],
            [-70.1980038551838, -3.909605472257662],
            [-70.17454498017398, -3.969562472312642],
            [-70.1050349801494, -4.057466972392114],
            [-70.05997398013648, -4.086243597417338],
            [-70.03407948012907, -4.134412722461074],
            [-69.95568948011268, -4.207642597526005],
            [-69.9495918551095, -4.279382722592171],
            [-69.97441798011148, -4.332777472642606],
            [-70.01859873011924, -4.353254847663253],
            [-70.07085998013278, -4.322318972636469],
            [-70.07936723013614, -4.285410472602547],
            [-70.15814423016104, -4.277208972597442],
            [-70.15382873015851, -4.318998972636068],
            [-70.18083560516737, -4.355404722670649],
            [-70.245824730195, -4.284615722606805],
            [-70.27853410521048, -4.22892797255596],
            [-70.29394923021887, -4.159307347491744],
            [-70.3797883552615, -4.133906597470085],
            [-70.43282060529017, -4.131289222468758],
            [-70.46631148030853, -4.175755347510685],
            [-70.52603585534469, -4.136077847474873],
            [-70.55867560536454, -4.170800847507687],
            [-70.61624348040151, -4.193481347529672],
            [-70.64462235542119, -4.126946722468239],
            [-70.69432335545469, -4.186671222524419],
            [-70.74702835549229, -4.161210972501408],
            [-70.816605855543, -4.197111472535592],
            [-70.82858448055157, -4.248150472583186],
            [-70.86463323057887, -4.256151472590991],
            [-70.8763818555874, -4.321646097652016],
            [-70.94828860564321, -4.376021847703307],
            [-71.07423398074513, -4.39410072272113],
            [-71.10579148077144, -4.3763143477048],
            [-71.18547973083848, -4.397892972725401],
            [-71.21298635586204, -4.37664434770577],
            [-71.26188423090395, -4.427826597753676],
            [-71.34095373097281, -4.439366972764804],
            [-71.38772848101407, -4.425601597752181],
            [-71.41880973104153, -4.464708222788705],
            [-71.47917685609548, -4.434917222761213],
            [-71.5359161061464, -4.462577472787135],
            [-71.56956423117676, -4.508229972829764],
            [-71.61591135621877, -4.528136472848397],
            [-71.64790535624795, -4.502731972824835],
            [-71.7067959813018, -4.509887722831638],
            [-71.739701731332, -4.481637347805383],
            [-71.86499785644753, -4.526451097847365],
            [-71.91851460649711, -4.529296847850094],
            [-71.91859748149717, -4.561912597880456],
            [-71.98221260655623, -4.627434097941546],
            [-72.0374009816076, -4.636661972950211],
            [-72.10304673166883, -4.706569598015382],
            [-72.18593285674628, -4.742790723049178],
            [-72.26916423182418, -4.79715022309987],
            [-72.32588598187729, -4.801556973104012],
            [-72.37856598192667, -4.834833473135039],
            [-72.41493473196077, -4.900318473196037],
            [-72.45254873199602, -4.90099372319668],
            [-72.4856929820271, -4.93518909822852],
            [-72.52047860705972, -4.93280584822634],
            [-72.60535673213931, -4.999184598288172],
            [-72.60652460714041, -5.028223723315223],
            [-72.65455348218545, -5.06283609834746],
            [-72.72865110725493, -5.054836473340035],
            [-72.81984548234041, -5.132780473412645],
            [-72.85496835737331, -5.13869534841817],
            [-72.91278748242746, -5.119895973400655],
            [-72.97773760748831, -5.063245223347892],
            [-73.05386685755956, -5.037248723323682],
            [-73.09810060760094, -5.000796473289756],
            [-73.16991798266812, -4.99800197328715],
            [-73.2362441077301, -4.970566348261583],
            [-73.10215235760474, -4.789773473093201],
            [-73.12943948263025, -4.695544973005453],
            [-73.06325773256835, -4.669731347981421],
            [-73.03524273254212, -4.632538722946774],
            [-73.03500198254191, -4.554062472873684],
            [-73.00676573251548, -4.507851597830634],
            [-72.93467773244798, -4.467095722792672],
            [-72.81144135733254, -4.498540972821931],
            [-72.78338085730626, -4.488455097812527],
            [-72.75656248228115, -4.382718722714046],
            [-72.63509573216724, -4.399130847729332],
            [-72.6029391071371, -4.35523322268843],
            [-72.62856060716115, -4.303654347640409],
            [-72.48176560702353, -4.293296847630719],
            [-72.38775660693541, -4.208531347551743],
            [-72.43674348198135, -4.161530472507993],
            [-72.39559485694276, -4.057240347410857],
            [-72.34816410689837, -4.006003847363099],
            [-72.30601110685889, -3.994261597352165],
            [-72.25536523181151, -3.944792972306063],
            [-72.2828872318373, -3.834838597203678],
            [-72.27993935683455, -3.739445097114829],
            [-72.30264848185581, -3.686124472065186],
            [-72.17427348173581, -3.604281221988864],
            [-72.14802223171132, -3.528520346918302],
            [-72.11844298168371, -3.497667721889551],
            [-72.19800585675803, -3.441046846836889],
            [-72.19845123175844, -3.404255846802617],
            [-72.27396285682906, -3.332783221736072],
            [-72.29343948184729, -3.29293559669898],
            [-72.33777435688877, -3.282446596689245],
            [-72.40382648195063, -3.235140971645199],
            [-72.4790566070211, -3.098662096518119],
            [-72.5463631070842, -3.07139922149274],
            [-72.57339060710953, -2.947851596377665],
            [-72.57077348210709, -2.890898471324626],
            [-72.51753510705718, -2.891138721324841],
            [-72.44865860699267, -2.845262971282111],
            [-72.37731248192587, -2.849958971286459],
            [-72.27682798183186, -2.786906846227687],
            [-72.19304773175355, -2.815356221254153],
            [-72.16771685672991, -2.800496096240324],
            [-72.11765323168322, -2.831450096269114],
            [-72.03273635660419, -2.816117221254782],
            [-71.98296098155797, -2.876294971310772],
            [-71.93827535651651, -2.903261721335841],
            [-71.79683848138608, -2.907253221339433],
            [-71.75487110634755, -2.94134959637112],
            [-71.74518410633864, -2.979136971406318],
            [-71.68561073128424, -3.011889471436736],
            [-71.64936735625128, -3.011951971436709],
            [-71.59578510620285, -2.95177934638061],
            [-71.49037885610824, -3.015398471439604],
            [-71.4887184811066, -3.108538346526339],
            [-71.40289848103046, -3.172093721585262],
            [-71.31074223094997, -3.181568346593791],
            [-71.20702348086104, -3.218945346628197],
            [-71.06440173074255, -3.215019346623857],
            [-71.05069498073127, -3.256615096662492],
            [-70.9758846056713, -3.280792096684543],
            [-70.93128510563602, -3.347411096746182],
            [-70.84265823056856, -3.348093721746155],
            [-70.76208598050947, -3.380150346775255],
            [-70.71462448047616, -3.363954096759715],
            [-70.65444723043484, -3.406691346798802],
            [-70.59437498039554, -3.424865221814956],
            [-70.52861148035505, -3.407837846798261],
            [-70.502637230339, -3.463229096849349],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 13,
        FIRST_IDPR: '1601',
        NOMBPROV: 'MAYNAS',
        FIRST_NOMB: 'LORETO',
        LAST_DCTO: 'LEY',
        LAST_LEY: '28362',
        FIRST_FECH: '08/06/1936',
        LAST_FECHA: '19/10/2004',
        MIN_SHAPE_: 12170565.8047,
        ha: 12170565.8,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.502637230339, -3.463229096849349],
            [-70.52861148035505, -3.407837846798261],
            [-70.59437498039554, -3.424865221814956],
            [-70.65444723043484, -3.406691346798802],
            [-70.71462448047616, -3.363954096759715],
            [-70.76208598050947, -3.380150346775255],
            [-70.84265823056856, -3.348093721746155],
            [-70.93128510563602, -3.347411096746182],
            [-70.9758846056713, -3.280792096684543],
            [-71.05069498073127, -3.256615096662492],
            [-71.06440173074255, -3.215019346623857],
            [-71.20702348086104, -3.218945346628197],
            [-71.31074223094997, -3.181568346593791],
            [-71.40289848103046, -3.172093721585262],
            [-71.4887184811066, -3.108538346526339],
            [-71.49037885610824, -3.015398471439604],
            [-71.59578510620285, -2.95177934638061],
            [-71.64936735625128, -3.011951971436709],
            [-71.68561073128424, -3.011889471436736],
            [-71.74518410633864, -2.979136971406318],
            [-71.75487110634755, -2.94134959637112],
            [-71.79683848138608, -2.907253221339433],
            [-71.93827535651651, -2.903261721335841],
            [-71.98296098155797, -2.876294971310772],
            [-72.03273635660419, -2.816117221254782],
            [-72.11765323168322, -2.831450096269114],
            [-72.16771685672991, -2.800496096240324],
            [-72.19304773175355, -2.815356221254153],
            [-72.27682798183186, -2.786906846227687],
            [-72.37731248192587, -2.849958971286459],
            [-72.44865860699267, -2.845262971282111],
            [-72.51753510705718, -2.891138721324841],
            [-72.57077348210709, -2.890898471324626],
            [-72.57339060710953, -2.947851596377665],
            [-72.5463631070842, -3.07139922149274],
            [-72.4790566070211, -3.098662096518119],
            [-72.40382648195063, -3.235140971645199],
            [-72.33777435688877, -3.282446596689245],
            [-72.29343948184729, -3.29293559669898],
            [-72.27396285682906, -3.332783221736072],
            [-72.19845123175844, -3.404255846802617],
            [-72.19800585675803, -3.441046846836889],
            [-72.11844298168371, -3.497667721889551],
            [-72.14802223171132, -3.528520346918302],
            [-72.17427348173581, -3.604281221988864],
            [-72.30264848185581, -3.686124472065186],
            [-72.27993935683455, -3.739445097114829],
            [-72.2828872318373, -3.834838597203678],
            [-72.25536523181151, -3.944792972306063],
            [-72.30601110685889, -3.994261597352165],
            [-72.34816410689837, -4.006003847363099],
            [-72.39559485694276, -4.057240347410857],
            [-72.43674348198135, -4.161530472507993],
            [-72.38775660693541, -4.208531347551743],
            [-72.48176560702353, -4.293296847630719],
            [-72.62856060716115, -4.303654347640409],
            [-72.6029391071371, -4.35523322268843],
            [-72.63509573216724, -4.399130847729332],
            [-72.75656248228115, -4.382718722714046],
            [-72.78338085730626, -4.488455097812527],
            [-72.81144135733254, -4.498540972821931],
            [-72.93467773244798, -4.467095722792672],
            [-73.00676573251548, -4.507851597830634],
            [-73.03500198254191, -4.554062472873684],
            [-73.03524273254212, -4.632538722946774],
            [-73.06325773256835, -4.669731347981421],
            [-73.12943948263025, -4.695544973005453],
            [-73.15560348265473, -4.709708973018631],
            [-73.26966010776135, -4.638835972952634],
            [-73.32256473281075, -4.584317972901867],
            [-73.3953027328787, -4.479252097803997],
            [-73.43521773291597, -4.446356222773385],
            [-73.4205614829023, -4.424193347752728],
            [-73.44280460792305, -4.306345972642966],
            [-73.380619357865, -4.25999547259979],
            [-73.40029298288336, -4.238546847579833],
            [-73.4682128579468, -4.284902347622998],
            [-73.48814310796539, -4.323648597659084],
            [-73.54922060802237, -4.341326597675574],
            [-73.59267673306293, -4.381820597713282],
            [-73.69192385815549, -4.439775347767245],
            [-73.74708910820694, -4.449058222775881],
            [-73.83358148328757, -4.43862222276617],
            [-73.88351760833412, -4.388433597719438],
            [-73.93700198338396, -4.371898472704035],
            [-73.94525973339165, -4.324501972659879],
            [-74.05870123349736, -4.247613222588289],
            [-74.10170685853745, -4.177025347522552],
            [-74.13543748356885, -4.061880847415293],
            [-74.1479144835805, -3.989321972347702],
            [-74.19221735862175, -3.882459847248168],
            [-74.21913598364681, -3.853998097221659],
            [-74.28113385870458, -3.829804222199139],
            [-74.36743973378495, -3.718679847095652],
            [-74.50143360890975, -3.698855347077182],
            [-74.55454298395921, -3.633314472016151],
            [-74.67923073407535, -3.5093687219007],
            [-74.67947298407556, -3.465786471860103],
            [-74.7125662341064, -3.43607422183242],
            [-74.75394498414492, -3.334533221737858],
            [-74.8187792342053, -3.296851596702756],
            [-74.81337123420025, -3.243214846652814],
            [-74.89290048427434, -3.209853471621747],
            [-74.99161148436626, -3.190005471603249],
            [-75.01780660939066, -3.12898047154642],
            [-75.0142886093874, -3.074875721496034],
            [-75.05463548442495, -2.940187596370577],
            [-75.01026948438364, -2.766357471208694],
            [-75.00992660938331, -2.684673721132612],
            [-74.97374810934964, -2.660699221110282],
            [-74.95746285933446, -2.611460971064412],
            [-74.99119310936588, -2.516005220975509],
            [-74.95681048433387, -2.504294970964609],
            [-74.94959185932713, -2.458790970922234],
            [-74.96514848434164, -2.365650345835471],
            [-75.01715035939004, -2.351790970822582],
            [-75.0832284844516, -2.293470720768257],
            [-75.1408843595053, -2.27892634575471],
            [-75.16607623452875, -2.191746095673522],
            [-75.24618660960336, -2.133828595619575],
            [-75.24325235960065, -2.103102095590961],
            [-75.28814923464243, -2.008710720503033],
            [-75.33755473468845, -2.003201220497905],
            [-75.44490035978843, -2.034136720526721],
            [-75.47254098481416, -2.00385547049852],
            [-75.53628910987355, -1.996460970491612],
            [-75.57483598490946, -1.963455095460868],
            [-75.74592386006879, -1.930923845430606],
            [-75.77707811009782, -1.856474595361264],
            [-75.77836486009902, -1.793951345303025],
            [-75.58093385991515, -1.546366595072415],
            [-75.53896298487602, -1.483474345013847],
            [-75.40389848475026, -1.00008371956363],
            [-75.38669973473422, -0.929738594498124],
            [-75.32203310967398, -0.974776844540051],
            [-75.21623835957548, -0.970833719536387],
            [-75.22117185958008, -0.86576334443853],
            [-75.27246910962783, -0.735396344317113],
            [-75.23586998459375, -0.647269969235025],
            [-75.22759998458606, -0.551429969145767],
            [-75.27774473463275, -0.498413094096374],
            [-75.31275760966535, -0.494241219092516],
            [-75.387519984735, -0.434329969036689],
            [-75.46272998480501, -0.318429968928751],
            [-75.48221998482317, -0.236959968852868],
            [-75.53076998486839, -0.180899968800655],
            [-75.61055060994272, -0.191123093810179],
            [-75.61049598494267, -0.113355468737757],
            [-75.56360998489897, -0.13119996875438],
            [-75.505625484845, -0.117942093742025],
            [-75.46067623480312, -0.151670593773433],
            [-75.4051293597514, -0.170267593790762],
            [-75.30624135965928, -0.15541834377692],
            [-75.25977173461601, -0.127947718751331],
            [-75.18359648454508, -0.038605968668117],
            [-75.07736335944612, -0.084204843710613],
            [-75.01689060938982, -0.145685593767876],
            [-74.97280073434874, -0.153525343775173],
            [-74.93208260931083, -0.217789468835024],
            [-74.8480872342326, -0.23358409384973],
            [-74.81391210920077, -0.192248093811233],
            [-74.7627121091531, -0.224789593841554],
            [-74.730389484123, -0.292918343904988],
            [-74.7311406091237, -0.339181468948072],
            [-74.66864060906549, -0.37375384398028],
            [-74.61667998401708, -0.373079968979647],
            [-74.57471610897798, -0.395033344000087],
            [-74.52954885893594, -0.465132844065382],
            [-74.42133685883516, -0.504523469102076],
            [-74.42687010884029, -0.541116844136163],
            [-74.38817623380426, -0.55565946914971],
            [-74.36643923378402, -0.610448094200736],
            [-74.37282610878998, -0.652886719240261],
            [-74.26815035869247, -0.847009719421053],
            [-74.27829685870192, -0.899855469470278],
            [-74.2672177336916, -0.982355719547117],
            [-74.2234043586508, -1.007361344570394],
            [-74.1683576085995, -1.006329344569453],
            [-74.0942609835305, -1.048011969608273],
            [-74.04620148348572, -1.044820219605302],
            [-74.0150736084567, -1.086476344644099],
            [-73.94779998339402, -1.13210921968658],
            [-73.91373798336228, -1.120030469675367],
            [-73.8571328583095, -1.231779344779427],
            [-73.68093160814523, -1.23802146978524],
            [-73.61994923308836, -1.261314469806947],
            [-73.61297173308186, -1.315490844857397],
            [-73.53163573300596, -1.435006344968702],
            [-73.52882423300336, -1.464669969996343],
            [-73.47856835795645, -1.522093720049797],
            [-73.50116798297755, -1.619152345140205],
            [-73.53695535801094, -1.688364845204654],
            [-73.49994998297639, -1.731195095244582],
            [-73.45928160793845, -1.736396595249415],
            [-73.42565135790704, -1.790302970299625],
            [-73.38333735786753, -1.774710720285103],
            [-73.30676360779599, -1.783441345293239],
            [-73.20379285769978, -1.763917470275041],
            [-73.15206798265142, -1.857580220362281],
            [-73.11276535761465, -1.872814720376468],
            [-73.1186677326202, -2.015700970509549],
            [-73.0944002325975, -2.042233345534255],
            [-73.1320273576327, -2.183552720665878],
            [-73.16876410766704, -2.224629720704136],
            [-73.11899610762049, -2.284539095759928],
            [-73.12110098262245, -2.325428345797994],
            [-73.06829885757307, -2.315140595788413],
            [-73.06641398257129, -2.355240220825779],
            [-72.96335685747485, -2.343152970814516],
            [-72.95600435746799, -2.391551220859577],
            [-72.88908598240532, -2.433320345898493],
            [-72.85179885737043, -2.435783220900781],
            [-72.76910348229293, -2.386804720855149],
            [-72.71517810724244, -2.385586595854028],
            [-72.69211723222082, -2.406285095873304],
            [-72.59551948213033, -2.366552720836286],
            [-72.51915548205875, -2.4252164708909],
            [-72.46645085700936, -2.421231970887193],
            [-72.37941760692787, -2.45498134591859],
            [-72.3681124819173, -2.489532095950759],
            [-72.29089673184507, -2.469137220931763],
            [-72.25822423181454, -2.432246595897373],
            [-72.20567148176542, -2.445952220910146],
            [-72.14776498171142, -2.415628345881882],
            [-72.04874123161923, -2.334936095806686],
            [-72.01010935658334, -2.368951220838317],
            [-71.96196098153871, -2.357141470827293],
            [-71.9260644815055, -2.307777345781301],
            [-71.88475585646735, -2.311593720784836],
            [-71.83276948141953, -2.190415970671927],
            [-71.79913285638864, -2.204369095684918],
            [-71.7405937313351, -2.142197220626953],
            [-71.73122448132648, -2.201453095682126],
            [-71.70850385630575, -2.227330095706199],
            [-71.66403035626536, -2.202302345682826],
            [-71.6040764812111, -2.231389095709845],
            [-71.46335385608528, -2.271090470746576],
            [-71.37108635600399, -2.38935184585648],
            [-71.31674285595686, -2.374400595842435],
            [-71.30238773094456, -2.343572220813694],
            [-71.23108248088366, -2.337610595807964],
            [-71.1881739808474, -2.379577845846891],
            [-71.16683735582967, -2.337767470807889],
            [-71.13032248079942, -2.337494845807532],
            [-71.13357735580223, -2.285131845758793],
            [-71.05332948073686, -2.270619845745017],
            [-71.00020510569473, -2.20785347068639],
            [-70.92866598063894, -2.254152345729127],
            [-70.87938673060174, -2.22089259569795],
            [-70.83595310556926, -2.290814595762728],
            [-70.77776185552726, -2.292990220764405],
            [-70.7512363555084, -2.328269595797048],
            [-70.66592385545032, -2.353773470820133],
            [-70.67843548045832, -2.402138720865193],
            [-70.62706848042482, -2.397534595860493],
            [-70.63829410543161, -2.465767470924007],
            [-70.59930435540666, -2.484436470940998],
            [-70.47506060533364, -2.455068345912469],
            [-70.43723410531264, -2.521246970973476],
            [-70.41253710529979, -2.525695345977321],
            [-70.35790635527334, -2.489322720942827],
            [-70.29516598024513, -2.512625095963598],
            [-70.35449610527115, -2.545266095994742],
            [-70.3345799802616, -2.57788872102474],
            [-70.27383748023591, -2.547328345995451],
            [-70.23006473021869, -2.574588471020061],
            [-70.21933010521387, -2.643587846083881],
            [-70.1032702301784, -2.657361846094448],
            [-70.0660393551695, -2.684095596118399],
            [-70.0600596051669, -2.758917471187587],
            [-70.13998148018425, -2.89081972131157],
            [-70.502637230339, -3.463229096849349],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 9,
        FIRST_IDPR: '2108',
        NOMBPROV: 'MELGAR',
        FIRST_NOMB: 'PUNO',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: 'EPOCA INDEP.',
        LAST_FECHA: 'EPOCA INDEP.',
        MIN_SHAPE_: 657194.75026,
        ha: 657194.75,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.26824210484881, -14.282969606886137],
            [-70.26753122984799, -14.285723981888658],
            [-70.266687229847, -14.289500106892115],
            [-70.26726472984737, -14.294075856896388],
            [-70.26760322984761, -14.296706856898846],
            [-70.26809672984791, -14.300939231902802],
            [-70.26902522984874, -14.303337731905097],
            [-70.27117185485069, -14.308076981909638],
            [-70.27168035485111, -14.309500981911],
            [-70.27267047985208, -14.31063260691211],
            [-70.27309697985244, -14.31220935691361],
            [-70.27383097985307, -14.314341231915648],
            [-70.27650660485543, -14.32255898192346],
            [-70.2802264798589, -14.328285606929043],
            [-70.28113510485977, -14.329403856930144],
            [-70.2835524798621, -14.331480731932253],
            [-70.28615085486462, -14.333656981934446],
            [-70.2919374798702, -14.33887823193971],
            [-70.30128272987938, -14.343567481944744],
            [-70.31428947989257, -14.340112731942485],
            [-70.31473310489302, -14.340244231942636],
            [-70.31668835489491, -14.341887231944266],
            [-70.31752635489572, -14.34262660694503],
            [-70.32228910490036, -14.345895356948379],
            [-70.3266473549046, -14.349902731952398],
            [-70.32664735490434, -14.356640231958638],
            [-70.32341622990091, -14.361198606962649],
            [-70.32356647990102, -14.3621002319635],
            [-70.3239729799014, -14.363564481964872],
            [-70.32360860490091, -14.36612960696725],
            [-70.32363222990077, -14.3706428569714],
            [-70.32289835489992, -14.37319410697375],
            [-70.31992147989685, -14.37547123197563],
            [-70.31546197989222, -14.379318606978877],
            [-70.3176043548941, -14.38648885698567],
            [-70.31795410489438, -14.387800356986942],
            [-70.31904710489532, -14.392143356991038],
            [-70.3194843548957, -14.393586106992391],
            [-70.3193239798954, -14.397039981995594],
            [-70.31935310489538, -14.398162231996627],
            [-70.31826010489404, -14.404924232002825],
            [-70.31802872989377, -14.406008232003803],
            [-70.32320922989872, -14.412850857010506],
            [-70.32443885489992, -14.414126107011784],
            [-70.32679885490211, -14.418849607016307],
            [-70.32717385490247, -14.41929685701676],
            [-70.32667572990181, -14.423183607020327],
            [-70.32645897990147, -14.426337857023247],
            [-70.32669335490168, -14.427406232024243],
            [-70.32838285490323, -14.430878857027587],
            [-70.32909960490397, -14.43116210702791],
            [-70.32926947990408, -14.432927732029556],
            [-70.32985160490449, -14.437003982033351],
            [-70.32978322990434, -14.439148482035336],
            [-70.33066210490509, -14.44330273203925],
            [-70.33152535490584, -14.445980482041797],
            [-70.33221685490652, -14.447005857042798],
            [-70.33191410490618, -14.44757035704331],
            [-70.33199997990626, -14.448025357043726],
            [-70.33253122990675, -14.44886923204454],
            [-70.332818354907, -14.449826232045464],
            [-70.3340058549081, -14.452638732048154],
            [-70.33396485490803, -14.453287107048732],
            [-70.33215435490614, -14.454982482050195],
            [-70.33163285490556, -14.456289107051356],
            [-70.33083597990473, -14.456814482051774],
            [-70.33017772990401, -14.458048857052889],
            [-70.33027935490406, -14.459472607054208],
            [-70.32922660490289, -14.46235348205681],
            [-70.3296426049031, -14.468261732062304],
            [-70.33039647990383, -14.469109357063159],
            [-70.33058585490397, -14.46977148206379],
            [-70.332679729906, -14.472078107066068],
            [-70.33635922990953, -14.47757210707142],
            [-70.33821685491127, -14.48126173207496],
            [-70.3380527299111, -14.481966857075605],
            [-70.33779885491063, -14.486581982079866],
            [-70.33828122991109, -14.48861323208178],
            [-70.34271685491528, -14.495888732088835],
            [-70.34524222991777, -14.497839857090836],
            [-70.34557222991805, -14.499806482092659],
            [-70.34679497991924, -14.501269482094095],
            [-70.34745247991988, -14.501599107094458],
            [-70.34836685492077, -14.502213107095072],
            [-70.34854797992097, -14.502433982095306],
            [-70.3490073549214, -14.503158482096017],
            [-70.3493474799217, -14.504094857096897],
            [-70.349668729922, -14.504822857097595],
            [-70.3515898549239, -14.506216857099018],
            [-70.35241697992471, -14.506944732099745],
            [-70.35280010492511, -14.507666732100438],
            [-70.35284797992509, -14.508952482101622],
            [-70.352777979925, -14.509162482101832],
            [-70.35214522992423, -14.51205485710446],
            [-70.35252860492454, -14.514365857106624],
            [-70.3529119799249, -14.516101732108282],
            [-70.35406222992586, -14.521160357113024],
            [-70.35437097992616, -14.521820607113671],
            [-70.35396635492566, -14.523939982115614],
            [-70.35331672992494, -14.525867607117352],
            [-70.35258185492408, -14.528711107119946],
            [-70.3526244799241, -14.529839982120976],
            [-70.352560604924, -14.530404482121506],
            [-70.35429572992567, -14.533284232124283],
            [-70.36139560493268, -14.539021732130095],
            [-70.36920472994055, -14.541050732132504],
            [-70.36874560493997, -14.54346623213469],
            [-70.36664410493759, -14.550667607141218],
            [-70.3652988549362, -14.551143982141584],
            [-70.35397847992444, -14.557672857146873],
            [-70.35134447992172, -14.558737732147677],
            [-70.3428111049125, -14.573300982160593],
            [-70.34206447991157, -14.578033232164943],
            [-70.34349372991292, -14.580547982167351],
            [-70.34493397991426, -14.583428482170142],
            [-70.35506247992443, -14.588029357175103],
            [-70.35618160492557, -14.588683607175767],
            [-70.36252585493176, -14.595612107182623],
            [-70.36467310493389, -14.597316982184362],
            [-70.36846360493786, -14.594942232182417],
            [-70.37061560494014, -14.592223982180027],
            [-70.37682735494676, -14.584554107173325],
            [-70.38729460495782, -14.573619732163879],
            [-70.388422604959, -14.572160857162604],
            [-70.38956560496021, -14.571213357161794],
            [-70.3909041049616, -14.570190732160937],
            [-70.40403597997525, -14.562299732154449],
            [-70.40578397997702, -14.563278607155473],
            [-70.4234766049947, -14.573740232166296],
            [-70.42486697999577, -14.584009482175887],
            [-70.42428060499516, -14.584962482176739],
            [-70.41596047998596, -14.605817482195553],
            [-70.41793972998784, -14.610179107199723],
            [-70.418096604988, -14.611173357200661],
            [-70.41643697998612, -14.615823482204872],
            [-70.4144577299841, -14.61659323220548],
            [-70.41108572998058, -14.618315857206868],
            [-70.4032017299723, -14.62451898221209],
            [-70.39525635496389, -14.632650107219138],
            [-70.3919337299605, -14.633248607219457],
            [-70.38774685495609, -14.636712982222395],
            [-70.38772047995585, -14.642641732227895],
            [-70.38763047995566, -14.6452517322303],
            [-70.38578635495335, -14.65822748224221],
            [-70.38385322995117, -14.663233732246736],
            [-70.3820954799492, -14.667874357250922],
            [-70.38273397994978, -14.670400982253314],
            [-70.38359360495055, -14.673318482256056],
            [-70.38475447995162, -14.676955857259513],
            [-70.38596685495277, -14.679715357262161],
            [-70.38847897995521, -14.683671857265995],
            [-70.39852722996481, -14.703517357285039],
            [-70.40198635496789, -14.71847023229913],
            [-70.40421297997008, -14.720606857301249],
            [-70.41184935497772, -14.727483982308117],
            [-70.41767185498348, -14.73375198231429],
            [-70.42672247999165, -14.76866935734724],
            [-70.43055660499546, -14.773503857351963],
            [-70.44554273001043, -14.787990482366325],
            [-70.44551773001007, -14.798125857375712],
            [-70.44681273001132, -14.801163857378612],
            [-70.44673798001118, -14.803529607380801],
            [-70.44846485501272, -14.810647857387503],
            [-70.45297598001706, -14.820380107396804],
            [-70.45396635501793, -14.825838607401932],
            [-70.45192723001578, -14.82741960740327],
            [-70.45042748001421, -14.82758623240334],
            [-70.44206710500539, -14.833462732408275],
            [-70.44318673000646, -14.836613107411262],
            [-70.44020660500286, -14.852734357426032],
            [-70.43814185500065, -14.855205857428196],
            [-70.43748485499995, -14.855518732428445],
            [-70.43732835499965, -14.860242607432816],
            [-70.43767247999997, -14.860899607433433],
            [-70.44111385500331, -14.867625857439883],
            [-70.44386685500606, -14.870973232443166],
            [-70.44884210501112, -14.87405160744632],
            [-70.4493371050116, -14.874898232447137],
            [-70.45598023001833, -14.88081198245302],
            [-70.45627985501856, -14.882948232455023],
            [-70.4575433550198, -14.884680607456701],
            [-70.46103785502322, -14.891121357462884],
            [-70.46101760502319, -14.892139107463837],
            [-70.4629512300249, -14.901114607472278],
            [-70.4638872300257, -14.906406357477241],
            [-70.46524073002698, -14.911359607481904],
            [-70.46613873002762, -14.9206718574906],
            [-70.4668222300283, -14.921230482491167],
            [-70.46592973002734, -14.922831982492582],
            [-70.46616998002754, -14.924224607493896],
            [-70.4679257300293, -14.926017607495664],
            [-70.46569535502695, -14.927992232497356],
            [-70.46551698002673, -14.928152732497491],
            [-70.50444985506728, -14.922429607494465],
            [-70.53183910509279, -15.027949857593875],
            [-70.55256073011338, -15.06363610762812],
            [-70.52008348007713, -15.15130135770763],
            [-70.56257973012069, -15.184009107740323],
            [-70.59585423015685, -15.126806732688985],
            [-70.65732310522064, -15.133998482698653],
            [-70.76082348032973, -15.030864482607365],
            [-70.83349460540487, -15.00208735758337],
            [-70.9936003555672, -15.084587357665066],
            [-71.00405010557917, -14.971877232560614],
            [-70.98474035555985, -14.94606848253609],
            [-70.99383548057051, -14.829163857427698],
            [-70.97548335555275, -14.764133732366764],
            [-71.01309348059102, -14.739693857345056],
            [-71.05324048063132, -14.754330482359693],
            [-71.08951035566818, -14.713188107322305],
            [-71.11794060569734, -14.648917732263184],
            [-71.11297848069242, -14.643925732258428],
            [-71.11245635569189, -14.643492607258027],
            [-71.10992760568944, -14.635670357250705],
            [-71.10992760568945, -14.63469248224978],
            [-71.10911848068862, -14.63395085724909],
            [-71.09900698067852, -14.632686482247665],
            [-71.0957971056753, -14.633037107247938],
            [-71.09399010567348, -14.63252460724741],
            [-71.08068698066019, -14.62989623224465],
            [-71.07548273065494, -14.631944732246438],
            [-71.06936760564886, -14.626045732240817],
            [-71.06564748064515, -14.624096857238905],
            [-71.06054073064007, -14.620266357235241],
            [-71.05492173063448, -14.616854107231937],
            [-71.05387960563344, -14.615709857230847],
            [-71.05312360563268, -14.61515810723032],
            [-71.05016985562979, -14.610144982225563],
            [-71.03877035561845, -14.598193857214188],
            [-71.0248956056046, -14.591925107207999],
            [-71.0100531055896, -14.597541982212848],
            [-71.01231248059196, -14.591579982207369],
            [-71.01510523059478, -14.58828510720438],
            [-71.01965060559941, -14.585952232202336],
            [-71.01969398059946, -14.583395357199946],
            [-71.0164004805962, -14.579235232196],
            [-71.00261973058244, -14.572344857189218],
            [-70.99273923057248, -14.575768357192164],
            [-70.9898217305695, -14.579078232195165],
            [-70.98470785556432, -14.580227732196096],
            [-70.97770323055724, -14.583021107198501],
            [-70.97343060555308, -14.57222935718834],
            [-70.97350785555318, -14.571369857187555],
            [-70.97323285555291, -14.570518982186748],
            [-70.96689860554656, -14.568911857185084],
            [-70.9664344805461, -14.56750223218376],
            [-70.96722523054692, -14.56629035718265],
            [-70.96799060554771, -14.56483348218133],
            [-70.96902410554877, -14.562292982178999],
            [-70.97061723055042, -14.560161607177056],
            [-70.97817698055813, -14.550491857168273],
            [-70.98184923056185, -14.54775485716581],
            [-70.98264460556271, -14.544389232162738],
            [-70.98236798056243, -14.543071732161488],
            [-70.97927198055933, -14.542123232160527],
            [-70.97906110555914, -14.540568607159061],
            [-70.98108998056124, -14.536642482155496],
            [-70.98298723056321, -14.532532107151699],
            [-70.98360635556384, -14.531570232150829],
            [-70.98447598056471, -14.530779857150117],
            [-70.98584610556611, -14.529093482148605],
            [-70.98840210556872, -14.525671607145473],
            [-70.98861460556896, -14.524371482144259],
            [-70.99220910557267, -14.516192232136774],
            [-70.99492323057545, -14.51054373213159],
            [-70.99598698057657, -14.507389357128687],
            [-70.99802685557864, -14.503914607125493],
            [-70.99932610557997, -14.502843232124551],
            [-70.9991664805798, -14.502273482124004],
            [-70.99809523057874, -14.501680857123448],
            [-70.9946309805753, -14.50072360712245],
            [-70.99340010557404, -14.500222232121967],
            [-70.98731473056795, -14.499219357120845],
            [-70.98658535556723, -14.498717982120386],
            [-70.98658535556726, -14.49573223211761],
            [-70.98736035556804, -14.494296357116287],
            [-70.98747423056817, -14.493065607115142],
            [-70.98865948056942, -14.490490107112777],
            [-70.98916085556992, -14.489441607111804],
            [-70.98922923057, -14.48882623211125],
            [-70.98485323056573, -14.479116982102102],
            [-70.98339448056427, -14.476700982099837],
            [-70.98259685556353, -14.47433048209759],
            [-70.98346285556443, -14.471549982095034],
            [-70.98382760556478, -14.47066110709423],
            [-70.98469373056568, -14.468792232092506],
            [-70.98467085556567, -14.468062857091844],
            [-70.98471660556575, -14.466626982090515],
            [-70.98501273056605, -14.46539623208936],
            [-70.98571948056677, -14.464188232088254],
            [-70.98635748056745, -14.462980357087158],
            [-70.98818085556928, -14.461886232086178],
            [-70.98834023056946, -14.460860607085236],
            [-70.98806685556919, -14.460199732084604],
            [-70.98781848056896, -14.456967982081588],
            [-70.98754698056872, -14.454905107079691],
            [-70.98336460556459, -14.45057898207553],
            [-70.98137260556261, -14.449015482074032],
            [-70.97376898055501, -14.447109232072048],
            [-70.9690783555503, -14.447237732072045],
            [-70.9642804805455, -14.44516010706999],
            [-70.96235298054359, -14.444538982069366],
            [-70.96368085554494, -14.442504107067515],
            [-70.96490160554617, -14.44154035706665],
            [-70.96595135554728, -14.438948607064242],
            [-70.96841435554978, -14.433444107059215],
            [-70.97004210555144, -14.431580607057539],
            [-70.97083448055227, -14.43063823205666],
            [-70.97158423055302, -14.430188482056273],
            [-70.97312648055458, -14.429545857055714],
            [-70.97376898055523, -14.429288857055496],
            [-70.97387610555533, -14.428132232054432],
            [-70.97325498055473, -14.426525857052921],
            [-70.9727837305543, -14.424084107050628],
            [-70.97186273055341, -14.421342482048056],
            [-70.97357185555516, -14.418324982045297],
            [-70.97596923055757, -14.41778735704485],
            [-70.9769862305586, -14.41748223204463],
            [-70.97704860555866, -14.417464982044603],
            [-70.97816910555979, -14.41714685704432],
            [-70.97872460556036, -14.416664107043902],
            [-70.96257723054488, -14.361845231992497],
            [-70.9129677304956, -14.327579981959264],
            [-70.88058085546399, -14.270881606905599],
            [-70.89934885548338, -14.229301981867522],
            [-70.86683010545178, -14.169080106810553],
            [-70.80892960539464, -14.124199356766972],
            [-70.78981835537634, -14.078816356724149],
            [-70.71985060530656, -14.074981481718044],
            [-70.69202435527775, -14.123915106762375],
            [-70.65014035523585, -14.12456510676123],
            [-70.61929148020415, -14.159357481792194],
            [-70.55104823013595, -14.155318481785205],
            [-70.54118560512514, -14.18970885681662],
            [-70.47963248006249, -14.223145731844378],
            [-70.4251697300094, -14.178093856799467],
            [-70.38691410497061, -14.196015481813719],
            [-70.32946222991413, -14.175177356790623],
            [-70.26824210484881, -14.282969606886137],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 4,
        FIRST_IDPR: '2109',
        NOMBPROV: 'MOHO',
        FIRST_NOMB: 'PUNO',
        LAST_DCTO: 'LEY',
        LAST_LEY: '25360',
        FIRST_FECH: '12/12/1991',
        LAST_FECHA: '12/12/1991',
        MIN_SHAPE_: 103544.158238,
        ha: 103544.16,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.65510910416012, -15.271365857726055],
            [-69.62986685413479, -15.264868982715566],
            [-69.5484262290466, -15.308678857740313],
            [-69.50858397901344, -15.248673857677046],
            [-69.48606310398807, -15.271581732693303],
            [-69.3983554789097, -15.212289107619128],
            [-69.29174560381662, -15.162267107546514],
            [-69.24063472876594, -15.186843732554985],
            [-69.17629610369488, -15.2613597326042],
            [-69.2485583537419, -15.370808982725414],
            [-69.25351135373008, -15.46919260781675],
            [-69.29007172877087, -15.436879607797346],
            [-69.33111835379579, -15.537947107900727],
            [-69.43496485390916, -15.482357857875309],
            [-69.476695853959, -15.427248982834149],
            [-69.56896360406243, -15.354504482786496],
            [-69.59647847908924, -15.369362482805473],
            [-69.6419762291416, -15.320153482768601],
            [-69.65510910416012, -15.271365857726055],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 10,
        FIRST_IDPR: '2004',
        NOMBPROV: 'MORROPON',
        FIRST_NOMB: 'PIURA',
        LAST_DCTO: 'LEY',
        LAST_LEY: '8174',
        FIRST_FECH: '08/10/1840',
        LAST_FECHA: '31/01/1936',
        MIN_SHAPE_: 384324.53309,
        ha: 384324.53,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.83943748862367, -5.630027348852201],
            [-79.86441986363151, -5.563419973789447],
            [-79.85398636362648, -5.531818348760535],
            [-79.90305486364332, -5.491449223721136],
            [-79.9458729886582, -5.514996098741152],
            [-79.96853911366736, -5.581165973801462],
            [-79.99919136367596, -5.585359348803901],
            [-80.083925613693, -5.560435598776492],
            [-80.25992961369059, -5.330316348553223],
            [-80.32300386368064, -5.290535098512168],
            [-80.36501761366847, -5.23242184845552],
            [-80.33554298866842, -5.121720723355472],
            [-80.28961911367342, -5.071517598312215],
            [-80.22150198867833, -5.050449223296965],
            [-80.25657611367303, -4.991449223240338],
            [-80.23430661367266, -4.94830859820183],
            [-80.17641011367517, -4.971753848226812],
            [-80.10244923866881, -4.929037098191134],
            [-80.08256236366948, -5.004466848261908],
            [-80.03880073866331, -5.02635934828428],
            [-80.00224611365542, -5.015972598276303],
            [-79.9593183636455, -5.036699223297305],
            [-79.90916798863014, -5.019400473283282],
            [-79.86243361361286, -4.972044848241117],
            [-79.78848436358439, -4.990822223261035],
            [-79.67825586353518, -5.093546848359692],
            [-79.64220898851659, -5.10721684837335],
            [-79.71491598855421, -5.126632848389334],
            [-79.72033198855816, -5.182089848440638],
            [-79.694101613547, -5.259195473512993],
            [-79.65519723852843, -5.333298848582904],
            [-79.66282423853329, -5.368953098615803],
            [-79.74035736357268, -5.380933598624571],
            [-79.76843361358698, -5.424527348664111],
            [-79.74998636357896, -5.446435598685063],
            [-79.64253323852495, -5.485191348724319],
            [-79.68160936354698, -5.530980348765692],
            [-79.73738086357459, -5.509001973743516],
            [-79.77704686359466, -5.561915973791297],
            [-79.80040236360512, -5.562152348790686],
            [-79.83943748862367, -5.630027348852201],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 6,
        FIRST_IDPR: '2201',
        NOMBPROV: 'MOYOBAMBA',
        FIRST_NOMB: 'SAN MARTIN',
        LAST_DCTO: 'LEY',
        LAST_LEY: 'S/N',
        FIRST_FECH: '02/01/1857',
        LAST_FECHA: '02/01/1857',
        MIN_SHAPE_: 392696.455,
        ha: 392696.46,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.74482773693734, -5.406899098667711],
            [-77.69595848689151, -5.411053098671616],
            [-77.6703643618675, -5.409644848670322],
            [-77.58166936178435, -5.461175598718363],
            [-77.55811911176224, -5.425578098685249],
            [-77.51739923672405, -5.466748223723582],
            [-77.46194348667206, -5.584140098832951],
            [-77.40872936162214, -5.589160473837651],
            [-77.37007423658586, -5.569555848819371],
            [-77.33831811155605, -5.603472598850999],
            [-77.23194136145632, -5.595460973843555],
            [-77.0417301112781, -5.674708348917381],
            [-76.99560561123492, -5.667119098910316],
            [-76.96135273620285, -5.706077223946598],
            [-76.89453711114034, -5.746232473984006],
            [-76.83545111108508, -5.745555348983378],
            [-76.81805123606883, -5.862144724091966],
            [-76.74672811100213, -5.954027599177535],
            [-76.75443473600934, -5.998390724218856],
            [-76.78682998603962, -6.023879974242596],
            [-76.84563986109461, -6.030359974248639],
            [-76.88346998612998, -6.082209974296933],
            [-76.84035161108966, -6.104993599318144],
            [-76.81532998606627, -6.244319974447888],
            [-76.83514448608479, -6.285152349485925],
            [-76.86710273611469, -6.26460859946679],
            [-76.94931636119159, -6.284375599485186],
            [-76.97181261121266, -6.322515349520715],
            [-76.95252148619461, -6.39716009959023],
            [-77.00919798624766, -6.413210974605167],
            [-77.1154847363472, -6.399205224592112],
            [-77.14658286137633, -6.351112474547315],
            [-77.13272523636337, -6.302421099501982],
            [-77.19727536142386, -6.233419974437714],
            [-77.12453586135571, -6.20335872440971],
            [-77.12046411135185, -6.159264599368662],
            [-77.14321998637318, -6.100823974314221],
            [-77.15383686138314, -6.016964474236135],
            [-77.14017986137034, -5.944519974168641],
            [-77.19552461142221, -5.938747349163272],
            [-77.22984036145438, -5.89878109912603],
            [-77.25306761147614, -5.803484599037287],
            [-77.29987198652003, -5.76189309899855],
            [-77.40345548661719, -5.699665973940541],
            [-77.50082736170855, -5.671920348914689],
            [-77.57108998677448, -5.708359973948578],
            [-77.62718998682709, -5.643629973888256],
            [-77.65537998685353, -5.580819973829764],
            [-77.64099998684, -5.526299973778991],
            [-77.74218898693486, -5.423376473683057],
            [-77.74482773693734, -5.406899098667711],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 5,
        FIRST_IDPR: '1103',
        NOMBPROV: 'NAZCA',
        FIRST_NOMB: 'ICA',
        LAST_DCTO: 'LEY',
        LAST_LEY: '2548',
        FIRST_FECH: '10/05/1955',
        LAST_FECHA: '19/11/1917',
        MIN_SHAPE_: 518745.164839,
        ha: 518745.16,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.08008998444866, -14.56900998220066],
            [-75.05990035942988, -14.622617607250607],
            [-74.95891973433582, -14.606451232235537],
            [-74.89541785927669, -14.545080482178394],
            [-74.85277248423697, -14.611809107240518],
            [-74.84574210923041, -14.707979482330083],
            [-74.8026972341903, -14.752152357371223],
            [-74.75600710914682, -14.771130857388902],
            [-74.74624410913773, -14.84355473245634],
            [-74.69384035908895, -14.838155232451316],
            [-74.66345098406063, -14.907934482516294],
            [-74.6642669840614, -15.04469648264366],
            [-74.76229860915272, -15.09805485769335],
            [-74.81591798420264, -15.139497732731936],
            [-74.87060198425358, -15.151229107742875],
            [-74.93986510931808, -15.206011732793893],
            [-74.96820073434448, -15.299422232880879],
            [-75.00387973437769, -15.330230357909564],
            [-75.04965210942034, -15.434503608006674],
            [-75.07332610944235, -15.442941608014523],
            [-75.08256835945097, -15.423691357996596],
            [-75.18589448454719, -15.367238232944027],
            [-75.15220510951583, -15.349363232927384],
            [-75.1688183595313, -15.307390607888296],
            [-75.23062310958888, -15.27720110786018],
            [-75.21662498457582, -15.231415982817547],
            [-75.2746798596299, -15.168320357758779],
            [-75.36865035971742, -15.14079485773315],
            [-75.44269335978639, -15.000591857602595],
            [-75.50427735984373, -14.959941482564727],
            [-75.49404485983419, -14.929660107536533],
            [-75.44029885978414, -14.894900357504152],
            [-75.41608398476157, -14.774743982392257],
            [-75.43267373477705, -14.749956982369177],
            [-75.41061523475649, -14.68523823230892],
            [-75.42633973477113, -14.643058607269626],
            [-75.36089060971018, -14.620730482248842],
            [-75.3636874847128, -14.582089732212852],
            [-75.33029698468171, -14.61536323224384],
            [-75.23919535959685, -14.591021482221171],
            [-75.21876760957782, -14.646386857272725],
            [-75.18558398454694, -14.636894482263875],
            [-75.15484948451828, -14.677018107301262],
            [-75.07994910944855, -14.602311357231669],
            [-75.08008998444866, -14.56900998220066],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 10,
        FIRST_IDPR: '0214',
        NOMBPROV: 'OCROS',
        FIRST_NOMB: 'ANCASH',
        LAST_DCTO: 'LEY',
        LAST_LEY: 'S/N',
        FIRST_FECH: '30/09/1941',
        LAST_FECHA: '02/01/1857',
        MIN_SHAPE_: 193492.38296,
        ha: 193492.38,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.34470323656262, -10.607980478511678],
            [-77.39530998661024, -10.661539978561535],
            [-77.38557036160107, -10.707593728604433],
            [-77.49495986170409, -10.7461026036402],
            [-77.5771744867816, -10.728570228623814],
            [-77.58637223679024, -10.646944853547794],
            [-77.57665036178108, -10.58374810348894],
            [-77.64073036184152, -10.596271728500533],
            [-77.64425973684482, -10.527409103436403],
            [-77.7367812369322, -10.56321210346964],
            [-77.68391998688222, -10.431385978346931],
            [-77.59937298680242, -10.38333397830229],
            [-77.52363848673103, -10.38739972830616],
            [-77.4814782366913, -10.346228603267855],
            [-77.46252148667342, -10.275658353202129],
            [-77.39914648661379, -10.272048853198799],
            [-77.35950386157651, -10.292033228217445],
            [-77.24375623646772, -10.26716610319436],
            [-77.22968323645452, -10.330003353252877],
            [-77.15820898638744, -10.361919978282607],
            [-77.17287111140118, -10.40086722831887],
            [-77.17603998640416, -10.483099978395474],
            [-77.19098823641822, -10.552132853459764],
            [-77.25187111147538, -10.545242228453294],
            [-77.31062886153059, -10.567691353474201],
            [-77.34470323656262, -10.607980478511678],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 10,
        FIRST_IDPR: '1306',
        NOMBPROV: 'OTUZCO',
        FIRST_NOMB: 'LA LIBERTAD',
        LAST_DCTO: 'DEC.',
        LAST_LEY: 'S/N',
        FIRST_FECH: '07/03/1964',
        LAST_FECHA: '17/12/1866',
        MIN_SHAPE_: 208386.55936,
        ha: 208386.56,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.68236961281167, -8.097219726169767],
            [-78.72132611284691, -8.09172060116432],
            [-78.71150773783762, -8.016232476094132],
            [-78.6836308628123, -7.997185601076627],
            [-78.6888981128168, -7.947408226030257],
            [-78.78022073789857, -7.883624975970092],
            [-78.77570548789403, -7.800680850892912],
            [-78.81625973793015, -7.796873100888981],
            [-78.89307048799758, -7.748966850843589],
            [-78.91228511301388, -7.686081975784825],
            [-78.89597923799933, -7.645388975747138],
            [-78.80376911291796, -7.63410647573761],
            [-78.71245823783674, -7.703982600803469],
            [-78.6960508628223, -7.762677600858228],
            [-78.6631899877927, -7.791983475885766],
            [-78.5812187377178, -7.71092185081086],
            [-78.55091598769017, -7.726203100825302],
            [-78.49944923764288, -7.697402350798764],
            [-78.46843748761417, -7.645744100750842],
            [-78.37606448752885, -7.639958975745895],
            [-78.34796498750292, -7.685716475788603],
            [-78.302802737461, -7.696462725798789],
            [-78.26115436242236, -7.754023975852518],
            [-78.27334536243379, -7.784910100881229],
            [-78.25231836241429, -7.837175725929978],
            [-78.25300586241505, -7.886529350975925],
            [-78.3204667374779, -7.905947225993766],
            [-78.40614773755767, -7.990096476071772],
            [-78.47184698761845, -7.99716097607801],
            [-78.53311723767507, -8.04266997612001],
            [-78.53589898767778, -8.08389485115839],
            [-78.59572348773254, -8.061569726137224],
            [-78.68236961281167, -8.097219726169767],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 7,
        FIRST_IDPR: '1903',
        NOMBPROV: 'OXAPAMPA',
        FIRST_NOMB: 'PASCO',
        LAST_DCTO: 'LEY',
        LAST_LEY: '13014',
        FIRST_FECH: '14/05/1876',
        LAST_FECHA: '17/06/1958',
        MIN_SHAPE_: 1784306.29337,
        ha: 1784306.29,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.45335010979628, -10.900295353784081],
            [-75.46522235980736, -10.901126853784856],
            [-75.46608648480817, -10.899641978783473],
            [-75.46734123480933, -10.895948103780057],
            [-75.46766148480961, -10.892184103776533],
            [-75.4674317348094, -10.890983728775403],
            [-75.46717423480918, -10.890367103774846],
            [-75.46712448480913, -10.888164728772804],
            [-75.4686132348105, -10.885943353770717],
            [-75.4695738598114, -10.88508960376993],
            [-75.47233335981399, -10.88282197876782],
            [-75.47285248481445, -10.881373853766458],
            [-75.47283423481444, -10.878873978764146],
            [-75.47263998481428, -10.875959728761433],
            [-75.47277923481438, -10.874437478759988],
            [-75.47411623481564, -10.87009297875596],
            [-75.47550985981694, -10.8676772287537],
            [-75.47791348481917, -10.864970228751181],
            [-75.4782666098195, -10.863712603750033],
            [-75.4779279848192, -10.86139185374787],
            [-75.47800660981927, -10.859581603746157],
            [-75.48030860982142, -10.853039103740093],
            [-75.4804072348215, -10.848719978736064],
            [-75.47976448482089, -10.847506603734928],
            [-75.47819035981944, -10.845465728733021],
            [-75.476660484818, -10.844224728731888],
            [-75.47514698481658, -10.842993978730732],
            [-75.47551423481693, -10.83989822872784],
            [-75.47540935981685, -10.834650978722943],
            [-75.47530435981675, -10.83323422872163],
            [-75.47456835981608, -10.829017603717709],
            [-75.4767753598181, -10.823955103712988],
            [-75.47741810981871, -10.82270072871182],
            [-75.47974323482087, -10.817926728707398],
            [-75.4790199848202, -10.815705103705318],
            [-75.47421510981574, -10.809763728699778],
            [-75.4709602348127, -10.808988728699063],
            [-75.46897423481086, -10.807995603698142],
            [-75.46805623480999, -10.80755022869773],
            [-75.46948648481133, -10.801941353692492],
            [-75.4698887348117, -10.801259853691867],
            [-75.47059010981236, -10.799123853689881],
            [-75.46996573481177, -10.79749447868835],
            [-75.4641972348064, -10.791367103682665],
            [-75.46176123480414, -10.788648728680107],
            [-75.46148798480385, -10.78758385367914],
            [-75.46009010980258, -10.786502603678121],
            [-75.45935710980189, -10.785414478677103],
            [-75.45920335980175, -10.784274478676064],
            [-75.4592768598018, -10.782110728674013],
            [-75.45948560980199, -10.780316603672354],
            [-75.45985098480234, -10.778959478671087],
            [-75.46106598480347, -10.777645603669873],
            [-75.46232248480464, -10.776703228668989],
            [-75.4701199848119, -10.776849978669144],
            [-75.47131998481302, -10.77819997867038],
            [-75.4718399848135, -10.77880997867095],
            [-75.47419998481571, -10.780139978672189],
            [-75.47788998481916, -10.780959978672973],
            [-75.4821399848231, -10.776389978668712],
            [-75.48231998482329, -10.774709978667142],
            [-75.48411998482496, -10.764989978658095],
            [-75.48484998482563, -10.764989978658097],
            [-75.48848998482902, -10.761389978654739],
            [-75.48922998482972, -10.760349853653764],
            [-75.49024998483067, -10.756829978650478],
            [-75.49453998483467, -10.755229853648991],
            [-75.49477998483489, -10.755159978648917],
            [-75.4962999848363, -10.755979978649686],
            [-75.49664998483662, -10.75699010365062],
            [-75.49999998483976, -10.758379978651924],
            [-75.50061998484031, -10.758059978651637],
            [-75.5025399848421, -10.757989978651548],
            [-75.50278998484235, -10.758049978651632],
            [-75.50524998484464, -10.756779978650423],
            [-75.50798998484719, -10.754619978648433],
            [-75.50923998484835, -10.753919978647769],
            [-75.50935998484847, -10.753919978647755],
            [-75.51001998484908, -10.7542499786481],
            [-75.51047998484951, -10.754519978648315],
            [-75.51283998485171, -10.755849978649563],
            [-75.51310998485197, -10.756100103649805],
            [-75.5134901098523, -10.756369978650051],
            [-75.51371998485253, -10.756429978650125],
            [-75.5144699848532, -10.756369978650051],
            [-75.51568998485435, -10.75502997864879],
            [-75.51719998485576, -10.7539599786478],
            [-75.52093998485925, -10.75430997864815],
            [-75.5219599848602, -10.753949978647796],
            [-75.52307998486124, -10.753669978647544],
            [-75.52370998486184, -10.7528899786468],
            [-75.52725998486513, -10.749499978643666],
            [-75.5287199848665, -10.748949978643145],
            [-75.52954998486727, -10.748609978642834],
            [-75.53061998486827, -10.746969978641284],
            [-75.53072998486836, -10.746009978640414],
            [-75.53083998486848, -10.74528997863973],
            [-75.53124998486886, -10.743469978638043],
            [-75.531419984869, -10.743289978637872],
            [-75.53321985987068, -10.743529978638094],
            [-75.53553998487284, -10.74273997863736],
            [-75.53629998487357, -10.74221997863689],
            [-75.53660998487383, -10.742109978636762],
            [-75.53712998487434, -10.742059978636712],
            [-75.53875998487585, -10.742289978636931],
            [-75.5419201098788, -10.741089978635813],
            [-75.5424799848793, -10.740969978635727],
            [-75.54414998488085, -10.741199978635937],
            [-75.54596998488253, -10.742459978637108],
            [-75.54839998488482, -10.744549978639036],
            [-75.54902998488541, -10.745049978639516],
            [-75.55117998488741, -10.745509978639944],
            [-75.55253998488867, -10.745199978639654],
            [-75.55501998489099, -10.744569978639078],
            [-75.55601998489193, -10.744259978638777],
            [-75.55677998489263, -10.744119978638645],
            [-75.55691998489276, -10.74406997863861],
            [-75.55743998489324, -10.743569978638137],
            [-75.55787998489366, -10.742329978636976],
            [-75.55814998489392, -10.74199997863667],
            [-75.55904998489474, -10.741619978636326],
            [-75.56004998489568, -10.740149978634955],
            [-75.56080998489638, -10.73650997863155],
            [-75.56228998489776, -10.733249978628526],
            [-75.5624499848979, -10.732299978627628],
            [-75.56305998489849, -10.730899978626335],
            [-75.5646899849, -10.729089978624641],
            [-75.56581998490104, -10.728669978624252],
            [-75.56776998490285, -10.727119978622822],
            [-75.57341835990812, -10.680632728579523],
            [-75.62341210995469, -10.622343603525229],
            [-75.71348611003859, -10.60628772851027],
            [-75.85676523517203, -10.554340478461924],
            [-75.93584536024572, -10.484709478397036],
            [-75.98549611029198, -10.48283560339531],
            [-75.92514998523576, -10.406759978324462],
            [-75.92440998523507, -10.356029978277215],
            [-75.85639998517172, -10.301709978226643],
            [-75.84012998515654, -10.258269978186165],
            [-75.78559998510573, -10.252370103180695],
            [-75.7039699850297, -10.206689978138124],
            [-75.70310998502893, -10.133039978069538],
            [-75.67489998500264, -10.047079977989508],
            [-75.60858998494088, -10.022869977966963],
            [-75.593660234927, -9.951791977900765],
            [-75.54864148488504, -9.983607977930394],
            [-75.47572885981715, -9.973958477921395],
            [-75.39565460974258, -9.913960477865512],
            [-75.36935598471807, -9.870832602825374],
            [-75.28793773464226, -9.870278102824837],
            [-75.27582160963097, -9.84976410280574],
            [-75.30306960965633, -9.783622102744145],
            [-75.2407506095983, -9.785835477746192],
            [-75.16012498452322, -9.839974602796618],
            [-75.10839848447505, -9.846253852802468],
            [-75.05988085942985, -9.819693352777726],
            [-74.99944210937356, -9.83845397779521],
            [-74.9431034843211, -9.761101602723155],
            [-74.89766798427878, -9.73711135270082],
            [-74.84935160923378, -9.748300727711243],
            [-74.80442573419195, -9.72982222769403],
            [-74.80703948419438, -9.686575727653741],
            [-74.73516998412742, -9.572706977547703],
            [-74.75052285914175, -9.524607102502921],
            [-74.7276992341205, -9.469968727452024],
            [-74.67710335907337, -9.42832422741325],
            [-74.58456060898718, -9.49562422747594],
            [-74.5755607339788, -9.570486352545625],
            [-74.49676348390541, -9.59334985256695],
            [-74.48555273389498, -9.702220727668326],
            [-74.4475814838596, -9.77995872774073],
            [-74.45020335886205, -9.870225977824777],
            [-74.41455185882884, -9.902111852854489],
            [-74.41232623382675, -9.95395510290276],
            [-74.42403460883767, -9.98944260293583],
            [-74.40060810881585, -10.070518978011332],
            [-74.36696673378452, -10.112896478050791],
            [-74.32775523374801, -10.109652728047791],
            [-74.3039374837258, -10.163594978098002],
            [-74.23166060865849, -10.185051603117996],
            [-74.22434198365168, -10.279230478205696],
            [-74.27671098370045, -10.37407422829402],
            [-74.2551796086804, -10.452479103367056],
            [-74.21873423364646, -10.4521128533667],
            [-74.18178835861201, -10.504043228415059],
            [-74.1326734835663, -10.604910853508994],
            [-74.16496035859635, -10.63844960354025],
            [-74.22985148365679, -10.635495353537497],
            [-74.28113473370456, -10.674858853574138],
            [-74.31730560873825, -10.726707228622416],
            [-74.37426910879131, -10.758109478651681],
            [-74.38568073380193, -10.811767353701642],
            [-74.48492223389438, -10.836456353724635],
            [-74.51004098391775, -10.88034960376551],
            [-74.5729951089764, -10.91260197879554],
            [-74.61410248401468, -10.904625478788121],
            [-74.64929823404745, -10.86707072875316],
            [-74.70017385909485, -10.877892728763243],
            [-74.73268560912513, -10.83572647872396],
            [-74.79180285918018, -10.831125228719678],
            [-74.83131098421698, -10.780504103672547],
            [-74.89330760927471, -10.769133228661927],
            [-74.95648160933355, -10.7327913536281],
            [-74.97925760935478, -10.746886853641232],
            [-75.0166587343896, -10.677134353576264],
            [-75.07431648444329, -10.6658104785657],
            [-75.11980498448567, -10.730220103625706],
            [-75.19101673455198, -10.715708353612175],
            [-75.24404998460138, -10.766949978659905],
            [-75.2623599846184, -10.825289978714236],
            [-75.31730423466959, -10.863567228749902],
            [-75.40637585975254, -10.856126478742958],
            [-75.45335010979628, -10.900295353784081],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 6,
        FIRST_IDPR: '1509',
        NOMBPROV: 'OYON',
        FIRST_NOMB: 'LIMA',
        LAST_DCTO: 'LEY',
        LAST_LEY: 'S/N',
        FIRST_FECH: 'EPOC.INDEP.',
        LAST_FECHA: '02/01/1857',
        MIN_SHAPE_: 187645.114944,
        ha: 187645.11,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.01096648624939, -10.695993103593766],
            [-76.95340836119546, -10.694613353592494],
            [-76.87947261112626, -10.656218728556757],
            [-76.86330861111112, -10.621822353524724],
            [-76.85720423610545, -10.49335872840507],
            [-76.82817298607826, -10.428980603345124],
            [-76.79989648605182, -10.419126978335964],
            [-76.77004098602389, -10.464798853378499],
            [-76.72505436098186, -10.485714728397976],
            [-76.70178823596011, -10.593544853498393],
            [-76.65565536091702, -10.646781853547987],
            [-76.65938073592048, -10.6882528535866],
            [-76.6334864858963, -10.78452847867625],
            [-76.58821161085403, -10.84505072873263],
            [-76.65420686091566, -10.88198247876703],
            [-76.69509573595386, -10.879273478764487],
            [-76.74311998599875, -10.934329853815775],
            [-76.79860998605061, -10.919069978801554],
            [-76.88923998613541, -10.84412997873174],
            [-76.96890023620998, -10.86390997875015],
            [-77.07763023631186, -11.012459978888474],
            [-77.13883998636928, -11.011229978887306],
            [-77.19423011142125, -10.957504478837242],
            [-77.16803998639668, -10.918539978800963],
            [-77.15649411138585, -10.84924410373645],
            [-77.17662111140473, -10.778074228670176],
            [-77.14028911137063, -10.75610347864972],
            [-77.03483611127176, -10.732751978627977],
            [-77.01096648624939, -10.695993103593766],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 5,
        FIRST_IDPR: '1307',
        NOMBPROV: 'PACASMAYO',
        FIRST_NOMB: 'LA LIBERTAD',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: 'EPOCA INDEP.',
        LAST_FECHA: 'EPOCA INDEP.',
        MIN_SHAPE_: 111825.46529,
        ha: 111825.47,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.18732936324247, -7.388664100504079],
            [-79.26767236330794, -7.489731100596527],
            [-79.31623198834725, -7.581806600681072],
            [-79.3584397383792, -7.559088850658973],
            [-79.3867803634013, -7.598589100694982],
            [-79.45482373845223, -7.638024600729803],
            [-79.5766113635327, -7.461509725561993],
            [-79.56953898852619, -7.389988225495791],
            [-79.59972261354326, -7.28574810039795],
            [-79.56108786351807, -7.288546850401847],
            [-79.54180548850435, -7.253925475370274],
            [-79.45879298844643, -7.225460975346243],
            [-79.40396673840843, -7.302349600419152],
            [-79.36927536338314, -7.324124975440243],
            [-79.25633411329738, -7.381863725496412],
            [-79.18732936324247, -7.388664100504079],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 4,
        FIRST_IDPR: '1008',
        NOMBPROV: 'PACHITEA',
        FIRST_NOMB: 'HUANUCO',
        LAST_DCTO: 'LEY',
        LAST_LEY: '2889',
        FIRST_FECH: '29/11/1918',
        LAST_FECHA: '29/11/1918',
        MIN_SHAPE_: 263590.903468,
        ha: 263590.9,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.98549611029198, -10.48283560339531],
            [-76.031669860335, -10.45592772837027],
            [-76.02595111032969, -10.426138728342499],
            [-76.04340348534592, -10.329727103252717],
            [-76.03937498534219, -10.236693353166073],
            [-76.06738086036829, -10.194599603126884],
            [-76.06381048536495, -10.1308378530675],
            [-76.04364448534616, -10.09904097803788],
            [-76.12531248542227, -10.066923853007975],
            [-76.13620898543243, -9.986281227932889],
            [-76.11720698541471, -9.903101602855397],
            [-76.08680861038638, -9.881322227835133],
            [-76.05857423536007, -9.821220727779146],
            [-75.87403323518814, -9.792919977752797],
            [-75.82456836014208, -9.83196097778914],
            [-75.79458986011413, -9.809890602768595],
            [-75.81035548512881, -9.76413472772601],
            [-75.79782811011715, -9.702785102668855],
            [-75.72125198504582, -9.617507852589421],
            [-75.71388286003894, -9.612500102584763],
            [-75.7114707350367, -9.690771477657671],
            [-75.7245448600489, -9.739999977703524],
            [-75.7162832350412, -9.802304727761538],
            [-75.75292573507531, -9.816982477775221],
            [-75.76140823508322, -9.856443352811972],
            [-75.65526560998434, -9.943765602893265],
            [-75.593660234927, -9.951791977900765],
            [-75.60858998494088, -10.022869977966963],
            [-75.67489998500264, -10.047079977989508],
            [-75.70310998502893, -10.133039978069538],
            [-75.7039699850297, -10.206689978138124],
            [-75.78559998510573, -10.252370103180695],
            [-75.84012998515654, -10.258269978186165],
            [-75.85639998517172, -10.301709978226643],
            [-75.92440998523507, -10.356029978277215],
            [-75.92514998523576, -10.406759978324462],
            [-75.98549611029198, -10.48283560339531],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 3,
        FIRST_IDPR: '2503',
        NOMBPROV: 'PADRE ABAD',
        FIRST_NOMB: 'UCAYALI',
        LAST_DCTO: 'LEY',
        LAST_LEY: '26429',
        FIRST_FECH: '01/06/1982',
        LAST_FECHA: '05/01/1995',
        MIN_SHAPE_: 945016.41555,
        ha: 945016.42,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.95364548433092, -8.858053476882146],
            [-74.93416410931276, -8.930224601949378],
            [-74.97885935935439, -8.95203922696969],
            [-75.04556248441652, -8.904216851925144],
            [-75.09436910946197, -8.922298851941994],
            [-75.14330273450754, -8.972193351988455],
            [-75.1509863595147, -9.038716852050399],
            [-75.18101560954267, -9.08099810208979],
            [-75.23758010959536, -9.106771477113776],
            [-75.21060160957022, -9.159136727162553],
            [-75.20971473456939, -9.229943352228487],
            [-75.26233010961839, -9.270367227266131],
            [-75.28272260963739, -9.309831977302883],
            [-75.32894135968041, -9.297029352290977],
            [-75.45210148479514, -9.296722602290691],
            [-75.49439060983453, -9.377355352365791],
            [-75.56244523489791, -9.400171852387029],
            [-75.62157223495299, -9.396734352383815],
            [-75.67083785999887, -9.396644477383749],
            [-75.73175786005561, -9.27226560226792],
            [-75.7941132351137, -9.188365227189772],
            [-75.80646486012519, -9.121486352127489],
            [-75.84439061016053, -9.0515058520623],
            [-75.87586136018986, -9.021458977034323],
            [-75.87435161018844, -8.970253851986643],
            [-75.91729686022845, -8.879238226901876],
            [-75.9216464852325, -8.780808601810225],
            [-75.94575011025493, -8.716138726749973],
            [-75.89185223520474, -8.661568226699165],
            [-75.83338711015027, -8.652288601690532],
            [-75.77963923510022, -8.547415351592848],
            [-75.77868111009931, -8.49385297654296],
            [-75.71647036004136, -8.487328476536883],
            [-75.70700961003253, -8.439324851492179],
            [-75.64813148497771, -8.409751976464642],
            [-75.59906298493203, -8.4119777264667],
            [-75.54662023488316, -8.466501976517472],
            [-75.44078998478462, -8.436833101489851],
            [-75.43532398477952, -8.39311297644913],
            [-75.50405885984354, -8.348841101407901],
            [-75.5028772348424, -8.289411851352565],
            [-75.45303885979602, -8.210448851279022],
            [-75.41573135976127, -8.24366422630994],
            [-75.33293935968415, -8.247763726313782],
            [-75.2710376096265, -8.263480726328417],
            [-75.21661073457581, -8.240421351306958],
            [-75.1426071095069, -8.272713851336995],
            [-75.08144973444995, -8.273900101338135],
            [-75.04035935941168, -8.312556601374114],
            [-75.00213660937608, -8.38891460144523],
            [-75.02946485940153, -8.417888726472217],
            [-74.99924410937336, -8.5051503515535],
            [-74.93970510931793, -8.597037226639072],
            [-74.9669648593433, -8.671355476708293],
            [-74.93228910931101, -8.729837851762749],
            [-74.92627748430543, -8.784378851813546],
            [-74.95364548433092, -8.858053476882146],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 7,
        FIRST_IDPR: '2005',
        NOMBPROV: 'PAITA',
        FIRST_NOMB: 'PIURA',
        LAST_DCTO: 'L.R.',
        LAST_LEY: '315',
        FIRST_FECH: 'EPOCA INDEP.',
        LAST_FECHA: '28/08/1920',
        MIN_SHAPE_: 170913.604632,
        ha: 170913.6,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.96149023817135, -5.311046848462039],
            [-80.9939511131289, -5.388460973527427],
            [-81.07816811296416, -5.313212848444672],
            [-81.10815036290373, -5.314746098440636],
            [-81.13812111283143, -5.263617223388739],
            [-81.19455261269259, -5.212119098331194],
            [-81.13316211281114, -5.070277348214411],
            [-81.09066986290361, -5.081738223232296],
            [-81.06021873795848, -5.039283098198896],
            [-81.08641598789535, -4.967333973129207],
            [-81.21055273758543, -4.816402347970434],
            [-80.96825786309073, -4.771792972969685],
            [-80.93192973815424, -4.804921848004936],
            [-80.95375586312618, -4.863189473055014],
            [-80.92429886317672, -4.893390598086655],
            [-80.85246873828174, -4.909433598110791],
            [-80.84816011330781, -5.093130848279253],
            [-80.85959761331469, -5.281361348449791],
            [-80.9392323631965, -5.237542973398404],
            [-81.0254218630533, -5.242789098389641],
            [-80.96149023817135, -5.311046848462039],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 11,
        FIRST_IDPR: '0215',
        NOMBPROV: 'PALLASCA',
        FIRST_NOMB: 'ANCASH',
        LAST_DCTO: 'LEY',
        LAST_LEY: '2971',
        FIRST_FECH: '02/01/1857',
        LAST_FECHA: '16/12/1918',
        MIN_SHAPE_: 207048.35143,
        ha: 207048.35,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.56581448677015, -8.237208976303696],
            [-77.63563473683584, -8.269212976333455],
            [-77.66629011186471, -8.312034601373314],
            [-77.70568123690177, -8.309384601370795],
            [-77.7459788619397, -8.363273601420945],
            [-77.79885936198949, -8.381187351437573],
            [-77.87688873706293, -8.398090851453192],
            [-77.87945511206539, -8.427865226480916],
            [-77.93371673711643, -8.454826226505938],
            [-77.99279886217211, -8.53241010157807],
            [-78.068173362243, -8.564176851607469],
            [-78.13048998730162, -8.61714160165665],
            [-78.1058027372785, -8.659820351696437],
            [-78.18470698735264, -8.694023476728054],
            [-78.23557823740028, -8.652919976689619],
            [-78.19352536236076, -8.60011135164059],
            [-78.21687873738257, -8.556917976600289],
            [-78.17365623734196, -8.50353710155071],
            [-78.13946873730977, -8.427831976480325],
            [-78.13749798730781, -8.368667976425256],
            [-78.11594998728752, -8.310099976370768],
            [-78.06646123724101, -8.277585476340612],
            [-78.01863473719604, -8.224316476291124],
            [-77.93321286211575, -8.197009726265852],
            [-77.89790423708247, -8.076015601153246],
            [-77.8104413620002, -8.067734976145642],
            [-77.71942973691459, -8.073445351151054],
            [-77.64736723684679, -8.049708976129017],
            [-77.63652023683662, -8.106191976181647],
            [-77.60568748680761, -8.154810601226943],
            [-77.55721673676204, -8.193732476263232],
            [-77.56581448677015, -8.237208976303696],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 5,
        FIRST_IDPR: '1104',
        NOMBPROV: 'PALPA',
        FIRST_NOMB: 'ICA',
        LAST_DCTO: 'LEY',
        LAST_LEY: '11969',
        FIRST_FECH: '16/01/1953',
        LAST_FECHA: '16/01/1953',
        MIN_SHAPE_: 121702.252076,
        ha: 121702.25,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.08008998444866, -14.56900998220066],
            [-75.07994910944855, -14.602311357231669],
            [-75.15484948451828, -14.677018107301262],
            [-75.18558398454694, -14.636894482263875],
            [-75.21876760957782, -14.646386857272725],
            [-75.23919535959685, -14.591021482221171],
            [-75.33029698468171, -14.61536323224384],
            [-75.3636874847128, -14.582089732212852],
            [-75.31685160966917, -14.530775357165071],
            [-75.30124998465466, -14.472537107110833],
            [-75.22791985958635, -14.395406232038992],
            [-75.2397891095974, -14.3207832319695],
            [-75.22616598458471, -14.12641410678849],
            [-75.23972073459736, -14.084046856749026],
            [-75.21055473457017, -14.050441356717757],
            [-75.16567998452838, -14.072529981738306],
            [-75.10884173447546, -14.032884731701415],
            [-75.05904998442908, -14.129619981791498],
            [-75.09079485945865, -14.21517773187115],
            [-75.0399791094113, -14.27787935692954],
            [-75.09392685946156, -14.31242448196173],
            [-75.13804898450265, -14.414655732056923],
            [-75.10126648446841, -14.43319910707419],
            [-75.09813773446548, -14.485097232122536],
            [-75.06909735943843, -14.526934357161505],
            [-75.08008998444866, -14.56900998220066],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 8,
        FIRST_IDPR: '0507',
        NOMBPROV: 'PARINACOCHAS',
        FIRST_NOMB: 'AYACUCHO',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: 'EPOCA INDEP.',
        LAST_FECHA: 'EPOCA INDEP.',
        MIN_SHAPE_: 587306.993338,
        ha: 587306.99,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.53162760800605, -14.507880357143739],
            [-73.53117235800562, -14.508448232144252],
            [-73.52385648299877, -14.511588232147176],
            [-73.52283673299782, -14.513788732149234],
            [-73.52199510799706, -14.51812723215327],
            [-73.52237185799738, -14.524459107159183],
            [-73.52224548299729, -14.526986107161518],
            [-73.51605610799149, -14.536246607170145],
            [-73.5089759829849, -14.541253232174789],
            [-73.50998635798582, -14.547454982180568],
            [-73.51537310799087, -14.56769135719943],
            [-73.51911910799437, -14.570671857202205],
            [-73.53337885800768, -14.58102148221184],
            [-73.53332648300763, -14.587073732217476],
            [-73.53085773300533, -14.593558357223515],
            [-73.52992398300445, -14.594923732224801],
            [-73.510151982986, -14.646261357272595],
            [-73.53574810800988, -14.673593732298055],
            [-73.53735510801137, -14.673407107297887],
            [-73.539079358013, -14.673278857297753],
            [-73.56337148303564, -14.681040482304981],
            [-73.57109898304286, -14.687271607310807],
            [-73.57210348304379, -14.688554732311982],
            [-73.5707148580425, -14.6926699823158],
            [-73.57049998304231, -14.693117232316233],
            [-73.57088085804267, -14.694130857317184],
            [-73.57428510804583, -14.694992232317986],
            [-73.57537885804685, -14.694923857317919],
            [-73.57459573304611, -14.695802732318725],
            [-73.57418948304574, -14.696201232319112],
            [-73.57360935804522, -14.697550732320357],
            [-73.57363873304523, -14.698103482320867],
            [-73.5750996080466, -14.699580107322243],
            [-73.57502148304651, -14.701414107323954],
            [-73.57471685804624, -14.701552732324105],
            [-73.57332223304493, -14.70302148232545],
            [-73.57155473304331, -14.706363232328581],
            [-73.57673248304812, -14.711685607333516],
            [-73.57677535804818, -14.71303123233477],
            [-73.57690435804831, -14.715509732337088],
            [-73.57570310804718, -14.718177732339566],
            [-73.57285348304448, -14.720531232341749],
            [-73.56949023304138, -14.71975585734105],
            [-73.57054885804236, -14.72544335734633],
            [-73.57089060804266, -14.725419982346315],
            [-73.57149023304324, -14.726794982347592],
            [-73.57074810804254, -14.728230482348948],
            [-73.5679316080399, -14.733433607353781],
            [-73.56585160803797, -14.73359573235393],
            [-73.56370510803598, -14.736861357356966],
            [-73.56280073303512, -14.742158232361906],
            [-73.56255073303491, -14.743601607363258],
            [-73.5625429830349, -14.74411323236372],
            [-73.56368948303594, -14.746244107365696],
            [-73.56449023303672, -14.747662107367024],
            [-73.56314260803545, -14.751449232370554],
            [-73.5623418580347, -14.753515607372494],
            [-73.56305460803536, -14.754783232373661],
            [-73.56408010803632, -14.760376982378862],
            [-73.563722608036, -14.764835982383023],
            [-73.56404885803627, -14.76604098238415],
            [-73.56441410803663, -14.768955107386864],
            [-73.56574998303789, -14.772408232390063],
            [-73.56269535803503, -14.780177732397314],
            [-73.56033598303283, -14.782597607399564],
            [-73.55891598303151, -14.783191357400115],
            [-73.55844335803107, -14.784474607401306],
            [-73.55721485802991, -14.78568560740243],
            [-73.55351948302646, -14.789355482405856],
            [-73.55368360802662, -14.79085935740726],
            [-73.55227535802533, -14.79516210741126],
            [-73.54901173302225, -14.801611357417276],
            [-73.54117185801496, -14.800501982416238],
            [-73.5345996080088, -14.798906232414753],
            [-73.53247848300684, -14.799923857415695],
            [-73.52981248300436, -14.800785107416505],
            [-73.52818748300282, -14.801703107417351],
            [-73.52695698300167, -14.803484357419011],
            [-73.52316598299814, -14.807517607422763],
            [-73.52218748299724, -14.808273482423479],
            [-73.5202051079954, -14.8106659824257],
            [-73.51999410799517, -14.811117232426115],
            [-73.52029885799546, -14.812042982426973],
            [-73.51980273299502, -14.81310160742797],
            [-73.51432035798987, -14.822181607436411],
            [-73.51484373299041, -14.824669982438728],
            [-73.51236723298805, -14.826328107440288],
            [-73.50783598298382, -14.825337857439361],
            [-73.50107223297753, -14.828263732442073],
            [-73.49994723297648, -14.828451232442259],
            [-73.49860348297523, -14.82937698244311],
            [-73.49736323297407, -14.831271482444883],
            [-73.49486135797173, -14.83463085744801],
            [-73.49240035796943, -14.83695310745018],
            [-73.49152348296862, -14.83754685745072],
            [-73.48632810796377, -14.841587857454497],
            [-73.48502735796255, -14.842570357455408],
            [-73.48016960795803, -14.841917607454803],
            [-73.47994460795779, -14.841703357454595],
            [-73.47613798295426, -14.840489607453469],
            [-73.47450823295274, -14.839909482452935],
            [-73.46827335794691, -14.838996982452088],
            [-73.46562110794446, -14.840259732453257],
            [-73.46069598293984, -14.829246482442995],
            [-73.45425948293382, -14.810738107425752],
            [-73.4524916079322, -14.802837607418406],
            [-73.44011373292062, -14.788061107404635],
            [-73.43788548291855, -14.784871482401666],
            [-73.43291785791392, -14.781818607398826],
            [-73.42482573290636, -14.77775060739504],
            [-73.4153393578975, -14.771192982388937],
            [-73.40454885788743, -14.76549623238361],
            [-73.40062348288374, -14.743925482363528],
            [-73.3843046078685, -14.70817973233023],
            [-73.37544473286025, -14.70110323232365],
            [-73.3705838578557, -14.694029107317055],
            [-73.37009423285525, -14.688158607311587],
            [-73.35636135784243, -14.686968732310477],
            [-73.35472848284088, -14.691962857315138],
            [-73.35220310783853, -14.701634732324159],
            [-73.3519433578383, -14.70265435732509],
            [-73.33770698282501, -14.710673857332557],
            [-73.32833010781624, -14.717361357338767],
            [-73.32173823281008, -14.716210982337717],
            [-73.28972660778017, -14.7327343573531],
            [-73.27750335776872, -14.72853110734919],
            [-73.27640898276772, -14.727584607348307],
            [-73.27305298276458, -14.723842232344817],
            [-73.27005573276176, -14.721312357342452],
            [-73.26721585775911, -14.71810035733945],
            [-73.25122410774418, -14.711387232333191],
            [-73.2390371077328, -14.711070357332897],
            [-73.23298323272711, -14.710433857332315],
            [-73.22737085772187, -14.708421982330446],
            [-73.22548573272012, -14.707479357329568],
            [-73.21987710771485, -14.703001982325395],
            [-73.21827473271337, -14.701611607324086],
            [-73.20229673269843, -14.704520232326786],
            [-73.19041160768731, -14.705774732327974],
            [-73.18800960768506, -14.703392107325758],
            [-73.18398748268129, -14.696898607319694],
            [-73.18183160767929, -14.691766857314914],
            [-73.17962598267722, -14.68818398231158],
            [-73.17222385767028, -14.685013732308622],
            [-73.14729485764697, -14.67777860730189],
            [-73.13338135763394, -14.680951607304834],
            [-73.1319567326326, -14.681526232305357],
            [-73.12352448262473, -14.681938357305748],
            [-73.12207185762335, -14.690257982313499],
            [-73.1211473576225, -14.691578607314723],
            [-73.10464023260705, -14.682994857306724],
            [-73.10675110760901, -14.676950107301092],
            [-73.10668710760895, -14.668864732293578],
            [-73.10444210760686, -14.667874232292641],
            [-73.09631810759923, -14.650481732276443],
            [-73.0784268575825, -14.656055232281629],
            [-73.07447073257879, -14.658318357283749],
            [-73.06937498257402, -14.661673732286857],
            [-73.06489648256984, -14.665566357290484],
            [-73.06312498256817, -14.66580473229072],
            [-73.05439235755998, -14.659884732285198],
            [-73.05220985755794, -14.659214732284559],
            [-73.04419148255042, -14.657813607283252],
            [-73.04039423254686, -14.649386357275413],
            [-73.04140348254782, -14.646952732273137],
            [-73.04361048254991, -14.625669357253338],
            [-73.03958548254612, -14.62433485725207],
            [-73.03436610754122, -14.62537010725303],
            [-73.03315835754009, -14.626319107253932],
            [-73.02203435752966, -14.627318982254861],
            [-73.0195061075273, -14.630610982257913],
            [-73.01466610752274, -14.630905857258181],
            [-73.01311998252132, -14.63267798225983],
            [-73.00121048251016, -14.658644357284007],
            [-72.99815798250731, -14.662616982287718],
            [-72.99596098250522, -14.662341857287455],
            [-72.99123910750082, -14.66061510728585],
            [-72.98593673249582, -14.660380482285625],
            [-72.98459935749456, -14.66000510728528],
            [-72.9830274824931, -14.655828857281387],
            [-72.97216010748294, -14.658021482283425],
            [-72.96502798247622, -14.666667982291484],
            [-72.95877060747037, -14.67719273230126],
            [-72.95150248246352, -14.685199232308722],
            [-72.95094648246304, -14.685386982308906],
            [-72.93443460744753, -14.695646857318446],
            [-72.93276310744595, -14.694385732317263],
            [-72.92127448243517, -14.685537982309022],
            [-72.91766985743182, -14.68536835730886],
            [-72.91223735742669, -14.696147232318895],
            [-72.89537048241087, -14.699224732321767],
            [-72.88539910740153, -14.705970107328032],
            [-72.8843459824005, -14.706392732328418],
            [-72.87440135739116, -14.708169607330085],
            [-72.87388098239067, -14.707973732329876],
            [-72.86563698238295, -14.710684107332396],
            [-72.85574223237366, -14.729164107349625],
            [-72.85428260737228, -14.731954232352196],
            [-72.85583698237373, -14.738699607358487],
            [-72.85200985737012, -14.748026857367185],
            [-72.84682460736528, -14.759823857378157],
            [-72.85107423236927, -14.7623348573805],
            [-72.85183710736997, -14.762714357380862],
            [-72.85267635737077, -14.763077732381195],
            [-72.8547134823727, -14.762963357381082],
            [-72.85663610737448, -14.762659982380814],
            [-72.85891723237664, -14.763816857381885],
            [-72.8627167323802, -14.763011982381135],
            [-72.86814123238527, -14.762743982380893],
            [-72.86970523238676, -14.761937107380145],
            [-72.8724059823893, -14.761472732379712],
            [-72.87256623238946, -14.761568107379802],
            [-72.8766479823933, -14.765451482383414],
            [-72.87757098239415, -14.765304607383282],
            [-72.87990573239635, -14.766169482384088],
            [-72.88020323239662, -14.76651760738442],
            [-72.88101960739739, -14.769503607387199],
            [-72.8818666073982, -14.770695732388312],
            [-72.88709260740308, -14.771959232389488],
            [-72.88789373240382, -14.771859107389394],
            [-72.88927460740514, -14.771409982388985],
            [-72.89600373241146, -14.77118585738877],
            [-72.89982610741504, -14.770548857388178],
            [-72.90850073242318, -14.770218857387873],
            [-72.91799910743211, -14.761648232379894],
            [-72.91828160743236, -14.760509482378854],
            [-72.91870885743276, -14.759497607377892],
            [-72.9299239824433, -14.75816635737667],
            [-72.932830857446, -14.761114107379413],
            [-72.93504335744808, -14.762762107380937],
            [-72.94084935745353, -14.764800107382843],
            [-72.95207973246406, -14.775205607392552],
            [-72.95338435746531, -14.77641960739367],
            [-72.95407873246593, -14.776809732394046],
            [-72.95567323246743, -14.779291107396343],
            [-72.96926885748019, -14.795148857411128],
            [-72.97087873248171, -14.796828232412683],
            [-72.97498323248554, -14.800236732415867],
            [-72.97675323248723, -14.803289357418711],
            [-72.97740935748783, -14.80436423241971],
            [-72.98563385749554, -14.804850607420157],
            [-72.99058535750018, -14.803999857419367],
            [-72.99180598250133, -14.803670857419064],
            [-72.99482723250418, -14.806168607421396],
            [-72.9971161075063, -14.81157973242644],
            [-72.99887848250796, -14.812788982427564],
            [-73.0030822325119, -14.816466232430985],
            [-73.00379185751257, -14.832489982445912],
            [-73.02044123252817, -14.85418648246612],
            [-73.03333010754027, -14.849925732462161],
            [-73.0343421075412, -14.848700732461026],
            [-73.0386027325452, -14.844120482456766],
            [-73.06118748256635, -14.825529357439443],
            [-73.06581448257069, -14.825359232439293],
            [-73.09186960759507, -14.838684232451712],
            [-73.10150635760411, -14.843681232456372],
            [-73.108870982611, -14.842949607455694],
            [-73.11363823261546, -14.843856607456544],
            [-73.11734473261895, -14.842552607455312],
            [-73.11871823262022, -14.842584607455343],
            [-73.1213769826227, -14.847402357459845],
            [-73.12676198262776, -14.853155357465202],
            [-73.12772848262864, -14.854431232466382],
            [-73.12792173262885, -14.857988107469696],
            [-73.1260659826271, -14.860462482472006],
            [-73.12521548262632, -14.861235732472725],
            [-73.1183722326199, -14.867885607478911],
            [-73.13561910763603, -14.888824607498421],
            [-73.14173785764177, -14.89638848250546],
            [-73.15725510765628, -14.906339732514729],
            [-73.16164035766037, -14.911188857519255],
            [-73.16585710766434, -14.912158732520163],
            [-73.16762798266599, -14.912032232520046],
            [-73.18668723268384, -14.910261232518396],
            [-73.19237985768913, -14.911230982519298],
            [-73.19343385769015, -14.91477298252259],
            [-73.1939398576906, -14.920591982528027],
            [-73.20085198269706, -14.922776107530057],
            [-73.23807635773188, -14.906148732514577],
            [-73.25314735774599, -14.899860982508724],
            [-73.2549982327477, -14.899753357508624],
            [-73.26471235775679, -14.906504857514914],
            [-73.26540123275741, -14.909329482517542],
            [-73.26542423275744, -14.910431857518574],
            [-73.26267248275487, -14.920083607527555],
            [-73.25673610774933, -14.924518982531694],
            [-73.25602348274866, -14.928214357535122],
            [-73.25816148275065, -14.9333352325399],
            [-73.25903260775146, -14.934417232540909],
            [-73.26531535775734, -14.963525107568012],
            [-73.26264035775485, -14.965695482570037],
            [-73.26538023275741, -14.974763357578476],
            [-73.27326810776478, -14.97710973258067],
            [-73.27746173276871, -14.978807107582256],
            [-73.28488723277565, -14.983741857586853],
            [-73.28930598277977, -14.98364498258675],
            [-73.29227323278253, -14.983515982586638],
            [-73.29640173278639, -14.981451857584712],
            [-73.30623898279559, -14.985773732588745],
            [-73.31014173279925, -14.986225357589166],
            [-73.3134316078023, -14.98670910758961],
            [-73.33291273282052, -15.00044910760241],
            [-73.33417060782169, -15.000932982602864],
            [-73.34148298282852, -15.002836357604632],
            [-73.34245310782943, -15.0035468576053],
            [-73.3412284828283, -15.006499982608041],
            [-73.34000198282715, -15.008359357609782],
            [-73.33833985782559, -15.012255857613399],
            [-73.3368554828242, -15.016099607616988],
            [-73.33593948282336, -15.023412107623793],
            [-73.333425732821, -15.026349607626537],
            [-73.33531235782277, -15.031643982631461],
            [-73.34133898282838, -15.035523232635079],
            [-73.34692685783361, -15.03783223263723],
            [-73.35359985783985, -15.036123482635636],
            [-73.37022485785536, -15.04055685763977],
            [-73.3777522328624, -15.04254260764162],
            [-73.40917573289174, -15.058238482656233],
            [-73.41004748289255, -15.057947857655977],
            [-73.41220623289459, -15.058695107656655],
            [-73.43101185791213, -15.063178607660843],
            [-73.43958198292015, -15.062392607660117],
            [-73.44359048292388, -15.060604732658453],
            [-73.44728510792733, -15.061517982659296],
            [-73.44807398292805, -15.061393482659186],
            [-73.45542185793491, -15.050516857649043],
            [-73.45832785793765, -15.044912607643834],
            [-73.49248085796953, -15.060701607658533],
            [-73.50481510798103, -15.056422482654558],
            [-73.5055391079817, -15.055962857654144],
            [-73.50637698298249, -15.05623635765438],
            [-73.51056635798639, -15.05817973265619],
            [-73.51198448298773, -15.058902357656871],
            [-73.51520123299073, -15.060427732658296],
            [-73.51596285799143, -15.06102960765885],
            [-73.52032223299551, -15.06108010765889],
            [-73.5265409830013, -15.05691998265503],
            [-73.53099810800545, -15.053351607651702],
            [-73.5333124830076, -15.055484357653679],
            [-73.53799610801198, -15.060017607657905],
            [-73.53860748301256, -15.059929732657837],
            [-73.53931448301321, -15.059456982657393],
            [-73.53974810801361, -15.058587857656574],
            [-73.54046685801428, -15.057064482655166],
            [-73.54182810801555, -15.05577335765396],
            [-73.54233398301602, -15.05567973265387],
            [-73.54352735801716, -15.055494857653702],
            [-73.5436894830173, -15.055505857653708],
            [-73.5449843580185, -15.055546857653756],
            [-73.54584373301931, -15.056689482654816],
            [-73.5456132330191, -15.059048857657007],
            [-73.54552335801901, -15.060687482658537],
            [-73.54476560801832, -15.065285107662818],
            [-73.54410548301769, -15.067054732664461],
            [-73.53679685801089, -15.083060607679366],
            [-73.53825785801222, -15.087769482683743],
            [-73.53826760801223, -15.090912107686668],
            [-73.53854298301249, -15.091289232687021],
            [-73.54623635801966, -15.093816357689382],
            [-73.54644535801988, -15.094113232689656],
            [-73.5462499830197, -15.098302732693563],
            [-73.54936323302259, -15.10330860769822],
            [-73.54966798302287, -15.104347607699193],
            [-73.54954098302278, -15.10685548270153],
            [-73.55085548302401, -15.110621107705033],
            [-73.5523672330254, -15.117402357711356],
            [-73.55285935802586, -15.121431607715106],
            [-73.55184960802491, -15.125189482718604],
            [-73.5496972330229, -15.124693357718138],
            [-73.54644135801986, -15.126335982719677],
            [-73.54438085801796, -15.127914107721137],
            [-73.54584960801931, -15.131824232724773],
            [-73.54837310802166, -15.134306607727094],
            [-73.54852735802181, -15.134408232727191],
            [-73.54812885802141, -15.134982482727718],
            [-73.54668748302011, -15.13609373272876],
            [-73.54676948302019, -15.136550732729184],
            [-73.54685948302027, -15.136660107729275],
            [-73.54730660802065, -15.13697848272958],
            [-73.54833985802163, -15.137603607730162],
            [-73.5484023580217, -15.138224607730745],
            [-73.54816798302149, -15.138960982731426],
            [-73.54607035801952, -15.144279357736375],
            [-73.54669335802011, -15.145091857737128],
            [-73.5479609830213, -15.14579098273778],
            [-73.55104498302418, -15.144140607736258],
            [-73.55179685802489, -15.143974607736089],
            [-73.55210548302517, -15.143994107736114],
            [-73.55277535802577, -15.144386732736468],
            [-73.55398048302692, -15.14962885774136],
            [-73.55306248302605, -15.153456982744915],
            [-73.5548378580277, -15.155642607746959],
            [-73.55470898302758, -15.15610348274739],
            [-73.55337498302634, -15.158374982749502],
            [-73.5523847330254, -15.158550732749665],
            [-73.55133198302443, -15.158949232750036],
            [-73.55105648302417, -15.159546857750582],
            [-73.54908985802231, -15.163675732754443],
            [-73.54887310802214, -15.164179732754906],
            [-73.54749223302086, -15.164751982755439],
            [-73.5469961080204, -15.16559760775623],
            [-73.54928910802252, -15.16950585775986],
            [-73.54938285802262, -15.170199232760515],
            [-73.54889260802214, -15.170695357760989],
            [-73.5484102330217, -15.17121685776147],
            [-73.5479766080213, -15.172501982762665],
            [-73.54784760802117, -15.174220732764264],
            [-73.54838473302168, -15.176427732766314],
            [-73.5481757330215, -15.176953107766808],
            [-73.54478323301832, -15.183085982772514],
            [-73.54454685801811, -15.184656232773971],
            [-73.54434373301793, -15.18488473277419],
            [-73.54130660801508, -15.185181607774464],
            [-73.53923248301314, -15.186212857775434],
            [-73.53662323301072, -15.19463873278328],
            [-73.53621285801032, -15.196169982784696],
            [-73.53645123301055, -15.197679732786098],
            [-73.53763085801165, -15.20406060779205],
            [-73.53753510801155, -15.208296857795991],
            [-73.53591998301005, -15.209902357797489],
            [-73.53593748301007, -15.210181607797754],
            [-73.53558785800973, -15.212624982800026],
            [-73.5370429830111, -15.21512698280236],
            [-73.53705860801111, -15.215507857802702],
            [-73.53454498300877, -15.216009732803172],
            [-73.53259760800695, -15.217773482804825],
            [-73.53229098300666, -15.217882857804927],
            [-73.52871023300334, -15.222929732809614],
            [-73.52339085799835, -15.22354548281019],
            [-73.52144110799654, -15.237912607823572],
            [-73.5180887329934, -15.245130482830284],
            [-73.51442848299, -15.252142982836823],
            [-73.51128148298706, -15.255050607839532],
            [-73.50635548298246, -15.261071232845135],
            [-73.50497010798115, -15.263824857847702],
            [-73.5043714829806, -15.2689729828525],
            [-73.5021166079785, -15.272873482856118],
            [-73.48427785796186, -15.281558982864219],
            [-73.48187273295962, -15.284699232867135],
            [-73.48167223295944, -15.286035357868379],
            [-73.48294160796063, -15.289810232871899],
            [-73.48294160796063, -15.290812607872825],
            [-73.47849860795645, -15.292015107873953],
            [-73.46987985794841, -15.29485460787659],
            [-73.46871073294734, -15.297994732879515],
            [-73.46640573294516, -15.301669357882949],
            [-73.46239698294144, -15.304508857885578],
            [-73.45457998293413, -15.311357107891963],
            [-73.45342223293308, -15.314416607894819],
            [-73.45302598293267, -15.31468910789506],
            [-73.44934248292928, -15.318001107898139],
            [-73.44853710792849, -15.318898607898983],
            [-73.44484398292506, -15.327223982906723],
            [-73.44064673292114, -15.348417357926472],
            [-73.44096073292143, -15.355057232932666],
            [-73.44110210792157, -15.355723732933285],
            [-73.44697585792703, -15.357982607935387],
            [-73.45661160793604, -15.364637357941573],
            [-73.48188023295963, -15.379525232955443],
            [-73.48374173296138, -15.379715857955626],
            [-73.48588560796337, -15.38019373295607],
            [-73.48940273296664, -15.381867357957633],
            [-73.49362185797058, -15.382230732957959],
            [-73.53663635801072, -15.397923482972592],
            [-73.54157260801533, -15.400896982975356],
            [-73.57463835804617, -15.407257107981286],
            [-73.58769985805834, -15.404820482979014],
            [-73.59410860806436, -15.403695107977962],
            [-73.59972385806957, -15.407066357981101],
            [-73.6035079830731, -15.411378857985119],
            [-73.62802123309598, -15.4200563579932],
            [-73.63011935809793, -15.418964357992186],
            [-73.64204410810906, -15.417789482991092],
            [-73.65161135811796, -15.416278857989692],
            [-73.65975185812556, -15.423498107996402],
            [-73.67452235813933, -15.431467108003819],
            [-73.69455723315802, -15.434776358006912],
            [-73.70432285816713, -15.423002232995955],
            [-73.71743010817936, -15.41420560798775],
            [-73.72684473318812, -15.409983607983829],
            [-73.75971985821877, -15.383140607958827],
            [-73.76285548322168, -15.376090107952269],
            [-73.76623535822483, -15.367715857944464],
            [-73.77205660823027, -15.354281482931958],
            [-73.77515410823317, -15.351242107929126],
            [-73.77931973323703, -15.346400357924606],
            [-73.77954860823725, -15.342740982921208],
            [-73.77645110823435, -15.340319607918946],
            [-73.77808385823587, -15.33874323291748],
            [-73.77988435823758, -15.338405607917167],
            [-73.78585048324314, -15.338011732916803],
            [-73.78928373324631, -15.338968232917695],
            [-73.79598998325258, -15.336660357915546],
            [-73.79857635825499, -15.33469010791371],
            [-73.79919435825558, -15.333788857912875],
            [-73.8009568582572, -15.32856660790801],
            [-73.81546785827075, -15.325529232905177],
            [-73.8251953582798, -15.325997357905615],
            [-73.86005398331228, -15.326117482905728],
            [-73.86296848331499, -15.325312482904986],
            [-73.864044233316, -15.324699357904418],
            [-73.8656539833175, -15.324163482903902],
            [-73.87093348332242, -15.33293623291209],
            [-73.87710573332818, -15.336231232915154],
            [-73.87936398333028, -15.337226857916075],
            [-73.88028710833115, -15.33738035791623],
            [-73.88407910833467, -15.33745673291629],
            [-73.88388823333449, -15.337916357916715],
            [-73.8748627333261, -15.355065357932698],
            [-73.87440485832568, -15.36406235794107],
            [-73.87081910832232, -15.379905732955827],
            [-73.87212373332355, -15.383621232959278],
            [-73.87518310832637, -15.391901982966989],
            [-73.8740082333253, -15.393531732968519],
            [-73.86977385832135, -15.401616107976043],
            [-73.87016298332169, -15.403767607978049],
            [-73.86879735832044, -15.417262982990609],
            [-73.86808010831977, -15.421436357994496],
            [-73.86951448332108, -15.428933108001473],
            [-73.87655635832766, -15.439152858010996],
            [-73.87686923332797, -15.438777983010644],
            [-73.87808985832909, -15.43808935801001],
            [-73.8874358583378, -15.438777983010644],
            [-73.89127348334137, -15.44456398301604],
            [-73.91477960836328, -15.458247233028771],
            [-73.92071535836881, -15.464829483034906],
            [-73.92729948337495, -15.472382483041935],
            [-73.93290710838018, -15.478101733047266],
            [-73.93485273338199, -15.480637608049628],
            [-73.93857573338545, -15.482525733051391],
            [-73.93986510838667, -15.482957858051792],
            [-73.94089510838762, -15.484251983053],
            [-73.94267273338929, -15.486734358055303],
            [-73.94618223339255, -15.49390985806198],
            [-73.95007323339615, -15.499752983067435],
            [-73.94989773339601, -15.501898733069417],
            [-73.94992823339605, -15.502988858070449],
            [-73.95147710839748, -15.505274733072564],
            [-73.95421598340003, -15.510927233077833],
            [-73.95872498340422, -15.519952733086239],
            [-73.96391298340906, -15.525852233091738],
            [-73.96849823341334, -15.53131485809682],
            [-73.97055810841525, -15.533656233099002],
            [-73.97261810841718, -15.534092858099411],
            [-73.9774551084217, -15.537214233102313],
            [-73.97801973342223, -15.537620483102694],
            [-73.98479460842852, -15.54092885810578],
            [-73.99191298343516, -15.547106608111534],
            [-73.99286648343603, -15.548411358112743],
            [-73.99387360843697, -15.549690233113935],
            [-73.99439235843745, -15.550391233114592],
            [-73.99532323343834, -15.55198385811607],
            [-74.00043485844309, -15.553952233117906],
            [-74.01117710845311, -15.564076483127337],
            [-74.01090998345285, -15.565029108128225],
            [-74.01083373345278, -15.569384608132273],
            [-74.01327510845505, -15.571353858134115],
            [-74.01486973345656, -15.582417483144424],
            [-74.01916498346054, -15.591490733152856],
            [-74.01837160845982, -15.593685108154904],
            [-74.01943210846079, -15.597582858158535],
            [-74.01943210846079, -15.598378233159277],
            [-74.02166748346286, -15.600383733161147],
            [-74.02313998346423, -15.60042098316118],
            [-74.02854160846928, -15.606561608166892],
            [-74.04992673348922, -15.619969358179386],
            [-74.05094910849014, -15.622281983181546],
            [-74.05232235849142, -15.623126858182319],
            [-74.05832673349703, -15.626461983185424],
            [-74.06060023349916, -15.628196733187044],
            [-74.0815887335187, -15.611681983171671],
            [-74.08196260851905, -15.610384983170459],
            [-74.08256535851962, -15.608255358168469],
            [-74.08358760852055, -15.597834608158768],
            [-74.08390823352087, -15.59667685815769],
            [-74.08460235852151, -15.59352685815476],
            [-74.08390823352087, -15.589961108151437],
            [-74.08089448351804, -15.582272483144282],
            [-74.07918548351645, -15.58097648314307],
            [-74.07881160851612, -15.575974483138422],
            [-74.07746885851485, -15.569305358132205],
            [-74.07701110851445, -15.567452483130483],
            [-74.07395173351159, -15.563053108126375],
            [-74.0740891085117, -15.561616858125054],
            [-74.07654573351398, -15.554808608118702],
            [-74.07654573351398, -15.552864108116891],
            [-74.07237248351011, -15.545870733110375],
            [-74.07205210850982, -15.544897983109474],
            [-74.06820673350623, -15.541285483106105],
            [-74.06816098350619, -15.537626233102696],
            [-74.07080073350866, -15.535264983100497],
            [-74.07126623350908, -15.53415310809947],
            [-74.07144923350926, -15.531096483096615],
            [-74.07149498350928, -15.529567733095185],
            [-74.0745086085121, -15.526094483091967],
            [-74.07626348351374, -15.522621108088726],
            [-74.07872010851601, -15.517017358083505],
            [-74.07779698351517, -15.51298810807975],
            [-74.07594298351343, -15.511088358077988],
            [-74.07464598351221, -15.509606358076612],
            [-74.07460023351219, -15.508587733075657],
            [-74.075477608513, -15.505577108072856],
            [-74.075477608513, -15.504605233071958],
            [-74.07441710851202, -15.502659858070132],
            [-74.0585022334972, -15.49814610806594],
            [-74.04512785848475, -15.492815983060956],
            [-74.04437260848404, -15.492308608060505],
            [-74.04303735848276, -15.489962608058319],
            [-74.04234310848214, -15.4884404830569],
            [-74.0386352334787, -15.48159035805052],
            [-74.03887935847891, -15.479539858048607],
            [-74.04094698348085, -15.474872608044265],
            [-74.04269410848246, -15.467931733037803],
            [-74.04241185848218, -15.466713858036657],
            [-74.04257198348233, -15.464806608034898],
            [-74.04171748348153, -15.46220873303247],
            [-74.03758235847769, -15.456623108027264],
            [-74.03729248347742, -15.456077608026764],
            [-74.03784185847795, -15.454154983024967],
            [-74.03847498347852, -15.450760858021816],
            [-74.04187010848167, -15.448463483019665],
            [-74.04553223348509, -15.443377483014931],
            [-74.05171198349088, -15.42476560799761],
            [-74.05373385849275, -15.417725607991038],
            [-74.05513760849406, -15.416220607989633],
            [-74.0562591084951, -15.4148902329884],
            [-74.06041723349897, -15.40754323298155],
            [-74.06189723350035, -15.406675357980747],
            [-74.06491848350318, -15.403035107977368],
            [-74.06542973350365, -15.402194982976575],
            [-74.07523348351278, -15.398823732973446],
            [-74.07775110851513, -15.398432732973067],
            [-74.08087923351802, -15.396698982971465],
            [-74.08166498351878, -15.395749107970586],
            [-74.11307523354802, -15.404983482979185],
            [-74.11496735854979, -15.409098607982997],
            [-74.11472323354957, -15.410816232984608],
            [-74.11500548354982, -15.411566732985316],
            [-74.12194823355628, -15.411347357985099],
            [-74.128074608562, -15.412535482986204],
            [-74.13723760857053, -15.424598732997442],
            [-74.146324108579, -15.426400982999121],
            [-74.1512603585836, -15.428750983001317],
            [-74.15374760858593, -15.440718608012451],
            [-74.15364835858584, -15.442526858014133],
            [-74.1517792335841, -15.447794858019044],
            [-74.15013123358253, -15.451024108022056],
            [-74.15969085859145, -15.472780233042315],
            [-74.1620941085937, -15.479524608048598],
            [-74.19374085862317, -15.491660108059891],
            [-74.19622798362549, -15.491357858059619],
            [-74.2043991086331, -15.488726608057169],
            [-74.234359608661, -15.485704483054354],
            [-74.2428393586689, -15.450401858021472],
            [-74.24382073366982, -15.443355858014911],
            [-74.24952160867514, -15.425989982998736],
            [-74.24991623367549, -15.424104357996987],
            [-74.25114410867664, -15.421999357995027],
            [-74.2531174836785, -15.418710482991962],
            [-74.2568039836819, -15.405282982979454],
            [-74.25717823368225, -15.403293982977607],
            [-74.25637885868153, -15.40209960797649],
            [-74.24910523367475, -15.3941677329691],
            [-74.24866060867433, -15.393325357968314],
            [-74.24470598367066, -15.3866562329621],
            [-74.242919483669, -15.384318607959926],
            [-74.24183398366799, -15.382033357957802],
            [-74.24040573366665, -15.380148107956053],
            [-74.23370410866039, -15.374359857950656],
            [-74.22149023364902, -15.361230732938434],
            [-74.2175147336453, -15.356442857933986],
            [-74.21413735864216, -15.353621357931338],
            [-74.21217098364035, -15.350842732928765],
            [-74.21105948363929, -15.348662482926722],
            [-74.20914110863752, -15.344681482923018],
            [-74.20983573363817, -15.342063107920582],
            [-74.20903423363741, -15.337788232916608],
            [-74.20534710863397, -15.333139232912272],
            [-74.2043958586331, -15.328912107908334],
            [-74.2045051086332, -15.328147732907624],
            [-74.2011741086301, -15.325663107905315],
            [-74.20103760862996, -15.323151232902966],
            [-74.20131060863021, -15.322004482901898],
            [-74.19593198362521, -15.319792982899843],
            [-74.19248785862202, -15.317365482897578],
            [-74.19163148362121, -15.314421982894842],
            [-74.19473548362409, -15.309712357890467],
            [-74.20260248363142, -15.29916960788064],
            [-74.20684485863536, -15.287853982870105],
            [-74.20628798363485, -15.287456357869731],
            [-74.20525373363388, -15.286660732868993],
            [-74.20505485863372, -15.285387857867802],
            [-74.2063277336349, -15.283359107865925],
            [-74.21285135864098, -15.271584857854949],
            [-74.2137662336418, -15.269556232853068],
            [-74.21666998364454, -15.265101107848906],
            [-74.21694848364477, -15.260685857844791],
            [-74.21762460864541, -15.256747857841138],
            [-74.21838048364613, -15.252014232836721],
            [-74.21929535864696, -15.24915023283406],
            [-74.22036935864797, -15.247479607832508],
            [-74.22207973364955, -15.244297357829542],
            [-74.22259810865005, -15.243437232828745],
            [-74.22397410865132, -15.236793232822553],
            [-74.22314448365056, -15.235544982821382],
            [-74.22227348364974, -15.235169982821027],
            [-74.21940623364706, -15.235376982821231],
            [-74.21668160864452, -15.234128857820068],
            [-74.21618360864407, -15.233880857819832],
            [-74.21283010864094, -15.233847607819799],
            [-74.21136910863957, -15.234429732820349],
            [-74.20338873363217, -15.234996107820876],
            [-74.2007324836297, -15.236341857822133],
            [-74.19619923362545, -15.23686135782261],
            [-74.19362885862306, -15.23551760782137],
            [-74.19326185862273, -15.23513673282101],
            [-74.18785748361769, -15.23002935781626],
            [-74.18647660861642, -15.228800732815104],
            [-74.1861523586161, -15.228255857814588],
            [-74.18313460861329, -15.225281607811821],
            [-74.18259573361279, -15.224990357811551],
            [-74.18094135861124, -15.223781232810431],
            [-74.17997848361036, -15.223341857810015],
            [-74.17770698360825, -15.223078107809782],
            [-74.17478123360551, -15.222949232809658],
            [-74.17419135860497, -15.222915982809631],
            [-74.16853910859969, -15.224437482811044],
            [-74.16143160859308, -15.228037232814387],
            [-74.15578910858783, -15.23010348281632],
            [-74.15367973358586, -15.229728482815979],
            [-74.14944535858191, -15.228654357814971],
            [-74.14625385857893, -15.22926173281553],
            [-74.14547073357821, -15.229593732815848],
            [-74.1433106085762, -15.230412107816612],
            [-74.14023248357333, -15.232331982818392],
            [-74.13931635857247, -15.232335982818405],
            [-74.13851560857174, -15.232669982818715],
            [-74.13738085857068, -15.233790982819762],
            [-74.13570123356911, -15.234283232820214],
            [-74.13446098356795, -15.234365232820284],
            [-74.13339448356696, -15.234447232820354],
            [-74.12828510856218, -15.234374982820297],
            [-74.12602935856009, -15.235681482821507],
            [-74.10730473354266, -15.23923248282482],
            [-74.10627148354169, -15.238181607823831],
            [-74.10524223354074, -15.237046857822778],
            [-74.09129498352775, -15.235144482821019],
            [-74.09061135852708, -15.235236357821098],
            [-74.08916598352575, -15.234876982820769],
            [-74.0836308585206, -15.232943357818955],
            [-74.07745123351485, -15.23097673281713],
            [-74.07555473351307, -15.23031635781652],
            [-74.07432423351192, -15.229380857815645],
            [-74.073335983511, -15.229060607815345],
            [-74.0720391085098, -15.228310607814654],
            [-74.07185160850962, -15.22801560781438],
            [-74.0549766084939, -15.215449232802673],
            [-74.05278323349187, -15.212982482800372],
            [-74.049699233489, -15.21126560779877],
            [-74.04824810848764, -15.21011135779771],
            [-74.04710760848657, -15.209119107796775],
            [-74.0457617334853, -15.206763857794584],
            [-74.04482035848443, -15.206201232794067],
            [-74.04137885848125, -15.200062482788347],
            [-74.03880473347883, -15.198078107786493],
            [-74.03683198347699, -15.1971249827856],
            [-74.03543160847568, -15.19657423278509],
            [-74.03434485847467, -15.19611398278468],
            [-74.03353310847393, -15.19590623278447],
            [-74.03297848347343, -15.195695357784285],
            [-74.03013085847077, -15.194130857782811],
            [-74.02956835847024, -15.192845732781624],
            [-74.02936323347004, -15.192607482781403],
            [-74.0277246084685, -15.19224223278106],
            [-74.02677935846762, -15.19110160778],
            [-74.02226173346345, -15.185935607775184],
            [-74.02100198346226, -15.182343732771846],
            [-74.02067385846195, -15.181376857770928],
            [-74.02027748346157, -15.180779357770382],
            [-74.01797660845945, -15.179632857769315],
            [-74.01692385845845, -15.178558607768315],
            [-74.01291798345471, -15.174626982764652],
            [-74.01152348345343, -15.174581982764616],
            [-74.00644135844867, -15.171728482761953],
            [-74.0048476084472, -15.170667982760973],
            [-74.0011016084437, -15.166839732757403],
            [-73.9984159834412, -15.16094535775192],
            [-73.99824610844105, -15.160894482751866],
            [-73.9967949834397, -15.16097660775194],
            [-73.98473635842848, -15.155218732746583],
            [-73.97010160841481, -15.147849607739722],
            [-73.96823635841311, -15.146050732738038],
            [-73.96373048340888, -15.144923857736986],
            [-73.95861323340412, -15.145607482737628],
            [-73.94378123339028, -15.138093732730631],
            [-73.94266798338927, -15.137683482730246],
            [-73.9409843583877, -15.137185607729782],
            [-73.93520698338232, -15.13585748272855],
            [-73.92753123337515, -15.132705107725622],
            [-73.92213285837013, -15.131140607724161],
            [-73.9170586083654, -15.131373107724382],
            [-73.9150214833635, -15.131333982724337],
            [-73.91221098336086, -15.130265607723336],
            [-73.9075156083565, -15.129281232722427],
            [-73.904831983354, -15.128554732721746],
            [-73.90400585835324, -15.128543107721738],
            [-73.9014999833509, -15.128986357722155],
            [-73.90029298334979, -15.129199232722344],
            [-73.8987288583483, -15.129333357722468],
            [-73.89780860834748, -15.129373107722513],
            [-73.89370710834365, -15.129131857722282],
            [-73.88834760833865, -15.128054732721292],
            [-73.8878554833382, -15.12802935772125],
            [-73.88590035833636, -15.12772460772097],
            [-73.88379098333439, -15.127806607721048],
            [-73.88183198333256, -15.12889648272206],
            [-73.8814357333322, -15.12918698272233],
            [-73.87747260832853, -15.13102935772405],
            [-73.87474410832598, -15.132874982725776],
            [-73.87282623332418, -15.134472607727254],
            [-73.872617233324, -15.134570357727352],
            [-73.87169735832312, -15.134776982727535],
            [-73.87028710832182, -15.13496098272771],
            [-73.86832035831999, -15.134765607727541],
            [-73.86560160831745, -15.132951232725835],
            [-73.86480860831672, -15.132599607725517],
            [-73.85986910831211, -15.132775357725693],
            [-73.8489277333019, -15.132470732725404],
            [-73.84672660829986, -15.132445232725367],
            [-73.84629498329946, -15.132761732725667],
            [-73.84070898329426, -15.13305073272594],
            [-73.83674998329056, -15.127781232721029],
            [-73.8364628582903, -15.126076232719436],
            [-73.83530073328922, -15.124949232718395],
            [-73.8347246082887, -15.12502148271846],
            [-73.83433598328831, -15.124861357718306],
            [-73.832915858287, -15.121847607715505],
            [-73.83266410828675, -15.121277357714968],
            [-73.83221285828633, -15.119919982713704],
            [-73.8323964832865, -15.117943357711857],
            [-73.83083598328504, -15.113277357707526],
            [-73.83029298328454, -15.109996107704474],
            [-73.83017385828443, -15.109435482703942],
            [-73.83074023328498, -15.10678710770147],
            [-73.830804733285, -15.10544335770022],
            [-73.83054885828479, -15.104810607699642],
            [-73.82726560828173, -15.103540982698453],
            [-73.82656248328108, -15.102824232697781],
            [-73.82611723328064, -15.101310732696371],
            [-73.82003335827498, -15.092831982688475],
            [-73.81987698327485, -15.092443357688124],
            [-73.8191034832741, -15.09159373268733],
            [-73.81712098327226, -15.09033198268615],
            [-73.81327935826869, -15.08739648268341],
            [-73.81149585826702, -15.08703123268308],
            [-73.80041810825671, -15.082982482679313],
            [-73.7984981082549, -15.080925732677395],
            [-73.79847848325487, -15.080113232676638],
            [-73.79700973325353, -15.075132857672],
            [-73.79556635825217, -15.072652357669693],
            [-73.79543948325205, -15.071285107668416],
            [-73.79550198325212, -15.07092773266808],
            [-73.7956972332523, -15.069339857666595],
            [-73.79577148325237, -15.069091857666379],
            [-73.79562310825223, -15.068040857665393],
            [-73.79562885825223, -15.067452982664838],
            [-73.79758985825406, -15.063621107661277],
            [-73.79875585825516, -15.062439482660173],
            [-73.79869535825507, -15.061027357658855],
            [-73.798609358255, -15.060701232658554],
            [-73.79845898325486, -15.056656107654783],
            [-73.79832810825476, -15.056119107654293],
            [-73.79837885825481, -15.055167982653396],
            [-73.79907623325543, -15.05225585765069],
            [-73.79992973325625, -15.049308607647946],
            [-73.80091410825716, -15.04651173264534],
            [-73.80588085826179, -15.041117232640316],
            [-73.80721873326303, -15.0395898576389],
            [-73.80775585826353, -15.038767607638134],
            [-73.80796873326375, -15.038529357637909],
            [-73.80953710826522, -15.035662107635243],
            [-73.80923248326492, -15.030644482630562],
            [-73.80799810826377, -15.027287107627442],
            [-73.80809373326387, -15.02608198262632],
            [-73.80827348326403, -15.024453232624802],
            [-73.80855273326429, -15.023367232623798],
            [-73.80917385826488, -15.017873107618666],
            [-73.81357223326897, -15.010427732611742],
            [-73.81251173326798, -15.005218732606895],
            [-73.81258985826804, -15.004142732605885],
            [-73.81396285826932, -14.996980607599216],
            [-73.81389060826925, -14.996447107598726],
            [-73.81521873327048, -14.99512885759749],
            [-73.8177637332729, -14.98787110759073],
            [-73.81707623327223, -14.985906232588905],
            [-73.81612110827132, -14.981208982584539],
            [-73.81420898326954, -14.980453107583827],
            [-73.81321885826863, -14.978884732582355],
            [-73.81342773326881, -14.977210982580804],
            [-73.8163066082715, -14.975677732579369],
            [-73.81910748327412, -14.972304732576236],
            [-73.8193944832744, -14.971996107575947],
            [-73.82110548327599, -14.968052732572273],
            [-73.82327148327799, -14.96664060757097],
            [-73.8259609832805, -14.966558607570894],
            [-73.82661135828111, -14.96656248257089],
            [-73.82820698328258, -14.965685732570064],
            [-73.83001173328428, -14.964126982568622],
            [-73.83013085828439, -14.962748107567341],
            [-73.83058598328482, -14.961343732566025],
            [-73.83220310828632, -14.96025973256503],
            [-73.83558198328947, -14.958894482563757],
            [-73.83794535829168, -14.953302732558539],
            [-73.84024023329383, -14.94776560755338],
            [-73.84090610829443, -14.941292982547361],
            [-73.84136523329488, -14.939572232545764],
            [-73.84185435829534, -14.938578982544827],
            [-73.8409671082945, -14.936390482542798],
            [-73.84176273329524, -14.932683232539338],
            [-73.84349098329685, -14.920533857528023],
            [-73.85291360830561, -14.91676498252452],
            [-73.8580253583104, -14.90920898251747],
            [-73.85806235831042, -14.908281232516616],
            [-73.85722848330964, -14.904374982512984],
            [-73.85804498331042, -14.903101607511797],
            [-73.86310935831513, -14.901544982510337],
            [-73.86376760831574, -14.90129885751012],
            [-73.86895898332058, -14.896306607505464],
            [-73.87661523332773, -14.894884732504146],
            [-73.87986523333075, -14.89047460750003],
            [-73.87917398333009, -14.883165982493237],
            [-73.87955273333046, -14.881609357491783],
            [-73.8795176083304, -14.880994107491201],
            [-73.88251948333323, -14.87313085748388],
            [-73.88384373333446, -14.871687482482535],
            [-73.8854121083359, -14.869228482480242],
            [-73.89074598334088, -14.864365232475713],
            [-73.89511723334496, -14.860546857472167],
            [-73.89646873334621, -14.858849607470589],
            [-73.8987929833484, -14.84751760746003],
            [-73.90014260834964, -14.842482482455331],
            [-73.89813085834777, -14.839890607452936],
            [-73.89875973334836, -14.837113232450347],
            [-73.89752348334719, -14.833343732446833],
            [-73.89733985834704, -14.832005857445573],
            [-73.89539848334522, -14.828466857442297],
            [-73.89568748334548, -14.826167982440154],
            [-73.89514448334498, -14.824880857438961],
            [-73.89476948334462, -14.822160107436419],
            [-73.89480660834465, -14.821724482436009],
            [-73.89459185834446, -14.820158232434549],
            [-73.8947461083446, -14.819388732433834],
            [-73.89489060834475, -14.81702735743164],
            [-73.89379685834373, -14.811718732426698],
            [-73.89354885834351, -14.810863232425897],
            [-73.89093948334107, -14.810689482425728],
            [-73.89003123334021, -14.808246107423459],
            [-73.88966410833987, -14.807613232422868],
            [-73.88521685833572, -14.804798857420252],
            [-73.88175198333252, -14.803812482419335],
            [-73.87932035833023, -14.803904357419412],
            [-73.8784062333294, -14.803554732419089],
            [-73.87809760832911, -14.803328107418883],
            [-73.87522260832641, -14.80253323241814],
            [-73.87226760832367, -14.80158010741725],
            [-73.87111723332258, -14.800396482416152],
            [-73.87095510832243, -14.798123107414026],
            [-73.87147460832291, -14.796791107412787],
            [-73.87112698332261, -14.795554732411642],
            [-73.87070898332222, -14.795388732411483],
            [-73.86897848332059, -14.793040982409304],
            [-73.87023048332178, -14.791165982407549],
            [-73.87141348332285, -14.789153232405678],
            [-73.87151423332297, -14.776857982394223],
            [-73.86975060832133, -14.775270732392746],
            [-73.86982623332138, -14.774288107391829],
            [-73.8705723583221, -14.767147107385187],
            [-73.86066223331285, -14.767421232385436],
            [-73.85829735831065, -14.766329857384422],
            [-73.85544710830797, -14.764207357382439],
            [-73.85478010830735, -14.762570107380933],
            [-73.85544710830797, -14.756627357375386],
            [-73.85077785830364, -14.7549294823738],
            [-73.83895960829264, -14.740567232360434],
            [-73.83115173328535, -14.732039732352494],
            [-73.82571860828027, -14.725922982346793],
            [-73.82561073328019, -14.725563232346452],
            [-73.8358292332897, -14.715668482337241],
            [-73.83611710828997, -14.713797482335515],
            [-73.8363689832902, -14.707069107329245],
            [-73.83611710828997, -14.704694357327023],
            [-73.83023985828449, -14.688072357311542],
            [-73.82782235828225, -14.684755357308472],
            [-73.82220035827702, -14.679189482303261],
            [-73.82141335827627, -14.678402482302557],
            [-73.82298748327774, -14.674185982298617],
            [-73.821132233276, -14.64534510727175],
            [-73.81113060826667, -14.638490357265383],
            [-73.80651085826238, -14.641465982268144],
            [-73.80518635826115, -14.642718982269301],
            [-73.79684523325336, -14.64533223227174],
            [-73.79179735824867, -14.64440148227088],
            [-73.7904369832474, -14.642790482269373],
            [-73.78491373324223, -14.639864607266649],
            [-73.78368160824111, -14.639953107266741],
            [-73.78326160824072, -14.640193357266963],
            [-73.78158010823914, -14.638363232265254],
            [-73.77524810823323, -14.642865232269436],
            [-73.77433198323237, -14.643374982269906],
            [-73.76097660821995, -14.646677732272998],
            [-73.75541010821473, -14.647517607273764],
            [-73.75643360821569, -14.644585982271037],
            [-73.75616010821544, -14.644185607270662],
            [-73.75480860821418, -14.64256448226917],
            [-73.75408985821349, -14.641333982268023],
            [-73.75480860821418, -14.639292982266122],
            [-73.75293360821244, -14.63656248226358],
            [-73.74957223320929, -14.638033232264958],
            [-73.7473203582072, -14.640283232267029],
            [-73.74873260820853, -14.636562482263576],
            [-73.74838748320818, -14.635101607262225],
            [-73.73734185819791, -14.638062482264962],
            [-73.72511135818651, -14.63815235726506],
            [-73.72445123318589, -14.638482482265351],
            [-73.72268773318423, -14.640456607267206],
            [-73.72078910818247, -14.64244535726906],
            [-73.71444735817656, -14.640077232266842],
            [-73.71276923317498, -14.637735232264655],
            [-73.70958010817202, -14.635751982262809],
            [-73.70898048317147, -14.635210982262308],
            [-73.70294723316583, -14.634402357261571],
            [-73.69976560816288, -14.63323048226047],
            [-73.69892573316208, -14.631609232258944],
            [-73.69850585816168, -14.631130857258515],
            [-73.69233785815592, -14.629839857257313],
            [-73.6774883581421, -14.622623982250593],
            [-73.63770160810499, -14.593007107223007],
            [-73.6346938581022, -14.590901732221035],
            [-73.62378823309201, -14.572710482204096],
            [-73.61902935808757, -14.566047982197897],
            [-73.61486260808368, -14.55735510718981],
            [-73.611968483081, -14.552234732185047],
            [-73.60907435807827, -14.548605857181656],
            [-73.60756048307688, -14.545555857178819],
            [-73.60756048307688, -14.544843482178162],
            [-73.60740473307673, -14.543819357177208],
            [-73.60654998307594, -14.543042857176463],
            [-73.60211785807181, -14.541729607175254],
            [-73.6005183580703, -14.541942232175456],
            [-73.59678573306684, -14.543972232177351],
            [-73.59148135806187, -14.548294232181364],
            [-73.58545673305626, -14.547115482180272],
            [-73.57766398304898, -14.538864357172576],
            [-73.57655085804797, -14.537489232171325],
            [-73.56793948303991, -14.526048982160637],
            [-73.56446085803665, -14.520893982155858],
            [-73.56304098303534, -14.520841857155808],
            [-73.56094035803338, -14.5224446071573],
            [-73.56022785803273, -14.522360857157208],
            [-73.55859335803119, -14.521774107156663],
            [-73.55540823302822, -14.518002232153155],
            [-73.55222260802525, -14.516727107151958],
            [-73.55092598302404, -14.514728232150103],
            [-73.54309235801674, -14.50889348214466],
            [-73.53655535801063, -14.508137107143979],
            [-73.53396223300821, -14.507218607143118],
            [-73.53162760800605, -14.507880357143739],
          ],
          [
            [-73.69029994798584, -15.329677673848092],
            [-73.64715998311381, -15.277134607860118],
            [-73.73905985819951, -15.265740482849507],
            [-73.72322685818473, -15.321027357900961],
            [-73.69029994798584, -15.329677673848092],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 9,
        FIRST_IDPR: '0810',
        NOMBPROV: 'PARURO',
        FIRST_NOMB: 'CUSCO',
        LAST_DCTO: 'LEY',
        LAST_LEY: '13203',
        FIRST_FECH: '02/01/1857',
        LAST_FECHA: '15/04/1959',
        MIN_SHAPE_: 197018.34791,
        ha: 197018.35,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.22867410678134, -13.714055356403511],
            [-72.16785310672347, -13.649528481343275],
            [-72.13220698168949, -13.639304731333665],
            [-72.09136723165051, -13.64916210634272],
            [-72.02364710658583, -13.613942231309704],
            [-71.94786248151324, -13.617410106312608],
            [-71.91613110648284, -13.593687856290414],
            [-71.84315885641263, -13.63079473132457],
            [-71.78976173136108, -13.698355481387182],
            [-71.8344422314039, -13.811415356492661],
            [-71.8010137313716, -13.851103481529433],
            [-71.80913673137937, -13.894519481569873],
            [-71.72938285630211, -13.975175731644455],
            [-71.69970898127299, -14.116285106775605],
            [-71.6768418562505, -14.222841856874588],
            [-71.6926601062657, -14.278734356926714],
            [-71.7484922313199, -14.266109356915388],
            [-71.7637159813348, -14.192424981846916],
            [-71.87699410644417, -14.200259731854871],
            [-71.90855073147465, -14.149336481807643],
            [-71.91022298147641, -14.059352356723892],
            [-71.96803898153209, -13.960337231631986],
            [-72.0556757316161, -13.934853481608597],
            [-72.10473048166307, -13.856078106535403],
            [-72.13533210669235, -13.783064731467512],
            [-72.16635935672194, -13.747415106434426],
            [-72.22867410678134, -13.714055356403511],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 13,
        FIRST_IDPR: '1901',
        NOMBPROV: 'PASCO',
        FIRST_NOMB: 'PASCO',
        LAST_DCTO: 'LEY',
        LAST_LEY: '12292',
        FIRST_FECH: '27/11/1944',
        LAST_FECHA: '15/04/1955',
        MIN_SHAPE_: 475013.378042,
        ha: 475013.38,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.031669860335, -10.45592772837027],
            [-75.98549611029198, -10.48283560339531],
            [-75.93584536024572, -10.484709478397036],
            [-75.85676523517203, -10.554340478461924],
            [-75.71348611003859, -10.60628772851027],
            [-75.62341210995469, -10.622343603525229],
            [-75.57341835990812, -10.680632728579523],
            [-75.56776998490285, -10.727119978622822],
            [-75.5683542349034, -10.727441353623108],
            [-75.56842548490347, -10.727946978623585],
            [-75.56832335990337, -10.728457728624049],
            [-75.56874898490375, -10.730364228625833],
            [-75.56825523490329, -10.731709103627084],
            [-75.5681304849032, -10.732642603627967],
            [-75.56876598490378, -10.733981728629207],
            [-75.56949223490447, -10.736387603631446],
            [-75.5705136099054, -10.7379537286329],
            [-75.57118698490603, -10.739667353634506],
            [-75.57164098490647, -10.740938603635675],
            [-75.57201448490679, -10.741915228636607],
            [-75.57315410990789, -10.74296622863759],
            [-75.57389785990857, -10.744416228638926],
            [-75.5738221099085, -10.746796103641138],
            [-75.57430460990894, -10.749203353643374],
            [-75.57452373490916, -10.750110728644216],
            [-75.57514948490974, -10.752676478646617],
            [-75.57549373491007, -10.753615103647487],
            [-75.57536848490993, -10.75849610365202],
            [-75.57571260991027, -10.759309603652783],
            [-75.57467998490931, -10.76162497865493],
            [-75.57417798490883, -10.763067228656302],
            [-75.57393735990861, -10.76569697865875],
            [-75.57386860990853, -10.767879603660756],
            [-75.57404048490869, -10.76896235366177],
            [-75.5742467349089, -10.769821853662584],
            [-75.57452185990915, -10.770887228663575],
            [-75.57546698491001, -10.772073103664669],
            [-75.57606848491058, -10.772657353665217],
            [-75.57636073491085, -10.773602728666111],
            [-75.57603635991056, -10.775879728668233],
            [-75.57619373491072, -10.776351853668672],
            [-75.57691060991137, -10.777173728669432],
            [-75.5768406099113, -10.777945978670141],
            [-75.5766074849111, -10.779251478671371],
            [-75.57656085991104, -10.780184103672235],
            [-75.57728360991172, -10.783028478674881],
            [-75.57958335991385, -10.78843310367992],
            [-75.58044285991467, -10.790796478682127],
            [-75.58183935991597, -10.792783853683973],
            [-75.58387585991784, -10.800797103691423],
            [-75.58739598492114, -10.804317228694721],
            [-75.58933273492293, -10.808818228698895],
            [-75.58999298492358, -10.811273853701172],
            [-75.59069373492422, -10.81156122870146],
            [-75.59313648492649, -10.811018103700944],
            [-75.594760234928, -10.811171853701103],
            [-75.59714160993022, -10.811721728701592],
            [-75.59834948493136, -10.81242810370227],
            [-75.6037548599364, -10.816899603706418],
            [-75.60406935993669, -10.8177378537072],
            [-75.60523160993775, -10.820837103710085],
            [-75.60598360993848, -10.82259185371172],
            [-75.61000860994221, -10.823564978712625],
            [-75.61514935994698, -10.822463353711607],
            [-75.61923085995079, -10.823582978712645],
            [-75.62219248495354, -10.823849353712893],
            [-75.62327598495456, -10.824075103713122],
            [-75.62679760995786, -10.824662103713651],
            [-75.62851335995944, -10.824842603713817],
            [-75.63239585996304, -10.825745603714658],
            [-75.636639859967, -10.827054978715898],
            [-75.64085060997093, -10.82678610371561],
            [-75.64147810997152, -10.826618728715495],
            [-75.65139998498077, -10.825259978714204],
            [-75.65501998498412, -10.825309978714275],
            [-75.65730010998625, -10.825759978714677],
            [-75.65928998498809, -10.82684997871571],
            [-75.66062960998937, -10.8270703537159],
            [-75.66167998499033, -10.82735060371615],
            [-75.66386998499236, -10.827709978716484],
            [-75.66552998499392, -10.828529978717262],
            [-75.66609998499445, -10.828579978717304],
            [-75.66696998499526, -10.829599978718253],
            [-75.66742123499567, -10.830356728718952],
            [-75.6679799849962, -10.83088997871946],
            [-75.66832998499652, -10.8310301037196],
            [-75.6691599849973, -10.831479978720013],
            [-75.66985110999795, -10.831952103720447],
            [-75.67112998499914, -10.832799978721225],
            [-75.67171960999967, -10.834658728722948],
            [-75.67230948500023, -10.836223478724433],
            [-75.67231673500024, -10.83625860372444],
            [-75.67256998500046, -10.836569978724752],
            [-75.6735698600014, -10.83732997872544],
            [-75.6741099850019, -10.837649978725755],
            [-75.67444998500224, -10.8377099787258],
            [-75.67545998500316, -10.83785997872596],
            [-75.67641998500405, -10.838629978726656],
            [-75.67765998500522, -10.83919997872721],
            [-75.68041623500777, -10.839282978727285],
            [-75.68416398501128, -10.838111228726175],
            [-75.69132773501794, -10.836565978724728],
            [-75.69217273501874, -10.836130728724337],
            [-75.6934359850199, -10.833703978722074],
            [-75.69329098501979, -10.832968728721394],
            [-75.69077648501744, -10.830213228718822],
            [-75.68995686001668, -10.823027103712116],
            [-75.69090673501756, -10.819478978708815],
            [-75.69674561002299, -10.816936603706464],
            [-75.70245923502831, -10.81673160370627],
            [-75.70475873503045, -10.815543228705163],
            [-75.70605636003165, -10.815325353704955],
            [-75.70638936003198, -10.81537997870502],
            [-75.70840186003386, -10.815744478705344],
            [-75.70905486003444, -10.815463228705074],
            [-75.70952586003489, -10.81473747870442],
            [-75.71018448503551, -10.814530853704216],
            [-75.71077686003603, -10.814510353704204],
            [-75.71410586003915, -10.813652478703407],
            [-75.7166149850415, -10.811903978701768],
            [-75.71872986004348, -10.810123228700126],
            [-75.71901673504374, -10.809830228699838],
            [-75.7201597350448, -10.809011103699076],
            [-75.72135286004593, -10.808793228698876],
            [-75.72224098504674, -10.808775103698846],
            [-75.72350961004791, -10.80853947869863],
            [-75.72393361004829, -10.8084906036986],
            [-75.72542911004969, -10.808719853698818],
            [-75.72746798505162, -10.809143603699196],
            [-75.7286384850527, -10.809373103699423],
            [-75.73237186005616, -10.80919197869926],
            [-75.73320573505694, -10.809409353699444],
            [-75.73353998505726, -10.810139978700132],
            [-75.73355998505727, -10.811019978700948],
            [-75.734329985058, -10.812579978702415],
            [-75.73539998505898, -10.814609978704286],
            [-75.73718998506067, -10.815629978705239],
            [-75.73777998506122, -10.81612997870571],
            [-75.74202998506517, -10.821149978710386],
            [-75.74310998506618, -10.82253997871169],
            [-75.74701998506981, -10.823709978712763],
            [-75.74761998507039, -10.824209978713245],
            [-75.75021986007282, -10.825279978714228],
            [-75.75067998507323, -10.825389978714329],
            [-75.7524599850749, -10.826849978715703],
            [-75.7534599850758, -10.827419978716216],
            [-75.75512998507736, -10.828599978717339],
            [-75.75772998507978, -10.829719978718375],
            [-75.75964998508158, -10.83016010371878],
            [-75.75970986008164, -10.830189978718813],
            [-75.76057986008247, -10.829829978718468],
            [-75.76136998508318, -10.830099978718719],
            [-75.76211998508387, -10.830359978718969],
            [-75.76293998508464, -10.829989978718627],
            [-75.76528998508684, -10.828019978716778],
            [-75.76571986008726, -10.827859978716651],
            [-75.76735998508875, -10.827569853716374],
            [-75.76849998508983, -10.827329978716143],
            [-75.7687899850901, -10.827169978715995],
            [-75.7700698600913, -10.826449978715322],
            [-75.77155998509268, -10.826419978715284],
            [-75.77268998509375, -10.82592997871483],
            [-75.77368998509466, -10.825949978714851],
            [-75.7759999850968, -10.825689978714621],
            [-75.77782998509852, -10.825859978714766],
            [-75.77880998509943, -10.825509978714457],
            [-75.78020998510074, -10.825309978714273],
            [-75.78063998510112, -10.82532997871427],
            [-75.78256998510292, -10.825319978714278],
            [-75.78459998510482, -10.82494997871393],
            [-75.78548998510564, -10.824639978713641],
            [-75.78598998510611, -10.824559978713546],
            [-75.78661998510671, -10.824809978713803],
            [-75.78913998510906, -10.825269978714225],
            [-75.78988998510975, -10.826619978715485],
            [-75.79092998511074, -10.827439978716257],
            [-75.7920899851118, -10.828019978716782],
            [-75.79789673511722, -10.832651603721098],
            [-75.80046011011959, -10.834645478722958],
            [-75.80254873512152, -10.83600622872421],
            [-75.80526548512408, -10.837986353726045],
            [-75.80930948512784, -10.842083228729878],
            [-75.81233736013064, -10.84458022873221],
            [-75.81622848513429, -10.848482603735853],
            [-75.81729111013527, -10.850906103738083],
            [-75.8181694851361, -10.861418978747885],
            [-75.8179606101359, -10.862374103748776],
            [-75.8150758601332, -10.866301103752443],
            [-75.81452998513268, -10.86998622875587],
            [-75.8150568601332, -10.871315228757103],
            [-75.81524323513337, -10.871888853757644],
            [-75.8174253601354, -10.873315728758962],
            [-75.82732798514463, -10.875189103760706],
            [-75.82856073514579, -10.8753974787609],
            [-75.82909911014627, -10.875623103761106],
            [-75.8322342351492, -10.879297978764521],
            [-75.83283361014976, -10.880224478765399],
            [-75.8369679851536, -10.886036728770824],
            [-75.83964298515609, -10.885627228770431],
            [-75.84568423516173, -10.88144435376653],
            [-75.84670773516267, -10.881180228766281],
            [-75.8493156101651, -10.880883103766022],
            [-75.84979423516553, -10.880425103765583],
            [-75.850264610166, -10.877033228762427],
            [-75.85043798516615, -10.87591910376139],
            [-75.85138911016703, -10.873488228759122],
            [-75.85199236016759, -10.873006228758662],
            [-75.85396998516944, -10.87189872875766],
            [-75.85505586017047, -10.870874728756688],
            [-75.855633110171, -10.870323478756179],
            [-75.85825936017342, -10.867856978753881],
            [-75.86115286017612, -10.867938978753962],
            [-75.86180961017675, -10.870876228756691],
            [-75.86176323517671, -10.871603603757357],
            [-75.86225648517717, -10.872082103757801],
            [-75.86294886017782, -10.872604853758297],
            [-75.8639086101787, -10.875312228760835],
            [-75.86402536017879, -10.875606978761114],
            [-75.86478186017949, -10.876585978762026],
            [-75.86641736018102, -10.875995478761476],
            [-75.86764761018217, -10.875360853760869],
            [-75.86784836018235, -10.87526860376078],
            [-75.8686207351831, -10.874927853760473],
            [-75.86959736018399, -10.874882478760448],
            [-75.8698018601842, -10.8745417287601],
            [-75.87032423518467, -10.872815353758503],
            [-75.87039248518472, -10.871316228757102],
            [-75.87080123518511, -10.86995335375585],
            [-75.87280036018699, -10.86824972875425],
            [-75.87404948518815, -10.867681853753712],
            [-75.88031873519398, -10.869044728754993],
            [-75.88132823519494, -10.869663853755554],
            [-75.88287311019637, -10.86990147875578],
            [-75.88963561020269, -10.869147103755086],
            [-75.89064361020361, -10.869767353755671],
            [-75.89281448520563, -10.870246103756092],
            [-75.89532148520799, -10.8691471037551],
            [-75.8960192352086, -10.868940353754871],
            [-75.89967686021203, -10.869631103755522],
            [-75.9002834852126, -10.869767353755666],
            [-75.90224973521441, -10.86987197875575],
            [-75.90401798521606, -10.870235228756082],
            [-75.90622261021814, -10.87101122875682],
            [-75.90714273521897, -10.871276853757085],
            [-75.9080389852198, -10.871446353757214],
            [-75.91043698522205, -10.87205197875778],
            [-75.91130198522285, -10.872208353757934],
            [-75.91316023522458, -10.872001103757759],
            [-75.91339686022481, -10.872201228757921],
            [-75.91638136022758, -10.873902853759509],
            [-75.91776448522887, -10.87528572876081],
            [-75.91914761023014, -10.880065978765256],
            [-75.91960861023058, -10.880988103766095],
            [-75.9227519852335, -10.8831464787681],
            [-75.92877061023914, -10.882948853767939],
            [-75.93713673524692, -10.886592853771338],
            [-75.93834473524804, -10.882476103767486],
            [-75.9385106102482, -10.881395978766502],
            [-75.93848111024816, -10.87888947876416],
            [-75.93740973524719, -10.875435353760937],
            [-75.93717836024696, -10.872662353758356],
            [-75.94034411024991, -10.869975978755855],
            [-75.94941323525835, -10.864698103750946],
            [-75.95156211026035, -10.863210478749552],
            [-75.95175098526053, -10.862856228749227],
            [-75.95250661026125, -10.861439478747902],
            [-75.95367311026233, -10.8602518537468],
            [-75.95476873526333, -10.859552728746145],
            [-75.95573211026424, -10.858778228745427],
            [-75.95743236026583, -10.854692228741609],
            [-75.9580669852664, -10.85384585374084],
            [-75.95823323526659, -10.852848478739906],
            [-75.95818798526652, -10.850188603737426],
            [-75.95832398526667, -10.849055228736383],
            [-75.95873198526702, -10.847755603735155],
            [-75.95949261026773, -10.846579353734056],
            [-75.96066136026882, -10.845289728732865],
            [-75.96108448526921, -10.844785978732398],
            [-75.97110773527857, -10.842578353730334],
            [-75.97255973527993, -10.840744228728617],
            [-75.97316086028047, -10.839377103727369],
            [-75.97365936028095, -10.838522228726559],
            [-75.9753154852825, -10.8362073537244],
            [-75.97645523528355, -10.834925228723217],
            [-75.9788768602858, -10.832147228720633],
            [-75.98144111028819, -10.830562353719158],
            [-75.9848779852914, -10.82794472871672],
            [-75.99032111029646, -10.823477978712567],
            [-75.99150823529757, -10.822219603711371],
            [-75.99267161029866, -10.820984978710243],
            [-75.99328898529923, -10.8208426037101],
            [-75.9957107353015, -10.821242228710476],
            [-75.99650223530223, -10.821685478710881],
            [-75.99726198530296, -10.823204978712312],
            [-75.99855986030416, -10.825136103714103],
            [-75.99909811030464, -10.82573760371465],
            [-76.00137748530678, -10.826497353715357],
            [-76.00330848530858, -10.827193853716004],
            [-76.00400498530924, -10.827357353716172],
            [-76.0066642353117, -10.828708103717414],
            [-76.00894361031384, -10.833604478721984],
            [-76.0084792353134, -10.83672797872489],
            [-76.00985336031466, -10.839075353727083],
            [-76.0150311103195, -10.842677228730418],
            [-76.02318548532709, -10.853470478740496],
            [-76.02438611032821, -10.854771228741699],
            [-76.02743998533106, -10.857079978743858],
            [-76.0298599853333, -10.858639978745284],
            [-76.03662998533963, -10.866709978752816],
            [-76.03834998534123, -10.868889853754833],
            [-76.04139998534407, -10.872029978757775],
            [-76.04175998534441, -10.872599978758302],
            [-76.04300998534558, -10.874389978759984],
            [-76.04431998534679, -10.87888997876416],
            [-76.04447998534692, -10.879519978764723],
            [-76.04902986035117, -10.882629978767655],
            [-76.05078986035281, -10.883489978768441],
            [-76.05684011035846, -10.883319978768302],
            [-76.05811998535965, -10.883399978768345],
            [-76.06211998536337, -10.884369853769263],
            [-76.06235998536359, -10.884639978769506],
            [-76.06274998536395, -10.885089978769942],
            [-76.06363998536477, -10.885369978770203],
            [-76.06430998536541, -10.885579978770377],
            [-76.0659199853669, -10.886019978770793],
            [-76.06688998536781, -10.886459978771194],
            [-76.06881998536961, -10.887059978771758],
            [-76.0697498603705, -10.888469978773083],
            [-76.07151011037213, -10.890079978774597],
            [-76.07278998537332, -10.89111997877554],
            [-76.0733299853738, -10.89176997877616],
            [-76.0742599853747, -10.892059978776413],
            [-76.07550998537587, -10.89259997877692],
            [-76.07728998537752, -10.89340997877766],
            [-76.07794998537811, -10.893349978777612],
            [-76.08140998538136, -10.894699978778878],
            [-76.08213998538204, -10.8951399787793],
            [-76.0824099853823, -10.895749978779856],
            [-76.08256998538243, -10.896569978780635],
            [-76.08221998538208, -10.897449978781445],
            [-76.08309998538292, -10.897489978781497],
            [-76.08319998538302, -10.897979978781935],
            [-76.08363998538343, -10.898429978782355],
            [-76.08538998538505, -10.899329978783184],
            [-76.08576998538541, -10.900129978783948],
            [-76.08585998538548, -10.900239978784047],
            [-76.08708998538665, -10.90214997878581],
            [-76.08738998538692, -10.902809978786456],
            [-76.08793998538742, -10.90436997878787],
            [-76.08825998538772, -10.904809978788295],
            [-76.08985998538921, -10.906449978789825],
            [-76.09017998538951, -10.906649978789998],
            [-76.0913401103906, -10.906559978789932],
            [-76.09163998539087, -10.906899978790252],
            [-76.09176998539101, -10.908249978791494],
            [-76.09196998539119, -10.908599978791841],
            [-76.09243998539162, -10.910369978793478],
            [-76.09227998539149, -10.911269978794303],
            [-76.09202998539124, -10.913499978796372],
            [-76.09283998539199, -10.914739978797531],
            [-76.09366998539276, -10.915789978798532],
            [-76.09410998539317, -10.917179978799828],
            [-76.0942299853933, -10.91838997880094],
            [-76.09448998539352, -10.918879978801407],
            [-76.09566998539464, -10.920319978802752],
            [-76.09595998539488, -10.921259978803613],
            [-76.09683998539572, -10.922949978805212],
            [-76.09802998539683, -10.922769978805043],
            [-76.09855998539732, -10.923220103805438],
            [-76.09877998539753, -10.925189978807273],
            [-76.0990399853978, -10.928859978810696],
            [-76.09895998539771, -10.930309978812051],
            [-76.1015299854001, -10.931979978813612],
            [-76.10370998540213, -10.933109978814656],
            [-76.10385998540227, -10.933739978815247],
            [-76.10449998540287, -10.93491997881634],
            [-76.10646998540469, -10.936489978817793],
            [-76.1091499854072, -10.938939978820084],
            [-76.10923998540727, -10.94016997882121],
            [-76.10941011040744, -10.941349978822323],
            [-76.10984998540785, -10.942769978823664],
            [-76.10998998540798, -10.943959978824754],
            [-76.11102998540896, -10.94764997882818],
            [-76.11134998540926, -10.947819978828358],
            [-76.11287011041065, -10.948339978828843],
            [-76.11567998541328, -10.950119978830482],
            [-76.11670998541423, -10.951619978831904],
            [-76.12048998541778, -10.958969978838754],
            [-76.12126998541851, -10.96062997884026],
            [-76.1222398604194, -10.96114997884077],
            [-76.19090423548339, -10.96031047883999],
            [-76.209796860501, -10.918966728801465],
            [-76.22367773551395, -10.915013603797806],
            [-76.24908011053763, -10.924550728806674],
            [-76.25892386054682, -10.920062478802494],
            [-76.26580473555322, -10.920167978802583],
            [-76.27883161056538, -10.923725103805909],
            [-76.27881723556537, -10.925417228807502],
            [-76.27873036056529, -10.92712385380908],
            [-76.27818098556475, -10.929076353810903],
            [-76.27696598556363, -10.930942103812638],
            [-76.27627173556299, -10.933255978814787],
            [-76.2769226105636, -10.935194103816587],
            [-76.27770348556432, -10.937855353819092],
            [-76.27823861056483, -10.943336728824182],
            [-76.27789161056451, -10.944493728825242],
            [-76.27750111056412, -10.945809853826477],
            [-76.27721186056387, -10.947342853827902],
            [-76.27644523556313, -10.950944103831253],
            [-76.27391423556077, -10.950452353830794],
            [-76.2729742355599, -10.9503367288307],
            [-76.27197611055898, -10.951247728831554],
            [-76.27147011055851, -10.953243728833387],
            [-76.27261261055959, -10.956671478836585],
            [-76.27120973555827, -10.956280978836238],
            [-76.26948861055666, -10.9551673538352],
            [-76.26875111055595, -10.955572228835553],
            [-76.26723248555454, -10.957278853837147],
            [-76.26578623555321, -10.958262353838068],
            [-76.26562711055304, -10.958566103838356],
            [-76.26545348555291, -10.960127978839795],
            [-76.26794111055523, -10.961198228840825],
            [-76.26902573555621, -10.961342978840939],
            [-76.27002373555715, -10.962615603842115],
            [-76.26986473555701, -10.963555728843],
            [-76.26950311055668, -10.964163353843583],
            [-76.26872211055594, -10.964481353843855],
            [-76.26844736055568, -10.964466853843831],
            [-76.26783998555511, -10.96430997884371],
            [-76.26745998555478, -10.965249978844568],
            [-76.2679499855552, -10.966789978845997],
            [-76.26963998555681, -10.967149978846335],
            [-76.27173998555875, -10.96615997884543],
            [-76.27143998555849, -10.968119978847241],
            [-76.2729477355599, -10.971019228849963],
            [-76.27261398555957, -10.972076228850934],
            [-76.27184623555885, -10.973578228852332],
            [-76.27088948555797, -10.974757478853423],
            [-76.27016623555728, -10.975313853853942],
            [-76.26927623555646, -10.975725478854333],
            [-76.26873111055596, -10.976137103854729],
            [-76.26834161055558, -10.97743885385594],
            [-76.2694097355566, -10.97946372885783],
            [-76.26797448555523, -10.979697353858052],
            [-76.26750723555479, -10.979619478857972],
            [-76.26670611055404, -10.979630603857958],
            [-76.26616086055354, -10.979841978858188],
            [-76.26581611055322, -10.980709728858983],
            [-76.26639461055377, -10.981833478860025],
            [-76.2668619855542, -10.983213103861315],
            [-76.26655036055394, -10.985427103863355],
            [-76.26628336055366, -10.985994603863887],
            [-76.26576061055319, -10.987396478865206],
            [-76.26559548555302, -10.988446853866177],
            [-76.26647323555386, -10.990904353868467],
            [-76.26454236055203, -10.99425297887159],
            [-76.26404273555157, -10.994468978871792],
            [-76.26274636055038, -10.995198103872452],
            [-76.26281398555045, -10.996372853873565],
            [-76.26389423555145, -10.998074228875167],
            [-76.26173373554941, -11.000288603877195],
            [-76.25992448554774, -10.999816103876784],
            [-76.25866873554658, -10.9993974788764],
            [-76.25731836054531, -10.999951103876898],
            [-76.25716986054518, -11.000234603877171],
            [-76.25660273554462, -11.000950228877816],
            [-76.25567111054379, -11.002395103879184],
            [-76.25581961054392, -11.002989228879711],
            [-76.25672436054477, -11.003907353880576],
            [-76.25916823554704, -11.004757978881356],
            [-76.25899273554685, -11.007120978883584],
            [-76.25734548554534, -11.00872772888506],
            [-76.25644073554449, -11.009537978885835],
            [-76.25627873554434, -11.010550603886754],
            [-76.25630573554436, -11.011414853887558],
            [-76.25887123554675, -11.012778603888835],
            [-76.25941136054725, -11.012846103888888],
            [-76.25995148554779, -11.015006478890932],
            [-76.25931686054719, -11.01589772889175],
            [-76.25683236054485, -11.01776110389349],
            [-76.25460436054277, -11.020461603895988],
            [-76.25365923554187, -11.021892853897317],
            [-76.2534431105417, -11.022878478898257],
            [-76.2540912355423, -11.023486103898817],
            [-76.25749398554547, -11.02502547890026],
            [-76.25800711054596, -11.025376478900592],
            [-76.259141235547, -11.025281978900477],
            [-76.2595733605474, -11.025916603901095],
            [-76.25901973554689, -11.027833978902851],
            [-76.2584931105464, -11.028198603903194],
            [-76.25787198554583, -11.02867110390366],
            [-76.25716986054518, -11.029724353904632],
            [-76.25669736054472, -11.030642603905472],
            [-76.25548211054361, -11.03122322890603],
            [-76.25342961054169, -11.031911728906652],
            [-76.25270048554101, -11.031979353906728],
            [-76.25229548554064, -11.031925353906656],
            [-76.2509857355394, -11.032330353907051],
            [-76.25155273553992, -11.033437603908073],
            [-76.25187686054024, -11.033869728908465],
            [-76.25199836054034, -11.036232603910685],
            [-76.25170136054008, -11.036745728911168],
            [-76.25114773553956, -11.039419228913657],
            [-76.25031998553877, -11.040379978914547],
            [-76.2488399855374, -11.04034010391452],
            [-76.24663261053537, -11.040781228914923],
            [-76.24470023553354, -11.041186478915298],
            [-76.2436872355326, -11.041217603915321],
            [-76.24321973553216, -11.041544853915633],
            [-76.24320411053216, -11.043414978917369],
            [-76.2434691105324, -11.043726603917655],
            [-76.24533998553414, -11.045409978919237],
            [-76.24405998553294, -11.046779978920496],
            [-76.24264148553162, -11.047030478920743],
            [-76.24208998553112, -11.046829978920554],
            [-76.24145073553052, -11.046441353920208],
            [-76.24073611052987, -11.046403728920168],
            [-76.24034761052947, -11.04659172892033],
            [-76.24023473552938, -11.046742228920477],
            [-76.2400342355292, -11.047581978921261],
            [-76.23997161052915, -11.047882853921541],
            [-76.23957036052877, -11.049199103922763],
            [-76.23964561052884, -11.050214478923717],
            [-76.24019711052935, -11.05107935392453],
            [-76.24101198553012, -11.051493103924908],
            [-76.24176998553081, -11.052059978925413],
            [-76.24071998552984, -11.053279978926568],
            [-76.23965998552885, -11.054169978927396],
            [-76.23963998552883, -11.054289978927487],
            [-76.24060998552974, -11.055259978928392],
            [-76.24179998553083, -11.057329978930328],
            [-76.2411198605302, -11.060369978933172],
            [-76.24331998553225, -11.066929853939278],
            [-76.24343998553236, -11.06812997894037],
            [-76.24516998553398, -11.070539978942627],
            [-76.24394998553285, -11.072269978944252],
            [-76.24393998553285, -11.072539978944512],
            [-76.24416998553305, -11.073229978945156],
            [-76.24416998553305, -11.073929978945797],
            [-76.24405998553294, -11.075499978947258],
            [-76.2443499855332, -11.077169978948827],
            [-76.24516723553397, -11.079064728950572],
            [-76.24544911053425, -11.080181978951627],
            [-76.24574111053451, -11.080675228952071],
            [-76.24703973553574, -11.081712228953036],
            [-76.24848923553708, -11.082787603954044],
            [-76.24850273553709, -11.083512478954717],
            [-76.24822086053685, -11.084358103955504],
            [-76.24793898553656, -11.085176853956268],
            [-76.25018998553868, -11.089129978959955],
            [-76.25095998553938, -11.094149978964618],
            [-76.2511799855396, -11.094959978965374],
            [-76.25123998553964, -11.096310103966655],
            [-76.25089998553933, -11.097779978968006],
            [-76.25071998553916, -11.101069978971077],
            [-76.25030998553876, -11.10320997897305],
            [-76.25024998553872, -11.104559978974308],
            [-76.24973986053824, -11.106289978975934],
            [-76.24954998553808, -11.106929978976513],
            [-76.24616998553492, -11.111209978980511],
            [-76.24595998553471, -11.111959978981202],
            [-76.24547998553427, -11.113309978982466],
            [-76.24308998553204, -11.115309978984344],
            [-76.23784998552716, -11.116019853984973],
            [-76.23618998552561, -11.118559978987362],
            [-76.23497998552449, -11.120379978989059],
            [-76.23258998552224, -11.12185997899043],
            [-76.2321199855218, -11.122479978990999],
            [-76.2308099855206, -11.124189978992593],
            [-76.22798998551797, -11.128779978996857],
            [-76.22475998551494, -11.131559853999471],
            [-76.22524998551542, -11.133139979000946],
            [-76.22479998551499, -11.134589979002277],
            [-76.22398998551425, -11.136199979003791],
            [-76.22001998551053, -11.138049979005519],
            [-76.2186998605093, -11.139709979007073],
            [-76.2186998605093, -11.14308997901021],
            [-76.21818998550881, -11.144099979011136],
            [-76.21563998550644, -11.14799997901479],
            [-76.21478998550566, -11.150029979016649],
            [-76.21462998550552, -11.150309979016933],
            [-76.30790036059248, -11.132664104000476],
            [-76.31807023560197, -11.09164260396229],
            [-76.39721673567577, -11.075761728947487],
            [-76.49121286076347, -11.089441353960218],
            [-76.53130273580089, -11.036064478910513],
            [-76.57694923584353, -11.016527353892325],
            [-76.56507661083242, -10.966178978845438],
            [-76.58821161085403, -10.84505072873263],
            [-76.6334864858963, -10.78452847867625],
            [-76.65938073592048, -10.6882528535866],
            [-76.65565536091702, -10.646781853547987],
            [-76.5385104858076, -10.7043517286016],
            [-76.51617373578677, -10.742954978637547],
            [-76.45636298573095, -10.647425478548591],
            [-76.41960998569665, -10.653169978553937],
            [-76.34602998562805, -10.596059978500769],
            [-76.29371998557926, -10.601889978506202],
            [-76.241959985531, -10.542699978451058],
            [-76.24681173553553, -10.479161228391892],
            [-76.30587998559061, -10.412169978329517],
            [-76.27112148555818, -10.371095728291243],
            [-76.22303898551336, -10.374882853294764],
            [-76.13048636042707, -10.429730478345846],
            [-76.031669860335, -10.45592772837027],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 13,
        FIRST_IDPR: '1308',
        NOMBPROV: 'PATAZ',
        FIRST_NOMB: 'LA LIBERTAD',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: '18/06/1987',
        LAST_FECHA: 'EPOCA INDEP.',
        MIN_SHAPE_: 399547.99952,
        ha: 399548,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.56581448677015, -8.237208976303696],
            [-77.55721673676204, -8.193732476263232],
            [-77.60568748680761, -8.154810601226943],
            [-77.63652023683662, -8.106191976181647],
            [-77.64736723684679, -8.049708976129017],
            [-77.64081598684061, -7.983332976067213],
            [-77.62362023682441, -7.876542475967778],
            [-77.65647736185528, -7.797888350894523],
            [-77.65358898685254, -7.770457100868973],
            [-77.70093161189698, -7.59353710070415],
            [-77.62204486182286, -7.622169975730906],
            [-77.60593748680772, -7.647134725754142],
            [-77.54556098675097, -7.672182475777507],
            [-77.53094861173722, -7.654479850761039],
            [-77.48452886169362, -7.699714475803203],
            [-77.52777661173428, -7.757582975857067],
            [-77.47983361168922, -7.803291600899668],
            [-77.44286111165448, -7.813641725909332],
            [-77.4067833616206, -7.902493100992062],
            [-77.37690923659255, -7.90882135099799],
            [-77.40712961162095, -7.991985601075431],
            [-77.3854726116006, -8.055386726134477],
            [-77.29225573651307, -8.060089351138894],
            [-77.23828911146242, -8.013324226095355],
            [-77.06870311130342, -8.039511726119777],
            [-77.01956248625739, -8.052687476132075],
            [-76.98672336122665, -8.11122522618659],
            [-76.90699798615199, -8.170735601242024],
            [-76.92559673616941, -8.21849735128651],
            [-76.90124323614663, -8.283466351347007],
            [-76.91374523615833, -8.326089851386698],
            [-76.97648148621707, -8.395943726451758],
            [-77.08548048631914, -8.406222601461307],
            [-77.1338437363645, -8.440021476492761],
            [-77.20421286143048, -8.48569135153528],
            [-77.26407811148667, -8.467531226518368],
            [-77.31748636153681, -8.53015235157666],
            [-77.36601748658238, -8.507086976555168],
            [-77.40033773661465, -8.48196685153173],
            [-77.40546061161945, -8.44148335149405],
            [-77.43889623665086, -8.419862351473908],
            [-77.45889636166964, -8.335655976395476],
            [-77.49136848670017, -8.282553101345995],
            [-77.56581448677015, -8.237208976303696],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 10,
        FIRST_IDPR: '0508',
        NOMBPROV: 'PAUCAR DEL SARA SARA',
        FIRST_NOMB: 'AYACUCHO',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: 'EPOCA INDEP.',
        LAST_FECHA: 'EPOCA INDEP.',
        MIN_SHAPE_: 208321.47098,
        ha: 208321.47,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.27479548276622, -15.39295385796792],
            [-73.33628085782367, -15.405148482979294],
            [-73.39216610787585, -15.398736982973318],
            [-73.44110210792157, -15.355723732933285],
            [-73.44096073292143, -15.355057232932666],
            [-73.44064673292114, -15.348417357926472],
            [-73.44484398292506, -15.327223982906723],
            [-73.44853710792849, -15.318898607898983],
            [-73.44934248292928, -15.318001107898139],
            [-73.45302598293267, -15.31468910789506],
            [-73.45342223293308, -15.314416607894819],
            [-73.45457998293413, -15.311357107891963],
            [-73.46239698294144, -15.304508857885578],
            [-73.46640573294516, -15.301669357882949],
            [-73.46871073294734, -15.297994732879515],
            [-73.46987985794841, -15.29485460787659],
            [-73.47849860795645, -15.292015107873953],
            [-73.48294160796063, -15.290812607872825],
            [-73.48294160796063, -15.289810232871899],
            [-73.48167223295944, -15.286035357868379],
            [-73.48187273295962, -15.284699232867135],
            [-73.48427785796186, -15.281558982864219],
            [-73.5021166079785, -15.272873482856118],
            [-73.5043714829806, -15.2689729828525],
            [-73.50497010798115, -15.263824857847702],
            [-73.50635548298246, -15.261071232845135],
            [-73.51128148298706, -15.255050607839532],
            [-73.51442848299, -15.252142982836823],
            [-73.5180887329934, -15.245130482830284],
            [-73.52144110799654, -15.237912607823572],
            [-73.52339085799835, -15.22354548281019],
            [-73.52871023300334, -15.222929732809614],
            [-73.53229098300666, -15.217882857804927],
            [-73.53259760800695, -15.217773482804825],
            [-73.53454498300877, -15.216009732803172],
            [-73.53705860801111, -15.215507857802702],
            [-73.5370429830111, -15.21512698280236],
            [-73.53558785800973, -15.212624982800026],
            [-73.53593748301007, -15.210181607797754],
            [-73.53591998301005, -15.209902357797489],
            [-73.53753510801155, -15.208296857795991],
            [-73.53763085801165, -15.20406060779205],
            [-73.53645123301055, -15.197679732786098],
            [-73.53621285801032, -15.196169982784696],
            [-73.53662323301072, -15.19463873278328],
            [-73.53923248301314, -15.186212857775434],
            [-73.54130660801508, -15.185181607774464],
            [-73.54434373301793, -15.18488473277419],
            [-73.54454685801811, -15.184656232773971],
            [-73.54478323301832, -15.183085982772514],
            [-73.5481757330215, -15.176953107766808],
            [-73.54838473302168, -15.176427732766314],
            [-73.54784760802117, -15.174220732764264],
            [-73.5479766080213, -15.172501982762665],
            [-73.5484102330217, -15.17121685776147],
            [-73.54889260802214, -15.170695357760989],
            [-73.54938285802262, -15.170199232760515],
            [-73.54928910802252, -15.16950585775986],
            [-73.5469961080204, -15.16559760775623],
            [-73.54749223302086, -15.164751982755439],
            [-73.54887310802214, -15.164179732754906],
            [-73.54908985802231, -15.163675732754443],
            [-73.55105648302417, -15.159546857750582],
            [-73.55133198302443, -15.158949232750036],
            [-73.5523847330254, -15.158550732749665],
            [-73.55337498302634, -15.158374982749502],
            [-73.55470898302758, -15.15610348274739],
            [-73.5548378580277, -15.155642607746959],
            [-73.55306248302605, -15.153456982744915],
            [-73.55398048302692, -15.14962885774136],
            [-73.55277535802577, -15.144386732736468],
            [-73.55210548302517, -15.143994107736114],
            [-73.55179685802489, -15.143974607736089],
            [-73.55104498302418, -15.144140607736258],
            [-73.5479609830213, -15.14579098273778],
            [-73.54669335802011, -15.145091857737128],
            [-73.54607035801952, -15.144279357736375],
            [-73.54816798302149, -15.138960982731426],
            [-73.5484023580217, -15.138224607730745],
            [-73.54833985802163, -15.137603607730162],
            [-73.54730660802065, -15.13697848272958],
            [-73.54685948302027, -15.136660107729275],
            [-73.54676948302019, -15.136550732729184],
            [-73.54668748302011, -15.13609373272876],
            [-73.54812885802141, -15.134982482727718],
            [-73.54852735802181, -15.134408232727191],
            [-73.54837310802166, -15.134306607727094],
            [-73.54584960801931, -15.131824232724773],
            [-73.54438085801796, -15.127914107721137],
            [-73.54644135801986, -15.126335982719677],
            [-73.5496972330229, -15.124693357718138],
            [-73.55184960802491, -15.125189482718604],
            [-73.55285935802586, -15.121431607715106],
            [-73.5523672330254, -15.117402357711356],
            [-73.55085548302401, -15.110621107705033],
            [-73.54954098302278, -15.10685548270153],
            [-73.54966798302287, -15.104347607699193],
            [-73.54936323302259, -15.10330860769822],
            [-73.5462499830197, -15.098302732693563],
            [-73.54644535801988, -15.094113232689656],
            [-73.54623635801966, -15.093816357689382],
            [-73.53854298301249, -15.091289232687021],
            [-73.53826760801223, -15.090912107686668],
            [-73.53825785801222, -15.087769482683743],
            [-73.53679685801089, -15.083060607679366],
            [-73.54410548301769, -15.067054732664461],
            [-73.54476560801832, -15.065285107662818],
            [-73.54552335801901, -15.060687482658537],
            [-73.5456132330191, -15.059048857657007],
            [-73.54584373301931, -15.056689482654816],
            [-73.5449843580185, -15.055546857653756],
            [-73.5436894830173, -15.055505857653708],
            [-73.54352735801716, -15.055494857653702],
            [-73.54233398301602, -15.05567973265387],
            [-73.54182810801555, -15.05577335765396],
            [-73.54046685801428, -15.057064482655166],
            [-73.53974810801361, -15.058587857656574],
            [-73.53931448301321, -15.059456982657393],
            [-73.53860748301256, -15.059929732657837],
            [-73.53799610801198, -15.060017607657905],
            [-73.5333124830076, -15.055484357653679],
            [-73.53099810800545, -15.053351607651702],
            [-73.5265409830013, -15.05691998265503],
            [-73.52032223299551, -15.06108010765889],
            [-73.51596285799143, -15.06102960765885],
            [-73.51520123299073, -15.060427732658296],
            [-73.51198448298773, -15.058902357656871],
            [-73.51056635798639, -15.05817973265619],
            [-73.50637698298249, -15.05623635765438],
            [-73.5055391079817, -15.055962857654144],
            [-73.50481510798103, -15.056422482654558],
            [-73.49248085796953, -15.060701607658533],
            [-73.45832785793765, -15.044912607643834],
            [-73.45542185793491, -15.050516857649043],
            [-73.44807398292805, -15.061393482659186],
            [-73.44728510792733, -15.061517982659296],
            [-73.44359048292388, -15.060604732658453],
            [-73.43958198292015, -15.062392607660117],
            [-73.43101185791213, -15.063178607660843],
            [-73.41220623289459, -15.058695107656655],
            [-73.41004748289255, -15.057947857655977],
            [-73.40917573289174, -15.058238482656233],
            [-73.3777522328624, -15.04254260764162],
            [-73.37022485785536, -15.04055685763977],
            [-73.35359985783985, -15.036123482635636],
            [-73.34692685783361, -15.03783223263723],
            [-73.34133898282838, -15.035523232635079],
            [-73.33531235782277, -15.031643982631461],
            [-73.333425732821, -15.026349607626537],
            [-73.33593948282336, -15.023412107623793],
            [-73.3368554828242, -15.016099607616988],
            [-73.33833985782559, -15.012255857613399],
            [-73.34000198282715, -15.008359357609782],
            [-73.3412284828283, -15.006499982608041],
            [-73.34245310782943, -15.0035468576053],
            [-73.34148298282852, -15.002836357604632],
            [-73.33417060782169, -15.000932982602864],
            [-73.33291273282052, -15.00044910760241],
            [-73.3134316078023, -14.98670910758961],
            [-73.31014173279925, -14.986225357589166],
            [-73.30623898279559, -14.985773732588745],
            [-73.29640173278639, -14.981451857584712],
            [-73.29227323278253, -14.983515982586638],
            [-73.28930598277977, -14.98364498258675],
            [-73.28488723277565, -14.983741857586853],
            [-73.27746173276871, -14.978807107582256],
            [-73.27326810776478, -14.97710973258067],
            [-73.26538023275741, -14.974763357578476],
            [-73.26264035775485, -14.965695482570037],
            [-73.26531535775734, -14.963525107568012],
            [-73.25903260775146, -14.934417232540909],
            [-73.25816148275065, -14.9333352325399],
            [-73.25602348274866, -14.928214357535122],
            [-73.25673610774933, -14.924518982531694],
            [-73.26267248275487, -14.920083607527555],
            [-73.26542423275744, -14.910431857518574],
            [-73.26540123275741, -14.909329482517542],
            [-73.26471235775679, -14.906504857514914],
            [-73.2549982327477, -14.899753357508624],
            [-73.25314735774599, -14.899860982508724],
            [-73.23807635773188, -14.906148732514577],
            [-73.20085198269706, -14.922776107530057],
            [-73.1939398576906, -14.920591982528027],
            [-73.19343385769015, -14.91477298252259],
            [-73.19237985768913, -14.911230982519298],
            [-73.18668723268384, -14.910261232518396],
            [-73.16762798266599, -14.912032232520046],
            [-73.16585710766434, -14.912158732520163],
            [-73.16164035766037, -14.911188857519255],
            [-73.15725510765628, -14.906339732514729],
            [-73.14173785764177, -14.89638848250546],
            [-73.13561910763603, -14.888824607498421],
            [-73.1183722326199, -14.867885607478911],
            [-73.12521548262632, -14.861235732472725],
            [-73.1260659826271, -14.860462482472006],
            [-73.12792173262885, -14.857988107469696],
            [-73.12772848262864, -14.854431232466382],
            [-73.12676198262776, -14.853155357465202],
            [-73.1213769826227, -14.847402357459845],
            [-73.11871823262022, -14.842584607455343],
            [-73.11734473261895, -14.842552607455312],
            [-73.11363823261546, -14.843856607456544],
            [-73.108870982611, -14.842949607455694],
            [-73.10150635760411, -14.843681232456372],
            [-73.09186960759507, -14.838684232451712],
            [-73.06581448257069, -14.825359232439293],
            [-73.06118748256635, -14.825529357439443],
            [-73.0386027325452, -14.844120482456766],
            [-73.0343421075412, -14.848700732461026],
            [-73.03333010754027, -14.849925732462161],
            [-73.02044123252817, -14.85418648246612],
            [-73.01420598252233, -14.855708107467537],
            [-73.0314636075385, -14.900051107508839],
            [-72.9866562324965, -14.956103357561013],
            [-73.00534823251402, -15.00931648261057],
            [-73.004241982513, -15.064126982661612],
            [-73.04756160755359, -15.134347857727025],
            [-73.11074823261278, -15.186106732775247],
            [-73.11683660761845, -15.222851607809472],
            [-73.17454523267247, -15.306607232887476],
            [-73.24593348273923, -15.286091857868389],
            [-73.28491973277569, -15.294937107876633],
            [-73.290618857781, -15.371318857947765],
            [-73.27479548276622, -15.39295385796792],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 6,
        FIRST_IDPR: '0811',
        NOMBPROV: 'PAUCARTAMBO',
        FIRST_NOMB: 'CUSCO',
        LAST_DCTO: 'LEY',
        LAST_LEY: '14116',
        FIRST_FECH: 'EPOCA INDEP.',
        LAST_FECHA: '15/06/1962',
        MIN_SHAPE_: 579946.97532,
        ha: 579946.98,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.0641787306583, -13.256700980967425],
            [-71.14347023073466, -13.35789710606311],
            [-71.16173623075213, -13.403397356105744],
            [-71.1432751057334, -13.476536731173455],
            [-71.21733560580552, -13.518552106213821],
            [-71.29409323088099, -13.455301356156175],
            [-71.37279635595777, -13.446204731148763],
            [-71.41625160599972, -13.516941356215119],
            [-71.46878123105091, -13.494244106194577],
            [-71.49888673108033, -13.458005856161178],
            [-71.52333723110353, -13.588385356282762],
            [-71.62660935620379, -13.566173856262992],
            [-71.6226367311998, -13.604283106298432],
            [-71.680780856256, -13.629478231322336],
            [-71.71952348129365, -13.552474606250927],
            [-71.74649510632001, -13.420927856128653],
            [-71.73968110631351, -13.385590731095729],
            [-71.82129698139222, -13.319599356034773],
            [-71.86384098143326, -13.227775605949505],
            [-71.88889048145758, -13.053487605787351],
            [-71.90636773147438, -13.020791230757006],
            [-71.82850348139988, -12.95422798069466],
            [-71.89140648146036, -12.833209855582298],
            [-71.90035160646909, -12.733355480489367],
            [-71.81059185638311, -12.744978480499768],
            [-71.74694135632194, -12.796107480547002],
            [-71.6949687312722, -12.738793105493366],
            [-71.6002984811813, -12.700058105456618],
            [-71.5521327311349, -12.709301980464847],
            [-71.45104748103692, -12.824969355571568],
            [-71.44887223103463, -12.863340605607268],
            [-71.415243856002, -12.894258605635654],
            [-71.35493810594372, -12.88397873062541],
            [-71.30100973089115, -12.940695355677477],
            [-71.23875385583061, -12.971933605705631],
            [-71.17304248076678, -12.983334605715202],
            [-71.16818223076109, -13.09408860581813],
            [-71.1220117307149, -13.233529230947013],
            [-71.0641787306583, -13.256700980967425],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 10,
        FIRST_IDPR: '2207',
        NOMBPROV: 'PICOTA',
        FIRST_NOMB: 'SAN MARTIN',
        LAST_DCTO: 'LEY',
        LAST_LEY: '12094',
        FIRST_FECH: '31/01/1944',
        LAST_FECHA: '07/04/1954',
        MIN_SHAPE_: 210749.915179,
        ha: 210749.92,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.52387311079399, -6.725382849895949],
            [-76.5242096107943, -6.69287022486566],
            [-76.45631798573095, -6.680006349853672],
            [-76.27504961056185, -6.692069099864924],
            [-76.2618952355496, -6.769101474936673],
            [-76.19737061048943, -6.749156974918072],
            [-76.1881924854809, -6.712312224883763],
            [-76.12676998542364, -6.687993099861113],
            [-76.0954082353944, -6.714859224886143],
            [-76.06852386036935, -6.801491349966825],
            [-76.05089573535291, -6.810860224975546],
            [-76.0105293603153, -6.893611350052608],
            [-76.05146348535344, -6.944295975099823],
            [-76.01079148531556, -6.996536475148489],
            [-76.01071711031548, -7.078379850224684],
            [-76.05994498536137, -7.161301225301924],
            [-76.14352523543924, -7.203962475341661],
            [-76.1579426104527, -7.198294600336381],
            [-76.15776198545251, -7.102745725247394],
            [-76.18599186047885, -7.075912600222397],
            [-76.31900061060283, -7.058452475206157],
            [-76.38063911066035, -7.014832475165505],
            [-76.4176757356949, -7.018316100168775],
            [-76.52350998579364, -6.976039975129376],
            [-76.54864361081711, -6.928329225084966],
            [-76.47135161074496, -6.855978475017574],
            [-76.48149023575444, -6.807419974972341],
            [-76.52387311079399, -6.725382849895949],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 8,
        FIRST_IDPR: '1105',
        NOMBPROV: 'PISCO',
        FIRST_NOMB: 'ICA',
        LAST_DCTO: 'LEY',
        LAST_LEY: 'S/N',
        FIRST_FECH: '08/03/1951',
        LAST_FECHA: '13/10/1900',
        MIN_SHAPE_: 394836.06162,
        ha: 394836.06,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.4773104848186, -13.525484356228866],
            [-75.51400710985278, -13.617092231314171],
            [-75.48486923482567, -13.650972231345737],
            [-75.52943985986717, -13.740066856428697],
            [-75.55458235989056, -13.749571231437574],
            [-75.64799610997757, -13.80743948149144],
            [-75.70165036002757, -13.817195356500532],
            [-75.78889061010882, -13.856441356537097],
            [-75.85321898516874, -13.837053731519037],
            [-75.88454098519793, -13.855173856535886],
            [-76.02797848533156, -13.840119106521877],
            [-76.05594723535762, -13.929296856604935],
            [-76.02504686032883, -13.959226606632802],
            [-76.0254511103292, -14.005025356675448],
            [-75.98654098529295, -14.06948635673548],
            [-76.0714941103721, -14.206890606863428],
            [-76.03311911033633, -14.27662310692839],
            [-75.98617573529259, -14.296318356946733],
            [-76.00350198530873, -14.390206982034153],
            [-76.03667773533967, -14.42605473206754],
            [-76.06619523536716, -14.386943357031127],
            [-76.13185936042835, -14.35426373200068],
            [-76.10994923540792, -14.292685606943335],
            [-76.14357036043926, -14.22762098188276],
            [-76.19074998548324, -14.197502106854706],
            [-76.23052536052032, -14.149119106809637],
            [-76.29019548557594, -14.16151173182119],
            [-76.28565436057173, -14.089548856754151],
            [-76.2664511105538, -14.014691356684448],
            [-76.28251561056878, -13.91804873159447],
            [-76.33001173561307, -13.92502535660094],
            [-76.39632811067494, -13.90889848158592],
            [-76.37129098565156, -13.808880731492783],
            [-76.29622461058156, -13.793468731478447],
            [-76.3007714855858, -13.837722606519629],
            [-76.26096286054869, -13.865601606545589],
            [-76.19766011048966, -13.640876981336323],
            [-76.19161323548406, -13.59449985629312],
            [-76.08849023538794, -13.564482481265198],
            [-75.98696873529335, -13.571644481271868],
            [-75.93556448524544, -13.631050731327193],
            [-75.86384961017863, -13.611429731308924],
            [-75.81446098513263, -13.6195624813165],
            [-75.68928098501604, -13.439670106148949],
            [-75.58512210991903, -13.402668231114486],
            [-75.538917734876, -13.405012481116671],
            [-75.50291598484245, -13.365950106080303],
            [-75.46577535980786, -13.432160106141964],
            [-75.4773104848186, -13.525484356228866],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 8,
        FIRST_IDPR: '2001',
        NOMBPROV: 'PIURA',
        FIRST_NOMB: 'PIURA',
        LAST_DCTO: 'LEY',
        LAST_LEY: '8231',
        FIRST_FECH: '19/02/1965',
        LAST_FECHA: '03/04/1936',
        MIN_SHAPE_: 598263.381126,
        ha: 598263.38,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.10244923866881, -4.929037098191134],
            [-80.17641011367517, -4.971753848226812],
            [-80.23430661367266, -4.94830859820183],
            [-80.25657611367303, -4.991449223240338],
            [-80.22150198867833, -5.050449223296965],
            [-80.28961911367342, -5.071517598312215],
            [-80.33554298866842, -5.121720723355472],
            [-80.36501761366847, -5.23242184845552],
            [-80.32300386368064, -5.290535098512168],
            [-80.25992961369059, -5.330316348553223],
            [-80.083925613693, -5.560435598776492],
            [-79.99919136367596, -5.585359348803901],
            [-79.96853911366736, -5.581165973801462],
            [-79.99483011368133, -5.751945348958449],
            [-80.05298436369806, -5.811244099010406],
            [-80.10908011370735, -5.787798723985564],
            [-80.20768948871296, -5.721476598918171],
            [-80.29120111370783, -5.677925723872216],
            [-80.32297273870424, -5.679326223871158],
            [-80.53462886363204, -5.601605473781346],
            [-80.55258011361354, -5.492269473678967],
            [-80.6367069885593, -5.486080098664634],
            [-80.63532811355705, -5.450374973631947],
            [-80.70542973849851, -5.429529348604976],
            [-80.69526761350303, -5.38482809856512],
            [-80.72409373847368, -5.352173848531849],
            [-80.80560161338727, -5.338550723509292],
            [-80.84934373832986, -5.298361348466718],
            [-80.82769923834842, -5.225009723402517],
            [-80.8055194883744, -5.220919973401637],
            [-80.74463086343128, -5.130072223325839],
            [-80.70032423846358, -5.017623098227697],
            [-80.69769336346074, -4.960085973175205],
            [-80.60601948853348, -4.929009723155887],
            [-80.54005073857003, -4.839789098079817],
            [-80.50742973858719, -4.823369098067441],
            [-80.4188691136272, -4.828960973079456],
            [-80.3615038636428, -4.783755848041838],
            [-80.3431347386435, -4.710331972975297],
            [-80.3711073636341, -4.677949222943564],
            [-80.35667386363595, -4.63683784790662],
            [-80.24885736365394, -4.585107472865399],
            [-80.22142573865689, -4.595451222876476],
            [-80.15341986365885, -4.604191347888086],
            [-80.12356636366033, -4.673011722953215],
            [-80.03555273864929, -4.656345722941771],
            [-80.02569323864971, -4.718234347999537],
            [-80.07732223865818, -4.731552723009573],
            [-80.09639848866455, -4.838447223107594],
            [-80.11708973866831, -4.882369098147221],
            [-80.10244923866881, -4.929037098191134],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 4,
        FIRST_IDPR: '0216',
        NOMBPROV: 'POMABAMBA',
        FIRST_NOMB: 'ANCASH',
        LAST_DCTO: 'LEY',
        LAST_LEY: 'S/N',
        FIRST_FECH: '28/06/1955',
        LAST_FECHA: '28/08/1868',
        MIN_SHAPE_: 92220.6273407,
        ha: 92220.63,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.56345311176813, -8.903373101924089],
            [-77.57426573677829, -8.900442976921365],
            [-77.60462611180688, -8.886709726908563],
            [-77.5812009867848, -8.771116601800916],
            [-77.59274311179566, -8.757665226788385],
            [-77.53867711174475, -8.701750351736349],
            [-77.49647923670504, -8.697020976731991],
            [-77.50155748670981, -8.614356101654982],
            [-77.48992136169885, -8.590674976632936],
            [-77.36601748658238, -8.507086976555168],
            [-77.31748636153681, -8.53015235157666],
            [-77.2890273615101, -8.58930472663175],
            [-77.29484961151557, -8.632933601672386],
            [-77.26059573648341, -8.64852147668691],
            [-77.247361361471, -8.712763726746745],
            [-77.27976761150143, -8.70282422673748],
            [-77.39265236160747, -8.767619101797777],
            [-77.44713286165867, -8.838320351863588],
            [-77.3924648616073, -8.881040976903401],
            [-77.40557036161962, -8.91317572693332],
            [-77.56345311176813, -8.903373101924089],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 5,
        FIRST_IDPR: '1009',
        NOMBPROV: 'PUERTO INCA',
        FIRST_NOMB: 'HUANUCO',
        LAST_DCTO: 'LEY',
        LAST_LEY: '12640',
        FIRST_FECH: '19/11/1984',
        LAST_FECHA: '02/02/1956',
        MIN_SHAPE_: 1004069.78967,
        ha: 1004069.79,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.67710335907337, -9.42832422741325],
            [-74.7276992341205, -9.469968727452024],
            [-74.75052285914175, -9.524607102502921],
            [-74.73516998412742, -9.572706977547703],
            [-74.80703948419438, -9.686575727653741],
            [-74.80442573419195, -9.72982222769403],
            [-74.84935160923378, -9.748300727711243],
            [-74.89766798427878, -9.73711135270082],
            [-74.9431034843211, -9.761101602723155],
            [-74.99944210937356, -9.83845397779521],
            [-75.05988085942985, -9.819693352777726],
            [-75.10839848447505, -9.846253852802468],
            [-75.16012498452322, -9.839974602796618],
            [-75.2407506095983, -9.785835477746192],
            [-75.30306960965633, -9.783622102744145],
            [-75.27582160963097, -9.84976410280574],
            [-75.28793773464226, -9.870278102824837],
            [-75.36935598471807, -9.870832602825374],
            [-75.39565460974258, -9.913960477865512],
            [-75.47572885981715, -9.973958477921395],
            [-75.54864148488504, -9.983607977930394],
            [-75.593660234927, -9.951791977900765],
            [-75.65526560998434, -9.943765602893265],
            [-75.76140823508322, -9.856443352811972],
            [-75.75292573507531, -9.816982477775221],
            [-75.7162832350412, -9.802304727761538],
            [-75.7245448600489, -9.739999977703524],
            [-75.7114707350367, -9.690771477657671],
            [-75.71388286003894, -9.612500102584763],
            [-75.67758986000516, -9.560060602535934],
            [-75.66874798499691, -9.509593727488928],
            [-75.63363085996423, -9.450165977433597],
            [-75.62157223495299, -9.396734352383815],
            [-75.56244523489791, -9.400171852387029],
            [-75.49439060983453, -9.377355352365791],
            [-75.45210148479514, -9.296722602290691],
            [-75.32894135968041, -9.297029352290977],
            [-75.28272260963739, -9.309831977302883],
            [-75.26233010961839, -9.270367227266131],
            [-75.20971473456939, -9.229943352228487],
            [-75.21060160957022, -9.159136727162553],
            [-75.23758010959536, -9.106771477113776],
            [-75.18101560954267, -9.08099810208979],
            [-75.1509863595147, -9.038716852050399],
            [-75.14330273450754, -8.972193351988455],
            [-75.09436910946197, -8.922298851941994],
            [-75.04556248441652, -8.904216851925144],
            [-74.97885935935439, -8.95203922696969],
            [-74.93416410931276, -8.930224601949378],
            [-74.95364548433092, -8.858053476882146],
            [-74.94071210931888, -8.857892601881998],
            [-74.85055835923491, -8.756891101787929],
            [-74.84668348423129, -8.661235351698855],
            [-74.79752685918551, -8.631711726671355],
            [-74.61781585901814, -8.548719351594073],
            [-74.54713460895233, -8.584567226627435],
            [-74.52535123393203, -8.749316226780897],
            [-74.53217810893838, -8.776133226805863],
            [-74.60892023400986, -8.820228351846916],
            [-74.64431073404282, -8.918783226938721],
            [-74.65136610904939, -9.048768352059772],
            [-74.6780937340743, -9.094039102101902],
            [-74.64070898403948, -9.11856447712475],
            [-74.63956448403842, -9.159681602163067],
            [-74.58943935899173, -9.30317635229671],
            [-74.67430860907075, -9.394472602381718],
            [-74.67710335907337, -9.42832422741325],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 1,
        FIRST_IDPR: '2001',
        NOMBPROV: 'PUIRA',
        FIRST_NOMB: 'PIURA',
        LAST_DCTO: 'LEY',
        LAST_LEY: '5951',
        FIRST_FECH: '28/12/1927',
        LAST_FECHA: '28/12/1927',
        MIN_SHAPE_: 24287.5992719,
        ha: 24287.6,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.85959761331469, -5.281361348449791],
            [-80.84934373832986, -5.298361348466718],
            [-80.80560161338727, -5.338550723509292],
            [-80.72409373847368, -5.352173848531849],
            [-80.69526761350303, -5.38482809856512],
            [-80.70542973849851, -5.429529348604976],
            [-80.96149023817135, -5.311046848462039],
            [-81.0254218630533, -5.242789098389641],
            [-80.9392323631965, -5.237542973398404],
            [-80.85959761331469, -5.281361348449791],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 15,
        FIRST_IDPR: '2101',
        NOMBPROV: 'PUNO',
        FIRST_NOMB: 'PUNO',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: 'EPOCA INDEP.',
        LAST_FECHA: 'EPOCA INDEP.',
        MIN_SHAPE_: 653760.31559,
        ha: 653760.32,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.60974410403844, -15.983390608372195],
            [-69.60992772903856, -15.98431248337308],
            [-69.60874997903692, -15.987552733375828],
            [-69.61027147903786, -15.99479685838277],
            [-69.60991210403728, -15.996294983384086],
            [-69.61353322904091, -16.00077535838889],
            [-69.61392385404122, -16.002132858390215],
            [-69.61420510404147, -16.002472608390594],
            [-69.61445310404173, -16.003083983391203],
            [-69.61509760404236, -16.00379885839199],
            [-69.61492385404215, -16.004138733392256],
            [-69.61439260404119, -16.00737698339514],
            [-69.61452347904132, -16.007667983395418],
            [-69.61694722904393, -16.008769483396904],
            [-69.62220697904935, -16.01395123340269],
            [-69.62494922905226, -16.01588285840498],
            [-69.62553510405291, -16.015865108405077],
            [-69.62649222905387, -16.017279483406565],
            [-69.62685547905423, -16.017730483407057],
            [-69.62878322905628, -16.019031233408615],
            [-69.63289447906106, -16.01742573340793],
            [-69.63413872906243, -16.01814060840881],
            [-69.63675785406542, -16.017706983408914],
            [-69.63866410406769, -16.016775358408406],
            [-69.64033785406976, -16.014824233406937],
            [-69.64323247907316, -16.013767608406514],
            [-69.64453510407465, -16.013369108406394],
            [-69.64584185407622, -16.012326233405673],
            [-69.64733185407795, -16.01233773340597],
            [-69.65079885408157, -16.01510348340916],
            [-69.65074810408146, -16.015798858409788],
            [-69.65047460408107, -16.016619108410485],
            [-69.65150585408199, -16.019044983412904],
            [-69.6506347290809, -16.020052733413674],
            [-69.65101560408124, -16.021238233414838],
            [-69.65096497908115, -16.02138673341497],
            [-69.6505937290807, -16.021746108415226],
            [-69.65170510408161, -16.024986358418413],
            [-69.65091797908066, -16.025820358419022],
            [-69.6516211040814, -16.0264023584197],
            [-69.65158597908118, -16.028083983421233],
            [-69.65121685408066, -16.02923435842221],
            [-69.6504628540797, -16.030220733422986],
            [-69.65070122907987, -16.03127348342399],
            [-69.65080635407986, -16.032634358425256],
            [-69.65066860407927, -16.036961858429216],
            [-69.65156435408012, -16.03868460843097],
            [-69.65180272907905, -16.05255473344376],
            [-69.6517382290789, -16.053234358444367],
            [-69.65758597908471, -16.061826233453356],
            [-69.65800197908516, -16.06221285845378],
            [-69.6659472290937, -16.06731835845994],
            [-69.66810935409603, -16.06867573346159],
            [-69.6774082291067, -16.067724608462395],
            [-69.6786387291081, -16.0679141084628],
            [-69.6827773541126, -16.070242233465695],
            [-69.68413672911385, -16.07354498346897],
            [-69.68705860411681, -16.077216858472877],
            [-69.68822260411798, -16.0790801084748],
            [-69.68774410411699, -16.083997483479234],
            [-69.68821922911748, -16.084353733479634],
            [-69.6969707291275, -16.084076608480945],
            [-69.69195697912022, -16.100681608495307],
            [-69.6900253541179, -16.10205273349623],
            [-69.68580272911288, -16.104136733497388],
            [-69.68472260411167, -16.103740233496826],
            [-69.68186135410828, -16.104999983497475],
            [-69.68114260410746, -16.10502735849736],
            [-69.68052347910668, -16.10570898349788],
            [-69.6802968541064, -16.10590035849802],
            [-69.67927535410519, -16.106427733498318],
            [-69.67791997910342, -16.108831983500277],
            [-69.67756635410299, -16.108996108500364],
            [-69.67666210410187, -16.10988873350103],
            [-69.67557422910065, -16.109617233500575],
            [-69.67468560409955, -16.11076560850147],
            [-69.67384760409857, -16.110771483501317],
            [-69.67302347909755, -16.11138473350173],
            [-69.67155860409575, -16.112861358502823],
            [-69.67162110409576, -16.113423858503342],
            [-69.67085935409489, -16.113804733503564],
            [-69.67010460409399, -16.113972233503574],
            [-69.67032422909408, -16.1155951085051],
            [-69.67407847909809, -16.11841060850838],
            [-69.68102672910516, -16.12806348351852],
            [-69.68443497910897, -16.129213733520185],
            [-69.68662310411155, -16.128340358519782],
            [-69.68831597911355, -16.127847358519627],
            [-69.68906685411393, -16.13307035852457],
            [-69.69133047911598, -16.138911733530346],
            [-69.69106547911547, -16.141175483532372],
            [-69.6879516041116, -16.14415685853456],
            [-69.687852104111, -16.149523483539475],
            [-69.69228685411619, -16.148562483539386],
            [-69.69363935411772, -16.148733358539786],
            [-69.69405922911805, -16.150315233541306],
            [-69.69408360411785, -16.153058983543847],
            [-69.69243822911568, -16.15593498354618],
            [-69.69156435411426, -16.160343608550082],
            [-69.70355960412721, -16.170020858561116],
            [-69.70445197912748, -16.178229233568818],
            [-69.70367985412616, -16.1827109835728],
            [-69.70984185413188, -16.198314483588238],
            [-69.7109644791332, -16.198424858588545],
            [-69.71956897914266, -16.20363460859483],
            [-69.72512422914869, -16.208247983600042],
            [-69.7254964791491, -16.208414608600247],
            [-69.7266991041505, -16.20828385860033],
            [-69.73061647915529, -16.205117858598094],
            [-69.73282410415787, -16.204828733598216],
            [-69.73512272916052, -16.204828733598603],
            [-69.73772547916352, -16.20523385859942],
            [-69.74341997917016, -16.204447233599666],
            [-69.74363672917043, -16.204429608599686],
            [-69.74517572917213, -16.205292983600753],
            [-69.74741222917457, -16.207015608602706],
            [-69.74881060417611, -16.207644483603527],
            [-69.74922460417658, -16.208234358604127],
            [-69.75093360417847, -16.209078108605187],
            [-69.75147847917894, -16.210746108606823],
            [-69.75234372917976, -16.213316358609326],
            [-69.7532597291808, -16.213289108609473],
            [-69.75338472918094, -16.213376983609564],
            [-69.7550586041825, -16.217779358613896],
            [-69.75775385418581, -16.215523483612262],
            [-69.75728322918532, -16.21496685861169],
            [-69.75886722918708, -16.215857483612755],
            [-69.75972422918811, -16.215383983612465],
            [-69.76055272918906, -16.21550385861271],
            [-69.7606308541891, -16.215914108613113],
            [-69.76105660418939, -16.218152358615225],
            [-69.76106435418941, -16.218177483615253],
            [-69.76125197918961, -16.218472608615556],
            [-69.76182422919007, -16.220775358617768],
            [-69.76355860419199, -16.221992233619176],
            [-69.76357622919198, -16.222210983619384],
            [-69.76333010419168, -16.22241998361953],
            [-69.7630527291913, -16.22339060862038],
            [-69.76288660419102, -16.22412698362103],
            [-69.76299235419111, -16.224515608621406],
            [-69.76392947919211, -16.225591858622558],
            [-69.76327210419129, -16.22634098362314],
            [-69.76333197919134, -16.226716733623483],
            [-69.76411722919221, -16.226982483623864],
            [-69.76409372919201, -16.228943358625664],
            [-69.76407810419184, -16.230998108627546],
            [-69.7641113541918, -16.23189060862837],
            [-69.76443747919213, -16.23247660862897],
            [-69.76527147919307, -16.23291598362951],
            [-69.76559960419326, -16.235132858631612],
            [-69.76564060419327, -16.235583983632033],
            [-69.76651172919348, -16.24497060864079],
            [-69.76782422919497, -16.245542983641556],
            [-69.76792385419509, -16.245701233641714],
            [-69.76847660419564, -16.2465722336426],
            [-69.77280272919987, -16.256048858652033],
            [-69.77399610420116, -16.2573242336534],
            [-69.77486910420207, -16.258591858654707],
            [-69.7751718542024, -16.25899410865513],
            [-69.77558197920278, -16.25992773365606],
            [-69.77636322920365, -16.260388858656604],
            [-69.77791210420533, -16.26182035865817],
            [-69.78359772921155, -16.26670310866359],
            [-69.78493560421306, -16.267095733664167],
            [-69.785728479214, -16.267011733664226],
            [-69.78805497921667, -16.267517358665067],
            [-69.79958610422962, -16.273393733672314],
            [-69.80618885423708, -16.276132108675885],
            [-69.79902797922495, -16.323934108718735],
            [-69.80102160422656, -16.33285160872726],
            [-69.80037110422559, -16.335484483729577],
            [-69.80070310422583, -16.33770510873168],
            [-69.8007207292257, -16.33927923373312],
            [-69.79606247921956, -16.34824410874063],
            [-69.79353510421626, -16.35244535874408],
            [-69.79151760421381, -16.353542983744767],
            [-69.79046872921253, -16.35409373374511],
            [-69.79004685421202, -16.354326233745258],
            [-69.78985347921169, -16.355755858746527],
            [-69.78957222921125, -16.35677535874742],
            [-69.78830272920959, -16.359076233749335],
            [-69.78795897920894, -16.36190435875189],
            [-69.78826372920925, -16.3625273587525],
            [-69.79549010421708, -16.371003858761483],
            [-69.79649610421822, -16.371384733761982],
            [-69.79721285421888, -16.373521483764065],
            [-69.79782222921955, -16.37428123376486],
            [-69.79688472921825, -16.376882858767114],
            [-69.79667385421794, -16.377675733767806],
            [-69.80143560422276, -16.3873769837775],
            [-69.80353910422512, -16.388595733778956],
            [-69.81048822923296, -16.392874983783997],
            [-69.81714847924074, -16.393763733785867],
            [-69.82571472925078, -16.39361523378707],
            [-69.82822847925377, -16.39315235878703],
            [-69.8317324792579, -16.393169983787605],
            [-69.83760935426486, -16.39215235878756],
            [-69.83979685426733, -16.393359358789013],
            [-69.84258985427041, -16.395951233791823],
            [-69.84653510427489, -16.39814260879444],
            [-69.84841597927705, -16.398759733795288],
            [-69.85241197928153, -16.401589858798502],
            [-69.85470897928394, -16.405679733802618],
            [-69.85476947928368, -16.41001773380662],
            [-69.85594135428472, -16.414324233810756],
            [-69.85526372928328, -16.423714858819295],
            [-69.85645047928446, -16.426408358821952],
            [-69.86907547929766, -16.449214733844844],
            [-69.87735597930696, -16.45568698385203],
            [-69.88063960431053, -16.460088983856554],
            [-69.88427322931452, -16.463866733860566],
            [-69.8792676043081, -16.47130423386669],
            [-69.87935335430797, -16.47462560886976],
            [-69.88675522931581, -16.487542858882726],
            [-69.88840072931755, -16.49029660888551],
            [-69.89003485431925, -16.49339060888859],
            [-69.89073810431996, -16.495252858890407],
            [-69.89417160432377, -16.498632233894025],
            [-69.89549297932503, -16.50300148389824],
            [-69.90224810433223, -16.514468733909755],
            [-69.90687910433736, -16.51948948391505],
            [-69.91539060434741, -16.519642608916406],
            [-69.92143447935487, -16.514419608912434],
            [-69.92950247936426, -16.516310858915293],
            [-69.93049372936532, -16.518116858917114],
            [-69.93090422936572, -16.518832358917827],
            [-69.93315385436826, -16.521147108920275],
            [-69.93739485437315, -16.522463233922075],
            [-69.94194585437806, -16.529516983929184],
            [-69.9642544794034, -16.54449698394599],
            [-69.9822823544232, -16.56799260897002],
            [-69.9886172294303, -16.57336910897581],
            [-70.01039360445469, -16.594783858998333],
            [-70.01146435445592, -16.59509323399875],
            [-70.01401035445889, -16.59580698399974],
            [-70.01884060446454, -16.59637810900087],
            [-70.02827347947513, -16.605400359010353],
            [-70.02432585446967, -16.619645859023],
            [-70.02369822946854, -16.62667973402942],
            [-70.03063560447623, -16.634723734037685],
            [-70.03326810447935, -16.634319984037642],
            [-70.04861160449776, -16.62770798403342],
            [-70.0726667295253, -16.641258984048786],
            [-70.08415897953815, -16.652967609060926],
            [-70.08678485454114, -16.65518410906327],
            [-70.09066022954573, -16.654095734062718],
            [-70.09900947955569, -16.650453359060307],
            [-70.09914035455584, -16.65029798406018],
            [-70.10367197956126, -16.6482459840588],
            [-70.10784685456633, -16.64412010905546],
            [-70.11337235457302, -16.639748734052045],
            [-70.1213783545825, -16.637194734050567],
            [-70.12616722958819, -16.634591484048695],
            [-70.13456610459818, -16.630662234045985],
            [-70.14274410460818, -16.620470609037454],
            [-70.14623147961238, -16.617130609034742],
            [-70.15440922962196, -16.615706234034295],
            [-70.15792110462601, -16.61600098403494],
            [-70.16916872963975, -16.600701234021972],
            [-70.17047035464135, -16.598662859020223],
            [-70.17582247964783, -16.592749484015314],
            [-70.17668360464977, -16.572164483996396],
            [-70.18051460465489, -16.556864733982643],
            [-70.18405097965976, -16.538531983966077],
            [-70.18538697966137, -16.537195983964978],
            [-70.19239397966938, -16.53879323396715],
            [-70.19332885467043, -16.539067358967507],
            [-70.19950685467751, -16.539626608968625],
            [-70.20099635467923, -16.539171108968354],
            [-70.20290435468152, -16.53693948396647],
            [-70.22220960470474, -16.512211108945493],
            [-70.22400522970683, -16.51159635894511],
            [-70.2271564797106, -16.50793885894203],
            [-70.22866622971239, -16.50607573394045],
            [-70.24260310472839, -16.50477085894056],
            [-70.24421460473027, -16.50382535893983],
            [-70.27223247976265, -16.49249998393192],
            [-70.28701560477981, -16.483310858924735],
            [-70.29917485479378, -16.479401608922174],
            [-70.30241910479751, -16.47752135892072],
            [-70.31375247981076, -16.466468733911462],
            [-70.3068402298035, -16.451135358896693],
            [-70.31641910481481, -16.438503858885827],
            [-70.31585760481425, -16.435696858883173],
            [-70.31824372981723, -16.42850385887673],
            [-70.31952872981876, -16.425967983874482],
            [-70.3225062298223, -16.421141483870265],
            [-70.33283985483429, -16.41147073386217],
            [-70.35923422986394, -16.411642358864473],
            [-70.36088760486585, -16.410831358863845],
            [-70.36154922986663, -16.409530483862692],
            [-70.36211935486773, -16.39583598385007],
            [-70.37300572988012, -16.390449858845937],
            [-70.38225697989063, -16.385923733842457],
            [-70.38237347989077, -16.385425733842013],
            [-70.38066022988892, -16.38399810884056],
            [-70.37147547987894, -16.374527858831083],
            [-70.35271172985847, -16.35828035881456],
            [-70.35079472985639, -16.356655733812907],
            [-70.34766410485307, -16.351823358808186],
            [-70.34534585485075, -16.34358148380039],
            [-70.34626085485188, -16.340187858797307],
            [-70.34213672984751, -16.332815858790152],
            [-70.33925760484479, -16.319633608777725],
            [-70.3425412298487, -16.312733233771603],
            [-70.34617522985293, -16.307514108767066],
            [-70.34513485485206, -16.299074983759176],
            [-70.34381072985065, -16.298027108758102],
            [-70.34782622985537, -16.29111135875202],
            [-70.34104222984843, -16.27306748373478],
            [-70.34499197985342, -16.257053858720273],
            [-70.34513485485358, -16.25610198371941],
            [-70.34335335485173, -16.253029483716414],
            [-70.34175610485006, -16.24996298371346],
            [-70.33915597984718, -16.249100358712447],
            [-70.33895697984697, -16.248941358712273],
            [-70.33547247984336, -16.2409469837046],
            [-70.33397522984183, -16.237518483701304],
            [-70.32952560483692, -16.23578148369935],
            [-70.32651710483367, -16.2321118586957],
            [-70.326489354834, -16.2226091086869],
            [-70.32452860483207, -16.215912983680557],
            [-70.32778910483587, -16.210894983676173],
            [-70.32856010483695, -16.205411983671162],
            [-70.34739872985796, -16.20416560867152],
            [-70.35577060486713, -16.208249608675956],
            [-70.36064272987257, -16.207792858675916],
            [-70.3622793548744, -16.20649860867484],
            [-70.36735760488025, -16.20092173367007],
            [-70.36722985488049, -16.18925585865927],
            [-70.36934210488306, -16.18299260865362],
            [-70.3802794798956, -16.17111898364347],
            [-70.38411147989994, -16.167548358640445],
            [-70.38469710490062, -16.166633858639646],
            [-70.38732272990379, -16.15896023363274],
            [-70.40074860491889, -16.150615358626006],
            [-70.4025507299211, -16.144036483620045],
            [-70.40644322992588, -16.12909773360649],
            [-70.40647572992593, -16.12892910860634],
            [-70.4076599799274, -16.123159983601088],
            [-70.40801297992783, -16.122016483600056],
            [-70.40128710492105, -16.10252735858151],
            [-70.40630135492673, -16.097787108577485],
            [-70.40357372992392, -16.091729108571684],
            [-70.40325647992363, -16.09049210857051],
            [-70.41346960493534, -16.075172483557076],
            [-70.41419910493613, -16.075394608557335],
            [-70.41632410493845, -16.07583860855789],
            [-70.42075272994352, -16.069613983552436],
            [-70.42476097994792, -16.06879735855196],
            [-70.42764722995113, -16.0674334835509],
            [-70.4342822299586, -16.061878358546213],
            [-70.43506935495951, -16.06015060854467],
            [-70.43845547996375, -16.043076983529094],
            [-70.43902747996451, -16.038635483525024],
            [-70.44112135496688, -16.03581423352255],
            [-70.44422497997033, -16.033996608521083],
            [-70.45040397997701, -16.036970983524256],
            [-70.46063935498788, -16.04773348353491],
            [-70.47135785499954, -16.048348483536195],
            [-70.48089035501013, -16.041495608530465],
            [-70.48141748001076, -16.04030948352941],
            [-70.48255960501203, -16.039474858528706],
            [-70.49907673003001, -16.039386983529685],
            [-70.50241210503368, -16.03791410852853],
            [-70.50732423003905, -16.038144483529052],
            [-70.51194773004428, -16.02976673352158],
            [-70.51449560504702, -16.031743483523574],
            [-70.5176584800504, -16.03336885852527],
            [-70.52162873005481, -16.030346983522715],
            [-70.52376748005717, -16.028650858521285],
            [-70.52970910506399, -16.014891108508877],
            [-70.5309162300653, -16.014159358508273],
            [-70.54188473007741, -16.00594535850133],
            [-70.54929310508568, -15.995183733491784],
            [-70.55126373008783, -15.99462398349138],
            [-70.55842410509568, -15.989923608487445],
            [-70.55975210509732, -15.982731858480848],
            [-70.55789073009531, -15.982471233480512],
            [-70.55639060509372, -15.980901483478961],
            [-70.54775810508445, -15.978045608475796],
            [-70.54480148008128, -15.977751858475363],
            [-70.54152335507774, -15.977346233474787],
            [-70.53933748007542, -15.97656748347395],
            [-70.5198183550543, -15.97580660847206],
            [-70.51656623005074, -15.976244483472248],
            [-70.5012874800347, -15.957416108453852],
            [-70.48921698002177, -15.951787858447867],
            [-70.48426673001646, -15.949685608445607],
            [-70.47989460501192, -15.942433233438614],
            [-70.4820684800147, -15.927775983425176],
            [-70.48317173001595, -15.925731733423353],
            [-70.48445248001747, -15.921629108419628],
            [-70.48427673001734, -15.919639733417773],
            [-70.47927798001217, -15.91119548340962],
            [-70.47739610501017, -15.90921610840768],
            [-70.4729507300054, -15.907009733405333],
            [-70.46274972999443, -15.904195983402056],
            [-70.45630135498749, -15.901586858399227],
            [-70.45089672998175, -15.898364233395878],
            [-70.44616947997669, -15.895728858393113],
            [-70.43299185496353, -15.85805235835732],
            [-70.43090185496139, -15.85352723335298],
            [-70.42755722995794, -15.847177108346868],
            [-70.4314508549626, -15.833549358334515],
            [-70.43072797996187, -15.832076983333103],
            [-70.42145710495213, -15.822419858323514],
            [-70.42119847995215, -15.812473483314275],
            [-70.42129485495227, -15.811939483313788],
            [-70.43146985496368, -15.800245483303655],
            [-70.42866747996165, -15.768246108273821],
            [-70.4270136049599, -15.767276983272804],
            [-70.42014472995275, -15.757768483263527],
            [-70.41987397995256, -15.755015608260951],
            [-70.42171022995464, -15.751467608257808],
            [-70.424644354958, -15.746311108253224],
            [-70.4157548549487, -15.736613483243636],
            [-70.40630285493863, -15.731409608238145],
            [-70.3959486049275, -15.729808983235941],
            [-70.39337360492469, -15.73014973323607],
            [-70.38390672991436, -15.733444358238442],
            [-70.37711885490675, -15.740452983244444],
            [-70.37426285490362, -15.741036483244775],
            [-70.35857572988678, -15.735988983238936],
            [-70.32011322984522, -15.72999785823044],
            [-70.31278510483699, -15.736902358236247],
            [-70.31276372983702, -15.735900358235329],
            [-70.31193560483612, -15.734974608234394],
            [-70.31081247983494, -15.734173858233579],
            [-70.30960735483366, -15.733320358232678],
            [-70.30787097983182, -15.73245898323176],
            [-70.3078106048318, -15.731216858230598],
            [-70.30679497983076, -15.729689483229098],
            [-70.3064512298304, -15.728777358228223],
            [-70.30471672982858, -15.727187483226626],
            [-70.3044003548283, -15.724976608224543],
            [-70.30423247982814, -15.724429733224019],
            [-70.30350585482739, -15.723390608223],
            [-70.30294335482684, -15.72251560822214],
            [-70.29895997982274, -15.716100608215891],
            [-70.29149222981496, -15.706923858206792],
            [-70.29135535481484, -15.706566358206452],
            [-70.28958597981305, -15.702701233202736],
            [-70.28922072981271, -15.701753858201817],
            [-70.28854897981198, -15.701964858201956],
            [-70.28836910481178, -15.701845733201845],
            [-70.28755860481093, -15.70095898320095],
            [-70.28599610480923, -15.700701233200576],
            [-70.28498247980816, -15.699771608199633],
            [-70.28397460480714, -15.697873233197791],
            [-70.28347085480667, -15.69618560819619],
            [-70.283742229807, -15.695447233195532],
            [-70.28231447980545, -15.695162108195152],
            [-70.28153710480461, -15.6949589831949],
            [-70.28112110480419, -15.694347483194301],
            [-70.28065822980372, -15.693718733193679],
            [-70.27992972980292, -15.693732483193616],
            [-70.27888872980176, -15.69417185819395],
            [-70.2782851048011, -15.693960983193708],
            [-70.26896972979105, -15.692068733191185],
            [-70.26525772978746, -15.680989983180613],
            [-70.26016997978226, -15.673222608172992],
            [-70.246238229767, -15.675535108173941],
            [-70.24586910476664, -15.674673858173106],
            [-70.24470510476542, -15.673119108171571],
            [-70.24377147976446, -15.671820358170287],
            [-70.2436972297644, -15.67160748317009],
            [-70.24364260476435, -15.671220733169726],
            [-70.24059572976127, -15.666109358164729],
            [-70.24049985476117, -15.666076233164683],
            [-70.2389707297595, -15.665552733164075],
            [-70.23738672975776, -15.66609185816443],
            [-70.23399810475408, -15.665583983163671],
            [-70.23332622975336, -15.66588673316389],
            [-70.23191797975198, -15.662212858160368],
            [-70.23062885475056, -15.662320358160356],
            [-70.22876947974864, -15.660343733158358],
            [-70.22632422974607, -15.658396483156352],
            [-70.22526372974494, -15.657812483155709],
            [-70.2250351047447, -15.657292983155203],
            [-70.22541597974514, -15.656546858154556],
            [-70.2257382297455, -15.65613473315421],
            [-70.22556635474538, -15.655054733153186],
            [-70.22383197974344, -15.65596685815388],
            [-70.22217972974174, -15.654124983152029],
            [-70.22127147974078, -15.653089858150977],
            [-70.22105272974059, -15.652316358150243],
            [-70.2210937297407, -15.650886733148926],
            [-70.22047847974004, -15.650595733148611],
            [-70.2198574797393, -15.651613233149488],
            [-70.21604297973536, -15.646615233144523],
            [-70.21498822973426, -15.645892608143768],
            [-70.21456635473379, -15.645759733143596],
            [-70.21396297973315, -15.64568560814348],
            [-70.21322072973231, -15.646380858144045],
            [-70.21199810473102, -15.645556608143181],
            [-70.20870897972753, -15.643978483141423],
            [-70.2085859797274, -15.643843733141283],
            [-70.20779885472655, -15.6430663581405],
            [-70.20465435472325, -15.640833983138142],
            [-70.2048339797235, -15.639226608136685],
            [-70.20485160472356, -15.638765608136245],
            [-70.20531060472412, -15.637263608134905],
            [-70.20537310472419, -15.63694923313462],
            [-70.20379097972246, -15.636855483134392],
            [-70.20269535472146, -15.633263733130956],
            [-70.20363285472254, -15.631945233129834],
            [-70.20379097972273, -15.631165983129122],
            [-70.20318560472212, -15.630076233128063],
            [-70.20163085472056, -15.62719135812526],
            [-70.20234185472137, -15.626703108124866],
            [-70.20096872971997, -15.623939483122182],
            [-70.20098635472003, -15.623730483121996],
            [-70.1986874797178, -15.617814483116309],
            [-70.19833197971742, -15.61742385811591],
            [-70.19715435471616, -15.617248108115637],
            [-70.19689447971587, -15.61746685811583],
            [-70.19652147971537, -15.61961910811778],
            [-70.19554097971427, -15.619931608117978],
            [-70.1905586047089, -15.619124983116778],
            [-70.19077147970907, -15.620070358117676],
            [-70.19103510470936, -15.620339858117942],
            [-70.18902935470699, -15.624162108121297],
            [-70.18916797970714, -15.624374983121506],
            [-70.18759572970536, -15.625679733122569],
            [-70.187259729705, -15.625306608122187],
            [-70.18566010470319, -15.627072233123675],
            [-70.18478510470221, -15.627816358124287],
            [-70.18418160470148, -15.629025358125348],
            [-70.18307222970027, -15.629328108125515],
            [-70.18220310469934, -15.629212858125324],
            [-70.1804453546975, -15.627515608123591],
            [-70.17954497969656, -15.626636733122705],
            [-70.17834372969524, -15.62691798312284],
            [-70.17549022969202, -15.629351608124823],
            [-70.17519535469171, -15.629318358124769],
            [-70.17349022968993, -15.627527358122947],
            [-70.1733867296898, -15.627607483123017],
            [-70.17299810468933, -15.629251983124503],
            [-70.17206835468818, -15.632171858127114],
            [-70.1704453546864, -15.631642608126464],
            [-70.17037510468631, -15.632425733127194],
            [-70.17133797968727, -15.633892608128624],
            [-70.16964060468545, -15.633052733127693],
            [-70.1692773546851, -15.632706983127337],
            [-70.16774610468343, -15.632572233127064],
            [-70.16737110468296, -15.633521483127911],
            [-70.16637885468185, -15.634570358128789],
            [-70.1662851046817, -15.635119108129288],
            [-70.16668360468213, -15.635632983129803],
            [-70.16929097968489, -15.637392608131682],
            [-70.16754885468282, -15.640865233134722],
            [-70.16819135468349, -15.641453108135323],
            [-70.16789047968312, -15.642185608135964],
            [-70.16674610468182, -15.643183608136797],
            [-70.1661933546813, -15.641599608135278],
            [-70.16547460468055, -15.641322233134943],
            [-70.16319535467791, -15.64437310813754],
            [-70.1630839796778, -15.644400233137569],
            [-70.16342760467805, -15.646738233139748],
            [-70.161554729676, -15.64684373313968],
            [-70.1596543546738, -15.649414108141862],
            [-70.158902354673, -15.648988233141402],
            [-70.15775385467181, -15.647583983139992],
            [-70.15720310467123, -15.647681608140024],
            [-70.15704685467102, -15.647847483140165],
            [-70.15709760467102, -15.649160108141382],
            [-70.15977535467388, -15.650519608142897],
            [-70.1560683546698, -15.651548858143487],
            [-70.15611322966976, -15.653109358144944],
            [-70.15432622966775, -15.65449610814605],
            [-70.15148060466451, -15.656962858148052],
            [-70.14863672966143, -15.656830108147656],
            [-70.15023635466302, -15.659634733150403],
            [-70.1502382296628, -15.663906233154357],
            [-70.1499141046624, -15.66547660815578],
            [-70.14893360466122, -15.666794983156892],
            [-70.14972460466213, -15.666734358156914],
            [-70.15021485466264, -15.666804733157038],
            [-70.14728710465937, -15.668218733158044],
            [-70.1449961046569, -15.668273483157876],
            [-70.143126979655, -15.665195358154836],
            [-70.14221285465403, -15.663886733153541],
            [-70.13820510464998, -15.657716858147431],
            [-70.13503122964669, -15.653857483143561],
            [-70.1342871046459, -15.652966858142662],
            [-70.13298435464458, -15.651269483140954],
            [-70.13165822964329, -15.648343733138127],
            [-70.12810747963975, -15.641310608131265],
            [-70.1270214796386, -15.640636733130528],
            [-70.12664647963823, -15.640456983130331],
            [-70.12643747963799, -15.640376983130231],
            [-70.12445510463587, -15.639810608129515],
            [-70.12377347963512, -15.639492233129145],
            [-70.12344335463482, -15.638732608128414],
            [-70.12340822963482, -15.637888733127626],
            [-70.0894162295975, -15.6414487331274],
            [-70.08374222959125, -15.642462858127745],
            [-70.08583010459385, -15.636290983122265],
            [-70.0858828545939, -15.636189483122173],
            [-70.07017572957744, -15.623849608109083],
            [-70.06409185457152, -15.610928733096488],
            [-70.03138985453792, -15.57493148305961],
            [-70.02915510453569, -15.571382483056075],
            [-70.02807660453469, -15.568446608053241],
            [-70.01862385452466, -15.564340608048367],
            [-70.00017872950481, -15.560943483043081],
            [-69.99998822950468, -15.559285108041536],
            [-70.00999997951577, -15.556339858039973],
            [-70.01016597951596, -15.556347608040003],
            [-70.01201760451791, -15.556918733040742],
            [-70.01421497952032, -15.556757358040851],
            [-70.01100947951707, -15.552545233036597],
            [-70.00990735451595, -15.551856108035823],
            [-70.00404172950948, -15.552975483036171],
            [-69.99887572950414, -15.548778483031702],
            [-69.99572097950082, -15.546553233029256],
            [-69.99558310450075, -15.545455733028238],
            [-69.99558910450098, -15.542276858025302],
            [-69.99404172949929, -15.541982983024852],
            [-69.99250022949765, -15.541940983024634],
            [-69.98987310449476, -15.542324858024674],
            [-69.98869372949343, -15.542993358025148],
            [-69.98816860449283, -15.543412733025477],
            [-69.98763197949216, -15.545131608027006],
            [-69.98792472949238, -15.5462784830281],
            [-69.98997310449437, -15.550422608032157],
            [-69.9880894794921, -15.553708858034973],
            [-69.98626635449016, -15.553106733034197],
            [-69.98480322948862, -15.552285983033267],
            [-69.98334347948712, -15.550711858031638],
            [-69.98189710448575, -15.54781235802879],
            [-69.97769585448125, -15.546772983027326],
            [-69.97571472947914, -15.545996108026364],
            [-69.97419097947753, -15.545434358025656],
            [-69.97355535447679, -15.545885108025997],
            [-69.97323297947638, -15.54718398302716],
            [-69.9662424794687, -15.548692358027704],
            [-69.96569447946808, -15.548596108027533],
            [-69.96402735446671, -15.542070358021304],
            [-69.96684960447003, -15.538064358017959],
            [-69.95911572946211, -15.530598108010112],
            [-69.95746310446042, -15.529260483008688],
            [-69.95274772945564, -15.524158733003388],
            [-69.9292012294311, -15.509459232986838],
            [-69.9274493544292, -15.509986607987098],
            [-69.92273835442427, -15.507385732984101],
            [-69.92106285442247, -15.506819357983359],
            [-69.91014197941061, -15.507731732982775],
            [-69.90511660440524, -15.506779732981231],
            [-69.89949160439971, -15.498894482973217],
            [-69.8607051043535, -15.557054983021564],
            [-69.77069197924742, -15.65838910810157],
            [-69.7507668542195, -15.729080358163467],
            [-69.79385947926728, -15.725300983166798],
            [-69.84551597932725, -15.685009358137366],
            [-69.84491460432997, -15.640677733096412],
            [-69.89291785438586, -15.593426733059525],
            [-69.96346922945591, -15.699753608166775],
            [-70.0174228545158, -15.690793233165019],
            [-70.03420410453235, -15.722986108196665],
            [-70.02410460451749, -15.7868797332545],
            [-69.96330960444605, -15.850883608306239],
            [-69.88303685435369, -15.890195608331817],
            [-69.82212097929367, -15.7854464832265],
            [-69.7674658542292, -15.83180310826074],
            [-69.76930835422594, -15.894957858319168],
            [-69.71385660416189, -15.916833608330096],
            [-69.6717393541109, -15.956604608359244],
            [-69.60974410403844, -15.983390608372195],
          ],
          [
            [-70.20011110471563, -15.699947108192413],
            [-70.20617147971929, -15.765786233253868],
            [-70.14743135465547, -15.751394233234956],
            [-70.20011110471563, -15.699947108192413],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 1,
        FIRST_IDPR: '2504',
        NOMBPROV: 'PURUS',
        FIRST_NOMB: 'UCAYALI',
        LAST_DCTO: 'LEY',
        LAST_LEY: '9815',
        FIRST_FECH: '02/07/1943',
        LAST_FECHA: '02/07/1943',
        MIN_SHAPE_: 1847225.67525,
        ha: 1847225.68,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.61388473029926, -9.916855477850127],
            [-70.66762748034313, -9.972452852903498],
            [-70.67284373034627, -10.035238227962008],
            [-70.65052598032642, -10.080343103003267],
            [-70.7078532303742, -10.135611103056345],
            [-70.7051114803714, -10.158680603077705],
            [-70.81744335546766, -10.256857478171929],
            [-70.8791894805221, -10.284248103198811],
            [-70.92293560556075, -10.327509728239962],
            [-70.96588085559938, -10.339814478252235],
            [-70.96722460560011, -10.381341853290882],
            [-71.0245334806512, -10.478200103382038],
            [-71.12738923074556, -10.504360853408036],
            [-71.19621285580877, -10.579861353479208],
            [-71.22955273083903, -10.688964853581169],
            [-71.22118635583067, -10.761383978648473],
            [-71.25732823086435, -10.784485103670423],
            [-71.27337698087896, -10.847357478729107],
            [-71.35731660595731, -10.951545478827006],
            [-71.53161448112213, -10.952229103829117],
            [-71.5905371061779, -10.972033228847955],
            [-71.72016110630084, -10.996553478871546],
            [-71.83566985641048, -11.000474603875702],
            [-72.01612885658172, -10.98968747886626],
            [-72.05173910661544, -11.010911603886111],
            [-72.13028710668992, -10.990333978867115],
            [-72.18180860673868, -11.0097246038853],
            [-72.21027335676561, -11.013279853888651],
            [-72.26212623181469, -10.971675853849995],
            [-72.32029685686967, -10.980279353858078],
            [-72.40006485694502, -10.929813978811188],
            [-72.43721098198009, -10.842589853730004],
            [-72.48434760702456, -10.792189478683111],
            [-72.44336985698598, -10.636491978538087],
            [-72.4060971069508, -10.64946222855012],
            [-72.38080235692699, -10.537832228446149],
            [-72.40788510695255, -10.517047728426823],
            [-72.4224488569663, -10.44647222836111],
            [-72.47819523201886, -10.36770847828781],
            [-72.47305635701406, -10.301707353226357],
            [-72.44472148198736, -10.255042478182869],
            [-72.381767231928, -10.22249647815248],
            [-72.33609598188495, -10.179478728112384],
            [-72.24254848179663, -10.18880972812096],
            [-72.21413335676984, -10.159707853093812],
            [-72.1569524817158, -10.165256853098898],
            [-72.14657435670603, -10.09846247803666],
            [-72.1893534817465, -10.020873102964474],
            [-72.18040435673807, -9.999671852944719],
            [-71.73577148131801, -9.99974997794353],
            [-71.37504485597917, -10.000144477941578],
            [-71.3427637309493, -9.966773477910229],
            [-71.29743560590698, -9.992177727933454],
            [-71.19198635580987, -9.940392602884065],
            [-71.15764648077884, -9.872148477820122],
            [-71.05096485568221, -9.815837852766188],
            [-70.99838285563477, -9.81821285276756],
            [-70.97402148061362, -9.76073822771367],
            [-70.92451060556976, -9.741639727694983],
            [-70.86813435552111, -9.664762727622348],
            [-70.79917773046152, -9.6419222275996],
            [-70.7497596054204, -9.569289727530823],
            [-70.65777173034421, -9.514258352477196],
            [-70.56259035526865, -9.430427352396322],
            [-70.51371685522842, -9.492802852452611],
            [-70.53930898024754, -9.535291602493006],
            [-70.59185048028947, -9.54854510250705],
            [-70.59824410529332, -9.607287102561864],
            [-70.55116998025375, -9.669806602618435],
            [-70.5280683552338, -9.726015602669865],
            [-70.53662885523966, -9.76583985270716],
            [-70.61228123030043, -9.805398477746474],
            [-70.61388473029926, -9.916855477850127],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 12,
        FIRST_IDPR: '0812',
        NOMBPROV: 'QUISPICANCHI',
        FIRST_NOMB: 'CUSCO',
        LAST_DCTO: 'LEY',
        LAST_LEY: '11624',
        FIRST_FECH: '05/09/1940',
        LAST_FECHA: '02/10/1951',
        MIN_SHAPE_: 737522.12985,
        ha: 737522.13,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.86250235545317, -13.800291856467704],
            [-70.95654598054612, -13.804714481474461],
            [-70.99163585558134, -13.761338856435023],
            [-71.08097073066982, -13.725501981403756],
            [-71.10353710569167, -13.76037973143665],
            [-71.16376948075111, -13.743197231421862],
            [-71.19309823077951, -13.79315498146884],
            [-71.28931235587376, -13.827706231502566],
            [-71.30781773089153, -13.890009606560794],
            [-71.3734561059557, -13.920335356589927],
            [-71.47847973105839, -13.929779106599984],
            [-71.51687885609567, -13.967218731635247],
            [-71.56239473114017, -13.934712356605424],
            [-71.5629361061408, -13.905745856578493],
            [-71.62017573119647, -13.897267856571096],
            [-71.64755360622327, -13.82337048150257],
            [-71.7016819812757, -13.819790606499637],
            [-71.70330285627745, -13.759524481443542],
            [-71.78976173136108, -13.698355481387182],
            [-71.84315885641263, -13.63079473132457],
            [-71.81981760639022, -13.60775773130301],
            [-71.82089848139144, -13.521749981222955],
            [-71.80462598137579, -13.508258106210283],
            [-71.73767160631112, -13.570121606267481],
            [-71.71952348129365, -13.552474606250927],
            [-71.680780856256, -13.629478231322336],
            [-71.6226367311998, -13.604283106298432],
            [-71.62660935620379, -13.566173856262992],
            [-71.52333723110353, -13.588385356282762],
            [-71.49888673108033, -13.458005856161178],
            [-71.46878123105091, -13.494244106194577],
            [-71.41625160599972, -13.516941356215119],
            [-71.37279635595777, -13.446204731148763],
            [-71.29409323088099, -13.455301356156175],
            [-71.21733560580552, -13.518552106213821],
            [-71.1432751057334, -13.476536731173455],
            [-71.16173623075213, -13.403397356105744],
            [-71.14347023073466, -13.35789710606311],
            [-71.0641787306583, -13.256700980967425],
            [-71.01403535560927, -13.26718848097608],
            [-70.97452123057197, -13.1682856058832],
            [-70.94219023054067, -13.15649885587146],
            [-70.9213928555211, -13.110830980828462],
            [-70.84296098044575, -13.067048480785592],
            [-70.75841310536326, -13.108044230821049],
            [-70.71004810531657, -13.109462605820688],
            [-70.65975610526861, -13.088459105799304],
            [-70.58754010519907, -13.103129980809948],
            [-70.54984673016175, -13.15286435585449],
            [-70.46424773008005, -13.159690605856639],
            [-70.38846685500734, -13.19238873088278],
            [-70.42040835503526, -13.258570105946022],
            [-70.39283623000719, -13.31416073099605],
            [-70.40007310501208, -13.371738606049892],
            [-70.44255010505196, -13.393359856072353],
            [-70.4526179800599, -13.447918356123512],
            [-70.53403173013632, -13.525270106199494],
            [-70.54172373014245, -13.57448898124557],
            [-70.6161908552143, -13.609062481281075],
            [-70.6539128552502, -13.655936106326184],
            [-70.71512885531024, -13.662253981334466],
            [-70.73474710532801, -13.739016731406489],
            [-70.69885823029128, -13.803167606464726],
            [-70.72136135531301, -13.829341856489908],
            [-70.76837498035982, -13.81433210647768],
            [-70.8265547304172, -13.827236356491618],
            [-70.86250235545317, -13.800291856467704],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 10,
        FIRST_IDPR: '0217',
        NOMBPROV: 'RECUAY',
        FIRST_NOMB: 'ANCASH',
        LAST_DCTO: 'L.R.',
        LAST_LEY: '561',
        FIRST_FECH: '08/11/1963',
        LAST_FECHA: '12/10/1921',
        MIN_SHAPE_: 232861.42135,
        ha: 232861.42,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.53625148674276, -9.737866352701255],
            [-77.5030977367115, -9.662697227631282],
            [-77.42438111163744, -9.679717477647173],
            [-77.31099436153083, -9.674310602642185],
            [-77.26970573649206, -9.648738852618411],
            [-77.22777298645268, -9.713389227678633],
            [-77.24384773646776, -9.80573247776462],
            [-77.18713473641454, -9.836212852793032],
            [-77.17378986140204, -9.913134227864669],
            [-77.20560411143188, -9.967025102914862],
            [-77.21411911143989, -10.023732477967666],
            [-77.20159961142814, -10.09338285303253],
            [-77.21277498643863, -10.133711603070084],
            [-77.28366798650524, -10.154211728089141],
            [-77.32052961153984, -10.127044228063808],
            [-77.3458071115636, -10.160530853094997],
            [-77.40064848661517, -10.132154353068527],
            [-77.45866598666977, -10.15616210309087],
            [-77.51526361172309, -10.147400353082649],
            [-77.55465286176022, -10.188464603120876],
            [-77.5753231117797, -10.24986022817802],
            [-77.67440436187314, -10.20671147813773],
            [-77.65625761185599, -10.151978478086775],
            [-77.69878323689613, -10.14139447807687],
            [-77.72186523691786, -10.078679228018448],
            [-77.77527536196824, -10.004193352949006],
            [-77.63140823683251, -10.022017602965802],
            [-77.60816973681058, -9.950135977898865],
            [-77.55249023675806, -9.872179727826332],
            [-77.47890423668876, -9.873769477827864],
            [-77.48895311169824, -9.819837852777638],
            [-77.52145311172883, -9.792271727751924],
            [-77.53625148674276, -9.737866352701255],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 11,
        FIRST_IDPR: '1605',
        NOMBPROV: 'REQUENA',
        FIRST_NOMB: 'LORETO',
        LAST_DCTO: 'LEY',
        LAST_LEY: '9815',
        FIRST_FECH: '02/07/1943',
        LAST_FECHA: '02/07/1943',
        MIN_SHAPE_: 5034385.19278,
        ha: 5034385.19,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.43521773291597, -4.446356222773385],
            [-73.3953027328787, -4.479252097803997],
            [-73.32256473281075, -4.584317972901867],
            [-73.26966010776135, -4.638835972952634],
            [-73.15560348265473, -4.709708973018631],
            [-73.12943948263025, -4.695544973005453],
            [-73.10215235760474, -4.789773473093201],
            [-73.2362441077301, -4.970566348261583],
            [-73.16991798266812, -4.99800197328715],
            [-73.09810060760094, -5.000796473289756],
            [-73.05386685755956, -5.037248723323682],
            [-72.97773760748831, -5.063245223347892],
            [-72.91278748242746, -5.119895973400655],
            [-72.85496835737331, -5.13869534841817],
            [-72.88788998240416, -5.165820598443426],
            [-72.8640312323818, -5.23459422350745],
            [-72.86718585738477, -5.29006159855914],
            [-72.89568810741147, -5.324968848591666],
            [-72.9246547324386, -5.40808972366908],
            [-72.95790323246973, -5.464396598721514],
            [-72.94961548246197, -5.547524848798912],
            [-72.97373160748454, -5.613442223860313],
            [-72.95898285747072, -5.656359598900296],
            [-73.05734735756279, -5.796485349030802],
            [-73.15206798265142, -5.867959599097371],
            [-73.18546160768263, -5.950084349173878],
            [-73.18665873268375, -6.004119224224192],
            [-73.22234110771713, -6.022061974240916],
            [-73.2499414827429, -6.133077974344297],
            [-73.15659898265565, -6.326801599524695],
            [-73.11169885761367, -6.44691572463659],
            [-73.14886073264842, -6.513764599698835],
            [-73.19939098269565, -6.569353349750624],
            [-73.32934810781713, -6.597780724777091],
            [-73.39201610787565, -6.641614849817925],
            [-73.52082860799591, -6.676102974850037],
            [-73.56167273303402, -6.72148047489231],
            [-73.60329335807283, -6.731980849902072],
            [-73.71045998317277, -6.840189975002862],
            [-73.72395010818536, -6.8873499750468],
            [-73.76130998322022, -6.940119975095925],
            [-73.76191998322078, -7.062539975209962],
            [-73.7984196082548, -7.113060975256998],
            [-73.71661910817855, -7.227558225363635],
            [-73.69487910815826, -7.299067225430212],
            [-73.72256335818409, -7.341216350469479],
            [-73.8186636082737, -7.338219100466706],
            [-73.86157685831368, -7.388138350513198],
            [-73.9237343583716, -7.362212475489044],
            [-73.94082610838751, -7.393945350518597],
            [-73.91131635836003, -7.475837850594867],
            [-73.94280973338937, -7.533695725648736],
            [-73.98303323342684, -7.534361350649385],
            [-74.03379960847415, -7.484864600603267],
            [-74.03996598347989, -7.448154725569065],
            [-74.1383888585716, -7.39635235052085],
            [-74.18085685861115, -7.358747225485807],
            [-74.25902498368397, -7.344052475472134],
            [-74.31623210873727, -7.351270350478857],
            [-74.46265185887361, -7.298941225430105],
            [-74.50009123390852, -7.262049475395766],
            [-74.55038035895532, -7.166110100306392],
            [-74.6419238590406, -7.187009850325874],
            [-74.64490785904339, -7.30089260043194],
            [-74.71990160911322, -7.321773100451379],
            [-74.8344583592199, -7.293276725424828],
            [-74.87626423425884, -7.231670725367458],
            [-74.85236323423659, -7.158140600298974],
            [-74.87521310925786, -7.125052225268159],
            [-74.83660698422192, -7.071665600218446],
            [-74.7992324841871, -6.976831475130132],
            [-74.85096685923529, -6.91451560007208],
            [-74.89540035927668, -6.833201224996357],
            [-74.90140998428227, -6.732709974902749],
            [-74.95603123433314, -6.685486349858771],
            [-74.97550973435126, -6.554925724737188],
            [-74.97120260934724, -6.516434349701353],
            [-74.9058487342864, -6.426333224617424],
            [-74.88862110927035, -6.319351599517778],
            [-74.86505860924841, -6.272859349474493],
            [-74.86736373425056, -6.222403849427507],
            [-74.78993198417842, -6.155281099364975],
            [-74.77571448416519, -6.105135974318277],
            [-74.7243857341174, -6.074566849289818],
            [-74.69826898409308, -6.027561099246042],
            [-74.68901223408444, -5.960449099183524],
            [-74.76131298415179, -5.926060974151501],
            [-74.80817385919543, -5.929359349154564],
            [-74.88669935926856, -5.866033224095583],
            [-74.96169135933842, -5.90149409912862],
            [-75.04113810941239, -6.004480724224551],
            [-75.14692835951094, -6.039268724256945],
            [-75.18983785955088, -6.073753849289061],
            [-75.32883623468034, -6.099313849312858],
            [-75.39673435974358, -6.082253849296953],
            [-75.52937335986711, -6.122162599334128],
            [-75.56892960990395, -6.00038859922073],
            [-75.56700973490216, -5.958365224181591],
            [-75.53414648487156, -5.902345724129417],
            [-75.51447998485321, -5.739259973977536],
            [-75.4371343597812, -5.725416723964624],
            [-75.38903385973641, -5.691250223932817],
            [-75.30493385965808, -5.686214973928125],
            [-75.20809998456788, -5.617099973863746],
            [-75.1815799845432, -5.623229973869475],
            [-75.06598760943554, -5.499397598754127],
            [-75.01946610939221, -5.496929598751835],
            [-74.96009760933693, -5.519859348773189],
            [-74.9020898592829, -5.470304348727024],
            [-74.82899410921483, -5.45370897371158],
            [-74.63801435903697, -5.354012848618726],
            [-74.61517023401568, -5.304329848572455],
            [-74.57292198397634, -5.264320098535202],
            [-74.41833785883236, -5.206929723481731],
            [-74.3506279837693, -5.153484598431955],
            [-74.24551360867137, -5.136191348415847],
            [-74.17578123360644, -5.079804723363347],
            [-74.17044923360146, -5.032695348319469],
            [-74.13116798356488, -5.015031223303032],
            [-74.08938785852595, -4.969160973260302],
            [-74.03713523347727, -4.946627098239302],
            [-73.9544161084002, -4.981158723271474],
            [-73.93324898338048, -5.01075497329904],
            [-73.89300098334294, -4.955575473247644],
            [-73.87435160832557, -4.88280859817988],
            [-73.84383998329713, -4.871409973169238],
            [-73.71154798317382, -4.735417973042609],
            [-73.64423348311102, -4.714238348022886],
            [-73.58198248305295, -4.618681597933878],
            [-73.5294099830039, -4.584300722901852],
            [-73.46483148294361, -4.515207972837501],
            [-73.43521773291597, -4.446356222773385],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 9,
        FIRST_IDPR: '2208',
        NOMBPROV: 'RIOJA',
        FIRST_NOMB: 'SAN MARTIN',
        LAST_DCTO: 'LEY',
        LAST_LEY: '24040',
        FIRST_FECH: '26/12/1984',
        LAST_FECHA: '26/12/1984',
        MIN_SHAPE_: 265242.99289,
        ha: 265242.99,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.19727536142386, -6.233419974437714],
            [-77.22298511144795, -6.14689597435711],
            [-77.28153336150284, -6.141717599352281],
            [-77.34642798656373, -6.114341099326756],
            [-77.39549761160977, -6.03937672425694],
            [-77.43446298664632, -6.060411724276512],
            [-77.47861523668774, -6.000894474221088],
            [-77.53380886173956, -6.063371349279222],
            [-77.59345636179553, -6.009347349228896],
            [-77.67167486186892, -5.974104349195999],
            [-77.65189061185036, -5.935331974159911],
            [-77.67788286187472, -5.859646599089411],
            [-77.76392911195539, -5.819708724052145],
            [-77.77370711196448, -5.685207098926874],
            [-77.73689061192995, -5.605138848852346],
            [-77.76576173695699, -5.538982473790724],
            [-77.76845123695946, -5.438048723696728],
            [-77.74482773693734, -5.406899098667711],
            [-77.74218898693486, -5.423376473683057],
            [-77.64099998684, -5.526299973778991],
            [-77.65537998685353, -5.580819973829764],
            [-77.62718998682709, -5.643629973888256],
            [-77.57108998677448, -5.708359973948578],
            [-77.50082736170855, -5.671920348914689],
            [-77.40345548661719, -5.699665973940541],
            [-77.29987198652003, -5.76189309899855],
            [-77.25306761147614, -5.803484599037287],
            [-77.22984036145438, -5.89878109912603],
            [-77.19552461142221, -5.938747349163272],
            [-77.14017986137034, -5.944519974168641],
            [-77.15383686138314, -6.016964474236135],
            [-77.14321998637318, -6.100823974314221],
            [-77.12046411135185, -6.159264599368662],
            [-77.12453586135571, -6.20335872440971],
            [-77.19727536142386, -6.233419974437714],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 12,
        FIRST_IDPR: '0106',
        NOMBPROV: 'RODRIGUEZ DE MENDOZA',
        FIRST_NOMB: 'AMAZONAS',
        LAST_DCTO: 'LEY',
        LAST_LEY: '7626',
        FIRST_FECH: '31/10/1932',
        LAST_FECHA: '31/10/1932',
        MIN_SHAPE_: 258853.07366,
        ha: 258853.07,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.53380886173956, -6.063371349279222],
            [-77.47861523668774, -6.000894474221088],
            [-77.43446298664632, -6.060411724276512],
            [-77.39549761160977, -6.03937672425694],
            [-77.34642798656373, -6.114341099326756],
            [-77.28153336150284, -6.141717599352281],
            [-77.22298511144795, -6.14689597435711],
            [-77.19727536142386, -6.233419974437714],
            [-77.13272523636337, -6.302421099501982],
            [-77.14658286137633, -6.351112474547315],
            [-77.17173248639992, -6.381169974575313],
            [-77.27551436149723, -6.384731099578619],
            [-77.32635661154492, -6.468027349656161],
            [-77.3351523615532, -6.522328474706741],
            [-77.39386986160831, -6.602688224781572],
            [-77.43614911164799, -6.605217224783892],
            [-77.38173298659693, -6.697589099869944],
            [-77.45465898666538, -6.681688349855119],
            [-77.54407423674932, -6.676996099850701],
            [-77.56369761176776, -6.644084724820053],
            [-77.62666411182686, -6.612740224790802],
            [-77.72617823692028, -6.552055599734221],
            [-77.73260473692625, -6.453146474642089],
            [-77.7522632369447, -6.403551849595889],
            [-77.6990278618947, -6.335594599532665],
            [-77.63520323683478, -6.316106599514543],
            [-77.61757348681823, -6.28075597448165],
            [-77.56788286177161, -6.309462599508397],
            [-77.5297597367358, -6.245707974449046],
            [-77.46981148667952, -6.182348599390064],
            [-77.49976811170764, -6.153001974362732],
            [-77.50992061171713, -6.086494974300795],
            [-77.53380886173956, -6.063371349279222],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 5,
        FIRST_IDPR: '2110',
        NOMBPROV: 'SAN ANTONIO DE PUTINA',
        FIRST_NOMB: 'PUNO',
        LAST_DCTO: 'DEC.',
        LAST_LEY: 'S/N',
        FIRST_FECH: '17/03/1962',
        LAST_FECHA: '02/05/1854',
        MIN_SHAPE_: 315771.4097,
        ha: 315771.41,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.69096147926986, -14.517818732038062],
            [-69.66109185423952, -14.541843607055302],
            [-69.65812710423032, -14.60509185711308],
            [-69.61220697918121, -14.661820357157417],
            [-69.58175585415665, -14.627220732120056],
            [-69.46268322905406, -14.587139107059677],
            [-69.41272710400591, -14.621109732080015],
            [-69.32480047893462, -14.601415232041195],
            [-69.33320247894542, -14.576415357020325],
            [-69.29757560393105, -14.486793981929262],
            [-69.30313310394921, -14.401556356852522],
            [-69.24528097892032, -14.316819856759874],
            [-69.21860347888777, -14.392373231821809],
            [-69.2204902288807, -14.439273481865273],
            [-69.14270297882271, -14.455073356857278],
            [-69.11391297879665, -14.487802731878357],
            [-69.14060410380975, -14.513434731909982],
            [-69.15003035380332, -14.580314231973988],
            [-69.22004260385489, -14.58155823199542],
            [-69.229672228849, -14.656694982066883],
            [-69.25797960386694, -14.682883982098604],
            [-69.22526472883067, -14.740701982142545],
            [-69.30120372888894, -14.765298982185442],
            [-69.35379685392844, -14.801665732231937],
            [-69.4349999789957, -14.838677732284733],
            [-69.46592760403298, -14.770320357228687],
            [-69.52966385408297, -14.842957482308538],
            [-69.51766297906762, -14.876171357336666],
            [-69.53952110408348, -14.917459107378994],
            [-69.61379885415565, -14.913964857389788],
            [-69.6208961041593, -14.944984732419616],
            [-69.69682622923362, -14.952990232440001],
            [-69.72936935426405, -14.975917357466358],
            [-69.78352810432055, -14.954531732454898],
            [-69.84733985438146, -15.00488873251041],
            [-69.87660935440378, -15.108755857610191],
            [-69.88229685440999, -15.104421107606948],
            [-69.8838971044118, -15.102675607605542],
            [-69.89825697942615, -15.110800982614917],
            [-69.9006582294287, -15.109894732614388],
            [-69.90201347943037, -15.106215732611165],
            [-69.90569260443445, -15.102868732608549],
            [-69.90619047943511, -15.100794107606703],
            [-69.91042272943996, -15.094542607601463],
            [-69.91110610444065, -15.095081732602052],
            [-69.91575835444517, -15.099036107606288],
            [-69.92187772945212, -15.090980607599628],
            [-69.91604210444687, -15.079529357588317],
            [-69.9188964794503, -15.072671232582348],
            [-69.9209508544525, -15.071718607581735],
            [-69.92229072945476, -15.059153982570292],
            [-69.92949610446229, -15.058528732570613],
            [-69.93556997946861, -15.058409607571251],
            [-69.94331122947672, -15.05698048257087],
            [-69.95587597949071, -15.04316523255963],
            [-69.95849610449368, -15.03923498255632],
            [-69.9643103545026, -14.995181232516313],
            [-69.9611573545001, -14.983947107505562],
            [-69.96021560449927, -14.981417982503109],
            [-69.95978510449889, -14.980637732502343],
            [-69.96158147950095, -14.977758232499886],
            [-69.96131872950126, -14.968719607491508],
            [-69.96042535450042, -14.967668607490442],
            [-69.95827685449834, -14.965180357487874],
            [-69.96321497950404, -14.956018482479996],
            [-69.96420560450514, -14.95484210747902],
            [-69.966805979508, -14.952365482477049],
            [-69.97083047951234, -14.949579357474946],
            [-69.97516447951702, -14.9461739824723],
            [-69.96868335451158, -14.927003607453834],
            [-69.96672772950978, -14.923821857450674],
            [-69.96756847951075, -14.922295732449356],
            [-69.97690760452143, -14.905370982434807],
            [-69.97671997952143, -14.902638107432255],
            [-69.97656097952147, -14.899070232428931],
            [-69.97981110452591, -14.882095232413624],
            [-69.98384110453031, -14.877856482410172],
            [-69.98116210452822, -14.8673887324002],
            [-69.97721872952424, -14.866789107399187],
            [-69.97575197952278, -14.866115232398409],
            [-69.97331447952052, -14.862468732394744],
            [-69.96994335451723, -14.860255857392318],
            [-69.96229047950999, -14.851447982383288],
            [-69.94872985449629, -14.849752982380123],
            [-69.93154685447875, -14.850104732378368],
            [-69.92029922946641, -14.863115357389008],
            [-69.9072174794533, -14.860262482384739],
            [-69.89761272944418, -14.851567107375512],
            [-69.90166435444876, -14.844826357369794],
            [-69.90323285445082, -14.838503357364155],
            [-69.90436960445238, -14.832750607358994],
            [-69.85209085440272, -14.792127982314698],
            [-69.85131010440197, -14.791527482314043],
            [-69.8499499794007, -14.79046785731288],
            [-69.84956635440035, -14.790081982312483],
            [-69.84953510440032, -14.789901982312305],
            [-69.8495667294004, -14.789686482312101],
            [-69.85092635440195, -14.787303357310092],
            [-69.85712185440879, -14.778732482303003],
            [-69.85785397940968, -14.77685710730137],
            [-69.85299797940526, -14.771009857295326],
            [-69.85358197940597, -14.769417982293932],
            [-69.85372247940614, -14.768826232293407],
            [-69.85348435440602, -14.767458982292123],
            [-69.85417772940686, -14.7660429822909],
            [-69.85397135440672, -14.764713357289653],
            [-69.85320772940639, -14.759274607284524],
            [-69.85169435440497, -14.757991982283139],
            [-69.84905772940243, -14.756706607281597],
            [-69.84106485439516, -14.748256357272727],
            [-69.83920022939347, -14.745726607270127],
            [-69.83821172939255, -14.744749857269106],
            [-69.83690497939143, -14.74265910726699],
            [-69.83649122939117, -14.740720732265153],
            [-69.83616447939096, -14.739239857263737],
            [-69.83831022939357, -14.733235232258474],
            [-69.83838747939369, -14.73271348225802],
            [-69.83798172939348, -14.730414357255848],
            [-69.84123910439732, -14.722665857249108],
            [-69.84568197940187, -14.720705857247914],
            [-69.84849310440484, -14.718890857246603],
            [-69.84803985440469, -14.714881107242862],
            [-69.84919060440603, -14.712161357240495],
            [-69.85522272941289, -14.70107323223106],
            [-69.8557457294142, -14.690647732221523],
            [-69.85644310441496, -14.689706357220743],
            [-69.8586646044175, -14.685549482217198],
            [-69.85772122941667, -14.683984857215618],
            [-69.85591810441501, -14.68265785721418],
            [-69.85256772941183, -14.680718607211928],
            [-69.85066860441017, -14.677553607208754],
            [-69.85020222940987, -14.675721107206998],
            [-69.85227385441266, -14.666225107198493],
            [-69.85144722941196, -14.66437748219669],
            [-69.84859460440983, -14.655800107188407],
            [-69.85047097941181, -14.654179607187158],
            [-69.85180147941315, -14.6539407321871],
            [-69.85441147941586, -14.652115607185772],
            [-69.8551448544167, -14.65080210718465],
            [-69.85642747941837, -14.64531585717976],
            [-69.85602235441813, -14.643128107177683],
            [-69.8566706044189, -14.64158860717633],
            [-69.85667060441985, -14.629151107164857],
            [-69.85853410442203, -14.624978357161261],
            [-69.85910135442269, -14.623600982160063],
            [-69.85954935442345, -14.619166357156015],
            [-69.85751997942174, -14.61560360715247],
            [-69.8552266044198, -14.611503857148373],
            [-69.85647260442128, -14.608066232145374],
            [-69.85665235442153, -14.607257857144646],
            [-69.85800585442297, -14.606083982143742],
            [-69.8587851044238, -14.604929732142763],
            [-69.85908597942434, -14.601749982139891],
            [-69.85967572942506, -14.599789107138152],
            [-69.8601953544257, -14.598259732136793],
            [-69.85992572942568, -14.59477148213354],
            [-69.86374810442997, -14.58807423212787],
            [-69.86430072943058, -14.587121107127047],
            [-69.86706447943342, -14.585536982125944],
            [-69.86856060443469, -14.587999982128425],
            [-69.86843360443439, -14.590515607130717],
            [-69.86939647943511, -14.593425732133518],
            [-69.87074222943629, -14.595453107135565],
            [-69.87277735443799, -14.59945910713955],
            [-69.87403135443918, -14.59973635713995],
            [-69.87653122944157, -14.601042982141488],
            [-69.87683397944186, -14.601290982141759],
            [-69.87882035444399, -14.598980482139876],
            [-69.88481447945036, -14.5925646071347],
            [-69.88976172945578, -14.585199232128522],
            [-69.88973822945582, -14.584476607127868],
            [-69.88675622945337, -14.577688857121206],
            [-69.89224860445938, -14.569106232113972],
            [-69.89938222946662, -14.566230482112196],
            [-69.90181210446903, -14.565940607112253],
            [-69.9077151044742, -14.57506098212139],
            [-69.90970635447601, -14.57710798212353],
            [-69.91162310447781, -14.578466482125016],
            [-69.91902960448515, -14.578503732125968],
            [-69.92046247948666, -14.577163857124882],
            [-69.92200710448837, -14.574614357122721],
            [-69.93277085449942, -14.568793482118629],
            [-69.93797135450451, -14.569599732119991],
            [-69.94307097950941, -14.57183723212266],
            [-69.95132397951768, -14.570800357122684],
            [-69.956757479523, -14.571796857124227],
            [-69.9615338545276, -14.574010607126812],
            [-69.96287147952896, -14.573542357126538],
            [-69.9707769795367, -14.575303732129079],
            [-69.97951385454493, -14.582597107136785],
            [-69.98449322954981, -14.583790107138448],
            [-69.98582360455113, -14.583790107138599],
            [-69.9875772295529, -14.58366923213868],
            [-69.98859260455394, -14.583318732138462],
            [-69.99106110455662, -14.579656232135363],
            [-69.99160860455724, -14.578791232134622],
            [-69.99197135455762, -14.578650232134537],
            [-69.99857672956443, -14.574712732131621],
            [-69.99918310456519, -14.572429357129582],
            [-70.00033122956644, -14.570803982128208],
            [-70.0019308545681, -14.5700944821277],
            [-70.00371110457004, -14.566972482125031],
            [-70.00345535456987, -14.565865482123963],
            [-70.00900560457578, -14.559993482119134],
            [-70.01091322957778, -14.558493857117947],
            [-70.00935185457621, -14.55841823211771],
            [-70.00305572957035, -14.551782232110916],
            [-70.00330697957068, -14.550650482109893],
            [-70.0006693545686, -14.54172348210133],
            [-69.99610447956435, -14.537327607096763],
            [-69.99370372956213, -14.53469010709409],
            [-69.99113472955989, -14.529388607088896],
            [-69.9900722295589, -14.528285107087758],
            [-69.98924997955815, -14.527302732086769],
            [-69.98756635455659, -14.52568160708507],
            [-69.98636910455551, -14.52400973208339],
            [-69.98508197955428, -14.523423857082724],
            [-69.98161910455076, -14.524929732083711],
            [-69.97908985454825, -14.524474607083002],
            [-69.97820510454746, -14.523453107081972],
            [-69.97620122954586, -14.517466857076212],
            [-69.97450385454435, -14.514931732073688],
            [-69.97373435454361, -14.514474607073168],
            [-69.97171872954165, -14.51424023207271],
            [-69.97051760454043, -14.514484357072831],
            [-69.96809572953804, -14.5146503570727],
            [-69.96711722953707, -14.514445357072395],
            [-69.9661621045362, -14.513302732071223],
            [-69.96549610453569, -14.511173857069188],
            [-69.96522072953543, -14.510824232068824],
            [-69.96202347953252, -14.50743748206534],
            [-69.9613066045318, -14.507322232065146],
            [-69.95770497952836, -14.505308607062876],
            [-69.95691797952759, -14.505701232063156],
            [-69.9564316045271, -14.505537107062963],
            [-69.95599222952671, -14.504935732062341],
            [-69.95404310452487, -14.503904357061163],
            [-69.95334572952426, -14.50244723205973],
            [-69.9529082295239, -14.501605482058912],
            [-69.95286735452389, -14.501261732058582],
            [-69.95266797952374, -14.500572232057905],
            [-69.95207035452326, -14.498785107056207],
            [-69.95178122952301, -14.498382857055812],
            [-69.95065822952193, -14.498165982055474],
            [-69.95000772952139, -14.496027357053414],
            [-69.95004297952156, -14.494642607052137],
            [-69.95000960452157, -14.493630857051196],
            [-69.95013285452173, -14.493462857051044],
            [-69.95030472952193, -14.492878857050526],
            [-69.95040997952204, -14.49262498205032],
            [-69.94866610452041, -14.491384232048956],
            [-69.94487822951668, -14.491712482048827],
            [-69.94064572951253, -14.491229232047873],
            [-69.9331753545054, -14.488303982044322],
            [-69.92048472949324, -14.483893857038712],
            [-69.91885735449168, -14.483683857038319],
            [-69.91530410448813, -14.484575857038735],
            [-69.91050660448352, -14.483710732037343],
            [-69.9050277294784, -14.480250232033466],
            [-69.90369060447706, -14.48069598203372],
            [-69.89973197947322, -14.48048610703303],
            [-69.89718647947082, -14.479039607031417],
            [-69.895256979469, -14.478569857030712],
            [-69.89302547946698, -14.47598610702805],
            [-69.89193447946606, -14.474292732026353],
            [-69.890611354465, -14.47117773202331],
            [-69.89011722946483, -14.466970732019353],
            [-69.8901152294649, -14.466206982018669],
            [-69.8901347294653, -14.460947232013813],
            [-69.88982222946514, -14.458945357011919],
            [-69.89083597946635, -14.455681607009023],
            [-69.8927654794687, -14.449535107003602],
            [-69.89291210446892, -14.448304732002478],
            [-69.89185160446848, -14.440124981994778],
            [-69.89128322946803, -14.43909960699377],
            [-69.89087310446779, -14.436716856991518],
            [-69.89059372946757, -14.436056606990872],
            [-69.88987110446688, -14.435685606990443],
            [-69.88987510446701, -14.434274356989132],
            [-69.80840035438682, -14.455623106998164],
            [-69.77774222935818, -14.448595356987386],
            [-69.72740035430508, -14.50873435703538],
            [-69.69096147926986, -14.517818732038062],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 7,
        FIRST_IDPR: '0609',
        NOMBPROV: 'SAN IGNACIO',
        FIRST_NOMB: 'CAJAMARCA',
        LAST_DCTO: 'LEY',
        LAST_LEY: '15560',
        FIRST_FECH: '12/05/1965',
        LAST_FECHA: '12/05/1965',
        MIN_SHAPE_: 492734.92851,
        ha: 492734.93,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.705955112817, -4.62387309793632],
            [-78.63793773775745, -4.657981847968406],
            [-78.64466136276363, -4.736452348041433],
            [-78.60064648772477, -4.773386723076015],
            [-78.64582023776538, -4.962265598251644],
            [-78.61963073774234, -5.000525098287385],
            [-78.65771411277623, -5.067422973349459],
            [-78.7006242378141, -5.104082723383374],
            [-78.70911323782185, -5.179628848453651],
            [-78.68424411280036, -5.267490223535599],
            [-78.58981148771682, -5.324943598589535],
            [-78.67736523779482, -5.408740223667113],
            [-78.7779296128827, -5.391462098650413],
            [-78.78634698789017, -5.428189223684546],
            [-78.86717811295978, -5.469723848722607],
            [-78.94879348802857, -5.476476598728191],
            [-79.05876948811851, -5.44674809869941],
            [-79.09834961314947, -5.365456973623325],
            [-79.14889448818947, -5.398009723653007],
            [-79.14622461318794, -5.454742223705784],
            [-79.21360161324033, -5.508491723754861],
            [-79.2689609882818, -5.513285098758497],
            [-79.29769923830217, -5.458126973706727],
            [-79.3044003633062, -5.391804723644945],
            [-79.32807223832219, -5.315914098573979],
            [-79.35904886334362, -5.288626973548089],
            [-79.3486523633356, -5.232576223496154],
            [-79.37840811335565, -5.190521473456542],
            [-79.34330661333101, -5.165367098433765],
            [-79.3222988633156, -5.116908223389071],
            [-79.29436123829554, -5.110480473383531],
            [-79.24508598825842, -5.001359348282793],
            [-79.21007036323203, -4.965242223249656],
            [-79.07353711312655, -4.971054723256673],
            [-79.01194723807737, -5.013986348297236],
            [-78.97389061304553, -4.897021473188762],
            [-78.89510336298015, -4.891435598184186],
            [-78.90734948799022, -4.861832598156544],
            [-78.8916854879768, -4.808005848106576],
            [-78.91272798799432, -4.791000973090608],
            [-78.88275198796883, -4.714062473019213],
            [-78.83922011293181, -4.656037847965522],
            [-78.80252398790046, -4.637468597948445],
            [-78.705955112817, -4.62387309793632],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 7,
        FIRST_IDPR: '0610',
        NOMBPROV: 'SAN MARCOS',
        FIRST_NOMB: 'CAJAMARCA',
        LAST_DCTO: 'LEY',
        LAST_LEY: '23508',
        FIRST_FECH: '29/12/1984',
        LAST_FECHA: '11/12/1982',
        MIN_SHAPE_: 135538.271153,
        ha: 135538.27,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.2773828624367, -7.373447350498122],
            [-78.30729686246438, -7.333624975460938],
            [-78.27175198743132, -7.293609350423774],
            [-78.21620898737966, -7.296535225426664],
            [-78.23956248740122, -7.206537100342805],
            [-78.18369448734911, -7.149147475289518],
            [-78.05652723723034, -7.134790975276447],
            [-78.01594536219234, -7.096804725241149],
            [-77.9511497371316, -7.064613475211298],
            [-77.92268923710502, -7.157463475297789],
            [-77.83105023701911, -7.310030975440013],
            [-77.82960248701775, -7.347638600475015],
            [-77.883101612068, -7.364187475490372],
            [-77.94255136212378, -7.345664600473027],
            [-77.9826336121614, -7.387463100511884],
            [-78.01331836219026, -7.462164100581388],
            [-78.08496673725732, -7.454312475573923],
            [-78.13174798730111, -7.480312475598042],
            [-78.17438411234092, -7.457267100576462],
            [-78.21984173738318, -7.387624225511494],
            [-78.2773828624367, -7.373447350498122],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 14,
        FIRST_IDPR: '2209',
        NOMBPROV: 'SAN MARTIN',
        FIRST_NOMB: 'SAN MARTIN',
        LAST_DCTO: 'LEY',
        LAST_LEY: '8268',
        FIRST_FECH: '20/05/1936',
        LAST_FECHA: '08/05/1936',
        MIN_SHAPE_: 552655.875748,
        ha: 552655.88,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.91589186022712, -6.141157849351833],
            [-75.83445061015126, -6.139095474349911],
            [-75.81482986013299, -6.100899974314328],
            [-75.71501998504002, -6.138269974349131],
            [-75.68036998500774, -6.095629974309417],
            [-75.62895998495986, -6.082119974296855],
            [-75.61389448494583, -6.023240224242013],
            [-75.56892960990395, -6.00038859922073],
            [-75.52937335986711, -6.122162599334128],
            [-75.52555498486355, -6.165125599374173],
            [-75.48718760982781, -6.203415349409832],
            [-75.49031085983073, -6.267036224469059],
            [-75.5500949848864, -6.347707974544188],
            [-75.50959660984869, -6.492141349678718],
            [-75.50022848483994, -6.640322224816714],
            [-75.50123285984088, -6.767045724934754],
            [-75.51180235985073, -6.785569599952012],
            [-75.63344223496404, -6.843062350005547],
            [-75.66809735999632, -6.826417349990044],
            [-75.69769873502388, -6.868602725029338],
            [-75.76842948508977, -6.853817850015576],
            [-75.84387536016003, -6.797261724962891],
            [-75.96365161027163, -6.788423224954651],
            [-76.05089573535291, -6.810860224975546],
            [-76.06852386036935, -6.801491349966825],
            [-76.0954082353944, -6.714859224886143],
            [-76.12676998542364, -6.687993099861113],
            [-76.1881924854809, -6.712312224883763],
            [-76.19737061048943, -6.749156974918072],
            [-76.2618952355496, -6.769101474936673],
            [-76.27504961056185, -6.692069099864924],
            [-76.45631798573095, -6.680006349853672],
            [-76.40792761068577, -6.635648099812371],
            [-76.41839373569556, -6.561830224743606],
            [-76.46273048573694, -6.52599022471023],
            [-76.47825586075142, -6.483931599671047],
            [-76.42548236070219, -6.381304349575492],
            [-76.37959398565935, -6.391298349584792],
            [-76.35598861063733, -6.339355474536406],
            [-76.31705611060103, -6.364468724559801],
            [-76.26985136055703, -6.426474974617546],
            [-76.1824129854755, -6.430300599621118],
            [-76.18054973547375, -6.500832099686803],
            [-76.13257886042905, -6.499500349685572],
            [-76.0690168603698, -6.472735224660636],
            [-76.00728198531229, -6.498629474684748],
            [-75.9540593602627, -6.490474599677161],
            [-75.90199773521417, -6.459167849647993],
            [-75.92919573523955, -6.356835099552689],
            [-75.97853473528549, -6.294108974494259],
            [-75.93317423524324, -6.212657974418413],
            [-75.91589186022712, -6.141157849351833],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 13,
        FIRST_IDPR: '0611',
        NOMBPROV: 'SAN MIGUEL',
        FIRST_NOMB: 'CAJAMARCA',
        LAST_DCTO: 'LEY',
        LAST_LEY: '25042',
        FIRST_FECH: '02/01/1857',
        LAST_FECHA: '14/06/1989',
        MIN_SHAPE_: 254550.23539,
        ha: 254550.24,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.91954298801639, -7.210945350342575],
            [-78.9583729880496, -7.187734350320525],
            [-79.0178632381001, -7.179261725311896],
            [-79.05884573813513, -7.240298850368129],
            [-79.12440236318875, -7.180578100311592],
            [-79.25180373829116, -7.198348600325831],
            [-79.3053012383318, -7.147146225277148],
            [-79.32258598834301, -7.025683600163807],
            [-79.2966343633229, -6.999833725140353],
            [-79.3110088633323, -6.90134172504846],
            [-79.2627403632948, -6.859156225010184],
            [-79.22907323826865, -6.86046622501205],
            [-79.20708786325088, -6.82143947497614],
            [-79.14633586320294, -6.86560347501821],
            [-79.0828788631513, -6.87376172502676],
            [-79.04974361312352, -6.836218474992293],
            [-78.98290148806771, -6.862382100017472],
            [-78.936449113028, -6.839579974996807],
            [-78.88322498798227, -6.843542600001039],
            [-78.88694123798516, -6.797179099957846],
            [-78.82063023792688, -6.693844974862296],
            [-78.74616211286165, -6.744611349910167],
            [-78.71841411283702, -6.739673849905801],
            [-78.63838798776567, -6.77781597494184],
            [-78.615083612745, -6.851611600010679],
            [-78.63467573776282, -6.890882850047134],
            [-78.68552148780854, -6.91037497506491],
            [-78.69657423781858, -6.941984350094281],
            [-78.78644923789885, -7.02367572516961],
            [-78.86674223796936, -7.04516597518885],
            [-78.87833198798016, -7.142361350279174],
            [-78.91954298801639, -7.210945350342575],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 4,
        FIRST_IDPR: '0612',
        NOMBPROV: 'SAN PABLO',
        FIRST_NOMB: 'CAJAMARCA',
        LAST_DCTO: 'LEY',
        LAST_LEY: '23336',
        FIRST_FECH: '11/12/1981',
        LAST_FECHA: '11/12/1981',
        MIN_SHAPE_: 66711.1988703,
        ha: 66711.2,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.74599411286411, -7.219331975352074],
            [-78.89762886299755, -7.228392600359065],
            [-78.91954298801639, -7.210945350342575],
            [-78.87833198798016, -7.142361350279174],
            [-78.86674223796936, -7.04516597518885],
            [-78.78644923789885, -7.02367572516961],
            [-78.69657423781858, -6.941984350094281],
            [-78.68552148780854, -6.91037497506491],
            [-78.63467573776282, -6.890882850047134],
            [-78.615083612745, -6.851611600010679],
            [-78.60137873773269, -6.867628850025682],
            [-78.61350611274385, -6.920726350075052],
            [-78.60498236273648, -6.997251850146332],
            [-78.62368361275342, -7.010656225158698],
            [-78.6617148627881, -7.102990225244382],
            [-78.71999611284068, -7.170818350307104],
            [-78.74599411286411, -7.219331975352074],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 4,
        FIRST_IDPR: '2111',
        NOMBPROV: 'SAN ROMAN',
        FIRST_NOMB: 'PUNO',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: '28/02/1958',
        LAST_FECHA: 'EPOCA INDEP.',
        MIN_SHAPE_: 228458.151909,
        ha: 228458.15,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.96831247947176, -15.535706983015968],
            [-69.96684960447003, -15.538064358017959],
            [-69.96402735446671, -15.542070358021304],
            [-69.96569447946808, -15.548596108027533],
            [-69.9662424794687, -15.548692358027704],
            [-69.97323297947638, -15.54718398302716],
            [-69.97355535447679, -15.545885108025997],
            [-69.97419097947753, -15.545434358025656],
            [-69.97571472947914, -15.545996108026364],
            [-69.97769585448125, -15.546772983027326],
            [-69.98189710448575, -15.54781235802879],
            [-69.98334347948712, -15.550711858031638],
            [-69.98480322948862, -15.552285983033267],
            [-69.98626635449016, -15.553106733034197],
            [-69.9880894794921, -15.553708858034973],
            [-69.98997310449437, -15.550422608032157],
            [-69.98792472949238, -15.5462784830281],
            [-69.98763197949216, -15.545131608027006],
            [-69.98816860449283, -15.543412733025477],
            [-69.98869372949343, -15.542993358025148],
            [-69.98987310449476, -15.542324858024674],
            [-69.99250022949765, -15.541940983024634],
            [-69.99404172949929, -15.541982983024852],
            [-69.99558910450098, -15.542276858025302],
            [-69.99558310450075, -15.545455733028238],
            [-69.99572097950082, -15.546553233029256],
            [-69.99887572950414, -15.548778483031702],
            [-70.00404172950948, -15.552975483036171],
            [-70.00990735451595, -15.551856108035823],
            [-70.01100947951707, -15.552545233036597],
            [-70.01421497952032, -15.556757358040851],
            [-70.01201760451791, -15.556918733040742],
            [-70.01016597951596, -15.556347608040003],
            [-70.00999997951577, -15.556339858039973],
            [-69.99998822950468, -15.559285108041536],
            [-70.00017872950481, -15.560943483043081],
            [-70.01862385452466, -15.564340608048367],
            [-70.02807660453469, -15.568446608053241],
            [-70.02915510453569, -15.571382483056075],
            [-70.03138985453792, -15.57493148305961],
            [-70.06409185457152, -15.610928733096488],
            [-70.07017572957744, -15.623849608109083],
            [-70.0858828545939, -15.636189483122173],
            [-70.08583010459385, -15.636290983122265],
            [-70.08374222959125, -15.642462858127745],
            [-70.0894162295975, -15.6414487331274],
            [-70.12340822963482, -15.637888733127626],
            [-70.12344335463482, -15.638732608128414],
            [-70.12377347963512, -15.639492233129145],
            [-70.12445510463587, -15.639810608129515],
            [-70.12643747963799, -15.640376983130231],
            [-70.12664647963823, -15.640456983130331],
            [-70.1270214796386, -15.640636733130528],
            [-70.12810747963975, -15.641310608131265],
            [-70.13165822964329, -15.648343733138127],
            [-70.13298435464458, -15.651269483140954],
            [-70.1342871046459, -15.652966858142662],
            [-70.13503122964669, -15.653857483143561],
            [-70.13820510464998, -15.657716858147431],
            [-70.14221285465403, -15.663886733153541],
            [-70.143126979655, -15.665195358154836],
            [-70.1449961046569, -15.668273483157876],
            [-70.14728710465937, -15.668218733158044],
            [-70.15021485466264, -15.666804733157038],
            [-70.14972460466213, -15.666734358156914],
            [-70.14893360466122, -15.666794983156892],
            [-70.1499141046624, -15.66547660815578],
            [-70.1502382296628, -15.663906233154357],
            [-70.15023635466302, -15.659634733150403],
            [-70.14863672966143, -15.656830108147656],
            [-70.15148060466451, -15.656962858148052],
            [-70.15432622966775, -15.65449610814605],
            [-70.15611322966976, -15.653109358144944],
            [-70.1560683546698, -15.651548858143487],
            [-70.15977535467388, -15.650519608142897],
            [-70.15709760467102, -15.649160108141382],
            [-70.15704685467102, -15.647847483140165],
            [-70.15720310467123, -15.647681608140024],
            [-70.15775385467181, -15.647583983139992],
            [-70.158902354673, -15.648988233141402],
            [-70.1596543546738, -15.649414108141862],
            [-70.161554729676, -15.64684373313968],
            [-70.16342760467805, -15.646738233139748],
            [-70.1630839796778, -15.644400233137569],
            [-70.16319535467791, -15.64437310813754],
            [-70.16547460468055, -15.641322233134943],
            [-70.1661933546813, -15.641599608135278],
            [-70.16674610468182, -15.643183608136797],
            [-70.16789047968312, -15.642185608135964],
            [-70.16819135468349, -15.641453108135323],
            [-70.16754885468282, -15.640865233134722],
            [-70.16929097968489, -15.637392608131682],
            [-70.16668360468213, -15.635632983129803],
            [-70.1662851046817, -15.635119108129288],
            [-70.16637885468185, -15.634570358128789],
            [-70.16737110468296, -15.633521483127911],
            [-70.16774610468343, -15.632572233127064],
            [-70.1692773546851, -15.632706983127337],
            [-70.16964060468545, -15.633052733127693],
            [-70.17133797968727, -15.633892608128624],
            [-70.17037510468631, -15.632425733127194],
            [-70.1704453546864, -15.631642608126464],
            [-70.17206835468818, -15.632171858127114],
            [-70.17299810468933, -15.629251983124503],
            [-70.1733867296898, -15.627607483123017],
            [-70.17349022968993, -15.627527358122947],
            [-70.17519535469171, -15.629318358124769],
            [-70.17549022969202, -15.629351608124823],
            [-70.17834372969524, -15.62691798312284],
            [-70.17954497969656, -15.626636733122705],
            [-70.1804453546975, -15.627515608123591],
            [-70.18220310469934, -15.629212858125324],
            [-70.18307222970027, -15.629328108125515],
            [-70.18418160470148, -15.629025358125348],
            [-70.18478510470221, -15.627816358124287],
            [-70.18566010470319, -15.627072233123675],
            [-70.187259729705, -15.625306608122187],
            [-70.18759572970536, -15.625679733122569],
            [-70.18916797970714, -15.624374983121506],
            [-70.18902935470699, -15.624162108121297],
            [-70.19103510470936, -15.620339858117942],
            [-70.19077147970907, -15.620070358117676],
            [-70.1905586047089, -15.619124983116778],
            [-70.19554097971427, -15.619931608117978],
            [-70.19652147971537, -15.61961910811778],
            [-70.19689447971587, -15.61746685811583],
            [-70.19715435471616, -15.617248108115637],
            [-70.19833197971742, -15.61742385811591],
            [-70.1986874797178, -15.617814483116309],
            [-70.20098635472003, -15.623730483121996],
            [-70.20096872971997, -15.623939483122182],
            [-70.20234185472137, -15.626703108124866],
            [-70.20163085472056, -15.62719135812526],
            [-70.20318560472212, -15.630076233128063],
            [-70.20379097972273, -15.631165983129122],
            [-70.20363285472254, -15.631945233129834],
            [-70.20269535472146, -15.633263733130956],
            [-70.20379097972246, -15.636855483134392],
            [-70.20537310472419, -15.63694923313462],
            [-70.20531060472412, -15.637263608134905],
            [-70.20485160472356, -15.638765608136245],
            [-70.2048339797235, -15.639226608136685],
            [-70.20465435472325, -15.640833983138142],
            [-70.20779885472655, -15.6430663581405],
            [-70.2085859797274, -15.643843733141283],
            [-70.20870897972753, -15.643978483141423],
            [-70.21199810473102, -15.645556608143181],
            [-70.21322072973231, -15.646380858144045],
            [-70.21396297973315, -15.64568560814348],
            [-70.21456635473379, -15.645759733143596],
            [-70.21498822973426, -15.645892608143768],
            [-70.21604297973536, -15.646615233144523],
            [-70.2198574797393, -15.651613233149488],
            [-70.22047847974004, -15.650595733148611],
            [-70.2210937297407, -15.650886733148926],
            [-70.22105272974059, -15.652316358150243],
            [-70.22127147974078, -15.653089858150977],
            [-70.22217972974174, -15.654124983152029],
            [-70.22383197974344, -15.65596685815388],
            [-70.22556635474538, -15.655054733153186],
            [-70.2257382297455, -15.65613473315421],
            [-70.22541597974514, -15.656546858154556],
            [-70.2250351047447, -15.657292983155203],
            [-70.22526372974494, -15.657812483155709],
            [-70.22632422974607, -15.658396483156352],
            [-70.22876947974864, -15.660343733158358],
            [-70.23062885475056, -15.662320358160356],
            [-70.23191797975198, -15.662212858160368],
            [-70.23332622975336, -15.66588673316389],
            [-70.23399810475408, -15.665583983163671],
            [-70.23738672975776, -15.66609185816443],
            [-70.2389707297595, -15.665552733164075],
            [-70.24049985476117, -15.666076233164683],
            [-70.24059572976127, -15.666109358164729],
            [-70.24364260476435, -15.671220733169726],
            [-70.2436972297644, -15.67160748317009],
            [-70.24377147976446, -15.671820358170287],
            [-70.24470510476542, -15.673119108171571],
            [-70.24586910476664, -15.674673858173106],
            [-70.246238229767, -15.675535108173941],
            [-70.26016997978226, -15.673222608172992],
            [-70.26525772978746, -15.680989983180613],
            [-70.26896972979105, -15.692068733191185],
            [-70.2782851048011, -15.693960983193708],
            [-70.27888872980176, -15.69417185819395],
            [-70.27992972980292, -15.693732483193616],
            [-70.28065822980372, -15.693718733193679],
            [-70.28112110480419, -15.694347483194301],
            [-70.28153710480461, -15.6949589831949],
            [-70.28231447980545, -15.695162108195152],
            [-70.283742229807, -15.695447233195532],
            [-70.28347085480667, -15.69618560819619],
            [-70.28397460480714, -15.697873233197791],
            [-70.28498247980816, -15.699771608199633],
            [-70.28599610480923, -15.700701233200576],
            [-70.28755860481093, -15.70095898320095],
            [-70.28836910481178, -15.701845733201845],
            [-70.28854897981198, -15.701964858201956],
            [-70.28922072981271, -15.701753858201817],
            [-70.28958597981305, -15.702701233202736],
            [-70.29135535481484, -15.706566358206452],
            [-70.29149222981496, -15.706923858206792],
            [-70.29895997982274, -15.716100608215891],
            [-70.30294335482684, -15.72251560822214],
            [-70.30350585482739, -15.723390608223],
            [-70.30423247982814, -15.724429733224019],
            [-70.3044003548283, -15.724976608224543],
            [-70.30471672982858, -15.727187483226626],
            [-70.3064512298304, -15.728777358228223],
            [-70.30679497983076, -15.729689483229098],
            [-70.3078106048318, -15.731216858230598],
            [-70.30787097983182, -15.73245898323176],
            [-70.30960735483366, -15.733320358232678],
            [-70.31081247983494, -15.734173858233579],
            [-70.31193560483612, -15.734974608234394],
            [-70.31276372983702, -15.735900358235329],
            [-70.31278510483699, -15.736902358236247],
            [-70.32011322984522, -15.72999785823044],
            [-70.35857572988678, -15.735988983238936],
            [-70.37426285490362, -15.741036483244775],
            [-70.37711885490675, -15.740452983244444],
            [-70.38390672991436, -15.733444358238442],
            [-70.39337360492469, -15.73014973323607],
            [-70.3959486049275, -15.729808983235941],
            [-70.40630285493863, -15.731409608238145],
            [-70.4157548549487, -15.736613483243636],
            [-70.424644354958, -15.746311108253224],
            [-70.42171022995464, -15.751467608257808],
            [-70.41987397995256, -15.755015608260951],
            [-70.42014472995275, -15.757768483263527],
            [-70.4270136049599, -15.767276983272804],
            [-70.42866747996165, -15.768246108273821],
            [-70.43146985496368, -15.800245483303655],
            [-70.42129485495227, -15.811939483313788],
            [-70.42119847995215, -15.812473483314275],
            [-70.42145710495213, -15.822419858323514],
            [-70.43072797996187, -15.832076983333103],
            [-70.4314508549626, -15.833549358334515],
            [-70.42755722995794, -15.847177108346868],
            [-70.43090185496139, -15.85352723335298],
            [-70.43299185496353, -15.85805235835732],
            [-70.44616947997669, -15.895728858393113],
            [-70.45089672998175, -15.898364233395878],
            [-70.45630135498749, -15.901586858399227],
            [-70.46274972999443, -15.904195983402056],
            [-70.4729507300054, -15.907009733405333],
            [-70.47739610501017, -15.90921610840768],
            [-70.47927798001217, -15.91119548340962],
            [-70.48427673001734, -15.919639733417773],
            [-70.48445248001747, -15.921629108419628],
            [-70.48317173001595, -15.925731733423353],
            [-70.4820684800147, -15.927775983425176],
            [-70.47989460501192, -15.942433233438614],
            [-70.48426673001646, -15.949685608445607],
            [-70.48921698002177, -15.951787858447867],
            [-70.5012874800347, -15.957416108453852],
            [-70.51656623005074, -15.976244483472248],
            [-70.5198183550543, -15.97580660847206],
            [-70.53933748007542, -15.97656748347395],
            [-70.54152335507774, -15.977346233474787],
            [-70.54480148008128, -15.977751858475363],
            [-70.54775810508445, -15.978045608475796],
            [-70.55639060509372, -15.980901483478961],
            [-70.55789073009531, -15.982471233480512],
            [-70.55975210509732, -15.982731858480848],
            [-70.5628899801007, -15.982499983480826],
            [-70.64331835518708, -15.989736358491934],
            [-70.7054023552526, -16.03175785853398],
            [-70.76863998032015, -16.016799983522958],
            [-70.8201981053753, -15.978467983489526],
            [-70.820869480376, -15.977913858489034],
            [-70.82801060538353, -15.97825523348963],
            [-70.83437348039028, -15.97437285848628],
            [-70.8372344803933, -15.974691358486702],
            [-70.84130860539763, -15.971826608484191],
            [-70.8402328553967, -15.958013483471323],
            [-70.83934023039578, -15.95686723347023],
            [-70.83673098039309, -15.954703358468104],
            [-70.8359451053924, -15.945914233459925],
            [-70.83669285539327, -15.940960858455348],
            [-70.83683010539342, -15.940015733454477],
            [-70.8375473553942, -15.939167983453716],
            [-70.84111023039807, -15.930231233445568],
            [-70.84387973040103, -15.926207483441935],
            [-70.84327698040043, -15.923255858439164],
            [-70.84533835540263, -15.921928483438025],
            [-70.890541105451, -15.851253483374048],
            [-70.79725585535441, -15.780317608304662],
            [-70.6756276052258, -15.789271733307583],
            [-70.63381573018235, -15.744907233264339],
            [-70.57538135512063, -15.721438108239463],
            [-70.46536623000469, -15.65415698317054],
            [-70.43577122997218, -15.676841233189602],
            [-70.36518822989653, -15.662290358171182],
            [-70.25733422978422, -15.551551733060146],
            [-70.34296285488146, -15.41637110794177],
            [-70.30533210484242, -15.386123107910892],
            [-70.27426347981216, -15.314284357841919],
            [-70.17689497970518, -15.382839982896938],
            [-70.15690035468424, -15.373011732885978],
            [-70.1447911046715, -15.368552357880711],
            [-70.13419722965962, -15.378935607889277],
            [-70.08581785460804, -15.373529607879401],
            [-70.07296535459503, -15.360560857866071],
            [-70.03092910454819, -15.391542107890135],
            [-70.02545447954192, -15.398367732895831],
            [-70.0225176045387, -15.399031107896116],
            [-70.02904110454237, -15.455591982949098],
            [-70.00378347951425, -15.470411732959896],
            [-69.96831247947176, -15.535706983015968],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 8,
        FIRST_IDPR: '1309',
        NOMBPROV: 'SANCHEZ CARRION',
        FIRST_NOMB: 'LA LIBERTAD',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: '03/11/1900',
        LAST_FECHA: 'EPOCA INDEP.',
        MIN_SHAPE_: 250187.37675,
        ha: 250187.38,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.74598273693927, -7.479159725597616],
            [-77.70093161189698, -7.59353710070415],
            [-77.65358898685254, -7.770457100868973],
            [-77.65647736185528, -7.797888350894523],
            [-77.62362023682441, -7.876542475967778],
            [-77.64081598684061, -7.983332976067213],
            [-77.70368423689975, -7.960748851046127],
            [-77.80385161199395, -8.007515601089569],
            [-77.83827448702633, -7.975176601059418],
            [-77.88549211207072, -7.998049476080665],
            [-77.95883748713965, -8.001077726083361],
            [-78.00426573718228, -7.97437610105839],
            [-78.03269498720888, -7.906919350995526],
            [-78.07302348724677, -7.944253851030212],
            [-78.13286436230284, -7.957348851042252],
            [-78.19297461235908, -7.937111476023243],
            [-78.2082851123733, -7.904318350992638],
            [-78.25300586241505, -7.886529350975925],
            [-78.25231836241429, -7.837175725929978],
            [-78.27334536243379, -7.784910100881229],
            [-78.26115436242236, -7.754023975852518],
            [-78.22465311238835, -7.759331475857596],
            [-78.11605336228669, -7.654007350759825],
            [-78.08122111225416, -7.696168850799141],
            [-77.9986422371767, -7.679073975783405],
            [-77.91278073709607, -7.624903475733109],
            [-77.87043748705628, -7.585330100696316],
            [-77.84416798703154, -7.514380850630297],
            [-77.74598273693927, -7.479159725597616],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 10,
        FIRST_IDPR: '2112',
        NOMBPROV: 'SANDIA',
        FIRST_NOMB: 'PUNO',
        LAST_DCTO: 'LEY',
        LAST_LEY: '28509',
        FIRST_FECH: '02/05/1854',
        LAST_FECHA: '13/05/2005',
        MIN_SHAPE_: 1277741.68708,
        ha: 1277741.69,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.64986322935322, -13.341716855946913],
            [-69.31059960414954, -13.195783230747452],
            [-68.87187322906549, -13.003925855440778],
            [-68.85438897904996, -13.060735355485933],
            [-68.87451897903476, -13.115675105543623],
            [-68.84545147899756, -13.245914105651163],
            [-68.91724972894029, -13.48652635589766],
            [-68.99300785392009, -13.649857481073454],
            [-69.06103010394405, -13.656957981102291],
            [-69.06611910393933, -13.688314481132549],
            [-68.97708010388851, -13.758154356166848],
            [-68.98063872888366, -13.783330106191055],
            [-68.91774385385511, -13.813664231196073],
            [-68.95138572884954, -13.879091106268081],
            [-68.9821344788575, -13.894061231292655],
            [-68.97809747883556, -13.978036856367861],
            [-68.93740035380745, -14.025427731396388],
            [-68.89564447878799, -14.041259731395039],
            [-68.85617972874134, -14.162667981489896],
            [-68.82779885371582, -14.2194199815298],
            [-68.96240622876957, -14.22005473158293],
            [-69.01524335377397, -14.314709231687916],
            [-68.98540660374371, -14.374663356732144],
            [-69.0309261037575, -14.42608985679494],
            [-69.08394797878452, -14.451795606835972],
            [-69.11391297879665, -14.487802731878357],
            [-69.14270297882271, -14.455073356857278],
            [-69.2204902288807, -14.439273481865273],
            [-69.21860347888777, -14.392373231821809],
            [-69.24528097892032, -14.316819856759874],
            [-69.30313310394921, -14.401556356852522],
            [-69.29757560393105, -14.486793981929262],
            [-69.33320247894542, -14.576415357020325],
            [-69.32480047893462, -14.601415232041195],
            [-69.41272710400591, -14.621109732080015],
            [-69.46268322905406, -14.587139107059677],
            [-69.58175585415665, -14.627220732120056],
            [-69.61220697918121, -14.661820357157417],
            [-69.65812710423032, -14.60509185711308],
            [-69.66109185423952, -14.541843607055302],
            [-69.69096147926986, -14.517818732038062],
            [-69.65978047924727, -14.453333731973544],
            [-69.72407310431035, -14.418077731951279],
            [-69.72786047932021, -14.349689356888783],
            [-69.78323335437511, -14.311986856862077],
            [-69.7993657293939, -14.268325731824023],
            [-69.85092422944277, -14.26271610682574],
            [-69.80589897941547, -14.082832481653659],
            [-69.76319535438134, -14.030593731599518],
            [-69.72519335434862, -14.018781231583118],
            [-69.72319997935077, -13.976969981544224],
            [-69.66349960430836, -13.884636231449893],
            [-69.73282210437225, -13.83609398141564],
            [-69.78574385442563, -13.75072148134426],
            [-69.75371960439915, -13.737892231327988],
            [-69.74946272940174, -13.669223606263982],
            [-69.72653122938573, -13.633746106227946],
            [-69.71313522938681, -13.505575481107686],
            [-69.68163685436197, -13.499835481097707],
            [-69.63275610433335, -13.406301981003786],
            [-69.64986322935322, -13.341716855946913],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 9,
        FIRST_IDPR: '0218',
        NOMBPROV: 'SANTA',
        FIRST_NOMB: 'ANCASH',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: '15/04/1955',
        LAST_FECHA: 'EPOCA INDEP.',
        MIN_SHAPE_: 398234.452448,
        ha: 398234.45,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.03716761221472, -9.243706977240347],
            [-78.05609948723257, -9.274701227269121],
            [-78.14388873731525, -9.267190102261909],
            [-78.21128461237856, -9.208968102207454],
            [-78.26080436242509, -9.200922727199771],
            [-78.35965436251797, -9.263016977257163],
            [-78.40381236255959, -9.347127852335232],
            [-78.42789448758212, -9.349300727337123],
            [-78.47506448762613, -9.331539102320294],
            [-78.50898236265755, -9.27881447727102],
            [-78.48681048763663, -9.203347602200873],
            [-78.50830073765654, -9.18038285217938],
            [-78.57224223771588, -9.171570352170706],
            [-78.56126948770547, -9.116152477119208],
            [-78.58265036272512, -9.084412102089521],
            [-78.62479098776406, -9.07807422708329],
            [-78.64492386278206, -8.969228476981794],
            [-78.59166248773285, -8.945999226960573],
            [-78.57648473771854, -8.889046601907664],
            [-78.5882811127293, -8.85867747687933],
            [-78.56534311270782, -8.792598976817976],
            [-78.518138737664, -8.752980601781411],
            [-78.44822836259907, -8.736926726766884],
            [-78.35481048751181, -8.67327935170808],
            [-78.23557823740028, -8.652919976689619],
            [-78.18470698735264, -8.694023476728054],
            [-78.1058027372785, -8.659820351696437],
            [-78.00884173718735, -8.666978476703338],
            [-77.97202736215273, -8.692001976726722],
            [-78.01459411219287, -8.76635597679589],
            [-77.99724923717663, -8.845853351869945],
            [-77.9403059871231, -8.926109476944807],
            [-77.94352948712617, -8.97489897699023],
            [-77.99716311217675, -9.017221852029511],
            [-78.05932573723538, -9.086125727093547],
            [-78.06222286223814, -9.128433977132914],
            [-78.02132686219967, -9.171457102173076],
            [-78.06681148724255, -9.185971477186486],
            [-78.03716761221472, -9.243706977240347],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 11,
        FIRST_IDPR: '0613',
        NOMBPROV: 'SANTA CRUZ',
        FIRST_NOMB: 'CAJAMARCA',
        LAST_DCTO: 'LEY',
        LAST_LEY: '9607',
        FIRST_FECH: '21/04/1950',
        LAST_FECHA: '18/09/1942',
        MIN_SHAPE_: 138620.15732,
        ha: 138620.16,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-79.20708786325088, -6.82143947497614],
            [-79.12084773818134, -6.776969974936145],
            [-79.14156211319724, -6.70154172486567],
            [-79.21379098825454, -6.695630849858996],
            [-79.22906036326614, -6.671405974836204],
            [-79.24023898827411, -6.617656599785997],
            [-79.11840236317775, -6.628496099798054],
            [-79.05594336312562, -6.516363224694615],
            [-78.98917573807005, -6.512015599691376],
            [-78.95234373803912, -6.527244099705961],
            [-78.82326261292815, -6.525738974705821],
            [-78.77751361288836, -6.564289099742084],
            [-78.75801948787156, -6.632123099805367],
            [-78.7694166128817, -6.650173224822096],
            [-78.71841411283702, -6.739673849905801],
            [-78.74616211286165, -6.744611349910167],
            [-78.82063023792688, -6.693844974862296],
            [-78.88694123798516, -6.797179099957846],
            [-78.88322498798227, -6.843542600001039],
            [-78.936449113028, -6.839579974996807],
            [-78.98290148806771, -6.862382100017472],
            [-79.04974361312352, -6.836218474992293],
            [-79.0828788631513, -6.87376172502676],
            [-79.14633586320294, -6.86560347501821],
            [-79.20708786325088, -6.82143947497614],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 8,
        FIRST_IDPR: '1310',
        NOMBPROV: 'SANTIAGO DE CHUCO',
        FIRST_NOMB: 'LA LIBERTAD',
        LAST_DCTO: 'LEY',
        LAST_LEY: '2338',
        FIRST_FECH: 'EPOCA INDEP.',
        LAST_FECHA: '13/11/1916',
        MIN_SHAPE_: 267531.56582,
        ha: 267531.57,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.23557823740028, -8.652919976689619],
            [-78.28263773744413, -8.571934101614016],
            [-78.2636984874262, -8.467382351516754],
            [-78.3476269875046, -8.474464851522999],
            [-78.3742656125294, -8.45963672650909],
            [-78.37388948752887, -8.4045436014578],
            [-78.42985448758078, -8.360237976416268],
            [-78.41205661256407, -8.304135101364116],
            [-78.3773027375318, -8.310181476369927],
            [-78.30069998746028, -8.236337726301475],
            [-78.3041429874633, -8.168585351238383],
            [-78.32439261248214, -8.154115226224826],
            [-78.28868236244867, -8.062235476139433],
            [-78.31998573747782, -8.057539976134933],
            [-78.40614773755767, -7.990096476071772],
            [-78.3204667374779, -7.905947225993766],
            [-78.25300586241505, -7.886529350975925],
            [-78.2082851123733, -7.904318350992638],
            [-78.19297461235908, -7.937111476023243],
            [-78.13286436230284, -7.957348851042252],
            [-78.07302348724677, -7.944253851030212],
            [-78.03269498720888, -7.906919350995526],
            [-78.00426573718228, -7.97437610105839],
            [-77.95883748713965, -8.001077726083361],
            [-77.88549211207072, -7.998049476080665],
            [-77.83827448702633, -7.975176601059418],
            [-77.80385161199395, -8.007515601089569],
            [-77.70368423689975, -7.960748851046127],
            [-77.64081598684061, -7.983332976067213],
            [-77.64736723684679, -8.049708976129017],
            [-77.71942973691459, -8.073445351151054],
            [-77.8104413620002, -8.067734976145642],
            [-77.89790423708247, -8.076015601153246],
            [-77.93321286211575, -8.197009726265852],
            [-78.01863473719604, -8.224316476291124],
            [-78.06646123724101, -8.277585476340612],
            [-78.11594998728752, -8.310099976370768],
            [-78.13749798730781, -8.368667976425256],
            [-78.13946873730977, -8.427831976480325],
            [-78.17365623734196, -8.50353710155071],
            [-78.21687873738257, -8.556917976600289],
            [-78.19352536236076, -8.60011135164059],
            [-78.23557823740028, -8.652919976689619],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 8,
        FIRST_IDPR: '1206',
        NOMBPROV: 'SATIPO',
        FIRST_NOMB: 'JUNIN',
        LAST_DCTO: 'LEY',
        LAST_LEY: '9801',
        FIRST_FECH: '26/03/1965',
        LAST_FECHA: '29/01/1943',
        MIN_SHAPE_: 1914201.50745,
        ha: 1914201.51,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.97293748434889, -11.438175979285017],
            [-75.02840423440053, -11.350232479203106],
            [-75.04735135941817, -11.301642354157856],
            [-74.96823998434449, -11.300299854156622],
            [-74.89121998427278, -11.27062997912897],
            [-74.91104998429124, -11.213079979075378],
            [-74.87693998425947, -11.155669979021908],
            [-74.74593998413746, -11.067429978939735],
            [-74.72964085912228, -11.017230353893014],
            [-74.64742098404571, -11.009049603885373],
            [-74.62261798402263, -10.975863228854484],
            [-74.6974399840923, -10.955359978835384],
            [-74.70017385909485, -10.877892728763243],
            [-74.64929823404745, -10.86707072875316],
            [-74.61410248401468, -10.904625478788121],
            [-74.5729951089764, -10.91260197879554],
            [-74.50838373391623, -10.950299728830647],
            [-74.50008098390849, -10.995998478873222],
            [-74.44541048385757, -11.004852478881471],
            [-74.37365473379074, -11.056522353929575],
            [-74.25643598368156, -11.018222728893917],
            [-74.20147460863036, -11.019663728895253],
            [-74.09177835852817, -10.986814603864666],
            [-74.0054982334478, -10.993192603870618],
            [-73.97873848342287, -11.070033103942157],
            [-73.97827173342243, -11.120626978989277],
            [-73.91648723336485, -11.14310197901021],
            [-73.86130360831343, -11.14553022901247],
            [-73.86657348331835, -11.037352853911724],
            [-73.85411035830674, -10.954905978834947],
            [-73.8234072332781, -10.921072728803427],
            [-73.81421685826955, -10.79989647869059],
            [-73.75720010821641, -10.73590472863101],
            [-73.6961931081595, -10.773029228665553],
            [-73.6103962330795, -10.846573853734071],
            [-73.56026148303272, -10.860609228747126],
            [-73.5714909830432, -10.958093728837918],
            [-73.54257873301621, -11.047424478921112],
            [-73.48260348296024, -11.065242228937686],
            [-73.45009860792992, -11.224749479086228],
            [-73.46011023293927, -11.302711354158843],
            [-73.48408748296166, -11.31934397917434],
            [-73.41974948290158, -11.383938229234493],
            [-73.47971623295757, -11.4713638543159],
            [-73.44972760792957, -11.531943104372335],
            [-73.55965098303216, -11.592050979428304],
            [-73.60826960807752, -11.660160104491741],
            [-73.57448123304597, -11.73414547956065],
            [-73.57750510804881, -11.836484729655934],
            [-73.49053160796767, -11.878170479694747],
            [-73.44113498292157, -11.944200104756256],
            [-73.43769435791835, -11.989631854798581],
            [-73.39788485788118, -12.020943479827729],
            [-73.40768910789032, -12.065989729869662],
            [-73.36188735784756, -12.135964729934836],
            [-73.44606060792616, -12.160289104957476],
            [-73.46964848294819, -12.219966480013085],
            [-73.57432635804585, -12.276842355066027],
            [-73.59046535806092, -12.376944230159253],
            [-73.63715048310446, -12.378367980160583],
            [-73.70781423317035, -12.267907230057714],
            [-73.73694073319751, -12.266786980056674],
            [-73.80189398325805, -12.22029710501337],
            [-73.84324998329662, -12.239429980031197],
            [-73.92383685837173, -12.224749230017514],
            [-73.98045160842446, -12.242588355034144],
            [-74.09835723353432, -12.284913605073564],
            [-74.12143948355585, -12.333601480118912],
            [-74.18761823361747, -12.314375980101003],
            [-74.27051573369468, -12.239144730030949],
            [-74.32187298374251, -12.229777105022206],
            [-74.34594560876494, -12.173736729970017],
            [-74.35238410877093, -12.147764979945846],
            [-74.41952023383345, -12.093775854895554],
            [-74.45553598386702, -12.0335787298395],
            [-74.4883772338976, -12.03319597983913],
            [-74.54899948395406, -11.99699035480542],
            [-74.50764848391556, -11.987081979796201],
            [-74.48669535889603, -11.942206979754411],
            [-74.53600785894197, -11.878362979694948],
            [-74.57788860898096, -11.882384729698707],
            [-74.59669985899848, -11.818498104639215],
            [-74.64848935904669, -11.7378596045641],
            [-74.64254073404116, -11.700954104529727],
            [-74.72101460911423, -11.664661104495925],
            [-74.72514173411811, -11.61399847944875],
            [-74.82024623420666, -11.588361979424883],
            [-74.91707885929686, -11.528412354369053],
            [-74.94148173431958, -11.56487347940299],
            [-74.97496173435076, -11.550761729389851],
            [-74.95414285933138, -11.503947354346256],
            [-74.91598310929585, -11.48821822933161],
            [-74.97293748434889, -11.438175979285017],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 6,
        FIRST_IDPR: '2008',
        NOMBPROV: 'SECHURA',
        FIRST_NOMB: 'PIURA',
        LAST_DCTO: 'LEY',
        LAST_LEY: '15417',
        FIRST_FECH: '15/06/1920',
        LAST_FECHA: '29/01/1965',
        MIN_SHAPE_: 648768.63496,
        ha: 648768.63,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.70542973849851, -5.429529348604976],
            [-80.63532811355705, -5.450374973631947],
            [-80.6367069885593, -5.486080098664634],
            [-80.55258011361354, -5.492269473678967],
            [-80.53462886363204, -5.601605473781346],
            [-80.32297273870424, -5.679326223871158],
            [-80.29120111370783, -5.677925723872216],
            [-80.20768948871296, -5.721476598918171],
            [-80.10908011370735, -5.787798723985564],
            [-80.05298436369806, -5.811244099010406],
            [-80.1308632387153, -5.888665974077644],
            [-80.19891411372186, -5.892999974077376],
            [-80.32741411372082, -5.947441349118387],
            [-80.37621873871724, -6.006968724169357],
            [-80.62713286365211, -6.372080099480027],
            [-80.79791986350864, -6.288984349380743],
            [-80.91313473836394, -6.190584099272527],
            [-81.0915663630655, -6.082027349140668],
            [-81.1492597379333, -5.98124022403718],
            [-81.14928911291626, -5.889230473953702],
            [-81.09746098801197, -5.838173723917926],
            [-81.0684472380581, -5.785746098875862],
            [-81.02169723814796, -5.830556598925263],
            [-80.94314448827328, -5.845679723952578],
            [-80.8914882383394, -5.809820348928086],
            [-80.86124411337, -5.749665973877685],
            [-80.85254686336641, -5.635488223774531],
            [-80.89758398829228, -5.511453098654599],
            [-80.93021873823882, -5.457523473600446],
            [-80.9939511131289, -5.388460973527427],
            [-80.96149023817135, -5.311046848462039],
            [-80.70542973849851, -5.429529348604976],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 10,
        FIRST_IDPR: '0219',
        NOMBPROV: 'SIHUAS',
        FIRST_NOMB: 'ANCASH',
        LAST_DCTO: 'LEY',
        LAST_LEY: '14152',
        FIRST_FECH: '14/03/1964',
        LAST_FECHA: '22/06/1962',
        MIN_SHAPE_: 145769.400515,
        ha: 145769.4,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.59274311179566, -8.757665226788385],
            [-77.61437948681599, -8.750993601782152],
            [-77.72073023691607, -8.65653147669407],
            [-77.68866061188587, -8.576356976619445],
            [-77.7041566119004, -8.50987122655752],
            [-77.76481473695748, -8.440994476493323],
            [-77.79885936198949, -8.381187351437573],
            [-77.7459788619397, -8.363273601420945],
            [-77.70568123690177, -8.309384601370795],
            [-77.66629011186471, -8.312034601373314],
            [-77.63563473683584, -8.269212976333455],
            [-77.56581448677015, -8.237208976303696],
            [-77.49136848670017, -8.282553101345995],
            [-77.45889636166964, -8.335655976395476],
            [-77.43889623665086, -8.419862351473908],
            [-77.40546061161945, -8.44148335149405],
            [-77.40033773661465, -8.48196685153173],
            [-77.36601748658238, -8.507086976555168],
            [-77.48992136169885, -8.590674976632936],
            [-77.50155748670981, -8.614356101654982],
            [-77.49647923670504, -8.697020976731991],
            [-77.53867711174475, -8.701750351736349],
            [-77.59274311179566, -8.757665226788385],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 11,
        FIRST_IDPR: '0509',
        NOMBPROV: 'SUCRE',
        FIRST_NOMB: 'AYACUCHO',
        LAST_DCTO: 'LEY',
        LAST_LEY: '15231',
        FIRST_FECH: '08/07/1964',
        LAST_FECHA: '23/11/1964',
        MIN_SHAPE_: 178432.945025,
        ha: 178432.95,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.74788773320773, -13.763366106450404],
            [-73.697779358161, -13.847415981528691],
            [-73.67915035814366, -13.916318356592841],
            [-73.65647073312249, -13.92995123160554],
            [-73.6183222330869, -14.026134731695112],
            [-73.61110160808018, -14.088718731753378],
            [-73.51224998298795, -14.26815823192049],
            [-73.5338354830081, -14.354294107000687],
            [-73.58174998305279, -14.416134732058289],
            [-73.6952889831587, -14.326589981974895],
            [-73.73825623319874, -14.338163856985684],
            [-73.79882823325521, -14.293944606944502],
            [-73.89658398334632, -14.262831981915538],
            [-73.87925460833016, -14.216695481872575],
            [-73.95003398339614, -14.185727106843746],
            [-73.96601323341103, -14.137040481798385],
            [-73.9120156083607, -14.11962898178219],
            [-73.88539448333589, -13.99643748166747],
            [-73.86305360831507, -13.937678981612743],
            [-73.8260496082806, -13.91453985659118],
            [-73.80451948326052, -13.864484356544574],
            [-73.80332273325942, -13.796138981480915],
            [-73.74788773320773, -13.763366106450404],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 8,
        FIRST_IDPR: '2006',
        NOMBPROV: 'SULLANA',
        FIRST_NOMB: 'PIURA',
        LAST_DCTO: 'LEY',
        LAST_LEY: '2603',
        FIRST_FECH: '10/11/1950',
        LAST_FECHA: '03/12/1917',
        MIN_SHAPE_: 532185.75359,
        ha: 532185.75,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.84934373832986, -5.298361348466718],
            [-80.85959761331469, -5.281361348449791],
            [-80.84816011330781, -5.093130848279253],
            [-80.85246873828174, -4.909433598110791],
            [-80.92429886317672, -4.893390598086655],
            [-80.95375586312618, -4.863189473055014],
            [-80.93192973815424, -4.804921848004936],
            [-80.96825786309073, -4.771792972969685],
            [-80.84821286327005, -4.748371097964135],
            [-80.87247661323015, -4.688427722906399],
            [-80.92944336313812, -4.632871097848367],
            [-80.89095698819324, -4.595468722819212],
            [-80.91944136314135, -4.520330097747055],
            [-80.88042386319772, -4.491527347725638],
            [-80.85230861322698, -4.383066347629897],
            [-80.9377051130866, -4.291087847535827],
            [-80.90892773812644, -4.224523472478638],
            [-80.93638473807678, -4.178242222433163],
            [-80.8828222381637, -4.19280072245271],
            [-80.80330661327326, -4.175966847445973],
            [-80.70032036339256, -4.187800597466814],
            [-80.66645111342764, -4.21505884749484],
            [-80.62322073846691, -4.231779347513922],
            [-80.56083011351058, -4.174701222466481],
            [-80.52434573853044, -4.107414097407347],
            [-80.48733198855084, -4.0831288473876],
            [-80.44932036357282, -4.125249972428938],
            [-80.43427348858373, -4.195937472495046],
            [-80.3712889886082, -4.197554722500514],
            [-80.32883011362199, -4.223132847526629],
            [-80.36717573861388, -4.282050722578687],
            [-80.42672461359489, -4.336583972625119],
            [-80.45377736358574, -4.385615222668454],
            [-80.43879298859669, -4.456439472734748],
            [-80.38954298861746, -4.486111347765427],
            [-80.28987698864074, -4.433238222722817],
            [-80.23380473864897, -4.441824222733854],
            [-80.20148048865678, -4.566990222851233],
            [-80.22142573865689, -4.595451222876476],
            [-80.24885736365394, -4.585107472865399],
            [-80.35667386363595, -4.63683784790662],
            [-80.3711073636341, -4.677949222943564],
            [-80.3431347386435, -4.710331972975297],
            [-80.3615038636428, -4.783755848041838],
            [-80.4188691136272, -4.828960973079456],
            [-80.50742973858719, -4.823369098067441],
            [-80.54005073857003, -4.839789098079817],
            [-80.60601948853348, -4.929009723155887],
            [-80.69769336346074, -4.960085973175205],
            [-80.70032423846358, -5.017623098227697],
            [-80.74463086343128, -5.130072223325839],
            [-80.8055194883744, -5.220919973401637],
            [-80.82769923834842, -5.225009723402517],
            [-80.84934373832986, -5.298361348466718],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 10,
        FIRST_IDPR: '2301',
        NOMBPROV: 'TACNA',
        FIRST_NOMB: 'TACNA',
        LAST_DCTO: 'LEY',
        LAST_LEY: '13238',
        FIRST_FECH: '02/02/2001',
        LAST_FECHA: '08/06/1959',
        MIN_SHAPE_: 824806.295563,
        ha: 824806.3,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-69.4683788537011, -17.464798859696753],
            [-69.46829297869643, -17.504169859732645],
            [-69.66541210394577, -17.65990035992049],
            [-69.79598822911971, -17.646242234933172],
            [-69.82119722914977, -17.686355484974403],
            [-69.82167772914633, -17.743812485027227],
            [-69.80004885411651, -17.762331985040383],
            [-69.79571485410347, -17.862257860131233],
            [-69.74717772903223, -17.94393748519696],
            [-69.75150385403488, -17.985589860235955],
            [-69.81308010410879, -18.114478485365602],
            [-69.85430872916092, -18.16534761041959],
            [-69.96054885429687, -18.262884735526615],
            [-70.05070697941487, -18.268802735545332],
            [-70.1496523545401, -18.318367235604008],
            [-70.28680472971297, -18.30810548561023],
            [-70.35387697979493, -18.326193360633663],
            [-70.37671110482206, -18.350927735658665],
            [-70.4812891049501, -18.273412110596208],
            [-70.67857423018233, -18.16184761050705],
            [-70.75945698027533, -18.080417985436323],
            [-70.81315623033596, -18.040488235402144],
            [-70.87918948040935, -18.01475386038148],
            [-70.88947260542113, -17.97651561034647],
            [-70.86333998039254, -17.96679998533626],
            [-70.83929498036679, -17.91151761028387],
            [-70.72931635524641, -17.785091860161],
            [-70.69741210521089, -17.767781235143158],
            [-70.68306460519563, -17.71962461009771],
            [-70.60841798011232, -17.656283235034458],
            [-70.50889648000084, -17.545339859924947],
            [-70.44683260492975, -17.515907234893017],
            [-70.42828322990907, -17.483341734861426],
            [-70.34578710481297, -17.460740234833548],
            [-70.33179522979479, -17.50931635987716],
            [-70.23745260467882, -17.58966735994218],
            [-70.17143160459857, -17.587447234932974],
            [-70.14338672956217, -17.63171285997056],
            [-70.15372260457218, -17.693400360028647],
            [-70.1895839796145, -17.7372694850732],
            [-70.19330272961749, -17.775636735108982],
            [-70.13761122954867, -17.772665985099803],
            [-70.02030660440715, -17.661583984982464],
            [-69.98322460436387, -17.597140609917982],
            [-69.89406835425119, -17.582773484891383],
            [-69.81955472916428, -17.45465035976145],
            [-69.72316010403901, -17.461046859749867],
            [-69.64634010394303, -17.410299984688056],
            [-69.60320997888768, -17.39665998466636],
            [-69.53879997880166, -17.405969984660302],
            [-69.4683788537011, -17.464798859696753],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 3,
        FIRST_IDPR: '1703',
        NOMBPROV: 'TAHUAMANU',
        FIRST_NOMB: 'MADRE DE DIOS',
        LAST_DCTO: 'LEY',
        LAST_LEY: '13656',
        FIRST_FECH: '26/12/1912',
        LAST_FECHA: '07/06/1961',
        MIN_SHAPE_: 2003020.80175,
        ha: 2003020.8,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-72.17536910673256, -11.044263728917446],
            [-72.18180860673868, -11.0097246038853],
            [-72.13028710668992, -10.990333978867115],
            [-72.05173910661544, -11.010911603886111],
            [-72.01612885658172, -10.98968747886626],
            [-71.83566985641048, -11.000474603875702],
            [-71.72016110630084, -10.996553478871546],
            [-71.5905371061779, -10.972033228847955],
            [-71.53161448112213, -10.952229103829117],
            [-71.35731660595731, -10.951545478827006],
            [-71.27337698087896, -10.847357478729107],
            [-71.25732823086435, -10.784485103670423],
            [-71.22118635583067, -10.761383978648473],
            [-71.22955273083903, -10.688964853581169],
            [-71.19621285580877, -10.579861353479208],
            [-71.12738923074556, -10.504360853408036],
            [-71.0245334806512, -10.478200103382038],
            [-70.96722460560011, -10.381341853290882],
            [-70.96588085559938, -10.339814478252235],
            [-70.92293560556075, -10.327509728239962],
            [-70.8791894805221, -10.284248103198811],
            [-70.81744335546766, -10.256857478171929],
            [-70.7051114803714, -10.158680603077705],
            [-70.7078532303742, -10.135611103056345],
            [-70.65052598032642, -10.080343103003267],
            [-70.67284373034627, -10.035238227962008],
            [-70.66762748034313, -9.972452852903498],
            [-70.61388473029926, -9.916855477850127],
            [-70.61364835528587, -10.500259728392406],
            [-70.61375385527438, -11.000167978857066],
            [-70.52783198020191, -10.934277353792611],
            [-70.45505473013863, -10.995595728846535],
            [-70.4243007301116, -11.03834572888485],
            [-70.30779298001532, -11.07049810390876],
            [-70.24458197996628, -11.049386228885501],
            [-70.199052729931, -11.054014728886944],
            [-70.14122597988879, -11.030059103860822],
            [-70.09458697985698, -10.989934728820268],
            [-70.02801922981119, -10.97762547880366],
            [-69.95397597976532, -10.928565603751874],
            [-69.90052535473255, -10.920604728739539],
            [-69.78822685466766, -10.929647353736266],
            [-69.73930035463923, -10.965041228763395],
            [-69.63468697959158, -10.957338353742957],
            [-69.59685972957806, -10.941736853723281],
            [-69.56292772956274, -10.966835978741509],
            [-69.22833197941127, -11.499824229169876],
            [-69.1034472293743, -11.681347729305383],
            [-68.97029397933802, -11.913147729478716],
            [-69.08113872932802, -11.906162104505345],
            [-69.13010347932617, -11.91830660452979],
            [-69.1912382293332, -11.909988229537777],
            [-69.24420122934642, -11.88262697952532],
            [-69.33525785436433, -11.906517604567318],
            [-69.42078322939868, -11.865861354546814],
            [-69.46107422941311, -11.875033229562657],
            [-69.52304097944423, -11.84122847954216],
            [-69.5874863544824, -11.777748104493806],
            [-69.62857810450537, -11.760062479483578],
            [-69.69391597954022, -11.771638729503362],
            [-69.76454885457956, -11.803677729541988],
            [-69.89859760467132, -11.75934960451597],
            [-69.92749022969384, -11.725871104487888],
            [-70.11645510483751, -11.666214854448974],
            [-70.16675585487866, -11.63310347942195],
            [-70.23927347994015, -11.559084104358154],
            [-70.27925785497622, -11.47488672928247],
            [-70.30441997999776, -11.452052729262741],
            [-70.35476760503948, -11.454175729267527],
            [-70.41607810509207, -11.428492229246775],
            [-70.47570873014546, -11.352508104178986],
            [-70.76620285540255, -11.384202479218896],
            [-70.77413410541011, -11.365815979202024],
            [-70.93035473055491, -11.275387354121788],
            [-71.06302798067853, -11.250163979100927],
            [-71.10305560571626, -11.220910854074384],
            [-71.15363835576359, -11.227117104080943],
            [-71.30698485590838, -11.185339979044043],
            [-71.40774973100393, -11.147887479010233],
            [-71.45993923105357, -11.104598853970415],
            [-71.54810173113727, -11.081942603950004],
            [-71.58689935617403, -11.095900853963268],
            [-71.70667148128778, -11.089931103958413],
            [-71.81156073138746, -11.070546353940856],
            [-71.85112973142509, -11.043033353915398],
            [-71.90074123147217, -11.066552728937484],
            [-71.9866488565537, -11.054029603926086],
            [-72.13681060669602, -11.082958978953407],
            [-72.17536910673256, -11.044263728917446],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 6,
        FIRST_IDPR: '2007',
        NOMBPROV: 'TALARA',
        FIRST_NOMB: 'PIURA',
        LAST_DCTO: 'LEY',
        LAST_LEY: '818',
        FIRST_FECH: '31/10/1932',
        LAST_FECHA: '14/11/1908',
        MIN_SHAPE_: 295210.92241,
        ha: 295210.92,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.96825786309073, -4.771792972969685],
            [-81.21055273758543, -4.816402347970434],
            [-81.32810348723024, -4.681884722825915],
            [-81.30964848728291, -4.661818347811677],
            [-81.28244723733962, -4.52518947269414],
            [-81.30361336226956, -4.483249972652297],
            [-81.26714261236869, -4.434068347614986],
            [-81.24927923740663, -4.354816347546837],
            [-81.26065623736739, -4.310890722505221],
            [-81.23705273742455, -4.25067572245512],
            [-81.16858398759916, -4.210462722430141],
            [-81.10061523774966, -4.128935597366651],
            [-81.04141798787164, -4.087546847337321],
            [-80.97132223801057, -4.127117222382325],
            [-80.93638473807678, -4.178242222433163],
            [-80.90892773812644, -4.224523472478638],
            [-80.9377051130866, -4.291087847535827],
            [-80.85230861322698, -4.383066347629897],
            [-80.88042386319772, -4.491527347725638],
            [-80.91944136314135, -4.520330097747055],
            [-80.89095698819324, -4.595468722819212],
            [-80.92944336313812, -4.632871097848367],
            [-80.87247661323015, -4.688427722906399],
            [-80.84821286327005, -4.748371097964135],
            [-80.96825786309073, -4.771792972969685],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 4,
        FIRST_IDPR: '1701',
        NOMBPROV: 'TAMBOPATA',
        FIRST_NOMB: 'MADRE DE DIOS',
        LAST_DCTO: 'LEY',
        LAST_LEY: '1782',
        FIRST_FECH: '26/12/1912',
        LAST_FECHA: '26/12/1912',
        MIN_SHAPE_: 3620032.15417,
        ha: 3620032.15,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-68.87187322906549, -13.003925855440778],
            [-69.31059960414954, -13.195783230747452],
            [-69.64986322935322, -13.341716855946913],
            [-69.9741621046275, -13.245101605899071],
            [-70.03255472967997, -13.223748105885067],
            [-70.40286810502359, -13.114412855811256],
            [-70.35930335498665, -13.008405605710342],
            [-70.35382397998508, -12.914494355622901],
            [-70.33296110496755, -12.870422605580746],
            [-70.29398560493301, -12.845782730555413],
            [-70.25250972989969, -12.749607480463451],
            [-70.19585397985071, -12.720935355432852],
            [-70.16154785482279, -12.6786802303911],
            [-70.07501897975084, -12.63935285534763],
            [-70.06729185474777, -12.579667855291653],
            [-70.103432729782, -12.508304605228565],
            [-70.22959160489194, -12.466855480199632],
            [-70.25678335491763, -12.421170105159101],
            [-70.24697260491064, -12.385302730125177],
            [-70.27558447993859, -12.313117605060071],
            [-70.34750210500573, -12.204446854963575],
            [-70.40993085506288, -12.147423979914054],
            [-70.43581560508568, -12.15441860492188],
            [-70.49891698014375, -12.106803854880674],
            [-70.54099585518163, -12.112289104887621],
            [-70.64036335527423, -12.037163354821738],
            [-70.64436548027861, -12.00574922979271],
            [-70.74665598037318, -11.99799235478893],
            [-70.85923710547873, -11.965659479762067],
            [-70.89056173050875, -11.924196604724305],
            [-70.98175423059463, -11.918297854720873],
            [-71.02615835563697, -11.885012229690826],
            [-71.07642760568564, -11.77962272959374],
            [-71.12629048073305, -11.753339729570136],
            [-71.20637110580903, -11.755534729573398],
            [-71.25142573085218, -11.708167979529927],
            [-71.32066410591823, -11.679185604503806],
            [-71.33936323093626, -11.6387402294664],
            [-71.41572898100954, -11.528847354364885],
            [-71.49381035608401, -11.489047479328537],
            [-71.57872548116497, -11.469628729311113],
            [-71.59114060617692, -11.431128854275368],
            [-71.66469848124697, -11.419385104264913],
            [-71.64860548123177, -11.377734479226007],
            [-71.75021098132858, -11.329896479182041],
            [-71.82613098140081, -11.315101979168597],
            [-71.85387498142711, -11.36815435421812],
            [-71.89356473146486, -11.352866479204014],
            [-71.94561398151428, -11.386306979235352],
            [-71.99935885656537, -11.37045147922077],
            [-72.0968737316579, -11.377597604227672],
            [-72.11205923167235, -11.331073354184388],
            [-72.16308598172073, -11.320124979174288],
            [-72.20462335676005, -11.347432729199811],
            [-72.21766410677242, -11.30018172915584],
            [-72.198183356754, -11.261696854119975],
            [-72.200240231756, -11.205296854067436],
            [-72.1214062316814, -11.107140603975898],
            [-72.18486498174153, -11.06581047893752],
            [-72.17536910673256, -11.044263728917446],
            [-72.13681060669602, -11.082958978953407],
            [-71.9866488565537, -11.054029603926086],
            [-71.90074123147217, -11.066552728937484],
            [-71.85112973142509, -11.043033353915398],
            [-71.81156073138746, -11.070546353940856],
            [-71.70667148128778, -11.089931103958413],
            [-71.58689935617403, -11.095900853963268],
            [-71.54810173113727, -11.081942603950004],
            [-71.45993923105357, -11.104598853970415],
            [-71.40774973100393, -11.147887479010233],
            [-71.30698485590838, -11.185339979044043],
            [-71.15363835576359, -11.227117104080943],
            [-71.10305560571626, -11.220910854074384],
            [-71.06302798067853, -11.250163979100927],
            [-70.93035473055491, -11.275387354121788],
            [-70.77413410541011, -11.365815979202024],
            [-70.76620285540255, -11.384202479218896],
            [-70.47570873014546, -11.352508104178986],
            [-70.41607810509207, -11.428492229246775],
            [-70.35476760503948, -11.454175729267527],
            [-70.30441997999776, -11.452052729262741],
            [-70.27925785497622, -11.47488672928247],
            [-70.23927347994015, -11.559084104358154],
            [-70.16675585487866, -11.63310347942195],
            [-70.11645510483751, -11.666214854448974],
            [-69.92749022969384, -11.725871104487888],
            [-69.89859760467132, -11.75934960451597],
            [-69.76454885457956, -11.803677729541988],
            [-69.69391597954022, -11.771638729503362],
            [-69.62857810450537, -11.760062479483578],
            [-69.5874863544824, -11.777748104493806],
            [-69.52304097944423, -11.84122847954216],
            [-69.46107422941311, -11.875033229562657],
            [-69.42078322939868, -11.865861354546814],
            [-69.33525785436433, -11.906517604567318],
            [-69.24420122934642, -11.88262697952532],
            [-69.1912382293332, -11.909988229537777],
            [-69.13010347932617, -11.91830660452979],
            [-69.08113872932802, -11.906162104505345],
            [-68.97029397933802, -11.913147729478716],
            [-68.6544707292852, -12.49942972989126],
            [-68.69221222923615, -12.58360097998477],
            [-68.73241072920985, -12.61094135502712],
            [-68.71888397919852, -12.66184060506756],
            [-68.74171872916989, -12.724941480134733],
            [-68.78821635414697, -12.756945480183125],
            [-68.83469297912193, -12.813158355252726],
            [-68.83577872910858, -12.859040105294964],
            [-68.87156697909816, -12.885467355332636],
            [-68.87187322906549, -13.003925855440778],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 8,
        FIRST_IDPR: '2304',
        NOMBPROV: 'TARATA',
        FIRST_NOMB: 'TACNA',
        LAST_DCTO: 'LEY',
        LAST_LEY: '12136',
        FIRST_FECH: '12/01/1956',
        LAST_FECHA: '26/10/1954',
        MIN_SHAPE_: 277252.87686,
        ha: 277252.88,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-70.34578710481297, -17.460740234833548],
            [-70.2438014796955, -17.35871348472962],
            [-70.19438072963764, -17.32430498469272],
            [-70.12446685455332, -17.319910109680855],
            [-70.09331535451686, -17.290048109649604],
            [-70.09530685452755, -17.122885984495714],
            [-70.07000410449915, -17.08118335945421],
            [-70.07315247950532, -17.034914234411943],
            [-70.04677347947238, -17.056382859428478],
            [-70.03971872945995, -17.127967734493552],
            [-69.85140035421983, -17.24373635957299],
            [-69.7882461041406, -17.2364433595557],
            [-69.73166997906378, -17.294966734599196],
            [-69.69359760401655, -17.2746426095733],
            [-69.64131997894788, -17.2874499845745],
            [-69.57521872886137, -17.292589859564977],
            [-69.46731635371029, -17.37353710961321],
            [-69.4683788537011, -17.464798859696753],
            [-69.53879997880166, -17.405969984660302],
            [-69.60320997888768, -17.39665998466636],
            [-69.64634010394303, -17.410299984688056],
            [-69.72316010403901, -17.461046859749867],
            [-69.81955472916428, -17.45465035976145],
            [-69.89406835425119, -17.582773484891383],
            [-69.98322460436387, -17.597140609917982],
            [-70.02030660440715, -17.661583984982464],
            [-70.13761122954867, -17.772665985099803],
            [-70.19330272961749, -17.775636735108982],
            [-70.1895839796145, -17.7372694850732],
            [-70.15372260457218, -17.693400360028647],
            [-70.14338672956217, -17.63171285997056],
            [-70.17143160459857, -17.587447234932974],
            [-70.23745260467882, -17.58966735994218],
            [-70.33179522979479, -17.50931635987716],
            [-70.34578710481297, -17.460740234833548],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 9,
        FIRST_IDPR: '1207',
        NOMBPROV: 'TARMA',
        FIRST_NOMB: 'JUNIN',
        LAST_DCTO: 'LEY',
        LAST_LEY: 'S/N',
        FIRST_FECH: '14/06/1958',
        LAST_FECHA: '02/01/1857',
        MIN_SHAPE_: 267058.176136,
        ha: 267058.18,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.9601899852684, -11.333323979187364],
            [-75.94757661025663, -11.33472910418866],
            [-75.9441874852535, -11.335307604189223],
            [-75.94009736024968, -11.334442979188395],
            [-75.93470136024466, -11.332009354186155],
            [-75.92315623523388, -11.329533229183827],
            [-75.9173683602285, -11.332126354186252],
            [-75.91653048522772, -11.332068604186205],
            [-75.9109278602225, -11.332339854186465],
            [-75.90907636022078, -11.333137604187197],
            [-75.90844073522018, -11.333570979187595],
            [-75.90523436021721, -11.335521479189412],
            [-75.9001016102124, -11.336894479190686],
            [-75.89014773520313, -11.333161479187204],
            [-75.89431861020702, -11.32326097917799],
            [-75.89873523521116, -11.31566872917092],
            [-75.90315061021526, -11.31235210416782],
            [-75.90605448521796, -11.310270854165896],
            [-75.91064948522224, -11.305862479161801],
            [-75.9138316102252, -11.298702854155138],
            [-75.9246718602353, -11.280818354138477],
            [-75.97192573527934, -11.21416597907638],
            [-75.96048998526867, -11.217389979079385],
            [-75.96011998526832, -11.217439979079467],
            [-75.95435998526297, -11.218019979079987],
            [-75.95204998526081, -11.218269979080203],
            [-75.95017998525906, -11.217909979079886],
            [-75.94616998525534, -11.218089979080066],
            [-75.94006998524965, -11.217349979079367],
            [-75.93793986024768, -11.21752997907951],
            [-75.93229998524242, -11.217389979079389],
            [-75.93081998524103, -11.217869979079845],
            [-75.93009998524037, -11.218429979080375],
            [-75.93001998524029, -11.218529854080474],
            [-75.9214399852323, -11.219359854081217],
            [-75.91948998523046, -11.217599979079592],
            [-75.91645998522765, -11.214499979076706],
            [-75.91502998522633, -11.211899979074298],
            [-75.91509998522639, -11.210689979073162],
            [-75.9138299852252, -11.208689979071318],
            [-75.91336998522476, -11.207729979070407],
            [-75.91210998522361, -11.205959979068776],
            [-75.91028998522192, -11.203559979066533],
            [-75.90978998522144, -11.203199979066184],
            [-75.90654998521842, -11.201479979064594],
            [-75.90515998521713, -11.201449979064547],
            [-75.90032448521262, -11.195092354058648],
            [-75.9045912352166, -11.176965354041748],
            [-75.90463648521663, -11.176014854040876],
            [-75.90342911021553, -11.174901479039836],
            [-75.89703223520958, -11.172620104037707],
            [-75.89694873520948, -11.166029479031572],
            [-75.89575286020838, -11.165219354030812],
            [-75.88789948520105, -11.15307485401949],
            [-75.8866659851999, -11.147732479014529],
            [-75.88269336019621, -11.142277354009442],
            [-75.88109373519474, -11.141447229008682],
            [-75.8776626101915, -11.140326479007651],
            [-75.87540423518941, -11.140434104007722],
            [-75.87363298518774, -11.140876854008138],
            [-75.872823110187, -11.14107935400834],
            [-75.8615643601765, -11.144848479011836],
            [-75.86117698517617, -11.14477447901176],
            [-75.8571482351724, -11.140069354007407],
            [-75.8553054851707, -11.135068979002728],
            [-75.85506123517047, -11.132560854000412],
            [-75.85301948516856, -11.129275978997349],
            [-75.85189773516751, -11.124245853992658],
            [-75.85204273516764, -11.123016978991522],
            [-75.8516567351673, -11.119728478988447],
            [-75.84764411016357, -11.120176478988853],
            [-75.84643923516244, -11.120225728988906],
            [-75.84388211016004, -11.120299478988976],
            [-75.84073486015711, -11.119832228988543],
            [-75.84054998515693, -11.11988847898859],
            [-75.83893986015543, -11.11941422898817],
            [-75.83795636015454, -11.117275103986156],
            [-75.83695686015359, -11.109891978979295],
            [-75.83692461015356, -11.105401603975107],
            [-75.83736111015398, -11.104058603973854],
            [-75.83733598515396, -11.103883228973691],
            [-75.83421998515107, -11.098545603968715],
            [-75.8332061101501, -11.096510853966832],
            [-75.83281698514973, -11.095704478966068],
            [-75.8324562351494, -11.094622103965047],
            [-75.83829461015486, -11.085527353956591],
            [-75.83860223515514, -11.083294978954505],
            [-75.838458610155, -11.08171672895306],
            [-75.83774386015433, -11.080915478952296],
            [-75.83701661015364, -11.078818478950334],
            [-75.83762548515422, -11.07484435394663],
            [-75.83784536015442, -11.074100228945937],
            [-75.83825786015481, -11.071061728943135],
            [-75.83833636015488, -11.070396353942524],
            [-75.83809923515466, -11.069657103941836],
            [-75.83691973515356, -11.069089353941298],
            [-75.83650948515319, -11.068561853940784],
            [-75.83617536015286, -11.067024978939363],
            [-75.8353372351521, -11.064654478937157],
            [-75.83180073514878, -11.050927228924389],
            [-75.8256631101431, -11.05038722892388],
            [-75.8215792351393, -11.049859353923374],
            [-75.81955036013736, -11.051211853924654],
            [-75.81801323513594, -11.051457853924884],
            [-75.81305123513134, -11.048844603922436],
            [-75.81285311013114, -11.044732603918607],
            [-75.81278861013108, -11.043970228917898],
            [-75.81273811013104, -11.043448103917418],
            [-75.81329398513155, -11.0414041039155],
            [-75.81421423513241, -11.040210728914376],
            [-75.81573148513381, -11.037321728911696],
            [-75.81727923513527, -11.034843728909399],
            [-75.81694611013496, -11.03425622890884],
            [-75.81576711013385, -11.033676728908306],
            [-75.8114323601298, -11.031198228906023],
            [-75.80827973512687, -11.028710853903691],
            [-75.80738311012605, -11.027843103902875],
            [-75.8057344851245, -11.025182103900388],
            [-75.80492461012376, -11.023562478898892],
            [-75.80333386012227, -11.020814853896322],
            [-75.80263973512164, -11.015532728891417],
            [-75.80231423512133, -11.01442635389039],
            [-75.80181536012086, -11.0135803538896],
            [-75.80055723511968, -11.012430728888505],
            [-75.79947261011868, -11.011844978887973],
            [-75.7975419851169, -11.011172478887339],
            [-75.79684773511622, -11.010543478886762],
            [-75.79512861011465, -11.008582978884919],
            [-75.79395723511355, -11.008078603884485],
            [-75.79267211011235, -11.00754185388395],
            [-75.79164698511138, -11.00698860388345],
            [-75.7909148601107, -11.00648422888298],
            [-75.78943436010933, -11.006077478882606],
            [-75.78875561010871, -11.005964603882513],
            [-75.78727998510733, -11.005446478882014],
            [-75.78644786010655, -11.004881353881498],
            [-75.78447686010469, -11.000140978877083],
            [-75.78430698510454, -10.997739228874849],
            [-75.7840609851043, -10.997493228874617],
            [-75.77944248510002, -10.997503728874612],
            [-75.7763938600972, -10.998538603875591],
            [-75.77463723509554, -10.999256228876234],
            [-75.774026110095, -10.99974735387673],
            [-75.77136186009251, -11.001794228878616],
            [-75.76808386008945, -11.004825478881443],
            [-75.7667411100882, -11.005655103882207],
            [-75.76673198508817, -11.005660728882214],
            [-75.75969298508161, -11.010235853886472],
            [-75.75830398508032, -11.010500103886727],
            [-75.75548011007768, -11.009743103886024],
            [-75.75489436007715, -11.008717478885076],
            [-75.75080973507336, -11.005626603882192],
            [-75.75013086007273, -11.00525897888183],
            [-75.73963111006293, -11.000739978877645],
            [-75.73953111006284, -11.000929978877817],
            [-75.73610111005964, -11.00129997887817],
            [-75.73423111005792, -11.00162997887846],
            [-75.73342111005715, -11.00161997887847],
            [-75.73289111005667, -11.001619978878463],
            [-75.73222111005603, -11.001489853878324],
            [-75.7290811100531, -10.999999978876945],
            [-75.72827111005236, -10.99966997887664],
            [-75.72729111005144, -10.998729978875748],
            [-75.72712111005129, -10.998149978875228],
            [-75.72642111005064, -10.995269978872544],
            [-75.7254409850497, -10.993959978871308],
            [-75.72526111004954, -10.993499978870876],
            [-75.7253111100496, -10.99238997886985],
            [-75.72584111005007, -10.991129978868674],
            [-75.72568111004993, -10.990849978868427],
            [-75.72445111004879, -10.990019978867641],
            [-75.7235011100479, -10.990039978867689],
            [-75.72192111004645, -10.99020997886783],
            [-75.72144111004599, -10.99006997886769],
            [-75.72097111004555, -10.989699978867343],
            [-75.72066111004526, -10.98928997886696],
            [-75.71922111004392, -10.98659997886446],
            [-75.71867111004339, -10.98529997886324],
            [-75.71838111004314, -10.984979978862944],
            [-75.71786111004266, -10.984519978862513],
            [-75.71594111004086, -10.983609978861693],
            [-75.71570111004063, -10.983329978861432],
            [-75.71494111003994, -10.981780103859988],
            [-75.71440111003943, -10.981289978859525],
            [-75.71205111003722, -10.981019978859264],
            [-75.71161111003684, -10.980949978859192],
            [-75.70910111003451, -10.980529978858799],
            [-75.70850111003394, -10.980309978858624],
            [-75.70561111003124, -10.979069978857458],
            [-75.70455111003024, -10.977059978855564],
            [-75.70185111002773, -10.974329978853024],
            [-75.70156111002748, -10.973869978852605],
            [-75.69736111002355, -10.968779978847857],
            [-75.69348111001995, -10.968379978847503],
            [-75.69192111001851, -10.967169978846375],
            [-75.69048111001716, -10.96655997884582],
            [-75.68683111001377, -10.966419978845662],
            [-75.68661111001356, -10.966280103845552],
            [-75.68596111001294, -10.964609978843983],
            [-75.68540111001244, -10.963939978843372],
            [-75.68503123501208, -10.963889978843323],
            [-75.68239111000963, -10.964799978844148],
            [-75.68148111000878, -10.963909978843324],
            [-75.68138111000867, -10.962899978842385],
            [-75.68112111000842, -10.962289978841818],
            [-75.67834111000585, -10.959519978839237],
            [-75.67767111000524, -10.958739978838526],
            [-75.67760111000516, -10.958479978838271],
            [-75.67762111000519, -10.95694997883686],
            [-75.67746111000503, -10.953919978834021],
            [-75.67619111000386, -10.953350103833506],
            [-75.6754811100032, -10.952869978833064],
            [-75.67507111000282, -10.951919978832157],
            [-75.6746411100024, -10.95081997883115],
            [-75.67443111000222, -10.950669978830996],
            [-75.67346111000131, -10.950659978830993],
            [-75.67283111000071, -10.950989978831295],
            [-75.67111110999912, -10.951219978831515],
            [-75.67055110999858, -10.951199978831502],
            [-75.66772110999597, -10.9492499788297],
            [-75.66622110999458, -10.94941997882983],
            [-75.66393110999243, -10.950299978830657],
            [-75.66144110999011, -10.951419978831696],
            [-75.66074110998946, -10.951949978832204],
            [-75.65915098498797, -10.952299978832515],
            [-75.65763110998657, -10.952959978833153],
            [-75.65492123498403, -10.954909978834957],
            [-75.65353110998272, -10.956259978836213],
            [-75.65313110998238, -10.956819978836737],
            [-75.65299110998225, -10.957089978836994],
            [-75.65227098498158, -10.958139978837966],
            [-75.65087110998026, -10.958789978838578],
            [-75.64702110997668, -10.959629978839356],
            [-75.64520110997498, -10.959289978839024],
            [-75.64500110997479, -10.95916997883892],
            [-75.6445711099744, -10.958399978838196],
            [-75.64356110997346, -10.957139978837029],
            [-75.64199123497201, -10.95664997883655],
            [-75.64088110997095, -10.957129978837024],
            [-75.63945110996963, -10.957949978837805],
            [-75.63805110996833, -10.958879978838656],
            [-75.63717110996751, -10.959009978838758],
            [-75.6359812349664, -10.959269978839016],
            [-75.63424123496478, -10.959229978838977],
            [-75.63235110996301, -10.959360103839087],
            [-75.6309311099617, -10.959279853839025],
            [-75.62700110995803, -10.959279853839021],
            [-75.62437110995559, -10.95892997883871],
            [-75.62109110995253, -10.959499978839226],
            [-75.61881110995039, -10.96087997884051],
            [-75.61652110994827, -10.960839978840475],
            [-75.6134311099454, -10.96038997884005],
            [-75.61074110994288, -10.95888997883866],
            [-75.60896110994123, -10.958039978837853],
            [-75.60773110994008, -10.958219978838024],
            [-75.60338110993604, -10.956259978836208],
            [-75.60310110993578, -10.956019978835993],
            [-75.60182110993458, -10.954729978834795],
            [-75.60158110993434, -10.954629978834706],
            [-75.60024110993311, -10.954489978834575],
            [-75.59994110993284, -10.954419978834494],
            [-75.59921110993216, -10.95439997883448],
            [-75.59855110993153, -10.954569978834652],
            [-75.59723123493032, -10.954979978835008],
            [-75.59289110992628, -10.95671997883664],
            [-75.59182110992526, -10.956439978836386],
            [-75.58700110992076, -10.955109978835134],
            [-75.58541110991929, -10.954229978834311],
            [-75.5842311099182, -10.95346997883362],
            [-75.58117110991533, -10.953359978833491],
            [-75.57816110991251, -10.95387997883401],
            [-75.57575110991029, -10.953919978834016],
            [-75.57443110990907, -10.95442997883449],
            [-75.57302110990774, -10.954439978834516],
            [-75.57023110990515, -10.954409978834489],
            [-75.56788110990296, -10.953909978834021],
            [-75.56561110990083, -10.954689978834738],
            [-75.5651211099004, -10.955479978835472],
            [-75.56486110990015, -10.956119978836071],
            [-75.56284110989826, -10.956790103836711],
            [-75.56087110989642, -10.957299978837163],
            [-75.56015110989574, -10.957469978837327],
            [-75.55833110989407, -10.957329978837196],
            [-75.55511110989109, -10.956409978836348],
            [-75.55239098488853, -10.954919978834962],
            [-75.55229110988846, -10.953879978834],
            [-75.55218110988832, -10.952489978832709],
            [-75.55187110988807, -10.95092997883125],
            [-75.55089110988713, -10.950469978830824],
            [-75.54815110988459, -10.949549978829959],
            [-75.54771110988419, -10.949509978829925],
            [-75.54708110988358, -10.949639978830042],
            [-75.54619110988276, -10.950129978830507],
            [-75.54023110987721, -10.950780103831097],
            [-75.53941110987644, -10.950469978830817],
            [-75.53747110987466, -10.948689978829181],
            [-75.53701110987423, -10.948349978828835],
            [-75.5362311098735, -10.948409978828888],
            [-75.53541110987273, -10.94886997882931],
            [-75.5349598598723, -10.949029978829474],
            [-75.53371998487115, -10.949789978830172],
            [-75.53224110986979, -10.950949978831263],
            [-75.5299211098676, -10.950539978830864],
            [-75.52873110986651, -10.950259978830617],
            [-75.52774110986557, -10.950139978830522],
            [-75.52569110986367, -10.9502199788306],
            [-75.52357110986169, -10.949969978830357],
            [-75.52252110986073, -10.950059978830433],
            [-75.52007110985845, -10.952089978832328],
            [-75.51829110985676, -10.953339978833485],
            [-75.51780110985634, -10.953169978833321],
            [-75.51656110985518, -10.953029978833191],
            [-75.51571110985437, -10.953249853833402],
            [-75.51537110985404, -10.953429978833581],
            [-75.51417110985294, -10.954649978834698],
            [-75.51215110985108, -10.957509978837374],
            [-75.51083110984983, -10.95854997883834],
            [-75.51052110984953, -10.95907997883884],
            [-75.50551110984487, -10.9620399788416],
            [-75.50337110984289, -10.962039978841606],
            [-75.5006068598403, -10.959055228838816],
            [-75.49858973483843, -10.955693353835699],
            [-75.4964161098364, -10.951764228832019],
            [-75.49602523483604, -10.948531228829008],
            [-75.49627885983628, -10.946443978827068],
            [-75.49483723483495, -10.941707228822647],
            [-75.49594835983596, -10.93624247881758],
            [-75.49460360983473, -10.923035103805255],
            [-75.48647510982714, -10.918181103800745],
            [-75.48302910982393, -10.917800228800393],
            [-75.48029160982138, -10.917608103800198],
            [-75.47553010981693, -10.915642228798392],
            [-75.47396323481549, -10.914640603797467],
            [-75.46791998480988, -10.910456603793568],
            [-75.46532335980744, -10.908804103792027],
            [-75.46591348480801, -10.905695978789112],
            [-75.46522235980736, -10.901126853784856],
            [-75.45335010979628, -10.900295353784081],
            [-75.42349935976851, -10.936894353818179],
            [-75.46411523480633, -11.024212853899504],
            [-75.5277702348656, -11.050058353923555],
            [-75.58439985991835, -11.051399353924793],
            [-75.66738985999567, -11.09827335396845],
            [-75.63767598496798, -11.164202104029856],
            [-75.58837260992206, -11.17712797904191],
            [-75.57930998491362, -11.228729979089941],
            [-75.50672660984601, -11.22407622908561],
            [-75.434789609779, -11.258207854117398],
            [-75.43095998477544, -11.332309979186432],
            [-75.4096663597556, -11.38777210423806],
            [-75.46082110980326, -11.428304354275808],
            [-75.45746648480012, -11.453432604299227],
            [-75.51254198485142, -11.511886354353672],
            [-75.62502648495621, -11.589096729425567],
            [-75.71842148504318, -11.544296729383841],
            [-75.80176361012079, -11.544773104384289],
            [-75.87438998518844, -11.490928729334156],
            [-75.91638286022757, -11.415831979264212],
            [-75.88531998519863, -11.40407997925326],
            [-75.93944523524908, -11.347132229200216],
            [-75.95705686026547, -11.334071604188047],
            [-75.9601899852684, -11.333323979187364],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 16,
        FIRST_IDPR: '0907',
        NOMBPROV: 'TAYACAJA',
        FIRST_NOMB: 'HUANCAVELICA',
        LAST_DCTO: 'LEY',
        LAST_LEY: '11585',
        FIRST_FECH: '08/01/1912',
        LAST_FECHA: '31/01/1951',
        MIN_SHAPE_: 336926.00538,
        ha: 336926.01,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.54899948395406, -11.99699035480542],
            [-74.4883772338976, -12.03319597983913],
            [-74.45553598386702, -12.0335787298395],
            [-74.41952023383345, -12.093775854895554],
            [-74.35238410877093, -12.147764979945846],
            [-74.34594560876494, -12.173736729970017],
            [-74.3888599838049, -12.192669979987649],
            [-74.38480998380112, -12.268629980058392],
            [-74.41628985883047, -12.304769980092068],
            [-74.44396923385624, -12.271973605061499],
            [-74.55211723395696, -12.287339855075825],
            [-74.58117698398404, -12.332669355118043],
            [-74.5759899839792, -12.373169980155753],
            [-74.59729498399905, -12.410015605190056],
            [-74.59733235899907, -12.466094605242283],
            [-74.69205473408726, -12.521859355294218],
            [-74.72193160911512, -12.487480355262218],
            [-74.77968948416891, -12.463849605240197],
            [-74.86297460924648, -12.522789105295077],
            [-74.94385348432178, -12.533050730304655],
            [-75.00706248438065, -12.493226605267568],
            [-75.02426760939667, -12.434714855213077],
            [-75.0666953594362, -12.393955105175108],
            [-75.13100998449609, -12.37849985516073],
            [-75.14022998450466, -12.342099980126822],
            [-75.07162785944078, -12.235202605027279],
            [-75.04832723441909, -12.170329729966861],
            [-75.07460660944356, -12.060146729864249],
            [-75.04705260941792, -12.01156360481898],
            [-74.93037423430924, -12.022858354829514],
            [-74.87077410925373, -12.06599197986967],
            [-74.83737360922264, -12.070818479874177],
            [-74.74860998413995, -12.041419979846799],
            [-74.71690010911043, -12.059419979863554],
            [-74.6591077340566, -11.995782479804308],
            [-74.54899948395406, -11.99699035480542],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 5,
        FIRST_IDPR: '2210',
        NOMBPROV: 'TOCACHE',
        FIRST_NOMB: 'SAN MARTIN',
        LAST_DCTO: 'LEY',
        LAST_LEY: '24016',
        FIRST_FECH: '06/12/1984',
        LAST_FECHA: '06/12/1984',
        MIN_SHAPE_: 602785.59055,
        ha: 602785.59,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.59771673586293, -7.689289100793657],
            [-76.6302448608933, -7.753482100853446],
            [-76.63165648589462, -7.842609475936433],
            [-76.60572736087039, -7.881425975972592],
            [-76.62452348588796, -7.906803225996232],
            [-76.61433011087844, -7.985707101069708],
            [-76.56328486083076, -7.99212210107568],
            [-76.52004686079042, -8.03136322611224],
            [-76.4569238607315, -8.054822226134075],
            [-76.39273548567161, -8.120216726194991],
            [-76.33081273561385, -8.153002226225523],
            [-76.33320698561607, -8.203689476272718],
            [-76.2996521105848, -8.217456101285553],
            [-76.23672873552614, -8.211309476279819],
            [-76.19709561048919, -8.294439226357259],
            [-76.16583061046003, -8.311226476372884],
            [-76.14578123544136, -8.367545101425337],
            [-76.10916598540722, -8.379613226436579],
            [-76.13044123542706, -8.467211101518147],
            [-76.11592573541351, -8.657574226695445],
            [-76.13125586042781, -8.65678710169472],
            [-76.16643948546063, -8.756414101787476],
            [-76.22356048551384, -8.791425726820098],
            [-76.22868361051862, -8.74808397677974],
            [-76.26668298555404, -8.655046726693065],
            [-76.27846473556504, -8.577460976620818],
            [-76.32418548560766, -8.524134601571166],
            [-76.33346811061634, -8.429473851482992],
            [-76.37578536065581, -8.422581226476602],
            [-76.41680098569408, -8.443436601495998],
            [-76.4439536107194, -8.488791851538243],
            [-76.46616736074012, -8.569742976613655],
            [-76.54268748581153, -8.509710976557743],
            [-76.62431636088776, -8.511953101559813],
            [-76.69727148595591, -8.532408226578866],
            [-76.83158361108148, -8.516655226564168],
            [-76.84726673609615, -8.546481851591974],
            [-76.91629836116073, -8.570159726613992],
            [-77.04980136128573, -8.553715226598678],
            [-77.09165073632494, -8.516981351564445],
            [-77.08887886132234, -8.482822226532646],
            [-77.14256536137268, -8.47582560152612],
            [-77.1338437363645, -8.440021476492761],
            [-77.08548048631914, -8.406222601461307],
            [-76.97648148621707, -8.395943726451758],
            [-76.91374523615833, -8.326089851386698],
            [-76.90124323614663, -8.283466351347007],
            [-76.92559673616941, -8.21849735128651],
            [-76.90699798615199, -8.170735601242024],
            [-76.98672336122665, -8.11122522618659],
            [-77.01956248625739, -8.052687476132075],
            [-77.06870311130342, -8.039511726119777],
            [-77.07436311130874, -7.992300726075823],
            [-77.10251948633511, -7.952671976038913],
            [-77.07697411131116, -7.848622975941998],
            [-77.02084298625857, -7.8058727259022],
            [-76.9295299861731, -7.841739975935604],
            [-76.87324498612043, -7.898796975988766],
            [-76.80030386105221, -7.920584101009038],
            [-76.7192964859765, -7.864526975956834],
            [-76.76343461101776, -7.793464975890655],
            [-76.77304548602673, -7.745709225846197],
            [-76.65779098591902, -7.726422475828232],
            [-76.63418023589698, -7.686844475791377],
            [-76.59771673586293, -7.689289100793657],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 11,
        FIRST_IDPR: '1301',
        NOMBPROV: 'TRUJILLO',
        FIRST_NOMB: 'LA LIBERTAD',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: '04/01/1879',
        LAST_FECHA: 'EPOCA INDEP.',
        MIN_SHAPE_: 178395.630474,
        ha: 178395.63,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.68236961281167, -8.097219726169767],
            [-78.63908861277253, -8.143001226212721],
            [-78.76387498788579, -8.173830101240346],
            [-78.77979536290026, -8.201623226266054],
            [-78.83814111295243, -8.198236226262308],
            [-78.80249636292102, -8.25755160131791],
            [-78.81675811293415, -8.309992726366563],
            [-78.881374987992, -8.342660101396232],
            [-78.9005274880092, -8.371453101422787],
            [-78.95755273805871, -8.293523476349538],
            [-78.9860878630828, -8.212361351273602],
            [-79.11556636319231, -8.096662101163934],
            [-79.13007811320405, -8.059849601129438],
            [-79.2104961132697, -7.99903122607135],
            [-79.14951123821875, -7.943663101021017],
            [-79.10588798818279, -7.981977476057408],
            [-79.040006238127, -7.988320976064378],
            [-78.99057111308366, -7.900325100983207],
            [-78.9033668630077, -7.887382975972284],
            [-78.88475398799102, -7.841976475930221],
            [-78.81625973793015, -7.796873100888981],
            [-78.77570548789403, -7.800680850892912],
            [-78.78022073789857, -7.883624975970092],
            [-78.6888981128168, -7.947408226030257],
            [-78.6836308628123, -7.997185601076627],
            [-78.71150773783762, -8.016232476094132],
            [-78.72132611284691, -8.09172060116432],
            [-78.68236961281167, -8.097219726169767],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 6,
        FIRST_IDPR: '2401',
        NOMBPROV: 'TUMBES',
        FIRST_NOMB: 'TUMBES',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: '18/06/1962',
        LAST_FECHA: 'EPOCA INDEP.',
        MIN_SHAPE_: 178553.10246,
        ha: 178553.1,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.18073823863193, -3.925826222259574],
            [-80.24020898862757, -3.956230472284903],
            [-80.26181998862607, -3.998889972323251],
            [-80.29807623861984, -4.017258097338329],
            [-80.36262573860044, -3.983161347303332],
            [-80.43024411357433, -3.989503847305127],
            [-80.48733198855084, -4.0831288473876],
            [-80.52434573853044, -4.107414097407347],
            [-80.56083011351058, -4.174701222466481],
            [-80.62322073846691, -4.231779347513922],
            [-80.66645111342764, -4.21505884749484],
            [-80.58348498849182, -4.138908097431848],
            [-80.55865623850447, -4.056615222358096],
            [-80.5229976135252, -4.007034722315105],
            [-80.55056961349936, -3.88261909719875],
            [-80.54498411350045, -3.835913347156186],
            [-80.5880762384686, -3.834814847152125],
            [-80.55398186348808, -3.733548722061469],
            [-80.60384173844565, -3.670581847000141],
            [-80.60473261344286, -3.6371963469694],
            [-80.5448457384864, -3.589732471929888],
            [-80.50487498850882, -3.508951221858123],
            [-80.43356461354784, -3.489849971844702],
            [-80.37883011357219, -3.494611346851987],
            [-80.33779298859243, -3.631466846980295],
            [-80.29583398860571, -3.673839847021456],
            [-80.30499986360758, -3.772566472112142],
            [-80.25580586362024, -3.83276509717014],
            [-80.24358598862474, -3.897535097230554],
            [-80.18073823863193, -3.925826222259574],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 6,
        FIRST_IDPR: '1606',
        NOMBPROV: 'UCAYALI',
        FIRST_NOMB: 'LORETO',
        LAST_DCTO: 'LEY',
        LAST_LEY: 'S/N',
        FIRST_FECH: '02/07/1943',
        LAST_FECHA: '02/01/1857',
        MIN_SHAPE_: 2970684.42579,
        ha: 2970684.43,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-74.50009123390852, -7.262049475395766],
            [-74.53572710894169, -7.294427350425917],
            [-74.57952135898249, -7.409929475533471],
            [-74.61607385901654, -7.435037600556875],
            [-74.6429021090415, -7.500086475617441],
            [-74.64314435904173, -7.562285100675365],
            [-74.57082048397437, -7.668488225774286],
            [-74.58403223398669, -7.698493725802236],
            [-74.55788873396234, -7.748482475848776],
            [-74.51571848392307, -7.777172600875497],
            [-74.56974510897338, -7.815980475911654],
            [-74.62798423402761, -7.939828101026973],
            [-74.72794448412073, -7.981008976065358],
            [-74.77125610916106, -8.036063226116621],
            [-74.89643848427762, -8.081268226158718],
            [-74.98225973435756, -7.968986351054152],
            [-75.03334960940514, -7.950054726036502],
            [-75.1424908595068, -7.97864060106314],
            [-75.18433773454575, -8.01660235109848],
            [-75.21457223457391, -8.127144101201432],
            [-75.24388473460122, -8.155517601227855],
            [-75.28780260964213, -8.125513101199925],
            [-75.3761469847244, -8.138777101212293],
            [-75.43009623477464, -8.166598226238182],
            [-75.45303885979602, -8.210448851279022],
            [-75.5028772348424, -8.289411851352565],
            [-75.50405885984354, -8.348841101407901],
            [-75.43532398477952, -8.39311297644913],
            [-75.44078998478462, -8.436833101489851],
            [-75.54662023488316, -8.466501976517472],
            [-75.59906298493203, -8.4119777264667],
            [-75.64813148497771, -8.409751976464642],
            [-75.70700961003253, -8.439324851492179],
            [-75.71647036004136, -8.487328476536883],
            [-75.77868111009931, -8.49385297654296],
            [-75.77963923510022, -8.547415351592848],
            [-75.83338711015027, -8.652288601690532],
            [-75.89185223520474, -8.661568226699165],
            [-75.94575011025493, -8.716138726749973],
            [-75.97975973528662, -8.636001976675361],
            [-75.9663144852741, -8.592027351634398],
            [-75.96490623527279, -8.451742226503761],
            [-75.94598436025517, -8.400874976456373],
            [-75.98496286029147, -8.322150351383064],
            [-75.93488923524482, -8.31155172637318],
            [-75.91336173522477, -8.254386851319952],
            [-75.94116486025068, -8.18618397625643],
            [-75.94959273525853, -8.099232976175465],
            [-75.91700598522817, -8.071776351149868],
            [-75.84493273516101, -8.059638101138562],
            [-75.82949286014664, -7.973495851058327],
            [-75.85938623517448, -7.921168976009612],
            [-75.96697948527472, -7.960359351046109],
            [-76.04380298534632, -7.79234360088963],
            [-76.0848799853846, -7.761814725861192],
            [-76.10159261040017, -7.722663975824744],
            [-76.09768373539652, -7.642242475749818],
            [-76.07989048537992, -7.591160100702259],
            [-76.09137361039065, -7.560355725673569],
            [-76.16614511046032, -7.494307100612066],
            [-76.21455061050544, -7.404520725528457],
            [-76.1960382354882, -7.333528100462317],
            [-76.2131113605041, -7.310142600440558],
            [-76.16666148546082, -7.267596350400923],
            [-76.14352523543924, -7.203962475341661],
            [-76.05994498536137, -7.161301225301924],
            [-76.01071711031548, -7.078379850224684],
            [-76.01079148531556, -6.996536475148489],
            [-76.05146348535344, -6.944295975099823],
            [-76.0105293603153, -6.893611350052608],
            [-76.05089573535291, -6.810860224975546],
            [-75.96365161027163, -6.788423224954651],
            [-75.84387536016003, -6.797261724962891],
            [-75.76842948508977, -6.853817850015576],
            [-75.69769873502388, -6.868602725029338],
            [-75.66809735999632, -6.826417349990044],
            [-75.63344223496404, -6.843062350005547],
            [-75.51180235985073, -6.785569599952012],
            [-75.50123285984088, -6.767045724934754],
            [-75.50022848483994, -6.640322224816714],
            [-75.50959660984869, -6.492141349678718],
            [-75.5500949848864, -6.347707974544188],
            [-75.49031085983073, -6.267036224469059],
            [-75.48718760982781, -6.203415349409832],
            [-75.52555498486355, -6.165125599374173],
            [-75.52937335986711, -6.122162599334128],
            [-75.39673435974358, -6.082253849296953],
            [-75.32883623468034, -6.099313849312858],
            [-75.18983785955088, -6.073753849289061],
            [-75.14692835951094, -6.039268724256945],
            [-75.04113810941239, -6.004480724224551],
            [-74.96169135933842, -5.90149409912862],
            [-74.88669935926856, -5.866033224095583],
            [-74.80817385919543, -5.929359349154564],
            [-74.76131298415179, -5.926060974151501],
            [-74.68901223408444, -5.960449099183524],
            [-74.69826898409308, -6.027561099246042],
            [-74.7243857341174, -6.074566849289818],
            [-74.77571448416519, -6.105135974318277],
            [-74.78993198417842, -6.155281099364975],
            [-74.86736373425056, -6.222403849427507],
            [-74.86505860924841, -6.272859349474493],
            [-74.88862110927035, -6.319351599517778],
            [-74.9058487342864, -6.426333224617424],
            [-74.97120260934724, -6.516434349701353],
            [-74.97550973435126, -6.554925724737188],
            [-74.95603123433314, -6.685486349858771],
            [-74.90140998428227, -6.732709974902749],
            [-74.89540035927668, -6.833201224996357],
            [-74.85096685923529, -6.91451560007208],
            [-74.7992324841871, -6.976831475130132],
            [-74.83660698422192, -7.071665600218446],
            [-74.87521310925786, -7.125052225268159],
            [-74.85236323423659, -7.158140600298974],
            [-74.87626423425884, -7.231670725367458],
            [-74.8344583592199, -7.293276725424828],
            [-74.71990160911322, -7.321773100451379],
            [-74.64490785904339, -7.30089260043194],
            [-74.6419238590406, -7.187009850325874],
            [-74.55038035895532, -7.166110100306392],
            [-74.50009123390852, -7.262049475395766],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 7,
        FIRST_IDPR: '0813',
        NOMBPROV: 'URUBAMBA',
        FIRST_NOMB: 'CUSCO',
        LAST_DCTO: 'LEY',
        LAST_LEY: '9396',
        FIRST_FECH: '09/09/1905',
        LAST_FECHA: '01/10/1941',
        MIN_SHAPE_: 144731.63075,
        ha: 144731.63,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-71.98021685654447, -13.42949610613781],
            [-72.00060323156397, -13.444038231151426],
            [-72.05065435661183, -13.453669981160575],
            [-72.0986953566577, -13.431034606139658],
            [-72.1155499816738, -13.398214481109157],
            [-72.20996035676374, -13.366779481080131],
            [-72.21952173177286, -13.326280981042464],
            [-72.26712448181812, -13.330239606046215],
            [-72.38579910693066, -13.304489731022493],
            [-72.36914335691488, -13.358814606073032],
            [-72.44368298198543, -13.344675731059972],
            [-72.53335198207019, -13.372895356086365],
            [-72.54668160708277, -13.332609356048883],
            [-72.5544238570901, -13.230080105953403],
            [-72.5997714821329, -13.107242230839049],
            [-72.54205573207844, -13.082345980815825],
            [-72.49028523202956, -13.09972623083197],
            [-72.43639360697858, -13.151616605880202],
            [-72.38955473193427, -13.174134730901116],
            [-72.32798373187593, -13.164332105891864],
            [-72.28129935683167, -13.10770373083907],
            [-72.23167448178454, -13.126289480856267],
            [-72.19063460674556, -13.096150355828097],
            [-72.15242160670914, -13.138597855867534],
            [-72.14880298170569, -13.177080230903346],
            [-72.05768298161877, -13.219448980942534],
            [-72.04412773160574, -13.290567856008682],
            [-71.96867898153347, -13.398602106108989],
            [-71.98021685654447, -13.42949610613781],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 7,
        FIRST_IDPR: '0107',
        NOMBPROV: 'UTCUBAMBA',
        FIRST_NOMB: 'AMAZONAS',
        LAST_DCTO: 'LEY',
        LAST_LEY: '23843',
        FIRST_FECH: '14/11/1944',
        LAST_FECHA: '30/05/1984',
        MIN_SHAPE_: 399323.007084,
        ha: 399323.01,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.13080473729819, -5.902560599128826],
            [-78.17241411233698, -5.994150349214023],
            [-78.22371748738463, -6.061550849276669],
            [-78.2914023624472, -6.06514559927983],
            [-78.36811611251788, -6.108618349320072],
            [-78.37306448752256, -6.169783224377005],
            [-78.41972811256537, -6.188424599394203],
            [-78.50906423764646, -6.085308349297821],
            [-78.589442737719, -6.076535849289256],
            [-78.61227161273924, -6.018182474234825],
            [-78.66032036278189, -5.955558599176236],
            [-78.7120153628271, -5.830443099059446],
            [-78.70057048781682, -5.785220849017423],
            [-78.6837978628017, -5.707054098944775],
            [-78.60481598773114, -5.607207848852264],
            [-78.58073548770939, -5.548483598797706],
            [-78.55474736268606, -5.538074723788134],
            [-78.56536173769577, -5.587881973834442],
            [-78.50331123764013, -5.691557098931262],
            [-78.45945661260042, -5.715840973954031],
            [-78.40557061255124, -5.69025734893041],
            [-78.36813073751688, -5.62679147387144],
            [-78.31650736246951, -5.617634223863054],
            [-78.32613186247826, -5.56619047381515],
            [-78.311830362465, -5.485992598740495],
            [-78.23886961239776, -5.470393723726164],
            [-78.19221936235458, -5.41721384867676],
            [-78.14627998731191, -5.339879973604808],
            [-78.10614648727467, -5.341998098606854],
            [-78.07319373724411, -5.409186598669476],
            [-78.02179123719621, -5.365814973629178],
            [-77.95551511213445, -5.399720598660827],
            [-77.93136173711191, -5.460812598717755],
            [-77.94142311212133, -5.489394973744362],
            [-77.99126761216787, -5.514076223767271],
            [-77.98808398716497, -5.567720723817248],
            [-78.10397261227308, -5.755042973991507],
            [-78.07635686224741, -5.769425724004951],
            [-78.13080473729819, -5.902560599128826],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 1,
        FIRST_IDPR: '0510',
        NOMBPROV: 'VICTOR FAFARDO',
        FIRST_NOMB: 'AYACUCHO',
        LAST_DCTO: 'LEY',
        LAST_LEY: 'S/N',
        FIRST_FECH: '02/01/1857',
        LAST_FECHA: '02/01/1857',
        MIN_SHAPE_: 26507.7681717,
        ha: 26507.77,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.89040848334056, -13.89768535657549],
            [-73.86305360831507, -13.937678981612743],
            [-73.88539448333589, -13.99643748166747],
            [-74.00983985845185, -14.030607356699266],
            [-74.04978323348907, -13.982650356654634],
            [-74.07037323350825, -14.02667148169561],
            [-74.11604298355078, -14.028872981697665],
            [-74.10097860853675, -13.952540106626564],
            [-74.11025773354541, -13.908167981585251],
            [-74.0696847335076, -13.887193731565736],
            [-74.0371426084773, -13.936693356611809],
            [-73.89040848334056, -13.89768535657549],
          ],
        ],
      },
    } /* {
            "type": "Feature",
            "properties": {
                "COUNT": 11,
                "FIRST_IDPR": "0510",
                "NOMBPROV": "VICTOR FAJARDO",
                "FIRST_NOMB": "AYACUCHO",
                "LAST_DCTO": "LEY",
                "LAST_LEY": "1306",
                "FIRST_FECH": "23/12/1986",
                "LAST_FECHA": "14/11/1910",
                "MIN_SHAPE_": 199897.767151,
                "ha": 199897.77
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [[[[-73.88539448333589, -13.99643748166747], [-73.9120156083607, -14.11962898178219], [-73.96601323341103, -14.137040481798385], [-73.95003398339614, -14.185727106843746], [-73.9991582334419, -14.15919335681901], [-74.03916898347916, -14.098499856762501], [-74.0854606085223, -14.088808981753479], [-74.07037323350825, -14.02667148169561], [-74.04978323348907, -13.982650356654634], [-74.00983985845185, -14.030607356699266], [-73.88539448333589, -13.99643748166747]]], [[[-74.05007085848935, -13.682645606375226], [-74.00397260844639, -13.697832356389386], [-73.95824235840378, -13.788363856473673], [-73.91181610836053, -13.828455606511026], [-73.89040848334056, -13.89768535657549], [-74.0371426084773, -13.936693356611809], [-74.0696847335076, -13.887193731565736], [-74.11025773354541, -13.908167981585251], [-74.15836060859021, -13.867979481547824], [-74.15976473359153, -13.841370356523047], [-74.2409067336671, -13.783445856469097], [-74.25405660867935, -13.758994606446326], [-74.31388823373507, -13.740335356428954], [-74.42586723383938, -13.79756060648225], [-74.51986323392693, -13.736378731425255], [-74.52608410893271, -13.715335981405692], [-74.61666410901707, -13.707757856398635], [-74.64128910904002, -13.742269481430757], [-74.70728123410147, -13.745257856433541], [-74.76652535915663, -13.772234356458657], [-74.78241998417145, -13.57850998127827], [-74.7094941091035, -13.58515623128445], [-74.62717385902685, -13.55674610625799], [-74.59927348400088, -13.577900356277688], [-74.51653910892381, -13.602298856300424], [-74.44375385885601, -13.58553323128481], [-74.36838673378583, -13.60280073130087], [-74.30132848372337, -13.632719106328743], [-74.13948010857263, -13.632939356328956], [-74.05007085848935, -13.682645606375226]]]]
            }
        }*/,
    ,
    {
      type: 'Feature',
      properties: {
        COUNT: 8,
        FIRST_IDPR: '0511',
        NOMBPROV: 'VILCAS HUAMAN',
        FIRST_NOMB: 'AYACUCHO',
        LAST_DCTO: '-',
        LAST_LEY: '-',
        FIRST_FECH: '12/03/1986',
        LAST_FECHA: 'EPOCA INDEP.',
        MIN_SHAPE_: 120829.28893,
        ha: 120829.29,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-73.74788773320773, -13.763366106450404],
            [-73.80332273325942, -13.796138981480915],
            [-73.80451948326052, -13.864484356544574],
            [-73.8260496082806, -13.91453985659118],
            [-73.86305360831507, -13.937678981612743],
            [-73.89040848334056, -13.89768535657549],
            [-73.91181610836053, -13.828455606511026],
            [-73.95824235840378, -13.788363856473673],
            [-74.00397260844639, -13.697832356389386],
            [-74.05007085848935, -13.682645606375226],
            [-74.03932223347931, -13.604837856302785],
            [-74.05562885849449, -13.535736356238422],
            [-74.08046873351766, -13.450576231159111],
            [-74.0079644834501, -13.412506731123672],
            [-73.979946233424, -13.42804348113814],
            [-73.99047223343382, -13.480883981187327],
            [-73.95987185840528, -13.500893606205974],
            [-73.90428910835351, -13.455087856163313],
            [-73.83348798328753, -13.471785856178844],
            [-73.81424373326959, -13.509212481213714],
            [-73.79192085824879, -13.640959606336413],
            [-73.74361973320376, -13.655562481349993],
            [-73.67371348313856, -13.730739356420004],
            [-73.74788773320773, -13.763366106450404],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 3,
        FIRST_IDPR: '1312',
        NOMBPROV: 'VIRU',
        FIRST_NOMB: 'LA LIBERTAD',
        LAST_DCTO: 'LEY',
        LAST_LEY: '13792',
        FIRST_FECH: '04/01/1995',
        LAST_FECHA: '28/12/1961',
        MIN_SHAPE_: 319306.79799,
        ha: 319306.8,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.63908861277253, -8.143001226212721],
            [-78.65246661278493, -8.184655976251383],
            [-78.60697036274347, -8.195391226261703],
            [-78.56614261270624, -8.249533226312396],
            [-78.58829686272684, -8.307498101366217],
            [-78.47913748762642, -8.351083351407468],
            [-78.42985448758078, -8.360237976416268],
            [-78.37388948752887, -8.4045436014578],
            [-78.3742656125294, -8.45963672650909],
            [-78.3476269875046, -8.474464851522999],
            [-78.2636984874262, -8.467382351516754],
            [-78.28263773744413, -8.571934101614016],
            [-78.23557823740028, -8.652919976689619],
            [-78.35481048751181, -8.67327935170808],
            [-78.44822836259907, -8.736926726766884],
            [-78.518138737664, -8.752980601781411],
            [-78.56534311270782, -8.792598976817976],
            [-78.5882811127293, -8.85867747687933],
            [-78.57648473771854, -8.889046601907664],
            [-78.59166248773285, -8.945999226960573],
            [-78.64492386278206, -8.969228476981794],
            [-78.65166211278796, -8.914355476930648],
            [-78.76115823788716, -8.773956976798964],
            [-78.73738286286519, -8.71208410174162],
            [-78.74382611287052, -8.63383010166871],
            [-78.7780078629011, -8.569412101608421],
            [-78.93488473804013, -8.436324226482727],
            [-78.90083986300985, -8.414230476462595],
            [-78.9005274880092, -8.371453101422787],
            [-78.881374987992, -8.342660101396232],
            [-78.81675811293415, -8.309992726366563],
            [-78.80249636292102, -8.25755160131791],
            [-78.83814111295243, -8.198236226262308],
            [-78.77979536290026, -8.201623226266054],
            [-78.76387498788579, -8.173830101240346],
            [-78.63908861277253, -8.143001226212721],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 8,
        FIRST_IDPR: '1011',
        NOMBPROV: 'YAROWILCA',
        FIRST_NOMB: 'HUANUCO',
        LAST_DCTO: 'LEY',
        LAST_LEY: 'S/N',
        FIRST_FECH: '06/09/1920',
        LAST_FECHA: '02/01/1857',
        MIN_SHAPE_: 76463.4341542,
        ha: 76463.43,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.57047461083748, -9.959232477907658],
            [-76.61155073587582, -9.926880852877542],
            [-76.7200604859772, -9.898357477850977],
            [-76.75801948601269, -9.842460852798933],
            [-76.73327536098954, -9.779392477740176],
            [-76.70249023596078, -9.74803910271099],
            [-76.72298236097994, -9.71138472767684],
            [-76.67339061093358, -9.681669977649184],
            [-76.52629486079621, -9.665648477634267],
            [-76.47696873575019, -9.673880852641924],
            [-76.46302348573718, -9.706246102672072],
            [-76.46739261074126, -9.820171852778165],
            [-76.45508598572977, -9.881613102835384],
            [-76.54877148581721, -9.922632977873588],
            [-76.57047461083748, -9.959232477907658],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 10,
        FIRST_IDPR: '1208',
        NOMBPROV: 'YAULI',
        FIRST_NOMB: 'JUNIN',
        LAST_DCTO: 'LEY',
        LAST_LEY: '12097',
        FIRST_FECH: '13/01/1962',
        LAST_FECHA: '07/04/1954',
        MIN_SHAPE_: 363634.997669,
        ha: 363635,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-76.21462998550552, -11.150309979016933],
            [-76.2126698605037, -11.152399979018892],
            [-76.20674998549816, -11.150339979016943],
            [-76.20301998549469, -11.14922997901591],
            [-76.20221998549393, -11.1499699790166],
            [-76.20140998549319, -11.154099979020438],
            [-76.20209998549383, -11.155259979021517],
            [-76.20379998549542, -11.156129979022339],
            [-76.20837986049969, -11.165979854031502],
            [-76.20831998549963, -11.166819979032294],
            [-76.20901998550028, -11.1677899790332],
            [-76.20828998549962, -11.170049979035316],
            [-76.20722998549863, -11.171099979036304],
            [-76.20588373549737, -11.172209104037327],
            [-76.20465623549622, -11.172589104037677],
            [-76.20341423549506, -11.172004604037127],
            [-76.20301986049468, -11.171858479036993],
            [-76.20211386049384, -11.171756229036893],
            [-76.20014123549201, -11.171595479036748],
            [-76.1980809854901, -11.17161010403676],
            [-76.1978617354899, -11.171829229036963],
            [-76.19741861048946, -11.173234479038268],
            [-76.19745761048952, -11.175124229040033],
            [-76.19751598548955, -11.176468479041278],
            [-76.19780823548983, -11.179351854043965],
            [-76.19712636048921, -11.180092229044655],
            [-76.19525598548746, -11.181475479045947],
            [-76.19361948548594, -11.181650854046111],
            [-76.19106723548354, -11.181631354046099],
            [-76.18999573548255, -11.181962604046408],
            [-76.18900211048162, -11.182663979047067],
            [-76.18732661048006, -11.183676979048004],
            [-76.18654736047934, -11.183579604047917],
            [-76.18551473547836, -11.182605479047007],
            [-76.18487186047776, -11.181650854046113],
            [-76.18461861047754, -11.181183354045679],
            [-76.18302098547605, -11.179975354044547],
            [-76.18272223547578, -11.180030604044607],
            [-76.18144948547459, -11.181718979046188],
            [-76.18204686047514, -11.182602229046985],
            [-76.18310998547615, -11.182709979047086],
            [-76.18508998547796, -11.185639979049846],
            [-76.18678998547955, -11.188009979052044],
            [-76.18770436048041, -11.189316479053273],
            [-76.1879021104806, -11.189969229053862],
            [-76.18761536048032, -11.191996729055758],
            [-76.18749661048022, -11.19244172905617],
            [-76.18708123547984, -11.193499979057139],
            [-76.18678436047956, -11.194914354058461],
            [-76.18869336048132, -11.196991229060394],
            [-76.18968236048224, -11.198069229061405],
            [-76.19137348548385, -11.198998979062273],
            [-76.19305486048539, -11.20050222906368],
            [-76.18935598548194, -11.200779104063933],
            [-76.18827798548094, -11.201599979064682],
            [-76.18777361048046, -11.203597729066542],
            [-76.18836698548103, -11.205011979067857],
            [-76.18853511048118, -11.206515354069277],
            [-76.18823836048092, -11.206960479069673],
            [-76.18725923547998, -11.207771479070445],
            [-76.18452961047745, -11.211895604074282],
            [-76.18418361047715, -11.21278560407511],
            [-76.18431198547727, -11.214694479076904],
            [-76.18365923547663, -11.216464854078529],
            [-76.18292736047596, -11.21745385407947],
            [-76.17816036047151, -11.21900660408092],
            [-76.1760933604696, -11.218986729080896],
            [-76.17487686046847, -11.219154979081038],
            [-76.17341311046708, -11.219392354081243],
            [-76.17195923546574, -11.219570354081421],
            [-76.17135598546517, -11.21958022908145],
            [-76.16937798546334, -11.219797854081655],
            [-76.1680527354621, -11.220470354082279],
            [-76.16756811046163, -11.221330729083075],
            [-76.16757798546165, -11.224564854086065],
            [-76.17091098546476, -11.225256979086717],
            [-76.17280986046653, -11.226048354087462],
            [-76.173294485467, -11.226434104087822],
            [-76.17395711046758, -11.22738347908871],
            [-76.17397686046762, -11.228352729089613],
            [-76.17345273546711, -11.229529604090692],
            [-76.1734362354671, -11.230754354091834],
            [-76.17402961046766, -11.230978604092043],
            [-76.17676998547023, -11.23320997909412],
            [-76.17700998547045, -11.233669979094554],
            [-76.17751998547092, -11.23591997909666],
            [-76.17692998547037, -11.239749979100209],
            [-76.17734998547076, -11.241849979102184],
            [-76.17728998547071, -11.242979979103229],
            [-76.17316998546687, -11.249629979109429],
            [-76.17251998546625, -11.25126985411096],
            [-76.17158998546539, -11.255259979114646],
            [-76.17057998546446, -11.255889979115247],
            [-76.16840998546242, -11.256679979115988],
            [-76.16757998546164, -11.257989979117196],
            [-76.1676099854617, -11.262459854121353],
            [-76.16755998546164, -11.263330104122177],
            [-76.1669899854611, -11.264669979123433],
            [-76.1661299854603, -11.265779979124463],
            [-76.16495998545922, -11.270789979129132],
            [-76.16307998545747, -11.272629979130853],
            [-76.1622499854567, -11.273419979131592],
            [-76.15995998545456, -11.275609979133609],
            [-76.15648998545132, -11.278699979136501],
            [-76.15626998545112, -11.280869979138519],
            [-76.15502011044993, -11.282699979140208],
            [-76.15436998544934, -11.2845199791419],
            [-76.15392998544893, -11.285599979142933],
            [-76.15341998544844, -11.286329979143618],
            [-76.15090998544612, -11.287619979144797],
            [-76.1502499854455, -11.287829979144995],
            [-76.14744998544288, -11.288499979145614],
            [-76.14672998544224, -11.288869979145948],
            [-76.14002998543599, -11.2966399791532],
            [-76.1387699854348, -11.30508997916108],
            [-76.13804998543412, -11.306379979162278],
            [-76.1352501104315, -11.307659979163452],
            [-76.13450998543084, -11.308749979164478],
            [-76.1340499854304, -11.31007997916573],
            [-76.13434998543067, -11.311909979167426],
            [-76.13488998543117, -11.312699979168162],
            [-76.135759985432, -11.313229854168656],
            [-76.13701998543316, -11.314389979169748],
            [-76.1360699854323, -11.315369979170649],
            [-76.1339399854303, -11.316049979171261],
            [-76.13285998542928, -11.31726997917242],
            [-76.13196998542846, -11.317869979172977],
            [-76.12992998542657, -11.318899979173933],
            [-76.12831998542507, -11.321559979176428],
            [-76.1273998604242, -11.322760104177526],
            [-76.12655998542341, -11.323449979178152],
            [-76.12513998542212, -11.324509979179153],
            [-76.12420998542123, -11.32580997918036],
            [-76.12244998541958, -11.32484997917948],
            [-76.12139998541862, -11.326349979180884],
            [-76.12092998541819, -11.327469979181911],
            [-76.12024998541756, -11.328009979182417],
            [-76.11967998541702, -11.328389979182761],
            [-76.11792986041536, -11.329819979184103],
            [-76.11682998541434, -11.330499979184749],
            [-76.11502998541268, -11.330749979184965],
            [-76.11411998541183, -11.330969979185184],
            [-76.1120499854099, -11.331769979185912],
            [-76.11049998540845, -11.332999979187075],
            [-76.10917998540722, -11.334219979188205],
            [-76.10858998540668, -11.334869979188802],
            [-76.10804998540617, -11.33725997919104],
            [-76.10660998540483, -11.3419499791954],
            [-76.1064499854047, -11.344599979197861],
            [-76.10585523540414, -11.351217229204037],
            [-76.10574911040403, -11.351686354204462],
            [-76.10557198540387, -11.35258035420529],
            [-76.10464273540299, -11.353624729206278],
            [-76.10358948540201, -11.354022979206636],
            [-76.10102273539964, -11.35644810420889],
            [-76.09977023539847, -11.358262854210585],
            [-76.09689461039576, -11.360908479213043],
            [-76.09619486039512, -11.362739354214751],
            [-76.09591686039488, -11.363630729215576],
            [-76.09586898539482, -11.365202729217048],
            [-76.09561973539458, -11.367560729219237],
            [-76.09559098539455, -11.369525854221095],
            [-76.09546636039445, -11.370196729221705],
            [-76.09514998539416, -11.371567479222968],
            [-76.09487448539389, -11.37226847922365],
            [-76.08564848538529, -11.381665354232387],
            [-76.08140736038135, -11.387733729238036],
            [-76.07891748537902, -11.385741729236186],
            [-76.07657398537684, -11.385097354235581],
            [-76.07563661037595, -11.38536097923584],
            [-76.07174373537235, -11.387429729237747],
            [-76.07075586037142, -11.38741597923775],
            [-76.06932061037006, -11.385837854236268],
            [-76.06712311036803, -11.383407729233992],
            [-76.06510073536616, -11.382964354233598],
            [-76.06271811036395, -11.384559854235091],
            [-76.06221423536347, -11.384629229235154],
            [-76.06079486036215, -11.383785479234348],
            [-76.0591463603606, -11.382461604233129],
            [-76.05133223535333, -11.377540104228533],
            [-76.04900073535114, -11.37680497922785],
            [-76.04780648535004, -11.37453360422576],
            [-76.04696173534924, -11.371797729223186],
            [-76.04517598534758, -11.371547104222948],
            [-76.04257561034515, -11.37179772922318],
            [-76.04202273534463, -11.3721073542235],
            [-76.0312301103346, -11.374419229225632],
            [-76.02656336033023, -11.379937979230764],
            [-76.02313548532705, -11.377505479228498],
            [-76.02093211032498, -11.373719354225004],
            [-76.01469336031919, -11.375476604226623],
            [-76.01331248531788, -11.375671854226809],
            [-76.01105623531579, -11.373296479224608],
            [-76.00924598531411, -11.369675854221219],
            [-76.00831073531323, -11.367775104219458],
            [-76.00653861031158, -11.3654558542173],
            [-76.00445936030964, -11.364598479216495],
            [-76.00123273530663, -11.364903729216769],
            [-76.00005498530554, -11.364739354216628],
            [-75.99816511030379, -11.36421897921615],
            [-75.99178448529784, -11.362403354214464],
            [-75.98992811029609, -11.360577604212764],
            [-75.98907248529531, -11.359574354211832],
            [-75.98503923529154, -11.358152104210506],
            [-75.98388761029048, -11.358382479210697],
            [-75.9811199852879, -11.358279979210623],
            [-75.97972998528661, -11.357689979210065],
            [-75.97895811028587, -11.357822604210195],
            [-75.97855498528551, -11.356314354208767],
            [-75.97772898528473, -11.354658354207237],
            [-75.97634023528343, -11.35346297920613],
            [-75.97514673528232, -11.35338410420606],
            [-75.97358161028089, -11.353530479206173],
            [-75.97220786027958, -11.353507979206158],
            [-75.96911273527672, -11.35080622920366],
            [-75.96938161027697, -11.348001104201028],
            [-75.97057761027807, -11.345972604199165],
            [-75.96503186027292, -11.340426854193973],
            [-75.9622514852703, -11.335838354189706],
            [-75.96082986026899, -11.333473354187515],
            [-75.9601899852684, -11.333323979187364],
            [-75.95705686026547, -11.334071604188047],
            [-75.93944523524908, -11.347132229200216],
            [-75.88531998519863, -11.40407997925326],
            [-75.91638286022757, -11.415831979264212],
            [-75.87438998518844, -11.490928729334156],
            [-75.80176361012079, -11.544773104384289],
            [-75.7564404850786, -11.63957060447257],
            [-75.70038061002636, -11.712312854540334],
            [-75.75464523507692, -11.774746979598445],
            [-75.81496061013308, -11.789636104612324],
            [-75.83226248514924, -11.76984897959389],
            [-75.89307573520588, -11.783552479606671],
            [-75.90665423521853, -11.873640604690564],
            [-75.95574711026427, -11.87049285468761],
            [-76.02433011032817, -11.89763472971289],
            [-76.05186148535381, -11.938962104751385],
            [-76.05131111035328, -11.892237979707872],
            [-76.13694536043312, -11.780957229604226],
            [-76.20232773549405, -11.72804922955497],
            [-76.20488273549641, -11.659531104491146],
            [-76.1877071104804, -11.592789854429006],
            [-76.22370511051399, -11.562733229401],
            [-76.28678873557278, -11.591232604427553],
            [-76.37683911065677, -11.50148860434396],
            [-76.37150998565178, -11.475523229319775],
            [-76.41183186068942, -11.41459735426304],
            [-76.42268361069952, -11.35485547920741],
            [-76.45702348573157, -11.334427729188377],
            [-76.47769611075086, -11.29352885415028],
            [-76.49207723576428, -11.189313979053235],
            [-76.51840236078887, -11.116078103985025],
            [-76.49121286076347, -11.089441353960218],
            [-76.39721673567577, -11.075761728947487],
            [-76.31807023560197, -11.09164260396229],
            [-76.30790036059248, -11.132664104000476],
            [-76.21462998550552, -11.150309979016933],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 33,
        FIRST_IDPR: '1510',
        NOMBPROV: 'YAUYOS',
        FIRST_NOMB: 'LIMA',
        LAST_DCTO: 'LEY',
        LAST_LEY: '12644',
        FIRST_FECH: '26/03/1965',
        LAST_FECHA: '02/02/1956',
        MIN_SHAPE_: 699051.38377,
        ha: 699051.38,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-75.96140236026953, -13.001378855740784],
            [-75.95743098526582, -12.969891355711459],
            [-75.98998236029615, -12.922085980666928],
            [-76.00854711031344, -12.85386685560342],
            [-75.95940998526767, -12.84896998059887],
            [-75.94253373525196, -12.802141605555255],
            [-75.98392836029052, -12.782298855536773],
            [-76.04516911034757, -12.781957480536462],
            [-76.14444286044008, -12.82042485557228],
            [-76.19936611049127, -12.813454980565778],
            [-76.28301136056926, -12.752124730508655],
            [-76.29955423558468, -12.715343480474427],
            [-76.25166936054002, -12.591592230359154],
            [-76.29103961057672, -12.57423910534301],
            [-76.3521152356337, -12.520017605292491],
            [-76.31354686059771, -12.430623105209255],
            [-76.34539586062743, -12.388004105169582],
            [-76.345997735628, -12.337726480122736],
            [-76.32372386060722, -12.291551855079732],
            [-76.25030161053877, -12.29973035508738],
            [-76.2168636105076, -12.264251355054313],
            [-76.0694692353702, -12.207410980001388],
            [-76.08622323538583, -12.16040097995762],
            [-76.05679298535841, -12.13273247993184],
            [-76.06135073536267, -12.088517604890665],
            [-76.0197621103239, -12.062770854866674],
            [-75.99317973529912, -11.994679729803273],
            [-75.92599086023655, -11.98429422979361],
            [-75.82407386014157, -12.015687229822818],
            [-75.76566298508719, -12.05962735486376],
            [-75.69430473502072, -12.042691354848007],
            [-75.62752348495852, -12.141740229940213],
            [-75.66412435999263, -12.194053604988946],
            [-75.56409610989944, -12.33628898012142],
            [-75.55958998489525, -12.410719980190715],
            [-75.53620110987345, -12.517275355289954],
            [-75.57184060990664, -12.564067855333537],
            [-75.57961323491386, -12.641484355405629],
            [-75.551818109888, -12.68142673044281],
            [-75.57655073491104, -12.715142480474228],
            [-75.51389260985268, -12.788652355542677],
            [-75.53108185986869, -12.822207855573941],
            [-75.61309160994507, -12.866975355615637],
            [-75.63612460996653, -12.916175230661457],
            [-75.61439310994628, -12.964933355706847],
            [-75.66323598499179, -12.994035730733955],
            [-75.67036123499841, -13.022611730760554],
            [-75.72546436004973, -13.038124980775018],
            [-75.76215848508393, -13.018888605757096],
            [-75.80206248512107, -13.060429730795773],
            [-75.93768286024742, -12.991324855731415],
            [-75.96140236026953, -13.001378855740784],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 8,
        FIRST_IDPR: '0220',
        NOMBPROV: 'YUNGAY',
        FIRST_NOMB: 'ANCASH',
        LAST_DCTO: 'L.R.',
        LAST_LEY: '176',
        FIRST_FECH: '02/01/1857',
        LAST_FECHA: '02/08/1920',
        MIN_SHAPE_: 136484.28304,
        ha: 136484.28,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-78.05609948723257, -9.274701227269121],
            [-78.03716761221472, -9.243706977240347],
            [-77.86126761204886, -9.251189602247656],
            [-77.83947073702828, -9.217574227216396],
            [-77.79752161198877, -9.229190727227259],
            [-77.77718636196956, -9.181542602182937],
            [-77.78115623697323, -9.085154352093161],
            [-77.69981448689657, -9.056933602066968],
            [-77.63235723683302, -9.009759977023124],
            [-77.57426573677829, -8.900442976921365],
            [-77.56345311176813, -8.903373101924089],
            [-77.5440027367498, -8.931291976950122],
            [-77.55273048675807, -8.987820352002734],
            [-77.48653711169577, -9.007269477020921],
            [-77.44514448665683, -8.990177727005003],
            [-77.38632811160157, -8.99508197700961],
            [-77.35949611157633, -8.977238226993013],
            [-77.36200198657869, -9.064992227074734],
            [-77.43734573664952, -9.059246102069354],
            [-77.49046036169948, -9.106671852113488],
            [-77.530343736737, -9.082921977091324],
            [-77.60131998680382, -9.108935227115486],
            [-77.58891836179217, -9.15870022716186],
            [-77.61990011182135, -9.214448352213747],
            [-77.66137598686045, -9.239836727237353],
            [-77.68929673688673, -9.224164727222714],
            [-77.72450748691996, -9.276399102271311],
            [-77.76564848695872, -9.2923163522861],
            [-77.77125273696406, -9.333952352324863],
            [-77.74419598693856, -9.370708977359131],
            [-77.7620318619554, -9.417154727402359],
            [-77.90099611208649, -9.442234352425498],
            [-77.93290848711656, -9.398981977385146],
            [-77.99294948717323, -9.446605602429386],
            [-78.03017911220833, -9.418492352403105],
            [-78.00331648718294, -9.380970602368217],
            [-78.0597479872361, -9.33084960232142],
            [-78.05609948723257, -9.274701227269121],
          ],
        ],
      },
    } /* {
            "type": "Feature",
            "properties": {
                "COUNT": 7,
                "FIRST_IDPR": "2113",
                "NOMBPROV": "YUNGUYO",
                "FIRST_NOMB": "PUNO",
                "LAST_DCTO": "LEY",
                "LAST_LEY": "24042",
                "FIRST_FECH": "28/12/1984",
                "LAST_FECHA": "28/12/1984",
                "MIN_SHAPE_": 42065.0115975,
                "ha": 42065.01
            },
            "geometry": {
                "type": "MultiPolygon",
                "coordinates": [[[[-68.91838872820082, -16.403720733548646], [-68.9183991032421, -16.23329710839452], [-68.81421685311504, -16.319773483425823], [-68.81421685310735, -16.347986358451255], [-68.91838872820082, -16.403720733548646]]], [[[-69.20017160353875, -16.26418623352617], [-69.14535410347972, -16.253145483498322], [-69.05525885339289, -16.19736160841603], [-68.97685035329701, -16.25708273344015], [-69.05064035337023, -16.282703733491807], [-69.02195872832873, -16.331905983525626], [-69.04295097833312, -16.42040173361385], [-69.14194035346148, -16.330993733567965], [-69.1909384785196, -16.314549983569098], [-69.20017160353875, -16.26418623352617]]]]
            }
        }*/,
    ,
    {
      type: 'Feature',
      properties: {
        COUNT: 4,
        FIRST_IDPR: '2403',
        NOMBPROV: 'ZARUMILLA',
        FIRST_NOMB: 'TUMBES',
        LAST_DCTO: 'LEY',
        LAST_LEY: 'S/N',
        FIRST_FECH: '25/11/1942',
        LAST_FECHA: '12/01/1871',
        MIN_SHAPE_: 75819.2950151,
        ha: 75819.3,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-80.18073823863193, -3.925826222259574],
            [-80.24358598862474, -3.897535097230554],
            [-80.25580586362024, -3.83276509717014],
            [-80.30499986360758, -3.772566472112142],
            [-80.29583398860571, -3.673839847021456],
            [-80.33779298859243, -3.631466846980295],
            [-80.37883011357219, -3.494611346851987],
            [-80.29235936359613, -3.408804721776996],
            [-80.22052148861104, -3.443858346812469],
            [-80.23972848860942, -3.477789096843002],
            [-80.2061738636159, -3.534517596896857],
            [-80.21812298861586, -3.57298247193186],
            [-80.18876948861973, -3.597933596956174],
            [-80.18080861362517, -3.738581972086482],
            [-80.19260161362554, -3.771683597116574],
            [-80.16043161362836, -3.80239059714634],
            [-80.15855473863076, -3.869818347208747],
            [-80.1271653636317, -3.895515972233853],
            [-80.18073823863193, -3.925826222259574],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        COUNT: 3,
        FIRST_IDPR: '0104',
        NOMBPROV: 'CONDORCANQUI',
        FIRST_NOMB: 'AMAZONAS',
        LAST_DCTO: 'LEY',
        LAST_LEY: '23832',
        FIRST_FECH: '18/05/1984',
        LAST_FECHA: '18/05/1984',
        MIN_SHAPE_: 1792723.84631,
        ha: 1792723.85,
      },
      geometry: {
        type: 'Polygon',
        coordinates: [
          [
            [-77.74528511193766, -5.133017598412655],
            [-77.95551511213445, -5.399720598660827],
            [-78.02179123719621, -5.365814973629178],
            [-78.07319373724411, -5.409186598669476],
            [-78.10614648727467, -5.341998098606854],
            [-78.06761323723877, -5.265271473535475],
            [-78.05689711222878, -5.20859172348272],
            [-78.09932248726814, -5.14608997342442],
            [-78.15881136232325, -5.109946973390659],
            [-78.19484098735651, -5.037893598323512],
            [-78.11941261228651, -4.840238973139591],
            [-78.14523986231033, -4.758099348063056],
            [-78.12014111228703, -4.699513348008532],
            [-78.1472857373121, -4.643187597956048],
            [-78.21889923737828, -4.658537722970214],
            [-78.25334836241004, -4.705210473013595],
            [-78.2772073624319, -4.66275172297401],
            [-78.32948048747983, -4.679537097989519],
            [-78.3612611125088, -4.647703597959793],
            [-78.41692573755948, -4.659531097970655],
            [-78.46583011260371, -4.648492222960219],
            [-78.52242998765449, -4.585746097901617],
            [-78.5887153627136, -4.561966472879238],
            [-78.59891761272249, -4.509091222829963],
            [-78.63575023775503, -4.500882472822165],
            [-78.63407811275331, -4.413238222740591],
            [-78.65227298776908, -4.332512722665349],
            [-78.61824411273902, -4.294221722629858],
            [-78.5813222377059, -4.135498097482231],
            [-78.54079236266972, -4.073877222424994],
            [-78.56263486268911, -4.049814847402519],
            [-78.56611036269207, -3.993301097349911],
            [-78.52450486265491, -3.937263972297858],
            [-78.4886691126228, -3.933608097294566],
            [-78.47653123761178, -3.858693347224828],
            [-78.41390736255522, -3.792609722163466],
            [-78.4024764875448, -3.735387597110208],
            [-78.42273786256305, -3.690889597068724],
            [-78.40081761254315, -3.651339222031983],
            [-78.38769336253117, -3.554970971942258],
            [-78.36492973751047, -3.529699221918781],
            [-78.35918311250518, -3.469521971862763],
            [-78.31874023746829, -3.395069471793512],
            [-78.24621098740202, -3.402326221800398],
            [-78.21478961237327, -3.508593721899396],
            [-78.14916936231282, -3.480796096873582],
            [-78.17120873733303, -3.350827846752538],
            [-77.95136711212929, -3.09052534651033],
            [-77.93250998711174, -3.035284346458909],
            [-77.89150748707354, -3.009034596434486],
            [-77.8086159869962, -2.986125096413193],
            [-77.77852461196815, -3.187176221600434],
            [-77.7764788619663, -3.409130971807158],
            [-77.76053936195142, -3.459802971854373],
            [-77.61693561181704, -3.634301972016952],
            [-77.61562298681582, -3.707718722085303],
            [-77.65182223684975, -3.779009722151685],
            [-77.56940486177257, -3.872749597239043],
            [-77.5417728617467, -3.943334222304774],
            [-77.53812336174326, -4.028707972384298],
            [-77.54965573675409, -4.109892847459897],
            [-77.58555073678774, -4.251333972591607],
            [-77.56538686176886, -4.372462472704432],
            [-77.58594311178817, -4.475013097799928],
            [-77.65545698685331, -4.518544972840444],
            [-77.64901561184728, -4.5464159728664],
            [-77.6719453618688, -4.629468472943739],
            [-77.77187886196239, -4.717558598025701],
            [-77.79462186198369, -4.763006848068038],
            [-77.79357486198278, -4.849963473149002],
            [-77.7601517369515, -4.909345098204342],
            [-77.75928973695069, -4.951781348243859],
            [-77.82461961201189, -5.031778723318306],
            [-77.8222774870097, -5.081792723364894],
            [-77.7742867369648, -5.097177973379262],
            [-77.74528511193766, -5.133017598412655],
          ],
        ],
      },
    },
  ],
};

exports.provinces = provinces;
