import React from 'react';
import { Modal } from 'antd';

const ViewImage = ({ visible, setVisible, file, setFile = () => {} }) => {
  return (
    <Modal
      open={visible}
      footer={null}
      onCancel={() => {
        setVisible(false);
        setFile(null);
      }}
      centered={true}
      closable={true}
      className='modal-image'
    >
      <div className='content-preview'>
        <img src={file} alt='preview' />

        <div className='download mt-2 mb-3'></div>
      </div>
    </Modal>
  );
};

export default ViewImage;
