import React from 'react';

const Filters = (props) => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M21.25 15.625H12.5V13.125H21.25V15.625ZM21.25 18.125H12.5V20.625H21.25V18.125ZM8.75 20.625V8.75H23.75C24.4391 8.75 25 9.31094 25 10V20.625H27.5V10C27.5 7.93281 25.8172 6.25 23.75 6.25H15.625V3.75H30C30.6891 3.75 31.25 4.31094 31.25 5V20.625H32.1766C32.7656 20.625 33.3156 20.8281 33.75 21.1797V5C33.75 2.93281 32.0672 1.25 30 1.25H13.125V6.25H6.25V21.2031C6.68906 20.8359 7.24844 20.625 7.85156 20.625H8.75Z'
        fill='url(#paint0_linear_827_16672)'
      />
      <path
        d='M32.1765 22.5H7.8515C7.28275 22.5 7.00931 23.2 7.42806 23.5844L17.6062 32.9516L18.1234 38.7485H21.8734L22.1578 33.436L32.6046 23.5782C33.0156 23.1907 32.7406 22.4985 32.1749 22.4985L32.1765 22.5Z'
        fill='url(#paint1_linear_827_16672)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_827_16672'
          x1='6.25'
          y1='1.25'
          x2='14.6686'
          y2='27.3564'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.272135' stopColor='#34263B' />
          <stop offset='0.658079' stopColor='#13172C' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_827_16672'
          x1='7.89443'
          y1='23.7738'
          x2='20.015'
          y2='42.8506'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.35' stopColor='#F7052D' />
          <stop offset='0.85' stopColor='#CA5AFA' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Filters;
