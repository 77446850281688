import React from 'react';
import { IconCloseCircle } from '../Icons';

const FloatWindows = ({ title, children, show, handleShow }) => {
  return (
    <div className={`fw-layer ${!show && 'hidden'}`}>
      <div className='fw'>
        <div className='fw-top'>
          {title && <h4>{title}</h4>}
          <IconCloseCircle onClick={() => handleShow(false)} />
        </div>
        <div className='fw-content'>{children}</div>
        <div className='fw-buttom'></div>
      </div>
    </div>
  );
};

export default FloatWindows;
