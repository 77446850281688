import {
  MY_ROUTES_SET_LIST,
  MY_ROUTES_IS_LOADING_LIST,
  MY_ROUTE_SET_ACTIVE_ROUTE,
  MY_ROUTES_SET_ORIGIN,
  MY_ROUTES_SET_DESTINATION,
  MY_ROUTES_ENABLE_CREATE_ROUTE_MODE,
  MY_ROUTES_SET_POINTS_TO_GENERATE_REPORT,
  MY_ROUTES_SET_GEOJSON_ROUTE,
  MY_ROUTES_SET_MY_ROUTE_LAYER,
} from '../actions/actionsType';

const initialState = {
  list: [],
  isLoadingList: false,
  activeRoute: null,
  myRouteLayer: null,
  origin: '',
  destination: '',
  createRouteMode: false,
  pointsToGenerateReport: null,
  geoJSONRoute: null,
};

const myRoutes = (state, action) => {
  // NOSONAR
  if (!state) state = initialState;

  switch (action.type) {
    case MY_ROUTES_SET_LIST:
      return {
        ...state,
        list: action.payload,
      };
    case MY_ROUTES_IS_LOADING_LIST:
      return {
        ...state,
        isLoadingList: action.payload,
      };
    case MY_ROUTE_SET_ACTIVE_ROUTE:
      return {
        ...state,
        activeRoute: action.payload,
      };
    case MY_ROUTES_SET_MY_ROUTE_LAYER:
      return {
        ...state,
        myRouteLayer: action.payload,
      };
    case MY_ROUTES_SET_ORIGIN:
      return {
        ...state,
        origin: action.payload,
      };
    case MY_ROUTES_SET_DESTINATION:
      return {
        ...state,
        destination: action.payload,
      };
    case MY_ROUTES_ENABLE_CREATE_ROUTE_MODE:
      return {
        ...state,
        createRouteMode: action.payload,
      };
    case MY_ROUTES_SET_POINTS_TO_GENERATE_REPORT:
      return {
        ...state,
        pointsToGenerateReport: action.payload,
      };
    case MY_ROUTES_SET_GEOJSON_ROUTE:
      return {
        ...state,
        geoJSONRoute: action.payload,
      };
    default:
      return state;
  }
};

export default myRoutes;
