import React from 'react';
import { Button, Form, Input } from 'antd';
import iconSecurity from '../../images/ic_shield.png';

const formLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 8 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
};

const DatosAcceso = ({ addData, handleRegister }) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    addData(values, handleRegister);
  };

  const validateRePW = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error('Las contraseñas no coinciden'));
    },
  });

  const validatePW = () => ({
    validator(_, value) {
      if (!value) {
        return Promise.reject(new Error('Este campo es obligatorio'));
      }
      const re = {
        number: /[0-9]/,
        lower: /[a-z]/,
        upper: /[A-Z]/,
        special: /[$-/:-?{-~!"^_`\[\]@#]/,
      };
      if (!re.number.test(value)) {
        return Promise.reject(
          new Error('La contraseña debe tener al menos un número'),
        );
      }
      if (!re.lower.test(value)) {
        return Promise.reject(
          new Error('La contraseña debe tener al menos una letra en minúscula'),
        );
      }
      if (!re.upper.test(value)) {
        return Promise.reject(
          new Error('La contraseña debe tener al menos una letra en mayúscula'),
        );
      }
      if (!re.special.test(value)) {
        return Promise.reject(
          new Error('La contraseña debe tener al menos un caractér especial'),
        );
      }
      return Promise.resolve();
    },
  });

  return (
    <div className='card_register_content form' style={{ marginTop: '8px' }}>
      <Form
        {...formLayout}
        form={form}
        onFinish={onFinish}
        style={{ maxWidth: 320 }}
      >
        <Form.Item
          name='password'
          rules={[
            { required: true, message: 'Este campo es obligatorio' },
            {
              min: 8,
              message: 'La contraseña debe tener mínimo de 8 carácteres',
            },
            validatePW(),
          ]}
        >
          <Input.Password placeholder='Ingresar contraseña' />
        </Form.Item>
        <Form.Item
          name='repassword'
          dependencies={['password']}
          rules={[
            { required: true, message: 'Este campo es obligatorio' },
            {
              min: 8,
              message: 'La contraseña debe tener mínimo de 8 carácteres',
            },
            validateRePW,
          ]}
        >
          <Input.Password placeholder='Repetir contraseña' />
        </Form.Item>

        <div className='box_security'>
          <div className='icon_security'>
            <img src={iconSecurity} alt='Seguridad' />
          </div>
          <div className='text_message'>
            Una contraseña segura tiene mínimo 8 caracteres, entre minúsculas,
            mayúsculas, números y un carácter especial{' '}
            <strong>({'~!@#$%^&*-`|{}[]:;"<>,.?/'})</strong>.
          </div>
        </div>

        <Form.Item className='btn_create_password'>
          <Button type='primary' htmlType='submit'>
            <b>Crear contraseña</b>
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default DatosAcceso;
