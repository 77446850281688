import { post, put, get } from '../lib/ajax';
import {
  CHANGE_USERS_QUERY,
  LOADED_ARTICLES,
  LOADING_CREATE_ARTICLE,
  LOADING_PUBLISH_ARTICLE,
  LOAD_ARTICLES_LOADING,
  OPEN_FORM_ARTICLE,
} from '../redux/actions/actionsType';

export const getArticles = async (dispatch, params = {}) => {
  dispatch({ type: CHANGE_USERS_QUERY, payload: params.query });
  dispatch({ type: LOAD_ARTICLES_LOADING, payload: true });

  const { data } = await post('/articles/list', params);
  if (data.success) {
    dispatch({ type: LOADED_ARTICLES, payload: data.payload });
  }
};

export const publishWebArticle = async (dispatch, id, pagination) => {
  dispatch({ type: LOADING_PUBLISH_ARTICLE, payload: id });

  const { data } = await post(`/articles/publishWeb?id=${id}`);
  dispatch({ type: LOADING_PUBLISH_ARTICLE, payload: false });

  if (data.success) {
    await getArticles(dispatch, pagination);
    return true;
  }

  return false;
};

export const publishEmailArticle = async (dispatch, id, pagination) => {
  dispatch({ type: LOADING_PUBLISH_ARTICLE, payload: id });

  const { data } = await post(`/articles/publishEmail?id=${id}`);
  dispatch({ type: LOADING_PUBLISH_ARTICLE, payload: false });

  if (data.success) {
    await getArticles(dispatch, pagination);
    return true;
  }

  return false;
};

export const uploadImage = async (obj) => {
  const { data } = await post('/articles/image', obj);
  if (data.success) {
    return data.payload;
  }
  return null;
};

export const getArticleByUrl = async (url) => {
  const { data } = await get(`/articles-public?url=${url}`, false);

  return data.payload;
};

export const getPortalArticles = async (category, page = 1) => {
  const pageParam = page ? `&page=${page}` : '';
  const categoryParam = category ? `&category=${category}` : '';

  const { data } = await get(
    `/articles-public?list=1${pageParam}${categoryParam}`,
    false,
  );

  return data.payload;
};

export const getMonitoringArticles = async () => {
  try {
    const { data } = await get('/articles-public?monitoringArticles=1', false);
    return data.payload;
  } catch (error) {
    throw new Error('Failed to fetch monitoring articles');
  }
};

export const getPreventionArticles = async () => {
  const { data } = await get('/articles-public?preventionArticles=1', false);

  return data.payload;
};

export const getRecentArticles = async () => {
  try {
    const { data } = await get('/articles-public?recentArticles=1', false);
    return data.payload;
  } catch (error) {
    throw new Error('Failed to fetch recent articles');
  }
};

export const getRisksArticles = async () => {
  try {
    const { data } = await get('/articles-public?risksArticles=1', false);
    return data.payload;
  } catch (error) {
    throw new Error('Error retrieving risks articles');
  }
};

export const getSearchArticles = async (obj) => {
  const { data } = await post('/articles-public?search=1', obj, false);

  return data.payload;
};

export const rateArticles = async (obj) => {
  const { data } = await post('/articles-public?rate=1', obj, false);
  if (data.success === false) {
    return Promise.reject(undefined);
  }
  return data.payload;
};

export const getPopularArticles = async (category) => {
  const { data } = await get(
    `/articles-public?popularArticles=1&category=${category}`,
    false,
  );

  return data.payload;
};

export const createArticlePag = async (dispatch, article, pagination = {}) => {
  dispatch({ type: LOADING_CREATE_ARTICLE, payload: true });

  const { data } = await post('/articles/createArticle', article);

  if (data.success) {
    dispatch({ type: OPEN_FORM_ARTICLE, payload: false });
    dispatch({ type: LOADING_CREATE_ARTICLE, payload: false });
    await getArticles(dispatch, pagination);

    return true;
  }

  dispatch({ type: LOADING_CREATE_ARTICLE, payload: false });

  return false;
};

export const updateArticlePag = async (dispatch, article, pagination) => {
  dispatch({ type: LOADING_CREATE_ARTICLE, payload: true });
  const { data } = await put('/articles/updateArticle', article);

  if (data.success) {
    dispatch({ type: OPEN_FORM_ARTICLE, payload: false });
    dispatch({ type: LOADING_CREATE_ARTICLE, payload: false });
    await getArticles(dispatch, pagination);

    return true;
  }

  dispatch({ type: LOADING_CREATE_ARTICLE, payload: false });

  return false;
};

export const removeArticlePag = async (dispatch, id, pagination) => {
  const { data } = await post(`/articles/removePagination?id=${id}`);
  if (data.success) {
    await getArticles(dispatch, pagination);
    return true;
  }
  return false;
};
