import React, { useCallback, useState, useEffect } from 'react';
import { Slider, notification } from 'antd';
import Button from '../../../../common/Button';

import {
  updateFiltersAlerts,
  getForecastConfigById,
} from '../../../../services/locations';
import { pushDigitalData } from '../../../../lib/utils';
import { TRACK } from '../../../../lib/consts';

const FlashAlerts = ({ data, idLocation, onUpdatedData }) => {
  const [flashState, setFlashState] = useState({
    id: idLocation,
    state: 0,
    rings: [0, 0, 0],
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getForecastConfigById(idLocation).then((res) => {
      if (res) {
        const flashData = res?.find((el) => el.type === 'flash');
        if (flashData) {
          let rings = [0, 0, 0];
          if (flashData.value !== null && flashData.value !== '') {
            const valueObject = JSON.parse(flashData.value);
            rings = valueObject.rings;
          }
          setFlashState({
            ...flashData,
            rings: rings,
          });
        } else {
          setFlashState({
            id: idLocation,
            state: 0,
            rings: [0, 0, 0],
          });
        }
      } else {
        setFlashState({
          id: idLocation,
          state: 0,
          rings: [0, 0, 0],
        });
      }
    });
  }, [idLocation]);

  useEffect(() => {
    if (data) {
      if (data.type === 'flash') {
        let rings = [0, 0, 0];
        if (data.value !== null && data.value !== '') {
          const valueObject = JSON.parse(data.value);
          rings = valueObject.rings;
        }
        setFlashState({
          ...data,
          rings: rings,
        });
      } else {
        setFlashState({
          id: idLocation,
          state: 0,
          rings: [0, 0, 0],
        });
      }
    }
  }, [data, idLocation]);

  const handleSubmit = useCallback(() => {
    if (process.env.REACT_APP_ANALYTICS_ENABLE !== '0') {
      pushDigitalData(
        {
          action: {
            group: 'Centro Monitoreo',
            category: 'Configuración',
            name: `Alerta - Configurar`,
            label: `Rayos - ${flashState.rings?.join('-')}`,
          },
        },
        TRACK.ACTION,
      );
    }

    setLoading(true);
    const data = {
      id: idLocation,
      flash: {
        state: 1,
        value: JSON.stringify({ rings: flashState.rings }),
      },
    };
    updateFiltersAlerts(data)
      .then((res) => {
        if (res === true) {
          notification.success({
            message: 'Alerta de rayos configurada',
            description: 'Se ha configurado la alerta de rayos',
          });
        }
      })
      .finally(() => {
        getForecastConfigById(idLocation).then((res) => {
          if (res) {
            const flashData = res?.find((el) => el.type === 'flash');
            if (!flashData.rings) {
              flashData.rings = flashState.rings;
            }
            setFlashState(flashData);
            onUpdatedData('flash', flashData);
          }
          setLoading(false);
        });
      });
  }, [flashState]);

  const onDeleteAlert = useCallback(() => {
    setLoading(true);

    if (process.env.REACT_APP_ANALYTICS_ENABLE !== '0') {
      pushDigitalData(
        {
          action: {
            group: 'Centro Monitoreo',
            category: 'Configuración',
            name: `Alerta - Eliminar`,
            label: `Rayos - ${flashState.rings?.join('-')}`,
          },
        },
        TRACK.ACTION,
      );
    }

    const data = {
      id: idLocation,
      flash: {
        state: 0,
        value: null,
      },
    };
    updateFiltersAlerts(data)
      .then((res) => {
        if (res === true) {
          notification.success({
            message: 'Alerta de rayos eliminada',
            description: 'Se ha eliminado la alerta de rayos',
          });
        }
      })
      .finally(() => {
        getForecastConfigById(idLocation).then((res) => {
          if (res) {
            const flashData = res?.find((el) => el.type === 'flash');
            setFlashState(flashData);
            onUpdatedData('flash', flashData);
          }
          setLoading(false);
        });
      });
  }, [flashState]);

  const handleChangeSlider = (index) => {
    return (newValue) => {
      let newFlashState = { ...flashState };

      if (!Array.isArray(newFlashState.rings)) {
        newFlashState.rings = [0, 0, 0];
      }

      switch (index) {
        case 2:
          if (newValue < newFlashState.rings[1]) {
            return;
          }
          break;
        case 1:
          if (
            newValue > newFlashState.rings[2] ||
            newValue < newFlashState.rings[0]
          ) {
            return;
          }
          break;
        case 0:
          if (newValue > newFlashState.rings[1]) {
            return;
          }
          break;
        default:
          break;
      }

      newFlashState.rings[index] = newValue;
      setFlashState(newFlashState);
    };
  };
  return (
    <div className='mt-3 pt-2 drawer-map-alert__body'>
      <h5>
        1ra alerta al ingresar al radio de&nbsp;
        {flashState?.rings?.[2]} km
      </h5>
      <div className='pb-3' style={{ paddingTop: 54 }}>
        <Slider
          marks={{ 0: '0 km', 10: '10 km', 20: '20 km', 30: '30 km' }}
          step={1}
          min={0}
          max={30}
          value={flashState?.rings?.[2] ?? 0}
          tooltip={{
            open: true,
            getPopupContainer: (e) => e,
          }}
          onChange={handleChangeSlider(2)}
        />
      </div>
      <h5>
        2da alerta al ingresar al radio de&nbsp;
        {flashState?.rings?.[1]} km
      </h5>
      <div className='pb-3' style={{ paddingTop: 54 }}>
        <Slider
          marks={{ 0: '0 km', 10: '10 km', 20: '20 km', 30: '30 km' }}
          step={1}
          min={0}
          max={30}
          value={flashState?.rings?.[1] ?? 0}
          tooltip={{
            open: true,
            getPopupContainer: (e) => e,
          }}
          onChange={handleChangeSlider(1)}
        />
      </div>
      <h5>
        3ra alerta al ingresar al radio de&nbsp;
        {flashState?.rings?.[0]} km
      </h5>
      <div className='pb-3' style={{ paddingTop: 54 }}>
        <Slider
          marks={{ 0: '0 km', 10: '10 km', 20: '20 km', 30: '30 km' }}
          step={1}
          min={0}
          max={30}
          value={flashState?.rings?.[0] ?? 0}
          tooltip={{
            open: true,
            getPopupContainer: (e) => e,
          }}
          onChange={handleChangeSlider(0)}
        />
      </div>
      <div className='mt-3 flex flex-col items-center'>
        <Button
          variant='fill'
          style={{
            width: '50%',
          }}
          disabled={loading}
          onClick={handleSubmit}
          loading={loading}
          text={'Configurar'}
        />
        <Button
          variant='text-primary'
          className='mt-2'
          disabled={flashState.state === 0 || loading}
          onClick={onDeleteAlert}
          loading={loading}
          text={'Eliminar alerta'}
        />
      </div>
    </div>
  );
};

export default FlashAlerts;
