export const getDepartments = (zones) =>
  zones.map((zone) => ({ ...zone, value: zone.label }));

export const getProvinces = (departments) => {
  const allProvinces = departments.reduce(
    (prevDepartment, currentDepartment) => {
      const currentProvinces = currentDepartment.children.map((province) => ({
        ...province,
        value: province.label,
        text: province.label,
      }));

      return [...prevDepartment, ...currentProvinces];
    },
    [],
  );

  return allProvinces.sort((a, b) => a.label.localeCompare(b.label));
};

export const getDistricts = (provinces) => {
  const allDistricts = provinces.reduce((prevProvince, currentProvince) => {
    const currentProvinces = currentProvince.children.map((district) => ({
      ...district,
      value: district.label,
      text: district.label,
    }));

    return [...prevProvince, ...currentProvinces];
  }, []);

  const districtsUniques = [];
  const districtsRepeated = new Set();

  for (const obj of allDistricts) {
    if (!districtsRepeated.has(obj.label)) {
      districtsRepeated.add(obj.label);
      districtsUniques.push(obj);
    }
  }

  return districtsUniques.sort((a, b) => a.label.localeCompare(b.label));
};
