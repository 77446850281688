import { useEffect, useState } from 'react';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { RideGlSeeDetailSolid } from '@rimac-seguros/ride-system-components';

const Detail = ({ course, selected, itemId }) => {
  const [report, setReport] = useState(null);

  const handleDownloadReport = () => {
    window.open(
      `${process.env.REACT_APP_WEB_PATH_S3_NEW}${report.filename_uploaded}`,
      '_blank',
    );
  };

  useEffect(() => {
    if (selected && course) {
      const info = selected?.request_service_item_results;
      if (info) {
        const data = info?.filter((item) => item.course === course.course_key);

        data && data.length > 0 && setReport(data[0]);
      }
    }
  }, [selected, course, itemId]);

  return (
    <>
      <div className='card-house-detail'>
        <div className='header'>
          <RideGlSeeDetailSolid size='2x-large' />
          <p className='title ml-2'>Detalle del curso</p>
        </div>

        <div className='detail'>
          <div className='detail-item'>
            <p className='label'>Nombre:</p>
            <p className='value'>{course?.course}</p>
          </div>

          <div className='detail-item'>
            <p className='label'>Contacto:</p>
            <p className='value'>{selected.contact}</p>
          </div>

          <div className='detail-item'>
            <p className='label'>Fecha de capacitación:</p>
            <p className='value'>{course?.date || 'sin información'}</p>
          </div>

          <div className='detail-item'>
            <p className='label'>Duración en horas:</p>
            <p className='value'>{course?.hours}</p>
          </div>
        </div>
      </div>

      {report && selected.state === 3 && (
        <div className='service-detail__body__action'>
          <div className='service-detail__body__action--info'>
            <div className='service-detail__body__action--info__text'>
              <h3>Tenemos un reporte para este curso</h3>
              <p>Listado en archivo pdf</p>
            </div>
          </div>

          <Button
            htmlType='button'
            className='service-detail__body__action--button'
            icon={<DownloadOutlined />}
            onClick={handleDownloadReport}
          >
            Descargar
          </Button>
        </div>
      )}
    </>
  );
};

export default Detail;
