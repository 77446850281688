import moment from 'moment';

export const getServiceDate = (item) => {
  const technicalReports = item.request_service_item_results;
  if (technicalReports && technicalReports.length > 0) {
    const technicalReport = technicalReports[technicalReports.length - 1];

    return moment.unix(technicalReport.date);
  }

  const date = item?.requestServiceItemsData?.find(
    (rsId) => rsId.key === 'event_date',
  )?.value;

  return date ? moment.unix(date) : null;
};
