// eslint-disable-next-line no-unused-vars
import React, { useEffect, useRef } from 'react';
import { TileLayer, useMap } from 'react-leaflet';
import { connect } from 'react-redux';

const parse_georaster = require('georaster');
const GeoRasterLayer = require('georaster-layer-for-leaflet');

const Index = ({ activeSubLayer, activeSubLayerFromRedux, opacity }) => {
  const currentSubLayer = activeSubLayer ?? activeSubLayerFromRedux;
  const map = useMap();
  const layer = useRef(null);

  useEffect(() => {
    fetch(currentSubLayer.path)
      .then((response) => response.arrayBuffer())
      .then((arrayBuffer) => {
        parse_georaster(arrayBuffer).then((georaster) => {
          layer.current = new GeoRasterLayer({
            georaster: georaster,
            opacity: 1,
            pixelValuesToColorFn: currentSubLayer.pixelValuesToColorFn,
            resolution: 100, // optional parameter for adjusting display resolution
          });
          layer.current.addTo(map);
          layer.current.bringToFront();
        });
      });

    return () => {
      if (layer.current) layer.current.removeFrom(map);
    };
  }, []);

  return (
    <TileLayer
      attribution='Google Maps'
      url='https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}'
      opacity={opacity}
    />
  );
};

const mapDispatchToProps = () => ({});

const mapStateToProps = (state) => ({
  activeSubLayerFromRedux: state.maps.activeSubLayer,
});

export default connect(mapStateToProps, mapDispatchToProps)(Index);
