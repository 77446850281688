import React from 'react';
import { useSelector } from 'react-redux';
import { LAYERS } from './utils';

const LegendBottom = () => {
  const { activeLayer, activeSubLayer, drawerVisible, siderVisible, selected } =
    useSelector((state) => ({
      activeLayer: state.maps.activeLayer,
      activeSubLayer: state.maps.activeSubLayer,
      drawerVisible: state.controlMap.drawerVisible,
      siderVisible: state.controlMap.siderVisible,
      selected: state.controlMap.selected,
    }));

  const currentSubLayer = activeSubLayer ? activeSubLayer.id : null;
  const isOpened = useSelector((state) => state.sideMenu.isOpened);

  return LAYERS[currentSubLayer] ? (
    <div
      id={`${currentSubLayer}_legend`}
      className={`legend_bottom  ${
        selected === '7' ? 'legend_bottom_forescasts ' : ''
      } ${drawerVisible ? 'legend_bottom_drawer ' : ''} ${
        !siderVisible ? 'legend_bottom_sider ' : ''
      } ${
        currentSubLayer === 'satellite' &&
        currentSubLayer !== 'INFRA' &&
        'notBorder'
      } ${isOpened ? 'legend_bottom_opened' : ''}`}
    >
      <>
        <div
          className='unit_measurement'
          style={
            activeLayer === 'waves'
              ? {
                  background:
                    'linear-gradient(90deg, rgba(82, 157, 183, 0.67) 0%, #529DB7 104.76%)',
                }
              : {}
          }
        >
          {LAYERS[currentSubLayer]?.unitMeasurement}
        </div>
        <div className='legend_content'>
          {LAYERS[currentSubLayer].values.map((val) => (
            <div
              key={val[0]}
              className='box_values'
              style={{ background: val[1], color: val[2] }}
            >
              {val[0]}
            </div>
          ))}
        </div>
        {LAYERS[currentSubLayer].info && (
          <p className='child_leyend_info'>{LAYERS[currentSubLayer].info}</p>
        )}
      </>
    </div>
  ) : null;
};

export default LegendBottom;
