import { get, post, put } from '../lib/ajax';
import {
  LOADED_BUSINESS_SEGMENT,
  LOADING_CREATE_BUSINESS_SEGMENT,
  LOAD_BUSINESS_SEGMENT_LOADING,
  OPEN_FORM_BUSINESS_SEGMENT,
} from '../redux/actions/actionsType';

export const getAllBusinessSegment = async (dispatch) => {
  const r = await get('/business_segment/listAll');
  if (r.data.success) {
    dispatch &&
      dispatch({ type: LOADED_BUSINESS_SEGMENT, payload: r.data.payload });

    return r.data.payload.list;
  }

  return null;
};

export const getAllBusinessSegmentNew = async () => {
  const r = await get('/business_segment');
  if (r.data.success) {
    return r.data.payload.list;
  }

  return null;
};

export const getPagBusinessSegment = async (dispatch, pagination) => {
  dispatch({ type: LOAD_BUSINESS_SEGMENT_LOADING, payload: true });

  const r = await post('/business_segment/list', pagination);
  if (r.data.success) {
    dispatch({ type: LOADED_BUSINESS_SEGMENT, payload: r.data.payload });

    return true;
  }

  return false;
};

export const createBusinessSegmentPag = async (typeIndustry, dispatch) => {
  dispatch &&
    dispatch({ type: LOADING_CREATE_BUSINESS_SEGMENT, payload: true });
  const r = await post('/business_segment/createPagination', typeIndustry);

  if (r.data.success) {
    dispatch && dispatch({ type: OPEN_FORM_BUSINESS_SEGMENT, payload: false });
    dispatch &&
      dispatch({ type: LOADING_CREATE_BUSINESS_SEGMENT, payload: false });
    dispatch &&
      (await getPagBusinessSegment(dispatch, {
        page: 1,
        items_per_page: 5,
        query: '',
      }));

    return true;
  }
  dispatch &&
    dispatch({ type: LOADING_CREATE_BUSINESS_SEGMENT, payload: false });

  return false;
};

export const updateBusinessSegmentPag = async (
  typeIndustry,
  dispatch,
  pagination = {},
) => {
  dispatch({ type: LOADING_CREATE_BUSINESS_SEGMENT, payload: true });
  const r = await put('/business_segment/updatePagination', typeIndustry);

  if (r.data.success) {
    dispatch({ type: OPEN_FORM_BUSINESS_SEGMENT, payload: false });
    dispatch({ type: LOADING_CREATE_BUSINESS_SEGMENT, payload: false });
    dispatch && (await getPagBusinessSegment(dispatch, pagination));

    return true;
  }
  dispatch({ type: LOADING_CREATE_BUSINESS_SEGMENT, payload: false });

  return false;
};

export const removeBusinessSegmentPag = async (id, dispatch, pagination) => {
  const r = await post('/business_segment/removePagination?id=' + id);
  if (r.data.success) {
    await getPagBusinessSegment(dispatch, pagination);

    return true;
  }

  return false;
};
