import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { Button, Form, Input, notification, Select } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

import {
  LOADING_CREATE_USER_COMPANY,
  SELECT_ITEM_COMPANY,
} from '../../../../../redux/actions/actionsType';

import InputWithLabel, {
  SelectWithLabel,
} from '../../../../../common/InputWithLabel';
import ModalConfirm from '../../../../../common/ModalConfirm';

import text from '../../../../../config/text_es';
import { filterSelectOptions } from '../../../../../lib/utils';
import { useScreen } from '../../../../../lib/screen';
import {
  createCompany,
  getCompanyByRuc,
  getGiroOfCompany,
  updateCompany,
} from '../../../../../services/company';
import { getAllBrokers } from '../../../../../services/brokers';
import { getAllBusinessSegment } from '../../../../../services/businessSegment';
import { getTypeIndustry } from '../../../../../services/typeIndustry';
import { getZonesWithDistricts } from '../../../../../services/zones';

import HeaderForm from '../../common/HeaderForm';
import PrincipalAdmin from './PrincipalAdmin';

import IconCheck from '../../../../../images/icon_check.svg';

const BusinessForm = ({
  isLoadingCreate,
  company,
  activeLoadingForm,
  pagination,
  listTypeIndustry,
  update,
  create,
  loadZonesWithDistricts,
  loadListTypeIndustry,
  loadBrokers,
  loadBusinessSegment,
  onBack = () => {},
}) => {
  const [form] = Form.useForm();
  const { isBeforeTable } = useScreen();
  const { validateFields, setFieldsValue } = form;
  const [loadingGiro, setLoadingGiro] = useState(false);
  const [enableLoadGiro, setEnableLoadGiro] = useState(false);
  const [brokers, setBrokers] = useState([]);
  const [confirmMobile, setConfirmMobile] = useState('');
  const [businessSegment, setBusinessSegment] = useState([]);

  const handleLoadGiro = useCallback(() => {
    validateFields(async (err, values) => {
      if (!(err && err.ruc)) {
        setLoadingGiro(true);
        const result = await getGiroOfCompany(values.ruc);
        setLoadingGiro(false);
        result && setFieldsValue({ giro: result });
      }
    });
  }, [setFieldsValue, validateFields]);

  const validateRUCField = async (rule, value) => {
    setEnableLoadGiro(false);
    if (!isNaN(value))
      if (value.length > 0 && value.length !== 11) {
        throw new Error('Ruc inválido.');
      } else {
        const exist = await getCompanyByRuc(value);
        if (!company && exist.success)
          throw new Error('RUC ya esta registrado.');

        if (exist.success && exist.company.id !== company.id)
          throw new Error('RUC ya esta registrado.');
      }
    else throw new Error('Ruc inválido.');

    setEnableLoadGiro(true);
  };

  const handleSave = async (e) => {
    activeLoadingForm(true);

    try {
      const values = await form.validateFields();

      if (!values.brokerId) delete values.brokerId;
      if (!values.principalAdminId) delete values.principalAdminId;
      if (values.visible_in_reports === 'Sí') values.visible_in_reports = '1';

      company?.id
        ? update(
            { uuid: company.uuid, ...values },
            { page: pagination.current, limit: pagination.pageSize },
            isBeforeTable,
            setConfirmMobile,
          )
        : create(values, isBeforeTable, setConfirmMobile);

      !isBeforeTable && onBack();
    } catch (err) {
      console.log('Validate Failed:', err);
    }

    activeLoadingForm(false);
  };

  useEffect(() => {
    loadZonesWithDistricts();
    if (Array.isArray(listTypeIndustry) && listTypeIndustry.length === 0)
      loadListTypeIndustry();

    loadBrokers().then((brokers) => setBrokers(brokers));
    loadBusinessSegment().then((businessSegment) =>
      setBusinessSegment(businessSegment),
    );
  }, [
    listTypeIndustry,
    loadBrokers,
    loadBusinessSegment,
    loadListTypeIndustry,
    loadZonesWithDistricts,
  ]);

  return (
    <div className='d_content'>
      <section className='section-form-newsletters section-form-event'>
        <HeaderForm
          title={`${company ? 'Editar' : 'Crear'} empresa`}
          handleReturn={() => onBack(false, null)}
        />

        <div className='form-content form-business'>
          <Form disabled={isLoadingCreate} form={form} onFinish={handleSave}>
            <div className='form-content__double'>
              <div className='left'>
                <Form.Item name='id' initialValue={company ? company.id : ''}>
                  <Input type='hidden' />
                </Form.Item>

                <Form.Item
                  name='ruc'
                  initialValue={company ? company.ruc : ''}
                  rules={[
                    { required: true, message: text.required_message },
                    { validator: validateRUCField },
                  ]}
                  normalize={(value) => value.trim()}
                >
                  <InputWithLabel disabled={isLoadingCreate} label='Ruc' />
                </Form.Item>

                <div className='business-turn'>
                  <Form.Item
                    name='giro'
                    initialValue={company ? company.giro : ''}
                  >
                    <InputWithLabel
                      label={'Giro predominante'}
                      optional={true}
                      disabled={isLoadingCreate}
                    />
                  </Form.Item>

                  <Button
                    loading={loadingGiro}
                    disabled={!enableLoadGiro}
                    type='link'
                    className='link'
                    onClick={handleLoadGiro}
                  >
                    <UploadOutlined /> Cargar
                  </Button>
                </div>

                <Form.Item
                  name='business_name'
                  initialValue={company ? company.business_name : ''}
                  rules={[{ required: true, message: text.required_message }]}
                >
                  <InputWithLabel
                    label='Nombre o Razón Social'
                    disabled={isLoadingCreate}
                  />
                </Form.Item>

                <Form.Item
                  name='name'
                  initialValue={company ? company.name : ''}
                  rules={[{ required: true, message: text.required_message }]}
                >
                  <InputWithLabel
                    label='Nombre comercial'
                    disabled={isLoadingCreate}
                  />
                </Form.Item>

                <Form.Item
                  name='economic_group'
                  initialValue={company ? company.economic_group : ''}
                  rules={[{ required: false, message: text.required_message }]}
                >
                  <InputWithLabel
                    optional={true}
                    label='Grupo económico'
                    disabled={isLoadingCreate}
                  />
                </Form.Item>

                <Form.Item
                  name='businessSegmentId'
                  initialValue={
                    company && company.businessSegmentId
                      ? company.businessSegmentId.toString()
                      : ''
                  }
                  rules={[{ required: true, message: text.required_message }]}
                >
                  <SelectWithLabel
                    label='Segmento comercial'
                    loading={isLoadingCreate}
                    disabled={isLoadingCreate}
                    showSearch
                    filterOption={filterSelectOptions}
                    allowClear
                  >
                    {businessSegment.map((item) => (
                      <Select.Option
                        value={item.id.toString()}
                        key={`businesss-${item.id.toString()}`}
                      >
                        {item.name}
                      </Select.Option>
                    ))}
                  </SelectWithLabel>
                </Form.Item>

                <Form.Item
                  name='typeIndustryId'
                  initialValue={
                    company && company.typeIndustry
                      ? company.typeIndustry.id.toString()
                      : ''
                  }
                  rules={[{ required: true, message: text.required_message }]}
                >
                  <SelectWithLabel
                    label='Tipo de Industria'
                    loading={isLoadingCreate}
                    disabled={isLoadingCreate}
                    showSearch
                    filterOption={filterSelectOptions}
                    allowClear
                  >
                    {listTypeIndustry.map((type) => (
                      <Select.Option
                        value={type.id.toString()}
                        key={`type-${type.id.toString()}`}
                      >
                        {type.name}
                      </Select.Option>
                    ))}
                  </SelectWithLabel>
                </Form.Item>

                <Form.Item
                  name='brokerId'
                  initialValue={
                    company && company.brokerId
                      ? company.brokerId.toString()
                      : ''
                  }
                  rules={[{ required: false, message: text.required_message }]}
                >
                  <SelectWithLabel
                    loading={isLoadingCreate}
                    disabled={isLoadingCreate}
                    showSearch
                    filterOption={filterSelectOptions}
                    allowClear
                    label='Corredor'
                    optional={true}
                  >
                    {brokers.map((item) => (
                      <Select.Option
                        value={item.id.toString()}
                        key={`broker-${item.id.toString()}`}
                      >
                        {item.name}
                      </Select.Option>
                    ))}
                  </SelectWithLabel>
                </Form.Item>

                <PrincipalAdmin
                  companyId={company?.id}
                  principalAdminId={company?.principalAdminId || ''}
                />

                <Form.Item
                  name='visible_in_reports'
                  initialValue={
                    company ? (company.visible_in_reports ? 'Sí' : 'No') : 'Sí'
                  }
                >
                  <SelectWithLabel
                    label='Visible en los reportes'
                    loading={isLoadingCreate}
                    disabled={isLoadingCreate}
                  >
                    <Select.Option key={1}>Sí</Select.Option>
                    <Select.Option key={0}>No</Select.Option>
                  </SelectWithLabel>
                </Form.Item>

                <Form.Item
                  name='state'
                  initialValue={
                    company ? (company.state === 1 ? 'Activo' : 'Inactivo') : ''
                  }
                  rules={[{ required: true, message: text.required_message }]}
                >
                  <SelectWithLabel
                    label='Estado'
                    loading={isLoadingCreate}
                    disabled={isLoadingCreate}
                  >
                    <Select.Option key={1}>Activo</Select.Option>
                    <Select.Option key={2}>Inactivo</Select.Option>
                  </SelectWithLabel>
                </Form.Item>
              </div>

              <div className='right'>
                <img src='/images/form_img_woman.png' alt='' aria-hidden />
              </div>
            </div>

            <div className='form-actions'>
              <Button
                onClick={handleSave}
                htmlType='submit'
                disabled={isLoadingCreate}
              >
                {company ? 'Guardar cambios' : 'Crear'}
              </Button>
            </div>
          </Form>
        </div>
      </section>
      <ModalConfirm
        visible={Boolean(confirmMobile)}
        onClose={() => setConfirmMobile('')}
        title={confirmMobile || ''}
        image={IconCheck}
        actionsContent={
          <div className='actions-delete-modal mt-2'>
            <Button
              className='button w-max'
              type='primary'
              onClick={() => {
                setConfirmMobile('');
                onBack();
              }}
            >
              Aceptar
            </Button>
          </div>
        }
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  loadZonesWithDistricts: () => getZonesWithDistricts(dispatch),
  activeLoadingForm: (payload) =>
    dispatch({ type: LOADING_CREATE_USER_COMPANY, payload }),
  loadListTypeIndustry: () => getTypeIndustry(dispatch),
  loadBrokers: () => getAllBrokers(),
  loadBusinessSegment: () => getAllBusinessSegment(),
  toggleSelectCompany: (item) =>
    dispatch({ type: SELECT_ITEM_COMPANY, payload: item }),
  create: async (values, isBeforeTable, setConfirmMobile) => {
    const created = await createCompany(values, dispatch, {
      page: 1,
      limit: 5,
    });

    localStorage.removeItem('companies-list');

    if (isBeforeTable && created)
      return setConfirmMobile('¡Listo! Se creó la empresa correctamente');

    created
      ? notification.success({
          description: 'Se creo la empresa correctamente.',
          message: '¡Tipo de industria creada!',
        })
      : notification.warning({
          description: 'Ha ocurrido un error.',
          message: 'Crear empresa',
        });
  },
  update: async (values, pagination, isBeforeTable, setConfirmMobile) => {
    const updated = await updateCompany(values, dispatch, pagination);

    localStorage.removeItem('companies-list');

    if (isBeforeTable && updated)
      return setConfirmMobile(
        '¡Cambios guardados! Se guardaron los cambios correctamente',
      );

    updated
      ? notification.success({
          description: 'Se guardaron los cambios correctamente.',
          message: '¡Cambios guardados!',
        })
      : notification.warning({
          description: 'Ha ocurrido un error.',
          message: 'Actualizar empresa',
        });
  },
});

const mapStateToProps = (state) => ({
  isLoadingCreate: state.companies.isLoadingCreate,
  zones: state.zones.zones,
  listTypeIndustry: state.typeIndustry.list || [],
  pagination: state.companies.pagination,
});

export default connect(mapStateToProps, mapDispatchToProps)(BusinessForm);
