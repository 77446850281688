import {
  RideGlSearchSolid,
  RideIconButton,
} from '@rimac-seguros/ride-system-components';
import { Carousel, Modal } from 'antd';
import { useRef, useState } from 'react';

const processImage = (urlString) => {
  return urlString && urlString.indexOf('https://') !== -1
    ? `${urlString}`
    : urlString
    ? `${process.env.REACT_APP_WEB_PATH_S3_NEW}${urlString}`
    : null;
};

const Carousels = ({ eventImages }) => {
  const [open, setOpen] = useState(false);
  const indexImageRef = useRef(0);
  const imagesSelected = eventImages[indexImageRef.current];

  return (
    <>
      <div className='newsletterCarousel'>
        <RideIconButton
          size='medium'
          variant='outline'
          glyph={<RideGlSearchSolid />}
          onClick={() => setOpen(true)}
          className='newsletterCarousel__close'
        />
        <Carousel
          afterChange={(currentSlide) => (indexImageRef.current = currentSlide)}
          arrows
          draggable
        >
          {eventImages.map((image, idx) => (
            <img
              key={`${processImage(image?.url)}-${idx}`}
              src={processImage(image?.url)}
              alt={image?.name}
            />
          ))}
        </Carousel>
      </div>
      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        destroyOnClose
        footer={null}
        width={'100%'}
        className='newsletterCarouselModal'
      >
        <img
          src={processImage(imagesSelected?.url)}
          alt={imagesSelected?.name}
        />
      </Modal>
    </>
  );
};

export default Carousels;
