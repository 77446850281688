import React, { useCallback, useState } from 'react';
import {
  Input,
  Typography,
  Select,
  Button,
  AutoComplete,
  DatePicker,
  TimePicker,
  Tooltip,
} from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { capitalize } from '../../lib/utils';
import locale from 'antd/es/date-picker/locale/es_ES';

const { Text } = Typography;
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TextArea } = Input;

export const InputWithLabel = ({
  className,
  label,
  value,
  disabled,
  style,
  optional = false,
  ...props
}) => (
  <div
    className={`input-with-label ${className} ${
      disabled ? 'disabled-field' : ''
    }`}
    style={style || {}}
  >
    {value ? <Text className='input-label'>{label}</Text> : null}
    {!value && optional ? (
      <Text className='input-label input-label-optional'>(OPCIONAL)</Text>
    ) : null}
    <Input
      {...(props || {})}
      disabled={disabled}
      value={value}
      placeholder={label}
    />
  </div>
);

const convertText = (textCapitalize, text) => {
  if (!textCapitalize) return text;

  return capitalize(text);
};

export const SelectWithLabel = ({
  className,
  label,
  options,
  value,
  disabled,
  textCapitalize = false,
  optional = false,
  children,
  hoverable = false,
  ...props
}) => (
  <div>
    {value ? (
      <>
        <Text className='input-label'>{label}</Text>
        <br />
      </>
    ) : null}
    {!value && optional ? (
      <Text className='input-label input-label-optional'>(OPCIONAL)</Text>
    ) : null}
    <Select
      {...(props || {})}
      placeholder={label}
      value={value || undefined}
      getPopupContainer={(e) => e}
      disabled={disabled}
    >
      {children
        ? children
        : options[0] && typeof options[0] !== 'object'
        ? options.map((el) => (
            <Option
              key={el}
              title={el}
              disabled={disabled}
              value={el}
              className={className}
            >
              {el}
            </Option>
          ))
        : options && options.length
        ? options.map(
            ({
              value,
              label,
              disabled,
              title,
              name,
              id,
              className,
              typePoliza = null,
            }) => (
              <Option
                key={value || id}
                title={title}
                disabled={disabled}
                value={typePoliza?.id || value || id}
                className={className}
              >
                {hoverable ? (
                  <Tooltip title={label || name}>
                    <span className={'option-capitalize'}>
                      {typeof (label || name) === 'string'
                        ? convertText(textCapitalize, label || name)
                        : label || name}
                    </span>
                  </Tooltip>
                ) : (
                  <span className={'option-capitalize'}>
                    {typeof (label || name) === 'string'
                      ? convertText(textCapitalize, label || name)
                      : label || name}
                  </span>
                )}
              </Option>
            ),
          )
        : null}
    </Select>
  </div>
);

export const InputCounter = ({
  className,
  onChange = () => {},
  value,
  maxHours,
  disabledLess = false,
  ...inputProps
}) => (
  <div
    className={`flex items-center middle-width input-counter ${
      className || ''
    }`}
  >
    <Button
      disabled={disabledLess ? value === 1 : !value}
      onClick={() =>
        onChange({ target: { value: Number(value || 0) - 1 } }, 'less')
      }
      type='link'
    >
      <MinusOutlined />
    </Button>
    <Input
      type='number'
      value={value}
      onChange={onChange}
      max={maxHours}
      min={0}
      {...(inputProps || {})}
    />
    <Button
      disabled={maxHours && value === maxHours}
      onClick={() =>
        onChange({ target: { value: Number(value || 0) + 1 } }, 'add')
      }
      type='link'
    >
      <PlusOutlined />
    </Button>
  </div>
);

export const AutoCompleteWithLabel = ({
  className,
  label,
  value,
  disabled,
  optional,
  ...props
}) => (
  <div className={` ${className} ${disabled ? 'disabled-field' : ''}`}>
    {value ? <Text className='input-label'>{label}</Text> : null}
    {!value && optional ? (
      <Text className='input-label input-label-optional'>(OPCIONAL)</Text>
    ) : null}
    <AutoComplete
      {...(props || {})}
      className={value ? 'input-absolute' : ''}
      disabled={disabled}
      value={value || undefined}
      placeholder={label}
    />
  </div>
);

export const RangePickerWithLabel = ({
  className,
  label,
  value,
  disabled,
  hiddenRange,
  isHourPicker,
  ...props
}) => (
  <div
    className={`range-picker-with-label ${className} ${
      disabled ? 'disabled-field' : ''
    }`}
  >
    {value ? <Text className='input-label'>{label}</Text> : null}
    {isHourPicker ? (
      <TimePicker
        {...(props || {})}
        placeholder={label}
        disabled={disabled}
        format='HH:mm'
        locale={locale}
      />
    ) : !hiddenRange ? (
      <RangePicker
        {...(props || {})}
        disabled={disabled}
        locale={locale}
        placeholder={label}
      />
    ) : (
      <DatePicker
        {...(props || {})}
        locale={locale}
        disabled={disabled}
        placeholder={label}
      />
    )}
  </div>
);

export const TextAreaInput = ({
  onChange = () => {},
  value,
  name,
  maxLength = 500,
  hasCounter = true,
  ...props
}) => {
  const [textValue, setTextValue] = useState(value);

  const _handleChangeArea = useCallback(
    ({ target: { value } }) => {
      if (hasCounter) {
        if (value?.length <= maxLength) {
          onChange({ target: { value, name } });
          setTextValue(value);
        } else {
          onChange({ target: { value: value.slice(0, maxLength), name } });
          setTextValue(value.slice(0, maxLength));
        }
      } else {
        onChange({ target: { value, name } });
        setTextValue(value);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [maxLength, onChange, hasCounter],
  );

  return (
    <div className='text-area-container'>
      <TextArea
        className='text-area-custom'
        onChange={_handleChangeArea}
        value={textValue}
        {...(props || {})}
      />
      {hasCounter && (
        <Text className='sub-text'>
          {textValue?.length ?? 0}/{maxLength}
        </Text>
      )}
    </div>
  );
};

export const TextAreaWithLabel = ({
  onChange = () => {},
  className,
  label,
  value,
  disabled,
  name,
  maxLength = 500,
  hasCounter = true,
  ...props
}) => {
  const [textValue, setTextValue] = useState(value);

  const _handleChangeArea = useCallback(
    ({ target: { value } }) => {
      if (hasCounter) {
        if (value?.length <= maxLength) {
          onChange({ target: { value, name } });
          setTextValue(value);
        } else {
          onChange({ target: { value: value.slice(0, maxLength), name } });
          setTextValue(value.slice(0, maxLength));
        }
      } else {
        onChange({ target: { value, name } });
        setTextValue(value);
      }
    },
    [maxLength, onChange, hasCounter],
  );

  return (
    <div
      className={`text-area-with-label ${className} ${
        disabled ? 'disabled-field' : ''
      }`}
    >
      {value ? <Text className='input-label'>{label}</Text> : null}
      <TextArea
        {...(props || {})}
        className={`${
          value ? 'text-area-custom-with-label' : 'text-area-custom'
        }`}
        onChange={_handleChangeArea}
        disabled={disabled}
        value={textValue}
        placeholder={label}
      />
      {hasCounter && (
        <Text className='sub-text'>
          {textValue?.length ?? 0}/{maxLength}
        </Text>
      )}
    </div>
  );
};

export default InputWithLabel;
