import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { Button, Skeleton } from 'antd';

import {
  getCompanyByRuc,
  reportCompanyPolizasTracking,
  updatePoliza,
} from '../../../../../services/company';
import { getLevelCompany } from '../../../../../services/levelCompany';
import { getTypePoliza } from '../../../../../services/typePolicy';
import { getOnePolizaByCompanyId } from '../../../../../services/polizas';

import IconFinish from '../../../../../images/icon_finish.svg';

import Table from '../../../../../common/Table';
import TableMessage from '../../../../../common/Table/TableMessage';
import ModalConfirm from '../../../../../common/ModalConfirm';
import openNotification from '../../../../../common/Notification';
import ModalForm from '../BusinessList/Poliza/ModalForm';
import FilterSection from '../../../ui/PreventionService/components/FilterSection';
import GenericCard from '../BusinessList/Poliza/GenericCard';
import RenderFilterAndInfo from '../RenderFilterAndInfo';

import { useScreen } from '../../../../../lib/screen';
import { getFiltersOptions } from '../../../../../lib/utils';

import {
  CardSkeleton,
  iconLeft,
  iconRight,
} from '../../../ui/PreventionService/Reports/utils';
import { filtersCommon, finishFilters } from '../utils';

const PolizaByDefeat = () => {
  const dispatch = useDispatch();
  const { list, isLoadingPolizas, listTypePoliza, listLevelCompany } =
    useSelector((state) => ({
      list: state.poliza.list,
      isLoadingPolizas: state.poliza.isLoading,
      listTypePoliza: state.typePoliza.list || [],
      listLevelCompany: state.levelCompany.list,
    }));
  const { isBeforeTable, isMobileScreen } = useScreen();
  const [listFiltered, setListFiltered] = useState(() => list);
  const [listMobile, setListMobile] = useState(() => list);
  const [polizaSelected, setPolizaSelected] = useState(null);
  const [formOpened, setFormOpened] = useState(false);
  const [pagination, setPagination] = useState(() => ({
    pageSize: 5,
    total: 0,
    current: 1,
  }));
  const [confirmMobile, setConfirmMobile] = useState('');
  const [filterMobile, setFilterMobile] = useState(false);
  const [filterDate, setFilterDate] = useState({ start: null, end: null });
  const [filtersOptions, setFiltersOptions] = useState({});
  const [isLoadingForm, setIsLoadingForm] = useState(false);
  const [loadCompany, setLoadCompany] = useState(false);
  const [currentCompany, setCurrentCompany] = useState(null);

  const loadCompanyPolizasTracking = useCallback(
    async (type) => {
      const trackingList = await reportCompanyPolizasTracking(dispatch, type);
      if (!trackingList) {
        openNotification('error', 'Error al cargar las pólizas');
      }
    },
    [dispatch],
  );

  const loadTypePoliza = useCallback(() => {
    getTypePoliza(dispatch);
  }, [dispatch]);

  const loadLevelCompany = useCallback(() => {
    getLevelCompany(dispatch);
  }, [dispatch]);

  const handleSearch = useCallback(
    (e) => {
      const { value } = e.target;
      const listFiltered = list.filter((item) => {
        const { ruc, business_name, typePoliza, noPoliza } = item;
        const rucLowerCase = ruc.toLowerCase();
        const BusinessNameLowerCase = business_name.toLowerCase();
        const typePolizaLowerCase = typePoliza.toLowerCase();
        const noPolizaLowerCase = noPoliza.toLowerCase();
        const valueLowerCase = value.toLowerCase();

        return (
          rucLowerCase.includes(valueLowerCase) ||
          BusinessNameLowerCase.includes(valueLowerCase) ||
          typePolizaLowerCase.includes(valueLowerCase) ||
          noPolizaLowerCase.includes(valueLowerCase)
        );
      });
      setListFiltered(listFiltered);
    },
    [list],
  );

  const handleChangeDates = useCallback((dates = []) => {
    if (dates && dates.length > 0) {
      const [startDate, endDate] = dates;
      setFilterDate({ start: startDate, end: endDate });
    } else {
      setFilterDate({ start: null, end: null });
    }
  }, []);

  const handleModalFilter = () => setFilterMobile((prev) => !prev);

  const handleClearFilters = () => {
    setFilterDate({ start: null, end: null });
    setListFiltered(list);
    setFilterMobile(false);
    Object.keys(filtersOptions).forEach((name) => {
      setFiltersOptions((prev) => {
        const newcols = prev[name].map((el) => {
          return {
            ...el,
            selected: false,
          };
        });

        return { ...prev, [name]: newcols };
      });
    });
  };

  const handleTableChangeMobile = (current) => {
    if (
      current === 0 ||
      Math.ceil(pagination.total / pagination.pageSize) + 1 === current
    ) {
      return;
    }

    setListMobile(
      listFiltered.slice(
        (current - 1) * pagination.pageSize,
        current * pagination.pageSize,
      ),
    );
    setPagination({ ...pagination, current });
  };

  const handleTableChange = (pagination) => {
    setListMobile(
      listFiltered.slice(
        (pagination.current - 1) * pagination.pageSize,
        pagination.current * pagination.pageSize,
      ),
    );
    setPagination({ ...pagination, current: pagination.current });
  };

  const handleFormAction = (values) => {
    setIsLoadingForm(true);

    if (polizaSelected) {
      updatePoliza(values)
        .then(() => {
          loadCompanyPolizasTracking('toexpire');
          setIsLoadingForm(false);
          setFormOpened(false);

          if (isBeforeTable) {
            return setConfirmMobile(
              '¡Listo! Se renovó la póliza correctamente.',
            );
          }

          openNotification(
            'success',
            '¡Listo! Se renovó la póliza correctamente.',
          );
        })
        .catch(() => {
          setIsLoadingForm(false);
          openNotification('error', 'Error al renovar la póliza');
        });
    } else {
      openNotification('error', 'Error al renovar la póliza');
      setIsLoadingForm(false);
    }
  };

  const handleRenew = useCallback(
    (item) => async () => {
      setLoadCompany(true);

      const result = await getCompanyByRuc(item.ruc);
      setCurrentCompany(result?.company ? result.company : null);

      const poliza = await getOnePolizaByCompanyId(
        result?.company?.id,
        item.id,
      );
      setPolizaSelected(poliza ? poliza : null);

      setFormOpened(true);
      setLoadCompany(false);
    },
    [],
  );

  const columns = [
    {
      title: 'ruc',
      dataIndex: 'ruc',
      key: 'ruc',
      width: '120px',
    },
    {
      title: 'nombre o razón social',
      dataIndex: 'business_name',
      key: 'business_name',
      width: '220px',
      render: (value) => <p className='text-uppercase'>{value || ''}</p>,
    },
    {
      title: 'tipo de poliza',
      dataIndex: 'typePoliza',
      key: 'typePoliza',
      width: '120px',
    },
    {
      title: 'no. poliza',
      dataIndex: 'noPoliza',
      key: 'noPoliza',
      width: '120px',
    },
    {
      title: 'nivel de empresa',
      dataIndex: 'segment',
      key: 'segment',
      width: '145px',
      render: (value) => <p className='text-uppercase'>{value || ''}</p>,
    },
    {
      title: 'gestor de cuenta',
      dataIndex: 'gestor',
      key: 'gestor',
      width: '170px',
    },
    {
      title: 'Fecha de inicio',
      dataIndex: 'startDate',
      key: 'startDate',
      sorter: (a, b) =>
        moment(a.startDate, 'DD/MM/YYYY') - moment(b.startDate, 'DD/MM/YYYY'),
      width: '145px',
    },
    {
      title: 'fecha de fin',
      dataIndex: 'endDate',
      key: 'endDate',
      sorter: (a, b) =>
        moment(a.endDate, 'DD/MM/YYYY') - moment(b.endDate, 'DD/MM/YYYY'),
      width: '130px',
    },
    {
      title: 'estado',
      dataIndex: 'validate',
      key: 'validate',
      width: '160px',
      render: (validate) => (
        <div className='flex flex-row flex-justify-justify-between'>
          <span
            style={{ background: '#FCDA6A', height: 20 }}
            className={'withpoint'}
          >
            <div
              style={{ background: '#FC9700' }}
              className={'withpoint-circle'}
            ></div>
            <span
              style={{
                fontFamily: 'BR Sonoma',
                fontWeight: 900,
                fontSize: 11,
                lineHeight: 16,
              }}
            >
              {validate || ''}
            </span>
          </span>
        </div>
      ),
    },
    {
      title: 'prima (US$)',
      dataIndex: 'prima',
      key: 'prima',
      width: '120px',
    },
    {
      dataIndex: 'actions',
      title: 'Acciones',
      fixed: 'right',
      key: 'actions',
      width: 80,
      render: (__, item) =>
        listFiltered.length > 0 ? (
          <Button
            loading={loadCompany}
            disabled={loadCompany}
            style={{ textAlign: 'start', border: 'none', borderRadius: 0 }}
            type='link'
            className='link'
            onClick={handleRenew(item)}
          >
            Renovar póliza
          </Button>
        ) : null,
    },
  ];

  const handleUpdateFilters = useCallback((name, value) => {
    setFiltersOptions((prev) => {
      const newcols = prev[name].map((el) => {
        return el?.value === value
          ? {
              ...(el ?? {}),
              selected: !el.selected,
            }
          : el;
      });

      return { ...prev, [name]: newcols };
    });
  }, []);

  const handleClearOptions = useCallback((name) => {
    setFiltersOptions((prev) => {
      const newcols = prev[name].map((el) => ({ ...el, selected: false }));

      return { ...prev, [name]: newcols };
    });
  }, []);

  useEffect(() => {
    if (list.length) {
      setPagination((prev) => ({ ...prev, total: list.length }));
      const filtersOpts = getFiltersOptions(list, filtersCommon);
      setFiltersOptions(filtersOpts);
    }
    setListFiltered(list);
  }, [list]);

  useEffect(() => {
    let filtersOpts = {};
    let filter = false;

    if (filterDate.start && filterDate.end) {
      filtersOpts = { ...filterDate };
      filter = true;
    }

    Object.keys(filtersOptions).forEach((key) => {
      const optionSelected = filtersOptions[key].filter(
        (opt) => opt?.selected === true,
      );
      if (optionSelected?.length > 0) {
        filtersOpts[key] = optionSelected.map((el) => el.value);
        filter = true;
      }
    });

    if (filter) {
      const responseFilter = finishFilters(list, filtersOpts);
      setListFiltered(responseFilter);
      setListMobile(responseFilter.slice(0, 5));
      setPagination((prev) => ({
        ...prev,
        total: responseFilter.length,
        current: 1,
      }));
    } else {
      setListFiltered(list);
      setListMobile(list.slice(0, 5));
      setPagination((prev) => ({ ...prev, total: list.length, current: 1 }));
    }
  }, [filtersOptions, filterDate]);

  useEffect(() => {
    loadCompanyPolizasTracking('toexpire');
    loadTypePoliza();
    loadLevelCompany();
  }, [loadCompanyPolizasTracking, loadLevelCompany, loadTypePoliza]);

  return (
    <>
      <FilterSection
        complete={true}
        text={`${
          isBeforeTable
            ? 'Filtra tu búsqueda'
            : 'Filtrar lista de pólizas por vencer'
        }`}
        handleFilter={handleSearch}
      />

      <section className='section-form-newsletters section-form-event'>
        {!isMobileScreen ? (
          !isLoadingPolizas && list.length === 0 ? (
            <TableMessage complete={true} message='No se encontrarón polizas' />
          ) : (
            <section className='show-data'>
              {isLoadingPolizas ? (
                <Skeleton active title={false} paragraph={{ rows: 1 }} />
              ) : (
                <RenderFilterAndInfo
                  onChangeDates={handleChangeDates}
                  onUpdateFilters={handleUpdateFilters}
                  onClearOptions={handleClearOptions}
                  onClearFilters={handleClearFilters}
                  onModalFilter={handleModalFilter}
                  filterMobile={filterMobile}
                  filterDate={filterDate}
                  filtersOptions={filtersOptions}
                  listFiltered={listFiltered}
                />
              )}

              <Table
                dataSource={listFiltered}
                columns={columns}
                rowKey={(record) => record.id}
                loading={isLoadingPolizas}
                locale={{ emptyText: 'No se encontraron polizas.' }}
                pagination={pagination}
                onChange={handleTableChange}
              />
            </section>
          )
        ) : (
          <section className='content-mobile action-bottom'>
            {isLoadingPolizas ? (
              <Skeleton active title={false} paragraph={{ rows: 1 }} />
            ) : (
              <RenderFilterAndInfo
                onChangeDates={handleChangeDates}
                onUpdateFilters={handleUpdateFilters}
                onClearOptions={handleClearOptions}
                onClearFilters={handleClearFilters}
                onModalFilter={handleModalFilter}
                filterMobile={filterMobile}
                filterDate={filterDate}
                filtersOptions={filtersOptions}
                listFiltered={listFiltered}
              />
            )}

            {isLoadingPolizas ? (
              [1, 2, 3, 4].map((el) => <CardSkeleton key={el} />)
            ) : listMobile.length === 0 ? (
              <TableMessage
                complete={true}
                message='No se encontrarón  polizas'
              />
            ) : (
              listMobile.map((data) => (
                <GenericCard
                  key={data.id}
                  data={data}
                  onAction={handleRenew(data)}
                />
              ))
            )}

            <div className='d_content__list__pag'>
              <div
                className={'d_content__list__pag__button'}
                onClick={() => handleTableChangeMobile(pagination.current - 1)}
              >
                {iconLeft({
                  color: pagination.current === 1 ? '#A9AFD9' : '#4F4FFF',
                })}
              </div>

              <div className='d_content__list__pag__number color-red-1'>
                {pagination.current}
              </div>
              <div className='d_content__list__pag__sep'>/</div>
              <div className='d_content__list__pag__number color-gray-3'>
                {Math.ceil(pagination.total / pagination.pageSize)}
              </div>

              <div
                className={'d_content__list__pag__button'}
                onClick={() => handleTableChangeMobile(pagination.current + 1)}
              >
                {iconRight({
                  color:
                    pagination.current ===
                    Math.ceil(pagination.total / pagination.pageSize)
                      ? '#A9AFD9'
                      : '#4F4FFF',
                })}
              </div>
            </div>
          </section>
        )}
      </section>

      <ModalForm
        handleClose={() => {
          setFormOpened(false);
          setPolizaSelected(null);
        }}
        handleAction={handleFormAction}
        isLoadingCreate={isLoadingForm}
        visible={formOpened}
        data={polizaSelected}
        company={currentCompany}
        listTypePoliza={listTypePoliza}
        listLevelCompany={listLevelCompany}
        byDefeat={true}
      />

      <ModalConfirm
        visible={Boolean(confirmMobile)}
        onClose={() => setConfirmMobile('')}
        title={confirmMobile || ''}
        image={IconFinish}
        actionsContent={
          <div className='actions-delete-modal mt-2'>
            <Button
              className='button w-max'
              type='primary'
              onClick={() => setConfirmMobile('')}
            >
              Aceptar
            </Button>
          </div>
        }
      />
    </>
  );
};
export default PolizaByDefeat;
