import React from 'react';
import { useSelector } from 'react-redux';
import PopupClickHandler from './PopupClickHandler';
import PopupSearchResult from './PopupSearchResult';

const Popups = () => {
  const position = useSelector((state) => state.common.positionLatLng);

  return (
    <>
      <PopupClickHandler />
      {position && <PopupSearchResult />}
    </>
  );
};

export default Popups;
