import {
  LOAD_SIMULATIONS_LOADING,
  LOADED_SIMULATIONS,
} from '../redux/actions/actionsType';
import { get, post } from '../lib/ajax';

export const getSimulations = async (dispatch, data = {}) => {
  dispatch({ type: LOAD_SIMULATIONS_LOADING, payload: true });
  const r = await post('/simulations/list', data);
  if (r?.data?.success) {
    dispatch({ type: LOADED_SIMULATIONS, payload: r.data.payload });
  }
};

export const removeSimulation = async (dispatch, id) => {
  dispatch({ type: LOAD_SIMULATIONS_LOADING, payload: true });
  const r = await get('/simulations/remove?id=' + id);
  if (r?.data?.success) {
    getSimulations(dispatch);
  }
};
