import { notification } from 'antd';
import { WarningOutlined } from '@ant-design/icons';

const showError = (description) => {
  notification.error({
    description,
    message: 'Error!',
    duration: 5,
  });
  throw new Error(description);
};

const validateLatitude = (latitude, description) => {
  const lat = Number(latitude);
  if (isNaN(lat)) {
    showError(description);
  }

  return lat;
};

const validateLongitude = (longitude, description) => {
  const lng = Number(longitude);
  if (isNaN(lng)) {
    showError(description);
  }

  return lng;
};

const validateName = (name, description) => {
  if (
    !name ||
    !/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\d\s]+$/.test(name) ||
    name.trim() === ''
  ) {
    showError(description);
  }
  return name;
};

const validateEmail = (email, index) => {
  if (!email || email === '') {
    showError(
      `El campo "Correo" es obligatorio para el usuario en la fila ${
        index + 2
      }.`,
    );
  }
  if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
    showError(
      `El campo "Correo" debe ser una dirección de correo electrónico válida para el usuario con correo ${email} en la fila ${
        index + 2
      }.`,
    );
  }
  return email;
};

export const formatLocationRows = (rows, properties) => {
  if (!rows) {
    return [];
  }

  if (!Array.isArray(rows)) {
    throw new Error('Los filas deben estar dentro de un array');
  }

  if (rows.length === 0) {
    return [];
  }

  return rows.map((row, index) => {
    const lat = validateLatitude(
      row['LATITUD (*)'],
      `El campo "Latitud" es obligatorio y debe ser numérico para la ubicación en la fila ${
        index + 2
      }.`,
    );
    const lng = validateLongitude(
      row['LONGITUD (*)'],
      `El campo "Longitud" es obligatorio y debe ser numérico para la ubicación en la fila ${
        index + 2
      }.`,
    );
    const name = validateName(
      row['NOMBRE (*)'],
      `El campo "Nombre" es obligatorio y debe contener solo letras para la ubicación en la fila ${
        index + 2
      }.`,
    );

    let email;
    if (properties?.isAdmin) {
      email = validateEmail(row['CORREO (*)']?.trim(), index);
    }

    if (properties?.isAdmin) {
      return {
        lat,
        lng,
        name,
        username: email,
      };
    } else {
      return {
        lat,
        lng,
        name,
      };
    }
  });
};
