import { useState } from 'react';
import { Checkbox, AutoComplete } from 'antd';

export const IconClose = (props = {}) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={32}
    height={32}
    fill='none'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d={`M19.913 11.38a.5.5 0 0 1 .707.707L16.707 16l3.913 3.913a.5.5 0 0 1-.707.707L16 16.707l-3.913
       3.913a.5.5 0 0 1-.707-.707L15.293 16l-3.913-3.913a.5.5 0 0 1 .707-.707L16 15.293l3.913-3.913Z`}
      fill='#A9AFD9'
    />
  </svg>
);

const MultipleSelect = ({
  loading,
  placeholder = 'Buscar',
  filters = [],
  updateFilterColumns,
  className = '',
  style = {},
}) => {
  const [value, setValue] = useState('');
  const [visible, setVisible] = useState(false);

  const filterOptions = () => {
    const filteredOptions = filters.filter((option) =>
      option.label.toLowerCase().includes(value.toLowerCase()),
    );

    return filteredOptions
      .slice(0, 10)
      .filter((el) => el?.value)
      .map((item) => (
        <AutoComplete.Option key={item.value}>
          <Checkbox
            name={item.value}
            onChange={(ev) => updateFilterColumns(name, ev.target.name)}
            checked={item.selected}
          >
            {item.label}
          </Checkbox>
        </AutoComplete.Option>
      ));
  };

  return (
    <div>
      <AutoComplete
        className={`flex items-center less-input-border select-multiple ${
          loading ? 'disabled-field' : ''
        } ${className}`}
        disabled={loading}
        style={{ minWidth: 230, ...style }}
        open={visible}
        placeholder={placeholder}
        onSearch={(val) => setValue(val)}
        defaultValue={''}
        value={value}
        onDropdownVisibleChange={(visible) => {
          setVisible(visible);
        }}
        onSelect={() => {
          setVisible(true);
        }}
        size='large'
      >
        {filterOptions()}
      </AutoComplete>
    </div>
  );
};

export default MultipleSelect;
