import React, { useState } from 'react';
import { Button, Card } from 'antd';

const CardCumuloMobile = ({ item, filterColumns }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Card style={{ width: '100%' }} className='d_content__card'>
      <div className='d_content__card__body'>
        {(isExpanded ? filterColumns : filterColumns.slice(0, 4)).map(
          ({ key, title }) => (
            <div key={key} className='card-detail__item'>
              <h4>{title}</h4>
              <p>{item[key] || '-'}</p>
            </div>
          ),
        )}
      </div>
      <div className='d_content__card__button'>
        <Button
          className='d_content__card__button__text'
          type='link'
          onClick={() => {
            setIsExpanded((prev) => !prev);
          }}
        >
          {!isExpanded ? 'Ver más' : 'Ver menos'}{' '}
          <i className='fas fa-chevron-right'></i>
        </Button>
      </div>
    </Card>
  );
};

export default CardCumuloMobile;
