import { get } from '../lib/ajax';
import { LOADED_EVENT_TYPE } from '../redux/actions/actionsType';

export const getEventType = async (dispatch) => {
  const r = await get('/event_type/list');
  if (r.data.success) {
    dispatch({
      type: LOADED_EVENT_TYPE,
      payload: r.data.list?.sort((a, b) => a.name.localeCompare(b.name)),
    });
  }
};
