import L from 'leaflet';
import moment from 'moment';
import { Marker, Popup } from 'react-leaflet';

import { iconLastSismo, iconSismo } from '../../utils';
import { standardizeLongitude } from '../../../../lib/utils';

const dateFormat = 'YYYY-MM-DD HH:mm:ss';

const MarkerComponent = ({ point, isLastEarthquake }) => {
  const getIcon = (isLastEarthquake) =>
    L.divIcon({
      html: isLastEarthquake ? iconLastSismo : iconSismo,
      iconSize: [27, 38],
      iconAnchor: [13, 38],
      className: 'leaflet-icon-marker-custom',
    });

  return (
    <Marker
      key={point.uuid ?? point.id}
      position={[point.lat, point.lng]}
      icon={getIcon(isLastEarthquake)}
    >
      <Popup>
        <div className='popup-map-v2'>
          <div className='popup-title'>
            <span>Sismo de magnitud {point.magnitude}</span>
            <div className='popup-title-subtitle'>{point.place}</div>
          </div>
          <div
            className='popup-content popup-map-scrollable'
            style={{ maxHeight: 240 }}
          >
            <div className='popup-detail'>
              <span className='popup-detail-header'>Información</span>
              <span className='popup-detail-content'>{point.source}</span>
            </div>
            <div className='popup-detail'>
              <span className='popup-detail-header'>Fecha del Evento</span>
              <span className='popup-detail-content'>
                {point.time
                  ? moment(point.time, dateFormat).format('DD-MM-YYYY')
                  : '-'}
              </span>
            </div>
            <div className='popup-detail'>
              <span className='popup-detail-header'>Hora local</span>
              <span className='popup-detail-content'>
                {point.time
                  ? moment(point.time, dateFormat).format('HH:mm:ss')
                  : '-'}
              </span>
            </div>
            <div className='popup-detail'>
              <span className='popup-detail-header'>Latitud</span>
              <span className='popup-detail-content'>{point.lat}</span>
            </div>
            <div className='popup-detail'>
              <span className='popup-detail-header'>Longitud</span>
              <span className='popup-detail-content'>
                {standardizeLongitude(point.lng)}
              </span>
            </div>
            <div className='popup-detail'>
              <span className='popup-detail-header'>Profundidad (Km)</span>
              <span className='popup-detail-content'>{point.depth}</span>
            </div>
          </div>
        </div>
      </Popup>
    </Marker>
  );
};

export default MarkerComponent;
