import React, { useEffect, useState } from 'react';
import SkeletonContent from '../../../../../../../../common/Skeleton';
import { reportServicesRanking } from '../../../../../../../../services/dashboard';

const Activities = ({ dataMonitoring, isLoading }) => {
  const [servicesInfo, setServicesInfo] = useState({});

  useEffect(() => {
    reportServicesRanking().then((data) => {
      setServicesInfo(data);
    });
  }, []);

  return (
    <div className='pdf-resumen__activity'>
      <h3>Mi Actividad</h3>

      <div className='activities-list'>
        {!isLoading ? (
          <article className='activity'>
            <div>
              <img src='/images/icons/icon_shield.svg' alt='Boletines' />
              <h3 className='subtitle-text'>Servicios</h3>
            </div>

            <div className='activity-list'>
              <div>
                <h4>Último servicio solicitado</h4>
                <p>{servicesInfo?.lastService?.name}</p>
              </div>

              <div>
                <h4>Servicio más solicitado</h4>
                <p>{servicesInfo?.servicePopular?.name}</p>
              </div>

              <div>
                <h4>Servicios programados</h4>
                <p>{servicesInfo?.servicesCompany?.length ?? ''}</p>
              </div>
            </div>
          </article>
        ) : (
          isLoading && <SkeletonContent type='card' />
        )}

        {!isLoading && dataMonitoring ? (
          <article className='activity'>
            <div>
              <img src='/images/icons/icon_mail.svg' alt='Boletines' />
              <h3 className='subtitle-text'>Alertas y Boletines</h3>
            </div>

            <div className='activity-list'>
              <div>
                <h4>Alertas Recibidas</h4>
                <p>{dataMonitoring?.alerts?.statistics?.total ?? 0}</p>
              </div>

              <div>
                <h4>Boletines Recibidos</h4>
                <p>{dataMonitoring.newsletter?.statistics?.total ?? 0}</p>
              </div>

              <div>
                <h4>Último boletín vigente</h4>
                <p>{dataMonitoring?.newsletterCurrent || 'Ninguno vigente'}</p>
              </div>
            </div>
          </article>
        ) : (
          isLoading && <SkeletonContent type='card' />
        )}

        {!isLoading && dataMonitoring ? (
          <article className='activity'>
            <div>
              <img
                src='/images/icons/icon_protection.svg'
                alt='Interacciones'
              />
              <h3 className='subtitle-text'>Interacciones</h3>
            </div>

            <div className='activity-list'>
              <div>
                <h4>Última vez logueado</h4>
                <p>{dataMonitoring.lastLogin || 'No hay información'}</p>
              </div>

              <div>
                <h4>Veces logueado por mes</h4>
                <p>{dataMonitoring.visitsMonth || 'No hay información'}</p>
              </div>

              <div>
                <h4>Tiempo promedio de uso</h4>
                <p>{dataMonitoring.avgTimeOnPage || 'No hay información'}</p>
              </div>
            </div>
          </article>
        ) : (
          isLoading && <SkeletonContent type='card' />
        )}
      </div>
    </div>
  );
};

export default Activities;
