import React, { memo } from 'react';
import { Button, Form, Input } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import InputFile from '../../../../common/InputFile';

const UploadEvidenceFile = ({
  onUpdateFile = () => {},
  form,
  keyValue,
  value,
}) => {
  const fileUrl = value
    ? `${process.env.REACT_APP_WEB_PATH_S3_NEW}rain_risk/${value}`
    : null;

  return (
    <div className='flex-col'>
      {fileUrl && (
        <Button
          icon={<DownloadOutlined />}
          style={{ marginTop: -12, color: 'red', fontSize: 14 }}
          className='mr-b'
          target='_blank'
          type='link'
          href={fileUrl}
        >
          Descargar evidencia previa
        </Button>
      )}
      <InputFile
        withoutPreview
        multipleFilesText
        title='Archivo subido'
        filesAccepted={[]}
        defaultValue={fileUrl}
        onChangeFile={(file) => {
          onUpdateFile(file.file);
          form.setFieldsValue({ [`${keyValue}-fileObject`]: file.file });
        }}
      />
      <Form.Item
        name={`${keyValue}-fileObject`}
        style={{
          display: 'none',
        }}
      >
        <Input type='hidden' />
      </Form.Item>
      <Form.Item name={`${keyValue}-file`}>
        <Input type='hidden' />
      </Form.Item>
    </div>
  );
};

export default memo(UploadEvidenceFile);
