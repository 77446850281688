import { secureRandom } from '../../../../../lib/utils';

export const addRandSpaceToLatLng = (lat, lng, space = 0.001) => {
  const randSpace = secureRandom() * space * (secureRandom() > 0.5 ? 1 : -1);
  const latitude = lat + randSpace;
  const longitude = lng + randSpace;

  return {
    latitude,
    longitude,
  };
};
