import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Radio, Slider } from 'antd';

import { renderIcon } from '../renderIcon';
import { closePopUp, pushDigitalData, debounce } from '../../../../lib/utils';
import { selectSublayer } from '../../../../components/Map/utils';
import { checkPermission } from '../../../../lib/security';
import {
  MAP_SET_ACTIVE_LAYER,
  MAP_SET_ACTIVE_MULTI_LAYERS,
  MAP_SET_ACTIVE_SUBLAYER,
} from '../../../../redux/actions/actionsType';
import SwitchLayerMode from './SwitchLayerMode';
import { TRACK } from '../../../../lib/consts';

export const filterLayers = (layers, user) => {
  return layers.filter((layer) => {
    if (layer.permission) {
      return checkPermission(user, layer.permission);
    }
    return true;
  });
};

export const findParentLayer = (layers, sublayer) =>
  layers.find((layer) => {
    if (layer.mode) {
      const foundLayer = layer.mode.find((slayer) => slayer.id === sublayer.id);
      return Boolean(foundLayer);
    } else {
      return layer.id === sublayer.id;
    }
  });

const DrawerLayers = ({ layers, isMobile, controlMapDrawerVisible }) => {
  const dispatch = useDispatch();
  const { activeLayer, multiSelectMode, activeMultiLayers, user } = useSelector(
    (state) => ({
      activeLayer: state.maps.activeLayer,
      multiSelectMode: state.maps.multiSelectMode,
      activeMultiLayers: state.maps.activeMultiLayers,
      user: state.auth.user,
    }),
  );

  const setActiveLayer = (layer) =>
    dispatch({ type: MAP_SET_ACTIVE_LAYER, payload: layer });
  const setActiveSubLayer = (layer) =>
    dispatch({ type: MAP_SET_ACTIVE_SUBLAYER, payload: layer });
  const setActiveMultiLayers = (layers) =>
    dispatch({ type: MAP_SET_ACTIVE_MULTI_LAYERS, payload: layers });
  const allLayers = layers.reduce((prevLayer, currentLayer) => {
    let auxLayer = [];
    if (currentLayer.mode) {
      currentLayer.mode.forEach((subLayer) => {
        auxLayer.push({ ...subLayer });
      });
    } else {
      auxLayer.push(currentLayer);
    }

    return prevLayer.concat(auxLayer);
  }, []);

  const idMultiLayers = activeMultiLayers.map(
    (activeMultiLayer) => activeMultiLayer.activeSubLayer?.id,
  );

  const handleSelectMap = (layer) => {
    if (process.env.REACT_APP_ANALYTICS_ENABLE !== '0') {
      pushDigitalData(
        {
          action: {
            group: 'Centro Monitoreo',
            category: 'Fenómenos y eventos',
            name: 'Capas',
            label: layer.name,
          },
        },
        TRACK.ACTION,
      );
    }

    closePopUp();

    if (layer.id !== activeLayer?.id) {
      localStorage.setItem('layer_key', layer.id);
      setActiveLayer(layer);
      setActiveSubLayer(selectSublayer(layer));
    }

    if (isMobile) {
      controlMapDrawerVisible(false);
    }
  };

  const handleMultiSelectMap = (layer) => {
    closePopUp();

    if (!idMultiLayers.includes(layer.id)) {
      setActiveMultiLayers([
        ...activeMultiLayers,
        {
          activeLayer: setActiveLayer(findParentLayer(layers, layer)),
          activeSubLayer: layer,
          opacity: 0,
        },
      ]);

      localStorage.setItem('layer_key', layer.id);
      setActiveLayer(findParentLayer(layers, layer));
      setActiveSubLayer(layer);
    } else {
      const activeLayers = activeMultiLayers.filter(
        (activeMultiLayer) => activeMultiLayer?.activeSubLayer?.id !== layer.id,
      );
      setActiveMultiLayers(activeLayers);

      if (activeLayers?.at(-1)) {
        localStorage.setItem('layer_key', activeLayers.at(-1).activeLayer?.id);
        setActiveLayer(activeLayers.at(-1).activeLayer);
        setActiveSubLayer(activeLayers.at(-1).activeSubLayer);
      }
    }

    if (isMobile) {
      controlMapDrawerVisible(false);
    }
  };

  const handleChangeOpacity = useCallback(
    debounce((value, layer) => {
      const activeLayers = activeMultiLayers.map((activeMultiLayer) => {
        if (activeMultiLayer?.activeSubLayer?.id === layer.id) {
          return {
            ...activeMultiLayer,
            opacity: Math.round((value / 100) * 100) / 100,
          };
        }

        return activeMultiLayer;
      });
      setActiveMultiLayers(activeLayers);
    }, 800),
    [activeMultiLayers],
  );

  return (
    <div className='drawer-map-layers relative'>
      <SwitchLayerMode />
      <div className='drawer-map-layers__list'>
        {!multiSelectMode ? (
          <>
            {filterLayers(layers, user).map((layer) => {
              return (
                <div
                  key={layer.id}
                  className={`drawer-map-layers__list__item ${
                    activeLayer?.id === layer?.id ? 'active' : ''
                  }`}
                  onClick={() => handleSelectMap(layer)}
                >
                  <div className='drawer-map-layers__list__item__name'>
                    {renderIcon(layer.id)}
                    <div className='drawer-map-layers__list__item__name__text'>
                      {layer.name}
                    </div>
                  </div>
                  <div className='drawer-map-layers__list__item__action'>
                    <Radio
                      className='radio-layer'
                      checked={activeLayer?.id === layer.id}
                    />
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <>
            {filterLayers(allLayers, user).map((layer) => {
              const opacityLayer = activeMultiLayers.find(
                (activeMultiLayer) => activeMultiLayer.id === layer.id,
              )?.opacity;

              return (
                <div
                  key={layer.id}
                  className={`drawer-map-layers__list__item flex-wrap ${
                    idMultiLayers.includes(layer.id) ? 'active' : ''
                  }`}
                  onClick={() => handleMultiSelectMap(layer)}
                >
                  <div className='flex justify-between items-center full-width'>
                    <div className='drawer-map-layers__list__item__name'>
                      {renderIcon(layer.id)}

                      <div className='drawer-map-layers__list__item__name__text'>
                        {layer.name}
                      </div>
                    </div>
                    <div className='drawer-map-layers__list__item__action'>
                      <Checkbox
                        className='checkbox-layer'
                        checked={idMultiLayers.includes(layer.id)}
                      />
                    </div>
                  </div>
                  {idMultiLayers.includes(layer.id) && (
                    <div
                      className='full-width'
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Slider
                        defaultValue={opacityLayer ? opacityLayer * 100 : 0}
                        onChange={(value) => handleChangeOpacity(value, layer)}
                      />
                    </div>
                  )}
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default DrawerLayers;
