import React from 'react';
import moment from 'moment';
import { Button, Popover } from 'antd';
import {
  EditOutlined,
  MailOutlined,
  CheckOutlined,
  DeleteOutlined,
} from '@ant-design/icons';
import { RideTag } from '@rimac-seguros/ride-system-components';

import { eventTypes } from '../../../../lib/consts';
import { newsletterStates } from '../utils';

const Card = ({
  data,
  ZonesDetected,
  handleEdit,
  handlePublish,
  handleSend,
  handleRemove,
  loadingSending,
  loadingApprove,
  setLoadingSending,
  setLoadingApprove,
}) => {
  const { text, caseTag } = newsletterStates[data.state];
  const type = eventTypes.find((item) => item.id === data.type);

  return (
    <article className='card-user'>
      <RideTag text={text} case={caseTag} />

      <div>
        <h4>Tipo</h4>
        <p>{`${type && type.name ? type.name : ''}`}</p>
      </div>

      <div>
        <h4>Fecha de evento</h4>
        <p>
          {moment(data.event?.date_event_start).format('DD MMM YYYY - HH:mm') +
            `${
              data.event.date_event_end
                ? ` a ${moment(data.event?.date_event_end).format(
                    'DD MMM YYYY - HH:mm',
                  )}`
                : ''
            }`}
        </p>
      </div>

      <div>
        <h4>Condición detectada</h4>
        <p>{data.detected_condition}</p>
      </div>

      <div>
        <h4>Regiones afectadas</h4>
        <div>
          <Popover
            placement='right'
            className='popover-zones'
            content={<ZonesDetected zones={data.zones} />}
          >
            <span className='popup-region-hover'>Ver regiones</span>
          </Popover>
        </div>
      </div>

      <div>
        <h4>Usuario publicación</h4>
        <p>
          {data.publisher
            ? `${data.publisher.name} ${data.publisher.last_name1} ${data.publisher.last_name2}`
            : ''}
        </p>
      </div>

      <div className='actions'>
        <Button
          icon={<EditOutlined />}
          shape='circle'
          size='small'
          title='Editar'
          onClick={() => handleEdit(data)}
        />

        {data.state === 1 && !data.sent && (
          <Button
            icon={<MailOutlined />}
            shape='circle'
            size='small'
            title='Enviar boletín a clientes'
            loading={loadingSending}
            onClick={() => {
              setLoadingApprove(true);
              handleSend(data);
            }}
          />
        )}

        {data.state === 0 && (
          <Button
            icon={<CheckOutlined />}
            shape='circle'
            size='small'
            title='Publicar'
            loading={loadingApprove}
            onClick={() => {
              setLoadingSending(true);
              handlePublish(data);
            }}
          />
        )}

        <Button
          icon={<DeleteOutlined />}
          shape='circle'
          size='small'
          title='Eliminar'
          onClick={() => handleRemove(data)}
        />
      </div>
    </article>
  );
};

export default Card;
