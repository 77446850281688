import { get, post, put } from '../lib/ajax';
import {
  LOADED_SERVICE,
  LOADING_CREATE_SERVICE,
  OPEN_FORM_SERVICE,
  LOAD_SERVICE_LOADING,
} from '../redux/actions/actionsType';

export const getService = async (dispatch) => {
  const r = await get('/service/list');
  if (r.data.success) {
    dispatch({ type: LOADED_SERVICE, payload: r.data.payload.list });

    return r.data.payload.list;
  }

  return [];
};

export const getCompaniesByService = async (id) => {
  const r = await get('/service/list?id=' + id);
  if (r.data.success) {
    return r.data.payload;
  }
  return [];
};

export const getPreventionServices = async (dispatch) => {
  const r = await get('/service/listFiltersPrevention');
  if (r.data.success) {
    dispatch({ type: LOADED_SERVICE, payload: r.data.payload.list });
    return r.data.payload.list;
  }

  return [];
};

export const getRisksServices = async (dispatch) => {
  const r = await get('/service/listFiltersRisk');
  if (r.data.success) {
    dispatch({
      type: LOAD_SERVICE_LOADING,
      payload: { riskList: r.data.payload.list },
    });

    return r.data.payload.list;
  }

  return [];
};

export const removeService = async (id, dispatch) => {
  const r = await post('/service/remove?id=' + id);
  if (r.data.success) {
    dispatch({ type: LOADED_SERVICE, payload: r.data.payload.list });

    return true;
  }

  return false;
};

export const createService = async (typeIndustry, dispatch) => {
  dispatch?.({ type: LOADING_CREATE_SERVICE, payload: true });
  const r = await post('/service/create', typeIndustry);

  if (r.data.success) {
    dispatch?.({ type: OPEN_FORM_SERVICE, payload: false });
    dispatch({ type: LOADED_SERVICE, payload: r.data.payload.list });
    dispatch?.({ type: LOADING_CREATE_SERVICE, payload: false });

    return true;
  }
  dispatch?.({ type: LOADING_CREATE_SERVICE, payload: false });

  return false;
};

export const updateService = async (typeIndustry, dispatch) => {
  dispatch({ type: LOADING_CREATE_SERVICE, payload: true });
  const r = await put('/service/update', typeIndustry);

  if (r.data.success) {
    dispatch({ type: OPEN_FORM_SERVICE, payload: false });
    dispatch({ type: LOADED_SERVICE, payload: r.data.payload.list });
    dispatch({ type: LOADING_CREATE_SERVICE, payload: false });

    return true;
  }
  dispatch({ type: LOADING_CREATE_SERVICE, payload: false });

  return false;
};
