import React from 'react';

const Clear = (props) => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M14.9719 18.5016L12.3579 21.1156C11.1375 22.336 11.1375 24.3141 12.3579 25.5344L14.686 27.8625C15.9063 29.0829 17.8844 29.0829 19.1047 27.8625L21.7188 25.2485L14.9719 18.5016Z'
        fill='url(#paint0_linear_827_16677)'
      />
      <path
        d='M32.9688 30H30.7812V27.5H32.9688V30ZM19.3656 30H28.75V27.5H22.1187L20.4312 29.1875C20.1094 29.5094 19.75 29.7812 19.3656 30ZM37.5 7.30938V10.8453L28.3453 20H38.75V37.5H1.25V20H10.8344C10.1875 20.725 9.79062 21.5953 9.64062 22.5H3.75V35H36.25V22.5H25.8453L23.7344 24.6109L15.6094 16.4859L29.5953 2.5H33.1312L25.3187 10.3125L29.9078 14.9016L37.5 7.30938ZM25.0547 19.7547L20.4656 15.1656L19.1469 16.4844L23.7359 21.0735L25.0547 19.7547ZM28.1406 16.6687L23.5516 12.0797L22.2328 13.3984L26.8219 17.9875L28.1406 16.6687Z'
        fill='url(#paint1_linear_827_16677)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_827_16677'
          x1='11.7115'
          y1='19.3081'
          x2='20.1783'
          y2='27.7749'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.35' stopColor='#F7052D' />
          <stop offset='0.85' stopColor='#CA5AFA' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_827_16677'
          x1='1.25'
          y1='2.5'
          x2='19.1424'
          y2='45.6335'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.272135' stopColor='#34263B' />
          <stop offset='0.658079' stopColor='#13172C' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Clear;
