import React, { memo, useState } from 'react';
import { Button, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ModalConfirm from '../../../../../common/ModalConfirm';
import { useCompaniesNoManager } from './hooks';
import { addCompanyToUser } from '../../../../../services/users';
import { filterSelectOptions } from '../../../../../lib/utils';

const ModalAddGestorCompany = ({ userId, onLoad }) => {
  const { companies, setQuery, companiesLoading } = useCompaniesNoManager();
  const [companyValue, setCompanyValue] = useState();
  const [visible, setVisible] = useState(false);

  const handleAddToCompany = () => {
    addCompanyToUser({
      company_id: companyValue,
      user_id: userId,
    })
      .then(onLoad)
      .finally(() => {
        setCompanyValue(null);
        setVisible(false);
      });
  };

  return (
    <>
      <Button
        type='link'
        size='small'
        onClick={() => {
          setVisible(true);
        }}
        icon={<PlusOutlined />}
      >
        Añadir Empresa
      </Button>
      <ModalConfirm
        visible={visible}
        onClose={() => {
          setVisible(false);
        }}
        title={'Agregar empresa'}
        content={
          <div className='flex flex-col items-center'>
            <Select
              loading={companiesLoading}
              showSearch
              getPopupContainer={(e) => e}
              style={{ marginBottom: 20, height: 48 }}
              className='select-with-label full-width'
              onChange={(value) => {
                setCompanyValue(value);
              }}
              onSearch={(e) => setQuery(e)}
              filterOption={filterSelectOptions}
              allowClear
            >
              {(companies ?? []).map((c) => (
                <Select.Option key={c.id} value={c.id}>
                  {c.name}
                </Select.Option>
              ))}
            </Select>
            <Button
              disabled={!companyValue}
              type='primary'
              size='large'
              className='full-width'
              onClick={handleAddToCompany}
              icon={<PlusOutlined />}
            >
              Añadir
            </Button>
          </div>
        }
      />
    </>
  );
};

export default memo(ModalAddGestorCompany);
