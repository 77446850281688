import React from 'react';
import Button from '../Button';

const InformCard = ({ title, img, href, tag }) => {
  return (
    <div className='inform-card'>
      <div className='inform-card__text-container'>
        <div className='inform-card__info'>{tag}</div>
        <div className='inform-card__title'>{title}</div>
        <div className='inform-card__button'>
          <Button className='secondary medium' href={href}>
            Leer artículo
          </Button>
        </div>
      </div>
      <div className='inform-card__img-container'>
        <img className='inform-card__img' src={img} alt='' />
      </div>
    </div>
  );
};

export default InformCard;
