import React from 'react';
import { Input } from 'antd';

const { Search } = Input;

const FilterSection = ({
  text,
  complete = false,
  handleFilter = () => {},
  placeholder = false,
}) => {
  return (
    <div className='profile-company__users--header filter-section'>
      <h2>{complete ? text : `Filtrar lista de ${text}`}</h2>

      <Search
        placeholder={`${placeholder ? placeholder : 'Palabras clave'}`}
        onChange={handleFilter}
        allowClear
        style={{
          width: '100%',
          maxWidth: '300px',
        }}
      />
    </div>
  );
};

export default FilterSection;
