import React, { useEffect, useState } from 'react';
import { Modal, Input, Button, Divider } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import FloatLabel from '../../../../../../../common/ui/FloatLabel';
import InputFile from '../../../../../../../common/InputFile';
import { updateOnlyParticipants } from '../../../../../../../services/requestServicesItemsDataParticipants';
import { connect } from 'react-redux';

const { TextArea } = Input;

const UpdateList = ({
  visible,
  onCancel,
  setFinish,
  selected,
  updateListParticipants,
}) => {
  const [comments, setComments] = useState('');
  const [courseKey, setCourseKey] = useState(null);
  const [list, setList] = useState([]);
  const [idServiceItemData, setIdServiceItemData] = useState(null);
  const [updating, setUpdating] = useState(false);

  const handleComments = (e) => setComments(e.target.value);

  const handleUpdate = async () => {
    setUpdating(true);
    const data = {
      course_key: courseKey,
      // hasExam: 0,
      participants: list,
      requestServiceItemDataId: idServiceItemData,
    };
    await updateListParticipants(data);
    onCancel();
    setUpdating(false);
    setFinish(true);
  };

  useEffect(() => {
    if (
      selected?.requestServiceItems?.[0]?.requestServiceItemsData.length > 0
    ) {
      const data = JSON.parse(
        selected.requestServiceItems[0].requestServiceItemsData[0].value,
      );
      setCourseKey(Object.keys(data)[0]);
      setIdServiceItemData(
        selected.requestServiceItems[0].requestServiceItemsData[0].id,
      );
    }
  }, [selected]);

  return (
    <Modal
      title=''
      open={visible}
      onCancel={onCancel}
      footer={false}
      className='modal-update'
      style={{ transformOrigin: 'right top' }}
      maskClosable={true}
    >
      <section className='modal-update__content'>
        <h2>Actualizar lista de participantes</h2>
        <Divider />
        <p className='description'>
          Adjunta la lista de participantes de la capacitación
        </p>

        <InputFile onChangeRows={setList} withoutPreview />

        <Button
          className='download mt-2 mb-3'
          href='/templates/PlantillaCapacitacionParticipantes.xlsx'
          type='link'
        >
          <DownloadOutlined />
          Descargar modelo de lista de asistencia
        </Button>

        <p className='description observation'>
          Observaciones o información adicional
        </p>

        <FloatLabel label='Observaciones' value={comments}>
          <TextArea
            value={comments}
            onChange={handleComments}
            minLength={15}
            maxLength={500}
            rows={3}
            showCount={true}
          />
        </FloatLabel>

        <Button
          type='primary'
          className='add'
          onClick={handleUpdate}
          loading={updating}
          disabled={!list.length}
        >
          Agregar
        </Button>
      </section>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateListParticipants: async (data) =>
    await updateOnlyParticipants(data, dispatch),
});

const mapStateToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UpdateList);
