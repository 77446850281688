export const checkOnePermition = (user, permission) => {
  if (
    user &&
    user.rol &&
    user.rol.permissions &&
    user.rol.permissions.length > 0
  ) {
    const hasPermission = user.rol.permissions.find(
      (el) => el.slug === permission,
    );
    if (hasPermission) {
      return true;
    }
  }

  return false;
};

export const checkPermission = (user, permission) => {
  if (Array.isArray(permission)) {
    for (const perm of permission) {
      if (checkOnePermition(user, perm)) {
        return true;
      }
    }
  } else {
    return checkOnePermition(user, permission);
  }

  return false;
};

export const checkModules = (user, slug) =>
  !!user?.modules && user.modules.some((module) => module?.slug === slug);

export const validateFile = (file, validations = {}) => {
  const { maxSize, allowedExtensions, maxNameLength } = validations;

  const errors = {};

  if (Array.isArray(allowedExtensions) && allowedExtensions.length > 0) {
    const extension = '.' + file.name.split('.').pop().toLowerCase();
    if (!allowedExtensions.includes(extension)) {
      errors.extension = 'File extension not allowed';
    }
  }

  if (maxNameLength && file.name.length > maxNameLength) {
    errors.nameLength = 'File name too long';
  }

  if (maxSize && file.size > maxSize) {
    errors.size = 'File size too large';
  }

  if (Object.keys(errors).length > 0) {
    return { isValid: false, errors };
  }

  return { isValid: true };
};
