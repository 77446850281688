import L from 'leaflet';
import {
  getSubLayerOptions,
  getSubLayerParams,
  getSubLayerRegionsOptions,
  getSubLayerRegionsParams,
  getSubLayerRegionsParamsFallback,
} from './utils';

export const handleSubLayer = (
  activeSubLayer,
  subLayerObj,
  map,
  opacity,
  defaultScene,
) => {
  if (!activeSubLayer?.animate) {
    if (!subLayerObj.current) {
      createSubLayer(activeSubLayer, subLayerObj, map, opacity, defaultScene);
    } else {
      updateSubLayer(activeSubLayer, subLayerObj, opacity, defaultScene);
    }
  }
};

export const createSubLayer = (
  activeSubLayer,
  subLayerObj,
  map,
  opacity,
  defaultScene,
) => {
  subLayerObj.current = L.tileLayer.wms(
    activeSubLayer.url,
    getSubLayerOptions(activeSubLayer, opacity, defaultScene),
  );
  map.addLayer(subLayerObj.current);
};

export const updateSubLayer = (
  activeSubLayer,
  subLayerObj,
  opacity,
  defaultScene,
) => {
  subLayerObj.current.setParams(
    getSubLayerParams(activeSubLayer, defaultScene),
  );
  subLayerObj.current.options.opacity = opacity;
};

export const handleSubLayerRegions = (
  activeSubLayer,
  subLayerRegionsObj,
  map,
) => {
  if (activeSubLayer.regions) {
    if (!subLayerRegionsObj.current) {
      createSubLayerRegions(activeSubLayer, subLayerRegionsObj, map);
    } else {
      updateSubLayerRegions(activeSubLayer, subLayerRegionsObj, map);
    }
  } else {
    removeSubLayerRegions(subLayerRegionsObj, map);
  }
};

export const createSubLayerRegions = (
  activeSubLayer,
  subLayerRegionsObj,
  map,
) => {
  subLayerRegionsObj.current = L.tileLayer.wms(
    `${process.env.REACT_APP_GEOSERVER_MAPS_NEW}`,
    getSubLayerRegionsOptions(activeSubLayer),
  );
  map.addLayer(subLayerRegionsObj.current);
};

export const updateSubLayerRegions = (
  activeSubLayer,
  subLayerRegionsObj,
  map,
) => {
  if (map.getZoom() > 8) {
    subLayerRegionsObj.current.setParams(
      getSubLayerRegionsParams(activeSubLayer),
    );
  } else {
    subLayerRegionsObj.current.setParams(
      getSubLayerRegionsParamsFallback(activeSubLayer),
    );
  }
};

export const removeSubLayerRegions = (subLayerRegionsObj, map) => {
  if (subLayerRegionsObj.current) {
    map.removeLayer(subLayerRegionsObj.current);
    subLayerRegionsObj.current = null;
  }
};
