import {
  LOAD_PROVIDER_LOADING,
  LOADED_PROVIDER,
} from '../redux/actions/actionsType';
import { post, get } from '../lib/ajax';

export const getProviders = async (dispatch, parameters) => {
  dispatch({ type: LOAD_PROVIDER_LOADING, payload: true });
  const r = await post('/providers/list', parameters);
  if (r?.data?.success) {
    dispatch({ type: LOADED_PROVIDER, payload: r.data.payload });
  }
};

export const getActiveProviders = async (parameters) => {
  const r = await post('/providers/listActives', parameters);
  if (r?.data?.success) {
    return r.data.payload;
  } else {
    return [];
  }
};

export const getServiceOfProviders = async (providerId) => {
  const r = await get(`/providers/getById?providerId=${providerId}`);
  if (r?.data?.success) {
    return r.data.payload;
  }
};

export const addServiceToProvider = async (providerId, serviceId, data) => {
  const r = await post('/providers/create', {
    data,
    providerId,
    serviceId,
  });
  if (r?.data?.success) {
    return r.data.payload;
  }
};

export const removeServiceToProvider = async (providerId, serviceId) => {
  const r = await get(
    `/providers/remove?providerId=${providerId}&serviceId=${serviceId}`,
  );
  if (r?.data?.success) {
    return r.data.payload;
  }
};
