import { useEffect, useRef } from 'react';
import L from 'leaflet';
import { useMap } from 'react-leaflet';
import 'leaflet.nontiledlayer';
import { convertHEXWithoutHash } from '../utils/leafletMapConfig';

const wmsUrl = process.env.REACT_APP_GEOSERVER_MAPS_NEW;

const LimitsLayer = ({ color }) => {
  const map = useMap();
  const layerRef = useRef(null);

  useEffect(() => {
    if (layerRef.current) {
      map.removeLayer(layerRef.current);
      layerRef.current = null;
    }

    const layer = L.nonTiledLayer.wms(wmsUrl, {
      layers: 'limites_web',
      styles: '',
      version: '1.3.0',
      format: 'image/png',
      transparent: true,
      env: `color:${convertHEXWithoutHash(color)}`,
    });

    layerRef.current = layer.addTo(map);

    return () => {
      map.removeLayer(layerRef.current);
      layerRef.current = null;
    };
  }, [color, map]);

  return null;
};

export default LimitsLayer;
