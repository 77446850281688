import React, { memo, useEffect, useCallback, useState, useMemo } from 'react';
import { Button, Card, Divider, Input, notification, Skeleton } from 'antd';
import { LeftCircleOutlined, LeftOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';

import { useScreen } from '../../../../../lib/screen';
import {
  getPermissions,
  getPermissionsByRol,
} from '../../../../../services/permission';
import { changePermissionsRol } from '../../../../../services/rols';
import { filterByString } from '../../../../../lib/utils';

import Title from '../../../../../common/Typograph/Title';
import SimpleTable from '../../../../../common/Table/SimpleTable';
import PaginationMobile from '../../../../../common/PaginationMobile';
import { SelectWithLabel } from '../../../../../common/InputWithLabel';
import CardUserMobile from '../../common/CardUserMobile';
import FiltersMobile from '../../../../../common/FiltersMobile';
import { COLUMNS, filterByStatus } from './utils';

const RolEditing = ({ rol = {}, onBack }) => {
  const { isMobileScreen } = useScreen();
  const dispatch = useDispatch();
  const [allPermissions, setAllPermissions] = useState([]);
  const [filters, setFilters] = useState({
    query: '',
    status: '',
  });
  const [isLoadingPermissions, setLoadingPermissions] = useState(false);
  const [initialPermissionsIds, setInitialPermissionsIds] = useState([]);
  const [permissionsIds, setPermissionsIds] = useState([]);
  const [pagination, setPagination] = useState({ pageSize: 5, current: 1 });

  const allPermissionsFiltered = useMemo(() => {
    let permissionsFiltered = allPermissions.map((data) => ({
      ...data,
      active: initialPermissionsIds.includes(data.id),
      onChange: () => {
        setPermissionsIds((prev) =>
          prev.includes(data.id)
            ? prev.filter((el) => el !== data.id)
            : prev.concat(data.id),
        );
      },
    }));

    if (filters.query !== '') {
      permissionsFiltered = filterByString(permissionsFiltered, filters.query);
    }

    if (filters.status !== '') {
      permissionsFiltered = filterByStatus(permissionsFiltered, filters.status);
    }

    return permissionsFiltered.sort((a, b) => b.active - a.active);
  }, [allPermissions, initialPermissionsIds, filters.query, filters.status]);

  useEffect(() => {
    setLoadingPermissions(true);
    getPermissions().then((permissions) => setAllPermissions(permissions));
    getPermissionsByRol(rol.id)
      .then((permissions) => {
        const permissionsIds = permissions.map((e) => e.id);
        setPermissionsIds(permissionsIds);
        setInitialPermissionsIds(permissionsIds);
      })
      .finally(() => {
        setLoadingPermissions(false);
      });
  }, [rol.id]);

  const handleChangeSearch = ({ target: { value } }) => {
    setFilters({ ...filters, query: value });
    setPagination((prev) => ({ ...prev, current: 1 }));
  };

  const handleSelectOption = (selected) => {
    setFilters({ ...filters, status: selected });
    setPagination((prev) => ({ ...prev, current: 1 }));
  };

  const handleSaveChanges = useCallback(() => {
    const result = initialPermissionsIds.reduce(
      (acc, curr) => {
        if (acc.add.includes(curr)) {
          acc.add = acc.add.filter((el) => el !== curr);
          acc.substract = acc.substract.filter((el) => el !== curr);
        }

        return acc;
      },
      { add: permissionsIds, substract: initialPermissionsIds },
    );

    let promises = [];

    if (result.add.length) {
      promises.push(
        changePermissionsRol(rol.id, {
          isAdd: true,
          roles: result.add,
        }),
      );
    }

    if (result.substract.length) {
      promises.push(
        changePermissionsRol(rol.id, {
          isAdd: false,
          roles: result.substract,
        }),
      );
    }

    Promise.all(promises)
      .then((reponse) => {
        if (!reponse) {
          throw Error('Error');
        }

        notification.success({
          description: 'Se actualizó roles correctamente.',
          message: '¡Listo!',
        });
      })
      .catch(() => {
        notification.error({
          description: 'Error al actualizar el rol.',
          message: 'Error!',
        });
      });
  }, [permissionsIds, initialPermissionsIds, dispatch, rol.id]);

  return (
    <div className='d_content flex flex-col mb-4 service-prevention'>
      {isMobileScreen && (
        <Button
          className='mr-1 flex items-center'
          icon={<LeftCircleOutlined />}
          type='link'
          size='large'
          onClick={onBack}
        >
          Volver
        </Button>
      )}
      <div
        className={`flex ${
          isMobileScreen ? 'flex-col' : 'items-center justify-between'
        }`}
      >
        <div className='flex items-center'>
          {!isMobileScreen && (
            <Button
              className='mr-1 flex items-center'
              type='link'
              size='large'
              onClick={onBack}
            >
              <LeftOutlined />
            </Button>
          )}
          <Title type={isMobileScreen ? 'bold-32' : 'bold-28'}>
            Lista de permisos: {rol.name}
          </Title>
        </div>
      </div>
      <Divider />
      {!isMobileScreen ? (
        <div
          className={`flex justify-between ${
            isMobileScreen ? 'flex-col mb-3' : 'items-center mt-2 mb-4'
          }`}
        >
          <Title>Filtrar lista de permisos</Title>
          <div className='flex gap-2'>
            <Input.Search
              placeholder={'Palabras clave'}
              style={{
                width: '100%',
                maxWidth: '300px',
              }}
              onChange={handleChangeSearch}
              allowClear
              value={filters.query}
            />
            <SelectWithLabel
              className={'ml-2'}
              label={'Ver permisos por estados'}
              options={[
                {
                  label: 'Activo',
                  value: '1',
                },
                {
                  label: 'Inactivo',
                  value: '0',
                },
              ]}
              onChange={handleSelectOption}
              value={filters.status}
            />
          </div>
        </div>
      ) : (
        <div className='flex justify-between items-center'>
          <span className='text-base'>
            {allPermissionsFiltered.length === 1 ? (
              <>Se encontró 1 permiso</>
            ) : (
              <>
                Se encontraron <strong>{allPermissionsFiltered.length}</strong>{' '}
                permisos
              </>
            )}
          </span>
          {isMobileScreen && (
            <FiltersMobile
              buttonTitle={'Filtrar'}
              onClearFilters={() => {
                setFilters({ query: '', status: '' });
              }}
              filters={[
                {
                  key: 'query',
                  typeFilter: 'search',
                  className: 'search',
                  placeholder: 'Palabras clave',
                  onChange: handleChangeSearch,
                  value: filters.query,
                },
                {
                  key: 'status',
                  typeFilter: 'select',
                  label: 'Ver permisos por estados',
                  options: [
                    {
                      label: 'Activo',
                      value: '1',
                    },
                    {
                      label: 'Inactivo',
                      value: '0',
                    },
                  ],
                  onChange: handleSelectOption,
                  value: filters.status,
                },
              ]}
            />
          )}
        </div>
      )}
      {isMobileScreen ? (
        isLoadingPermissions ? (
          [1, 2, 3].map((i) => (
            <Card className='mt-2' key={i}>
              <Skeleton />
            </Card>
          ))
        ) : (
          <>
            {allPermissionsFiltered
              .slice(
                pagination.pageSize * (pagination.current - 1),
                pagination.pageSize * pagination.current,
              )
              .map((rol) => (
                <CardUserMobile key={rol.id} columns={COLUMNS} item={rol} />
              ))}
            <PaginationMobile
              {...pagination}
              total={allPermissionsFiltered?.length}
              onChange={(page) => {
                setPagination((prev) => ({
                  ...prev,
                  current: page,
                }));
              }}
            />
          </>
        )
      ) : (
        <SimpleTable
          className={'mt-2'}
          loading={isLoadingPermissions}
          columns={COLUMNS}
          pagination={{
            ...pagination,
            total: allPermissionsFiltered?.length,
            onChange: (page) => {
              setPagination((prev) => ({
                ...prev,
                current: page,
              }));
            },
          }}
          dataSource={allPermissionsFiltered.map((data) => ({
            ...data,
            active: permissionsIds.includes(data.id),
            onChange: () => {
              setPermissionsIds((prev) =>
                prev.includes(data.id)
                  ? prev.filter((el) => el !== data.id)
                  : prev.concat(data.id),
              );
            },
          }))}
        />
      )}
      {isMobileScreen ? (
        <div
          className={'fixed-bottom p-3 full-width'}
          style={{ background: '#EDEFFC' }}
        >
          <span
            className='full-width absolute'
            style={{
              height: 12,
              top: -12,
              left: 0,
              background:
                'linear-gradient(to bottom, rgba(255,0,0,0), #EDEFFC)',
            }}
          ></span>
          <Button
            type='primary'
            size='large'
            className='full-width'
            onClick={handleSaveChanges}
          >
            Guardar cambios
          </Button>
        </div>
      ) : (
        <Button
          className='mt-2'
          style={{ alignSelf: 'flex-end' }}
          size='large'
          type='primary'
          onClick={handleSaveChanges}
        >
          Guardar cambios
        </Button>
      )}
    </div>
  );
};

export default memo(RolEditing);
