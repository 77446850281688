import React, { useEffect } from 'react';
import { Divider, Form, Input, Modal, notification } from 'antd';

import InputWithLabel from '../../../../common/InputWithLabel';
import text from '../../../../config/text_es';
import { useScreen } from '../../../../lib/screen';
import { IconCloseRed } from '../../ui/PreventionService/Reports/utils';
import Title from '../../../../common/Typograph/Title';

const ModalForm = ({
  handleClose,
  handleAction,
  data,
  visible,
  isLoadingCreate,
}) => {
  const [form] = Form.useForm();
  const { validateFields, setFieldsValue } = form;
  const { isMobileScreen } = useScreen();

  const handleCancel = () => {
    handleClose();
    form.resetFields();
  };

  const handleOk = () => {
    validateFields().then((values) => {
      if (!values.name.trim()) {
        setFieldsValue({ name: '' });
        notification.destroy();
        return notification.warning({
          description: 'Agregue un nombre válido.',
          message: 'Crear segmento comercial!',
        });
      }

      handleAction(values);
      form.resetFields();
    });
  };

  useEffect(() => {
    const elements = document.querySelectorAll('.ant-popover');
    if (elements.length > 0) {
      elements.forEach((element) => {
        element.classList.add('ant-popover-hidden');
      });
    }
  }, [visible]);

  return (
    <Modal
      open={visible}
      title=''
      closable={true}
      onCancel={handleCancel}
      centered={true}
      onOk={handleOk}
      className={`modal-provider modal-centered ${
        isMobileScreen ? 'modal-mobile' : ''
      }`}
      cancelText='Cancelar'
      okText={`${data ? 'Guardar' : 'Crear'}`}
    >
      <div
        style={
          isMobileScreen
            ? { width: '100%' }
            : { padding: '64px 64px 12px 64px', minWidth: 528 }
        }
      >
        <Title type={'bold-28'} style={{ textAlign: 'center' }}>{`${
          data ? 'Editar segmento comercial' : 'Crear segmento comercial'
        }`}</Title>
        <Divider className='mt-2 mb-1' />
        <Form form={form} disabled={isLoadingCreate}>
          <Form.Item name='id' initialValue={data ? data.id : ''}>
            <Input type='hidden' />
          </Form.Item>

          <Form.Item
            name='name'
            initialValue={data ? data?.name : ''}
            rules={[{ required: true, message: text.required_message }]}
          >
            <InputWithLabel label='Nombre' disabled={isLoadingCreate} />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default ModalForm;
