import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Typography } from 'antd';

import Topbar from '../../../../common/ui/Topbar';

import ModalEditContact from '../PreventionService/ServiceRequest/components/ModalEditContact';
import { useScreen } from '../../../../lib/screen';
import ServiceDetail from './ServiceDetail';
import ServicesAvailable from '../PreventionService/ServicesAvailable';
import ServiceRequest from '../PreventionService/ServiceRequest';
import { pushDigitalData } from '../../../../lib/utils';
import { TRACK } from '../../../../lib/consts';
import Tabs from '../../../../common/Tabs';
import { RideButton } from '@rimac-seguros/ride-system-components';

const { Title } = Typography;

const listTabPanelCommon = [
  {
    key: '1',
    component: ServicesAvailable,
    title: 'Servicios disponibles',
  },
  {
    key: '2',
    component: ServiceRequest,
    title: 'Servicios solicitados',
  },
  /* {
    key: '3',
    component: Reports,
    title: 'Informes técnicos',
  }, */
];

const listTabAdminRimac = [
  {
    key: '1',
    component: ServiceRequest,
    title: 'Lista de servicios',
  },
  /* {
    key: '3',
    component: ReportsRimac,
    title: 'Informes técnicos',
  }, */
];

const RiskServices = () => {
  const [active, setActive] = useState('1');
  const [editContact, setEditContact] = useState(null);
  const { isMobileScreen: isPhone } = useScreen();
  const { userAuth } = useSelector((state) => ({
    userAuth: state.auth.user,
  }));

  const navigate = useNavigate();

  const { id, itemId } = useParams();
  const matchId = id && itemId;

  const handleChangeTab = (key) => {
    setActive(key);
    if (process.env.REACT_APP_ANALYTICS_ENABLE !== '0') {
      pushDigitalData(
        {
          action: {
            group: 'Centro Monitoreo',
            category: 'Servicios de Riesgos',
            name: 'Click',
            label: `Tab - ${
              (isAdminRimac ? listTabAdminRimac : listTabPanelCommon).find(
                (x) => x.key === key,
              )?.title
            }`,
          },
        },
        TRACK.ACTION,
      );
    }
  };
  const isAdminRimac = ['admin_rimac', 'gestor_de_cuenta'].includes(
    userAuth?.rol?.slug,
  );

  return (
    <div id='checkSize' className='h100vh dashboard-UI'>
      <Topbar />
      {matchId ? (
        <ServiceDetail key='service-detail' />
      ) : (
        <>
          {editContact && (
            <ModalEditContact
              isRisk
              editContact={editContact}
              onBack={() => {
                setEditContact(null);
              }}
            />
          )}
          <div
            className={`d_content service-prevention ${
              editContact ? 'hidden' : 'block'
            }`}
          >
            <div className='flex justify-between items-center mb-4'>
              <Title className='header-container'>
                Servicios de Gestión de Riesgos
              </Title>
              {!isPhone &&
                (!isAdminRimac ? active === '2' : active === '1') && (
                  <RideButton
                    variant='fill'
                    size='medium'
                    onClick={() => {
                      if (process.env.REACT_APP_ANALYTICS_ENABLE !== '0') {
                        pushDigitalData(
                          {
                            action: {
                              group: 'Centro Monitoreo',
                              category: 'Servicios de Riesgos',
                              name: 'Lista de Servicios',
                              label: 'Nueva Solicitud',
                            },
                          },
                          TRACK.ACTION,
                        );
                      }
                      navigate('/solicitar-servicio?risks=true', {
                        replace: true,
                      });
                    }}
                    text='Nueva solicitud'
                  />
                )}
            </div>
            <Tabs
              activeKey={active}
              onChange={handleChangeTab}
              items={(isAdminRimac
                ? listTabAdminRimac
                : listTabPanelCommon
              ).map(({ component: Component, title, key }) => ({
                label: title,
                key: key,
                children: (
                  <Component
                    isRisks
                    isActive={active === key}
                    isAdminRimac={isAdminRimac}
                    editContact={editContact}
                    companyId={userAuth?.company_id}
                    setEditContact={setEditContact}
                  />
                ),
              }))}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default RiskServices;
