import { Modal, Input, Button } from 'antd';
import React, { useState } from 'react';
import { LeftOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';

import FloatLabel from '../../../../../../../common/ui/FloatLabel';
import { saveRequestServiceItemQuiz } from '../../../../../../../services/requestServicesItems';

const { TextArea } = Input;

const stars = [
  { value: 1 },
  { value: 2 },
  { value: 3 },
  { value: 4 },
  { value: 5 },
];

const buttons = [
  {
    key: '5sd7',
    value:
      'La coordinación con el especialista previo a la ejecución del servicio',
  },
  {
    key: '9k5g',
    value: 'Desempeño del especialista durante la visita a sus instalaciones',
  },
  { key: '0244', value: 'Contenido y recomendaciones del informe' },
  { key: 'd5g4', value: 'Otros' },
];

const ModalSurvey = ({
  visible,
  onCancel,
  setFinishSurvey,
  onFinishQuiz,
  initStep = 1,
  initTypeMessage = '',
  requestServiceItemId,
  requestServiceItemName = '',
  closable = true,
}) => {
  const [current, setCurrent] = useState(initStep);
  const [rating, setRating] = useState(null);
  const [whyChoose, setWhyChoose] = useState(null);
  const [comments, setComments] = useState('');
  const dispatch = useDispatch();
  const { isLoadingCreateQuiz } = useSelector(
    (state) => state.requestServicesItems,
  );

  const next = () => setCurrent((prev) => prev + 1);
  const back = () => setCurrent((prev) => prev - 1);

  const handleRating = (current) => setRating(current);

  const handleChoose = (value) => setWhyChoose(value);

  const handleComments = (e) => setComments(e.target.value);

  const handleFinish = () => {
    saveRequestServiceItemQuiz(
      {
        questions: {
          Q1: rating,
          Q2: whyChoose,
          Q3: comments,
        },
        request_service_item_id: requestServiceItemId,
      },
      dispatch,
    ).then(() => {
      onFinishQuiz(true);
      onCancel();
      setFinishSurvey(true);
    });
  };

  return (
    <Modal
      title=''
      open={visible}
      onCancel={onCancel}
      confirmLoading={isLoadingCreateQuiz}
      footer={false}
      closable={closable}
      keyboard={closable}
      className='modal-survey'
      style={{ transformOrigin: 'bottom' }}
      maskClosable={false}
    >
      <div className='steps'>
        {current > 1 ? (
          <Button icon={<LeftOutlined />} type='link' onClick={back}>
            Regresar
          </Button>
        ) : null}

        {current > 0 && (
          <div className='steps-title'>
            <span className='current'>0{current} </span>/ 03
          </div>
        )}

        <div className='steps-content'>
          {current === 0 &&
            (initTypeMessage === 'InfoTecnico' ? (
              <>
                <div className='steps-content'>
                  <h2>Ayúdanos a seguir mejorando</h2>
                  <p>
                    {
                      'Responde estas 3 preguntas, te tomará 10 segundos ¡Gracias!'
                    }
                  </p>
                </div>
                <div className='steps-content__action'>
                  <button style={{ width: '200px' }} onClick={next}>
                    Responder encuesta
                  </button>
                </div>
                <span
                  className='relative'
                  style={{
                    top: '24px',
                    color: 'rgb(255, 28, 68)',
                    fontWeight: 'bold',
                  }}
                >
                  * Podrás descargar el informe cuando completes la encuesta.
                </span>
              </>
            ) : (
              <>
                <div className='steps-content'>
                  <h2>Rellenar encuesta</h2>
                  <p>{`Ayúdanos contestando 3 preguntas sobre el último servicio realizado: ${requestServiceItemName}`}</p>
                </div>
                <div className='steps-content__action'>
                  <button
                    style={{ width: '200px' }}
                    onClick={onCancel}
                    className='secondary'
                  >
                    En otro momento
                  </button>
                  <button style={{ width: '200px' }} onClick={next}>
                    Responder encuesta
                  </button>
                </div>
              </>
            ))}

          {current === 1 && (
            <>
              <div className='steps-content__rating'>
                <h2>
                  ¿Qué tan satisfecho te sientes con el servicio brindado por
                  RIMAC?
                </h2>

                <div className='rating'>
                  {stars.map((star) => (
                    <div
                      className={`rating-star ${
                        rating >= star.value ? 'active' : ''
                      }`}
                      key={star.value}
                      onClick={() => handleRating(star.value)}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='30'
                        height='30'
                        viewBox='0 0 30 30'
                        fill='currentColor'
                      >
                        <path
                          d='M15.3688 26.02L22.1806 29.7601C23.6071 30.5437 25.2833 29.2614 24.998 27.6229L23.7141 19.7154C23.6785 19.4304 23.7498 19.1455 23.9638 18.9674L29.4203 13.3751C30.5616 12.1997 29.9196 10.1694 28.3504 9.92003L20.754 8.7802C20.4687 8.74458 20.2548 8.56649 20.1478 8.31715L16.7597 1.12201C16.0464 -0.374005 13.9779 -0.374005 13.2647 1.12201L9.87662 8.31715C9.76962 8.56649 9.51998 8.74458 9.27033 8.7802L1.67397 9.92003C0.0690989 10.1694 -0.572848 12.1997 0.604054 13.3751L6.09626 18.9674C6.27458 19.1811 6.38157 19.466 6.34591 19.7154L4.99069 27.6229C4.70538 29.297 6.38157 30.5437 7.80812 29.7601L14.6199 26.02C14.8339 25.9132 15.1192 25.9132 15.3688 26.02Z'
                          fill='currentColor'
                        />
                      </svg>
                    </div>
                  ))}
                </div>
              </div>

              <div className='steps-content__action'>
                <button onClick={next} disabled={!rating}>
                  Continuar
                </button>
              </div>
            </>
          )}

          {current === 2 && (
            <>
              <div className='steps-content__choose'>
                <h2>Cuéntanos ¿Por qué elegiste la opción anterior?</h2>

                <div className='buttons'>
                  {buttons.map((el) => (
                    <div
                      className={`button ${
                        whyChoose === el.value ? 'active' : ''
                      }`}
                      key={el.value}
                      onClick={() => handleChoose(el.value)}
                    >
                      {el.value}
                    </div>
                  ))}
                </div>
              </div>

              <div className='steps-content__action'>
                <button onClick={next} disabled={!whyChoose}>
                  Continuar
                </button>
              </div>
            </>
          )}

          {current === 3 && (
            <>
              <div className='steps-content__choose'>
                <h2>Cuéntanos ¿Por qué elegiste la opción anterior?</h2>

                <FloatLabel label='Escribenos tus comentarios' value={comments}>
                  <TextArea
                    value={comments}
                    onChange={handleComments}
                    minLength={15}
                    maxLength={500}
                    rows={3}
                    showCount={true}
                  />
                </FloatLabel>
              </div>

              <div className='steps-content__action'>
                <button disabled={isLoadingCreateQuiz} onClick={handleFinish}>
                  Enviar
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ModalSurvey;
