import { useNavigate } from 'react-router-dom';
import {
  RideButton,
  RideGlArrowForward,
  RideTag,
} from '@rimac-seguros/ride-system-components';
import DOMPurify from 'dompurify';

import { pushDigitalData } from '../../../../../lib/utils';
import { TRACK, eventTypes } from '../../../../../lib/consts';
import Time from './../Time';

const NewsletterCard = ({ id, title, event, type, disabled }) => {
  const navigate = useNavigate();
  const isActive = disabled ? false : Boolean(event.date_to_end_diff);

  return (
    <article className='newsletterCard'>
      <img
        className='newsletterCard__image'
        src={
          event?.image?.indexOf('https://') !== -1
            ? event?.image
            : `${process.env.REACT_APP_WEB_PATH_S3_NEW}${event?.image}`
        }
        alt={event?.detected_condition}
      />
      <div className='newsletterCard__info'>
        <div className='newsletterCard__date-status'>
          <div className='flex gap-2 items-start'>
            <RideTag
              case={'informative'}
              text={eventTypes.find((event) => event.id === type)?.name}
            />
            <RideTag
              type='strong'
              case={isActive ? 'success' : 'null'}
              text={isActive ? 'Vigente' : 'No vigente'}
            />
          </div>
          <Time
            dateStart={event?.date_event_start}
            dateEnd={event?.date_event_end}
          />
        </div>
        <h2 className='newsletterCard__title'>{title}</h2>
        <div
          className='newsletterCard__description'
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(event?.summary),
          }}
        ></div>
        <RideButton
          className='ml-auto mt-auto'
          text={'Leer boletín'}
          variant='text-primary'
          glyph={<RideGlArrowForward />}
          onClick={() => {
            if (process.env.REACT_APP_ANALYTICS_ENABLE !== '0') {
              pushDigitalData(
                {
                  action: {
                    group: 'Centro Monitoreo',
                    category: 'Boletines',
                    name: 'Leer Boletín',
                    label: title,
                  },
                },
                TRACK.ACTION,
              );
            }

            navigate(`/monitoreo/boletines?id=${id}`);
          }}
        />
      </div>
    </article>
  );
};

export default NewsletterCard;
