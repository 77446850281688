import React, { memo } from 'react';

const InformationCard = ({ img, title, description }) => {
  return (
    <div className='information__card'>
      <div className='information__card__icon'>
        <img src={img} alt='' />
      </div>
      <div className='information__card__text-box'>
        <div className='information__card__title'>{title}</div>
        <div className='information__card__description'>{description}</div>
      </div>
    </div>
  );
};

export default memo(InformationCard);
