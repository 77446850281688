import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Popover, Radio, Tooltip, notification, Spin } from 'antd';
import axios from 'axios';
import { RideButton } from '@rimac-seguros/ride-system-components';

import { getIntersectionPoints, getRoutePoints } from './utils';
import {
  deleteMyRoute,
  getMyRoutes,
  getReportURL,
} from '../../../../services/myRoutes';
import { MY_ROUTE_SET_ACTIVE_ROUTE } from '../../../../redux/actions/actionsType';
import { IconDots } from '../../../Icons/iconText';
import ResendModal from './ResendModal';

const ListRoutes = ({ isMobile, controlMapDrawerVisible, setStep }) => {
  const dispatch = useDispatch();
  const [uuid, setUuid] = useState(null);
  const [loading, setLoading] = useState(false);
  const activeLayer = useSelector((state) => state.maps.activeLayer);
  const { list, activeRoute } = useSelector((state) => state.myRoutes);
  const geologicalPoints = useSelector(
    (state) => state.geologicalHazards.points,
  );
  const [showModal, setShowModal] = useState(null);

  const handleSelectRoute = (route) => {
    const parent = document.querySelector('.leaflet-popup-close-button');
    if (parent) {
      parent.click();
    }

    route.uuid !== activeRoute?.uuid
      ? dispatch({ type: MY_ROUTE_SET_ACTIVE_ROUTE, payload: route })
      : dispatch({ type: MY_ROUTE_SET_ACTIVE_ROUTE, payload: null });

    if (isMobile) {
      controlMapDrawerVisible(false);
    }
  };

  const generateReport = async (e, route, geologicalRadius) => {
    e.stopPropagation();

    if (activeLayer.id !== 'geological') {
      setShowModal(route);
      return;
    }

    if (route?.uuid !== activeRoute?.uuid) {
      notification.error({
        message: 'Debe seleccionar la ruta para generar el reporte.',
      });
      return;
    }

    setLoading(true);

    try {
      const resRoute = await axios.get(activeRoute?.url);
      if (resRoute.status !== 200) {
        notification.error({
          message: 'Ha ocurrido un error al generar el reporte.',
        });
        return;
      }

      let routeGeoJSON = resRoute.data;
      const routePoints = getRoutePoints(routeGeoJSON);
      const crossPoints = await getIntersectionPoints(
        geologicalPoints,
        routeGeoJSON,
        geologicalRadius,
      );

      const url = await getReportURL({
        routePoints,
        geologicalPoints: crossPoints,
        uuid,
      });
      window.open(url);
    } catch (error) {
      notification.error({
        message: 'Ha ocurrido un error al generar el reporte.',
      });
    } finally {
      setLoading(false);
    }
  };

  const deleteRoute = async (e, id) => {
    e.stopPropagation();
    await deleteMyRoute(dispatch, id);
    dispatch({
      type: MY_ROUTE_SET_ACTIVE_ROUTE,
      payload: null,
    });
    setStep(1);
    getMyRoutes(dispatch);
  };

  const handleOptionsRoute = (e, id) => {
    e.stopPropagation();
    setUuid(id);
  };

  return (
    <>
      {list.map((route) => (
        <div
          key={route?.uuid}
          className={`drawer-map-layers__list__item ${
            activeRoute?.uuid === route?.uuid ? 'active' : ''
          }`}
          onClick={() => handleSelectRoute(route)}
        >
          <div className='drawer-map-layers__list__item__name'>
            <Popover
              placement='topRight'
              trigger='click'
              content={
                <div className='buttons-popover'>
                  {loading ? (
                    <div className='generating-report'>
                      <Spin /> <span>Generando reporte...</span>
                    </div>
                  ) : (
                    <RideButton
                      onClick={(e) =>
                        generateReport(e, route, route?.geologicalRadius)
                      }
                      text='Generar reporte'
                      size='medium'
                      variant='text-primary'
                    />
                  )}
                  <Button onClick={(e) => deleteRoute(e, route?.uuid)}>
                    <span>Eliminar ruta</span>
                  </Button>
                </div>
              }
              className='popover-actions'
            >
              <Button onClick={(e) => handleOptionsRoute(e, route?.uuid)}>
                <IconDots />
              </Button>
            </Popover>
            <Tooltip placement='top' title={route?.name} mouseEnterDelay={0.5}>
              <div className='drawer-map-layers__list__item__name__text ellipsis'>
                {route?.name}
              </div>
              <span className='drawer-map-layers__list__item__radius__text ellipsis'>
                Radio: {route?.geologicalRadius} m
              </span>
            </Tooltip>
          </div>
          <div className='drawer-map-layers__list__item__action'>
            <Radio
              className='radio-layer'
              checked={activeRoute?.uuid === route.uuid}
            />
          </div>
        </div>
      ))}
      {showModal && (
        <ResendModal
          visible={Boolean(showModal)}
          route={showModal}
          onCancel={() => setShowModal(null)}
        />
      )}
    </>
  );
};

export default ListRoutes;
