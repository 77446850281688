import { useSelector } from 'react-redux';

import Ruler from './Ruler';

const TOOLS = {
  ruler: <Ruler />,
  clear: null,
};

const Tools = () => {
  const activeTool = useSelector((state) => state.maps.activeTool);

  return <>{TOOLS[activeTool]}</>;
};

export default Tools;
