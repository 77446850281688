import React from 'react';

const Polygon = (props) => {
  return (
    <svg
      width='32'
      height='32'
      viewBox='0 0 40 40'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M36.875 13.125C37.9094 13.125 38.75 12.2844 38.75 11.25V6.25C38.75 5.21563 37.9094 4.375 36.875 4.375H31.875C30.8406 4.375 30 5.21563 30 6.25V7.01719L10 4.84375V3.125C10 2.09063 9.15938 1.25 8.125 1.25H3.125C2.09063 1.25 1.25 2.09063 1.25 3.125V8.125C1.25 9.15938 2.09063 10 3.125 10H4.375V30H3.125C2.09063 30 1.25 30.8406 1.25 31.875V36.875C1.25 37.9094 2.09063 38.75 3.125 38.75H8.125C9.15938 38.75 10 37.9094 10 36.875V31.875C10 31.5391 9.91094 31.225 9.75469 30.9516L13.8203 25.5313C13.0656 25.1547 12.3875 24.6438 11.8219 24.0297L7.34375 30H6.875V10H8.125C9.15938 10 10 9.15938 10 8.125V7.35781L30 9.53125V11.25C30 12.2844 30.8406 13.125 31.875 13.125H33.125V30H31.875C31.6734 30 31.4797 30.0328 31.2984 30.0922L22.8187 22.8234C22.3953 23.5516 21.8422 24.1937 21.1906 24.7203L30 32.2703V36.8734C30 37.9078 30.8406 38.7484 31.875 38.7484H36.875C37.9094 38.7484 38.75 37.9078 38.75 36.8734V31.8734C38.75 30.8391 37.9094 29.9984 36.875 29.9984H35.625V13.1234H36.875V13.125ZM7.5 36.25H3.75V32.5H7.5V36.25ZM7.5 7.5H3.75V3.75H7.5V7.5ZM36.25 36.25H32.5V32.5H36.25V36.25ZM32.5 6.875H36.25V10.625H32.5V6.875Z'
        fill='url(#paint0_linear_827_16667)'
      />
      <path
        d='M16.875 24.375C19.6364 24.375 21.875 22.1364 21.875 19.375C21.875 16.6136 19.6364 14.375 16.875 14.375C14.1136 14.375 11.875 16.6136 11.875 19.375C11.875 22.1364 14.1136 24.375 16.875 24.375Z'
        fill='url(#paint1_linear_827_16667)'
      />
      <defs>
        <linearGradient
          id='paint0_linear_827_16667'
          x1='1.25'
          y1='1.25'
          x2='21.3531'
          y2='46.482'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.272135' stopColor='#34263B' />
          <stop offset='0.658079' stopColor='#13172C' />
        </linearGradient>
        <linearGradient
          id='paint1_linear_827_16667'
          x1='12.1366'
          y1='15.1598'
          x2='20.3759'
          y2='23.3991'
          gradientUnits='userSpaceOnUse'
        >
          <stop offset='0.35' stopColor='#F7052D' />
          <stop offset='0.85' stopColor='#CA5AFA' />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Polygon;
