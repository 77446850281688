import {
  RideGlLeft,
  RideGlRight,
  RideIconButton,
} from '@rimac-seguros/ride-system-components';

const Pagination = ({
  current = 1,
  total = 5,
  pageSize = 5,
  onChange = () => {},
}) => {
  return (
    <div className='flex flex-row items-center mx-auto'>
      <RideIconButton
        variant='outline'
        size='medium'
        glyph={<RideGlLeft />}
        disabled={current === 1}
        onClick={() => {
          onChange(current - 1);
        }}
        aria-label='Traer los anteriores 5 resultados'
      />
      <div className='font-bold'>
        <span className={'ml-2 mr-1 text-base'}>{current}</span>
        <span className='text-base'>/</span>
        <span className={'ml-1 mr-2 text-base'}>
          {Math.ceil(total / pageSize) || 1}
        </span>
      </div>
      <RideIconButton
        variant='outline'
        size='medium'
        glyph={<RideGlRight />}
        disabled={current === Math.ceil(total / pageSize)}
        onClick={() => onChange(current + 1)}
        aria-label='Traer los siguientes 5 resultados'
      />
    </div>
  );
};

export default Pagination;
