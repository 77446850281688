import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import useQueryParams from '../../../hooks/useQueryParams';
import Topbar from '../../../common/ui/Topbar';
import ArticlePreview from './ArticlePreview';

const PREVIEWS = {
  articleURL: ArticlePreview,
};

const Preview = () => {
  const navigate = useNavigate();
  const querys = useQueryParams();
  const keys = Object.keys(querys);

  if (keys.length === 0) navigate('/configuracion?configkey=articles-list');

  const PreviewComponent = useMemo(() => {
    const key = keys[0];
    const Component = PREVIEWS[key];

    return Component ? <Component queryValue={querys[key]} /> : null;
  }, [keys]);

  return (
    <div id='checkSize' className='h100vh dashboard-UI'>
      <Topbar />
      {PreviewComponent}
    </div>
  );
};

export default Preview;
