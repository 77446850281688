import React from 'react';
import { Skeleton } from 'antd';

const PopupLayerHeader = ({
  dataLayer,
  activeLayer,
  activeSubLayer,
  children,
}) => {
  return (
    <div className='popup-title'>
      <span className='layer-name'>{activeLayer?.name}</span>
      {!['emergencias', 'sismos', 'ligthing', 'tsunamis'].includes(
        activeLayer?.id,
      ) && (
        <div className='popup-value'>
          {typeof dataLayer === 'number' && !isNaN(dataLayer) ? (
            `${dataLayer.toFixed(2)} ${
              activeSubLayer?.unit ?? activeLayer?.unit
            }`
          ) : (
            <Skeleton active title={false} paragraph={{ rows: 1 }} />
          )}
        </div>
      )}
      {children}
    </div>
  );
};

export default PopupLayerHeader;
