import * as React from 'react';

const IconArrow = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width={12}
    height={12}
    fill='none'
    {...props}
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M3.864 2.364a.9.9 0 0 1 1.272 0l3 3a.9.9 0 0 1 0 1.272l-3 3a.9.9 0 1 1-1.272-1.272L6.227 6 3.864 3.636a.9.9 0 0 1 0-1.272Z'
      fill='#A9AFD9'
    />
  </svg>
);

export default IconArrow;
