import React from 'react';

import { Button, Typography } from 'antd';
import {
  RideButton,
  RideGlUploadOutline,
} from '@rimac-seguros/ride-system-components';

import { IconUpload } from '../../../../common/Icons';
import { useScreen } from '../../../../lib/screen';

const { Title } = Typography;

const renderIcon = (key) => {
  if (key === 'business') {
    return <IconUpload />;
  }

  return null;
};

const Header = ({
  active = {},
  hiddenHeaderActions,
  setOpenBussinessForm = () => {},
  setIsUploadView = () => {},
}) => {
  const { isMobileScreen } = useScreen();

  const handleAction = () => {
    if (active.key === 'business') {
      setOpenBussinessForm(true);
    }
  };

  const handleLinks = (keyUpload) => {
    if (active.key === 'business') {
      setIsUploadView(keyUpload);
    }
  };

  return (
    <div className='flex justify-between items-center mb-2'>
      <Title className='header-container'>{active?.label}</Title>

      {isMobileScreen && active?.link && (
        <div className='mobile-link'>
          {typeof active?.link === 'string' ? (
            <Button
              type='link'
              className='link'
              onClick={() => handleLinks(true)}
            >
              {renderIcon(active?.key)} {active?.link}
            </Button>
          ) : (
            active?.link.map(({ text, key }) => (
              <Button
                className='mr-2 link'
                key={key}
                type='link'
                onClick={() => handleLinks(key)}
              >
                {renderIcon(active?.key)} {text}
              </Button>
            ))
          )}
        </div>
      )}

      {isMobileScreen && active?.create && !hiddenHeaderActions && (
        <div
          className={'fixed-bottom p-3 full-width'}
          style={{ background: '#EDEFFC' }}
        >
          <span
            className='full-width absolute'
            style={{
              height: 12,
              top: -12,
              left: 0,
              background:
                'linear-gradient(to bottom, rgba(255,0,0,0), #EDEFFC)',
            }}
          ></span>

          <Button
            className='full-width'
            onClick={() => handleAction()}
            type='primary'
            style={{ height: 48, fontSize: 18 }}
          >
            {active?.create}
          </Button>
        </div>
      )}

      {!isMobileScreen && !hiddenHeaderActions && (
        <div className='flex gap-4'>
          {typeof active?.link === 'string' ? (
            <RideButton
              onClick={() => handleLinks(true)}
              variant='text-primary'
              text={active?.link}
              glyphPosition='left'
              glyph={<RideGlUploadOutline />}
              size='medium'
            />
          ) : (
            active?.link?.map(({ text, key }) => (
              <RideButton
                key={key}
                onClick={() => handleLinks(key)}
                variant='text-primary'
                text={text}
                glyph={<RideGlUploadOutline />}
                glyphPosition='left'
                size='medium'
              />
            ))
          )}
          {active?.create && (
            <RideButton
              variant='fill'
              onClick={() => handleAction()}
              text={active?.create}
              size='medium'
            />
          )}
        </div>
      )}
    </div>
  );
};

export default Header;
