import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TileLayer } from 'react-leaflet';

import { getSismoEventsToMap } from '../../../../services/sismo';
import PointGeoMarker from './PointGeoMarker';

const LayerEarthquake = ({ opacity, lastEarthquake }) => {
  const dispatch = useDispatch();
  const points = useSelector((state) => state.sismo.sismosToMap);
  const filters = useSelector((state) => state.sismo.filters);
  const [selectedPoint, setSelectedPoint] = useState(null);

  const getPoints = async (filters) => {
    await getSismoEventsToMap({ dispatch, ...filters });
    setSelectedPoint(null);
  };

  useEffect(() => {
    getPoints(filters);
  }, [filters]);

  const pointsFiltered = useMemo(() => {
    if (!(filters.acceleration && filters.acceleration.length > 0)) {
      return points;
    }

    return points.map((point) => {
      const data = JSON.parse(point.data);
      const acc = data.ground_acceleration;

      if (acc && typeof acc === 'object' && Array.isArray(acc.features)) {
        const featuresFiltered = acc.features.filter((feature) => {
          const value = feature.properties.value;
          return filters.acceleration.some((filter) => {
            if (
              Array.isArray(filter) &&
              value >= filter[0] &&
              value < filter[1]
            ) {
              return true;
            }
            return typeof filter === 'string' && value >= 1000;
          });
        });
        data.ground_acceleration = { ...acc, features: featuresFiltered };
      }

      point.data = JSON.stringify(data);

      return point;
    });
  }, [points, filters]);

  return (
    <>
      <TileLayer
        attribution='Google Maps'
        url='https://www.google.cn/maps/vt?lyrs=m@189&gl=cn&x={x}&y={y}&z={z}'
        opacity={opacity}
      />
      {pointsFiltered.map((point, index, arr) => {
        const isLastPoint = arr.length - 1 === index;

        return (
          <PointGeoMarker
            key={point.id}
            point={point}
            isLastPoint={isLastPoint}
            lastEarthquake={lastEarthquake}
            showAcceleration={
              selectedPoint ? selectedPoint === point.id : isLastPoint
            }
            setSelectedPoint={setSelectedPoint}
          />
        );
      })}
    </>
  );
};

export default LayerEarthquake;
