import React, { Fragment, useMemo } from 'react';
import {
  Form,
  Checkbox,
  Radio,
  Input,
  Slider,
  DatePicker,
  Select,
  AutoComplete,
  Typography,
} from 'antd';
import { useSelector } from 'react-redux';
import {
  handleUpdateFilters,
  handleChangeDate,
  handleChangeRange,
  handleUpdateInputFilters,
  handleUpdateSelectFilters,
} from './filterHandler';
import { getDepartments, getDistricts, getProvinces } from './geography';

const Text = Typography.Text;

const FormItem = ({
  item,
  setFilters,
  date,
  updateDate,
  setDate,
  setUpdateDate,
  filters,
}) => {
  const zones = useSelector((state) => state.zones.zones);

  const handleUpdate = handleUpdateFilters(setFilters);
  const handleDateChange = handleChangeDate(setFilters, setDate, setUpdateDate);
  const updateRange = handleChangeRange(setFilters);
  const updateInputFilters = handleUpdateInputFilters(setFilters);
  const updateSelectFilters = handleUpdateSelectFilters(setFilters);
  const departments = useMemo(() => getDepartments(zones), [zones]);
  const provinces = useMemo(() => getProvinces(departments), [departments]);
  const districts = useMemo(() => getDistricts(provinces), [provinces]);

  return (
    <Fragment key={`KEY ${item.id}`}>
      <div className='drawer-map-filter__body__title'>{item.title}</div>
      <div className='drawer-map-filter__body__checkbox'>
        {item?.checkbox?.map((check, index) => (
          <div
            className='drawer-map-filter__body__checkbox__item'
            key={`check-${check?.id ?? index}`}
          >
            <Form.Item name={`check['${check?.id}']`} valuePropName='checked'>
              <Checkbox
                id='event-1'
                onChange={(e) =>
                  handleUpdate(
                    e,
                    check?.valueToSet ?? check?.value ?? check?.name,
                    item.key,
                  )
                }
              >
                <Text>{check?.name}</Text>
              </Checkbox>
            </Form.Item>
          </div>
        ))}
        {item.radio && (
          <Form.Item name='radio.group' initialValue={null}>
            <Radio.Group>
              {item.radio.map((radio) => (
                <Radio
                  value={radio.value}
                  key={radio.id}
                  onChange={(e) => handleUpdate(e, radio?.valueToSet, item.key)}
                >
                  <Text>{radio.name}</Text>
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        )}
        {item.input && (
          <Input
            name={item.name}
            value={filters?.[item.name]}
            placeholder={item.label}
            onChange={updateInputFilters}
          />
        )}
        {item.select && (
          <Select
            placeholder={item.label}
            value={filters?.[item.key]}
            onChange={(value) => updateSelectFilters(value, item.key)}
            style={{ width: '100%' }}
            options={departments.map((department) => ({
              value: department.value,
              label: department.label,
            }))}
          />
        )}
        {item.autocomplete && (
          <AutoComplete
            value={filters?.[item.key]}
            onChange={(value) => updateSelectFilters(value, item.key)}
            placeholder={item.label}
            filterOption={(inputValue, option) =>
              option.props.children
                .toUpperCase()
                .indexOf(inputValue.toUpperCase()) !== -1
            }
            style={{ width: '100%' }}
          >
            {item.typeAutocomplete === 'provinces'
              ? provinces.map((province) => (
                  <Select.Option key={province.value} value={province.value}>
                    {province.label}
                  </Select.Option>
                ))
              : districts.map((district) => (
                  <Select.Option key={district.value} value={district.value}>
                    {district.label}
                  </Select.Option>
                ))}
          </AutoComplete>
        )}
        {item.date && (
          <DatePicker.RangePicker
            name='date'
            placeholder={['', '']}
            format='DD-MM-YYYY'
            value={date}
            onChange={(value) => handleDateChange('date', value)}
          />
        )}
        {item.updateDate && (
          <DatePicker.RangePicker
            name='updateDate'
            placeholder={['', '']}
            format='DD-MM-YYYY'
            value={updateDate}
            onChange={(value) => handleDateChange('updateDate', value)}
          />
        )}
        {item.range && (
          <>
            <Slider
              range
              className='range-earthquake'
              step={item.step}
              value={[
                filters[item.key]?.[0] ?? item.defaultValue?.[0],
                filters[item.key]?.[1] ?? item.defaultValue?.[1],
              ]}
              max={item.max}
              min={item.min}
              tooltip={{ formatter: (value) => `${value}` }}
              onChange={(values) => updateRange(values, item.key)}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'justify-between',
              }}
            >
              <span>
                <b>Min:</b> {filters[item.key]?.[0] ?? item.defaultValue?.[0]}
              </span>
              <span>
                <b>Max:</b> {filters[item.key]?.[1] ?? item.defaultValue?.[1]}
              </span>
            </div>
          </>
        )}
      </div>
    </Fragment>
  );
};

export default FormItem;
