import React, { useCallback, useMemo, useState } from 'react';
import { Button, List, Popover } from 'antd';
import { DownloadOutlined, EyeOutlined, MoreOutlined } from '@ant-design/icons';
import moment from 'moment';

import TableCommon from '../../../../../../../common/Table';
import { secureRandom } from '../../../../../../../lib/utils';

const PopoverOptions = ({ onViewDetail, onDownload, existFile }) => {
  const [visible, setVisible] = useState(false);

  return (
    <Popover
      onOpenChange={setVisible}
      open={visible}
      placement='topRight'
      content={
        <List
          dataSource={[
            {
              action: onViewDetail,
              icon: <EyeOutlined />,
              title: 'Ver detalle',
              key: 'detail',
            },
            existFile
              ? {
                  action: onDownload,
                  icon: <DownloadOutlined />,
                  title: 'Descargar informe',
                  key: 'download',
                }
              : false,
          ].filter(Boolean)}
          renderItem={({ action, icon, key, title }) => (
            <List.Item
              style={{ width: 167, height: 40, paddingLeft: 16 }}
              key={key}
              onClick={action}
            >
              <span style={{ marginRight: 12 }}>{icon}</span>
              {title}
            </List.Item>
          )}
        ></List>
      }
    >
      <Button className='button-more'>
        <MoreOutlined />
      </Button>
    </Popover>
  );
};

const TableInHouse = ({ data, dataResult, setCourseSelected }) => {
  const rows = useMemo(() => {
    return data.reduce(
      (
        total,
        { id, value, requestServiceItemsDataParticipants: participants },
      ) => {
        const courses = value
          ? Object.values(typeof value === 'string' ? JSON.parse(value) : {})
          : [];
        const result = courses.reduce((acc, course) => {
          const founded = dataResult.find(
            (el) => course && el.course === course['course_key'],
          );
          acc.push({
            rowKey: `${id}-${
              course?.visitDate ? course?.visitDate : secureRandom()
            }`,
            id,
            ...(course || {}),
            date: course?.visitDate
              ? moment.unix(course?.visitDate).format('DD-MM-YY HH:mm')
              : 'Sin información',
            filenameUploaded: founded?.filename_uploaded,
            state: founded?.state || course?.state || 'Sin información',
            hours: course?.hours || 'Sin información',
            participants: participants?.length ?? course.participants,
          });

          return acc;
        }, []);

        return total.concat(result);
      },
      [],
    );
  }, [data, dataResult]);

  const _handleViewDetail = useCallback(
    (item) => () => {
      setCourseSelected(item);
    },
    [],
  );

  const _handleDownloadReport = useCallback(
    (item) => () => {
      if (item?.filenameUploaded) {
        const a = document.createElement('a');
        a.href = `${process.env.REACT_APP_WEB_PATH_S3_NEW}${item?.filenameUploaded}`;
        a.download = item?.filenameUploaded;
        a.textContent = 'Download file!';
        a.target = '_blank';
        a.click();
      }
    },
    [],
  );

  const columnsServiceInhouse = useMemo(
    () => [
      {
        title: 'CURSO',
        key: 'course',
        dataIndex: 'course',
      },
      {
        title: 'NÚMERO DE HORAS',
        key: 'hours',
        dataIndex: 'hours',
      },
      {
        title: 'FECHA DE VISITA',
        key: 'date',
        dataIndex: 'date',
      },
      {
        title: 'ACCIONES',
        key: 'actions',
        width: 80,
        render: (_, item) => (
          <PopoverOptions
            existFile={item?.filenameUploaded}
            onViewDetail={_handleViewDetail(item)}
            onDownload={_handleDownloadReport(item)}
          />
        ),
      },
    ],
    [],
  );

  return (
    <TableCommon
      dataSource={rows}
      scroll={{ x: '100%' }}
      columns={columnsServiceInhouse}
    />
  );
};

export default TableInHouse;
