import { ConfigProvider, TimePicker as TimePickerAntd } from 'antd';
import {
  RideGlCalendarSolid,
  RideGlClockSolid,
  RideGlError,
} from '@rimac-seguros/ride-system-components';

import locale from 'antd/locale/es_ES';
import dayjs from 'dayjs';
import 'dayjs/locale/es';

dayjs.locale('es');

const TimePicker = ({
  placeholder,
  id,
  size = 'large',
  name,
  value,
  onChange,
  disabled = false,
}) => (
  <ConfigProvider locale={locale}>
    <div className='timePicker'>
      <TimePickerAntd
        placeholder={placeholder}
        id={id || name}
        size={size}
        name={name}
        value={value}
        onChange={onChange}
        suffixIcon={<RideGlClockSolid size='large' />}
        allowClear={{ clearIcon: <RideGlError size='large' /> }}
        format='HH:mm'
        disabled={disabled}
      />
    </div>
  </ConfigProvider>
);

export default TimePicker;
