import React from 'react';
import { Routes, Route } from 'react-router-dom';

import AssignmentServiceCompany from '../client/Configuration/Services/AssignmentServiceCompany';
import BulkUploadLocations from './Settings2/BulkUploadLocations';
import ClientDetails from '../client/Administration/ClientTracking/ClientDetails';
import ClientTracking from '../client/Administration/ClientTracking';
import CompaniesAdmin from '../client/Administration/Companies';
import Configuration from '../client/Configuration';
import Courses from '../client/ui/Courses';
import Cumulo from '../client/Cumulo';
import Dashboard from './Dashboard';
import DetailNewsManagment from '../client/Newsletters/NewsletterManagment/DetailNewsManagment';
import DetailEventManagment from '../client/Newsletters/EventManagment/DetailEventManagment';
import FormClient from '../client/Administration/ClientTracking/FormClient';
import FormContainer from '../client/Administration/Users/UserList/FormContainer';
import FormBusiness from '../client/Administration/Companies/BusinessList/FormBusiness';
import Google from '../client/News/Google';
import ListSimulations from '../client/Simulations';
import Preview from '../client/Preview';
import RainRisk from '../client/RainRisk';
import RequestServiceForm from '../client/RequestServiceForm';
import RequestServices from '../client/RequestServices';
import RequestServicesItems from '../client/RequestServicesItems';
import RequestServicesPurchaseOrder from '../client/RequestServicesPurchaseOrder';
import Resumen from '../client/Administration/Resumen';
import RiskServices from '../client/ui/RiskService';
import SocialConflicts from '../client/Administration/SocialConflicts';
import TabsConfiguration from './Settings2/TabsConfiguration';
import Tracking from '../client/Administration/Tracking';
import Twitter from '../client/News/Twitter';
import Users from '../client/Administration/Users';
import ValueOffer from '../client/Administration/ValueOffer';

import PermissionRoute from './PermissionRoute';
import NotFound from './NotFound';
import Newsletters from '../client/Newsletters';

const AdminRoutes = () => {
  return (
    <Routes>
      <Route
        path='administracion/perfil-empresa'
        element={
          <PermissionRoute
            element={<Dashboard />}
            permission='access_view_profile_company'
          />
        }
      />
      {/* <Route
        path='documentacion'
        element={
          <PermissionRoute
            element={<Documentation />}
            permission='access_view_documentation'
          />
        }
      /> */}
      <Route
        path='servicio-de-prevencion'
        element={
          <PermissionRoute
            element={<RequestServices />}
            permission={['read_request_services', 'allows_to_manager_accounts']}
          />
        }
      />
      <Route
        path='servicio-de-prevencion/:id/:itemId'
        element={
          <PermissionRoute
            element={<RequestServices />}
            permission={['read_request_services', 'allows_to_manager_accounts']}
          />
        }
      />
      <Route
        path='evaluacion-de-riesgo-por-lluvia'
        element={
          <PermissionRoute
            element={<RainRisk />}
            permission={['view_autoevaluation_rain_risk']}
          />
        }
      />
      <Route
        path='servicio-de-riesgos'
        element={
          <PermissionRoute
            element={<RiskServices />}
            permission={['read_request_services', 'allows_to_manager_accounts']}
          />
        }
      />
      <Route
        path='servicio-de-riesgos/:id/:itemId'
        element={
          <PermissionRoute
            element={<RiskServices />}
            permission={['read_request_services', 'allows_to_manager_accounts']}
          />
        }
      />
      <Route
        path='solicitar-servicio'
        element={
          <PermissionRoute
            element={<RequestServiceForm />}
            permission={['read_request_services', 'allows_to_manager_accounts']}
          />
        }
      />
      <Route
        path='servicio-de-prevencion-solicitud-proveedor'
        element={
          <PermissionRoute
            element={<RequestServicesItems />}
            permission='list_self_providers_request_service'
          />
        }
      />
      <Route
        path='servicio-de-prevencion-preliquidacion'
        element={
          <PermissionRoute
            element={<RequestServicesItems />}
            permission='list_self_providers_request_service'
          />
        }
      />
      <Route
        path='solicitudes-de-orden-de-compra'
        element={
          <PermissionRoute
            element={<RequestServicesPurchaseOrder />}
            permission={'allow_purchase_order'}
          />
        }
      />
      <Route
        path='monitoreo/configuracion'
        element={
          <PermissionRoute
            element={<TabsConfiguration />}
            permission={['access_my_locations']}
          />
        }
      />
      <Route
        path='monitoreo/configuracion/carga-masiva'
        element={
          <PermissionRoute
            element={<BulkUploadLocations />}
            permission={['access_my_locations']}
          />
        }
      />
      <Route
        path='configuracion'
        element={
          <PermissionRoute
            element={<Configuration />}
            permission='access_setting'
          />
        }
      />
      <Route
        path='vista-previa'
        element={
          <PermissionRoute element={<Preview />} permission='access_setting' />
        }
      />
      <Route
        path='asignacion-servicio-empresa'
        element={
          <PermissionRoute
            element={<AssignmentServiceCompany />}
            permission='access_setting'
          />
        }
      />
      <Route
        path='cursos'
        element={
          <PermissionRoute element={<Courses />} permission='access_setting' />
        }
      />
      <Route
        path='simulaciones/cumulo'
        element={
          <PermissionRoute
            element={<Cumulo />}
            permission='access_analysis_zone'
          />
        }
      />
      <Route
        path='simulaciones/lista-simulaciones'
        element={
          <PermissionRoute
            element={<ListSimulations />}
            permission='access_analysis_zone'
          />
        }
      />
      <Route
        path='noticias/google'
        element={
          <PermissionRoute
            element={<Google />}
            permission='access_news_google'
          />
        }
      />
      <Route
        path='noticias/twitter'
        element={
          <PermissionRoute
            element={<Twitter />}
            permission='access_news_twitter'
          />
        }
      />
      <Route
        path='monitoreo/boletines'
        element={
          <PermissionRoute
            element={<Newsletters />}
            permission={['access_newsletters']}
          />
        }
      />
      <Route
        path='monitoreo/boletin/detalle'
        element={
          <PermissionRoute
            element={<DetailNewsManagment />}
            permission='access_newsletters_detail'
          />
        }
      />
      <Route
        path='monitoreo/evento/detalle'
        element={
          <PermissionRoute
            element={<DetailEventManagment />}
            permission='access_event_detail'
          />
        }
      />
      <Route
        path='administracion/resumen'
        element={
          <PermissionRoute
            element={<Resumen />}
            permission='access_view_summary'
          />
        }
      />
      <Route
        path='administracion/empresas'
        element={
          <PermissionRoute
            element={<CompaniesAdmin />}
            permission='list_company'
          />
        }
      />
      <Route
        path='administracion/empresas/formulario-empresa'
        element={
          <PermissionRoute
            element={<FormBusiness />}
            permission='list_company'
          />
        }
      />
      <Route
        path='administracion/empresas/formulario-empresa/:uuid'
        element={
          <PermissionRoute
            element={<FormBusiness />}
            permission='list_company'
          />
        }
      />
      <Route
        path='administracion/usuarios'
        element={
          <PermissionRoute element={<Users />} permission='list_users' />
        }
      />
      <Route
        path='administracion/usuarios/formulario-usuario'
        element={
          <PermissionRoute
            element={<FormContainer />}
            permission='create_users'
          />
        }
      />
      <Route
        path='administracion/usuarios/formulario-usuario/:uuid'
        element={
          <PermissionRoute
            element={<FormContainer />}
            permission='edit_users'
          />
        }
      />
      <Route
        path='administracion/conflictos-sociales'
        element={
          <PermissionRoute
            element={<SocialConflicts />}
            permission='allow_access_social_conflicts'
          />
        }
      />
      <Route
        path='administracion/oferta-de-valor'
        element={
          <PermissionRoute
            element={<ValueOffer />}
            permission={'access_value_offer'}
          />
        }
      />
      <Route
        path='administracion/traking-de-alertas-y-boletines'
        element={
          <PermissionRoute
            element={<Tracking />}
            permission={'allow_access_traking_alerts_newsletters'}
          />
        }
      />
      <Route
        path='administracion/seguimiento-a-clientes'
        element={
          <PermissionRoute
            element={<ClientTracking />}
            permission={'allow_access_client_tracking'}
          />
        }
      />
      <Route
        path='administracion/seguimiento-a-clientes/form'
        element={
          <PermissionRoute
            element={<FormClient />}
            permission={['allow_access_client_tracking']}
          />
        }
      />
      <Route
        path='administracion/seguimiento-a-clientes/:id'
        element={
          <PermissionRoute
            element={<ClientDetails />}
            permission={['allow_access_client_tracking']}
          />
        }
      />
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
};

export default AdminRoutes;
