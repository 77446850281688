import React, { useState, useEffect, useCallback } from 'react';
import { Button, Divider } from 'antd';
import {
  DownloadOutlined,
  PlusOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { useSelector } from 'react-redux';
import InputFile from '../../../../../../common/InputFile';
import ButtonReportCertificates from '../../../../RequestServicesItems/ModalCheckParticipants/ButtonReportCertificates';

const Workshop = ({
  onUpdateForm,
  participants: participantsDefault = [],
  selected,
  courseKey,
  courseSelectedId,
  onGenerateCertificates,
}) => {
  const [participants, setParticipants] = useState([]);
  const { isGeneratingCertificates: isLoading } = useSelector((state) => ({
    isLoading: state.requestServicesItemsDataParticipants,
  }));

  useEffect(() => {
    if (participantsDefault && participantsDefault.length)
      setParticipants(participantsDefault);
  }, [participantsDefault]);

  const onChangeRows = useCallback((participants, action) => {
    if (action === 'save')
      return setParticipants((prev) =>
        prev.map((item) =>
          item.id
            ? participants.id === item.id
              ? { ...item, ...participants }
              : item
            : participants.key === item.key
            ? { ...item, ...participants }
            : item,
        ),
      );
    if (action === 'delete')
      return setParticipants((prev) =>
        prev.filter((r) =>
          r.id ? r.id !== participants.id : r.key !== participants.key,
        ),
      );

    setParticipants(participants);
  }, []);

  const _handleAddParticipants = useCallback(() => {
    setParticipants((prev) =>
      prev.concat({
        document: '',
        email: '',
        key: String(participants.length + 1),
        last_name: '',
        name: '',
        position: '',
      }),
    );
  }, [participants]);

  return (
    <div className='flex flex-col in-house-container mb-2'>
      <Divider className='mt-3 mb-3' />
      <Button
        className='mb-3 button-download'
        href='/templates/PlantillaCapacitacionParticipantes.xlsx'
        target='_blank'
        type='link'
      >
        <DownloadOutlined />
        Descargar modelo de lista de asistencia
      </Button>
      <div className='flex justify-between items-center mb-3'>
        <span className='text-subtitle-bold'>
          Adjunta la lista de participantes de la capacitación (Opcional)
        </span>
        <div className='flex items-center'>
          <Button
            size='large'
            className='button-purple-link mr-3'
            icon={<PlusOutlined />}
            type='link'
            onClick={_handleAddParticipants}
          >
            Agregar participante
          </Button>
          <Button
            type='primary'
            disabled={!participants?.length}
            onClick={() => {
              onUpdateForm(participants);
            }}
          >
            Actualizar participantes
          </Button>
        </div>
      </div>

      <InputFile
        allowClear={true}
        withCertification
        onChangeRows={onChangeRows}
        participants={participants}
        processExcelDefault
      />
      {selected.state > 5 ? (
        <div
          className='mt-4'
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          {participantsDefault &&
          participantsDefault.length &&
          participantsDefault.some((el) => !el.certificate) ? (
            <Button
              className='mr-3'
              icon={isLoading ? <SyncOutlined spin /> : <SyncOutlined />}
              type='ghost'
              size='large'
              disabled={isLoading}
              onClick={onGenerateCertificates}
            >
              Genera certificados
            </Button>
          ) : null}
          <ButtonReportCertificates
            courseKey={courseKey}
            requestServiceItemDataId={courseSelectedId}
          />
        </div>
      ) : null}
    </div>
  );
};

export default Workshop;
