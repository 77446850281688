import L from 'leaflet';
import cloneDeep from 'lodash.clonedeep';
import moment from 'moment';

const DATE_FORMAT = 'DD/MM hh:mm A';

export const generateTicks = (times, unitTime, format, addValue) => {
  const ticks = {};
  for (let i = 1; i <= times; i += 1) {
    ticks[i] = moment()
      .add(i * addValue, unitTime || 'hour')
      .format(format || DATE_FORMAT);
  }
  return ticks;
};

export const generateMarks = (times, unitTime, format, addValue, step) => {
  const marks = {};
  for (let i = 0; i <= times; i += step) {
    marks[i] = moment()
      .add(i * addValue, unitTime || 'hour')
      .format(format || DATE_FORMAT);
  }
  return marks;
};

export const generateRealtimeTicks = (times, unitTime, format, interval) => {
  const ticks = {};
  for (let i = 1; i <= times; i += 1) {
    ticks[i] = moment()
      .add((times - i) * interval * -1, unitTime || 'hour')
      .format(format || DATE_FORMAT);
  }
  return ticks;
};

export const generateRealtimeMarks = (
  times,
  unitTime,
  format,
  interval,
  separation,
) => {
  const marks = {};
  for (let i = 0; i <= times; i += separation) {
    if (i === 0) {
      continue;
    }
    marks[i] = moment()
      .add((times - i) * interval * -1, unitTime || 'hour')
      .format(format || DATE_FORMAT);
  }
  return marks;
};

export const getSubLayerOptions = (activeSubLayer, opacity, defaultScene) => {
  return {
    layers: getLayers(activeSubLayer, defaultScene),
    tileSize: 1024,
    format: 'image/png',
    tiled: true,
    transparent: true,
    version: '1.3.0',
    dateTime: moment().get('hour'),
    opacity,
  };
};

export const getSubLayerParams = (activeSubLayer, defaultScene) => {
  return {
    layers: getLayers(activeSubLayer, defaultScene),
  };
};

export const getLayers = (activeSubLayer, defaultScene) => {
  return `cmo:${activeSubLayer.layersId}_${
    activeSubLayer?.animate?.realtime ? '1' : defaultScene || '1'
  }`;
};

export const getSubLayerRegionsOptions = (activeSubLayer) => {
  return {
    layers: getSubLayerRegionsLayers(activeSubLayer),
    tileSize: 1024,
    format: 'image/png',
    tiled: true,
    transparent: true,
    mode: activeSubLayer.id,
    version: '1.3.0',
    dateTime: moment().get('hour'),
    zIndex: 11,
  };
};

export const getSubLayerRegionsParams = (activeSubLayer) => {
  return {
    layers: getSubLayerRegionsLayers(activeSubLayer),
  };
};

export const getSubLayerRegionsParamsFallback = (activeSubLayer) => {
  return {
    layers: getSubLayerRegionsLayersFallback(activeSubLayer),
  };
};

export const getSubLayerRegionsLayers = (activeSubLayer) => {
  return activeSubLayer.styles === 'dark'
    ? 'cmo:peru_america_sur_dark'
    : 'cmo:peru_america_sur';
};

export const getSubLayerRegionsLayersFallback = (activeSubLayer) =>
  activeSubLayer.styles === 'dark' ? 'cmo:region_dark' : 'cmo:region';

export const removeLayers = (map, scenesLayersRef) => {
  if (Object.keys(scenesLayersRef.current).length > 0) {
    Object.values(scenesLayersRef.current).forEach((l) => {
      map.removeLayer(l.layer);
      l.layer.removeFrom(map);
    });
  }
};

export const updateOpacity = (scenesLayers, opacity, setScenesLayers) => {
  if (Object.keys(scenesLayers).length > 0) {
    setScenesLayers((prevScenesLayers) => {
      const scenesLayersCopy = cloneDeep(prevScenesLayers);
      Object.values(scenesLayersCopy).forEach((item) => {
        if (item.layer.options.opacity !== opacity) {
          item.layer.options.opacity = opacity;
          item.layer.redraw();
        }
      });

      return scenesLayersCopy;
    });
  }
};

export const loadLayer = (otions) => {
  const {
    url,
    layersId,
    sceneIndex,
    setScenesLayersLoaded,
    visible,
    opacity,
    activeSubLayer,
    map,
  } = otions;
  const item = {
    state: 'loading',
    layer: L.tileLayer.wms(url, {
      layers: `cmo:${layersId}_${
        activeSubLayer?.animate?.realtime ? sceneIndex + 1 : sceneIndex
      }`,
      tileSize: 1024,
      format: 'image/png',
      tiled: true,
      transparent: true,
      version: '1.3.0',
      dateTime: moment().get('hour'),
      opacity,
    }),
  };
  item.layer.options.opacity = opacity;
  item.layer.on('load', function () {
    setScenesLayersLoaded((prev) => ({
      ...prev,
      [sceneIndex]: true,
    }));
  });
  item.layer.on('loading', function () {
    setScenesLayersLoaded((prev) => ({
      ...prev,
      [sceneIndex]: false,
    }));
  });
  map.addLayer(item.layer);
  if (!visible) {
    item.layer.getContainer().style.visibility = 'hidden';
  }

  return item;
};

export const cleanupLayers = (map, subLayerObj, subLayerRegionsObj) => {
  if (subLayerObj.current) {
    map.removeLayer(subLayerObj.current);
    subLayerObj.current = null;
  }
  if (subLayerRegionsObj.current) {
    map.removeLayer(subLayerRegionsObj.current);
    subLayerRegionsObj.current = null;
  }
};
