import { get, post, put } from '../lib/ajax';
import {
  LOADING_COURSES,
  LOADED_COURSES,
  LOADING_CREATE_COURSE,
  LOADED_COURSES_PAGINATE,
} from '../redux/actions/actionsType';

export const getAllCourses = async (dispatch) => {
  dispatch({ type: LOADING_COURSES, payload: true });
  const r = await get('/courses/listAll');
  if (r.data.success) {
    dispatch({ type: LOADED_COURSES, payload: r.data.payload });
  }
};

export const getCourses = async (dispatch, data = {}) => {
  dispatch({ type: LOADING_COURSES, payload: true });

  const r = await post('/courses/list', data);
  if (r.data.success) {
    dispatch({ type: LOADED_COURSES_PAGINATE, payload: r.data.payload });

    return true;
  }

  return false;
};

export const createCoursePaginated = async (dispatch, data = {}) => {
  dispatch({ type: LOADING_CREATE_COURSE, payload: true });
  const r = await post('/courses/create', data);

  if (r.data.success) {
    await getCourses(dispatch);

    return true;
  }

  return false;
};

export const updateCoursePaginated = async (
  dispatch,
  data = {},
  pagination = {},
) => {
  dispatch({ type: LOADING_CREATE_COURSE, payload: true });
  const r = await put('/courses/update', data);

  if (r.data.success) {
    await getCourses(dispatch, {
      page: pagination.current,
      limit: pagination.pageSize,
      query: pagination.query,
    });

    return true;
  }

  return false;
};
