import React, { memo, useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Button, List, notification, Popover, Skeleton } from 'antd';
import {
  EllipsisOutlined,
  HddOutlined,
  EditOutlined,
  DeleteOutlined,
} from '@ant-design/icons';

import {
  createService,
  getService,
  removeService,
  updateService,
} from '../../../../services/service';
import { filterByString } from '../../../../lib/utils';

import TableMessage from '../../../../common/Table/TableMessage';
import FilterSection from '../../ui/PreventionService/components/FilterSection';
import TableCommon from '../../../../common/Table';
import {
  CardSkeleton,
  iconLeft,
  iconRight,
} from '../../ui/PreventionService/Reports/utils';
import ModalConfirm from '../../../../common/ModalConfirm';
import CardItemMobile from '../common/CardItemMobile';
import MainTitle from '../common/MainTitle';

import MoreHorizIcon from '../../../../images/more_horiz_svg.svg';
import DeleteModalImage from '../../../../images/delete_modal_image.svg';
import { typeServices } from '../common/FormConfiguration/constants';

const columnsFixed = [
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
    width: 250,
  },
  {
    title: 'Tipo',
    dataIndex: 'type',
    key: 'type',
    render: (type) => typeServices.find((el) => el.value === type)?.name,
  },
  {
    title: 'Fijo',
    dataIndex: 'fixed',
    key: 'fixed',
    render: (bool) => (bool ? 'Si' : 'No'),
  },
  {
    title: 'Costo rimac',
    dataIndex: 'rimac_cost',
    key: 'rimac_cost',
    width: '120px',
  },
  {
    title: 'Costo mercado',
    dataIndex: 'market_cost',
    key: 'market_cost',
    width: '120px',
  },
  {
    title: 'Beneficio principal',
    dataIndex: 'core_benefit',
    key: 'core_benefit',
    width: '120px',
  },
];

const notificationError = () =>
  notification.warning({
    message: 'Ha ocurrido un error.',
  });

const notificationSuccess = (params) =>
  notification.success({
    ...params,
  });

const Services = ({
  onOpenForm,
  renderItem,
  queryAction,
  onModalSuccess,
  onSetAction,
  isMobileScreen,
  isBeforeTable,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { listWithFixed = [], isLoadingList } = useSelector(
    (state) => state.service,
  );
  const [listfiltered, setListFiltered] = useState([]);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [pagination, setPagination] = useState(() => ({
    pageSize: 5,
    total: 0,
  }));

  useEffect(() => {
    getService(dispatch);
  }, []);

  useEffect(() => {
    if (listWithFixed.length) {
      setPagination((prev) => ({ ...prev, total: listWithFixed.length }));
      setListFiltered(listWithFixed);
    }
  }, [listWithFixed]);

  const onCreateService = useCallback(
    async (service) => {
      const created = await createService(service, dispatch);
      const successInfo = {
        description: 'Se creo el servicio correctamente.',
        message: '¡Servicio creado!',
      };

      if (created)
        isBeforeTable
          ? onModalSuccess(successInfo)
          : notificationSuccess(successInfo);
      else notificationError();

      onSetAction(false);
    },
    [isBeforeTable],
  );

  const onUpdateService = useCallback(
    async (service) => {
      const update = await updateService(service, dispatch);
      const successInfo = {
        description: 'Se guardaron los cambios correctamente.',
        message: '¡Cambios guardados!',
      };

      if (update)
        isBeforeTable
          ? onModalSuccess(successInfo)
          : notificationSuccess(successInfo);
      else notificationError();
    },
    [isBeforeTable],
  );

  const onRemoveService = useCallback(
    async (id) => {
      const removed = removeService(id, dispatch);
      const successInfo = {
        description: 'Se eliminó el servicio correctamente.',
        message: '¡Servicio eliminado!',
      };
      if (removed)
        isBeforeTable
          ? onModalSuccess(successInfo)
          : notificationSuccess(successInfo);
      else notificationError();
    },
    [isBeforeTable],
  );

  const _handleFindServices = useCallback(
    (e) => {
      const txt = e.target.value;
      if (txt.length >= 2) {
        const result = filterByString(listWithFixed, txt);
        setPagination((prev) => ({ ...prev, total: result.length }));
        setListFiltered(result);
      }
      if (txt.length === 0) {
        setPagination((prev) => ({ ...prev, total: listWithFixed.length }));
        setListFiltered(listWithFixed);
      }
    },
    [listWithFixed],
  );

  const _handleAction = useCallback(
    (action, item) => () => {
      if (action === 'remove') {
        setItemToDelete(null);
        onRemoveService(item);
      } else {
        onOpenForm({
          action,
          ...item,
          hasFullImage: true,
          titleSection:
            action === 'create' ? 'Crear Servicio' : 'Editar Servicio',
          onAction: async (data, onFinish = () => {}) => {
            await (action === 'create'
              ? onCreateService(data)
              : onUpdateService(data));
            onFinish();
            onOpenForm(null);
          },
        });
      }
    },
    [onCreateService, onUpdateService, onOpenForm, onRemoveService],
  );

  useEffect(() => {
    if (queryAction) _handleAction('create', renderItem)();
  }, [queryAction]);

  const columns = columnsFixed.concat({
    title: 'Acciones',
    dataIndex: 'actions',
    fixed: 'right',
    key: 'actions',
    width: 80,
    render: (__, item) => (
      <Popover
        className='popover'
        placement='topRight'
        content={
          <List
            dataSource={[
              {
                action: () =>
                  navigate(`/asignacion-servicio-empresa?service=${item.id}`),
                icon: <HddOutlined />,
                title: 'Asignar Empresa',
                key: 'assignCompanies',
              },
              {
                action: _handleAction('edit', item),
                icon: <EditOutlined />,
                title: 'Editar',
                key: 'edit',
              },
              {
                action: () => {
                  setItemToDelete(item.id);
                },
                icon: <DeleteOutlined />,
                title: 'Eliminar',
                key: 'delete',
              },
            ]}
            renderItem={({ action, icon, key, title }) => (
              <List.Item
                style={{ width: 167, height: 40, paddingLeft: 16 }}
                className='list-item-custom'
                key={key}
                onClick={action}
              >
                {React.cloneElement(icon, { style: { marginRight: 12 } })}
                {title}
              </List.Item>
            )}
          ></List>
        }
      >
        <Button>
          <EllipsisOutlined />
        </Button>
      </Popover>
    ),
  });

  return (
    <>
      <MainTitle
        buttonAction={_handleAction('create', renderItem)}
        renderItem={renderItem}
        isMobileScreen={isMobileScreen}
      />
      <FilterSection
        complete={true}
        text='Filtrar tipos de servicios'
        handleFilter={_handleFindServices}
      />
      {!pagination.total && !isLoadingList ? (
        <TableMessage complete={true} message='No se encontrarón polizas' />
      ) : !isMobileScreen ? (
        <section className='show-data'>
          {isLoadingList ? (
            <Skeleton active title={false} paragraph={{ rows: 1 }} />
          ) : (
            <p className='info-total-data'>
              Se encontraron <span>{pagination.total}</span>{' '}
              {pagination.total === 1 ? 'servicio' : 'servicios'}
            </p>
          )}

          <TableCommon
            dataSource={listfiltered}
            columns={columns}
            loading={isLoadingList}
            locale={{ emptyText: 'No se encontraron polizas.' }}
            pagination={pagination}
          />
        </section>
      ) : (
        <section className='content-mobile action-bottom'>
          {isLoadingList ? (
            <Skeleton active title={false} paragraph={{ rows: 1 }} />
          ) : (
            <p className='info-total-data'>
              Se encontraron <span>{pagination.total}</span>{' '}
              {pagination.total === 1 ? 'póliza' : 'pólizas'}
            </p>
          )}

          {isLoadingList ? (
            [1, 2, 3, 4].map((el) => <CardSkeleton key={el} />)
          ) : listfiltered.length === 0 ? (
            <TableMessage
              complete={true}
              message='No se encontrarón servicios'
            />
          ) : (
            listfiltered.map((data) => (
              <CardItemMobile
                key={data.id}
                data={data}
                columns={columnsFixed}
                onEdit={_handleAction('edit', data)}
                onDelete={() => {
                  setItemToDelete(data.id);
                }}
              />
            ))
          )}

          <div className='d_content__list__pag'>
            <div className={'d_content__list__pag__button'}>
              {iconLeft({
                color: pagination.current === 1 ? '#A9AFD9' : '#4F4FFF',
              })}
            </div>

            <div className='d_content__list__pag__number color-red-1'>
              {pagination.current}
            </div>
            <div className='d_content__list__pag__sep'>/</div>
            <div className='d_content__list__pag__number color-gray-3'>
              {Math.ceil(pagination.total / pagination.pageSize)}
            </div>

            <div className={'d_content__list__pag__button'}>
              {iconRight({
                color:
                  pagination.current ===
                  Math.ceil(pagination.total / pagination.pageSize)
                    ? '#A9AFD9'
                    : '#4F4FFF',
              })}
            </div>
          </div>
        </section>
      )}

      <ModalConfirm
        visible={Boolean(itemToDelete)}
        onClose={() => setItemToDelete(null)}
        title={'¿Seguro que desea eliminar el servicio de la lista ?'}
        image={DeleteModalImage}
        actionsContent={
          <div className='actions-delete-modal mt-2'>
            <Button
              className='button secondary-button mr-2'
              onClick={() => setItemToDelete(null)}
            >
              Cancelar
            </Button>

            <Button
              className='button ml-1'
              type='primary'
              onClick={_handleAction('remove', itemToDelete)}
            >
              Eliminar
            </Button>
          </div>
        }
      />
    </>
  );
};

export default memo(Services);
