import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { Button, notification, Popover, Skeleton } from 'antd';

import useDebounce from '../../../../hooks/useDebounce';
import { useScreen } from '../../../../lib/screen';
import { getEventType } from '../../../../services/eventType';
import { getEvents } from '../../../../services/events';
import { getRols } from '../../../../services/rols';
import {
  CardSkeleton,
  IconDocumentOutline,
  IconDots,
  IconEditTable,
  iconLeft,
  iconRight,
} from '../../ui/PreventionService/Reports/utils';

import Table from '../../../../common/Table';
import TableMessage from '../../../../common/Table/TableMessage';
import Card from './Card';
import DrawerFilters from './DrawerFilters';
import FilterSection from './FilterSection';

const EvenManagment = ({
  load,
  loadRols,
  pagination,
  isLoading,
  list,
  user,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { widthScreen } = useScreen();
  const [rows, setRows] = useState([]);
  const [textSearch, setTextSearch] = useState('');
  const textSearchDebounced = useDebounce(textSearch);
  const [filters, setFilters] = useState({});
  const [drawerFilterVisible, setDrawerFilterVisible] = useState(false);

  const handleToggleFiltersDrawer = useCallback(() => {
    setDrawerFilterVisible((prev) => !prev);
  }, []);

  const columns = [
    {
      title: 'Tipo',
      dataIndex: 'eventType',
      key: 'eventType',
      width: '160px',
      render: (type) => type?.name,
    },
    {
      title: 'Fecha de evento',
      dataIndex: 'date_event_start',
      key: 'date_event_start',
      width: '180px',
      render: (__, event) =>
        event?.date_event_end
          ? moment(event?.date_event_start).format('DD/MM/YYYY - HH:mm') +
            `${
              event.date_event_end
                ? ` a ${moment(event?.date_event_end).format(
                    'DD/MM/YYYY - HH:mm',
                  )}`
                : ''
            }`
          : moment(event?.date_event_start).format('DD MMM YYYY - HH:mm'),
    },
    {
      title: 'Condición detectada',
      dataIndex: 'detected_condition',
      key: 'detected_condition',
      width: '180px',
      render: (text, record) => {
        const detectedConditionRed = record?.detected_condition_red ?? '';

        return `${text} ${detectedConditionRed}`;
      },
    },
    {
      title: 'Provincias afectadas',
      dataIndex: 'provinces',
      key: 'provinces',
      width: '220px',
      render: (provinces) => (
        <div className='flex pos-end'>
          <span className='table-state' style={{ background: '#FCDA6A' }}>
            <div className='point' style={{ background: '#FC9700' }}></div>
            <span>
              {provinces.length}{' '}
              {provinces.length === 1
                ? 'PROVINCIA AFECTADA'
                : 'PROVINCIAS AFECTADAS'}
            </span>
          </span>
        </div>
      ),
    },
    {
      title: 'Acciones',
      key: 'actions',
      width: 80,
      fixed: 'right',
      render: (__, record) =>
        rows.length > 0 ? (
          <Popover
            placement='topRight'
            content={<ActionsContent record={record} />}
            className='popover-actions'
          >
            <Button className='button-popover'>
              <IconDots />
            </Button>
          </Popover>
        ) : null,
    },
  ];

  const ActionsContent = ({ record }) => (
    <div className='buttons-popover'>
      <Button onClick={() => handleEdit(record)}>
        <IconEditTable /> <span>Editar evento</span>
      </Button>

      <Button onClick={() => handleCreateNewsletter(record)}>
        <IconDocumentOutline /> <span>Crear boletín</span>
      </Button>
    </div>
  );

  const handleEdit = async (event) => {
    if (!event)
      notification.warning({
        description: 'No se encontró el evento.',
        message: '¡Gestión de eventos!',
      });

    localStorage.setItem('event-selected', JSON.stringify(event));
    navigate('/monitoreo/evento/detalle');
  };

  const handleCreateNewsletter = async (event) => {
    localStorage.setItem('event-selected', JSON.stringify(event));
    navigate('/monitoreo/boletin/detalle');
  };

  const handleFilter = (key, value) => {
    if (key === 'date' && value && value.length > 0) {
      const dateInitial = moment(value[0]).format('YYYY-MM-DDT00:00:00-05:00');

      const dateFinal = moment(value[1]).format('YYYY-MM-DDT23:59:59-05:00');

      setFilters((prev) => ({
        ...prev,
        date_event_end: dateFinal,
        date_event_start: dateInitial,
      }));
      load({
        page: 1,
        query: textSearch,
        limit: pagination.pageSize,
        filter: {
          ...filters,
          date_event_end: dateFinal,
          date_event_start: dateInitial,
        },
      });
    } else {
      setFilters((prev) => ({ ...prev, [key]: value }));
      load({
        page: 1,
        query: textSearch,
        limit: pagination.pageSize,
        filter: { ...filters, [key]: value },
      });
    }
  };

  const handleSearchFilter = (e) => setTextSearch(e.target.value.trimStart());

  const handleClearFilters = useCallback(() => {
    setFilters({});
    setTextSearch('');
    load({
      query: '',
      page: 1,
      limit: pagination.pageSize,
      filter: {},
    });
  }, [load, pagination.pageSize]);

  const handleTableChange = (pag) =>
    load({
      page: pag.current,
      limit: pagination.pageSize,
      query: textSearch,
      filter: filters,
    });

  const handleTableChangeMobile = (current) => {
    if (
      current === 0 ||
      Math.ceil(pagination.total / pagination.pageSize) + 1 === current
    )
      return;

    load({
      page: current,
      limit: pagination.pageSize,
    });
  };

  useEffect(() => {
    setRows(list);
  }, [list]);

  useEffect(() => {
    load({
      query: textSearchDebounced.length >= 2 ? textSearchDebounced : '',
      page: 1,
      limit: pagination.pageSize,
      filter: Object.entries(filters).length === 0 ? {} : filters,
    });
  }, [textSearchDebounced]);

  useEffect(() => {
    loadRols(user);
    getEventType(dispatch);
  }, []);

  return (
    <>
      <FilterSection
        complete={true}
        text={'Filtrar eventos'}
        textSearch={textSearch}
        handleFilter={handleFilter}
        handleSearchFilter={handleSearchFilter}
        handleClearFilters={handleClearFilters}
        filters={filters}
        total={pagination?.total}
        handleToggleFiltersDrawer={handleToggleFiltersDrawer}
      />
      {widthScreen > 767 ? (
        !isLoading && list.length === 0 ? (
          <TableMessage complete={true} message='No se encontrarón eventos' />
        ) : (
          <section className='show-data'>
            {isLoading ? (
              <Skeleton active title={false} paragraph={{ rows: 1 }} />
            ) : (
              <p className='info-total-data'>
                Se encontraron <span>{pagination.total}</span>{' '}
                {pagination.total === 1 ? 'evento' : 'eventos'}
              </p>
            )}

            <Table
              dataSource={rows}
              columns={columns}
              rowKey={(record) => record.id}
              loading={isLoading}
              locale={{ emptyText: 'No se encontraron boletines.' }}
              pagination={pagination}
              onChangeRows={setRows}
              onChange={handleTableChange}
            />
          </section>
        )
      ) : (
        <section className='content-mobile'>
          {isLoading ? (
            [1, 2, 3, 4].map((el) => <CardSkeleton key={el} />)
          ) : rows.length === 0 ? (
            <TableMessage
              complete={true}
              message='No se encontrarón boletines'
            />
          ) : (
            rows.map((data) => (
              <Card
                key={data.id}
                data={data}
                handleEdit={handleEdit}
                handleCreate={handleCreateNewsletter}
              />
            ))
          )}

          <div className='d_content__list__pag'>
            <div
              className={'d_content__list__pag__button'}
              onClick={() => handleTableChangeMobile(pagination.current - 1)}
            >
              {iconLeft({
                color: pagination.current === 1 ? '#A9AFD9' : '#4F4FFF',
              })}
            </div>

            <div className='d_content__list__pag__number color-red-1'>
              {pagination.current}
            </div>
            <div className='d_content__list__pag__sep'>/</div>
            <div className='d_content__list__pag__number color-gray-3'>
              {Math.ceil(pagination.total / pagination.pageSize)}
            </div>

            <div
              className={'d_content__list__pag__button'}
              onClick={() => handleTableChangeMobile(pagination.current + 1)}
            >
              {iconRight({
                color:
                  pagination.current ===
                  Math.ceil(pagination.total / pagination.pageSize)
                    ? '#A9AFD9'
                    : '#4F4FFF',
              })}
            </div>
          </div>
        </section>
      )}
      <DrawerFilters
        key='drawer-filter-event'
        visible={drawerFilterVisible}
        onClose={handleToggleFiltersDrawer}
        handleClearFilters={handleClearFilters}
        handleFilter={handleFilter}
        filters={filters}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  load: (pagination) => getEvents(dispatch, pagination),
  loadRols: (user) => getRols(dispatch, user),
});

const mapStateToProps = (state) => ({
  list: state.events.events,
  isLoading: state.events.isLoading,
  pagination: state.events.pagination,
  user: state.auth.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(EvenManagment);
