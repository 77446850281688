import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

import { getInfoReportOperativeAllPolizas } from '../../../../../../../../services/dashboard';
import { MainSkeleton } from '../../utils/Loadings';

const Main = () => {
  const [loading, setLoading] = useState(true);
  const [polizasInfo, setPolizasInfo] = useState([]);
  const [companyDetails, setCompanyDetails] = useState({});

  useEffect(() => {
    setLoading(true);
    getInfoReportOperativeAllPolizas()
      .then((r) => {
        if (r) {
          setPolizasInfo(r.list);
          setCompanyDetails(r.companyDetails);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      {!loading ? (
        <>
          <div className='flex justify-between items-center main-header'>
            <h3 className='main-header__name'>{companyDetails?.social}</h3>
            <span className='main-header__ruc'>RUC: {companyDetails?.ruc}</span>
          </div>
          <div className='main-table'>
            <div className='main-table-head'>
              <div className='main-table-head__item'>
                <span>Gestor de cuenta: </span>
                <span>{companyDetails?.manager ?? '-'}</span>
              </div>
              <div className='main-table-head__item'>
                <span>Contacto de gestor: </span>
                <span>{companyDetails?.managerContactPhone || '-'}</span>
              </div>
              <div className='main-table-head__item'>
                <span>Correo de gestor: </span>
                <span>{companyDetails?.managerContactEmail || '-'}</span>
              </div>
            </div>
            <div className='main-table-content'>
              <table>
                <thead>
                  <tr>
                    <th>póliza</th>
                    <th>vigencia de la oferta</th>
                    <th>valor de mercado servicio de prevención</th>
                    <th>utilizado</th>
                    <th>no utilizado</th>
                  </tr>
                </thead>
                <tbody>
                  {polizasInfo
                    ? polizasInfo?.map(
                        ({
                          id,
                          typePoliza,
                          totalOVMarket,
                          totalOVMarketExecuted,
                          endDate,
                          startDate,
                        }) => (
                          <tr key={id}>
                            <td>{typePoliza}</td>
                            <td>
                              {dayjs(startDate).format('DD MMM YYYY')} -{' '}
                              {dayjs(endDate).format('DD MMM YYYY')}
                            </td>
                            <td>USD {totalOVMarket}</td>
                            <td>USD {totalOVMarketExecuted}</td>
                            <td>
                              USD{' '}
                              {Number(totalOVMarket) -
                                Number(totalOVMarketExecuted)}
                            </td>
                          </tr>
                        ),
                      )
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <MainSkeleton />
      )}
    </>
  );
};

export default Main;
