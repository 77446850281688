import React from 'react';

const InformCardHome = ({ title, href, tag }) => {
  return (
    <div className='inform-card-home'>
      <div className='inform-card-home__text-container'>
        <div className='inform-card-home__info'>{tag}</div>
        <div className='inform-card-home__title'>{title}</div>
        <a className='inform-card-home__link seeMore' href={href}>
          Leer Informe
        </a>
      </div>
      <div className='inform-card-home__img-container'>
        <img
          className='inform-card-home__img'
          src='/images/portal/icon_inform.svg'
          alt=''
          aria-hidden
        />
      </div>
    </div>
  );
};

export default InformCardHome;
