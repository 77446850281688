import React from 'react';

const SkeletonLayerList = ({ items = 5 }) => {
  const rows = Array.from({ length: items });

  return (
    <>
      {rows.map((_, idx, arr) => (
        <div
          key={idx}
          className={'drawer-map-layers__list__item skeleton'}
          style={{
            height: '68px',
            animationDelay: `${Math.round((1000 / arr.length) * idx)}ms`,
          }}
        />
      ))}
    </>
  );
};

export default SkeletonLayerList;
