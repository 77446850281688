import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { Button, Radio } from 'antd';

import InputWithLabel from '../../../../common/InputWithLabel';
import Title from '../../../../common/Typograph/Title';

import { getService } from '../../../../services/service';

import { IconPlus } from '../../ui/PreventionService/Reports/utils';
import ServiceInput from './ServiceInput';

const LevelCompanyForm = ({ onChange, ...props }) => {
  const dispatch = useDispatch();
  const { formProps } = props;
  const [loadingServices, setLoadingServices] = useState(false);
  const [services, setServices] = useState([]);
  const [funds, setFunds] = useState(formProps?.budget || 0);
  const [totalCost, setTotalCost] = useState(0);
  const [totalUsed, setTotalUsed] = useState(0);
  const [data, setData] = useState(() => {
    // onChange({target:{value: formProps?.type !== 2 ? 1 : 2, name: 'type'}})
    return {
      ...formProps,
      type: formProps?.type !== 2 ? 1 : 2,
      major: formProps?.major || null,
      minor: formProps?.minor || null,
      budget: formProps?.budget || null,
      levelCompanyServices:
        formProps?.levelCompanyServices &&
        formProps?.levelCompanyServices?.length > 0
          ? formProps?.levelCompanyServices.map((el) => ({
              ...el,
              key: uuidv4(),
            }))
          : [],
    };
  });

  const handleFundsPercent = async () => {
    if (data?.type === 2) {
      onChange({ target: { value: data?.budget || 0, name: 'budget' } });
      const listServices =
        services && services.length > 0 ? services : await getService(dispatch);

      const values = data?.levelCompanyServices?.map((el) => {
        const service = listServices.find(
          (service) => service.id === el.serviceId,
        );

        return service?.rimac_cost * el.count || 0;
      });

      const sumValues = values.reduce((acc, curr) => acc + curr, 0);
      setTotalCost(sumValues || 0);
      onChange({ target: { value: sumValues || 0, name: 'totalCost' } });
      const cost = sumValues / funds;
      const percent =
        cost > 0 && cost !== Number.POSITIVE_INFINITY
          ? (cost * 100).toFixed(2)
          : 0;
      setTotalUsed(percent);
    }
  };

  const handleRadioChange = (e) => {
    setData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    onChange({ target: { value: e.target.value, name: 'type' } });
  };

  const handleInputChange = (e) => {
    e.persist();
    setData((prev) => ({ ...prev, [e?.target?.name]: e?.target?.value }));
    onChange({ target: { value: e?.target?.value, name: e?.target?.name } });
  };

  const handleAddService = () => {
    const newService = {
      key: uuidv4(),
      serviceId: null,
      count: 1,
    };

    setData((prev) => ({
      ...prev,
      levelCompanyServices: [...prev.levelCompanyServices, newService],
    }));
    handleFundsPercent();
    onChange({
      target: {
        value: [...data.levelCompanyServices, newService],
        name: 'levelCompanyServices',
      },
    });
  };

  const handleRemoveService = (index) => {
    setData((prev) => ({
      ...prev,
      levelCompanyServices: prev.levelCompanyServices.filter(
        (el) => el.key !== index,
      ),
    }));
    onChange({
      target: {
        value: data.levelCompanyServices.filter((el) => el.key !== index),
        name: 'levelCompanyServices',
      },
    });
    handleFundsPercent();
  };

  useEffect(() => {
    setLoadingServices(true);
    getService(dispatch).then((res) => {
      setServices(res);
      setLoadingServices(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleFundsPercent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.type, JSON.stringify(data?.levelCompanyServices)]);

  return (
    <>
      <div className='pt-2 mb-2 full-width'>
        <Title className={'mb-3 mt-3'} type={'bold-18'}>
          Tipo
        </Title>

        <Radio.Group
          className='flex flex-col'
          name='type'
          value={data?.type}
          onChange={handleRadioChange}
        >
          <Radio value={1}>
            <span className='text-base'>Estandar</span>
          </Radio>
          <Radio style={{ marginTop: 16 }} value={2}>
            <span className='text-base'>Personalizado</span>
          </Radio>
        </Radio.Group>
      </div>

      <div className='pt-2 mb-2 full-width'>
        <Title className={'mb-2 mt-3'} type={'bold-18'}>
          {data?.type === 1 ? 'Rango' : 'Presupuesto'}
        </Title>

        {data?.type === 1 ? (
          <div className='flex'>
            <InputWithLabel
              type='number'
              className={'mr-2'}
              style={{ maxWidth: 216 }}
              name='minor'
              label={'Rango menor'}
              value={data?.minor}
              onChange={(e) => handleInputChange(e)}
            />

            <InputWithLabel
              type='number'
              name='major'
              label={'Rango mayor'}
              style={{ maxWidth: 216 }}
              value={data?.major}
              onChange={(e) => handleInputChange(e)}
            />
          </div>
        ) : (
          <div className='flex items-center'>
            <InputWithLabel
              type='number'
              name='budget'
              label={'Escribe un presupuesto'}
              value={data?.budget}
              onChange={(e) => {
                handleInputChange(e);
                setFunds(e?.target?.value);
                handleFundsPercent();
              }}
            />

            <div className='flex items-center ml-2'>
              <span className='text-bold text-base'>
                Coste total: ${totalCost}
              </span>
              <span className='text-bold text-base ml-2'>
                Consumido: {totalUsed}%
              </span>
            </div>
          </div>
        )}
      </div>

      <div className='pt-2 mb-3 full-width'>
        <div className='justify-between mb-3'>
          <Title type={'bold-18'}>Servicios</Title>

          <Button
            type='link'
            size='small'
            className='flex align-items'
            onClick={handleAddService}
          >
            <IconPlus className='mr-1' /> Agregar servicio
          </Button>
        </div>

        {!loadingServices ? (
          data?.levelCompanyServices.map((el) => (
            <ServiceInput
              key={el.key}
              companyLevelType={data?.type}
              levelCompanyService={
                el ? el : { serviceId: null, count: null, key: uuidv4() }
              }
              services={services}
              onChange={onChange}
              setData={setData}
              loadingServices={loadingServices}
              data={data}
              removeService={handleRemoveService}
              handleFundsPercent={handleFundsPercent}
            />
          ))
        ) : (
          <p>Cargando...</p>
        )}
      </div>
    </>
  );
};

export default LevelCompanyForm;
