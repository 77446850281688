import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';
import ControlLayer from './ControlLayer';

import { secureRandom } from '../../../../lib/utils';
import {
  removeLayers,
  createSubLayer,
  updateSubLayer,
  createSubLayerRegions,
  updateSubLayerRegions,
  removeSubLayerRegions,
  loadLayer,
  loadedAllScenes,
  handleSceneBarChange,
  handleSceneLoading,
} from './utils';
import {
  onPlayAction,
  changeMoveOfMap,
  onChangeBar,
  onAfterChangeBar,
  handleSelectHour,
} from './eventHandlers';

import { useGenerateMarksTimes } from './hooks/useGenerateMarksTimes';
import useZoomEvents from './hooks/useZoomEvents';

const WMS = () => {
  const activeSubLayer = useSelector((state) => state.maps.activeSubLayer);

  const subLayerObj = useRef();
  const subLayerRegionsObj = useRef();

  const { map } = useZoomEvents(activeSubLayer, subLayerRegionsObj);

  useEffect(() => {
    return () => {
      map.removeLayer(subLayerObj.current);
      subLayerObj.current = null;
      map.removeLayer(subLayerRegionsObj.current);
      subLayerRegionsObj.current = null;

      Object.values(scenesLayers).forEach((item) => {
        map.removeLayer(item);
      });
    };
  }, []);

  useEffect(() => {
    if (activeSubLayer) {
      removeLayers(map, scenesLayers);
      resetAnimation();

      if (!subLayerObj.current) {
        subLayerObj.current = createSubLayer(activeSubLayer, defaultScene, map);
      } else {
        updateSubLayer(subLayerObj.current, activeSubLayer, defaultScene);
      }

      if (activeSubLayer.regions) {
        if (!subLayerRegionsObj.current) {
          subLayerRegionsObj.current = createSubLayerRegions(
            activeSubLayer,
            map,
          );
        }
        updateSubLayerRegions(subLayerRegionsObj.current, activeSubLayer, map);
      } else {
        subLayerRegionsObj.current = removeSubLayerRegions(
          subLayerRegionsObj.current,
          map,
        );
      }
    }
  }, [activeSubLayer]);

  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoadingScenes, setIsLoadingScenes] = useState(false);
  const [selectedSceneBar, setSelectedSceneBar] = useState(null);
  const [scenesLayers, setScenesLayers] = useState({});
  const [scenesLayersLoaded, setScenesLayersLoaded] = useState([]);
  const [forceGenerateTimes, setForceGenerateTimes] = useState(false);
  const intervalControl = useRef(null);
  const intervalUpdateTiles = useRef(null);

  const [selectedHour, setSelectedHour] = useState('12h');
  const passHoursToSelecedtIdxTime = { '2h': 0, '6h': 1, '12h': 2 };
  const idxTimes = useMemo(
    () => passHoursToSelecedtIdxTime[selectedHour],
    [selectedHour],
  );

  const resetAnimation = () => {
    setIsPlaying(null);
    setIsLoadingScenes(false);
    setSelectedSceneBar(null);
    setScenesLayers({});
    setScenesLayersLoaded([]);
  };

  const { marks, ticks } = useGenerateMarksTimes(
    activeSubLayer,
    idxTimes,
    forceGenerateTimes,
  );

  useEffect(() => {
    handleSceneBarChange(
      activeSubLayer,
      selectedSceneBar,
      scenesLayers,
      setScenesLayersLoaded,
      map,
      setScenesLayers,
      loadLayer,
      idxTimes,
    );
  }, [selectedSceneBar]);

  useEffect(() => {
    const someLayerIsLoading =
      Object.values(scenesLayers).length !==
      Object.values(scenesLayersLoaded).filter(Boolean).length;
    if (someLayerIsLoading) setIsLoadingScenes(true);
    else setIsLoadingScenes(false);
  }, [scenesLayers, scenesLayersLoaded]);

  useEffect(() => {
    handleSceneLoading(
      isLoadingScenes,
      isPlaying,
      intervalControl,
      intervalUpdateTiles,
      activeSubLayer,
      defaultScene,
      idxTimes,
      setSelectedSceneBar,
      scenesLayers,
      setForceGenerateTimes,
      secureRandom,
    );
  }, [isLoadingScenes, isPlaying]);

  useEffect(() => {
    if (isPlaying) {
      loadedAllScenes(
        activeSubLayer,
        scenesLayers,
        setScenesLayersLoaded,
        setScenesLayers,
        map,
        idxTimes,
      );
    } else {
      clearInterval(intervalControl.current);
      intervalControl.current = null;
      clearInterval(intervalUpdateTiles.current);
      intervalUpdateTiles.current = null;
    }
  }, [isPlaying, activeSubLayer]);

  const onPlay = () =>
    onPlayAction(isPlaying, setIsPlaying, setIsLoadingScenes);
  const onChange = (val) =>
    onChangeBar(
      val,
      isPlaying,
      intervalControl,
      setSelectedSceneBar,
      setIsPlaying,
      map,
    );
  const onAfterChange = (val) =>
    onAfterChangeBar(
      val,
      isPlaying,
      intervalControl,
      setSelectedSceneBar,
      setIsPlaying,
      map,
    );
  const handleSelect = (hour) =>
    handleSelectHour(hour, setSelectedHour, scenesLayers, map, resetAnimation);

  const defaultScene = useMemo(() => {
    if (activeSubLayer.animate?.days) {
      return Number(moment().format('HH'));
    }

    return activeSubLayer?.animate?.realtime
      ? activeSubLayer?.animate?.times[idxTimes]
      : 1;
  }, [activeSubLayer, idxTimes]);

  useEffect(() => {
    if (activeSubLayer?.animate?.realtime)
      window.currentTime =
        activeSubLayer?.animate.times[idxTimes] -
        (selectedSceneBar || defaultScene);
    else window.currentTime = selectedSceneBar || defaultScene;
  }, [selectedSceneBar, defaultScene, activeSubLayer, idxTimes]);

  return activeSubLayer?.animate ? (
    <ControlLayer
      isLoadingScenes={isLoadingScenes}
      selectedHour={selectedHour}
      handleSelectHour={handleSelect}
      onPlayAction={onPlay}
      isPlaying={isPlaying}
      selectedSceneBar={selectedSceneBar}
      defaultScene={defaultScene}
      onAfterChangeBar={onAfterChange}
      onChangeBar={onChange}
      forceGenerateTimes={forceGenerateTimes}
      idxTimes={idxTimes}
      marks={marks}
      ticks={ticks}
    />
  ) : null;
};

export default WMS;
