import { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Button, Input, notification, Popover, Skeleton, Select } from 'antd';
import { RideTag } from '@rimac-seguros/ride-system-components';

import Table from '../../../../common/Table';
import TableMessage from '../../../../common/Table/TableMessage';
import { useScreen } from '../../../../lib/screen';
import { LOAD_NEWSLETTERS_LOADING } from '../../../../redux/actions/actionsType';
import {
  getNewsletter,
  getNewsletters,
  newslettersApprove,
  newslettersSentNotification,
  newslettersToggleDisabled,
  removeNewsletter,
} from '../../../../services/newsletters';
import {
  CardSkeleton,
  IconCheck,
  IconCloseWithoutCircle,
  IconDots,
  IconEmail,
  IconTrash,
  iconLeft,
  iconRight,
} from '../../ui/PreventionService/Reports/utils';
import Card from './Card';
import { eventTypes } from '../../../../lib/consts';
import { newsletterStates } from '../utils';
import Empty from '../../../../common/Empty';

const { Search } = Input;

const LIMIT = 5;

const NewsletterManagment = ({
  load,
  getNewsletter,
  loadingNewsletters,
  list,
  isLoading,
  pagination,
  removeNewsletter,
}) => {
  const { widthScreen } = useScreen();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [textSearch, setTextSearch] = useState('');
  const [filterItems, setFilterItems] = useState([]);
  const [loadingApprove, setLoadingApprove] = useState(false);
  const [loadingToggleDisabled, setLoadingToggleDisabled] = useState(false);
  const [loadingSending, setLoadingSending] = useState(false);

  const columns = [
    {
      title: 'Tipo',
      dataIndex: 'type',
      key: 'type',
      width: '160px',
      render: (type) => {
        const data = eventTypes.find((item) => item.id === type);

        return (
          <span className='column-strong'>
            {data && data.name ? data.name : ''}
          </span>
        );
      },
    },
    {
      title: 'Fecha de evento',
      dataIndex: 'event',
      key: 'event',
      width: '180px',
      render: (event) =>
        event.date_event_end
          ? moment(event?.date_event_start).format('DD/MM/YYYY - HH:mm') +
            `${
              event.date_event_end
                ? ` a ${moment(event?.date_event_end).format(
                    'DD/MM/YYYY - HH:mm',
                  )}`
                : ''
            }`
          : moment(event?.date_event_start).format('DD MMM YYYY - HH:mm'),
    },
    {
      title: 'Condición detectada',
      dataIndex: 'detected_condition',
      key: 'detected_condition',
      width: '180px',
    },
    {
      title: 'Regiones afectadas',
      dataIndex: 'zones',
      key: 'zones',
      width: '170px',
      render: (zones) => (
        <Popover
          placement='right'
          className='popover-zones'
          content={<ZonesDetected zones={zones} />}
        >
          <span className='popup-region-hover'>Ver regiones</span>
        </Popover>
      ),
    },
    {
      title: 'Estado',
      dataIndex: 'state',
      key: 'state',
      width: '120px',
      render: (type) => {
        const { text, caseTag } = newsletterStates[type];

        return <RideTag type='subtle' case={caseTag} text={text} />;
      },
    },
    {
      title: 'Usuario publicación',
      dataIndex: 'publisher',
      key: 'publisher',
      width: '180px',
      render: (u) =>
        u ? <div>{`${u.name} ${u.last_name1} ${u.last_name2}`}</div> : '',
    },
    {
      title: 'Acciones',
      key: 'actions',
      fixed: 'right',
      width: 80,
      render: (__, record) =>
        rows.length > 0 && (
          <Popover
            placement='topRight'
            content={<ActionsContent record={record} />}
            className='popover-actions'
          >
            <Button className='button-popover'>
              <IconDots />
            </Button>
          </Popover>
        ),
    },
  ];

  const ZonesDetected = ({ zones }) => {
    const handleClosePopup = () => {
      const popups = document.querySelectorAll('.ant-popover');
      popups.forEach((popup) => popup.classList.add('hidden'));
    };

    const div = document.createElement('div');
    div.innerHTML = zones;

    const elementsWithPadding = div.querySelectorAll(
      '[style*="padding: 0px 50px;"]',
    );
    const elementsWithPaddindLeft = div.querySelectorAll(
      '[style*="padding-left: 10px;',
    );
    const elementsWithFontSize = div.querySelectorAll(
      '[style*="font-size:18px;"]',
    );
    elementsWithPadding.forEach((element) => {
      element.removeAttribute('style');
    });
    elementsWithPaddindLeft.forEach((element) => {
      element.removeAttribute('style');
    });
    elementsWithFontSize.forEach((element) => {
      element.removeAttribute('style');
      element.setAttribute('style', 'font-size: 16px;');
    });
    div.querySelectorAll('img').forEach((img) => img.remove());

    const cleanedHtml = div.innerHTML
      .replace(/<[^>]+?\s+width="15%".*?>/g, '')
      .replace(
        /<table[^>]*\s+class="stylingblock-content-wrapper"[^>]*>/g,
        '$&<style>table.stylingblock-content-wrapper { margin-bottom: 1rem; }</style>',
      );

    return (
      <div className='zones-detected'>
        <div className='header'>
          <h3>Regiones afectadas</h3>
          <div className='close' onClick={handleClosePopup}>
            <IconCloseWithoutCircle />
          </div>
        </div>

        <div className='content-zones'>
          {!zones ? (
            'No muestra regiones afectadas'
          ) : (
            <div dangerouslySetInnerHTML={{ __html: cleanedHtml }} />
          )}
        </div>
      </div>
    );
  };

  const isRecordActive = (event) => {
    if (!event.date_event_end && event.date_event_start)
      return Boolean(moment(event.date_event_start).add(1, 'days') >= moment());

    return Boolean(moment(event.date_event_end) >= moment());
  };

  const ActionsContent = ({ record }) => (
    <div className='buttons-popover'>
      {/* <Button onClick={() => handleEdit(record)}>
        <IconEditTable /> <span>Editar boletín</span>
      </Button> */}

      {record.state === 1 && !record.sent && (
        <Button
          loading={loadingApprove}
          onClick={() => {
            setLoadingApprove(true);
            handleSend(record);
          }}
          disabled={loadingApprove}
        >
          <IconEmail /> <span>Enviar boletín a clientes</span>
        </Button>
      )}

      {record.state === 0 && (
        <Button
          loading={loadingSending}
          onClick={() => {
            setLoadingSending(true);
            handlePublish(record);
          }}
          disabled={loadingSending}
        >
          <IconCheck /> <span>Publicar</span>
        </Button>
      )}
      {isRecordActive(record.event) && (
        <Button
          loading={loadingToggleDisabled}
          onClick={() => handleToggleDisabled(record)}
          disabled={loadingToggleDisabled}
        >
          <IconCheck />{' '}
          <span>
            {!record.disabled ? 'Desactivar' : 'Activación por fecha'}
          </span>
        </Button>
      )}
      <Button onClick={() => handleRemove(record)}>
        <IconTrash /> <span>Eliminar boletín</span>
      </Button>
    </div>
  );

  const handleEdit = async (newsletter) => {
    if (newsletter)
      getNewsletter(newsletter.id).then((response) => {
        localStorage.setItem(
          'newsletter-selected',
          JSON.stringify({ ...newsletter, content: response.content }),
        );
        navigate('/monitoreo/boletin/detalle');
      });
    else localStorage.removeItem('newsletter-selected');
  };

  const handlePublish = async (newsletter) => {
    setLoadingApprove(true);
    const isPublished = await newslettersApprove(
      newsletter,
      {
        query: textSearch,
        page: pagination.current,
        limit: LIMIT,
      },
      dispatch,
    );

    isPublished
      ? notification.success({
          description: 'Se publico correctamente el boletín.',
          message: '¡Boletín Publicado!',
        })
      : notification.warning({
          description: 'Ha ocurrido un error.',
          message: '¡Gestión de boletínes!',
        });
    setLoadingApprove(false);
  };

  const handleRemove = async (newsletter) => {
    const done = removeNewsletter(newsletter.id, {
      query: textSearch,
      page: pagination.current,
      limit: LIMIT,
    });
    done
      ? notification.success({
          description: 'Se realizado el cambio correctamente.',
          message: '¡Boletín eliminado!',
        })
      : notification.warning({
          description: 'Ha ocurrido un error.',
          message: '¡Gestión de boletínes!',
        });
  };

  const handleToggleDisabled = async (newsletter) => {
    setLoadingToggleDisabled(true);

    const done = await newslettersToggleDisabled(
      newsletter,
      {
        query: textSearch,
        page: pagination.current,
        limit: LIMIT,
      },
      dispatch,
    );

    done
      ? notification.success({
          description: 'Se ha realizado el cambio correctamente.',
          message: '¡Boletín actualizado!',
        })
      : notification.warning({
          description: 'Ha ocurrido un error.',
          message: '¡Gestión de boletínes!',
        });

    setLoadingToggleDisabled(false);
  };

  const handleSend = async (newsletters) => {
    setLoadingSending(true);
    const sended = await newslettersSentNotification(
      newsletters,
      {
        query: textSearch,
        page: pagination.current,
        limit: LIMIT,
      },
      dispatch,
    );
    sended
      ? notification.success({
          description: 'Se envío correctamente el boletín a los clientes.',
          message: '¡Boletin enviado!',
        })
      : notification.warning({
          description: 'Ha ocurrido un error.',
          message: '¡Gestión de boletínes!',
        });
    setLoadingSending(false);
  };

  const handleFilter = (e) => {
    const txt = e.target.value.trim();
    setTextSearch(txt);

    if (txt.length >= 2)
      load({
        query: txt,
        page: 1,
        limit: LIMIT,
        ...(filterItems.length === 0 ? {} : { filters: { type: filterItems } }),
        order: 'createdAt',
      });
    else
      load({
        query: '',
        page: 1,
        limit: LIMIT,
        ...(filterItems.length === 0 ? {} : { filters: { type: filterItems } }),
        order: 'createdAt',
      });
  };

  const handleTableChange = (pag) =>
    load({
      page: pag.current,
      limit: LIMIT,
      query: textSearch,
      ...(filterItems.length === 0 ? {} : { filters: { type: filterItems } }),
      order: 'createdAt',
    });

  const handleTableChangeMobile = (current) => {
    if (current === 0 || Math.ceil(pagination.total / LIMIT) + 1 === current)
      return;

    load({
      page: current,
      limit: LIMIT,
      ...(filterItems.length === 0 ? {} : { filters: { type: filterItems } }),
      order: 'createdAt',
    });
  };

  const handleFilterType = (type) => {
    setFilterItems(type);
    load({
      page: pagination.current,
      query: textSearch,
      limit: LIMIT,
      ...(type.length === 0 ? {} : { filters: { type } }),
      order: 'createdAt',
    });
  };

  useEffect(() => {
    setRows(list);
  }, [list]);

  useEffect(() => {
    localStorage.setItem('newsletter-selected', null);
    loadingNewsletters(true);
    load({
      page: 1,
      limit: LIMIT,
      order: 'createdAt',
    });
  }, []);

  return (
    <>
      <div className='profile-company__users--header filter-section mt-0 mb-2'>
        <h2 style={{ marginBottom: 0 }}>Filtrar Boletines</h2>
        <div className='flex items-center gap-2'>
          <Select
            mode='multiple'
            style={{ width: 270 }}
            placeholder='Filtrar por tipo de boletín'
            onChange={handleFilterType}
            allowClear
            maxTagTextLength={4}
          >
            {eventTypes.map((evt) => (
              <Select.Option value={evt.id} key={evt.id}>
                {evt.name}
              </Select.Option>
            ))}
          </Select>

          <Search
            placeholder='Palabras clave'
            style={{
              width: '100%',
              maxWidth: '300px',
            }}
            onChange={handleFilter}
            allowClear
          />
        </div>
      </div>

      {widthScreen > 767 ? (
        !isLoading && list.length === 0 ? (
          <Empty title='No se encontraron boletines' />
        ) : (
          <section className='show-data'>
            {isLoading ? (
              <Skeleton active title={false} paragraph={{ rows: 1 }} />
            ) : (
              <p className='info-total-data'>
                Se encontraron <span>{pagination.total}</span>{' '}
                {pagination.total === 1 ? 'boletin' : 'boletines'}
              </p>
            )}

            <Table
              dataSource={rows}
              columns={columns}
              rowKey={(record) => record.id}
              loading={isLoading}
              locale={{ emptyText: 'No se encontraron boletines.' }}
              pagination={pagination}
              onChangeRows={setRows}
              onChange={handleTableChange}
            />
          </section>
        )
      ) : (
        <section className='content-mobile'>
          {isLoading ? (
            [1, 2, 3, 4].map((el) => <CardSkeleton key={el} />)
          ) : rows.length === 0 ? (
            <TableMessage
              complete={true}
              message='No se encontrarón boletines'
            />
          ) : (
            rows.map((data) => (
              <Card
                key={data.id}
                data={data}
                handleEdit={handleEdit}
                handlePublish={handlePublish}
                handleSend={handleSend}
                handleRemove={handleRemove}
                ZonesDetected={ZonesDetected}
                setLoadingApprove={setLoadingApprove}
                setLoadingSending={setLoadingSending}
                loadingApprove={loadingApprove}
                loadingSending={loadingSending}
              />
            ))
          )}

          <div className='d_content__list__pag'>
            <div
              className={'d_content__list__pag__button'}
              onClick={() => handleTableChangeMobile(pagination.current - 1)}
            >
              {iconLeft({
                color: pagination.current === 1 ? '#A9AFD9' : '#4F4FFF',
              })}
            </div>

            <div className='d_content__list__pag__number color-red-1'>
              {pagination.current}
            </div>
            <div className='d_content__list__pag__sep'>/</div>
            <div className='d_content__list__pag__number color-gray-3'>
              {Math.ceil(pagination.total / pagination.pageSize)}
            </div>

            <div
              className={'d_content__list__pag__button'}
              onClick={() => handleTableChangeMobile(pagination.current + 1)}
            >
              {iconRight({
                color:
                  pagination.current ===
                  Math.ceil(pagination.total / pagination.pageSize)
                    ? '#A9AFD9'
                    : '#4F4FFF',
              })}
            </div>
          </div>
        </section>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  loadingNewsletters: (payload) =>
    dispatch({ type: LOAD_NEWSLETTERS_LOADING, payload: payload }),
  load: (data) => getNewsletters(dispatch, data),
  getNewsletter: (id) => getNewsletter(id),
  removeNewsletter: (id, data) => removeNewsletter({ id, data, dispatch }),
});

const mapStateToProps = (state) => ({
  list: state.newsletters.newsletters,
  pagination: state.newsletters.pagination,
  isLoading: state.newsletters.isLoading,
  query: state.newsletters.query,
  filter: state.newsletters.filter,
  user: state.auth.user,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewsletterManagment);
