import { useEffect, useState } from 'react';
import { useDispatch, connect } from 'react-redux';
import { Button, notification, Skeleton } from 'antd';

import { useScreen } from '../../../../lib/screen';
import { OPEN_FORM_TYPE_POLIZA } from '../../../../redux/actions/actionsType';
import {
  createTypePolizaPag,
  getAllPolizasPagination,
  removeTypePolizaPag,
  updateTypePolizaPag,
} from '../../../../services/typePolicy';
import {
  CardSkeleton,
  IconEditTable,
  iconLeft,
  iconRight,
  IconTrash,
} from '../../ui/PreventionService/Reports/utils';

import useDebounce from '../../../../hooks/useDebounce';
import FilterSection from '../../ui/PreventionService/components/FilterSection';
import ModalForm from './ModalForm';
import ModalConfirm from '../../../../common/ModalConfirm';
import Table from '../../../../common/Table';
import PopoverActions from '../../../../common/Table/PopoverActions';
import TableMessage from '../../../../common/Table/TableMessage';
import CardItemMobile from '../common/CardItemMobile';
import MainTitle from '../common/MainTitle';
import DeleteModalImage from '../../../../images/delete_modal_image.svg';
import IconFinish from '../../../../images/icon_finish.svg';

const TypePolizas = ({
  load,
  create,
  update,
  toggleModal,
  listPagination,
  isLoadingList,
  pagination,
  isLoadingCreate,
  openedForm,
  renderItem,
  queryAction,
}) => {
  const dispatch = useDispatch();
  const { widthScreen, isMobileScreen } = useScreen();
  const [rows, setRows] = useState([]);
  const [textSearch, setTextSearch] = useState('');
  const debouncedTextSearch = useDebounce(textSearch);
  const [itemSelected, setItemSelected] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [confirmMobile, setConfirmMobile] = useState('');

  const _handleEdit = (item) => () => {
    setItemSelected(item);
    toggleModal(true);
  };

  const _handleDelete = (item) => () => {
    setItemToDelete(item);
  };

  const columns = [
    {
      title: 'Nombre',
      dataIndex: 'name',
      key: 'name',
      width: '200px',
      render: (text) => <span className='text-uppercase'>{text || ''}</span>,
    },
    {
      title: 'Código',
      dataIndex: 'code',
      key: 'code',
      width: '150px',
      render: (text) => <span className='text-uppercase'>{text || ''}</span>,
    },
    {
      dataIndex: 'actions',
      title: 'Acciones',
      fixed: 'right',
      key: 'actions',
      width: 80,
      render: (__, item) =>
        rows.length > 0 ? (
          <PopoverActions
            actions={
              <>
                <Button onClick={_handleEdit(item)}>
                  <IconEditTable /> <span>Editar</span>
                </Button>

                <Button onClick={_handleDelete(item)}>
                  <IconTrash /> <span>Eliminar</span>
                </Button>
              </>
            }
          />
        ) : null,
    },
  ];

  useEffect(() => {
    if (debouncedTextSearch.length >= 2) {
      load({
        query: debouncedTextSearch,
        page: 1,
        limit: pagination.pageSize,
      });
    } else {
      load({
        query: '',
        page: 1,
        limit: pagination.pageSize,
      });
    }
  }, [debouncedTextSearch]);

  const handleFilter = (e) => setTextSearch(e.target.value.trim());

  const handleTableChange = (pag) =>
    load({
      page: pag.current,
      limit: pag.pageSize,
      query: textSearch,
    });

  const handleTableChangeMobile = (current) => {
    if (
      current === 0 ||
      Math.ceil(pagination.total / pagination.pageSize) + 1 === current
    ) {
      return;
    }

    load({
      page: current,
      limit: pagination.pageSize,
      query: textSearch,
    });
  };

  const handleAction = async (values) => {
    itemSelected
      ? await update(
          values,
          {
            page: pagination.current,
            limit: pagination.pageSize,
            query: textSearch,
          },
          widthScreen,
          setConfirmMobile,
        )
      : await create(values, widthScreen, setConfirmMobile);

    setItemSelected(null);
  };

  const handleRemove = async () => {
    setLoadingDelete(true);

    if (!itemToDelete) {
      return notification.warning({
        description: 'No ha seleccionado ninguna poliza para eliminar.',
        message: 'Eliminar póliza',
      });
    }

    const deleted = await removeTypePolizaPag(itemToDelete.id, dispatch, {
      page: pagination.current,
      limit: pagination.pageSize,
      query: textSearch,
    });

    if (widthScreen < 1024 && deleted) {
      setItemToDelete(null);
      setLoadingDelete(false);

      return setConfirmMobile(
        '¡Listo! Se eliminó el tipo de póliza correctamente',
      );
    }

    if (deleted) {
      notification.success({
        description: 'Se eliminó el tipo de póliza correctamente.',
        message: '¡Tipo de póliza eliminada! ',
      });
    } else {
      notification.warning({
        description: 'Ha ocurrido un error.',
        message: 'Crear póliza',
      });
    }

    setItemToDelete(null);
    setLoadingDelete(false);
  };

  useEffect(() => {
    setRows(listPagination);
  }, [listPagination]);

  useEffect(() => {
    queryAction && toggleModal(true);
  }, [queryAction]);

  return (
    <>
      <MainTitle
        buttonAction={() => toggleModal(true)}
        renderItem={renderItem}
        isMobileScreen={isMobileScreen}
      />

      <FilterSection
        complete={true}
        text='Filtrar tipos de póliza'
        handleFilter={handleFilter}
      />

      {widthScreen > 767 ? (
        !isLoadingList && listPagination.length === 0 ? (
          <TableMessage complete={true} message='No se encontrarón polizas' />
        ) : (
          <section className='show-data'>
            {isLoadingList ? (
              <Skeleton active title={false} paragraph={{ rows: 1 }} />
            ) : (
              <p className='info-total-data'>
                Se encontraron <span>{pagination.total}</span>{' '}
                {pagination.total === 1 ? 'póliza' : 'pólizas'}
              </p>
            )}

            <Table
              dataSource={rows}
              columns={columns}
              rowKey={(record) => record.id}
              loading={isLoadingList}
              locale={{ emptyText: 'No se encontraron polizas.' }}
              pagination={pagination}
              onChangeRows={setRows}
              onChange={handleTableChange}
            />
          </section>
        )
      ) : (
        <section className='content-mobile action-bottom'>
          {isLoadingList ? (
            <Skeleton active title={false} paragraph={{ rows: 1 }} />
          ) : (
            <p className='info-total-data'>
              Se encontraron <span>{pagination.total}</span>{' '}
              {pagination.total === 1 ? 'póliza' : 'pólizas'}
            </p>
          )}

          {isLoadingList ? (
            [1, 2, 3, 4].map((el) => <CardSkeleton key={el} />)
          ) : rows.length === 0 ? (
            <TableMessage complete={true} message='No se encontrarón polizas' />
          ) : (
            rows.map((data) => (
              <CardItemMobile
                key={data.id}
                data={data}
                columns={columns.filter((el) => el.dataIndex !== 'actions')}
                onEdit={(item) => {
                  setItemSelected(item);
                  toggleModal(true);
                }}
                onDelete={setItemToDelete}
              />
            ))
          )}

          <div className='d_content__list__pag'>
            <div
              className={'d_content__list__pag__button'}
              onClick={() => handleTableChangeMobile(pagination.current - 1)}
            >
              {iconLeft({
                color: pagination.current === 1 ? '#A9AFD9' : '#4F4FFF',
              })}
            </div>

            <div className='d_content__list__pag__number color-red-1'>
              {pagination.current}
            </div>
            <div className='d_content__list__pag__sep'>/</div>
            <div className='d_content__list__pag__number color-gray-3'>
              {Math.ceil(pagination.total / pagination.pageSize)}
            </div>

            <div
              className={'d_content__list__pag__button'}
              onClick={() => handleTableChangeMobile(pagination.current + 1)}
            >
              {iconRight({
                color:
                  pagination.current ===
                  Math.ceil(pagination.total / pagination.pageSize)
                    ? '#A9AFD9'
                    : '#4F4FFF',
              })}
            </div>
          </div>
        </section>
      )}

      <ModalForm
        visible={openedForm}
        handleClose={() => {
          setItemSelected(null);
          toggleModal(false);
        }}
        isLoadingCreate={isLoadingCreate}
        data={itemSelected}
        handleAction={handleAction}
      />

      <ModalConfirm
        visible={itemToDelete}
        onClose={() => setItemToDelete(null)}
        title='¿Seguro que desea eliminar el
        tipo de póliza de la lista ?'
        image={DeleteModalImage}
        actionsContent={
          <div className='actions-delete-modal mt-2'>
            <Button
              className='button secondary-button mr-2'
              onClick={() => setItemToDelete(null)}
            >
              Cancelar
            </Button>

            <Button
              className='button ml-1'
              type='primary'
              disabled={loadingDelete}
              loading={loadingDelete}
              onClick={handleRemove}
            >
              Eliminar
            </Button>
          </div>
        }
      />

      <ModalConfirm
        visible={confirmMobile}
        onClose={() => setConfirmMobile('')}
        title={confirmMobile || ''}
        image={IconFinish}
        actionsContent={
          <div className='actions-delete-modal mt-2'>
            <Button
              className='button w-max'
              type='primary'
              onClick={() => setConfirmMobile('')}
            >
              Aceptar
            </Button>
          </div>
        }
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  load: (pagination) => getAllPolizasPagination(dispatch, pagination),
  toggleModal: (opened) =>
    dispatch({ type: OPEN_FORM_TYPE_POLIZA, payload: opened }),
  create: async (typeIndustry, widthScreen, setConfirmMobile) => {
    const created = await createTypePolizaPag(typeIndustry, dispatch);

    if (widthScreen < 1024 && created)
      return setConfirmMobile(
        '¡Listo! Se creó el tipo de póliza correctamente',
      );

    created
      ? notification.success({
          description: 'Se creo el tipo de póliza correctamente.',
          message: '¡Tipo de póliza creada!',
        })
      : notification.warning({
          description: 'Ha ocurrido un error.',
          message: 'Crear póliza',
        });
  },
  update: async (typeIndustry, pagination, widthScreen, setConfirmMobile) => {
    const updated = await updateTypePolizaPag(
      typeIndustry,
      dispatch,
      pagination,
    );

    if (widthScreen < 1024 && updated)
      return setConfirmMobile(
        '¡Cambios guardados! Se guardaron los cambios correctamente',
      );

    updated
      ? notification.success({
          description: 'Se guardaron los cambios correctamente.',
          message: '¡Cambios guardados!',
        })
      : notification.warning({
          description: 'Ha ocurrido un error.',
          message: 'Actualizar póliza',
        });
  },
});

const mapStateToProps = (state) => ({
  listPagination: state.typePoliza.listPagination,
  isLoadingList: state.typePoliza.isLoadingList,
  pagination: state.typePoliza.pagination,
  isLoadingCreate: state.typePoliza.isLoadingCreate,
  openedForm: state.typePoliza.openedForm,
});

export default connect(mapStateToProps, mapDispatchToProps)(TypePolizas);
