import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Divider, Typography, notification } from 'antd';
import { LeftCircleOutlined, DownloadOutlined } from '@ant-design/icons';

import { useScreen } from '../../../../lib/screen';
import { formatLocationRows } from './utils';
import {
  createBulkLocations,
  createBulkLocationsAdmin,
  getLocations,
} from '../../../../services/locations';

import Title from '../../../../common/Typograph/Title';
import Topbar from '../../../../common/ui/Topbar';
import InputFile from '../../../../common/InputFile';

const { Paragraph } = Typography;

const BulkUploadLocations = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isMobileScreen } = useScreen();
  const { user } = useSelector((state) => state.auth);
  const [isloading, setIsLoading] = useState(false);
  const [locationsData, setLocationsData] = useState(null);
  const isAdminClient = user.rol.slug === 'administrador_cliente';

  const handleUpload = async () => {
    try {
      setIsLoading(true);

      const creationResults = isAdminClient
        ? await createBulkLocationsAdmin(locationsData)
        : await createBulkLocations(locationsData);

      if (creationResults) {
        creationResults.total === creationResults.usersSuccess
          ? notification.success({
              message: '¡Archivo subido!',
              description:
                locationsData.length === 1
                  ? `Se registró 1 ubicación.`
                  : `Se registraron ${locationsData.length} ubicaciones.`,
            })
          : notification.warning({
              message: '¡Archivo subido!',
              duration: 6,
              description:
                'No se completo el registro de una o más ubicaciones de los usuarios.',
            });
      } else {
        notification.error({
          message: 'Error',
          description: 'Hubo un error al subir archivo.',
        });
      }

      await getLocations(dispatch);
      navigate('/monitoreo/configuracion');
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Hubo un error al subir archivo.',
      });
    } finally {
      setIsLoading(false);
      setLocationsData(null);
    }
  };

  const handleProcessExcelData = (rows) => {
    const rowsFormatted = formatLocationRows(rows, { isAdmin: isAdminClient });
    setLocationsData(rowsFormatted);
  };

  const onBack = () => navigate('/monitoreo/configuracion');

  return (
    <div className='h100vh dashboard-UI'>
      <Topbar />
      <div className='d_content'>
        {isMobileScreen ? (
          <Button
            className='mr-1 flex items-center'
            icon={<LeftCircleOutlined />}
            type='link'
            size='large'
            onClick={onBack}
          >
            Volver
          </Button>
        ) : null}
        <div className='flex justify-between items-center mb-2'>
          <div className='flex justify-between gap-2'>
            {!isMobileScreen && (
              <Button
                className='mr-1 flex items-center'
                type='link'
                size='large'
                onClick={onBack}
              >
                <LeftCircleOutlined style={{ fontSize: 24 }} />
              </Button>
            )}
            <Title className='header-container'>
              Carga masiva de ubicaciones
            </Title>
          </div>
          {isMobileScreen ? <Divider /> : null}
          <Button
            type='link'
            href={`${
              isAdminClient
                ? '/templates/PlantillaCargaDeUbicacionesAdm.xlsx'
                : '/templates/PlantillaCargaDeUbicaciones.xlsx'
            }`}
            style={{ alignSelf: 'flex-start' }}
            size={isMobileScreen ? 'small' : 'large'}
            onClick={() => {}}
          >
            <DownloadOutlined />
            Descargar plantilla
          </Button>
        </div>
        {isAdminClient && (
          <Paragraph style={{ fontSize: 16 }} className='mt-3'>
            Si deseas crear ubicaciones favoritas propias dentro de la carga
            masiva coloca tu correo en la seccion indicada
          </Paragraph>
        )}
        <InputFile
          title={'Ubicaciones Favoritas'}
          className={'mt-3 mb-3'}
          withoutPreview
          processExcelData={handleProcessExcelData}
          allowClear={true}
        />
        <Button
          type='primary'
          onClick={handleUpload}
          size='large'
          disabled={isloading || !locationsData}
        >
          Subir excel
        </Button>
      </div>
    </div>
  );
};

export default BulkUploadLocations;
