import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LoginForm from './LoginForm';
import RecoveryPassword from './RecoveryPassword';
import LeftContainer from './LeftContainer';
import MobileFooter from './MobileFooter';

const Login = () => {
  const navigate = useNavigate();
  const [showRecoveryPass, setShowRecoveryPass] = useState(false);
  const isLogged = useSelector((state) => state.auth.isLogged);
  const user = useSelector((state) => state.auth.user);

  const handleShowRecovery = (show) => {
    setShowRecoveryPass(show);
  };

  useEffect(() => {
    if (isLogged && user?.rol) {
      let route = localStorage.getItem('preLoginUrl') || '/mapas';

      if (!localStorage.getItem('preLoginUrl')) {
        if (user.rol.slug === 'proveedor') {
          route = '/servicio-de-prevencion-solicitud-proveedor';
        } else if (user.rol.slug === 'admin_rimac') {
          route = '/administracion/resumen';
        } else if (
          user.rol.slug === 'administrador_cliente' ||
          user.rol.slug === 'usuario_cliente'
        ) {
          route = '/administracion/perfil-empresa';
        } else {
          route = '/monitoreo/mapas';
        }
      }

      navigate(route);
      localStorage.removeItem('preLoginUrl');
    }
  }, [navigate, isLogged, user]);

  if (isLogged) {
    return (
      <div className='login-content'>
        <LeftContainer isLoading={isLogged} />
      </div>
    );
  }

  return (
    <div className='login-content'>
      <LeftContainer isLoading={isLogged} />
      {!isLogged && <LoginForm handleShowRecovery={handleShowRecovery} />}
      <RecoveryPassword
        show={showRecoveryPass}
        handleShowRecovery={handleShowRecovery}
      />
      <MobileFooter />
    </div>
  );
};

export default Login;
