import { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, notification, Skeleton } from 'antd';

import useDebounce from '../../../../hooks/useDebounce';
import {
  createParameter,
  listParametersPag,
  removeParameterPag,
  updateParameter,
} from '../../../../services/parameter';

import PopoverActions from '../../../../common/Table/PopoverActions';
import Table from '../../../../common/Table/SimpleTable';
import ModalConfirm from '../../../../common/ModalConfirm';
import {
  CardSkeleton,
  IconEditTable,
  iconLeft,
  iconRight,
  IconTrash,
} from '../../ui/PreventionService/Reports/utils';
import MainTitle from '../common/MainTitle';
import CardItemMobile from '../common/CardItemMobile';
import FilterSection from '../../ui/PreventionService/components/FilterSection';

import DeleteModalImage from '../../../../images/delete_modal_image.svg';
import { IconDots } from '../../../../common/Icons/iconText';
import Empty from '../../../../common/Empty';

const Parameters = ({
  renderItem,
  onOpenForm,
  isMobileScreen,
  onModalSuccess,
  isBeforeTable,
  queryAction,
  onSetAction,
}) => {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [textSearch, setTextSearch] = useState('');
  const debouncedTextSearch = useDebounce(textSearch);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const { list, pagination, isLoading } = useSelector(
    (state) => state.parameter,
  );

  const handleFilter = (e) => {
    const txt = e.target.value.trim();
    setTextSearch(txt);
    debouncedHandleChangeSearch(txt);
  };

  const handleTableChange = (pag) =>
    listParametersPag(dispatch, {
      page: pag.current,
      limit: pag.pageSize,
      query: textSearch,
    });

  const handleTableChangeMobile = (current) => {
    if (
      current === 0 ||
      Math.ceil(pagination.total / pagination.pageSize) + 1 === current
    ) {
      return;
    }

    listParametersPag(dispatch, {
      page: current,
      limit: pagination.pageSize,
      query: textSearch,
    });
  };

  const _handleDelete = (item) => {
    setItemToDelete(item);
  };

  const _handleAction = useCallback(
    (action, item) => async () => {
      onOpenForm({
        action,
        ...item,
        keyConfig: 'versions',
        hasImage: false,
        titleSection: action === 'create' ? 'Crear versión' : 'Editar versión',
        onAction: async (data, onFinish = () => {}) => {
          if (action === 'create') {
            const created = await createParameter(data, dispatch);
            if (created)
              notification.success({
                message: 'Parametro creado correctamente.',
              });
            else
              notification.warning({
                message: 'Ha ocurrido un error.',
              });
          } else {
            const updated = await updateParameter(data, dispatch);
            if (updated)
              notification.success({
                message: 'Parametro creado correctamente.',
              });
            else
              notification.warning({
                message: 'Ha ocurrido un error.',
              });
          }
          onFinish();
          onOpenForm(null);
          action === 'create' && onSetAction(false);
        },
      });
    },
    [dispatch, onOpenForm, onSetAction],
  );

  const columns = [
    {
      title: 'Key',
      dataIndex: 'key',
      key: 'key',
      width: '30%',
      render: (text) => (
        <span>
          {text?.length > 30 ? `${(text || '').substring(0, 30)}...` : text}
        </span>
      ),
    },
    {
      title: 'Valor',
      dataIndex: 'value',
      key: 'value',
      width: '30%',
      render: (text) => (
        <span>
          {text?.length > 30 ? `${(text || '').substring(0, 30)}...` : text}
        </span>
      ),
    },
    {
      title: 'Descripción',
      dataIndex: 'description',
      key: 'description',
      render: (text) => (
        <span>
          {text?.length > 80 ? `${(text || '').substring(0, 80)}...` : text}
        </span>
      ),
    },
    {
      dataIndex: 'actions',
      title: 'Acciones',
      fixed: 'right',
      key: 'actions',
      width: 80,
      render: (__, item) =>
        !(rows.length > 0 && item?.type === 1) ? (
          <div
            style={{
              background: '#EDEFFC',
              width: 32,
              height: 32,
              borderRadius: 8,
              display: 'grid',
              placeContent: 'center',
            }}
          >
            <IconDots disabled style={{ color: '#A9AFD9' }} />
          </div>
        ) : (
          <PopoverActions
            actions={
              <>
                <Button onClick={_handleAction('edit', item)}>
                  <IconEditTable /> <span>Editar</span>
                </Button>

                <Button
                  onClick={() => {
                    _handleDelete(item);
                  }}
                >
                  <IconTrash /> <span>Eliminar</span>
                </Button>
              </>
            }
          />
        ),
    },
  ];

  const _handleRemove = async () => {
    setLoadingDelete(true);

    if (!itemToDelete) {
      return notification.warning({
        description: 'No ha seleccionado ningun parametro para eliminar.',
        message: 'Eliminar parametro',
      });
    }

    const deleted = await removeParameterPag(itemToDelete.id, dispatch, {
      page: pagination.current,
      limit: pagination.pageSize,
      query: textSearch,
    });

    if (isBeforeTable && deleted) {
      setItemToDelete(null);
      setLoadingDelete(false);

      onModalSuccess({ message: '¡Listo! Se eliminó correctamente' });

      return;
    }

    if (deleted) {
      notification.success({
        description: 'Se eliminó el parametro correctamente.',
        message: 'Parametro eliminado! ',
      });
    } else {
      notification.warning({
        description: 'Ha ocurrido un error.',
        message: 'Eliminar parametro',
      });
    }

    setItemToDelete(null);
    setLoadingDelete(false);
  };

  useEffect(() => {
    if (debouncedTextSearch.length >= 2) {
      listParametersPag(dispatch, {
        query: debouncedTextSearch,
        page: 1,
        limit: pagination.pageSize,
      });
    } else {
      listParametersPag(dispatch, {
        query: '',
        page: 1,
        limit: pagination.pageSize,
      });
    }
  }, [debouncedTextSearch]);

  useEffect(() => {
    setRows(list);
  }, [list]);

  useEffect(() => {
    if (queryAction) _handleAction('create', renderItem)();
  }, [queryAction]);

  return (
    <>
      <MainTitle
        buttonAction={_handleAction('create', renderItem)}
        renderItem={renderItem}
        isMobileScreen={isMobileScreen}
      />

      <FilterSection
        complete={true}
        text={`${
          isMobileScreen ? 'Filtra tu búsqueda' : 'Filtrar lista de parametros'
        }`}
        handleFilter={handleFilter}
      />

      {!isMobileScreen ? (
        !isLoading && list?.length === 0 ? (
          <Empty title='No se encontraron parámetros' />
        ) : (
          <section className='show-data'>
            {isLoading ? (
              <Skeleton active title={false} paragraph={{ rows: 1 }} />
            ) : (
              <p className='info-total-data'>
                Se encontraron <span>{pagination?.total}</span>{' '}
                {pagination?.total === 1 ? 'parametro' : 'parametros'}
              </p>
            )}

            <Table
              dataSource={rows}
              columns={columns}
              rowKey={(record) => record.id}
              loading={isLoading}
              locale={{ emptyText: 'No se encontraron parametros.' }}
              pagination={pagination}
              onChangeRows={setRows}
              onChange={handleTableChange}
            />
          </section>
        )
      ) : (
        <section className='content-mobile action-bottom'>
          {isLoading ? (
            <Skeleton active title={false} paragraph={{ rows: 1 }} />
          ) : (
            <p className='info-total-data'>
              Se encontraron <span>{pagination?.total}</span>{' '}
              {pagination?.total === 1 ? 'parametro' : 'parametros'}
            </p>
          )}

          {isLoading ? (
            [1, 2, 3, 4].map((el) => <CardSkeleton key={el} />)
          ) : rows?.length === 0 ? (
            <Empty title='No se encontraron parámetros' />
          ) : (
            rows?.map((data) => (
              <CardItemMobile
                key={data.id}
                data={data}
                columns={columns.filter((el) => el.dataIndex !== 'actions')}
                onEdit={_handleAction('edit', data)}
                onDelete={setItemToDelete}
                section='parameters'
              />
            ))
          )}

          <div className='d_content__list__pag'>
            <div
              className={'d_content__list__pag__button'}
              onClick={() => handleTableChangeMobile(pagination?.current - 1)}
            >
              {iconLeft({
                color: pagination?.current === 1 ? '#A9AFD9' : '#4F4FFF',
              })}
            </div>

            <div className='d_content__list__pag__number color-red-1'>
              {pagination?.current}
            </div>
            <div className='d_content__list__pag__sep'>/</div>
            <div className='d_content__list__pag__number color-gray-3'>
              {Math.ceil(pagination?.total / pagination?.pageSize)}
            </div>

            <div
              className={'d_content__list__pag__button'}
              onClick={() => handleTableChangeMobile(pagination?.current + 1)}
            >
              {iconRight({
                color:
                  pagination.current ===
                  Math.ceil(pagination?.total / pagination?.pageSize)
                    ? '#A9AFD9'
                    : '#4F4FFF',
              })}
            </div>
          </div>
        </section>
      )}

      <ModalConfirm
        visible={itemToDelete}
        onClose={() => setItemToDelete(null)}
        title='¿Seguro que desea eliminar el parametro de la lista?'
        image={DeleteModalImage}
        actionsContent={
          <div className='actions-delete-modal mt-2'>
            <Button
              className='button secondary-button mr-2'
              onClick={() => setItemToDelete(null)}
            >
              Cancelar
            </Button>

            <Button
              className='button ml-1'
              type='primary'
              disabled={loadingDelete}
              loading={loadingDelete}
              onClick={_handleRemove}
            >
              Eliminar
            </Button>
          </div>
        }
      />
    </>
  );
};

export default memo(Parameters);
