import { createContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import Topbar from '../../../../../common/ui/Topbar';
import UserForm from './components/users/UserForm';
import Resumen from './components/resumen';
import Users from './components/users';
import Surveys from './components/surveys';
import Header from './components/utils/Header';
import Tabs from '../../../../../common/Tabs';

const TABS = [
  {
    key: 'resumen',
    label: 'Resumen',
    children: <Resumen />,
  },
  {
    key: 'users',
    label: 'Usuarios',
    children: <Users />,
  },
  {
    key: 'surveys',
    label: 'Encuestas',
    children: <Surveys />,
  },
];

export const ProfileCompanyContext = createContext({});

const { Provider } = ProfileCompanyContext;

const ProfileCompany = () => {
  const [tabActive, setTabActive] = useState('resumen');
  const [modalUser, setModalUser] = useState(false);
  const [editingUser, setEditingUser] = useState(false);
  const [userSelected, setUserSelected] = useState(null);
  const [data, setData] = useState(null);
  const [latLong, setLatLong] = useState({ lat: null, lng: null });
  const { search } = useLocation();
  const navigate = useNavigate();
  const company_id = useSelector((state) => state.auth.user.company_id);

  const handleChangeTab = (key) => setTabActive(key);

  useEffect(() => {
    const query = new URLSearchParams(search);
    setTabActive(query.get('tab') || 'resumen');

    setTimeout(() => {
      if (query.has('tab')) {
        query.delete('tab');
        navigate('?' + query.toString(), { replace: true });
      }
    }, 300);
  }, []);

  return (
    <Provider
      value={{
        tabActive,
        modalUser,
        editingUser,
        userSelected,
        data,
        latLong,
        setModalUser,
        setEditingUser,
        setUserSelected,
        setData,
        setLatLong,
        company_id,
      }}
    >
      <div id='checkSize' className='h100vh dashboard-UI'>
        <Topbar />
        {modalUser && (
          <UserForm
            visible={modalUser}
            userData={userSelected ?? {}}
            onBack={() => setModalUser(false)}
          />
        )}
        <section className={`d_content ${modalUser ? 'hidden' : 'block'}`}>
          <div className='profile-company'>
            <Header tabActive={tabActive} />
            <Tabs
              activeKey={tabActive}
              onChange={handleChangeTab}
              items={TABS}
            />
          </div>
        </section>
      </div>
    </Provider>
  );
};

export default ProfileCompany;
