import {
  LOAD_CAMPUS_IMPORTED_LOADING,
  LOADED_CAMPUS_IMPORTED,
  LOADING_SYNC_CARNAT,
  FAILED_SYNC_CARNAT,
  CHANGE_CAMPUS_IMPORTED_QUERY,
  CHANGE_CAMPUS_IMPORTED_FILTERS,
} from '../actions/actionsType';

const initialState = {
  list: [],
  page: 1,
  total: 0,
  isLoadingList: false,
  syncLoading: false,
  syncProgressFailed: false,
  query: '',
  filters: [],
  pagination: {
    pageSize: 10,
    current: 1,
    total: 0,
  },
};

const campusImported = (state, action) => {
  if (!state) state = initialState;

  switch (action.type) {
    case LOAD_CAMPUS_IMPORTED_LOADING:
      return {
        ...state,
        isLoadingList: action.payload,
      };
    case LOADED_CAMPUS_IMPORTED:
      if (state.query && state.query !== action.payload.query) return state;

      return {
        ...state,
        list: action.payload.list,
        page: action.payload.page,
        total: action.payload.total,
        pagination: {
          current: action.payload.page,
          pageSize: action.payload.items_per_page,
          total: action.payload.total,
        },
        isLoadingList: false,
      };
    case LOADING_SYNC_CARNAT:
      return {
        ...state,
        syncLoading: true,
      };
    case FAILED_SYNC_CARNAT:
      return {
        ...state,
        syncLoading: false,
        syncProgressFailed: true,
      };
    case CHANGE_CAMPUS_IMPORTED_QUERY:
      return {
        ...state,
        query: action.payload || '',
      };
    case CHANGE_CAMPUS_IMPORTED_FILTERS:
      return {
        ...state,
        filters: action.payload || [],
      };
    default:
      return state;
  }
};

export default campusImported;
