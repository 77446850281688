import { useEffect, useState } from 'react';
import { Button, Card, Modal, Skeleton, Input } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import useDebounce from '../../../../../hooks/useDebounce';
import { useCompaniesUsers } from './hooks';
import { useScreen } from '../../../../../lib/screen';
import { removeCompanyToUser } from '../../../../../services/users';

import texts from '../../../../../common/texts.json';
import Title from '../../../../../common/Typograph/Title';
import SimpleTable from '../../../../../common/Table/SimpleTable';
import PaginationMobile from '../../../../../common/PaginationMobile';
import CardUserMobile from '../../common/CardUserMobile';
import ModalConfirm from '../../../../../common/ModalConfirm';
import DeleteModalImage from '../../../../../images/delete_modal_image.svg';
import ModalAddGestorCompany from './ModalAddGestorCompany';

const { Search } = Input;

const ManagerCompaniesList = ({ visible, onCancel, userId }) => {
  const { companiesUsers, pagination, loading, loadCompaniesUsers } =
    useCompaniesUsers();
  const [querySearch, setQuerySearch] = useState('');
  const debouncedQuerySearch = useDebounce(querySearch);
  const [idCompanyRemove, setIdCompanyRemove] = useState();
  const [removeCompanyModal, setRemoveCompanyModal] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const { isMobileScreen } = useScreen();

  useEffect(() => {
    if (userId) {
      loadCompaniesUsers({
        query: debouncedQuerySearch.length >= 2 ? debouncedQuerySearch : '',
        page: 1,
        limit: 5,
        id: userId,
      });
    }
  }, [userId, debouncedQuerySearch]);

  const handleChangeSearch = (e) => setQuerySearch(e.target.value.trim());

  const handlerRemoveCompany = (id = false) => {
    setLoadingDelete(true);
    removeCompanyToUser({
      user_id: userId,
      company_id: id,
    }).then(() => {
      loadCompaniesUsers({ id: userId });
      setLoadingDelete(false);
      setRemoveCompanyModal(false);
    });
  };

  const handleTableChange = (pag) => {
    loadCompaniesUsers({
      id: userId,
      page: pag,
    });
  };

  const columns = [
    {
      title: texts.header_table_ruc,
      dataIndex: 'ruc',
      key: 'ruc',
    },
    {
      title: texts.header_table_business_name,
      dataIndex: 'business_name',
      key: 'business_name',
    },
    {
      title: texts.header_table_name,
      dataIndex: 'name',
      key: 'name',
      width: '30%',
    },
    {
      title: '',
      dataIndex: 'operation',
      key: 'operation',
      fixed: 'right',
      width: 50,
      render: (_, item) => (
        <Button
          size='small'
          type='link'
          icon={<DeleteOutlined />}
          onClick={() => {
            setIdCompanyRemove(item['id']);
            setRemoveCompanyModal(true);
          }}
        >
          Eliminar
        </Button>
      ),
    },
  ];

  return (
    <>
      <Modal
        title={''}
        onCancel={onCancel}
        open={visible}
        destroyOnClose
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
        maskClosable={false}
        width={isMobileScreen ? '100%' : '70%'}
      >
        <Title type={'bold-24'} style={{ textAlign: 'center' }}>
          Listado de empresas
        </Title>
        <div
          className='flex mt-4 items-center'
          style={{ justifyContent: 'justify-between', marginBottom: 20 }}
        >
          <ModalAddGestorCompany
            userId={userId}
            onLoad={() => loadCompaniesUsers({ id: userId })}
          />
          <Search
            className={`search ${isMobileScreen ? 'mt-2' : ''}`}
            placeholder={'Palabras clave'}
            style={{
              width: '100%',
              maxWidth: '300px',
            }}
            onChange={handleChangeSearch}
          />
        </div>
        {isMobileScreen ? (
          loading ? (
            [1, 2, 3].map((i) => (
              <Card className='mt-2' key={i}>
                <Skeleton />
              </Card>
            ))
          ) : (
            <>
              {companiesUsers.map((company) => (
                <CardUserMobile
                  style={{ border: '1px solid #A9AFD9' }}
                  key={company.id}
                  columns={columns}
                  item={company}
                />
              ))}
              <PaginationMobile {...pagination} onChange={handleTableChange} />
            </>
          )
        ) : (
          <SimpleTable
            columns={columns}
            dataSource={companiesUsers}
            rowKey='id'
            pagination={{
              ...pagination,
              onChange: handleTableChange,
            }}
            loading={loading}
          />
        )}
      </Modal>
      <ModalConfirm
        visible={removeCompanyModal}
        image={DeleteModalImage}
        title={'¿Estás seguro que desea eliminar esta empresa?'}
        onClose={() => setRemoveCompanyModal(false)}
        actionsContent={
          <div className='actions-delete-modal mt-2'>
            <Button
              className='button secondary-button mr-2'
              onClick={() => setRemoveCompanyModal(false)}
            >
              Cancelar
            </Button>
            <Button
              className='button ml-1'
              type='primary'
              loading={loadingDelete}
              onClick={() => {
                handlerRemoveCompany(idCompanyRemove);
              }}
            >
              Aceptar
            </Button>
          </div>
        }
      />
    </>
  );
};

export default ManagerCompaniesList;
