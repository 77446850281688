import {
  LOADED_EMERGENCY,
  LOAD_EMERGENCY_LOADING,
  CLEAR_EMERGENCY,
  LOADING_DOWNLOAD_EMERGENCY,
  LOADING_DOWNLOAD_EMERGENCY_FAILED,
  LOADED_DOWNLOAD_EMERGENCY,
  CHANGE_MAP_FILTERS_EMERGENCY,
  LOADING_EMERGENCY_UPDATE_POINT,
  LOADING_EMERGENCY_CREATE_POINT,
} from '../actions/actionsType';

const TYPES = [
  { type: 'INCENDIO', color: '#d32f2f', name: 'Incendio' },
  {
    type: 'MATERIALES PELIGROSOS (INCIDENTE)',
    color: '#767575',
    name: 'Materiales peligrosos',
  },
  { type: 'RESCATE', color: '#ffd801', name: 'Rescate' },
  {
    type: 'ACCIDENTE VEHICULAR',
    color: '#e55100',
    name: 'Accidente vehicular',
  },
  { type: 'SERVICIO ESPECIAL', color: '#3ec3ff', name: 'Servicio especial' },
  { type: 'EMERGENCIA MEDICA', color: '#1566c2', name: 'Emergencia Médica' },
];

const initialState = {
  list: [],
  types: [],
  filters: [],
  isLoadingList: false,
  mapFilters: {},
  isLoadingCreatePoint: false,
  isLoadingUpdatePoint: false,
};

const emergencies = (state, action) => {
  if (!state) state = initialState;

  switch (action.type) {
    case LOAD_EMERGENCY_LOADING:
      return {
        ...state,
        isLoadingList: action.payload,
      };
    case LOADED_EMERGENCY:
      const listTypes = [
        ...new Set(
          action.payload.list.map((item) =>
            item?.type ? item?.type.split(' / ')[0] : '',
          ),
        ),
      ];
      const types = TYPES.filter((type) => listTypes.includes(type.type));

      return {
        ...state,
        list: action.payload.list,
        types,
        isLoadingList: false,
      };
    case CLEAR_EMERGENCY:
      return {
        ...state,
        list: [],
        filters: [],
      };
    case LOADING_DOWNLOAD_EMERGENCY:
      return {
        ...state,
        list: [],
        filters: [],
        isLoading: action.payload,
      };
    case LOADING_DOWNLOAD_EMERGENCY_FAILED:
      return {
        ...state,
        list: [],
        filters: [],
        isLoading: false,
        exportFailed: true,
      };
    case LOADED_DOWNLOAD_EMERGENCY:
      return {
        ...state,
        list: [],
        filters: [],
        isLoading: false,
        filePath: action.payload,
      };
    case CHANGE_MAP_FILTERS_EMERGENCY:
      return {
        ...state,
        mapFilters: action.payload,
      };
    case LOADING_EMERGENCY_CREATE_POINT:
      return {
        ...state,
        isLoadingCreatePoint: action.payload,
      };
    case LOADING_EMERGENCY_UPDATE_POINT:
      return {
        ...state,
        isLoadingUpdatePoint: action.payload,
      };
    default:
      return state;
  }
};

export default emergencies;
