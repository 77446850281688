import React, { useEffect, useState } from 'react';

import _ from 'lodash';
import { Button, Modal, Row, Col, List } from 'antd';
import { EyeOutlined } from '@ant-design/icons';

import nom from './nomenclatures';

const ListSignals = ({ itemData }) => {
  const [visible, setVisible] = useState(false);
  const [totalOrder, setTotalOrder] = useState(0);
  const signals = JSON.parse(itemData.value);
  const keys = Object.keys(signals);

  const handleOrdersCount = () => {
    let orders = 0;
    keys.forEach((key) => {
      const signal =
        typeof signals[key] === 'string'
          ? JSON.parse(signals[key])
          : signals[key];
      nom.materials.forEach((mat) => {
        _.has(signal, mat.key) && (orders += Number(signal[mat.key]));
      });
    });

    return orders;
  };

  useEffect(() => {
    setTotalOrder(handleOrdersCount());
  }, [keys]);

  return (
    <div>
      <label>Señaleticas</label>
      <p>
        {`${totalOrder} ${totalOrder > 1 ? 'Pedidos' : 'Pedido'}`}{' '}
        <Button icon={<EyeOutlined />} onClick={() => setVisible(true)} />
      </p>

      <Modal
        title={'Detalles'}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        open={visible}
        okText='Aceptar'
        cancelText='Cancelar'
        destroyOnClose
        maskClosable={false}
        width='80%'
        className='modalShowSignal'
      >
        <Row type='flex' align='top' gutter={[16, 16]}>
          {keys.map((key) => {
            const signal =
              typeof signals[key] === 'string'
                ? JSON.parse(signals[key])
                : signals[key];
            const materials = nom.materials
              .map((mat) => {
                return _.has(signal, mat.key)
                  ? { name: mat.label, count: signal[mat.key] }
                  : null;
              })
              .filter(Boolean);

            return (
              <Col key={key} sm={24} md={12} lg={8}>
                <div className='item-signal flex flex-wrap'>
                  <div className='signal-image'>
                    <img
                      src={`${process.env.REACT_APP_WEB_PATH_S3_NEW}${signal.path}`}
                      alt='Señalética'
                    />
                  </div>
                  <div>
                    <List
                      size='small'
                      bordered={false}
                      dataSource={materials}
                      header={<b>Materiales:</b>}
                      renderItem={(item) => (
                        <List.Item>
                          {item.name}: {item.count}
                        </List.Item>
                      )}
                    />
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </Modal>
    </div>
  );
};

export default ListSignals;
