import {
  Capacitacion,
  Default,
  PeritajeElectrico,
  PruebaSismica,
  Termografia,
  RiskEvaluation,
  FireTraining,
  FireSystem,
  VibrationalAnalysis,
  EmergencyTraining,
  Simulations,
} from './IconList';

const renderIcon = (name) => {
  const icons = {
    'evaluación de riesgos': <RiskEvaluation />, // Evaluación de riesgos
    'peritaje eléctrico': <PeritajeElectrico />, // Peritaje Eléctrico
    'prueba sismica': <PruebaSismica />, // Prueba Sismica
    termografía: <Termografia />, // Termografía
    'análisis vibracional': <VibrationalAnalysis />, // Analisis vibracional
    señaletica: <Default />, // Señaletica
    'capacitacion in house': <Capacitacion />, // Capacitacion in House
    'asesoría en ingeniería de sistemas contra incendio': <FireTraining />,
    'prueba sistemas contra incendio': <FireSystem />,
    'asesoría en implementación y revisión de planes de contingencia': (
      <EmergencyTraining />
    ),
    simulaciones: <Simulations />,
  };

  return name && icons[name.toLowerCase()] ? (
    icons[name.toLowerCase()]
  ) : (
    <Default />
  );
};

export default renderIcon;
