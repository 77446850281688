import { get, post, put } from '../lib/ajax';
import {
  LOADED_LOCATIONS,
  LOADING_CREATE_LOCATIONS,
  LOADING_UPDATE_ALL_ALERTS_LOCATIONS,
  LOAD_LOCATIONS_LOADING,
  OPEN_FORM_LOCATIONS,
} from '../redux/actions/actionsType';
import { getUserAuthenticated } from './users';

const BASE_URL = '/locations';

export const getLocations = async (dispatch) => {
  dispatch({ type: LOAD_LOCATIONS_LOADING, payload: true });
  const r = await get(`${BASE_URL}/list`);

  if (r.data.success) {
    dispatch({ type: LOADED_LOCATIONS, payload: r.data.payload });

    return r.data.payload;
  }

  return false;
};

export const getLocationById = async (id) => {
  const r = await get(`${BASE_URL}/locationId?locationId=${id}`);
  if (r.data.success) {
    return r.data.payload;
  }

  return false;
};

export const getForecastConfigById = async (id) => {
  const r = await get(`${BASE_URL}/listById?id=${id}`);
  if (r.data.success) {
    return r.data.payload;
  }

  return false;
};

export const removeLocation = async (id, dispatch) => {
  const r = await post(`${BASE_URL}/remove?id=${id}`);
  if (r.data.success) {
    await getLocations(dispatch);
  }
};

export const createLocation = async (locations, dispatch) => {
  dispatch && dispatch({ type: LOADING_CREATE_LOCATIONS, payload: true });
  const r = await post(`${BASE_URL}/create`, locations);

  if (r.data.success) {
    dispatch && dispatch({ type: OPEN_FORM_LOCATIONS, payload: false });
    dispatch && dispatch({ type: LOADING_CREATE_LOCATIONS, payload: false });
    dispatch && (await getLocations(dispatch));

    return true;
  }
  dispatch && dispatch({ type: LOADING_CREATE_LOCATIONS, payload: false });

  return false;
};

export const updateLocation = async (locations, dispatch) => {
  dispatch({ type: LOADING_CREATE_LOCATIONS, payload: true });
  const r = await put(`${BASE_URL}/update`, locations);

  if (r.data.success) {
    dispatch({ type: OPEN_FORM_LOCATIONS, payload: false });
    dispatch({ type: LOADING_CREATE_LOCATIONS, payload: false });
    await getLocations(dispatch);

    return true;
  }
  dispatch({ type: LOADING_CREATE_LOCATIONS, payload: false });

  return false;
};

export const updateFiltersAlerts = async (filters) => {
  const r = await put(`${BASE_URL}/updateFilters`, filters);
  return r.data.success;
};

export const updateAllAlerts = async (state, dispatch) => {
  dispatch({ type: LOADING_UPDATE_ALL_ALERTS_LOCATIONS, payload: true });
  const r = await put(`${BASE_URL}/updateAllAlerts`, state);
  if (r.data.success) {
    await getLocations(dispatch);
    await getUserAuthenticated(dispatch);
    dispatch({ type: LOADING_UPDATE_ALL_ALERTS_LOCATIONS, payload: false });

    return true;
  }
  dispatch({ type: LOADING_UPDATE_ALL_ALERTS_LOCATIONS, payload: false });

  return false;
};

export const getForecastData = async (latlng) => {
  const r = await post(`${BASE_URL}/forecast`, latlng);

  if (r.data.success) {
    return r.data.payload;
  }
  return false;
};

export const createBulkLocations = async (data) => {
  const r = await post(`${BASE_URL}/createMassiveLocations`, data);
  if (r.data.success && typeof r.data.payload === 'object') {
    return {
      usersSuccess: r.data.payload.success,
      usersFailed: r.data.payload.errors.length,
      total: r.data.payload.success + r.data.payload.errors.length,
    };
  }

  return null;
};

export const createBulkLocationsAdmin = async (data) => {
  const r = await post(`${BASE_URL}/createMassiveLocationsAdminRimac`, data);
  if (r.data.success && typeof r.data.payload === 'object') {
    return {
      usersSuccess: r.data.payload.success,
      usersFailed: r.data.payload.errors.length,
      total: r.data.payload.success + r.data.payload.errors.length,
    };
  }

  return null;
};
