import React from 'react';
import { Button, Popover } from 'antd';
import { IconDots } from '../Icons/iconText';

const PopoverActions = ({ actions }) => {
  return (
    <Popover
      placement='topRight'
      content={<div className='buttons-popover'>{actions}</div>}
      className='popover-actions'
    >
      <Button className='button-popover'>
        <IconDots />
      </Button>
    </Popover>
  );
};

export default PopoverActions;
