import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Divider, Form, Modal, Input, message } from 'antd';

import dayjs from 'dayjs';

import texts from './../../../../common/texts.json';
import text from './../../../../config/text_es.json';
import Title from '../../../../common/Typograph/Title';

const formLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 24 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 24 } },
};

const FiremenEmergenciesForm = ({ onOk, object, visible, openForm }) => {
  if (!object) {
    return 'Cargando...';
  }

  const [form] = Form.useForm();
  const { validateFields } = form;

  const { isLoadingCreatePoint, isLoadingUpdatePoint } = useSelector(
    (state) => state.emergencies,
  );

  const isLoadingPoint = useMemo(
    () => isLoadingCreatePoint || isLoadingUpdatePoint,
    [isLoadingCreatePoint, isLoadingUpdatePoint],
  );

  const handleSave = () => {
    validateFields()
      .then((values) => {
        onOk(
          object.id
            ? { ...values, id: object.id }
            : { ...values, source: 'manual', state: 1 },
        );
      })
      .catch((err) => {
        message.error(err);
      });
  };

  const handleCancel = () => {
    openForm(false);
  };

  const keys = Object.keys(texts).filter(
    (k) => k.indexOf('map_firemenemergency') !== -1,
  );

  return (
    <Modal
      title={''}
      onOk={handleSave}
      onCancel={handleCancel}
      open={visible}
      okText={object?.id ? 'Editar' : 'Crear'}
      cancelText='Cancelar'
      destroyOnClose
      confirmLoading={isLoadingPoint}
      cancelButtonProps={isLoadingPoint}
      maskClosable={false}
    >
      <Title type='bold-28' style={{ textAlign: 'center' }}>
        {object?.id ? 'Editar emergencia' : 'Crear emergencia'}
      </Title>
      <Divider className='mt-2 mb-3' />
      <Form
        {...formLayout}
        form={form}
        disabled={isLoadingPoint}
        className='form-emergencies'
      >
        {keys.map((key) => {
          const required = texts[key].includes('*');
          const keyValue = key.split('_')[2];

          return ['date'].includes(keyValue) ? (
            <Form.Item
              key={key}
              name={keyValue}
              initialValue={
                object[keyValue]
                  ? dayjs(object[keyValue]).format('YYYY-MM-DD')
                  : undefined
              }
              rules={[{ required, message: text.required_message }]}
            >
              <input
                type='date'
                name={keyValue}
                defaultValue={
                  object[keyValue]
                    ? dayjs(object[keyValue]).format('YYYY-MM-DD')
                    : ''
                }
                className='full-width mb-2'
              />
            </Form.Item> 
          ) : (
            <Form.Item
              key={key}
              name={keyValue}
              initialValue={object[keyValue]}
              rules={[{ required, message: text.required_message }]}
            >
              <Input
                className={'mb-2'}
                addonBefore={texts[key]}
                disabled={isLoadingPoint}
              />
            </Form.Item>
          );
        })}
      </Form>
    </Modal>
  );
};

export default FiremenEmergenciesForm;
