import React from 'react';
import { useSelector } from 'react-redux';
import LayerCumulus from './LayerCumulus';
import LayerRoad from './LayerRoad';

const Index = () => {
  const activeUtilsLayer = useSelector((state) => state.maps.activeUtilsLayer);

  return (
    <>
      {activeUtilsLayer.map((layer) => {
        if (layer.key === 'cumulo') {
          return <LayerCumulus key={layer.key} />;
        } else {
          return <LayerRoad key={layer.key} layerKey={layer.key} />;
        }
      })}
    </>
  );
};

export default Index;
