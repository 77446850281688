import { useState } from 'react';
import { useSelector } from 'react-redux';

import useQueryParams from '../../../hooks/useQueryParams';
import { checkPermission } from '../../../lib/security';

import NewsletterList from './NewsletterList';
import NewsletterManagment from './NewsletterManagment';
import EventManagment from './EventManagment';
import Topbar from '../../../common/ui/Topbar';
import Tabs from '../../../common/Tabs';
import Header from './Header';

const TABS = [
  {
    key: 'newsletterList',
    children: <NewsletterList />,
    label: 'Boletines',
    title: 'Boletines',
  },
  {
    key: 'newsletterManagment',
    children: <NewsletterManagment />,
    label: 'Gestión boletines',
    title: 'Gestión de boletines',
  },
  {
    key: 'eventManagment',
    children: <EventManagment />,
    label: 'Gestión eventos',
    title: 'Gestión de eventos',
  },
];

const Newsletters = () => {
  const user = useSelector((state) => state.auth.user);
  const params = useQueryParams();
  const [activeTab, setActiveTab] = useState(() => {
    if (['newsletterManagment', 'eventManagment'].includes(params.tab)) {
      return params.tab;
    }
    return TABS[0].key;
  });

  const title = TABS.find((tab) => tab.key === activeTab).title;

  return (
    <div className='h100vh dashboard-UI'>
      <Topbar />
      <div className='d_content'>
        <Header activeTab={activeTab} title={title} />
        <section>
          {checkPermission(user, 'access_newsletters_events_management') ? (
            <Tabs
              onChange={(key) => setActiveTab(key)}
              activeKey={activeTab}
              items={TABS}
              destroyInactiveTabPane
            />
          ) : (
            <NewsletterList />
          )}
        </section>
      </div>
    </div>
  );
};

export default Newsletters;
