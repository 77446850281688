import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RideGlArrowBack } from '@rimac-seguros/ride-system-components';

import FormUser from './FormUser';
import Button from '../../../../../common/Button';
import Topbar from '../../../../../common/ui/Topbar';

const FormContainer = () => {
  const navigate = useNavigate();
  const { uuid } = useParams();

  return (
    <div className='h100vh dashboard-UI'>
      <Topbar />
      <div className='d_content form-user'>
        <div>
          <Button
            type='primary'
            glyph={<RideGlArrowBack />}
            onClick={() => navigate(-1)}
            text='Volver'
            glyphPosition='left'
            variant='text-primary'
          />
          {uuid ? <h1>Editar usuario</h1> : <h1>Crear usuario</h1>}
          <p>
            Completa los campos requeridos. Para garantizar un acceso correcto a
            la herramientas.
          </p>
        </div>
        <FormUser />
      </div>
    </div>
  );
};

export default FormContainer;
