import { useMemo } from 'react';
import {
  generateTicksAndMarksForDays,
  generateTicksAndMarksForRealtime,
  generateTicksAndMarksDefault,
} from '../utils';

export const useGenerateMarksTimes = (
  activeSubLayer,
  idxTimes,
  forceGenerateTimes,
) => {
  return useMemo(() => {
    let marks = {};
    let ticks = {};

    if (activeSubLayer?.animate) {
      if (activeSubLayer?.animate.days) {
        ({ ticks, marks } = generateTicksAndMarksForDays(activeSubLayer));
      } else if (activeSubLayer?.animate.realtime) {
        ({ ticks, marks } = generateTicksAndMarksForRealtime(
          activeSubLayer,
          idxTimes,
        ));
      } else {
        ({ ticks, marks } = generateTicksAndMarksDefault(activeSubLayer));
      }
    }

    return {
      marks,
      ticks,
    };
  }, [activeSubLayer, forceGenerateTimes, idxTimes]);
};
