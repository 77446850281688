import { Progress } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { reportProfileCompany } from '../../../../../../../../services/dashboard';
import { PieSkeleton } from '../../utils/Loadings';

const Prevention = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    reportProfileCompany()
      .then((res) => {
        setData(res);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const percentProcces = data
    ? Math.trunc((data?.process / data?.total) * 100)
    : 0;
  const percentAvailable = data
    ? Math.trunc(((data?.process + data?.newServices) / data?.total) * 100)
    : 0;

  return (
    <>
      {!loading ? (
        <div className='card pdf-resumen__information pdf-resumen__prevention'>
          <p className='title'>Servicios</p>

          <div className='graphic'>
            <div className='chart'>
              <div className='chart-grafic donut'>
                <div className='progress-title'>
                  <span className='quantity'>{data?.total || 0}</span>
                  <span className='label'>Servicios Solicitados</span>
                </div>
                <Progress
                  className='main-graphic'
                  percent={percentAvailable}
                  type='circle'
                  format={() => ''}
                  strokeWidth={8}
                  success={{ percent: percentProcces }}
                />
              </div>
              <div className='chart-legend'>
                <div className='chart-legend-item'>
                  <span className='indicator-content'>
                    <span className='indicator-color-4'></span>
                    Nuevo
                  </span>
                  <span className='indicator-quantity'>
                    {data?.newServices}
                  </span>
                </div>
                <div className='chart-legend-item'>
                  <span className='indicator-content'>
                    <span className='indicator-color-3'></span>
                    En Proceso
                  </span>
                  <span className='indicator-quantity'>{data?.process}</span>
                </div>
                <div className='chart-legend-item'>
                  <span className='indicator-content'>
                    <span className='indicator-color-5'></span>
                    Ejecutado
                  </span>
                  <span className='indicator-quantity'>{data?.closed}</span>
                </div>
              </div>
            </div>
          </div>

          <div className='info'>
            <ul className='info__header'>
              <li>Servicio</li>
              <li>Fecha de solicitud</li>
              <li>Estado</li>
            </ul>

            <div className='info__body'>
              {data?.services?.length > 0 &&
                data?.services.map((el) => (
                  <ul className='row' key={el.id}>
                    <li>{el?.service?.name || 'sin información'}</li>
                    <li>
                      {el?.createdAt
                        ? moment(el?.createdAt).format('DD/MM/YYYY')
                        : 'sin información'}
                    </li>
                    <li>
                      <span
                        className={
                          el?.requestService?.state === 1
                            ? 'created'
                            : el?.requestService?.state === 2
                              ? 'process'
                              : 'finished'
                        }
                      >
                        {el?.requestService?.state === 1
                          ? 'Nuevo'
                          : el?.requestService?.state === 2
                            ? 'En Proceso'
                            : 'Ejecutado'}
                      </span>
                    </li>
                  </ul>
                ))}
            </div>
          </div>
        </div>
      ) : (
        <PieSkeleton />
      )}
    </>
  );
};

export default Prevention;
