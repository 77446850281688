import React, { useEffect } from 'react';
import { Button, message } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { DownloadOutlined } from '@ant-design/icons';
import { LOADED_REPORTING_PARTICIPANTS_SERVICE_ITEMS_DATA_PARTICIPANTS } from '../../../../redux/actions/actionsType';
import { reportParticipants } from '../../../../services/requestServicesItemsDataParticipants';

const ButtonReportParticipants = ({
  courseKey,
  requestServiceItemDataId,
  disabled,
}) => {
  const dispatch = useDispatch();
  const isReportingParticipants = useSelector(
    (state) =>
      state.requestServicesItemsDataParticipants.isReportingParticipants,
  );
  const reportingParticipantsFailed = useSelector(
    (state) =>
      state.requestServicesItemsDataParticipants.reportingParticipantsFailed,
  );
  const reportingParticipantsFilePath = useSelector(
    (state) =>
      state.requestServicesItemsDataParticipants.reportingParticipantsFilePath,
  );

  useEffect(() => {
    if (reportingParticipantsFailed) message.error('Ha corrido un error');
  }, [reportingParticipantsFailed]);

  useEffect(() => {
    if (reportingParticipantsFilePath) {
      message.info('Reporte generado correctamente.');
      window.open(`${reportingParticipantsFilePath}`, '_blank');
      dispatch({
        type: LOADED_REPORTING_PARTICIPANTS_SERVICE_ITEMS_DATA_PARTICIPANTS,
        payload: null,
      });
    }
  }, [dispatch, reportingParticipantsFilePath]);

  const handlerGenerateReport = () => {
    reportParticipants(
      {
        course_key: courseKey,
        requestServiceItemDataId,
      },
      dispatch,
    );
  };

  return (
    <Button
      type='primary'
      icon={<DownloadOutlined />}
      loading={isReportingParticipants}
      disabled={!courseKey || !requestServiceItemDataId || disabled}
      onClick={handlerGenerateReport}
    >
      Reporte de participantes
    </Button>
  );
};

export default ButtonReportParticipants;
