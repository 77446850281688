import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';

import { useScreen } from '../../lib/screen';
import { SIDEMENU_CHANGE_VISIBLE } from '../../redux/actions/actionsType';
import { subMenuMaps } from './data';

import AsideHeader from './components/AsideHeader';
import SubNavigation from './components/SubNavigation';
import ScrollButton from './components/ScrollButton';
import MobileMenu from './components/MobileMenu';

const Sidemenu = () => {
  const { isMobileScreen } = useScreen();
  const isOpened = useSelector((state) => state.sideMenu.isOpened);
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  useEffect(() => {
    const savedState = Cookies.get('sideMenuState');
    if (savedState !== undefined) {
      const isOpenedState = JSON.parse(savedState);
      dispatch({ type: SIDEMENU_CHANGE_VISIBLE, payload: isOpenedState });
    }
  }, [dispatch]);

  useEffect(() => {
    if (!isMobileScreen) {
      Cookies.set('sideMenuState', JSON.stringify(isOpened), { expires: 7 });
    }
  }, [isOpened, isMobileScreen]);

  if (isMobileScreen) {
    return <MobileMenu />;
  }

  return (
    <aside className={`aside-menu ${isOpened ? 'open' : ''}`}>
      <div style={{ position: 'relative', height: '100%' }}>
        <AsideHeader />
        <ul className='parent'>
          <SubNavigation
            user={user}
            items={subMenuMaps({ rol: user?.rol?.slug })}
          />
        </ul>
        {isOpened && <ScrollButton targetSelector='.parent' />}
      </div>
    </aside>
  );
};

export default Sidemenu;
