import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Skeleton } from 'antd';

import Table from '../../../../../common/Table';
import TableMessage from '../../../../../common/Table/TableMessage';
import { getProviders } from '../../../../../services/providers';
import { useScreen } from '../../../../../lib/screen';
import {
  CardSkeleton,
  IconEditTable,
  iconLeft,
  iconRight,
} from '../Reports/utils';
// import FilterSection from '../components/FilterSection'
import ModalProvider from './components/ModalProvider';
import CardProvider from './components/CardProvider';
import Search from 'antd/lib/input/Search';
import Title from '../../../../../common/Typograph/Title';

const states = {
  0: { tag: 'Inactivo', bg: '#D7DBF5', point: '#7981B2' },
  1: { tag: 'Activo', bg: '#A7EB92', point: '#389E0D' },
};

const prices = {
  0: { tag: 'No', bg: '#D7DBF5', point: '#7981B2' },
  1: { tag: 'Si', bg: '#A7EB92', point: '#389E0D' },
};

const Providers = ({ load, list, isLoading, pagination, isActive }) => {
  const { widthScreen, isMobileScreen } = useScreen();
  const [rows, setRows] = useState([]);
  const [textSearch, setTextSearch] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [providerSelected, setProviderSelected] = useState(null);

  const columns = [
    {
      title: 'Usuario',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Nombre',
      key: 'name',
      render: (__, item) => `${item.name} ${item.last_name1}`,
    },
    {
      title: 'Ver precios',
      dataIndex: 'can_see_prices',
      key: 'can_see_prices',
      render: (item) => {
        const { tag, bg, point } = prices[item ? 1 : 0];

        return (
          <div className='flex'>
            <span className='table-state' style={{ background: bg }}>
              <div className='point' style={{ background: point }}></div>
              <span>{tag}</span>
            </span>
          </div>
        );
      },
    },
    {
      title: 'Estado',
      key: 'state',
      dataIndex: 'state',
      render: (state) => {
        const { tag, bg, point } = states[state] ?? states[0];

        return (
          <div className='flex'>
            <span className='table-state' style={{ background: bg }}>
              <div className='point' style={{ background: point }}></div>
              <span>{tag}</span>
            </span>
          </div>
        );
      },
    },
    {
      title: 'Acciones',
      key: 'actions',
      width: 80,
      render: (__, item) => {
        return (
          <div className='edit-item' onClick={() => handleEdit(item)}>
            <IconEditTable color={'#7981B2'} />
          </div>
        );
      },
    },
  ];

  const handleEdit = (item) => {
    setProviderSelected(item);
    setShowModal(true);
  };

  const handleTableChange = (pag) =>
    load({
      page: pag.current,
      limit: pag.pageSize,
      query: textSearch,
    });

  const handleTableChangeMobile = (current) => {
    if (
      current === 0 ||
      Math.ceil(pagination.total / pagination.pageSize) + 1 === current
    )
      return;

    load({
      page: current,
      limit: pagination.pageSize,
    });
  };

  const handleFilter = (e) => {
    const txt = e.target.value;
    setTextSearch(txt);

    // if (txt === query) return;

    if (txt.length >= 2)
      load({
        query: txt,
        page: 1,
        limit: pagination.pageSize,
      });
    else
      load({
        query: '',
        page: 1,
        limit: pagination.pageSize,
      });
  };

  const handleClearFilter = () => {
    setTextSearch('');
  };

  useEffect(() => {
    setRows(list);
  }, [list]);

  useEffect(() => {
    if (isActive) load({ page: 1, limit: pagination.pageSize });

    return () => {
      handleClearFilter();
    };
  }, [isActive, load, pagination.pageSize]);

  return (
    <>
      {/* <FilterSection text={'proveedores'} handleFilter={handleFilter} /> */}

      <div className='flex full-width justify-between mb-2'>
        <Title className='mt-1' type='bold-20'>
          Filtrar lista de proveedores
        </Title>
        {!isMobileScreen ? (
          <Search
            placeholder='Palabras clave'
            value={textSearch}
            style={{
              width: '100%',
              maxWidth: '300px',
            }}
            allowClear
            onChange={handleFilter}
          />
        ) : null}
      </div>
      {isMobileScreen ? (
        <Search
          placeholder='Palabras clave'
          value={textSearch}
          allowClear
          onChange={handleFilter}
          style={{
            width: '100%',
            maxWidth: '300px',
          }}
        />
      ) : null}
      {!isMobileScreen ? (
        !isLoading && list.length === 0 ? (
          <TableMessage
            complete={true}
            message='No se encontrarón proveedores'
          />
        ) : (
          <section className='show-data'>
            {isLoading ? (
              <Skeleton active title={false} paragraph={{ rows: 1 }} />
            ) : (
              <p className='info-total-data'>
                Se encontraron <span>{pagination.total}</span> proveedores
              </p>
            )}

            <Table
              dataSource={rows}
              columns={columns}
              rowKey={(record) => record.id}
              loading={isLoading}
              locale={{ emptyText: 'No se encontraron servicios.' }}
              pagination={pagination}
              onChangeRows={setRows}
              onChange={handleTableChange}
            />
          </section>
        )
      ) : isLoading ? (
        [1, 2, 3].map((el) => <CardSkeleton key={el} />)
      ) : !isLoading && list.length === 0 ? (
        <TableMessage complete={true} message='No se encontrarón proveedores' />
      ) : (
        <section className='content-mobile'>
          {isLoading ? (
            <Skeleton active title={false} paragraph={{ rows: 1 }} />
          ) : (
            <p className='info-total-data'>
              Se encontraron <span>{pagination.total}</span> proveedores
            </p>
          )}
          {rows.map((provider) => (
            <CardProvider
              key={provider.id}
              provider={provider}
              handleEdit={handleEdit}
              states={states}
              prices={prices}
            />
          ))}

          <div className='d_content__list__pag'>
            <div
              className={'d_content__list__pag__button'}
              onClick={() => handleTableChangeMobile(pagination.current - 1)}
            >
              {iconLeft({
                color: pagination.current === 1 ? '#A9AFD9' : '#4F4FFF',
              })}
            </div>

            <div className='d_content__list__pag__number color-red-1'>
              {pagination.current}
            </div>
            <div className='d_content__list__pag__sep'>/</div>
            <div className='d_content__list__pag__number color-gray-3'>
              {Math.ceil(pagination.total / pagination.pageSize)}
            </div>

            <div
              className={'d_content__list__pag__button'}
              onClick={() => handleTableChangeMobile(pagination.current + 1)}
            >
              {iconRight({
                color:
                  pagination.current ===
                  Math.ceil(pagination.total / pagination.pageSize)
                    ? '#A9AFD9'
                    : '#4F4FFF',
              })}
            </div>
          </div>
        </section>
      )}

      {showModal && providerSelected && (
        <ModalProvider
          visible={showModal}
          handleClose={() => setShowModal(false)}
          provider={providerSelected}
          widthScreen={widthScreen}
        />
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  load: (parameters) => getProviders(dispatch, parameters),
});

const mapStateToProps = (state) => ({
  list: state.providers.list || [],
  isLoading: state.providers.isLoadingList,
  pagination: state.providers.pagination,
  query: state.providers.query,
});

export default connect(mapStateToProps, mapDispatchToProps)(Providers);
