import React, { memo } from 'react';
import { SelectWithLabel } from '../../../../common/InputWithLabel';

const SelectCompany = ({
  companySelected,
  loadingCompanies,
  onSelectCompany,
  className,
  label,
  companies,
  ...props
}) => {
  return (
    <SelectWithLabel
      disabled={loadingCompanies}
      showSearch
      filterOption={(input, option) => {
        return (
          (option?.props?.children?.props?.children || '')
            ?.toLowerCase()
            ?.indexOf(input?.toLowerCase()) >= 0
        );
      }}
      label={loadingCompanies ? 'Cargando...' : label ?? 'Empresas disponibles'}
      onSelect={onSelectCompany}
      notFoundContent='No Encontrado'
      value={loadingCompanies ? undefined : companySelected}
      defaultValue={companySelected}
      loading={loadingCompanies}
      options={companies}
      {...props}
    />
  );
};

export default memo(SelectCompany);
