import React from 'react';
import { Divider, Modal } from 'antd';

import TableMessage from '../../../../common/Table/TableMessage';
import Title from '../../../../common/Typograph/Title';
import { useScreen } from '../../../../lib/screen';

const ModalVersion = ({ visible, handleCancel, data, loading }) => {
  const { isMobileScreen } = useScreen();

  return (
    <Modal
      open={visible}
      title=''
      closable={true}
      onCancel={handleCancel}
      centered={true}
      className={
        'modal-provider modal-centered modal-config modal-preview-version'
      }
      footer={null}
    >
      <div
        className='content-modal'
        style={
          isMobileScreen ? { width: '100%' } : { padding: 64, minWidth: 528 }
        }
      >
        <Title type={'bold-28'} style={{ textAlign: 'center' }}>
          Nuevas actualizaciones
        </Title>
        <Divider className='mt-2 mb-4' />
        {loading ? (
          <div class='lds-roller'>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        ) : (
          <div className='content-version'>
            {data.length > 0 ? (
              data.map((item) => (
                <div className='card-version' key={`${item.type}`}>
                  <div className='left'>
                    <span className={`tag ${item.type === 1 ? 'new' : ''}`}>
                      {item.type === 1 ? 'Nuevo' : 'Mejorado'}
                    </span>

                    <p>Versión {item.version}</p>

                    <h4>{item.title}</h4>
                  </div>
                  <div className='right'>
                    {item.image ? (
                      <img
                        src={`${process.env.REACT_APP_WEB_PATH_S3_NEW}${item.image}`}
                        alt=''
                      />
                    ) : (
                      <div className='not-image'>No hay imágen</div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <TableMessage complete={true} message='Sin contenido' />
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default ModalVersion;
