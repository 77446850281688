import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DatePicker, notification, Spin } from 'antd';
import Chart from 'react-apexcharts';
import dayjs from 'dayjs';

import { getForecastData } from '../../../../services/locations';
import {
  COMMON_FORECAST_DAYS_DATA,
  COMMON_SHOW_FORECAST_DAYS_DIALOG,
} from '../../../../redux/actions/actionsType';

import ForecastInfo from './ForecastInfo';
import { processData, calculateAverage, eventsKeys } from './utils';
import SelectLocations from './SelectLocations';
import Tabs from '../../../Tabs';

const defaultActiveKey = 'temp';

const DrawerForescasts = () => {
  const dispatch = useDispatch();
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const [currentDay, setCurrentDay] = useState(dayjs().format('DD'));
  const allowDate = {
    maxDate: dayjs().add(2, 'day').endOf('day'),
    minDate: dayjs().startOf('day'),
  };
  const [dataProcessed, setDataProcessed] = useState(null);
  const [dataChar, setDataChar] = useState({
    options: { xaxis: {} },
    series: [],
  });

  const { locations, forecastDaysData, selectLocationId, latlngForecast } =
    useSelector((state) => ({
      locations: state.locations.list || [],
      forecastDaysData: state.common.forecastDaysData,
      selectLocationId: state.common.selectLocationId,
      latlngForecast: state.common.latlngForecast,
    }));

  const setForecastData = (data) => {
    dispatch({ type: COMMON_FORECAST_DAYS_DATA, payload: data });
    dispatch({ type: COMMON_SHOW_FORECAST_DAYS_DIALOG, payload: true });
  };

  const renderForecastInfo = () => {
    if (!dataProcessed) {
      return [];
    }
    return Object.keys(dataProcessed)
      .filter((el) => el === currentDay)
      .map((day) => {
        const currentData = dataProcessed?.[day];
        const currentDayHours = dataProcessed?.[day]
          ? Object.keys(dataProcessed?.[day]).sort(
              (a, b) => Number(a) - Number(b),
            )
          : [];

        return (
          <ForecastInfo
            key={day}
            slides={Array.from(
              {
                length: Math.ceil(currentDayHours.length / 6),
              },
              (_, index) => ({
                currentData,
                currentDayHours: currentDayHours.slice(
                  index * 6,
                  (index + 1) * 6,
                ),
              }),
            )}
          />
        );
      });
  };

  const renderTabs = () => {
    console.log(dataChar, forecastDaysData);
    if (!dataChar?.series?.length || !forecastDaysData) {
      return null;
    }

    const tabs = [
      {
        key: 'temp',
        label: 'Temperatura',
        children: (
          <Chart
            options={dataChar.options}
            series={dataChar.series}
            type='bar'
            key='temp-surface-bar'
            data-testid='chart-temp'
          />
        ),
      },
      {
        key: 'pp',
        label: 'Lluvias',
        children: (
          <Chart
            options={dataChar.options}
            series={dataChar.series}
            type='bar'
            key='past3hprecip-surface-bar'
            data-testid='chart-pp'
          />
        ),
      },
      {
        key: 'ws',
        label: 'Vientos',
        children: (
          <Chart
            options={dataChar.options}
            series={dataChar.series}
            type='bar'
            key='ws-bar'
            data-testid='chart-ws'
          />
        ),
      },
    ];

    return (
      <div className='drawer-map-forecast__body__days'>
        <div className='drawer-map-forecast__title'>Promedio por día</div>
        <Tabs
          defaultActiveKey={defaultActiveKey}
          onTabClick={_handleChangeTabs}
          data-testid='tabs'
          items={tabs}
        />
      </div>
    );
  };

  const getCharBar = (forecastData, key, date = dayjs()) => {
    let daysNames = [];

    const averageDays = forecastData
      ? Object.keys(forecastData).reduce((acc, day, idx) => {
          const today = dayjs(date).add(idx, 'day');
          const dayFormat = today.format('dd');
          daysNames.push(`${dayFormat.toUpperCase()} ${day}`);

          const prom = calculateAverage(forecastData, day, key);

          let value;
          if (isNaN(prom)) {
            value = 0;
          } else {
            if (key === 'pp') {
              value = Math.round(prom * 100) / 100;
            } else {
              value = Math.round(prom);
            }
          }
          acc[day] = value;

          return acc;
        }, {})
      : [];

    setDataChar({
      options: {
        colors: ['#7D7DFA'],
        plotOptions: {
          bar: {
            columnWidth: '50%',
            borderRadius: 8,
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          style: {
            fontSize: '10px',
            colors: ['#141938'],
          },
        },
        chart: {
          id: key,
        },
        xaxis: {
          categories: daysNames,
        },
      },
      series: [
        {
          name: eventsKeys[key],
          data: Object.values(averageDays),
        },
      ],
    });
  };

  const locationSelected = locations.find((l) => l.id === selectLocationId);

  const [hasAttemptedFetch, setHasAttemptedFetch] = useState(false);

  useEffect(() => {
    const fetchData = (coordinates) => {
      getForecastData(coordinates)
        .then((data) => {
          if (!data) {
            throw Error('No se cargaron los datos para los pronósticos');
          }
          setForecastData(data);
          const processedData = processData(data);
          setDataProcessed(processedData);
          getCharBar(processedData, defaultActiveKey, dayjs());
        })
        .catch(() => {
          if (hasAttemptedFetch) {
            notification.error({
              description: 'Hubo un error al generar el pronóstico.',
              message: 'Error',
            });
          }
        });
    };

    if (selectLocationId && locationSelected?.lat && locationSelected?.lng) {
      setHasAttemptedFetch(true);
      fetchData({
        lat: locationSelected.lat,
        lng: locationSelected.lng,
      });
    } else if (latlngForecast) {
      setHasAttemptedFetch(true);
      fetchData({
        lat: latlngForecast.lat,
        lng: latlngForecast.lng,
      });
    } else if (hasAttemptedFetch) {
      notification.error({
        description: 'Debe seleccionar un punto en el mapa.',
        message: 'Error',
      });
    }
  }, [locationSelected, latlngForecast, hasAttemptedFetch]);

  const _handleChangeCalendar = (date) => {
    setCurrentDay(dayjs(date).format('DD'));
    setOpenDatePicker((p) => !p);
  };

  const _handleChangeTabs = (k) => {
    getCharBar(dataProcessed, k);
  };

  return (
    <>
      <div className='drawer-map-forecast'>
        <div className='drawer-map-forecast__body'>
          {locations.length !== 0 ? (
            <SelectLocations />
          ) : (
            <div className='empty-selection-box'>
              Selecciona un punto en el mapa para ver los pronósticos o guarde
              una ubicación
            </div>
          )}
          {(selectLocationId || latlngForecast) && (
            <>
              {dataProcessed ? (
                <>
                  <div className='drawer-map-forecast__body__title'>
                    PRONÓSTICO DEL DÍA
                  </div>
                  <div className='drawer-map-forecast__body__header'>
                    <div
                      className='drawer-map-forecast__body__header__date'
                      onClick={() => setOpenDatePicker(!openDatePicker)}
                    >
                      <p>{dayjs().date(currentDay).format('DD-MM-YYYY')}</p>
                    </div>
                  </div>
                  <DatePicker
                    defaultValue={dayjs()}
                    open={Boolean(openDatePicker)}
                    showNow={false}
                    showTime={false}
                    disabledDate={(curr) =>
                      curr.isAfter(allowDate.maxDate, 'day') ||
                      curr.isBefore(allowDate.minDate, 'day')
                    }
                    allowClear={false}
                    onChange={_handleChangeCalendar}
                    className='drawer-map-forecast__body__date-picker'
                    popupClassName='drawer-map-forecast__body__date-picker-dropdown'
                  />
                  <div className='drawer-map-forecast__body__day'>
                    <div className='drawer-map-forecast__body__day__hours'>
                      {renderForecastInfo()}
                    </div>
                  </div>
                  {renderTabs()}
                </>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '30vh',
                  }}
                >
                  <Spin />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default DrawerForescasts;
