import React from 'react';
import { Button, Card } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';

const CardUserMobile = ({ item, columns, onAction, style = {}, userAuth }) => (
  <Card style={{ width: '100%', ...style }} className='d_content__card'>
    {onAction && userAuth?.rol?.slug !== 'comercial' && (
      <div className='d_content__card__button'>
        <Button onClick={onAction}>
          <EllipsisOutlined />
        </Button>
      </div>
    )}
    <div className='d_content__card__body relative' style={{ marginTop: 10 }}>
      {columns
        .filter((el) => !el.hiddenMobile)
        .map(({ key, dataIndex, title, render, fixed, fixedMobile }) => (
          <div
            key={key}
            className={'card-detail__item'}
            style={
              [fixed, fixedMobile].includes('top')
                ? { position: 'absolute', top: -36 }
                : {}
            }
          >
            {fixed || fixedMobile ? null : (
              <h4 style={{ color: '#7981B2', textTransform: 'uppercase' }}>
                {title}
              </h4>
            )}
            <p>
              {(render ? render(item[dataIndex], item) : item[dataIndex]) ||
                '-'}
            </p>
          </div>
        ))}
    </div>
  </Card>
);

export default CardUserMobile;
