import { useState, useMemo, memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Divider, notification } from 'antd';
import { LeftCircleOutlined } from '@ant-design/icons';
import {
  RideSelect,
  RideTextArea,
  RideTextField,
} from '@rimac-seguros/ride-system-components';
import moment from 'moment';

import {
  getCompanies,
  getPolizasToValueOffer,
} from '../../../../../services/company';
import {
  createValueOffer,
  updateValueOffer,
} from '../../../../../services/valueOffer';
import { useScreen } from '../../../../../lib/screen';
import useDebounce from '../../../../../hooks/useDebounce';

import { CompaniesField, UploadFile } from './FieldsComponents';
import { fields, isValidField } from './fields';
import { getAllBusinessSegment } from '../../../../../services/businessSegment';
import { getTypePoliza } from '../../../../../services/typePolicy';
import Title from '../../../../../common/Typograph/Title';

const FormValueOffer = ({ onBack, initialData, isValueOfferRenew, type }) => {
  const { isMobileScreen } = useScreen();
  const dispatch = useDispatch();
  const [loadingForm, setLoading] = useState(false);
  const [polizas, setListPolizas] = useState([]);
  const [isUnknownRuc, setIsUnknownRuc] = useState(false);
  const [formData, setFormData] = useState(() => {
    let result = {};
    if (initialData?.id)
      result = fields.reduce(
        (acc, { key }) => {
          acc[key] = initialData[key];

          return acc;
        },
        { id: initialData.id },
      );

    return result;
  });
  const [ruc, setRuc] = useState(formData.ruc);
  const debounceRuc = useDebounce(ruc);
  const { companies, businessSegmentList } = useSelector((state) => ({
    companies: state.companies.companies,
    businessSegmentList: state.businessSegment.list,
  }));

  const companySelected = companies
    ? companies.find(
        (el) =>
          el.business_name.toUpperCase() ===
          formData?.business_name?.toUpperCase(),
      )
    : {};

  useEffect(() => {
    if (polizas && formData?.poliza_id) {
      const poliza_selected = polizas.find(
        (el) => Number(el.id) === Number(formData?.poliza_id),
      );
      if (poliza_selected)
        setFormData((prev) => ({
          ...prev,
          prima: poliza_selected?.prima,
          month_renew:
            poliza_selected?.end_date &&
            moment(poliza_selected.end_date).format('MM'),
          year_renew:
            poliza_selected?.end_date &&
            moment(poliza_selected.end_date).format('YYYY'),
        }));
    }
  }, [formData.poliza_id]);

  const disabledNext = useMemo(() => {
    return fields?.reduce((result, elem) => {
      if (elem.required)
        return result || !isValidField(elem.valid, formData[elem.key]);

      return result;
    }, false);
  }, [formData]);

  useEffect(() => {
    if (companySelected) {
      setFormData((prev) => ({
        ...prev,
        businessSegment: companySelected?.businessSegment?.name,
      }));
      getPolizasToValueOffer(companySelected).then((response) => {
        let polizas = response;
        if (initialData?.id && initialData?.poliza_id) {
          const existPoliza = polizas.find(
            (poliza) => poliza.id === initialData.poliza_id,
          );
          if (!existPoliza)
            polizas = [
              {
                id: initialData.poliza.id,
                no_poliza: initialData.poliza.no_poliza,
                typePoliza: initialData.poliza.typePoliza.name,
                end_date: moment(
                  moment(initialData.poliza.start_date).format('DD-MM-YYYY'),
                  'DD-MM-YYYY',
                )
                  .add(initialData.poliza.validity, 'M')
                  .format('YYYY-MM-DDT00:00:00.000Z'),
                start_date: initialData.poliza.start_date,
                validity: initialData.poliza.validity,
                prima: new Intl.NumberFormat('en-US', {
                  style: 'currency',
                  currency: 'USD',
                }).format(initialData.poliza.prima),
              },
              ...polizas,
            ];
        }
        setListPolizas(polizas);
      });
    } else {
      getTypePoliza(dispatch).then((policies) => {
        setListPolizas(
          policies.map((policy) => ({ ...policy, typePoliza: policy.name })),
        );
      });
    }
  }, [companySelected]);

  const handleChangeField = ({ target: { value, name } }) => {
    if (name === 'ruc') {
      setRuc(value);
    }
    if (name === 'business_name') {
      setFormData((prev) => ({
        ...prev,
        ruc: companies.find((company) => company.name === value)?.ruc,
      }));
    }
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    getAllBusinessSegment(dispatch);
  }, []);

  useEffect(() => {
    if (
      formData?.ruc &&
      formData?.ruc.length >= 3 &&
      formData?.ruc.length <= 11
    )
      getCompanies(dispatch, { query: formData.ruc }).then((exists) =>
        !exists ? setIsUnknownRuc(true) : setIsUnknownRuc(false),
      );
  }, [debounceRuc]);

  const createNewValueOffer = async (values, type) => {
    setLoading(true);
    const created = await createValueOffer(
      {
        ...values,
      },
      dispatch,
      { type },
    );
    if (created) {
      notification.success({
        description: 'Oferta de valor creada correctamente.',
        message: '¡Oferta de valor!',
      });
      onBack();
    } else {
      notification.warning({
        description: 'Ha ocurrido un error.',
        message: '¡Oferta de valor!',
      });
    }
    setLoading(false);
  };

  const updateExistValueOffer = async (data, type) => {
    setLoading(true);
    const updated = await updateValueOffer(
      {
        ...data,
      },
      dispatch,
      { type },
    );
    if (updated) {
      notification.success({
        description: 'Oferta de valor actualizada correctamente.',
        message: '¡Oferta de valor!',
      });
      onBack();
    } else {
      notification.warning({
        description: 'Ha ocurrido un error.',
        message: '¡Oferta de valor!',
      });
    }
    setLoading(false);
  };

  const handleAction = () =>
    formData.id
      ? updateExistValueOffer(formData, type)
      : createNewValueOffer(formData, type);

  return (
    <div className='d_content service-prevention'>
      {isMobileScreen && (
        <Button
          className='mr-1 flex items-center'
          icon={<LeftCircleOutlined />}
          type='link'
          size='large'
          onClick={onBack}
        >
          Volver
        </Button>
      )}
      <div
        className={`flex ${
          isMobileScreen ? 'flex-col' : 'items-center justify-between'
        }`}
      >
        <div className='flex items-center'>
          {!isMobileScreen && (
            <Button
              className='mr-1 flex items-center'
              type='link'
              size='large'
              onClick={onBack}
            >
              <LeftCircleOutlined style={{ fontSize: 24 }} />
            </Button>
          )}
          <Title type={isMobileScreen ? 'bold-32' : 'bold-28'}>
            {initialData?.id ? 'Editar' : 'Crear'} oferta de valor
          </Title>
        </div>
      </div>
      <Divider />
      <div
        className={'flex flex-col full-width'}
        style={isMobileScreen ? { paddingBottom: 100 } : { paddingBottom: 40 }}
      >
        <div className={`flex ${isMobileScreen ? 'flex-col' : ''}`}>
          <div className='flex flex-col flex-1'>
            {fields.map(({ key, title, type = 'text', options, ...props }) => {
              return (
                <div className='full-width' key={key}>
                  {title && (
                    <Title className={'mb-2'} type={'bold-14'}>
                      {title}
                    </Title>
                  )}
                  {(() => {
                    switch (type) {
                      case 'select':
                        if (key === 'business_name') {
                          return (
                            <CompaniesField
                              key={key}
                              name={key}
                              value={formData[key]}
                              onChange={handleChangeField}
                              isUnknownRuc={isUnknownRuc}
                              {...props}
                            />
                          );
                        }
                        if (key === 'businessSegment') {
                          return (
                            <RideSelect
                              key={key}
                              name={key}
                              value={formData[key]}
                              defaultValue={formData[key]}
                              className='mb-2'
                              onChange={(e) => {
                                setFormData((prev) => ({
                                  ...prev,
                                  [key]: e.target.value,
                                }));
                              }}
                              options={businessSegmentList.map((el) => ({
                                value: el.name ?? '',
                                label: el.name,
                              }))}
                              disabled={isValueOfferRenew && true}
                              {...props}
                            />
                          );
                        }
                        if (key === 'poliza_id') {
                          return (
                            <RideSelect
                              key={key}
                              name={key}
                              value={formData[key]}
                              defaultValue={formData[key]}
                              className='mb-2'
                              onChange={(e) => {
                                setFormData((prev) => ({
                                  ...prev,
                                  [key]: e.target.value,
                                }));
                              }}
                              options={
                                polizas?.map((el) => ({
                                  value: el.id,
                                  label: el.typePoliza,
                                })) ?? []
                              }
                              {...props}
                            />
                          );
                        }
                        return (
                          <RideSelect
                            key={key}
                            name={key}
                            value={formData[key]}
                            defaultValue={formData[key]}
                            className='mb-2'
                            onChange={(e) => {
                              setFormData((prev) => ({
                                ...prev,
                                [key]: e.target.value,
                              }));
                            }}
                            options={options ?? []}
                            {...props}
                          />
                        );
                      case 'file':
                        return (
                          <UploadFile
                            key={key}
                            onChange={(selected) => {
                              setFormData((prev) => ({
                                ...prev,
                                [key]: selected,
                              }));
                            }}
                            defaultValue={formData[key]}
                          />
                        );
                      case 'textarea':
                        return (
                          <RideTextArea
                            key={key}
                            name={key}
                            onChange={handleChangeField}
                            value={formData[key]}
                            showCharacterCounter
                            {...props}
                          />
                        );
                      default:
                        return (
                          <RideTextField
                            key={key}
                            name={key}
                            className='mb-2'
                            onChange={handleChangeField}
                            type={type}
                            value={formData[key]}
                            {...props}
                          />
                        );
                    }
                  })()}
                </div>
              );
            })}
          </div>
          {!isMobileScreen && (
            <div className='flex justify-center items-baseline flex-1'>
              <img src={'/images/form_img_woman.png'} alt='' aria-hidden />
            </div>
          )}
        </div>
        {isMobileScreen ? (
          <div
            className={'fixed-bottom p-3 full-width'}
            style={{ background: '#EDEFFC' }}
          >
            <span
              className='full-width absolute'
              style={{
                height: 12,
                top: -12,
                left: 0,
                background:
                  'linear-gradient(to bottom, rgba(255,0,0,0), #EDEFFC)',
              }}
            ></span>
            <Button
              className='full-width text-xl'
              type='primary'
              disabled={disabledNext}
              onClick={handleAction}
            >
              {initialData?.id ? 'Editar' : 'Crear'}
            </Button>
          </div>
        ) : (
          <Button
            disabled={disabledNext}
            style={{
              maxWidth: 200,
            }}
            loading={loadingForm}
            type='primary'
            size='large'
            className='mt-2 pl-3 pr-3'
            onClick={handleAction}
          >
            {initialData?.id ? 'Editar' : 'Crear'}
          </Button>
        )}
      </div>
    </div>
  );
};

export default memo(FormValueOffer);
