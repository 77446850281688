import React, { memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Input, List, Popover, Skeleton } from 'antd';
import { SyncOutlined, ReloadOutlined } from '@ant-design/icons';

import useDebounce from '../../../../../hooks/useDebounce';
import { useScreen } from '../../../../../lib/screen';
import {
  getSentMails,
  reSentMail,
  updateStatusSentMails,
} from '../../../../../services/sentMails';

import Title from '../../../../../common/Typograph/Title';
import PaginationMobile from '../../../../../common/PaginationMobile';
import { IconDots } from '../../../../../common/Icons/iconText';
import CardUserMobile from '../../common/CardUserMobile';
import SimpleTable from '../../../../../common/Table/SimpleTable';

const { Search } = Input;

const ListActions = (record) => (
  <List
    className={`full-width ${record.isMobileScreen ? 'text-base' : ''}`}
    dataSource={[
      {
        key: 'sync',
        action: record.updateStatus,
        icon: <SyncOutlined />,
        title: 'Actualizar estado',
      },
      {
        key: 'reload',
        action: record.resentEmail,
        icon: <ReloadOutlined />,
        title: 'Reenviar correo',
      },
    ].filter(Boolean)}
    renderItem={({ action, icon, key, title }) => (
      <List.Item
        style={
          !record.isMobileScreen
            ? { height: 40, paddingLeft: 16, width: 228 }
            : {}
        }
        key={key}
        onClick={action}
      >
        {React.cloneElement(icon, { style: { marginRight: 12 } })}
        {title}
      </List.Item>
    )}
  />
);

const columns = [
  {
    title: 'Id',
    dataIndex: 'sentId',
  },
  {
    title: 'Tipo',
    dataIndex: 'type',
  },
  {
    title: 'Destinatario',
    dataIndex: 'to',
  },
  {
    title: 'Estado',
    dataIndex: 'state',
    render: (state) => {
      switch (state) {
        case -1:
          return 'En cola';
        case 1:
          return 'Entregado';
        case 2:
          return 'Abierto';
        case 3:
          return 'Rebotado';
        case 4:
          return 'Pendiente';
        default:
          return 'Desconocido';
      }
    },
  },
  {
    title: 'fecha de envio',
    dataIndex: 'updatedAt',
  },
  {
    title: '',
    dataIndex: 'action',
    fixed: 'right',
    hiddenMobile: true,
    render: (_, record) => {
      return (
        <Popover
          placement='topRight'
          trigger='hover'
          className='popover-actions'
          content={<ListActions {...record} />}
        >
          <Button>
            <IconDots />
          </Button>
        </Popover>
      );
    },
  },
];

const EmailsSent = () => {
  const dispatch = useDispatch();
  const { isMobileScreen } = useScreen();
  const {
    isLoadingList: isLoading,
    list,
    pagination,
  } = useSelector((state) => state.sentMail);
  const [querySearch, setQuerySearch] = useState('');
  const debouncedQuerySearch = useDebounce(querySearch);

  const handleGetEmailsSent = (data) => getSentMails(dispatch, data);

  useEffect(() => {
    handleGetEmailsSent({
      query: debouncedQuerySearch,
      page: 1,
      limit: pagination.pageSize,
    });
  }, [debouncedQuerySearch]);

  const handleChangeSearch = (e) => setQuerySearch(e.target.value);

  const handleResentMail = async (id, query) => {
    const success = await reSentMail(id, {
      query,
      page: pagination.current,
      limit: pagination.pageSize,
    });

    if (success) {
      handleGetEmailsSent({
        query,
        page: pagination.current,
        limit: pagination.pageSize,
      });
    }
  };

  const handleUpdateStatus = async (id, query) => {
    const success = await updateStatusSentMails(id, {
      query,
      page: pagination.current,
      limit: pagination.pageSize,
    });

    if (success) {
      handleGetEmailsSent({
        query,
        page: pagination.current,
        limit: pagination.pageSize,
      });
    }
  };

  return (
    <div>
      <div
        className={`flex justify-between ${
          isMobileScreen ? 'flex-col pt-2 mb-3' : 'items-center mt-1 mb-4'
        }`}
      >
        <Title>Filtrar lista de correos</Title>
        <Search
          placeholder={'Palabras clave'}
          style={{
            width: '100%',
            maxWidth: '300px',
          }}
          onChange={handleChangeSearch}
        />
      </div>
      {pagination?.total !== 1 ? (
        <span className='text-base'>
          Se encontraron <strong>{pagination?.total}</strong> correos
        </span>
      ) : (
        <span className='text-base'>
          Se encontró <strong>{pagination?.total}</strong> correo
        </span>
      )}
      {isMobileScreen ? (
        isLoading ? (
          [1, 2, 3].map((i) => (
            <Card className='mt-2' key={i}>
              <Skeleton />
            </Card>
          ))
        ) : (
          <>
            {list.map((user) => (
              <CardUserMobile
                key={user.id}
                columns={columns}
                item={{
                  ...user,
                  key: user.id,
                  isMobileScreen,
                  resentEmail: () => {
                    handleResentMail(user.id, querySearch);
                  },
                  updateStatus: () => {
                    handleUpdateStatus(user.id, querySearch);
                  },
                }}
              />
            ))}
            <PaginationMobile
              {...pagination}
              onChange={(page) => {
                handleGetEmailsSent({
                  limit: 5,
                  page,
                });
              }}
            />
          </>
        )
      ) : (
        <SimpleTable
          className={'mt-3'}
          loading={isLoading}
          columns={columns}
          dataSource={list.map((elem) => ({
            ...elem,
            key: elem.id,
            isMobileScreen,
            resentEmail: () => {
              handleResentMail(elem.id, querySearch);
            },
            updateStatus: () => {
              handleUpdateStatus(elem.id, querySearch);
            },
          }))}
          pagination={{
            ...pagination,
            onChange: (page) => {
              handleGetEmailsSent({
                limit: 5,
                page,
              });
            },
          }}
        />
      )}
    </div>
  );
};

export default memo(EmailsSent);
