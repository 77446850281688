import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useMapEvents } from 'react-leaflet';

import { getDataPointLeaflet } from '../../../lib/apiClient';
import { createRoadEmergency } from '../../../services/roadEmergency';
import { createFiremenEmergency } from '../../../services/emergencies';
import { createConflict } from '../../../services/conflict';
import { loadPolyDetails } from '../../../lib/panelTool';
import { closePopUp } from '../../../lib/utils';
import { checkPermission } from '../../../lib/security';

import { CalendarIcon, EditIcon, StarIcon } from '../utils';
import ConflictForm from '../LayersTypes/LayerSocialConflict/ConflictForm';
import RoadEmergenciesForm from '../LayersTypes/LayerRoadEmergency/RoadEmergenciesForm';
import FiremenEmergenciesForm from '../LayersTypes/LayerFireMan/FiremenEmergenciesForm';
import PopupLayer from '../../../common/PopupLayer';
import {
  CONTROLMAP_DRAWER_VISIBLE,
  CONTROLMAP_SELECTED,
  COMMON_FORECAST_POINT_DATA,
} from '../../../redux/actions/actionsType';

const PopupClickHandler = () => {
  const dispatch = useDispatch();
  const {
    isBlockedClick,
    activeLayer,
    activeSubLayer,
    activeTool,
    selectedFeature,
    layerPolygon,
    conflictStates,
    map,
    user,
  } = useSelector((state) => ({
    isBlockedClick: state.maps.isBlockedClick,
    activeLayer: state.maps.activeLayer,
    activeSubLayer: state.maps.activeSubLayer,
    activeTool: state.maps.activeTool,
    selectedFeature: state.myLayers.selectedFeature,
    layerPolygon: state.myLayers.layerPolygon,
    conflictStates: state.conflict.filter?.states,
    map: state.maps.map,
    user: state.auth.user,
  }));
  const [position, setPosition] = useState(null);
  const [data, setData] = useState(null);
  const [isOpenForm, setIsOpenForm] = useState(false);

  const handleClick = async (e) => {
    if ((activeTool && activeTool !== 'clear') || isBlockedClick) {
      return;
    }

    setPosition(e.latlng);

    getDataPointLeaflet(e.latlng, activeSubLayer)
      .then((dataPoint) => {
        setData(dataPoint?.features?.[0]?.properties?.GRAY_INDEX);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const createSocialConflictPoint = async (data, setIsOpenForm, states) => {
    await createConflict(data, dispatch, states);
    setIsOpenForm(false);
  };

  const createRoadEmergencyPoint = async (data, setIsOpenForm) => {
    await createRoadEmergency(data, dispatch);
    setIsOpenForm(false);
  };

  const createFiremenEmergencyPoint = async (data, setIsOpenForm) => {
    await createFiremenEmergency(dispatch, data);
    setIsOpenForm(false);
  };

  const onClickForecast = (data) => {
    const { id, lat, lng } = data;
    dispatch({ type: CONTROLMAP_SELECTED, payload: 'forecast' });
    dispatch({ type: CONTROLMAP_DRAWER_VISIBLE, payload: true });
    dispatch({ type: COMMON_FORECAST_POINT_DATA, payload: { id, lat, lng } });
  };

  useMapEvents({
    click: handleClick,
  });

  const _handlePolySimulation = () => {
    if (layerPolygon) {
      loadPolyDetails({ polygon: layerPolygon, filters: {}, dispatch, map });
    }
  };

  const onOk = useCallback(
    (dataForm) => {
      switch (activeSubLayer?.id) {
        case 'conflictos':
          createSocialConflictPoint(dataForm, setIsOpenForm, conflictStates);
          break;
        case 'emergencias_vial':
          createRoadEmergencyPoint(dataForm, setIsOpenForm);
          break;
        case 'bomberos':
          createFiremenEmergencyPoint(dataForm, setIsOpenForm);
          break;
        default:
          break;
      }
      closePopUp();
    },
    [activeSubLayer],
  );

  const EmergencyForm = useMemo(() => {
    switch (activeSubLayer?.id) {
      case 'conflictos':
        return ConflictForm;
      case 'emergencias_vial':
        return RoadEmergenciesForm;
      case 'bomberos':
        return FiremenEmergenciesForm;
      default:
        return <></>;
    }
  }, [activeSubLayer]);

  const isAdminRimac = user.rol.slug === 'admin_rimac';
  const canAdministrateManualPoints = checkPermission(
    user,
    'administrate_manual_points_emergency',
  );
  const isEmergenciesLayer = activeLayer?.id === 'emergencias';
  const isConflictsSubLayer = activeSubLayer?.id === 'conflictos';
  const hasForecast = activeLayer?.forecast;

  const handleOpenForm = () => setIsOpenForm(true);
  const handleForecastClick = () => onClickForecast(position);
  const handlePolySimulation = () => _handlePolySimulation();

  return (
    <Fragment>
      {position && (
        <PopupLayer position={position} interactive>
          <PopupLayer.Header
            dataLayer={data}
            activeLayer={activeLayer}
            activeSubLayer={activeSubLayer}
          >
            {isEmergenciesLayer && canAdministrateManualPoints && (
              <span onClick={handleOpenForm}>
                <EditIcon />
                <span>
                  {isConflictsSubLayer
                    ? 'Agregar conflicto'
                    : 'Agregar emergencia'}
                </span>
              </span>
            )}
          </PopupLayer.Header>
          <PopupLayer.Position />
          <PopupLayer.Footer>
            {hasForecast && (
              <div
                onClick={handleForecastClick}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '5px',
                  color: '#4f4fff',
                  fontWeight: 'bold',
                }}
              >
                <CalendarIcon />
                <span>Pronóstico a largo plazo</span>
              </div>
            )}
            {selectedFeature && isAdminRimac && (
              <div onClick={handlePolySimulation}>
                <StarIcon />
                <span>Simular polígono</span>
              </div>
            )}
          </PopupLayer.Footer>
        </PopupLayer>
      )}
      {isOpenForm && (
        <EmergencyForm
          visible={isOpenForm}
          openForm={setIsOpenForm}
          onOk={onOk}
          object={position}
        />
      )}
    </Fragment>
  );
};

export default PopupClickHandler;
