import {
  LOADED_LEVEL_COMPANY,
  LOAD_LEVEL_COMPANY_LOADING,
  LOADED_ALL_LEVEL_COMPANY,
} from '../actions/actionsType';

const initialState = {
  list: [],
  listPag: [],
  isLoadingList: false,
  pagination: {
    pageSize: 5,
    current: 1,
    total: 0,
  },
};

const levelCompany = (state, action) => {
  if (!state) state = initialState;

  switch (action.type) {
    case LOAD_LEVEL_COMPANY_LOADING:
      return {
        ...state,
        isLoadingList: action.payload,
      };
    case LOADED_LEVEL_COMPANY:
      return {
        ...state,
        list: action.payload,
        isLoadingList: false,
      };
    case LOADED_ALL_LEVEL_COMPANY:
      if (state.query && state.query !== action.payload.query) return state;

      return {
        ...state,
        listPag: action.payload.list,
        pagination: {
          current: action.payload.page,
          pageSize: action.payload.items_per_page,
          total: action.payload.total,
        },
        isLoadingList: false,
      };
    default:
      return state;
  }
};

export default levelCompany;
