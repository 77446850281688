import { Popover, Button } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  AlertOutlined,
  MoreOutlined,
} from '@ant-design/icons';

const PopoverOptions = ({
  location,
  onDeleteLocation,
  allow_alerts,
  onOpenForm,
  onUpdateLocation,
  isMainLocation,
}) => {
  const dataSource = [];

  if (!isMainLocation) {
    dataSource.push({
      action: () => onOpenForm(location),
      icon: <EditOutlined />,
      title: 'Editar',
      key: 'edit',
    });
  }

  /*   dataSource.push({
    action: onUpdateLocation,
    icon: <AlertOutlined />,
    title: `${allow_alerts ? 'Desactivar' : 'Activar'} alertas`,
    key: 'disable',
  }); */

  if (!isMainLocation) {
    dataSource.push({
      action: () => onDeleteLocation(),
      icon: <DeleteOutlined />,
      title: 'Eliminar',
      key: 'delete',
    });
  }

  return (
    <Popover
      placement='topRight'
      content={
        <div>
          {dataSource.map(({ action, icon, key, title }) => (
            <Button
              className='flex gap-2 items-center'
              key={key}
              onClick={action}
              type='link'
            >
              {icon}
              {title}
            </Button>
          ))}
        </div>
      }
    >
      <Button className='reset-button'>
        <MoreOutlined />
      </Button>
    </Popover>
  );
};

export default PopoverOptions;
