import { get, post, put } from '../lib/ajax';
import {
  LOADED_PAG_TYPE_INDUSTRY,
  LOADED_TYPE_INDUSTRY,
  LOADING_CREATE_TYPE_INDUSTRY,
  LOAD_TYPE_INDUSTRY_LOADING,
  OPEN_FORM_TYPE_INDUSTRY,
} from '../redux/actions/actionsType';

export const getTypeIndustry = async (dispatch) => {
  const r = await get('/type_industry/list');
  if (r.data.success)
    dispatch({ type: LOADED_TYPE_INDUSTRY, payload: r.data.payload });
};

export const getTypeIndustryNew = async () => {
  const r = await get('/type_industry');
  if (r.data.success) return r.data.payload.list;

  return null;
};

export const getPagTypeIndustry = async (dispatch, pagination) => {
  dispatch({ type: LOAD_TYPE_INDUSTRY_LOADING, payload: true });

  const r = await post('/type_industry/listPagination', pagination);
  if (r.data.success) {
    dispatch({ type: LOADED_PAG_TYPE_INDUSTRY, payload: r.data.payload });

    return true;
  }

  return false;
};

export const createTypeIndustryPag = async (typeIndustry, dispatch) => {
  dispatch?.({ type: LOADING_CREATE_TYPE_INDUSTRY, payload: true });
  const r = await post('/type_industry/create', typeIndustry);

  if (r.data.success) {
    dispatch?.({ type: OPEN_FORM_TYPE_INDUSTRY, payload: false });
    dispatch?.({ type: LOADING_CREATE_TYPE_INDUSTRY, payload: false });
    dispatch &&
      (await getPagTypeIndustry(dispatch, {
        page: 1,
        items_per_page: 5,
        query: '',
      }));

    return true;
  }
  dispatch?.({ type: LOADING_CREATE_TYPE_INDUSTRY, payload: false });

  return false;
};

export const updateTypeIndustryPag = async (
  typeIndustry,
  dispatch,
  pagination = {},
) => {
  dispatch({ type: LOADING_CREATE_TYPE_INDUSTRY, payload: true });
  const r = await put('/type_industry/update', typeIndustry);

  if (r.data.success) {
    dispatch({ type: OPEN_FORM_TYPE_INDUSTRY, payload: false });
    dispatch({ type: LOADING_CREATE_TYPE_INDUSTRY, payload: false });
    dispatch && (await getPagTypeIndustry(dispatch, pagination));

    return true;
  }
  dispatch({ type: LOADING_CREATE_TYPE_INDUSTRY, payload: false });

  return false;
};

export const removeTypeIndustryPag = async (id, dispatch, pagination) => {
  const r = await post('/type_industry/remove?id=' + id);
  if (r.data.success) {
    await getPagTypeIndustry(dispatch, pagination);

    return true;
  }

  return false;
};
