import React from 'react';
import Chart from 'react-apexcharts';
import moment from 'moment';
import es from 'apexcharts/dist/locales/es.json';
import { useScreen } from '../../../../../lib/screen';
import { Card } from 'antd';

const IndicatorChart = ({
  title,
  data = [],
  months,
  percent,
  height = '200',
}) => {
  const { isMobileScreen } = useScreen();

  const dataToShow = isMobileScreen ? data?.slice(0, 3) : data;

  return (
    <Card className='mt-4 pt-2'>
      <Chart
        options={{
          legend: {
            position: 'top',
            ...(isMobileScreen
              ? {
                  horizontalAlign: 'left',
                  offsetY: 0,
                }
              : {
                  horizontalAlign: 'right',
                  offsetY: -30,
                }),
            markers: {
              radius: '50%',
            },
            floating: false,
          },
          title: {
            text: title,
            color: '#141938',
            align: 'left',
            style: isMobileScreen
              ? {
                  maxWidth: 122,
                  textOverflow: 'ellipsis',
                  display: 'flex',
                }
              : {},
          },
          xaxis: {
            categories:
              dataToShow?.map((item) =>
                months
                  ? moment().set('month', item.month).format('MMMM')
                  : item.name || '',
              ) ?? [],
            labels: {
              style: {
                colors: '#727697',
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                colors: '#727697',
              },
            },
          },
          colors: ['#7D7DFA', '#FFCA61'],
          chart: {
            defaultLocale: 'es',
            locales: [es],
            toolbar: {
              show: true,
              tools: {
                download:
                  '<img src="/images/icons/more_horiz.svg" class="ico-download" />',
              },
              ...(isMobileScreen
                ? {
                    offsetX: 0,
                    offsetY: 0,
                  }
                : {
                    offsetX: -16,
                    offsetY: -4,
                  }),
            },
          },
          dataLabels: {
            style: {
              colors: ['#1F2345'],
              fontWeight: 400,
              color: '#1F2345',
              fontSize: '10px',
            },
            formatter: (val) => {
              return val && percent ? val + '%' : val;
            },
          },
        }}
        series={[
          {
            name: 'Real',
            data:
              dataToShow?.map((item) =>
                Math.round(
                  item.accumulatedAdvance != null
                    ? item.accumulatedAdvance
                    : item.real,
                ),
              ) ?? [],
          },
          {
            name: 'Meta',
            data:
              dataToShow?.map((item) =>
                Math.round(
                  item.monthlyGoal != null
                    ? item.monthlyGoal
                    : item.expectedGoal,
                ),
              ) ?? [],
          },
        ]}
        height={height}
        type='bar'
      />
    </Card>
  );
};

export default IndicatorChart;
