import {
  LOAD_LOCATIONS_LOADING,
  LOADED_LOCATIONS,
  LOADING_CREATE_LOCATIONS,
  OPEN_FORM_LOCATIONS,
  LOADING_UPDATE_ALL_ALERTS_LOCATIONS,
} from '../actions/actionsType';

const initialState = {
  list: [],
  openedForm: false,
  isLoadingList: false,
  isLoadingCreate: false,
  isLoadingUpdateAllAlerts: false,
};

const locations = (state, action) => {
  if (!state) state = initialState;

  switch (action.type) {
    case LOAD_LOCATIONS_LOADING:
      return {
        ...state,
        isLoadingList: action.payload,
      };
    case LOADED_LOCATIONS:
      return {
        ...state,
        list: action.payload,
        isLoadingList: false,
      };
    case OPEN_FORM_LOCATIONS:
      return {
        ...state,
        openedForm: action.payload,
      };
    case LOADING_CREATE_LOCATIONS:
      return {
        ...state,
        isLoadingCreate: action.payload,
      };
    case LOADING_UPDATE_ALL_ALERTS_LOCATIONS:
      return {
        ...state,
        isLoadingUpdateAllAlerts: action.payload,
      };
    default:
      return state;
  }
};

export default locations;
