import React from 'react';
import Alarm from './icons/Alarm';
import Cloudy from './icons/Cloudy';
import Default from './icons/Default';
import Monitoreo from './icons/Monitoreo';
import Rain from './icons/Rain';
import Satelite from './icons/Satelite';
import Sealevel from './icons/Sealevel';
import Temperature from './icons/Temperature';
import Thunder from './icons/Thunder';
import Tsunami from './icons/Tsunami';
import Wind from './icons/Wind';
import Visibility from './icons/Visibility';
import Cumulus from './icons/Cumulus';
import Roads from './icons/Roads';
import Coordinates from './icons/Coordinates';
import Polygon from './icons/Polygon';
import Circle from './icons/Circle';
import Filters from './icons/Filters';
import Clear from './icons/Clear';

export const ICONS = {
  // MapIcons
  satellite: <Satelite />,
  VISIBLE: <Satelite />,
  INFRA: <Satelite />,
  wind: <Wind />,
  clouds: <Cloudy />,
  hc: <Cloudy />,
  mc: <Cloudy />,
  lc: <Cloudy />,
  rains: <Rain />,
  rain: <Rain />,
  pp: <Rain />,
  pp_acc: <Rain />,
  pp_acc_12: <Rain />,
  pp_acc_24: <Rain />,
  pp_acc_72: <Rain />,
  pp_acc_120: <Rain />,
  hr: <Rain />,
  nino_nina: <Rain />,
  ligthing: <Thunder />,
  flashed: <Thunder />,
  ligthing_den: <Thunder />,
  ligthing_ene: <Thunder />,
  ligthing_aen: <Thunder />,
  waves: <Sealevel />,
  wave: <Sealevel />,
  temperature: <Temperature />,
  temp: <Temperature />,
  Emergencias: <Alarm />,
  sismos: <Monitoreo />,
  'sismos-map': <Monitoreo />,
  tsunamis: <Tsunami />,
  vis_horizontal: <Visibility />,
  'vis_horizontal+': <Visibility />,
  'weather-forecast': <Rain />,

  // UtilIcons
  red_vial_departamental_dic18: <Roads />,
  red_vial_nacional_dic18: <Roads />,
  red_vial_vecinal_dic18: <Roads />,
  cumulo: <Cumulus />,

  // UtilsCumulusIcons
  marker: <Coordinates />,
  polygon: <Polygon />,
  circle: <Circle />,
  filter: <Filters />,
  clear: <Clear />,

  // ToolIcons
  ruler: <Polygon />,
};

export const renderIcon = (key) => ICONS[key] || <Default />;
