import { Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import React from 'react';

const CardCourse = ({ course, handleEdit }) => {
  return (
    <article className='card-user'>
      <div className='flex pos-end'>
        <Button
          icon={<EditOutlined />}
          shape='circle'
          size='small'
          onClick={() => handleEdit(course)}
        />
      </div>

      <div>
        <h4>Nombre</h4>
        <p>{course?.name || ''}</p>
      </div>

      <div>
        <h4>Tipo</h4>
        <p>{course?.typePoliza?.name || ''}</p>
      </div>
    </article>
  );
};

export default CardCourse;
