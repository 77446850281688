import React, { useEffect } from 'react';
import { Form, Radio, Button, Tooltip, Input } from 'antd';
import { LeftOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import {
  createRiskRainService,
  presignedRainRiskService,
} from '../../../../services/requestServices';
import { finishRiskRainService } from '../../../../services/requestServices';
import UploadEvidenceFile from './UploadEvidenceFile';
import cryptoUtils from '../../../../lib/crypto';

const { Item } = Form;

const keyForm = 'geologyForm';

const questionsLabels = [
  {
    label:
      'La geomorfologia de la zona corresponde a terrazas medias a altas no inundables con drenaje bueno a moderado.',
    key: 'geologyQuestion1',
    tooltipText:
      'La terrazas medias a altas son áreas de terreno que se encuentran a una altura intermedia o alta en comparación con el entorno contiguo, y generalmente cuando ocurren lluvias intensas, no se inunda fácilmente y el agua tiende a escurrirse o drenarse fácilmente.',
  },
  {
    label:
      'La pendiente de la zona donde se ubica la instalación es menor a 5°',
    key: 'geologyQuestion2',
    tooltipText:
      'Una pendiente de menos de 5, indica que el terreno o la zona en la que se encuentra la instalación tiene una inclinación suave, practicamente plana o con una ligera inclinación.',
  },
  {
    label:
      'La litología de la zona corresponde a rocas duras intrusivas de tipo granito, granodiorita, Se comportan muy estables y difícilmente erosivas',
    key: 'geologyQuestion3',
    tooltipText:
      'Las rocas intrusivas son conocidas por su resistencia y durabilidad, su comportamiento es estable y menos propensas a desgastarse por la acción del clima, agua o otros agentes naturales.',
  },
  {
    label:
      'La vegetación de la zona es de densidad alta, recubrimiento denso del suelo y el nivel de protección frente a los agentes externos es bueno.',
    key: 'geologyQuestion4',
    tooltipText:
      'Significa que hay gran cantidad de plantas y árboles en el área, capas gruesas de plantas que cubre el suelo.',
  },
  {
    label: 'La precipitación de la zona corresponde a un nivel de riesgo bajo',
    key: 'geologyQuestion5',
    tooltipText:
      'Precipitación es la cantidad de agua que cae del cielo en forma de lluvia, nieve, granizo, entre otros. Un nivel de riesgo bajo quiere decir que las lluvias normales o moderadas no representa una amenzada importante para las actividades o infraestructura del área.',
  },
];

const GeologyForm = ({ setCurrent, current, onNext }) => {
  const [form] = Form.useForm();
  const data = useSelector((state) => ({
    geologyForm: state.rainForm.geologyForm,
    polizaId: state.rainForm.polizaId,
  }));
  const dispatch = useDispatch();

  const createFormulary = (formValues) => {
    createRiskRainService(
      {
        key: keyForm,
        value: formValues.map(({ key, label, value, file, comment }) => ({
          key,
          label,
          value,
          file,
          comment,
        })),
      },
      data.polizaId,
    ).then(() => {
      finishRiskRainService(data.polizaId).then(() => {
        onNext();
      });
    });
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      if (values) {
        dispatch({
          type: 'UPDATE_FORM_GEOLOGY_VALUES',
          payload: { ...(data?.geologyForm || {}), ...values },
        });

        const formValues = questionsLabels.map((question) => ({
          key: question.key,
          label: question.label,
          value: values[question.key],
          comment: values[`${question.key}-comment`],
          fileObject: values[`${question.key}-fileObject`],
          file: values[`${question.key}-fileObject`]
            ? `${cryptoUtils.randomStr(5)}-${
                values[question.key + '-fileObject'].name
              }`
            : null,
        }));

        const files = formValues.filter(
          (e) => e.fileObject && typeof e.fileObject === 'object',
        );
        if (files && files.length) {
          Promise.all(
            files.map(({ fileObject, file }) =>
              presignedRainRiskService(fileObject, file),
            ),
          ).then(() => {
            createFormulary(formValues);
          });
        } else {
          createFormulary(formValues);
        }
      }
    });
  };

  useEffect(() => {
    form.setFieldsValue(data.geologyForm);
    questionsLabels.forEach((question) => {
      if (data.geologyForm[`${question.key}-file`]) {
        const name = data.geologyForm[`${question.key}-file`];
        const fileUrl = `${process.env.REACT_APP_WEB_PATH_S3_NEW}rain_risk/${name}`;
        fetch(fileUrl)
          .then((res) => res.blob())
          .then((blob) => {
            const file = new File([blob], name);
            form.setFieldsValue({ [`${question.key}-fileObject`]: file });
          })
          .catch((err) => {
            console.error('Error fetching file: ', err);
          });
      }
    });
  }, []);

  return (
    <Form form={form} onFinish={handleSubmit} layout='vertical'>
      {questionsLabels.map(({ label, key, tooltipText }) => (
        <React.Fragment key={key}>
          <Item
            label={
              <span>
                {label}
                <Tooltip title={tooltipText}>
                  <QuestionCircleOutlined style={{ marginLeft: '5px' }} />
                </Tooltip>
              </span>
            }
            name={key}
          >
            <Radio.Group>
              <Radio value='1'>Cumple</Radio>
              <Radio value='2'>No cumple</Radio>
              <Radio value='3'>No aplica</Radio>
            </Radio.Group>
          </Item>
          <UploadEvidenceFile
            form={form}
            keyValue={key}
            value={form.getFieldValue(`${key}-file`)}
            onUpdateFile={(file) => {
              form.setFieldsValue({ [`${key}-fileObject`]: file });
            }}
          />
          <Item label='Comentario' name={`${key}-comment`}>
            <Input.TextArea />
          </Item>
        </React.Fragment>
      ))}
      <div className='flex gap-2 justify-center mb-3'>
        <Button
          type='primary'
          size='large'
          onClick={() => setCurrent(current - 1)}
        >
          <LeftOutlined />
          Anterior
        </Button>
        <Button type='primary' size='large' htmlType='submit'>
          Finalizar
        </Button>
      </div>
    </Form>
  );
};

export default GeologyForm;
