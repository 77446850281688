import {
  LOAD_COMPANIES_LOADING,
  LOADED_COMPANIES,
  LOADING_CREATE_USER_COMPANY,
  OPEN_FORM_COMPANY,
  CHANGE_COMPANY_QUERY,
  SELECT_ITEM_COMPANY,
} from '../actions/actionsType';

const initialState = {
  companies: [],
  openedForm: false,
  companiesShow: null,
  isLoadingCompanies: false,
  isLoadingCreate: false,
  companySelected: null,
  query: '',
  pagination: {
    pageSize: 5,
    current: 1,
    total: 0,
  },
};

const companies = (state, action) => {
  if (!state) state = initialState;

  switch (action.type) {
    case LOAD_COMPANIES_LOADING:
      return {
        ...state,
        isLoadingCompanies: action.payload,
      };
    case LOADED_COMPANIES:
      if (state.query && state.query !== action.payload.query) return state;

      return {
        ...state,
        companies: action.payload.list,
        isLoadingCompanies: false,
        pagination: {
          current: action.payload.page,
          pageSize: action.payload.items_per_page,
          total: action.payload.total,
        },
      };
    case OPEN_FORM_COMPANY:
      return {
        ...state,
        openedForm: action.payload,
      };
    case LOADING_CREATE_USER_COMPANY:
      return {
        ...state,
        isLoadingCreate: action.payload,
      };
    case CHANGE_COMPANY_QUERY:
      return {
        ...state,
        query: action.payload || '',
      };
    case SELECT_ITEM_COMPANY:
      return {
        ...state,
        companySelected: action.payload,
      };
    default:
      return state;
  }
};

export default companies;
