import {
  LOG_IN,
  LOG_IN_CHANGE_USER_TYPE,
  LOG_IN_LOADING,
  LOG_IN_WORKING,
  LOG_IN_LOADED_USER,
  LOG_IN_SIMULATE_USER,
} from '../actions/actionsType';
import { setAuthorizationHeader } from '../../lib/ajax';

const initialState = {
  isLogged: false,
  error: null,
  userType: null,
  loading: false,
  working: false,
  user: null,
  simulateUser: localStorage.getItem('simulate_user')
    ? localStorage.getItem('simulate_user')
    : null,
};

const auth = (state, action) => {
  if (!state) state = initialState;

  switch (action.type) {
    case LOG_IN_CHANGE_USER_TYPE:
      return {
        ...state,
        userType: action.payload.type || null,
        error: action.payload.error || null,
      };
    case LOG_IN:
      if (action.payload.success)
        if (
          action.payload.session &&
          action.payload.session.idToken &&
          action.payload.session.idToken.jwtToken
        )
          setAuthorizationHeader(action.payload.session.accessToken.jwtToken);

      if (action.payload.success) localStorage.setItem('isAuth', true);

      return {
        ...state,
        isLogged: action.payload.success,
        session: action.payload.session,
        user: action.payload.user ? action.payload.user : state.user,
        error: action.payload.e ? action.payload.e.message : null,
        working: false,
        loading: false,
      };
    case LOG_IN_LOADED_USER:
      return { ...state, user: action.payload.user };
    case LOG_IN_LOADING:
      return { ...state, loading: action.payload };
    case LOG_IN_WORKING:
      return { ...state, working: action.payload };
    case LOG_IN_SIMULATE_USER:
      localStorage.setItem('simulate_user', action.payload);

      return { ...state, simulateUser: action.payload };
    default:
      return state;
  }
};

export default auth;
