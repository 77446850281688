import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Divider, Form, Modal, Input } from 'antd';
import dayjs from 'dayjs';

import texts from './../../../../common/texts.json';
import text from './../../../../config/text_es.json';
import { SelectWithLabel } from './../../../../common/InputWithLabel';
import Title from '../../../../common/Typograph/Title';
import CODE_EQUIVALENCE from './codeEquivalence';

const formLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 24 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 24 } },
};

const RoadEmergenciesForm = ({ onOk, object, visible, openForm }) => {
  const [form] = Form.useForm();
  const { isLoadingCreatePoint, isLoadingUpdatePoint } = useSelector(
    (state) => state.roadEmergency,
  );

  const isLoadingPoint = useMemo(
    () => isLoadingCreatePoint || isLoadingUpdatePoint,
    [isLoadingCreatePoint, isLoadingUpdatePoint],
  );

  const codTransOpts = Object.entries(CODE_EQUIVALENCE).map(([key, value]) => {
    const name = value.includes('Tránsito') ? value.split(' ')[1] : value;

    return {
      key,
      id: key,
      title: value,
      name,
    };
  });

  const handleSave = async () => {
    try {
      let values = await form.validateFields();
      const { latitud, longitud, ...restValues } = values;

      const latitudStr = latitud.toString();
      const longitudStr = longitud.toString();

      onOk(
        object.id
          ? {
              ...restValues,
              latitud: latitudStr,
              longitud: longitudStr,
              id: object.id,
            }
          : {
              ...restValues,
              latitud: latitudStr,
              longitud: longitudStr,
              source: 'manual',
              state: 1,
            },
      );
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  };

  const handleCancel = () => {
    openForm(false);
  };

  const keys = Object.keys(texts).filter((k) => {
    return k.indexOf('map_roademergency') !== -1;
  });

  const createFormItem = (key, keyValue, initialValue, required) => {
    switch (keyValue) {
      case 'fechaActualizacion':
      case 'fechaEmergencia':
        return (
          <Form.Item
            key={key}
            name={keyValue}
            rules={[{ required, message: text.required_message }]}
            initialValue={
              initialValue
                ? dayjs(initialValue).format('YYYY-MM-DD')
                : undefined
            }
          >
            <input
              type='date'
              placeholder={texts[key]}
              className='full-width mb-2'
              value={
                initialValue ? dayjs(initialValue).format('YYYY-MM-DD') : ''
              }
            />
          </Form.Item>
        );
      case 'condicionTransitoCodigo':
        return (
          <Form.Item
            key={key}
            name={keyValue}
            initialValue={initialValue}
            rules={[{ required, message: text.required_message }]}
          >
            <SelectWithLabel
              style={{ marginBottom: '16px' }}
              label={texts[key]}
              options={codTransOpts}
              disabled={isLoadingPoint}
            />
          </Form.Item>
        );
      case 'idEmergenciaVial':
        return (
          <Form.Item
            key={key}
            name={keyValue}
            initialValue={initialValue}
            rules={[
              { required, message: text.required_message },
              () => ({
                validator(_, value) {
                  if (!value || /^\d+$/.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('El valor debe ser numérico'),
                  );
                },
              }),
            ]}
          >
            <Input
              className={'mb-2'}
              disabled={isLoadingPoint}
              addonBefore={texts[key]}
            />
          </Form.Item>
        );
      default:
        return (
          <Form.Item
            key={key}
            name={keyValue}
            initialValue={initialValue}
            rules={[{ required, message: text.required_message }]}
          >
            <Input
              className={'mb-2'}
              disabled={isLoadingPoint}
              addonBefore={texts[key]}
            />
          </Form.Item>
        );
    }
  };

  const getInitialValue = (key, object) => {
    const keyValue = key.split('_')[2];
    if (object?.id) return object[keyValue];
    if (key.includes('latitud')) return object?.lat;
    if (key.includes('longitud')) return object?.lng;
    return '';
  };

  return (
    <Modal
      title={''}
      onOk={handleSave}
      onCancel={handleCancel}
      open={visible}
      okText={object?.id ? 'Editar' : 'Crear'}
      cancelText='Cancelar'
      destroyOnClose
      confirmLoading={isLoadingPoint}
      cancelButtonProps={isLoadingPoint}
      maskClosable={false}
    >
      <Title type='bold-28' style={{ textAlign: 'center' }}>
        {object?.id ? 'Editar emergencia' : 'Crear emergencia'}
      </Title>
      <Divider className='mt-2 mb-3' />
      <Form
        {...formLayout}
        form={form}
        disabled={isLoadingPoint}
        className='form-emergencies'
      >
        {keys.map((key) => {
          const required = texts[key].includes('*');
          const keyValue = key.split('_')[2];
          const initialValue = getInitialValue(key, object);

          return createFormItem(key, keyValue, initialValue, required);
        })}
      </Form>
    </Modal>
  );
};

export default RoadEmergenciesForm;
