const SERVICES_ITEMS = [
  {
    img: '/images/portal/icon_vip-update.svg',
    title: 'Especialistas altamente calificados',
    description:
      'Contamos con especialistas en gestión de riesgos con alta experiencia y profesionalismo',
  },
  {
    img: '/images/portal/icon_person-cuorse.svg',
    title: 'Servicios dinámicos y lúdicos',
    description:
      'Contamos con herramientas y servicios virtuales y presenciales que facilitan el aprendizaje.',
  },
  {
    img: '/images/portal/icon_live-class.svg',
    title: 'Asesorías personalizadas',
    description:
      'Te ayudamos a estár un paso por delante de los riesgos que amenazan tus operaciones ahora y en el futuro.',
  },
  {
    img: '/images/portal/icon_emergency-training.svg',
    title: 'Preparación para situaciones adversas',
    description:
      'Ser resiliente como empresa para poder sostener la operación del negocio en tiempos de adversidad.',
  },
];

export default SERVICES_ITEMS;
