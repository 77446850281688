import { secureRandom } from '../../lib/utils';

const iconPrevention = 'svg-preventionService';
const iconResume = 'svg-resume';

export const subMenuMaps = ({ rol }) => [ // NOSONAR
  {
    key: 'monitoreo',
    name: 'MONITOREO',
    permission: ['show_maps', 'access_newsletters', 'access_my_locations'],
    children: [
      {
        parent: 'monitoreo',
        key: 'map',
        name: 'Fenómenos y eventos',
        icon: 'svg-world',
        to: '/monitoreo/mapas',
        permission: 'show_maps',
      },
      {
        parent: 'monitoreo',
        key: 'boletines',
        name: 'Boletines',
        icon: 'svg-book',
        to: '/monitoreo/boletines',
        permission: 'access_newsletters',
      },
      {
        parent: 'monitoreo',
        key: 'setting',
        name: 'Configuración',
        icon: 'svg-setting',
        to: '/monitoreo/configuracion',
        permission: 'access_my_locations',
      },
    ],
  },
  {
    key: 'prevencion',
    name: 'PREVENCIÓN',
    permission: [
      'show_virtual_courses',
      'read_request_services',
      'list_self_providers_request_service',
      'allow_purchase_order',
    ],
    children: [
      {
        parent: 'prevencion',
        key: 'service_prevention',
        name: 'Servicios de Prevención',
        icon: iconPrevention,
        to: '/servicio-de-prevencion',
        permission: ['read_request_services'],
      },
      {
        parent: 'prevencion',
        key: 'autoevaluation_rain_risk',
        name: 'Auto-evaluación de Riesgo por Lluvia',
        icon: iconPrevention,
        to: '/evaluacion-de-riesgo-por-lluvia',
        permission: ['view_autoevaluation_rain_risk'],
      },
      {
        parent: 'prevencion',
        key: 'a6.2.1',
        name: 'Solicitudes del proveedor',
        icon: iconPrevention,
        permission: ['list_self_providers_request_service'],
        to: '/servicio-de-prevencion-solicitud-proveedor',
      },
      {
        parent: 'prevencion',
        key: 'a6.2.2',
        name: 'Preliquidación',
        icon: iconResume,
        permission: ['list_self_providers_request_service'],
        to: '/servicio-de-prevencion-preliquidacion',
      },
      {
        parent: 'prevencion',
        key: 'a6.2.3',
        name: 'Orden de compra',
        icon: iconResume,
        permission: 'allow_purchase_order',
        to: '/solicitudes-de-orden-de-compra',
      },
      ['admin_rimac', 'gestor_de_cuenta'].includes(rol)
        ? {
            parent: 'prevencion',
            key: 'virtual-courses',
            name: 'Cursos',
            icon: 'svg-study',
            permission: ['show_virtual_courses'],
            to: '/cursos',
          }
        : {
            parent: 'prevencion',
            key: 'virtual-courses',
            name: 'Cursos virtuales',
            icon: 'svg-study',
            permission: 'show_virtual_courses',
            external: true,
            blank: true,
          },
    ],
  },
  {
    key: 'riesgos',
    name: 'GESTIÓN DE RIESGOS',
    permission: ['show_risk_services'],
    children: [
      {
        parent: 'riesgos',
        key: 'risk_services',
        name: 'Servicios de Gestión de Riesgos',
        icon: iconPrevention,
        to: '/servicio-de-riesgos',
        permission: ['show_risk_services'],
      },
      {
        parent: 'riesgos',
        key: 'link-diagnostic',
        name: 'Diagnóstico de madurez',
        icon: 'svg-study',
        permission: ['show_risk_services'],
        external: true,
        blank: true,
        to: 'https://diagnostico-riesgos.app-prevencionrimac.com/evaluation',
      },
      {
        parent: 'riesgos',
        key: 'admin-diagnostic',
        name: 'Administración Diagnóstico',
        icon: 'svg-study',
        permission: ['access_setting'],
        external: true,
        blank: true,
        to: 'https://diagnostico-riesgos.app-prevencionrimac.com/auth',
      },
    ],
  },
  {
    key: 'social',
    name: 'NOTICIAS',
    permission: ['access_news_google', 'access_news_twitter'],
    children: [
      {
        parent: 'social',
        key: 'google',
        name: 'Google',
        icon: 'svg-google',
        permission: 'access_news_google',
        to: '/noticias/google',
      },
      {
        parent: 'social',
        key: 'twitter',
        name: 'Twitter',
        icon: 'svg-twitter',
        permission: 'access_news_twitter',
        to: '/noticias/twitter',
      },
    ],
  },
  {
    key: 'documentation',
    name: 'DOCUMENTACIÓN',
    permission: ['access_view_documentation'],
    children: [
      {
        parent: 'documentation',
        key: 'documentation',
        name: 'Manuales',
        icon: 'svg-resume',
        to: '/documentacion',
        permission: 'access_view_documentation',
      },
    ],
  },
  {
    key: 'simulaciones',
    name: 'SIMULACIONES',
    permission: ['access_analysis_zone'],
    children: [
      {
        parent: 'simulaciones',
        key: 'cumulus',
        name: 'Cúmulo',
        icon: 'svg-cumulo',
        to: '/simulaciones/cumulo',
        permission: 'access_analysis_zone',
      },
      {
        parent: 'simulaciones',
        key: 'simulations',
        name: 'Listado',
        icon: iconResume,
        to: '/simulaciones/lista-simulaciones',
        permission: 'access_analysis_zone',
      },
    ],
  },
  {
    key: 'configuration',
    name: 'configuración del sistema',
    activePath: ['/configuration'],
    permission: ['allows_to_manager_accounts', 'access_setting'],
    children: [
      {
        parent: 'configuration',
        key: 'main-config',
        name: 'Configuraciones',
        to: '/configuracion',
        icon: 'svg-setting',
        permission: ['access_setting'],
      },
    ],
  },
  (() => {
    switch (rol) {
      case 'admin_rimac':
      case 'gestor_de_cuenta':
      case 'comercial':
        return {
          key: 'adminstration',
          name: 'Administración',
          permission: [
            'allows_to_manager_accounts',
            'access_report_indicators',
            'access_report_companies_polizas',
            'read_company',
            'access_report_companies_dealers_general',
            'access_value_offer',
            'list_users',
          ],

          children: [
            {
              parent: 'adminstration',
              key: 'resume',
              name: 'Resumen',
              to: '/administracion/resumen',
              icon: iconResume,
              exact: true,
              permission: [
                'access_report_companies_polizas',
                'access_report_companies_dealers_general',
                'access_report_request_service_items_survey',
                'access_report_indicators',
              ],
            },
            {
              parent: 'adminstration',
              key: 'business',
              name: 'Empresas',
              to: '/administracion/empresas',
              icon: 'svg-business',
              exact: true,
              permission: ['read_company'],
            },
            {
              parent: 'adminstration',
              key: 'users',
              name: 'Usuarios',
              to: '/administracion/usuarios',
              icon: 'svg-users',
              exact: true,
              permission: 'list_users',
            },
            {
              parent: 'adminstration',
              key: 'social-conflict',
              name: 'Conflictos sociales',
              to: '/administracion/conflictos-sociales',
              icon: 'svg-alert',
              exact: true,
              permission: 'allow_access_social_conflicts',
            },
            {
              parent: 'adminstration',
              key: 'offer-value',
              name: 'Oferta de valor',
              to: '/administracion/oferta-de-valor',
              icon: 'svg-offerValue',
              exact: true,
              permission: 'access_value_offer',
            },
            {
              parent: 'adminstration',
              key: 'tracking',
              name: 'Tracking de Alertas y Boletines',
              to: '/administracion/traking-de-alertas-y-boletines',
              icon: 'svg-alert',
              exact: true,
              permission: 'allow_access_traking_alerts_newsletters',
            },
            {
              parent: 'adminstration',
              key: 'client-tracking',
              name: 'Seguimiento a clientes',
              to: '/administracion/seguimiento-a-clientes',
              icon: 'svg-alert',
              exact: true,
              permission: 'allow_access_client_tracking',
            },
          ],
        };
      default:
        return {
          key: 'perfil_empresa',
          name: 'PERFIL DE LA EMPRESA',
          permission: 'access_view_profile_company',
          children: [
            {
              parent: 'perfil_empresa',
              key: 'resumen',
              name: 'Resumen',
              icon: 'svg-resume',
              to: '/administracion/perfil-empresa',
              permission: 'access_view_profile_company',
            },
            {
              parent: 'perfil_empresa',
              key: 'centro_ayuda',
              name: 'Centro de ayuda',
              icon: 'svg-help',
              permission: [
                'access_support_ticket',
                'allows_to_manager_accounts',
              ],
              to: '/soporte/centro-de-ayuda',
            },
          ],
        };
    }
  })(),
];

const getVectorTileLayerStyles = (properties) => ({
  fillOpacity: 0.3,
  fill: true,
  weight: 0,
  color: properties.Colores,
  fillColor: properties.Colores,
});

export const getPixelValuesToColorFnLDEN = (values) => {
  if (values[0] === 0) {
    return '#FFFFFF';
  }
  if (values[0] >= 40) {
    return '#7E0000';
  }
  if (values[0] >= 30) {
    return '#F10606';
  }
  if (values[0] >= 10) {
    return '#FF5900';
  }
  if (values[0] >= 1) {
    return '#FFFF00';
  }
  return '#FFFFFF';
};

export const getPixelValuesToColorFnLENE = (values) => {
  if (values[0] === 0) {
    return '#FFFFFF';
  }
  if (values[0] >= 3000) {
    return '#FDE725';
  }
  if (values[0] >= 1700) {
    return '#35B779';
  }
  if (values[0] >= 1000) {
    return '#1F9E89';
  }
  if (values[0] >= 500) {
    return '#31688E';
  }
  if (values[0] > 1) {
    return '#440154';
  }
  return '#FFFFFF';
};

export const getPixelValuesToColorFnLAEN = (values) => {
  if (values[0] === 0) {
    return '#FFFFFF';
  }
  if (values[0] >= 3000) {
    return '#F0FA21';
  }
  if (values[0] >= 1700) {
    return '#ED7953';
  }
  if (values[0] >= 1000) {
    return '#C8447C';
  }
  if (values[0] >= 500) {
    return '#7201A8';
  }
  if (values[0] > 1) {
    return '#0D0887';
  }
  return '#FFFFFF';
};

export const layers = [
  {
    id: 'satellite',
    name: 'Satélite',
    type: 'layer-map',
    animated: true,
    forecast: true,
    permission: 'show_satellite_layer',
    mode: [
      {
        id: 'VISIBLE',
        name: 'VISIBLE',
        type: 'layer-map',
        url: `${process.env.REACT_APP_GEOSERVER_MAPS_NEW}`,
        styles: 'dark',
        layersId: 'c02',
        regions: true,
        animate: {
          realtime: true,
          times: [12, 36, 72],
          splits: 12,
          unitTime: 'minute',
          format: 'hh:mm A',
        },
        unit: '%',
      },
      {
        id: 'INFRA',
        name: 'INFRA+',
        type: 'layer-map',
        url: `${process.env.REACT_APP_GEOSERVER_MAPS_NEW}`,
        styles: 'dark',
        layersId: 'c13',
        regions: true,
        default: true,
        animate: {
          realtime: true,
          times: [12, 36, 72],
          splits: 12,
          unitTime: 'minute',
          format: 'hh:mm A',
        },
      },
    ],
    unit: '°C',
  },
  {
    id: 'ligthing',
    name: 'Rayos',
    permission: 'show_ligthing_layer',
    mode: [
      {
        id: 'flashed',
        name: 'Descargas eléctricas',
        type: 'layer-flashed',
        path: `${process.env.REACT_APP_WEB_PATH_MAPS_NEW}rayos/flash_pts.geojson?u=${secureRandom}`,
        vectorTileLayerStyles: {
          data: getVectorTileLayerStyles,
        },
      },
      // {
      //   id: 'ligthing_den',
      //   name: 'Densidad',
      //   type: 'layer-ligthing_den',
      //   path: `${
      //     process.env.REACT_APP_WEB_PATH_MAPS_NEW
      //   }rayos/flash_den.tif?u=${secureRandom()}`,
      //   pixelValuesToColorFn: getPixelValuesToColorFnLDEN,
      // },
      // {
      //   id: 'ligthing_ene',
      //   name: 'Energía acumulada',
      //   type: 'layer-ligthing_ene',
      //   path: `${
      //     process.env.REACT_APP_WEB_PATH_MAPS_NEW
      //   }rayos/flash_ene.tif?u=${secureRandom()}`,
      //   pixelValuesToColorFn: getPixelValuesToColorFnLENE,
      // },
      // {
      //   id: 'ligthing_aen',
      //   name: 'Promedio energía acumulada',
      //   type: 'layer-ligthing_aen',
      //   path: `${
      //     process.env.REACT_APP_WEB_PATH_MAPS_NEW
      //   }rayos/flash_aen.tif?u=${secureRandom()}`,
      //   pixelValuesToColorFn: getPixelValuesToColorFnLAEN,
      // },
    ],
  },
  {
    id: 'pp',
    name: 'Lluvias',
    type: 'layer-map',
    animated: true,
    forecast: true,
    permission: 'show_precipitation_layer',
    mode: [
      {
        id: 'pp',
        name: 'Lluvias',
        type: 'layer-map',
        url: `${process.env.REACT_APP_GEOSERVER_MAPS_NEW}`,
        styles: 'dark',
        layersId: 'pp',
        regions: true,
        default: true,
        animate: {
          times: 72,
          splits: 1,
          days: true,
        },
      },
    ],
    unit: 'mm',
  },
  {
    id: 'pp_acc',
    name: 'Lluvias acumuladas',
    type: 'layer-map',
    permission: 'show_precipitation_acc_layer',
    mode: [
      {
        id: 'pp_acc_12',
        name: '12 horas',
        type: 'layer-map',
        url: `${process.env.REACT_APP_GEOSERVER_MAPS_NEW}`,
        styles: 'dark',
        format: 'tileLayer',
        layersId: 'pp_acc_12',
        regions: true,
        default: true,
      },
      {
        id: 'pp_acc_24',
        name: '24 horas',
        type: 'layer-map',
        url: `${process.env.REACT_APP_GEOSERVER_MAPS_NEW}`,
        styles: 'dark',
        format: 'tileLayer',
        regions: true,
        layersId: 'pp_acc_24',
      },
      {
        id: 'pp_acc_72',
        name: '3 Días',
        url: `${process.env.REACT_APP_GEOSERVER_MAPS_NEW}`,
        styles: 'dark',
        type: 'layer-map',
        format: 'tileLayer',
        regions: true,
        layersId: 'pp_acc_72',
      },
      {
        id: 'pp_acc_120',
        name: '5 Días',
        type: 'layer-map',
        url: `${process.env.REACT_APP_GEOSERVER_MAPS_NEW}`,
        styles: 'dark',
        format: 'tileLayer',
        regions: true,
        layersId: 'pp_acc_120',
      },
    ],
    unit: 'mm',
  },
  {
    id: 'weather-forecast',
    name: 'Pronóstico Climático',
    permission: 'show_weather-forecast_layer',
    mode: [
      {
        id: 'rainfall',
        type: 'layer-rainfall',
        default: true,
        name: 'Precipitaciones',
      },
      {
        id: 'max_temperature',
        type: 'layer-max-temperature',
        name: 'Temperatura máxima',
      },
      {
        id: 'min_temperature',
        type: 'layer-min-temperature',
        name: 'Temperatura mínima',
      },
    ],
  },
  {
    id: 'hr',
    name: 'Humedad relativa',
    type: 'layer-map',
    animated: true,
    permission: 'show_humidity-relative_layer',
    mode: [
      {
        id: 'hr',
        name: 'Humedad relativa',
        type: 'layer-map',
        url: `${process.env.REACT_APP_GEOSERVER_MAPS_NEW}`,
        styles: 'light',
        layersId: 'hr',
        regions: true,
        default: true,
        animate: {
          times: 72,
          splits: 1,
          days: true,
        },
      },
    ],
    unit: '%',
  },
  {
    id: 'temp',
    name: 'Temperatura',
    type: 'layer-map',
    animated: true,
    forecast: true,
    permission: 'show_temperature_layer',
    mode: [
      {
        id: 'temp',
        name: 'Temperatura',
        type: 'layer-map',
        url: `${process.env.REACT_APP_GEOSERVER_MAPS_NEW}`,
        styles: 'light',
        layersId: 'temp',
        regions: true,
        default: true,
        animate: {
          times: 72,
          splits: 1,
          days: true,
        },
      },
    ],
    unit: '°C',
  },
  {
    id: 'wind',
    name: 'Vientos',
    type: 'layer-map',
    animated: true,
    forecast: true,
    permission: 'show_winds_layer',
    mode: [
      {
        id: 'wind',
        name: 'Vientos',
        type: 'layer-map',
        url: `${process.env.REACT_APP_GEOSERVER_MAPS_NEW}`,
        styles: 'light',
        layersId: 'ws',
        regions: true,
        default: true,
        animate: {
          times: 72,
          splits: 1,
          days: true,
        },
      },
    ],
    unit: 'km/h',
  },
  {
    id: 'clouds',
    name: 'Nubes',
    type: 'layer-map',
    animated: true,
    permission: 'show_clouds_layer',
    mode: [
      {
        id: 'hc',
        name: 'Nubes altas',
        type: 'layer-map',
        url: `${process.env.REACT_APP_GEOSERVER_MAPS_NEW}`,
        styles: 'light',
        layersId: 'hc',
        regions: true,
        animate: {
          times: 72,
          splits: 1,
          days: true,
        },
      },
      {
        id: 'mc',
        name: 'Nubes medias',
        type: 'layer-map',
        url: `${process.env.REACT_APP_GEOSERVER_MAPS_NEW}`,
        styles: 'light',
        layersId: 'mc',
        regions: true,
        default: true,
        animate: {
          times: 72,
          splits: 1,
          days: true,
        },
      },
      {
        id: 'lc',
        name: 'Nubes bajas',
        type: 'layer-map',
        url: `${process.env.REACT_APP_GEOSERVER_MAPS_NEW}`,
        styles: 'light',
        layersId: 'lc',
        regions: true,
        default: true,
        animate: {
          times: 72,
          splits: 1,
          days: true,
        },
      },
    ],
    unit: '%',
  },
  {
    id: 'waves',
    name: 'Oleajes',
    type: 'layer-map',
    animated: true,
    permission: 'show_waves_layer',
    mode: [
      {
        id: 'waves',
        name: 'Oleajes',
        type: 'layer-map',
        url: `${process.env.REACT_APP_GEOSERVER_MAPS_NEW}`,
        styles: 'light',
        layersId: 'group_hcwv',
        regions: true,
        default: true,
        animate: {
          times: 72,
          splits: 1,
          days: true,
        },
      },
    ],
    unit: 'm',
  },
  {
    id: 'vis_horizontal',
    name: 'Visibilidad horizontal',
    animated: true,
    type: 'layer-map',
    permission: 'show_horizontal-view_layer',
    mode: [
      {
        id: 'vis_horizontal+',
        type: 'layer-map',
        name: 'Visibilidad horizontal',
        url: `${process.env.REACT_APP_GEOSERVER_MAPS_NEW}`,
        styles: 'dark',
        regions: true,
        layersId: 'vis',
        default: true,
        animate: {
          times: 72,
          splits: 1,
          days: true,
        },
      },
    ],
    unit: 'km',
  },
  {
    id: 'sismos',
    name: 'Sismos',
    permission: 'show_earthquakes_layer',
    mode: [
      {
        id: 'sismos-map',
        name: 'Sismos',
        type: 'layer-earthquake',
      },
    ],
  },
  {
    id: 'tsunamis',
    name: 'Tsunamis',
    permission: 'show_tsunamis_layer',
    mode: [
      {
        id: 'tsunamis-map',
        name: 'Tsunamis',
        type: 'layer-tsunami',
      },
    ],
  },
  {
    id: 'geological',
    name: 'Geología',
    permission: 'show_geological_layer',
    mode: [
      {
        id: 'peligros_geologicos',
        name: 'Peligros geológicos',
        type: 'layer-geological_hazards',
        url: `${process.env.REACT_APP_WEB_PATH_S3_NEW}shapes_files/main_file.zip`,
        vectorTileLayerStyles: {
          data: getVectorTileLayerStyles,
        },
      },
    ],
  },
  {
    id: 'emergencias',
    name: 'Emergencias',
    animated: true,
    permission: 'show_emergency_layer',
    mode: [
      {
        id: 'conflictos',
        type: 'layer-social-conflicts',
        name: 'Conflictos sociales',
        default: true,
      },
      {
        id: 'emergencias_vial',
        type: 'layer-road-emergency',
        name: 'Emergencias viales',
      },
      {
        id: 'bomberos',
        type: 'layer-fireman',
        name: 'Bomberos',
      },
    ],
    unit: '',
  },
];

export const utils = [
  {
    key: 'red_vial_departamental_dic18',
    name: 'Vías departamentales',
    type: 'road',
  },
  {
    key: 'red_vial_nacional_dic18',
    name: 'Vías nacionales',
    type: 'road',
  },
  {
    key: 'red_vial_vecinal_dic18',
    name: 'Vías vecinal',
    type: 'road',
  },
  {
    key: 'cumulo',
    name: 'Cúmulo',
    type: 'cumulo',
    animated: true,
    mode: [
      {
        styles: 'dark',
        default: true,
      },
    ],
  },
];

export const tools = [
  {
    key: 'ruler',
    name: 'Regla',
  },
  {
    key: 'clear',
    name: 'Limpiar',
  },
];

export const cumulusTools = [
  {
    key: 'marker',
    name: 'Coordenadas',
    title: 'Insertar coordenadas',
    icon: 'map-pin',
    drawer: true,
    descriptions: 'Ingresa una coordenada para consultar un evento',
  },
  {
    key: 'polygon',
    name: 'Trazado libre',
    icon: 'polygon',
  },
  {
    key: 'circle',
    name: 'Círculo',
    icon: 'circle',
  },
  {
    key: 'filter',
    name: 'Filtros',
    title: 'Filtros',
    icon: 'filter',
    drawer: true,
    descriptions: 'Selecciona o escribe las opciones que deseas visualizar',
  },
  {
    key: 'clear',
    name: 'Borrar',
    icon: 'clear',
    svg: true,
  },
];

export const weatherForecast = {
  rainfall: {
    type: 'rainfall',
    colors: ['#cd8742', '#ffdf82', '#ffffff', '#99ccff', '#3333ff', '#eb989f'],
  },
  'rainfall-efm': {
    type: 'rainfall-efm',
    colors: ['#cd8742', '#ffdf82', '#ffffff', '#99ccff', '#3333ff'],
  },
  'max-temperature': {
    type: 'maxTemperature',
    colors: ['#6ee7f2', '#dbf1fd', '#ffffff', '#ffdf82', '#feaa44'],
  },
  'min-temperature': {
    type: 'minTemperature',
    colors: ['#6ee7f2', '#dbf1fd', '#ffffff', '#ffdf82', '#feaa44'],
  },
};
