import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { notification } from 'antd';
import L from 'leaflet';
import { Popup, useMap } from 'react-leaflet';
import moment from 'moment';
import axios from 'axios';

import { getForecastDataPoint } from '../../../../services/myRoutes';
import {
  RainIcon,
  VisHorizontalIcon,
  WindIcon,
} from '../../CreateMyRoute/utils';
import { MY_ROUTES_SET_MY_ROUTE_LAYER } from '../../../../redux/actions/actionsType';

const NO_DATA = 'No hay datos';

const MyRoutes = () => {
  const dispatch = useDispatch();
  const activeRoute = useSelector((state) => state.myRoutes.activeRoute);

  const setMyRouteLayer = (routeLayer) =>
    dispatch({ type: 'MY_ROUTES_SET_MY_ROUTE_LAYER', payload: routeLayer });

  const map = useMap();
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [dataPoint, setDataPoint] = useState(null);
  const polylineRef = useRef(null);
  const markerLayerGroupRef = useRef(null);

  const transformCoordinatesToLatLng = (coordinates) =>
    coordinates.map((coordinate) => ({
      lat: coordinate[1],
      lng: coordinate[0],
    }));

  const handleClickOnMarker = (e, marker) => {
    setSelectedMarker(null);
    L.DomEvent.stopPropagation(e);
    getForecastDataPoint(marker).then((data) => {
      setSelectedMarker(marker);
      setDataPoint(data);
    });
  };

  useEffect(() => {
    const fetchGeoJSONData = async () => {
      if (activeRoute) {
        const res = await axios.get(activeRoute?.url);

        if (res.status === 200) {
          const geojson = res.data;
          if (!geojson.features) {
            notification.error({ message: 'No hay ruta para mostrar.' });

            return;
          }

          markerLayerGroupRef.current = L.layerGroup();
          if (geojson.type === 'FeatureCollection') {
            geojson.features.forEach((feature) => {
              if (feature.geometry.type === 'LineString') {
                const points = transformCoordinatesToLatLng(
                  feature.geometry.coordinates,
                );
                polylineRef.current = L.polyline(points, { weight: 10 }).addTo(
                  map,
                );

                if (polylineRef.current) {
                  setMyRouteLayer(polylineRef.current);
                  map.fitBounds(polylineRef.current.getBounds());
                }
              } else if (feature.geometry.type === 'Point') {
                const point = {
                  lat: feature.geometry.coordinates[1],
                  lng: feature.geometry.coordinates[0],
                };
                const marker = L.circleMarker(point, {
                  radius: 7,
                  color: '#141938',
                  stroke: true,
                  fillOpacity: 1,
                  fillColor: '#FFF',
                  className: 'route-marker',
                }).addTo(map);
                markerLayerGroupRef.current.addLayer(marker);

                marker.on('click', (e) => handleClickOnMarker(e, point));
              }
            });
          }
          markerLayerGroupRef.current.addTo(map);
        }
      }
    };

    fetchGeoJSONData();

    return () => {
      if (polylineRef.current) {
        setMyRouteLayer(null);
        map.removeLayer(polylineRef.current);
      }
      if (markerLayerGroupRef.current) {
        map.removeLayer(markerLayerGroupRef.current);
      }
    };
  }, [activeRoute, map]);

  return (
    <>
      {selectedMarker && dataPoint && (
        <Popup position={selectedMarker}>
          <div className='route-popUp'>
            <strong className='route-popUp__title'>Pronósticos:</strong>
            <ul className='route-popUp__list'>
              {dataPoint.map((forecast, idx) => {
                return (
                  <li key={idx} className='route-popUp__list-item'>
                    {moment(forecast?.time).format('DD/MM/YY HH:mm a')}
                    <ul className='forecasts'>
                      <li className='forecasts__item'>
                        <RainIcon />
                        <span>{forecast?.rain || NO_DATA}</span>
                      </li>
                      <li className='forecasts__item'>
                        <VisHorizontalIcon />
                        <span>{forecast?.vis_horizontal || NO_DATA}</span>
                      </li>
                      <li className='forecasts__item'>
                        <WindIcon />
                        <span>{forecast?.wind || NO_DATA}</span>
                      </li>
                    </ul>
                  </li>
                );
              })}
            </ul>
          </div>
        </Popup>
      )}
    </>
  );
};

export default MyRoutes;
