import {
  LOADED_SISMOS_TO_MAP,
  CHANGE_SISMO_FILTERS,
} from '../actions/actionsType';

const initialState = {
  sismosToMap: [],
  filters: {},
  isLoadingSismo: false,
};

const sismo = (state, action) => {
  if (!state) state = initialState;

  switch (action.type) {
    case LOADED_SISMOS_TO_MAP:
      return {
        ...state,
        sismosToMap: action.payload,
        isLoadingSismo: false,
      };
    case CHANGE_SISMO_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    default:
      return state;
  }
};

export default sismo;
