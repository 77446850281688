import React from 'react';

export const RainIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    version='1.0'
    preserveAspectRatio='xMidYMid meet'
    viewBox='114.15 62 261.67 366.56'
    width='16px'
    height='16px'
  >
    <g
      transform='translate(0.000000,490.000000) scale(0.100000,-0.100000)'
      fill='#000000'
      stroke='none'
    >
      <path d='M2339 4163 c-433 -491 -706 -878 -917 -1299 -279 -557 -351 -1060 -211 -1462 97 -277 298 -498 579 -638 536 -266 1244 -169 1638 225 342 341 420 848 226 1454 -132 409 -406 880 -797 1371 -135 168 -394 466 -407 466 -4 0 -54 -53 -111 -117z m905 -1957 c25 -24 39 -76 56 -206 27 -216 -7 -447 -93 -615 -33 -66 -111 -169 -147 -195 -55 -39 -131 -13 -145 50 -8 35 5 60 60 121 122 137 178 380 145 624 -25 188 -25 191 3 219 34 34 89 35 121 2z' />
    </g>
  </svg>
);

export const VisHorizontalIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    version='1.0'
    preserveAspectRatio='xMidYMid meet'
    viewBox='185.1 113.12 549.8 373.71'
    width='16px'
    height='16px'
  >
    <g
      transform='translate(0.000000,600.000000) scale(0.100000,-0.100000)'
      fill='#000000'
      stroke='none'
    >
      <path d='M4252 4854 c-458 -55 -890 -214 -1277 -468 -356 -234 -697 -591 -910 -951 -57 -97 -154 -289 -189 -373 l-25 -62 25 -62 c64 -155 200 -402 302 -548 493 -699 1247 -1147 2098 -1246 171 -20 585 -15 734 10 605 97 1090 320 1532 703 329 285 624 694 782 1081 l25 62 -26 63 c-73 179 -233 459 -354 620 -504 665 -1218 1076 -2035 1172 -160 19 -524 18 -682 -1z m634 -638 c428 -106 761 -410 898 -818 50 -147 59 -212 60 -398 0 -158 -2 -185 -27 -283 -63 -250 -179 -452 -359 -623 -164 -155 -347 -254 -578 -312 -93 -23 -124 -26 -280 -26 -157 0 -186 3 -283 27 -474 118 -815 459 -934 934 -25 98 -27 125 -27 283 1 186 10 251 60 398 132 397 472 713 875 813 132 33 186 38 344 34 117 -3 172 -9 251 -29z' />
      <path d='M4500 3743 c-306 -47 -545 -259 -627 -557 -21 -74 -23 -289 -5 -356 80 -285 285 -490 563 -561 68 -18 278 -18 339 0 148 42 252 101 353 199 87 85 147 182 188 302 30 86 33 106 33 220 1 76 -4 146 -13 179 -77 299 -316 523 -612 570 -62 11 -168 12 -219 4z' />
    </g>
  </svg>
);

export const WindIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    version='1.0'
    preserveAspectRatio='xMidYMid meet'
    viewBox='63.11 185.22 513.89 269.74'
    width='16px'
    height='16px'
  >
    <g
      transform='translate(0.000000,640.000000) scale(0.100000,-0.100000)'
      fill='#000000'
      stroke='none'
    >
      <path d='M4323 4535 c-151 -42 -277 -153 -329 -290 -26 -67 -26 -210 -1 -282 20 -57 88 -157 113 -167 26 -10 53 3 60 28 4 17 -5 37 -34 76 -180 238 6 559 324 560 129 0 228 -44 319 -141 268 -290 146 -745 -241 -897 -99 -40 -193 -52 -388 -52 -195 0 -216 -5 -216 -49 0 -40 19 -43 252 -38 192 3 234 7 298 26 124 36 232 95 314 172 93 87 141 159 180 269 26 74 30 99 30 195 1 121 -15 197 -60 289 -60 124 -177 235 -294 282 -82 33 -245 42 -327 19z' />
      <path d='M3347 4190 c-111 -28 -211 -112 -247 -208 -18 -45 -21 -72 -18 -132 10 -161 117 -270 265 -270 102 0 179 46 224 132 42 82 13 203 -62 258 -75 54 -161 47 -231 -19 -32 -29 -41 -46 -45 -82 -6 -54 15 -111 50 -133 49 -32 82 12 52 69 -24 42 -14 80 26 100 93 48 179 -74 120 -171 -34 -55 -127 -80 -198 -53 -128 48 -154 259 -43 352 63 54 109 71 190 71 124 1 217 -60 277 -182 25 -51 28 -68 28 -157 0 -91 -3 -106 -29 -160 -53 -107 -155 -185 -287 -220 -42 -12 -134 -15 -417 -15 -231 0 -370 -4 -383 -10 -22 -12 -25 -52 -5 -69 11 -9 116 -11 413 -9 382 4 400 5 457 26 119 45 231 132 283 220 166 282 -10 652 -317 668 -36 2 -82 -1 -103 -6z' />
      <path d='M652 3359 c-19 -7 -23 -15 -20 -41 l3 -33 115 -3 c95 -2 117 0 126 13 10 14 12 14 18 0 9 -24 82 -20 96 5 24 44 -37 87 -83 58 -18 -12 -26 -12 -47 -1 -29 15 -173 17 -208 2z' />
      <path d='M1068 3363 c-21 -5 -32 -36 -23 -63 6 -19 14 -21 93 -18 l87 3 3 30 c5 40 -19 55 -87 54 -31 -1 -64 -3 -73 -6z' />
      <path d='M1332 3363 c-22 -9 -34 -41 -22 -63 12 -23 68 -28 88 -8 35 35 -17 91 -66 71z' />
      <path d='M1456 3357 c-12 -9 -17 -23 -14 -42 l3 -30 135 0 135 0 3 30 c6 46 -17 55 -138 55 -71 0 -112 -4 -124 -13z' />
      <path d='M1852 3354 c-25 -17 -29 -46 -8 -63 9 -8 103 -11 322 -9 l309 3 3 30 c6 53 -4 55 -318 55 -242 0 -289 -2 -308 -16z' />
      <path d='M4686 3215 c-20 -20 -20 -25 0 -53 l15 -22 389 0 c330 0 396 -2 436 -16 99 -33 154 -103 154 -192 0 -64 -13 -98 -53 -140 -76 -79 -199 -57 -237 42 -21 58 21 127 79 128 45 1 74 -28 66 -67 -18 -88 -18 -93 4 -100 52 -16 91 33 91 113 0 84 -64 142 -157 142 -64 0 -112 -27 -147 -84 -57 -92 -29 -208 66 -272 166 -112 377 17 378 231 0 91 -29 154 -99 217 -91 82 -89 82 -557 86 -369 3 -413 1 -428 -13z' />
      <path d='M1532 3108 c-19 -19 -14 -45 10 -62 20 -14 124 -16 923 -16 748 0 909 -3 954 -15 132 -35 235 -114 288 -222 25 -52 28 -68 28 -158 0 -89 -3 -106 -28 -157 -34 -69 -97 -134 -157 -162 -63 -29 -179 -28 -236 2 -100 52 -144 116 -144 212 0 91 56 179 126 196 49 12 122 -2 159 -31 31 -23 40 -26 61 -16 51 23 14 91 -68 125 -21 9 -66 16 -101 16 -148 0 -255 -109 -265 -270 -8 -141 59 -249 196 -313 59 -28 77 -32 152 -31 109 0 179 26 255 95 94 87 145 203 145 333 0 208 -122 371 -338 455 l-67 26 -940 3 c-734 2 -943 0 -953 -10z' />
      <path d='M3829 3096 c-10 -22 -9 -29 6 -46 17 -19 31 -20 256 -20 251 0 319 -8 434 -51 344 -128 488 -480 326 -796 -29 -58 -123 -157 -178 -187 -100 -56 -231 -72 -340 -40 -74 22 -171 89 -211 148 -142 211 -16 499 218 499 188 0 306 -200 195 -331 -28 -34 -32 -64 -11 -82 62 -51 161 120 141 243 -28 167 -203 286 -380 257 -213 -35 -356 -264 -304 -489 37 -161 193 -305 368 -342 87 -18 223 -7 301 25 158 64 287 217 338 398 27 100 23 263 -10 358 -40 116 -74 168 -167 260 -68 66 -106 94 -171 127 -159 79 -197 86 -517 90 l-283 5 -11 -26z' />
      <path d='M1788 2979 c-27 -15 -24 -67 5 -74 12 -2 153 -6 312 -7 l290 -3 58 -28 c66 -33 101 -73 118 -138 21 -79 -9 -159 -80 -206 -86 -58 -211 1 -211 99 0 79 89 127 138 75 27 -28 27 -40 5 -78 -22 -37 -8 -64 32 -64 63 0 96 119 49 181 -40 54 -70 69 -138 69 -53 0 -68 -4 -98 -27 -48 -36 -78 -101 -78 -165 0 -105 108 -203 224 -203 109 0 208 81 242 197 50 173 -70 340 -267 373 -84 13 -578 13 -601 -1z' />
    </g>
  </svg>
);

const convertToCoordinates = (points) =>
  points.map((point) => [point.lng, point.lat]);

export const transformRouteToGeoJSON = (route) => {
  const coordinates = convertToCoordinates(route);
  const objGeoJSON = {
    type: 'Feature',
    properties: {
      'stroke-width': 10,
      stroke: '#3388ff',
      opacity: 1,
    },
    geometry: {
      coordinates,
      type: 'LineString',
    },
  };

  return objGeoJSON;
};

export const transformMarkersToGeoJSON = (marker) => {
  const coordinates = convertToCoordinates(marker);
  const objGeoJSON = coordinates.map((coordinate) => {
    return {
      type: 'Feature',
      properties: {
        color: '#141938',
        'fill-opacity': 1,
        'fill-color': '#FFF',
        radius: 7,
      },
      geometry: {
        coordinates: coordinate,
        type: 'Point',
      },
    };
  });

  return objGeoJSON;
};
