import { useEffect, useState } from 'react';
import { isLatLngSearch } from '../lib/utils';

let autocomplete;

const usePlacesAutocomplete = (querySearch, onUseCoordinates = () => {}) => {
  const [places, setPlaces] = useState([]);

  useEffect(() => {
    getPlaces(querySearch);
  }, [querySearch]);

  const getPlaces = (textSearch = '') => {
    if (textSearch.length < 3) {
      setPlaces([]);
    }

    if (!isLatLngSearch(textSearch)) {
      getPredictions(textSearch);
    } else {
      onUseCoordinates(isLatLngSearch(textSearch));
    }
  };

  const getPredictions = async (inputSearch) => {
    if (!autocomplete) {
      const { AutocompleteService } =
        await window.google.maps.importLibrary('places');
      autocomplete = new AutocompleteService();
    }

    const query = {
      input: inputSearch,
      componentRestrictions: {
        country: ['pe', 'cl', 'ec', 'ar', 'co', 've', 'bo', 'py', 'uy'],
      },
    };

    autocomplete.getPlacePredictions(query, (places, status) => {
      if (status === 'OK' || status === 'ZERO_RESULTS') {
        setPlaces(places || []);
      }
    });
  };

  return places;
};

export default usePlacesAutocomplete;
