import {
  TRACKING_SET_LIST,
  TRACKING_IS_LOADING,
  TRACKING_UPDATE_FILTERS,
  TRACKING_SET_PAGINATION,
} from '../actions/actionsType';

const initialState = {
  list: [],
  isLoading: false,
  filters: {},
  pagination: { pageSize: 5, page: 1, total: 0 },
};

const tracking = (state, action) => {
  if (!state) state = initialState;
  switch (action.type) {
    case TRACKING_SET_LIST:
      return {
        ...state,
        list: action.payload,
      };
    case TRACKING_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case TRACKING_UPDATE_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };
    case TRACKING_SET_PAGINATION:
      return {
        ...state,
        pagination: action.payload,
      };
    default:
      return state;
  }
};

export default tracking;
