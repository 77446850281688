import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AutoComplete, Button, Form, notification, Select } from 'antd';

import {
  MY_ROUTES_ENABLE_CREATE_ROUTE_MODE,
  MY_ROUTES_SET_DESTINATION,
  MY_ROUTES_SET_ORIGIN,
} from '../../../../redux/actions/actionsType';
import {
  createMyRoute,
  getPresignedMyRoutesUrl,
  sendGeoJSONFile,
} from '../../../../services/myRoutes';
import { transformDrawsToGeoJSON } from '../../../../lib/utils';
import usePlacesAutocomplete from '../../../../hooks/usePlacesAutocomplete';

import InputWithLabel from '../../../InputWithLabel';

const { Option } = Select;
const radiusOptions = ['5', '10', '15', '20'];

const FormRouteByGoogle = ({ setStep }) => {
  const dispatch = useDispatch();
  const { createRouteMode, geoJSONRoute } = useSelector((state) => ({
    createRouteMode: state.myRoutes.createRouteMode,
    geoJSONRoute: state.myRoutes.geoJSONRoute,
  }));
  const [form] = Form.useForm();
  const origin = useSelector((state) => state.myRoutes.origin);
  const destination = useSelector((state) => state.myRoutes.destination);
  const [originSearch, setOriginSearch] = useState('');
  const originPlaces = usePlacesAutocomplete(originSearch);
  const originAddresses = originPlaces.map((place) => place.description);
  const [destinationSearch, setDestinationSearch] = useState('');
  const destinationPlaces = usePlacesAutocomplete(destinationSearch);
  const destinationAddresses = destinationPlaces.map(
    (place) => place.description,
  );

  const disabledGenerateRoute = useMemo(
    () => !(origin && destination),
    [origin, destination],
  );

  const disabledSaveRoute = useMemo(
    () => disabledGenerateRoute || !createRouteMode,
    [disabledGenerateRoute, createRouteMode],
  );

  const hasErrors = (fieldsError) =>
    Object.keys(fieldsError).some((field) => fieldsError[field]);

  const handleSaveRoute = (e) => {
    e.preventDefault();
    form.validateFields(async (err, values) => {
      if (!err) {
        let file = transformDrawsToGeoJSON(geoJSONRoute);

        if (!file) {
          notification.error({
            message: 'No se ha podido generar el archivo geoJSON a guardar.',
          });

          return;
        }

        try {
          const res = await getPresignedMyRoutesUrl({ name: file.name });
          const { success, payload } = res?.data ?? {};
          const typeFile = file.name.split('.').pop();

          if (success && payload && payload?.url) {
            await sendGeoJSONFile(payload?.url, file);
            await createMyRoute({
              origin,
              destination,
              ...values,
              typeFile,
              url: payload.nameKey,
            });
          }
        } catch (error) {
          notification.error({
            message: 'Ha ocurrido un error al subir el archivo.',
          });
          console.error(error);
        } finally {
          setStep(1);
        }
      }
    });
  };

  const handleGenerateRoute = () => {
    if (origin.length > 0 && destination.length > 0) {
      form.setFieldsValue({
        name: `${origin} - ${destination}`,
      });
      generateMyRoute();
    }
  };

  const setOrigin = (origin) =>
    dispatch({ type: MY_ROUTES_SET_ORIGIN, payload: origin });
  const setDestination = (destination) =>
    dispatch({ type: MY_ROUTES_SET_DESTINATION, payload: destination });
  const generateMyRoute = () =>
    dispatch({ type: MY_ROUTES_ENABLE_CREATE_ROUTE_MODE, payload: true });
  const hideRouteOnMap = () =>
    dispatch({ type: MY_ROUTES_ENABLE_CREATE_ROUTE_MODE, payload: false });

  useEffect(() => {
    form.validateFields();

    return hideRouteOnMap;
  }, []);

  return (
    <Form onSubmit={handleSaveRoute} form={form}>
      <div className='flex flex-col gap-2 mt-2'>
        <div className='flex flex-col gap-2'>
          <strong>Origen:</strong>
          <AutoComplete
            id='input_search-origin'
            className='drawer-map-search__body__input__search autocomplete'
            placeholder='Palabra clave'
            style={{ width: '100%' }}
            onSearch={(text) => {
              setOriginSearch(text);
              setOrigin('');
            }}
            onSelect={(value) => {
              setOriginSearch(value);
              setOrigin(value);
            }}
            value={originSearch}
            dataSource={originAddresses}
            allowClear
          />
        </div>
        <div className='flex flex-col gap-2'>
          <strong>Destino:</strong>
          <AutoComplete
            id='input_search-destiny'
            className='drawer-map-search__body__input__search autocomplete'
            placeholder='Palabra clave'
            style={{ width: '100%' }}
            onSearch={(text) => {
              setDestinationSearch(text);
              setDestination('');
            }}
            onSelect={(value) => {
              setDestinationSearch(value);
              setDestination(value);
            }}
            value={destinationSearch}
            dataSource={destinationAddresses}
            allowClear
          />
        </div>
        <div className='flex flex-col gap-2'>
          <strong>Radio de análisis de peligros geológicos:</strong>
          <Form.Item
            name='geologicalRadius'
            initialValue='5'
            rules={[{ required: true, message: 'Este campo es requerido' }]}
            style={{ margin: 0 }}
          >
            <Select style={{ fontSize: '1rem' }}>
              {radiusOptions.map((option) => (
                <Option key={option} value={option}>
                  {option} m.
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div className='flex flex-col gap-2'>
          <strong>Nombre de la ruta:</strong>
          <Form.Item
            name='name'
            initialValue=''
            rules={[{ required: true, message: 'Este campo es requerido' }]}
          >
            <InputWithLabel label={'Nombre'} />
          </Form.Item>
        </div>
        <Button
          type='primary'
          size='small'
          className='mt-3'
          onClick={handleGenerateRoute}
          disabled={disabledGenerateRoute}
        >
          Generar ruta
        </Button>
        <Button
          type='primary'
          size='small'
          className='mt-1 full-width'
          htmlType='submit'
          disabled={disabledSaveRoute || hasErrors(form.getFieldsError())}
          onClick={() => form.submit()}
        >
          Guardar ruta
        </Button>
      </div>
    </Form>
  );
};

export default FormRouteByGoogle;
