import PropTypes from 'prop-types';
import { RideButton } from '@rimac-seguros/ride-system-components';

const Button = ({
  className,
  disabled,
  fullWidth,
  glyph,
  glyphPosition,
  href,
  loading,
  size,
  target,
  text,
  type,
  variant,
  onClick,
  ...props
}) => {
  return (
    <RideButton
      className={className}
      disabled={disabled}
      fullWidth={fullWidth}
      glyph={glyph}
      glyphPosition={glyphPosition}
      href={href}
      loading={loading}
      size={size}
      target={target}
      text={text}
      type={type}
      variant={variant}
      onClick={onClick}
      {...props}
    />
  );
};

Button.propTypes = {
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  glyphPosition: PropTypes.oneOf(['left', 'right']),
  loading: PropTypes.bool,
  size: PropTypes.oneOf(['x-large', 'large', 'medium', 'small']),
  text: PropTypes.string.isRequired,
  variant: PropTypes.oneOf([
    'fill',
    'fill-inverse',
    'outline',
    'outline-inverse',
    'text-primary',
    'text-secondary',
  ]),
};

export default Button;
