import { post, put, get } from '../lib/ajax';
import {
  CHANGE_CHANGELOG_QUERY,
  LOAD_CHANGELOG_LOADING,
  LOADED_CHANGELOG,
  LOADING_CREATE_CHANGELOG,
} from '../redux/actions/actionsType';

export const getChangelog = async (dispatch, params = {}) => {
  dispatch({ type: CHANGE_CHANGELOG_QUERY, payload: params.query });
  dispatch({ type: LOAD_CHANGELOG_LOADING, payload: true });

  const { data } = await post('/changelog/list', params);
  if (data.success) {
    dispatch({ type: LOADED_CHANGELOG, payload: data.payload });

    return true;
  }

  return false;
};

export const getAllChangelog = async (minVersion, roldId) => {
  const { data } = await get(
    `/changelog/listAll?min_version=${minVersion}&rolId=${roldId}`,
  );
  if (data.success) {
    return data.payload;
  }

  return [];
};

export const createChangelog = async (dispatch, data, pagination = {}) => {
  dispatch({ type: LOADING_CREATE_CHANGELOG, payload: true });
  const r = await post('/changelog/create', data);
  if (r.data.success) {
    dispatch({ type: LOADING_CREATE_CHANGELOG, payload: false });
    await getChangelog(dispatch, pagination);

    return true;
  }
  dispatch({ type: LOADING_CREATE_CHANGELOG, payload: false });

  return false;
};

export const updateChangelog = async (dispatch, data, pagination) => {
  dispatch({ type: LOADING_CREATE_CHANGELOG, payload: true });
  const r = await put('/changelog/update', data);
  if (r.data.success) {
    dispatch({ type: LOADING_CREATE_CHANGELOG, payload: false });
    await getChangelog(dispatch, pagination);

    return true;
  }
  dispatch({ type: LOADING_CREATE_CHANGELOG, payload: false });

  return false;
};

export const updateVersionViewed = async (data) => {
  const r = await put('/changelog/update_version_viewed', data);
  return r.data.success;
};

export const removeChangelog = async (dispatch, id, pagination = {}) => {
  const r = await post('/changelog/remove?id=' + id);
  if (r.data.success) {
    await getChangelog(dispatch, pagination);

    return true;
  }

  return false;
};
