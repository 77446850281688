import React, { useEffect, useState } from 'react';
import { Form, DatePicker, TimePicker, Modal, Button, Divider } from 'antd';
import moment from 'moment';

import { useScreen } from '../../../lib/screen';
import { getAdminClients } from '../../../services/requestServicesItems';
import text from '../../../config/text_es';
import Paragraph from '../../../common/Typograph/Paragraph';
import Title from '../../../common/Typograph/Title';

const formLayout = {
  labelCol: { xs: { span: 24 }, sm: { span: 8 } },
  wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
};

const FormCreateEventDate = ({
  visible,
  setVisible,
  doSendEventDate,
  item,
  courseKeySelected,
  setVisibleFormEventDateEdit,
  visibleFormEventDateEdit,
}) => {
  const [form] = Form.useForm();
  const { isMobileScreen } = useScreen();
  const [isLoading, setIsLoading] = useState(false);
  const [googleCalendarLink, setGoogleCalendarLink] = useState();
  const [outlookCalendarLink, setOutlookCalendarLink] = useState();
  const [clients, setClients] = useState([]);

  useEffect(() => {
    if (!item) return;
    getAdminClients(item.id).then((clients) =>
      setClients(clients.map((c) => c.username)),
    );
  }, [item]);

  const getInitialDate = () => {
    if (visibleFormEventDateEdit) {
      let initial = '';
      item?.requestServiceItemsData?.length > 0 &&
        item.requestServiceItemsData.forEach((itemData) => {
          if (itemData.key === 'event_date')
            initial = moment.unix(itemData.value).format('YYYY-MM-DD HH:mm');

          if (itemData.key === 'courses' && courseKeySelected)
            try {
              const courseData = JSON.parse(itemData.value);
              initial = moment
                .unix(courseData[courseKeySelected].visitDate)
                .format('YYYY-MM-DD HH:mm');
            } catch (err) {}
        });

      return initial ? moment(initial) : '';
    }

    return '';
  };

  useEffect(() => {
    if (!form.getFieldValue('date') || !form.getFieldValue('time')) return;
    const date = moment(
      form.getFieldValue('date').format('DD-MM-YYYY') +
        ' ' +
        form.getFieldValue('time').format('HH:mm'),
      'DD-MM-YYYY HH:mm',
    );
    const dateEnd = date.clone().add(30, 'minutes');
    setGoogleCalendarLink(
      `http://www.google.com/calendar/event?action=TEMPLATE&text=${
        item?.service?.name
      }+-+RIMAC&dates=${date.format('YYYYMMDD')}T${date.format(
        'HHmm',
      )}00/${dateEnd.format('YYYYMMDD')}T${dateEnd.format(
        'HHmm',
      )}00&details=&sf=true&output=xml&add=${clients.join(',')}`,
    );
    setOutlookCalendarLink(
      `https://outlook.office.com/calendar/0/deeplink/compose?&to=${clients.join(
        ',',
      )}&subject=${item?.service?.name}+-+RIMAC&startdt=${date.format(
        'YYYY-MM-DDTHH:mm:00',
      )}&enddt=${dateEnd.format('YYYY-MM-DDTHH:mm:00')}`,
    );
  }, [form.getFieldValue('date'), form.getFieldValue('time')]);

  const handleSave = async () => {
    setIsLoading(true);
    try {
      const values = await form.validateFields();
      const date = values.date;
      const time = values.time;
      const mergeTime = moment(
        date.format('DD-MM-YYYY') + ' ' + time.format('HH:mm'),
        'DD-MM-YYYY HH:mm',
      );

      doSendEventDate(mergeTime.unix(), item);
      setVisible(false);
      setVisibleFormEventDateEdit(false);
    } catch (error) {
      console.error('Failed:', error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(false);
  }, [visible]);

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title=''
      onOk={handleSave}
      onCancel={handleCancel}
      open={visible}
      okText='Agendar'
      cancelText='Cancelar'
      destroyOnClose
      confirmLoading={isLoading}
      cancelButtonProps={isLoading}
      className={`modal-provider modal-centered ${
        isMobileScreen ? 'modal-mobile' : ''
      }`}
      maskClosable={false}
    >
      <Title type='bold-28' style={{ textAlign: 'center' }}>
        Detalles
      </Title>
      <Divider className='mt-2 mb-3' />
      <Form {...formLayout} form={form}>
        <Form.Item
          label='Fecha para agendar'
          name='date'
          initialValue={getInitialDate()}
          rules={[{ required: true, message: text.required_message }]}
        >
          <DatePicker format='DD-MM-YYYY' disabled={isLoading} />
        </Form.Item>
        <Form.Item
          label='Hora para agendar'
          name='time'
          initialValue={getInitialDate()}
          rules={[{ required: true, message: text.required_message }]}
        >
          <TimePicker format='HH:mm' disabled={isLoading} />
        </Form.Item>
        <center>
          <Paragraph style={{ marginBottom: 16 }}>
            Agendar fecha de visita con:
          </Paragraph>
        </center>
        <div style={{ display: 'flex', justifyContent: 'justify-between' }}>
          <Button
            href={googleCalendarLink}
            target='_blank'
            disabled={!googleCalendarLink || clients.length === 0}
          >
            <img
              src='/images/icons/Google_calendar.svg'
              width={18}
              height={18}
              alt='Google calendar'
            />
            &nbsp;Google calendar
          </Button>
          <Button
            href={outlookCalendarLink}
            target='_blank'
            disabled={!outlookCalendarLink || clients.length === 0}
          >
            <img
              src='/images/icons/Outlook_calendar.svg'
              width={18}
              height={18}
              alt='Outlook calendar'
            />
            &nbsp;Outlook calendar
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default FormCreateEventDate;
