import axios from 'axios';

export const getFlashes = async (path) => {
  const result = await axios.get(path, {
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  });

  return result.data;
};
