import React from 'react';
import { useSelector } from 'react-redux';
import { checkPermission } from '../../lib/security';
import NotFound from './NotFound';

const PermissionRoute = ({ permission, element }) => {
  const user = useSelector((state) => state.auth.user);

  return checkPermission(user, permission) ? element : <NotFound />;
};

export default PermissionRoute;
