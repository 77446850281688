export const insPatGlossary = {
  inspat_codigo_unico_identificacion: {
    text: 'Código único de identificación',
    width: 200,
  },
  inspat_codigo_producto: {
    text: 'Código del Producto',
    width: 120,
  },
  inspat_numero_renovacion: {
    text: 'Número de Renovación',
    width: 120,
  },
  inspat_zona: {
    text: 'Zona',
    width: 100,
  },
  inspat_motivo_rechazo: {
    text: 'Motivo de rechazo',
    width: 300,
  },
  inspat_poliza: {
    text: 'Póliza',
    width: 100,
  },
  inspat_ruc: {
    text: 'RUC',
    width: 120,
  },
  inspat_nombre_cliente1: {
    text: 'Nombre del cliente 1',
    width: 300,
  },
  inspat_direccion: {
    text: 'Dirección',
    width: 100,
  },
  inspat_direccion1: {
    text: 'Dirección 1',
    width: 300,
  },
  inspat_distrito: {
    text: 'Distrito',
    width: 300,
  },
  inspat_provincia: {
    text: 'Provincia',
    width: 100,
  },
  inspat_departamento: {
    text: 'Departamento',
    width: 140,
  },
  inspat_zona_riesgo: {
    text: 'Zona de Riesgo',
    width: 100,
  },
  inspat_giro: {
    text: 'Giro',
    width: 300,
  },
  inspat_ocupacion: {
    text: 'Ocupación',
    width: 300,
  },
  inspat_clasificacion_riesgo: {
    text: 'Clasificación de Riesgo',
    width: 140,
  },
  inspat_valor_declarado: {
    text: 'Valor Declarado',
    width: 120,
  },
  inspat_tipo_riesgo: {
    text: 'Tipo de Riesgo',
    width: 100,
  },
  inspat_fecha_inspeccion: {
    text: 'Fecha de la Inspección',
    width: 120,
  },
  inspat_mes_inspeccion: {
    text: 'Mes de inspección',
    width: 120,
  },
  inspat_anno_inspeccion: {
    text: 'Año de inspección',
    width: 120,
  },
  inspat_corredor: {
    text: 'Corredor o Broker',
    width: 300,
  },
  inspat_inspector_asignado_inspeccion: {
    text: 'Inspector asignado a inspección',
    width: 300,
  },
  inspat_inspector_interno: {
    text: 'Inspector interno quien reqlizó la revisión',
    width: 300,
  },
  inspat_fecha_entrega: {
    text: 'Fecha de entrega de informe',
    width: 150,
  },
  inspat_fecha_cerrado: {
    text: 'Fecha de cerrado de informe',
    width: 150,
  },
  inspat_estado: {
    text: 'Estado',
    width: 150,
  },
  inspat_motivo_inspeccion: {
    text: 'Motivo de la Inspección',
    width: 150,
  },
  inspat_mes_renovacion: {
    text: 'Mes de Renovación',
    width: 120,
  },
  inspat_anno_renovacion: {
    text: 'Año de Renovación',
    width: 120,
  },
  inspat_fecha_renovacion: {
    text: 'Fecha de Renovación',
    width: 120,
  },
  inspat_ingeniero_responsable: {
    text: 'Ingeniero Responsable',
    width: 300,
  },
  inspat_sede: {
    text: 'Sede',
    width: 150,
  },
  inspat_estrategico: {
    text: 'Estratégico',
    width: 150,
  },
  inspat_red_contra_incendio: {
    text: '¿El Local tiene red contra incendio?',
    width: 150,
  },
  inspat_sistema_bombeo_contra_incendio: {
    text: '¿El sistema de bombeo contra incendio cumple NFPA20?',
    width: 240,
  },
  inspat_pruebas_red_contra_incendio: {
    text: '¿Se realizan pruebas a la red contra incendio?',
    width: 200,
  },
  inspat_inspeccion_red_contra_incendio_nfpa25: {
    text: '¿La inspección, prueba y mantenimiento de la Red Contra Incendios cumple con la NFPA25?',
    width: 360,
  },
  inspat_reserva_agua_exclusiva_contra_incendio: {
    text: '¿La reserva de agua es exclusiva para lucha contra incendio?',
    width: 240,
  },
  inspat_hidrantes_internos: {
    text: '¿Tiene hidrantes internos?',
    width: 150,
  },
  inspat_hidrantes_externos: {
    text: '¿Tiene hidrantes externos?',
    width: 150,
  },
  inspat_hidrantes_mangueras_contra_incendio_nfpa1422: {
    text: '¿La instalación de hidrantes y mangueras  contra incendio cumple con las NFPA14 y 22?',
    width: 360,
  },
  inspat_rociadores: {
    text: '¿El local tiene rociadores?',
    width: 150,
  },
  inspat_instalacion_rociadores_nfpa13: {
    text: '¿La instalación de rociadores cumple NFPA 13?',
    width: 300,
  },
  inspat_sistema_deteccion_humo_temperatura: {
    text: '¿Tiene sistema de deteccion de humo y/o tempereatura?',
    width: 300,
  },
  inspat_comple_nfpa72: {
    text: '¿Cumple NFPA 72?',
    width: 150,
  },
  inspat_con_brigadas: {
    text: '¿Cuentan con brigadas?',
    width: 150,
  },
  inspat_capacitaciones_bomberos: {
    text: '¿Las brigadas reciben capacitaciones de los bomberos?',
    width: 250,
  },
  inspat_certificado_indeci: {
    text: '¿El cliente cuenta con certificado de Indeci?',
    width: 250,
  },
  inspat_proteccion_instalacion_electrica: {
    text: '¿La Protección, diseño y Mantenimiento de Instalaciones Eléctricas es mala, regular, buena?',
    width: 360,
  },
  inspat_condiciones_trabajo: {
    text: '¿Las condiciones de trabajos en caliente son buenas, malas, regulares?',
    width: 300,
  },
  inspat_tipo_construccion_casco: {
    text: '¿Qué tipo de construcción tiene el casco: concreto, ladrillo y cemento o Quincha y adobe / Silar / Madera / Estructura de metal / Yeso?',
    width: 520,
  },
  inspat_tipo_construccion_divisiones_internas: {
    text: '¿Qué tipo de construcción tienen las divisiones internas: concreto, ladrillo y cemento o Quincha y adobe / Silar / Madera / Estructura de metal / Yeso?',
    width: 520,
  },
  inspat_tipo_construccion_techos: {
    text: '¿Qué tipo de construcción tienen los techos: concreto, ladrillo y cemento o Quincha y adobe / Silar / Madera / Estructura de metal / Yeso?',
    width: 520,
  },
  inspat_dist_horiz_mario_rio: {
    text: 'Distancia Horizontal al Mar / Río',
    width: 250,
  },
  inspat_dist_vert_mar_rio: {
    text: 'Distancia Vertical al Mar / Río',
    width: 250,
  },
  inspat_dist_horiz_montanna: {
    text: 'Distancia Horizontal a la Montaña',
    width: 250,
  },
  inspat_dist_vert_montanna: {
    text: 'Distancia Vertical a la Montaña',
    width: 250,
  },
  inspat_zona_direccion_munichre: {
    text: '¿A qué zona pertenece la dirección inspeccionado según Munich Re?',
    width: 280,
  },
  inspat_rer_incendio: {
    text: 'RER Incendio',
    width: 100,
  },
  inspat_rer_robo: {
    text: 'RER Robo',
    width: 100,
  },
  inspat_rer_rotura_maquina: {
    text: 'RER Rotura de Maquinaria',
    width: 140,
  },
  inspat_rer_riesgo_naturaleza: {
    text: 'RER Riesgo Naturaleza',
    width: 140,
  },
  inspat_rer_responsabilidad_civil: {
    text: 'RER Responsabilidad Civil',
    width: 160,
  },
  inspat_rer_terrorismo: {
    text: 'RER Terrorismo',
    width: 120,
  },
};

export const buildInsPatColumns = () =>
  Object.entries(insPatGlossary).map(([key, value]) => ({
    title: value.text,
    dataIndex: key,
    key,
    width: value.width,
  }));

export const cumulusColumns = [
  {
    title: 'Producto',
    dataIndex: 'prod',
    key: 'prod',
    width: 120,
    type: 'int',
    isStatic: true,
    visible: true,
  },
  {
    title: 'Ruc',
    dataIndex: 'ruc',
    key: 'ruc',
    width: 120,
    visible: true,
  },
  {
    title: 'Cliente',
    dataIndex: 'nombre_cliente',
    key: 'nombre_cliente',
    width: 300,
    visible: true,
  },
  {
    title: 'Dirección del riesgo',
    dataIndex: 'direccion_del_riesgo',
    key: 'direccion_del_riesgo',
    width: 300,
    visible: true,
  },
  {
    title: 'Xgeo',
    dataIndex: 'xgeo',
    key: 'xgeo',
    width: 100,
  },
  {
    title: 'Ygeo',
    dataIndex: 'ygeo',
    key: 'ygeo',
    width: 100,
  },
  {
    title: 'Prod Descripcion',
    dataIndex: 'prod_descripcion',
    key: 'prod_descripcion',
    width: 160,
    visible: true,
  },
  {
    title: 'Póliza',
    dataIndex: 'poliza',
    key: 'poliza',
    width: 100,
    visible: true,
  },
  {
    title: 'Certif',
    dataIndex: 'certif',
    key: 'certif',
    width: 100,
  },
  {
    title: 'Ramo',
    dataIndex: 'ramo',
    key: 'ramo',
    width: 100,
    type: 'int',
  },
  {
    title: 'Inicio de Vigencia',
    dataIndex: 'vigencia_inicio',
    key: 'vigencia_inicio',
    width: 120,
    type: 'date',
    visible: true,
    render: (data) => (data ? new Date(data).toLocaleDateString('es-PE') : ''),
  },
  {
    title: 'Fin de Vigencia',
    dataIndex: 'vigencia_fin',
    key: 'vigencia_fin',
    width: 120,
    type: 'date',
    visible: true,
    render: (data) => (data ? new Date(data).toLocaleDateString('es-PE') : ''),
  },
  {
    title: 'Monto Valor',
    dataIndex: 'monto_valor',
    key: 'monto_valor',
    width: 100,
    type: 'int',
    visible: true,
  },
  {
    title: 'Valor Declarado NP',
    dataIndex: 'valor_declarado_np',
    key: 'valor_declarado_np',
    width: 160,
    type: 'int',
    visible: true,
  },
  {
    title: 'Valor Declarado Edificio',
    dataIndex: 'valor_declarado_edificio',
    key: 'valor_declarado_edificio',
    width: 140,
    type: 'int',
  },
  {
    title: 'Valor Declarado Contenido',
    dataIndex: 'valor_declarado_contenido',
    key: 'valor_declarado_contenido',
    width: 160,
    type: 'int',
  },
  {
    title: 'Valor Declarado Lucro Cesante',
    dataIndex: 'valor_declarado_lucro_cesante',
    key: 'valor_declarado_lucro_cesante',
    width: 160,
    type: 'int',
  },
  {
    title: 'Suma asegurada NP',
    dataIndex: 'suma_asegurada_np',
    key: 'suma_asegurada_np',
    width: 160,
    type: 'int',
  },
  {
    title: 'Suma asegurada retenida',
    dataIndex: 'suma_asegurada_retenida',
    key: 'suma_asegurada_retenida',
    width: 160,
    type: 'int',
  },
  {
    title: 'Valor Declarado Retenido',
    dataIndex: 'valor_declarado_retenido',
    key: 'valor_declarado_retenido',
    width: 160,
    type: 'int',
  },
  {
    title: 'Id Direc',
    dataIndex: 'id_direc',
    key: 'id_direc',
    width: 100,
  },
  {
    title: 'Nro pisos',
    dataIndex: 'nro_pisos',
    key: 'nro_pisos',
    width: 100,
    type: 'int',
  },
  {
    title: 'Nro sotanos',
    dataIndex: 'nro_sotanos',
    key: 'nro_sotanos',
    width: 100,
    type: 'int',
  },
  {
    title: 'Año contrucción',
    dataIndex: 'anno_contruc',
    key: 'anno_contruc',
    width: 120,
    type: 'int',
  },
  {
    title: 'Cod uso',
    dataIndex: 'cod_uso',
    key: 'cod_uso',
    width: 100,
    type: 'int',
  },
  {
    title: 'Uso sbs',
    dataIndex: 'uso_sbs',
    key: 'uso_sbs',
    width: 100,
  },
  {
    title: 'Tep',
    dataIndex: 'tep',
    key: 'tep',
    width: 200,
  },
  {
    title: 'Uso inmueble',
    dataIndex: 'uso_inmueble',
    key: 'uso_inmueble',
    width: 140,
  },
  {
    title: 'Pais',
    dataIndex: 'pais',
    key: 'pais',
    width: 100,
  },
  {
    title: 'Departamento',
    dataIndex: 'departamento',
    key: 'departamento',
    width: 200,
  },
  {
    title: 'Provincia',
    dataIndex: 'provincia',
    key: 'provincia',
    width: 200,
  },
  {
    title: 'Cantidad de Boletines',
    dataIndex: 'cant_boletin',
    key: 'cant_boletin',
    width: 140,
  },
  {
    title: 'Distrito',
    dataIndex: 'districto',
    key: 'districto',
    width: 200,
  },
  {
    title: 'Tipogeo',
    dataIndex: 'tipogeo',
    key: 'tipogeo',
    width: 100,
  },
  {
    title: 'Ubigeo',
    dataIndex: 'ubigeo',
    key: 'ubigeo',
    width: 100,
  },
  {
    title: 'Fronting',
    dataIndex: 'fronting',
    key: 'fronting',
    width: 100,
  },
  {
    title: 'Corredor',
    dataIndex: 'corredor',
    key: 'corredor',
    width: 150,
  },
  {
    title: 'Giro Negocio L',
    dataIndex: 'giroNegocioL',
    key: 'giroNegocioL',
    width: 150,
  },
  {
    title: 'Tipo suelo',
    dataIndex: 'tipo_suelo',
    key: 'tipo_suelo',
    width: 100,
  },
  {
    title: 'Prima',
    dataIndex: 'prima',
    key: 'prima',
    width: 100,
    type: 'int',
  },
  {
    title: 'Prima Tmto',
    dataIndex: 'primaTmto',
    key: 'primaTmto',
    width: 100,
    type: 'int',
  },
  {
    title: 'segmento',
    dataIndex: 'segmento',
    key: 'segmento',
    width: 200,
  },
  {
    title: 'Ejecutivo de cuenta',
    dataIndex: 'ejecutivoDeCuenta',
    key: 'ejecutivoDeCuenta',
    width: 200,
  },
  {
    title: 'Inundación',
    dataIndex: 'peligro_inundacion',
    key: 'peligro_inundacion',
    width: 120,
  },
  {
    title: 'Peligro de huaicos y deslizamientos',
    dataIndex: 'peligro_huaicos_deslizamientos',
    key: 'peligro_huaicos_deslizamientos',
    width: 160,
  },
  {
    title: 'Peligro Sísmico',
    dataIndex: 'peligro_sismico',
    key: 'peligro_sismico',
    width: 100,
  },
  {
    title: 'Tsunami',
    dataIndex: 'peligro_tsunami',
    key: 'peligro_tsunami',
    width: 100,
  },
  {
    title: 'Rayos',
    dataIndex: 'peligro_rayos',
    key: 'peligro_rayos',
    width: 100,
  },
  {
    title: 'Lluvias',
    dataIndex: 'peligro_lluvias',
    key: 'peligro_lluvias',
    width: 100,
  },
  {
    title: 'Peligro Volcánico',
    dataIndex: 'peligro_volcanico',
    key: 'peligro_volcanico',
    width: 100,
  },
  {
    title: 'Peligro por Caída de Ceniza Volcánica',
    dataIndex: 'peligro_ceniza_volcanica',
    key: 'peligro_ceniza_volcanica',
    width: 160,
  },
  ...buildInsPatColumns(),
  {
    title: 'ID CONTACTO',
    dataIndex: 'pk_con_contacto',
    key: 'pk_con_contacto',
    width: 200,
  },
  {
    title: 'NOMBRE DEL ASEGURADO',
    dataIndex: 'asegurado',
    key: 'asegurado',
    width: 200,
  },
  {
    title: 'FECHA OCURRENCIA SINIESTRO',
    dataIndex: 'fecocurrencia',
    key: 'fecocurrencia',
    width: 200,
  },
  {
    title: 'ID RAMO',
    dataIndex: 'pk_rmo_ramo',
    key: 'pk_rmo_ramo',
    width: 200,
  },
  {
    title: 'RAMO',
    dataIndex: 'codramo',
    key: 'codramo',
    width: 200,
  },
  {
    title: 'Cantidad de siniestros',
    dataIndex: 'cant_siniestros',
    key: 'cant_siniestros',
    width: 200,
  },
  {
    title: 'Total de monto asegurado',
    dataIndex: 'total_mtosumaasegurada',
    key: 'total_mtosumaasegurada',
    width: 200,
  },
  {
    title: 'ID APLICACIÓN PRODUCTO',
    dataIndex: 'pk_prd_producto',
    key: 'pk_prd_producto',
    width: 200,
  },
  {
    title: 'CÓDIGO DEL PRODUCTO',
    dataIndex: 'codproducto',
    key: 'codproducto',
    width: 200,
  },
  {
    title: 'PRODUCTO',
    dataIndex: 'nomproducto',
    key: 'nomproducto',
    width: 200,
  },
  {
    title: 'ID COBERTURA',
    dataIndex: 'pk_cob_cobertura',
    key: 'pk_cob_cobertura',
    width: 200,
  },
  {
    title: 'CÓDIGO DE COBERTURA',
    dataIndex: 'cob_cobertura',
    key: 'cob_cobertura',
    width: 200,
  },
  {
    title: 'COBERTURA',
    dataIndex: 'dsccobertura',
    key: 'dsccobertura',
    width: 200,
  },
  {
    title: 'ID CAUSA DEL CASO',
    dataIndex: 'pk_csa_causa',
    key: 'pk_csa_causa',
    width: 200,
  },
  {
    title: 'CÓDIGO DE CAUSA',
    dataIndex: 'codcausa',
    key: 'codcausa',
    width: 200,
  },
  {
    title: 'CAUSA DE SINIESTRO',
    dataIndex: 'dsccausa',
    key: 'dsccausa',
    width: 200,
  },
  {
    title: 'ID CONSECUENCIA',
    dataIndex: 'pk_con_consecuencia',
    key: 'pk_con_consecuencia',
    width: 200,
  },
  {
    title: 'CÓDIGO DE CONSECUENCIA',
    dataIndex: 'codconsecuencia',
    key: 'codconsecuencia',
    width: 200,
  },
  {
    title: 'CONSECUENCIA',
    dataIndex: 'dscconsecuencia',
    key: 'dscconsecuencia',
    width: 200,
  },
  {
    title: 'ID ESTADO DEL CASO',
    dataIndex: 'pk_sin_est_siniestro',
    key: 'pk_sin_est_siniestro',
    width: 200,
  },
  {
    title: 'ESTADO DE SINIESTRO',
    dataIndex: 'dscestado_sin',
    key: 'dscestado_sin',
    width: 200,
  },
  {
    title: 'ID UBICACIÓN',
    dataIndex: 'pk_sin_ubicacion',
    key: 'pk_sin_ubicacion',
    width: 200,
  },
  {
    title: 'DEPARTAMENTO',
    dataIndex: 'dscdepartamento',
    key: 'dscdepartamento',
    width: 200,
  },
  {
    title: 'PROVINCIA',
    dataIndex: 'dscprovincia',
    key: 'dscprovincia',
    width: 200,
  },
  {
    title: 'DISTRITO',
    dataIndex: 'dscdistrito',
    key: 'dscdistrito',
    width: 200,
  },
  {
    title: 'DIRECCIÓN',
    dataIndex: 'dscdireccion',
    key: 'dscdireccion',
    width: 200,
  },
  {
    title: 'DESCRIPCIÓN DE SINIESTRO',
    dataIndex: 'dscconcurrencia',
    key: 'dscconcurrencia',
    width: 200,
  },
  {
    title: 'SUMA ASEGURADA',
    dataIndex: 'mtosumaasegurada',
    key: 'mtosumaasegurada',
    width: 200,
  },
  {
    title: 'ID PAGO INDEMNIZACIÓN',
    dataIndex: 'pk_pgoindenmizacion',
    key: 'pk_pgoindenmizacion',
    width: 200,
  },
  {
    title: 'TIPO DE PAGO A REALIZAR',
    dataIndex: 'codtipopago',
    key: 'codtipopago',
    width: 200,
  },
  {
    title: 'TIPO MONEDA DE PAGO',
    dataIndex: 'codmonedapago',
    key: 'codmonedapago',
    width: 200,
  },
  {
    title: 'INDEMNIZACIÓN BRUTA',
    dataIndex: 'mtoindenmizacionbruta',
    key: 'mtoindenmizacionbruta',
    width: 200,
  },
  {
    title: 'INDEMNIZACIÓN NETA',
    dataIndex: 'mtoindenmizacionneta',
    key: 'mtoindenmizacionneta',
    width: 200,
  },
  {
    title: 'ID ESTADO DE PAGO',
    dataIndex: 'pk_estadopgo',
    key: 'pk_estadopgo',
    width: 200,
  },
  {
    title: 'CÓDIGO ESTADO DE PAGO',
    dataIndex: 'codestado',
    key: 'codestado',
    width: 200,
  },
  {
    title: 'ESTADO DE PAGO',
    dataIndex: 'dscestado_pago',
    key: 'dscestado_pago',
    width: 200,
  },
  {
    key: 'operation',
    fixed: 'right',
    width: 35,
    visible: true,
  },
  {
    title: 'RIESGO INUNDACIÓN',
    dataIndex: 'cenpred_riesgo_inundacion',
    key: 'cenpred_riesgo_inundacion',
    width: 200,
  },
  {
    title: 'RIESGO MOV. MASA',
    dataIndex: 'cenpred_riesgo_movimiento_masa',
    key: 'cenpred_riesgo_movimiento_masa',
    width: 200,
  },
];

export const optionsFilter = (type) =>
  type === 'int'
    ? [
        { id: 'eq', lable: 'Igual a', setValue: (value) => +value },
        { id: 'ne', lable: 'No igual a', setValue: (value) => value },
        { id: 'gt', lable: 'Mayor a', setValue: (value) => +value },
        { id: 'lt', lable: 'Menor a', setValue: (value) => +value },
        { id: 'not', lable: 'Campo lleno' },
        { id: 'is', lable: 'Campo vacío' },
      ]
    : type === 'date'
    ? [
        {
          id: 'like',
          lable: 'Igual a',
          setValue: (value) => moment(value).format('DD/MM/YYYY'),
        },
        {
          id: 'gt',
          lable: 'Mayor a',
          setValue: (value) => moment(value).format('DD/MM/YYYY'),
        },
        {
          id: 'lt',
          lable: 'Menor a',
          setValue: (value) => moment(value).format('DD/MM/YYYY'),
        },
        { id: 'not', lable: 'Campo lleno' },
        { id: 'is', lable: 'Campo vacío' },
      ]
    : [
        {
          id: 'eq',
          lable: 'Igual a',
          setValue: (value) => value.toLowerCase().trim(),
        },
        {
          id: 'in',
          lable: 'Igual a (Separado por comas)',
          setValue: (value) => value.toLowerCase().trim().split(','),
        },
        { id: 'ne', lable: 'No igual a', setValue: (value) => value },
        {
          id: 'notIn',
          lable: 'No Igual a (Separado por comas)',
          setValue: (value) => value.toLowerCase().trim().split(','),
        },
        {
          id: 'like',
          lable: 'Contiene',
          setValue: (value) => value.toLowerCase().trim(),
        },
        {
          id: 'notLike',
          lable: 'No contiene',
          setValue: (value) => value.toLowerCase().trim(),
        },
        { id: 'not', lable: 'Campo lleno' },
        { id: 'is', lable: 'Campo vacío' },
      ];
