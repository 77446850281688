import { post } from '../lib/ajax';
import {
  ERROR_FORM_USERS,
  LOAD_USERS_LOADING,
  LOADED_USERS,
  LOADING_CREATE_USERS,
  CHANGE_USERS_QUERY,
  LOG_IN,
  UPDATE_TOTAL_USERS,
} from '../redux/actions/actionsType';
import { Auth } from 'aws-amplify';
import { checkAuth, findUser, setClockDriftLocalStore } from '../lib/auth';

export const getUserList = async (
  dispatch,
  data = {},
  isFirstRender = false,
) => {
  dispatch({ type: LOAD_USERS_LOADING, payload: true });
  dispatch({ type: CHANGE_USERS_QUERY, payload: data.query });
  const currentUser = await Auth.currentAuthenticatedUser();

  if (!currentUser) return;

  const response = await post('/users/list', { payload: data });
  const payload = response.data?.payload;

  if (payload && payload.success) {
    dispatch({ type: LOADED_USERS, payload: payload.userList });
    if (isFirstRender) {
      dispatch({
        type: UPDATE_TOTAL_USERS,
        payload: payload.userList.total,
      });
    }
    return payload;
  }
};

export const createUser = async (dispatch, user, isFirstRender = false) => {
  dispatch({ type: LOADING_CREATE_USERS, payload: true });

  const currentUser = await Auth.currentAuthenticatedUser();

  if (!currentUser) return;

  const response = await post('/users/save', { payload: user });
  const payload = response.data?.payload;

  if (payload && payload.success) {
    dispatch({
      type: UPDATE_TOTAL_USERS,
      payload: payload.totalUsers + 1,
    });
    if (isFirstRender) {
      dispatch({
        type: UPDATE_TOTAL_USERS,
        payload: payload.totalUsers,
      });
    }
    return payload;
  } else {
    dispatch({ type: ERROR_FORM_USERS, payload: payload.error });
  }
};

export const createUserNew = async (user) => {
  const r = await post('/users/save', { payload: { ...user } });
  return r;
};

export const updateUser = async (user, dispatch, pagination, currUser) => {
  try {
    const response = await post('/users/update', { payload: user });
    const payload = response.data?.payload;

    if (payload.success) {
      if (!dispatch) return true;

      if (
        currUser?.rol_id !== user.rol_id &&
        currUser?.username === user.username
      ) {
        await getUserAuthenticated(dispatch);
      }

      await getUserList(dispatch, {
        page: pagination.current,
        limit: pagination.pageSize,
      });

      return true;
    }
  } catch (error) {
    return false;
  }

  return false;
};

export const updateUserNew = async (user) => {
  const r = await post('/users/update-by-uuid', { payload: user });
  return r.data;
};

export const deleteUser = async (
  user,
  dispatch,
  pagination,
  currUser,
  totalUsers,
) => {
  const response = await post('/users/delete', {
    payload: {
      id: user.id,
    },
  });

  const responseData = response.data?.payload;

  if (responseData.success) {
    if (!dispatch) return;

    if (
      currUser?.rol_id !== user.rol_id &&
      currUser?.username === user.username
    )
      await getUserAuthenticated(dispatch);

    dispatch({ type: UPDATE_TOTAL_USERS, payload: totalUsers - 1 });
    await getUserList(dispatch, {
      page: pagination.current,
      limit: pagination.pageSize,
    });

    return true;
  }

  return false;
};

export const checkIfExistUser = async (username) => {
  const response = await post('/users/get', { payload: { username } });
  const payload = response.data?.payload;

  if (payload && payload.success) return payload;

  return false;
};

export const getUserByUuid = async (uuid) => {
  const r = await post('/users/get', { payload: { uuid } });
  return r.data;
};

export const addCompanyToUser = async (data) => {
  const response = await post('/users/add-company', { payload: data });
  const payload = response.data?.payload;

  if (payload && payload.success) return true;

  return false;
};

export const removeCompanyToUser = async (data) => {
  const response = await post('/users/remove-company', { payload: data });
  const payload = response.data?.payload;

  return payload?.success || false;
};

export const getUserNameSimulate = () =>
  localStorage.getItem('simulate_user') ?? false;

export const removeSimulateUser = () =>
  localStorage.removeItem('simulate_user');

export const resendRegistrationEmail = async (data) => {
  const response = await post('/users/resend-registration-email', {
    payload: data,
  });
  return response.data?.payload;
};

export const getEvolLink = async () => {
  const response = await post('/users/get-evol-token');
  const payload = response.data?.payload;

  if (payload.success) return payload.result;
};

export const getListCumulus = async (data) => {
  const response = await post('/users/list-history-cumulus', {
    payload: data,
  });
  const payload = response.data?.payload;

  if (payload.success) return payload.result;
};

export const createCumuluHistory = async (data) => {
  const response = await post('/users/add-cumulu', {
    payload: data,
  });

  const payload = response.data?.payload;

  if (payload.success) return payload.result;
};

export const getUserAuthenticated = async (dispatch) => {
  const result = await checkAuth();
  if (result.success) {
    setClockDriftLocalStore(result.session?.clockDrift);
    dispatch({
      type: LOG_IN,
      payload: { success: true, session: result.session },
    });
    try {
      const response = await findUser(result.session.idToken.payload.email);

      if (response.data?.success) {
        dispatch({
          type: LOG_IN,
          payload: {
            success: true,
            session: result.session,
            user: response.data.user,
          },
        });
      } else {
        localStorage.clear();
        localStorage.setItem('errorTakeSession', true);
        window.location.href = '/login';
      }
    } catch (e) {
      dispatch({ type: LOG_IN, payload: { success: false, session: null } });
    }
  } else {
    dispatch({ type: LOG_IN, payload: { success: false, session: null } });
  }
};

export const getListManagerCompanies = async (data) => {
  const response = await post('/users/list-manager-companies', {
    payload: data,
  });
  const payload = response.data?.payload;

  if (payload.success) return payload.list;

  return false;
};

export const getUsersByRoles = async (data = {}) => {
  const response = await post('/users/list-by-roles', {
    payload: data,
  });
  const payload = response.data?.payload;

  if (payload.success) return payload.userList;

  return [];
};

export const getCumulusUrlToImport = async (data) => {
  const response = await post('/users/get-cumulus-url', {
    payload: data,
  });
  const payload = response.data?.payload;

  if (payload.success) return payload.result;

  return;
};
