import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';

const { Option } = Select;

import { COMMON_SELECT_LOCATION_ID } from '../../../../redux/actions/actionsType';

export const getLocationName = (location) => {
  if (location?.name) {
    return location.name;
  }
  if (location?.province && location?.department) {
    return `${location.province}, ${location.department}`;
  }
  return `${location?.lat}, ${location?.lng}`;
};

const SelectLocations = () => {
  const dispatch = useDispatch();
  const locations = useSelector((state) => state.locations.list || []);
  const selectLocationId = useSelector(
    (state) => state.common.selectLocationId,
  );

  const setSelectLocationId = (id) =>
    dispatch({ type: COMMON_SELECT_LOCATION_ID, payload: id });

  return (
    <>
      <div className='drawer-map-alert__body__title'>
        ELIGE TU UBICACIÓN FAVORITA
      </div>
      <Select
        value={selectLocationId}
        onChange={(val) => setSelectLocationId(val)}
        placeholder='Ubicación'
        style={{ width: '100%' }}
      >
        {locations.map((location) => (
          <Option key={location.id} value={location.id}>
            {getLocationName(location)}{' '}
            {selectLocationId === location.id && (
              <span className='filter__select__icon'>
                <i className='fas fa-check'></i>
              </span>
            )}
          </Option>
        ))}
      </Select>
    </>
  );
};

export default SelectLocations;
