import React, { useState } from 'react';
import { Button, Form, Input, Progress } from 'antd';
import shield from '../../images/ic_shield.png';
import { checkPassword } from '../../lib/utils';
import { RideButton } from '@rimac-seguros/ride-system-components';

const FormReset = ({ onOk, loading }) => {
  const [form] = Form.useForm();
  const [percentSecure, setPercentSecure] = useState(0);

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        console.log('Form Values:', values); // Debugging line
        if (values) {
          onOk(values);
        }
      })
      .catch((errorInfo) => {
        console.log('Validation Failed:', errorInfo); // Debugging line for validation failure
      });
  };

  const compareToFirstPW = (_rule, value) => {
    if (value && value !== form.getFieldValue('password')) {
      return Promise.reject('Las contraseña no son iguales');
    }
    return Promise.resolve();
  };

  const validateStrongPW = (_rule, value) => {
    const testedResult = checkPassword(value);
    if (testedResult < 5) {
      return Promise.reject('La contraseña no es segura');
    }

    setPercentSecure((testedResult * 100) / 5);
    return Promise.resolve();
  };

  return (
    <Form form={form} onFinish={handleSubmit} className='mt-2'>
      <Form.Item
        name='password'
        rules={[
          { required: true, message: 'Por favor inserta la contraseña' },
          { validator: validateStrongPW },
        ]}
      >
        <Input.Password className='input_password' placeholder='Contraseña' />
      </Form.Item>
      <Form.Item
        name='confirm'
        rules={[
          { required: true, message: 'Por favor inserta la contraseña' },
          { validator: compareToFirstPW },
        ]}
      >
        <Input.Password
          className='input_password'
          placeholder='Repetir Contraseña'
        />
      </Form.Item>
      <div className='secure-box'>
        <Progress
          className='secure-bar-info'
          status={percentSecure < 95 ? 'exception' : ''}
          percent={percentSecure}
          showInfo={false}
        />
        <div
          className='secure-text-info'
          style={{ color: percentSecure < 95 ? '#EF3340' : '#83CC5E' }}
        >
          <p>{percentSecure < 95 ? 'Débil' : 'Segura'}</p>
        </div>
      </div>
      <div className='flex items-center gap-2'>
        <img src={shield} alt='Seguridad' />
        <p style={{ marginBottom: 0 }}>
          Una contraseña segura tiene mínimo 8 caracteres, entre minúsculas,
          mayúsculas, números y un carácter especial{' '}
          <strong>({'~!@#$%^&*-`|{}[]:;"<>,.?/'})</strong>.
        </p>
      </div>

      <div className='flex space-around' style={{ marginTop: 16 }}>
        <RideButton
          variat='fill'
          text='Crear contraseña'
          size='large'
          loading={loading}
          type='submit'
        />
      </div>
    </Form>
  );
};

export default FormReset;
