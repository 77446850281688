import React from 'react';
import { Divider, Modal, notification } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

import { useScreen } from '../../../lib/screen';
import { getServiceDate } from './utils';

import { IconCloseCircle } from '../../../common/Icons';
import Title from '../../../common/Typograph/Title';
import SimpleTable from '../../../common/Table/SimpleTable';
import { generatePurchaseOrder } from '../../../services/requestServicesPurchaseOrder';

const getReportCreatedDate = (item) => {
  const technicalReports = item.request_service_item_results;
  if (technicalReports && technicalReports.length > 0) {
    const technicalReport = technicalReports[technicalReports.length - 1];

    return moment(technicalReport.createdAt);
  }
};

const columns = [
  {
    title: 'Fecha',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: 90,
    align: 'center',
    render: (date) => {
      if (date)
        return (
          new Date(date).toLocaleDateString('es-PE') +
          ' ' +
          new Date(date).toLocaleTimeString('es-PE')
        );

      return '';
    },
  },
  {
    title: 'Servicio',
    dataIndex: 'requestServiceItems',
    key: 'requestServiceItemsServicio',
    width: 220,
    render: (field, item) => `${item?.service?.name ? item.service.name : ''}`,
  },
  {
    title: 'Zona',
    dataIndex: 'request_service_item_results',
    key: 'request_service_item_results_zone',
    width: 200,
    render: (_, item) =>
      `${item?.request_service_item_results?.[0]?.zone ?? ''}`,
  },
  {
    title: 'Precio',
    dataIndex: 'request_service_item_results',
    key: 'request_service_item_results_price',
    width: 120,
    render: (_, item) =>
      `${item?.request_service_item_results?.[0]?.price ?? ''}`,
  },
  {
    title: 'Empresa',
    dataIndex: 'requestServiceItems',
    key: 'requestServiceItemsEmpresa',
    width: 200,
    render: (_, item) =>
      `${
        item?.requestService?.company?.name
          ? item?.requestService?.company?.name
          : ''
      }`,
  },
  {
    title: 'F.Servicio',
    key: 'fservice',
    width: 110,
    align: 'center',
    render: (_, item) => {
      const date = getServiceDate(item);
      if (date && date.isValid() && item?.service?.type !== '1')
        return (
          <div>
            {date.format('DD/MM/YYYY')}{' '}
            {item.request_service_item_results?.length > 0 ? '' : <i>T</i>}
          </div>
        );
    },
  },
  {
    title: 'F.Informe',
    key: 'finforme',
    width: 110,
    align: 'center',
    render: (_, item) =>
      item?.service?.type !== '1' &&
      getReportCreatedDate(item)?.format('DD/MM/YYYY'),
  },
];

const ModalPurchaseOrder = ({ visible, list, providerId, onClose }) => {
  const { isMobileScreen } = useScreen();

  const handleGenerate = () => {
    const parseList = list.reduce((acc, item) => {
      const { request_service_item_results, requestService, service } = item;
      const date = getServiceDate(item);

      return acc.concat({
        date_service: date.format('MM/DD/YYYY'),
        service: service?.name,
        zone: request_service_item_results?.[0]?.zone,
        price: request_service_item_results?.[0]?.price ?? 0,
        company: requestService?.company?.name,
      });
    }, []);
    generatePurchaseOrder(parseList, providerId)
      .then((res) => {
        if (!res) throw Error('Error');

        notification.success({
          description: 'Se generó la orden correctamente.',
          message: 'Orden generada!',
        });
      })
      .catch(() => {
        notification.warning({
          description: 'Ha ocurrido un error.',
          message: '¡Error al generar orden!',
        });
      })
      .finally(() => {
        onClose();
      });
  };

  return (
    <Modal
      title=''
      open={visible}
      okText='Generar'
      onOk={handleGenerate}
      cancelText='Cancelar'
      onCancel={onClose}
      width='80%'
      className={`modal-provider modal-centered ${
        isMobileScreen ? 'modal-mobile' : ''
      }`}
    >
      <Title
        type='bold-28'
        style={{ margin: '0 auto', width: '90%', textAlign: 'center' }}
      >
        {'Generar Orden de Pago'}
      </Title>
      <Divider className='mt-2 mb-3' />
      <div>
        <SimpleTable columns={columns} dataSource={list} />
      </div>
    </Modal>
  );
};

export default ModalPurchaseOrder;
