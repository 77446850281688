import React, { memo, useCallback, useState } from 'react';
import { Button, Radio } from 'antd';
import Axios from 'axios';

import { bulkExport, checkExported } from '../../../services/polizas';
import ModalConfirm from '../../../common/ModalConfirm';
import { IconCloseModal } from '../ui/PreventionService/Reports/utils';

const ModalDownloadCumulo = ({
  visible,
  onClose,
  columns = [],
  filters = [],
  query = '',
}) => {
  const [loadingDownload, setIsLoadingDownload] = useState(false);
  const [radioValue, setRadioValue] = useState('custom');

  const _handleDownload = useCallback(() => {
    setIsLoadingDownload(true);
    bulkExport({
      items: [],
      fields:
        radioValue === 'custom'
          ? columns.map((c) => c.dataIndex).filter(Boolean)
          : false,
      query,
      filters,
    }).then((response) => {
      const id = setInterval(() => {
        checkExported(response?.data?.payload).then((resp) => {
          if (resp?.data?.payload?.found) {
            clearInterval(id);
            const idDownload = setInterval(() => {
              let cancelRequest = Axios.CancelToken.source();
              Axios({
                method: 'get',
                url: resp.data.payload.data,
                onDownloadProgress: (progressEvent) => {
                  const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total,
                  );
                  if (percentCompleted !== Infinity) {
                    clearInterval(idDownload);
                    cancelRequest.cancel();

                    const link = document.createElement('a');
                    link.href = resp.data.payload.data;
                    document.body.appendChild(link);
                    link.click();
                    setIsLoadingDownload(false);
                    onClose();
                  }
                },
                cancelToken: cancelRequest.token,
              })
                .then(() => {
                  clearInterval(idDownload);
                })
                .catch((response) => {
                  console.error('errr', response);
                });
            }, 4000);
          }
        });
      }, 4000);
    });
  }, [columns, filters, onClose, query, radioValue]);

  const _handleChangeRadioGroup = useCallback(({ target: { value } }) => {
    setRadioValue(value);
  }, []);

  return (
    <ModalConfirm
      title={'¿Que tipo de descarga desea realizar?'}
      visible={visible}
      onClose={onClose}
      content={
        <div className='full-width mb-2' style={{ overflow: 'auto' }}>
          <Radio.Group
            className='full-width'
            onChange={_handleChangeRadioGroup}
            value={radioValue}
          >
            <Radio
              className='flex items-center radio-success-color text-neutral-color text-base'
              style={{
                whiteSpace: 'normal',
              }}
              value={'custom'}
            >
              <strong>Descarga personalizada</strong>: Descarga los registros
              con las columnas filtradas de la tabla
            </Radio>
            <Radio
              className='flex items-center mt-2 radio-success-color text-neutral-color text-base'
              style={{
                whiteSpace: 'normal',
              }}
              value={'all'}
            >
              <strong>Descarga Completa</strong>: Descarga los registros con
              todas las columnas de la tabla tanto visibles y ocultas
            </Radio>
          </Radio.Group>
        </div>
      }
      actionsContent={
        <div className='actions-delete-modal mt-2'>
          <Button className='button secondary-button mr-2' onClick={onClose}>
            Cancelar
          </Button>
          <Button
            className='button ml-1'
            type='primary'
            onClick={_handleDownload}
            loading={loadingDownload}
          >
            Descargar
          </Button>
        </div>
      }
    />
  );
};

export default memo(ModalDownloadCumulo);
