import React, { useEffect, useState } from 'react';
import { Col, Form, message, Modal, Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

import { getService } from '../../../../../../services/service';
import { savePlanning } from '../../../../../../services/polizas';
import { useScreen } from '../../../../../../lib/screen';
import InputWithLabel from '../../../../../../common/InputWithLabel';
import { IconCloseCircle } from '../../../../../../common/Icons';

const antIcon = (
  <LoadingOutlined
    style={{ fontSize: 56, width: 100, margin: '30px auto' }}
    spin
  />
);

const ModalServicesPlanning = ({
  visible,
  data: poliza,
  onClose,
  onSavedChanges,
}) => {
  const dispatch = useDispatch();
  const { listServices, isLoadingServices } = useSelector((state) => ({
    listServices: state.service.listWithFixed || [],
    isLoadingServices: state.service.isLoadingList,
  }));

  const loadListServices = () => getService(dispatch);
  const [form] = Form.useForm();
  const [loading] = useState(false);
  const [servicesOfPoliza, setServicesOfPoliza] = useState([]);
  const { isMobileScreen } = useScreen();

  useEffect(() => {
    if (visible) {
      setServicesOfPoliza(poliza?.levelCompany?.levelCompanyServices || []);
      loadListServices();
    }
  }, [visible, poliza]);

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      values = Object.keys(values).map((k) => {
        return {
          serviceName: listServices.find((s) => s.id === Number(k))?.name || '',
          serviceId: k,
          count: values[k],
        };
      });
      savePlanning({
        id: poliza.id,
        planning: values,
      }).then(async (resp) => {
        if (resp) {
          onSavedChanges(true);
          onClose();
        } else {
          message.error('Ha ocurrido un error');
          onClose();
        }
      });
    });
  };

  const getServiceName = (item) =>
    listServices.find((i) => i.id === item.serviceId)?.name || '';

  const getInitialValue = (item) => {
    if (poliza?.planning) {
      const planning = JSON.parse(poliza.planning);

      return (
        planning.find((p) => Number(p.serviceId) === item.serviceId)?.count || 0
      );
    }

    return 0;
  };

  return (
    <Modal
      title={''}
      onOk={handleSubmit}
      onCancel={onClose}
      open={visible}
      confirmLoading={loading}
      okText={'Actualizar'}
      cancelText='Cancelar'
      destroyOnClose
      className={`modal-provider modal-centered ${
        isMobileScreen ? 'modal-mobile' : ''
      }`}
      maskClosable={false}
    >
      <div className='content-modal'>
        <h2>Planificación de servicios</h2>

        {isLoadingServices ? (
          <Spin
            indicator={antIcon}
            style={{ width: 100, margin: '20px auto', display: 'block' }}
          />
        ) : (
          <Form form={form} style={{ marginTop: 30 }} onFinish={handleSubmit}>
            <Row style={{ marginBottom: 30 }}>
              <Col span={13}>
                <strong>Servicios</strong>
              </Col>
              <Col span={6} style={{ textAlign: 'center' }}>
                <strong>Cantidad base</strong>
              </Col>
              <Col span={5} style={{ textAlign: 'center' }}>
                <strong>Planificación</strong>
              </Col>
            </Row>
            {servicesOfPoliza.map((item) => {
              return (
                <Form.Item
                  style={{ margin: '24px 0', padding: '0 8px' }}
                  key={item.id}
                  name={item.serviceId.toString()}
                  initialValue={getInitialValue(item)}
                  rules={[{ required: true, message: 'Campo requerido' }]}
                >
                  <Row gutter={[16, 8]} align='middle'>
                    <Col
                      span={13}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '40px',
                        padding: 0,
                        lineHeight: '24px',
                      }}
                    >
                      <span>{getServiceName(item)}</span>
                    </Col>
                    <Col span={6} style={{ textAlign: 'center', padding: 0 }}>
                      <span style={{ lineHeight: '24px' }}>{item.count}</span>
                    </Col>
                    <Col span={5} style={{ padding: 0 }}>
                      <InputWithLabel
                        style={{ height: 40 }}
                        type='number'
                        min={0}
                        max={item.count}
                      />
                    </Col>
                  </Row>
                </Form.Item>
              );
            })}
          </Form>
        )}
      </div>
    </Modal>
  );
};

export default ModalServicesPlanning;
