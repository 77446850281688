import * as React from 'react';

const Alarm = (props) => (
  <svg
    width='32'
    height='32'
    viewBox='0 0 40 40'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M33.0711 7.44697L31.3039 5.67979L34.4289 2.55479L36.1961 4.32197L33.0711 7.44697ZM38.757 14.5313H33.757V17.0313H38.757V14.5313ZM8.69609 5.67822L5.57109 2.55322L3.80391 4.32041L6.92891 7.44541L8.69609 5.67822ZM8.07109 25.8829L6.30391 24.1157L3.17891 27.2407L4.94609 29.0079L8.07109 25.8829ZM36.8211 27.2407L33.6961 24.1157L31.9289 25.8829L35.0539 29.0079L36.8211 27.2407ZM6.26172 14.5313H1.26172V17.0313H6.26172V14.5313Z'
      fill='url(#paint0_linear_8950_136924)'
    />
    <path
      d='M33.7297 30.3345L30.6047 27.2095L29.2781 25.8829L30.6047 24.5563L30.75 24.411L29.2625 11.7688C28.8922 8.62197 26.2234 6.24854 23.0547 6.24854H16.9469C13.7781 6.24854 11.1094 8.62197 10.7391 11.7688L9.25156 24.411L9.39687 24.5563L10.7234 25.8829L9.39687 27.2095L6.27188 30.3345L4.94687 31.6595L4.43125 31.1438L3.4375 37.5001H36.5641L35.5703 31.1438L35.0547 31.6595L33.7297 30.3345ZM13.2234 12.0626C13.4453 10.1751 15.0469 8.7501 16.9484 8.7501H23.0563C24.9578 8.7501 26.5578 10.1735 26.7812 12.0626L28.5969 27.5001H11.4078L13.2234 12.0626ZM6.35937 35.0001L7.14062 30.0001H32.8625L33.6437 35.0001H6.35937ZM17.1641 15.4235L14.6875 15.0876L14.9609 13.0782C15.175 11.5017 16.5359 10.3126 18.1281 10.3126H19.3781V12.8126H18.1281C17.7813 12.8126 17.4859 13.072 17.4391 13.4142L17.1656 15.4235H17.1641Z'
      fill='url(#paint1_linear_8950_136924)'
    />
    <defs>
      <linearGradient
        id='paint0_linear_8950_136924'
        x1='2.24262'
        y1='4.62944'
        x2='22.7776'
        y2='33.7346'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.35' stopColor='#F7052D' />
        <stop offset='0.85' stopColor='#CA5AFA' />
      </linearGradient>
      <linearGradient
        id='paint1_linear_8950_136924'
        x1='3.4375'
        y1='6.24854'
        x2='19.5347'
        y2='44.6403'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.272135' stopColor='#34263B' />
        <stop offset='0.658079' stopColor='#13172C' />
      </linearGradient>
    </defs>
  </svg>
);

export default Alarm;
