import React, { useState, useEffect } from 'react';
import { Divider } from 'antd';
import CardEmptyContact from '../../../../../../common/CardEmptyContact';
import CommentField from '../CommentField';
import cryptoUtils from '../../../../../../lib/crypto';

const ServiceInHouse = ({
  onUpdateForm,
  comment,
  course,
  companyId,
  isAdminRimac,
}) => {
  const [currentCourse] = useState(
    course || { course_key: `course-${cryptoUtils.randomStr(3)}` },
  );

  useEffect(() => {
    onUpdateForm({ courses: currentCourse });
  }, [currentCourse]);

  return (
    <div className='flex flex-col in-house-container mb-2'>
      <Divider className='mt-3 mb-3' />
      <CardEmptyContact
        description={
          'En caso no encuentres el curso en el listado comunícate con tu gestor'
        }
        title='El servicio será derivado al proveedor.'
        companyId={companyId}
        isAdminRimac={isAdminRimac}
      />
      <CommentField
        className='mt-5'
        onUpdateForm={onUpdateForm}
        comment={comment}
      />
    </div>
  );
};

export default ServiceInHouse;
