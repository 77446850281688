import React, { useEffect, useState } from 'react';
import moment, { locale } from 'moment';
import {
  DatePicker,
  Form,
  Input,
  Modal,
  Radio,
  Select,
  Typography,
} from 'antd';
import text from '../../../../../../config/text_es';
import { useScreen } from '../../../../../../lib/screen';
import { getExpiredPolizasOfCompany } from '../../../../../../services/company';

const { Text } = Typography;

const ModalForm = ({
  handleClose,
  handleAction,
  data,
  visible,
  company,
  isLoadingCreate,
  listTypePoliza,
  listLevelCompany,
  byDefeat = false,
}) => {
  const [form] = Form.useForm();
  const { isMobileScreen } = useScreen();
  const [isRenewal, setIsRevewal] = useState(false);
  const [listExpired, setListExpired] = useState([]);

  const handleCancel = () => {
    handleClose();
    form.resetFields();
  };

  const handleOk = () => {
    form.validateFields().then((values) => {
      if (!values.companyId && company) {
        values.companyId = company.id;
      }

      handleAction({
        ...values,
        poliza_renewed: isRenewal ? values.poliza_renewed : null,
      });
      form.resetFields();
    });
  };

  useEffect(() => {
    const elements = document.querySelectorAll('.ant-popover');
    if (elements.length > 0) {
      elements.forEach((element) => {
        element.classList.add('ant-popover-hidden');
      });
    }
  }, [visible]);

  useEffect(() => {
    const fetchExpiredPolizas = async () => {
      if (company) {
        const { list, renewed } = await getExpiredPolizasOfCompany(company);

        if (list) {
          const expiredPolizas = list.filter((poliza) => {
            const isRenewed = renewed.includes(poliza.id);
            const isDataPolizaRenewed =
              data && poliza.id === data.poliza_renewed;

            if (isDataPolizaRenewed && isRenewed) {
              return true;
            }

            return !isRenewed;
          });

          setListExpired(expiredPolizas);
        }
      }
    };

    if (visible) {
      fetchExpiredPolizas();
      if (data) {
        setIsRevewal(!!data.poliza_renewed);
      }
    }
  }, [company, data, visible]);

  return (
    <Modal
      open={visible}
      title=''
      closable={true}
      onCancel={() => handleCancel()}
      centered={true}
      onOk={handleOk}
      className={`modal-provider modal-centered modal-config modalPolizaCompany ${
        isMobileScreen ? 'modal-mobile' : ''
      }`}
      cancelText='Cancelar'
      okText={`${byDefeat ? 'Guardar Cambios' : data ? 'Editar' : 'Crear'}`}
    >
      <h2>{`${byDefeat ? 'Renovar' : data ? 'Editar' : 'Crear'} póliza`}</h2>

      <Form form={form} disabled={isLoadingCreate} className='form-action'>
        <Form.Item
          name='id'
          initialValue={data ? data.id : ''}
          className='hidden'
        >
          <Input type='hidden' />
        </Form.Item>

        <Form.Item
          className='radio-input'
          label='¿Deseas renovar la póliza?'
          name='type'
          initialValue={isRenewal}
        >
          <Radio.Group onChange={(e) => setIsRevewal(e.target.value)}>
            {byDefeat ? (
              <>
                <Radio value={false}>Si</Radio>
                <Radio value={true} disabled>
                  No
                </Radio>
              </>
            ) : (
              <>
                <Radio value={true} disabled={listExpired.length === 0}>
                  Si
                </Radio>
                <Radio value={false}>No</Radio>
              </>
            )}
          </Radio.Group>
        </Form.Item>

        {isRenewal ? (
          <Form.Item
            name='poliza_renewed'
            initialValue={data ? data.poliza_renewed : ''}
            rules={[{ required: true, message: text.required_message }]}
          >
            <Select
              labelInValue
              loading={isLoadingCreate}
              disabled={isLoadingCreate || byDefeat}
              placeholder='Póliza a Renovar'
              allowClear
            >
              {listExpired.map((expiredPolicies) => (
                <Select.Option
                  key={expiredPolicies.id}
                  value={expiredPolicies.id}
                >
                  {`${expiredPolicies.no_poliza} `}
                  <small>
                    <Text type='danger'>(Exp. {expiredPolicies.end_date})</Text>
                  </small>
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ) : null}

        <Form.Item
          name='typePolizaId'
          initialValue={
            data && data.typePolizaId ? data.typePolizaId : undefined
          }
          rules={[{ required: true, message: text.required_message }]}
        >
          <Select
            loading={isLoadingCreate}
            disabled={isLoadingCreate}
            placeholder='Seleccione el tipo de póliza'
          >
            {Array.isArray(listTypePoliza) &&
              listTypePoliza.map((polizaType) => (
                <Select.Option key={polizaType.id} value={polizaType.id}>
                  {polizaType.name}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        <Form.Item
          name='levelCompanyId'
          initialValue={
            data && data.levelCompany
              ? { key: data.levelCompany.id, label: data.levelCompany.name }
              : undefined
          }
          rules={[{ required: true, message: text.required_message }]}
        >
          <Select
            labelInValue
            loading={isLoadingCreate}
            disabled={isLoadingCreate}
            placeholder='Nivel de empresa'
            allowClear
          >
            {listLevelCompany.map((lc) => (
              <Select.Option key={lc?.id} value={lc?.id}>
                {lc?.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name='no_poliza'
          initialValue={data ? data.no_poliza : ''}
          rules={[{ required: true, message: text.required_message }]}
        >
          <Input
            label='Número de póliza'
            disabled={isLoadingCreate}
            placeholder='Ingrese el número de póliza'
          />
        </Form.Item>

        <Form.Item
          name='start_date'
          initialValue={data ? moment(data.start_date) : null}
          rules={[{ required: true, message: text.required_message }]}
        >
          <DatePicker
            label='Fecha de inicio'
            className='w-full'
            placeholder='Fecha de inicio'
            format='DD/MM/YYYY'
            locale={locale}
            allowClear={true}
            disabled={isLoadingCreate}
          />
        </Form.Item>

        <Form.Item
          name='validity'
          initialValue={data ? data.validity : ''}
          rules={[{ required: true, message: text.required_message }]}
        >
          <Input
            type='number'
            min={0}
            addonBefore='Vigencia (meses)'
            disabled={isLoadingCreate}
            placeholder='Colocar la vigencia por meses'
          />
        </Form.Item>

        <Form.Item
          style={{ marginBottom: 42 }}
          name='prima'
          initialValue={data ? data.prima : ''}
          rules={[{ required: true, message: text.required_message }]}
        >
          <Input
            type='number'
            min={0}
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            addonBefore='Prima (US$)'
            disabled={isLoadingCreate}
            placeholder='Ingrese el valor de la prima'
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ModalForm;
