import { useMemo, useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import {
  RideGlLeft,
  RideIconButton,
} from '@rimac-seguros/ride-system-components';

import Participants from '../Participants';
import Detail from './Detail';

import ButtonReportCertificates from '../../../../RequestServicesItems/ModalCheckParticipants/ButtonReportCertificates';
import Tabs from '../../../../../../common/Tabs';

const TABS = [
  { label: 'Detalle', children: Detail, key: 'detail' },
  {
    label: 'Lista de participantes',
    children: Participants,
    key: 'participants',
  },
];

const HouseDetail = ({
  selected,
  itemId,
  courseSelected,
  setCourseSelected,
}) => {
  const [hasCertificate, setHasCertificate] = useState(false);
  const [active, setActive] = useState('detail');

  const callback = (key) => {
    setActive(key);
  };

  useState(() => {
    if (selected?.service?.type === '5') setHasCertificate(true);
  }, [selected]);

  const handleReturn = () => {
    setCourseSelected(null);
  };

  const courseInfo = useMemo(() => {
    return {
      courseKey: courseSelected.course_key,
      requestServiceItemDataId: courseSelected.id,
    };
  }, []);

  return (
    <>
      <div className='header-house'>
        {setCourseSelected ? (
          <div className='return'>
            <RideIconButton
              variant='outline'
              size='small'
              glyph={<RideGlLeft />}
              onClick={handleReturn}
            />
            <span>Curso: {courseSelected?.course}</span>
          </div>
        ) : (
          <div className='title-sections'>
            <span>Detalle de curso</span>
          </div>
        )}
        {active === 'participants' &&
        (hasCertificate ||
          (selected?.service?.type === '5' && selected.state > 5)) ? (
          <div className='actions'>
            <ButtonReportCertificates
              courseKey={courseSelected.course_key}
              requestServiceItemDataId={courseSelected.id}
              icon={<UploadOutlined />}
            />
          </div>
        ) : null}
      </div>

      <div className='content-house' style={{ paddingTop: 0 }}>
        <Tabs
          activeKey={active}
          onChange={callback}
          items={TABS.map((TAB) => ({
            ...TAB,
            children: (
              <TAB.children
                course={courseSelected}
                selected={selected}
                itemId={itemId}
                courseInfo={courseInfo}
                state={selected.state}
                setHasCertificate={setHasCertificate}
              />
            ),
          }))}
        />
      </div>
    </>
  );
};

export default HouseDetail;
