import { memo, useState, useCallback, useEffect } from 'react';
import { Card, Checkbox, Col, notification } from 'antd';
import { UpOutlined, DownOutlined } from '@ant-design/icons';

import TextArea from 'antd/lib/input/TextArea';
import InputWithLabel from '../../../../../common/InputWithLabel';
import Tabs from '../../../../../common/Tabs';

const ParameterTypeVariable = memo(({ name, defaultValue, onUpdateForm }) => {
  const type = Array.isArray(defaultValue) ? 'array' : typeof defaultValue;
  const [internalValue, setInternalValue] = useState(defaultValue);

  useEffect(() => {
    onUpdateForm(internalValue);
  }, [internalValue]);

  switch (type) {
    case 'object':
      return Object.keys(internalValue).map((key, index) => (
        <div key={`${name}-object-expand-${index + 1}`} className='mt-2'>
          <CardExpandParameter
            defaultValue={defaultValue[key]}
            onUpdateForm={(paramType, value) => {
              setInternalValue((prev) => ({
                ...prev,
                [paramType]: value,
              }));
            }}
            name={key}
            type={key}
            title={key}
          />
        </div>
      ));
    case 'array':
      return defaultValue.map((elem, index) => {
        return (
          <ParameterTypeVariable
            key={`array-expand-${index + 1}`}
            onUpdateForm={(value) => {
              setInternalValue((prev) => {
                const newArr = [...prev];
                newArr.splice(index, 1, value);

                return newArr;
              });
            }}
            defaultValue={elem}
          />
        );
      });
    case 'number':
      return (
        <InputWithLabel
          type='number'
          className={'mb-2'}
          min={0}
          onChange={({ target: { value } }) => {
            setInternalValue(value);
          }}
          name={name}
          value={internalValue}
        />
      );
    case 'string':
      return (
        <InputWithLabel
          className={'mb-2'}
          name={name}
          value={internalValue}
          onChange={({ target: { value } }) => {
            setInternalValue(value);
          }}
        />
      );
    case 'boolean':
      return (
        <div className='flex mb-2'>
          <Checkbox
            checked={internalValue}
            name={name}
            onChange={({ target: { checked } }) => {
              setInternalValue(checked);
            }}
          >
            Check
          </Checkbox>
        </div>
      );
    default:
      return null;
  }
});

const CardExpandParameter = ({ title, type, onUpdateForm, defaultValue }) => {
  const [isExpand, setIsExpand] = useState(false);

  return (
    <Card className={'flex flex-col rounded-xl'}>
      <div
        className='flex justify-between items-center pointer'
        onClick={() => {
          setIsExpand((prev) => !prev);
        }}
      >
        <div className='flex items-center'>
          <span style={{ fontSize: 14, fontWeight: 900 }}>{title}</span>
        </div>
        {isExpand ? (
          <UpOutlined className='mt-1' />
        ) : (
          <DownOutlined className='mt-1' />
        )}
      </div>
      {isExpand ? (
        <div className='mt-3'>
          <ParameterTypeVariable
            onUpdateForm={(value) => {
              onUpdateForm(type, value);
            }}
            defaultValue={defaultValue}
          />
        </div>
      ) : null}
    </Card>
  );
};

const InputCode = memo(
  ({ defaultValue, onBlurCode }) => {
    const [codeValue, setCodeValue] = useState(defaultValue);

    useEffect(() => {
      setCodeValue(defaultValue);
    }, [defaultValue]);

    const _handleChangeCode = useCallback(({ target: { value } }) => {
      setCodeValue(value);
    }, []);

    return (
      <div className='text-area-container'>
        <TextArea
          rows={4}
          value={codeValue}
          onChange={_handleChangeCode}
          onBlur={onBlurCode}
          className='text-area-custom'
        />
      </div>
    );
  },
  (prev, curr) => prev.defaultValue === curr.defaultValue,
);

const InputCodeConvert = ({ value: initialValueCode, onChange }) => {
  const [codeValue, setCodeValue] = useState(() =>
    JSON.parse(initialValueCode || '{}'),
  );
  const newValue = JSON.stringify(codeValue);

  useEffect(() => {
    onChange({ value: newValue, name: 'value' });
  }, [newValue]);

  const _handleBlurCode = useCallback(({ target: { value } }) => {
    setCodeValue((prev) => {
      try {
        return JSON.parse(value);
      } catch (error) {
        notification.error({ message: 'Código inválido.' });

        return prev;
      }
    });
  }, []);

  const onUpdateForm = useCallback((type, value) => {
    if (type && value) {
      setCodeValue((prev) => ({ ...prev, [type]: value }));
    }
  }, []);

  const tabs = [
    {
      key: '1',
      label: 'Expandido',
      children: (
        <>
          {Object.keys(codeValue).map((key, index) => (
            <Col
              key={key}
              span={12}
              className='mb-3'
              style={
                index % 2 === 0 ? { paddingRight: 12 } : { paddingLeft: 12 }
              }
            >
              <CardExpandParameter
                defaultValue={codeValue[key]}
                onUpdateForm={onUpdateForm}
                name={key}
                type={key}
                title={key}
              />
            </Col>
          ))}
        </>
      ),
    },
    {
      key: '2',
      label: 'Código',
      children: (
        <InputCode defaultValue={newValue} onBlurCode={_handleBlurCode} />
      ),
    },
  ];

  return <Tabs defaultActiveKey='1' items={tabs} />;
};

export default memo(InputCodeConvert);
