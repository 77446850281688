import { Button, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { RideButton } from '@rimac-seguros/ride-system-components';

const { Title } = Typography;

const Header = ({ title, activeTab }) => {
  const navigate = useNavigate();

  const handleCreateEvent = () => {
    localStorage.setItem('event-selected', null);
    navigate('/monitoreo/evento/detalle');
  };

  return (
    <header className='flex flex-row items-center justify-between mb-4'>
      <Title className='header-container'>{title}</Title>
      {activeTab === 'eventManagment' && (
        <RideButton
          onClick={handleCreateEvent}
          size='large'
          text='Crear evento'
        />
      )}
    </header>
  );
};

export default Header;
