import {
  UPDATE_FORM_PERSONAL_DATA_VALUES,
  UPDATE_FORM_RECURRENCY_VALUES,
  UPDATE_FORM_LOCATION_VALUES,
  UPDATE_FORM_EDIFICATION_VALUES,
  UPDATE_FORM_MACHINE_VALUES,
  UPDATE_FORM_STOCK_VALUES,
  UPDATE_FORM_LOSS_PROFIT_VALUES,
  UPDATE_FORM_PROTECTION_VALUES,
  UPDATE_FORM_GEOLOGY_VALUES,
  UPDATE_FORM_MULTIPLE_VALUES,
} from '../actions/actionsType';

const initialState = {
  polizaId: null,
  steps: {
    personalDataForm: 2,
    recurrencyForm: 2,
    locationForm: 2,
    edificationForm: 2,
    machineForm: 2,
    stockForm: 2,
    lossProfitForm: 2,
    protectionForm: 2,
    geologyForm: 2,
  },
  personalDataForm: null,
  recurrencyForm: {
    recurrencyQuestion1: '',
    recurrencyQuestion2: '',
  },
  locationForm: {
    locationQuestion1: '',
    locationQuestion2: '',
    locationQuestion3: '',
  },
  edificationForm: {
    edificationQuestion1: '',
    edificationQuestion2: '',
    edificationQuestion3: '',
    edificationQuestion4: '',
    edificationQuestion5: '',
    edificationQuestion6: '',
    edificationQuestion7: '',
    edificationQuestion8: '',
  },
  machineForm: {
    machineQuestion1: '',
    machineQuestion2: '',
    machineQuestion3: '',
    machineQuestion4: '',
    machineQuestion5: '',
  },
  stockForm: {
    stockQuestion1: '',
    stockQuestion2: '',
    stockQuestion3: '',
  },
  profitLossForm: {
    profitQuestion1: '',
    profitQuestion2: '',
    profitQuestion3: '',
  },
  protectionForm: {
    protectionQuestion1: '',
    protectionQuestion2: '',
    protectionQuestion3: '',
    protectionQuestion4: '',
    protectionQuestion5: '',
    protectionQuestion6: '',
    protectionQuestion7: '',
  },
  geologyForm: {
    geologyQuestion1: '',
    geologyQuestion2: '',
    geologyQuestion3: '',
    geologyQuestion4: '',
    geologyQuestion5: '',
  },
};

const rainFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_FORM_PERSONAL_DATA_VALUES:
      return {
        ...state,
        personalDataForm: {
          ...state.personalDataForm,
          ...action.payload,
        },
      };
    case UPDATE_FORM_RECURRENCY_VALUES:
      return {
        ...state,
        recurrencyForm: {
          ...state.recurrencyForm,
          ...action.payload,
        },
      };
    case UPDATE_FORM_LOCATION_VALUES:
      return {
        ...state,
        locationForm: {
          ...state.locationForm,
          ...action.payload,
        },
      };
    case UPDATE_FORM_EDIFICATION_VALUES:
      return {
        ...state,
        edificationForm: {
          ...state.edificationForm,
          ...action.payload,
        },
      };
    case UPDATE_FORM_MACHINE_VALUES:
      return {
        ...state,
        machineForm: {
          ...state.machineForm,
          ...action.payload,
        },
      };
    case UPDATE_FORM_STOCK_VALUES:
      return {
        ...state,
        stockForm: {
          ...state.stockForm,
          ...action.payload,
        },
      };
    case UPDATE_FORM_LOSS_PROFIT_VALUES:
      return {
        ...state,
        lossProfitForm: {
          ...state.lossProfitForm,
          ...action.payload,
        },
      };
    case UPDATE_FORM_PROTECTION_VALUES:
      return {
        ...state,
        protectionForm: {
          ...state.protectionForm,
          ...action.payload,
        },
      };
    case UPDATE_FORM_GEOLOGY_VALUES:
      return {
        ...state,
        geologyForm: {
          ...state.geologyForm,
          ...action.payload,
        },
      };
    case UPDATE_FORM_MULTIPLE_VALUES:
      let toUpd = {};
      for (let key in action.payload) toUpd[key] = action.payload[key];

      return {
        ...state,
        ...toUpd,
      };
    default:
      return state;
  }
};

export default rainFormReducer;
