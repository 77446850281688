import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { checkPermission } from '../../../lib/security';
import SubNavegation from './SubNavigation';

const LinkMenu = (props = {}) => {
  const isOpened = useSelector((state) => state.sideMenu.isOpened);

  if (props.to) {
    return <NavLink {...props}>{props.children}</NavLink>;
  } else {
    return isOpened ? <span>{props.children}</span> : <hr />;
  }
};

const ChildrenMenu = ({ item, user }) => {
  const havePermissions =
    (item.permission && checkPermission(user, item.permission)) ||
    !item.permission;

  return havePermissions ? (
    <li key={item.key}>
      <LinkMenu to={havePermissions && item.to}>{item.name}</LinkMenu>
      <ul className='submenu submenu--show'>
        <SubNavegation user={user} items={item.children} />
      </ul>
    </li>
  ) : null;
};

export default ChildrenMenu;
