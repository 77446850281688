import { get } from '../lib/ajax';

export const runUnsubscribe = async (code_invitation, reason) => {
  const r = await get(
    '/unsubscribe?code=' + code_invitation + '&reason=' + encodeURI(reason),
    false,
  );

  return r;
};
