import React, { memo } from 'react';
import IconInformation from '../../../../images/icon_info.svg';

const NotSelectedAddress = ({ title = '' }) => {
  return (
    <div className='not-selected-box'>
      <img src={IconInformation} alt='' />
      <h2 style={{ fontWeight: 'bold', fontSize: '20px', color: '#7981B2' }}>
        {title}
      </h2>
    </div>
  );
};

export default memo(NotSelectedAddress);
