import { LOADED_ZONES } from '../actions/actionsType';

const initialState = {
  zones: [],
  isLoadingList: false,
};

const zones = (state, action) => {
  if (!state) {
    state = initialState;
  }

  switch (
    action.type // NOSONAR
  ) {
    case LOADED_ZONES:
      if (action.payload) {
        return {
          ...state,
          zones: action.payload,
          isLoadingList: false,
        };
      }
      return { ...state };
    default:
      return state;
  }
};

export default zones;
