import { useState } from 'react';
import { useMap, Marker, Popup } from 'react-leaflet';
import { Button, notification } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import {
  RideGlLocationMapSolid,
  RideActionButton,
} from '@rimac-seguros/ride-system-components';

const FlyToButton = () => {
  const map = useMap();
  const [markerPosition, setMarkerPosition] = useState(null);
  const [loading, setLoading] = useState(false);

  const goToCurrentLocation = () => {
    setLoading(true);
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setMarkerPosition([latitude, longitude]);
          map.flyTo([latitude, longitude], 18);
          setLoading(false);
        },
        () => {
          notification.error({
            message: 'Error',
            description: 'No se pudo obtener la ubicación actual.',
          });
          setLoading(false);
        },
      );
    } else {
      notification.error({
        message: 'Error',
        description: 'Tu navegador no soporta geolocalización.',
      });
      setLoading(false);
    }
  };

  const removeMarker = () => {
    setMarkerPosition(null);
  };

  return (
    <>
      <RideActionButton
        glyph={<RideGlLocationMapSolid />}
        tooltipText='Ir a mi ubicación'
        onClick={goToCurrentLocation}
        placement='left-center'
        loading={loading}
        size='small'
        style={{ position: 'absolute', bottom: 10, right: 10, zIndex: 1000 }}
      />
      {markerPosition && (
        <Marker position={markerPosition}>
          <Popup>
            <div className='p-2' style={{ textAlign: 'center' }}>
              <h3>¡Estás aquí!</h3>
              <p>
                Esta es tu ubicación actual basada en la geolocalización de tu
                dispositivo.
              </p>
              <Button
                icon={<DeleteOutlined />}
                size='small'
                type='danger'
                onClick={removeMarker}
              >
                Borrar marcador
              </Button>
            </div>
          </Popup>
        </Marker>
      )}
    </>
  );
};

export default FlyToButton;
