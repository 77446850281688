import moment from 'moment';

export const finishFilters = (list, filtersOpts = {}) => {
  const filterKeys = Object.keys(filtersOpts);

  const filterList = list.filter((item) => {
    if (filterKeys.includes('gestor'))
      if (!filtersOpts['gestor'].includes(item['gestor'])) return false;

    if (filterKeys.includes('segment'))
      if (!filtersOpts['segment'].includes(item['segment'])) return false;

    if (filterKeys.includes('start') && filterKeys.includes('end'))
      if (
        !(
          moment(item?.startDate, 'DD-MM-YYYY').isBetween(
            filtersOpts.start,
            filtersOpts.end,
          ) &&
          moment(item?.endDate, 'DD-MM-YYYY').isBetween(
            filtersOpts.start,
            filtersOpts.end,
          )
        )
      )
        return false;

    return true;
  });

  return filterList;
};

export const filtersCommon = [
  {
    key: 'segment',
    placeholder: 'Por segmento comercial',
    type: 'selectMultiple',
    value: 'id',
    label: 'name',
  },
  {
    key: 'gestor',
    placeholder: 'Gestor de Prevención',
    type: 'selectMultiple',
    value: 'code',
    label: 'name',
  },
];
