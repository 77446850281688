import React, { memo, useState } from 'react';
import { Button } from 'antd';

import {
  IconCloseWithoutCircle,
  IconDots,
} from '../../ui/PreventionService/Reports/utils';

const info = {
  1: {
    text: 'Nuevo',
    color: '#A7EB92',
    point: '#389E0D',
  },
  2: {
    text: 'Mejorado',
    color: '#C8C8FA',
    point: '#4F4FFF',
  },
};

const renderType = (data, section) => {
  if (section === 'levelCompany')
    return data.type === 2 ? 'Personalizado' : 'Estandar';
};

const renderCount = (data, section) => {
  if (section === 'levelCompany')
    return `${
      data?.levelCompanyServices?.length
        ? data?.levelCompanyServices?.length
        : 0
    } ${data?.levelCompanyServices?.length === 1 ? 'Servicio' : 'Servicios'}`;
};

const renderDate = (data, section) => {
  if (section === 'articles-list') {
    const dateObj = new Date(data.createdAt);
    const day = dateObj.getDate();
    const month = dateObj.toLocaleString('default', { month: 'short' });
    const year = dateObj.getFullYear();
    const hour = dateObj.getHours();
    const minutes = dateObj.getMinutes();

    return data?.createdAt
      ? `${day} ${month} ${year} - ${hour}:${minutes}`
      : '';
  }
};

const renderAuthor = (data, section) => {
  if (section === 'articles-list')
    return (
      `${data.user?.name} ${data.user?.last_name1} ${data.user?.last_name2}` ||
      ''
    );
};

const CardItemMobile = ({
  data,
  onPreview,
  onEdit,
  onDelete,
  onSend,
  columns,
  section = '',
  isLoadingPublish,
}) => {
  const [opened, setOpened] = useState(false);
  const type = columns.find((el) => el.key === 'type');

  return (
    <>
      <article className='card-user card-external-action'>
        <div className={`flex ${type ? 'pos-justify' : 'pos-end'} `}>
          {type && data?.type && (
            <div className='flex flex-row flex-justify-justify-between'>
              <span
                style={{ background: info[data.type]?.color || '', height: 20 }}
                className={'withpoint'}
              >
                <div
                  style={{ background: info[data.type]?.point || '' }}
                  className={'withpoint-circle'}
                ></div>
                <span
                  style={{
                    fontFamily: 'BR Sonoma',
                    fontWeight: 900,
                    fontSize: 11,
                    lineHeight: 16,
                  }}
                >
                  {info[data.type]?.text || ''}
                </span>
              </span>
            </div>
          )}

          <div className='action'>
            <Button className='button-popover' onClick={() => setOpened(true)}>
              <IconDots />
            </Button>
          </div>
        </div>

        {columns
          .filter((el) => el.key !== 'type')
          .map(({ title, dataIndex, key }) => {
            return (
              <div key={key}>
                <h4>{title}</h4>
                <p
                  className='text-uppercase'
                  style={{ wordBreak: 'break-all' }}
                >
                  {dataIndex === 'type'
                    ? renderType(data, section)
                    : dataIndex === 'count'
                    ? renderCount(data, section)
                    : dataIndex === 'createdAt'
                    ? renderDate(data, section)
                    : key === 'author'
                    ? renderAuthor(data, section)
                    : data[dataIndex] || ''}
                </p>
              </div>
            );
          })}
      </article>

      {opened && (
        <div className='action-complete'>
          <div className='content'>
            <div className='title'>
              <h3>Más opciones</h3>
              <div className='icon' onClick={() => setOpened(false)}>
                <IconCloseWithoutCircle />
              </div>
            </div>

            <div className='buttons'>
              <div className='button' onClick={() => onPreview(data)}>
                <span>Vista Previa</span>
              </div>
              <div className='button' onClick={() => onEdit(data)}>
                <span>Editar</span>
              </div>

              {section === 'articles-list' && data?.state === 1 && (
                <Button
                  className='button'
                  loading={isLoadingPublish === data.id}
                  disabled={!!isLoadingPublish}
                  onClick={() => onSend(data)}
                >
                  <span>Enviar a clientes</span>
                </Button>
              )}

              <div className='button' onClick={() => onDelete(data)}>
                <span>Eliminar</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default memo(CardItemMobile);
