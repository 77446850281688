import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from 'antd';
import L from 'leaflet';

import { COMMON_SET_POSITION_LATLNG } from '../../../../redux/actions/actionsType';
import { adornText } from '../../Topbar/utils';
import { clickOnPredictions } from './utils';
import usePlacesAutocomplete from '../../../../hooks/usePlacesAutocomplete';

import IconLocation from './IconLocation';

const { Search } = Input;

const resetTextToHtml = (text) => {
  const wordInit = text.substring(0, text.indexOf('<b>'));
  const wordSelect = text.substring(
    text.indexOf('<b>') + 3,
    text.indexOf('</b>'),
  );
  const wordEnd = text.substring(text.indexOf('</b>') + 4);

  return (
    <span>
      {wordInit}
      <span className='highlighted'>{wordSelect}</span>
      {wordEnd}
    </span>
  );
};

const DrawerSearch = () => {
  const dispatch = useDispatch();
  const [querySearch, setQuerySearch] = useState('');
  const map = useSelector((state) => state.maps.map);
  const places = usePlacesAutocomplete(querySearch, (latlng) => {
    dispatch({
      type: COMMON_SET_POSITION_LATLNG,
      payload: L.latLng(latlng.lat, latlng.lng),
    });
    map.flyTo(latlng, 10);
  });

  const predictions = places.map((p) => ({
    ...p,
    textHighlight: resetTextToHtml(
      adornText(p.structured_formatting?.main_text || '', querySearch),
    ),
  }));

  const handleSelectPlace = (prediction) => {
    setQuerySearch(prediction.description);
    clickOnPredictions(prediction.place_id, prediction.description).then(
      (position) => {
        dispatch({ type: COMMON_SET_POSITION_LATLNG, payload: position });
        map.flyTo(position, 10);
      },
    );
  };

  return (
    <div className='drawer-map-search__body__input'>
      <Search
        id='input_search'
        className='drawer-map-search__body__input__search'
        placeholder='Palabra clave'
        style={{ width: '100%' }}
        onChange={(e) => setQuerySearch(e.target.value)}
        value={querySearch}
        prefix={<i className='fas fa-search'></i>}
        allowClear
      />
      <div className='drawer-map-search__body__input__list'>
        <div
          className='drawer-map-search__body__input__list__box'
          style={{ display: predictions.length === 0 ? 'block' : 'none' }}
        >
          <p className='drawer-map-search__body__input__list__box__text'>
            {' '}
            <span>
              {querySearch === '' || querySearch.length < 4
                ? 'Ingresar una palabra de búsqueda'
                : 'No se encontraron coincidencias'}
            </span>
          </p>
        </div>
        {predictions.map((prediction, key) => (
          <div
            key={key}
            className='drawer-map-search__body__input__list__item'
            style={{ background: !key ? '#E3E6FA' : 'none' }}
            onClick={() => handleSelectPlace(prediction)}
          >
            <IconLocation />
            <div className='drawer-map-search__body__input__list__item__text'>
              <p className='drawer-map-search__body__input__list__item__text__title'>
                <span>{prediction?.textHighlight}</span>
              </p>
              <p className='drawer-map-search__body__input__list__item__text__address'>
                {prediction?.structured_formatting?.secondary_text}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DrawerSearch;
