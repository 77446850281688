import { Button, Modal } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { OPEN_FORM_REQUEST } from '../../../../../../redux/actions/actionsType';

const ModalToPlain = ({
  isActive,
  handleCancel,
  count = 0,
  updateForm,
  item,
}) => {
  const navigate = useNavigate();

  const handleAdd = () => {
    const id = `${item.id}-${item.requestServiceId}`;

    updateForm({
      id,
      service: {
        serviceName: item.service.name,
        serviceType: item.service.type,
        serviceId: item.service.id,
        id: item.service.id,
        levelCompany: item.requestService.companyId,
      },
      contact: {
        contact: item.contact,
        address: item.address,
        position: item.position,
        number: item.number,
        email: item.email,
      },
      step: 1,
      additional: count,
      polizaId: item.requestService.polizaId,
      requestServiceId: item.requestServiceId,
      requestServiceItemId: item.id,
    });
    navigate(
      `/solicitar-servicio?poliza=${item.requestService.polizaId}&service=${item.serviceId}`,
    );
  };

  return (
    <Modal
      open={isActive}
      className='modalToPlain'
      onCancel={handleCancel}
      destroyOnClose
      maskClosable={true}
      footer={null}
      centered={true}
    >
      <div className='modalToPlain-content'>
        <h3>Planificar servicios de señaletica</h3>

        <p>
          Tiene {count} unidades disponibles para distribuir en el servicio
          solicitado
        </p>

        <Button onClick={handleAdd}>Agregar</Button>
      </div>
    </Modal>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateForm: async (payload) => {
    dispatch({
      type: OPEN_FORM_REQUEST,
      payload: {
        data: payload,
        action: 'add-exists',
      },
    });
  },
});

const mapStateToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ModalToPlain);
