import React, { useContext } from 'react';
import L from 'leaflet';
import { PopupContext } from '.';
import { standardizeLongitude } from '../../lib/utils';

const PopupLayerPosition = () => {
  let position = useContext(PopupContext);

  if (Array.isArray(position)) {
    position = L.latLng(position[0], position[1]);
  }

  if (!position) {
    return null;
  }

  return (
    <div className='popup-content popup-map-scrollable'>
      <span className='popup-detail'>
        <span className='popup-detail-header'>Coordenadas</span>
        <span className='popup-detail-content'>
          {position.lat.toFixed(6)},{' '}
          {standardizeLongitude(position.lng).toFixed(6)}
        </span>
      </span>
    </div>
  );
};

export default PopupLayerPosition;
